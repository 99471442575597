<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>HR Policies List</h1>
            </div>
        </div>
        <div class="navbar page-breadcrumb breadcrumb mb20">
           
            <ul class="nav navbar-nav navbar-right">
                
                <li>
                    <div *ngIf="this.currentUserRole == true">
                   
                        <a (click)="id=0;modalOpen=true" class="btn btn-transparent blue  btn-sm">Add
                            New</a>
                    </div>

                    
                   
                </li>
            </ul>
        </div>
        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9 customSelect">
                            <div class="text-left">        
                                <select class="form-control" [(ngModel)]="itemsPerPage" (change)="onItemsPerPageChange()"  style="width: 75px;">
                                   <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                                 </select>
                            </div>
                        </div>
                        <div class="pull-right text-right">
                            <input type="text" class="form-control" placeholder="Type to filter ....."
                                autocomplete="off" id="filterList" (input)="getHrPoliciesList()">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped  table-bordered table-hover">
                            <thead class="flip-content">
                                <tr>
                                    <th class="text-center">Ref Name</th>
                                    <!-- <th class="text-center">File Name</th> -->
                                    <th class="text-center">Document Name </th>
                                    <th class="text-center"> Actions </th>
                                </tr>
                            </thead>
                            <tbody  *ngFor="let item of hr_policies_documentList| paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">
                                <tr >
                                    <td>{{item.ref_Name}}</td>
                                    <!-- <td>{{item.file_Name}}</td> -->
                                    <td>{{item.doc_Description}}</td>
                                    <td class="text-center">
                                     
                                      
                                            <a (click)="updateStatus(item.id,'0') ">
                                                <div class="circle greenColor" *ngIf="item.isActive && this.currentUserRole == true" data-toggle="tooltip" data-placement="top" title="Active" style="float: none; vertical-align: inherit;"></div>
                                            </a>
                                            <a (click)="updateStatus(item.id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive && this.currentUserRole == true" data-toggle="tooltip" data-placement="top" title="Inactive" style="float: none; vertical-align: inherit;"></div>
                                            </a>
                                       

                                            <a *ngIf="item.isActive && this.currentUserRole == true" data-toggle="modal"
                                            (click)="id=item.id;modalOpen=true"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit HR Policies"></i></a>&nbsp;

                                        <a (click)="downloadDocument(item.id,item.doc_Path,item.file_Name)" data-toggle="modal"
                                        *ngIf="item.doc_Path != null"><i
                                            class="fa fa-download purple iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Download HR Policies"></i></a>
                                        &nbsp;

                                        <a (click)="viewDocument(item.id,item.doc_Path,item.file_Name)" data-toggle="modal"
                                        *ngIf="item.doc_Path != null"><i
                                            class="fa fa-eye blue iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="View HR Policies"></i></a>
        

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                     
                    <div class="text-center" *ngIf="hr_policies_documentList != null">
                        <pagination-controls (pageChange)="getHrPoliciesList($event)" autoHide="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="modalOpen">
    <app-hr-policies-form [pId]="id" (modalClose)="modalOpen=$event" (refreshPage)="getHrPoliciesList($event)"></app-hr-policies-form>
</div>


