<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">

        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Leave Approval
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <div class="row">
                        <div class="col-md-12">
                            <ul class="nav nav-tabs">
                                <li class="active">
                                    <a data-toggle="tab"
                                        (click)="tabActive = 'calendarView'; loadApprovalCalendarView();">Calendar
                                        View</a>
                                </li>
                                <li><a data-toggle="tab" (click)="tabActive = 'listView'">List View</a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane active" [class.active]="tabActive == 'calendarView'"
                                    id="calendarView">
                                    <div class="col-md-10" style="z-index: 0;">
                                        <div id="approvalLeaveCalendar" class="portlet light bordered">
                                            <full-calendar [options]='calendarOptions'>
                                            </full-calendar>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                      <select class="form-control employeeSelectClass">
                                        <option value="">Select Employee</option>
                                        <option>XYZ</option>
                                        <option>ABC</option>
                                     </select>
                                        <!-- <ng-select2 [data]="empList" id="empidsfilter"
                                            [options]="{multiple: true,tags: false}"
                                            (valueChanged)="setFormData($event)"
                                            [placeholder]="'Select employee'" *ngIf="(roleidCond == 1 || roleidCond == 2 || roleidCond == 6) && userroleCond && emp_idCond">
                                        </ng-select2> -->
                                        <table class="table">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">
                                                        <span class="label label-info"
                                                            style="display: block;padding: 9px;background: #F5B041 !important;font-size: 15px;font-weight: 500;border-radius: 30px!important;width: 100px;float: left;margin-right: 12px;margin-bottom: 5px;">Pending
                                                        </span>
                                                        <input type="checkbox" class="bSwitch bSwitch_approval"
                                                            name="checkbox[]" id="checkbox11" checked value="Pending" />
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <span class="label label-primary"
                                                            style="display: block;padding: 9px;background: #52BE80!important;font-size: 15px;font-weight: 500;border-radius: 30px!important;width: 100px;float: left;margin-right: 12px;margin-bottom: 5px;">Approved
                                                        </span>
                                                        <input type="checkbox" class="bSwitch bSwitch_approval"
                                                            name="checkbox[]" id="checkbox22" checked
                                                            value="Approved" />
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <span class="label label-warning"
                                                            style="display: block;padding: 9px;background: #E74C3C!important;font-size: 15px;font-weight: 500;border-radius: 30px!important;width: 100px;float: left;margin-right: 12px;margin-bottom: 5px;">Rejected
                                                        </span>
                                                        <input type="checkbox" class="bSwitch bSwitch_approval"
                                                            name="checkbox[]" id="checkbox33" checked
                                                            value="Rejected" />
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <span class="label label-success"
                                                            style="display: block;padding: 9px;background: #5DADE2!important;font-size: 15px;font-weight: 500;border-radius: 30px!important;width: 100px;float: left;margin-right: 12px;margin-bottom: 5px;">Cancelled
                                                        </span>
                                                        <input type="checkbox" class="bSwitch bSwitch_approval"
                                                            name="checkbox[]" id="checkbox44" checked
                                                            value="Cancelled" />
                                                    </th>
                                                </tr>

                                                <tr>
                                                    <!--*ngIf="isApprovedLeaveCancelPresent" -->
                                                    <th scope="row">
                                                        <span class="label label-success"
                                                            style="display: block;padding: 9px;background: #800080!important;font-size: 11px;font-weight: 500;border-radius: 30px!important;width: 100px;float: left;margin-right: 12px;margin-bottom: 5px;">Cancel
                                                            Request
                                                        </span>
                                                        <input type="checkbox" class="bSwitch bSwitch_approval"
                                                            name="checkbox[]" id="checkbox66" checked
                                                            value="CancellationRequest" />
                                                    </th>
                                                </tr>

                                                <tr>
                                                    <th scope="row">
                                                        <span class="label label-success"
                                                            style="display: block;padding: 9px;background: #99A3A4!important;font-size: 15px;font-weight: 500;border-radius: 30px!important;width: 100px;float: left;margin-right: 12px;margin-bottom: 5px;">Holiday
                                                        </span>
                                                        <!-- <input type="checkbox" name="checkbox[]" id="checkbox55" checked value="Holiday"/> -->
                                                    </th>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-content">
                             
                                <div class="tab-pane" [class.active]="tabActive == 'listView'" id="listView">
                                    <form method="get" [formGroup]="approvalform">
                                    <div class="row">
                                      <div class="col-md-2 col-sm-6">
                                        <select id="company_Id" class="form-control" formControlName="company_Id" (change)="companyWiseGetData()">
                                            <option value="">Select Company</option>
                                            <option *ngFor="let company of companyList;" [value]="company.company_Id">{{company.company_Name}}</option>
                                         </select>
                                      </div>
                                      <div class="col-md-2 col-sm-6">
                                        <select id="Dept_Id" class="form-control" formControlName="Dept_Id" >
                                            <option value="">Select Department</option>
                                            <option *ngFor="let item of departmentList;" [value]="item.dept_Id">
                                                {{item.dept_Name}}</option>
                                        </select>
                                      </div>
                                      <div class="col-md-1 col-sm-6">
                                        <select id="Zone_Id" class="form-control" formControlName="Zone_Id">
                                        <option value="">Select Zone</option>
                                 <option *ngFor="let item of zoneList;" value={{item.zone_Id}}>
                                      {{item.zone_Name}}</option>
                                      </select>
                                      </div>
                                        <div class="col-md-2 col-sm-6">
                                          <select class="form-control employeeSelectClass" formControlName="leavestatus">
                                            <option value="">Select Leave status</option>
                                            <option>Approved</option>
                                            <option>Rejected</option>
                                            <option>Pending</option>
                                            <option>Cancelled</option>
                                         </select>
                                        </div>
                                        <div class="col-md-2 col-sm-6">
                                        <select class="form-control employeeSelectClass"  >
                                            <option value="">Select Employee Name</option>
                                            <option>ABC</option>
                                            <option>XYZ</option>
                                         </select>
                                         <!-- <select id="Zone_Id" class="form-control" formControlName="fullName">
                                            <option value="">Select Employee Name</option>
                                     <option *ngFor="let item of employeeList;" value={{item.zone_Id}}>
                                          {{item.zone_Name}}</option>
                                          </select> -->
                                        </div>
                                        <div class="col-md-2 col-sm-6">
                                          <div class="input-group DOB" id="listviewDate" data-wrap="true">
                                            <input type="text" class="form-control readonlyClass"
                                              id=""       formControlName="approvalDate"    data-input />

                                            <span class="input-group-btn" data-toggle>
                                                <button class="btn default form-control datePicker" type="button">
                                                    <i class="fa fa-calendar"></i>
                                                </button>
                                            </span>
                                        </div>
                                        </div>
                                        <div class="col-md-1 col-sm-6">
                                          <button class="btn blue"  (click)="advanceFilter()">Go</button>
                                        </div>
                          
                                        <!-- <div class="col-md-3 col-sm-6 text-right">(click)="disableLeave()"
                                            <input type="text" class="form-control" placeholder="Type to filter ..."
                                                id="filterList" (input)="getLeaveGridList(1)">
                                        </div> -->
                                    </div>
                                </form>
                                    <br />
                                    <div class="text-left">        
                                        <select class="form-control" [(ngModel)]="itemsPerPage" (change)="onItemsPerPageChange()"  style="width: 75px;">
                                            <option *ngFor="let option of itemsPerPageOptions" [value]="option" >{{ option }}</option>
                                          </select>

                                         
                                    </div>
                                    <div class="table-responsive">
                                      <table id="example2" class="display table table-striped table-bordered"
                                            cellspacing="0" width="100%" style="border:1px solid #e7ecf1;">
                                            <thead>
                                                <tr>
                                                  <th class="text-center" (click)="sortColumn('Pending Days')"   style="width: 15%;"> Pending Days <i class="fa" [ngClass]="getSortIcon(' Pending Days')"></i></th>
                                                    <th class="text-center"  (click)="sortColumn(' Employee Name')" style="width: 15%;"> Employee Name  <i class="fa" [ngClass]="getSortIcon(' Employee Name ')"></i></th>
                                                    <th class="text-center" (click)="sortColumn('Start Date')"  style="width: 15%;"> Start Date  <i class="fa" [ngClass]="getSortIcon('  Start Date')"></i> </th>
                                                    <th class="text-center"  (click)="sortColumn(' End Date')"  style="width: 10%;"> End Date  <i class="fa" [ngClass]="getSortIcon('  End Date ')"></i> </th>
                                                    <th class="text-center" (click)="sortColumn(' No. Of Days ')" style="width: 10%;"> No. Of Days   <i class="fa" [ngClass]="getSortIcon(' No. Of Days')"></i></th>
                                                    <th class="text-center" (click)="sortColumn(' Leave Type ')"  style="width: 10%;"> Leave Type  <i class="fa" [ngClass]="getSortIcon(' Leave Type')"></i> </th>
                                                    <th class="text-center" (click)="sortColumn(' Status ')" style="width: 10%;"> Status  <i class="fa" [ngClass]="getSortIcon(' Status')"></i></th>
                                                    <th class="text-center" (click)="sortColumn('  Applied By  ')" style="width: 10%;"> Applied By  <i class="fa" [ngClass]="getSortIcon(' Applied By')"></i></th>
                                                    <th class="text-center" (click)="sortColumn('  Approved By ')" style="width: 10%;"> Approved By  <i class="fa" [ngClass]="getSortIcon(' Approved By ')"></i> </th>
                                                    <th class="text-center" style="width: 10%;"> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                          <tr  >
                                            <td class="text-center">10</td>
                                            <td class="text-center">XYZ</td>
                                            <td class="text-center">21 oct 2023
                                            </td>
                                            <td class="text-center">23 nov 2023</td>
                                            <td class="text-center">3</td>
                                            <td class="text-center">Annual</td>
                                            <td class="text-center">Approved</td>
                                            <td class="text-center">XYZ</td>
                                            <td class="text-center">ABC</td>
                                            <td>
                                              <a data-toggle="modal" (click)="approvedLeave()" class="mr-1"
                                            >
                                              <i class="fa fa-check green-color iconcss iconFontsize" data-toggle="tooltip" data-placement="top"
                                              title="Approve Leave"></i></a>
                                              <a data-toggle="modal" (click)="editLeave()" class="mr-1"
                                              >
                                              <i class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Leave"></i></a>
                                                <a data-toggle="modal" (click)="rejectLeave()" class="mr-1"
                                                >
                                                <i
                                                class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Reject Leave"></i></a>


                                            </td>
                                        </tr>
                                        </tbody>
                                            </table>
                                        <!-- <table id="example2" class="display table table-striped table-bordered"
                                            cellspacing="0" width="100%" style="border:1px solid #e7ecf1;">
                                            <thead>
                                                <tr>
                                                    <th class="text-center" style="width: 15%;"> Date</th>
                                                    <th class="text-center" style="width: 15%;"> Name</th>
                                                    <th class="text-center" style="width: 15%;">Leave Type </th>
                                                    <th class="text-center" style="width: 10%;"> Effective Days </th>
                                                    <th class="text-center" style="width: 10%;"> Approved Days </th>
                                                    <th class="text-center" style="width: 10%;"> Status </th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                *ngFor="let item of leaveGridList | paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalGridRecord, id:'leaveGrid'}">
                                                <tr>
                                                    <td class="text-center"><a data-toggle="modal"
                                                            (click)="openModelForLeaves(item.id)">{{item.start_date |
                                                            date:'dd/MM/yyyy'}}
                                                            To {{item.end_date | date:'dd/MM/yyyy'}}</a></td>
                                                    <td class="text-center">{{item.employee_FullName}}</td>
                                                    <td class="text-center">{{item.leave_type}} ({{item.leave_Code}})
                                                    </td>
                                                    <td class="text-center">{{item.no_of_leaves}}</td>
                                                    <td class="text-center">{{item.approvedCount}}</td>
                                                    <td class="text-center">{{item.status}}</td>
                                                </tr>
                                            </tbody>
                                        </table> -->
                                    </div>
                                 <div class="text-center" >
                                        <pagination-controls (pageChange)=" getEmployeeList($event)" autoHide="true">
                                        </pagination-controls>
                                        
                                    </div>
                                
                                </div>

                          
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->

<div class="modal fade draggable-modal" id="approvedLeaveModal" tabindex="-1" role="basic" aria-hidden="true">
  <div class="modal-dialog modal-md" style="margin:5% auto !important">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
              <h4 class="modal-title">Alert!</h4>
          </div>
          <div class="modal-body">
            <h4 class="text-center"><b>You want to Approve!</b></h4>
             <input type="text" class="form-control">
          </div>
          <div class="modal-footer">
            <button type="button"  class="btn blue">Yes!</button>
              <button type="button"  class="btn btn-secondary" title="Clear All">Cancel</button>
              <!-- <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button> -->

          </div>
      </div>
  </div>
</div>


<div class="modal fade draggable-modal" id="draggable" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
       <div class="modal-content">
          <div class="modal-header">
             <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
             <h4 class="modal-title">Add Leave</h4>
          </div>
 
             <div class="modal-body" style="display: inline-table;">
 
                      <form id="submit_form" class="form-horizontal" [formGroup]="approveleaveform">
 
                         <div class="col-md-12">
                            <div class="row mb-0">
                               <label class="form-label col-md-2">Request For</label>
                               <div class="col-lg-3">
 
                                  <div class="md-radio-inline">
                                     <div class="md-radio">
                                       <input type="radio" id="forSelf" formControlName="requestLeave" value="Self" class="md-radiobtn">
                                       <label for="forSelf">
                                         <span></span>
                                         <span class="check"></span>
                                         <span class="box"></span>Self
                                       </label>
                                     </div>
                                     <div class="md-radio">
                                       <input type="radio" id="forOthers" formControlName="requestLeave" value="Others" class="md-radiobtn">
                                       <label for="forOthers">
                                         <span></span>
                                         <span class="check"></span>
                                         <span class="box"></span>Others
                                       </label>
                                     </div>
                                   </div>
 
                               </div>
 
                               
 
                               <div class="col-lg-5">
                                  <input type="text" *ngIf="isForOthersSelected()" formControlName="selectEmployee" class="form-control" placeholder="Select Employee Name or Employee Code">
 
                               </div>
                            </div>
 
                         </div>
 
                         <div class="col-md-12">
                            <div class="row">
                               <div class="col-lg-5" *ngIf="approveleaveform.get('requestLeave').value === 'Self'">
                                  <label class="control-label mb-6">Leave Type<span class="required"> * </span></label>
                                  <select class="form-control" id="Leave_type_id" formControlName="Leave_type_id">
                                        <option value="">Select Leave Type</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
 
                                           <!--
                                              (change)="getEmpLeaveBalance($event.target.value)"
                                              *ngFor="let item of leavetypelist;" value={{item.id}}
                                              {{item.name}}
                                           -->
                                  </select>
                               </div>
 
                               <div class="col-lg-5" *ngIf="approveleaveform.get('requestLeave').value === 'Others'">
                                  <label class="control-label mb-6">Leave Type<span class="required"> * </span></label>
                                  <select class="form-control" id="Leave_type_id" formControlName="Leave_type_id">
                                        <option>Select Leave Type</option>
                                        <option>Sick Leave</option>
                                        <option>Maternity</option>
                                        <option>Shared Parental Leave</option>
                                        <option>Paternity</option>
                                        <option>Parental</option>
                                        <option>Compassionate Leave</option>
                                        <option>Parental Bereavement Leave</option>
                                        <option>Authorised Unpaid Leave</option>
                                        <option>Unauthorised Unpaid Leave</option>
                                        <option>Other authorized paid leave</option>
                                        <option>Study Leave and Training</option>
                                        <option>Jury service</option>
                                        <option>Work From Home</option>
                                        <option>Carers Leave</option>
                                        <option>AWOL</option>
                                        <option>Unpaid Suspension</option>
                                        <option>Paid Suspension</option>
                                           <!--
                                              (change)="getEmpLeaveBalance($event.target.value)"
                                              *ngFor="let item of leavetypelist;" value={{item.id}}
                                              {{item.name}}
                                           -->
                                  </select>
                               </div>
 
                               <div class="col-lg-4" *ngIf="approveleaveform.get('requestLeave').value !== 'Self' && isSickLeaveSelected()">
                                  <label class="control-label mb-6">Sick Leave Type<span class="required"> * </span></label>
                                  <select  class="form-control" formControlName="sickLeaveType">
                                     <option>Select Sick Leave Type</option>
                                     <option>Anxiety/ Depression/ Other Psychiatric Illnesses</option>
                                     <option>Mental Health - Work related</option>
                                     <option>Asthma</option>
                                     <option>Back Problems</option>
                                     <option>Benign and Malignant Tumours, Cancers</option>
                                     <option>Blood Disorders</option>
                                     <option>Burns, Poisoning, Frostbite, Hypothermia</option>
                                     <option>Chest and Respiratory Problems</option>
                                     <option>Cold, Cough, Flu - Influenza</option>
                                     <option>Dental and Oral Problems</option>
                                     <option>Ears, Nose and Throat</option>
                                     <option>Endocrine/Glandular Problems</option>
                                     <option>Eye Problems</option>
                                     <option>Gastro-intestinal Problems</option>
                                     <option>Genitourinary and Gynaecological Disorders</option>
                                     <option>Headache/Migraine</option>
                                     <option>Heart, Cardiac and Circulatory Problems</option>
                                     <option>Infections, Anaemia, Endocrine Disorders</option>
                                     <option>Infectious Diseases</option>
                                     <option>Injury, Fracture</option>
                                     <option>Musculo-skeletal</option>
                                     <option>Nervous System Disorders</option>
                                     <option>Other Known Causes - not elsewhere classified</option>
                                     <option>Other Musculoskeletal Problems</option>
                                     <option>Pregnancy Related Disorders</option>
                                     <option>Skin Disorders</option>
                                     <option>Stress</option>
                                     <option>Substance Abuse</option>
                                     <option>Surgery Related</option>
                                   </select>
                               </div>
 
                               <div class="col-lg-3">
                                  <label>&nbsp;</label>
                                  <h4 class="text-center">Available Balance: <span style="color: #52BE80; "><b>23</b></span></h4>
                               </div>
                            </div>
                            
                         </div>
 
                         <div class="col-md-12">
                            <div class="row mt-0">
 
                               
                               <div class="col-md-5">
                                  <label class="control-label mb-6">Leave Start From<span class="required"> * </span></label>
                                  <div id="start_Date_open" data-wrap="true">
                                     <div class="input-group flatpickr" id="startdateflatpickropen" data-wrap="true"
                                        (change)="getSelectedLeaveDate()">
                                        <input type="text" class="form-control" id="" data-input
                                           formControlName="Start_date" />
                                        <span class="input-group-btn" data-toggle>
                                           <button class="btn default" type="button">
                                              <i class="fa fa-calendar"></i>
                                           </button>
                                        </span>
                                     </div>
                                  </div>
                               </div>
 
                               <div class="col-lg-5">
                                  <label class="control-label mb-6">Leave End On<span class="required"> * </span></label>
                                  <div id="end_Date_open" data-wrap="true">
                                     <div class="input-group flatpickr" id="enddateflatpickropen" data-wrap="true"
                                        (change)="getSelectedLeaveDate()">
                                        <input type="text" class="form-control" id="" data-input
                                           formControlName="End_date" />
                                        <span class="input-group-btn" data-toggle>
                                           <button class="btn default" type="button">
                                              <i class="fa fa-calendar"></i>
                                           </button>
                                        </span>
                                     </div>
                                  </div>
                               </div>
 
                               <div class="col-lg-5">
                                  <label class="control-label mb-6">&nbsp;</label>
                                  <select id="Start_type" class="form-control" formControlName="Start_type">
                                     <option value="Fullday">Full Day</option>
                                     <option value="Firsthalf">First Half</option>
                                     <option value="Secondhalf">Second Half</option>
                                  </select>
                               </div>
 
                               <div class="col-lg-5">
                                  <label class="control-label mb-6">&nbsp;</label>
                                  <select id="End_type" class="form-control" formControlName="End_type" >
                                   <option value="Fullday">Full Day</option>
                                  <option value="Firsthalf">First Half</option>
                                  <option value="Secondhalf">Second Half</option>
                               </select>
                               </div>
 
                            </div>
                         </div>
 
                         <div class="col-md-12 mb-15" >
                            <h4>Total No. of Days Taken: <span style="color: #3598dc"><b>5</b></span></h4>
                         </div>
 
                          <!--
                            *ngIf="isVisible"
                            {{empLeaveBalance}}
                         -->
 
                            <div class="col-md-12">
                               <label class="control-label mb-6">Reason:</label>
                               <textarea class="form-control" formControlName="Comment" id="Comment"></textarea>
                            </div>
 
 
 
                         <!-- <div class="form-group" *ngIf="dayscount > 15">
                            <label class="control-label col-md-2">&nbsp;
                            </label>
                            <div class="col-md-8">
                               <div class="alert alert-danger">
                                  <strong>Warning!</strong> Your can't take leave more than 15 days.
                               </div>
                            </div>
                         </div> -->
 
                      </form>
 
             </div>
 
             <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" *ngIf="!isedit"  (click)="Upsert()"  class="btn blue">Save</button>
                <button type="button"   *ngIf="isedit"  (click)="save()"  class="btn blue">Upadate</button>
                <!--[disabled]="dayscount > 15"-->
             </div>
 
       </div>
    </div>
 </div>

<div class="modal fade draggable-modal" id="rejectLeaveModal" tabindex="-1" role="basic" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="margin:5% auto !important">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
              <h4 class="modal-title">Advanced Search</h4>
          </div>
          <div class="modal-body">
              <app-candidateAdvancedSearch></app-candidateAdvancedSearch>
          </div>
          <div class="modal-footer">
              <button type="button"  class="btn dark btn-outline" title="Clear All">Clear</button>
              <!-- <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button> -->
              <button type="button"  class="btn blue">Search</button>
          </div>
      </div>
  </div>
</div>

<div class="modal fade draggable-modal" id="editLeaveModal" tabindex="-1" role="basic" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="margin:5% auto !important">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
              <h4 class="modal-title">Advanced Search</h4>
          </div>
          <div class="modal-body">
              <app-candidateAdvancedSearch></app-candidateAdvancedSearch>
          </div>
          <div class="modal-footer">
              <button type="button"  class="btn dark btn-outline" title="Clear All">Clear</button>
              <!-- <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button> -->
              <button type="button"  class="btn blue">Search</button>
          </div>
      </div>
  </div>
</div>

<div class="modal fade draggable-modal" id="draggableForLeaveApproval" role="basic" aria-hidden="true"
    style="z-index: 0;">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important;">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Leave Days</h4>
            </div>
            <div class="modal-body">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover" id="tblDepartment"
                        style="position: relative;" role="grid">
                        <thead class="flip-content">
                            <tr>
                                <th>Leave Date</th>
                                <th>Name</th>
                                <th>Day</th>
                                <th>Day Type</th>
                                <th>Leave Type</th>
                                <th>Status</th>
                                <th>Note</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item_day of empLeaveList">
                                <td>{{item_day.leave_Date | date:'dd-MM-yyyy'}}</td>
                                <td>{{item_day.employee_Name}}</td>
                                <td>{{item_day.leave_Date | date:'EEEE'}}</td>
                                <td *ngIf="item_day.leave_Day_Type == 'Fullday'">
                                    Full Day</td>
                                <td *ngIf="item_day.leave_Day_Type == 'Firsthalf'">
                                    First Half</td>
                                <td *ngIf="item_day.leave_Day_Type == 'Secondhalf'">
                                    Second Half</td>
                                <td *ngIf="(item_day.leave_Day_Type == 'Sandwich')">Weekend
                                </td>
                                <td *ngIf="(item_day.leave_Day_Type == 'HolidaySandwich')">Holiday
                                </td>
                                <td>{{item_day.leave_Type}} ({{item_day.leave_Code}})</td>
                                <td *ngIf="(item_day.leave_Day_Type == 'Sandwich')"><span class="badge badge-pill"
                                        style="background-color: #99A3A4 !important;font-size: 14px!important;height: 25px!important;padding: 5px 13px!important;">Weekend</span>
                                </td>
                                <td *ngIf="(item_day.leave_Day_Type == 'HolidaySandwich')"><span
                                        class="badge badge-pill"
                                        style="background-color: #99A3A4 !important;font-size: 14px!important;height: 25px!important;padding: 5px 13px!important;">Holiday</span>
                                </td>
                                <td
                                    *ngIf="(item_day.leave_Day_Type != 'Sandwich') && (item_day.leave_Day_Type != 'HolidaySandwich')">
                                    <span class="badge badge-pill" *ngIf="(item_day.status == 'Pending')"
                                        style="background-color: #F5B041 !important;font-size: 14px!important;height: 25px!important;padding: 5px 13px!important;">{{item_day.status}}</span>
                                    <span class="badge badge-pill" *ngIf="(item_day.status == 'Approved')"
                                        style="background-color: #52BE80 !important;font-size: 14px!important;height: 25px!important;padding: 5px 13px!important;">{{item_day.status}}</span>
                                    <span class="badge badge-pill" *ngIf="(item_day.status == 'Rejected')"
                                        style="background-color: #E74C3C !important;font-size: 14px!important;height: 25px!important;padding: 5px 13px!important;">{{item_day.status}}</span>
                                    <span class="badge badge-pill" *ngIf="(item_day.status == 'Cancelled')"
                                        style="background-color: #5DADE2 !important;font-size: 14px!important;height: 25px!important;padding: 5px 13px!important;">{{item_day.status}}</span>
                                    <span class="badge badge-pill" *ngIf="(item_day.status == 'CancellationRequest')"
                                        style="background-color: #800080 !important;font-size: 14px!important;height: 25px!important;padding: 5px 13px!important;">{{item_day.status}}</span>
                                </td>
                                <td>{{item_day.action_Notes}}</td>
                                <td class="text-center">
                                    <a (click)="ApproveEmpSingleLeave(item_day.id,item_day.company_id,'Approved')"
                                        *ngIf="(item_day.leave_Day_Type != 'Sandwich') && (item_day.leave_Day_Type != 'HolidaySandwich') && (item_day.status == 'Pending')"><i
                                            class="fa fa-check iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Approve"></i>&nbsp;</a>
                                    <a (click)="CancelSingleLeaves(item_day.id,item_day.company_id,'Rejected')"
                                        *ngIf="(item_day.leave_Day_Type != 'Sandwich') && (item_day.leave_Day_Type != 'HolidaySandwich') && (item_day.status == 'Pending')"><i
                                            class="fa fa-close red-color iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Reject"></i></a>

                                    <a (click)="ApproveEmpSingleLeave(item_day.id,item_day.company_id,'Cancelled')"
                                        *ngIf="(item_day.leave_Day_Type != 'Sandwich') && (item_day.leave_Day_Type != 'HolidaySandwich') && (item_day.status == 'CancellationRequest')"><i
                                            class="fa fa-check iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Approve"></i>&nbsp;</a>
                                    <a (click)="CancelSingleLeaves(item_day.id,item_day.company_id,'Approved')"
                                        *ngIf="(item_day.leave_Day_Type != 'Sandwich') && (item_day.leave_Day_Type != 'HolidaySandwich') && (item_day.status == 'CancellationRequest')"><i
                                            class="fa fa-close red-color iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Cancel"></i></a>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="8"></td>
                            </tr>
                            <tr>
                                <td colspan="1"><b>Note:</b></td>
                                <td colspan="7">{{this.leaveApplyComment}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <a *ngIf="pendingActions > 0"
                    (click)="ApproveLeaves(this.empleaverangid,'Approved',this.empleaverangcompanyid,0)"
                    class="btn blue">Approve All</a> &nbsp;
                <a *ngIf="pendingActions > 0"
                    (click)="CancelLeaves(this.empleaverangid,'Rejected',this.empleaverangcompanyid,0)"
                    class="btn red">Reject All</a>

                <a *ngIf="(this.isApprovedLeaveCancel == 1) && (this.isLeave_CancelAll == 1)"
                    (click)="ApproveLeaves(this.empleaverangid,'Cancelled',this.empleaverangcompanyid,0)"
                    class="btn blue">Approve All</a> &nbsp;
                <a *ngIf="(this.isApprovedLeaveCancel == 1) && (this.isLeave_CancelAll == 1)"
                    (click)="CancelLeaves(this.empleaverangid,'Approved',this.empleaverangcompanyid,0)"
                    class="btn red">Reject All</a>

            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>

<style>
    td.details-control {
        background: url('assets/pages/img/SD7Dz.png') no-repeat center center;
        cursor: pointer;
    }

    tr.shown td.details-control {
        background: url('assets/pages/img/d4ICC.png') no-repeat center center;
    }

    td.fc-daygrid-day.fc-day.fc-day-sat.fc-day-future {
        background-color: #ddd;
    }

    td.fc-daygrid-day.fc-day.fc-day-sun.fc-day-future {
        background-color: #ddd;
    }

    div#swal2-validation-message {
        font-size: 14px;
    }
</style>
