import { Component, OnInit, ViewChild } from '@angular/core';
import { CompanyBasicInfoComponent } from "./company-basic-info.component";
import { CompanySettingComponent } from "./company-setting.component";
import { CompanyContactComponent } from "./company-contact.component";
import { CompanyEmailConfigComponent } from "./companyemailconfig/companyemailconfig.component";
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { CommonService } from 'src/app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEventPattern } from 'rxjs';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
   selector: 'app-company-add',
   templateUrl: './company-add.component.html',

})
export class CompanyAddComponent implements OnInit {
   @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
   @ViewChild(CompanyBasicInfoComponent, { static: false }) companyW1: CompanyBasicInfoComponent;
   @ViewChild(CompanySettingComponent, { static: false }) companyW2: CompanySettingComponent;
   @ViewChild(CompanyContactComponent, { static: false }) companyW3: CompanyContactComponent;
   @ViewChild(CompanyEmailConfigComponent, { static: false }) companyW4: CompanyEmailConfigComponent;
   backbutton: string = "hidden";
   editCompany: number = 0;
   pbWidth: number = 25;
   progressBarWidth: string = this.pbWidth + '%';
   activeWizard: number = 1;
   title: string = "Add";
   btnName: string = "Continue";
   w1: string = "active";
   w2: string = "";
   w3: string = "";
   w4: string = "";
   t1: string = "tab-pane active";
   t2: string = "tab-pane";
   t3: string = "tab-pane";
   t4: string = "tab-pane";


   constructor(
      private toastr: ToastrService,
      private router: Router,
      private commonApiService: CommonApiService,
      private route: ActivatedRoute,
      private commonservice: CommonService
   ) {

   }

   ngOnInit() {

      // $(document).ready(function () {
      //    $("#currency,#language").select2({ width: "auto" });
      // })
      this.route.queryParams.subscribe(params => {
         if (params.cId == 0) {
            localStorage.removeItem("companyId");
         }
         else if (params.cId != undefined) {

            localStorage.setItem("companyId", params.cId);
            this.editCompany = params.cId;
            this.title = "Edit"
            this.commonApiService.getById(Commonvariable.CompanyDetail, params.cId, "ViewSummary").subscribe((response) => {
               if (response != null) {
                  var editData = response["data"][0];
                  var formData1 = this.companyW1.companyBasicInfoform;
                  formData1.controls.company_Id.setValue(editData.company_Id)
                  // formData1.controls.company_Code.setValue(editData.company_Code)
                  formData1.controls.company_Name.setValue(editData.company_Name)
                  formData1.controls.registration_No.setValue(editData.registration_No)
                  formData1.controls.updatedBy.setValue(Commonvariable.User_Id)
                  this.companyW2.companyform.controls.currency_Id.setValue(editData.currency_Id)
                  this.companyW2.companyform.controls.language.setValue(editData.language)

                  let date = response["data"][0].registration_Date != null ? response["data"][0].registration_Date.split("T")[0].split("-") : "";
                  if (date != "") {
                     $("#registration_Date").val(date[2] + "-" + date[1] + "-" + date[0]);
                  }
                  $("#company_Code").prop("disabled", "disabled");

                  //console.log(editData.company_contact)
                  var CContactData = editData.company_contact;
                  this.companyW3.isedit = true;
                  if (CContactData.length > 0) {
                     this.companyW3.removeItem(0);
                     for (let index = 0; index < CContactData.length; index++) {
                        this.companyW3.addItem(CContactData[index]);

                       //console.log(this.companyW3.companyContactform.value)
                     }
                  }
                  this.commonApiService.getimage(Commonvariable.Company, this.editCompany, "DownloadLogo").subscribe((response) => {

                     var fileType = response.type;
                     var validImageTypes = ["image/gif", "image/jpeg", "image/png", "image/jpg", "image/bmp"];
                     if ($.inArray(fileType, validImageTypes) < 0) {
                        this.companyW2.logoInnerHTML = "";
                        this.companyW2.logoInnerHTML = "<img  src='assets/layouts/layout4/img/selectPic.png' alt = ''/>";
                     } else {

                        if (response != null) {
                           var reader = new FileReader();
                           reader.readAsDataURL(response);
                           reader.addEventListener("load", () => {
                              this.companyW2.logoInnerHTML = "";
                              this.companyW2.logoInnerHTML = "<img  src=" + reader.result + " alt = ''/>";
                              this.companyW2.logoClass = "fileinput fileinput-exists";
                           }, false);
                        }
                     }
                     this.companyW4.edit(editData.email_config, editData.comp_emp_config,params.cId)
                  })
               }
            })
         }
      });

      setTimeout(() => {
         $('[data-toggle="tooltip"]').tooltip();
      }, 500);

   }

   companyUpsert() {

      if (this.activeWizard == 4 && this.companyW4.validation()) {

         this.companyW1.companyBasicInfoform.controls.currency_Id.setValue(parseInt(this.companyW2.companyform.controls.currency_Id.value));
         this.companyW1.companyBasicInfoform.controls.language.setValue(this.companyW2.companyform.controls.language.value);
         this.companyW1.companyBasicInfoform.controls.company_contact.setValue(this.companyW3.companyContactform.controls.company_Contact.value);

         if (this.companyW4.companyemailconfigform.controls.email_Host.value == null || this.companyW4.companyemailconfigform.controls.email_Host.value == "") {
            this.companyW1.companyBasicInfoform.controls.email_config.setValue(null);
         }
         else {
            this.companyW1.companyBasicInfoform.controls.email_config.setValue(this.companyW4.companyemailconfigform.value);
         }
         if (this.companyW4.employeeCodeConfiguration.controls.total_Digit.value == null || this.companyW4.employeeCodeConfiguration.controls.total_Digit.value == "") {
            this.companyW1.companyBasicInfoform.controls.comp_emp_config.setValue(null);
         }
         else {
            this.companyW1.companyBasicInfoform.controls.comp_emp_config.setValue(this.companyW4.employeeCodeConfiguration.value);
         }



         //this.companyW1.companyBasicInfoform.controls.company_contact.setValue(this.companyW3.companyContactform.controls.company_Contact.value);

         var data = this.companyW1.companyUpsert();

         this.commonApiService.Upsert(Commonvariable.CompanyDetail, data, data.company_Id).subscribe((response) => {
            if (response != null) {
               if (response["message"] == Commonvariable.DuplicationMsg) {
                  this.toastr.warning('Record is already exists !', 'Company');
               }
               else {

                  if (this.companyW2.companyLogo != "") {
                     var companyLogo = this.companyW2.companyLogoChange ? this.companyW2.companyLogo : null;
                     const formData = new FormData();
                     formData.append('intCompanyId', response["data"]["company_Id"]);
                     formData.append('file', companyLogo);

                     this.commonApiService.UploadLogo(Commonvariable.Company, "UploadLogo", formData).subscribe((response) => {
                        this.toastr.success(data.company_Id > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Company');
                        this.router.navigateByUrl('companylist')
                     });
                  }
                  else {
                     this.toastr.success(data.company_Id > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Company');
                     this.router.navigateByUrl('companylist')

                  }


                  // this.companyW3.companyContactUpsert(data.company_Id > 0 ? data.company_Id : response["data"]["company_Id"], this.companyW1.companyBasicInfoform.controls.company_Code.value, companyLogo,this.companyW2.companyLogoChange);
               }
            }
         })
      }
      else {
         if (this["companyW" + this.activeWizard].validation()) {
            this.wizardSetting("next");
         }
      }
   }



   moveWizard(id) {
      var movePermission = true;

      if (this.activeWizard < id) {
         for (let index = 1; index < id; index++) {
            if (!this["companyW" + index].validation()) {
               movePermission = false;
               break;
            }
         }
      }
      else {
         if (!this["companyW" + this.activeWizard].validation()) {
            movePermission = false;
         }
      }

      if (movePermission) {
         if (this.activeWizard < id) {
            this.activeWizard = id - 1;
            this.pbWidth = 25 * this.activeWizard
            this.wizardSetting("next")
         }
         else {
            this.activeWizard = id + 1;
            this.pbWidth = 25 * this.activeWizard;
            this.wizardSetting("back")
         }
      }
   }


   wizardSetting(event) {
      if (event == "next") {
         this.activeWizard++;
         this.pbWidth = this.pbWidth + 25;
      }
      else {
         this.activeWizard--;
         this.pbWidth = this.pbWidth - 25;
      }
      for (let index = 1; index <= 4; index++) {
         this["w" + index] = index == this.activeWizard ? "active" : index < this.activeWizard ? "done" : "";
         this["t" + index] = index == this.activeWizard ? "tab-pane active" : "tab-pane";
      }
      this.progressBarWidth = this.pbWidth + "%"
      this.backbutton = this.activeWizard > 1 ? "visible" : "hidden"
      this.btnName = this.activeWizard == 4 ? "Save" : "Continue"
   }


   history(editedId) {
      this.historyView.getHistory("company", editedId)
   }

}


