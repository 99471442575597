import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html'
})
export class FeedbackComponent implements OnInit {

  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  feedbackform: FormGroup;
  feedbacklist: any;
  filterList: any;
  companylist: any;
  languageList: Array<Select2OptionData>;
  listcall: boolean = true;
  title: string;
  p: number = 1;
  totalRecord: 0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.title = "Add";
    this.feedbackform = this.fb.group({
      id: ['0'],      
      name: ['', Validators.required],      
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });
    if (this.listcall) {
      this.getFeedbackList();
    }
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }

  getFeedbackList(PageIndex = 1) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.feedbacklist = []
    this.commonApiService.getListWithFilter(Commonvariable.Feedback, PageIndex, $("#filterList").val()).subscribe((result) => {      
      if (result != null && result["data"] != "") {        
        this.totalRecord = result["data"]["recordCount"]
        this.feedbacklist = result["data"]["list"];
        this.filterList = result["data"]["list"];

        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })
  }

  

  setFormData(event) {
    this.feedbackform.controls.language.setValue(event)
  }


  FeedbackUpsert() {
    var Form = this.feedbackform;

    if (this.validation()) {
      Form.get("isActive").setValue(Form.get("isActive").value ? 1 : 0);
      Form.get("name").setValue(Form.get("name").value);      
      this.commonApiService.Upsert(Commonvariable.Feedback, Form.value, Form.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Ethnicity');
          }
          else {
            $("#draggable").modal('hide');
            this.getFeedbackList();
            this.toastr.success(Form.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Ethnicity');
          }
        }
      })
    }

  }

  FeedbackDelete(Id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Feedback, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getFeedbackList();
          this.toastr.success(response["message"], 'Feedback');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Feedback, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.getFeedbackList();
            this.toastr.success(response["message"], 'Feedback');            
          }

        })
      }

    });
  }

  openModel(Id) {
    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Feedback, Id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var Form = this.feedbackform;
          this.commonService.setEditData(Form, editData);          
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Feedback'); })
    }
    else {
      $("#name").prop("disabled", false);
      this.listcall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });


  }

  validation() {
    var Form = this.feedbackform;
    if (!Form.valid) {
      for (const key of Object.keys(Form.controls)) {
        if (Form.controls[key].invalid) {
          let fieldName = "";          
          if (key == "name") {
            fieldName = "Feedback"
          }         
          this.toastr.info(fieldName + " is required !", "Feedback");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {      
      if ($("#name").val().length < 2) {
        this.toastr.info("Feedback must be at least 2 characters long !", "Feedback");
        $("#name").focus();
        return false;
      }      
    }

    return true;
  }

  history()
  {
    this.historyView.getHistory("feedback",this.feedbackform.value.id)
  }
  

}
