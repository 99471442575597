<form [formGroup]="empBasicInfoform">
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Employee Photo
            </label>
            <div class="col-md-10">
                <div [class]="logoClass" data-provides="fileinput">
                    <div class="fileinput-new thumbnail" style="width: 200px; height: 150px;">
                        <img src="assets/layouts/layout4/img/selectPic.png" alt="" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"
                        style="max-width: 200px; max-height: 150px;" [innerHTML]="logoInnerHTML">
                    </div>
                    <div>
                        <span class="btn default btn-file">
                            <span class="fileinput-new"> Select image </span>
                            <span class="fileinput-exists"> Change </span>
                            <input type="file" name="..." accept="image/x-png,image/jpg,image/jpeg"
                                (change)="filechange($event.target.files)"> </span>
                        <a href="javascript:;" class="btn red fileinput-exists" id="remove"
                            (click)="empLogo = null;empLogoChange=true" data-dismiss="fileinput"> Remove </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group form-md-line-input">
            <label class="col-md-2 control-label" for="form_control_1">Title
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <div class="md-radio-inline">
                    <div class="md-radio">
                        <input type="radio" id="radio53" name="Title" formControlName="Title" value="Mr."
                            class="md-radiobtn">
                        <label for="radio53">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Mr. </label>
                    </div>
                    <div class="md-radio has-error">
                        <input type="radio" id="radio54" name="Title" formControlName="Title" value="Mrs"
                            class="md-radiobtn" checked>
                        <label for="radio54">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Mrs. </label>
                    </div>
                    <div class="md-radio has-warning">
                        <input type="radio" id="radio55" name="Title" formControlName="Title" value="Miss"
                            class="md-radiobtn">
                        <label for="radio55">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Miss. </label>
                    </div>
                    <div class="md-radio has-info">
                        <input type="radio" id="radio56" name="Title" formControlName="Title" value="Ms."
                            class="md-radiobtn">
                        <label for="radio56">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Ms. </label>
                    </div>
                    <div class="md-radio has-info">
                        <input type="radio" id="radio57" name="Title" formControlName="Title" value="Dr."
                            class="md-radiobtn">
                        <label for="radio57">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Dr. </label>
                    </div>
                    <div class="md-radio has-warning">
                        <input type="radio" id="radio58" name="Title" formControlName="Title" value="Other"
                            class="md-radiobtn">
                        <label for="radio58">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Other </label>
                    </div>
                </div>
            </div>
            <!-- <label class="col-md-2 control-label" for="form_control_1">Gender
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <div class="md-radio-inline">
                    <div class="md-radio">
                        <input type="radio" id="radio57" name="Gender_Id" formControlName="Gender_Id" value="1"
                            class="md-radiobtn">
                        <label for="radio57">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Male </label>
                    </div>
                    <div class="md-radio has-error">
                        <input type="radio" id="radio58" name="Gender_Id" formControlName="Gender_Id" value="2"
                            class="md-radiobtn">
                        <label for="radio58">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Female </label>
                    </div>
                    <div class="md-radio has-warning">
                        <input type="radio" id="radio60" name="Gender_Id" formControlName="Gender_Id" value="3"
                            class="md-radiobtn">
                        <label for="radio60">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Prefer Not To Say </label>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">First Name
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="FirstName" id="FirstName" autocomplete="off"
                    maxlength="50" />
            </div>
            <label class="control-label col-md-2">Middle Name
                <!-- <span class="required"> * </span> -->
            </label>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="MiddleName" id="MiddleName" autocomplete="off"
                    maxlength="50" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Last Name
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="LastName" id="LastName" autocomplete="off"
                    maxlength="50" />
            </div>
            <label class="control-label col-md-2">Mobile Number
            </label>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="PhoneNumber" id="PhoneNumber"
                    autocomplete="off" maxlength="20" />
            </div>
            <!-- <label class="control-label col-md-2">Blood Group</label>
            <div class="col-md-3">
                    <select id="BloodGroup" class="form-control" formControlName="BloodGroup">
                        <option value="null">Select Blood Group</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                    </select>

            </div> -->
            <!-- <label class="control-label col-md-2">Ethnicity</label>
            <div class="col-md-3">
                <ng-select2 [data]="ethinicityList" id="Ethnicity_Id" formControlName="Ethnicity_Id"
                    [placeholder]="'--- Select ---'">
                </ng-select2>
            </div> -->

        </div>
    </div>
    <div class="row">
        <div class="form-group">



            <!-- <label class="control-label col-md-2">Language</label>
            <div class="col-md-3">
                <ng-select2 [data]="languagesList" id="Languages_Id" formControlName="Languages_Id"
                    [options]="{multiple: true,tags: false}" [value]='this.empBasicInfoform.controls.Languages_Id.value'
                    [placeholder]="'--- Select ---'">
                </ng-select2>
            </div> -->

            <!-- <select class="form-control" formControlName="Languages_Id" id="Languages_Id">
                    <option value="">Select Languages</option>
                    <option *ngFor="let item of languagesList;" value={{item.id}}>
                        {{item.languages}}</option>
                </select> -->


            <!-- <label class="control-label col-md-2">Nationality
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
              

                 [value]='this.candidateinfoform.controls.lstJob_Category.value' 
                <ng-select2 [data]="nationalityList" id="Nationality_Id" [options]="{multiple: true,tags: false}"
                    [value]='this.empBasicInfoform.controls.lstNationality_Id.value'
                    (valueChanged)="setFormData('lstNationality_Id',$event)" [placeholder]="'--- Select ---'">
                </ng-select2>
            </div> -->

            <label class="control-label col-md-2">Email
            </label>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="Email" id="Email" autocomplete="off"
                    maxlength="200" />
            </div>


        </div>
    </div>




    <div class="row">
        <div class="form-group">

            <label class="control-label col-md-2">Date Of Birth
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <div class="input-group DOB" id="flatpickropen" data-wrap="true">
                    <input type="text" class="form-control datePicker DOB readonlyClass" id="DOB" autocomplete="off"
                        data-input />
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default form-control datePicker" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
            <label class="control-label col-md-2">Telephone Number
            </label>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="Telephone" id="Telephone" autocomplete="off"
                    maxlength="20" />
            </div>
            <!-- <label class="control-label col-md-2">Marital Status</label>
            <div class="col-md-3">
                <ng-select2 [data]="MaritalList" id="MaritalStatus_Id" formControlName="MaritalStatus_Id"
                    [placeholder]="'--- Select ---'">
                </ng-select2>
            </div> -->


            <!-- <label class="control-label col-md-2">Contact No
            <span class="required"> * </span>
        </label>
        <div class="col-md-1_1">
            <input type="text" class="form-control" placeholder="Type Mobile No" />
        </div>
        <div class="col-md-1_1">
            <input type="text" class="form-control" placeholder="Type Alternate No" />
        </div> -->
        </div>
    </div>
    <!-- <div class="row">
        <div class="form-group form-md-line-input">
            <label class="col-md-2 control-label" for="form_control_1">Disability
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <div class="md-radio-inline">
                    <div class="md-radio">
                        <input type="radio" id="radio57Disability" name="Disability" formControlName="Disability"
                            value="Yes" class="md-radiobtn">
                        <label for="radio57Disability">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Yes </label>
                    </div>
                    <div class="md-radio has-error">
                        <input type="radio" id="radio58Disability" name="Disability" formControlName="Disability"
                            value="No" class="md-radiobtn">
                        <label for="radio58Disability">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> No </label>
                    </div>
                    <div class="md-radio has-warning">
                        <input type="radio" id="radio59Disability" name="Disability" formControlName="Disability"
                            value="Prefer Not To Say" class="md-radiobtn">
                        <label for="radio59Disability">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Prefer Not To Say </label>
                    </div>
                </div>
            </div>

            <label class="control-label col-md-2">
                Sexual Orientation
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <select id="Sexual_Orientation" class="form-control " formControlName="Sexual_Orientation">
                    <option value="">--- Select ---</option>
                    <option value="Prefer Not To Say">Prefer Not To Say</option>
                    <option value="Asexual">Asexual</option>
                    <option value="Bisexual">Bisexual</option>
                    <option value="Gay">Gay</option>
                    <option value="Heterosexual">Heterosexual</option>
                    <option value="Lesbian">Lesbian</option>
                </select>
            </div>
        </div>
    </div> -->
</form>