<form [formGroup]="empNotificatonform">
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label">User Type<span class="required">*</span></label>
                    <select id="roleuser_Type" class="form-control" formControlName="user_Type">
                        <option value="">Select User Role</option>
                        <option *ngFor="let item of roleList;" value={{item.id}}>
                            {{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label">UserName<span class="required">*</span></label>
                    <input type="text" class="form-control" id="login_Id" formControlName="login_Id"
                        autocomplete="off" />
                </div>
            </div>
            
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label">First Name</label>
                    <input type="text" class="form-control" formControlName="firstName" readonly />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label">Last Name</label>
                    <input type="text" class="form-control" formControlName="lastName" readonly />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label">Employee Code</label>
                </div>
                <input type="text" class="form-control" formControlName="emp_Code" readonly />
            </div>
        </div>

    </div>
</form>
<div class="modal-footer">
    <button type="button" (click)="upsert()" class="btn btn blue">Approve</button>
    <button type="button" class="btn btn-danger" (click)="reject()">Reject</button>
</div>  
