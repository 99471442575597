<!-- <div class="progress">
    <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="40" aria-valuemin="0"
        aria-valuemax="100" style="width:40%">
        40%
    </div>
</div> -->
<div id="accordion1" class="panel-group">
    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_5"> 1. Personal Details </a>

            </h4>
        </div>
        <div id="accordion1_5" class="panel-collapse collapse in">
            <div class="panel-body">


                <div class="row" *ngIf="employeeBasicInfo != null">
                    <div class="form-group">
                        <label class="control-label col-md-2">Title</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.title}}" readonly />
                        </div>

                        <!-- <label class="control-label col-md-2">Gender</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.gender_Name}}" readonly />
                        </div> -->

                        <label class="control-label col-md-2">First Name</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.firstName}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Middle Name</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.middleName}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Last Name</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.lastName}}" readonly />
                        </div>

                        <label class="control-label col-md-2"> Email</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.email}}" readonly />
                        </div>



                        <label class="control-label col-md-2">Date Of Birth</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.dob | date:'dd/MM/yyyy'}}" readonly />
                        </div>

                        <!-- <label class="control-label col-md-2">Age</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.age}}" readonly />
                        </div> -->

                        <!-- <label class="control-label col-md-2">Ethnicity</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.ethnicity_Name}}" readonly />
                        </div> -->

                        <!-- <label class="control-label col-md-2">Language</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.languages_Name}}" readonly />
                        </div> -->
                        <!-- <ng-select2 [data]="this.employeeBasicInfo.languages_Id"
                                [options]="{multiple: true,tags: false}" [value]='this.employeeBasicInfo.languages_Id' [placeholder]=""
                                [disabled]="true">
                            </ng-select2> -->

                        <label class="control-label col-md-2">Nationality</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.nationality_Name}}" readonly />
                        </div>

                        <!-- <label class="control-label col-md-2">Marital Status</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.marital_Status}}" readonly />
                        </div>
                        <label class="control-label col-md-2">Disability</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.disability}}" readonly />
                        </div>
                        <label class="control-label col-md-2">Sexual Orientation</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.sexual_Orientation}}" readonly />
                        </div> -->
                        <label class="control-label col-md-2">Mobile Number</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.phoneNumber}}" readonly />
                        </div>

                        <label class="control-label col-md-2"> Telephone</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.telephone}}" readonly />
                        </div>

                        <label class="control-label col-md-2"> Email</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.email}}" readonly />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_1"> 2. Current Job Details </a>
            </h4>
        </div>
        <div id="accordion1_1" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="row" *ngIf="employeeSummary != null">
                    <div class="form-group">
                        <label class="control-label col-md-2">Company</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeSummary.company_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Site</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.site_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Department</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.dept_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Zone</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.zone_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Job Title</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.desig_Name}}" readonly />

                        </div>

                        <label class="control-label col-md-2">Job Description</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.jD_Name}}" readonly />
                                <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="download()"
                                title="Download Job Description"><i class="fa fa-download fa-1x"></i></a>
                        </div>

                        <label class="control-label col-md-2">Employee Number</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.emp_Code}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Role Start Date</label>
                        <!--  prev name change Joining Date -->
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.joiningDate | date:'dd/MM/yyyy'}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Shift Start</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" id="shift_Start"
                                value="{{this.employeeSummary.shift_Start}}" readonly>
                        </div>
                        <label class="control-label col-md-2">Shift End</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" id="shift_End"
                                value="{{this.employeeSummary.shift_End}}" readonly>
                        </div>
                        <label class="control-label col-md-2">Shift Variable</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                *ngIf="this.employeeSummary.shift_Variable != 1" value="No" readonly />
                            <input type="text" class="form-control readonlyClass"
                                *ngIf="this.employeeSummary.shift_Variable == 1" value="Yes" readonly />
                        </div>
                        <!-- <label class="control-label col-md-2">Shift Time</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.shift_Name}} {{this.employeeSummary.shift_Time}}"
                                readonly />
                        </div> -->

                        <!-- 
                        <label class="control-label col-md-2">NI Number</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.niNo}}" readonly />
                        </div> -->


                        <label class="control-label col-md-2">Work Email</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.work_Email}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Work Phone</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.work_Phone}}" readonly />
                        </div>


                        <label class="control-label col-md-2">Reporting</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeSummary.reporting_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2"> Line Manager</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeSummary.line_Manager_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Work Extension</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeSummary.work_Extension}}" readonly />
                        </div>


                        <label class="control-label col-md-2">RAF</label>
                        <div class="col-md-4 form-group" >
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeSummary.vacancy_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2" *ngIf="employeeSummary != null">Deputy</label>
                        <div class="col-md-4 form-group" *ngIf="employeeSummary != null">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeSummary.deputy_Name}}" readonly />
                        </div>


                        <label class="control-label col-md-2">RAF Approver</label>
                        <!-- prev name change RAF Approval -->
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                *ngIf="employeeSummary.raF_Approval != 1" value="No" readonly />

                            <input type="text" class="form-control readonlyClass"
                                *ngIf="employeeSummary.raF_Approval == 1" value="Yes" readonly />
                        </div>


                        <label class="control-label col-md-2">Tupe</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" *ngIf="employeeSummary.tupe != 1"
                                value="No" readonly />
                            <input type="text" class="form-control readonlyClass" *ngIf="employeeSummary.tupe == 1"
                                value="Yes" readonly />
                        </div>


                        <label class="control-label col-md-2">HOD</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" *ngIf="employeeSummary.isHOD != 1"
                                value="No" readonly />

                            <input type="text" class="form-control readonlyClass" *ngIf="employeeSummary.isHOD == 1"
                                value="Yes" readonly />
                        </div>
                        <!-- <label class="control-label col-md-2">Employee's length of Service</label>
                        <div class="col-md-2 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employee_length_service_year}}" readonly />
                        </div>
                        <div class="col-md-2 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employee_length_service_month}}" readonly />
                        </div> -->


                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_2"> 3. Career History </a>
            </h4>
        </div>
        <div id="accordion1_2" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center"> Contract Type</th>
                                <th class="text-center"> Job Title</th>
                                <th class="text-center"> Role Start</th>
                                <th class="text-center"> Role End</th>
                                <th class="text-center"> Zone</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeContract">
                            <tr>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.emp_Contract_Name}}">
                                        {{item.emp_Contract_Name}}
                                    </div>
                                </td>
                                <td>{{item.emp_Job_Title}}</td>
                                <td>{{item.emp_Role_Start | date :'dd/MM/yyyy'}}</td>
                                <td>{{item.emp_Role_End | date :'dd/MM/yyyy'}}</td>
                                <td>{{item.emp_Zone_Name}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_3"> 4. Probation </a>
            </h4>
        </div>
        <div id="accordion1_3" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Probation Period</th>
                                <th class="text-center">Probation End Date</th>
                                <th class="text-center" class="text-center">Next Review Date</th>
                                <th class="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeProbation">
                            <tr>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.probationPeriod}}">
                                        {{item.probationPeriod}}
                                    </div>
                                </td>
                                <td class="text-center">{{item.probationEndDate |date:'dd/MM/yyyy'}}</td>
                                <td class="text-center">{{item.nextReviewDate | date :'dd/MM/yyyy'}}</td>
                                <td>{{item.status}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_4"> 5. Salary </a>
            </h4>
        </div>
        <div id="accordion1_4" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Salary</th>
                                <th class="text-center">Salary Type</th>
                                <th class="text-center"> </th>
                                <!-- <th class="text-center">Apprisal From</th>
                                <th class="text-center">Apprisal To</th> -->
                            </tr>

                        </thead>
                        <tbody *ngFor="let item of employeeSalary">
                            <tr>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.salary}}">{{item.salary}}
                                    </div>
                                </td>
                                <!-- <td class="text-center">{{item.apprisalFrom | date :'dd/MM/yyyy'}}</td>
                                <td class="text-center">{{item.apprisalTo | date :'dd/MM/yyyy'}}</td> -->
                                <td class="text-center">{{item.salaryType}}</td>
                                <td class="text-center"></td>
                            </tr>
                            <tr>
                                <th class="text-center">Additional Payment</th>
                                <th class="text-center">Additional Amount</th>
                                <th class="text-center">Payment Type</th>
                                <!-- Add more columns if needed -->
                            </tr>
                            <!-- Loop to display emp_salary_additional data -->
                            <tr *ngFor="let additionalItem of item.emp_salary_additional">
                                <td class="text-center">{{ additionalItem.additionalPayment }}</td>
                                <td class="text-center">{{ additionalItem.additionalPaymentAmount }}</td>
                                <td class="text-center">{{ additionalItem.additionalPaymentType }}</td>
                                <!-- You can add more columns for other properties if needed -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>



    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_6"> 6. Address Details </a>
            </h4>
        </div>
        <div id="accordion1_6" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <!-- <th class="text-center">Address Type</th> -->
                                <th class="text-center">Address</th>
                                <th class="text-center">City</th>
                                <th class="text-center">County</th>
                                <th class="text-center">Country</th>
                                <th class="text-center">Post Code </th>
                                <th class="text-center">Default</th>
                                <th class="text-center">Active</th>

                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeAddress">
                            <tr>
                                <!-- <td>{{item.address_Type}}</td> -->
                                <td>{{item.address1}}, {{item.address2}}</td>
                                <td>{{item.city}}</td>
                                <td>{{item.state}}</td>
                                <td>{{item.country_Name}}</td>
                                <td>{{item.postCode}}</td>
                                <td>
                                    <div>
                                        <div class="circle greenColor" *ngIf="item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Default">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Indefault">
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="text-center">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive">
                                        </div>
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1" href="#accordion1_7"> 7. Personal Contact Details </a>
            </h4>
        </div>
        <div id="accordion1_7" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Contact Type</th>
                                <th class="text-center">Contact Details</th>
                                <th class="text-center" style="width: 2%;">Default</th>
                                <th class="text-center" style="width: 2%;">Active</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeContact">
                            <tr>
                                <td>{{item.contact_Type}}</td>
                                <td>{{item.contact_Value}}</td>
                                <td>
                                    <div>
                                        <div class="circle greenColor" *ngIf="item.isDefault" data-toggle="tooltip" data-placement="top" title="Default">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isDefault" data-toggle="tooltip" data-placement="top" title="Indefault">
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="text-center">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip" data-placement="top" title="Active">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip" data-placement="top" title="Inactive">
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div> -->

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_8"> 7. Emergency Contact </a>
            </h4>
        </div>
        <div id="accordion1_8" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Emergency Contact Name</th>
                                <th class="text-center">Emergency Contact Number</th>
                                <th class="text-center">Relationship</th>
                                <th class="text-center" style="width: 2%;">Default</th>
                                <th class="text-center" style="width: 2%;">Active</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeEmergency">
                            <tr>
                                <td>{{item.contactName}}</td>
                                <td>{{item.contactNo}}</td>
                                <td>{{item.relationship_Name}}</td>
                                <td>
                                    <div class="text-center">
                                        <div class="circle greenColor" *ngIf="item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Default">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Indefault">
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="text-center">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive">
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_9"> 8. Work Reference </a>
            </h4>
        </div>
        <div id="accordion1_9" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Reference Received</th>
                                <th class="text-center">Date Received</th>
                                <th class="text-center">Date Sent</th>
                                <th class="text-center">Attachment</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeReference">
                            <tr>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="Yes">{{item.required}}
                                    </div>
                                </td>
                                <td>{{item.dateOfRec | date :'dd/MM/yyyy'}}</td>
                                <td>{{item.dateSent | date :'dd/MM/yyyy'}}</td>
                                <td>{{item.file_Name}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_10"> 9. Payroll Details </a>
            </h4>
        </div>
        <div id="accordion1_10" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="row" *ngIf="employeeSummary != null">
                    <label class="control-label col-md-2">NI Number</label>
                    <div class="col-md-4 form-group">
                        <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.niNo}}"
                            readonly />
                    </div>

                    <label class="control-label col-md-2">Loan</label>
                    <div class="col-md-4 form-group">
                        <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.loan}}"
                            readonly />
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Bank Name</th>
                                <th class="text-center">Account Holder</th>
                                <!-- <th class="text-center">Account Type</th> -->
                                <th class="text-center">Account No.</th>
                                <th class="text-center">Sort Code</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeBank">
                            <tr>
                                <td>{{item.bank_Name}}</td>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.account_Holder}}">
                                        {{item.account_Holder}}
                                    </div>
                                </td>
                                <!-- <td>{{item.account_Type}}</td> -->
                                <td>{{item.account_No}}</td>
                                <td>{{item.bank_Code}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_11"> 10. Right To Work </a>
            </h4>
        </div>
        <div id="accordion1_11" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th style="width:20%;" class="text-center">Nationality</th>
                                <th class="text-center" style="width: 20%;">ECS Status</th>
                                <th class="text-center" style="width: 20%;">RTW Status type</th>
                                <th class="text-center" style="width: 20%;">Sponsored Employee</th>
                                
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of emp_righttoworkview">
                            <tr>
                                <!-- <td>{{item.ecsReceivedDate | date :'dd/MM/yyyy' }}</td> -->
                                <td>{{item.nationality_Name}}</td>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.ecsStatus}}">
                                        {{item.ecsStatus}}
                                    </div>
                                </td>
                                <td>{{item.rtW_VisaStatus_Name}}</td>
                                <!-- <td>{{item.ecsExpiryDate  | date :'dd/MM/yyyy'}}</td> -->
                                <td>{{item.stringisSponsoredEmployee}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>


    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_12"> 11. Additional Document </a>
            </h4>
        </div>
        <div id="accordion1_12" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Upload File Name</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of empDocuments">
                            <tr>
                                <td>
                                    {{item.file_Name}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>




    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_13"> 12. Leave </a>
            </h4>
        </div>
        <div id="accordion1_13" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Leave Type</th>
                                <th class="text-center">Total Leave</th>
                                <th class="text-center">Taken Leave</th>
                                <th class="text-center">Balance Leave</th>
                                <!-- <th class="text-center" style="width: 5%"> Actions </th> -->
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of empLeaveBalance">
                            <tr>

                                <td class="text-center">
                                    {{item.leave_Type}} ({{item.leave_Type_Code}})
                                </td>
                                <td class="text-center">
                                    {{item.total_leave}}
                                </td>
                                <td class="text-center">
                                    {{item.taken_leave}}
                                </td>
                                <td class="text-center">
                                    {{item.balance_leave}}
                                </td>
                                <!-- <td class="text-right">
                                    <a (click)="downloadDocument(item.emp_Doc_Id,item.emp_Doc_Path)"><i
                                            class="fa fa-download purple iconcss iconFontsize"
                                            data-toggle="tooltip" data-placement="top"
                                            title="Download Document"></i></a>&nbsp;
                                    <a data-toggle="modal" href="#emp-document"
                                        (click)="editempInfo('EmpDocument',item.emp_Doc_Id)"><i
                                            class="fa fa-edit purple iconcss iconFontsize"
                                            data-toggle="tooltip" data-placement="top"
                                            title="Edit Document"></i></a>&nbsp;
                                    <a (click)="Delete(12,item.emp_Doc_Id)">
                                        <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                            data-toggle="tooltip" data-placement="top"
                                            title="Delete Document"></i></a>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle popoverTitle accordion-toggle-styled" data-toggle="collapse"
                    data-parent="#accordion1" href="#accordion1_13">
                    13. Equality and Diversity Monitoring
                </a>
                <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-container="body"
                                        data-placement="top" data-html="true" id="equality">
                                        <span class="glyphicon glyphicon-info-sign"></span>
                                    </span>
                                    <div id="popover-content-equality" class="hide">
                                        The information requested in this form is required for us to monitor
                                        our Equality and Diversity Policy and we encourage you complete
                                        this form in full to assist us with this. The information provided is to
                                        be used solely for monitoring and to comply with our legal
                                        obligations in relation to the Equality Act 2010. Any information
                                        provided in this form will be treated in the strictest confidence.
                                        The data provided will be processed and held for the specified
                                        purposes and in accordance with data protection legislation, as set
                                        out in our Data Protection Policy and the Privacy Notice issued to
                                        you.
                                    </div>
            </h4>

        </div>
        <div id="accordion1_13" class="panel-collapse collapse in">
            <div class="panel-body">

                <div class="row mt-0 mb-0">
                    <label class="control-label col-md-2">Genders</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.gender}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Marital Status</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.maritalStatus}}" readonly />
                        </div>

                        <label class="control-label col-md-2"><span *ngIf="person_equalityInfo?.gender === 'Other'">If Other, please specify</span></label>                        <div class="col-md-4">
                            <input type="text" class="form-control  form-group readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.gender === 'Other'"
                                value="{{person_equalityInfo?.gender_Other}}" readonly />
                        </div>

                        <label class="control-label col-md-2"><span *ngIf="person_equalityInfo?.maritalStatus === 'Other'">If Other, please specify</span></label>
                        <div class="col-md-4">
                            <input type="text" class="form-control form-group readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.maritalStatus === 'Other'"
                                value="{{person_equalityInfo?.marital_Other}}" readonly />
                        </div>
                </div>

                <div class="row mt-0 mb-0">
                    <label class="control-label col-md-2">Sexual Orientation</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.sexual_Orientation}}" readonly />
                        </div>
                        <label class="control-label col-md-2">Age</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.age}}" readonly />
                        </div>
                        <label class="control-label col-md-2"><span *ngIf="person_equalityInfo?.sexual_Orientation === 'Other'">If Other, please specify</span></label>
                        <div class="col-md-4">
                            <input type="text" class="form-control form-group readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.sexual_Orientation === 'Other'"
                                value="{{person_equalityInfo?.sexual_Other}}" readonly />
                        </div>
                </div>

                <div class="row mt-0 mb-0">
                    <label class="control-label col-md-2">
                        Disability
                        <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-container="body"
                        data-placement="left" type="button" data-html="true" id="disability">
                        <span class="glyphicon glyphicon-info-sign"></span>
                    </span>
                    </label>
                    <div id="popover-content-disability" class="hide">
                        The Equality Act 2010 defines a disabled person
                        as someone who has a mental or physical
                        impairment that has a substantial and long-term
                        adverse effect on the person’s ability to carry out
                        normal day-to-day activities. If you have a
                        condition which fits the Equality Act definition,
                        please tick ‘yes’ even if you are not limited by
                        your condition.
                        Do you consider yourself to have a disability?
                    </div>
                    <div class="col-md-4 form-group pos-relative">
                        <input type="text" class="form-control readonlyClass" autocomplete="off"
                            value="{{person_equalityInfo?.disability}}" readonly />
                    </div>

                    <label class="control-label col-md-2">Religion or Belief</label>
                    <div class="col-md-4 form-group">
                        <input type="text" class="form-control readonlyClass" autocomplete="off"
                            value="{{person_equalityInfo?.religionOrBelief}}" readonly />
                    </div>
                </div>

                <div class="row mt-0 mb-0">
                    <label class="control-label col-md-2">Is the gender you identify with the same as your gender
                        registered at birth?</label>
                    <div class="col-md-4 form-group">
                        <input type="text" class="form-control readonlyClass" autocomplete="off"
                            value="{{person_equalityInfo?.gender_Birth}}" readonly />
                    </div>
                    <label class="control-label col-md-2">
                        Ethnicity
                        <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-placement="left" type="button"
                            data-html="true" id="ethnicity">
                            <span class="glyphicon glyphicon-info-sign"></span>
                        </span>
                    </label>
                    <div id="popover-content-ethnicity" class="hide">
                        Ethnic origin is not about nationality, place of
                        birth or citizenship. It is about what best
                        describes your ethnic group or background.
                        Please choose one option and tick the
                        appropriate box below.
                        What is your ethnic group?
                    </div>
                    <div class="col-md-4 form-group">
                        <input type="text" class="form-control readonlyClass" autocomplete="off"
                            value="{{person_equalityInfo?.ethnicity}}" readonly />
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="form-group">
                        <label class="control-label col-md-2">Gender</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.gender}}" readonly />
                        </div>

                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.gender === 'Other'"
                                value="{{person_equalityInfo?.gender_Other}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Marital Status</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.maritalStatus}}" readonly />
                        </div>

                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.maritalStatus === 'Other'"
                                value="{{person_equalityInfo?.marital_Other}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Sexual Orientation</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.sexual_Orientation}}" readonly />
                        </div>

                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.sexual_Orientation === 'Other'"
                                value="{{person_equalityInfo?.sexual_Other}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Age</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.age}}" readonly />
                        </div>

                        <label class="control-label col-md-2">
                            Disability
                            <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-container="body"
                            data-placement="left" type="button" data-html="true" id="disability">
                            <span class="glyphicon glyphicon-info-sign"></span>
                        </span>
                        </label>
                        <div id="popover-content-disability" class="hide">
                            The Equality Act 2010 defines a disabled person
                            as someone who has a mental or physical
                            impairment that has a substantial and long-term
                            adverse effect on the person’s ability to carry out
                            normal day-to-day activities. If you have a
                            condition which fits the Equality Act definition,
                            please tick ‘yes’ even if you are not limited by
                            your condition.
                            Do you consider yourself to have a disability?
                        </div>
                        <div class="col-md-4 form-group pos-relative">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.disability}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Religion or Belief</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.religionOrBelief}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Is the gender you identify with the same as your gender
                            registered at birth?</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.gender_Birth}}" readonly />
                        </div>
                        <label class="control-label col-md-2">
                            Ethnicity
                            <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-placement="left" type="button"
                                data-html="true" id="ethnicity">
                                <span class="glyphicon glyphicon-info-sign"></span>
                            </span>
                        </label>
                        <div id="popover-content-ethnicity" class="hide">
                            Ethnic origin is not about nationality, place of
                            birth or citizenship. It is about what best
                            describes your ethnic group or background.
                            Please choose one option and tick the
                            appropriate box below.
                            What is your ethnic group?
                        </div>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.ethnicity}}" readonly />
                        </div>
                    </div>
                </div> -->


            </div>
        </div>
    </div>
</div>
<div class="modal" id="modalJobDescription" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="modalJobDescriptionclose()"  aria-hidden="true"></button>
                <h4 class="modal-title">Job Description</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <iframe [src]='fileUrl' width="100%" height="600px"></iframe>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button"  class="btn dark btn-outline" (click)="modalJobDescriptionclose()">Close</button>
            </div>
        </div>
    </div>
</div>