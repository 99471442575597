import { Component, OnInit, ViewChild, DebugElement } from '@angular/core';
import { CandidatePersonalInfoComponent } from "../onboarding-personal-Info/candidate-personal-info.component";
import { AddressInfoCandidateComponent } from "./address-info/address-info-candidate.component";
import { CandidateJobDetailsComponent } from "../onboarding-job-detail/candidate-job-detail.component";
import { ContactCandidateComponent } from "./contact-candidate/contact-candidate.component";
import { EmergencyCandidateComponent } from "./emergency-contact-candidate/emergency-contact-candidate.component";
import { RefCandidateComponent } from "./reference-candidate/reference-candidate.component";
import { BankCandidateComponent } from "./bank-candidate/bank-candidate.component";
import { ProbationCandidateComponent } from "./probation-candidate/probation-candidate.component";
import { ContractCandidateComponent } from "./contract-candidate/contract-candidate.component";
import { RightWorkCandidateComponent } from "./right-work-candidate/right-work-candidate.component";
import { SalaryCandidateComponent } from "./salary-candidate/salary-candidate.component";
import { DocumentCandidateComponent } from "./document-candidate/document-candidate.component";

import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { CandidatePersonEqualityComponent } from '../onboarding-person-equality/candidate-person-equality.component';
// import { EmpReferenceComponent } from './employee-reference/employee-reference.component';
declare var $: any;

@Component({
   selector: 'app-candidateedit',
   templateUrl: './candidate-edit.component.html',
})
export class CandidateEditComponent implements OnInit {
   @ViewChild(HistoryComponent, { static: false }) History: HistoryComponent;
   @ViewChild(CandidatePersonalInfoComponent, { static: false }) CandidatePersonalInfo: CandidatePersonalInfoComponent;
   @ViewChild(CandidatePersonEqualityComponent, { static: false }) CandidatePersonEquality: CandidatePersonEqualityComponent;
   @ViewChild(AddressInfoCandidateComponent, { static: false }) CandidateAddress: AddressInfoCandidateComponent;
   @ViewChild(CandidateJobDetailsComponent, { static: false }) CandidateJDetail: CandidateJobDetailsComponent;
   @ViewChild(ContactCandidateComponent, { static: false }) CandidateContact: ContactCandidateComponent;
   @ViewChild(EmergencyCandidateComponent, { static: false }) CandidateEmeContact: EmergencyCandidateComponent;
   @ViewChild(RefCandidateComponent, { static: false }) CandidateReference: RefCandidateComponent;
   @ViewChild(BankCandidateComponent, { static: false }) CandidateBank: BankCandidateComponent;
   @ViewChild(ProbationCandidateComponent, { static: false }) CandidateProbation: ProbationCandidateComponent;
   @ViewChild(ContractCandidateComponent, { static: false }) CandidateContract: ContractCandidateComponent;
   @ViewChild(RightWorkCandidateComponent, { static: false }) candidateRightToWork: RightWorkCandidateComponent;
   @ViewChild(SalaryCandidateComponent, { static: false }) CandidateSalary: SalaryCandidateComponent;
   @ViewChild(DocumentCandidateComponent, { static: false }) CandidateDocument: DocumentCandidateComponent;

   fileUrl;
   fileUrlmodalJobDescription:any;
   fileName: any;
   logoClass = "fileinput fileinput-new";
   logoInnerHTML = "";
   candidateSummary: any;
   candidatePersonalInfo? : any;
   person_equalityInfo?:any;
   candidateAddress: any;
   candidateJobDetails: any;
   candidateConatct: any;
   candidateEmergency: any;
   candidateBank: any;
   candidateRef: any;
   candidateProbation: any;
   candidateContract: any;
   candidaterightToWork: any;
   candidatesalary: any;
   candidatedocument; any;
   classMainDiv: string = "page-content-wrapper";
   html: any;
   editid: any;
   candidateLogin: boolean = false;
   historydata: history[] = [];
   filterhistory: any;
   p: number = 1;
   ethinicityId: any;
   ethnicity_Name: any;
   iscompanyDoc = 0;
   editMode: boolean = false
   editRecord: number = 0;

   constructor(private commonApiService: CommonApiService,
      private toastr: ToastrService,
      private route: ActivatedRoute,
      private commonService: CommonService,
      private sanitizer: DomSanitizer,private sanitizer1: DomSanitizer,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {


      this.route.queryParamMap.subscribe(params => {
         this.editid = params["params"]["editId"];
         this.candidateLogin = false;
         if (this.editid == undefined) {
            this.editid = sessionStorage.getItem("menuHide").substring(9);
            this.candidateLogin = true;
            this.classMainDiv = "";
         }

      });

      // if (sessionStorage.getItem("menuHide") != "") {
      //    this.editid =  sessionStorage.getItem("menuHide").substring(9);
      //    this.candidateLogin = true;
      //    this.classMainDiv = "";
      // }
      // else {
      //    this.route.queryParamMap.subscribe(params => {
      //       this.editid = params["params"]["editId"];
      //       this.candidateLogin = false;
      //    });
      // }


      this.getSummary()

      this.commonService.flatpickr("fromdate,#todate", 0);

   }

   ngAfterViewInit(){
      $("[data-toggle=popover]").each(function(i, obj) {

         $(this).popover({
           html: true,
           content: function() {
             var id = $(this).attr('id')
             return $('#popover-content-' + id).html();
           }
         });
         
         });

        
   }
   getSummary() {




      this.commonApiService.getById(Commonvariable.OnboardingDetail, this.editid, "ViewSummary").subscribe((response) => {
         if (response != null) {
            this.candidateJobDetails = response["data"][0];
            this.candidateBank = this.candidateJobDetails["onboarding_bank"];
            this.candidateProbation = this.candidateJobDetails["onboarding_probation"];
            this.candidateContract = this.candidateJobDetails["onboarding_contract"];
            this.candidaterightToWork = this.candidateJobDetails["onboarding_righttowork"];
            this.candidatesalary = this.candidateJobDetails["onboarding_salary"];
            this.candidatedocument = this.candidateJobDetails["onboarding_document"]


            if (response["data"][0]["person_Id"] != null) {
               this.commonApiService.getById(Commonvariable.PersonService, response["data"][0]["person_Id"], "ViewSummary").subscribe((result) => {
                  this.candidatePersonalInfo = result["data"][0];
                  this.candidateAddress = this.candidatePersonalInfo["person_Address"];
                  this.candidateConatct = this.candidatePersonalInfo["person_Contact"];
                  this.candidateEmergency = this.candidatePersonalInfo["person_Emergency"];
                  this.candidateRef = this.candidatePersonalInfo["person_Reference"];
                  this.person_equalityInfo = this.candidatePersonalInfo["person_equality"];

                  

                  if (this.candidatePersonalInfo.ethnicity_Id) {
                     this.getEthinicityName(this.candidatePersonalInfo.ethnicity_Id);
                  }

                  this.candidateImage();
               })
            }
         }
      })


   }









   getEthinicityName(id) {

      this.commonApiService.getById("Ethinicity", id).subscribe((response) => {
         //console.log(response);
         if (response != null) {
            this.ethnicity_Name = response["data"][0].ethnicity_Name;
            //console.log(this.ethnicity_Name);

         }
      })

   }




   candidateImage() {
      this.commonApiService.getimage(Commonvariable.Person, this.candidatePersonalInfo.id, "DownloadImage").subscribe((response) => {

         if (response != null) {
            var fileType = response.type;
            var validImageTypes = ["image/gif", "image/jpeg", "image/png", "image/jpg", "image/bmp"];
            if ($.inArray(fileType, validImageTypes) < 0) {
               this.logoInnerHTML = "";
               this.logoInnerHTML = "<img  src='assets/layouts/layout4/img/selectPic.png' alt = ''/>";
            } else {

               if (response != null) {
                  var reader = new FileReader();
                  reader.readAsDataURL(response);
                  reader.addEventListener("load", () => {
                     this.logoInnerHTML = "";
                     this.logoInnerHTML = "<img  src=" + reader.result + " alt = ''/>";
                     this.logoClass = "fileinput fileinput-exists";
                  }, false);
               }
            }
         }
      })
   }



   editempInfo(name, cid, iscompanyDoc = 0) {
      
      $(".modal").modal("hide");
      if (name == 'CandidateProbation' && cid == 0) {
         this[name].addCandidateProbation(this.editid);
      }
      if (name == "candidateRightToWork") {
         this.candidateRightToWork.getRTWCategory(this.candidateJobDetails.company_Id)
      }

      if (name == "CandidateContract") {
         this.CandidateContract.getContractList(this.candidateJobDetails.company_Id)
      }
      if (name == "CandidateDocument") {
         this.CandidateDocument.iscompanyDoc = iscompanyDoc;
         this.iscompanyDoc = iscompanyDoc;
      }
      if (cid > 0) {
         this.editMode = true;
         this.editRecord = cid;
         this[name].Edit(cid);
      }
      else {
         this.editMode = false;
         this[name].ngOnInit();
      }
   }

   update(eid) {
      let data = "";
      let tableName = "";
      let modal = ""
      let id = 1;

      if (eid == 1) {
         data = this.CandidatePersonalInfo.validation() ? this.CandidatePersonalInfo.candidateBasicInfoform.value : ""
         tableName = Commonvariable.Person;
         modal = "personal-info";
      }
      if (eid == 2) {
         data = this.CandidateAddress.validation() ? this.CandidateAddress.candidateForm.value : ""
         tableName = Commonvariable.Person_Address
         modal = "address-info";
         id = data["id"] == "0" ? 0 : 1
         data["person_Id"] = this.candidateJobDetails.person_Id;
      }
      else if (eid == 3) {
         this.CandidateJDetail.candidateJobDetailform.controls.NiNo.setValue($("#NiNumber").val())
         // this.CandidateJDetail.candidateJobDetailform.controls.TotalExperience.setValue($("#TotalExperience").val())
         data = this.CandidateJDetail.validation() ? this.CandidateJDetail.candidateJobDetailform.value : ""
         tableName = Commonvariable.Candidate
         modal = "job-details";
         data["Id"] = this.editid;
      }
      else if (eid == 4) {
         data = this.CandidateContact.validation() ? this.CandidateContact.candidateForm.value : ""
         tableName = Commonvariable.Person_Contact
         modal = "emp-contact";
         id = data["id"] == "0" ? 0 : 1
         data["person_Id"] = this.candidateJobDetails.person_Id;
      }
      else if (eid == 5) {
         data = this.CandidateEmeContact.validation() ? this.CandidateEmeContact.candidateForm.value : ""
         tableName = Commonvariable.Person_Emergency
         modal = "emp-eme-contact";
         id = data["id"] == "0" ? 0 : 1
         data["person_Id"] = this.candidateJobDetails.person_Id;
      }
      else if (eid == 6) {
         data = this.CandidateReference.validation() ? this.CandidateReference.candidateForm.value : ""
         tableName = Commonvariable.Person_Reference
         modal = "emp-ref";
         id = data["id"] == "0" ? 0 : 1
         data["person_Id"] = this.candidateJobDetails.person_Id;
      }
      else if (eid == 7) {
         data = this.CandidateBank.validation() ? this.CandidateBank.candidateForm.value : ""
         tableName = Commonvariable.Onboarding_Bank
         modal = "emp-bank";
         id = data["id"] == "0" ? 0 : 1
         data["onboarding_Id"] = this.editid;
      }
      else if (eid == 8) {
         data = this.CandidateProbation.validation() ? this.CandidateProbation.candidateForm.value : ""
         tableName = Commonvariable.Onboarding_Probation
         modal = "emp-probation";
         id = data["id"] == "0" ? 0 : 1
         data["onboarding_Id"] = this.editid;
      }
      else if (eid == 9) {
         data = this.CandidateContract.validation() ? this.CandidateContract.candidateForm.value : ""
         tableName = Commonvariable.Onboarding_Contract
         modal = "emp-contract";
         id = data["id"] == "0" ? 0 : 1
         data["onboarding_Id"] = this.editid;
      }
      else if (eid == 10) {
         data = this.candidateRightToWork.validation() ? this.candidateRightToWork.candidateForm.value : ""
         tableName = Commonvariable.Onboarding_RightToWork
         modal = "emp-right-to-work";
         id = data["id"] == "0" ? 0 : 1
         data["onboarding_Id"] = this.editid;
      }
      else if (eid == 11) {
         data = this.CandidateSalary.validation() ? this.CandidateSalary.candidateForm.value : ""
         tableName = Commonvariable.Onboarding_Salary
         modal = "emp-salary";
         id = data["id"] == "0" ? 0 : 1
         data["onboarding_Id"] = this.editid;
      }
      else if (eid == 12) {
         data = this.CandidatePersonEquality.validation() ? this.CandidatePersonEquality.candidatePersonEqualityform.value : ""
         tableName = Commonvariable.Person_Equality
         modal = "equality_info";
         id = data["Id"] == "0" ? 0 : 1
         data["person_Id"] = this.candidateJobDetails.person_Id;
      }

      if (data != "") {
         data["onboarding_Id"] = this.editid;

         this.commonApiService.Upsert(tableName, data, id).subscribe((result) => {
            if (result["message"] == Commonvariable.DuplicationMsg) {
               this.toastr.warning('Record is already exists !', 'Onboarding');
            }
            else {
               if (this.candidatePersonalInfo != null) {
                  const formData = new FormData();
                  formData.append('empBasicInfo_Id', this.candidatePersonalInfo.id);
                  formData.append('file', this.CandidatePersonalInfo.candidateLogo);

                  if (this.CandidatePersonalInfo.candidateLogoChange) {
                     this.commonApiService.UploadLogo(Commonvariable.Person, "UploadImage", formData).subscribe((response) => {
                        $("#" + modal).modal('hide');
                        this.toastr.success(id == 1 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Onboarding');
                        this.getSummary();
                        if (eid == 1) {
                           this.logoInnerHTML = "";
                           this.logoInnerHTML = "<img  src='assets/layouts/layout4/img/selectPic.png' alt = ''/>";
                           this.candidateImage()
                        }
                     });
                  }
                  else {
                     $("#" + modal).modal('hide');
                     $(".modal").modal('hide');
                     this.toastr.success(id == 1 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Onboarding');
                     this.getSummary();
                  }

               }
               else {
                  $("#" + modal).modal('hide');
                  this.toastr.success(id == 1 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Onboarding');
                  this.getSummary();
               }
            }
         })
      }
   }

   Delete(eid, id) {

      let tableName = "";
      let methodName = null;
      if (eid == 2) {
         tableName = Commonvariable.Person_Address
      }
      else if (eid == 4) {
         tableName = Commonvariable.Person_Contact
      }
      else if (eid == 5) {
         tableName = Commonvariable.Person_Emergency
      }
      else if (eid == 6) {
         tableName = Commonvariable.Person_Reference
      }
      else if (eid == 7) {
         tableName = Commonvariable.Onboarding_Bank
      }
      else if (eid == 8) {
         tableName = Commonvariable.Onboarding_Probation
      }
      else if (eid == 9) {
         tableName = Commonvariable.Onboarding_Contract
      }
      else if (eid == 10) {
         tableName = Commonvariable.OnboardingDetail
         methodName = "DeleteRightToWorkWithDoc"
      }
      else if (eid == 11) {
         tableName = Commonvariable.Onboarding_Salary
      }
      else if (eid == 12) {
         tableName = Commonvariable.Onboarding_Document
      }
      this.commonService.deleteConfirm().then((result) => {
         if (result.value) {
            this.commonApiService.Delete(tableName, id, methodName).subscribe((response) => {
               if (response["status"] == 200) {
                  this.toastr.success(response["message"], 'Onboarding');
                  this.getSummary()
               }
            })
         }
      })
   }


   history(tblName) {
      this.History.getHistory(tblName, this.editRecord);
      // this.commonService.dfpickr();	
      // $('#job-details').hide();
   }

   validation() {
      if ($("#fromdate").val() == "") {
         this.toastr.info("From date is required !", 'Onboarding Edit');
         $("#fromdate").focus();
         return false;
      }
      if ($("#todate").val() == "") {
         this.toastr.info("To date is required !", 'Onboarding Edit');
         $("#todate").focus();
         return false;
      }
      return true;
   }



   reviewCandidate() {
      this.commonService.dyanmicConfirm("You Want Submit To HR  !", "Yes !").then((result) => {
         if (result.value) {
            var ids = this.editid + "," + "Submit" + "," + Commonvariable.User_Id
            this.commonApiService.Put(Commonvariable.OnboardingDetail, ids, "StatusChange").subscribe((response) => {
               if (response != null) {
                  this.toastr.success(response["message"], "Onboarding");
               }
            })
         }
      })
   }
   download() {

      if (parseInt(this.candidateJobDetails.jD_Id) > 0) {
         
         this.commonApiService.getimage(Commonvariable.JobDescription, parseInt(this.candidateJobDetails.jD_Id), "DownloadDocument").subscribe((resp: any) => {
            if (resp.type != "application/json") {
               const blobData = new Blob([resp], { type: resp.type });
               var createObjectURL = (window.URL.createObjectURL(blobData));
               if (resp.type == "application/pdf" || resp.type == "image/jpeg" || resp.type == "image/png") {
                  this.fileUrlmodalJobDescription = this.sanitizer1.bypassSecurityTrustResourceUrl(createObjectURL);
                 
                   $("#modalJobDescription1").modal("show")
               } else if (resp.type == "application/vnd.ms-word") {
                  const a = document.createElement('a');
                  a.setAttribute('type', 'hidden');
                  a.href = URL.createObjectURL(blobData);
                  a.download = 'Job_Description.docx';
                  a.click();
                  a.remove();
               } else {

                  const a = document.createElement('a');
                  a.setAttribute('type', 'hidden');
                  a.href = URL.createObjectURL(blobData);
                  a.download = 'Job_Description';
                  a.click();
                  a.remove();
               }
            }
            else {
               this.commonService.toaster("warning", 'Document Not Available', 'Job Description');
            }
         });
      }
      else{
         this.commonService.toaster("warning", 'Not selected.', 'Job Description')
       }
   }



   downloadDocument(id, path) {
      this.fileName = "Onboarding_Document" + "." + String(path).split('.')[1]
      this.commonApiService.getimage(Commonvariable.Onboarding_Document, id, 'Download').subscribe((result) => {
         const blobData = new Blob([result], { type: result.type });
         var createObjectURL = (window.URL.createObjectURL(blobData));
         console.log(result);
         if (result.type == "application/pdf" || result.type == "image/jpeg" || result.type == "image/png") {
            this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
            $("#modalCandidateDocument").modal("show")
         } else if (result.type == "application/vnd.ms-word") {
            const a = document.createElement('a');
            a.setAttribute('type', 'hidden');
            a.href = URL.createObjectURL(blobData);
            a.download = 'Onboarding_documents.docx';
            a.click();
            a.remove();

         } else {
            const a = document.createElement('a');
            a.setAttribute('type', 'hidden');
            a.href = URL.createObjectURL(blobData);
            a.download = 'Onboarding_documents';
            a.click();
            a.remove();
         }

      })


      if (this.candidatedocument.filter(x => x.id == id)[0].doc_Name != undefined) {
         this.fileName = this.candidatedocument.filter(x => x.id == id)[0].doc_Name;
         this.commonApiService.getimage(Commonvariable.Onboarding_Document, id, "Download").subscribe((response) => {
            const blobData = new Blob([response], { type: 'application/octet-stream' });
            this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blobData));
            setTimeout(() => {
               $("#download")[0].click();
            }, 500);
         })
      }

   }

   addDocumentsonRTW() {

      if (this.candidateRightToWork.validation()) {

         $(".modal").modal('hide');

         var data = this.candidateRightToWork.candidateForm.value;

         var method = data["id"] == 0 ? "AddRightToWorkWithDoc" : "EditRightToWorkWithDoc"
         var editCall = data["id"] == 0 ? false : true

         //console.log(data);return false;
         const formData = new FormData();
         formData.append('Id', data.id);
         formData.append('OnboardingDoc_Id', this.candidateRightToWork.editDocumentId.toString());
         formData.append('Onboarding_Id', this.editid);
         formData.append('DocId', data.doc_Id);
         formData.append('files', data.files);
         formData.append('RWC_Id', data.rwC_Id);
         formData.append('IssueAuthority', data.issueAuthority);
         formData.append('IssueCountry', data.issueCountry);
         formData.append('IssueDate', data.issueDate);
         formData.append('ExpiryDate', data.expiryDate);
         formData.append('Doc_Name', data.Doc_Name);
         formData.append('Notes', data.notes);
         formData.append('isActive', data.isActive);
         // formData.append('AddedBy', data.addedBy);
         formData.append('UpdatedBy', data.updatedBy);

         this.commonApiService.UploadLogo(Commonvariable.OnboardingDetail, method, formData, editCall).subscribe((response: any) => {

            if (response.data.id > 0) {


               this.candidateRightToWork.candidateForm.controls.doc_Id.setValue(response.data.id)
               //this.update(10);
               $(".modal").modal('hide');
               this.toastr.success(editCall ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Onboarding');
               this.getSummary();
            } else {
               this.toastr.warning(response.message, 'Onboarding');
               return false;
            }
            $("#remove").click();
         });


      }

   }

   addDocuments() {
      if (this.CandidateDocument.validation()) {
         
         $("#emp-document").modal('hide');

         var data = this.CandidateDocument.candidateForm.value;
         var method = data["id"] == 0 ? "Add" : "Edit"

         if (this.CandidateDocument.iscompanyDoc == 1) {
            method = data["id"] == 0 ? "AddCompanyDoc" : "EditCompanyDoc"
         }

         var editCall = data["id"] == 0 ? false : true
         const formData = new FormData();
         formData.append('Id', data.id);
         formData.append('Onboarding_Id', this.editid);
         formData.append('Doc_Id', data.doc_Id);
         formData.append('files', data.files);
         formData.append('Is_CompanyDoc', String(this.CandidateDocument.iscompanyDoc));
         formData.append('Doc_Name', data.doc_Name);
         formData.append('Notes', data.notes);
         formData.append('isActive', data.isActive);
         formData.append('AddedBy', data.addedBy);
         formData.append('UpdatedBy', data.updatedBy);
         this.commonApiService.UploadLogo(Commonvariable.Onboarding_Document, method, formData, editCall).subscribe((response: any) => {
            $(".removeFile").click();
            $("#emp-document").modal('hide');
            if (response.data) {
               this.toastr.success(editCall ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Onboarding');
               this.getSummary();
            } else {
               this.toastr.warning(response.message, 'Onboarding');
            }

         });
      }

   }





}

interface history {

   addedBy: number;
   addedOn: string;
   action: string;
   date: [];
}