<!-- <form [formGroup]="empform">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Reference Name<span class="required"> * </span></label>
                <input type="text" class="form-control" id="ref_Name" formControlName="ref_Name" autocomplete="off"
                    maxlength="100">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Reference Email Address</label>
                <input type="text" class="form-control" id="ref_Email" formControlName="ref_Email" autocomplete="off"
                    maxlength="50">
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-md-5">
            <div class="form-group">
                <label class="control-label">Reference Contact Number<span class="required"> * </span></label>
                <div class="input-group mobilenowithcountryode">
                    <div class="input-group-btn">
                        <ng-select2 [data]="countryList" id="country_Id" formControlName="country_Id"
                            [placeholder]="'--- Select ---'">
                        </ng-select2>
                    </div>
                    <input type="text" class="form-control" id="ref_ContactNo" formControlName="ref_ContactNo"
                        autocomplete="off" maxlength="20" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.key === "Backspace" ||  event.charCode == 40 
                    || event.charCode == 32 || event.charCode == 41 || event.charCode == 43 || event.charCode == 45'>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group">
                <label class="control-label">Reference Relationship<span class="required"> * </span></label>
                <input type="text" class="form-control" id="ref_Relationship" formControlName="relationship" autocomplete="off"
                maxlength="100">
                <select class="form-control" id="ref_Relationship" formControlName="relationship_Id">
                    <option value="">Select Reference Relationship</option>
                    <option *ngFor="let item of emprefrelList;" value={{item.id}}>{{item.name}}</option>
                </select> -->
                <!-- <input list="browsers" id="ref_Relationship" class="form-control" (change)="addRelationship($event)"
                    autocomplete="off" formControlName="ref_Relationship">
                <datalist id="browsers">
                    <option *ngFor="let item of emprefrelList;" value={{item.name}}>
                    </option>
                </datalist> 
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="empref" class="md-check" formControlName="isActive">
                            <label for="empref">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form> -->

<!-- <form [formGroup]="empform">
    
    <div class="form-group">
        <div class="table-responsive">
            
          
        
            <table class="table table-hover" id="work" style="position: relative;" role="grid">
                <thead>
                    <tr>
                        <th style="width: 10%;">Received </th>
                        <th style="width: 15%;">Date Of Received </th>
                        <th style="width: 15%;">Notes</th>                        
                        <th style="width: 15%;">Attachment Upload</th>
                        <th style="width: 2%;">Active</th>
                        <th style="width: 5%;"><button  type="button" (click)="addItem(null)" class="btn pull-right"><i class="fa fa-plus"
                            aria-hidden="true"></i></button></th>
                    </tr>
                </thead>
                <tbody formArrayName="emp_ref"
                    *ngFor="let item of empform.get('emp_ref')['controls']; let i = index;">
                    <tr [formGroupName]="i">
                        <label>Reference{{i+1}}   Received</label>
                        <div class="md-checkbox-inline">
                            <label class="control-label">Yes &nbsp;&nbsp;</label>
                            <div class="md-checkbox">
                                <input type="checkbox" id="empReferenceRequired{{i}}" class="md-check" (click)="chekboxclickyes($event,i)" >
                                <label for="empReferenceRequired{{i}}">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> </label>
                            </div>
                            <label class="control-label">No &nbsp;&nbsp;</label>
                            <div class="md-checkbox">
                                <input type="checkbox" id="empReferencenotRequired{{i}}" class="md-check"  (click)="chekboxclickno($event,i)" >
                                <label for="empReferencenotRequired{{i}}">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> </label>
                            </div>
                        </div>
                        <td>
                           
                            <div class="input-group input-medium" id="flatpickropenEmpCStD{{i}}" data-wrap="true" >
                                <input type="text" class="form-control readonlyClass" id="DateOfRec{{i}}" formControlName="DateOfRec"
                                    data-input >
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button" >
                                        <i class="fa fa-calendar" (click)="openFlatpickr(i)"></i>
                                    </button>
                                </span>
                            </div>
                        </td>
                        <td><span class="required" *ngIf="isrequiredclass[i]"> * </span>
                            <textarea cols="55" class="form-control input-medium" rows="3" id="Notes{{i}}" formControlName="Notes"></textarea>
                        </td>                       
                        <td><span class="required" *ngIf="isrequiredclass[i]"> * </span>
                            <div class="fileinput fileinput-new" data-provides="fileinput">
                                <div class="input-group input-large">
                                    <div class="form-control uneditable-input input-fixed input-medium"
                                        data-trigger="fileinput">
                                        <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                        <span class="fileinput-filename" id="fileinput-filename{{i}}"> </span>
                                    </div>
                                    <span class="input-group-addon btn default btn-file">
                                        <span class="fileinput-new"> Select file </span>
                                        <span class="fileinput-exists"> Change </span>
                                        <input type="file" id="files{{i}}" (change)="fileChange($event,i)">
                                    </span>
                                    <a href="javascript:;" id="remove{{i}}"
                                        class="input-group-addon btn red fileinput-exists" (click)="removeDoc(i)"
                                        data-dismiss="fileinput"> Remove </a>
                                </div>
                            </div>
                        </td>
                     
    
                        <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="empdoc{{i}}" class="md-check" formControlName="isActive">
                                    <label for="empdoc{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td>
    
                      
                        <td>
                            <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                                    class="fa fa-minus" aria-hidden="true"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
    </div>
</form> -->
<div class="row max-height">

    <form [formGroup]="empform">


        <div class="col-sm-12" id="work" role="grid">
    
            <div class="pull-right">
                <button  type="button" (click)="addItem(null)" class="btn pull-right"><i class="fa fa-plus"
                    aria-hidden="true"></i></button>
            </div>

            <div class="col-lg-12">

                <div class="row mt-0 mb-0" formArrayName="emp_ref"
                *ngFor="let item of empform.get('emp_ref')['controls']; let i = index;">
        
                    <div [formGroupName]="i">
        
                        <div class="row mt-0 mb-0">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-4">
                                <h4 class="mb-15">Reference{{i+1}}</h4>
                            </div>
                            <div class="col-lg-7">
                                <button *ngIf="i>0" type="button"  class="btn pull-right"><i
                                     class="fa fa-minus" aria-hidden="true"></i></button> <!-- (click)="removeItem(i)" -->
                            </div>
                        </div>
                        
                        <div class="row mt-0">
                            <div class="col-lg-2"></div>
                            <label class="col-lg-3 form-label l-38">Received</label>
                            <div class="md-checkbox-inline">
                                <label class="control-label">Yes &nbsp;&nbsp;</label>
                                <div class="md-checkbox">
                                    <input type="checkbox" id="empReferenceRequired{{i}}" class="md-check" (click)="chekboxclickyes($event,i)" >
                                    <label for="empReferenceRequired{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                                <label class="control-label">No &nbsp;&nbsp;</label>
                                <div class="md-checkbox">
                                    <input type="checkbox" id="empReferencenotRequired{{i}}" class="md-check"  (click)="chekboxclickno($event,i)" >
                                    <label for="empReferencenotRequired{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </div>

                    
                        
                        <div class="row mt-0">
                            <div class="col-lg-2"></div>
                            <label class="col-lg-3 form-label l-38">Date Of Received<span class="required text-danger" *ngIf="isrequiredclass[i]"> * </span> </label>
                            <div class="col-lg-5">
                                <div class="input-group" id="flatpickropenEmpCStD{{i}}" data-wrap="true" >
                                    <input type="text" class="form-control readonlyClass" id="DateOfRec{{i}}" 
                                        data-input >
                                    <span class="input-group-btn" data-toggle>
                                        <button class="btn default" type="button" >
                                            <i class="fa fa-calendar" (click)="openFlatpickrStD(i)"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-0">
                            <div class="col-lg-2"></div>
                            <label class="col-lg-3 form-label l-38">Date Sent<span class="required text-danger" *ngIf="isrequiredclass[i]"> * </span> </label>
                            <div class="col-lg-5">
                                <div class="input-group" id="flatpickropenEmpCEnD{{i}}" data-wrap="true" >
                                    <input type="text" class="form-control readonlyClass" id="DateSent{{i}}" 
                                        data-input >
                                    <span class="input-group-btn" data-toggle>
                                        <button class="btn default" type="button" >
                                            <i class="fa fa-calendar" (click)="openFlatpickr(i)"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
        
                        <div class="row mt-0">
                            <div class="col-lg-2"></div>
                            <label class="col-lg-3 form-label l-38">Notes<span class="required text-danger" *ngIf="isrequiredclass[i]"> * </span></label>
                            <div class="col-lg-5">
                                <textarea cols="55" class="form-control" rows="3" id="Notes{{i}}" formControlName="Notes"></textarea>
                            </div>
                        </div>
        
                        <div class="row mt-0">
                            <div class="col-lg-2"></div>
                            <label class="col-lg-3 form-label l-38">Attachment Upload<span class="required text-danger" *ngIf="isrequiredclass[i]"> * </span></label>
                            <div class="col-lg-5">
                                <div class="fileinput fileinput-new" data-provides="fileinput">
                                    <div class="input-group input-large">
                                        <div class="form-control uneditable-input input-fixed input-medium"
                                            data-trigger="fileinput">
                                            <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                            <span class="fileinput-filename" id="fileinput-filename{{i}}"> </span>
                                        </div>
                                        <span class="input-group-addon btn default btn-file">
                                            <span class="fileinput-new"> Select file </span>
                                            <span class="fileinput-exists"> Change </span>
                                            <input type="file" id="files{{i}}" (change)="fileChange($event,i)">
                                        </span>
                                        <a href="javascript:;" id="remove{{i}}"
                                            class="input-group-addon btn red fileinput-exists" (click)="removeDoc(i)"
                                            data-dismiss="fileinput"> Remove </a>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div class="row mt-0">
                            <div class="col-lg-2"></div>
                            <label class="col-lg-3 form-label l-38">Active</label>
                            <div class="col-lg-5">
                                <div class="md-checkbox-inline">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="empdoc{{i}}" class="md-check" formControlName="isActive">
                                        <label for="empdoc{{i}}">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
        
                </div>

            </div>
        </div>
    
        
     
    
    </form>

</div>