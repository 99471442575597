import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;
import { Observable } from "rxjs"


@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html'
})
export class PayrollComponent implements OnInit {

  @Input() custom_emp_Id: string;

  payrolllist: any;
  filterPayroll: any;
  listCall: boolean = true;
  Toast: any;
  exportshow: boolean = false;
  fileUrl;
  p: number = 1;
  module: any;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private fb: FormBuilder) { }
  ngOnInit() {
    if (this.listCall)    
      this.getPayrollList();
    $("#filterValue").flatpickr({ dateFormat: "d-m-Y", mode: "range" });

  }

  onChangeStatus(status: string) {
    //console.log("the selected value is " + status);

    this.commonApiService.getById(Commonvariable.Payroll, status).subscribe((result) => {      
      if (result != null && result["status"] == 200) {
        this.filterPayroll = result["data"];
        this.payrolllist = result["data"];        
        this.moduleList(result["data"].filter(x => x.isActive != 0));
        this.filterDateWise(1);

      }
    }, (error) => { 

      this.getPayrollList();
    })
    $("#checkall").prop("checked", false);
  }

  getPayrollList() {
    this.commonApiService.getById(Commonvariable.Payroll, "Open").subscribe((result) => {
      if (result != null && result["status"] == 200 && result["data"] != "") {
        this.payrolllist = result["data"];
        this.filterPayroll = result["data"];
        //this.export();
        this.moduleList(result["data"].filter(x => x.isActive != 0));
        //console.log(result["data"]);

      }
    })
  }

  export() {

    this.commonApiService.export(Commonvariable.Payroll, "export", { "Extension": ".csv" }).subscribe((response) => {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      this.exportshow = true;
    });
  }

  filterData(event) {
    const val = event.target.value.toLowerCase().trim();    
    if (val == "") {
      this.module = this.filterPayroll;
      this.moduleList(this.filterPayroll.filter(x => x.isActive != 0));
      this.p = 1;
    }
    else {

      const temp = this.filterPayroll.filter(function (d) {
        return String(d["firstName"]).toLowerCase().indexOf(val) !== -1 || String(d["lastName"]).toLowerCase().indexOf(val) !== -1 ||
        String(d["company_Name"]).toLowerCase().indexOf(val) !== -1 || String(d["dept_Name"]).toLowerCase().indexOf(val) !== -1 ||
        String(d["jobTitle"]).toLowerCase().indexOf(val) !== -1 || String(d["workStartDate"]).toLowerCase().indexOf(val) !== -1 || !val;
      });

      //const temp = this.commonService.filterData(event, this.payrolllist, "company_Name");
      this.module = temp;
      this.moduleList(temp.filter(x => x.isActive != 0));
      this.p = 1;
    }
  }


  filterDateWise(statusChange = 0) {

    if ($("#filterValue").val() != "") {
      var dates = $("#filterValue").val().split("to")
      var fdate = this.commonService.dateFormatChange(dates[0].trim());
      var tdate = this.commonService.dateFormatChange(dates[1].trim());
      var data = [];
      for (let index = 0; index < this.payrolllist.length; index++) {
        var workStartDate = this.filterPayroll[index].workStartDate.split("-");
        if (new Date(workStartDate[2] + "-" + workStartDate[1] + "-" + workStartDate[0]) >= new Date(fdate)
          && new Date(workStartDate[2] + "-" + workStartDate[1] + "-" + workStartDate[0]) <= new Date(tdate)) {
          data.push(this.filterPayroll[index]);
        }
      }
      this.module = data;
      this.moduleList(data.filter(x => x.isActive != 0));
    }
    else {
      if(statusChange == 0)
      {
        this.toastr.info("Select Range Date", "Payroll");
        $("#searchFilter").focus()
      }
      this.module = this.filterPayroll;
      this.moduleList(this.filterPayroll.filter(x => x.isActive != 0));
    }
    this.p = 1

  }

  clear() {
    $("#filterValue").val("");
    $("#searchFilter").val("");
    $("#filterValue").flatpickr({ dateFormat: "d-m-Y", mode: "range" });
    this.module = this.filterPayroll;
    this.p = 1
  }
  clearSearch() {
    $("#searchFilter").val("");
    $("#filterValue").val("");
    this.module = this.filterPayroll;
    this.p = 1
  }


  moduleList(list) {    
    var array = [];
    var index = 0;
    for (const key of Object.keys(list)) {
      var data = {
        "id": index,
        "select": false,
        "emp_Id": list[key].emp_Id,
        "title": list[key].title,
        "firstName": list[key].firstName,
        "lastName": list[key].lastName,
        "company_Name": list[key].company_Name,
        "dept_Name": list[key].dept_Name,
        "jobTitle": list[key].jobTitle,
        "workStartDate": list[key].workStartDate,
        "task_Id": list[key].task_Id
        //"selectall": false
      }
      index++;
      //console.log(data);
      array.push(data);
    }
    this.module = array
  }







  checkBoxAll(index, all = null) {

    if (all == null) {

      this.module[index].select = $("#selectall" + index).prop("checked");

      if (this.module.filter(x => x.select == true).length == this.module.length) {
        $("#checkall").prop("checked", true)
      }
      else {
        $("#checkall").prop("checked", false)
      }
    }
    else {
      for (let index = 0; index < this.module.length; index++) {

        this.module[index].select = $("#checkall").prop("checked")

      }
    }

  }

  getallIds() {

    var selectList = this.module.filter(x => x.select);

    var empIdStr = [];
    var combineData = "";

    if (selectList.length == 0) {
      this.toastr.warning("Please select at least one checkbox", "Payroll");
      return false;
    }

    for (let index = 0; index < selectList.length; index++) {

      var jsonData = {
        FieldName: "Emp_Id",
        FieldValue: '\"' + selectList[index].emp_Id + '\"',
        Parameter: "==",
        ConditionWith: selectList.length - 1 == index ? "" : "||"
      }
      empIdStr.push(jsonData);

      combineData += combineData == "" ? selectList[index].task_Id : "," + selectList[index].task_Id;

    }

    combineData += "," + "Completed"

    var data = {
      "searchBy": empIdStr
    }

    this.commonApiService.ExportById(Commonvariable.Payroll, "ExportBy", data).subscribe((resp: any) => {

      const blobData = new Blob([resp], { type: 'application/octet-stream' });
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blobData));

      setTimeout(() => {
        $("#download")[0].click();
        if ($("#status").val() == "Open")
        {
          this.updateStatus(combineData);
        }
      }, 500);    


    });       

    this.ngOnInit();
    this.p = 2;

  }

  updateStatus(data) {
    this.commonApiService.Put("Task_Activity", data, "UpdateSelectedStatus").subscribe((resp) => {
      this.toastr.success("Status completed successfully", "Candidate");
      this.ngOnInit();
      this.p = 2;
    });

  }


}
