<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-head">
      <div class="page-title">
        <h1>Accessibility</h1>
      </div>
    </div>

    <div class="navbar page-breadcrumb breadcrumb mb20">
      <ul class="nav navbar-nav navbar-right">
        <li>
          <div>
            <a href="javascript:;" (click)="openModel(0)" class="btn btn-transparent blue  btn-sm">Add
              Accessibility</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-12">
      <div class="portlet box grey">
        <div class="portlet-body">
          <diV class="row">
            <div class="col-md-9">
            </div>
            <div class="col-md-3 text-right">
              <input type="text" class="form-control" placeholder="Type to filter ..." id="filterList"
                          (input)="getAccessibilityList()">
            </div>
          </diV>
          <br />
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover">
              <thead class="flip-content">
                <tr>

                  <th class="text-center">Company</th>
                  <th class="text-center">Reporting</th>
                  <th class="text-center">Deputy</th>
                  <th class="text-center" style="width: 2%"> Actions </th>
                </tr>
              </thead>
              <tbody
                *ngFor="let item of accessibilityList| paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">
                <tr>
                  <td>
                    <!-- <div class="col-md-1 col-xs-12">
                      <a (click)="updateStatus(item.id,'0')">
                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip" data-placement="top"
                          title="Active"></div>
                      </a>
                      <a (click)="updateStatus(item.id,'1')">
                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip" data-placement="top"
                          title="Inactive"></div>
                      </a>
                    </div> -->
                    {{item.companyName}}
                  </td>
                  <td> {{item.reporting}} </td>
                  <td> {{item.deputy}} </td>
                  <td class="text-center">
                    <a (click)="openModel(item.companyId)"><i
                        class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip" data-placement="top"
                        title="Edit Accessibility"></i></a>&nbsp;
                    <a (click)="delete(item.companyId)">
                      <i class="fa fa-trash-o red-color iconcss iconFontsize" data-toggle="tooltip" data-placement="top"
                        title="Delete Accessibility"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center">
            <pagination-controls (pageChange)="getAccessibilityList($event)" autoHide="true">
                  </pagination-controls>
          </div>

        </div>
      </div>
    </div>

    <!-- END PAGE BASE CONTENT -->
  </div>
  <!-- END CONTENT BODY -->
</div>

<div class="modal fade draggable-modal" id="modalAccessibility" role="basic" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="margin:1% auto !important">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
        <h4 class="modal-title">{{title}} Accessibility</h4>
      </div>
      <div class="modal-body">
        <form [formGroup]="accessibilityForm">
          <div class="row">
            <div class="col-md-6" *ngIf="title == 'Add'">
              <div class="form-group">
                <label class="control-label">Company<span class="required"> * </span></label>
                <ng-select2 [data]="companylist" id="accessibilitycompanyid" [options]="{multiple: true,tags: false}"
                  [width]="400" [value]="accessibilityForm.controls.company_Id.value"
                  (valueChanged)="setFormData('companyid',$event)" [placeholder]="'--- Select ---'">
                </ng-select2>
              </div>
            </div>
            <div class="col-md-6" *ngIf="title == 'Edit'">
              <div class="form-group">
                <label class="control-label">Company<span class="required"> * </span></label>
                <input id="tempCompany" class="form-control" value="{{setcompanyName}}" readonly>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="form-group">
                <label class="control-label">Reporting <span class="required"> * </span></label>
                <hr>
                <div class="md-radio-inline">
                  <div class="md-radio">
                    <input type="radio" id="isAcrossCompanyForReportingTo" name="reporting" value="AcrossCompany"
                      formControlName="reporting" class="md-radiobtn">
                    <label for="isAcrossCompanyForReportingTo">
                      <span></span>
                      <span class="check"></span>
                      <span class="box"></span> Across Company </label>
                  </div>
                  <div class="md-radio">
                    <input type="radio" id="isCompanyForReportingTo" name="reporting" value="Company"
                      formControlName="reporting" class="md-radiobtn">
                    <label for="isCompanyForReportingTo">
                      <span></span>
                      <span class="check"></span>
                      <span class="box"></span>Company</label>
                  </div>
                  <div class="md-radio">
                    <input type="radio" id="isDepartmentforreportingto" name="reporting" value="Department"
                      formControlName="reporting" class="md-radiobtn">
                    <label for="isDepartmentforreportingto">
                      <span></span>
                      <span class="check"></span>
                      <span class="box"></span> Department </label>
                  </div>
                  <div class="md-radio">
                    <input type="radio" id="isJobtitleforreportingto" name="reporting" value="JobTitle"
                      formControlName="reporting" class="md-radiobtn">
                    <label for="isJobtitleforreportingto">
                      <span></span>
                      <span class="check"></span>
                      <span class="box"></span> JobTitle </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <div class="form-group">
                <label class="control-label">Deputy <span class="required"> * </span></label>
                <hr>
                <div class="md-radio-inline">
                  <div class="md-radio">
                    <input type="radio" id="isAcrossCompanyFordeputy" name="deputy" value="AcrossCompany"
                      formControlName="deputy" class="md-radiobtn">
                    <label for="isAcrossCompanyFordeputy">
                      <span></span>
                      <span class="check"></span>
                      <span class="box"></span> Across Company </label>
                  </div>
                  <div class="md-radio">
                    <input type="radio" id="isCompanyFordeputy" name="deputy" value="Company" formControlName="deputy"
                      class="md-radiobtn">
                    <label for="isCompanyFordeputy">
                      <span></span>
                      <span class="check"></span>
                      <span class="box"></span>Company</label>
                  </div>
                  <div class="md-radio">
                    <input type="radio" id="isDepartmentfordeputy" name="deputy" value="Department"
                      formControlName="deputy" class="md-radiobtn">
                    <label for="isDepartmentfordeputy">
                      <span></span>
                      <span class="check"></span>
                      <span class="box"></span> Department </label>
                  </div>
                  <div class="md-radio">
                    <input type="radio" id="isJobtitlefordeputy" name="deputy" value="JobTitle" formControlName="deputy"
                      class="md-radiobtn">
                    <label for="isJobtitlefordeputy">
                      <span></span>
                      <span class="check"></span>
                      <span class="box"></span> JobTitle </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn  dark btn-outline pull-left" (click)="history()"
                    *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button>
        <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
        <button type="button" (click)="upsert()" class="btn blue">Save</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="basic" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="margin:5% auto !important">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
              <h4 class="modal-title">Accessibility History</h4>
          </div>
          <div class="modal-body" *ngIf="title == 'Edit'">
              <app-history></app-history>
          </div>
      </div>
  </div>
</div>