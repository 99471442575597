import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { Select2OptionData } from 'ng-select2';
import { Router } from '@angular/router';
declare var $: any;


@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html'
})
export class DesignationComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  designationform: FormGroup;
  departmentlist: Array<Select2OptionData>;
  designationlist: any;
  filterlist: any;
  listcall: boolean = true;
  companylist: Array<Select2OptionData>;
  zonelist: Array<Select2OptionData>;
  employeelist: Array<Select2OptionData>;
  title: string = "Add";
  p: number = 1;
  totalRecord: 0;
  id : number = 0;
  modalOpen: boolean = false;
  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit() {
    if (this.listcall) {
      this.getDesignationList(1);
      this.getCompanyList();
    }
  }

  getDesignationList(PageIndex) {

    this.totalRecord = 0;
    this.designationlist = [];
    this.filterlist = [];
    this.p = PageIndex;
    this.commonApiService.getListWithFilter(Commonvariable.Designation, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.designationlist = result["data"]["list"];
        this.filterlist = result["data"]["list"];
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })
  }

  getCompanyList() {

    this.companylist = [];
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'company_Name'
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }

  designationDelete(dId) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Designation, dId).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getDesignationList(1);
          this.toastr.success(response["message"], 'Job Title');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Designation, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.getDesignationList(1);
            this.toastr.success(response["message"], 'Job Title');
          }

        })
      }

    });
  }


}
