import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeePersonalInfoComponent } from "./employee-personal-info/employee-personal-info.component";
import { EmployeeAddressInfoComponent } from "./employee-address-info/employee-address-info.component";
import { EmployeeJobDetailsComponent } from "./employee-job-detail/employee-job-detail.component";
import { EmployeeRightWorkComponent } from "./employee-right-work/employee-right-work.component";
import { EmpEmergencyContactComponent } from "./employee-emergency-contact/employee-emergency-contact.component";
import { EmpContactComponent } from "./employee-contact/employee-contact.component";
import { EmployeeBankInfoComponent } from "./employee-bank-info/employee-bank-info.component";
import { EmpReferenceComponent } from "./employee-reference/employee-reference.component";
import { EmpProbationComponent } from "../employee/probation/employee-probation.component";
import { EmpContractComponent } from "../employee/employee-contract/employee-contract.component";
import { EmployeeSalaryComponent } from "../employee/employee-salary/employee-salary.component";
import { EmployeeDocumentComponent } from "../employee/employee-document/employee-document.component";
import { PersonAdvancedSearchComponent } from './person-advanced-search/person-advanced-search.component';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEventPattern } from 'rxjs';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { debug } from 'console';
import * as moment from 'moment';
import { CandidatePersonEqualityComponent } from '../onboarding/onboarding-person-equality/candidate-person-equality.component';
declare var $: any;

@Component({
   selector: 'app-employee-add',
   templateUrl: './employee-add.component.html',

})
export class EmployeeAddComponent implements OnInit {
   @ViewChild(EmployeePersonalInfoComponent, { static: false }) empW1: EmployeePersonalInfoComponent;
   @ViewChild(EmployeeAddressInfoComponent, { static: false }) empW2: EmployeeAddressInfoComponent;
   @ViewChild(EmployeeJobDetailsComponent, { static: false }) empW3: EmployeeJobDetailsComponent;
   //@ViewChild(EmpContactComponent, { static: false }) empW4: EmpContactComponent;
   @ViewChild(EmpEmergencyContactComponent, { static: false }) empW5: EmpEmergencyContactComponent;
   @ViewChild(EmpReferenceComponent, { static: false }) empW6: EmpReferenceComponent;
   @ViewChild(EmployeeBankInfoComponent, { static: false }) empW7: EmployeeBankInfoComponent;
   @ViewChild(EmpProbationComponent, { static: false }) empW8: EmpProbationComponent;
   @ViewChild(EmpContractComponent, { static: false }) empW9: EmpContractComponent;
   @ViewChild(EmployeeRightWorkComponent, { static: false }) empW10: EmployeeRightWorkComponent;
   @ViewChild(EmployeeSalaryComponent, { static: false }) empW11: EmployeeSalaryComponent;
   @ViewChild(EmployeeDocumentComponent, { static: false }) empW12: EmployeeDocumentComponent;
   @ViewChild(CandidatePersonEqualityComponent, { static: false }) empW13: CandidatePersonEqualityComponent;
   @ViewChild(PersonAdvancedSearchComponent, { static: false }) advancedSearch: PersonAdvancedSearchComponent;
   inputText: string = 'Hi...have a nice day - message from parent';

   searchPersonList: any;
   backbutton: boolean = false;
   personSearchbutton: string = "visible";
   personSearchList: string = "hidden";
   class: string = "btn btn-primary active";
   pbWidth: number = 8.33;
   progressBarWidth: string = this.pbWidth + '%';
   btnName: string = "Continue";
   activeWizard: number = 1;
   w1: string = "active";
   initialFlag: boolean = false;
   companyid: any;
   jobtitleid: any ;
   jobtitlename:any;
   w2: string = "";
   w3: string = "";
   w4: string = "";
   w5: string = "";
   w6: string = "";
   w7: string = "";
   w8: string = "";
   w9: string = "";
   w10: string = "";
   w11: string = "";
   w12: string = "";
   w13: string = "";
   t1: string = "tab-pane active";
   t2: string = "tab-pane";
   t3: string = "tab-pane";
   t4: string = "tab-pane";
   t5: string = "tab-pane";
   t6: string = "tab-pane";
   t7: string = "tab-pane";
   t8: string = "tab-pane";
   t9: string = "tab-pane";
   t10: string = "tab-pane";
   t11: string = "tab-pane";
   t12: string = "tab-pane";
   t13: string = "tab-pane";



   constructor(private commonApiService: CommonApiService,
      private toastr: ToastrService,
      private router: Router,
      private route: ActivatedRoute,
      private commonservice: CommonService
   ) {

   }

   ngOnInit() {
      this.getRelationshipList();

      setTimeout(() => {
         $('[data-toggle="tooltip"]').tooltip();
      }, 500);
      console.log(this.companyid);
   }

   getRelationshipList() {
      this.commonApiService.List(Commonvariable.Relationship).subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.empW5.relList = result["data"].sort((a, b) => a.name.localeCompare(b.name));
            this.empW6.relationList = result["data"].sort((a, b) => a.name.localeCompare(b.name));
         }
      });
   }
   getJobtitle() {
      this.commonApiService.getById(Commonvariable.Designation,this.jobtitleid).subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.jobtitlename=result["data"][0].desig_Name;
            console.log(this.jobtitlename);
         }
      });
   }

   employeeUpsert(saveExit = false) {
      // if ((this.activeWizard == 12 && this.empW12.validation()) || saveExit) {
      if (this["empW" + this.activeWizard].validation() && saveExit) {
         // this.empW3.empJobDetailform.controls.emp_basicinfo.setValue(this.empW1.empBasicInfoform.value);
         if (this.empW2.empUpsert())
            this.empW1.empBasicInfoform.controls.person_Address.setValue(this.empW2.empform.controls.emp_address.value);
         // if (this.empW4.empUpsert())
         //    this.empW1.empBasicInfoform.controls.person_Contact.setValue(this.empW4.empform.controls.emp_Contact.value);
         if (this.empW5.empUpsert())
            this.empW1.empBasicInfoform.controls.person_Emergency.setValue(this.empW5.empform.controls.emp_Contact.value);
       //  if (this.empW6.empUpsert())
            //this.empW1.empBasicInfoform.controls.person_Reference.setValue(this.empW6.empform.controls.emp_ref.value);
         // this.empW3.empJobDetailform.controls.emp_reference.setValue(this.empW6.empform.controls.emp_ref.value);
         
         // if (this.empW2.empUpsert())
         //    this.empW3.empJobDetailform.controls.emp_address.setValue(this.empW2.empform.controls.emp_address.value);
         // if (this.empW4.empUpsert())
         //    this.empW3.empJobDetailform.controls.emp_contact.setValue(this.empW4.empform.controls.emp_Contact.value);
         // if (this.empW5.empUpsert())
         //    this.empW3.empJobDetailform.controls.emp_emergency.setValue(this.empW5.empform.controls.emp_Contact.value);
         // if (this.empW6.empUpsert())
         //    this.empW3.empJobDetailform.controls.emp_reference.setValue(this.empW6.empform.controls.emp_ref.value);
         if (this.empW7.empUpsert())
            //this.empW3.empJobDetailform.controls.NiNo.setValue(this.empW7.empBankInfoform.controls.emp_BankInfo.value[0].NiNo);
            this.empW3.empJobDetailform.controls.emp_bank.setValue(this.empW7.empBankInfoform.controls.emp_BankInfo.value);
            var empbankInfo = this.empW7.empBankInfoform.get('emp_BankInfo').value;

            this.empW3.empJobDetailform.controls.NiNo.setValue(empbankInfo[0].NiNo);
            this.empW3.empJobDetailform.controls.Loan.setValue(empbankInfo[0].Loan);
         if (this.empW8.empUpsert())
       
            this.empW3.empJobDetailform.controls.emp_probation.setValue(this.empW8.empform.controls.emp_Probation.value);
         if (this.empW9.empUpsert())
            //this.empW3.empJobDetailform.controls.desig_Name.setValue(this.empW9.empform.controls.Emp_Job_Title.value);
            //this.jobtitlename = 
            //this.empW9.empform.controls.Emp_Job_Title.setValue(this.jobtitlename);
            this.empW3.empJobDetailform.controls.emp_contract.setValue(this.empW9.empform.controls.emp_Contract.value);
         if (this.empW10.empUpsert())
            this.empW3.empJobDetailform.controls.emp_righttowork.setValue(this.empW10.empform.controls.emp_righttowork.value);
            if (this.empW10.empUpsert())
            this.empW3.empJobDetailform.controls.emp_righttoworkview.setValue(this.empW10.empform.controls.emp_righttowork.value);
            if (this.empW11.empUpsert()) {

            var empData = this.empW11.empform.get('emp_salary').value;
            for (let index = 0; index < empData.length; index++) {
               empData[index].Company_Id = this.empW3.empJobDetailform.controls.company_Id.value;
               empData[index].Dept_Id = this.empW3.empJobDetailform.controls.Dept_Id.value;
               empData[index].Desig_Id = this.empW3.empJobDetailform.controls.Desig_Id.value;
              
               
            }
            this.empW3.empJobDetailform.controls.emp_salary.setValue(this.empW11.empform.controls.emp_salary.value);
         }
         if (this.empW13.empUpsert()){
            this.empW3.empJobDetailform.controls.person_equality.setValue(this.empW13.candidatePersonEqualityform.value);
         }
         
         let postRequest = {
            "personDetailView": this.empW1.empBasicInfoform.value,
            "employeeView": this.empW3.empUpsert()
         }

         this.commonApiService.PostRequest(Commonvariable.EmployeeDetail, "InsertWithPerson", postRequest).subscribe((response) => {
            if (response != null) {
               if (response["message"] == Commonvariable.DuplicationMsg) {
                  this.toastr.warning('Record is already exists !', 'Employee');
               }
               else {

                  if (this.empW1.empLogoChange) {
                     const formData = new FormData();
                     formData.append('empBasicInfo_Id', response["data"]["personDetailView"]["id"]);
                     formData.append('file', this.empW1.empLogo);
                     this.commonApiService.UploadLogo(Commonvariable.Person, "UploadImage", formData).subscribe((res) => {
                        this.uploadDocument(response["data"]["employeeView"]["emp_Id"]);
                     });
                  }
                  else {
                     this.uploadDocument(response["data"]["employeeView"]["emp_Id"]);
                  }


               }
               $(".employee").addClass("active");
               $(".employeeadd").removeClass("active");
            }
         })






         // this.commonApiService.Upsert(Commonvariable.PersonService, this.empW1.empBasicInfoform.value, this.empW1.empBasicInfoform.value.Id).subscribe((result) => {
         //    if (result["data"]["id"] != undefined) {
         //       this.empW3.empJobDetailform.controls.Person_Id.setValue(result["data"]["id"]);

         //       this.commonApiService.Upsert(Commonvariable.EmployeeDetail, this.empW3.empUpsert(), 0).subscribe((response) => {
         //          if (response != null) {
         //             if (response["message"] == Commonvariable.DuplicationMsg) {
         //                this.toastr.warning('Record is already exists !', 'Employee');
         //             }
         //             else {

         //                if (result["data"]["id"] != undefined && this.empW1.empLogoChange) {
         //                   const formData = new FormData();
         //                   formData.append('empBasicInfo_Id', result["data"]["id"]);
         //                   formData.append('file', this.empW1.empLogo);
         //                   this.commonApiService.UploadLogo(Commonvariable.Person, "UploadImage", formData).subscribe((response) => {
         //                      this.uploadDocument(response["data"]["emp_Id"]);
         //                   });
         //                }
         //                else {
         //                   this.uploadDocument(response["data"]["emp_Id"]);
         //                }

         //             }
         //          }
         //       })

         //    }
         //    $(".employee").addClass("active");
         //    $(".employeeadd").removeClass("active");
         // })



      }
      else {

         if (this["empW" + this.activeWizard].validation()) {
            if (this.activeWizard == 1) {
               if (!this.initialFlag) {
                  var ids = "flatpickropenProEndD0,#flatpickropenNextRevD0,#flatpickropenEmpCEnD0,#flatpickropenEmpCStD0,#flatpickropenIssueD0,#flatpickropenExpiryD0,#ApprisalFrom0,#ApprisalTo0"
                  this.commonservice.flatpickr(ids, 0);
                  this.initialFlag = true
               }
            }
            if (this.activeWizard == 3) {
               var joiningDate = moment(this.empW3.empJobDetailform.controls['JoiningDate'].value).format("DD-MM-YYYY")
               this.empW8.probationList = this.empW3.ProbationList;
               this.empW8.companyid = this.empW3.companyid;
               this.empW9.companyid = this.empW3.companyid;
               this.empW9.jobtitlename = this.jobtitlename;
               this.empW9.joiningDate = joiningDate;
               this.empW9.contractlist = this.empW3.contractList;
               this.empW10.rtwCategoryList = this.empW3.righttoworkList;
               this.empW11.currency_Code = this.empW3.companyDetail ? this.empW3.companyDetail.currency_Code : this.empW3.companyDetail;
               this.jobtitleid =this.empW3.empJobDetailform.controls.Desig_Id.value;
               this.getJobtitle();
            }
            if (this.activeWizard == 4) {
               //this.empW5.getRelationshipList();
            }
            if (this.activeWizard == 5) {
               //this.empW6.getRelationshipList();
            }
            var tidbox = $('.nav-justified>li.done').last().index();
            if (tidbox + 1 > this.activeWizard) {
               this.activeWizard = tidbox + 1;
            }
            this.wizardSetting("next");
         }
      }

      // $('.nav-justified>li.done').removeClass('active');
   }



   uploadDocument(empid) {

      if (this.empW12.empUpsert() && parseInt(empid) > 0) {
         var documents = this.empW12.empform.get('emp_document').value;
         var formDatalength = documents.length;
         for (let index = 0; index < documents.length; index++) {
            const formData = new FormData();
            formData.append('Emp_Id', empid);
            formData.append('Doc_Id', !documents[index].is_CompanyDoc ? documents[index].Doc_Id : 0);
            formData.append('Emp_Doc_Name', !documents[index].is_CompanyDoc ? documents[index].Emp_Doc_Name : '');
            formData.append('files', documents[index].files);
            formData.append('Notes', !documents[index].is_CompanyDoc ? documents[index].Notes : '');
            formData.append('AddedBy', Commonvariable.User_Id);
            formData.append('isActive', documents[index].isActive ? "1" : "0");
            formData.append('Is_CompanyDoc', documents[index].is_CompanyDoc ? "1" : "0");

            this.commonApiService.UploadLogo(Commonvariable.EmpDocument, "Add", formData).subscribe((response) => {
               if (index == formDatalength - 1) {
                  this.toastr.success(Commonvariable.SaveMsg, 'Employee');
                  this.router.navigateByUrl('employee');
               }
            });

         }
      }
      if (this.empW6.empUpsert() && parseInt(empid) > 0) {
         var documents1 = this.empW6.empform.get('emp_ref').value;
         var formDatalength1 = documents1.length;
         for (let index = 0; index < documents1.length; index++) {
            const formData = new FormData();
            
       
            formData.append('Emp_Id', empid);
            formData.append('DateSent', documents1[index].DateSent ? documents1[index].DateSent : '');
            formData.append('DateOfRec', documents1[index].DateOfRec ? documents1[index].DateOfRec : '');
            formData.append('Notes', documents1[index].Notes ? documents1[index].Notes : '');
            formData.append('files', documents1[index].files);
            formData.append('Required', documents1[index].Required);
            formData.append('AddedBy', Commonvariable.User_Id);
            formData.append('isActive', documents1[index].isActive ? "1" : "0");

            this.commonApiService.UploadLogo(Commonvariable.EmpRefrence, "Add", formData).subscribe((response) => {
               if (index == formDatalength1 - 1) {
                  this.toastr.success(Commonvariable.SaveMsg, 'Employee');
                  this.router.navigateByUrl('employee');
               }
            });

         }
      }
      else {
         this.toastr.success(Commonvariable.SaveMsg, 'Employee');
         this.router.navigateByUrl('employee');
      }



   }

   wizardSetting(event) {
      if (event == "next") {
         if (this.activeWizard != 13) {
            if (this.activeWizard == 3) {
               this.activeWizard = 5;
               this.pbWidth = this.pbWidth + 8.33;
            } else {
               this.activeWizard++;
               this.pbWidth = this.pbWidth + 8.33;
            }
         }
         else {
            this.activeWizard;
            this.pbWidth = this.pbWidth;
         }
      }
      else {
         if (this.activeWizard == 5) {
            this.activeWizard = 3;
            this.pbWidth = this.pbWidth - 8.33;
         } else {
            this.activeWizard--;
            this.pbWidth = this.pbWidth - 8.33;
         }
      }
      for (let index = 1; index <= 13; index++) {
         this["w" + index] = index == this.activeWizard ? "active" : index < this.activeWizard ? "done" : "";
         this["t" + index] = index == this.activeWizard ? "tab-pane active" : "tab-pane";
      }
      this.progressBarWidth = this.pbWidth + "%"
      this.backbutton = this.activeWizard > 1 ? true : false
      this.personSearchbutton = this.activeWizard > 1 ? "hidden" : "visible"
      this.personSearchList = this.activeWizard > 1 ? "hidden" : "visible"
      this.btnName = this.activeWizard == 13 ? "Save" : "Continue"

   }


   opentab(id) {
      var movePermission = true;

      // if (this.activeWizard) {
      //    var ids = "flatpickropenProEndD0,#flatpickropenNextRevD0,#flatpickropenEmpCStD0,#flatpickropenEmpCEnD0,#flatpickropenIssueD0,#flatpickropenExpiryD0,#ApprisalFrom0,#ApprisalTo0"
      //    this.commonservice.flatpickr(ids, 0);
      // }

      if (this.activeWizard < id) {
         for (let index = 1; index < id; index++) {
            if (!this["empW" + index].validation()) {
               movePermission = false;
               break;
            }
         }
      }
      else {
         if (!this["empW" + this.activeWizard].validation()) {
            movePermission = false;
         }
      }

      if (movePermission) {
         if (this.activeWizard < id) {
            this.activeWizard = id - 1;
            this.pbWidth = 8.33 * this.activeWizard
            this.wizardSetting("next")
         }
         else {
            this.activeWizard = id + 1;
            this.pbWidth = 8.33 * this.activeWizard;
            this.wizardSetting("back")
         }
      }


   }


   advanceFilter() {
      this.searchPersonList = [];
      this.empW1.ngOnInit();
      this.empW2.ngOnInit();
      //this.empW4.ngOnInit();
      this.empW5.ngOnInit();
      this.empW6.ngOnInit();
      var data = {
         "SearchBy": this.advancedSearch.search()
      };
      //"SearchPerson"
      if (data.SearchBy) {
         this.commonApiService.getPaginated(Commonvariable.PersonService, null, null, null, null, null, data, "GetPaginatedByViewSummary").subscribe((res) => {
            if (res["data"] != undefined && res["data"] != "") {
               this.searchPersonList = res["data"];
               this.personSearchList = this.searchPersonList.length > 0 ? "visible" : "hidden"
            }
            $("#filterModal").modal("hide");
            this.advancedSearch.ngOnInit();
         })
      }

   }

   setPersonValue(id) {

      var person = this.searchPersonList.filter(x => x.id == id)[0]
      this.empW1.empEdit(id)
      this.empW2.edit(person["person_Address"]);
      //this.empW4.edit(person["person_Contact"]);
      this.empW5.edit(person["person_Emergency"]);
     // this.empW6.edit(person["person_Reference"]);

      // this.commonApiService.getById(Commonvariable.PersonService, id, "ViewSummary").subscribe((result) => {
      //    this.empW2.edit(result["data"][0]["person_Address"]);
      //    this.empW4.edit(result["data"][0]["person_Contact"]);
      //    this.empW5.edit(result["data"][0]["person_Emergency"]);
      //    this.empW6.edit(result["data"][0]["person_Reference"]);
      // })
   }

}


