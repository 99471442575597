import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;


@Component({
  selector: 'app-referenceCandidate',
  templateUrl: './reference-candidate.component.html'
})
export class RefCandidateComponent implements OnInit {
  candidateForm: FormGroup;
  emprefrelList: any;
  isEdit : boolean = false;
  countryList: Array<Select2OptionData>;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {

    this.isEdit = false;
    this.candidateForm = this.fb.group({
      id: ['0'],
      person_Id: ['0'],
      ref_Name: ['', Validators.required],
      ref_Email: [null],
      country_Id:[null],
      ref_ContactNo: ['', Validators.required],
      relationship: ['', Validators.required],
      isActive: ['1'],
      isReview: ['1'],
      updatedBy: [Commonvariable.User_Id]
    });
    this.getRelationshipList();
    this.getCountryList()
  }

  
  getCountryList() {

    var requestData = {
       "searchBy": [
          {
             "FieldName": "isActive",
             "FieldValue": "1",
             "Parameter": "=="
          }
       ],
       "OrderBy": "country_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Country, requestData).subscribe((response) => {
       if (response != null && response["data"] != "") {
          this.countryList = response["data"].map(x => ({
             id: x.id,
             text: x.country_Name + " (" + x.country_Code + ") "
          }));                    
          if(this.isEdit == false){
            this.candidateForm.controls.country_Id.setValue(response["data"].filter(x => x.isDefault == 1)[0]["id"]); 
          }
          
       }
    })
 }

  getRelationshipList() {
    this.commonApiService.List(Commonvariable.Relationship).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.emprefrelList = result["data"].sort((a, b) => a.name.localeCompare(b.name));
      }
    });
  }


  Edit(Id) {
    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.Person_Reference, Id).subscribe((response) => {
        if (response != null) {
          this.isEdit = true;
          this.getCountryList()
          var editData = response["data"];
          var formData = this.candidateForm;
          this.commonService.setEditData(formData, editData);
          formData.controls.updatedBy.setValue(Commonvariable.User_Id);
        }
      })
    }
  }


  validation() {
    var eForm = this.candidateForm;
    //sForm.get("company_Id").setValue($("#company_Id").val()); 
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);

    this.commonService.setNull(this.candidateForm)
    if (!eForm.valid) {
      for (const key of Object.keys(eForm.controls)) {
        if (eForm.controls[key].invalid) {
          let fieldName = "";

          if (key == "ref_Name") {
            fieldName = "Reference Name"
          }
          else if (key == "ref_ContactNo") {
            fieldName = "Reference contact number"
          }
          else if (key == "relationship") {
            fieldName = "Reference relationship"
          }
          this.toastr.info(fieldName + " is required !", "Candidate");
          $("#" + key).focus();
          return false;
        }
        
      }
    }
    else {

      if (eForm.value.country_Id == null) {
        this.toastr.info("Country is required  !", "Candidate");
        $("#country_Id select").focus();
        return false;
      }
      // if ($("#ref_Name").val().length < 2) {
      //   this.toastr.info("Reference name must be at least 2 characters long !", "Candidate");
      //   $("#ref_Name").focus();
      //   return false;
      // }
    //   if ($("#ref_Name" ).val().search(/^[^-\s][a-zA-Z_\s-]+$/) != 0) {
    //     this.toastr.info("Reference name valid Format !", "Candidate");
    //     $("#ref_Name").focus();
    //     return false;
    //  }
      if ( $("#ref_Email").val() != '' && $("#ref_Email").val().search(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/) != 0) {
        this.toastr.info("Enter valid email address  !", "Candidate");
        $("#ref_Email").focus();
        return false;
      }
      if ($("#ref_ContactNo").val().search(/[^0-9- +()]+/) > -1) {
        this.toastr.info(" Enter valid reference contact number!", "Candidate");
        $("#ref_ContactNo").focus();
        return false;
      }
      // if ($("#ref_ContactNo").val().length < 9) {
      //   this.toastr.info("Reference contact number must be at least 9 digit long !", "Candidate");
      //   $("#ref_ContactNo").focus();
      //   return false;
      // }
      if ($("#ref_Relationship").val() != "" && /["\\]/.test($("#ref_Relationship").val())) {
        this.toastr.info("Reference Relationship does not allow Special Characters \" \\ ", "Candidate");
        $("#ref_Relationship").focus();
        return false;
      }
      // if ($("#ref_ContactNo").val().indexOf(" ") > -1) {
      //   this.toastr.info("Reference contact number not allow space !", "Candidate");
      //   $("#ref_ContactNo").focus();
      //   return false;
      // }
      // if ($("#ref_Relationship").val().length < 2) {
      //   this.toastr.info("Reference relationship must be at least 2 characters long !", "Candidate");
      //   $("#ref_Relationship").focus();
      //   return false;
      // }
      // if ($("#ref_Relationship").val().indexOf(" ") > -1) {
      //   this.toastr.info("Reference relationship not allow space !", "Candidate");
      //   $("#ref_Relationship").focus();
      //   return false;
      // }
    }


    return true;
  }

  addRelationship(event) {
    if (event.target.value != "") {
      if (this.emprefrelList.filter(x => x.name == event.target.value).length == 0) {
        var data = {
          "Id": 0,
          "Name": event.target.value,
          "isActive": 1,
          "AddedBy": Commonvariable.User_Id
        }
        this.commonApiService.Upsert(Commonvariable.Relationship, data, 0).subscribe((result) => {
          this.getRelationshipList();
        });
      }
    }
  }




}
