import { Component, OnInit, asNativeElements, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { CommonService } from 'src/app/services/common.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { resource } from 'selenium-webdriver/http';

declare var $: any;

@Component({
  selector: 'app-auditlog',
  templateUrl: './auditlog.component.html'
})
export class AuditLogComponent implements OnInit {

  auditLogList: any;
  filterList: any;
  totalItems: 0;
  filterItems :0;
  p: number = 1;
  searchPage:number =0;

  constructor(
    private commonApiService: CommonApiService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.searchOneValue("onLoad", 0);
  }



  filterData(event) {
    const val = event.target.value.toLowerCase().trim();
    if (val == "") {
      this.auditLogList = this.filterList;
      this.totalItems =this.filterItems;
      this.p = this.searchPage;
    }
    else {
      
      
      const temp = this.filterList.filter(function (d) {

      return d["middleName"].toLowerCase().indexOf(val) !== -1 || d["type"].toLowerCase().indexOf(val) !== -1 ||
          d["firstName"].toLowerCase().indexOf(val) !== -1 || d["lastName"].toLowerCase().indexOf(val) !== -1 ||
          d["hostName"].toLowerCase().indexOf(val) !== -1 || d["ipAddress"].toLowerCase().indexOf(val) !== -1 || d["status"].toLowerCase().indexOf(val) !== -1 || d["description"].toLowerCase().indexOf(val) !== -1 || !val;

      });

      //const temp = this.commonService.filterData(event, this.auditLogList, "status");
      this.auditLogList = temp;
      this.totalItems = temp.length;
      this.p = 1;
    }
  }

  serverPagination(event) {
    this.searchOneValue("onLoad", event - 1);
    this.p = event;
    this.searchPage = event;
  }

  searchOneValue(action, pageIndex) {
  
    var apiCall = true;
    if (action == "filter" && ($("input[name='filter']:checked").val() == undefined || $("#filterValue").val() == "")) {
      apiCall = false;
    }
    else if(($("input[name='filter']:checked").val() != undefined && $("#filterValue").val() != ""))
    {
      action = "filter";
    }
    var fName = action == "filter" ? $("input[name='filter']:checked").val() : "1";
    var fValue = action == "filter" ? '"' + $("#filterValue").val() + '\"' : "1";
    var pageindex = pageIndex;
    var pageSize = 10;

    if (apiCall) {
      this.commonApiService.getBy(Commonvariable.AuditLog, fName, fValue, null, pageindex, pageSize, null).subscribe((result) => {
        if (result != null && result["status"] == 200) {
          this.totalItems = result["data"]["recordCount"];
          this.auditLogList = result["data"]["list"];
          if (action != "filter") {
            this.filterList = result["data"]["list"];
            this.filterItems = result["data"]["recordCount"];
          }
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Audit Log'); })
    }
    else {
      this.auditLogList = this.filterList;
    }
  }


  clear() {
    this.auditLogList = this.filterList;
    this.totalItems =this.filterItems;
    $(".md-check").prop("checked",false)
    $("#filterValue").val("");
  }


}
