<form [formGroup]="candidateForm">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Probation Period<span class="required"> * </span></label>
                <input type="hidden" id="company_Id" formControlName="company_Id" value="">
                <input type="hidden" id="joiningDate" formControlName="joiningDate" value="">                
                <input type="hidden" id="prob_Duration" formControlName="prob_Duration" value="">                
                <input type="hidden" id="prob_DurationUnit" formControlName="prob_DurationUnit" value="">                
                <!-- <input type="text" class="form-control" id="probationPeriod" formControlName="probationPeriod"
                    autocomplete="off" maxlength="100"> -->
                <select class="form-control" id="probationPeriod" formControlName="probationPeriod" (change)="onChangeProb($event.target.value)">
                    <option value="">Select Probation</option>
                    <option *ngFor="let item of probationList;" value="{{item.prob_Duration}} {{item.prob_DurationUnit}}">
                        {{item.prob_Duration}} {{item.prob_DurationUnit}}</option>
                </select>
            </div>
        </div> 
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Probation End Date<span class="required"> * </span></label>
                <div class="input-group" id="flatpickropenpEndD" data-wrap="true">
                    <input type="text" class="form-control readonlyClass" id="probationEndDate" data-input>
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>               
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Next Review Date</label>
                <div class="input-group" id="flatpickropenReviewD" data-wrap="true">
                    <input type="text" class="form-control readonlyClass" id="nextReviewDate" data-input>
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Status</label>                
                <select id="status" class="form-control" formControlName="status">
                    <option value="">Select Status</option>
                    <option value="On probation">On probation</option>
                    <option value="Probation frozen">Probation frozen</option>
                    <option value="Probation extended">Probation extended</option>
                    <option value="Passed probation">Passed probation</option>
                    <option value="Failed probation">Failed probation</option>
                </select>
            </div>
        </div>
        <div class="col-md-7">
            <div class="form-group">
                <label class="control-label">Notes</label>
                <textarea class="form-control" id="notes" formControlName="notes" rows="2"></textarea>
            </div>
        </div>
        <div class="col-md-1">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="isActive" class="md-check" formControlName="isActive">
                            <label for="isActive">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</form>