<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Menu
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a data-toggle="modal" (click)="openModel(0)" class="btn btn-transparent blue  btn-sm">Add
                            Menu</a>
                    </div>
                </li>
            </ul>
        </div>

        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" id="filterList" placeholder="Type to filter..."
                                autocomplete="off" (input)="getMenuList(1)">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblMenu">
                            <thead class="flip-content">
                                <tr>
                                    <th class="text-center"> Name </th>
                                    <th class="text-center" style="width:20%;">Url</th>
                                    <th class="text-center" style="width: 10%;">Icon</th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of menulist| paginate: { itemsPerPage: 10, currentPage: p ,totalItems :totalRecord}">
                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(item.menu_Id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.menu_Id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12">
                                            {{item.name}}
                                        </div>
                                    </td>
                                    <td style="width: 10%;">{{item.url}}</td>
                                    <td>{{item.icon}}</td>
                                    <td class="text-center">
                                        <a (click)="getModule(item.menu_Id)"><i
                                                class="fa fa-hand-o-up iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Module Permission"></i></a>&nbsp;
                                        <a (click)="openModel(item.menu_Id)"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Menu"></i></a>&nbsp;
                                        <!-- <a (click)="menuDelete(item.menu_Id)">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top" title="Delete Menu"></i></a> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="menulist!= null">
                        <pagination-controls (pageChange)="getMenuList($event)" autoHide="true"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->


<div class="modal fade draggable-modal" id="draggable" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Menu</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="menuform">
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Name<span class="required"> *</span></label>
                                    <input type="text" id="name" class="form-control" autocomplete="off"
                                        formControlName="name" maxlength="30">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">URL</label>
                                    <input type="text" id="url" class="form-control" autocomplete="off"
                                        formControlName="url" maxlength="30">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Icon</label>
                                    <input type="text" id="icon" class="form-control" autocomplete="off"
                                        formControlName="icon" maxlength="40">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Parent</label>
                                    <!-- <select id="parent_Id" class="form-control" formControlName="parent_Id">
                                        <option value="">--- Select ---</option>
                                        <option *ngFor="let item of parentList;" value={{item.menu_Id}}>
                                            {{item.name}}</option>
                                    </select> -->
                                    <ng-select2 [data]="parentList" id="parent_Id"
                                        (valueChanged)="setFormData('parent_Id',$event)"
                                        [value]="menuform.controls.parent_Id.value" [placeholder]="'--- Select ---'">
                                    </ng-select2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Sorting</label>
                                    <input type="text" id="sorting" class="form-control" autocomplete="off"
                                        formControlName="sorting"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"' value="0">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="control-label">Color Code</label>
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="checkbox1_2" class="md-check"
                                                formControlName="isColor_Code">
                                            <label for="checkbox1_2">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="control-label">Active</label>
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="checkbox1_3" class="md-check"
                                                formControlName="isActive">
                                            <label for="checkbox1_3">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="upsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade draggable-modal" id="modulePermission" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:2% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Module Permission</h4>
            </div>
            <div class="modal-body">



                <!-- <diV class="row">
                                <div class="col-md-9">
                                </div>
                                <div class="col-md-3 text-right">
                                    <input type="text" class="form-control" placeholder="Type to filter the zone name column..."
                                        (change)="filterData($event)">
                                </div>
                            </diV> -->
                <!-- <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label">Role</label>
                            <select id="roleid" class="form-control">
                                <option value="">Select Role</option>
                                <option *ngFor="let item of roleList;" value={{item.id}}>
                                    {{item.name}}</option>
                            </select>
                        </div>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-6">
                    </div>
                    <div class="col-md-6 text-right">
                        <input type="text" class="form-control" placeholder="Type to filter ..."
                            (input)="filterModulePermission($event.target.value)">
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th style="padding: 26px" class="text-center">Module</th>
                                <th class="text-center" style="width: 20%;padding: 12px">
                                    <div class="form-group">
                                        <label class="control-label col-md-8" style="padding: 9px">Permission</label>
                                        <div class="col-md-1" *ngIf="this.moduleList != null" style="padding: 5px">
                                            <div class="md-checkbox-inline">
                                                <div class="md-checkbox">
                                                    <input type="checkbox" id="checkall" (click)="checkBox(0,true)"
                                                        class="md-check">
                                                    <label for="checkall">
                                                        <span></span>
                                                        <span class="check"></span>
                                                        <span class="box"></span> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let item of moduleList| paginate: { itemsPerPage: 10, currentPage: m ,id: 'pagination2' }">
                                <td style="padding: 17px"> {{item.moduleName}}
                                </td>
                                <td class="text-center" colspan="2">
                                    <div class="form-group">
                                        <div class="md-checkbox-inline">
                                            <div class="md-checkbox">
                                                <input type="checkbox" id="permission{{item.id}}" class="md-check"
                                                    [checked]="item.permission" (click)="checkBox(item.id,false)">
                                                <label for="permission{{item.id}}">
                                                    <span></span>
                                                    <span class="check"></span>
                                                    <span class="box"></span> </label>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center">
                    <pagination-controls (pageChange)="m = $event" id="pagination2" autoHide="true">
                    </pagination-controls>
                </div>


            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" class="btn blue" (click)="upsertModulePermission()">Save</button>
            </div>
        </div>
    </div>
</div>