import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import Swal from 'sweetalert2';
import { templateJitUrl } from '@angular/compiler';
declare var $: any;


@Component({
  selector: 'app-documenttype',
  templateUrl: './documenttype.component.html'
})
export class DocumentTypeComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  documenttypeform: FormGroup;
  //getDocumentTypeList: any;
  DocumentTypeList: any[] =[];
  filterDocumentType: any;
  listCall: boolean = true;
  companylist: any;
  title: string;
  Toast: any;
  p: number = 1;
  cities: city[] = [];
  totalRecord: 0;


  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) { }



  ngOnInit() {
    this.title = "Add";
    this.documenttypeform = this.fb.group({
      id: ['0'],
      name: ['', Validators.required],
      fileType: [null],
      category: [null],
      notes: [''],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: ['0']
    });

    if (this.listCall) {
      this.getDocumentTypeList();
    }

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }

  getDocumentTypeList(PageIndex = 1) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.DocumentTypeList = [];
    this.filterDocumentType = [];
    this.commonApiService.getListWithFilter(Commonvariable.Document, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.DocumentTypeList = result["data"]["list"];
        this.filterDocumentType = result["data"]["list"];
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);
      }
    })

  }


  documentTypeUpsert() {
    var documenttypeForm = this.documenttypeform;
    if (this.validation()) {
      documenttypeForm.get("isActive").setValue(documenttypeForm.get("isActive").value ? 1 : 0);
      var Name = documenttypeForm.get("name").value;
      documenttypeForm.get("name").setValue(Name.trim());
      this.commonApiService.Upsert(Commonvariable.Document, documenttypeForm.value, documenttypeForm.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Document Type');
          }
          else {
            $("#draggable").modal('hide');
            this.getDocumentTypeList();
            this.toastr.success(documenttypeForm.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Document Type');
          }
        }
      })
    }
  }

  documentTypeDelete(documenttypeId) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Document, documenttypeId).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getDocumentTypeList();
          this.toastr.success(response["message"], 'Document Type');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Document, Id, status).subscribe((response) => {
          
          if (response["status"] == 200) {
            this.getDocumentTypeList();
            this.toastr.success(response["message"], 'Document Type');                        
          }

        })
      }

    });
  }

  openModel(documenttypeId) {

    if (documenttypeId > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Document, documenttypeId).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var formData = this.documenttypeform;
          this.commonService.setEditData(formData, editData);
          formData.controls.updatedBy.setValue(Commonvariable.User_Id)
        }
      })
    }
    else {
      // $("#zone_Code").removeAttr("disabled")
      this.listCall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });

  }

 


  validation() {
    var documentTypeForm = this.documenttypeform;
    if (!documentTypeForm.valid) {

      for (const key of Object.keys(documentTypeForm.controls)) {
        if (documentTypeForm.controls[key].invalid) {
          let fieldName = "";
          if (key == "name") {
            fieldName = "Document Name"
          }
          this.toastr.info(fieldName + " is required !", "Document Type");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if ($("#name").val().length < 1) {
        this.toastr.info("Document Name must be at least 1 characters long !", "Document Type");
        $("#name").focus();
        return false;
      }
      if (/["\\]/.test($("#name").val())) {
        this.toastr.info("name does not allow Special Characters \" \\ ", "Document Type");
        $("#name").focus();
        return false;
      }
      if (/["\\]/.test($("#notes").val())) {
        this.toastr.info("notes does not allow Special Characters \" \\ ", "Document Type");
        $("#notes").focus();
        return false;
      }
      // if ($("#name").val().search(/[^a-zA-Z ]+/) > -1) {
      //   this.toastr.info("Document Name only alphabets and numeric allow !", "Document Type");
      //   $("#name").focus();
      //   return false;
      // }

    }
    return true;
  }

  history() {
    this.historyView.getHistory("document",this.documenttypeform.controls.id.value)
  }




}

interface city {
  id: number;
  text: string;
}
