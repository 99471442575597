<div class="modal fade draggable-modal in" id="draggable" role="basic" aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="closeModal()" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Job Title</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="designationform">
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Company<span class="required"> * </span></label>
                                    <ng-select2 [data]="companylist" id="company_Id" formControlName="company_Id" (ngModelChange)="getDepartmentList()" [placeholder]="'--- Select ---'">
                                    </ng-select2>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Department<span class="required"> *
                                        </span></label>

                                    <ng-select2 [data]="departmentlist" id="dept_Id" formControlName="dept_Id" (ngModelChange)="getEmployeeList()" [placeholder]="'--- Select ---'">
                                    </ng-select2>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Zone
                                        <!-- <span class="required"> * </span> -->
                                    </label>
                                    <ng-select2 [data]="zonelist" id="zone_Id" formControlName="zone_Id" [placeholder]="'--- Select ---'"></ng-select2>

                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Job Code<span class="required">
                                            *</span></label>
                                    <input type="text" class="form-control" id="desig_Code" formControlName="desig_Code"
                                        maxlength="30">
                                </div>
                            </div> -->
                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Job Title<span class="required"> *
                                        </span></label>
                                    <input type="text" class="form-control" id="desig_Name" formControlName="desig_Name" maxlength="50">
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Report To</label>
                                    <ng-select2 [data]="employeelist" id="report_To" formControlName="report_To" [placeholder]="'--- Select ---'"></ng-select2>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Additional Report To</label>
                                    <ng-select2 [data]="employeelist" id="additionalReport_To" formControlName="additionalReport_To" [placeholder]="'--- Select ---'">
                                    </ng-select2>
                                </div>
                            </div>
                        </div>
                        <div class="row">



                        </div>
                        <div class="row">
                            <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Level<span class="required"> *</span></label>
                                    <input type="number" class="form-control" id="level" formControlName="level" min="0"
                                        max="999" min="0">
                                </div>
                            </div> -->

                            <div class="col-md-2">
                                <div class="md-checkbox-inline">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="checkbox1_3" class="md-check" formControlName="isActive">
                                        <label for="checkbox1_3">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span>Active </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="md-checkbox-inline">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="mandatory_RAF_Approval" class="md-check" formControlName="mandatory_RAF_Approval">
                                        <label for="mandatory_RAF_Approval">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span>Compulsory Approver</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn  dark btn-outline pull-left" (click)="history()" *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button>
                <button type="button" (click)="closeModal()" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="designationUpsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Job Title History</h4>
            </div>
            <div class="modal-body" *ngIf="title == 'Edit'">
                <app-history></app-history>
            </div>
        </div>
    </div>
</div>