<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Document Type list
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a data-toggle="modal" (click)="openModel(0)" class="btn btn-transparent blue  btn-sm">Add
                            Document Type</a>
                    </div>
                </li>
            </ul>
        </div>

        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" placeholder="Type to filter..." id="filterList"
                                (input)="getDocumentTypeList()">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover">
                            <thead class="flip-content">
                                <tr>
                                    <th style="width: 25%;" class="text-center"> Name </th>
                                    <th class="text-center" style="width: 50%;">Notes</th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let typeList of DocumentTypeList| paginate: { itemsPerPage: 10, currentPage: p ,totalItems :totalRecord }">

                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(typeList.id,'0')">
                                            <div class="circle greenColor" *ngIf="typeList.isActive" data-toggle="tooltip"
                                                data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(typeList.id,'1')">
                                            <div class="circle redColor" *ngIf="!typeList.isActive" data-toggle="tooltip"
                                                data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12">{{typeList.name}}
                                        </div>
                                    </td>
                                    <td>
                                        <!-- {{ (typeList.notes.length > 50)? (typeList.notes | slice:0:50)+'...':(typeList.notes) }} -->
                                        {{ typeList.notes }}
                                    </td>
                                    <td class="text-center">
                                        <a *ngIf="typeList.isActive" href="javascript:;" (click)="openModel(typeList.id)"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Document Type"></i></a>
                                        <a *ngIf="!typeList.isActive" href="javascript:;" (click)="documentTypeDelete(typeList.id)">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top" title="Delete Document Type"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="DocumentTypeList.length > 0">
                        <pagination-controls (pageChange)="getDocumentTypeList($event) " autoHide="true"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->


<div class="modal fade draggable-modal" id="draggable" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Document Type</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="documenttypeform">
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Name<span class="required"> *</span></label>
                                    <input type="text" id="name" class="form-control" autocomplete="off" maxlength="50"
                                        formControlName="name">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Notes</label>
                                    <textarea id="notes" class="form-control" rows="2"
                                        formControlName="notes"></textarea>
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">File Type<span class="required"> *
                                        </span></label>
                                    <input type="text" id="documenttype_FileType" class="form-control"
                                        autocomplete="off" formControlName="documenttype_FileType">
                                </div>
                            </div> -->
                        </div>
                        <!-- <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Category<span class="required"> *
                                        </span></label>
                                    <input type="text" id="documenttype_Category" class="form-control"
                                        autocomplete="off" formControlName="documenttype_Category" maxlength="30">
                                </div>
                            </div> 
                           
                        </div> -->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Active</label>
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="checkbox1_3" class="md-check"
                                                formControlName="isActive">
                                            <label for="checkbox1_3">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn  dark btn-outline pull-left" (click)="history()"
                    *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="documentTypeUpsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Document Type History</h4>
            </div>
            <div class="modal-body" *ngIf="title == 'Edit'">
                <app-history></app-history>
            </div>
      
        </div>
    </div>
</div>