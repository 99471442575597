import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { ScheduleInterviewCandidateComponent } from "../scheduleInterviewforcandidate/scheduleInterviewforcandidate.component";
import { InterviewFeedbackComponent } from "../interviewFeedback/interviewFeedback.component";
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Select2OptionData } from 'ng-select2';
declare var $: any;


@Component({
  selector: 'app-selection-candidate-wise',
  templateUrl: './selection-candidate-wise.component.html'
})
export class SelectionCandidateWiseComponent implements OnInit {
  @ViewChild(ScheduleInterviewCandidateComponent, { static: false }) ScheduleInterview: ScheduleInterviewCandidateComponent;
  @ViewChild(InterviewFeedbackComponent, { static: false }) InterviewFeedback: InterviewFeedbackComponent;

  isFinal: boolean = false;
  p1: number = 1;
  m1: number = 1;
  n: number = 1;

  totalRecord1: 0;
  candidateList: any[];
  reportingEmpList: any;
  selectionCanndidateList: any[];
  filterSelectionCanndidateList: any[];
  fileUrl: any;
  filename: string = "Candidate.pdf";
  candidate_Id = 0;
  vacancy_Id = 0;
  company_Id: any;
  files: string;
  candidateWiseRAF: any[];
  jobCategorArr:any;

  documentName = "Attach File";
  docFiles: any[];
  roleList: Array<Select2OptionData>;
  roleDisplay: boolean = true
  roleId: any;
  options: any;
  file: any;
  CandidateWisesalaryPlaceHolder: any;
  jd_Name: string;
  salaryPlaceHolder: any;
  salaryType = ""
  currency_Code = "";
  candidateVacancyList: any[] = []

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.getCandidateWiseList(1)
    this.getRole()
    this.options = {
      multiple: true,
    };

    $('#candidatewiseemailsend').summernote();

  }

  getCandidateWiseList(PageIndex) {

    this.totalRecord1 = 0;
    this.p1 = PageIndex;
    this.candidateList = [];
    this.commonApiService.getListWithFilter(Commonvariable.CandidateService, PageIndex, $("#candfilterList").val(), "CadidateWiseGetPaginated").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord1 = result["data"]["recordCount"]
        this.candidateList = result["data"]["list"];
        

        
        
        this.candidateList.forEach(x => {
          x.job_Category = x.job_Category != null ? x.job_Category.replace(/[~]+/g, ", ") : x.job_Category
          x.skills = x.skills != null ? x.skills.replace(/[~]+/g, ", ") : x.skills
          x.job_Level = x.job_Level != null ? x.job_Level.replace(/[~]+/g, ", ") : x.job_Level
        });
      }
    })
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }
  getRole() {
    this.commonApiService.List(Commonvariable.Roles).subscribe((response) => {
      if (response["data"] != "") {
        this.roleList = response["data"].map(x => ({
          id: x.id,
          text: x.name
        }));
      }
    })
  }


  fileChange(event) {
    this.documentName = "";
    this.docFiles = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.documentName += this.documentName == "" ? event.target.files[i].name : ", " + event.target.files[i].name;
      this.docFiles.push(event.target.files[i]);
    }
  }

  setData(id) {
    this.roleId = id
  }

  validation() {
    if ($("#to").val() == "") {

      this.commonService.toaster("info", "Mail to is required !", "SendMail");
      $("#to").focus();
      return false;
    }


    return true
  }


  openModel(id) {
    this.m1 = 1;
    this.candidate_Id = id;

    var requestData = {
      "searchBy": [
        {
          "FieldName": "candidate_Id",
          "FieldValue": id,
          "Parameter": "=="
        }
      ],
    }

    this.commonApiService.getPaginated(Commonvariable.VacancyService, null, null, null, null, 0, requestData, 'RAFWiseSelectedGetPaginated').subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.selectionCanndidateList = result["data"]["list"];
        this.filterSelectionCanndidateList = result["data"]["list"];
      }
    })

    $("#filterListbyCandidate").val("")

    $('#modalbycandiadte').modal({
      backdrop: 'static'
    });

  }

  updateSelectCandidate(id) {
    this.selectionCanndidateList[this.selectionCanndidateList.findIndex(x => x.id == id)].selectedRaf = $("#Candidate" + id).prop("checked") ? 1 : 0
    this.filterSelectionCanndidateList[this.filterSelectionCanndidateList.findIndex(x => x.id == id)].selectedRaf = $("#Candidate" + id).prop("checked") ? 1 : 0
  }


  downloadCV(id, path) {
    this.filename = "cv" + "." + String(path).split('.')[1]
    this.commonApiService.getimage(Commonvariable.JobSeeker, id, 'DownloadCV').subscribe((result) => {
      const blobData = new Blob([result], { type: result.type });
      var createObjectURL = (window.URL.createObjectURL(blobData));
      this.file = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
      //this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blobData));

      if (result.type == "application/pdf" || result.type == "image/jpeg" || result.type == "image/png") {

        $('#selectionCandidateWiseOpenFile').modal({
          backdrop: 'static'
        });

      } else if (result.type == "application/vnd.ms-word") {
        const a = document.createElement('a');
        a.setAttribute('type', 'hidden');
        a.href = URL.createObjectURL(blobData);
        a.download = 'documents.docx';
        a.click();
        a.remove();
      } else {

        const a = document.createElement('a');
        a.setAttribute('type', 'hidden');
        a.href = URL.createObjectURL(blobData);
        a.download = 'documents';
        a.click();
        a.remove();

      }
    })
  }

  async UpsertInterviewDetailForFeedback(status) {
    var requestData: any;
    if (status == 'Selected') {
      if ($("#candidateWiseSalary").val() == "" || $("#candidateWiseSalary").val() == undefined) {
        this.commonService.toaster("info", "Salary is required !", "Interview Feedback")
        $("#candidateWiseSalary").focus();
        return false;
      }
      // if ($("#candidateWiseSalaryType").val() == "" || $("#candidateWiseSalaryType").val() == undefined) {
      //   this.commonService.toaster("info", "SalaryType is required !", "Interview Feedback")
      //   $("#candidateWiseSalaryType").focus();
      //   return false;
      // }
      if ($("#Reporting_Id").val() == "" || $("#Reporting_Id").val() == undefined) {
        this.commonService.toaster("info", "Reporting is required !", "Interview Feedback")
        $("#Reporting_Id").focus();
        return false;
      }
      if ($("#candidateWiseJoiningDate").val() == "" || $("#candidateWiseJoiningDate").val() == undefined) {
        this.commonService.toaster("info", "Joining date is required !", "Interview Feedback")
        $("#candidateWiseJoiningDate").focus();
        return false;
      }
      requestData = this.InterviewFeedback.upsert(this.candidate_Id, this.vacancy_Id, status, $("#candidateWiseSalary").val(), $("#Reporting_Id").val(), this.commonService.dateFormatChange($("#candidateWiseJoiningDate").val()))
    }
    else {
      requestData = this.InterviewFeedback.upsert(this.candidate_Id, this.vacancy_Id, status)
    }

    if (requestData != null) {
      await this.commonApiService.Upsert("Candidate_Interviewer", requestData, this.candidate_Id, '/EditMultiple').subscribe((result) => {
        if (result != null) {
          $("#modalfeedbackinterview").modal('hide');
          this.commonService.toaster("success", Commonvariable.SaveMsg, "Interview")
        }
      })
    }
    this.getCandidateWiseList(1)
  }

  filterData(event) {
    const val = event.target.value.toLowerCase().trim();
    console.log(val)
    if (val == "") {
      this.selectionCanndidateList = this.filterSelectionCanndidateList;
    }
    else {
      const temp = this.filterSelectionCanndidateList.filter(function (d) {
        return String(d["desig_Name"]).toLowerCase().indexOf(val) !== -1 || String(d["noOfPositions"]).toLowerCase().indexOf(val) !== -1
          || String(d["appplicant"]).toLowerCase().indexOf(val) !== -1 || String(d["selected"]).toLowerCase().indexOf(val) !== -1
          || !val;
      });
      this.selectionCanndidateList = temp;
      this.m1 = 1;
    }
    this.m1 = 1;
  }


  showIsFinal() {
    this.isFinal = !this.isFinal;

    setTimeout(() => {
      this.commonService.flatpickr('candidateWiseJoiningDates', 0)
    }, 8);
  }

  Addrecord() {

    let postRecord = {
      "Candidate_Id": this.candidate_Id,
      "Vacancy_Id": this.filterSelectionCanndidateList.filter(x => x.selectedRaf == 1).map(x => x.id),
      "AddedBy": Commonvariable.User_Id,
    }

    this.commonApiService.getPaginated(Commonvariable.Candidate_Vacancy_Link, null, null, null, null, 0, postRecord, "Insert_MultipleVacancy").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.getCandidateWiseList(1)
        this.commonService.toaster("success", Commonvariable.SaveMsg, "Raf-wise");
        $('#modalbycandiadte').modal("hide")
      }
    })

  }

  getCanidateWiseRAF(id) {
    var requestData = {
      "searchBy": [
        {
          "FieldName": "candidate_Id",
          "FieldValue": id,
          "Parameter": "=="
        }
      ],
    }
    this.commonApiService.getPaginated(Commonvariable.Candidate_Vacancy_Link, null, null, null, null, 0, requestData, "GetCandidateVacancyLinkedData").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.candidateWiseRAF = result["data"]
      }
    })
  }

  expandTable(id) {
    this.candidateWiseRAF = [];
    var visibility = $(".sub_tbl_" + id).toggle().is(":visible");
    $(".sub_tbl").hide();
    $(".details-control").css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    if (visibility === true) {
      $(".sub_tbl_" + id).show();
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/d4ICC.png) no-repeat center center");
      this.getCanidateWiseRAF(id);
      this.n = 1
    } else {
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    }

  }


  async UpdateStatus(status, candidate_Id = 0, vacancy_Id = 0) {

    this.candidate_Id = candidate_Id != 0 ? candidate_Id : this.candidate_Id;
    this.vacancy_Id = vacancy_Id != 0 ? vacancy_Id : this.vacancy_Id;

    if (status == "Offered") {
      this.commonApiService.getById(Commonvariable.VacancyService, vacancy_Id, "OpenVacancyCount").subscribe((result) => {
        if (result["data"] > 0) {
          this.commonService.dyanmicConfirm("You Want To Change Status Of Candidate !", "Yes !").then((result) => {
            if (result.value) {
              let ids = this.candidate_Id + "," + this.vacancy_Id + "," + status
              this.commonApiService.Put(Commonvariable.Candidate_Vacancy_Link, ids, "ChangeStatus").subscribe((response) => {
                if (response != null) {
                  this.getCandidateWiseList(1)
                  this.commonService.toaster("success", response["message"], "Interview")
                }
              })
            }
          })
        }
        else {
          this.commonService.toaster("info", "All open vacancies have been filled !", "Interview")
        }
      })
    }
    else {
      this.commonService.dyanmicConfirm("You Want To Change Status Of Candidate !", "Yes !").then((result) => {
        if (result.value) {
          let ids = this.candidate_Id + "," + this.vacancy_Id + "," + status
          this.commonApiService.Put(Commonvariable.Candidate_Vacancy_Link, ids, "ChangeStatus").subscribe((response) => {
            if (response != null) {
              this.getCandidateWiseList(1)
              this.commonService.toaster("success", response["message"], "Interview")
            }
          })
        }
      })
    }

  }

  async moveToOnboarding(candidate_Id, vacancy_Id) {

    if (candidate_Id > 0) {
      this.commonService.dyanmicConfirm("You Want This Candidate Move To Onboarding !", "Yes !").then((result) => {
        if (result.value) {
          this.commonApiService.PostRequest(Commonvariable.CandidateService, "MoveToOnboarding/" + candidate_Id + "," + vacancy_Id, {}).subscribe((response) => {
            if (response != null) {
              this.getCandidateWiseList(1)
              this.commonService.toaster("success", response["message"], "Interview")
            }
          })
        }
      })
    }

  }

  openInterviewModel(Id, vId, company_id) {
    this.candidate_Id = Id;
    this.vacancy_Id = vId;
    if (company_id > 0) {
      $('#modalcandidatescheduleinterview').modal({
        backdrop: 'static'
      });
      this.getInterviews();
      this.ScheduleInterview.getEmpList(company_id)


    }
    else {
      this.commonService.toaster("success", "Company Not Available", "Interview")
    }

  }

  async UpsertInterviewDetail(status) {
    var requestData = this.ScheduleInterview.upsert(this.candidate_Id, this.vacancy_Id, status)
    if (requestData != null) {
      await this.commonApiService.Upsert("Candidate_Interviewer", requestData, this.candidate_Id, '/EditMultiple').subscribe((result) => {
        if (result != null) {
          this.getCandidateWiseList(1)
          this.GetMail();
          $("#modalcandidatescheduleinterview").modal('hide');

          this.commonService.toaster("success", Commonvariable.SaveMsg, "Interview")
        }
      })
    }

  }

  getInterviews() {
    this.ScheduleInterview.ngOnInit();
    if (this.candidate_Id > 0) {
      this.commonApiService.getById(Commonvariable.Candidate_Interviewer, this.candidate_Id + "," + this.vacancy_Id, "GetInterviewSchedule").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.ScheduleInterview.fillInterviewer(result["data"])
        }
        else {
          this.ScheduleInterview.createItem();
        }
      })
    }
  }
  getInterviewsForFeedback() {
    if (this.candidate_Id > 0) {
      this.commonApiService.getById(Commonvariable.Candidate_Interviewer, this.candidate_Id + "," + this.vacancy_Id, "GetInterviewSchedule").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.InterviewFeedback.fillInterviewer(result["data"]);
          this.CandidateWisesalaryPlaceHolder = result["data"][0][0]["salaryFrom"] + " - " + result["data"][0][0]["salaryTo"];
          this.currency_Code =  result["data"][0][0]["currency_Code"] ;
        }
        else {
          this.InterviewFeedback.createItem();
        }
      })
    }
  }
  openInterviewFeedbackModel(Id, vId, comany_Id, dept_Id, desig_Id) {

    $(".isFinalBody").hide();

    this.isFinal = false;
    this.candidate_Id = Id;
    this.vacancy_Id = vId;
    if (comany_Id > 0) {

      let reportingParameter = "Reporting" + "," + comany_Id + "," + dept_Id + "," + desig_Id + "," + 0
      this.commonApiService.getById(Commonvariable.EmployeeDetail, reportingParameter, "GetEmployeeByAccessibility").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.reportingEmpList = result["data"];
        }
      })

      this.InterviewFeedback.getEmpList(comany_Id);

      this.getInterviewsForFeedback();
      $('#modalfeedbackinterview').modal({
        backdrop: 'static'
      });
    }
    else {
      this.commonService.toaster("info", "Company Not Available", "Interview")
    }

  }
  GetMail(candidate_Id = 0, Vacancy_Id = 0) {
    if (candidate_Id > 0) {
      this.candidate_Id = candidate_Id;
    }
    if (Vacancy_Id > 0) {
      this.vacancy_Id = Vacancy_Id;
    }

    this.commonApiService.getByIdTwoParam(Commonvariable.Candidate_Interviewer, this.candidate_Id, this.vacancy_Id, "GetSendMailData").subscribe((response) => {
      if (response["data"] != "") {
        this.documentName = "";
        this.docFiles = [];
        this.company_Id = response["data"]["company_Id"];
        
        this.files = response["data"]["jD_Path"] == null || response["data"]["jD_Path"] == "" ? '' : response["data"]["jD_Path"];
        this.jd_Name=response["data"]["jD_Name"]
        $('#candSendMail').modal({
          backdrop: 'static'
        });
        this.roleId = null
        setTimeout(() => {
          $("#candidatewiseto").val(response["data"]["to"])
          $("#candidatewisecc").val(response["data"]["cc"])
          $("#candidatewisebcc").val(response["data"]["bcc"])
          $("#candidatewisesubject").val(response["data"]["subject"])
          $('#candidatewiseemailsend').summernote('code', response["data"]["body"]);
        }, 500);

      }
    })

  }

  sendMail() {

    // var postData = ({
    //   To: $("#candidatewiseto").val(),
    //   CC: $("#candidatewisecc").val(),
    //   BCC: $("#candidatewisebcc").val(),
    //   Subject: $("#candidatewisesubject").val(),
    //   Body: $('#candidatewiseemailsend').summernote('code'),
    //   file: this.files,
    //   Role_Ids: this.roleId != "undefined" ? this.roleId : null,
    //   Company_Id: this.company_Id
    // });

    // this.commonApiService.PostRequest(Commonvariable.Candidate_Interviewer, "SendMail", postData).subscribe((response) => {
    //   if (response["status"] == 200) {
    //     $("#candSendMail").modal('hide');
    //     this.commonService.toaster("success", response["message"], "Interview")
    //   }
    // })
    const formData = new FormData();
    formData.append('To', $("#candidatewiseto").val());
    formData.append('CC', $("#candidatewisecc").val());
    formData.append('BCC', $("#candidatewisebcc").val());
    formData.append('Subject', $("#candidatewisesubject").val());
    formData.append('Role_Ids', this.roleId != undefined ? String(this.roleId) : null);
    formData.append('Body', $('#candidatewiseemailsend').summernote('code'));
    formData.append('Company_Id', this.company_Id);
    formData.append('JD_Path', this.files);
    formData.append('JD_Name', this.jd_Name);
    if (this.docFiles != undefined) {
      for (var i = 0; i < this.docFiles.length; i++) {
        formData.append('file', this.docFiles[i]);
      }
    }


    this.commonApiService.UploadLogo(Commonvariable.Candidate_Interviewer, "SendMailWithFileObj", formData).subscribe((response) => {
      if (response["status"] == 200) {
        $("#candSendMail").modal('hide');
        this.commonService.toaster("success", response["message"], "Interview")
      }
    })

    this.getCandidateWiseList(1)
  }


  
  directOpenMoveToOnboarding(id,vid) {

    this.candidateVacancyList = [];

    this.candidate_Id = id;
    this.reportingEmpList = [];

    this.commonApiService.getById(Commonvariable.Candidate_Vacancy_Link, id, "GetCan_Vac_LinkByCandidate_Id").subscribe((res) => {
      if (res) {
        this.candidateVacancyList = res["data"];
        let item = this.candidateVacancyList.filter(x => x.vacancy_Id == vid)
        if (item.length > 0) {
          item = item[0];
          this.getReportingEmpList(item["company_Id"], item["dept_Id"], item["desig_Id"], item["salaryFrom"], item["salaryTo"], item["currency_Code"], item["salaryType"], item["vacancy_Id"])
        }
      }
    })
    this.currency_Code = "";
    this.salaryPlaceHolder = "0";
    this.salaryType = "";
    this.vacancy_Id = vid;
    
    $("#modalcandidateMovetoOnboarding").modal("show");
    this.commonService.flatpickr('candidateSelectionJoiningDates', 0)
    $("#candidateSelectionSalary").val("");
    $("#candidateSelectionJoiningDate").val("");
    $("#candidateSelectionReporting").val("");
  }

  
  getReportingEmpList(company_Id, dept_Id, desig_Id, salaryFrom, salaryTo, currency_Code, salaryType, vacancy_Id) {

    this.salaryPlaceHolder = salaryFrom + "-" + salaryTo;
    this.currency_Code = currency_Code;
    this.salaryType = salaryType;
    this.vacancy_Id = vacancy_Id;

    let reportingParameter = "Reporting" + "," + company_Id + "," + dept_Id + "," + desig_Id + "," + 0
    this.commonApiService.getById(Commonvariable.EmployeeDetail, reportingParameter, "GetEmployeeByAccessibility").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.reportingEmpList = result["data"];
      }
    })
  }

  
  directMoveToOnboarding() {

    if (this.validationmto()) {
      let postRequest = {
        "candidate_Id": this.candidate_Id,
        "vacancy_Id": this.vacancy_Id,
        "Salary": $("#candidateSelectionSalary").val(),
        "SalaryType": this.salaryType,
        "Reporting_Id": $("#candidateSelectionReporting").val(),
        "JoiningDate":  this.commonService.dateFormatChange($("#candidateSelectionJoiningDate").val())
      }

      this.commonApiService.PostRequest(Commonvariable.CandidateService, "DirectMoveToOnboarding", postRequest).subscribe((res) => {
        if (res) {
          this.commonService.toaster("success", "Move to onboarding successfully ", "Interview");
          this.getCandidateWiseList(1)
          $(".modal").modal("hide");
        }
      })
    }

  }

  validationmto() {

    if (this.currency_Code == "" || this.salaryType == "" && this.vacancy_Id == 0 ) {
      this.commonService.toaster("info", "Select Vacancy ", "Interview");
      return false;
    }

    // if ($("#candidateSelectionSalary").val() == "") {
    //   this.commonService.toaster("info", "Salary is required ", "Candidate");
    //   $("#candidateSelectionSalary").focus();
    //   return false;
    // }

    // if ($("#candidateSelectionReporting").val() == "") {
    //   this.commonService.toaster("info", "Reporting is required ", "Candidate");
    //   $("#candidateSelectionReporting").focus();
    //   return false;
    // }

    // if ($("#candidateSelectionJoiningDate").val() == "") {
    //   this.commonService.toaster("info", "Joining date is required ", "Candidate");
    //   $("#candidateSelectionJoiningDate").focus();
    //   return false;
    // }

    return true;
  }



}
