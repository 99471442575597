import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
   selector: 'app-user-profile',
   templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
   
   employeeId:any;
   employeeSummary :any;
   employeeAddress:any;
   employeeBasicInfo:any;
   employeeContract:any;
   employeeEmergency:any;
   employeeReference:any;
   employeeBank:any;
   employeeProbation:any;
   employeeContact:any;
   employeeRighttowork:any;
   empLeaveBalance :any;
   employeeSalary:any;
   ethnicity_Name:any;
   empDocuments:any;
   constructor(private commonApiService: CommonApiService,
      private toastr: ToastrService,
      private commonService: CommonService,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {
      

   }

   getSummary(id) {
      this.employeeId =id;
      this.commonApiService.getById(Commonvariable.EmployeeDetail, id, "ViewSummary").subscribe((response) => {
         if (response != null) {
            this.employeeSummary = response["data"][0];            
// console.log(this.employeeSummary)

            // if (this.employeeBasicInfo.ethnicity_Code != null && this.employeeBasicInfo.ethnicity_Code !="") {
            //    this.commonApiService.getById("Ethinicity", this.employeeBasicInfo.ethnicity_Code).subscribe((response) => {
            //       if (response != null) {
            //          this.ethnicity_Name = response["data"][0].ethnicity_Name;
            //       }
            //    })
            // }
            this.employeeContract=this.employeeSummary["emp_contract"];   
            this.employeeBank=this.employeeSummary["emp_bank"];
            this.employeeProbation=this.employeeSummary["emp_probation"];
            this.employeeRighttowork=this.employeeSummary["emp_righttowork"];
            this.empLeaveBalance = this.employeeSummary["emp_leave_balance"];
            this.employeeSalary=this.employeeSummary["emp_salary"];
            this.empDocuments = this.employeeSummary["emp_document"];

            if (response["data"][0]["person_Id"] != null) {
               this.commonApiService.getById(Commonvariable.PersonService, response["data"][0]["person_Id"], "ViewSummary").subscribe((result) => {
                  this.employeeBasicInfo = result["data"][0];
                  this.employeeAddress = this.employeeBasicInfo["person_Address"];
                  this.employeeContact = this.employeeBasicInfo["person_Contact"];                  
                  this.employeeEmergency = this.employeeBasicInfo["person_Emergency"];                  
                  this.employeeReference = this.employeeBasicInfo["person_Reference"];
                                    
               })

            }


            $('#userProfile').modal({
               backdrop: 'static'
             });
            
         }
      })
   }


 
}


