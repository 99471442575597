import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
import { strictEqual } from 'assert';
import { HistoryComponent } from 'src/app/pages/history/history.component';
declare var $: any;


@Component({
  selector: 'app-leaverule',
  templateUrl: './leaverule.component.html'
})
export class LeaveRuleComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  leaveRuleform: FormGroup;
  leaveRulelist: any;
  leaveTypeList: Array<Select2OptionData>;
  companylist: Array<Select2OptionData>;

  title: string;
  p: number = 1;
  totalRecord: 0;
  setcompanyName: any;

  advancedBalanceType: string;
  advancedleaveProcessPriority: string;
  isBalance: boolean = false
  isLimit: boolean = false
  isProbation: boolean = false
  isLeaver:boolean = false
  isCarryForward: boolean = false
  isEncashment: boolean = false
  isSandwichApply: boolean = false
  isApprovedLeaveCancel: boolean = false  
  paidStatus:string;
  

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.title = "Add";
    this.advancedBalanceType = ''
    this.advancedleaveProcessPriority = '';
    this.isBalance = false;
    this.isLimit = false;
    this.isProbation = false;
    this.isLeaver = false;
    this.isCarryForward = false;
    this.isEncashment = false;
    this.isSandwichApply = false;
    this.isApprovedLeaveCancel = false;
    

    this.leaveRuleform = this.fb.group({
      id: ['0'],
      leave_Type_Id: [''],
      companyIdList: [''],
      isPaid: [''],
      is_Balance: [false],      
      balance_Type: [''],      
      normal_Balance: [''],
      advanced_Hourly_Balance: [''],
      advanced_Annually_Balance: [''],
      is_Sequence: [false],
      min_Sequence_Leave: [''],
      max_Sequence_Leave: [''],
      is_Probation: [false],
      is_Leaver: [false],      
      probation_Monthly_Allowed: [''],
      leaver_Monthly_Allowed: [''],
      is_CarryForward: [false],
      carryForward_Leave_Upto: [''],
      is_Encashment: [false],
      encashment_Leave_Upto: [''],
      is_Sandwich_Apply: [false],
      is_Approved_Leave_Cancel:[false],
      LeaveProcessPriority: ['CarryForward'],
      is_Holiday_For_Sandwich: [false],
      isActive: [true],
      AddedBy: [Commonvariable.User_Id],
      UpdatedBy: [Commonvariable.User_Id],
    });

    this.getLeaveRuleList();
    this.getLeaveType();

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

    this.paidStatus = 'Paid';
  }

  getLeaveRuleList(PageIndex = 1) {
    this.p = PageIndex;
    this.totalRecord = 0;
    this.leaveRulelist = []
    this.commonApiService.getListWithFilter(Commonvariable.Leave_Rule, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.leaveRulelist = result["data"]["list"];

        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })
  }

  getLeaveType() {
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'name'
    }
    this.commonApiService.GetByPaginated(Commonvariable.Leave_Type, requestData).subscribe((response) => {
      if (response["data"] != "") {
        this.leaveTypeList = response["data"].map(x => ({
          id: x.id,
          text: x.name
        }))
      }
    })

  }

  getCompanyList() {
    this.companylist = []
    if (this.leaveRuleform.controls.leave_Type_Id.value > 0) {
      this.commonApiService.getById(Commonvariable.Leave_Rule, this.leaveRuleform.controls.leave_Type_Id.value, "GetPendingLeaveRuleCompany").subscribe((response) => {
        if (response["data"] != "") {
          this.companylist = response["data"].map(x => ({
            id: x.company_Id,
            text: x.company_Name
          }))
        }
      })
    }
  }


  Upsert() {
    
    
    if(this.leaveRuleform.controls.id.value){
      this.leaveRuleform.controls.UpdatedBy.setValue(Commonvariable.User_Id);
    }else{
      this.leaveRuleform.controls.AddedBy.setValue(Commonvariable.User_Id);
    }        
    if (this.validation()) {
      this.commonApiService.Upsert(Commonvariable.Leave_Rule, this.leaveRuleform.value, this.leaveRuleform.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.commonService.toaster("warning", 'Record is already exists !', 'Holiday')
          }
          else {
            $("#draggable").modal('hide');
            this.getLeaveRuleList();
            this.commonService.toaster("success", this.leaveRuleform.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Leave Rule')
          }
        }
      })
    }

  }

  Delete(Id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Leave_Rule, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getLeaveRuleList();
          this.commonService.toaster("success", response["message"], 'Leave Rule')
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Leave_Rule, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.getLeaveRuleList();
            this.commonService.toaster("success", response["message"], 'Leave Rule')            
          }

        })
      }

    });
  }

  openModel(Id) {
    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Leave_Rule, Id).subscribe((response) => {
        if (response["data"] != "") {
          
          var editData = response["data"];                    
          var formValue = this.leaveRuleform;          
          
          if(editData[0]["isPaid"] == 'Paid'){
            this.paidStatus = 'Paid';
          }else{
            this.paidStatus = 'UnPaid';
          }        

          this.setcompanyName = editData[0]["company_Name"];
          this.advancedBalanceType = editData[0]["balance_Type"];
          this.advancedleaveProcessPriority = editData[0]["leaveProcessPriority"];
          
          this.isBalance = editData[0]["is_Balance"] == 1 ? true : false;          
          this.isLimit = editData[0]["is_Sequence"] == 1 ? true : false;
          this.isProbation = editData[0]["is_Probation"] == 1 ? true : false;
          this.isLeaver = editData[0]["is_Leaver"] == 1 ? true : false;
          this.isCarryForward = editData[0]["is_CarryForward"] == 1 ? true : false;
          this.isEncashment = editData[0]["is_Encashment"] == 1 ? true : false;
          this.isSandwichApply = editData[0]["is_Sandwich_Apply"] == 1 ? true : false;          
          this.isApprovedLeaveCancel = editData[0]["is_Approved_Leave_Cancel"] == 1 ? true : false;                            

          this.commonService.setEditData(formValue, editData);
          
          formValue.controls.LeaveProcessPriority.setValue(editData[0]["leaveProcessPriority"]);
          formValue.controls.probation_Monthly_Allowed.setValue(editData[0]["probation_Monthly_Allowed"]);
          formValue.controls.leaver_Monthly_Allowed.setValue(editData[0]["leaver_Monthly_Allowed"]);
          

          
        }
      })
    }
    else {
      this.ngOnInit()
    }
    $('#draggable').modal({
      backdrop: 'static'
    });


  }



  validation() {
    var formValue = this.leaveRuleform;

    

    
    for (const key of Object.keys(formValue.controls)) {     

      if (key == "leave_Type_Id") {
        if (formValue.controls.leave_Type_Id.value == '') {
          this.commonService.toaster("info", "Leave Type is required !", 'Leave Rule')
          $("#leavetypeid select").focus()
          return false;
        }
      }

      if (key == "companyIdList") {
        if (formValue.controls.companyIdList.value == '') {
          this.commonService.toaster("info", "Company is required !", 'Leave Rule')
          $("#companyid select").focus()
          return false;
        }
      }

      if (key == "isPaid") {
        if (formValue.controls.isPaid.value == '') {
          this.commonService.toaster("info", "Paid selction is required !", 'Leave Rule')
          return false;
        }
      }

      if (key == "is_Balance") {
        if (!formValue.controls.is_Balance.value) {
          formValue.controls.balance_Type.setValue(null);
          formValue.controls.normal_Balance.setValue(null);
          formValue.controls.advanced_Hourly_Balance.setValue(null);
          formValue.controls.advanced_Annually_Balance.setValue(null);
        }
      }


      if(formValue.controls.isPaid.value == 'Paid'){
        if (key == "balance_Type") {
          if (formValue.controls.is_Balance.value && (formValue.controls.balance_Type.value == '' || formValue.controls.balance_Type.value == null)) {
            this.commonService.toaster("info", "Balance type selction is required !", 'Leave Rule')
            return false;
          }
        }
      
      
      

      if (key == "LeaveProcessPriority") {

        if (formValue.controls.is_CarryForward.value && formValue.controls.is_Encashment.value){
          if ((formValue.controls.LeaveProcessPriority.value == '' || formValue.controls.LeaveProcessPriority.value == null)) {
            this.commonService.toaster("info", "Priority is required !", 'Leave Rule')
            return false;
          }          
        }
        
      }
      

    
      if (key == "normal_Balance") {
        if (formValue.controls.balance_Type.value == 'Normal') {

          if (formValue.controls.normal_Balance.value == '' || formValue.controls.normal_Balance.value == null) {
            this.commonService.toaster("info", "Balance is required !", 'Leave Rule')
            $("#normal_Balance").focus()
            return false;
          }

          formValue.controls.advanced_Hourly_Balance.setValue(null);
          formValue.controls.advanced_Annually_Balance.setValue(null);
        }
      }
    

      if (key == "advanced_Hourly_Balance") {
        if (formValue.controls.balance_Type.value == 'Advanced') {

          if (formValue.controls.advanced_Hourly_Balance.value == '' || formValue.controls.advanced_Hourly_Balance.value == null) {
            this.commonService.toaster("info", "Advanced hourly balance is required !", 'Leave Rule')
            $("#advanced_Hourly_Balance").focus()
            return false;
          }

          formValue.controls.normal_Balance.setValue(null);
        }
      }
      

      if (key == "advanced_Annually_Balance") {
        if (formValue.controls.balance_Type.value == 'Advanced' && (formValue.controls.advanced_Annually_Balance.value == '' || formValue.controls.advanced_Annually_Balance.value == null)) {
          this.commonService.toaster("info", "Advanced annually balance is required !", 'Leave Rule')
          $("#advanced_Annually_Balance").focus()
          return false;
        }
        // else {

        //   if (formValue.controls.balance_Type.value == 'Advanced' && (formValue.controls.advanced_Hourly_Balance.value > formValue.controls.advanced_Annually_Balance.value)) {
        //     this.commonService.toaster("info", "Advanced hourly balance not more then Advanced annually balance !", 'Leave Rule')
        //     $("#advanced_Hourly_Balance").focus()
        //     return false;
        //   }

        // }
      }
    }

      if (key == "is_Sequence") {
        if (!formValue.controls.is_Sequence.value) {
          formValue.controls.min_Sequence_Leave.setValue(null);
          formValue.controls.max_Sequence_Leave.setValue(null);
        }
      }


      if (key == "min_Sequence_Leave") {
        

        if(formValue.controls.is_Sequence.value && (formValue.controls.min_Sequence_Leave.value == '' || formValue.controls.min_Sequence_Leave.value == null)){
          if(formValue.controls.min_Sequence_Leave.value != '0'){
            this.commonService.toaster("info", "Min limit upto is required !", 'Leave Rule')
            $("#min_Sequence_Leave").focus();
            return false;

          }
        }
      }

      if (key == "max_Sequence_Leave") {
        if (formValue.controls.is_Sequence.value && (formValue.controls.max_Sequence_Leave.value == '' || formValue.controls.max_Sequence_Leave.value == null)) {
          this.commonService.toaster("info", "Max limit upto is required !", 'Leave Rule')
          $("#max_Sequence_Leave").focus();
          return false;
        }
        else {
          if (formValue.controls.is_Sequence.value && (parseInt(formValue.controls.min_Sequence_Leave.value) > parseInt(formValue.controls.max_Sequence_Leave.value))) {
            this.commonService.toaster("info", "Min limit not more then Max limit !", 'Leave Rule')
            $("#min_Sequence_Leave").focus();
            return false;
          }
        }
      }

      if (key == "is_Probation") {
        if (!formValue.controls.is_Probation.value) {
          formValue.controls.probation_Monthly_Allowed.setValue(null);
        }
      }

      if (key == "probation_Monthly_Allowed") {        
        if ((formValue.controls.is_Probation.value || formValue.controls.is_Probation.value == 1)) {
          if(formValue.controls.probation_Monthly_Allowed.value == '' || formValue.controls.probation_Monthly_Allowed.value == null){
            if(formValue.controls.probation_Monthly_Allowed.value != '0'){
              this.commonService.toaster("info", "Probation is required !", 'Leave Rule')
              $("#probation_Monthly_Allowed").focus();
              return false;
            }            
          }
          
        }
      }

      if (key == "is_Leaver") {
        if (!formValue.controls.is_Leaver.value) {
          formValue.controls.leaver_Monthly_Allowed.setValue(null);
        }
      }

      if (key == "leaver_Monthly_Allowed") {        
        if ((formValue.controls.is_Leaver.value || formValue.controls.is_Leaver.value == 1)) {
          if(formValue.controls.leaver_Monthly_Allowed.value == '' || formValue.controls.leaver_Monthly_Allowed.value == null){
            if(formValue.controls.leaver_Monthly_Allowed.value != '0'){
              this.commonService.toaster("info", "Leaver is required !", 'Leave Rule')
              $("#leaver_Monthly_Allowed").focus();
              return false;
            }            
          }
          
        }
      }


      
    if(formValue.controls.isPaid.value == 'Paid'){  
      if (key == "is_CarryForward") {
        if (!formValue.controls.is_CarryForward.value) {
          formValue.controls.carryForward_Leave_Upto.setValue(null);
        }
      }

      if (key == "carryForward_Leave_Upto") {
        if (formValue.controls.is_CarryForward.value && (formValue.controls.carryForward_Leave_Upto.value == '' || formValue.controls.carryForward_Leave_Upto.value == null)) {
          this.commonService.toaster("info", "Carry forward upto is required !", 'Leave Rule')
          $("#carryForward_Leave_Upto").focus();
          return false;
        }
      }

      if (key == "is_Encashment") {
        if (!formValue.controls.is_Encashment.value) {
          formValue.controls.encashment_Leave_Upto.setValue(null);
        }
      }

      if (key == "encashment_Leave_Upto") {
        if (formValue.controls.is_Encashment.value && (formValue.controls.encashment_Leave_Upto.value == '' || formValue.controls.encashment_Leave_Upto.value == null)) {
          this.commonService.toaster("info", "Encashment upto is required !", 'Leave Rule')
          $("#encashment_Leave_Upto").focus();
          return false;
        }
      }
    }  
      if (key == "is_Sandwich_Apply") {
        if (!formValue.controls.is_Sandwich_Apply.value) {
          formValue.controls.is_Holiday_For_Sandwich.setValue(false);
        }
      }

      
    }   

    formValue.get("isActive").setValue(formValue.get("isActive").value ? 1 : 0);
    formValue.get("is_Balance").setValue(formValue.get("is_Balance").value ? 1 : 0);      
    formValue.get("is_Sequence").setValue(formValue.get("is_Sequence").value ? 1 : 0);
    formValue.get("is_Probation").setValue(formValue.get("is_Probation").value ? 1 : 0);
    formValue.get("is_Leaver").setValue(formValue.get("is_Leaver").value ? 1 : 0);
    formValue.get("is_CarryForward").setValue(formValue.get("is_CarryForward").value ? 1 : 0);
    formValue.get("is_Encashment").setValue(formValue.get("is_Encashment").value ? 1 : 0);
    formValue.get("is_Sandwich_Apply").setValue(formValue.get("is_Sandwich_Apply").value ? 1 : 0);
    formValue.get("is_Approved_Leave_Cancel").setValue(formValue.get("is_Approved_Leave_Cancel").value ? 1 : 0);    
    formValue.get("is_Holiday_For_Sandwich").setValue(formValue.get("is_Holiday_For_Sandwich").value ? 1 : 0);


    return true;
  }

  setFormData(fieldName, id) {
    if (id != undefined) {
      if (fieldName == 'leavetypeid') {
        this.leaveRuleform.controls.leave_Type_Id.setValue(id)
        this.getCompanyList();
      }
      if (fieldName == 'companyid') {
        this.leaveRuleform.controls.companyIdList.setValue(String(id))
      }
    }
  }

  history() {
    this.historyView.getHistory("leave_rule",this.leaveRuleform.controls.id.value)
  }

  isPaidStatus(status){    
    this.paidStatus = status;
  }

}
