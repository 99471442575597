<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>Vacancy</h1>
            </div>
        </div>

        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a (click)="openModel(0)" class="btn btn-transparent blue  btn-sm">Add Vacancy</a>
                    </div>
                </li>
            </ul>
        </div>

        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" placeholder="Type to filter the job Title column..."
                                (change)="filterData($event)">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblZone">
                            <thead class="flip-content">
                                <tr>
                                    <th class="text-center"> Job Title </th>
                                    <th class="text-center" style="width: 15%;">Company</th>
                                    <th class="text-center" style="width: 15%;">Site</th>
                                    <th class="text-center" style="width: 15%;">Department</th>
                                    <th class="text-center" style="width: 10%;">Valid From</th>
                                    <th class="text-center" style="width: 10%;">Valid Up to</th>
                                    <th class="text-center" style="width: 10%;">No Of Postions</th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of vacancylist| paginate: { itemsPerPage: 10, currentPage: p }">
                                    <td>{{item.jobTitle}}</td>
                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                                data-placement="top" title="Active"></div>
                                            <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                                data-placement="top" title="Inactive"></div>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                            data-placement="bottom" title="{{item.company_Name}}">
                                            {{item.company_Name}}</div>
                                    </td>
                                    <td>{{item.site_Name}}</td>
                                    <td>{{item.dept_Name}}</td>
                                    <td class="text-center">{{item.validFrom | date :'dd/MM/yyyy'}}</td>
                                    <td class="text-center">{{item.validUpto | date :'dd/MM/yyyy'}}</td>
                                    <td class="text-center">{{item.noOfPositions}}</td>
                                    <td class="text-center">
                                        <a (click)="openModel(item.id)"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Vacancy"></i></a>&nbsp;
                                        <a (click)="Delete(item.id)">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Delete Vacancy"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="vacancylist != null">
                        <pagination-controls (pageChange)="p = $event" autoHide="true"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade draggable-modal" id="draggable" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:6% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Vacancy</h4>
            </div>
            <div class="modal-body">
                <!-- <ul class="nav nav-tabs">
                    <li class="active">
                        <a href="#details" data-toggle="tab">Candidate Details</a>
                    </li>
                    <li><a href="#docUpload" data-toggle="tab">Candidate Document</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane active" id="details"> -->
                <form [formGroup]="vacancyform">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Company<span class="required"> *
                                    </span></label>
                                <select class="form-control" id="company_Id" formControlName="company_Id"
                                    (change)="getSite()">
                                    <option value="">Select Your Company</option>
                                    <option *ngFor="let company of companylist;" value={{company.company_Id}}>
                                        {{company.company_Name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Site<span class="required"> *
                                    </span></label>
                                <select class="form-control" id="site_Id" formControlName="site_Id">
                                    <option value="">Select Your Site</option>
                                    <option *ngFor="let item of siteList;" value={{item.site_Id}}>
                                        {{item.site_Name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Department<span class="required"> *
                                    </span></label>
                                <select class="form-control" id="dept_Id" formControlName="dept_Id">
                                    <option value="">Select Your Department</option>
                                    <option *ngFor="let item of departmentList;" value={{item.dept_Id}}>
                                        {{item.dept_Name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Job Title<span class="required"> *
                                    </span></label>
                                <input type="text" id="jobTitle" class="form-control" formControlName="jobTitle"
                                    autocomplete="off" pattern="^[a-zA-Z]+(\s[a-zA-Z]+)?$"
                                    oninvalid="setCustomValidity('Please enter on alphabets only. ')">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">No Of Positions<span class="required"> *
                                    </span></label>
                                <input type="number" id="noOfPositions" class="form-control" min="1" max="100"
                                    formControlName="noOfPositions" autocomplete="off">
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Description</label>
                                <textarea class="form-control" formControlName="description"
                                    style="max-width: 100%;"></textarea>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Notes</label>
                                <textarea class="form-control" formControlName="notes"
                                    style="max-width: 100%;"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Valid From<span class="required"> *
                                    </span></label>
                                <div class="input-group">
                                    <input type="text" id="validFrom" class="form-control" />
                                    <span class="input-group-btn">
                                        <button class="btn default" type="button">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Valid Up To<span class="required"> *
                                    </span></label>
                                <div class="input-group">
                                    <input type="text" id="validUpto" class="form-control" />
                                    <span class="input-group-btn">
                                        <button class="btn default" type="button">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label class="control-label">Active</label>
                                <div class="md-checkbox-inline">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="checkbox1_3" class="md-check"
                                            formControlName="isActive">
                                        <label for="checkbox1_3">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </form>
                <!-- </div>
                    <div class="tab-pane" id="docUpload">
                        <app-candidatedocument></app-candidatedocument>
                    </div>
                </div> -->
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn  dark btn-outline pull-left" (click)="history()"
                    *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button> -->
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="Upsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Vacancy History</h4>
            </div>
            <div class="modal-body" *ngIf="title == 'Edit'">
                <app-history></app-history>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>