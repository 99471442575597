<form [formGroup]="empfilterform">
    <div class="form-group">
        <div class="table-resposive">
            <table class="table table-hover" id="work" style="position: relative;" role="grid">
                <thead>
                    <tr>
                        <th>Field Name</th>
                        <th>Parameter</th>
                        <th>Field Value</th>
                        <!-- <th>Condition With</th> -->
                        <th style="width: 5%;"><button type="button" (click)="addItem(null)" class="btn pull-right"><i
                                    class="fa fa-plus" aria-hidden="true"></i></button></th>
                    </tr>
                </thead>
                <tbody formArrayName="SearchBy"
                    *ngFor="let item of empfilterform.get('SearchBy')['controls']; let i = index;">
                    <tr [formGroupName]="i" *ngIf="i > 0">
                        <td colspan="2">
                            <label class="control-label pull-right" style="margin-top:1%">Condition With</label>
                        </td>
                        <td>
                            <select class="form-control" id="ConditionWith{{i}}" formControlName="ConditionWith">
                                <option value="">Select Condition</option>
                                <option value="&&">And</option>
                                <option value="||">Or</option>
                            </select>
                        </td>
                    </tr>
                    <tr [formGroupName]="i">
                        <td>
                            <select class="form-control" id="FieldName{{i}}" formControlName="FieldName">
                                <option value="">Select Field Name</option>
                                <option value="emp_Code">Employee Code</option>
                                <option value="FirstName">First Name</option>
                                <!-- <option value="MiddleName">Middle Name</option> -->
                                <option value="LastName">Last Name</option>
                                <option value="Company_Name">Company</option>
                                <option value="Dept_Name">Department</option>
                                <option value="Desig_Name">Designation</option>
                                <option value="Shift_Name">Shift</option>
                            </select>
                        </td>
                        <td>
                            <select class="form-control" id="Parameter{{i}}" formControlName="Parameter">
                                <option value="">Select Parameter</option>
                                <option value="==">Exact match</option>
                                <option value=">=">Greater than or equal to</option>
                                <option value='<='>Less than or equal to </option>
                                <option value="!=">Not Equal To</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control" id="FieldValue{{i}}" formControlName="FieldValue"
                                autocomplete="off" maxlength="50">
                        </td>
                        <td>
                            <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                                    class="fa fa-minus" aria-hidden="true"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</form>