import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/services/login.service'
import { CommonApiService } from 'src/app/services/commonApi.service'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LeftMenuComponent } from './../../left-menu/left-menu.component'
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import { Location } from '@angular/common';
import { Commonvariable } from 'src/app/models/commonvariable';
import packagejson from '../../../../package.json';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  // @ViewChild(LeftMenuComponent, { static: false }) LeftMenu: LeftMenuComponent;
  loginForm: FormGroup;
  Login_Id: string = "input100";
  password: string = "input100";
  username: string = "input100";
  hostname: any;
  public appVersion: string = packagejson.version;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private loginService: LoginService,
    private commonApiService: CommonApiService,
    private loc: Location
  ) { }

  ngOnInit() {
    $("#Login_Id").focus();

    let currentUser = JSON.parse(localStorage.getItem('currentHRUser'));
    if (currentUser != null && currentUser["data"] != undefined && !this.loginService.isTokenExpired()) {

      if (currentUser["data"]["person_Id"] != null) {
        this.commonApiService.getById(Commonvariable.PersonService, currentUser["data"]["person_Id"], "PersonPassCheck").subscribe((response) => {
          if (response["data"]) {
            this.router.navigateByUrl('frocechangepassword');
          }
          else {
            if (currentUser["data"]["role"] == "User") {
              this.router.navigateByUrl('employee');
            }
            else {
              this.router.navigateByUrl('dashboard');
            }
          }
        });
      }
      else {
        this.router.navigateByUrl('dashboard');
      }
    }
    else {
      localStorage.clear();
      sessionStorage.clear();
    }

    const angularRoute = this.loc.path();
    this.hostname = window.location.hostname;

    this.loginForm = this.fb.group({
      Login_Id: ['', [Validators.required, Validators.maxLength(100)]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }
  // Validators.maxLength(15), Validators.minLength(8)
  get formControls(): any {
    return this.loginForm['controls'];
  }

  valueChange(Fieldname) {
    if ($("#" + Fieldname).val() != "") {
      this[Fieldname] = "input100 has-val";
    }
    else {
      this[Fieldname] = "input100";
    }
  }


  //verify user detail
  userRequest() {
    var form = this.loginForm;
    if (this.validation()) {

      this.loginService.login(this.loginForm.value).subscribe((result) => {

        if (result["status"] == 200 && result["data"].isVerify) {

          //  this.spinner.hide();
          localStorage.clear();
          sessionStorage.clear();

          this.loginService.setCurrentLoginData(result["data"]);


          localStorage.setItem('currentHRUser', JSON.stringify(result));
          localStorage.setItem('user_Id', result["data"]["user_Id"]);
          sessionStorage.setItem("menuHide", result["data"]["login_Id"].includes("Candidate") ? result["data"]["login_Id"] : "");
          sessionStorage.setItem("loadPage", "1");


          if (result["data"]["emp_Id"] != null)
          {
            localStorage.setItem('HR_Emp_Id', result["data"]["emp_Id"].filter(x => x.company_Id == result["data"]["company_Id"])[0].emp_Id);
          }

          this.commonApiService.getById(Commonvariable.Menu, this.loginService.currentUser.role_Id, "GetActiveLink").subscribe((response)=>{
            localStorage.setItem('luxshHRmenuLinks', response["data"]);


            if (result["data"]["role"] == "Candidate") {
              //candidate login (Empid not coming)
              this.router.navigateByUrl('candidateedit');
            }
            else {
              if (parseInt(result["data"]["person_Id"]) > 0 && result["data"]["person_Id"] != null) {
                this.commonApiService.getById(Commonvariable.PersonService, result["data"]["person_Id"], "PersonPassCheck").subscribe((response) => {
                  if (response["data"]) {
  
                    this.router.navigateByUrl('frocechangepassword');
                  }
                  else {
  
                    if (result["data"]["emp_Id"].length > 1) {
                      this.router.navigateByUrl('company-selection');
                    }
                    else {
                      if (result["data"]["role"] == "User") {
                        this.router.navigateByUrl('employee');
                      }
                      else {
                        this.router.navigateByUrl('dashboard');
                      }
                      setTimeout(() => {
                        // this.LeftMenu.getMenuList();
                      });
                      
                    }
                  }
                })
              }
              else {
                //Superadmin Login (Empid not coming)
                this.router.navigateByUrl('dashboard');
                // this.LeftMenu.getMenuList();
              }
            }
  

          })         

        }
        else {
          // alert('Username and Password not match.');
          this.toastr.error('Username and Password not match !', 'Login');
        }
      })
    }
  }

  validation() { 
    var loginForm = this.loginForm;
    if (!loginForm.valid) {
      for (const key of Object.keys(loginForm.controls)) {
        if (loginForm.controls[key].invalid) {
          let Msg = "";
          if (key == "Login_Id") {
            if (loginForm.controls[key].errors.required) {
              Msg = "UserName is required !"
            }
            else if (loginForm.controls[key].errors.maxlength) {
              Msg = "Sorry You are Exceeding the Limit !"
            }
          }
          else if (key == "password") {
            if (loginForm.controls[key].errors.required) {
              Msg = "Password is required !"
            }
            // else if (loginForm.controls[key].errors.maxlength) {
            //   Msg = "Password not more then 15 character !"
            // }
            else if (loginForm.controls[key].errors.minlength) {
              Msg = "Password enter must be 8 character !"
            }
          }
          this.toastr.info(Msg, "Login");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if ($("#Login_Id").val().indexOf(" ") > -1) {
        this.toastr.info("UserName not allow space !", "Login");
        $("#Login_Id").focus();
        return false;
      }
    }
    return true;
  }


  changeScreen(hname, sname) {
    $("#" + hname).hide();
    $("#" + sname).show();
  }


  forgetPassword() {
    if ($("#username").val() != "") {
      this.loginService.forgetPassword($("#username").val()).subscribe((result) => {
        if (result["status"] == 200) {
          this.toastr.success('Reset pasword link sent to register email address !', 'Forgot Password');
          this.changeScreen('forgotPassForm', 'loginForm');
        }
      })
    }
    else {
      this.toastr.info("UserName is Required !", "Forgot Password");
      $("#username").focus();
    }
  }





}
