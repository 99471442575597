<form [formGroup]="companyBasicInfoform">
<!-- 
    <div class="form-group">
        <label class="control-label col-md-4">Code
            <span class="required"> * </span>
        </label>
        <div class="col-md-3">
            <input type="text" id="company_Code" class="form-control" formControlName="company_Code"
                autocomplete="off" />
        </div>
    </div> -->
    <div class="form-group">
        <label class="control-label col-md-4">Name
            <span class="required"> * </span>
        </label>
        <div class="col-md-6">
            <input type="text" id="company_Name" class="form-control" formControlName="company_Name"
                autocomplete="off" />
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-md-4">Registration No
            <span class="required"> * </span>
        </label>
        <div class="col-md-3">
            <input type="text" id="registration_No" class="form-control" formControlName="registration_No"
                autocomplete="off" />
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-md-4">Registration Date<span class="required"> * </span></label>
        <div class="col-md-3">
            <!-- <input type="date" class="form-control" id="registration_Date" formControlName="registration_Date" /> -->
            
            <div class="input-group flatpickr" id="flatpickropen"  data-wrap="true">
                <input type="text" class="form-control readonlyClass" id="registration_Date" data-input />
                <span class="input-group-btn" data-toggle>
                    <button class="btn default" type="button">
                        <i class="fa fa-calendar"></i>
                    </button>
                </span> 
            </div>
        </div>
    </div>
</form>