import { Component, OnInit, asNativeElements, ViewChild, NgZone, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { EmployeeAdvancedSearchComponent } from './employee-advanced-search/employee-advanced-search.component';
import { EmployeeViewSummaryComponent } from './employee-view-summary/employee-view-summary.component';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { CommonService } from 'src/app/services/common.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { resource } from 'selenium-webdriver/http';
import { Router, ActivatedRoute } from '@angular/router';
import { strictEqual } from 'assert';
import { trim } from 'jquery';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html'
})
export class EmployeeComponent implements OnInit {
  @ViewChild(EmployeeAdvancedSearchComponent, { static: false }) advancedSearch: EmployeeAdvancedSearchComponent;
  @ViewChild(EmployeeViewSummaryComponent, { static: false }) viewSummary: EmployeeViewSummaryComponent;
  empList: any[] = [];
  filterEmp: any;
  p: number = 1;
  totalRecord = 0;
  filterAdvance: boolean = false;
  userRole = "";
  personId = 0;
  advancedFilterData: any;
  SummaryId: number = 0;
  modelpop: boolean = false;
  sortDirection: { [key: string]: string } = {};
  fname:any;
  currentSort: { column: string, direction: string } = { column: 'emp_Code', direction: 'asc' };
  constructor(
    private commonApiService: CommonApiService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder, private ngZone: NgZone) { }

  ngOnInit() {
    this.getEmployeeList();
    this.userRole = JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"];
    this.personId = Commonvariable.emp_Id
  }
  // ngAfterViewInit(): void {
  //   // Initialize DataTables in ngAfterViewInit
  //   $(document).ready(() => {
  //     $('#tblemp').DataTable();
  //   });
  // }
  // ngAfterViewInit(): void {
  //   // Initialize DataTables in ngAfterViewInit
  //   $(document).ready(() => {
  //     $('#tblemp').DataTable({
  //       serverSide: true,
  //       processing: true,
  //       ajax: {
  //         url: 'your-server-api-endpoint',
  //         type: 'POST',
  //       },
  //       columns: [
  //         // Define your columns here
  //         { data: 'emp_Code', title: 'Emp.Code' },
  //         { data: 'firstName', title: 'Name' },
  //         {data: 'company_Name', tittle:'Company'},
  //         {data: 'desig_Name', tittle:'Job Title'},
  //         {data: 'lineManager', tittle:'Line Manager'},
  //         {data: 'dob', tittle:'Date of Birth'},
  //         { data: 'actions', title: 'Actions' },
  //       ],
  //       order: [
  //         // Default sorting column and direction
  //         { column: 0, dir: 'asc' },
  //       ],
  //     });
  //   });
  // }

  // ngAfterViewInit(): void {
  //   // Initialize DataTables in ngAfterViewInit
  //   $(document).ready(() => {
  //     const table = $('#tblemp').DataTable({
  //       // ... your existing options
  //       order: [[0, 'asc']], // Set the initial sorting column and direction
  //       drawCallback: () => {
  //         // Update the sorting icons after each draw
  //         $('[data-toggle="tooltip"]').tooltip();
  //       },
  //     });
  //   });
  // }
 
  // ngAfterViewInit(): void {
  //   // Initialize DataTables in ngAfterViewInit
  //   $(document).ready(() => {
  //     $('#tblemp').DataTable({
  //       // Add "order" option to specify the default sorting column and direction
  //       order: [[0, 'asc']], // Adjust the column index based on your default sorting column
  //     });
  //   });
  // }
  
  
  
  clickOnModaltest() {
    $(".employee").removeClass("active");
    $(".employeeadd").addClass("active");
  }
  clickOnModal() {
    // $("body").css({ overflow: 'hidden' });
    // $('body').css('position', 'fixed');
    $('#filterModal').modal({
      backdrop: 'static', keyboard: false, show: false
    })

  }
  getSummary(emp_Id) {
    this.modelpop = true;
    this.SummaryId = emp_Id;
    // $("#viewSummary").modal("show");
    $('#viewSummary').modal({
      backdrop: 'static'
    });
    //this.viewSummary.getSummary(emp_Id);
    // if (meid != 1)
    //   this.moveEmployee = true;
  }
  // withou async method
  // getEmployeeList(PageIndex = 1) {

  //   // if ($("input[name='filter']:checked").val() != undefined && $("#filterValue").val() != "") {
  //   //   this.searchOneValue(PageIndex)
  //   // }
  //   // else
  //   if (this.filterAdvance) {
  //     this.advanceFilter(null, PageIndex)
  //   }
  //   else {
  //     this.totalRecord = 0;
  //     this.empList = [];
  //     this.filterEmp = [];
  //     this.p = PageIndex;


  //     var requestData
  //     if (this.advancedFilterData != "" && this.advancedFilterData != null && this.advancedFilterData != 'undefined') {
  //       requestData = {
  //         "searchBy": this.advancedFilterData,
  //         "PageIndex": PageIndex - 1,
  //         "CommonSearch": $("#filterList").val().trim(),
  //         "PageSize": 10,
  //       }
  //     } else {

  //       requestData = {
  //         "searchBy": [],
  //         "PageIndex": PageIndex - 1,
  //         "CommonSearch": $("#filterList").val().trim(),
  //         "PageSize": 10
  //       }
  //     }

  //     this.empList = [];
  //     this.totalRecord = 0


  //     this.commonApiService.GetByPaginated(Commonvariable.EmployeeDetail, requestData, "GetPaginated").subscribe((result) => {
  //       if (result != null && result["status"] == 200) {
  //         this.empList = [];
  //         if (result["data"] != "" && result["data"]["list"] != undefined) {
  //           this.totalRecord = result["data"]["recordCount"]
  //           this.empList = result["data"]["list"];
  //         }
  //       }
  //     })

  //   }
  //   setTimeout(() => {
  //     $('[data-toggle="tooltip"]').tooltip();
  //   }, 500);

  // }
 
  private requestCounter = 0;
  itemsPerPageOptions: number[] = [10, 20, 50,100];
  itemsPerPage: number = 10;
  async getEmployeeList(PageIndex = 1, sortColumn: string = 'emp_Code', sortDirection: string = 'asc'): Promise<void> {
    const currentCounter = ++this.requestCounter;

    try {
      if (this.filterAdvance) {
        this.advanceFilter(null, PageIndex);
      } else {
        this.totalRecord = 0;
        this.empList = [];
        this.filterEmp = [];
        this.p = PageIndex;

        let requestData;

        if (this.advancedFilterData && this.advancedFilterData.length > 0) {
          requestData = {
            searchBy: this.advancedFilterData,
            PageIndex: PageIndex - 1,
            CommonSearch: $("#filterList").val().trim(),
            PageSize: this.itemsPerPage,
            OrderBy: sortColumn + " "+ sortDirection ,
            //SortDirection: sortDirection
          };
        }
         else {

          if($("#fname").val().trim() != '' && $("#fname").val().trim()!= 'undefined' || $("#lname").val().trim() != '' && $("#lname").val().trim()!= 'undefined')
            requestData = {
              searchBy: [{
                FieldName: "FirstName",
                FieldValue:($("#fname").val().trim()),
                Parameter: "",
                ConditionWith: ""
              },
              
              {
                FieldName: "LastName",
                FieldValue:$("#lname").val().trim(),
                Parameter: "",
                ConditionWith: ""
              }],
              PageIndex: PageIndex - 1,
              CommonSearch: $("#filterList").val().trim(),
              PageSize: this.itemsPerPage,
              OrderBy: sortColumn +" "+ sortDirection,
              //SortDirection: sortDirection
            }
            else{
          
          requestData = {
            searchBy: [],
            PageIndex: PageIndex - 1,
            CommonSearch: $("#filterList").val().trim(),
            PageSize: this.itemsPerPage,
            OrderBy: sortColumn +" "+ sortDirection,
            //SortDirection: sortDirection
          };
        }
        }

        this.empList = [];
        this.totalRecord = 0;

        const result = await this.commonApiService.GetByPaginated(Commonvariable.EmployeeDetail, requestData, "GetPaginated").toPromise();

        //if (currentCounter === this.requestCounter) {
        if (requestData.CommonSearch == $("#filterList").val().trim()) {
          // Check if this is the last request
          if (result != null && result["status"] === 200) {
            this.empList = [];
            if (result["data"] !== "" && result["data"]["list"] !== undefined) {
              this.totalRecord = result["data"]["recordCount"];
              this.empList = result["data"]["list"];
            }
          }

          setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
          }, 500);
        }
        else {
          this.spinner.hide();
        }
      }
    } catch (error) {

    }
  }

  // sortColumn(column: string): void {
  //   const isAscending = true; // or false depending on your logic
  //   this.getEmployeeList(this.p, column, isAscending ? 'asc' : 'desc');
  // }
  
  // sortColumn(column: string): void {
  //   // Determine the current sorting direction for the clicked column
  //   const currentSortDirection = this.sortDirection[column];
  
  //   // Determine the new sorting direction
  //   const newSortDirection = currentSortDirection === 'asc' ? 'desc' : 'asc';
  
  //   // Update the sorting direction for the clicked column
  //   this.sortDirection[column] = newSortDirection;
  
  //   // Get the current page index
  //   const currentPageIndex = this.p;
  
  //   // Call the getEmployeeList function with the new sorting parameters
  //   this.getEmployeeList(currentPageIndex, column, newSortDirection);
  // }

  sortColumn(column: string): void {
    if (this.currentSort.column === column) {
      // toggle direction if it's the same column
      this.currentSort.direction = this.currentSort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      // set the new column and reset direction
      this.currentSort = { column, direction: 'asc' };
    }
  
    // call your data retrieval method with the new sort parameters
    this.getEmployeeList(this.p, this.currentSort.column, this.currentSort.direction);
  }
  
  // method to get the appropriate icon class based on the current sort
  getSortIcon(column: string): string {
    if (this.currentSort.column === column) {
      return this.currentSort.direction === 'asc' ? 'fa-sort-up' : 'fa-sort-down';
    } else {
      return 'fa-sort';
    }
  }
  
  onItemsPerPageChange() {
    this.p = 1;
    this.getEmployeeList();
  }
  

  isColumnSorted(column: string, direction: string): boolean {
    const table = $('#tblemp').DataTable();
    const order = table.order()[0];
    return order && order[0] === table.column(`:${column}`).index() && order[1] === direction;
  }
  

  advanceFilterEmp() {
    

    var data = {
      "SearchBy": this.advancedSearch.search()
    }

    if (data.SearchBy) {

      this.advancedFilterData = data.SearchBy;
      if (data.SearchBy[0]["FieldName"] != "" && data.SearchBy[0]["FieldValue"] != "") {
        this.empList = []
        this.totalRecord = 0

        this.ngZone.run(() => {
          this.commonApiService.getPaginated(Commonvariable.EmployeeDetail, null, null, null, null, 10, data).subscribe((result) => {
            if (result != null && result["data"] != "") {
              this.empList = result["data"]["list"];
              this.totalRecord = result["data"]["recordCount"];
              this.p = 1
            }
            $("#filterModal").modal("hide");
          })

        })
      }
    }

  }

  employeeDelete(Id) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.EmployeeDetail, Id).subscribe((response) => {
          if (response["status"] == 200) {
            this.getEmployeeList();
            this.toastr.success(response["message"], 'Employee');
          }
        })
      }
    })
  }

  updateStatus(Id, status = null) {

    if (Commonvariable.UserRole != "User") {
      this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
        if (result.value) {
          this.commonApiService.commonUpdateStatus(Commonvariable.EmployeeDetail, Id, status).subscribe((response) => {

            if (response["status"] == 200) {
              this.toastr.success(response["message"], 'Employee');
              this.ngOnInit();
            }

          })
        }
      });
    }

  }

  advanceFilter(sData = null, pageindex = 1) {
    
    var data;
    if (sData != null) {
      data = sData;
    }
    else {
      this.p = pageindex;
      data = {
        "SearchBy": this.advancedSearch.search() == false ? [] : this.advancedSearch.search(),
        "PageIndex": pageindex - 1,
        "PageSize": 10,
      }
      this.filterAdvance = true;
    }
    if (data.SearchBy.length > 0) {

      this.ngZone.run(() => {

        if (data.SearchBy[0]["FieldName"] != "" && data.SearchBy[0]["FieldValue"] != "") {
          this.empList = []


          this.commonApiService.empDetailList(Commonvariable.EmployeeDetail, data).subscribe((result) => {
            if (result != null && result["data"] != "") {
              this.empList = result["data"];
              this.totalRecord = this.empList.length;
              //this.empList = result["data"].filter(x => x.isActive != 0);
              $("body").css({ overflow: 'inherit' });
              $('body').css('position', 'unset');
              $("#filterModal").modal("hide");
              //this.advancedSearch.ngOnInit();
            }
          })

        }
        else {
          this.empList = this.filterEmp;
          this.filterAdvance = false;
          $("body").css({ overflow: 'inherit' });
          $('body').css('position', 'unset');
          $("#filterModal").modal("hide");
        }
      })
    }
  }


  searchOneValue(pageindex = 1) {
    this.p = pageindex;
    var data = {
      "SearchBy": [{
        "FieldName": $("input[name='filter']:checked").val(),
        "FieldValue": '"' + String($("#filterValue").val().trim()) + '\"',
        "Parameter": '==',
        "OrderBy": 'emp_Id'
      }],
      "PageIndex": pageindex - 1,
      "PageSize": 10,
    }
    if ($("input[name='filter']:checked").val().trim() != undefined && $("#filterValue").val().trim() != "")
      this.advanceFilter(data)

  }

  clear() {
    this.filterAdvance = false;
    this.advancedFilterData = [];
    this.getEmployeeList();
    this.advancedSearch.ngOnInit();
    $("body").css({ overflow: 'inherit' });
    $('body').css('position', 'unset');

    $("#filterModal").modal("hide");
    $(".md-radiobtn").prop("checked", false)
    $("#filterValue").val("");
    $("#filterList").val("");
  }


  editRecord(empId) {

    if (this.userRole == "User") {
      this.router.navigateByUrl('userviewsummary')
    }
    else {
      this.router.navigate(['employeeProfile'], { queryParams: { editId: empId } })
    }

  }

  closepoopup() {
    this.modelpop = false;
    $("#viewSummary").modal("hide");
    // this.viewSummary.
  }

}
