<style>
    /* Custom.css:267 */
    
    table tbody tr td {
        white-space: pre-wrap !important;
    }
</style>
<diV class="row">
    <div class="col-md-2">

    </div>
    <div class="col-md-3">
        <div class="form-group">

            <div class="input-group" id="flatpickrhistoryfromdate" data-wrap="true">
                <input type="text" id="fromdate" name="fromdate" class="form-control readonlyClass" placeholder="From Date ..." autocomplete="off" data-input #historyFromDate>
                <span class="input-group-btn" data-toggle>
                    <button  class="btn default" type="button">
                        <i class="fa fa-calendar"></i>
                    </button>
                </span>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="form-group">
            <div class="input-group" id="flatpickrhistorytodate" data-wrap="true">
                <input type="text" id="todate" name="todate" class="form-control readonlyClass" placeholder="To Date ..." autocomplete="off" data-input #historyToDate>
                <span class="input-group-btn" data-toggle>
                    <button  class="btn default" type="button">
                        <i class="fa fa-calendar"></i>
                    </button>
                </span>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <button type="button" (click)="getHistoryByTable()" class="btn blue">Search</button>&nbsp;
        <button type="button" class="btn dark btn-outline" (click)="clear()">Clear</button>
    </div>
</diV>
<br />

<div class="table-responsive">
    <table class="table">
        <thead>
            <tr>
                <!-- <th style="width: 10%;">Table</th> -->
                <th>Field Name</th>
                <th>Old Value</th>
                <th>New Value</th>
                <!-- <th style="width: 10%;">Action</th> -->
                <th style="width: 10%;">Updated By</th>
                <th style="width: 10%;" class="text-center">Updated On</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of historyList | paginate: { itemsPerPage: 5, currentPage: p,totalItems :totalRecord,id:'pagination10' }">
                <!-- <td>{{item.tableName}}</td> -->
                <td>
                    <table class="table">
                        <tbody>
                            <tr *ngFor="let value of item.fieldValue">
                                <td> {{value.value}}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <table class="table">
                        <tbody>
                            <tr *ngFor="let value of item.oldValue">
                                <td>{{value.value}}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <table class="table">
                        <tbody>
                            <tr *ngFor="let value of item.newValue">
                                <td>{{value.value}}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <!-- <td>{{item.action}}</td> -->
                <td>{{item.addedBy_Name}}</td>
                <td class="text-center">{{item.addedOn | date :'dd/MM/yyyy hh:mm'}}</td>
            </tr>
        </tbody>
    </table>
</div>
<div class="text-center" *ngIf="historyList!= null">
    <pagination-controls id='pagination10' (pageChange)="getHistoryByTable($event)" autoHide="true">
    </pagination-controls>
</div>

<div class="modal-footer">
    <button type="button" data-dismiss="modal" class="btn dark btn-outline" (click)="clear()">Close</button>
</div>
<!-- <div class="text-center">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
</div> -->