import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;

@Component({
  selector: 'app-ethnicity',
  templateUrl: './ethnicity.component.html'
})
export class EthnicityComponent implements OnInit {

  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  ethnicityform: FormGroup;
  ethnicitylist: any;
  filterList: any;
  companylist: any;
  languageList: Array<Select2OptionData>;
  listcall: boolean = true;
  title: string;
  p: number = 1;
  totalRecord: 0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.title = "Add";
    this.ethnicityform = this.fb.group({
      id: ['0'],
      ethnicity_Code: [''],
      ethinicity_Label: ['', Validators.required],
      ethnicity_Name: ['', Validators.required],
      notes: [''],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });
    this.getLanguageList();

    if (this.listcall) {
      this.getEthnicityList();
    }

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }

  getEthnicityList(PageIndex = 1) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.ethnicitylist = []
    this.commonApiService.getListWithFilter(Commonvariable.Ethinicity, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.ethnicitylist = result["data"]["list"];
        this.filterList = result["data"]["list"];

        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })
  }

  getLanguageList() {
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'languages'
    }
    this.commonApiService.GetByPaginated(Commonvariable.Languages, requestData).subscribe((result) => {
      if (result["data"] != "") {
        this.languageList = result["data"].map(x => ({
          id: x.languages,
          text: x.languages
        }))
      }
    })
  }

  setFormData(event) {
    this.ethnicityform.controls.language.setValue(event)
  }


  EthnicityUpsert() {
    var Form = this.ethnicityform;

    if (this.validation()) {
      Form.get("isActive").setValue(Form.get("isActive").value ? 1 : 0);
      Form.get("ethnicity_Name").setValue(Form.get("ethnicity_Name").value);
      Form.get("ethinicity_Label").setValue(Form.get("ethinicity_Label").value);
      
      this.commonApiService.Upsert(Commonvariable.Ethinicity, Form.value, Form.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Ethnicity');
          }
          else {
            $("#draggable").modal('hide');
            this.getEthnicityList();
            this.toastr.success(Form.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Ethnicity');
          }
        }
      })
    }

  }

  EthnicityDelete(Id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Ethinicity, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getEthnicityList();
          this.toastr.success(response["message"], 'Ethnicity');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Ethinicity, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.getEthnicityList();
            this.toastr.success(response["message"], 'Ethnicity');            
          }

        })
      }

    });
  }

  openModel(Id) {
    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Ethinicity, Id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var Form = this.ethnicityform;
          this.commonService.setEditData(Form, editData);
          // $("#ethnicity_Code").prop("disabled", true);
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Ethnicity'); })
    }
    else {
      $("#ethnicity_Code").prop("disabled", false);
      this.listcall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });


  }

  validation() {
    var Form = this.ethnicityform;
    if (!Form.valid) {
      for (const key of Object.keys(Form.controls)) {
        if (Form.controls[key].invalid) {
          let fieldName = "";
          if (key == "ethnicity_Code") {
            fieldName = "Ethnicity Code"
          }
          else if(key == "ethinicity_Label"){
              fieldName = "Ethnicity_Label"
          }
          else if (key == "ethnicity_Name") {
            fieldName = "Ethnicity"
          }
         
          this.toastr.info(fieldName + " is required !", "Ethnicity");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      // if ($("#ethnicity_Code").val().indexOf(" ") > -1) {
      //   this.toastr.info("Ethnicity code not allow space !", "Ethnicity");
      //   $("#ethnicity_Code").focus();
      //   return false;
      // }
      // if ($("#ethnicity_Code").val().length < 2) {
      //   this.toastr.info("Ethnicity code must be at least 2 characters long !", "Ethnicity");
      //   $("#ethnicity_Code").focus();
      //   return false;
      // }
      // if ($("#ethnicity_Code").val().search(/[^a-zA-Z0-9]+/) > -1) {
      //   this.toastr.info("Ethnicity only alphabets and numeric allow !", "Ethnicity");
      //   $("#ethnicity_Code").focus();
      //   return false;
      // }
      // if ($("#ethinicity_Label").val().length < 2) {
      //   this.toastr.info("Ethnicity Label must be at least 2 characters long !", "Ethnicity");
      //   $("#ethinicity_Label").focus();
      //   return false;
      // }
      // if (/["\\]/.test($("#ethinicity_Label").val())) {
      //   this.toastr.info("Ethnicity Label does not allow Special Characters \" \\ ", "Ethnicity");
      //   $("#ethinicity_Label").focus();
      //   return false;
      // }

      if ($("#ethnicity_Name").val().length < 2) {
        this.toastr.info("Ethnicity Name must be at least 2 characters long !", "Ethnicity");
        $("#ethnicity_Name").focus();
        return false;
      }
      if (/["\\]/.test($("#ethnicity_Name").val())) {
        this.toastr.info("Ethnicity Name does not allow Special Characters \" \\ ", "Ethnicity");
        $("#ethnicity_Name").focus();
        return false;
      }
      if (/["\\]/.test($("#notes").val())) {
        this.toastr.info("notes does not allow Special Characters \" \\ ", "Ethnicity");
        $("#notes").focus();
        return false;
      }
      // if (Form.controls.language.value.search(/[^a-zA-Z ]+/) > -1) {
      //   this.toastr.info("Language only alphabets allow !", "Ethnicity");
      //   $("#language select").focus();
      //   return false;
      // }
      // if (Form.controls.language.value.length < 2) {
      //   this.toastr.info("Language Name must be at least 2 characters long !", "Ethnicity");
      //   $("#language select").focus();
      //   return false;
      // }
      // if ($("#ethnicity_Name").val().search(/^[^-\s][a-zA-Z0-9\s.]+[^-\s]+$/) != 0) {
      //   this.toastr.info("Ethnicity Name only alphabets and numeric allow !", "Ethnicity");
      //   $("#ethnicity_Name").focus();
      //   return false;
      // }
      // if ($("#ethnicity_Name").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
      //   this.toastr.info("Ethnicity Name only alphabets and numeric allow !", "Ethnicity");
      //   $("#ethnicity_Name").focus();
      //   return false;
      // }

    }

    return true;
  }

  history()
  {
    this.historyView.getHistory("ethinicity",this.ethnicityform.value.id)
  }
  

}
