 
                <form [formGroup]="shiftform">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Company<span class="required"> *
                                    </span></label>
                                <ng-select2 [data]="companylist" id="company_Id" formControlName="company_Id"
                                    [placeholder]="'--- Select ---'" [options]="{multiple: true,tags: false}">
                                </ng-select2>
                            </div>
                        </div>
                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Shift Code<span class="required"> *
                                    </span></label>
                                <input type="text" id="shift_Code" class="form-control" formControlName="shift_Code"
                                    maxlength="20" autocomplete="off">
                            </div>
                        </div> -->
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Shift Name<span class="required"> *
                                    </span></label>
                                <input type="text" id="shift_Name" class="form-control" formControlName="shift_Name"
                                    maxlength="30" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Shift Start<span class="required"> *
                                    </span></label>
                                <div class="input-group" id="flatpickropenshiftstatetime" data-wrap="true">
                                    <input type="time" id="shift_Start" class="form-control readonlyClass"
                                        formControlName="shift_Start" (change)="onChangeTimeS()" data-input>
                                    <span class="input-group-btn" data-toggle>
                                        <button class="btn default" type="button">
                                            <i class="fa fa-clock-o"></i>
                                        </button>
                                    </span>
                                </div>

                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Shift Duration
                                    <span class="required"> *</span>
                                </label>
                                <input type="text" id="Shift_Duration" class="form-control"
                                    formControlName="Shift_Duration" maxlength="5" autocomplete="off"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                    (input)="onChangeTime($event.target.value)">
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Shift End<span class="required"> *
                                    </span></label>
                                <div class="input-group" id="flatpickropenshiftendtime" data-wrap="true">
                                    <input type="time" id="shift_End" class="form-control time-picker readonlyClass"
                                        formControlName="shift_End" data-input>
                                    <span class="input-group-btn" data-toggle>
                                        <button class="btn default" type="button">
                                            <i class="fa fa-clock-o"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-2">
                            <div class="form-group">
                                <label class="control-label">Night Shift</label>
                                <div class="md-checkbox-inline">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="nightShift" class="md-check"
                                            formControlName="nightShift">
                                        <label for="nightShift">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label class="control-label">Shift Variable</label>
                                <div class="md-checkbox-inline">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="shift_Variable" class="md-check"
                                            formControlName="shift_Variable">
                                        <label for="shift_Variable">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="form-group">
                                <label class="control-label">Active</label>
                                <div class="md-checkbox-inline">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="checkbox1_3" class="md-check"
                                            formControlName="isActive">
                                        <label for="checkbox1_3">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            