import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
   selector: 'app-leave-upload',
   templateUrl: './leave-upload.component.html'
})
export class LeaveUploadComponent implements OnInit {

   backbutton: string = "hidden";
   pbWidth: number = 33.33;
   progressBarWidth: string = this.pbWidth + '%';
   activeWizard: number = 1;
   btnName: string = "Next";
   w1: string = "active";
   w2: string = "";
   w3: string = "";


   p: number = 1;
   p2: number = 1;

   t1: string = "tab-pane active";
   t2: string = "tab-pane";
   t3: string = "tab-pane";
   empAllCheck: boolean = false;
   empAllCheckLB: boolean = false;

   isLeaveBalVisible: boolean = false;

   companylist: any;


   empform: FormGroup;
   empnewleaveform: FormGroup;
   empleavesummeryform: FormGroup;
   empBalanceList: any[] = [];

   addleaveform: FormGroup;
   leavetypelist: any;
   totalCLLeaveBalanceEmp: number;
   totalSLLeaveBalanceEmp: number;
   totalPLLeaveBalanceEmp: number;

   CLtaken_leave: number;
   SLtaken_leave: number;
   PLtaken_leave: number;
   totalRecord: 0;
   dayscount: any;

   items: FormArray;
   itemss: FormArray;
   emp_Id: any = 0;




   currentEvents: EventApi[] = [];


   leaveList: any;
   constructor(private commonApiService: CommonApiService,
      private toastr: ToastrService,
      private commonService: CommonService,
      private router: Router,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {


      this.commonService.flatpickr("effectiveDatesign", 0);

      this.getLeaveList();

      this.empform = this.fb.group({
         emp_Leave: this.fb.array([]),
      });

      this.empnewleaveform = this.fb.group({
         emp_new_Leave: this.fb.array([]),
      });

      this.empleavesummeryform = this.fb.group({
         emp_leave_summery: this.fb.array([]),
      });



      this.addleaveform = this.fb.group({
         Id: 0,
         Emp_id: localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id,
         Applied_by: localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id,
         AddedBy: localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id
      });


      setTimeout(() => {
         $('[data-toggle="tooltip"]').tooltip();
      }, 500);

      //this.commonService.flatpickr("start_Date_open", 0);
      //this.commonService.flatpickr("end_Date_open", 0);

      this.getLeaveTypeList();
      this.getLeave(localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id);




      var addLeaveForm = this.addleaveform;

      $('.select').select2({ width: '100%', dropdownCssClass: "bigdrop" });
      this.getCompanyList();

      $(".leave_bal_table").hide();
      $('#Company_Id').on('select2:select', function (e) {
         $("#effectiveDate").val('');
         $(".leave_bal_table").hide();

      });

   }

   createItem(data = null) {
      return this.fb.group({
         index: data != null ? data.index : '0',
         emp_id: data != null ? data.emp_id : '0',
         leave_type_id: data != null ? data.leave_type_id : null,
         leave_Type_Code: data != null ? data.leave_Type_Code : "",
         leave_Type: data != null ? data.leave_Type : "",
         prorated_LeaveBalance: data != null ? data.prorated_LeaveBalance : 0,
         leaveBalanceToUpdate: data != null ? parseFloat(data.balance_leave) == 0 ? parseFloat(data.leaveBalanceToUpdate) : parseFloat(data.balance_leave) : 0,
         balance_leave: data != null ? data.balance_leave : 0,
         ischecked: false,
         uploaded_field: 0
      });
   }

   createNewLeaveItem(data = null) {
      return this.fb.group({
         index: data != null ? data.index : '0',
         emp_Id: data != null ? data.emp_Id : '0',
         current_Balance: data != null ? data.current_Balance : '0',
         leave_type_id: data != null ? data.leave_type_id : null,
         firstName: data != null ? data.firstName : "",
         lastName: data != null ? data.lastName : 0,
         leaveType: data != null ? data.leaveType : 0,
         new_Balance: data != null ? data.new_Balance : 0,
         carryForward_Balance: data != null ? data.carryForward_Balance : 0,
         uploaded_Balance: data != null ? data.uploaded_Balance : 0,
         prev_Id: data != null ? data.prev_Id : 0,
         ischecked: false,

      });
   }

   empLeaveSummeryItem(data = null) {
      return this.fb.group({
         Id: data != null ? data.Id : '0',
         emp_Id: data != null ? data.emp_Id : '0',
         current_Balance: data != null ? data.current_Balance : '0',
         leave_type_id: data != null ? data.leave_type_id : null,
         firstName: data != null ? data.firstName : "",
         lastName: data != null ? data.lastName : 0,
         leaveType: data != null ? data.leaveType : 0,
         new_Balance: data != null ? data.new_Balance : 0,
         carryForward_Balance: data != null ? data.carryForward_Balance : 0,
         uploaded_Balance: data != null ? data.uploaded_Balance : 0,
         prev_Id: data != null ? data.prev_Id : 0,
         ischecked: false,

      });
   }



   getCompanyList() {

      this.companylist = [];

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": 'company_Name'
      }

      this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
         if (response["data"] != null && response["status"] == 200 && response["data"] != "") {
            this.companylist = response["data"];
            $('.select').select2({ width: '100%', dropdownCssClass: "bigdrop" });
            $('.select').val("").trigger('change')
            //this.companylist = response["data"].filter(x => x.isActive != 0);
         }
      })
   }


   addLeaveData(id) {

      $("#checkalllb").prop("checked", false)
      this.emp_Id = id
      var companyId = $('#Company_Id').val();
      if (companyId == "") {
         this.commonService.toaster("info", "Please select company !", "Leave Upload")
         $("#Company_Id").focus();
         $("#effectiveDate").val('');
         return false
      }
      if ($("#effectiveDate").val() == "") {
         this.commonService.toaster("info", "Effective date required !", "Leave Upload")
         $("#effectiveDate").focus();
      }
      else {

         $(".leave_bal_table").show();
         this.commonApiService.getById(Commonvariable.Leave_Balance, companyId + "," + this.commonService.dateFormatChange($("#effectiveDate").val()), "GetLeaveBalanceProratedByCompany").subscribe((result) => {
            this.items = this.empform.get('emp_Leave') as FormArray;
            this.items.clear();
            if (result["data"] != "") {
               this.isLeaveBalVisible = true               
               let index = 0
               result["data"].forEach(x => {
                  let leaveUpload = {
                     "index": index,
                     "balance_leave": x.balance_leave,
                     "emp_id": x.emp_id,
                     "leaveBalanceToUpdate": x.leaveBalanceToUpdate,
                     "leave_Type": x.leave_Type,
                     "leave_Type_Code": x.leave_Type_Code,
                     "leave_type_id": x.leave_type_id,
                     "prorated_LeaveBalance": x.prorated_LeaveBalance
                  }
                  index++;                  
                  this.items.push(this.createItem(leaveUpload));
               });
            }else{
               this.isLeaveBalVisible = false
               this.commonService.toaster("info", "Leave type not available!", "Leave Upload")
               $("#effectiveDate").focus();
            }
         })
      }
   }

   getLeaveList() {
      this.leaveList = [];

      var requestData = {
         "searchBy": [
            {
               "FieldName": "Emp_Id",
               "FieldValue": localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id,
               "Parameter": "=="
            }
         ]

      }
      this.commonApiService.getPaginated(Commonvariable.Employee_Leave, null, null, null, null, null, requestData).subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.leaveList = result["data"][0];

         }
      })

      return this.leaveList
   }




   getLeaveTypeList() {
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "Company_Id",
               "FieldValue": Commonvariable.Company_Id,
               "Parameter": "=="
            }
         ],
         "OrderBy": 'name'
      }
      this.commonApiService.GetByPaginatedRuleWise(Commonvariable.Leave_Type, requestData).subscribe((response) => {
         if (response["data"] != "") {
            this.leavetypelist = response["data"];
         }
      })
   }

   getLeave(id) {


      if (id) {
         this.commonApiService.getById(Commonvariable.Leave_Balance, id, "GetLeaveBalance").subscribe((result) => {
            if (result != null && result["data"] != "") {
               this.CLtaken_leave = result["data"][0].total_leave - result["data"][0].taken_leave;
               this.totalCLLeaveBalanceEmp = result["data"][0].total_leave;

               this.SLtaken_leave = result["data"][1].total_leave - result["data"][1].taken_leave;
               this.totalSLLeaveBalanceEmp = result["data"][1].total_leave;

               this.PLtaken_leave = result["data"][2].total_leave - result["data"][2].taken_leave;
               this.totalPLLeaveBalanceEmp = result["data"][2].total_leave;

            }
         })
      }

   }




   validation() {
      if ($("#effectiveDate").val() == "") {
         this.commonService.toaster("info", "Effective date required !", "Leave upload")
         $("#effectiveDate").focus();
         return false;
      }

      return true;
   }

   UpsertEmpLeaveUpload() {

      var data = this.empleavesummeryform.value;

      var customnewbalance = [];
      var balanceIndex = 0;
      data.emp_leave_summery.forEach(x => {
         var customnewbalancearr = {
            "index": balanceIndex,
            "current_Balance": x.current_Balance,
            "emp_Id": x.emp_Id,
            "firstName": x.firstName,
            "ischecked": x.ischecked,
            "lastName": x.lastName,
            "leaveType": x.leaveType,
            "leave_type_id": x.leave_type_id,
            "new_Balance": parseFloat(x.uploaded_Balance),
            "prev_Id": parseInt(x.prev_Id),
         }
         customnewbalance.push(customnewbalancearr);
      });

      var jsonLeaveUpload = {
         "Leave_Balance_Bulk_Uploads": customnewbalance,
         "Company_Id": parseInt($('#Company_Id').val()),
         "EffectiveDate": this.commonService.dateFormatChange($("#effectiveDate").val()),
         "Operation": $('input[name="uploaded_field"]').filter(":checked").val()
      }

      this.commonApiService.PostRequest(Commonvariable.Leave_Balance, "AddBulkProratedLeaveBalance", jsonLeaveUpload).subscribe((result) => {
         if (result["status"] == 200) {
            this.toastr.success(result["message"], 'Leave Upload');
            //this.router.navigateByUrl('leave-upload');
            $("#effectiveDate").val('');
            $(".leave_bal_table").hide();
            this.moveWizard(1);

         }
      })


   }

   UpsertLeaveUpload() {

      if (this.validation()) {

         this.p = 1;
         var data = this.empform.value;



         if (data.emp_Leave.filter(x => x.ischecked).length == 0) {
            this.commonService.toaster("info", "Please select atleast one leave type !", "Leave upload");
            return false;
         } else {



            // data.emp_Leave.filter(x => x.ischecked).forEach(x => {                  


            //    if(Number.isInteger(x.leaveBalanceToUpdate)){
            //       console.log(x.leaveBalanceToUpdate);
            //       console.log(Number.isInteger(x.leaveBalanceToUpdate));
            //    }


            // });





            var json = {
               "Leave_Balance_Prorates": data.emp_Leave.filter(x => x.ischecked),
               "Company_Id": parseInt($('#Company_Id').val()),
               "EffectiveDate": this.commonService.dateFormatChange($("#effectiveDate").val()),
               "Operation": $('input[name="uploaded_field"]').filter(":checked").val()
            }



         }
         
         this.commonApiService.PostRequest(Commonvariable.Leave_Balance, "GetLeaveBalanceEmployeeList", json).subscribe((result) => {
            this.itemss = this.empnewleaveform.get('emp_new_Leave') as FormArray;
            this.itemss.clear();

            this.empBalanceList = [];            
            if (result["data"] != "") {
               var balanceIndex = 0;
               result["data"].forEach(x => {                  
                  let data = x;
                  var balance = {
                     index: balanceIndex,
                     emp_Id: data != null ? data.emp_Id : '0',
                     current_Balance: data != null ? data.current_Balance : '0',
                     leave_type_id: data != null ? data.leave_type_id : null,
                     firstName: data != null ? data.firstName : "",
                     lastName: data != null ? data.lastName : 0,
                     leaveType: data != null ? data.leaveType : 0,
                     new_Balance: data != null ? data.new_Balance : 0,
                     carryForward_Balance: data != null ? data.carryForward_Balance : 0,
                     uploaded_Balance: data != null ? data.uploaded_Balance : 0,
                     prev_Id: data != null ? data.prev_Id : 0,
                     ischecked: false,
                  };
                  balanceIndex++;
                  this.empBalanceList.push(balance);                       
               });
               this.wizardSetting("next");
            } else {
               this.commonService.toaster("warning", "Data not available!", "Leave upload");
               return false;
            }

         })
      }

   }


   UpsertEmpLeaveSummary() {

      this.p2 = 1;
      var data = this.empBalanceList;

      if (data.filter(x => x.ischecked).length == 0) {
         this.commonService.toaster("info", "Please select atleast one employee !", "Leave process");
         return false;
      } else {


         var customempbalanceforsummary = [];
         var balanceIndex = 0;
         data.forEach(x => {
            if (x.ischecked == true) {
               var employeeArr = {
                  "index": x.index,
                  "Emp_Id": x.emp_Id,
                  "FirstName": x.firstName,
                  "LastName": x.lastName,
                  "LeaveType": x.leaveType,
                  "LeaveTypeCode": x.leaveTypeCode,
                  "Leave_type_id": x.leave_type_id,
                  "Current_Balance": parseFloat(x.current_Balance),
                  "New_Balance": x.new_Balance > 0 ? x.new_Balance : 0 ,
                  "CarryForward_Balance": parseFloat(x.carryForward_Balance),
                  "Uploaded_Balance": parseFloat(x.uploaded_Balance),
                  "prev_Id": parseInt(x.prev_Id),
               }
               customempbalanceforsummary.push(employeeArr);
            }
         });

         this.commonApiService.PostRequest(Commonvariable.Leave_Balance, "GetLeaveBalanceEmployeeListForSummary", customempbalanceforsummary).subscribe((result) => {

            this.itemss = this.empleavesummeryform.get('emp_leave_summery') as FormArray;
            this.itemss.clear();
            if (result["data"] != "") {
               result["data"].forEach(x => {
                  this.itemss.push(this.empLeaveSummeryItem(x));
               });
            }
            this.wizardSetting("next");
         })
      }
   }

   moveWizard(id) {
      var movePermission = true;

      // if (this.activeWizard < id) {
      //    for (let index = 1; index < id; index++) {
      //       if (!this["companyW" + index].validation()) {
      //          movePermission = false;
      //          break;
      //       }
      //    }
      // }
      // else {
      //    if (!this["companyW" + this.activeWizard].validation()) {
      //       movePermission = false;
      //    }
      // }

      if (movePermission) {
         if (this.activeWizard < id) {
            this.activeWizard = id - 1;
            this.pbWidth = 33.33 * this.activeWizard
            this.wizardSetting("next")
         }
         else {
            this.activeWizard = id + 1;
            this.pbWidth = 33.33 * this.activeWizard;
            this.wizardSetting("back")
         }
      }
   }


   wizardSetting(event) {
      if (event == "next") {
         this.activeWizard++;
         this.pbWidth = this.pbWidth + 33.33;
      }
      else {
         this.activeWizard--;
         this.pbWidth = this.pbWidth - 33.33;
      }
      for (let index = 1; index <= 3; index++) {
         this["w" + index] = index == this.activeWizard ? "active" : index < this.activeWizard ? "done" : "";
         this["t" + index] = index == this.activeWizard ? "tab-pane active" : "tab-pane";
      }
      this.progressBarWidth = this.pbWidth + "%"
      this.backbutton = this.activeWizard > 1 ? "visible" : "hidden"
      this.btnName = this.activeWizard == 3 ? "Save" : "Continue"
   }


   // moveWizard(id) {
   //    var movePermission = true;

   //    if (this.validation()) {
   //       movePermission = false;
   //    }

   // }

   // wizardSetting(event) {
   //    if (event == "next") {
   //       this.activeWizard++;
   //       this.pbWidth = this.pbWidth + 50;
   //    }
   //    else {
   //       this.activeWizard--;
   //       this.pbWidth = this.pbWidth - 50;
   //    }
   //    for (let index = 1; index <= 2; index++) {
   //       this["w" + index] = index == this.activeWizard ? "active" : index < this.activeWizard ? "done" : "";
   //       this["t" + index] = index == this.activeWizard ? "tab-pane active" : "tab-pane";
   //    }
   //    this.progressBarWidth = this.pbWidth + "%"
   //    this.backbutton = this.activeWizard > 1 ? "visible" : "hidden"
   //    this.btnName = this.activeWizard == 2 ? "Save" : "Next"
   // }

   public checkAllLB(index: any, checkAllLB = false): void {

      if (checkAllLB) {
         this.empAllCheckLB = !this.empAllCheckLB;
         this.empform.get('emp_Leave')['controls'].forEach(x => {
            x.controls.ischecked.setValue(this.empAllCheckLB)
         });
      }
      else {
         if (this.empform.get('emp_Leave').value.filter(x => x.ischecked).length == this.empform.get('emp_Leave').value.length) {
            this.empAllCheckLB = true;
         }
         else {
            this.empAllCheckLB = false;
         }
      }


   }

   public checkAll(index: any, checkAll = false): void {

      if (checkAll) {
         this.empAllCheck = !this.empAllCheck;
         this.empnewleaveform.get('emp_new_Leave')['controls'].forEach(x => {
            x.controls.ischecked.setValue(this.empAllCheck)
         });
      }
      else {
         if (this.empnewleaveform.get('emp_new_Leave').value.filter(x => x.ischecked).length == this.empnewleaveform.get('emp_new_Leave').value.length) {
            this.empAllCheck = true;
         }
         else {
            this.empAllCheck = false;
         }
      }
   }

   exportTableToCSV() {
      var filename = Date.now() + ".csv";
      var csv = [];
      var rows = document.querySelectorAll("#sample_3 tr");
      for (var i = 0; i < rows.length; i++) {
         var row = [], cols = rows[i].querySelectorAll("td, th");

         for (var j = 0; j < cols.length; j++)
            row.push(cols[j].innerHTML);

         csv.push(row.join(","));
      }

      // Download CSV file
      this.downloadCSV(csv.join("\n"), filename);
   }
   downloadCSV(csv, filename) {
      var csvFile;
      var downloadLink;

      // CSV file
      csvFile = new Blob([csv], { type: "text/csv" });

      // Download link
      downloadLink = document.createElement("a");

      // File name
      downloadLink.download = filename;

      // Create a link to the file
      downloadLink.href = window.URL.createObjectURL(csvFile);

      // Hide download link
      downloadLink.style.display = "none";

      // Add the link to DOM
      document.body.appendChild(downloadLink);

      // Click download link
      downloadLink.click();
   }


   public balanceSet(index, keyname, event, checkAll = false) {

      if (checkAll) {
         this.empAllCheck = !this.empAllCheck;
         this.empBalanceList.forEach(element => {
            element.ischecked = this.empAllCheck;
         });
      }
      else {

         if (keyname == "ischecked") {
            this.empBalanceList[index][keyname] = $("#balanceIsChecked" + index).prop("checked")
         }
         else {
            this.empBalanceList[index][keyname] = event.target.value
         }

         if (this.empBalanceList.filter(x => x.ischecked).length == this.empBalanceList.length) {
            this.empAllCheck = true;
         }
         else {
            this.empAllCheck = false;
         }
      }

   }

   // Only Numbers with Decimals
   keyPressNumbersDecimal(event) {
      var charCode = (event.which) ? event.which : event.keyCode;
      if (charCode != 46 && charCode > 31
         && (charCode < 48 || charCode > 57)) {
         event.preventDefault();
         return false;
      }
      return true;
   }

}