<style>
    .mb-10 {
        margin-bottom: 10px;
    }
</style>
<form [formGroup]="scheduleInterviewform">
    <div class="form-group">
        <div class="table-resposive" style="max-height: 500px;overflow: auto;">
            <table class="table table-hover">
                <thead>
                    <tr>

                        <th style="width: 2%;">Sr No.</th>
                        <th>Interview Round<span class="required"> *
                            </span></th>
                        <th>Date<span class="required"> *
                            </span></th>
                        <th>Interviewer<span class="required"> *
                            </span></th>
                        <th>FeedBack</th>
                        <th>Notes</th>
                        <th style="width: 5%;"></th>
                        <!-- <th style="width: 5%;">
                            <button id="btnAdd" type="button" class="btn pull-right"
                                (click)="addscheduleInterview()"><i class="fa fa-plus" aria-hidden="true"></i></button>
                        </th> -->
                    </tr>
                </thead>
                <tbody formArrayName="scheduleInterview"
                    *ngFor="let item of scheduleInterviewform.get('scheduleInterview')['controls']; let i = index;">
                    <tr [formGroupName]="i">
                        <td>
                            {{i+1}}
                        </td>
                        <td>

                            <input type="text" class="form-control" id="name{{i}}" formControlName="name" maxlength="20"
                                autocomplete="off"
                                *ngIf="scheduleInterviewform.get('scheduleInterview').value[i].disabled"
                                [readonly]="scheduleInterviewform.get('scheduleInterview').value[i].disabled">

                            <ng-select2 [data]="interviewStageList" id="name{{i}}" formControlName="name"
                                *ngIf="!scheduleInterviewform.get('scheduleInterview').value[i].disabled"
                                [placeholder]="'--- Select ---'">
                            </ng-select2>


                        </td>
                        <td *ngIf="!scheduleInterviewform.get('scheduleInterview').value[i].disabled">
                            <!-- <input type="datetime-local" class="form-control" id="interview_date_time{{i}}"
                                formControlName="dateTime"
                                [readonly]="scheduleInterviewform.get('scheduleInterview').value[i].disabled"> -->


                            <div class="input-group flatpickr" id="candidate_interview_date_time{{i}}" data-wrap="true">
                                <input type="text" class="form-control" id="candidate_interview_date_times{{i}}"
                                    data-input formControlName="dateTime" />
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>

                        </td>
                        <td *ngIf="scheduleInterviewform.get('scheduleInterview').value[i].disabled">
                            <input type="text" class="form-control" readonly
                                value="{{scheduleInterviewform.get('scheduleInterview').value[i].dateTime}}">
                        </td>
                        <td>
                            <div formArrayName="interviewDetail"
                                *ngFor="let interview of interviewDetail(i).controls; let y = index;" class="mb-10">
                                <div [formGroupName]="y">
                                    <select class="form-control" id="interviewer{{i}}{{y}}"
                                        formControlName="interviewer"
                                        [attr.disabled]="scheduleInterviewform.get('scheduleInterview').value[i].disabled ? '':null">
                                        <option value="">Select Interviewer</option>
                                        <option *ngFor="let item of empList" value="{{item.emp_Id}}">{{item.firstName}}
                                            {{item.lastName}}</option>
                                    </select>
                                </div>
                            </div>
                        </td>
                        <td *ngIf="scheduleInterviewform.get('scheduleInterview').value[i].disabled">
                            <div formArrayName="interviewDetail"
                                *ngFor="let interview of interviewDetail(i).controls; let g = index;" class="mb-10">
                                <div [formGroupName]="g">
                                    <input type="text" class="form-control" readonly
                                        value="{{scheduleInterviewform.get('scheduleInterview').value[i].interviewDetail[g].status}}">
                                </div>
                            </div>
                        </td>
                        <td *ngIf="!scheduleInterviewform.get('scheduleInterview').value[i].disabled">

                        </td>
                        <td *ngIf="scheduleInterviewform.get('scheduleInterview').value[i].disabled">
                            <div formArrayName="interviewDetail"
                                *ngFor="let interview of interviewDetail(i).controls; let h = index;" class="mb-10">
                                <div [formGroupName]="h">

                                    <textarea class="form-control" readonly rows="2"
                                        value="{{scheduleInterviewform.get('scheduleInterview').value[i].interviewDetail[h].notes}}"></textarea>
                                </div>
                            </div>
                        </td>
                        <td *ngIf="!scheduleInterviewform.get('scheduleInterview').value[i].disabled">

                        </td>
                        <td>
                            <div formArrayName="interviewDetail"
                                *ngFor="let interview of interviewDetail(i).controls; let iv = index;" class="mb-10">
                                <div [formGroupName]="iv">
                                    <button *ngIf="iv==0" type="button" class="btn" id="btnadd{{i}}{{iv}}"
                                        (click)="addInterviewDetail(i)"
                                        [disabled]="scheduleInterviewform.get('scheduleInterview').value[i].disabled"><i
                                            class="fa fa-plus" aria-hidden="true"></i></button>
                                    <button *ngIf="iv > 0" type="button" class="btn" id="btnremove{{i}}{{iv}}"
                                        (click)="removeInterviewDetail(i,iv)"
                                        [disabled]="scheduleInterviewform.get('scheduleInterview').value[i].disabled"><i
                                            class="fa fa-minus" aria-hidden="true"></i></button>
                                </div>
                            </div>

                        </td>
                        <!-- <td>
                            <button *ngIf="i>0" type="button" id="btnAdd{{i}}" (click)="removecheduleInterview(i)"
                                [disabled]="scheduleInterviewform.get('scheduleInterview').value[i].disabled"
                                class="btn pull-right"><i class="fa fa-minus" aria-hidden="true"></i></button>
                        </td> -->
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</form>