import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from '../history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { CandidateAdvancedSearchComponent } from './onboarding-advanced-search/candidate-advanced-search.component'
import { CandidateViewSummaryComponent } from './onboarding-view-summary/candidate-view-summary.component'
import { DomSanitizer } from '@angular/platform-browser';
import { SendMailComponent } from '../sendmail/sendmail.component'
declare var $: any;
declare var tinymce: any;


@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html'
})
export class OnboardingComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  @ViewChild(CandidateAdvancedSearchComponent, { static: false }) advancedSearch: CandidateAdvancedSearchComponent;
  @ViewChild(CandidateViewSummaryComponent, { static: false }) viewSummary: CandidateViewSummaryComponent;
  @ViewChild(SendMailComponent, { static: false }) MailSend: SendMailComponent;


  totalRecord: 0;
  candidateform: FormGroup;
  moveEmployee: boolean = false;
  employeeStatusReadyToCreate: boolean = false;
  candidatelist: any;
  candidateStatus: any;
  filterList: any;
  filterCandidate: any;
  candidateList: any;
  listCall: boolean = true;
  companylist: any;
  siteList: any;
  departmentList: any;
  designationList: any;
  filterAdvance: boolean = false;
  JDList: any;
  zoneList: any;
  shiftList: any;
  title: string;
  Toast: any;
  p: number = 1;
  viewSummaryStatus: boolean = false
  companyId: any;
  onboardingId: any;
  mailsend: boolean = true;
  advancedFilterData: any;
  SummaryId: number = 0;
  modelpop: boolean = false;
  fileUrl;
  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,private sanitizer: DomSanitizer,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.title = "Add";
    this.candidateform = this.fb.group({
      id: ['0'],
      company_Id: ['', Validators.required],
      site_Id: ['', Validators.required],
      dept_Id: ['', Validators.required],
      zone_Id: ['', Validators.required],
      desig_Id: ['', Validators.required],
      jD_Id: ['', Validators.required],
      shift_Id: ['', Validators.required],
      mobile: ['', Validators.required],
      eMail: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      totalExperience: [null],
      referenceFrom: [null],
      referenceBy: [''],
      jobStartDate: ['', Validators.required],
      notes: [null],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [0]
    });
    this.commonService.flatpickr("jobStartDate", 0);



    //this.history()
    if (this.listCall) {
      this.getStatus();
      //this.statusWiseFilter();
      //this.getStatus();
      //this.getCompanyList();
    }
    this.commonService.myData.subscribe((data) => {
      this.getCandidateListCan();
    });





  }


  getCandidateListCan(PageIndex = 1) {
    

    this.p = PageIndex;
    this.totalRecord = 0;
    this.candidateList = [];
    var requestData
    if (this.advancedFilterData != "" && this.advancedFilterData != null && this.advancedFilterData != 'undefined') {
      requestData = {
        "searchBy": this.advancedFilterData,
        "PageIndex": PageIndex - 1,
        "CommonSearch": $("#filterList").val().trim(),
        "PageSize": 10,
      }
    } else {
      requestData = {

        "searchBy": [
          {
            "FieldName": "Status",
            "FieldValue": $("#status").val() == "All" ? JSON.stringify('Close') : $("#status").val() == undefined ? JSON.stringify("") : JSON.stringify($("#status").val()),
            "Parameter": $("#status").val() == "All" ? "!=" : "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "isActive",
            "FieldValue": "null",
            "Parameter": "!="
          }
        ],
        "PageIndex": PageIndex - 1,
        "CommonSearch": $("#filterList").val().trim(),
        "PageSize": 10,
      }

    }


    this.commonApiService.getPaginated(Commonvariable.Candidate, null, null, null, null, null, requestData).subscribe((result) => {

      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.candidateList = result["data"]["list"];
        this.filterCandidate = result["data"]["list"];
      }
    })
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);
  }

  getCandidateList(PageIndex = 1) {


    if ($("input[name='filter']:checked").val() != undefined && $("#filterValue").val() != "") {
      this.searchOneValue(PageIndex)
    }
    else if (this.filterAdvance) {
      this.advanceFilter(null, PageIndex)
    }
    else {

      this.totalRecord = 0;
      this.candidatelist = [];
      this.filterList = [];
      this.p = PageIndex;


      var requestData = {
        "searchBy": [
          {
            "FieldName": "Status",
            "FieldValue": $("#status").val() == "All" ? JSON.stringify('Close') : JSON.stringify($("#status").val()),
            "Parameter": $("#status").val() == "All" ? "!=" : "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "isActive",
            "FieldValue": "null",
            "Parameter": "!="
          }
        ],
        "PageIndex": PageIndex - 1,
        "CommonSearch": $("#filterList").val().trim(),
        "PageSize": 10

      }



      this.commonApiService.GetByPaginated(Commonvariable.Candidate, requestData, "GetPaginated").subscribe((result) => {
        if (result != null && result["status"] == 200) {
          this.totalRecord = result["data"]["recordCount"]
          this.candidatelist = result["data"]["list"];
          if ($("#status").val() == 'All') {
            this.filterList = result["data"]["list"];
          } else {
            this.filterList = result["data"]["list"].filter(x => x.status == $("#status").val());
          }
          // this.getStatus();
          //this.statusWiseFilter(PageIndex);
        }
      })
    }

    // this.commonApiService.List(Commonvariable.Candidate).subscribe((result) => {
    //   if (result != null && result["data"] != "") {
    //     this.candidatelist = result["data"];
    //     if ($("#status").val() == 'All') {
    //       this.filterList = result["data"];
    //     } else {
    //       this.filterList = result["data"].filter(x => x.status == $("#status").val());
    //     }
    //     this.getStatus();
    //     this.statusWiseFilter();
    //   }
    // })

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);
  }

  getStatus() {
    this.commonApiService.List(Commonvariable.Onboarding_Status).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.candidateStatus = response["data"].filter(x => x.isActive != 0);
        this.getCandidateListCan();
      }
    })
  }

  getCompanyList() {
    this.commonApiService.List(Commonvariable.Company).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companylist = response["data"].filter(x => x.isActive != 0);
      }
    })
  }

  getSite(id = 0) {
    this.commonApiService.cascadingDropDwon(Commonvariable.Site, "company_Id", id > 0 ? id : $("#company_Id").val()).subscribe((response) => {
      if (response["data"] != "") {
        this.siteList = response["data"];
        this.getDepartment(id);
      }
    }, (error) => { this.toastr.error(error["error"].message, 'Candidate'); })
  }

  getDepartment(id = 0) {
    this.commonApiService.cascadingDropDwon(Commonvariable.Department, "company_Id", id > 0 ? id : $("#company_Id").val()).subscribe((response) => {
      if (response != null) {
        this.departmentList = response["data"];
        this.getZone(id);
      }
    }, (error) => { this.toastr.error(error["error"].message, 'Candidate'); })
  }

  getZone(id = 0) {
    this.commonApiService.cascadingDropDwon(Commonvariable.Zone, "company_Id", id > 0 ? id : $("#company_Id").val()).subscribe((response) => {
      if (response != null) {
        this.zoneList = response["data"];
        this.getDesignation(id);
      }
    }, (error) => { this.toastr.error(error["error"].message, 'Candidate'); })
  }

  getDesignation(id = 0) {
    this.commonApiService.cascadingDropDwon(Commonvariable.Designation, "company_Id", id > 0 ? id : $("#company_Id").val()).subscribe((response) => {
      if (response != null) {
        this.designationList = response["data"];
        this.getJD(id);
      }
    }, (error) => { this.toastr.error(error["error"].message, 'Candidate'); })
  }

  getJD(id = 0) {
    this.commonApiService.cascadingDropDwon(Commonvariable.JobDescription, "company_Id", id > 0 ? id : $("#company_Id").val()).subscribe((response) => {
      if (response != null) {
        this.JDList = response["data"];
        this.getshift(id);
      }
    }, (error) => { this.toastr.error(error["error"].message, 'Candidate'); })
  }

  getshift(id = 0) {
    this.commonApiService.cascadingDropDwon(Commonvariable.Shift, "company_Id", id > 0 ? id : $("#company_Id").val()).subscribe((response) => {
      if (response != null) {
        this.shiftList = response["data"];
      }
    }, (error) => { this.toastr.error(error["error"].message, 'Candidate'); })
  }


  Upsert() {
    var formData = this.candidateform;
    if (this.validation()) {
      this.commonApiService.Upsert(Commonvariable.Candidate, formData.value, formData.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Candidate');
          }
          else {
            $("#draggable").modal('hide');
            // $('a[href="#docUpload"]').click();
            this.getCandidateListCan();
            this.toastr.success(formData.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Candidate');
          }
        }
      })
    }

  }

  Delete(id) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.OnboardingDetail, id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getCandidateListCan();
          this.toastr.success(response["message"], 'Candidate');
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.OnboardingDetail, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.toastr.success(response["message"], 'Candidate');
            this.ngOnInit();
          }

        })
      }

    });
  }

  clickOnModal() {
    $("body").css({ overflow: 'hidden' });
    $('body').css('position', 'fixed');
    $('#filterModal').modal({
      backdrop: 'static', keyboard: false, show: false
    })

  }

  openModel(id) {
    if (id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Candidate, id).subscribe((response) => {
        if (response != null) {
          var editData = response["data"];
          var formData = this.candidateform;
          this.commonService.setEditData(formData, editData);
          let jobStartDate = response["data"][0].jobStartDate != null ? response["data"][0].jobStartDate.split("T")[0].split("-") : "";
          if (jobStartDate != "") {
            $("#jobStartDate").val(jobStartDate[2] + "-" + jobStartDate[1] + "-" + jobStartDate[0]);
          }
          this.getSite(editData[0].company_Id);
          // formData.controls.updatedBy.setValue(Commonvariable.User_Id)
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Candidate'); })
    }
    else {
      $("#zone_Code").removeAttr("disabled")
      this.listCall = false;
      this.siteList = [];
      this.departmentList = [];
      this.zoneList = [];
      this.designationList = [];
      this.JDList = [];
      this.shiftList = [];
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });


  }

  filterData(event) {
    const val = event.target.value.toLowerCase().trim();
    if (val == "") {
      if ($("#status").val() == 'All') {
        this.candidatelist = this.filterList;
      } else {
        this.candidatelist = this.filterList.filter(x => x.status == $("#status").val());
      }

      this.p = 1;
    }
    else {

      // this.filterList.forEach(d => {
      //   if (d.joiningDate != null) {
      //     var datearray =  String(val.split("T")[0]).split("-") ;
      //     var newdate = datearray[2] + '-' + datearray[1] + '-' + datearray[0];
      //   }
      // });


      const temp = this.filterList.filter(function (d) {

        if (d["joiningDate"]) {
          var date = val;
          var datearray = date.split("/");
          var newdate = datearray[2] + '-' + datearray[1] + '-' + datearray[0];
        }

        return String(d["eMail"]).toLowerCase().indexOf(val) !== -1 || String(d["firstName"]).toLowerCase().indexOf(val) !== -1 ||
          String(d["middleName"]).toLowerCase().indexOf(val) !== -1 || String(d["lastName"]).toLowerCase().indexOf(val) !== -1 ||
          String(d["company_Name"]).toLowerCase().indexOf(val) !== -1 || String(d["dept_Name"]).toLowerCase().indexOf(val) !== -1 ||
          String(d["desig_Name"]).toLowerCase().indexOf(val) !== -1 || String(d["mobile"]).toLowerCase().indexOf(val) !== -1 ||
          String(d["joiningDate"]).toLowerCase().indexOf(newdate) !== -1 || String(d["status"]).toLowerCase().indexOf(val) !== -1 || !val;
      });
      // const temp = this.commonService.filterData(event, this.candidatelist, "firstName");
      this.candidatelist = temp;
      this.p = 1;
    }
  }

  validation() {
    var formData = this.candidateform;
    formData.controls.jobStartDate.setValue(this.commonService.dateFormatChange($("#jobStartDate").val()));
    if (!formData.valid) {
      for (const key of Object.keys(formData.controls)) {
        if (formData.controls[key].invalid) {
          let fieldName = "";
          if (key == "company_Id") {
            fieldName = "Company"
          }
          else if (key == "site_Id") {
            fieldName = "Site"
          }
          else if (key == "dept_Id") {
            fieldName = "Department"
          }
          else if (key == "zone_Id") {
            fieldName = "Zone"
          }
          else if (key == "desig_Id") {
            fieldName = "Designation"
          }
          else if (key == "jD_Id") {
            fieldName = "Job description"
          }
          else if (key == "shift_Id") {
            fieldName = "Shift"
          }
          else if (key == "mobile") {
            fieldName = "Mobile"
          }
          else if (key == "eMail") {
            fieldName = "Email"
          }
          else if (key == "firstName") {
            fieldName = "First name"
          }
          else if (key == "middleName") {
            fieldName = "Middle name"
          }
          else if (key == "lastName") {
            fieldName = "Last name"
          }
          else if (key == "jobStartDate") {
            fieldName = "Job start date"
          }
          this.toastr.info(fieldName + " is required !", "Candidate");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      // if ($("#mobile").val().length < 9) {
      //   this.toastr.info("Mobile must be at least 9 characters long !", "Candidate");
      //   $("#mobile").focus();
      //   return false;
      // }
      if ($("#mobile").val().search(/[^0-9]+/) > -1) {
        this.toastr.info("Mobile only numeric value allow !", "Candidate");
        $("#mobile").focus();
        return false;
      }
      if ($("#eMail").val().length < 2) {
        this.toastr.info("Email must be at least 2 characters long !", "Candidate");
        $("#eMail").focus();
        return false;
      }
      // if ($("#firstName").val().length < 2) {
      //   this.toastr.info("First name must be at least 2 characters long !", "Candidate");
      //   $("#firstName").focus();
      //   return false;
      // }
      if ($("#firstName").val() != "" && /["\\]/.test($("#firstName").val())) {
        this.toastr.info("First name does not allow Special Characters \" \\ ", "Employee");
        $("#firstName").focus();
        return false;
      }
      // if ($("#firstName").val().search(/[^a-zA-Z-]+/) > -1) {
      //   this.toastr.info("First name only alphabets value allow !", "Candidate");
      //   $("#firstName").focus();
      //   return false;
      // }
      // if ($("#middleName").val().length < 1) {
      //   this.toastr.info("Middle name must be at least 1 characters long !", "Candidate");
      //   $("#middleName").focus();
      //   return false;
      // }
      if ($("#middleName").val() != "" && /["\\]/.test($("#middleName").val())) {
        this.toastr.info("Middle name does not allow Special Characters \" \\ ", "Employee");
        $("#middleName").focus();
        return false;
      }
      // if ($("#middleName").val().search(/[^a-zA-Z]+/) > -1) {
      //   this.toastr.info("Middle name only alphabets value allow !", "Candidate");
      //   $("#middleName").focus();
      //   return false;
      // }
      // if ($("#lastName").val().length < 2) {
      //   this.toastr.info("Last name must be at least 2 characters long !", "Candidate");
      //   $("#lastName").focus();
      //   return false;
      // }

      if ($("#lastName").val() != "" && /["\\]/.test($("#lastName").val())) {
        this.toastr.info("Last name does not allow Special Characters \" \\ ", "Employee");
        $("#lastName").focus();
        return false;
      }


      // if ($("#lastName").val().search(/[^a-zA-Z-]+/) > -1) {
      //   this.toastr.info("last name only alphabets value allow !", "Candidate");
      //   $("#lastName").focus();
      //   return false;
      // }

    }
    return true;
  }

  history() {
    //this.historyView.getHistoryList('\"onboarding\"', '\"Id\"', '\"' + this.candidateform.value.id + '\"');
  }

  getSummary(id, meid, empstatus, isActive = 0) {

    this.modelpop = true;
    this.SummaryId = id;
    // $("#viewSummary").modal("show");
    $('#viewSummary').modal({
      backdrop: 'static'
    });

    if (meid != 1)
      this.moveEmployee = true;
    if (empstatus == 'ReadyToCreate')
      this.employeeStatusReadyToCreate = true;
    else { this.employeeStatusReadyToCreate = false; }
    this.viewSummaryStatus = isActive == 0 ? true : false
  }


  advanceFilterCan() {

    var data = {
      "SearchBy": this.advancedSearch.search()
    }

    if (data.SearchBy) {



      if (data.SearchBy.filter(x => x.FieldName == 'Status').length > 0) {
        data.SearchBy = data.SearchBy.filter(x => x.FieldName != 'Status');
      }
      if (data.SearchBy.filter(x => x.FieldName == 'isActive').length > 0) {
        data.SearchBy = data.SearchBy.filter(x => x.FieldName != 'isActive');
      }
      data.SearchBy.push({
        "FieldName": "Status",
        "FieldValue": $("#status").val() == "All" ? JSON.stringify('Close') : JSON.stringify($("#status").val()),
        "Parameter": $("#status").val() == "All" ? "!=" : "==",
        "ConditionWith": "&&"
      },
        {
          "FieldName": "isActive",
          "FieldValue": "null",
          "Parameter": "!="
        })

      data.SearchBy[data.SearchBy.length - 3]["ConditionWith"] = "&&"

      this.advancedFilterData = data.SearchBy;


      if (data.SearchBy[0]["FieldName"] != "" && data.SearchBy[0]["FieldValue"] != "") {
        this.candidateList = []
        this.totalRecord = 0

        this.commonApiService.getPaginated(Commonvariable.Candidate, null, null, null, null, 10, data).subscribe((result) => {
          if (result != null && result["data"] != "") {
            this.candidateList = result["data"]["list"];
            this.totalRecord = result["data"]["recordCount"];
            this.p = 1
          }
          $("#filterModal").modal("hide");
        })
      }
    }

  }


  advanceFilter(sData = null, pageindex = 1) {
    var data;
    if (sData != null) {
      data = sData;
    }
    else {

      this.p = pageindex;

      data = {
        "SearchBy": this.advancedSearch.search() == false ? [] : this.advancedSearch.search(),
        "PageIndex": pageindex - 1,
        "PageSize": 10,
      }


      if (data.SearchBy) {


        data.SearchBy.push({
          "FieldName": "Status",
          "FieldValue": $("#status").val() == "All" ? JSON.stringify('Close') : JSON.stringify($("#status").val()),
          "Parameter": $("#status").val() == "All" ? "!=" : "==",
        })

        data.SearchBy[data.SearchBy.length - 1]["ConditionWith"] = "&&"
        data.SearchBy[data.SearchBy.length - 2]["ConditionWith"] = "&&"

        data.SearchBy.push({
          "FieldName": "isActive",
          "FieldValue": "null",
          "Parameter": "!=",
        })







        this.filterAdvance = true;
      }
      if (data.SearchBy.length > 0) {

        if (data.SearchBy[0]["FieldName"] != "" && data.SearchBy[0]["FieldValue"] != "") {


          this.commonApiService.getPaginated(Commonvariable.OnboardingDetail, null, null, null, null, 10, data).subscribe((result) => {
            if (result != null && result["data"] != "" && result["status"] == 200) {
              this.candidatelist = result["data"]["list"];
              this.totalRecord = result["data"]["recordCount"];
              this.filterList = result["data"]["list"];
            }
            $("body").css({ overflow: 'inherit' });
            $('body').css('position', 'unset');
            $("#filterModal").modal("hide");
          })

        }
        else {
          this.candidatelist = this.filterList;
          this.filterAdvance = false;
          $("body").css({ overflow: 'inherit' });
          $('body').css('position', 'unset');
          $("#filterModal").modal("hide");
        }
      }

    }

  }

  searchOneValue(PageIndex = 1) {
    this.p = PageIndex;
    var data = {
      "SearchBy": [{
        "FieldName": $("input[name='filter']:checked").val(),
        "FieldValue": '"' + $.trim($("#filterValue").val()) + '\"',
        "Parameter": '==',
        "OrderBy": 'emp_Id'
      }],
      "PageIndex": PageIndex - 1,
      "PageSize": 10,
    }
    if ($("input[name='filter']:checked").val() != undefined && $.trim($("#filterValue").val()) != "")
      this.advanceFilter(data)
  }

  statusWiseFilter(PageIndex = 1) {

    var SelVal = $("#status").val();

    if (SelVal == 'All') {
      var data = {
        "SearchBy": [{
          "FieldName": "Status",
          "FieldValue": '""',
          "Parameter": '!='
        }]
      }
      this.advanceFilter(data, PageIndex)
    } else {
      var data = {
        "SearchBy": [{
          "FieldName": "Status",
          "FieldValue": '"' + $("#status").val() + '\"',
          "Parameter": '=='
        }]
      }
      this.advanceFilter(data, PageIndex)

    }


  }

  clear() {
    this.filterAdvance = false;
    this.advancedSearch.ngOnInit();
    this.advancedFilterData = [];
    this.getCandidateListCan();
    // this.candidatelist = this.filterList;

    this.p = 1;

    $("body").css({ overflow: 'inherit' });
    $('body').css('position', 'unset');

    $("#filterModal").modal("hide");
    $(".md-radiobtn").prop("checked", false)
    $("#filterValue").val("");
    $("#filterList").val("");
    //$("#status").val("Open")

  }

  clearSearch() {
    $(".md-radiobtn").prop("checked", false)
    this.filterAdvance = false;
    $("#filterValue").val("");
    $("#filterList").val("");
    this.getCandidateListCan();
    this.p = 1;

  }

  SendMail(id, company_Id = 0) {
    this.companyId = company_Id
    this.onboardingId = id;

    this.MailSend.docFiles = [];
    this.MailSend.documentName = "Attach File";
    this.commonService.dyanmicConfirm("You Want Send Mail To Candidate !", "Yes !").then((result) => {
      if (result.value) {

        this.commonApiService.getById(Commonvariable.OnboardingDetail, id, "GetSendMailData").subscribe((response) => {
          if (response["data"] != "") {
            this.MailSend.roleIdValue = null;
            $('#SendMail').modal({
              backdrop: 'static'
            });

            setTimeout(() => {
              $("#SendMail #to").val(response["data"]["to"])
              $("#SendMail #cc").val(response["data"]["cc"])
              $("#SendMail #bcc").val(response["data"]["bcc"])
              $("#SendMail #subject").val(response["data"]["subject"])
              $('#SendMail #emailsend').summernote('code', response["data"]["body"]);
            }, 500);

          }
        })
      }
    })
  }

  reviewCandidate(id, status, company_Id = 0) {
    this.commonService.dyanmicConfirm("You Want To Change Status Of Candidate !", "Yes !").then((result) => {
      if (result.value) {
        var ids = id + "," + status + "," + Commonvariable.User_Id
        this.commonApiService.Put(Commonvariable.OnboardingDetail, ids, "StatusChange").subscribe((response) => {
          if (response != null) {
            if (status == "UserCreation" || status == "Reviewed") {
              this.commonApiService.getById(Commonvariable.OnboardingDetail, id, "GetSendMailData").subscribe((response) => {
                if (response["data"] != "") {
                  this.onboardingId = id;
                  this.MailSend.roleIdValue = null;
                  this.companyId = company_Id;
                  $('#SendMail').modal({
                    backdrop: 'static'
                  });

                  setTimeout(() => {
                    $("#SendMail #to").val(response["data"]["to"])
                    $("#SendMail #cc").val(response["data"]["cc"])
                    $("#SendMail #bcc").val(response["data"]["bcc"])
                    $("#SendMail #subject").val(response["data"]["subject"])
                    $('#SendMail #emailsend').summernote('code', response["data"]["body"]);
                  }, 500);

                }
              })
            }
            else {
              this.toastr.success(response["message"], "Candidate");
            }
            this.getCandidateListCan();
          }
        })
      }
    })
  }


  candidateReview(id, status, company_Id = 0) {
    this.companyId = company_Id;
    this.onboardingId = id;
    this.commonService.dyanmicConfirmwithdenybutton("Do you want to inform candidate that their information has been processed correctly ?", "Yes !", "Skip").then((result) => {
      if (result.value) {
        var ids = id + "," + status + "," + Commonvariable.User_Id
        this.commonApiService.Put(Commonvariable.OnboardingDetail, ids, "StatusChange").subscribe((response) => {
          if (response != null) {
            this.commonApiService.getById(Commonvariable.OnboardingDetail, id, "GetSendMailData").subscribe((response) => {
              if (response["data"] != "") {
                this.MailSend.roleIdValue = null;
                $('#SendMail').modal({
                  backdrop: 'static'
                });

                setTimeout(() => {
                  $("#SendMail #to").val(response["data"]["to"])
                  $("#SendMail #cc").val(response["data"]["cc"])
                  $("#SendMail #bcc").val(response["data"]["bcc"])
                  $("#SendMail #subject").val(response["data"]["subject"])
                  $('#SendMail #emailsend').summernote('code', response["data"]["body"]);
                }, 500);
              }
            })
            this.getCandidateListCan();
          }
        })
      }
      else if (result.isDenied) {
        var ids = id + "," + status + "," + Commonvariable.User_Id
        this.commonApiService.Put(Commonvariable.OnboardingDetail, ids, "StatusChange").subscribe((response) => {
          this.getCandidateListCan();
        })
      }
    })
  }






  moveToEmployee(id, company_Id = 0) {
    this.companyId = company_Id;
    this.commonService.dyanmicConfirm("You Want This Candidate Move To Employee !", "Yes !").then((result) => {
      if (result.value) {
        this.mailsend = false;
        this.commonApiService.Post(Commonvariable.OnboardingDetail, id, "MoveToEmployee").subscribe((response) => {
          if (response != null) {
            this.getCandidateListCan();
            if (response["data"]["emp_Id"] > 0) {

              this.MailSend.docFiles = [];
              this.MailSend.documentName = "Attach File";

              this.commonApiService.getById(Commonvariable.EmployeeDetail, response["data"]["emp_Id"], "GetSignupMailData").subscribe((response) => {
                if (response["data"] != "") {

                  this.onboardingId = 0
                  this.MailSend.roleIdValue = null;
                  $('#SendMail').modal({
                    backdrop: 'static'
                  });

                  setTimeout(() => {
                    $("#SendMail #to").val(response["data"]["to"])
                    $("#SendMail #cc").val(response["data"]["cc"])
                    $("#SendMail #bcc").val(response["data"]["bcc"])
                    $("#SendMail #subject").val(response["data"]["subject"])
                    $('#SendMail #emailsend').summernote('code', response["data"]["body"]);
                  }, 500);
                }
              })
              this.toastr.success(response["message"], "Onboarding");
            }

            // this.getCandidateListCan()

          }
        })
      }
    })
  }

  activeClass() {
    $(".candidate").removeClass('active');
    $(".candidateadd").addClass('active');
  }

  sendMail() {
    const formData = new FormData();
    formData.append('To', $("#SendMail #to").val());
    formData.append('CC', $("#SendMail #cc").val());
    formData.append('BCC', $("#SendMail #bcc").val());
    formData.append('Subject', $("#SendMail #subject").val());
    formData.append('Role_Ids', this.MailSend.roleId != undefined ? this.MailSend.roleId : null);
    formData.append('Onboarding_Id', this.onboardingId);
    formData.append('Body', $('#SendMail #emailsend').summernote('code'));

    formData.append('Company_Id', this.companyId);

    if (this.MailSend.docFiles != undefined) {
      for (var i = 0; i < this.MailSend.docFiles.length; i++) {
        formData.append('file', this.MailSend.docFiles[i]);
      }
    }
    this.commonApiService.UploadLogo(Commonvariable.OnboardingDetail, "SendMail_Onboarding", formData).subscribe((response) => {
      let postRequest = {
        "Notification_Type_Id": 1,
        "Company_Id": this.companyId,
        "Mail_To": $("#SendMail #to").val(),
        "Mail_CC": $("#SendMail #CC").val(),
        "Mail_BCC": $("#SendMail #BCC").val(),
        "Role_Id": this.MailSend.roleId != "undefined" ? this.MailSend.roleId : null,
        "Subject": $("#SendMail #subject").val(),
        "Body": $('#SendMail #emailsend').summernote('code'),
        "isActive": 1,
        "AddedBy": Commonvariable.User_Id
      }

      if (this.onboardingId != 0) {
        this.commonApiService.Upsert(Commonvariable.Notification, postRequest, 0).subscribe((response) => {
          this.getCandidateListCan()
          this.commonService.toaster("success", "Mail Sent Successfully !", "Onboarding");
        })
      }
      else {
        this.mailsend = true;
        this.getCandidateListCan();
        this.commonService.toaster("success", "Mail Sent Successfully !", "Onboarding");
      }

    });


  }

  userCreation(id, status, company_Id = 0) {

    this.commonApiService.List(Commonvariable.OnboardingDetail, "CheckRule").subscribe((result) => {

      if (!result["data"]) {
        this.commonService.dyanmicConfirm("Rule For User Creation Not Available, Do You Want To Change Status Of Candidate to Ready to Create !", "Yes !").then((result) => {
          if (result.value) {
            status = 'ReadyToCreate';
            var ids = id + "," + status + "," + Commonvariable.User_Id
            this.commonApiService.Put(Commonvariable.OnboardingDetail, ids, "StatusChange").subscribe((response) => {
              if (response != null) {
                this.toastr.success(response["message"], "Candidate");
                this.commonService.myData.next(!this.commonService.myData);
                this.getCandidateListCan();
              }
            })
          }
        })
      }
      else {


        this.commonService.dyanmicConfirm("You Want To Change Status Of Candidate !", "Yes !").then((result) => {
          if (result.value) {
            var ids = id + "," + status + "," + Commonvariable.User_Id
            this.commonApiService.Put(Commonvariable.OnboardingDetail, ids, "StatusChange").subscribe((response) => {
              if (response != null) {
                if (status == "UserCreation" || status == "Reviewed") {

                  this.MailSend.docFiles = [];
                  this.MailSend.documentName = "Attach File";

                  this.commonApiService.getById(Commonvariable.OnboardingDetail, id, "GetSendMailData").subscribe((response) => {
                    if (response["data"] != "") {
                      this.onboardingId = id;
                      this.companyId = company_Id;
                      this.MailSend.roleIdValue = null;
                      $('#SendMail').modal({
                        backdrop: 'static'
                      });

                      setTimeout(() => {
                        $("#SendMail #to").val(response["data"]["to"])
                        $("#SendMail #cc").val(response["data"]["cc"])
                        $("#SendMail #bcc").val(response["data"]["bcc"])
                        $("#SendMail #subject").val(response["data"]["subject"])
                        $('#SendMail #emailsend').summernote('code', response["data"]["body"]);
                      }, 500);

                    }
                  })
                }
                else {
                  this.toastr.success(response["message"], "Candidate");
                }
                this.commonService.myData.next(!this.commonService.myData);
                this.getCandidateListCan();
              }
            })
          }
        })
      }

    })


  }
  closepoopup() {
    this.modelpop = false;
    $("#viewSummary").modal("hide");
    // this.viewSummary.
  }
  // download() {
  //   debugger;

  //   if (parseInt(this.candidateform.controls.jD_Id.value) > 0) {
  //     //var fileName = String(this.jobTitleList.filter(x => x.jD_Id == this.empJobDetailform.controls.JD_Id.value)[0]["path"]).split(".")[1]
  //     //this.JDescription.download(fileName == undefined ? ".pdf" : fileName, parseInt(this.vacancyForm.controls.JD_Id.value));
  //     this.commonApiService.getimage(Commonvariable.JobDescription, parseInt(this.candidateform.controls.jD_Id.value.jD_Id), "DownloadDocument").subscribe((resp: any) => {

  //       if (resp.type != "application/json") {
  //         const blobData = new Blob([resp], { type: resp.type });
  //         var createObjectURL = (window.URL.createObjectURL(blobData));
  
  //         if (resp.type == "application/pdf" || resp.type == "image/jpeg" || resp.type == "image/png") {
  //           this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
  //           $("#modalJobDescription").modal("show")
  //         } else if (resp.type == "application/vnd.ms-word") {
  //           const a = document.createElement('a');
  //           a.setAttribute('type', 'hidden');
  //           a.href = URL.createObjectURL(blobData);
  //           a.download = 'Job_Description.docx';
  //           a.click();
  //           a.remove();
  //         } else {
  
  //           const a = document.createElement('a');
  //           a.setAttribute('type', 'hidden');
  //           a.href = URL.createObjectURL(blobData);
  //           a.download = 'Job_Description';
  //           a.click();
  //           a.remove();
  
  //         }
  //       }else{
  //         this.commonService.toaster("warning", 'Document Not Available', 'Job Description')
  //       }
        
  //     });
  //   }
  // }
  // modalJobDescriptionclose(){
  //   $("#modalJobDescription").modal("hide");
  // }

}
