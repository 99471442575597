import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { ScheduleInterviewStatusComponent } from "../scheduleInterviewforstatus/scheduleInterviewforstatus.component";
import { InterviewFeedbackComponent } from "../interviewFeedback/interviewFeedback.component";
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Select2OptionData } from 'ng-select2';
declare var $: any;


@Component({
  selector: 'app-selection-status-wise',
  templateUrl: './selection-status-wise.component.html'
})
export class SelectionStatusWiseComponent implements OnInit {
  @ViewChild(ScheduleInterviewStatusComponent, { static: false }) ScheduleInterview: ScheduleInterviewStatusComponent;
  @ViewChild(InterviewFeedbackComponent, { static: false }) InterviewFeedback: InterviewFeedbackComponent;

  isFinal: boolean = false;
  p1: number = 1;

  totalRecord: 0;
  statusWiseList: any[];
  reportingEmpList: any;
  selectionCanndidateList: any[];
  filterSelectionCanndidateList: any[];
  fileUrl: any;
  filename: string = "Candidate.pdf";
  candidate_Id = 0;
  vacancy_Id = 0;
  company_Id: any;
  files: string;
  candidateWiseRAF: any[];

  documentName = "Attach File";
  docFiles: any[];
  roleList: Array<Select2OptionData>;
  roleDisplay: boolean = true
  roleId: any;
  options: any;
  file: any;
  CandidateWisesalaryPlaceHolder: any;
  jd_Name: string;

  salaryPlaceHolder: any;
  salaryType = ""
  currency_Code = "";
  candidateVacancyList: any[] = []

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.getStatusWiseList(1);
    this.getRole()
    this.options = {
      multiple: true,
    };
  }

  getStatusWiseList(PageIndex) {

    this.totalRecord = 0;
    this.p1 = PageIndex;
    this.statusWiseList = [];

    var requestData = {
      "searchBy": [
        {
          "FieldName": "status",
          "FieldValue": $("#approvallist_status").val() == "status" ? 'status' : JSON.stringify($("#approvallist_status").val()),
          "Parameter": "=="
        }
      ],
      "PageIndex": PageIndex - 1,
      // "CommonSearch": $("#statusFilterList").val().trim(),
      "PageSize": 10,
    }
    this.commonApiService.getPaginated(Commonvariable.Candidate_Vacancy_Link, null, null, null, null, 0, requestData, "GetCandidateVacancyLinkedData").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.statusWiseList = result["data"].filter(d => d.moveToOnboarding != 1);
      }
    })

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }


  async downloadCV(id) {
    this.commonApiService.getimage(Commonvariable.JobSeeker, id, 'DownloadCV').subscribe((result) => {
      const blobData = new Blob([result], { type: 'application/octet-stream' });
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blobData));
      setTimeout(() => {
        $("#download")[0].click();
      }, 500);
    })
  }

  fileChange(event) {
    this.documentName = "";
    this.docFiles = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.documentName += this.documentName == "" ? event.target.files[i].name : ", " + event.target.files[i].name;
      this.docFiles.push(event.target.files[i]);
    }
  }

  setData(id) {
    this.roleId = id
  }

  getRole() {
    this.commonApiService.List(Commonvariable.Roles).subscribe((response) => {
      if (response["data"] != "") {
        this.roleList = response["data"].map(x => ({
          id: x.id,
          text: x.name
        }));
      }
    })
  }

  validation() {
    if ($("#to").val() == "") {

      this.commonService.toaster("info", "Mail to is required !", "SendMail");
      $("#to").focus();
      return false;
    }


    return true
  }

  showIsFinal() {
    this.isFinal = !this.isFinal;

    setTimeout(() => {
      this.commonService.flatpickr('statusWiseJoiningDates', 0)
    }, 8);
  }




  async UpsertInterviewDetailForFeedback(status) {
    var requestData: any;
    if (status == 'Selected') {
      if ($("#candidateWiseSalary").val() == "" || $("#candidateWiseSalary").val() == undefined) {
        this.commonService.toaster("info", "Salary is required !", "Interview Feedback")
        $("#candidateWiseSalary").focus();
        return false;
      }
      // if ($("#candidateWiseSalaryType").val() == "" || $("#candidateWiseSalaryType").val() == undefined) {
      //   this.commonService.toaster("info", "SalaryType is required !", "Interview Feedback")
      //   $("#candidateWiseSalaryType").focus();
      //   return false;
      // }
      if ($("#Reporting_Id").val() == "" || $("#Reporting_Id").val() == undefined) {
        this.commonService.toaster("info", "Reporting is required !", "Interview Feedback")
        $("#Reporting_Id").focus();
        return false;
      }
      if ($("#statusWiseJoiningDate").val() == "" || $("#statusWiseJoiningDate").val() == undefined) {
        this.commonService.toaster("info", "Joining date is required !", "Interview Feedback")
        $("#statusWiseJoiningDate").focus();
        return false;
      }
      requestData = this.InterviewFeedback.upsert(this.candidate_Id, this.vacancy_Id, status, $("#candidateWiseSalary").val(), $("#Reporting_Id").val(), this.commonService.dateFormatChange($("#statusWiseJoiningDate").val()))
    }
    else {
      requestData = this.InterviewFeedback.upsert(this.candidate_Id, this.vacancy_Id, status)
    }

    if (requestData != null) {
      await this.commonApiService.Upsert("Candidate_Interviewer", requestData, this.candidate_Id, '/EditMultiple').subscribe((result) => {
        if (result != null) {
          $("#modalStatusWisefeedbackinterview").modal('hide');
          this.getStatusWiseList(1)
          this.commonService.toaster("success", Commonvariable.SaveMsg, "Interview")
        }
      })
    }

  }

  async UpsertInterviewDetail(status) {
    var requestData = this.ScheduleInterview.upsert(this.candidate_Id, this.vacancy_Id, status)
    if (requestData != null) {
      await this.commonApiService.Upsert("Candidate_Interviewer", requestData, this.candidate_Id, '/EditMultiple').subscribe((result) => {
        if (result != null) {
          this.getStatusWiseList(1);
          this.GetMail();
          $("#modalStatusWisescheduleinterview").modal('hide');

          this.commonService.toaster("success", Commonvariable.SaveMsg, "Interview")
        }
      })
    }

  }

  async UpdateStatus(status, candidate_Id = 0, vacancy_Id = 0) {

    this.candidate_Id = candidate_Id != 0 ? candidate_Id : this.candidate_Id;
    this.vacancy_Id = vacancy_Id != 0 ? vacancy_Id : this.vacancy_Id;

    if (status == "Offered") {
      this.commonApiService.getById(Commonvariable.VacancyService, vacancy_Id, "OpenVacancyCount").subscribe((result) => {
        if (result["data"] > 0) {
          this.commonService.dyanmicConfirm("You Want To Change Status Of Candidate !", "Yes !").then((result) => {
            if (result.value) {
              let ids = this.candidate_Id + "," + this.vacancy_Id + "," + status
              this.commonApiService.Put(Commonvariable.Candidate_Vacancy_Link, ids, "ChangeStatus").subscribe((response) => {
                if (response != null) {
                  // this.isFinal = false
                  //this.getCandidate(0, this.vacancy_Id)
                  this.getStatusWiseList(1)
                  this.commonService.toaster("success", response["message"], "Interview")
                }
              })
            }
          })
        }
        else {
          this.commonService.toaster("info", "All open vacancies have been filled !", "Interview")
        }
      })
    }
    else {
      this.commonService.dyanmicConfirm("You Want To Change Status Of Candidate !", "Yes !").then((result) => {
        if (result.value) {
          let ids = this.candidate_Id + "," + this.vacancy_Id + "," + status
          this.commonApiService.Put(Commonvariable.Candidate_Vacancy_Link, ids, "ChangeStatus").subscribe((response) => {
            if (response != null) {
              // this.isFinal = false
              //this.getCandidate(0, this.vacancy_Id)
              this.getStatusWiseList(1)
              this.commonService.toaster("success", response["message"], "Interview")
            }
          })
        }
      })
    }

  }

  async moveToOnboarding(candidate_Id, vacancy_Id) {

    if (candidate_Id > 0) {
      this.commonService.dyanmicConfirm("You Want This Candidate Move To Onboarding !", "Yes !").then((result) => {
        if (result.value) {
          this.commonApiService.PostRequest(Commonvariable.CandidateService, "MoveToOnboarding/" + candidate_Id + "," + vacancy_Id, {}).subscribe((response) => {
            if (response != null) {
              this.getStatusWiseList(1)
              this.commonService.toaster("success", response["message"], "Interview")
            }
          })
        }
      })
    }

  }

  openInterviewModel(Id, vId, company_id) {
    this.candidate_Id = Id;
    this.vacancy_Id = vId;
    if (company_id > 0) {
      $('#modalStatusWisescheduleinterview').modal({
        backdrop: 'static'
      });
      this.ScheduleInterview.getEmpList(company_id)
      this.getInterviews();

    }
    else {
      this.commonService.toaster("success", "Company Not Available", "Interview")
    }

  }


  openInterviewFeedbackModel(Id, vId, comany_Id, dept_Id, desig_Id) {

    $(".isFinalBody").hide();
    this.isFinal = false;

    this.candidate_Id = Id;
    this.vacancy_Id = vId;
    if (comany_Id > 0) {
      this.InterviewFeedback.getEmpList(comany_Id);

      let reportingParameter = "Reporting" + "," + comany_Id + "," + dept_Id + "," + desig_Id + "," + 0
      this.commonApiService.getById(Commonvariable.EmployeeDetail, reportingParameter, "GetEmployeeByAccessibility").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.reportingEmpList = result["data"];
        }
      })

      this.getInterviewsForFeedback();

      $('#modalStatusWisefeedbackinterview').modal({
        backdrop: 'static'
      });
    }
    else {
      this.commonService.toaster("info", "Company Not Available", "Interview")
    }

  }

  getInterviews() {
    this.ScheduleInterview.ngOnInit();
    if (this.candidate_Id > 0) {
      this.commonApiService.getById(Commonvariable.Candidate_Interviewer, this.candidate_Id + "," + this.vacancy_Id, "GetInterviewSchedule").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.ScheduleInterview.fillInterviewer(result["data"])
        }
        else {
          this.ScheduleInterview.createItem();
        }
      })
    }
  }
  getInterviewsForFeedback() {
    if (this.candidate_Id > 0) {
      this.commonApiService.getById(Commonvariable.Candidate_Interviewer, this.candidate_Id + "," + this.vacancy_Id, "GetInterviewSchedule").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.InterviewFeedback.fillInterviewer(result["data"]);
          this.CandidateWisesalaryPlaceHolder = result["data"][0][0]["salaryFrom"] + " - " + result["data"][0][0]["salaryTo"];
          this.currency_Code =  result["data"][0][0]["currency_Code"] ;
        }
        else {
          this.InterviewFeedback.createItem();
        }
      })
    }
  }
  GetMail(candidate_Id = 0, Vacancy_Id = 0) {
    if (candidate_Id > 0) {
      this.candidate_Id = candidate_Id;
    }
    if (Vacancy_Id > 0) {
      this.vacancy_Id = Vacancy_Id;
    }

    this.commonApiService.getByIdTwoParam(Commonvariable.Candidate_Interviewer, this.candidate_Id, this.vacancy_Id, "GetSendMailData").subscribe((response) => {
      if (response["data"] != "") {
        this.documentName = "";
        this.docFiles = [];
        this.company_Id = response["data"]["company_Id"];
        this.files = response["data"]["jD_Path"] == null || response["data"]["jD_Path"] == "" ? '' : response["data"]["jD_Path"];
        this.jd_Name = response["data"]["jD_Name"]
        $('#StatusSendMail').modal({
          backdrop: 'static'
        });
        this.roleId = null;

        setTimeout(() => {
          $("#statuswiseto").val(response["data"]["to"])
          $("#statuswisecc").val(response["data"]["cc"])
          $("#statuswisebcc").val(response["data"]["bcc"])
          $("#statuswisesubject").val(response["data"]["subject"])
          $('#statuswiseemailsend').summernote('code', response["data"]["body"]);
        }, 500);

      }
    })

  }

  sendMail() {

    const formData = new FormData();
    formData.append('To', $("#statuswiseto").val());
    formData.append('CC', $("#statuswisecc").val());
    formData.append('BCC', $("#statuswisebcc").val());
    formData.append('Subject', $("#statuswisesubject").val());
    formData.append('Role_Ids', this.roleId != undefined ? String(this.roleId) : null);
    formData.append('Body', $('#statuswiseemailsend').summernote('code'));
    formData.append('Company_Id', this.company_Id);
    formData.append('JD_Path', this.files);
    formData.append('JD_Name', this.jd_Name);
    if (this.docFiles != undefined) {
      for (var i = 0; i < this.docFiles.length; i++) {
        formData.append('file', this.docFiles[i]);
      }
    }


    this.commonApiService.UploadLogo(Commonvariable.Candidate_Interviewer, "SendMailWithFileObj", formData).subscribe((response) => {
      if (response["status"] == 200) {
        $("#StatusSendMail").modal('hide');
        this.commonService.toaster("success", response["message"], "Interview")
      }
    })

    this.getStatusWiseList(1)
  }


  
  getReportingEmpList(company_Id, dept_Id, desig_Id, salaryFrom, salaryTo, currency_Code, salaryType, vacancy_Id) {

    this.salaryPlaceHolder = salaryFrom + "-" + salaryTo;
    this.currency_Code = currency_Code;
    this.salaryType = salaryType;
    this.vacancy_Id = vacancy_Id;

    let reportingParameter = "Reporting" + "," + company_Id + "," + dept_Id + "," + desig_Id + "," + 0
    this.commonApiService.getById(Commonvariable.EmployeeDetail, reportingParameter, "GetEmployeeByAccessibility").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.reportingEmpList = result["data"];
      }
    })
  }


  
  directOpenMoveToOnboarding(id,vid) {

    this.candidateVacancyList = [];

    this.reportingEmpList = [];

    this.candidate_Id = id;

    this.commonApiService.getById(Commonvariable.Candidate_Vacancy_Link, id, "GetCan_Vac_LinkByCandidate_Id").subscribe((res) => {
      if (res) {
        this.candidateVacancyList = res["data"];
        let item = this.candidateVacancyList.filter(x => x.vacancy_Id == vid)
        if (item.length > 0) {
          item = item[0];
          this.getReportingEmpList(item["company_Id"], item["dept_Id"], item["desig_Id"], item["salaryFrom"], item["salaryTo"], item["currency_Code"], item["salaryType"], item["vacancy_Id"])
        }
      }
    })
    this.currency_Code = "";
    this.salaryPlaceHolder = "0";
    this.salaryType = "";
    this.vacancy_Id = vid;

    $("#modalstatusMovetoOnboarding").modal("show");
    this.commonService.flatpickr('statusSelectionJoiningDates', 0)
    $("#statusSelectionSalary").val("");
    $("#statusSelectionJoiningDate").val("");
    $("#statusSelectionReporting").val("");
  }


  
  directMoveToOnboarding() {

    if (this.validationmto()) {
      let postRequest = {
        "candidate_Id": this.candidate_Id,
        "vacancy_Id": this.vacancy_Id,
        "Salary": $("#statusSelectionSalary").val(),
        "SalaryType": this.salaryType,
        "Reporting_Id": $("#statusSelectionReporting").val(),
        "JoiningDate":  this.commonService.dateFormatChange($("#statusSelectionJoiningDate").val())
      }

      this.commonApiService.PostRequest(Commonvariable.CandidateService, "DirectMoveToOnboarding", postRequest).subscribe((res) => {
        if (res) {
          this.commonService.toaster("success", "Move to onboarding successfully ", "Interview");
          this.getStatusWiseList(1)
          $(".modal").modal("hide");
        }
      })
    }

  }

  validationmto() {

    if (this.currency_Code == "" || this.salaryType == "" && this.vacancy_Id == 0 ) {
      this.commonService.toaster("info", "Select Vacancy ", "Interview");
      return false;
    }

    // if ($("#statusSelectionSalary").val() == "") {
    //   this.commonService.toaster("info", "Salary is required ", "Candidate");
    //   $("#statusSelectionSalary").focus();
    //   return false;
    // }

    // if ($("#statusSelectionReporting").val() == "") {
    //   this.commonService.toaster("info", "Reporting is required ", "Candidate");
    //   $("#statusSelectionReporting").focus();
    //   return false;
    // }

    // if ($("#statusSelectionJoiningDate").val() == "") {
    //   this.commonService.toaster("info", "Joining date is required ", "Candidate");
    //   $("#statusSelectionJoiningDate").focus();
    //   return false;
    // }

    return true;
  }



}
