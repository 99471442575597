import { Component, OnInit } from '@angular/core';
import packagejson from '../../../package.json';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public appVersion: string = packagejson.version;
  constructor() { }

  ngOnInit() {
  }

  scrollUp()
  {
    window.scroll(0,0)
  }

}
