import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { Commonvariable } from 'src/app/models/commonvariable';

import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
declare var $: any;
declare var flatpickr: any;

@Component({
  selector: 'app-leave-dashboard',
  templateUrl: './leave-dashboard.component.html',
  styleUrls: ['./leave-dashboard.component.css']
})
export class LeaveDashboardComponent implements OnInit {
  data: any;
  options: any;
  changereqform: any;
  dashboardform: any;
  companyList: any;
  siteList: any[];
  departmentList: any[];
  zoneList: any[];
  JDList: any[];
  designationList: any[];
  empList: any[];
  deputyList: any[];
  shiftList: any[];
  righttoworkList: any[];
  companyDetail: any;
  rafList: any[];
  constructor(private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private router: Router,
    private commonService: CommonService,
    private fb:FormBuilder) {

      this.data = {
        labels: ['Label 1', 'Label 2', 'Label 3','label 4'],
        datasets: [
          {
            label: 'Historical Data',
            data: [100, 20, 30],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)'
            ],
            borderWidth: 1
          }
        ]
      }
      this.options = {
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Time(day/month/Year)',
              fontColor: 'black'
            },
            ticks: {
              beginAtZero: true
            },
             categoryPercentage: 0.5, // Adjust the width of the bar
          barPercentage: 0.8 // Adjust the width of the category
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Number of Leaves',
              fontColor: 'black'
            },
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false // Remove the grid lines
            },
            barThickness: 50
          }]
        },
        legend: {
          display: true,
          position: 'top', // 'left', 'top', 'right', 'bottom'
          labels: {
            fontColor: 'black',
            boxWidth: 1,
            padding:30
          }
        }
      };
  }

  leaveList: any;
  leaveFilter: any;
  totalRecord: 100;
  leaveTypeAll:any

  ngOnInit() {
    this.commonService.flatpickr("dashboardEmpDate", 0);
    this.commonService.flatpickr("dashboardEmpDate1", 0);
    this.getEmpLeaveList(1);
    this.getLeave(localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id);



     
   this. initializeForm();
 
   this.getdata();
   this.getCompany();
   this.getRafList(0);
   this.getRafListAll();
   //this.getJobTitle() ;
   this.companyWiseGetData();
   //this.getReportingList();
  // this.getJobTitle();
   //this.getDeputyList();
  }
  

  getdata(){
    return this.commonApiService.getallEmployeeDetails(Commonvariable.Employee_Change_Requisition,"GetAll").subscribe((el:any)=>{
console.log("alldata",el)
    })
 }
  

  initializeForm(): void {
    this.dashboardform = this.fb.group({
     
      
      company_Id: ['', Validators.required],
      Site_Id: ['', Validators.required],
     
      Dept_Id: ['', Validators.required],
      Zone_Id: ["", Validators.required],
      Desig_Id: ['', Validators.required],
      JD_Id: [""],
      Emp_Code: ['', Validators.required],
      JoiningDate: ['', Validators.required],
      Shift_Id: ['', Validators.required],
      Reporting_Id: ['', Validators.required],
      Line_Manager_Id: ['0'],
      dashboardDate:['',''],
      dashboardDate1:['','']
     });
  
 
    }

 

  getEmpLeaveList(PageIndex) {
    this.leaveList = [];
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);
  }

  getLeave(id) {
    if (id) {
      this.commonApiService.getById(Commonvariable.Leave_Balance, id, "GetLeaveBalance").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.leaveTypeAll = result["data"];
        }
      })
    }
  }
  getCompany() {
     
    if (this.companyList == null) {
       this.companyList = [];
    }

    var requestData = {
       "searchBy": [
          {
             "FieldName": "isActive",
             "FieldValue": "1",
             "Parameter": "=="
          }
       ],
       "OrderBy": "company_Name"
    }
    if (this.companyList.length == 0) {
       this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((result) => {
          if (result != null && result["data"] != "") {
          
             this.companyList = result["data"].filter(x => x.isActive != 0);
          }
  
       })
    }
 }



companyWiseGetData(editmode = false) {
   this.siteList = [];
   this.departmentList = [];
   this.zoneList = [];
  
   if (!editmode) {
     this.dashboardform.controls.Site_Id.setValue('');
     this.dashboardform.controls.Dept_Id.setValue('');
     this.dashboardform.controls.JD_Id.setValue('');
   
     
   }
 

   const selectedCompanyId = this.dashboardform.controls.company_Id.value;
console.log(selectedCompanyId,"id")
 
   if (selectedCompanyId != "") {
     localStorage.removeItem('employeeHRCompanyId');
     localStorage.setItem('employeeHRCompanyId', selectedCompanyId);
 
     var requestData = {
       "searchBy": [
         {
           "FieldName": "Company_Id",
           "FieldValue": selectedCompanyId,
           "Parameter": "==",
           "ConditionWith": "&&"
         },
         {
           "FieldName": "isActive",
           "FieldValue": "1",
           "Parameter": "=="
         }
       ]
     };
 
     this.commonApiService.getPaginated(Commonvariable.CompanyDetail, null, null, null, null, null, requestData, "GetByCompany_IdPaginated").subscribe((result) => {
       this.companyDetail = result["data"][0];
       this.siteList = this.companyDetail["site"];
       this.departmentList = this.companyDetail["department"];
       this.zoneList = this.companyDetail["zone"];
      //  this.shiftList = this.companyDetail["shift"];
   
      //  this.righttoworkList = this.companyDetail["rightToWork"];
       this.dashboardform.controls.company_Id.setValue(selectedCompanyId);
      // this.getJobTitle();
 
     });
   }
 }
 


getRafListAll() {
     
  this.commonApiService.List(Commonvariable.VacancyService, "GetDropDownListAllRef").subscribe((result) => {
     if (result["data"] != "") {
       
        this.AllrafList = result["data"];
        console.log(this.AllrafList);
     }
  })

}



  AllrafList(AllrafList: any) {
    throw new Error('Method not implemented.');
  }
getJobDescriptionList(id) {
   this.JDList = [];
   let postRequest = {
     "searchBy": [
        {
           "FieldName": "desig_Id",
           "FieldValue": id,
           "Parameter": "==",
           "ConditionWith": "&&",
        },
        {
           "FieldName": "isActive",
           "FieldValue": 1,
           "Parameter": "=="
        }
     ]
   }
   
   if (id > 0) {
     this.commonApiService.getPaginated(Commonvariable.JobDescription, null, null, null, null, 0, postRequest, 'GetByPaginated').subscribe((result) => {
        if (result != null && result["data"] != "") {
           this.JDList = result["data"];
        }
     })
   }
   }


async getRafList(id) {

this.rafList = [];
let postRequest = {
  "searchBy": [
     {
        "FieldName": "Open",
        "FieldValue": "0",
        "Parameter": ">",
        "ConditionWith": "&&"
     },
     {
        "FieldName": "site_Id",
        "FieldValue": id,
        "Parameter": "=="
     }
  ],
  "PageSize": 1000
}

if (id > 0) {
  await this.commonApiService.getPaginated(Commonvariable.VacancyService, null, null, null, null, 0, postRequest, 'RAFWiseGetPaginated').subscribe((result) => {
     if (result != null && result["data"] != "") {
        this.rafList = result["data"]["list"];
    
     }
  })
}


}


ngAfterViewInit() {
  flatpickr('#dashboardEmpDate', {
    // Flatpickr options
    onClose: (selectedDates: Date[]) => {
      console.log('Flatpickr onClose Triggered');
      console.log('Selected Dates:', selectedDates);

      const selectedDate = selectedDates.length > 0 ? selectedDates[0] : null;
      console.log('Selected Date:', selectedDate);
      const formattedDate = selectedDate ? selectedDate.toISOString().split('T')[0] : null; // Extract date portion
      console.log('Formatted Date:', formattedDate);
      this.dashboardform.get('dashboardDate').setValue(formattedDate);
    },
  });
  flatpickr('#dashboardEmpDate1', {
    // Flatpickr options
    onClose: (selectedDates: Date[]) => {
      console.log('Flatpickr onClose Triggered');
      console.log('Selected Dates:', selectedDates);

      const selectedDate = selectedDates.length > 0 ? selectedDates[0] : null;
      console.log('Selected Date:', selectedDate);
      const formattedDate = selectedDate ? selectedDate.toISOString().split('T')[0] : null;
      console.log('Formatted Date:', formattedDate);
      this.dashboardform.get('dashboardDate').setValue(formattedDate);
    },
  });
}
chartdata(){
  const formData = this.dashboardform.value;

    
  const updateData = {
   
        
          company_Id: formData.company_Id ? parseInt(formData.company_Id, 10) : '',
          dept_Id: formData.Dept_Id ? parseInt(formData.Dept_Id, 10) : '',
          zone_Id: formData.Zone_Id ? parseInt(formData.Zone_Id, 10) : '',
         
          DashboardDate_Date: formData.dashboardDate? formData.dashboardDate : '',
          
      };
     console.log('Updated Data:', updateData);
      
     
         this.commonApiService.SaveData(Commonvariable.Employee_Change_Requisition, 'Add', updateData).subscribe(
        (response) => {
         const dashboardDateFlatpickr = flatpickr("#effectiveDate input");
         const flatpickropenReq = flatpickr("#flatpickropenReq");
      
        
          if (response != null) {
            console.log('Data updated successfully:', response);
            this.toastr.success('Record saved sucessfully!');
          this.changereqform.reset();
          
          
          dashboardDateFlatpickr.clear();
          flatpickropenReq.clear();
             
          }
            else {
             
               this.toastr.warning('Record not saved !');
          
            
          }
      
        },
        
      );
   
    
  
}

leavedata(){
  const formData = this.dashboardform.value;

    
  const updateData = {
   
        
          company_Id: formData.company_Id ? parseInt(formData.company_Id, 10) : '',
          
         
          DashboardDate_Date1: formData.dashboardDate1? formData.dashboardDate1 : '',
          
      };
     console.log('Updated Data:', updateData);
      
     
         this.commonApiService.SaveData(Commonvariable.Employee_Change_Requisition, 'Add', updateData).subscribe(
        (response) => {
         const dashboardDateFlatpickr = flatpickr("#dashboardEmpDate1");
         const flatpickropenReq = flatpickr("#flatpickropenReq");
      
        
          if (response != null) {
            console.log('Data updated successfully:', response);
            this.toastr.success('Record saved sucessfully!');
          this.dashboardform.reset();
          
          
          dashboardDateFlatpickr.clear();
          flatpickropenReq.clear();
             
          }
            else {
             
               this.toastr.warning('Record not saved !');
               dashboardDateFlatpickr.clear();
               flatpickropenReq.clear();
               this.dashboardform.reset();
            
          }
      
        },
        
      );
   
    
  
}

}



