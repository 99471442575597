import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Commonvariable } from '../models/commonvariable';
import * as jwt_decode from 'jwt-decode';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  // baseUrl = "http://172.16.1.61:8088/api/";
  baseUrl = Commonvariable.BASE_API_URL;
  currentUser: any;
  public leftMenuRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private httpClient: HttpClient) { 
  }

  login(formData) {
    return this.httpClient.post(this.baseUrl + "user/signIn", formData);
  }

  logout(id) {
    return this.httpClient.post(this.baseUrl + "User/SignOut/" + id, "");
  }

  setCurrentLoginData(token) {
    this.currentUser = token;
    Commonvariable.User_Id = this.currentUser.user_Id
    Commonvariable.Company_Id = this.currentUser.company_Id
    Commonvariable.emp_Id = this.currentUser.person_Id
    Commonvariable.onboarding_Id = this.currentUser.onboarding_Id
    Commonvariable.Roles=this.currentUser.role
    this.leftMenuRefresh.next(!this.leftMenuRefresh.value)
  }


  isTokenExpired() {
    let currentUser = JSON.parse(localStorage.getItem('currentHRUser'));
    const decoded = jwt_decode(currentUser["data"].token_No);
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return !(date.valueOf() > new Date().valueOf());
  }

  forgetPassword(username) {
    return this.httpClient.post(this.baseUrl + "User/ForgotPassword/" + username, "");
  }

  verifyLink(data) {
    return this.httpClient.post(this.baseUrl + "User/VerifyLink", data);
  }

  resetPassword(data) {
    return this.httpClient.post(this.baseUrl + "User/ResetPassword", data);
  }




}  
