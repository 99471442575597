import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { debounceTime, distinctUntilChanged, endWith, filter, startWith, switchMap } from 'rxjs/operators';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Observable, of } from 'rxjs';
import { Select2OptionData } from 'ng-select2';

import { ToastrService } from 'ngx-toastr';
declare var flatpickr: any;

@Component({
  selector: 'app-changereq-form',
  templateUrl: './changereq-form.component.html',
  styleUrls: ['./changereq-form.component.css']
})
export class ChangereqFormComponent implements OnInit {
  searchControl = new FormControl();
  employees:  any;
  empcodelist:any;
  selectedEmployee: any | undefined;
  employeeDetails: any | undefined;
  isLoading = false;
  uniqueFirstNames: string[] = [];
  uniquelastname: string[] = [];
  changereqform:FormGroup
  filteredEmpCodes: any;
  allEmpCodes: any;

  employeeData: any[] = [];
  employmentType: string = '';
  lastname:any;
  campany:any;
  department:any;
  zone:any;
  worklocation:any;
  linemanager:any;
  jobtitle:any;
  shifttime:any;
  salary:any;
  additionalpayment:any;
  newid:number;
  companyDetail: any;
  companyList: any;
  siteList: any;
  departmentList: any;
  zoneList: any;
  JDList: any;
  shiftList: any;
  designationList: any;
  empList: Array<Select2OptionData>;
  contractList: any;
  ProbationList: any;
  deputyList: Array<Select2OptionData>;
  righttoworkList: any[] = [];
  modalOpen: boolean = false;
  modalOpendesig: boolean = false;
  modalOpendept: boolean = false;
  modalOpensift: boolean = false;
  modalOpensite: boolean = false;
  rafList: any;
  companyid: any;
  AutoGenButtonVisibal: boolean = true;
  AllrafList:any;
  isInputDisabled: boolean = false;

  data: any[];
  firstEmployeeFirstName: any;
  uniqueAdditionalPayments: string[] = [];
  addPayment1: any;
  salaryHouryRate1: any;
  shifttime1: any;
  jobTitle1: any;
  reporting1: any;
  workSite1: any;
  zone1: any;
  department1: any;
  company11: any;
  employeeDatas: any[] = [];
  selectedEmployees: any;
  employeess: any;
  
  items: FormArray;
   effectiveDate: any;
  constructor( public fb: FormBuilder, private commonService: CommonService, private commonApiService: CommonApiService ,private toastr: ToastrService) { 
     
  }
 ngOnInit()
   {
    
    this.commonService.flatpickr("flatpickropenReq", 0);
    this.commonService.flatpickr("flatpickropenEff", 0);
    this.commonService.flatpickr("flatpickropen", 0);

   
    
   this. initializeForm();
 
    this.getdata();
    this.getCompany();
    this.getRafList(0);
    this.getRafListAll();
    this.getJobTitle() ;
    this.companyWiseGetData();
    this.getReportingList();
    this.getJobTitle();
    this.getDeputyList();
    this.setupAutocomplete();
  
   
  
  this.commonService.flatpickr("flatpickropen", 0);
   
 
   }
 

   initializeForm(): void {
      this.changereqform = this.fb.group({
        enteremployeeid:['',''],
        firstName:['',''],
       LastName:['',''],
       fullName: [{value: '', disabled: true}, [Validators.required]],
        company11: [{value: '', disabled: true}, [Validators.required]],
       company_Name: ['', Validators.required],
       department1:[{value: '', disabled: true},[]],
        zone1:[{value: '', disabled: true},[]],
       workSite1:[{value: '', disabled: true},[]],
       reporting1:[{value: '', disabled: true},[]],
        jobTitle1:[{value: '', disabled: true},[]],
       shifttime1:[{value: '', disabled: true},[]],
        salaryHouryRate1:[{value: '', disabled: true},[]],
        salaryHouryRate:['',''],
        addPayment1:['',[]],
        emp_id:['',''],
        Person_Id: 0,
        company_Id: ['', Validators.required],
        Site_Id: ['', Validators.required],
        Vacancy_Id: [''],
        vacancy_Name: [''],
        Dept_Id: ['', Validators.required],
        Zone_Id: ["", Validators.required],
        Desig_Id: ['', Validators.required],
        JD_Id: [""],
        Emp_Code: ['', Validators.required],
        JoiningDate: ['', Validators.required],
        Shift_Id: ['', Validators.required],
        Reporting_Id: ['', Validators.required],
        Line_Manager_Id: ['0'],
        effectiveDate:['',''],
       });
      this.commonService.flatpickr("flatpickropenJ", 0);
   
      }
   private setupAutocomplete(): void {
   
     this.searchControl.valueChanges.pipe(
      
       debounceTime(10),
       distinctUntilChanged(),
       switchMap((id) => this.commonApiService.getEmployeesByKeyword(id))
     ).subscribe((response: { data: any[] }) => {
       if (response != null && Array.isArray(response.data)) {
         this.employeeDatas = response.data;
         console.log(response,"res")
       }
     });
   }
 
   onSelectEmployee(employee: any): void {
      
     this.selectedEmployee = employee;

    
     
             if (this.selectedEmployee) {
       this.commonApiService.getEmployeeDetails(Commonvariable.Employee_Change_Requisition,  'GetByEmpID',this.selectedEmployee).subscribe(
         (details) => {
           this.employeeData = details['data'] !== '' ? details['data'] : null;
           console.log(this.employeeData, 'employee details');
           this.searchControl.setValue(this.selectedEmployee);
           this.userprofileDetails(this.employeeData);
    
         },
         (error) => {
           console.error('Error fetching employee details:', error);
         }
       );
     }
   }
  radioSelection = new FormControl('');
    onRadioChange(): void {
     const selectedOption = this.radioSelection.value;
       console.log('Radio - Selected option:', selectedOption);
   }
     getdata(){
    return this.commonApiService.getallEmployeeDetails(Commonvariable.Employee_Change_Requisition,"GetAll").subscribe((el:any)=>{
console.log("alldata",el)
    })
 }
   

  userprofileDetails(employeeData){
    this.changereqform.patchValue({     
          fullName: this.employeeData && this.employeeData[0] && `${this.employeeData[0]['firstName']} ${this.employeeData[0]['lastName']}`,
          emp_id:this.employeeData && this.employeeData[0] && `${this.employeeData[0]['emp_Id']} ${this.employeeData[0]['emp_Id']}`,
          firstName : this.employeeData && this.employeeData[0] && this.employeeData[0]['firstName'],
          LastName : this.employeeData && this.employeeData[0] && this.employeeData[0]['lastName'],
        company11: this.employeeData && this.employeeData[0] && this.employeeData[0]['companyName'],
        department1: this.employeeData && this.employeeData[0] && this.employeeData[0]['departmentName'],
        zone1: this.employeeData && this.employeeData[0] && this.employeeData[0]['zoneName'],
        workSite1: this.employeeData && this.employeeData[0] && this.employeeData[0]['work_Location_Site'],
        reporting1: this.employeeData && this.employeeData[0] && this.employeeData[0]['lineManager'],
      jobTitle1: this.employeeData && this.employeeData[0] && this.employeeData[0]['jobTitle'],
    shifttime1: this.employeeData && this.employeeData[0] && this.employeeData[0]['shiftTime'],
    salaryHouryRate1: this.employeeData && this.employeeData[0] && this.employeeData[0]['salary'],
    addPayment1: this.employeeData.map((employee) => employee['addPayment']),
    effectiveDate: this.employeeData && this.employeeData[0] && this.employeeData[0]['effectiveDate'],
onCompanyChange(): void {
  const selectedCompanyIndex = this.changereqform.controls['company1'].value;
  const selectedCompany = this.companyList[selectedCompanyIndex];

  this.changereqform.controls['company11'].setValue(selectedCompany);
} });}

ngAfterViewInit() {
   flatpickr('#effectiveDate', {
     // Flatpickr options
     onClose: (selectedDates: Date[]) => {
       console.log('Flatpickr onClose Triggered');
       console.log('Selected Dates:', selectedDates);
 
       const selectedDate = selectedDates.length > 0 ? selectedDates[0] : null;
       console.log('Selected Date:', selectedDate);
 
       this.changereqform.get('effectiveDate').setValue(selectedDate);
     },
   });
 }

// and effectiveDate is a form control in changereqform

// ngAfterViewInit() {
//   flatpickr('#effectiveDate', {
//     onClose: (selectedDates: Date[]) => {
//       console.log('Flatpickr onClose Triggered');
//       console.log('Selected Dates:', selectedDates);

//       const selectedDate = selectedDates.length > 0 ? selectedDates[0] : null;
//       console.log('Selected Date:', selectedDate);

//       // Format the date to the desired string format
//       const formattedDate = selectedDate ? selectedDate.toLocaleString() : null;
//       console.log('Formatted Date:', formattedDate);

//       // Set the value of the effectiveDate form control
//       this.changereqform.get('effectiveDate').setValue(formattedDate);
//     },
//   });
// }

    

    updateProfile() {
     
     const formData = this.changereqform.value;

    
  const updateData = {
   
         emp_Id: formData.emp_id !== undefined ? (formData.emp_id.match(/\d+/) ? parseInt(formData.emp_id.match(/\d+/)[0], 10) : null) : null,
          ​firstName:formData.firstName?formData.firstName:'',
          LastName:formData.LastName?formData.LastName:'',
          company_Id: formData.company_Id ? parseInt(formData.company_Id, 10) : '',
          dept_Id: formData.Dept_Id ? parseInt(formData.Dept_Id, 10) : '',
          zone_Id: formData.Zone_Id ? parseInt(formData.Zone_Id, 10) : '',
          work_Location_Site: formData.Site_Id ? parseInt(formData.Site_Id, 10) : '',
          lineManager: formData.Line_Manager_Id ? parseInt(formData.Line_Manager_Id, 10) : '',
          jobTitle: formData.Desig_Id ? parseInt(formData.Desig_Id, 10) : '',
          shiftTime: formData.Shift_Id ? parseInt(formData.Shift_Id, 10) : '',
          salary: formData.salaryHouryRate ? formData.salaryHouryRate : '',
        
          addPayment: formData.addPayment1? parseInt(formData.addPayment1,10) : '',
         Effective_Date: formData.effectiveDate? formData.effectiveDate : '',
          
      };
     console.log('Updated Data:', updateData);
      
     
         this.commonApiService.SaveData(Commonvariable.Employee_Change_Requisition, 'Add', updateData).subscribe(
        (response) => {
         const effectiveDateFlatpickr = flatpickr("#effectiveDate input");
         const flatpickropenReq = flatpickr("#flatpickropenReq");
      
        
          if (response != null) {
            console.log('Data updated successfully:', response);
            this.toastr.success('Record saved sucessfully!');
          this.changereqform.reset();
          
          
          effectiveDateFlatpickr.clear();
          flatpickropenReq.clear();
             
          }
            else {
             
               this.toastr.warning('Record not saved !');
          
            
          }
      
        },
        
      );
   
    
  
}

 fetchData(): Observable<any> {

   
    const defaultId =1
  
    return this.commonApiService.getEmployeesByKeyword(defaultId);
  }
private populateUniqueAdditionalPayments() {
    
    this.uniqueAdditionalPayments = Array.from(new Set(this.employeeData.map(employee => employee.additionalPayment)));
  }
 getCompany() {
     
    if (this.companyList == null) {
       this.companyList = [];
    }

    var requestData = {
       "searchBy": [
          {
             "FieldName": "isActive",
             "FieldValue": "1",
             "Parameter": "=="
          }
       ],
       "OrderBy": "company_Name"
    }
    if (this.companyList.length == 0) {
       this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((result) => {
          if (result != null && result["data"] != "") {
          
             this.companyList = result["data"].filter(x => x.isActive != 0);
          }
  
       })
    }
 }



companyWiseGetData(editmode = false) {
   this.siteList = [];
   this.departmentList = [];
   this.zoneList = [];
   this.JDList = [];
   this.designationList = [];
   this.shiftList = [];
   this.empList = [];
   this.deputyList = [];
   this.righttoworkList = [];
 
   if (!editmode) {
     this.changereqform.controls.Site_Id.setValue('');
     this.changereqform.controls.Dept_Id.setValue('');
     this.changereqform.controls.Zone_Id.setValue('');
     this.changereqform.controls.JD_Id.setValue('');
     this.changereqform.controls.Desig_Id.setValue('');
     this.changereqform.controls.Shift_Id.setValue('');
     this.changereqform.controls.Vacancy_Id.setValue('');
     this.changereqform.controls.Reporting_Id.setValue('');
     this.changereqform.controls.Line_Manager_Id.setValue('0');
     
   }
 

   const selectedCompanyId = this.changereqform.controls.company_Id.value;

 
   if (selectedCompanyId != "") {
     localStorage.removeItem('employeeHRCompanyId');
     localStorage.setItem('employeeHRCompanyId', selectedCompanyId);
 
     var requestData = {
       "searchBy": [
         {
           "FieldName": "Company_Id",
           "FieldValue": selectedCompanyId,
           "Parameter": "==",
           "ConditionWith": "&&"
         },
         {
           "FieldName": "isActive",
           "FieldValue": "1",
           "Parameter": "=="
         }
       ]
     };
 
     this.commonApiService.getPaginated(Commonvariable.CompanyDetail, null, null, null, null, null, requestData, "GetByCompany_IdPaginated").subscribe((result) => {
       this.companyDetail = result["data"][0];
       this.siteList = this.companyDetail["site"];
       this.departmentList = this.companyDetail["department"];
       this.zoneList = this.companyDetail["zone"];
       this.shiftList = this.companyDetail["shift"];
       this.contractList = this.companyDetail["contract"];
       this.ProbationList = this.companyDetail["probation"];
       this.righttoworkList = this.companyDetail["rightToWork"];
       this.changereqform.controls.company_Id.setValue(selectedCompanyId);
       this.getJobTitle();
 
     });
   }
 }
 

// 
 
 getReportingList() {

  let formData = this.changereqform.controls

  let reportingParameter = "Reporting" + "," + formData.company_Id.value + "," + formData.Dept_Id.value + "," + formData.Desig_Id.value + "," + 0

  let deputyParameter = "Deputy" + "," + formData.company_Id.value + "," + formData.Dept_Id.value + "," + formData.Desig_Id.value + "," + 0

  this.commonApiService.getById(Commonvariable.EmployeeDetail, reportingParameter, "GetEmployeeByAccessibility").subscribe((result) => {
     if (result != null && result["data"] != "") {
        this.empList = result["data"].map(x => ({
           id: x.emp_Id,
           text: x.firstName + " " + x.lastName
        }))
     }
  })

  this.commonApiService.getById(Commonvariable.EmployeeDetail, deputyParameter, "GetEmployeeByAccessibility").subscribe((result) => {
     if (result != null && result["data"] != "") {
        this.deputyList = [];
                 
        this.deputyList = result["data"].map(x => ({
           id: x.emp_Id,
           text: x.firstName + " " + x.lastName
        }))
     }
  })
}
getJobTitle() {

  this.designationList = [];
  if (this.changereqform.controls.company_Id.value != "" && this.changereqform.controls.Dept_Id.value != "") {


     var requestData = {
        "searchBy": [
           {
              "FieldName": "isActive",
              "FieldValue": "1",
              "Parameter": "==",
              "ConditionWith": "&&"
           },
           {
              "FieldName": "company_Id",
              "FieldValue": this.changereqform.controls.company_Id.value,
              "Parameter": "==",
              "ConditionWith": "&&"
           },
           {
              "FieldName": "dept_Id",
              "FieldValue": this.changereqform.controls.Dept_Id.value,
              "Parameter": "==",
           }
        ],
        "OrderBy": "desig_Name"
     }

     requestData.OrderBy = "desig_Name"
     this.commonApiService.GetByPaginated(Commonvariable.Designation, requestData).subscribe((response) => {
        if (response["data"] != "") {
           this.designationList = response["data"];
        }
     })

  }

}






async getDeputyList(company_Id = 0, Dept_Id = 0, Desig_Id = 0, Emp_Id = 0) {
  this.deputyList = [];
  var requestData = {
     "searchBy": [
        {
           "FieldName": "Company_Id",
           "FieldValue": company_Id != 0 ? company_Id : this.changereqform.controls.company_Id.value,
           "Parameter": "==",
           "ConditionWith": "&&"
        },
        {
           "FieldName": "Dept_Id",
           "FieldValue": Dept_Id != 0 ? Dept_Id : this.changereqform.controls.Dept_Id.value,
           "Parameter": "==",
           "ConditionWith": "&&"
        },
        {
           "FieldName": "Desig_Id",
           "FieldValue": Desig_Id != 0 ? Desig_Id : this.changereqform.controls.Desig_Id.value,
           "Parameter": "==",
           "ConditionWith": "&&"
        },
        {
           "FieldName": "Emp_Id",
           "FieldValue": Emp_Id != 0 ? Emp_Id : this.changereqform.controls.Emp_Id.value,
           "Parameter": "!=",
           "ConditionWith": "&&"
        },
        {
           "FieldName": "isActive",
           "FieldValue": "1",
           "Parameter": "=="
        }

     ],
     "OrderBy": "FirstName"
  }
  if (this.changereqform.controls.Desig_Id.value != '') {
     this.commonApiService.GetByPaginated(Commonvariable.EmployeeDetail, requestData).subscribe((result) => {
        if (result["data"] != "" && result["data"] != null) {
           this.deputyList = result["data"];
        
        }
     })
  }

}

getRafListAll() {
     
  this.commonApiService.List(Commonvariable.VacancyService, "GetDropDownListAllRef").subscribe((result) => {
     if (result["data"] != "") {
       
        this.AllrafList = result["data"];
        console.log(this.AllrafList);
     }
  })

}
getJobDescriptionList(id) {
   this.JDList = [];
   let postRequest = {
     "searchBy": [
        {
           "FieldName": "desig_Id",
           "FieldValue": id,
           "Parameter": "==",
           "ConditionWith": "&&",
        },
        {
           "FieldName": "isActive",
           "FieldValue": 1,
           "Parameter": "=="
        }
     ]
   }
   
   if (id > 0) {
     this.commonApiService.getPaginated(Commonvariable.JobDescription, null, null, null, null, 0, postRequest, 'GetByPaginated').subscribe((result) => {
        if (result != null && result["data"] != "") {
           this.JDList = result["data"];
        }
     })
   }
   }


async getRafList(id) {

this.rafList = [];
let postRequest = {
  "searchBy": [
     {
        "FieldName": "Open",
        "FieldValue": "0",
        "Parameter": ">",
        "ConditionWith": "&&"
     },
     {
        "FieldName": "site_Id",
        "FieldValue": id,
        "Parameter": "=="
     }
  ],
  "PageSize": 1000
}

if (id > 0) {
  await this.commonApiService.getPaginated(Commonvariable.VacancyService, null, null, null, null, 0, postRequest, 'RAFWiseGetPaginated').subscribe((result) => {
     if (result != null && result["data"] != "") {
        this.rafList = result["data"]["list"];
    
     }
  })
}
}






  
}

