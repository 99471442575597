import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html'
})
export class CountryComponent implements OnInit {

  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  countryform: FormGroup;
  countrylist: any;
  filterList: any;
  companylist: any;
  listcall: boolean = true;
  title: string;
  p: number = 1;
  totalRecord: 0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.title = "Add";
    this.countryform = this.fb.group({
      id: ['0'],
      country_Name: ['', Validators.required],
      country_Code: ['', Validators.required],
      district: [''],
      isActive: ['1'],
      isDefault: [null],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });

    if (this.listcall) {
      this.getCountryList(1);
    }

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }

  getCountryList(PageIndex = 1) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.countrylist = [];
    this.filterList = [];
    this.commonApiService.getListWithFilter(Commonvariable.Country, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.countrylist = result["data"]["list"];
        this.filterList = result["data"]["list"];
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);
      }
    })


  }


  Upsert() {
    var Form = this.countryform;

    if (this.validation()) {
      Form.get("isActive").setValue(Form.get("isActive").value ? 1 : 0);
      Form.get("isDefault").setValue(Form.get("isDefault").value ? 1 : 0);

      Form.get("updatedBy").setValue(Commonvariable.User_Id);
      this.commonApiService.Upsert(Commonvariable.Country, Form.value, Form.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Country');
          }
          else {
            $("#draggable").modal('hide');
            this.getCountryList(1);
            this.toastr.success(Form.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Country');
          }
        }
      })
    }

  }

  Delete(Id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Country, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getCountryList(1);
          this.toastr.success(response["message"], 'Country');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Country, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.getCountryList(1);
            this.toastr.success(response["message"], 'Country');
          }

        })
      }

    });
  }

  openModel(Id) {
    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Country, Id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var Form = this.countryform;
          this.commonService.setEditData(Form, editData);
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Country'); })
    }
    else {
      this.listcall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });


  }



  validation() {
    var Form = this.countryform;
    if (!Form.valid) {
      for (const key of Object.keys(Form.controls)) {
        if (Form.controls[key].invalid) {
          let fieldName = "";
          if (key == "country_Name") {
            fieldName = "Country Name"
          }
          else if (key == "country_Code") {
            fieldName = "Country Code"
          }

          this.toastr.info(fieldName + " is required !", "Country");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {

      if ($("#country_Name").val().length < 2) {
        this.toastr.info("Country Name must be at least 2 characters long !", "Country");
        $("#country_Name").focus();
        return false;
      }
      if (/["\\]/.test($("#country_Name").val())) {
        this.toastr.info("country Name does not allow Special Characters \" \\ ", "Country");
        $("#country_Name").focus();
        return false;
      }

      // if ($("#country_Name").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
      //   this.toastr.info("Country Name only alphabets and numeric allow !", "Country");
      //   $("#country_Name").focus();
      //   return false;
      // }
      if ($("#district").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
        this.toastr.info("District only alphabets and numeric allow !", "Country");
        $("#district").focus();
        return false;
      }

      // if ($("#country_Name").val().search(/^[^-\s][a-zA-Z0-9\s.]+[^-\s]+$/) != 0) {
      //    this.toastr.info("Country Name only alphabets and numeric allow !", "Country");
      //    $("#country_Name").focus();
      //    return false;
      // }
      if ($("#country_Code").val().indexOf(" ") > -1) {
        this.toastr.info("Country code not allow space !", "Country");
        $("#country_Code").focus();
        return false;
      }
      // if ($("#country_Code").val().search(/[^a-zA-Z0-9]+/) > -1) {
      //   this.toastr.info("Country code only alphabets and numeric allow !", "Country");
      //   $("#country_Code").focus();
      //   return false;
      // }
    }
    this.commonService.setNull(Form);
    return true;
  }


  history() {
    this.historyView.getHistory("country", this.countryform.controls.id.value)
  }

  checkDefaultData(event) {

    if (event.target.checked) {
      
      var Form = this.countryform;
      this.commonApiService.List(Commonvariable.Country, "CheckDefaultData").subscribe((response) => {
        if (response["data"] != null) {
          this.commonService.dyanmicConfirm("You want to set as default country  !", "Yes !").then((result) => {
            if (result.value) {
              //console.log(response["data"])                            
            }else{
              Form.get("isDefault").setValue(0);
            }
          });          
        }
      });
    }
  }

}
