<!-- <div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>Leave Dashboard
                </h1>
            </div>
        </div>
        <div class="portfolio-content portfolio-1">
            <div id="js-grid-juicy-projects" class="cbp-caption-active cbp-caption-overlayBottomReveal cbp-ready mb-5">

                <div class="row mb-3">
                    <div class="col-xl-3 col-sm-3 py-2" *ngFor="let item of leaveTypeAll">
                        <div class="card text-white h-100" style="margin-top: 17px;">
                            <a routerLink="/add-leave" style="text-decoration:none;">
                                <div class="card-body bg-success"
                                    style="padding: 16px;background:#fff!important;color: #678098;min-height: 134px;border-radius: 7px!important;">
                                    <h6 class="text-uppercase" style="font-size: 21px;font-weight: 500;">
                                        {{item.leave_Type}}</h6>
                                    <h1 class="display-4">{{item.balance_leave}}/{{item.total_leave}}</h1>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<style>
  .chart-container {
    width: 100%; /* Set the width to 100% for responsiveness */
  }
  .upcomingLeaveBox {
    background-color: #fff;
    padding: 15px;
  }
  .order-card {
    color: #fff;
  }

  .bg-c-blue {
    background: linear-gradient(45deg, #4099ff, #73b4ff);
  }

  .bg-c-green {
    background: linear-gradient(45deg, #2ed8b6, #59e0c5);
  }

  .bg-c-yellow {
    background: linear-gradient(45deg, #ffb64d, #ffcb80);
  }

  .bg-c-pink {
    background: linear-gradient(45deg, #ff5370, #ff869a);
  }
  .bg-c-cyan {
    background: linear-gradient(45deg, #01ffff, #00b8ea);
  }
  .bg-c-grey {
    background: linear-gradient(45deg, #d3d3d3, #c4c4c4);
  }
  .Leaveheading h1 {
    color: #697882;
    font-size: 22px;
    font-weight: 400;
    margin: 0;
    padding: 25px;
  }

  .card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
    box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
    border: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    height: 180px;
  }

  .card .card-block {
    padding: 25px;
    text-align: center;
  }

  .order-card i {
    font-size: 26px;
  }

  .f-left {
    float: left;
  }

  .f-right {
    float: right;
  }

  .portlet {
    margin-left: 0px !important;
  }

  .portlet.box.grey {
    min-height: 473px;
  }

  .portlet.box > .portlet-body {
    min-height: 473px;
  }
  .carousel-control.right,
  .carousel-control.left{
    background: #fbfdff;
    width: 35px;
    height: 35px;
    right: 0;
    opacity: 1;
    color: #666;
    text-align: center;
    text-shadow: none;
    top: 35%;
  }
.carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
    margin-right: -15px;
}
  .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next, .carousel-control .icon-prev{
    margin-top: -8px;
    font-size: 20px;
}
.carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
    margin-left: -15px;
}
#myCarousel{
  transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
}
#myCarousel .carousel-control.right,
#myCarousel .carousel-control.left{
  opacity: 0;
}
#myCarousel:hover .carousel-control.right,
#myCarousel:hover .carousel-control.left
{
  opacity: 1;
}
</style>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-head">
      <div class="page-title">
        <h1>Leave Dashboard</h1>
      </div>
    </div>
    <div class="portfolio-content portfolio-1">
      <div class="row">

        <div class="col-md-12">
          <div id="myCarousel" class="carousel slide" data-ride="carousel">
            <!-- Wrapper for slides -->
            <div class="carousel-inner">
              <div class="item active">
                <div class="row">
                  <div class="col-md-2">
                    <div class="card bg-c-blue order-card">
                      <div class="card-block">
                        <!-- <h6 class="m-b-20">New Signup</h6> -->
  
                        <h3 class="text-center">Annual Leave</h3>
                        <span>Total: 15</span><br />
                        <span>Remaining: 10</span>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-2">
                    <div class="card bg-c-green order-card">
                      <div class="card-block">
                        <h3 class="text-center">Sick Leave</h3>
                        <span>Total: 5</span><br />
                        <span>Remaining: 3</span>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-2">
                    <div class="card bg-c-yellow order-card">
                      <div class="card-block">
                        <h3 class="text-center">Compassionate Leave</h3>
                        <span>Total: 5</span><br />
                        <span>Remaining: 4</span>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-2">
                    <div class="card bg-c-pink order-card">
                      <div class="card-block">
                        <h3 class="text-center">Maternity Leave</h3>
                        <span>Total: 14</span><br />
                        <span>Remaining: 14</span>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-2">
                    <div class="card bg-c-cyan order-card">
                      <div class="card-block">
                        <h3 class="text-center">Shared Parental</h3>
                        <span>Total: 14</span><br />
                        <span>Remaining: 14</span>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-2">
                    <div class="card bg-c-grey order-card">
                      <div class="card-block">
                        <h3 class="text-center">Paternity Leave</h3>
                        <span>Total: 14</span><br />
                        <span>Remaining: 14</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="item">
                <div class="row">
                  <div class="col-md-2">
                    <div class="card bg-c-blue order-card">
                      <div class="card-block">
                        <!-- <h6 class="m-b-20">New Signup</h6> -->
  
                        <h3 class="text-center">Parental Leave</h3>
                        <span>Total: 15</span><br />
                        <span>Remaining: 10</span>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-2">
                    <div class="card bg-c-green order-card">
                      <div class="card-block">
                        <h3 class="text-center">Parental Bereavement</h3>
                        <span>Total: 5</span><br />
                        <span>Remaining: 3</span>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-2">
                    <div class="card bg-c-yellow order-card">
                      <div class="card-block">
                        <h3 class="text-center">Authorised Unpaid</h3>
                        <span>Total: 5</span><br />
                        <span>Remaining: 4</span>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-2">
                    <div class="card bg-c-pink order-card">
                      <div class="card-block">
                        <h3 class="text-center">Unauthorised Unpaid</h3>
                        <span>Total: 14</span><br />
                        <span>Remaining: 14</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="card bg-c-cyan order-card">
                      <div class="card-block">
                        <h3 class="text-center">Study Leave & Training</h3>
                        <span>Total: 14</span><br />
                        <span>Remaining: 14</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="card bg-c-grey order-card">
                      <div class="card-block">
                        <h3 class="text-center">Other Authorised Paid</h3>
                        <span>Total: 14</span><br />
                        <span>Remaining: 14</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="item">
                <div class="row">
                  <div class="col-md-2">
                    <div class="card bg-c-blue order-card">
                      <div class="card-block">
                        <!-- <h6 class="m-b-20">New Signup</h6> -->
  
                        <h3 class="text-center">Unpaid Suspension</h3>
                        <span>Total: 15</span><br />
                        <span>Remaining: 10</span>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-2">
                    <div class="card bg-c-green order-card">
                      <div class="card-block">
                        <h3 class="text-center">Paid Suspension</h3>
                        <span>Total: 5</span><br />
                        <span>Remaining: 3</span>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-2">
                    <div class="card bg-c-yellow order-card">
                      <div class="card-block">
                        <h3 class="text-center">Jury Service</h3>
                        <span>Total: 5</span><br />
                        <span>Remaining: 4</span>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-2">
                    <div class="card bg-c-pink order-card">
                      <div class="card-block">
                        <h3 class="text-center">Working From Home</h3>
                        <span>Total: 14</span><br />
                        <span>Remaining: 14</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="card bg-c-cyan order-card">
                      <div class="card-block">
                        <h3 class="text-center">Carers Leaves</h3>
                        <span>Total: 14</span><br />
                        <span>Remaining: 14</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="card bg-c-grey order-card">
                      <div class="card-block">
                        <h3 class="text-center">AWOL</h3>
                        <span>Total: 14</span><br />
                        <span>Remaining: 14</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- Left and right controls -->
            <a class="left carousel-control" href="#myCarousel" data-slide="prev">
              <span class="glyphicon glyphicon-chevron-left"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="right carousel-control"
              href="#myCarousel"
              data-slide="next"
            >
              <span class="glyphicon glyphicon-chevron-right"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>

       

      </div>
      <div class="row">
        <div class="Leaveheading">
          <h1>Your Upcoming Leaves</h1>
        </div>

        <div class="col-md-12">
          <div class="upcomingLeaveBox">
            <div class="table-responsive" style="margin-top: 20px">
              <table
                id="example2"
                class="display table table-striped table-bordered"
                cellspacing="0"
                width="100%"
                style="border: 1px solid #e7ecf1"
              >
                <thead>
                  <tr>
                    <th class="text-center" style="width: 15%">From</th>
                    <th class="text-center" style="width: 15%">To</th>
                    <th class="text-center" style="width: 15%">
                      Types Of Leave
                    </th>
                    <th class="text-center" style="width: 10%">Status</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr>
                    <td>12/02/2024</td>
                    <td>15/02/2024</td>
                    <td>Annual Leave</td>
                    <td>Pending</td>
                  </tr>
                  <tr>
                    <td>12/02/2024</td>
                    <td>15/02/2024</td>
                    <td>Annual Leave</td>
                    <td>Pending</td>
                  </tr>
                  <tr>
                    <td>12/02/2024</td>
                    <td>15/02/2024</td>
                    <td>Annual Leave</td>
                    <td>Pending</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </div>
        <div class="row">
          <div class="Leaveheading"><h1>Employee Dashboard</h1></div>
        </div>
        <div class="row">
 <form method="get" [formGroup]="dashboardform">
          
          <div class="col-md-8">
            <div class="portlet box">
              <div class="portlet-body">
                <div class="row">
                  <div class="col-md-3">
                    <select id="company_Id" class="form-control" formControlName="company_Id" (change)="companyWiseGetData()">
                      <option value="">Select Company</option>
                      <option *ngFor="let company of companyList;" [value]="company.company_Id">{{company.company_Name}}</option>
                   </select>
                   
                  </div>
                  <div class="col-md-2">
                    <select id="Dept_Id" class="form-control" formControlName="Dept_Id" >
                      <option value="">Select Department</option>
                      <option *ngFor="let item of departmentList;" [value]="item.dept_Id">
                          {{item.dept_Name}}</option>
                  </select>
                  </div>
                  <div class="col-md-2">
                    <select id="Zone_Id" class="form-control" formControlName="Zone_Id">
                      <option value="">Select Zone</option>
                      <option *ngFor="let item of zoneList;" value={{item.zone_Id}}>
                          {{item.zone_Name}}</option>
                  </select>
                  </div>
                  <div class="col-md-3">
                    <div
                      class="input-group DOB"
                      id="dashboardEmpDate"
                      data-wrap="true"
                    >
                      <input
                        type="text"
                        class="form-control readonlyClass"
                        id=""
                        data-input
                         formControlName="dashboardDate"
                      />

                      <span class="input-group-btn" data-toggle>
                        <button
                          class="btn default form-control datePicker"
                          type="button"
                        >
                          <i class="fa fa-calendar"></i>
                        </button>
                      </span>
                    </div>
                    
                  </div>
                  <div class="col-md-2">
                    <button class="btn blue"  (click)="chartdata()">Go</button>
                  </div>
                  <div class="col-md-12">
                    <div class="chart-container">
                      <p-chart
                        type="bar"
                        [data]="data"
                        [options]="options"
                        height="412px;"
                      ></p-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="portlet box">
              <div class="portlet-body">
                <div class="row">
                  <div class="Leaveheading">
                    <h1>Top 5 Monthly Leave Trend</h1>
                  </div>
                  <div class="col-md-6">
                    <select id="company_Id" class="form-control" formControlName="company_Id" (change)="companyWiseGetData()">
                      <option value="">Select Company</option>
                      <option *ngFor="let company of companyList;" [value]="company.company_Id">{{company.company_Name}}</option>
                   </select>
                  </div>
                  <div class="col-md-6">
                    <div
                      class="input-group DOB"
                      id="dashboardEmpDate1"
                      data-wrap="true"

                    >
                      <input
                        type="text"
                        class="form-control readonlyClass"
                        id=""
                        data-input
                               formControlName="dashboardDate1"
                               (input)="leavedata()"
                      />

                      <span class="input-group-btn" data-toggle>
                        <button
                          class="btn default form-control datePicker"
                          type="button"
                        >
                          <i class="fa fa-calendar"></i>
                        </button>
                      </span>
                    </div>
                  </div>

                  
                  <div class="col-md-12 m-15">
                    <div class="table-responsive">
                      <table
                        id="example2"
                        class="display table table-striped table-bordered"
                        cellspacing="0"
                        width="100%"
                        style="border: 1px solid #e7ecf1"
                      >
                        <thead>
                          <tr>
                            <th class="text-center" style="width: 15%">
                              Leave Type
                            </th>
                            <th class="text-center" style="width: 15%">
                              Emp Count
                            </th>
                          </tr>
                        </thead>
                        <tbody class="text-center">
                          <tr>
                            <td>Annual Leave</td>
                            <td>255</td>
                          </tr>
                          <tr>
                            <td>Sick Leave</td>
                            <td>200</td>
                          </tr>
                          <tr>
                            <td>Shared Parental Leave</td>
                            <td>150</td>
                          </tr>
                          <tr>
                            <td>Paternity</td>
                            <td>5</td>
                          </tr>
                          <tr>
                            <td>Parental</td>
                            <td>50</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
 </form>
        </div>

        <div class="row" style="margin-top: -20px;">
          <div class="Leaveheading"><h1>Top 5 Un-Approved Leaves</h1></div>
          <div class="col-md-12">
            <div class="upcomingLeaveBox">

                <div class="table-responsive">
                  <table
                    id="example2"
                    class="display table table-striped table-bordered"
                    cellspacing="0"
                    width="100%"
                    style="border: 1px solid #e7ecf1"
                  >
                    <thead>
                      <tr>
                        <th class="text-center" style="width: 15%">
                          Pending No. Of Days
                        </th>
                        <th class="text-center" style="width: 15%">Company</th>
                        <th class="text-center" style="width: 15%">
                          Employee ID
                        </th>
                        <th class="text-center" style="width: 15%">
                          Employee Name
                        </th>
                      </tr>
                    </thead>
                    <tbody class="text-center">
                      <tr>
                        <td>7</td>
                        <td>Luxsh</td>
                        <td>140</td>
                        <td>Shubham</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Luxsh</td>
                        <td>140</td>
                        <td>Shubham</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Luxsh</td>
                        <td>140</td>
                        <td>Shubham</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Luxsh</td>
                        <td>140</td>
                        <td>Shubham</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Luxsh</td>
                        <td>140</td>
                        <td>Shubham</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

            </div>
          </div>
        </div>
    </div>
  </div>
</div>
