import { Component, OnInit,ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;

@Component({
  selector: 'app-marital-status',
  templateUrl: './marital-status.component.html'  
})
export class MaritalStatusComponent implements OnInit {

  @ViewChild(HistoryComponent,{static:false}) historyView : HistoryComponent;
  maritalStatusform: FormGroup;
  maritalstatuslist: any;
  filterList:any;
  companylist: any;
  listcall: boolean = true;
  title: string;
  p: number = 1;
  totalRecord:0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.title = "Add";
    this.maritalStatusform = this.fb.group({
      id: ['0'],      
      marital_Status: ['', Validators.required],      
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],      
      updatedBy: [Commonvariable.User_Id]
    });
    
    if (this.listcall){
      this.getMaritalStatusList();      
    }  

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);
      
  }

  getMaritalStatusList(PageIndex = 1) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.maritalstatuslist = [];
    this.filterList = [];
    this.commonApiService.getListWithFilter(Commonvariable.Marital_Status, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.maritalstatuslist = result["data"]["list"];
        this.filterList = result["data"]["list"];
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })    
  }


  MaritalStatusUpsert() {
    var Form = this.maritalStatusform;

    if (this.validation()) {
      Form.get("marital_Status").setValue(Form.get("marital_Status").value.trim());
      Form.get("isActive").setValue(Form.get("isActive").value ? 1 : 0);      
      this.commonApiService.Upsert(Commonvariable.Marital_Status, Form.value, Form.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Marital Status');
          }
          else {
            $("#draggable").modal('hide');
            this.getMaritalStatusList();
            this.toastr.success(Form.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Marital Status');
          }
        }
      })
    }

  }

  RelationshipDelete(Id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Marital_Status, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getMaritalStatusList();
          this.toastr.success(response["message"], 'Marital Status');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Marital_Status, Id, status).subscribe((response) => {
          
          if (response["status"] == 200) {
            this.getMaritalStatusList();
            this.toastr.success(response["message"], 'Marital Status');                        
          }

        })
      }

    });
  }

  openModel(Id) {
    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Marital_Status, Id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var Form = this.maritalStatusform;
          this.commonService.setEditData(Form, editData);                    
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Marital Status'); })
    }
    else {      
      this.listcall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });


  }

  validation() {
    var Form = this.maritalStatusform;
    if (!Form.valid) {
      for (const key of Object.keys(Form.controls)) {
        if (Form.controls[key].invalid) {
          let fieldName = "";
          
          if (key == "marital_Status") {
            fieldName = "Marital status"
          }            
          this.toastr.info(fieldName + " is required !", "Marital Status");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {     
      if ($("#marital_Status").val().length < 2) {
        this.toastr.info("Marital status must be at least 2 characters long !", "Marital Status");
        $("#marital_Status").focus();
        return false;
      } 
      if ($("#marital_Status").val().indexOf(" ") > -1) {
        this.toastr.info("Marital status not allow space !", "Marital Status");
        $("#marital_Status").focus();
        return false;
      }     
      if ($("#marital_Status").val().search(/[^a-zA-Z-]+/) > -1) {
        this.toastr.info("Marital status only alphabets allow !", "Marital Status");
        $("#marital_Status").focus();
        return false;
     } 
     
      // if ($("#marital_Status").val().search(/^[^-\s][a-zA-Z0-9\s.]+[^-\s]+$/) != 0) {
      //    this.toastr.info("Marital status only alphabets and numeric allow !", "Marital Status");
      //    $("#marital_Status").focus();
      //    return false;
      // }
    }

    return true;
  }

  history() {
    this.historyView.getHistory("marital_status",this.maritalStatusform.controls.id.value)
  }
}