import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { LeftMenuComponent } from './../../left-menu/left-menu.component'
import { Select2OptionData } from 'ng-select2';
declare var $: any;


@Component({
  selector: 'app-company-selection',
  templateUrl: './company-selection.component.html'
})
export class CompanySelectionComponent implements OnInit {


  companylist: Array<Select2OptionData>;
  companyId: any;
  currentUser: any;

  constructor(
    private commonApiService: CommonApiService,
    private router: Router) { }

  ngOnInit() {
    $('#draggable').modal({
      backdrop: 'static'
    });
    this.getCompanyList()
  }


  getCompanyList() {
    this.currentUser = JSON.parse(localStorage.getItem('currentHRUser'));
    this.companyId = this.currentUser["data"].company_Id
    this.companylist = this.currentUser["data"]["emp_Id"].map(x => ({
      id: x.company_Id,
      text: x.company_Name
    }))
  }


  Select() {

    //Update company start    
    Commonvariable.Company_Id = this.companyId;
    
    this.currentUser["data"].company_Id = this.companyId;
    localStorage.setItem('currentHRUser', JSON.stringify(this.currentUser));
    Commonvariable.hrEmpId =this.currentUser["data"]["emp_Id"].filter(x => x.company_Id == this.companyId)[0].emp_Id
    localStorage.setItem('HR_Emp_Id', this.currentUser["data"]["emp_Id"].filter(x => x.company_Id == this.companyId)[0].emp_Id);
    //Update company end

    if (this.currentUser["data"]["role"] == "Candidate") {
      this.router.navigateByUrl('candidateedit');
    }
    else {
      if (parseInt(this.currentUser["data"]["person_Id"]) > 0 && this.currentUser["data"]["person_Id"] != null) {
        if (this.currentUser["data"]["role"] == "User") {
          this.router.navigateByUrl('userviewsummary');
        }
        else {
          this.router.navigateByUrl('dashboard');
        }
      }
      else {
        this.router.navigateByUrl('dashboard');
      }
    }
  }




  Back() {

    localStorage.clear();
    sessionStorage.clear();
    this.router.navigateByUrl('home');
  }


}
