import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { InterviewComponent } from "../interview/interview.component";
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import fieldVisibleInvisibleData from 'src/app/_files/field_visible_invisible.json';
import { HistoryComponent } from 'src/app/pages/history/history.component';
declare var $: any;
declare const Bloodhound;

@Component({
   selector: 'app-add-new-candidate',
   templateUrl: './add-new-candidate.component.html'
})
export class AddNewCandidateComponent implements OnInit {
   @ViewChild(InterviewComponent, { static: false }) Interview: InterviewComponent;
   @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
   logoClass = "fileinput fileinput-new";
   logoInnerHTML = "";
   candidateLogo: any;
   candidateLogoChange: boolean = false;
   candidateinfoform: FormGroup;
   ethinicityList: any;
   items: FormArray;
   editMode: boolean = false;
   documentList: any;
   appliedList: any;
   statusChange: boolean = false;
   fileName: any;
   fileUrl;
   editid: any;
   candidateid: any;
   approvedRAFList: Array<Select2OptionData> = [{ id: '0', text: 'Speculative' }];
   countryList: Array<Select2OptionData>;
   skillList: any;
   jobLevelList: any;
   skill: any;
   jobCategoryList: any;
   tagsList: any;
   prefereLocationList: any;
   candidateCV: any;
   changeCandidateCV: boolean = false;
   fieldVisibleInvisible: any;
   displayCV: boolean = false;
   cvName = "";
   currenciesList: any[] = [];


   constructor(private commonApiService: CommonApiService,
      private toastr: ToastrService,
      private commonService: CommonService,
      private route: ActivatedRoute,
      private sanitizer: DomSanitizer,
      private router: Router,
      private fb: FormBuilder
   ) {

      this.route.queryParamMap.subscribe(params => {
         this.ngOnInit();
         $('span[data-role="remove"]').click()
         $("#FirstName").focus()
         this.editid = params["params"]["editId"];
         if (this.editid) {
            this.Edit(atob(this.editid));
         }
      });
   }

   ngOnInit() {
      this.cvName = "";
      this.fieldVisibleInvisible = fieldVisibleInvisibleData[0].Candidate.Add_New_Candidate;
      this.candidateinfoform = this.fb.group({
         Id: ['0'],
         person_Id: ['0'],
         Title: [''],
         Gender_Id: ['0'],
         FirstName: ['', Validators.required],
         MiddleName: [''],
         LastName: ['', Validators.required],
         EMail: ['', Validators.required],
         Mobile: ['', Validators.required],
         Country_Id: [null],
         Expected_Salary: [null],
         currency_Id: [null],
         NoticeDay: ['0'],
         NoticeMonth: ['0'],
         NoticePeriodType: ['Month'],
         MoveToOnboarding: ['0'],
         Vacancy_Id: [null],
         isRightToWork: [''],
         isShortlisted: ['0'],
         Tags: [null],
         Skills: [null],
         Job_Category: [null],
         Job_Level: [null],
         lstJob_Category: [null],
         lstJob_Level: [null],
         Prefer_Location: [null],
         Notes: [null],
         Experience: [''],
         person_Email_Id: [0],
         person_Mobile_Id: [0],
         isActive: ['1'],
         AddedBy: [Commonvariable.User_Id],
         UpdatedBy: [Commonvariable.User_Id],
         emp_document: this.fb.array([this.createItem(null)]),
         candidate_Note: this.fb.array([this.createItemNote(null)]),
      });
      if (this.candidateinfoform.controls.Id.value > 0) {
         this.displayCV = true;
      }
      else {
         this.displayCV = false;
      }

      //$('#Vacancy_Id').select2({ width: '100%', dropdownCssClass: "bigdrop" });
      this.getDocument();
      this.getRAFApproved();
      this.skills();
      this.jobLevel();
      this.jobCategory();
      this.tags();
      //this.preferLocation();
      this.getCountryList();
      this.getCurrencies();
      setTimeout(() => {
         $('[data-toggle="tooltip"]').tooltip();
      }, 500);

   }


   getCurrencies() {
      this.commonApiService.List(Commonvariable.Currencies).subscribe((result) => {
         if (result["data"] != "")
            this.currenciesList = result["data"].filter(x => x.isActive != 0).sort((a, b) => a.currency_Name < b.currency_Name ? -1 : a.currency_Name > b.currency_Name ? 1 : 0).map(x => ({
               id: x.id,
               text: x.currency_Name + " - " + x.currency_Code
            }));;
         //console.log(result["data"].filter(x => x.isDefault == 1)[0]["id"])
         if (this.editMode == false) {
            this.candidateinfoform.controls.currency_Id.setValue(result["data"].filter(x => x.isDefault == 1)[0]["id"]);
         }


      })
   }



   getCountryList() {

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "country_Name"
      }
      this.commonApiService.GetByPaginated(Commonvariable.Country, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {

            this.countryList = response["data"].map(x => ({
               id: x.id,
               text: x.country_Name + " (" + x.country_Code + ") "
            }));

            //console.log(response["data"].filter(x => x.isDefault == 1)[0]["id"])
            if (this.editMode === false) {
               this.candidateinfoform.controls.Country_Id.setValue(response["data"].filter(x => x.isDefault == 1)[0]["id"]);
            }

         }
      })
   }


   skills() {
      this.skillList = [];
      this.commonApiService.List('Skills').subscribe((response: any) => {

         if (response["data"] != null && response["status"] == 200 && response["data"] != "") {
            var tempArray = []
            this.skillList = response["data"];

            for (let index = 0; index < this.skillList.length; index++) {
               var json = {
                  name: this.skillList[index].name,
                  id: this.skillList[index].id,
               }
               tempArray.push(json);

               if (index == (this.skillList.length - 1)) {
                  this.skillInitialize(tempArray);
                  break;
               }

            }
         }
      })
   }


   skillInitialize(data) {

      if (data.length > 0) {
         this.skill = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            local: data
         });

         $('#Skills').tagsinput({
            typeaheadjs: {
               name: 'skills',
               displayKey: 'name',
               valueKey: 'name',
               source: this.skill.ttAdapter()
            }
         });
         this.skill.initialize();
      }
   }

   jobLevel() {
      this.jobLevelList = [];
      this.commonApiService.List(Commonvariable.Designation, 'GetAllNames').subscribe((response: any) => {

         if (response["data"] != null && response["status"] == 200 && response["data"] != "") {
            this.jobLevelList = response["data"].map(x => ({
               id: x,
               text: x
            }));;
         }
      })
   }

   jobCategory() {
      this.jobCategoryList = [];
      this.commonApiService.List(Commonvariable.Department, 'GetAllNames').subscribe((response: any) => {
         if (response["data"] != null && response["status"] == 200 && response["data"] != "") {
            this.jobCategoryList = response["data"].map(x => ({
               id: x,
               text: x
            }));
         }
      })
   }


   tags() {
      this.tagsList = [];
      this.commonApiService.List('Tags').subscribe((response: any) => {

         if (response["data"] != null && response["status"] == 200 && response["data"] != "") {
            var tempArray = []
            this.tagsList = response["data"];

            for (let index = 0; index < this.tagsList.length; index++) {
               var json = {
                  name: this.tagsList[index].name,
                  id: this.tagsList[index].id,
               }
               tempArray.push(json);

               if (index == (this.tagsList.length - 1)) {
                  this.tagsInitialize(tempArray);
                  break;
               }

            }
         }
      })
   }


   tagsInitialize(data) {

      if (data.length > 0) {
         this.skill = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            local: data
         });

         $('#Tags').tagsinput({
            typeaheadjs: {
               name: 'tags',
               displayKey: 'name',
               valueKey: 'name',
               source: this.skill.ttAdapter()
            }
         });
         this.skill.initialize();
      }
   }

   preferLocation() {
      this.prefereLocationList = [];
      this.commonApiService.ListCity('Site').subscribe((response: any) => {

         if (response["data"] != null && response["status"] == 200 && response["data"] != "") {
            var tempArray = []
            this.prefereLocationList = response["data"];

            for (let index = 0; index < this.prefereLocationList.length; index++) {
               var json = {
                  city: this.prefereLocationList[index].city
               }
               tempArray.push(json);

               if (index == (this.prefereLocationList.length - 1)) {
                  this.preferLocationInitialize(tempArray);
                  break;
               }

            }
         }
      })
   }


   preferLocationInitialize(data) {

      if (data.length > 0) {
         this.skill = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('city'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            local: data
         });

         // $('#Prefer_Location').tagsinput({
         //    typeaheadjs: {
         //       name: 'preferLocation',
         //       displayKey: 'city',
         //       valueKey: 'city',
         //       source: this.skill.ttAdapter()
         //    }
         // });
         this.skill.initialize();
      }
   }


   getRAFApproved() {
      var requestData = {
         "searchBy": [
            {
               "FieldName": 'status',
               "FieldValue": '\"Approved\"', // oldname Live Vacancy
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": 'approvalStatus',
               "FieldValue": '\"Approved\"', // oldname Live Vacancy
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": 'open',
               "FieldValue": '0',
               "Parameter": ">",
               "ConditionWith": "&&"
            },
            {
               "FieldName": 'isActive',
               "FieldValue": 1,
               "Parameter": "=="
            }
         ],
         "OrderBy": 'desig_Name',
         "PageSize": 1000,
         "RecordLimit": 1000
      }
      this.commonApiService.getPaginated(Commonvariable.VacancyService, null, null, null, null, null, requestData).subscribe((response) => {
         if (response["data"] != null && response["data"] != "") {
            this.approvedRAFList = response["data"]['list'].map(x => ({
               id: x.id,
               text: x.desig_Name
            }));
         }
      })


   }



   getDocument() {

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": 'name'
      }

      this.commonApiService.GetByPaginated(Commonvariable.Document, requestData).subscribe((response) => {
         if (response["data"] != null && response["data"] != "") {
            this.documentList = response["data"];
            this.getAppliedList()
         }
      })
   }




   getAppliedList() {

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ]
      }
      this.commonApiService.getPaginated(Commonvariable.Vacancy_Approval, null, null, null, null, 10, requestData).subscribe((response) => {
         if (response["data"] != null && response["data"] != "" && response["status"] == 200) {
            this.appliedList = response["data"]["list"];
         }
      })
   }

   addItem(data) {
      if (data === null) {
         if (this.validationAddMoreItem()) {
            this.items = this.candidateinfoform.get('emp_document') as FormArray;
            // this.items= data.forEach(element => {
            this.items.push(this.createItem(data));
         }
         //}
      } else {
         this.items = this.candidateinfoform.get('emp_document') as FormArray;


         this.items.push(this.createItem(data));

      }


   }
   removeItem(index) {
      this.items = this.candidateinfoform.get('emp_document') as FormArray;
      if (this.items.value[index]["Id"] > 0) {
         this.commonApiService.Delete(Commonvariable.Candidate_Document, this.items.value[index]["Id"]).subscribe((result) => {
            this.commonService.toaster("success", result["message"], "Candidate Document");
            this.items.removeAt(index);
         })
      }
      else {
         this.items.removeAt(index);
      }
   }


   createItem(data) {
      return this.fb.group({
         Id: data != null ? data.id : '0',
         JobSeeker_Id: data != null ? data.jobSeeker_Id : '0',
         Doc_Id: data != null ? parseInt(data.doc_Id) : '0',
         files: null,
         file_Name: data != null ? data.file_Name : null,
         Doc_Name: data != null ? data.doc_Name : null,
         onchange: false,
         isActive: true,
         addedBy: Commonvariable.User_Id,
      });
   }

   validationAddMoreItem() {
      var empData = this.candidateinfoform.get('emp_document').value;
      for (let index = 0; index < empData.length; index++) {
         if (empData[index].Doc_Id == "") {
            this.toastr.info("Document is required !", "Candidate info");
            $("#Doc_Id" + index).focus();
            return false;
         }
         if (empData[index].files == "" && empData[index].Id == 0) {
            this.toastr.info("File is required !", "Candidate info");
            return false;
         }
         empData[index].isActive = empData[index].isActive ? 1 : 0
      }
      return true;
   }


   addItemNote(data) {
      if (data === null) {
         if (this.validationAddMoreItemNote()) {
            this.items = this.candidateinfoform.get('candidate_Note') as FormArray;
            this.items.push(this.createItemNote(data));
         }
      } else {
         this.items = this.candidateinfoform.get('candidate_Note') as FormArray;
         this.items.push(this.createItemNote(data));
      }
   }
   removeItemNote(index) {
      this.items = this.candidateinfoform.get('candidate_Note') as FormArray;
      if (this.items.value[index]["Id"] > 0) {
         this.commonApiService.Delete(Commonvariable.Candidate_Note, this.items.value[index]["Id"]).subscribe((result) => {
            this.commonService.toaster("success", result["message"], "Candidate Note");
            this.items.removeAt(index);
         })
      }
      else {
         this.items.removeAt(index);
      }
   }


   createItemNote(data) {
      return this.fb.group({
         Id: data != null ? data.id : 0,
         Candidate_Id: data != null ? data.candidate_Id : 0,
         Emp_Id: data != null ? data.emp_Id : Commonvariable.hrEmpId != "" ? Commonvariable.hrEmpId : Commonvariable.emp_Id,
         Notes: data != null ? data.notes : '',
         addedBy: Commonvariable.User_Id,
      });

   }




   validationAddMoreItemNote() {
      var empData = this.candidateinfoform.get('candidate_Note').value;
      for (let index = 0; index < empData.length; index++) {
         if (empData[index].Notes == "") {
            this.toastr.info("Note is required !", "Candidate Note");
            $("#Notes" + index).focus();
            return false;
         }
      }


      return true;
   }


   async Edit(Id) {
      if (Id > 0) {
         this.editMode = true;
         await this.commonApiService.getById(Commonvariable.CandidateService, Id, "ViewSummary").subscribe((response) => {
            if (response != null) {

               var editData = response["data"][0];

               if (editData.doc_Name != null) {
                  this.displayCV = true;
                  this.cvName = editData.doc_Name;

               }
               this.candidateinfoform.controls.Id.setValue(editData.id);
               this.candidateinfoform.controls.person_Id.setValue(editData.person_Id);
               this.candidateinfoform.controls.person_Email_Id.setValue(editData.person_Email_Id);
               this.candidateinfoform.controls.person_Mobile_Id.setValue(editData.person_Mobile_Id);
               this.candidateinfoform.controls.FirstName.setValue(editData.firstName);
               this.candidateinfoform.controls.MiddleName.setValue(editData.middleName);
               this.candidateinfoform.controls.LastName.setValue(editData.lastName);
               this.candidateinfoform.controls.EMail.setValue(editData.eMail);
               this.candidateinfoform.controls.Mobile.setValue(editData.mobile);
               this.candidateinfoform.controls.Expected_Salary.setValue(editData.expected_Salary);
               this.candidateinfoform.controls.currency_Id.setValue(editData.currency_Id);
               //this.candidateinfoform.controls.NoticePeriod.setValue(editData.noticePeriod);
               this.candidateinfoform.controls.NoticeDay.setValue(editData.noticeDay);
               this.candidateinfoform.controls.NoticeMonth.setValue(editData.noticeMonth);
               //this.candidateinfoform.controls.NoticeYear.setValue(editData.noticeYear);

               this.candidateinfoform.controls.Country_Id.setValue(editData.country_Id);

               this.candidateinfoform.controls.isRightToWork.setValue(String(editData.isRightToWork));
               this.candidateinfoform.controls.Experience.setValue(editData.experience);
               // this.candidateinfoform.controls.Experience_Year.setValue(editData.experience_Year);
               // this.candidateinfoform.controls.Experience_Month.setValue(editData.experience_Month);
               this.candidateinfoform.controls.Gender_Id.setValue(String(editData.gender_Id));
               //this.candidateinfoform.controls.Vacancy_Id.setValue(String(editData.vacancy_Id).split(","));
               this.candidateinfoform.controls.Vacancy_Id.setValue('0');

               this.candidateinfoform.controls.Vacancy_Id.setValue(editData.vacancy_Id.length > 0 ? String(editData.vacancy_Id).split(",") : null);

               this.candidateinfoform.controls.lstJob_Category.setValue(editData.job_Category != null ? String(editData.job_Category).split("~") : null);

               this.candidateinfoform.controls.lstJob_Level.setValue(editData.job_Level != null ? String(editData.job_Level).split("~") : null);


               this.candidateinfoform.controls.Title.setValue(editData.title);

               this.candidateinfoform.controls.Tags.setValue(editData.tags);
               $("#Tags").val(editData.tags != null ? editData.tags.replace(/[ ~]+/g, ",") : editData.tags);
               $("#Tags").tagsinput('add', editData.tags != null ? editData.tags.replace(/[ ~]+/g, ",") : editData.tags);

               this.candidateinfoform.controls.Skills.setValue(editData.skills);
               $("#Skills").val(editData.skills != null ? editData.skills.replace(/[ ~]+/g, ",") : editData.skills);
               $("#Skills").tagsinput('add', editData.skills != null ? editData.skills.replace(/[ ~]+/g, ",") : editData.skills);

               // this.candidateinfoform.controls.Job_Category.setValue(editData.job_Category);
               // $("#Job_Category").val(editData.job_Category != null ? editData.job_Category.replace(/[ ~]+/g, ",") : editData.job_Category);
               // $("#Job_Category").tagsinput('add', editData.job_Category != null ? editData.job_Category.replace(/[ ~]+/g, ",") : editData.job_Category);

               // this.candidateinfoform.controls.Job_Level.setValue(editData.job_Level);
               // $("#Job_Level").val(editData.job_Level != null ? editData.job_Level.replace(/[ ~]+/g, ",") : editData.job_Level);
               // $("#Job_Level").tagsinput('add', editData.job_Level != null ? editData.job_Level.replace(/[ ~]+/g, ",") : editData.job_Level);

               //this.candidateinfoform.controls.Prefer_Location.setValue(editData.prefer_Location);
               //$("#Prefer_Location").val(editData.prefer_Location != null ? editData.prefer_Location.replace(/[ ~]+/g, ",") : editData.prefer_Location);
               //$("#Prefer_Location").tagsinput('add', editData.prefer_Location != null ? editData.prefer_Location.replace(/[ ~]+/g, ",") : editData.prefer_Location);


               this.items = this.candidateinfoform.get('emp_document') as FormArray;
               this.items.clear();
               this.getCurrencies();
               editData.candidate_documentView.forEach(element => {
                  this.items.push(this.createItem(element));
               });


               this.items = this.candidateinfoform.get('candidate_Note') as FormArray;
               this.items.clear();

               //console.log(editData.candidate_Note);
               editData.candidate_Note.forEach(element => {
                  this.items.push(this.createItemNote(element));
               });

            }
         })
      }
   }


   validation() {
      var formData = this.candidateinfoform;
      // formData.controls.Tags.setValue($("#Tags").val().replace(/[ ,]+/g, "~"));
      formData.controls.Skills.setValue($("#Skills").val().replace(/[ ,]+/g, "~"));
      // formData.controls.Job_Category.setValue($("#Job_Category").val().replace(/[ ,]+/g, "~"));
      // formData.controls.Job_Level.setValue($("#Job_Level").val().replace(/[ ,]+/g, "~"));
      //formData.controls.Prefer_Location.setValue($("#Prefer_Location").val().replace(/[ ,]+/g, "~"));


      for (const key of Object.keys(formData.controls)) {

         // if (key == "Title") {
         //    if (formData.value[key] == '') {
         //       this.toastr.info("Title  is required !", "Candidate info");
         //       return false;
         //    }
         // }

         // if (key == "Gender_Id") {
         //    if (formData.value[key] == '') {
         //       this.toastr.info("Gender  is required !", "Candidate info");
         //       return false;
         //    }
         // }

         if (key == "FirstName") {
            if (formData.value[key] == '') {
               this.toastr.info("FirstName  is required !", "Candidate info");
               $("#FirstName").focus();
               return false;
            }

            if ($("#FirstName").val() != "" && /["\\]/.test($("#FirstName").val())) {
               this.toastr.info("First Name does not allow Special Characters \" \\ ", "Candidate info");
               $("#FirstName").focus();
               return false;
            }
            // if ($("#FirstName").val().length < 2) {
            //    this.toastr.info("First Name must be at least 2 characters long !", "Candidate info");
            //    $("#FirstName").focus();
            //    return false;
            // }

            // if ($("#FirstName").val().indexOf(" ") > -1) {
            //    this.toastr.info("First Name not allow space !", "Candidate info");
            //    $("#FirstName").focus();
            //    return false;
            // }

            // if ($("#FirstName").val().search(/[^a-zA-Z-]+/) > -1) {
            //    this.toastr.info("First Name only alphabets allow !", "Candidate info");
            //    $("#FirstName").focus();
            //    return false;
            // }
         }

         if (key == "MiddleName") {
            if (formData.value[key] == '') {
               formData.controls[key].setValue(null)
            }

            if (($("#MiddleName").val() != "" || $("#MiddleName").val() != null) && /["\\]/.test($("#MiddleName").val())) {
               this.toastr.info("Middle Name does not allow Special Characters \" \\ ", "Candidate info");
               $("#MiddleName").focus();
               return false;
            }
         }

         if (key == "LastName") {
            if (formData.value[key] == '') {
               this.toastr.info("LastName  is required !", "Candidate info");
               $("#LastName").focus();
               return false;
            }

            if ($("#LastName").val() != "" && /["\\]/.test($("#LastName").val())) {
               this.toastr.info("Last Name does not allow Special Characters \" \\ ", "Candidate info");
               $("#LastName").focus();
               return false;
            }
            // if ($("#LastName").val().length < 2) {
            //    this.toastr.info("Last Name must be at least 2 characters long !", "Candidate info");
            //    $("#LastName").focus();
            //    return false;
            // }
            // if ($("#LastName").val().indexOf(" ") > -1) {
            //    this.toastr.info("Last Name not allow space !", "Candidate info");
            //    $("#LastName").focus();
            //    return false;
            // }
            // if ($("#LastName").val().search(/[^a-zA-Z-]+/) > -1) {
            //    this.toastr.info("Last Name only alphabets allow !", "Candidate info");
            //    $("#LastName").focus();
            //    return false;
            // }
         }

         if (key == "EMail") {
            if (formData.value[key] == '') {
               this.toastr.info("Email is required !", "Candidate info");
               $("#EMail").focus();
               return false;
            }

            if ($("#EMail").val() == "" && !this.editMode) {
               this.toastr.info("Email is required !", "Candidate info");
               $("#EMail").focus();
               return false;
            }

            if ($("#EMail").val().search(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/) != 0) {
               this.toastr.info("Enter valid email address !", "Candidate info");
               $("#EMail").focus();
               return false;
            }
         }


         if (key == "Country_Id") {
            if (formData.value[key] == "" || formData.value[key] == null) {
               this.toastr.info("Country code is required !", "Candidate info");
               $("#Country_Id select").focus();
               return false;
            }
         }


         if (key == "Mobile") {
            if (formData.value[key] == '') {
               this.toastr.info("Mobile is required !", "Candidate info");
               $("#Mobile").focus();
               return false;
            }

            // if ($("#Mobile").val() != "" && $("#Mobile").val().length < 9) {
            //    this.toastr.info("Mobile must be at least 9 digit long !", "Candidate info");
            //    $("#Mobile").focus();
            //    return false;
            // }

            if ($("#Mobile").val().search(/[^0-9-+()]+/) > -1) {
               this.toastr.info("Mobile only numeric value allow !", "Candidate info");
               $("#Mobile").focus();
               return false;
            }
         }


         if (key == "NoticeDay") {
            if (formData.value[key] > 31) {
               this.toastr.info("Enter valid notice period days !", "Candidate info");
               $("#NoticeDay").focus();
               return false;
            }
         }

         if (key == "NoticeMonth") {
            if (formData.value[key] > 12) {
               this.toastr.info("Enter valid notice period months !", "Candidate info");
               $("#Mobile").focus();
               return false;
            }

         }

         // if (key == "Experience_Month") {
         //    if (formData.value.Experience_Month > 11) {
         //       this.toastr.info("Experience month not more then 11 !", "Candidate info");
         //       $("#Experience_Month").focus();
         //       return false;
         //    }
         // }

         if (key == "Expected_Salary") {
            if ($("#Expected_Salary").val() != "" && $("#Expected_Salary").val() != undefined) {
               if ($("#Expected_Salary").val().search(/[^0-9.]+/) > -1) {
                  this.toastr.info("Salary only number allow !", "Candidate");
                  return false;
               }
            }
         }

         if (key == "isRightToWork") {
            if (formData.value.isRightToWork == '') {
               this.toastr.info("RightTo Work  is required !", "Candidate info");
               return false;
            }
         }
         // if (/["\\]/.test($("#addNotes{{i}}").val())) {
         //    this.toastr.info("Notes does not allow Special Characters \" \\ ", "Job Description");
         //    $("#addNotes{{i}}").focus();
         //    return false;
         //  }
         if ($("#AddNotes").val() != "" && /["\\]/.test($("#AddNotes").val())) {
            this.toastr.info("Notes does not allow Special Characters \" \\ ", "Candidate");
            $("#AddNotes").focus();
            return false;
         }

      }
      if (!this.changeCandidateCV && formData.value.Id == 0) {
         this.toastr.info("Candidate CV is required !", "Candidate info");
         return false;
      }

      //this.commonService.setNull(formData)
      return true;
   }



   candidateUpsert() {
      var cForm = this.candidateinfoform;
      cForm.controls.lstJob_Category.setValue(cForm.value.lstJob_Category == null ? [] : cForm.value.lstJob_Category)
      cForm.controls.lstJob_Level.setValue(cForm.value.lstJob_Level == null ? [] : cForm.value.lstJob_Level)

      //console.log(cForm.controls.emp_document);
      if (this.validation() && this.validationAddMoreItem()) {

         //&& this.validationAddMoreItem()

         var notesCandidate = this.candidateinfoform.get('candidate_Note').value;
         var formDatalengthNoteCand = notesCandidate.length;
         //  console.log(cForm.value);
         //  return false;


         this.commonApiService.Upsert(Commonvariable.CandidateService, cForm.value, cForm.value.Id).subscribe((response) => {
            if (response["data"] != null && response["data"] != "") {

               if (response["data"]["id"] != undefined) {
                  //this.upsertNote(response["data"]["id"]);
                  this.candidateid = response["data"]["id"];

                  if (this.changeCandidateCV) {
                     const formData = new FormData();
                     formData.append('candidateId', response["data"]["id"]);
                     formData.append('file', this.candidateCV);
                     this.commonApiService.UploadLogo(Commonvariable.JobSeeker, "UploadCV", formData, false).subscribe((result) => {
                        this.uploadDocument(response["data"]["id"]);
                     })

                  }
                  else {
                     this.uploadDocument(response["data"]["id"]);
                  }


               }
            }
         })
      }
   }

   upsertNote(id = null) {

      var notesCandidate = this.candidateinfoform.get('candidate_Note').value;
      var formDatalengthNoteCand = notesCandidate.length;
      // console.log(notesCandidate);
      // console.log(id); return false;


      for (let index = 0; index < notesCandidate.length; index++) {


         const formDataNote = new FormData();
         formDataNote.append('Id', notesCandidate[index].Id != null ? notesCandidate[index].Id : 0);
         formDataNote.append('Candidate_Id', id != null ? id : 0);
         formDataNote.append('Notes', notesCandidate[index].Notes);
         formDataNote.append('Emp_Id', notesCandidate[index].Emp_Id != '' ? notesCandidate[index].Emp_Id : Commonvariable.User_Id);
         formDataNote.append('AddedBy', Commonvariable.User_Id);
         //formDataNote.append('UpdatedBy', Commonvariable.User_Id);

         //console.log(formDataNote);return false;
         var editcall = notesCandidate[index].Id > 0 ? true : false;
         this.commonApiService.UploadLogo(Commonvariable.Candidate_Note, editcall == true ? "Edit" : "Add", formDataNote, editcall).subscribe((response) => {
            if (index == (formDatalengthNoteCand - 1)) {
               this.router.navigateByUrl("candidateoverview")
               this.toastr.success(this.candidateinfoform.value.Id > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Candidate Info');
            }
         });



      }
   }

   uploadDocument(id = null) {
      var documents = this.candidateinfoform.get('emp_document').value;
      var formDatalength = documents.length;
      //console.log(documents);
      for (let index = 0; index < documents.length; index++) {
         //if (documents[index].onchange) {
         const formData = new FormData();
         formData.append('Id', documents[index].Id);
         formData.append('Candidate_Id', id);
         formData.append('Doc_Id', documents[index].Doc_Id);
         formData.append('Doc_Name', (documents[index].Doc_Name == '' || documents[index].Doc_Name == null) ? '' : documents[index].Doc_Name);
         formData.append('isActive', '1');
         formData.append('AddedBy', Commonvariable.User_Id);
         formData.append('UpdatedBy', Commonvariable.User_Id);

         formData.append('files', documents[index].files);
         var editcall = documents[index].Id > 0 ? true : false;
         this.commonApiService.UploadLogo(Commonvariable.Candidate_Document, editcall == true ? "Edit" : "Add", formData, editcall).subscribe((response) => {
            if (index == (formDatalength - 1)) {
               this.router.navigateByUrl("candidateoverview")
               this.toastr.success(this.candidateinfoform.value.Id > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Candidate Info');
            }
         });
         // }
         // else {
         //    if (index == (formDatalength - 1)) {
         //       this.router.navigateByUrl('candidateoverview');
         //       this.toastr.success(this.candidateinfoform.value.Id > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Candidate Info');
         //    }
         // }
      }
      if (documents.length == 0) {
         this.router.navigateByUrl('candidateoverview');
         this.toastr.success(this.candidateinfoform.value.Id > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Candidate Info');
      }
   }


   fileChangeMultiple(event, id) {
      // if (event.target.files.item(0).name.split('.')[1] == "pdf" || event.target.files.item(0).name.split('.')[1] == "doc"
      //    || event.target.files.item(0).name.split('.')[1] == "docx") {

      this.items = this.candidateinfoform.get('emp_document') as FormArray;

      if (this.items.value[id].Doc_Id != "") {
         this.items.value[id].files = event.target.files[0];
         this.items.value[id].onchange = true
      }
      else {
         this.toastr.info("Select document type !", "Employee");
         $("#Doc_Id" + id).focus();
         $("#remove" + id).click();
      }

      // }
      // else {
      //    this.toastr.info("Allow only pdf,doc,docx file format for upload !", "Employee");
      //    $("#remove" + id).click();
      // }

   }


   fileChangeForCV(event, id) {

      if (event.target.files.item(0).name.split('.')[1] == "pdf" || event.target.files.item(0).name.split('.')[1] == "doc"
         || event.target.files.item(0).name.split('.')[1] == "docx") {

         const formData = new FormData();
         formData.append('cvDocument', event.target.files[0]);

         this.commonApiService.UploadLogo(Commonvariable.CandidateService, "GetSkill", formData).subscribe((response) => {
            if (response["data"] != "") {
               $("#Skills").tagsinput('removeAll')
               $("#Skills").tagsinput('add', String(response["data"]));
            }
         });

         this.candidateCV = event.target.files[0];
         this.changeCandidateCV = true;
      }
      else {
         this.toastr.info("Allow only pdf,doc,docx file format for upload !", "Employee");
         $("#removeCV").click();
      }

   }

   fileChange(event, id) {

      if (event.target.files.item(0).name.split('.')[1] == "pdf" || event.target.files.item(0).name.split('.')[1] == "doc"
         || event.target.files.item(0).name.split('.')[1] == "docx") {

         const formData = new FormData();
         formData.append('cvDocument', event.target.files[0]);

         this.commonApiService.UploadLogo(Commonvariable.CandidateService, "GetSkill", formData).subscribe((response) => {
            if (response["data"] != "") {
               $("#Skills").tagsinput('removeAll')
               $("#Skills").tagsinput('add', String(response["data"]));
            }
         });

         this.candidateCV = event.target.files[0];
         this.changeCandidateCV = true;
      }
      else {
         this.toastr.info("Allow only pdf,doc,docx file format for upload !", "Employee");
         $("#removeCV").click();
      }

   }

   removeDoc(index) {
      this.candidateinfoform.get('emp_document').value[index].files = null;
      $("#candidateDocument" + index).removeClass("fileinput-exists")
      $("#candidateDocument" + index).addClass("fileinput-new")

   }



   downloadDocument(id) {

      if (id != 0) {
         this.fileName = this.candidateinfoform.get('emp_document').value.filter(x => x.Id == id)[0].file_Name;
         this.commonApiService.getimage(Commonvariable.Candidate_Document, id, "Download").subscribe((response) => {
            const blobData = new Blob([response], { type: response.type });
            var createObjectURL = (window.URL.createObjectURL(blobData));
            this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
            if (response.type == "application/pdf" || response.type == "image/jpeg" || response.type == "image/png") {
               $("#modalCandidateDocument").modal("show")
            } else if (response.type == "application/vnd.ms-word") {
               const a = document.createElement('a');
               a.setAttribute('type', 'hidden');
               a.href = URL.createObjectURL(blobData);
               a.download = 'download.docx';
               a.click();
               a.remove();
            } else {

               const a = document.createElement('a');
               a.setAttribute('type', 'hidden');
               a.href = URL.createObjectURL(blobData);
               a.download = 'download';
               a.click();
               a.remove();

            }

         })

      }
   }

   downloadCV() {
      var id = this.candidateinfoform.controls.Id.value;

      this.commonApiService.getimage(Commonvariable.JobSeeker, id, 'DownloadCV').subscribe((result) => {

         const blobData = new Blob([result], { type: result.type });
         var createObjectURL = (window.URL.createObjectURL(blobData));
         // console.log(result.type);
         // return false
         if (result.type == "application/pdf" || result.type == "image/jpeg" || result.type == "image/png") {

            this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
            $("#modalCandidateDocument").modal("show")
         } else {

            const a = document.createElement('a');
            a.setAttribute('type', 'hidden');
            a.href = URL.createObjectURL(blobData);
            a.download = 'CV';
            a.click();
            a.remove();

         }

      })
   }

   history() {
      this.historyView.getHistory("candidate", this.candidateinfoform.controls.Id.value)
   }

   setFormData(fieldName, id) {

      if (id != null) {
         if (id != undefined && id[0] != "") {
            this.candidateinfoform.controls[fieldName].setValue(id[0] == "" ? [] : id)
         }
         else {
            this.candidateinfoform.controls[fieldName].setValue([]);
         }
      }
      else {
         this.candidateinfoform.controls[fieldName].setValue(null);
      }
   }

}
