import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-dept-form',
  templateUrl: './dept-form.component.html'
})
export class DeptFormComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  departmentform: FormGroup;
  departmentlist: any;
  filterDepartment: any;
  companylist: Array<Select2OptionData>;
  listcall: boolean = true;
  checkValidation: boolean = false;
  title: string = "Add";
  p: number = 1;
  html: string = '';
  totalRecord: 0;
  @Input() pId?: any;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshPage: EventEmitter<number> = new EventEmitter();


  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private fb: FormBuilder,
    private toastr : ToastrService) { }



  ngOnInit() {

    document.body.className = "modal-open";

    this.title = "Add";
    this.departmentform = this.fb.group({
      dept_Id: ['0'],
      company_Id: ['', Validators.required],
      company_Name:[''],
      dept_Code: ['', Validators.required],
      dept_Name: ['', Validators.required],
      isActive: ['1'],
      notes: [''],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });


    this.getCompanyList();

    if (this.pId > 0) {
      this.openModel(this.pId);
    }


  }




  getDepartmentList(PageIndex) {
    this.p = PageIndex;
    this.totalRecord = 0;
    this.departmentlist = []
    this.commonApiService.getListWithFilter(Commonvariable.Department, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.departmentlist = result["data"]["list"];
        this.filterDepartment = result["data"]["list"];

        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })

  }




  getCompanyList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'company_Name'
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }


  get formControls(): any {
    return this.departmentform['controls'];
  }

  departmentUpsert() {
    var departForm = this.departmentform;

    if (this.validation()) {


      var deptId = departForm.controls.dept_Id.value;
      departForm.get("dept_Name").setValue(departForm.get("dept_Name").value.trim());
      departForm.get("isActive").setValue(departForm.get("isActive").value ? 1 : 0);
      this.commonApiService.UpsertWithMultiCompany(Commonvariable.Department, departForm.value, deptId).subscribe((response) => {
        if (response["status"] == 200) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Department');
          }
          else {
            this.closeModal();
            // $("#draggable").modal('hide');
            this.refreshPage.emit(1);
            this.toastr.success(deptId > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Department');
          }
        }
      })
    }
  }
  closeModal() {
    this.modalClose.emit(false)
  }

  openModel(dId) {

    if (dId > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Department, dId).subscribe((response) => {
        if (response != null && response["status"] == 200) {
          var editData = response["data"];
          var formData = this.departmentform;
          this.commonService.setEditData(formData, editData);
          formData.controls.updatedBy.setValue(Commonvariable.User_Id);
          this.departmentform.patchValue({
            company_Id : editData[0]['company_Id']
          })
          // $("#company_Id").val(editData[0]['company_Name']);
          // this.getDepartmentList();
          //  $("#dept_Code").prop("disabled", "disabled");
          
          
        }
      })
    }
    else {
      // $("#dept_Code").removeAttr("disabled")
      this.listcall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });

  }

  departmentDelete(dId) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Department, dId).subscribe((response) => {
          if (response["status"] == 200) {
            $("#draggable").modal('hide');
            this.getDepartmentList(1);
            this.toastr.success(response["message"], 'Department');
            this.p = 1;
          }
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Department, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.getDepartmentList(1);
            this.toastr.success(response["message"], 'Department');
          }

        })
      }

    });
  }
  validation() {
    var departForm = this.departmentform;
    if (!departForm.valid) {
      for (const key of Object.keys(departForm.controls)) {
        if (departForm.controls[key].invalid) {
          let fieldName = "";
          if (key == "company_Id") {
            fieldName = "Company"
            $("#company_Id select").focus();
          }
          else if (key == "dept_Code") {
            fieldName = "Department code"
          }
          else if (key == "dept_Name") {
            fieldName = "Department name"
          }
          this.toastr.info(fieldName + " is required !", "Department");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      // if ($("#dept_Code").val().indexOf(" ") > -1) {
      //   this.toastr.info("Department code not allow space !", "Department");
      //   $("#dept_Code").focus();
      //   return false;
      // }
      if ($("#dept_Code").val().length < 2) {
        this.toastr.info("Department code must be at least 2 characters long !", "Department");
        $("#dept_Code").focus();
        return false;
      }
      // if ($("#dept_Code").val().search(/[^a-zA-Z0-9]+/) > -1) {
      //   this.toastr.info("Department code only alphabets and numeric allow !", "Department");
      //   $("#dept_Code").focus();
      //   return false;
      // }
      if (/["\\]/.test($("#dept_Code").val())) {
        this.toastr.info("Department code does not allow Special Characters \" \\ ", "Department");
        $("#dept_Code").focus();
        return false;
      }
      if ($("#dept_Name").val().length < 2) {
        this.toastr.info("Department name must be at least 2 characters long !", "Department");
        $("#dept_Name").focus();
        return false;
      }
      if (/["\\]/.test($("#dept_Name").val())) {
        this.toastr.info("Department name does not allow Special Characters \" \\ ", "Department");
        $("#dept_Name").focus();
        return false;
      }
      // if ($("#dept_Name").val().search(/[^a-zA-Z ]+/) > -1) {
      //   this.toastr.info("Department name only alphabets allow !", "Department");
      //   $("#dept_Name").focus();
      //   return false;
      // }
      //   if ($("#dept_Name").val().search(/[^a-zA-Z]+/) > -1) {
      //     this.toastr.info("Department Name only alphabets allow !", "Employee");
      //     $("#dept_Name").focus();
      //     return false;
      //  }
      if (/["\\]/.test($("#notes").val())) {
        this.toastr.info("Notes does not allow Special Characters \" \\ ", "Department");
        $("#notes").focus();
        return false;
      }

    }
    return true;
  }


  history() {
    this.historyView.getHistory("department", this.departmentform.value.dept_Id)
  }

 

}


