<!-- <form>
    <div class="form-group">
        <label class="control-label col-md-2">Bank Name
            <span class="required"> * </span>
        </label>
        <div class="col-md-3">
            <input type="text" class="form-control" />
        </div>
        <label class="control-label col-md-2">Account No.
            <span class="required"> * </span>
        </label>
        <div class="col-md-3">
            <input type="text" class="form-control" />
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-md-2">IFSC Code
            <span class="required"> * </span>
        </label>
        <div class="col-md-3">
            <input type="text" class="form-control" />
        </div>
        <label class="control-label col-md-2">Account Name
            <span class="required"> * </span>
        </label>
        <div class="col-md-3">
            <input type="text" class="form-control" />
        </div>
    </div>
</form> -->



<form [formGroup]="empBankInfoform">
        <div class="form-group">
            <div class="table-responsive">
                <table class="table table-hover" id="work">
                    <thead>
                        <tr>
                            <th style="width: 20%;">NI Number</th>
                            <th style="width: 20%;">Loan</th>
                            <th style="width: 20%;">Bank Name <span class="required" *ngIf="this.empBankInfoform.get('emp_BankInfo').value[0].Bank_Name != '' || this.empBankInfoform.get('emp_BankInfo').value.length > 1"> * </span></th>
                            <th style="width: 20%;">Account Holder <span class="required" *ngIf="this.empBankInfoform.get('emp_BankInfo').value[0].Bank_Name != '' || this.empBankInfoform.get('emp_BankInfo').value.length > 1"> * </span></th>
                            <!-- <th style="width: 20%;">Account Type</th> -->
                            <th style="width: 10%;">Account No. <span class="required" *ngIf="this.empBankInfoform.get('emp_BankInfo').value[0].Bank_Name != '' || this.empBankInfoform.get('emp_BankInfo').value.length > 1"> * </span></th>
                            <th style="width: 10%;">Sort Code <span class="required" *ngIf="this.empBankInfoform.get('emp_BankInfo').value[0].Bank_Name != '' || this.empBankInfoform.get('emp_BankInfo').value.length > 1"> * </span></th>
                            <th style="width: 2%;">Active</th>
                            <th style="width: 5%;"><button *ngIf="this.empBankInfoform.get('emp_BankInfo').value[0].Bank_Name != ''" type="button" (click)="addItem(null)" class="btn pull-right"><i class="fa fa-plus"
                                        aria-hidden="true"></i></button></th>
                        </tr>
                    </thead>
                    <tbody formArrayName="emp_BankInfo" *ngFor="let item of empBankInfoform.get('emp_BankInfo')['controls']; let i = index;">
                        <tr [formGroupName]="i">
                            <td>
                                <input type="text" class="form-control" id="NiNo{{i}}" formControlName="NiNo" autocomplete="off">
                            </td>
                            <td>
                                <input type="text" class="form-control" id="Loan{{i}}" formControlName="Loan" autocomplete="off" maxlength="500">
                            </td>
                            <td>
                                <input type="text" class="form-control" id="Bank_Name{{i}}" formControlName="Bank_Name" autocomplete="off" maxlength="100">
                            </td>
                            <td>
                                    <input type="text" class="form-control" id="Account_Holder{{i}}" formControlName="Account_Holder" autocomplete="off" maxlength="30">
                            </td>
                            <!-- <td>
                                <input type="text" class="form-control" id="Account_Type{{i}}" formControlName="Account_Type" autocomplete="off" maxlength="30">
                            </td> -->
                            <td>
                                <input type="text" class="form-control" id="Account_No{{i}}" formControlName="Account_No" autocomplete="off" maxlength="20" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'>
                            </td>
                            <td>
                                <!-- <input type="text" class="form-control" id="Bank_Code{{i}}" formControlName="Bank_Code" autocomplete="off"> -->
                                <p-inputMask id="Bank_Code{{i}}" formControlName="Bank_Code" mask="99-99-99"></p-inputMask>
                           </td>
                            <td class="text-center">
                                <div class="md-checkbox-inline">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="BI{{i}}" class="md-check" formControlName="isActive">
                                        <label for="BI{{i}}">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> </label>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <button *ngIf ="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i class="fa fa-minus"
                                    aria-hidden="true"></i></button>
                            </td>
                        </tr>
    
                    </tbody>
                </table>
            </div>
        </div>
    </form>
    
    
    