<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>User List
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a (click)="openModel(0)" class="btn btn-transparent blue  btn-sm">Add User</a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <!-- <table datatable [dtOptions]="dtOptions" class="table table-sm  table-striped table-bordered table-hover"></table> -->
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" id="filterList"
                                placeholder="Type to filter the UserName column..." (input)="getUserList()"
                                autocomplete="off">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblDepartment">
                            <thead class="flip-content">
                                <tr>
                                    <th class="text-center" style="width: 30%;">UserName </th>
                                    <th class="text-center" style="width: 20%;"> Company </th>
                                    <th class="text-center" style="width: 20%;"> Employee </th>

                                    <th class="text-center" style="width: 10%;"> User Type </th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody
                                *ngFor="let item of userlist| paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">
                                <tr *ngIf="item.login_Id != 'superadmin'">
                                    <td>
                                        <div class="col-md-1">
                                            <a (click)="updateStatus(item.user_Id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.user_Id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        {{item.login_Id}}

                                    </td>
                                    <td>{{item.company_Name}}</td>
                                    <td>{{item.firstName}} {{item.lastName}}</td>
                                    <td>{{item.user_Type}}</td>
                                    <td class="numeric text-center">
                                        <a *ngIf="item.isActive" (click)="openModel(item.user_Id)"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit User"></i></a>&nbsp;
                                                &nbsp;

                                        <a *ngIf="item.isActive" (click)="ResetPassword(item.login_Id)"><i
                                                class="fa fa-unlock iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Reset Password"></i></a>
                                        <!-- <a *ngIf="!item.isActive" (click)="Delete(item.user_Id)"><i
                                                class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top" title="Delete User"></i></a> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="userlist != null">
                        <pagination-controls (pageChange)="getUserList($event)" autoHide="true"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->



<div class="modal fade draggable-modal" id="draggable" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} User</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="userform">

                    <div class="row">
                        <div class="col-md-6" *ngIf="companyvisible">
                            <div class="form-group">
                                <label class="control-label">Company<span class="required"> *
                                    </span></label>&nbsp;

                                <select id="company_Id" class="form-control companySelectClass"
                                    formControlName="company_Id">
                                    <option value="">Select Your Company</option>
                                    <option *ngFor="let company of companylist;" value={{company.company_Id}}>
                                        {{company.company_Name}}</option>
                                </select>

                            </div>

                            <!-- <div class="form-group" *ngIf="!companyvisible">
                                <label class="control-label"><span><b>Company</b> :</span>
                                    {{companyName}}</label>
                            </div> -->

                        </div>
                        <div class="col-md-6" *ngIf="empVisible">
                            <div class="form-group">
                                <label class="control-label">Employee<span class="required"> *
                                    </span></label>&nbsp;
                                <select id="person_Id" class="form-control employeeSelectClass"
                                    formControlName="person_Id" *ngIf="empVisible">
                                    <option value="">Select Your Employee</option>
                                    <option *ngFor="let emp of empList;" value={{emp.person_Id}}>
                                        {{emp.firstName}} {{emp.lastName}}</option>
                                </select>
                            </div>
                            <!-- <div class="form-group" *ngIf="!empVisible">
                                <label class="control-label"> <b>Employee</b>:
                                    {{empName}}</label>
                            </div>

                            <div class="form-group" *ngIf="!candidateVisible && candidate_Ids">
                                <label class="control-label" *ngIf="!candidateVisible && candidate_Ids">
                                    <b>Candidate</b>: {{candidateName}}</label>
                            </div> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">UserName<span class="required"> *</span></label>
                                <input type="text" id="login_Username" class="form-control" formControlName="login_Id"
                                    autocomplete="off">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">User Type<span class="required">*</span></label>
                                <!-- <input type="text" id="user_Type" class="form-control" formControlName="user_Type"
                                    autocomplete="off"> -->
                                <select id="user_Types" class="form-control userTypeSelectClass">
                                    <option value="">Select User Type</option>
                                    <option *ngFor="let item of roleList;" value={{item.id}}>
                                        {{item.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Email<span class="required">*</span></label>
                                <input type="text" id="email" class="form-control" formControlName="email"
                                    autocomplete="off">
                            </div>
                        </div>
                        <div class="col-md-5" *ngIf="passwordvisible">
                            <div class="form-group">
                                <label class="control-label">Password<span class="required">*</span></label>
                                <input type="password" id="password" class="form-control" formControlName="password"
                                    autocomplete="off">
                            </div>
                        </div> -->
                        <div class="col-md-1">
                            <label class="control-label">Active</label>
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="isActive" class="md-check" formControlName="isActive">
                                    <label for="isActive">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn  dark btn-outline pull-left" (click)="history()"
                    *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="Upsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="userSendMail" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Send Mail</h4>
            </div>
            <div class="modal-body">
                <app-sendmail></app-sendmail>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" data-dismiss="modal" class="btn blue" (click)="sendMail()">Send</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">User History</h4>
            </div>
            <div class="modal-body" *ngIf="title == 'Edit'">
                <app-history></app-history>
            </div>
        </div>
    </div>
</div>