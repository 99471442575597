import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import Swal from 'sweetalert2';
import { templateJitUrl } from '@angular/compiler';
declare var $: any;


@Component({
  selector: 'app-reason',
  templateUrl: './reason.component.html'
})
export class ReasonComponent implements OnInit {  
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  reasonform: FormGroup;
  reasonlist: any;
  filterReason: any;
  listCall: boolean = true;
  companylist: any;
  title: string;
  Toast: any;
  p: number = 1;  
  totalRecord: 0;  

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) { }



  ngOnInit() {
    this.title = "Add";
    this.reasonform = this.fb.group({
      id: ['0'],
      company_Id: [null],
      notes: ['', Validators.required],      
      isActive: ['1'],
      exit_Type:['Resignation'],      
      addedBy: [Commonvariable.User_Id],
      updatedBy: ['0']
    });

    if (this.listCall) {
      this.getReasonForLeaveList(1);
      this.getCompanyList();
    }

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);


  }

  getReasonForLeaveList(PageIndex=1) {


    this.p = PageIndex;
    this.totalRecord = 0;
    this.reasonlist =[];
    this.filterReason = [];

    this.commonApiService.getListWithFilter(Commonvariable.ReasonForLeave, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.reasonlist = result["data"]["list"];
        this.filterReason = result["data"]["list"];
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);
      }
    })
  }



  getCompanyList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "company_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company,requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {

        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))        
      }
    })
  }



  Upsert() {
    var reasonForm = this.reasonform;

    // var companyId = $("#company_Id").val();
    // reasonForm.controls.company_Id.setValue(companyId);

    if (this.validation()) {
      reasonForm.get("isActive").setValue(reasonForm.get("isActive").value ? 1 : 0);      
      this.commonApiService.UpsertWithMultiCompany(Commonvariable.ReasonForLeave, reasonForm.value, reasonForm.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Reason For Leave');
          }
          else {
            $("#draggable").modal('hide');
            this.getReasonForLeaveList(1);
            this.toastr.success(reasonForm.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Reason For Leave');
          }
        }
      })
    }

  }

  Delete(Id) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.ReasonForLeave, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getReasonForLeaveList(1);
          this.toastr.success(response["message"], 'Reason For Leave');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.ReasonForLeave, Id, status).subscribe((response) => {
          
          if (response["status"] == 200) {
            this.getReasonForLeaveList(1);
            this.toastr.success(response["message"], 'Reason For Leave');                        
          }

        })
      }

    });
  }

  openModel(Id) {

    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getByIdNew(Commonvariable.ReasonForLeave,Id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var formData = this.reasonform;
          this.commonService.setEditData(formData, editData);
         
          formData.controls.updatedBy.setValue(Commonvariable.User_Id);
          $("#company_Id").val(editData[0]['company_Name']);          
          
        }
      })
    }
    else {     
      this.listCall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });

  }

  filterData(event) {
    const val = event.target.value.toLowerCase().trim();
    if (val == "") {
      this.reasonlist = this.filterReason;
      this.p = 1;
    }
    else {
      const temp = this.filterReason.filter(function (d) {
        return String(d["company_Name"]).toLowerCase().indexOf(val) !== -1 || String(d["notes"]).toLowerCase().indexOf(val) !== -1 || !val;
      });      
      this.reasonlist = temp;
      this.p = 1;
    }
  }


  validation() {
    var reasonForm = this.reasonform;
    if (!reasonForm.valid) {

      for (const key of Object.keys(reasonForm.controls)) {
        if (reasonForm.controls[key].invalid) {
          let fieldName = "";
          // if (key == "company_Id") {
          //   fieldName = "Company"
          // }
          if (key == "notes") {
            fieldName = "Reason for Leave"
          }
          this.toastr.info(fieldName + " is required !", "Reason For Leave");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {      

      if (/["\\]/.test($("#notes").val())) {
        this.toastr.info("Reason for Leave does not allow Special Characters \" \\ ", "Reason For Leave");
        $("#notes").focus();
        return false;
      }
    }
    return true;
  }

  history() {
    this.historyView.getHistory("reasonforleave",this.reasonform.value.id)
  }

}