<div class="page-content-wrapper">

    <div class="page-content">

        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->

                <div class="page-title">
                    <h1>Add Leave</h1>                         
                </div>
                <div class="pull-right mr-20">
                    <a data-toggle="modal" href="#addLeaveModal" class="btn btn-transparent blue  btn-md" >
                         Add Leave
                    </a>
                </div>
               
           
            <!-- END PAGE TITLE -->
        </div>

        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">

                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" placeholder="Type to filter ..."
                                id="filterList">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table id="addLeaveTable" class="display table table-striped table-bordered"
                            cellspacing="0" width="100%" style="border:1px solid #e7ecf1;">
                            <thead>
                                <tr>
                                    <th class="text-center">Leave Type</th>
                                    <th class="text-center">Comapny</th>
                                    <th class="text-center">No. of Zones </th>
                                    <th class="text-center">Max Days</th>
                                    <th class="text-center">Min Days</th>
                                    <th class="text-center">By User </th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center">Annual Leave</td>
                                    <td class="text-center">LUXSH</td>
                                    <td class="text-center">2</td>
                                    <td class="text-center">10</td>
                                    <td class="text-center">8</td>
                                    <td class="text-center">Self</td>
                                    <td class="text-center">
                                        <a data-toggle="modal" data-target="#editLeaveModal">
                                        <i class="fa fa-edit purple iconcss iconFontsize mr-8" data-toggle="tooltip"
                                            data-placement="top" title="Edit Leave"></i>
                                        </a>                                       
                                        <a (click)="disableLeave()">
                                            <i class="fa fa-ban iconcss yellow-color iconFontsize mr-8" aria-hidden="true" data-toggle="tooltip"
                                            data-placement="top" title="Disable Leave"></i>
                                        </a>
                                        <a (click)="deleteLeave()">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                            data-toggle="tooltip" data-placement="top"
                                            title="Delete Leave"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">Annual Leave</td>
                                    <td class="text-center">SYRI</td>
                                    <td class="text-center">3</td>
                                    <td class="text-center">12</td>
                                    <td class="text-center">10</td>
                                    <td class="text-center">Approver</td>
                                    <td class="text-center">
                                        <a data-toggle="modal" data-target="#editLeaveModal">
                                        <i class="fa fa-edit purple iconcss iconFontsize mr-8" data-toggle="tooltip"
                                            data-placement="top" title="Edit Leave"></i>
                                        </a>                                       
                                        <a (click)="disableLeave()">
                                            <i class="fa fa-ban iconcss yellow-color iconFontsize mr-8" aria-hidden="true" data-toggle="tooltip"
                                            data-placement="top" title="Disable Leave"></i>
                                        </a>
                                        <a (click)="deleteLeave()">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                            data-toggle="tooltip" data-placement="top"
                                            title="Delete Leave"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">Paid Sick Leave</td>
                                    <td class="text-center">LUXSH</td>
                                    <td class="text-center">1</td>
                                    <td class="text-center">15</td>
                                    <td class="text-center">12</td>
                                    <td class="text-center">Approver</td>
                                    <td class="text-center">
                                        <a data-toggle="modal" data-target="#editLeaveModal">
                                        <i class="fa fa-edit purple iconcss iconFontsize mr-8" data-toggle="tooltip"
                                            data-placement="top" title="Edit Leave"></i>
                                        </a>                                       
                                        <a (click)="disableLeave()">
                                            <i class="fa fa-ban iconcss yellow-color iconFontsize mr-8" aria-hidden="true" data-toggle="tooltip"
                                            data-placement="top" title="Disable Leave"></i>
                                        </a>
                                        <a (click)="deleteLeave()">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                            data-toggle="tooltip" data-placement="top"
                                            title="Delete Leave"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center">Casual Leaves</td>
                                    <td class="text-center">LUXSH</td>
                                    <td class="text-center">2</td>
                                    <td class="text-center">18</td>
                                    <td class="text-center">14</td>
                                    <td class="text-center">Approver</td>
                                    <td class="text-center">
                                        <a data-toggle="modal" data-target="#editLeaveModal">
                                        <i class="fa fa-edit purple iconcss iconFontsize mr-8" data-toggle="tooltip"
                                            data-placement="top" title="Edit Leave"></i>
                                        </a>                                       
                                        <a (click)="enableLeave()">
                                            <i class="fa fa-check-circle iconcss green-color iconFontsize mr-8" aria-hidden="true" data-toggle="tooltip"
                                            data-placement="top" title="Enable Leave"></i>
                                        </a>
                                        <a (click)="deleteLeave()">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                            data-toggle="tooltip" data-placement="top"
                                            title="Delete Leave"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="text-center">
                        <pagination-controls  autoHide="true"></pagination-controls>
                    </div>

                </div>
            </div>
        </div>

    </div>

</div>

<!-- Add Leave Modal Start -->

<div class="modal fade" id="addLeaveModal" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">

            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Add Leave Type</h4>
            </div>

            <form action="">

                <div class="modal-body">
                
                        
                        <div class="row">                          

                           <div class="col-md-4 form-group">
                                <label class="form-label">Select Company<span class="red-color">*</span></label>
                                <ng-select2 [data]="data" [options]="options" [(ngModel)]="selectCompanyValue" placeholder="Select Company"></ng-select2>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label">Leave Type<span class="red-color">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Leave Name">
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label">Select Zone<span class="red-color">*</span></label>
                                <ng-select2 [data]="data1" [options]="options1" [(ngModel)]="selectZoneValue" placeholder="Select Zone"></ng-select2>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label">Minimum Leave days<span class="red-color">*</span></label>
                               <input type="number" class="form-control" placeholder="Enter No. of Days">
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label">Maximum Leave days<span class="red-color">*</span></label>
                               <input type="number" class="form-control" placeholder="Enter No. of Days">
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label">Entered in system by<span class="red-color">*</span></label>
                                <ng-select2 [data]="data2" [options]="options2" [(ngModel)]="selectUserValue" placeholder="Select User"></ng-select2>
                            </div>
                            
                        </div>

               
                </div>

            <div class="modal-footer">
                <button type="button" class="btn dark btn-outline" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn blue" data-dismiss="modal">Add Leave</button>               
            </div>

        </form>

        </div>
    </div>
</div>

<!-- Add Leave Modal End -->

<!-- Edit Leave Modal Start -->

<div class="modal fade" id="editLeaveModal" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">

            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Edit Leave Type</h4>
            </div>
            

            <form action="">

                <div class="modal-body">
                
                        
                        <div class="row">                          

                           <div class="col-md-4 form-group">
                                <label class="form-label">Select Company<span class="red-color">*</span></label>
                                <ng-select2 [data]="data" [options]="options" [(ngModel)]="selectCompanyValue" placeholder="Select Company"></ng-select2>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label">Leave Type<span class="red-color">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Leave Name" value="Sick Leave" readonly>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label">Select Zone<span class="red-color">*</span></label>
                                <ng-select2 [data]="data1" [options]="options1" [(ngModel)]="selectZoneValue" placeholder="Select Zone"></ng-select2>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label">Minimum Leave days<span class="red-color">*</span></label>
                               <input type="number" class="form-control" placeholder="Enter No. of Days" value="5" readonly>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label">Maximum Leave days<span class="red-color">*</span></label>
                               <input type="number" class="form-control" placeholder="Enter No. of Days" value="10" readonly>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="form-label">Entered in system by<span class="red-color">*</span></label>
                                <ng-select2 [data]="data2" [options]="options2" [(ngModel)]="selectUserValue" placeholder="Select User"></ng-select2>
                            </div>
                            
                        </div>

               
                </div>


            <div class="modal-footer">
                <button type="button" class="btn dark btn-outline" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn blue" data-dismiss="modal">Add Leave</button>               
            </div>

        </form>

        </div>
    </div>
</div>

<!-- Edit Leave Modal End -->
