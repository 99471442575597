<div [class]="classMainDiv">
    <div class="page-content">
        <div style="margin-bottom: 5%;" *ngIf="candidateLogin">

        </div>
        <div class="page-head" *ngIf="!candidateLogin">
            <div class="page-title">
                <h1>Onboarding Edit</h1>
            </div>
        </div>
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav">
                <li *ngIf="candidatePersonalInfo != null">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{candidatePersonalInfo.firstName}}
                    {{candidatePersonalInfo.lastName}}
                </li>
            </ul>
            <ul class="nav navbar-nav navbar-right">

                <li>
                    <div>
                        <a [href]="fileUrl" id="download" class="btn btn-transparent blue  btn-sm"
                            style="visibility: hidden;" [download]="fileName">Export</a>
                    </div>
                </li>
                <li *ngIf="!candidateLogin">
                    <div>
                        <a routerLink="/onboarding"><i class="fa fas fa-reply" data-toggle="tooltip"
                                data-placement="top" title="Back To Overview"></i></a>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-md-12">
            <div class="portlet box grey" style="margin-left: 0 !important;">
                <div class="portlet-body">
                    <div id="accordion1" class="panel-group">

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_1"> 1. Personal Details</a>
                                    <span style="float:right ;margin-top: -6px" *ngIf="candidatePersonalInfo != null">
                                        <button class="btn btn-sm dark btn-outline pull-right" type="button"
                                            data-toggle="modal" href="#personal-info"
                                            *ngIf="candidatePersonalInfo != null"
                                            (click)="editempInfo('CandidatePersonalInfo',candidatePersonalInfo.id)"
                                            data-backdrop="static" data-keyboard="false"><i class="fa fa-edit"
                                                data-toggle="tooltip" data-placement="bottom"
                                                title="Edit"></i></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_1" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="row">
                                        <div class="form-group">

                                            <label class="control-label col-md-1">Candidate Photo</label>
                                            <div class="col-md-11 form-group">


                                                <div [class]="logoClass" data-provides="fileinput">
                                                    <div class="fileinput-new thumbnail"
                                                        style="width: 200px; height: 150px;">
                                                        <img src="assets/layouts/layout4/img/selectPic.png" alt="" />
                                                    </div>
                                                    <div class="fileinput-preview fileinput-exists thumbnail"
                                                        [innerHTML]="logoInnerHTML"
                                                        style="max-width: 200px; max-height: 150px;"> </div>
                                                </div>

                                            </div>

                                            <label class="control-label col-md-1">Title</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{candidatePersonalInfo?.title}}" readonly />
                                            </div>

                                            <!-- <label class="control-label col-md-1">Gender</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{candidatePersonalInfo?.gender_Name}}" readonly />
                                            </div> -->

                                            <label class="control-label col-md-1">First Name</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{candidatePersonalInfo?.firstName}}" readonly />
                                            </div>

                                            <label class="control-label col-md-1">Middle Name</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{candidatePersonalInfo?.middleName}}" readonly />
                                            </div>

                                            <label class="control-label col-md-1">Last Name</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{candidatePersonalInfo?.lastName}}" readonly />
                                            </div>

                                            <label class="control-label col-md-1">Ethnicity</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{candidatePersonalInfo?.ethnicity_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-1">Date Of Birth</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{candidatePersonalInfo?.dob | date:'dd/MM/yyyy'}}"
                                                    readonly />
                                            </div>

                                            <!-- <label class="control-label col-md-1">Nationality</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{candidatePersonalInfo?.nationality_Name}}" readonly />
                                            </div> -->

                                            <label class="control-label col-md-1">Mobile Number</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{candidatePersonalInfo?.phoneNumber}}" readonly />
                                            </div>

                                            <label class="control-label col-md-1">Telephone</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{candidatePersonalInfo?.telephone}}" readonly />
                                            </div>

                                            <label class="control-label col-md-1">Email</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{candidatePersonalInfo?.email}}" readonly />
                                            </div>

                                            <!-- <label class="control-label col-md-1">Leaver</label>
                                            <div class="col-md-3 form-group">
                                             <div class="md-checkbox-inline">
                                                 <div class="md-checkbox" >
                                                     <input type="checkbox" id="myCheckbox" [(ngModel)]="checkboxValue" (change)="onCheckboxChange()">
                                                     <label for="myCheckbox">
                                                         <span></span>
                                                         <span class="check"></span>
                                                         <span class="box"></span> </label>
                                                 </div>
                                             </div> -->

                                            <!-- <label class="control-label col-md-1">Language</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{candidatePersonalInfo?.languages_Name}}" readonly />

                                            </div> -->




                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_3"> 2. Current Job Details <span
                                            *ngIf="candidateLogin">(Office Use Only)</span> </a>

                                    <span style="float:right ;margin-top:-6px" *ngIf="!candidateLogin">
                                        <button type="button" class="btn btn-sm dark btn-outline pull-right"
                                            data-toggle="modal" href="#job-details" *ngIf="candidateJobDetails != null"
                                            (click)="editempInfo('CandidateJDetail',candidateJobDetails.id)"
                                            data-backdrop="static" data-keyboard="false"><i class="fa fa-edit"
                                                data-toggle="tooltip"
                                                title="Edit"></i></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_3" class="panel-collapse collapse in">
                                <div class="panel-body" *ngIf="candidateJobDetails != null">

                                    <div class="row">
                                        <div class="form-group">
                                            <label class="control-label col-md-1">Company</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.candidateJobDetails.company_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-1">Site</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.candidateJobDetails.site_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-1">Department</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.candidateJobDetails.dept_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-1">Zone</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.candidateJobDetails.zone_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-1">Job Title</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.candidateJobDetails.desig_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-1">Job Description</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.candidateJobDetails.jD_Name}}" readonly />
                                                    <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="download()"
                                                    title="Download Job Description"><i class="fa fa-download fa-1x"></i></a>
                                            </div>
                                            
                                               
                                            

                                            <!-- <label class="control-label col-md-1">Shift Time</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.candidateJobDetails.shift_Name}} {{this.candidateJobDetails.shift_Time}}"
                                                    readonly />
                                            </div> -->

                                            <label class="control-label col-md-1">Shift Start</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" id="shift_Start" value="{{this.candidateJobDetails.shift_Start}}" readonly>
                                            </div>
                                            <label class="control-label col-md-1">Shift End</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" id="shift_End" value="{{this.candidateJobDetails.shift_End}}" readonly>
                                            </div>
                                            <!-- <label class="control-label col-md-1">Night Shift</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="candidateJobDetails.nightShift != 1" value="No" readonly />
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="candidateJobDetails.nightShift == 1" value="Yes" readonly />

                                                <label class="control-label" *ngIf="employeeSummary.tupe == 0">No</label>
                                            <label class="control-label" *ngIf="employeeSummary.tupe == 1">Yes</label> 
                                            </div> -->

                                            <label class="control-label col-md-1">Shift Variable</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="candidateJobDetails.shift_Variable != 1" value="No" readonly />
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="candidateJobDetails.shift_Variable == 1" value="Yes" readonly />

                                                <!-- <label class="control-label" *ngIf="employeeSummary.tupe == 0">No</label>
                                            <label class="control-label" *ngIf="employeeSummary.tupe == 1">Yes</label> -->
                                            </div>

                                            <label class="control-label col-md-1">Planned Start Date</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.candidateJobDetails.joiningDate | date :'dd/MM/yyyy'}}"
                                                    readonly />
                                            </div>

                                            <label class="control-label col-md-1">Email</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.candidateJobDetails.eMail}}" readonly />
                                            </div>

                                            <label class="control-label col-md-1">RAF</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.candidateJobDetails.vacancy_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-1">Holiday approver</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.candidateJobDetails.reporting_To}}" readonly />
                                            </div>

                                            <label class="control-label col-md-1">Employee Number</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.candidateJobDetails.emp_Code}}" readonly />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_9"> 3. Contract <span
                                            *ngIf="candidateLogin">(Office Use Only)</span> </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryList("\"onboarding_contract\"")' data-backdrop="static"
                                            data-keyboard="false"><i class="fa fa-history" data-toggle="tooltip"
                                                title="History"></i></button> -->
                                        <div style="margin-right: 66px;" *ngIf="candidateLogin"></div>
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            *ngIf="!candidateLogin" href="#emp-contract"
                                            (click)="editempInfo('CandidateContract',0)" style="float:right"
                                            data-backdrop="static" data-keyboard="false"><i class="fa fa-plus fa-1x"
                                                data-toggle="tooltip" title="Add Contract"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_9" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>

                                                    <th class="text-center">Contract Type</th>
                                                    <th class="text-center" style="width: 15%;"> Length of Contract
                                                    </th>
                                                    <th class="text-center" style="width: 15%;"> Contract Start
                                                    </th>
                                                    <th class="text-center" style="width: 15%;"> Contract End
                                                    </th>
                                                    <th class="text-center" style="width: 2%"> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of candidateContract">
                                                <tr>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.contract_Name}}">
                                                            {{item.contract_Name}}
                                                        </div>
                                                    </td>
                                                    <td>{{item.contract_Days}}</td>
                                                    <td>{{item.contract_Start | date :'dd/MM/yyyy'}}</td>
                                                    <td>{{item.contract_End | date :'dd/MM/yyyy'}}</td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-contract"
                                                            *ngIf="!candidateLogin"
                                                            (click)="editempInfo('CandidateContract',item.id)"
                                                            data-backdrop="static" data-keyboard="false"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Contract"></i></a>&nbsp;
                                                        <a (click)="Delete(9,item.id)"
                                                            *ngIf="!candidateLogin && !item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Contract"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_8"> 4. Probation <span
                                            *ngIf="candidateLogin">(Office Use Only)</span> </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryList("\"onboarding_probation\"")' data-backdrop="static"
                                            data-keyboard="false"><i class="fa fa-history" data-toggle="tooltip"
                                                title="History"></i></button> -->
                                        <div style="margin-right: 66px;" *ngIf="candidateLogin"></div>
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            *ngIf="!candidateLogin" href="#emp-probation"
                                            (click)="editempInfo('CandidateProbation',0)" style="float:right"
                                            data-backdrop="static" data-keyboard="false"><i class="fa fa-plus fa-1x"
                                                data-toggle="tooltip" title="Add Probation"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_8" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Probation Period</th>
                                                    <th style="width:15%;" class="text-center">Probation End Date</th>
                                                    <th class="text-center" style="width: 15%;">Next Review Date</th>
                                                    <th class="text-center" style="width: 15%;">Status</th>
                                                    <th class="text-center" style="width: 2%"> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of candidateProbation">
                                                <tr>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.probationPeriod}}">{{item.probationPeriod}}
                                                        </div>
                                                    </td>
                                                    <td>{{item.probationEndDate |date:'dd/MM/yyyy'}}</td>
                                                    <td>{{item.nextReviewDate | date :'dd/MM/yyyy'}}</td>
                                                    <td>{{item.status}}</td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-probation"
                                                            *ngIf="!candidateLogin"
                                                            (click)="editempInfo('CandidateProbation',item.id)"
                                                            data-backdrop="static" data-keyboard="false"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Probation"></i></a>&nbsp;
                                                        <a (click)="Delete(8,item.id)"
                                                            *ngIf="!candidateLogin  && !item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Probation"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_11"> 5. Salary <span
                                            *ngIf="candidateLogin">(Office Use Only)</span> </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryList("\"onboarding_salary\"")' data-backdrop="static"
                                            data-keyboard="false"><i class="fa fa-history" data-toggle="tooltip"
                                                title="History"></i></button> -->
                                        <div style="margin-right: 66px;" *ngIf="candidateLogin"></div>
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            *ngIf="!candidateLogin" href="#emp-salary"
                                            (click)="editempInfo('CandidateSalary',0)" style="float:right"
                                            data-backdrop="static" data-keyboard="false"><i class="fa fa-plus fa-1x"
                                                data-toggle="tooltip" title="Add Salary"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_11" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Salary</th>
                                                    <th class="text-center">Salary Type</th>
                                                    <!-- <th class="text-center" style="width: 10%;">Apprisal From</th>
                                                    <th class="text-center" style="width: 10%;">Apprisal To</th> -->
                                                    <th class="text-center" style="width: 2%"> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of candidatesalary">
                                                <tr>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.salary | currency:'GBP':'symbol':'1.0-0'}}">
                                                            {{item.salary | currency:'GBP':'symbol':'1.0-0'}}
                                                        </div>
                                                    </td>
                                                    <!-- <td>{{item.apprisalFrom | date :'dd/MM/yyyy'}}</td>
                                                    <td>{{item.apprisalTo | date :'dd/MM/yyyy'}}</td> -->
                                                    <td>{{item.salaryType}}</td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-salary"
                                                            *ngIf="!candidateLogin"
                                                            (click)="editempInfo('CandidateSalary',item.id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Salary"></i></a>&nbsp;
                                                        <!-- <a (click)="Delete(11,item.id)"
                                                            *ngIf="item.isActive && !candidateLogin  && !item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Salary"></i></a> -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_2"> 6. Address Details </a>
                                    <span style="float:right ;margin-top: -6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryList("\"person_address\"")' data-backdrop="static"
                                            data-keyboard="false"><i class="fa fa-history" data-toggle="tooltip"
                                                title="History"></i></button> -->
                                        <button type="button" data-toggle="modal" href="#address-info"
                                            class="btn btn-sm dark btn-outline"
                                            (click)="editempInfo('CandidateAddress',0)" style="float:right"
                                            data-backdrop="static" data-keyboard="false"><i class="fa fa-plus fa-1x"
                                                data-toggle="tooltip" title="Add Address Info"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_2" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Address</th>
                                                    <th style="width: 10%;" class="text-center">City</th>
                                                    <th style="width: 10%;" class="text-center">County</th>
                                                    <th style="width: 10%;" class="text-center">Country</th>
                                                    <th class="text-center" style="width: 10%;">Post Code </th>
                                                    <th class="text-center" style="width: 5%;">Default</th>
                                                    <th class="text-center" style="width: 5%;">Active</th>
                                                    <th class="text-center" style="width: 2%"> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of candidateAddress">
                                                <tr>
                                                    <td>{{item.address1}}, {{item.address2}}</td>
                                                    <td>{{item.city}}</td>
                                                    <td>{{item.state}}</td>
                                                    <td>{{item.country_Name}}</td>
                                                    <td>{{item.postCode}}</td>
                                                    <td>
                                                        <div class="text-center">
                                                            <div class="circle greenColor" *ngIf="item.isDefault"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Default">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isDefault"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Indefault">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="text-center">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#address-info"
                                                            (click)="editempInfo('CandidateAddress',item.id)"
                                                            data-backdrop="static" data-keyboard="false"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Address info"></i></a>&nbsp;
                                                        <a (click)="Delete(2,item.id)" *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Address info"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_4"> 7. Personal Contact Details</a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryList("\"person_contact\"")' data-backdrop="static"
                                            data-keyboard="false"><i class="fa fa-history" data-toggle="tooltip"
                                                title="History"></i></button> -->
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#emp-contact" (click)="editempInfo('CandidateContact',0)"
                                            style="float:right" data-backdrop="static" data-keyboard="false"><i
                                                class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Contact"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_4" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Contact Type</th>
                                                    <th class="text-center" style="width: 20%;">Contact Details</th>
                                                    <th class="text-center" style="width: 5%;">Default</th>
                                                    <th class="text-center" style="width: 5%;">Active</th>
                                                    <th class="text-center" style="width: 2%"> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of candidateConatct">
                                                <tr>
                                                    <td>{{item.contact_Type}}</td>
                                                    <td>{{item.contact_Value}}</td>
                                                    <td>
                                                        <div>
                                                            <div class="circle greenColor" *ngIf="item.isDefault"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Default">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isDefault"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Indefault">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="text-center">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-contact"
                                                            (click)="editempInfo('CandidateContact',item.id)"
                                                            data-backdrop="static" data-keyboard="false"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Contact"></i></a>&nbsp;
                                                        <a (click)="Delete(4,item.id)" *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Contact"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_5"> 8. Emergency Contact </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryList("\"person_emergency\"")' data-backdrop="static"
                                            data-keyboard="false"><i class="fa fa-history" data-toggle="tooltip"
                                                title="History"></i></button> -->
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#emp-eme-contact" (click)="editempInfo('CandidateEmeContact',0)"
                                            style="float:right" data-backdrop="static" data-keyboard="false"><i
                                                class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Emergency Contact"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_5" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Emergency Contact Name</th>
                                                    <th class="text-center" style="width: 15%;">Emergency Contact Number
                                                    </th>
                                                    <th class="text-center" style="width: 15%;">Relationship</th>
                                                    <th class="text-center" style="width: 4%;">Default</th>
                                                    <th class="text-center" style="width: 4%;">Active</th>
                                                    <th class="text-center" style="width: 2%"> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of candidateEmergency">
                                                <tr>
                                                    <td>{{item.contactName}}</td>
                                                    <td>{{item.contactNo}}</td>
                                                    <td>{{item.relationship_Name}}</td>
                                                    <td>
                                                        <div class="text-center">
                                                            <div class="circle greenColor" *ngIf="item.isDefault"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Default">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isDefault"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Indefault">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="text-center">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-eme-contact"
                                                            (click)="editempInfo('CandidateEmeContact',item.id)"
                                                            data-backdrop="static" data-keyboard="false"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Emergency Contact"></i></a>&nbsp;
                                                        <a (click)="Delete(5,item.id)" *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Emergency Contact"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_6"> 9. Work Reference </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryList("\"person_reference\"")' data-backdrop="static"
                                            data-keyboard="false"><i class="fa fa-history" data-toggle="tooltip"
                                                title="History"></i></button> -->
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#emp-ref" (click)="editempInfo('CandidateReference',0)"
                                            style="float:right" data-backdrop="static" data-keyboard="false"><i
                                                class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Work Reference"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_6" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Reference Name</th>
                                                    <th class="text-center">Reference Email Address</th>
                                                    <th class="text-center" style="width: 20%;">Reference Contact Number
                                                    </th>
                                                    <th class="text-center" style="width: 20%;">Reference Relationship
                                                    </th>
                                                    <th class="text-center" style="width: 2%"> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of candidateRef">
                                                <tr>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.ref_Name}}">{{item.ref_Name}}
                                                        </div>
                                                    </td>
                                                    <td>{{item.ref_Email}}</td>
                                                    <td>{{item.ref_ContactNo}}</td>
                                                    <td>{{item.relationship}}</td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-ref"
                                                            (click)="editempInfo('CandidateReference',item.id)"
                                                            data-backdrop="static" data-keyboard="false"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Reference"></i></a>&nbsp;
                                                        <a (click)="Delete(6,item.id)" *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Reference"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_7"> 10. Bank Details </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryList("\"onboarding_bank\"")' data-backdrop="static"
                                            data-keyboard="false"><i class="fa fa-history" data-toggle="tooltip"
                                                title="History"></i></button> -->
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#emp-bank" (click)="editempInfo('CandidateBank',0)"
                                            style="float:right"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Bank Info"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_7" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Bank Name</th>
                                                    <th class="text-center">Account Holder</th>
                                                    <!-- <th class="text-center" style="width: 15%;">Account Type</th> -->
                                                    <th class="text-center" style="width: 15%;">Account No.</th>
                                                    <th class="text-center" style="width: 15%;">Sort Code</th>
                                                    <th class="text-center" style="width: 2%"> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of candidateBank">
                                                <tr>
                                                    <td>{{item.bank_Name}}</td>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.account_Holder}}">{{item.account_Holder}}
                                                        </div>
                                                    </td>
                                                    <!-- <td>{{item.account_Type}}</td> -->
                                                    <td>{{item.account_No}}</td>
                                                    <td>{{item.bank_Code}}</td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-bank"
                                                            (click)="editempInfo('CandidateBank',item.id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Bank Info" data-backdrop="static"
                                                                data-keyboard="false"></i></a>&nbsp;
                                                        <a (click)="Delete(7,item.id)" *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Bank Info"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_10"> 11. Right To Work </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryList("\"onboarding_righttowork\"")'
                                            data-backdrop="static" data-keyboard="false"><i class="fa fa-history"
                                                data-toggle="tooltip" title="History"></i></button> -->
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#emp-right-to-work" (click)="editempInfo('candidateRightToWork',0)"
                                            style="float:right" data-backdrop="static" data-keyboard="false"><i
                                                class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Right To Work"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_10" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th style="width:10%;" class="text-center">Date of Issue</th>
                                                    <th class="text-center">Authority</th>
                                                    <th class="text-center" style="width: 10%;">Country of Issue</th>
                                                    <th class="text-center" style="width: 10%;">Date of Expiry</th>
                                                    <th class="text-center" style="width: 2%"> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of candidaterightToWork">
                                                <tr>
                                                    <td>{{item.issueDate | date :'dd/MM/yyyy'}}</td>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.issueAuthority}}">{{item.issueAuthority}}
                                                        </div>
                                                    </td>
                                                    <td>{{item.issueCountry}}</td>
                                                    <td>{{item.expiryDate | date :'dd/MM/yyyy'}}</td>
                                                    <td class="text-center">
                                                        <a (click)="downloadDocument(item.doc_Id,item.doc_Path)"><i
                                                                class="fa fa-download purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Download Document"></i></a>&nbsp;
                                                        <a data-toggle="modal" href="#emp-right-to-work"
                                                            (click)="editempInfo('candidateRightToWork',item.id)"
                                                            data-backdrop="static" data-keyboard="false"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Right to work"></i></a>&nbsp;
                                                        <a (click)="Delete(10,item.id)" *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Right to work"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_12"> 12. Onboarding Document <span
                                            *ngIf="candidateLogin"></span> </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryList("\"onboarding_document\"")' data-backdrop="static"
                                            data-keyboard="false"><i class="fa fa-history" data-toggle="tooltip"
                                                title="History"></i></button> -->
                                        <button *ngIf="candidateLogin" type="button" class="btn btn-sm dark btn-outline"
                                            data-toggle="modal" href="#emp-document"
                                            (click)="editempInfo('CandidateDocument',0,1)" style="float:right"
                                            data-backdrop="static" data-keyboard="false"><i class="fa fa-plus fa-1x"
                                                data-toggle="tooltip" title="Add Document"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_12" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Upload File Name</th>
                                                    <th class="text-center" style="width: 5%"> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of candidatedocument">
                                                <tr *ngIf="item.is_CompanyDoc == 1">
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.file_Name}}">{{item.file_Name}}
                                                        </div>
                                                    </td>
                                                    <td class="text-right">
                                                        <a (click)="downloadDocument(item.id,item.doc_Path)"><i
                                                                class="fa fa-download purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Download Document"></i></a>&nbsp;
                                                        <a data-toggle="modal" href="#emp-document"
                                                            *ngIf="candidateLogin"
                                                            (click)="editempInfo('CandidateDocument',item.id,1)"
                                                            data-backdrop="static" data-keyboard="false"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Document"></i></a>&nbsp;
                                                        <a (click)="Delete(12,item.id)"
                                                            *ngIf="candidateLogin && !item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Document"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_13"> 13. Additional Document </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryList("\"onboarding_document\"")' data-backdrop="static"
                                            data-keyboard="false"><i class="fa fa-history" data-toggle="tooltip"
                                                title="History"></i></button> -->
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#emp-document" (click)="editempInfo('CandidateDocument',0)"
                                            style="float:right" data-backdrop="static" data-keyboard="false"><i
                                                class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Document"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_13" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Document Name</th>
                                                    <th class="text-center" style="width: 5%"> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of candidatedocument">
                                                <tr *ngIf="item.is_CompanyDoc != 1">
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.doc_Name}}">{{item.doc_Name}}
                                                        </div>
                                                    </td>
                                                    <td class="text-right">
                                                        <a (click)="downloadDocument(item.id,item.doc_Path)"><i
                                                                class="fa fa-download purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Download Document"></i></a>&nbsp;
                                                        <a data-toggle="modal" href="#emp-document"
                                                            (click)="editempInfo('CandidateDocument',item.id)"
                                                            data-backdrop="static" data-keyboard="false"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Document"></i></a>&nbsp;
                                                        <a (click)="Delete(12,item.id)" *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Document"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--- the html code written by Brajeswari -->
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle popoverTitle accordion-toggle-styled"
                                        data-toggle="collapse" data-parent="#accordion1" href="#accordion1_14">
                                        14. Equality and Diversity Monitoring
                                    </a>
                                    <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-container="body"
                                        data-placement="top" data-html="true" id="equality">
                                        <span class="glyphicon glyphicon-info-sign"></span>
                                    </span>
                                    <span style="float:right ;margin-top: -6px" *ngIf="person_equalityInfo != null">
                                        <button class="btn btn-sm dark btn-outline pull-right" type="button"
                                            data-toggle="modal" href="#equality_info"
                                            *ngIf="person_equalityInfo != null"
                                            (click)="editempInfo('CandidatePersonEquality',person_equalityInfo.id)"
                                            data-backdrop="static" data-keyboard="false"><i class="fa fa-edit"
                                                data-toggle="tooltip" data-placement="bottom"
                                                title="Edit"></i></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <span style="float:right ;margin-top: -6px" *ngIf="person_equalityInfo == null">
                                        <button class="btn btn-sm dark btn-outline pull-right" type="button"
                                            data-toggle="modal" href="#equality_info"
                                            *ngIf="person_equalityInfo == null"
                                            (click)="editempInfo('CandidatePersonEquality',0)" data-backdrop="static"
                                            data-keyboard="false"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Add"></i></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <div id="popover-content-equality" class="hide">
                                        The information requested in this form is required for us to monitor
                                        our Equality and Diversity Policy and we encourage you complete
                                        this form in full to assist us with this. The information provided is to
                                        be used solely for monitoring and to comply with our legal
                                        obligations in relation to the Equality Act 2010. Any information
                                        provided in this form will be treated in the strictest confidence.
                                        The data provided will be processed and held for the specified
                                        purposes and in accordance with data protection legislation, as set
                                        out in our Data Protection Policy and the Privacy Notice issued to
                                        you.
                                    </div>
                                </h4>

                            </div>
                            <div id="accordion1_14" class="panel-collapse collapse">
                                <div class="panel-body">


                                    <div class="row mt-0 mb-0">

                                        <label class="control-label col-md-2">Gender</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.gender}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Marital Status</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.maritalStatus}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2"><span *ngIf="person_equalityInfo?.gender === 'Other'">If Other, please specify</span></label>                                            <div class="col-md-4">
                                                <input type="text" class="form-control form-group readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.gender === 'Other'"
                                                    value="{{person_equalityInfo?.gender_Other}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2"><span *ngIf="person_equalityInfo?.maritalStatus === 'Other'">If Other, please specify</span></label>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control form-group readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.maritalStatus === 'Other'"
                                                    value="{{person_equalityInfo?.marital_Other}}" readonly />
                                            </div>

                                    </div>


                                    <div class="row mt-0 mb-0">
                                        <label class="control-label col-md-2">Sexual Orientation</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.sexual_Orientation}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Age</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.age}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2"><span *ngIf="person_equalityInfo?.sexual_Orientation === 'Other'">If Other, please specify</span></label>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control form-group readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.sexual_Orientation === 'Other'"
                                                    value="{{person_equalityInfo?.sexual_Other}}" readonly />
                                            </div>
                                    </div>

                                    <div class="row mt-0 mb-0">
                                        <label class="control-label col-md-2">
                                            Disability
                                            <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-container="body"
                                                data-placement="left" type="button" data-html="true" id="disability">
                                                <span class="glyphicon glyphicon-info-sign"></span>
                                            </span>
                                        </label>
                                        <div id="popover-content-disability" class="hide">
                                            The Equality Act 2010 defines a disabled person
                                            as someone who has a mental or physical
                                            impairment that has a substantial and long-term
                                            adverse effect on the person’s ability to carry out
                                            normal day-to-day activities. If you have a
                                            condition which fits the Equality Act definition,
                                            please tick ‘yes’ even if you are not limited by
                                            your condition.
                                            Do you consider yourself to have a disability?
                                        </div>
                                        <div class="col-md-4 form-group pos-relative">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                value="{{person_equalityInfo?.disability}}" readonly />
                                        </div>
                
                                        <label class="control-label col-md-2">Religion or Belief</label>
                                        <div class="col-md-4 form-group">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                value="{{person_equalityInfo?.religionOrBelief}}" readonly />
                                        </div>
                                    </div>

                                    <div class="row mt-0 mb-0">
                                        <label class="control-label col-md-2">Is the gender you identify with the same as your gender
                                            registered at birth?</label>
                                        <div class="col-md-4 form-group">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                value="{{person_equalityInfo?.gender_Birth}}" readonly />
                                        </div>
                                        <label class="control-label col-md-2">
                                            Ethnicity
                                            <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-placement="left" type="button"
                            data-html="true" id="ethnicity">
                            <span class="glyphicon glyphicon-info-sign"></span>
                        </span>
                                        </label>
                                        <div id="popover-content-ethnicity" class="hide">
                                            Ethnic origin is not about nationality, place of
                                            birth or citizenship. It is about what best
                                            describes your ethnic group or background.
                                            Please choose one option and tick the
                                            appropriate box below.
                                            What is your ethnic group?
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                value="{{person_equalityInfo?.ethnicity}}" readonly />
                                        </div>
                                    </div>

                                    <!-- <div class="row">
                                        <div class="form-group">
                                            <label class="control-label col-md-2">Gender</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.gender}}" readonly />
                                            </div>

                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.gender === 'Other'"
                                                    value="{{person_equalityInfo?.gender_Other}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">Marital Status</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.maritalStatus}}" readonly />
                                            </div>

                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.maritalStatus === 'Other'"
                                                    value="{{person_equalityInfo?.marital_Other}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">Sexual Orientation</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.sexual_Orientation}}" readonly />
                                            </div>

                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.sexual_Orientation === 'Other'"
                                                    value="{{person_equalityInfo?.sexual_Other}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">Age</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.age}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">
                                                Disability
                                                <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-container="body"
                                                    data-placement="left" type="button" data-html="true" id="disability">
                                                    <span class="glyphicon glyphicon-info-sign"></span>
                                                </span>
                                            </label>
                                            <div id="popover-content-disability" class="hide">
                                                The Equality Act 2010 defines a disabled person
                                                as someone who has a mental or physical
                                                impairment that has a substantial and long-term
                                                adverse effect on the person’s ability to carry out
                                                normal day-to-day activities. If you have a
                                                condition which fits the Equality Act definition,
                                                please tick ‘yes’ even if you are not limited by
                                                your condition.
                                                Do you consider yourself to have a disability?
                                            </div>
                                            <div class="col-md-4 form-group pos-relative">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.disability}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">Religion or Belief</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.religionOrBelief}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">Is the gender you identify with the same as your gender
                                                registered at birth?</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.gender_Birth}}" readonly />
                                            </div>
                                            <label class="control-label col-md-2">
                                                Ethnicity
                                                <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-placement="left" type="button"
                                data-html="true" id="ethnicity">
                                <span class="glyphicon glyphicon-info-sign"></span>
                            </span>
                                            </label>
                                            <div id="popover-content-ethnicity" class="hide">
                                                Ethnic origin is not about nationality, place of
                                                birth or citizenship. It is about what best
                                                describes your ethnic group or background.
                                                Please choose one option and tick the
                                                appropriate box below.
                                                What is your ethnic group?
                                            </div>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.ethnicity}}" readonly />
                                            </div>
                                        </div>
                                    </div> -->
                                
                                </div>
                            </div>
                        </div>
                        <!--- / -->
                    </div>
                    <hr />
                    <div class="text-right" *ngIf="candidateLogin">
                        <button type="button" class="btn blue" (click)="reviewCandidate()" title="Submit To HR">Submit &
                            Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="personal-info" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Personal Details</h4>
            </div>
            <div class="modal-body">
                <app-candidatepersonalinfo></app-candidatepersonalinfo>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('person')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="update(1)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="equality_info" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Equality and Diversity Monitoring</h4>
            </div>
            <div class="modal-body">
                <app-candidatepersonequality></app-candidatepersonequality>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="update(12)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="address-info" role="dialog" aria-hidden="true" style="z-index: 0;">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Address Details</h4>
            </div>
            <div class="modal-body">
                <app-addressInfoCandidate></app-addressInfoCandidate>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('person_address')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="update(2)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="job-details" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Job Details</h4>
            </div>
            <div class="modal-body" *ngIf="!candidateLogin">
                <app-candidatejobdetail></app-candidatejobdetail>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('onboarding')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="update(3)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-contact" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Personal Contact Details</h4>
            </div>
            <div class="modal-body">
                <app-ContactCandidate></app-ContactCandidate>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('person_contact')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="update(4)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-eme-contact" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Emergency Contact</h4>
            </div>
            <div class="modal-body">
                <app-emergencycandidate></app-emergencycandidate>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('person_emergency')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="update(5)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-ref" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Work Reference</h4>
            </div>
            <div class="modal-body">
                <app-referenceCandidate></app-referenceCandidate>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('person_reference')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="update(6)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-bank" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Bank Details</h4>
            </div>
            <div class="modal-body">
                <app-bankCandidate></app-bankCandidate>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('onboarding_bank')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="update(7)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="emp-probation" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Probation</h4>
            </div>
            <div class="modal-body">
                <app-probationcandidate></app-probationcandidate>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('onboarding_probation')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="update(8)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-contract" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Contract</h4>
            </div>
            <div class="modal-body" *ngIf="!candidateLogin">
                <app-contractcandidate></app-contractcandidate>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('onboarding_contract')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="update(9)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-right-to-work" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Right To Work</h4>
            </div>
            <div class="modal-body">
                <app-rightworkCandidate></app-rightworkCandidate>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('onboarding_righttowork')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="addDocumentsonRTW()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-salary" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Salary</h4>
            </div>
            <div class="modal-body">
                <app-salarycandidate></app-salarycandidate>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('onboarding_salary')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="update(11)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-document" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title" *ngIf="iscompanyDoc == 1">Onboarding Document</h4>
                <h4 class="modal-title" *ngIf="iscompanyDoc == 0">Additional Document</h4>
            </div>
            <div class="modal-body">
                <app-documentcandidate></app-documentcandidate>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode && iscompanyDoc == 1" class="btn  dark btn-outline pull-left"
                    (click)="history('onboarding_document')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" *ngIf="editMode && iscompanyDoc == 0" class="btn  dark btn-outline pull-left"
                    (click)="history('additional_onboarding_document')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="addDocuments()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="historyModal" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">History</h4>
            </div>
            <div class="modal-body">
                <app-history></app-history>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="modalJobDescription1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Job Description</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <iframe [src]='fileUrlmodalJobDescription' width="100%" height="600px"></iframe>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="modalCandidateDocument" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Onboarding Document</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <iframe [src]='fileUrl' width="100%" height="600px"></iframe>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>