<form [formGroup]="candidateForm">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Bank Name<span class="required"> * </span></label>
                <input type="text" class="form-control" id="bank_Name" formControlName="bank_Name" autocomplete="off"
                    maxlength="100">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Account Holder<span class="required"> * </span></label>
                <input type="text" class="form-control" id="account_Holder" formControlName="account_Holder"
                    autocomplete="off" maxlength="50">
            </div>
        </div>
    </div>
    <div class="row">
        <!-- <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Account Type<span class="required"> * </span></label>
                <input type="text" class="form-control" id="account_Type" formControlName="account_Type"
                    autocomplete="off" maxlength="100">
            </div>
        </div> -->
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Account No.<span class="required"> * </span></label>
                <input type="text" class="form-control" id="account_No" formControlName="account_No"  onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Sort Code<span class="required"> * </span></label>
                <!-- <input type="text" class="form-control" id="bank_Code" formControlName="bank_Code"
                    autocomplete="off"> -->
                    <p-inputMask   formControlName="bank_Code" mask="99-99-99"></p-inputMask>
            </div>
        </div>
    </div>
    <div class="row">
        
        <div class="col-md-3">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="chk" class="md-check" formControlName="isActive">
                            <label for="chk">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>