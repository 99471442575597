import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ComingSoonComponent } from 'src/app/home/comingsoon/comingsoon.component';
import fieldVisibleInvisibleData from 'src/app/_files/field_visible_invisible.json';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, FullCalendarComponent } from '@fullcalendar/angular';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
declare var flatpickr: any;
declare var $: any;
declare const Bloodhound;

@Component({
  selector: 'app-leave-approval',
  templateUrl: './leave-approval.component.html'
})
export class LeaveApprovalComponent implements OnInit {

  //@ViewChild('calendarLeaveApproval', { static: false }) calendarComponent: FullCalendarComponent;

  addleaveform: FormGroup;
  leaveGridList: any;
  tabActive: string = "calendarView";
  leaveform: FormGroup;
  leaveList: any;
  leaveGridFilter: any;
  totalGridRecord: 0;
  p: number = 1;
  empLeaveList: any;
  title: string;
  calendarOptions: any;
  empleaverangid: number;
  leaveApplyComment: any;
  selectedItem: any;
  isApprovedLeaveCancelPresent: boolean = false;
  filterAdvance: boolean = false;
  pendingActions: number;
  empleaverangcompanyid: number;
  empleaverangreportingid: number;
  dayscount: any;
  currentEvents: EventApi[] = [];
  approvalCalMonth: boolean = false;
  sortDirection: { [key: string]: string } = {};
  currentSort: { column: string, direction: string } = { column: 'emp_Code', direction: 'asc' };
  isApprovedLeaveCancel: number;
  isLeave_CancelAll: number;
  empList: any;
  roleidCond: number;
  userroleCond: string;
  emp_idCond: number;
  rafList: any;
  JDList: any;
  companyDetail: any;
  siteList: any;
  departmentList: any;
  zoneList: any;
  approvalform: FormGroup;
  companyList: any;
  employeeList: Object;
  totalRecord: number;
  advancedFilterData: any;
  filterEmp: any[];
  advancedSearch: any;
     
  private requestCounter = 0;
   selectedItemData: any;
   
   itemsPerPageOptions: number[] = [10, 20, 50,100];
  itemsPerPage: number = 10;
  approveleaveform: FormGroup;
  isedit: boolean;
  leaveTypeDisabled: boolean;

  constructor(private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private router: Router,
    private commonService: CommonService,
    private fb: FormBuilder,private spinner: NgxSpinnerService, private ngZone: NgZone) { }
    

 

  ngOnInit() {
    this.commonService.flatpickr("startdateflatpickropen", 0);
    this.commonService.flatpickr("enddateflatpickropen", 0);
     
    this. initializeForm();
 
    
    this.getCompany();
    this.getRafList(0);
    this.getRafListAll();
    //this.getJobTitle() ;
    this.companyWiseGetData();
    this.commonService.flatpickr("listviewDate", 0);

    var self = this;
    var leaveform = this.leaveform;

    $("#checkbox11,#checkbox22,#checkbox33,#checkbox44,#checkbox55,#checkbox66").bootstrapSwitch();


    $('#checkbox11,#checkbox22,#checkbox33,#checkbox44,#checkbox55,#checkbox66').on({
      'switchChange.bootstrapSwitch': function (e, state) {
        //console.log(this); // DOM element  //console.log(e); // jQuery event     //console.log(state); // true | false        //console.log(this.value); // true | false
        var leaveTypeApprovalArr = [];
        $('.bSwitch_approval:checkbox:checked').each(function (i) {
          leaveTypeApprovalArr[i] = $(this).val();
        });

        self.onApprovalFlagChange(null, null, leaveTypeApprovalArr)


      },
      'click': function (e) {
        e.stopPropagation();
      }
    });


    $('body').on('click', '#approvalLeaveCalendar button.fc-prev-button,#approvalLeaveCalendar button.fc-next-button', function () {
      self.approvalCalMonth = true;

      var leaveTypeApprovalArr = [];
      $('.bSwitch_approval:checkbox:checked').each(function (i) {
        leaveTypeApprovalArr[i] = $(this).val();
      });

      self.getLeaveList(null, null, leaveTypeApprovalArr);
    });

    $(function () {

      $("#Start_date").datepicker({
        todayBtn: 1,
        autoclose: true,
        pickTime: false,
        daysOfWeekDisabled: [0, 6],
        weekStart: 1,
        todayHighlight: true,
        format: "d-m-yyyy"
      }).on('changeDate', function (selected) {
        var minDate = new Date(selected.date.valueOf());
        var Start_date = $('#Start_date').val();
        leaveform.controls.Start_date.setValue(Start_date);
        $('#End_date').datepicker('setStartDate', minDate, selected);
      });

      $("#End_date").datepicker({
        todayBtn: 1,
        autoclose: true,
        pickTime: false,
        daysOfWeekDisabled: [0, 6],
        weekStart: 1,
        todayHighlight: true,
        format: "d-m-yyyy",
      })
        .on('changeDate', function (selected) {
          var minDate = new Date(selected.date.valueOf());
          var End_date = $('#End_date').val();
          leaveform.controls.End_date.setValue(End_date);
          $('#Start_date').datepicker('setEndDate', minDate, selected);
        });
    });
    this.leaveform = this.fb.group({
      id: 0,
      employee_Leave_Id: 0,
      leave_Day_Type: ['']
    });


    var leaveTypeArrApprovalDefault = [];
    $('.bSwitch_approval:checkbox:checked').each(function (i) {
      leaveTypeArrApprovalDefault[i] = $(this).val();
    });

    this.getLeaveList(null, null, leaveTypeArrApprovalDefault);
    this.getLeaveGridList(1);
    this.getApprovedCancelRule(Commonvariable.Company_Id);

    this.getEmpList();


    if (Commonvariable.UserRole && Commonvariable.role_Id && localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id) {

      this.roleidCond = Commonvariable.role_Id
      this.userroleCond = Commonvariable.UserRole
      this.emp_idCond = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id
   }

  }

  

  getSelectedLeaveDate() {

    var addLeaveForm = this.addleaveform;

    var selectedStartDate = $("#Start_date").val();
    var selectedEndDate = $("#End_date").val();

    if (selectedStartDate == selectedEndDate) {
       addLeaveForm.controls.End_type.setValue('---');
       this.leaveTypeDisabled = true;
    } else {
       addLeaveForm.controls.End_type.setValue('Fullday');
       this.leaveTypeDisabled = false;
    }

    // console.log(event);
 }

  initializeForm(): void {
    this.approvalform = this.fb.group({
     
      fullName: [{value: '', disabled: true}, [Validators.required]],
      company_Id: ['', Validators.required],
      Site_Id: ['', Validators.required],
     
      Dept_Id: ['', Validators.required],
      Zone_Id: ["", Validators.required],
      Desig_Id: ['', Validators.required],
      JD_Id: [""],
      Emp_Code: ['', Validators.required],
      JoiningDate: ['', Validators.required],
      Shift_Id: ['', Validators.required],
      Reporting_Id: ['', Validators.required],
      Line_Manager_Id: ['0'],
      approvalDate:['',''],
      leavestatus:["",""]
      
     });
  
          
      this.approveleaveform = this.fb.group({
        Id: 0,
        // Emp_id: emp_ids,
        Approver_id: 1,
        Leave_type_id: ['', Validators.required],
        Start_date: ['', Validators.required],
        Start_type: ['',''],
        End_date: ['', Validators.required],
        End_type: ['',''],
        Comment: [''],
        isActive: ['1'],
       
        Company_id: Commonvariable.Company_Id,
        Applied_by: localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id,
        AddedBy: localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id,
        requestLeave: ['Self', Validators.required] 

        
     });

    }
    editLeave(){
      this. isedit=true;
       $('#draggable').modal('show')
      // Fetch data for the selected ID
      this.commonApiService.getById("","").subscribe(response => {
         this.selectedItemData  = response['data'] !== '' ? response['data'] : null;
       this.userprofileDetails(this.selectedItemData)
      this. isedit=true;
       
      });
    }

   
  userprofileDetails(selectedItemData){
   this.approveleaveform.patchValue({     
      requestLeave: this.selectedItem && this.selectedItem[0] && `${this.selectedItem[0]['requestLeave']} ${this.selectedItem[0]['requestLeave']}`,
      Leave_type_id:this.selectedItem && this.selectedItem[0] && `${this.selectedItem[0][' Leave_type_id']} ${this.selectedItem[0][' Leave_type_id']}`,
      Start_date :this.selectedItem && this.selectedItem[0] && this.selectedItem[0]['Start_date'],
      End_date :this.selectedItem && this.selectedItem[0] && this.selectedItem[0]['End_date'],
      Start_type: this.selectedItem && this.selectedItem[0] && this.selectedItem[0]['Start_type'],
      End_type: this.selectedItem && this.selectedItem[0] && this.selectedItem[0]['End_type'],
  })
console.log(this.addleaveform.value,"data")
} 

 
save() {
  const formData = this.approveleaveform.value;


const updateData = {

     emp_Id: formData.emp_id !== undefined ? (formData.emp_id.match(/\d+/) ? parseInt(formData.emp_id.match(/\d+/)[0], 10) : null) : null,
   
     Leave_type_id:formData.Leave_type_id?formData.Leave_type_id:'',
     Start_date:formData.Start_date?formData.Start_date:'',
     End_date: formData.End_date? formData.End_date : '',
     Start_type: formData.Start_type? formData.Start_type : '',
     End_type: formData.End_type? formData.End_type : '',
      
  };
console.log('Updated Data:', updateData);
 console.log('Updated Data:', updateData);
  
 
     this.commonApiService.SaveData(Commonvariable.Employee_Change_Requisition, 'Add', updateData).subscribe(
    (response) => {
     // const effectiveDateFlatpickr = flatpickr("#effectiveDate input");
     // const flatpickropenReq = flatpickr("#flatpickropenReq");
  
    
      if (response != null) {
        console.log('Data updated successfully:', response);
        this.toastr.success('Record saved sucessfully!');
        $('#draggable').modal('hide');
      this.addleaveform.reset();
      
      
     //  effectiveDateFlatpickr.clear();
     //  flatpickropenReq.clear();
         
      }
        else {
         
           this.toastr.warning('Record not saved !');
      
        
     }
   
     })
  }


  // approvedLeave(){
  //   return Swal.fire({
  //     title: 'You want to Approve!',
  //     html:"<input type='text'>",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes' ,
  //     cancelButtonText: 'No'
  //   })
  // }
  approvedLeave() {
    return Swal.fire({
      title: 'You want to Approve!',
      html: "<input type='text'>",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      
      if (result.isConfirmed) {
       
        $('#draggableForLeaveApproval').modal('show');
      }
    });
  }
  

  rejectLeave(){
    return Swal.fire({
      title: 'You want to Reject!',
      html:"<input type='text'>",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
  }
  // editLeave(){
  //   return Swal.fire({
  //     title: 'You want to Edit!',
  //     html:"<input type='text'>",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes',
  //     cancelButtonText: 'No'
  //   })
  // }

  setFormData(value) {

    var leaveTypeArrApprovalDefault = [];
    $('.bSwitch_approval:checkbox:checked').each(function (i) {
      leaveTypeArrApprovalDefault[i] = $(this).val();
    });
    this.getLeaveList(null, null, leaveTypeArrApprovalDefault);
    this.getLeaveGridList(1);
  }

  getEmpList() {
    this.empList = [];
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": 1,
          "Parameter": "=="
        }
      ]
    }
    this.commonApiService.empDetailList(Commonvariable.EmployeeDetail, requestData).subscribe((result) => {
      if (result != null && result["data"] != "") {
        //this.empList = result["data"]

        this.empList = result["data"].map(x => ({
          id: x.emp_Id,
          text: x.firstName + " " + x.lastName
        }));

        // if (this.empList.length > 0)
        //   this.empList = this.empList.sort((a, b) => a.firstName.localeCompare(b.firstName))
      }
    })
  }

  getApprovedCancelRule(Id) {
    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.Leave_Rule, Id, "GetIsApprovedCancelRule").subscribe((response) => {
        if (response["data"] != "") {
          this.isApprovedLeaveCancelPresent = response["data"]
        }
      })
    }
  }

  getLeaveGridList(PageIndex) {

    var emp_ids = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id;

    var multiEmpIdFilter
    if ($("#empidsfilter select").val() != "" && $("#empidsfilter select").val() != null) {
      multiEmpIdFilter = $("#empidsfilter select").val();
    } else {
      multiEmpIdFilter = [];
    }

    this.p = PageIndex;
    this.totalGridRecord = 0;
    this.leaveGridList = [];

      var requestData = {
        "searchBy": [
          {
            "FieldName": "Reporting_Id",
            "FieldValue": emp_ids,
            "Parameter": "=="
          }
        ],
        "Emp_Ids": multiEmpIdFilter,
        "PageIndex": PageIndex - 1,
        "CommonSearch": $("#filterList").val().trim(),
        "PageSize": 10,
      }

    if (emp_ids != "" && emp_ids != null) {

      this.commonApiService.getPaginated(Commonvariable.Employee_Leave, null, null, null, null, null, requestData).subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.totalGridRecord = result["data"]["recordCount"]
          this.leaveGridList = result["data"]['list'];
          this.leaveGridFilter = result["data"]['list'];
        }
      })

    }
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    $('#draggable').modal({
      backdrop: 'static'
    });
    var addLeaveForm = this.addleaveform;
    var datePartStartStr = selectInfo.startStr.match(/\d+/g),
      yearStartStr = datePartStartStr[0],
      monthStartStr = datePartStartStr[1], dayStartStr = datePartStartStr[2];
    if (selectInfo.startStr) {
      // $("#Start_date").datepicker("setDate" , dayStartStr+'-'+monthStartStr+'-'+yearStartStr);
    }
    addLeaveForm.controls.Start_date.setValue(dayStartStr + '-' + monthStartStr + '-' + yearStartStr);
    var datePartEndStr = selectInfo.endStr.match(/\d+/g),
      yearEndStr = datePartEndStr[0],
      monthEndStr = datePartEndStr[1], dayEndStr = parseInt(datePartEndStr[2]) - 1;
    if (selectInfo.endStr) {
      //$("#End_date").datepicker("setDate" , dayEndStr+'-'+monthEndStr+'-'+yearEndStr);
    }
    addLeaveForm.controls.End_date.setValue(dayEndStr + '-' + monthEndStr + '-' + yearEndStr);
    var days = this.daysdifference(monthStartStr + '/' + dayStartStr + '/' + yearStartStr, monthEndStr + '/' + parseInt(datePartEndStr[2]) + '/' + yearEndStr);
    this.dayscount = days;
  }

  handleEventClick(clickInfo: EventClickArg) {
    var Id = clickInfo.event._def.publicId;
    var compony_id = clickInfo.event._def.extendedProps.company_id;
    var reporter_id = clickInfo.event._def.extendedProps.reporting_Id;
    if (clickInfo.event._def.extendedProps.status == 'Pending' || clickInfo.event._def.extendedProps.status == 'Approved' || clickInfo.event._def.extendedProps.status == 'Rejected' || clickInfo.event._def.extendedProps.status == 'Cancelled' || clickInfo.event._def.extendedProps.status == 'CancellationRequest') {
      this.openModelForLeaves(Id, true);
    } else {
      return false
    }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

  daysdifference(firstDate, secondDate) {
    var startDay = new Date(firstDate);
    var endDay = new Date(secondDate);

    var millisBetween = startDay.getTime() - endDay.getTime();
    var days = millisBetween / (1000 * 3600 * 24);

    return Math.round(Math.abs(days));
  }

  leadingZero(value) {
    if (value < 10) {
      return "0" + value.toString();
    }
    return value.toString();
  }

  getLeaveList(mm = null, yyyy = null, leaveTypeApprovalArr = null) {

    var emp_ids = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id;

    var multiEmpIdFilter = null;
    this.leaveList = [];
    if (this.approvalCalMonth) {

      let calendarApi = String($("#approvalLeaveCalendar .fc-toolbar-title").text()).split(' ');

      let currentDate = new Date();
      let currentMonth = currentDate.getMonth() + 1;
      let currentYear = currentDate.getFullYear();

      if ($("#empidsfilter select").val() != "" && $("#empidsfilter select").val() != null) {
        multiEmpIdFilter = $("#empidsfilter select").val();
      } else {
        multiEmpIdFilter = [];
      }

      var requestData = {
        "Company_Id": Commonvariable.Company_Id,
        "Reporting_id": emp_ids !=null ? emp_ids : 0,
        "Emp_ids": multiEmpIdFilter,
        "Start":`${currentYear}-${('0' + currentMonth).slice(-2)}-01`,
        "End":  `${currentYear}-${('0' + currentMonth).slice(-2)}-${new Date(currentYear, currentMonth, 0).getDate()}`
      }

    } else {

      var m, y;
      if (mm) {
        var m = mm;
      } else {
        var check = moment(moment().toDate(), 'YYYY/MM/DD');
        m = check.format('M');
      }

      if (yyyy) {
        var y = yyyy;
      } else {
        var check = moment(moment().toDate(), 'YYYY/MM/DD');
        y = check.format('Y');
      }

      let nextMontYear = y + '-' + m;



      if ($("#empidsfilter select").val() != "" && $("#empidsfilter select").val() != null) {
        multiEmpIdFilter = $("#empidsfilter select").val();
      } else {
        multiEmpIdFilter = [];
      }
      let currentDate = new Date();
      let currentMonth = currentDate.getMonth() + 1;
      let currentYear = currentDate.getFullYear();
      var requestData = {
        
        "Company_Id": Commonvariable.Company_Id,
        "Reporting_id": emp_ids !=null ? emp_ids : 0,
        "Emp_ids": multiEmpIdFilter,
        "Start": `${currentYear}-${('0' + currentMonth).slice(-2)}-01`,
        "End":`${currentYear}-${('0' + currentMonth).slice(-2)}-${new Date(currentYear, currentMonth, 0).getDate()}`
      }

    }


    this.leaveList = [];


    if (emp_ids != "" && emp_ids != null) {
      this.commonApiService.GetEmpListCalendar(Commonvariable.Employee_Leave, requestData).subscribe((result) => {
        if (result != null && result["data"] != "") {
          //this.leaveList = result["data"]['leaveList'];
          let leave_List = [];
          leaveTypeApprovalArr.forEach(item => {

            leave_List = result["data"]['leaveList'].filter(x => x.status == item);

            leave_List.forEach(x => {
              this.leaveList.push(x)
            });
          });
          result["data"]['holidayList'].forEach(element => {
            this.leaveList.push(element);
          });
          this.calendarOptions = {
            headerToolbar: {
              left: 'prev,next',
              center: 'title',
              right: ''
            },
            firstDay: 1,
            //events: this.json3,
            initialView: 'dayGridMonth',
            fixedWeekCount: false,
            //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
            weekends: true,
            editable: false,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: false,
            select: this.handleDateSelect.bind(this),
            eventClick: this.handleEventClick.bind(this),
            eventsSet: this.handleEvents.bind(this),
            events: this.leaveList,
            buttonText: {
              month: 'Month',
              today: 'Today'
            }
          };
          setTimeout(() => {
            $("#approvalLeaveCalendar .fc-view-harness").trigger('click');
          }, 100);
        } else {
          this.calendarOptions = {
            headerToolbar: {
              left: 'prev,next',
              center: 'title',
              right: ''
            },
            firstDay: 1,
            //events: this.json3,
            initialView: 'dayGridMonth',
            fixedWeekCount: false,
            //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
            weekends: true,
            editable: false,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: false,
            select: this.handleDateSelect.bind(this),
            eventClick: this.handleEventClick.bind(this),
            eventsSet: this.handleEvents.bind(this),
            events: this.leaveList,
            buttonText: {
              month: 'Month',
              today: 'Today'
            }
          };
          setTimeout(() => {
            $("#approvalLeaveCalendar .fc-view-harness").trigger('click');
          }, 100);
        }
      });
    }

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);
   }


   ngAfterViewInit(){
    $('.btn-toggle').click(function() {
       $(this).find('.btn').toggleClass('active');           
       if ($(this).find('.blue').size()>0) {
          $(this).find('.btn').toggleClass('blue');
       }   
   });
      flatpickr('#leaveHistoryDate', {
      // Flatpickr options
      onClose: (selectedDates: Date[]) => {
        console.log('Flatpickr onClose Triggered');
        console.log('Selected Dates:', selectedDates);
  
        const selectedDate = selectedDates.length > 0 ? selectedDates[0] : null;
        console.log('Selected Date:', selectedDate);

  const formattedDate = selectedDate ? selectedDate.toISOString().split('T')[0] : null; // Extract date portion
      console.log('Formatted Date:', formattedDate);
        this.approveleaveform.get('effectiveDatee').setValue(formattedDate);
      },
    });

 }

 isSelf = true;

 onSwitchChange(state: boolean) {
   // Handle switch state change
 }

 isForOthersSelected() {
    return this.approveleaveform.get('requestLeave').value === 'Others';
  }

  isSickLeaveSelected() {
    return this.approveleaveform.get('Leave_type_id').value === 'Sick Leave';
  }


  // onApprovalFlagChange(mm = null, yyyy = null, leaveTypeApprovalArr = null) {

  //   var emp_ids = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id;

  //   var multiEmpIdFilter
  //   let calendarApi = String($("#approvalLeaveCalendar .fc-toolbar-title").text()).split(' ');


  //   let currentMonth = moment().month(calendarApi[0]).format("M");
  //   let currentYear = calendarApi[1];
  //   let nextMontYear = currentYear + '-' + this.leadingZero(currentMonth);

  //   if ($("#empidsfilter select").val() != "" && $("#empidsfilter select").val() != null) {
  //     multiEmpIdFilter = $("#empidsfilter select").val();
  //   } else {
  //     multiEmpIdFilter = [];
  //   }

  //   var requestData = {
  //     "Company_Id": Commonvariable.Company_Id,
  //     "Reporting_id": emp_ids !=null ? emp_ids : 0,
  //     "Emp_ids": multiEmpIdFilter,
  //     "Start": nextMontYear + "-" + moment(this.leadingZero(currentMonth)).clone().startOf("month").format("DD"),
  //     "End": nextMontYear + "-" + moment(this.leadingZero(currentMonth)).clone().endOf("month").format("DD")
  onApprovalFlagChange(mm = null, yyyy = null, leaveTypeApprovalArr = null) {
    var emp_ids = localStorage.getItem('HR_Emp_Id') || Commonvariable.emp_Id;

    let calendarApi = $("#approvalLeaveCalendar .fc-toolbar-title").text().split(' ');
    let currentMonthName = calendarApi[0];
    let currentYear = parseInt(calendarApi[1]); // Parse as integer

    // Convert month name to month number
    let currentMonth = moment(currentMonthName, 'MMMM').month() + 1; // Adjust month index to 1-based

    let nextMonthYear = currentYear + '-' + this.leadingZero(currentMonth);

    // Check the value of nextMonthYear
    console.log("Next Month Year: ", nextMonthYear);

    let multiEmpIdFilter = $("#empidsfilter select").val() || [];

    var requestData = {
        "Company_Id": Commonvariable.Company_Id,
        "Reporting_id": emp_ids != null ? emp_ids : 0,
        "Emp_ids": multiEmpIdFilter,
        "Start": moment(nextMonthYear, "YYYY-MM").startOf("month").format("YYYY-MM-DD"),
        "End": moment(nextMonthYear, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    }

    // Use console.log() to debug and check the values
    console.log(requestData);

    // Continue with the rest of your code...





      this.commonApiService.GetEmpListCalendar(Commonvariable.Employee_Leave, requestData).subscribe((result) => {
        if (result != null && result["data"] != "") {
          //this.leaveList = result["data"]['leaveList'];
          this.leaveList = [];
          leaveTypeApprovalArr.forEach(item => {

            let leave_List = result["data"]['leaveList'].filter(x => x.status == item);

            leave_List.forEach(x => {
              this.leaveList.push(x)
            });
          });

          result["data"]['holidayList'].forEach(element => {
            this.leaveList.push(element);
          });
          this.calendarOptions = {
            headerToolbar: {
              left: 'prev,next',
              center: 'title',
              right: ''
            },
            firstDay: 1,
            //events: this.json3,
            initialView: 'dayGridMonth',
            fixedWeekCount: false,
            //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
            weekends: true,
            editable: false,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: false,
            select: this.handleDateSelect.bind(this),
            eventClick: this.handleEventClick.bind(this),
            eventsSet: this.handleEvents.bind(this),
            events: this.leaveList,
            buttonText: {
              month: 'Month',
              today: 'Today'
            }
          };

        }
      })
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);


  }


  ApproveEmpSingleLeave(emp_leaves_days_id = null, company_id = null, status = null) {
    this.commonService.takeReasonConfirm("You want to Approve !", "Yes !").then((result) => {
      if (result.value) {
        var requestData = {
          "employee_leave_days_id": emp_leaves_days_id,
          "Company_id": company_id,
          "status": status,
          "Action_Notes": result.value
        }
        this.commonApiService.putWithParameter(Commonvariable.Employee_Leave, requestData, 'UpdateEmployeeLeaveDayStatus').subscribe((response) => {
          if (response["status"] == 200) {
            this.getLeaveGridList(1);

            var leaveTypeArrApprovalDefault = [];
            $('.bSwitch_approval:checkbox:checked').each(function (i) {
              leaveTypeArrApprovalDefault[i] = $(this).val();
            });

            this.getLeaveList(null, null, leaveTypeArrApprovalDefault);
            this.toastr.success(response["message"], 'Leave');
            this.openModelForLeaves(response['data']['employee_Leave_Id'], false);
          }
        })
      }
    });
  }

  ApproveLeaves(Id, status = null, compony_id = null, reporter_id = null) {
    this.commonService.takeReasonConfirm("You want to approve !", "Yes !").then((result) => {
      if (result.value) {
        var requestData = {
          "employee_leave_id": Id,
          "status": status,
          "Company_id": compony_id,
          "reporter_id": parseInt(localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id),
          "Action_Notes": result.value
        }
        this.commonApiService.putWithParameter(Commonvariable.Employee_Leave, requestData, 'UpdateLeaveStatus').subscribe((response) => {
          if (response["status"] == 200) {
            this.toastr.success(response["message"], 'Leave');
            this.getLeaveGridList(1);

            var leaveTypeArrApprovalDefault = [];
            $('.bSwitch_approval:checkbox:checked').each(function (i) {
              leaveTypeArrApprovalDefault[i] = $(this).val();
            });

            this.getLeaveList(null, null, leaveTypeArrApprovalDefault);
            $('#draggableForLeaveApproval').modal("hide")
          }
        })
      }
    });
  }


  

  CancelLeaves(Id, status = null, compony_id = null, reporter_id = null) {
    this.commonService.takeReasonConfirm("You want to reject !", "Yes !").then((result) => {
      if (result.value) {
        var requestData = {
          "employee_leave_id": Id,
          "status": status,
          "Company_id": compony_id,
          "reporter_id": parseInt(localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id),
          "Action_Notes": result.value
        }
        this.commonApiService.putWithParameter(Commonvariable.Employee_Leave, requestData, 'UpdateLeaveStatus').subscribe((response) => {
          if (response["status"] == 200) {
            this.toastr.success(response["message"], 'Leave');
            this.getLeaveGridList(1);

            var leaveTypeArrApprovalDefault = [];
            $('.bSwitch_approval:checkbox:checked').each(function (i) {
              leaveTypeArrApprovalDefault[i] = $(this).val();
            });

            this.getLeaveList(null, null, leaveTypeArrApprovalDefault);
            $('#draggableForLeaveApproval').modal("hide")
          }
        })
      }

    });
  }

  CancelSingleLeaves(Id = null, compony_id = null, status = null) {
    this.commonService.takeReasonConfirm("You want to reject !", "Yes !").then((result) => {
      if (result.value) {
        var requestData = {
          "employee_leave_days_id": Id,
          "Company_id": compony_id,
          "status": status,
          "Action_Notes": result.value
        }
        this.commonApiService.putWithParameter(Commonvariable.Employee_Leave, requestData, 'UpdateEmployeeLeaveDayStatus').subscribe((response) => {
          if (response["status"] == 200) {

            var leaveTypeArrApprovalDefault = [];
            $('.bSwitch_approval:checkbox:checked').each(function (i) {
              leaveTypeArrApprovalDefault[i] = $(this).val();
            });
            this.toastr.success(response["message"], 'Leave');

            if (response['data']['employee_Leave_Id']) {
              this.openModelForLeaves(response['data']['employee_Leave_Id'], false);
            }

            let calendarApi = String($("#approvalLeaveCalendar .fc-toolbar-title").text()).split(' ');


            let mm = moment().month(calendarApi[0]).format("M");
            let yy = calendarApi[1];

            this.getLeaveList(mm, yy, leaveTypeArrApprovalDefault);

            this.getLeaveGridList(1);

          }
        })
      }

    });
  }
  getEmpLeaveDays(id) {
    this.empLeaveList = [];
    this.commonApiService.getById(Commonvariable.Employee_Leave, id, "GetEmployeeLeaveDayList").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.empLeaveList = result["data"];
      }
    })
  }

  expandTable(id) {
    this.empLeaveList = [];
    var visibility = $(".sub_tbl_" + id).toggle().is(":visible");
    $(".sub_tbl").hide();
    $(".details-control").css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    if (visibility === true) {
      $(".sub_tbl_" + id).show();
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/d4ICC.png) no-repeat center center");
      this.getEmpLeaveDays(id);
    } else {
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    }
  }

  openModelForLeaves(id, reload = true) {
    this.empLeaveList = [];
    this.commonApiService.getById(Commonvariable.Employee_Leave, id, "GetEmployeeLeaveDayList").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.empLeaveList = result["data"]['employee_Leave_Days'];


        this.leaveApplyComment = result["data"]['leaveApplyComment'];
        this.pendingActions = result["data"]['pendingActions'];

        this.isApprovedLeaveCancel = result["data"]['is_Approved_Leave_Cancel'];
        this.isLeave_CancelAll = result["data"]['is_Leave_CancelAll'];

        this.empleaverangid = id;
        this.empleaverangcompanyid = result["data"]['employee_Leave_Days'][0]['company_id'];
        //console.log(result["data"]['employee_Leave_Days'][0]);
        this.empleaverangreportingid = result["data"]['employee_Leave_Days'][0]['reporting_Id'];
      }
    })
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);
    if (reload) {
      $('#draggableForLeaveApproval').modal({
        backdrop: 'static'
      });
    }
  }

  openModel(id) {
    if (id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Employee_Leave, id, 'GetEmployeeLeaveDay').subscribe((response) => {
        if (response != null) {
          var editData = response["data"];
          var formData = this.leaveform;
          //this.commonService.setEditData(formData, editData);
          //console.log(editData.leave_Day_Type);
          formData.controls.id.setValue(editData.id)
          formData.controls.leave_Day_Type.setValue(editData.leave_Day_Type)
          formData.controls.employee_Leave_Id.setValue(editData.employee_Leave_Id)
        }
      })
    }
    else {
      this.ngOnInit();
    }
    $('#draggableEditLeave').modal({
      backdrop: 'static'
    });
  }

  loadApprovalCalendarView() {

    var leaveTypeArrApprovalDefault = [];
    $('.bSwitch_approval:checkbox:checked').each(function (i) {
      leaveTypeArrApprovalDefault[i] = $(this).val();
    });

    this.getLeaveList(null, null, leaveTypeArrApprovalDefault);
  }
  Upsert() {
    var leaveForm = this.leaveform;
    this.commonApiService.Upsert(Commonvariable.Employee_Leave, leaveForm.value, leaveForm.controls.id.value).subscribe((response) => {
      if (response != null) {

        $("#draggableEditLeave").modal('hide');
        this.expandTable(leaveForm.controls.employee_Leave_Id.value);
        this.toastr.success(leaveForm.controls.zone_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Leave');
      }
    })
  }



  getCompany() {
     
    if (this.companyList == null) {
       this.companyList = [];
    }

    var requestData = {
       "searchBy": [
          {
             "FieldName": "isActive",
             "FieldValue": "1",
             "Parameter": "=="
          }
       ],
       "OrderBy": "company_Name"
    }
    if (this.companyList.length == 0) {
       this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((result) => {
          if (result != null && result["data"] != "") {
          
             this.companyList = result["data"].filter(x => x.isActive != 0);
          }
  
       })
    }
 }
  

companyWiseGetData(editmode = false) {
  this.siteList = [];
  this.departmentList = [];
  this.zoneList = [];
 
  if (!editmode) {
    this.approvalform.controls.Site_Id.setValue('');
    this.approvalform.controls.Dept_Id.setValue('');
    this.approvalform.controls.JD_Id.setValue('');
  
    
  }


  const selectedCompanyId = this.approvalform.controls.company_Id.value;
console.log(selectedCompanyId,"id")

  if (selectedCompanyId != "") {
    localStorage.removeItem('employeeHRCompanyId');
    localStorage.setItem('employeeHRCompanyId', selectedCompanyId);

    var requestData = {
      "searchBy": [
        {
          "FieldName": "Company_Id",
          "FieldValue": selectedCompanyId,
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ]
    };

    this.commonApiService.getPaginated(Commonvariable.CompanyDetail, null, null, null, null, null, requestData, "GetByCompany_IdPaginated").subscribe((result) => {
      this.companyDetail = result["data"][0];
      this.siteList = this.companyDetail["site"];
      this.departmentList = this.companyDetail["department"];
      this.zoneList = this.companyDetail["zone"];
      this.getname();

    });
    
  }
}



getRafListAll() {
    
 this.commonApiService.List(Commonvariable.VacancyService, "GetDropDownListAllRef").subscribe((result) => {
    if (result["data"] != "") {
      
       this.AllrafList = result["data"];
       console.log(this.AllrafList);
    }
 })

}



 AllrafList(AllrafList: any) {
   throw new Error('Method not implemented.');
 }
getJobDescriptionList(id) {
  this.JDList = [];
  let postRequest = {
    "searchBy": [
       {
          "FieldName": "desig_Id",
          "FieldValue": id,
          "Parameter": "==",
          "ConditionWith": "&&",
       },
       {
          "FieldName": "isActive",
          "FieldValue": 1,
          "Parameter": "=="
       }
    ]
  }
  
  if (id > 0) {
    this.commonApiService.getPaginated(Commonvariable.JobDescription, null, null, null, null, 0, postRequest, 'GetByPaginated').subscribe((result) => {
       if (result != null && result["data"] != "") {
          this.JDList = result["data"];
       }
    })
  }
  }


async getRafList(id) {

this.rafList = [];
let postRequest = {
 "searchBy": [
    {
       "FieldName": "Open",
       "FieldValue": "0",
       "Parameter": ">",
       "ConditionWith": "&&"
    },
    {
       "FieldName": "site_Id",
       "FieldValue": id,
       "Parameter": "=="
    }
 ],
 "PageSize": 1000
}

if (id > 0) {
 await this.commonApiService.getPaginated(Commonvariable.VacancyService, null, null, null, null, 0, postRequest, 'RAFWiseGetPaginated').subscribe((result) => {
    if (result != null && result["data"] != "") {
       this.rafList = result["data"]["list"];
   
    }
 })
}


}

getname(){

  const company_Id = this.approvalform.controls.company_Id.value;
  const Dept_Id = this.approvalform.controls.Dept_Id.value;
  const Zone_Id= this.approvalform.controls.Zone_Id.value;
  
  var requestData = {
    "searchBy": [
       {
          "FieldName": "company_Id",
          "FieldValue": company_Id != 0 ?company_Id : this.approvalform.controls.company_Id.value,
          "Parameter": "==",
          "ConditionWith": "&&"
       },
       {
          "FieldName": "Dept_Id",
          "FieldValue": Dept_Id != 0 ? Dept_Id : this.approvalform.controls.Dept_Id.value,
          "Parameter": "==",
          "ConditionWith": "&&"
       },
      
       {
          "FieldName": "Zone_Id",
          "FieldValue":Zone_Id != 0 ?Zone_Id : this.approvalform.controls.Emp_Id.value,
          "Parameter": "!=",
          "ConditionWith": "&&"
       },
       
       {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
       }

    ],
    "OrderBy": "FirstName"
 }
 this.commonApiService.getPaginated(Commonvariable.CompanyDetail, null, null, null, null, null, requestData, "GetByCompany_IdPaginated").subscribe((result) => {
  this.employeeList=result;

});
}
async getEmployeeList(PageIndex = 1, sortColumn: string = 'emp_Code', sortDirection: string = 'asc'): Promise<void> {
  const currentCounter = ++this.requestCounter;
console.log(this.itemsPerPage)
  try {
    if (this.filterAdvance) {
      this.advanceFilter(null, PageIndex);
    } else {
      this.totalRecord = 0;
      this.empList = [];
      this.filterEmp = [];
      this.p = PageIndex;

      let requestData;

      if (this.advancedFilterData && this.advancedFilterData.length > 0) {
        requestData = {
          searchBy: this.advancedFilterData,
          PageIndex: PageIndex - 1,
        //  CommonSearch: $("#filterList").val().trim(),
          PageSize: this.itemsPerPage,
          OrderBy: sortColumn + " "+ sortDirection ,
          //SortDirection: sortDirection
        };
      } else {
        requestData = {
          searchBy: [],
          PageIndex: PageIndex - 1,
        //  CommonSearch: $("#filterList").val().trim(),
          PageSize: this.itemsPerPage,
          OrderBy: sortColumn +" "+ sortDirection,
          //SortDirection: sortDirection
        };
      }

      this.empList = [];
      this.totalRecord = 0;

      const result = await this.commonApiService.GetByPaginated(Commonvariable.Dashboard, requestData, "GetPaginated").toPromise();

      //if (currentCounter === this.requestCounter) {
      if (requestData.CommonSearch == $("#filterList").val().trim()) {
        // Check if this is the last request
        if (result != null && result["status"] === 200) {
          this.empList = [];
          if (result["data"] !== "" && result["data"]["list"] !== undefined) {
            this.totalRecord = result["data"]["recordCount"];
            this.empList = result["data"]["list"];
          }
        }

        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);
      }
      else {
        this.spinner.hide();
      }
    }
  } catch (error) {

  }
}

  
sortColumn(column: string): void {
  if (this.currentSort.column === column) {
    // toggle direction if it's the same column
    this.currentSort.direction = this.currentSort.direction === 'asc' ? 'desc' : 'asc';
  } else {
    // set the new column and reset direction
    this.currentSort = { column, direction: 'asc' };
  }

  // call your data retrieval method with the new sort parameters
  this.getEmployeeList(this.p, this.currentSort.column, this.currentSort.direction);
}

// method to get the appropriate icon class based on the current sort
getSortIcon(column: string): string {
  if (this.currentSort.column === column) {
    return this.currentSort.direction === 'asc' ? 'fa-sort-up' : 'fa-sort-down';
  } else {
    return 'fa-sort';
  }
}

onItemsPerPageChange() {
  this.p = 1;
  this.getEmployeeList();
}

isColumnSorted(column: string, direction: string): boolean {
  const table = $('#tblemp').DataTable();
  const order = table.order()[0];
  return order && order[0] === table.column(`:${column}`).index() && order[1] === direction;
}

advanceFilterEmp() {

  var data = {
    "SearchBy": this.advancedSearch.search()
  }

  if (data.SearchBy) {

    this.advancedFilterData = data.SearchBy;
    if (data.SearchBy[0]["FieldName"] != "" && data.SearchBy[0]["FieldValue"] != "") {
      this.empList = []
      this.totalRecord = 0

      this.ngZone.run(() => {
        this.commonApiService.getPaginated(Commonvariable.EmployeeDetail, null, null, null, null, 10, data).subscribe((result) => {
          if (result != null && result["data"] != "") {
            this.empList = result["data"]["list"];
            this.totalRecord = result["data"]["recordCount"];
            this.p = 1
          }
          $("#filterModal").modal("hide");
        })

      })
    }
  }

}


advanceFilter(sData = null, pageindex = 1) {
 

  const company_Id = this.approvalform.controls.company_Id.value;
  const Dept_Id = this.approvalform.controls.Dept_Id.value;
  const Zone_Id= this.approvalform.controls.Dept_Id.value;
  const fullName= this.approvalform.controls.fullName.value;
  const leavestatus= this.approvalform.controls.leavestatus.value;
  const approvalDate= this.approvalform.controls.approvalDate.value;
  
  var requestData = {
    "searchBy": [
       {
          "FieldName": "company_Id",
          "FieldValue": company_Id != 0 ?company_Id : this.approvalform.controls.company_Id.value,
          "Parameter": "==",
          "ConditionWith": "&&"
       },
       {
          "FieldName": "Dept_Id",
          "FieldValue": Dept_Id != 0 ? Dept_Id : this.approvalform.controls.Dept_Id.value,
          "Parameter": "==",
          "ConditionWith": "&&"
       },
      
       {
          "FieldName": "Zone_Id",
          "FieldValue":Zone_Id != 0 ?Zone_Id : this.approvalform.controls.Zone_Id.value,
          "Parameter": "!=",
          "ConditionWith": "&&"
       },
       {
        "FieldName": "fullName",
        "FieldValue": fullName!= 0 ?fullName: this.approvalform.controls.fullName.value,
        "Parameter": "!=",
        "ConditionWith": "&&"
     },
     {
      "FieldName": "leavestatus",
      "FieldValue": leavestatus!= 0 ?leavestatus: this.approvalform.controls.leavestatus.value,
      "Parameter": "!=",
      "ConditionWith": "&&"
   },
   {
    "FieldName": "approvalDate",
    "FieldValue": approvalDate!= 0 ?approvalDate: this.approvalform.controls.approvalDate.value,
    "Parameter": "!=",
    "ConditionWith": "&&"
 },
  
       {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
       }
       

    ],
    "OrderBy": "FirstName"
 }
 this.commonApiService.getPaginated(Commonvariable.CompanyDetail, null, null, null, null, null, requestData, "GetByCompany_IdPaginated").subscribe((result) => {
  this.employeeList=result;

});


}

}
