import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
import { groupBy, mergeMap } from 'rxjs/operators';
import { DebugHelper } from 'protractor/built/debugger';
declare var $: any;

@Component({
   selector: 'app-interviewFeedback',
   templateUrl: './interviewFeedback.component.html',
})
export class InterviewFeedbackComponent implements OnInit {
   title = "Interview Feedback";
   interviewFeedBackform: FormGroup;
   items: FormArray;
   empList: any;

   constructor(
      private commonApiService: CommonApiService,
      private commonService: CommonService,
      private router: Router,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {

      this.interviewFeedBackform = this.fb.group({
         interviewFeedback: this.fb.array([this.createItem()]),
      });

      //      this.createItem()

   }

   getInterviewStatus(event){
      if(event){
         $(".isFinalBody").show();
      }
    }

   createItem(data = null) {
      return this.fb.group({
         Id: data != null ? data.id : 0,
         name: data != null ? data.name : null,
         dateTime: data != null ? this.commonService.refilldateTimeFormatChange(data.date) : null,
         interviewDetail: data != null ? this.fb.array([]) : this.fb.array([this.createInterviewDetail()]),
         disabled: data != null ? true : false
      });
   }

   createInterviewDetail(data = null) {
      return this.fb.group({
         Id: data != null ? data.id : 0,
         interviewer: data != null ? data.emp_Id : '',
         status: data != null ? data.status : '',
         note: data != null ? data.notes : '',
         isdisabled: data != null ? data.status == 'ShortListed' || data.status == 'Rejected' ? true : false : false
      });
   }

   addscheduleInterview(data = null) {
      if (this.validation()) {
         this.items = this.interviewFeedBackform.get('interviewFeedback') as FormArray;
         this.items.push(this.createItem(data));
      }
   }

   interviewDetail(Index: number): FormArray {
      return (this.interviewFeedBackform.get('interviewFeedback') as FormArray).at(Index).get("interviewDetail") as FormArray;
   }

   interviewFeedbackDisabled(i, j) {
      return this.interviewFeedBackform.get('interviewFeedback').value[i].interviewDetail[j].isdisabled
   }

   addInterviewDetail(index, data = null) {
      if (this.validation()) {
         this.items = (this.interviewFeedBackform.get('interviewFeedback') as FormArray).at(index).get("interviewDetail") as FormArray;
         this.items.push(this.createInterviewDetail(data));
      }

   }

   removeInterviewDetail(index, detailIndex) {
      this.items = (this.interviewFeedBackform.get('interviewFeedback') as FormArray).at(index).get("interviewDetail") as FormArray;
      this.items.removeAt(detailIndex);
   }

   getEmpList(id) {
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "company_Id",
               "FieldValue": id,
               "Parameter": "=="
            }
         ]
      }
      this.commonApiService.GetByPaginated(Commonvariable.EmployeeDetail, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            this.empList = response["data"];
         }
      })
   }



   upsert(id, vacancy_Id, status, salary = null, reporting_Id = null, JoiningDate = null) {

      var requestData = {
         "Candidate_Id": id,
         "Vacancy_Id": vacancy_Id,
         "status": status,
         "salary": salary,
         "reporting_Id": reporting_Id,
         "JoiningDate": JoiningDate,
         "UpdatedBy": Commonvariable.User_Id,
         "candidate_Interviewer": []
      }

      let formData = this.interviewFeedBackform.value;

      if (this.validation() && this.moreValidation()) {
         formData.interviewFeedback.forEach(x => {
            x.interviewDetail.forEach(y => {
               var prepareJson = {
                  "Id": y.Id,
                  "Candidate_Id": id,
                  "Vacancy_Id": vacancy_Id,
                  "Emp_Id": y.interviewer,
                  "Name": x.name,
                  "Date":  this.commonService.dateTimeFormatChange(x.dateTime) ,
                  "status": y.status,
                  "Notes": y.note,
                  "isActive": 1,
                  "AddedBy": Commonvariable.User_Id,
                  "UpdatedBy": Commonvariable.User_Id
               }
               requestData.candidate_Interviewer.push(prepareJson);
            });
         });
      }
      return requestData.candidate_Interviewer.length > 0 ? requestData : null;

   }



   async fillInterviewer(data) {
      if (data != null && data.length > 0) {

         this.items = this.interviewFeedBackform.get('interviewFeedback') as FormArray;
         this.items.clear();

         for (let index = 0; index < data.length; index++) {
            this.addscheduleInterview(data[index][0])
            data[index].forEach(interviewer => {
               this.addInterviewDetail(index, interviewer)
            });
         }
      }
   }

   moreValidation(){
      let formData = this.interviewFeedBackform.value;


      var feedbackInterview = formData.interviewFeedback;


      for (let indexFeedbackInterview = 0; indexFeedbackInterview < feedbackInterview.length; indexFeedbackInterview++) {
         var interviewDetail = feedbackInterview[indexFeedbackInterview]["interviewDetail"];
         for (let indexinterviewDetail = 0; indexinterviewDetail < interviewDetail.length; indexinterviewDetail++) {
            if (interviewDetail[indexinterviewDetail].status == "Schedule"|| interviewDetail[indexinterviewDetail].status == null || String(interviewDetail[indexinterviewDetail].status).trim() == "") {

               this.commonService.toaster("info", "Feedback is required !", this.title)
               $("#status" + indexFeedbackInterview + indexinterviewDetail).focus();
               return false;

            }
         }
      }
      return true;

   }




   validation() {

      let formData = this.interviewFeedBackform.value;


      var feedbackInterview = formData.interviewFeedback;


      for (let indexFeedbackInterview = 0; indexFeedbackInterview < feedbackInterview.length; indexFeedbackInterview++) {

         if (feedbackInterview[indexFeedbackInterview].name == null || String(feedbackInterview[indexFeedbackInterview].name).trim() == "") {

            this.commonService.toaster("info", "Name is required !", this.title)
            $("#name" + indexFeedbackInterview).focus();
            return false;
         }
         if (feedbackInterview[indexFeedbackInterview].dateTime == null || feedbackInterview[indexFeedbackInterview].dateTime == "") {
            this.commonService.toaster("info", "Datetime is required !", this.title)
            $("#interview_date_time" + indexFeedbackInterview).focus();
            return false;
         }


         var interviewDetail = feedbackInterview[indexFeedbackInterview]["interviewDetail"];

         for (let indexinterviewDetail = 0; indexinterviewDetail < interviewDetail.length; indexinterviewDetail++) {
            if (interviewDetail[indexinterviewDetail].interviewer == null || String(interviewDetail[indexinterviewDetail].interviewer).trim() == "") {

               this.commonService.toaster("info", "Interviewer is required !", this.title)
               $("#interviewer" + indexFeedbackInterview + indexinterviewDetail).focus();
               return false;

            }
         }
      }
      return true;
   }
}



