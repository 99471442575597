<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Company List
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right mr0">
                <li>
                    <div>
                        <a routerLink="/companyadd" class="btn btn-transparent blue  btn-sm">Add
                            Company</a>
                    </div>
                </li>
            </ul>
        </div>

        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->


        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" [formControl]="cSearch" placeholder="Type to filter ..." id="filterList"
                                (input)="getCompanyList(1)">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="sample_2">
                            <thead class="flip-content">
                                <tr>
                                    <!-- <th class="text-center" style="width: 8%;"> Logo </th> -->
                                    <th class="text-center"> Company</th>
                                    <!-- <th class="text-center" style="width: 15%;">Company Code </th> -->
                                    <th class="text-center" style="width: 10%;"> Registration No </th>
                                    <th class="text-center" style="width: 10%;"> Registration Date </th>
                                    <th class="text-center" style="width: 10%;"> Currency </th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of companyList | paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">
                                    <!-- *ngIf="item.isActive == 1" -->
                                    <!-- <td class="text-center">
                                        <img src="assets/layouts/layout4/company_logo/logo-light.png">
                                    </td> -->

                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(item.company_Id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive" pTooltip="Active" tooltipPosition="top"></div>
                                            </a>
                                            <a (click)="updateStatus(item.company_Id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive" pTooltip="InActive" tooltipPosition="top"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12" pTooltip="{{item.company_Name}}" tooltipPosition="top" >
                                            {{item.company_Name}}</div>
                                    </td>
                                    <!-- <td>{{item.company_Code}}</td> -->
                                    <td>{{item.registration_No}}</td>
                                    <td class="text-center">{{item.registration_Date | date:'dd/MM/yyyy'}}</td>
                                    <td>{{ item.currency}}</td>
                                    <td class="numeric text-center">
                                        <a *ngIf="item.isActive" [routerLink]="['/companyadd']" [queryParams]="{cId:item.company_Id}"
                                            skipLocationChange pTooltip="Edit Company" tooltipPosition="top"><i class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Edit Company"></i></a>
                                        <a *ngIf="!item.isActive" (click)="companyDelete(item.company_Id)" pTooltip="Delete Company" tooltipPosition="top">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="companyList != null">
                        <pagination-controls (pageChange)="getCompanyList($event)" autoHide="true">
                        </pagination-controls>
                    </div>

                </div>
            </div>
        </div>





        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->