import { Component, OnInit } from '@angular/core';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { CommonService } from 'src/app/services/common.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-raf-process',
  templateUrl: './raf-process.component.html',
  styleUrls: ['./raf-process.component.css']
})
export class RafProcessComponent implements OnInit {

  companyListForFilter: Array<Select2OptionData>;
  siteListForFilter: Array<Select2OptionData>;
  departmentListForFilter: Array<Select2OptionData>;
  filterCompanyId: any;
  filterSiteId: any;
  filterDepartmentId: any;
  rafRuleList: any[];
  rafRuleApprovalList: any[];

  P: number = 1;
  totalItemForRafRuleList: number = 1;


  constructor(private commonService: CommonService, private fb: FormBuilder,
    private commonApiService: CommonApiService) { }


  ngOnInit() {

    // setTimeout(() => {
    //   $('[data-toggle="tooltip"]').tooltip();
    // }, 500);

    this.getCompanyList()

    // this.vacancyRuleForm = this.fb.group({
    //   Id: ['0'],
    //   name: ['', Validators.required],
    //   Company_Id: ['', Validators.required],
    //   site_Id: ['', Validators.required],
    //   dept_Id: ['', Validators.required],
    //   isActive: ['1'],
    //   AddedBy: [Commonvariable.User_Id],
    //   vacancy_rule_recruiter: this.fb.array([this.vacancyRuleRecuiter()]),
    //   vacancy_rule_detail: this.fb.array([])

    // })
  }



  getCompanyList() {
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "company_Name"
    }

    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companyListForFilter = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }

  getSiteList(purpose, id) {
    if (purpose == 'forFilter') {
      this.filterCompanyId = id;
      this.siteListForFilter = [];
      this.departmentListForFilter = [];
    }

    var requestData = {
      "searchBy": [
        {
          "FieldName": "Company_Id",
          "FieldValue": id,
          "Parameter": "==",
          "ConditionWith": "&&"

        },
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "site_Name"
    }

    if (id > 0) {
      this.commonApiService.GetByPaginated(Commonvariable.Site, requestData).subscribe((response) => {
        if (response != null && response["data"] != "") {
          if (purpose == 'forFilter') {
            this.siteListForFilter = response["data"].map(x => ({
              id: x.site_Id,
              text: x.site_Name
            }))
            this.getDepartmentList('forFilter', id)
          }
        }
      })

    }

  }

  getDepartmentList(purpose, id) {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "Company_Id",
          "FieldValue": id,
          "Parameter": "==",
          "ConditionWith": "&&"

        },
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "Dept_Name"
    }

    this.commonApiService.GetByPaginated(Commonvariable.Department, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        if (purpose == 'forFilter') {
          this.departmentListForFilter = response["data"].map(x => ({
            id: x.dept_Id,
            text: x.dept_Name
          }))
        }
      }
    })
  }

  getRafRuleList(siteId, departmentId) {
    this.filterSiteId = siteId != undefined ? siteId : ''
    this.filterDepartmentId = departmentId != undefined ? departmentId : '';



  }








}

