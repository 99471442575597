<style>
    td.details-control {
        background: url('assets/pages/img/SD7Dz.png') no-repeat center center;
        cursor: pointer;
    }
    
    tr.shown td.details-control {
        background: url('assets/pages/img/d4ICC.png') no-repeat center center;
    }
</style>

<!-- <div class="page-head">
    <div class="page-title">
        <h1>Selection RAF Wise</h1>
    </div>
</div> -->
<div class="col-md-12">
    <!-- BEGIN SAMPLE TABLE PORTLET-->
    <div class="portlet box grey">
        <div class="portlet-body">

            <diV class="row">
                <div class="col-md-9">
                    <a [href]="fileUrl" id="download" class="btn btn-transparent blue  btn-sm" style="visibility: hidden;" [download]="filename">Export</a>
                </div>
                <div class="col-md-3 text-right">
                    <input type="text" class="form-control" placeholder="Type to filter..." id="filterList" autocomplete="off" (input)="getRAFWiseList(1)">
                </div>
            </diV>
            <br />
            <div class="table-responsive">
                <table class="display table table-striped table-bordered " cellspacing="0" width="100%" style="border:1px solid #e7ecf1;">
                    <thead>
                        <tr>
                            <th style="width: 2%"></th>
                            <th class="text-center"> Company </th>
                            <th class="text-center"> RAF </th>
                            <th class="text-center" style="width:6%;"> Position </th>
                            <th class="text-center" style="width:6%;"> New </th>
                            <th class="text-center" style="width:6%;"> Interviewed </th>
                            <th class="text-center" style="width:6%;">Selected</th>
                            <th class="text-center" style="width:6%;">Rejected</th>
                            <th class="text-center" style="width:6%;"> Offered </th>
                            <th class="text-center" style="width:6%;"> Accepted </th>
                            <th class="text-center" style="width:6%;"> Declined </th>
                            <th class="text-center" style="width:6%;"> Onboarding </th>
                            <th class="text-center" style="width:6%;"> Joined </th>
                            <th class="text-center" style="width:6%;"> Open </th>

                            <th style="width: 2%"> Actions </th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let item of rafWiseList| paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord ,id:'pagination1' }">
                        <tr>
                            <td class="details-control details-control-icon-{{item.id}}" (click)="expandTable(item.id,item.company_Id)"></td>
                            <td class="text-center">{{item.company_Name}}</td>
                            <td class="text-center">{{item.desig_Name}}</td>

                            <td class="text-center">{{item.noOfPositions}}</td>
                            <td class="text-center">{{item.new + item.interviewed}}</td>
                            <td class="text-center">{{item.interviewed}}</td>
                            <td class="text-center">
                                {{item.selectedStatus + item.offered + item.accepted + item.decline + item.onboarding + item.joined}}
                            </td>
                            <td class="text-center">{{item.rejected}}</td>
                            <td class="text-center">
                                {{item.offered + item.accepted + item.decline + item.onboarding + item.joined}}
                            </td>
                            <td class="text-center">{{item.accepted + item.onboarding + item.joined}}</td>
                            <td class="text-center">{{item.decline}}</td>
                            <td class="text-center">{{item.onboarding + item.joined}}</td>
                            <td class="text-center">{{item.joined}}</td>
                            <td class="text-center">{{item.open}}</td>
                            <td>
                                <a data-toggle="modal" (click)="openModel(item.id)"><i
                                        class="fa fa-hand-o-up green iconcss iconFontsize" data-toggle="tooltip"
                                        data-placement="top" title="Selection RAF"></i></a>&nbsp;
                                <!-- <a (click)="downloadJDDocument(item.jD_Id,item.jD_Path)" *ngIf="item.jD_Path">
                                    <i class="fa fa-download purple iconcss iconFontsize" data-toggle="tooltip"
                                        data-placement="top" title="Download RAF"></i>
                                </a>&nbsp; -->
                                <a (click)="openJDDocument(item.jD_Id,item.jD_Path)" *ngIf="item.jD_Path">
                                    <i class="fa fa-download purple iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="View RAF"></i>
                                </a>
                            </td>
                        </tr>
                        <tr class="sub_tbl sub_tbl_{{item.id}}" style="display:none;">
                            <td colspan="14">

                                <div class="table-responsive">
                                    <table cellpadding="4" cellspacing="0" style="width:50%;" class="table table-striped table-bordered dataTable no-footer table-hover">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of rafwiseCandidate | paginate: { itemsPerPage: 5, currentPage: s,id:'paginationsub12' }">
                                            <tr [style.background-color]="item.statusBackGroundColor" [style.color]="item.statusFrontColor">
                                                <td>{{item.firstName}} {{item.lastName}}</td>
                                                <td>{{item.status}}</td>
                                                <td>
                                                    <a (click)="downloadCV(item.id)" *ngIf="item.cv != null">
                                                        <i class="fa fa-download purple iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Download CV"></i>&nbsp;
                                                    </a>
                                                    <div *ngIf="item.moveToOnboarding != 1">
                                                        <a *ngIf="item.status == 'Selected'" (click)="UpdateStatus('Offered',item.candidate_Id,item.vacancy_Id)">
                                                            <i class="fa fa-gift iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Offered"></i>&nbsp;
                                                        </a>

                                                        <a *ngIf="item.status == 'Offered'" (click)="UpdateStatus('Accepted',item.candidate_Id,item.vacancy_Id)"><i
                                                                class="fa fa-check-square-o green iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Accepted"></i>&nbsp;</a>

                                                        <a *ngIf="item.status == 'Offered'" (click)="UpdateStatus('Decline',item.candidate_Id,item.vacancy_Id)"><i
                                                                class="fa fa-close red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Decline"></i>&nbsp;</a>

                                                        <a *ngIf="item.status == 'Accepted' || item.status == 'Offered' ||item.status == 'Selected'" (click)="moveToOnboarding(item.candidate_Id,item.vacancy_Id)">
                                                            <i class="fa fa-paper-plane-o iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Move To Onboarding"></i>&nbsp;
                                                        </a>

                                                        <a *ngIf="item.status != 'Move To Onboarding' && item.status != 'Rejected' 
                                                        && item.status != 'Decline' && item.status != 'Move To Employee'&& item.status != 'Accepted'
                                                        && item.status != 'Offered' && item.status != 'Selected'" (click)="directOpenMoveToOnboarding(item.candidate_Id,item.vacancy_Id)">
                                                            <i class="fa fa-paper-plane-o iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Move To Onboarding"></i>&nbsp;</a>

                                                        <a *ngIf="item.status == 'New'|| item.status == 'Shortlisted'" (click)="openInterviewModel(item.candidate_Id,item.vacancy_Id)">
                                                            <i class="fa fa-calendar iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Schedule Interview"></i>&nbsp;
                                                        </a>

                                                        <a *ngIf="item.status == 'Schedule'" (click)="openInterviewFeedbackModel(item.candidate_Id,item.vacancy_Id,item.dept_Id,item.desig_Id)">
                                                            <i class="fa fa-comments-o iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Interview Feedback"></i>&nbsp;
                                                        </a>
                                                        <a *ngIf="item.status == 'Schedule'" (click)=" GetMail(item.candidate_Id,item.vacancy_Id)">
                                                            <i class="fa fa-envelope-o iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Send Schedule Mail"></i>&nbsp;
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br />
                                <div class="text-center" *ngIf="rafwiseCandidate != null">
                                    <pagination-controls id="paginationsub12" (pageChange)="s =$event">
                                    </pagination-controls>
                                </div>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center" *ngIf="rafWiseList != null">
                <pagination-controls id="pagination1" (pageChange)="getRAFWiseList($event)">
                </pagination-controls>
            </div>
        </div>
    </div>
</div>


<div class="modal fade draggable-modal" id="draggable" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">By RAF</h4>
            </div>
            <div class="modal-body">
                <diV class="row">
                    <div class="col-md-9">
                    </div>
                    <div class="col-md-3 text-right">
                        <input type="text" class="form-control" placeholder="Type to filter..." id="filterListRafWise" autocomplete="off" (input)="filterData($event)">
                    </div>
                </diV>
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover" id="tblDepartment" style="position: relative;" role="grid">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center"> Candidate Name </th>
                                <th class="text-center"> Department </th>
                                <th class="text-center"> Job Title </th>
                                <th class="text-center">Skill</th>
                                <th class="text-center">Experience</th>
                                <th class="text-center">Applied For</th>
                                <th class="text-center"> Select By RAF </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of candidateList| paginate: { itemsPerPage: 5, currentPage: m ,id: 'pagination'}">
                                <td>{{item.firstName}} {{item.lastName}}</td>
                                <td>
                                    <aside data-toggle="tooltip" data-placement="bottom" title="{{item.job_Category}}" class="text-center">
                                        <!-- {{item.job_Category}} -->
                                        {{ item.job_Category != null && item.job_Category != "" ? (item.job_Category.length>20) ? (item.job_Category | slice:0:20)+'...':(item.job_Category) : item.job_Category}}
                                    </aside>
                                </td>
                                <td class="text-center">
                                    <aside data-toggle="tooltip" data-placement="bottom" title="{{item.job_Level}}" class="text-center">
                                        {{ item.job_Level != null ? (item.job_Level.length>20)? (item.job_Level | slice:0:20)+'...':(item.job_Level) : item.job_Level }}
                                    </aside>
                                </td>
                                <td class="text-center">
                                    <aside data-toggle="tooltip" data-placement="bottom" title="{{item.skills}}" class="text-center">
                                        {{ item.skills != null ?(item.skills.length>20)? (item.skills | slice:0:20)+'...':(item.skills) : item.skills}}
                                    </aside>
                                </td>

                                <td class="text-center">
                                    {{item.experience}}</td>
                                <td class="text-center">
                                    {{item.applied_For}}</td>

                                <td class="text-center">
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" data-id="{{item.id}}" id="rafCandidate{{item.id}}" [disabled]="item.isDisplay > 0 || item.selectedRaf > 0" class="md-check rafCandidate" (click)="updateSelectRAF(item.id)" [checked]="item.selectedRaf != 0">
                                            <label for="rafCandidate{{item.id}}">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center" *ngIf="candidateList != null">
                    <pagination-controls id="pagination" (pageChange)="m =$event" autoHide="true">
                    </pagination-controls>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" class="btn blue" (click)="Addrecord()">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="draggable1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Schedule Interview</h4>
            </div>
            <div class="modal-body">
                <app-scheduleInterview></app-scheduleInterview>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn dark btn-outline pull-left" (click)="history()"
                                               *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button> -->
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button class="btn btn-outline blue" (click)="UpsertInterviewDetail('Schedule')">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="draggable2" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Interview Feedback</h4>
            </div>
            <div class="modal-body">
                <app-interviewFeedback></app-interviewFeedback>
            </div>
            <div class="modal-footer">
                <div class="row isFinalBody">
                    <div class="col-md-2">
                        <div class="md-checkbox-inline text-left ">
                            <div class="md-checkbox">
                                <input type="checkbox" id="finalRafWise" class="md-check" (change)="showIsFinal();" [checked]="isFinal">
                                <label for="finalRafWise">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span>Is Final </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-left" *ngIf="isFinal">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Salary<span class="required"> *
                                </span> </label>
                            <div class="input-group">
                                <input type="number" min="0" step="0.01" id="rafWiseSalary" class="form-control" autocomplete="off" maxlength="50" placeholder="{{salaryPlaceHolder}}">
                                <span class="input-group-addon inputaddon">{{currency_Code}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label">Salary Type </label>
                            <select id="rafWiseSalaryType" class="form-control">
                                <option value="">--- Select ---</option>
                                <option value="Annually">Annually</option>
                                <option value="Hourly">Hourly</option>
                            </select>
                        </div>
                    </div> -->
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Reporting<span class="required"> *
                                </span> </label>
                            <select id="rafWiseReporting_Id" class="form-control">
                                <option value="">--- Select ---</option>
                                <option *ngFor="let emp of reportingEmpList;" value={{emp.emp_Id}}>
                                    {{emp.firstName}} {{emp.lastName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Planned Start Date<span class="required"> *
                                </span> </label>
                            <!-- <input id="rafWiseJoiningDate" type="date" class="form-control"> -->

                            <div class="input-group flatpickr" id="rafWiseJoiningDates" data-wrap="true">
                                <input type="text" class="form-control" id="rafWiseJoiningDate" data-input />
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>


                        </div>
                    </div>
                </div>
                <hr />
                <div class="text-center btnInterview">
                    <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                    <button class="btn blue" (click)="UpsertInterviewDetailForFeedback('Shortlisted')" [disabled]="isFinal">Save</button>
                    <button class="btn btn-outline blue" (click)="UpsertInterviewDetailForFeedback('Selected')" [disabled]="!isFinal">Selected</button>
                    <button class="btn btn-outline blue" (click)="UpsertInterviewDetailForFeedback('Rejected')" [disabled]="!isFinal">Rejected</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="modalscheduleinterview" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Schedule Interview</h4>
            </div>
            <div class="modal-body">
                <app-scheduleInterview></app-scheduleInterview>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn dark btn-outline pull-left" (click)="history()"
                                               *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button> -->
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button class="btn btn-outline blue" (click)="UpsertInterviewDetail('Schedule')">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="SendMail" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important;overflow-y: scroll;
    max-height: 765px;">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Send Mail</h4>
            </div>
            <div class="modal-body">
                <app-sendmail></app-sendmail>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" data-dismiss="modal" class="btn blue btn-outline" (click)="sendMail()">Send</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="selectionRafWiseOpenFile" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Description</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <iframe [src]='file' width="100%" height="600px"></iframe>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade draggable-modal" id="modalrafMovetoOnboarding" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Move To Onboarding</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <table class="table">

                            <thead>
                                <tr>
                                    <th>Vacancy Name</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of candidateVacancyList">
                                    <td>{{item.desig_Name}}</td>
                                    <td>{{item.status}}</td>
                                    <td>
                                        <input type="radio" name="mto" value="{{item.vacancy_Id}}" [checked]="item.vacancy_Id == vacancy_Id " (click)="getReportingEmpList(item.company_Id,item.dept_Id,item.desig_Id,item.salaryFrom,item.salaryTo,item.currency_Code,item.salaryType,item.vacancy_Id)">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Salary
                                <!-- <span class="required"> *</span> -->
                            </label>
                            <div class="input-group">
                                <input type="number" min="0" step="0.01" id="rafSelectionSalary" class="form-control" autocomplete="off" maxlength="50" placeholder="{{salaryPlaceHolder}}">
                                <span class="input-group-addon inputaddon">{{currency_Code}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Reporting
                                <!-- <span class="required"> *</span>  -->
                            </label>
                            <select class="form-control" id="rafSelectionReporting">
                                <option value="">--- Select ---</option>
                                <option *ngFor="let emp of reportingEmpList;" value={{emp.emp_Id}}>
                                    {{emp.firstName}} {{emp.lastName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Planned Start Date
                                <!-- <span class="required"> *</span> -->
                            </label>

                            <div class="input-group flatpickr" id="rafSelectionJoiningDates" data-wrap="true">
                                <input type="text" class="form-control" id="rafSelectionJoiningDate" data-input />
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button class="btn blue" type="button" (click)="directMoveToOnboarding()">Save</button>
            </div>
        </div>
    </div>
</div>