import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeePersonalInfoComponent } from "./employee-personal-info/employee-personal-info.component";
import { EmployeeJobDetailsComponent } from "./employee-job-detail/employee-job-detail.component";
import { EmpAddressInfoComponent } from "../employeeEdit/address-info/emp-address-Info.component";
import { EmployeeContactComponent } from "../employeeEdit/emp-contact/emp-contact.component";
import { EmployeeEmergencyComponent } from "../employeeEdit/emp-emergency-contact/emp-emergency-contact.component";
import { EmployeeBankComponent } from "../employeeEdit/emp-bank/emp-bank.component";
import { EmployeeRefComponent } from "../employeeEdit/emp-reference/emp-reference.component";
import { EmployeeProbationComponent } from "../employeeEdit/probation/emp-probation.component";
import { EmployeeContractComponent } from "../employeeEdit/emp-contract/emp-contract.component";
import { EmpRightWorkComponent } from "../employeeEdit/emp-right-work/emp-right-work.component";
import { EmpSalaryComponent } from "../employeeEdit/emp-salary/emp-salary.component";
import { EmpDocumentComponent } from "../employeeEdit/emp-document/emp-document.component";
import { EmpLeaveComponent } from "../employeeEdit/emp-leave/emp-leave.component";
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { interval } from 'rxjs';
import { CandidatePersonEqualityComponent } from '../onboarding/onboarding-person-equality/candidate-person-equality.component';
import { EmpResignationComponent } from './employee-resignation/employee-resignation.component';
import { CalculateAgePipe } from 'src/app/pipes/CalculateAgePipe';
import { start } from 'repl';
import { CurrencyPipe } from '@angular/common';
import { debounceTime, distinctUntilChanged, endWith, filter, startWith, switchMap } from 'rxjs/operators';
declare var $: any;
declare var moment: any;
declare let DateOfBirth: any;
@Component({
   selector: 'app-empprofile',
   templateUrl: './employee-profile.component.html',
   providers: [CalculateAgePipe]
})
export class EmployeeProfileComponent implements OnInit {
   @ViewChild(HistoryComponent, { static: false }) History: HistoryComponent;
   @ViewChild(EmployeePersonalInfoComponent, { static: false }) EmpPersonalInfo: EmployeePersonalInfoComponent;
   @ViewChild(EmployeeJobDetailsComponent, { static: false }) EmpJDetail: EmployeeJobDetailsComponent;
   @ViewChild(EmpAddressInfoComponent, { static: false }) EmpAddress: EmpAddressInfoComponent;
   @ViewChild(EmployeeContactComponent, { static: false }) EmpContact: EmployeeContactComponent;
   @ViewChild(EmployeeEmergencyComponent, { static: false }) EmpEmeContact: EmployeeEmergencyComponent;
   @ViewChild(EmployeeBankComponent, { static: false }) EmpBank: EmployeeBankComponent;
   @ViewChild(EmployeeRefComponent, { static: false }) EmpReference: EmployeeRefComponent;
   @ViewChild(EmployeeProbationComponent, { static: false }) EmpProbation: EmployeeProbationComponent;
   @ViewChild(EmployeeContractComponent, { static: false }) EmpContract: EmployeeContractComponent;
   @ViewChild(EmpRightWorkComponent, { static: false }) EmpRightToWork: EmpRightWorkComponent;
   @ViewChild(EmpSalaryComponent, { static: false }) EmpSalary: EmpSalaryComponent;
   @ViewChild(EmpDocumentComponent, { static: false }) EmpDocument: EmpDocumentComponent;
   @ViewChild(EmpLeaveComponent, { static: false }) EmpLeave: EmpLeaveComponent;
   @ViewChild(CandidatePersonEqualityComponent, { static: false }) CandidatePersonEquality: CandidatePersonEqualityComponent;
   @ViewChild(EmpResignationComponent, { static: false }) EmpResignation: EmpResignationComponent;


   logoClass = "fileinput fileinput-new";
   fileUrl;
   fileUrlmodalJobDescription:any;
   fileName: any;
   employeeSummary: any;
   logoInnerHTML = "";
   empPersonalInfo: any;
   empAddress: any;
   empJobDetails: any;
   empConatct: any;
   empEmergency: any;
   empBank: any;
   empRef: any;
   empProbation: any;
   empContract: any;
   emprightToWork: any;
   emp_righttoworkview: any;
   empsalary: any;
   empDocuments: any;
   empLeaveBalance: any;
   empRelations: any;
   editid: "";
   ethnicity_Name: any;
   emp_vacancy_link: any;
   employee_length_service_year: any;
   employee_length_service_month: any;
   editMode: boolean = false;
   editRecord: number = 0;
   empNiNoDetailform: FormGroup;
   person_equalityInfo?: any;
   empResignationform: FormGroup;
   reasonforleavelist: any[] = [];
   currentsanctionlist: any[] = [];
   documentList: any[] = [];
   items: FormArray;
   title: string;
   listCall: boolean = true;
   empResignationlist: any;
   p: number = 1;
   totalRecord: 0;
   filterlist: any;
   empList: any;
   empmanagerList: any =[];
   resignationId: any;
   datofBirth:any;
   age:string;
   AgeList: any;
   searchControl = new FormControl();
   empRelationsform:FormGroup;
   constructor(private commonApiService: CommonApiService,
      private toastr: ToastrService,
      private route: ActivatedRoute,
      private commonService: CommonService,
      private sanitizer: DomSanitizer,private sanitizer1: DomSanitizer,
      private fb: FormBuilder,
      private calculateAgePipe: CalculateAgePipe
   ) {

   }
   

   ngOnInit() {
      this.route.queryParamMap.subscribe(params => {
         this.editid = params["params"]["editId"];
         this.getSummary();
         this.hasEmpRef();
      });
      this.commonService.flatpickr("fromdate,#todate", 0);
      this.empResignationform = this.fb.group({
         emp_Resignation_Id: ['0'],
         emp_Id: ['', Validators.required],
         leaveReason_Id: ['', Validators.required],
         noticePeriodInput: ['', Validators.required],
         noticePeriodType: ['Months', Validators.required],
         noticePeriod: [''],
         resignationDate: ['', Validators.required],
         leaveDate: ['', Validators.required],
         lastWorkingDate: ['', Validators.required],
         notes: [''],
         isActive: ['1'],
         addedBy: [Commonvariable.User_Id],
         updatedBy: ['0'],
         emp_document: this.fb.array([this.createItem(null)]),
       });
       this.commonService.flatpickr("flatpickropenResD,#flatpickropenLeaD,#flatpickropenLastWD", 0)
       this.commonService.flatpickr("flatpickrooenLivedate,#flatpickropenExpdate", 0);
       this.empRelationsform = this.fb.group({
         Id: ['0'],
         emp_Id: [0],
         current_Sanction_Id: ['', Validators.required],
         live_Date: ['', Validators.required],
         exp_Date: ['', Validators.required],
         issuing_Manger_Emp_Id: ['', Validators.required],
         sanction_Description: ['', Validators.required],
         isActive: ['1'],
         addedBy: [Commonvariable.User_Id],
         updatedBy: ['0'],
       });
       if (this.listCall) {
         this.getEmployeeResignationList();
         this.getEmpList();
         this.setupAutocompleteManagerList();
        // this.getEmpManagerList();
         this.getAgeList();
         this.getCurrentSanctionList();
         //this.getReasionForLeaveList();
       }
   
       //this.commonService.select2SingleDropDown("empNameSelectListClass");
   
       setTimeout(() => {
         $('[data-toggle="tooltip"]').tooltip();
       }, 500);
   }

   ngAfterViewInit(){
      $("[data-toggle=popover]").each(function (i, obj) {
      
               $(this).popover({
                  html: true,
                  content: function () {
                     var id = $(this).attr('id')
                     return $('#popover-content-' + id).html();
                  }
               });
            });
            this.setupAutocompleteManagerList();
            
   }

   getAgeList() {
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "Description"
      }

      this.commonApiService.GetByPaginatedForAge(Commonvariable.Marital_Status, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            this.AgeList = response["data"].map(x => ({
               id: x.id,
               text: x.description,
               start: x.start,
               end: x.end
            }))

            console.log(this.AgeList);

            var dt= this.AgeList.filter(a=> a.start<=38 && a.end>=38)
         }
      })
   }

   checkboxValue: boolean = false;

   getReasionForLeaveList(event) {
      this.reasonforleavelist = [];
      var empid = event != "" ? event : 0;    
      this.empResignationform.controls.leaveReason_Id.setValue('')
      this.commonApiService.getById(Commonvariable.ReasonForLeave, empid, "GetByEmpId").subscribe((response) => {
        if (response != null && response["data"] != "") {
          this.reasonforleavelist = response["data"];
        }
      })
    }

    getCurrentSanctionList() {
      this.currentsanctionlist = [];
     
      this.commonApiService.List(Commonvariable.EmployeeRelations,"GetAllcurrentsanction").subscribe((response) => {
        if (response != null && response["data"] != "") {
          this.currentsanctionlist = response["data"];
        }
      })
    }

    getEmployeeResignationList(PageIndex = 1) {


      this.p = PageIndex;
      this.totalRecord = 0;
      this.empResignationlist = [];
      this.filterlist = [];
      this.commonApiService.getListWithFilterData(Commonvariable.EmployeeResignation, PageIndex).subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.totalRecord = result["data"]["recordCount"]
          this.empResignationlist = result["data"]["list"];
          this.filterlist = result["data"]["list"];
         //  this.resignationId = result["data"].sort((a, b) => a.firstName.localeCompare(b.firstName)).map(x => ({
         //    resignationId: x.emp_Resignation_Id,
         //  }))
         this.empResignationlist.forEach(item => {
            if (item.emp_Id === Number(this.editid)) {
               this.resignationId = item.emp_Resignation_Id;
            }
          });
          console.log(this.empResignationlist);
          console.log(this.resignationId);
        }
        console.log(this.empResignationlist);
      })
  
      
      // this.empResignationlist = [];
      // this.commonApiService.List(Commonvariable.EmployeeResignation).subscribe((result) => {
      //   if (result != null && result["status"] == 200) {
      //     this.empResignationlist = result["data"];
      //     this.filterlist = result["data"];
      //     //this.filterlist = result["data"]["list"];        
      //   }
      // })
    }
    getEmpList() {
      this.commonApiService.empDetailList(Commonvariable.EmployeeDetail).subscribe((result) => {
        if (result != null && result["status"] == 200) {
          //this.empList = result["data"];
          //.filter(x => x.isActive != 0).sort((a, b) => a.firstName.localeCompare(b.firstName));
          this.empList = result["data"].sort((a, b) => a.firstName.localeCompare(b.firstName)).map(x => ({
            id: x.emp_Id,
            text: x.firstName+ ' '+x.lastName
          }))
        }
      })
    }
   //  getEmpManagerList() {
   //    debugger;
   //    this.commonApiService.empManagersList(Commonvariable.EmployeeDetail).subscribe((result) => {
   //      if (result != null && result["status"] == 200) {
   //        //this.empList = result["data"];
   //        //.filter(x => x.isActive != 0).sort((a, b) => a.firstName.localeCompare(b.firstName));
   //        this.empmanagerList = result["data"].map(x => ({
   //          id: x.id,
   //          text: x.displayName
   //        }))
   //      }
   //    })
   //  }
   
    private setupAutocompleteManagerList(): void {
   
      this.searchControl.valueChanges.pipe(
       
        debounceTime(10),
        distinctUntilChanged(),
        switchMap((id) => this.commonApiService.getEmployeesManagerByKeyword(Commonvariable.EmployeeDetail,"AllMangers",id))
      ).subscribe((response: { data: any[] }) => {
        if (response != null && Array.isArray(response.data)) {
         this.empmanagerList = response["data"].map(x => ({
            id: x.id,
            text: x.displayName,
            empcode : x.empcode
          }))
          console.log(this.empmanagerList);
        }
      });
    }
    getDocument() {

      var requestData = {
        "searchBy": [
          {
            "FieldName": "isActive",
            "FieldValue": "1",
            "Parameter": "=="
          }
        ],
        "OrderBy": 'name'
      }
  
      this.commonApiService.GetByPaginated(Commonvariable.Document, requestData).subscribe((response) => {
        if (response["data"] != null && response["data"] != "") {
          this.documentList = response["data"];
        }
      })
    }
   onCheckboxChange() {
     if (this.checkboxValue) {
       // Show Bootstrap modal
       $('#leaverModal').modal({ backdrop: 'static', keyboard: false });
       if(this.resignationId != undefined){
         this.openModel(this.resignationId);
       }
       else{
         this.getSummary();
       }
       
       //this.openModel(this.editid);
     } else {
       // Hide Bootstrap modal
       $('#leaverModal').modal('hide');
     }
   }
   onCloseClick() {
      // Uncheck the checkbox when the close button is clicked
      this.checkboxValue = false;
      $('#leaverModal').modal('hide');
    }
   onModalHidden() {
      // Reset checkbox when the modal is hidden
      this.checkboxValue = false;
    }



   hasEmpRef(): boolean {
      return this.empRef && this.empRef.length > 0;
    }
    hasEmpRtw(): boolean {
      return this.emprightToWork && this.emprightToWork.length > 0;
    }



    openModel(id) {
      this.getDocument();
      this.items = this.empResignationform.get('emp_document') as FormArray;
      this.items.clear();
      if (id > 0) {
        this.title = "Edit";
  
        this.commonApiService.getById(Commonvariable.EmployeeDetail, id, "GetLeaver").subscribe((response) => {
  
          var splittedNoticPeriod = response["data"].noticePeriod.split(" ", 2);
  
          if (response != null) {
  
            var editData = response["data"]
  
  
            this.getReasionForLeaveList(editData.emp_Id)
            var formData = this.empResignationform;
  
  
            this.empResignationform.controls.emp_Resignation_Id.setValue(editData.emp_Resignation_Id)
            this.empResignationform.controls.emp_Id.setValue(editData.emp_Id)
            this.empResignationform.controls.leaveReason_Id.setValue(editData.leaveReason_Id)
            this.empResignationform.controls.noticePeriod.setValue(editData.noticePeriod)
            this.empResignationform.controls.notes.setValue(editData.notes)
            this.empResignationform.controls.isActive.setValue(editData.isActive)
  
            //this.commonService.select2SingleDropDown("empNameSelectListClass", editData.emp_Id);
  
            if (editData.emp_Id != "" && editData.emp_Id != null) {
              $("#emp_Id").prop("disabled", true);
            }
  
  
            let resignationDate = response["data"].resignationDate != null ? response["data"].resignationDate.split("T")[0].split("-") : "";
            let leaveDate = response["data"].leaveDate != null ? response["data"].leaveDate.split("T")[0].split("-") : "";
            let lastWorkingDate = response["data"].lastWorkingDate != null ? response["data"].lastWorkingDate.split("T")[0].split("-") : "";
  
  
  
  
            if (resignationDate != "") {
              $("#ResignationDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: resignationDate[2] + "-" + resignationDate[1] + "-" + resignationDate[0] });
              $("#ResignationDate").val(resignationDate[2] + "-" + resignationDate[1] + "-" + resignationDate[0]);
            }
            if (leaveDate != "") {
              $("#LeaveDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: leaveDate[2] + "-" + leaveDate[1] + "-" + leaveDate[0] });
              $("#LeaveDate").val(leaveDate[2] + "-" + leaveDate[1] + "-" + leaveDate[0]);
            }
            if (resignationDate != "") {
              $("#LastWorkingDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: lastWorkingDate[2] + "-" + lastWorkingDate[1] + "-" + lastWorkingDate[0] });
              $("#LastWorkingDate").val(lastWorkingDate[2] + "-" + lastWorkingDate[1] + "-" + lastWorkingDate[0]);
            }
            if (splittedNoticPeriod[0] != "") {
              $("#noticePeriodInput").val(splittedNoticPeriod[0]);
            }
            if (splittedNoticPeriod[1] != "") {
              $("#noticePeriodType").val(splittedNoticPeriod[1]);
            }
            if (response["data"].noticePeriod != "") {
              $("#noticePeriod").val(response["data"].noticePeriod);
            }
            formData.controls.noticePeriodInput.setValue(splittedNoticPeriod[0])
            formData.controls.noticePeriodType.setValue(splittedNoticPeriod[1])
            formData.controls.updatedBy.setValue(Commonvariable.User_Id)
  
  
            if (response["data"]["doc"].length > 0) {
              response["data"]["doc"].forEach(x => {
                this.items.push(this.createItem(x));
              });
            }
          }
        })
      }
      else {
        $("#emp_Id").prop("disabled", false);
  
        this.listCall = false;
        this.ngOnInit();
      }
      $('#leaverModal').modal({
        backdrop: 'static'
      });
  
    }

    autoCompleteLeaveDate() {
    
     // let addKey = $("#noticePeriodType").val() == "Days" ? 'days' : $("#noticePeriodType").val() == "Months" ? 'M' : 'Y';
     let addKey = $("#noticePeriodType").val() == "Days" ? 'days' : $("#noticePeriodType").val() == "Weeks" ? 'W' : $("#noticePeriodType").val() == "Months" ? 'M' : 'Y';
      let addvalue = $("#noticePeriodInput").val();    
      // if(addvalue !="" && addvalue !=null){            
      //   var noticeDate = moment(moment().format('DD-MM-YYYY'), 'DD-MM-YYYY').add(addvalue, addKey).format("DD-MM-YYYY");      
      //   $("#ResignationDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: noticeDate });
      // }
      if ($("#ResignationDate").val() != "") {
        var selectedStartDate = moment($("#ResignationDate").val(), 'DD-MM-YYYY').format("DD-MM-YYYY");
        var endDate = moment(selectedStartDate, 'DD-MM-YYYY').add(addvalue, addKey).format("DD-MM-YYYY");
        $("#LeaveDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: endDate });
        $("#LastWorkingDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: endDate });
      }
  
    }
  
  
    autoCompleteLeaveDateNotice(){
  
     // let addKey = $("#noticePeriodType").val() == "Days" ? 'days' : $("#noticePeriodType").val() == "Months" ? 'M' : 'Y';
     let addKey = $("#noticePeriodType").val() == "Days" ? 'days' : $("#noticePeriodType").val() == "Weeks" ? 'W' : $("#noticePeriodType").val() == "Months" ? 'M' : 'Y';
      let addvalue = $("#noticePeriodInput").val();    
      if(addvalue !="" && addvalue !=null){ 
        var selectedStartDate = moment($("#ResignationDate").val(), 'DD-MM-YYYY').format("DD-MM-YYYY");
        var endDate = moment(selectedStartDate, 'DD-MM-YYYY').add(addvalue, addKey).format("DD-MM-YYYY");
        $("#LeaveDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: endDate });
        $("#LastWorkingDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: endDate });
      }
    }   
  
 

    createItem(data) {
      return this.fb.group({
        Emp_Doc_Id: data != null ? data.emp_Doc_Id : '0',
        Doc_Id: data != null ? data.doc_Id : '',
        files: data != null ? null : '',
        FileNameRef: data != null ? data.emp_Doc_Name : '',
        file_Name: data != null ? data.file_Name : '',
        isActive: true,
        addedBy: Commonvariable.User_Id,
      });
    }

    addItem(data) {
      if (this.documentValidation()) {
        this.items = this.empResignationform.get('emp_document') as FormArray;
        this.items.push(this.createItem(data));
      }
    }
    removeItem(index) {
      this.items = this.empResignationform.get('emp_document') as FormArray;
      if (this.items.value[index]["Emp_Doc_Id"] > 0) {
        this.commonApiService.Delete(Commonvariable.EmployeeDetail, this.items.value[index]["Emp_Doc_Id"], "DeleteLeaverDocument").subscribe((result) => {
          this.commonService.toaster("success",result["message"],"Employee Document");
          this.items.removeAt(index);
          
        })
      }
      else {
        this.items.removeAt(index);
      }
  
      //this.items.removeAt(index);
    }
    documentValidation() {
      let document = this.empResignationform.controls.emp_document.value;
      if (document.length > 0) {
        for (let index = 0; index < document.length; index++) {
  
          if (document[index]["Doc_Id"] == '') {
            this.toastr.info("Select document type !", "Employee");
            $("#Doc_Id" + index).focus();
            return false;
          }
  
          if (document[index]["files"] == '') {
            this.toastr.info("Select file !", "Employee");
            return false;
          }
        }
      }
  
      return true;
    }

    fileChangeMultiple(event, id) {

      if (event.target.files.length > 0) {
        // if (event.target.files.item(0).name.split('.')[1] == "pdf" || event.target.files.item(0).name.split('.')[1] == "doc"
        //   || event.target.files.item(0).name.split('.')[1] == "docx") {
  
          this.items = this.empResignationform.get('emp_document') as FormArray;
  
          if (this.items.value[id].Doc_Id != "") {
            this.items.value[id].files = event.target.files[0];
            this.items.value[id].FileNameRef = event.target.files[0]["name"];
            this.items.value[id].onchange = true
          }
          else {
            this.toastr.info("Select document type !", "Employee");
            $("#Doc_Id" + id).focus();
            $("#removeEmpResignation" + id).click();
          }
        // }
        // else {
        //   this.toastr.info("Allow only pdf,doc,docx file format for upload !", "Employee");
        //   $("#removeEmpResignation" + id).click();
        // }
      }
    }

    removeDoc(index) {
      $("#EmployeeDocument" + index).removeClass("fileinput-exists")
      $("#EmployeeDocument" + index).addClass("fileinput-new")
    }

    filterData(event) {
      //const val = event.target.value;
      const val = event.target.value.toLowerCase().trim();
  
      if (val == "") {
        this.empResignationlist = this.filterlist;
        this.p = 1;
      }
      else {
        const temp = this.empResignationlist.filter(function (d) {
  
          if (d["resignationDate"]) {
            var date = val;
            var datearray = date.split("/");
            var resignationDate = datearray[2] + '-' + datearray[1] + '-' + datearray[0];
          }
  
          if (d["leaveDate"]) {
            var date = val;
            var datearray = date.split("/");
            var leaveDate = datearray[2] + '-' + datearray[1] + '-' + datearray[0];
          }
  
          if (d["lastWorkingDate"]) {
            var date = val;
            var datearray = date.split("/");
            var lastWorkingDate = datearray[2] + '-' + datearray[1] + '-' + datearray[0];
          }
  
          return String(d["firstName"]).toLowerCase().indexOf(val) !== -1 ||
            String(d["middleName"]).toLowerCase().indexOf(val) !== -1 || String(d["lastName"]).toLowerCase().indexOf(val) !== -1 ||
            String(d["leaveReason_Id"]).toLowerCase().indexOf(val) !== -1 || String(d["noticePeriod"]).toLowerCase().indexOf(val) !== -1 ||
            String(d["resignationDate"]).toLowerCase().indexOf(resignationDate) !== -1 || String(d["leaveDate"]).toLowerCase().indexOf(leaveDate) !== -1 ||
            String(d["lastWorkingDate"]).toLowerCase().indexOf(lastWorkingDate) !== -1 || !val;
  
        });
   
        //const temp = this.commonService.filterData(event, this.empResignationlist, "emp_Id");
        this.empResignationlist = temp;
        this.p = 1;
      }
    }
  
    validation() {
      var formData = this.empResignationform;
      formData.controls.resignationDate.setValue(this.commonService.dateFormatChange($("#ResignationDate").val()));
      formData.controls.leaveDate.setValue(this.commonService.dateFormatChange($("#LeaveDate").val()));
      formData.controls.lastWorkingDate.setValue(this.commonService.dateFormatChange($("#LastWorkingDate").val()));
  
  
      if (!formData.valid) {
        for (const key of Object.keys(formData.controls)) {
          if (formData.controls[key].invalid) {
            let fieldName = "";
            if (key == "emp_Id") {
              fieldName = "Employee"
            }
            else if (key == "leaveReason_Id") {
              fieldName = "Leave reason"
            }
            else if (key == "noticePeriodInput") {
              fieldName = "Notice period"
            }
            else if (key == "noticePeriodType") {
              fieldName = "Notice period Type"
            }
            else if (key == "resignationDate") {
              fieldName = "Date of notice"
            }          
            else if (key == "lastWorkingDate") {
              fieldName = "Last working date"
            }
            else if (key == "leaveDate") {
              fieldName = "Leave date"
            }
  
            this.toastr.info(fieldName + " is required !", "Employee Exit");
            $("#" + key).focus();
            return false;
          }
        }
      }
      else {
        if ($("#leaveReason_Id").val().search(/[^a-zA-Z0-9- ]+/) > -1) {
          this.toastr.info("Leave reason only alphanumeric  value allow !", "Employee Exit");
          $("#leaveReason_Id").focus();
          return false;
        }
        if ($("#noticePeriodInput").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
          this.toastr.info("Noticep period only alphanumeric value allow !", "Employee Exit");
          $("#noticePeriodInput").focus();
          return false;
        }
        if (moment($("#ResignationDate").val(), 'DD-MM-YYYY') > moment($("#LeaveDate").val(), 'DD-MM-YYYY') ||
          moment($("#ResignationDate").val(), 'DD-MM-YYYY') > moment($("#LastWorkingDate").val(), 'DD-MM-YYYY')) {
          this.toastr.info("Date of notice not more then leave date and last working date !", "Employee Exit");
          $("#ResignationDate").focus();
          return false;
        }
        if (moment($("#LeaveDate").val(), 'DD-MM-YYYY') > moment($("#LastWorkingDate").val(), 'DD-MM-YYYY')) {
          this.toastr.info("Leave date not more then last working date !", "Employee Exit");
          $("#LeaveDate").focus();
          return false;
        }
      }
      return true;
    }

    Upsert() {
      var empresForm = this.empResignationform;
  
      empresForm.controls.noticePeriod.setValue($("#noticePeriod").val());
      if (this.validation()) {
        //&& this.documentValidation()
        empresForm.get("isActive").setValue(empresForm.get("isActive").value ? 1 : 0);
        let narr = empresForm.value.emp_document.filter(f => f.files != null).map(function (x) {
          var o = Object.assign({});
          o.Emp_Doc_Id = x.Emp_Doc_Id;
          o.Doc_Id = x.Doc_Id;
          o.FileNameRef = x.FileNameRef;
          return o;
        })
  
        const formData = new FormData();
        formData.append('employee_Resignation', JSON.stringify(empresForm.value));
        formData.append('Doc_idCollection', JSON.stringify(narr));
  
        empresForm.value.emp_document.filter(f => f.files != null).map(function (x) {
          formData.append('file', x.files);
        })
  
  
        if (empresForm.controls.emp_Resignation_Id.value > 0) {
          this.commonApiService.putWithParameter(Commonvariable.EmployeeDetail, formData, "EditLeaver").subscribe((response) => {
            if (response != null) {
              if (response["message"] == Commonvariable.DuplicationMsg) {
                this.toastr.warning('Record is already exists !', 'Employee Exit');
              }
              else {
                $("#leaverModal").modal('hide');
                this.getEmployeeResignationList();
                this.toastr.success(empresForm.controls.emp_Resignation_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee Exit');
              }
            }
          })
        }
        else {
          this.commonApiService.PostRequest(Commonvariable.EmployeeDetail, "AddLeaver", formData).subscribe((response) => {
            if (response != null) {
              if (response["message"] == Commonvariable.DuplicationMsg) {
                this.toastr.warning('Record is already exists !', 'Employee Exit');
              }
              else {
                $("#leaverModal").modal('hide');
                this.getEmployeeResignationList();
                this.toastr.success(empresForm.controls.emp_Resignation_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee Exit');
              }
            }
          })
        }
  
  
  
  
  
        // this.commonApiService.Upsert(Commonvariable.EmployeeResignation, empresForm.value, empresForm.controls.emp_Resignation_Id.value).subscribe((response) => {
        //   if (response != null) {
        //     if (response["message"] == Commonvariable.DuplicationMsg) {
        //       this.toastr.warning('Record is already exists !', 'Employee Exit');
        //     }
        //     else {
        //       $("#draggable").modal('hide');
        //       this.getEmployeeResignationList();
        //       this.toastr.success(empresForm.controls.emp_Resignation_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee Exit');
        //     }
        //   }
        // })
      }
  
    }

    updateStatus(Id, status = null) {
      this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
        if (result.value) {
          this.commonApiService.commonUpdateStatus(Commonvariable.EmployeeResignation, Id, status).subscribe((response) => {
  
            if (response["status"] == 200) {
              this.toastr.success(response["message"], 'Employee Exit');
              this.getEmployeeResignationList();
            }
  
          })
        }
  
      });
    }

   getSummary() {
      this.commonApiService.getById(Commonvariable.EmployeeDetail, this.editid, "ViewSummary").subscribe((response) => {
         if (response != null) {
            
            this.employeeSummary = response["data"][0];

            console.log(this.employeeSummary);
          //  company_Id

            let joindig = this.employeeSummary.joiningDate;
            this.Getname(joindig);

            this.empPersonalInfo = this.employeeSummary["emp_basicinfo"];

            this.emp_vacancy_link = this.employeeSummary["employee_vacancy_link"][0] ? this.employeeSummary["employee_vacancy_link"][0] : '';

            this.empContract = this.employeeSummary["emp_contract"];

            //console.log(this.empContract);

            this.empBank = this.employeeSummary["emp_bank"];
           // console.log(this.empBank);

            this.empProbation = this.employeeSummary["emp_probation"];

            this.emprightToWork = this.employeeSummary["emp_righttowork"];
            this.emp_righttoworkview = this.employeeSummary["emp_righttoworkview"];
           // console.log(this.emp_righttoworkview);
            this.empsalary = this.employeeSummary["emp_salary"];
            this.empDocuments = this.employeeSummary["emp_document"];
            this.empRef = this.employeeSummary["emp_reference"];
            this.empLeaveBalance = this.employeeSummary["emp_leave_balance"];
            this.empRelations = this.employeeSummary["employee_relations"];
            console.log(this.empRelations);

            this.empResignationform.controls.emp_Id.setValue(this.employeeSummary.emp_Id)
        
            this.EmpContract.getContractList(this.employeeSummary.company_Id)



            if (response["data"][0]["person_Id"] != null) {
               this.commonApiService.getById(Commonvariable.PersonService, response["data"][0]["person_Id"], "ViewSummary").subscribe((result) => {
                  this.empPersonalInfo = result["data"][0];
                  this.datofBirth = this.empPersonalInfo["dob"];
                  this.datofBirth = this.calculateAgePipe.transform(this.datofBirth);
                  this.age = this.datofBirth;
                 // console.log(this.age);
                  var dt= this.AgeList.filter(a=> a.start<=this.age && a.end>=this.age)
                  //console.log(dt);
                  this.empAddress = this.empPersonalInfo["person_Address"];
                  this.empConatct = this.empPersonalInfo["person_Contact"];
                  this.empEmergency = this.empPersonalInfo["person_Emergency"];
                 // this.empRef = this.empPersonalInfo["person_Reference"];
                  this.person_equalityInfo = this.empPersonalInfo["person_equality"];
                  this.person_equalityInfo.age = dt[0].text;
                 // console.log(this.person_equalityInfo.age);
                 // this.person_equalityInfo.age = this.CandidatePersonEquality.candidatePersonEqualityform.controls
                 this.CandidatePersonEquality.candidatePersonEqualityform.controls.Age.setValue(this.person_equalityInfo.age)
                 //this.EmpJDetail.empJobDetailform.controls.contract_Id.setValue(this.empContract[0].emp_Contract_Id)
                 // console.log(this.person_equalityInfo.age);
                  this.getRelationshipList()
                  this.empImage();
                  //this.getEthinicityName();
               })

            }
         }
      })
   }

   Getname(_date: any) {
      let out;
      var tDate = new Date();
      tDate.setDate(tDate.getDate())
      var date = tDate.getDate().toString().length == 1 ? "0" + tDate.getDate() : tDate.getDate();
      var month = tDate.getMonth() + 1;
      var getmonth = month.toString().length == 1 ? "0" + month : month;
      let DateObj = tDate.getFullYear() + "-" + getmonth + "-" + date;

      const datee = _date;//$('#getjoiningDate').val();
      const now = moment(datee);
      let proEndDate = now.format('YYYY-MM-DD');

      out = this.diffDate(new Date(proEndDate), new Date(DateObj));
      this.display(out);


   }
   

   diffDate(startDate, endDate) {
      var b = moment(startDate),
         a = moment(endDate),
         //intervals = ['years', 'months', 'weeks', 'days'],
         intervals = ['years', 'months'],
         out = {};

      for (var i = 0; i < intervals.length; i++) {
         var diff = a.diff(b, intervals[i]);
         b.add(diff, intervals[i]);
         out[intervals[i]] = diff;
      }
      return out;
   }

   display(obj) {
      var stryear = '';
      var strmonth = '';
      for (const key in obj) {
         if (key == 'years') {
            stryear = stryear + obj[key] + ' ' + key + ' '
         }
         if (key == 'months') {
            strmonth = strmonth + obj[key] + ' ' + key + ' '
         }
      }
      this.employee_length_service_year = stryear;
      this.employee_length_service_month = strmonth;
      //this.employee_length_service = str;
      //alert(str);
      // console.log(str);
   }

   getRelationshipList() {
      this.commonApiService.List(Commonvariable.Relationship).subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.EmpEmeContact.emerrelList = result["data"].sort((a, b) => a.name.localeCompare(b.name));
            this.EmpReference.emprefrelList = result["data"].sort((a, b) => a.name.localeCompare(b.name));
            this.getProbationcompanywiseList()
         }
      }, (error) => { this.getProbationcompanywiseList() });
   }

   getProbationcompanywiseList() {
      this.commonApiService.cascadingDropDwon(Commonvariable.Probation, "company_Id", this.employeeSummary.company_Id).subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.EmpProbation.probationList = result["data"]
         }
      });
   }


   // getEthinicityName() {

   //    if (this.empPersonalInfo.ethnicity_Code != null) {
   //       this.commonApiService.getById("Ethinicity", this.empPersonalInfo.ethnicity_Code).subscribe((response) => {
   //          if (response != null) {
   //             this.ethnicity_Name = response["data"][0].ethnicity_Name;
   //          }
   //       })
   //    }

   // }

   empImage() {
      this.commonApiService.getimage(Commonvariable.Person, this.empPersonalInfo.id, "DownloadImage").subscribe((response) => {

         var fileType = response.type;
         var validImageTypes = ["image/gif", "image/jpeg", "image/png", "image/jpg", "image/bmp"];
         if ($.inArray(fileType, validImageTypes) < 0) {
            this.logoInnerHTML = "";
            this.logoInnerHTML = "<img  src='assets/layouts/layout4/img/selectPic.png' alt = ''/>";
         } else {
            if (response != null) {
               var reader = new FileReader();
               reader.readAsDataURL(response);
               reader.addEventListener("load", () => {
                  this.logoInnerHTML = "";
                  this.logoInnerHTML = "<img  src=" + reader.result + " alt = ''/>";
                  this.logoClass = "fileinput fileinput-exists";
               }, false);
            }
         }
      })
   }

   editempInfo(name, eid) {
      
      
      $(".modal").modal('hide')
      if (eid > 0) {
         this.editMode = true;
         this.editRecord = eid;
         if (name == 'CandidatePersonEquality') {
            this[name].Edit(eid);
         }
         if (name == 'EmpRelations') {
            this.empEditRelations(eid);
         }
         else {
            this[name].empEdit(eid);
         }
         if (name == 'EmpRightToWork') {
            this.EmpRightToWork.getRTWCategory(this.employeeSummary.company_Id)
         }
        // this.EmpRightToWork.getListVisaStype();
        if(name == 'EmpResignation'){
         this[name]. openModel(eid);
         this.EmpResignation. getReasionForLeaveList(event)
        }

        

      }
      else {
         this.editMode = false;
         if(name != 'EmpRelations'){
         this[name].ngOnInit();}
         if (name == 'EmpRightToWork') {
            this.EmpRightToWork.getRTWCategory(this.employeeSummary.company_Id)
         }
      }
   }
   updateNiNumber(eid) {
      let nino = (<HTMLInputElement>document.getElementById("NiNo")).value;
      let loan = (<HTMLInputElement>document.getElementById("Loan")).value;
      this.empNiNoDetailform = this.fb.group({
         Emp_Id: [eid],
         NiNo: [nino],
         Loan: [loan],
         updatedBy: [Commonvariable.User_Id],
      });
      let data = this.empNiNoDetailform.value;
      data["isReview"] = 1;
      this.commonApiService.Upsert(Commonvariable.Employee, data, eid, '/UpdateEmployeeNiNo').subscribe((result) => {
         if (result["status"] == 200) {
            this.toastr.success(Commonvariable.SaveMsg, 'Employee');
         } else {
            this.toastr.info(result["message"], 'Employee');
         }
      })
      this.getSummary();
   }
   updateEmpInfo(eid) {
      let datartw = "";
      let data = "";
      let data1 = "";
      let tableName = "";
      let modal = ""
      let id = 1;

      if (eid == 1) {
         data = this.EmpPersonalInfo.validation() ? this.EmpPersonalInfo.empBasicInfoform.value : ""
         tableName = Commonvariable.Person;
         modal = "personal-info";
         data["id"] = this.empPersonalInfo.id;
      }
      else if (eid == 2) {
         data = this.EmpAddress.validation() ? this.EmpAddress.empAddressform.value : ""
         tableName = Commonvariable.Person_Address
         modal = "address-info";
         id = data["Id"] == "0" ? 0 : 1
         data["Person_Id"] = this.empPersonalInfo.id;
      }
      else if (eid == 3) {
         datartw = this.EmpJDetail.validation() ? this.EmpJDetail.empJobDetailform.value : ""
         tableName = Commonvariable.Employee
         modal = "job-details";
         datartw["emp_Id"] = this.editid;
      }
      else if (eid == 4) {
         data = this.EmpContact.validation() ? this.EmpContact.empContactform.value : ""
         tableName = Commonvariable.Person_Contact
         modal = "emp-contact";
         id = data["id"] == "0" ? 0 : 1
         data["person_Id"] = this.empPersonalInfo.id;
      }
      else if (eid == 5) {
         data = this.EmpEmeContact.validation() ? this.EmpEmeContact.empEContactform.value : ""
         tableName = Commonvariable.Person_Emergency
         modal = "emp-eme-contact";
         id = data["id"] == "0" ? 0 : 1
         data["person_Id"] = this.empPersonalInfo.id;
      }
      else if (eid == 6) {
         
         data1 = this.EmpReference.validation() ? this.EmpReference.empform.controls.emp_ref.value : ""
         tableName = Commonvariable.EmpRefrence
         modal = "emp-ref";
         id = data1["id"] == "0" ? 0 : 1
         data1["emp_Id"] = this.editid;

        // data["person_Id"] = this.empPersonalInfo.id;
      }
      else if (eid == 7) {
         data = this.EmpBank.validation() ? this.EmpBank.empBankform.value : ""
         tableName = Commonvariable.EmpBank
         modal = "emp-bank";
         id = data["emp_Bank_Id"] == "0" ? 0 : 1
         data["emp_Id"] = this.editid;
         data["person_Id"] = this.empPersonalInfo.id;
      }
      else if (eid == 8) {
         data = this.EmpProbation.validation() ? this.EmpProbation.empform.value : ""
         tableName = Commonvariable.EmpProbation
         modal = "emp-probation";
         id = data["emp_Probation_Id"] == "0" ? 0 : 1
         data["emp_Id"] = this.editid;
      }
      else if (eid == 9) {
         data = this.EmpContract.validation() ? this.EmpContract.empform.value : ""
         tableName = Commonvariable.EmpContract
         modal = "emp-contract";
         id = data["emp_Contract_Id"] == "0" ? 0 : 1
         data["emp_Id"] = this.editid;
      }
      else if (eid == 10) {
         datartw = this.EmpRightToWork.validation() ? this.EmpRightToWork.empform.value : ""
         tableName = Commonvariable.EmpRightWork
         modal = "emp-right-to-work";
         id = datartw["rightToWork_Id"] == "0" ? 0 : 1
         datartw["emp_Id"] = this.editid;
      }
      else if (eid == 11) {
         
         data = this.EmpSalary.validation() ? this.EmpSalary.empform.value : ""
         tableName = Commonvariable.EmpSalary
         modal = "emp-salary";
         //id = data["emp_Salary_Id"] == "0" ? 0 : 1
         id = data["Emp_Salary_Id"] ==  "0" ? 0 : 1
         console.log(data["Emp_Salary_Id"])
         console.log(data["Emp_Id"])

       // id = data["emp_salary"][0]["emp_Salary_Id"] == "0" ? 0 : 1
        data["Emp_Id"] = this.editid;
      }
      else if (eid == 12) {
         data = this.CandidatePersonEquality.validation() ? this.CandidatePersonEquality.candidatePersonEqualityform.value : ""
         tableName = Commonvariable.Person_Equality
         modal = "equality_info";
         id = data["Id"] == "0" ? 0 : 1
         data["person_Id"] = this.empPersonalInfo.id;
      }
      if (data != "") {

         this.commonApiService.Upsert(tableName, data, id).subscribe((result) => {
            if (result["message"] == Commonvariable.DuplicationMsg) {
               this.toastr.warning('Record is already exists !', 'Employee');
            }
            else {

               if (this.empPersonalInfo != null) {

                  const formData = new FormData();
                  formData.append('empBasicInfo_Id', this.empPersonalInfo.id);
                  formData.append('file', this.EmpPersonalInfo.empLogo);

                  if (this.EmpPersonalInfo.empLogoChange) {
                     this.commonApiService.UploadLogo(Commonvariable.Person, "UploadImage", formData).subscribe((response) => {
                        $("#" + modal).modal('hide');
                        this.toastr.success(id == 1 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee');
                        this.getSummary()
                        if (eid == 1) {
                           this.logoInnerHTML = "";
                           this.logoInnerHTML = "<img  src='assets/layouts/layout4/img/selectPic.png' alt = ''/>";
                           this.empImage();
                        }
                     });
                  }
                  else {
                     $("#" + modal).modal('hide');
                     this.toastr.success(id == 1 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee');
                     this.getSummary()
                  }

               } else {
                  $("#" + modal).modal('hide');
                  this.toastr.success(id == 1 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee');
                  this.getSummary();
               }



            }
         })

      }
      if (datartw != "") {

         this.commonApiService.UpsertData(tableName, datartw, id).subscribe((result) => {
            if (result["message"] == Commonvariable.DuplicationMsg) {
               this.toastr.warning('Record is already exists !', 'Employee');
            }
            else {

               if (this.empPersonalInfo != null) {

                  const formData = new FormData();
                  formData.append('empBasicInfo_Id', this.empPersonalInfo.id);
                  formData.append('file', this.EmpPersonalInfo.empLogo);

                  if (this.EmpPersonalInfo.empLogoChange) {
                     this.commonApiService.UploadLogo(Commonvariable.Person, "UploadImage", formData).subscribe((response) => {
                        $("#" + modal).modal('hide');
                        this.toastr.success(id == 1 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee');
                        this.getSummary()
                        if (eid == 1) {
                           this.logoInnerHTML = "";
                           this.logoInnerHTML = "<img  src='assets/layouts/layout4/img/selectPic.png' alt = ''/>";
                           this.empImage();
                        }
                     });
                  }
                  else {
                     $("#" + modal).modal('hide');
                     this.toastr.success(id == 1 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee');
                     this.getSummary()
                  }

               } else {
                  $("#" + modal).modal('hide');
                  this.toastr.success(id == 1 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee');
                  this.getSummary();
               }



            }
         })

      }
      if (data1 != "") {
         var documents1 = this.EmpReference.empform.get('emp_ref').value;
         var formDatalength1 = documents1.length;
         for (let index = 0; index < documents1.length; index++) {
            const formData = new FormData();
            
        id=documents1[index].Emp_Reference_Id;
        formData.append('Emp_Reference_Id', documents1[index].Emp_Reference_Id);
            formData.append('Emp_Id', documents1.emp_Id);
            formData.append('DateSent', documents1[index].DateSent ? documents1[index].DateSent : '');
            formData.append('DateOfRec', documents1[index].DateOfRec ? documents1[index].DateOfRec : '');
            formData.append('Notes', documents1[index].Notes ? documents1[index].Notes : '');
            formData.append('files', documents1[index].files);
            formData.append('Required', documents1[index].Required);
            formData.append('AddedBy', Commonvariable.User_Id);
            formData.append('isActive', documents1[index].isActive ? "1" : "0");

         this.commonApiService.Upsert(tableName, formData, id).subscribe((result) => {
            if (result["message"] == Commonvariable.DuplicationMsg) {
               this.toastr.warning('Record is already exists !', 'Employee');
            }
            if (index == formDatalength1 - 1) {
               this.toastr.success(Commonvariable.SaveMsg, 'Employee');
              
            }
            
            else {

               if (this.empPersonalInfo != null) {

                  const formData = new FormData();
                  formData.append('empBasicInfo_Id', this.empPersonalInfo.id);
                  formData.append('file', this.EmpPersonalInfo.empLogo);

                  if (this.EmpPersonalInfo.empLogoChange) {
                     this.commonApiService.UploadLogo(Commonvariable.Person, "UploadImage", formData).subscribe((response) => {
                        $("#" + modal).modal('hide');
                        this.toastr.success(id == 1 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee');
                        this.getSummary()
                        if (eid == 1) {
                           this.logoInnerHTML = "";
                           this.logoInnerHTML = "<img  src='assets/layouts/layout4/img/selectPic.png' alt = ''/>";
                           this.empImage();
                        }
                     });
                  }
                  else {
                     $("#" + modal).modal('hide');
                     this.toastr.success(id == 1 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee');
                     this.getSummary()
                  }

               } else {
                  $("#" + modal).modal('hide');
                  this.toastr.success(id == 1 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee');
                  this.getSummary();
               }



            }
         })
      }

      }
   }

   Delete(eid, id) {

      let tableName = "";
      let methodName = null;
      if (eid == 2) {
         tableName = Commonvariable.Person_Address
      }
      else if (eid == 4) {
         tableName = Commonvariable.Person_Contact
      }
      else if (eid == 5) {
         tableName = Commonvariable.Person_Emergency
      }
      else if (eid == 6) {
         tableName = Commonvariable.Person_Reference
      }
      else if (eid == 7) {
         tableName = Commonvariable.EmpBank
      }
      else if (eid == 8) {
         tableName = Commonvariable.EmpProbation
      }
      else if (eid == 9) {
         tableName = Commonvariable.EmpContract
      }
      else if (eid == 10) {
         tableName = Commonvariable.EmployeeDetail
         methodName = "DeleteRightToWorkWithDoc"
      }
      else if (eid == 11) {
         tableName = Commonvariable.EmpSalary
      }
      else if (eid == 12) {
         tableName = Commonvariable.EmpDocument
      }
      else if (eid == 14) {
         tableName = Commonvariable.EmployeeRelations
      }
      this.commonService.deleteConfirm().then((result) => {
         if (result.value) {
            this.commonApiService.Delete(tableName, id, methodName).subscribe((response) => {
               if (response["status"] == 200) {
                  this.toastr.success(Commonvariable.DeleteMsg, 'Employee');
                  this.getSummary();
               }
            }, (error) => { this.toastr.error(error["error"].message, 'Employee'); })
         }
      })
   }

   history(tblName) {
      this.History.getHistory(tblName, this.editRecord);
   }

   download() {

      if (parseInt(this.employeeSummary.jD_Id) > 0) {
         
         this.commonApiService.getimage(Commonvariable.JobDescription, parseInt(this.employeeSummary.jD_Id), "DownloadDocument").subscribe((resp: any) => {
            if (resp.type != "application/json") {
               const blobData = new Blob([resp], { type: resp.type });
               var createObjectURL = (window.URL.createObjectURL(blobData));
               if (resp.type == "application/pdf" || resp.type == "image/jpeg" || resp.type == "image/png") {
                  this.fileUrlmodalJobDescription = this.sanitizer1.bypassSecurityTrustResourceUrl(createObjectURL);
                 
                   $("#modalJobDescription1").modal("show")
               } else if (resp.type == "application/vnd.ms-word") {
                  const a = document.createElement('a');
                  a.setAttribute('type', 'hidden');
                  a.href = URL.createObjectURL(blobData);
                  a.download = 'Job_Description.docx';
                  a.click();
                  a.remove();
               } else {

                  const a = document.createElement('a');
                  a.setAttribute('type', 'hidden');
                  a.href = URL.createObjectURL(blobData);
                  a.download = 'Job_Description';
                  a.click();
                  a.remove();
               }
            }
            else {
               this.commonService.toaster("warning", 'Document Not Available', 'Job Description');
            }
         });
      }
      else{
         this.commonService.toaster("warning", 'Not selected.', 'Job Description')
       }
   }

   downloadDocument(id, path) {

      this.fileName = "Employee_Document" + "." + String(path).split('.')[1]
      this.commonApiService.getimage(Commonvariable.EmpDocument, id, 'Download').subscribe((result) => {
         const blobData = new Blob([result], { type: result.type });
         //console.log(result.type);return false;
         var createObjectURL = (window.URL.createObjectURL(blobData));
         if (result.type == "application/pdf" || result.type == "image/jpeg" || result.type == "image/png") {
            this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
            $("#modalEmployeeDocument").modal("show")
         } else if (result.type == "application/vnd.ms-word") {
            const a = document.createElement('a');
            a.setAttribute('type', 'hidden');
            a.href = URL.createObjectURL(blobData);
            a.download = 'Additional_documents.docx';
            a.click();
            a.remove();
         } else {

            const a = document.createElement('a');
            a.setAttribute('type', 'hidden');
            a.href = URL.createObjectURL(blobData);
            a.download = 'Additional_documents';
            a.click();
            a.remove();

         }

      })
   }

   addDocumentsonRTW() {
      if (this.EmpRightToWork.validation()) {

         var data = this.EmpRightToWork.empform.value;

         console.log(data);
         console.log(this.EmpRightToWork.file)
         //return false;

         var method = (data["emp_Doc_Id"] == 0 || data["emp_Doc_Id"] == null) ? "AddRightToWorkWithDoc" : "EditRightToWorkWithDoc"
         var editCall = (data["emp_Doc_Id"] == 0 || data["emp_Doc_Id"] == null) ? false : true


         const formData = new FormData();
         formData.append('EmployeeDoc_Id', data.emp_Doc_Id != null ? data.emp_Doc_Id == -12 ? 0 : data.emp_Doc_Id : 0);
         // formData.append('Emp_Id', this.editid);
         // formData.append('Doc_Id', data.doc_Id);
         // formData.append('files', data.files);
         // formData.append('Emp_Doc_Name', data.files.name);
         // formData.append('Notes', data.notes);
         // formData.append('isActive', data.isActive);

         // // formData.append('AddedBy', data.addedBy);
         // formData.append('UpdatedBy', data.updatedBy);



         formData.append('Emp_Id', this.editid);
         formData.append('DocId', data.doc_Id);
         formData.append('files', this.EmpRightToWork.file);
         formData.append('RightToWork_Id', data.rightToWork_Id);
         formData.append('RWC_Id', data.rwC_Id);
         formData.append('IssueAuthority', data.issueAuthority);
         formData.append('IssueCountry', data.issueCountry);
         formData.append('IssueDate', data.issueDate);
         formData.append('ExpiryDate', data.expiryDate);
         //formData.append('Emp_Doc_Name', data.files.name);
         formData.append('Notes', data.notes);
         formData.append('isActive', data.isActive);
         formData.append('UpdatedBy', data.updatedBy);



         this.commonApiService.UploadLogo(Commonvariable.EmployeeDetail, method, formData, editCall).subscribe((response: any) => {

            if (response.data.emp_Doc_Id > 0) {

               this.EmpRightToWork.empform.controls.emp_Doc_Id.setValue(response.data.emp_Doc_Id)
               this.toastr.success(data.rightToWork_Id == 1 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee');
               $(".modal").modal('hide');
               this.getSummary();

            } else {
               this.toastr.success(response.message, 'Employee');
               return false;
            }
            $("#remove").click();
         });
         $("#emp-right-to-work").modal('hide');
         this.updateEmpInfo(10);
         this.getSummary();
         // }
         // else {
         //    this.updateEmpInfo(10);
         //    this.getSummary();
         // }



      }

   }

   addDocuments() {
      if (this.EmpDocument.validation()) {
         var data = this.EmpDocument.empform.value;
         var method = data["emp_Doc_Id"] == 0 ? "Add" : "Edit"
         var editCall = data["emp_Doc_Id"] == 0 ? false : true
         const formData = new FormData();
         formData.append('Emp_Doc_Id', data.emp_Doc_Id);
         formData.append('Emp_Id', this.editid);
         if (data.doc_Id != null) {
            formData.append('Doc_Id', data.doc_Id);
         }
         formData.append('files', data.files);
         formData.append('Emp_Doc_Name', data.emp_Doc_Name);
         formData.append('Notes', data.notes);
         formData.append('isActive', data.isActive);
         formData.append('Is_CompanyDoc', data.is_CompanyDoc);
         formData.append('AddedBy', data.addedBy);
         formData.append('UpdatedBy', data.updatedBy);
         this.commonApiService.UploadLogo(Commonvariable.EmpDocument, method, formData, editCall).subscribe((response) => {
            $("#emp-document").modal('hide');
            this.toastr.success(editCall ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee');
            this.getSummary()
         });
      }

   }

   addLeaveBalance() {
      this.commonApiService.PostRequest(Commonvariable.Leave_Balance, "AddProratedLeaveBalance", this.EmpLeave.empform.value.emp_Leave).subscribe((result) => {
         this.commonService.toaster("success", Commonvariable.SaveMsg, "Employee Leave");
         this.getSummary();
         $("#EmpLeave").modal('hide');
      })
   }
  
   validationRelations() {
      
      
      var formData = this.empRelationsform;
      formData.controls.emp_Id.setValue(this.employeeSummary.emp_Id);
      formData.controls.live_Date.setValue(this.commonService.dateFormatChange($("#LiveDate").val()));
      formData.controls.exp_Date.setValue(this.commonService.dateFormatChange($("#ExpDate").val()));
      
if(this.empmanagerList.length > 0){
   let selectedmanagerobject = this.empmanagerList.find(item => item.text === $("#issuing_Manger_Emp_Id").val());
   let selectedmanagerId: number | undefined = selectedmanagerobject ? selectedmanagerobject.id : undefined;
   formData.controls.issuing_Manger_Emp_Id.setValue(selectedmanagerId);
}
else{
   let selectedmanagerobject = this.empList.find(item => item.text === $("#issuing_Manger_Emp_Id").val());
   let selectedmanagerId: number | undefined = selectedmanagerobject ? selectedmanagerobject.id : undefined;
   formData.controls.issuing_Manger_Emp_Id.setValue(selectedmanagerId);

}
     
      if (formData.invalid) {
         for (const key of Object.keys(formData.controls)) {
            if (formData.controls[key].invalid) {
               let fieldName = key;
               this.toastr.info(fieldName + " is required !", "Employee");
               $("#" + key).focus();
               return false;
            }
         }

      }
      else {
         if (moment($("#LiveDate").val(), 'DD-MM-YYYY') > moment($("#ExpDate").val(), 'DD-MM-YYYY')) {
            this.toastr.info("Live date not more then Expiry date !", "Employee Relations");
            $("#LeaveDate").focus();
            return false;
          }

      }
      this.commonService.setNull(formData);
      return true;
   }
   empRelationscloset(){
      $("#EmpRelations").modal('hide');
      $("#LiveDate").val("");
      $("#ExpDate").val("");
      $("#issuing_Manger_Emp_Id").val("");
      $("#current_Sanction_Id").val("");
      $("#sanction_Description_Id").val("");
      $("#current_Sanction_Id").prop("disabled", false);
      $("#LiveDate").prop("disabled", false);
      $("#issuing_Manger_Emp_Id").prop("disabled", false);
     // this.empRelationsform.reset();
     this.empmanagerList=[];
      this.empRelationsform.controls.addedBy.setValue(Commonvariable.User_Id);
      this.empRelationsform.controls.updatedBy.setValue(0);
      this.empRelationsform.controls.isActive.setValue(1);

   }

   empRelationsUpsert() {
     
     
      if (this.validationRelations()) {
         
           
           if (this.empRelationsform.get('Id').value == null) {
            this.empRelationsform.get('Id').patchValue(0);
        }
        let postRequest = this.empRelationsform.value;
        
            this.commonApiService.Upsert(Commonvariable.EmployeeRelations, postRequest,this.empRelationsform.controls.Id.value).subscribe((response) => {
              if (response != null) {
               if (response["message"] == Commonvariable.DuplicationMsg) {
                  this.toastr.warning('Record is already exists !', 'Employee Relations');
                }
                else {
                  // $("#draggable").modal('hide');
                  $("#EmpRelations").modal('hide');
                  $("#LiveDate").val("");
                  $("#ExpDate").val("");
                  $("#sanction_Description_Id").val("");
                  $("#issuing_Manger_Emp_Id").val("");
                  $("#current_Sanction_Id").val("");
                  $("#current_Sanction_Id").prop("disabled", false);
                  $("#LiveDate").prop("disabled", false);
                  $("#issuing_Manger_Emp_Id").prop("disabled", false);
                  setTimeout(() => {
                    // this.empRelationsform.reset(); 
                    this.empmanagerList=[];
                  
                    this.empRelationsform.controls.issuing_Manger_Emp_Id.setValue("");
                    this.empRelationsform.controls.sanction_Description.setValue("");
                     this.empRelationsform.controls.addedBy.setValue(Commonvariable.User_Id);
                     this.empRelationsform.controls.updatedBy.setValue(0);
                     this.empRelationsform.controls.isActive.setValue(1);
                  }, 100);
                  
                  this.toastr.success(this.empRelationsform.controls.Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee Relations');
                  this.getSummary();
                }
              }
            })
          
        
         //return this.empRelationsform.value;
      }
      return "";
   }
   empEditRelations(Id) {
      if (Id > 0) {
         
         this.editMode = true;
         $("#current_Sanction_Id").prop("disabled", true);
         $("#LiveDate").prop("disabled", true);
         $("#issuing_Manger_Emp_Id").prop("disabled", true);
         
        this.commonApiService.getById(Commonvariable.EmployeeRelations, Id).subscribe((response) => {
          if (response != null && response["data"] != "") {
            var editData = response["data"];
            var formData = this.empRelationsform;
           
            let empExpiryDate = response["data"][0].exp_Date != null ? response["data"][0].exp_Date.split("T")[0].split("-") : "";
            if (empExpiryDate != "") {
              $("#ExpDate").val(empExpiryDate[2] + "-" + empExpiryDate[1] + "-" + empExpiryDate[0]);
            }
            let empLiveDate = response["data"][0].live_Date != null ? response["data"][0].live_Date.split("T")[0].split("-") : "";
            if (empLiveDate != "") {
              $("#LiveDate").val(empLiveDate[2] + "-" + empLiveDate[1] + "-" + empLiveDate[0]);
            }
            this.commonService.setEditData(formData, editData);
            let selectedmanagerobject = this.empList.find(item => item.id === formData.controls.issuing_Manger_Emp_Id.value);
            let selectedmanagername: number | undefined = selectedmanagerobject ? selectedmanagerobject.text : undefined;
            formData.controls.issuing_Manger_Emp_Id.setValue(selectedmanagername);
          
            this.empRelationsform.controls.Id.setValue(editData[0].id);
            this.empRelationsform.controls.updatedBy.setValue(Commonvariable.User_Id);
            this.empRelationsform.controls.addedBy.setValue(Commonvariable.User_Id);
            
          }
        })
      }
    }

}
