<!-- <form [formGroup]="empform">
    <div class="row">
        <div class="col-md-12 text-right">
            <div class="form-group">
                <div class="md-checkbox-inline">
                    <label class="control-label">Not Required &nbsp;&nbsp;</label>
                    <div class="md-checkbox">
                        <input type="checkbox" id="notRequired" class="md-check" [formControl]="notRequired">
                        <label for="notRequired">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> </label>
                    </div>
                </div>
                <hr/>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="table-responsive">
            <table class="table table-hover" id="work" style="position: relative;" role="grid">
                <thead>
                    <tr>
                        <th style="width: 15%;">Right To Work Document </th>
                        <th style="width: 15%;">Date of Issue </th>
                        <th style="width: 15%;">Authority </th>
                        <th style="width: 15%;">Country of Issue </th>
                        <th style="width: 15%;">Date of Expiry </th>
                        <th style="width: 2%;">Active</th>
                        <th style="width: 5%;"><button *ngIf="this.empform.get('emp_righttowork').value[0].IssueAuthority != ''" type="button" (click)="addItem(null)" class="btn pull-right"><i
                                    class="fa fa-plus" aria-hidden="true"></i></button></th>
                    </tr>
                </thead>
                <tbody formArrayName="emp_righttowork" *ngFor="let item of empform.get('emp_righttowork')['controls']; let i = index;">
                    <tr [formGroupName]="i">
                        <td>




                            <div class="input-group">
                                <select class="form-control" id="RightToworkCategory{{i}}" formControlName="RWC_Id" (change)="setId($event)">
                                    <option value="0">--- Select ---</option>
                                    <option *ngFor="let item of rtwCategoryList;" value={{item.rwC_Id}}>{{item.name}}</option>
                                </select>
                                <span class="input-group-btn">
                                    <button class="btn dark btn-outline input-group-btn-input-grp" type="button" (click)="modalOpen =true">
                                        <i class="fa fa-{{this.empform.get('emp_righttowork').value[i].RWC_Id != '0' ? 'edit' : 'plus'}}"></i>
                                   </button>
                                </span>
                            </div>



                        </td>
                        <td>
                            <div class="input-group" id="flatpickropenIssueD{{i}}" data-wrap="true">
                                <input type="text" class="form-control readonlyClass" id="IssueDate{{i}}" data-input>
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </td>
                        <td>
                            <input type="text" class="form-control" id="IssueAuthority{{i}}" formControlName="IssueAuthority" autocomplete="off" maxlength="95">
                        </td>
                        <td>
                            <input type="text" class="form-control" id="IssueCountry{{i}}" formControlName="IssueCountry" autocomplete="off" maxlength="95">
                        </td>
                        <td>
                            <div class="input-group" id="flatpickropenExpiryD{{i}}" data-wrap="true">
                                <input type="text" class="form-control readonlyClass" id="ExpiryDate{{i}}" data-input>
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="empright{{i}}" class="md-check" formControlName="isActive">
                                    <label for="empright{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td>
                        <td>
                            <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                                    class="fa fa-minus" aria-hidden="true"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</form> -->

<!-- <div *ngIf="modalOpen" class="row">
    <div class="col-lg-12">
        <app-righttowork-form [pId]="id" (modalClose)="modalOpen = $event" (refreshPageForEmp)="getRTWList($event)"></app-righttowork-form>
    </div>

</div> -->
<div class="row">

    <div class="col-md-12 text-right">

        <div class="form-group">

            <div class="md-checkbox-inline">

                <label class="control-label">Not Required &nbsp;&nbsp;</label>

                <div class="md-checkbox">

                    <input type="checkbox" id="notRequired" class="md-check" [formControl]="notRequired">

                    <label for="notRequired">

                        <span></span>

                        <span class="check"></span>

                        <span class="box"></span> </label>

                </div>

            </div>

            <hr />

        </div>

    </div>

</div>

<form [formGroup]="empform">
    <div formArrayName="emp_righttowork">
        <div *ngFor="let item of empform.get('emp_righttowork')['controls']; let i = index">
            <div [formGroupName]="i">

                <div class="row form-group">
                    
                    <div class="col-md-4">

                        <label class="form-label ">Nationality

                            <span class="required"> * </span>

                        </label>
                     
                        <ng-select2
                        [data]="nationalityList"
                        id="LstNationality_Id"
                        [options]="{ multiple: false, tags: true }"
                        [value]="item.get('LstNationality_Id').value"
                        [placeholder]="'--- Select ---'"
                        formControlName="LstNationality_Id"
                        (valueChanged)="onValueChange($event)"
                      >
                      </ng-select2>

                      <!-- (valueChanged)="setFormData('LstNationality_Id', $event)" -->

                    </div>

                    <div class="col-md-4">

                        <label class="form-label">ECS Status
                            <span class="required" *ngIf="isrequiredclass"> * </span>
                        </label>

                        <select class="form-control" id="ECSStatus{{i}}" (change)="ECSStatusData($event)" formControlName="ECSStatus">

                            <option value="null">ECS Status</option>

                            <option value="Requested">Requested</option>

                            <option value="Received">Received</option>

                            <option value="N/A">N/A</option>

                        </select>

                    </div>

                    <div class="col-md-4">

                        <label class="form-label">ECS Received
                            <span class="required" *ngIf="isrequiredforECS"> * </span>
                        </label>

                        <div class="input-group DOB" id="flatpickropenecsRec{{i}}" data-wrap="true">

                            <input type="text" class="form-control datePicker readonlyClass" id="ECSReceivedDate{{i}}"
                                autocomplete="off" data-input formControlName="ECSReceivedDate" />

                            <span class="input-group-btn" data-toggle>

                                <button class="btn default form-control datePicker" type="button">

                                    <i class="fa fa-calendar"></i>

                                </button>

                            </span>

                        </div>

                    </div>

                </div>



                <div class="row form-group">

                    <div class="col-md-4">

                        <label class="form-label">ECS Expiry Date
                            <span class="required" *ngIf="isrequiredforECS"> * </span>
                        </label>

                        <div class="input-group" id="flatpickropenecsED{{i}}" data-wrap="true">

                            <input type="text" class="form-control readonlyClass" id="ECSExpiryDate{{i}}"
                                data-input formControlName="ECSExpiryDate">

                            <span class="input-group-btn" data-toggle>

                                <button class="btn default" type="button">

                                    <i class="fa fa-calendar"></i>

                                </button>

                            </span>

                        </div>

                    </div>

                    <div class="col-md-4">

                            <label class="control-label">RTW DocumentType
                                <span class="required" *ngIf="isrequiredclass"> * </span>
                            </label>

                            <select class="form-control" id="RWC_Id{{i}}" formControlName="RWC_Id">

                                <option value="">Select RTW DocumentType</option>
                                <ng-container *ngIf="rtwCategoryList && rtwCategoryList.length > 0">
                                    <option *ngFor="let item of rtwCategoryList" [value]="item.rwC_Id">{{item.name}}</option>
                                </ng-container>

                            </select>
                    </div>

                    <div class="col-md-2">

                        <label class="form-label">RTW - Visa/Status Type
                            <span class="required" *ngIf="isrequiredclass" > * </span>
                        </label>

                        <select id="RTW_VisaStatus_Id{{i}}" class="form-control" (change)="toggleCommentsTextarea($event)" formControlName="RTW_VisaStatus_Id">
                           
                            <ng-container *ngIf="rtwVisaStaList && rtwVisaStaList.length > 0">
                                <option value="0">Select RTW - Visa/Status Type</option>
                                <option *ngFor="let item of rtwVisaStaList" [value]="item.id">{{item.name}}</option>
                                
                            </ng-container>
                        </select>

                    </div>

                    <div class="col-md-2"  id="Others"  *ngIf="isDivHidden">
                        <label>Other<span class="required" *ngIf="isrequiredComclass"> * </span></label>
                        <textarea cols="55" class="form-control" id="Other" formControlName="Other"></textarea>
                    </div>
                    <!-- <div class="col-md-2"  *ngIf="isDivHidden[i]">

                        <input type="text" class="form-control readonlyClass" id="Other{{i}} " style="display: none;"
                            data-input formControlName="Other"*ngIf="isrequiredComclass[i]">

                    </div> -->
                    <!-- <div class="col-md-2" id="Others"  *ngIf="isDivHidden[i]">
                        <label>Other<span class="required" *ngIf="isrequiredComclass[i]"> * </span></label>
                        <textarea cols="55" class="form-control" id="Other" formControlName="Other"></textarea>
                    </div> -->

                    <!-- </div> -->

                </div>

                <div class="row form-group">
                    <div class="col-md-4">

                        <label class="form-label">RTW - Visa/Status Expiry Date
                            <span class="required" *ngIf="isnotrequiredComclass"> * </span>
                        </label>

                        <div class="input-group" id="flatpickropenrtwVSED{{i}}" data-wrap="true">

                            <input type="text" class="form-control readonlyClass" id="RTW_VisaStatus_ExpiryDate{{i}}" data-input
                                formControlName="RTW_VisaStatus_ExpiryDate">

                            <span class="input-group-btn" data-toggle>

                                <button class="btn default" type="button">

                                    <i class="fa fa-calendar"></i>

                                </button>

                            </span>

                        </div>

                    </div>


                    <div class="col-md-4">

                        <label class="form-label">Passport Expiry Date
                            <span class="required" *ngIf="isrequiredclass"> * </span>
                        </label>

                        <div class="input-group" id="flatpickropenpassportED{{i}}" data-wrap="true">

                            <input type="text" class="form-control readonlyClass" id="PassportExpiryDate{{i}}" data-input
                                formControlName="PassportExpiryDate">

                            <span class="input-group-btn" data-toggle>

                                <button class="btn default" type="button">

                                    <i class="fa fa-calendar"></i>

                                </button>

                            </span>

                        </div>

                    </div>

 

                </div>

               
                <div class="row form-group">

                    <div class="col-md-4">

                        <label class="form-label">Sponsored Employee
                            <!-- <span class="required" *ngIf="isrequiredclass"> * </span> -->
                        </label>

                        <div class="md-checkbox-inline">

                            <div class="md-checkbox">

                                <input type="checkbox" id="isSponsoredEmployee{{i}}" class="md-check"
                                    formControlName="isSponsoredEmployee" (click)="chekboxclickyes($event)">

                                <label for="isSponsoredEmployee{{i}}">

                                    <span></span>

                                    <span class="check"></span>

                                    <span class="box"></span> </label>

                            </div>

                        </div>

                    </div>


                    <div class="col-md-4">

                        <label class="form-label">Sponsorship Expiry Date
                            <span class="required" *ngIf="isrequiredclassspo"> * </span>
                        </label>

                        <div class="input-group" id="flatpickropensponsershipED" data-wrap="true">

                            <input type="text" class="form-control readonlyClass" id="SponsoredExpiryDate"
                                data-input formControlName="SponsoredExpiryDate">

                            <span class="input-group-btn" data-toggle>

                                <button class="btn default" type="button">

                                    <i class="fa fa-calendar"></i>

                                </button>

                            </span>

                        </div>

                    </div>



                    <div class="col-md-4">

                        <label class="form-label">Comments
                            <!-- <span class="required" *ngIf="isrequiredclass"> * </span> -->
                        </label>

                        <textarea class="form-control" id="Comments{{i}}" formControlName="Comments" maxlength="200"></textarea>

                    </div>

                    

                </div>

            </div>

        
        
        </div>
            

    </div>
   
</form>

<div *ngIf="modalOpen" class="row">
    <div class="col-lg-12">

        <app-righttowork-form [pId]="id" (modalClose)="modalOpen = $event"
            (refreshPageForEmp)="getRTWList($event)"></app-righttowork-form>

    </div>
</div>



