<div class="modal fade draggable-modal in" id="draggable" role="basic" aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="closeModal()" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Department</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="departmentform">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="">
                                <label class="control-label">Company<span class="required"> * </span></label>&nbsp;
                                <ng-select2 *ngIf="title != 'Edit'" [data]="companylist" id="company_Id" formControlName="company_Id" [placeholder]="'--- Select ---'" [options]="{multiple: true,tags: false}" [disabled]="title == 'Edit'?true:false">
                                </ng-select2>
                                <input *ngIf="title == 'Edit'" type="text" id="company_Name" class="form-control" formControlName="company_Name" readonly>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="">
                                <label class="control-label">Department Code<span class="required"> *
                                    </span></label>&nbsp;
                                <label class="text-danger" *ngIf="formControls.dept_Code.invalid && formControls.dept_Code.errors.required && checkValidation">This
                                    field is required</label>
                                <input type="text" id="dept_Code" class="form-control" formControlName="dept_Code" autocomplete="off" maxlength="30">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="">
                                <label class="control-label">Department Name<span class="required"> *
                                    </span></label>&nbsp;
                                <label class="text-danger" *ngIf="formControls.dept_Name.invalid && formControls.dept_Name.errors.required && checkValidation">This
                                    field is required</label>
                                <input type="text" id="dept_Name" class="form-control" formControlName="dept_Name" autocomplete="off" maxlength="30">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="">
                                <label class="control-label">Notes</label>
                                <textarea class="form-control" id="notes" rows="1" formControlName="notes" style="max-width: 100%;"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-5">
                            <label class="control-label">Active</label>
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="isActive" class="md-check" formControlName="isActive">
                                    <label for="isActive">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn  dark btn-outline pull-left" (click)="history()" *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button>
                <button type="button" (click)="closeModal()" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="departmentUpsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Department History</h4>
            </div>
            <div class="modal-body" *ngIf="title == 'Edit'">
                <app-history></app-history>
            </div>
        </div>
    </div>
</div>