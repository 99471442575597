<form [formGroup]="empform">
    <div class="form-group">
        <div class="table-responsive">
            <table class="table table-hover" id="work">
                <thead>
                    <tr>
                        <th style="width: 25%;">Emergency Contact Name <span class="required"
                                *ngIf="this.empform.get('emp_Contact').value[0].ContactName != '' || this.empform.get('emp_Contact').value.length > 1">
                                * </span></th>
                        <th style="width: 25%;">Emergency Contact Number <span class="required"
                                *ngIf="this.empform.get('emp_Contact').value[0].ContactName != '' || this.empform.get('emp_Contact').value.length > 1">
                                * </span> </th>
                        <th style="width: 25%;">Relationship <span class="required"
                                *ngIf="this.empform.get('emp_Contact').value[0].ContactName != '' || this.empform.get('emp_Contact').value.length > 1">
                                * </span></th>
                        <!-- <th style="width: 2%;">Default</th> -->
                        <th style="width: 2%;">Active</th>
                        <th style="width: 5%;"><button
                                *ngIf="this.empform.get('emp_Contact').value[0].ContactName != ''" type="button"
                                (click)="addItem(null)" class="btn pull-right"><i class="fa fa-plus"
                                    aria-hidden="true"></i></button></th>
                    </tr>
                </thead>
                <tbody formArrayName="emp_Contact"
                    *ngFor="let item of empform.get('emp_Contact')['controls']; let i = index;">
                    <tr [formGroupName]="i">
                        <td>
                            <input type="text" class="form-control" id="ContactName{{i}}" formControlName="ContactName"
                                autocomplete="off" maxlength="95">
                        </td>
                        <td>
                            <div class="input-group mobilenowithcountryode">
                                <div class="input-group-btn">
                                    <ng-select2 [data]="countryList" id="country_Id{{i}}" formControlName="country_Id"
                                        [placeholder]="'--- Select ---'">
                                    </ng-select2>
                                </div>

                                <input type="text" class="form-control" id="ContactNo{{i}}" formControlName="ContactNo"
                                    autocomplete="off" maxlength="20"
                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.key === "Backspace" ||  event.charCode == 40 
                           || event.charCode == 32 || event.charCode == 41 || event.charCode == 43 || event.charCode == 45'>
                            </div>
                        </td>
                        <td>

                            <!-- <input list="browsers" id="Relationship{{i}}"  class="form-control"
                                (change)="addRelationship($event)" autocomplete="off" formControlName="Relationship" maxlength="30">
                            <datalist id="browsers">
                                <option *ngFor="let item of relList;" value={{item.name}}>
                                </option>
                            </datalist> -->
                            <select class="form-control" id="Relationship{{i}}" formControlName="Relationship_Id">
                                <option value="">Select Relationship</option>
                                <option *ngFor="let item of relList;" value={{item.id}}>
                                    {{item.name}}</option>
                            </select>
                        </td>
                        <!-- <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="Default{{i}}" class="md-check"
                                        formControlName="isDefault">
                                    <label for="Default{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td> -->
                        <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="Active{{i}}" class="md-check" formControlName="isActive">
                                    <label for="Active{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td>
                        <td>
                            <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                                    class="fa fa-minus" aria-hidden="true"></i></button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</form>