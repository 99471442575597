<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Payroll List
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav">
                <li>
                    <div class="col-md-6">
                        <div class="input-group input-xlarge">

                            <input type="text" id="filterValue" class="form-control"
                                placeholder="Filter By Joining Date">
                            <span class="input-group-btn">
                                <button class="btn blue" (click)="filterDateWise()" type="button">Go!</button>
                                <button class="btn btn-default" (click)="clear()" title="Clear Date" type="button"><i
                                        class="fa fa-refresh"></i></button>
                            </span>
                        </div>
                    </div>
                    <div>
                        <a [href]="fileUrl" id="download" class="btn btn-transparent blue  btn-sm"
                            style="visibility: hidden;" download="payroll.csv">Export</a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <!-- <div class="col-md-1">
                            <div class="form-group">
                                <button class="btn blue"><i class="fa fa-download"></i> Download</button>
                            </div>
                        </div> -->
                        <div class="col-md-3">
                            <div class="form-group">
                                    <select class="form-control" #statusSelect id="status"
                                        (change)="onChangeStatus(statusSelect.value)">
                                        <option value="Open">Open</option>
                                        <option value="Completed">Completed</option>
                                        <option value="All">All</option>
                                    </select>
                            </div>
                        </div>
                        <!-- <label class="control-label">Search</label> -->
                        <div class="col-md-3 text-right" style="float:right;">

                            <div class="input-group">

                                <input type="text" class="form-control" id="searchFilter"
                                    placeholder="Type to filter the company column..." (change)="filterData($event)">
                                <span class="input-group-btn">
                                    <button class="btn btn-default" (click)="clearSearch()" title="Clear Search"
                                        type="button"><i class="fa fa-refresh"></i></button>
                                </span>
                            </div>
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-sm  table-striped table-bordered table-hover" id="tbl">
                            <thead class="flip-content">
                                <tr>

                                    <th style="width: 10%;" class="text-center">

                                        <diV class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">

                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">

                                                    <div class="md-checkbox-inline" title="Select All To Download">
                                                        <div class="md-checkbox">
                                                            <input type="checkbox" (click)="checkBoxAll(0,'all')"
                                                                id="checkall" class="md-check">
                                                            <label for="checkall">
                                                                <span></span>
                                                                <span class="check"></span>
                                                                <span class="box"></span> </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-md-1">
                                                <div class="form-group">


                                                    <!-- <label class="control-label col-md-6">Select All</label> -->
                                                    <div class="form-group">
                                                        <button class="btn blue" title="Download"
                                                            (click)="getallIds()"><i
                                                                class="fa fa-download"></i></button>
                                                    </div>

                                                </div>
                                            </div>


                                        </diV>


                                    </th>
                                    <th style="width: 15%;padding: 22px;" class="text-center"> Name </th>
                                    <th style="padding: 22px;" class="text-center"> Company </th>
                                    <th style="width: 20%;padding: 22px; " class="text-center"> Department </th>
                                    <th style="width: 10%;padding: 22px;" class="text-center"> Designation </th>
                                    <th style="width: 10%; padding: 22px;" class="text-center"> Joining Date </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of module| paginate: { itemsPerPage: 10, currentPage: p };">
                                    <td class="text-center">
                                        <div class="form-group">
                                            <div class="md-checkbox-inline">
                                                <div class="md-checkbox">
                                                    <input type="checkbox" id="selectall{{item.id}}" class="md-check"
                                                        (click)="checkBoxAll(item.id)" [checked]="item.select">
                                                    <label for="selectall{{item.id}}">
                                                        <span></span>
                                                        <span class="check"></span>
                                                        <span class="box"></span> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="padding: 17px;">{{item.title}} {{item.firstName}} {{item.lastName}}</td>
                                    <td class="text-center" style="padding: 17px;">{{item.company_Name}}</td>
                                    <td class="text-center" style="padding: 17px;">{{item.dept_Name}}</td>
                                    <td class="text-center" style="padding: 17px;">{{item.jobTitle}}</td>
                                    <td class="text-center" style="padding: 17px;">{{item.workStartDate.split(" ")[0]}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="payrolllist != null">
                        <pagination-controls (pageChange)="p = $event" autoHide="true"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->


<!-- <div class="modal fade draggable-modal" id="draggable" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Zone</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="zoneform">
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Company<span class="required"> *
                                        </span></label>
                                    <select id="company_Id" class="form-control" formControlName="company_Id">
                                        <option value="">Select Your Company</option>
                                        <option *ngFor="let company of companylist;" value={{company.company_Id}}>
                                            {{company.company_Name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Zone Code<span class="required"> *
                                        </span></label>
                                    <input type="text" id="zone_Code" class="form-control" formControlName="zone_Code">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Zone Name<span class="required"> *
                                        </span></label>
                                    <input type="text" id="zone_Name" class="form-control" formControlName="zone_Name">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Active</label>
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="checkbox1_3" class="md-check"
                                                formControlName="isActive">
                                            <label for="checkbox1_3">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="zoneUpsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div> -->