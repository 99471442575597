import { Component, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;


@Component({
  selector: 'app-changePassword',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  changePassword() {
    if (this.validation()) {


      var postRequest = {
        "user_Id": Commonvariable.User_Id,
        "oldPassword": $("#oldpassword").val(),
        "newPassword": $("#newpassword").val()
      }

      this.commonApiService.putWithParameter("User", postRequest, "ChangePassword").subscribe((response) => {
        if (response != null) {
          this.toastr.success("Password Change Successfully !", 'Change Password');
          localStorage.clear();
          this.router.navigateByUrl('dashboard');
        }
      })
    }
  }

  validation() {
    if ($("#oldpassword").val() == "") {
      this.toastr.info("Old password is required !", "Change Password");
      $("#oldpassword").focus();
      return false;
    }
    if ($("#newpassword").val() == "") {
      this.toastr.info("New password is required !", "Change Password");
      $("#newpassword").focus();
      return false;
    }
    if ($("#confirmPassword").val() == "") {
      this.toastr.info("Confirm password is required !", "Change Password");
      $("#confirmPassword").focus();
      return false;
    }
    if ($("#newpassword").val().length < 8) {
      this.toastr.info("New password must be at least 8 characters long !", "Change Password");
      $("#newpassword").focus();
      return false;
    }
    if ($("#confirmPassword").val() != $("#newpassword").val()) {
      this.toastr.info("New password and confirm password doesn't match !", "Change Password");
      $("#confirmPassword").focus();
      return false;
    }
    return true;
  }


}
