import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;

@Component({
  selector: 'app-righttowork-form',
  templateUrl: './righttowork-form.component.html'
})
export class RightToworkFormComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  rwform: FormGroup;
  companylist: Array<Select2OptionData>;
  title: string;
  @Input() pId?: any;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshPage: EventEmitter<number> = new EventEmitter();
  @Output() refreshPageForEmp: EventEmitter<number> = new EventEmitter();


  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private fb: FormBuilder) { }



  ngOnInit() {
    document.body.className = "modal-open";

    this.title = "Add";

    this.rwform = this.fb.group({
      rwC_Id: ['0'],
      company_Id: [''],
      company_Name: [''],
      category: [null],
      name: ['', Validators.required],
      description: [''],
      notes: [''],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });



    this.getCompanyList();

    if (this.pId > 0) {
      this.editRecord(this.pId);
    }


  }




  getCompanyList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "company_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }




  Upsert() {
    var formData = this.rwform;

    if (this.validation()) {
      formData.get("isActive").setValue(formData.get("isActive").value ? 1 : 0);
      formData.get("name").setValue(formData.get("name").value);

      this.commonApiService.UpsertWithMultiCompany(Commonvariable.RightToWork_Category, formData.value, formData.controls.rwC_Id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.commonService.toaster("warning", 'Record is already exists !');
          }
          else {
            this.closeModal();
            this.refreshPageForEmp.emit(parseInt(localStorage.getItem("employeeHRCompanyId")));
            this.refreshPage.emit(1);
            this.commonService.toaster('success', formData.controls.rwC_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg);
          }
        }
      })
    }

  }

  editRecord(id) {

    if (id > 0) {
      this.title = "Edit";
      this.commonApiService.getByIdNew(Commonvariable.RightToWork_Category, id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var formData = this.rwform;
          this.commonService.setEditData(formData, editData);
          $("#company_Id").val(editData[0]['company_Name']);
        }
      })

    }
  }




  validation() {
    var zoneForm = this.rwform;
    if (!zoneForm.valid) {

      for (const key of Object.keys(zoneForm.controls)) {
        if (zoneForm.controls[key].invalid) {
          let fieldName = "";

          if (key == "name") {
            fieldName = "Type of Document"
            this.commonService.toaster("info", fieldName + " is required !");
            $("#" + key).focus();
            return false;
          }

        }
      }
    }
      if ($("#name").val().length < 2) {
        this.commonService.toaster("info", "Type of document must be at least 2 characters long !");
        $("#name").focus();
        return false;
      }
      if (/["\\]/.test($("#name").val())) {
        this.commonService.toaster("info", "Type of document does not allow Special Characters \" \\ ");
        $("#name").focus();
        return false;
      }
      if (/["\\]/.test($("#description").val())) {
        this.commonService.toaster("info", "Description does not allow Special Characters \" \\ ");
        $("#description").focus();
        return false;
      }
      if (/["\\]/.test($("#notes").val())) {
        this.commonService.toaster("info", "notes does not allow Special Characters \" \\ ");
        $("#notes").focus();
        return false;
      }
    return true;
  }




  closeModal() {
    this.modalClose.emit(false)
  }

  history() {
    this.historyView.getHistory("righttowork_category", this.rwform.value.rwC_Id)
  }

}


