<div class="row">
    <div class="col-md-12">
        <div class="form-group">
            <label class="control-label">To</label>
            <input type="text" id="to" class="form-control" autocomplete="off" placeholder="To">
        </div>
    </div>
    <div class="col-md-6">
        <div class="form-group">
            <label class="control-label">Cc</label>
            <input type="text" id="cc" class="form-control" autocomplete="off" placeholder="Cc">
        </div>
    </div>
    <div class="col-md-6">
        <div class="form-group">
            <label class="control-label">Bcc</label>
            <input type="text" id="bcc" class="form-control" autocomplete="off" placeholder="Bcc">
        </div>
    </div>
    <div class="col-md-6">
        <div class="form-group">
            <label class="control-label">Subject</label>
            <input type="text" id="subject" class="form-control" autocomplete="off" placeholder="Subject">
        </div>
    </div>
    <div class="col-md-6" *ngIf="roleDisplay">
        <div class="form-group">
            <label class="control-label">Role</label>
            <ng-select2 [data]="roleList"    [options]="options" [value]="roleIdValue" (valueChanged)="setData($event)" [placeholder]="'--- Select ---'"></ng-select2>
        </div>
    </div>
    <div class="col-md-12">
        <div class="form-group">
            <div id="emailsend"></div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="fileinput fileinput-new" data-provides="">
            <div class="input-group input-large docinbox">
                <div class="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                    <i class="fa fa-file fileinput-exists"></i>&nbsp;
                    <span class="fileinput-filename"></span>
                    <span class="fileinput-name" >{{documentName}}</span>
                </div>
                <span class="input-group-addon btn default btn-file">
                    <span class="fileinput-new"> Select file </span>
                    <span class="fileinput-exists"> Change </span>
                    <input type="file" id="files" name="files[]" (change)="fileChange($event)" multiple="multiple">
                </span>
                <a id="remove" class="input-group-addon btn red fileinput-exists" data-dismiss="fileinput"> Remove </a>
            </div>
            <br>
            <strong>Note :- </strong> <label> Press Ctrl to select multiple files.</label>
        </div>
    </div>
</div>
