import { Component, OnInit, ViewChild, ɵSWITCH_VIEW_CONTAINER_REF_FACTORY__POST_R3__ } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';

declare var $: any;


@Component({
  selector: 'app-sendmail',
  templateUrl: './sendmail.component.html'
})
export class SendMailComponent implements OnInit {
  documentName = "Attach File";
  docFiles: any;
  roleList: Array<Select2OptionData>;
  roleDisplay: boolean = false;
  roleId: any;
  roleIdValue: any;
  options: any;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.getRole()
    this.options = {
      multiple: true,
    };

    $('#emailsend').summernote();
  }

  getRole() {
    this.commonApiService.List(Commonvariable.Roles).subscribe((response) => {
      if (response["data"] != "") {
        this.roleList = response["data"].map(x => ({
          id: x.id,
          text: x.name
        }));
      }
    })
  }

  fileChange(event) {
    this.documentName = "";
    this.docFiles = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.documentName += this.documentName == "" ? event.target.files[i].name : ", " + event.target.files[i].name;
      this.docFiles.push(event.target.files[i]);
    }
  }

  setData(id) {
    this.roleIdValue = id;
    this.roleId = String(id)
  }

  validation() {
    if ($("#to").val() == "") {

      this.commonService.toaster("info", "Mail to is required !", "SendMail");
      $("#to").focus();
      return false;
    }


    return true
  }



}
