<!-- BEGIN HEADER -->
<style>
	::-webkit-scrollbar {
		width: 1px;
	}

	@media (min-width: 992px) {
		.modal-lg-usercreation {
			width: 1225px !important;
		}
	}	
</style>

<div class="page-header navbar navbar-fixed-top">
	<!-- BEGIN HEADER INNER -->
	<div class="page-header-inner ">
		<!-- BEGIN LOGO -->
		<div class="page-logo">
			<a routerLink="/dashboard" *ngIf="!isCandidate">
				<img src="assets/layouts/layout4/img/B&S People_App-logo_2023.png" alt="logo" class="logo-default" />
			</a>
			<a *ngIf="isCandidate">
				<img src="assets/layouts/layout4/img/B&S People_App-logo_2023.png" alt="logo" class="logo-default" />
			</a>
			<div class="menu-toggler sidebar-toggler">
				<!-- DOC: Remove the above "hide" to enable the sidebar toggler button on header -->
			</div>
		</div>
		<!-- END LOGO -->
		<!-- BEGIN RESPONSIVE MENU TOGGLER -->
		<a href="javascript:;" class="menu-toggler responsive-toggler" data-toggle="collapse"
			data-target=".navbar-collapse"> </a>
		<!-- END RESPONSIVE MENU TOGGLER -->
		<!-- BEGIN PAGE TOP -->

		<div class="page-top">
			<!-- BEGIN HEADER SEARCH BOX -->
			<!-- DOC: Apply "search-form-expanded" right after the "search-form" class to have half expanded search box -->
			<!-- <form class="search-form" action="#" method="GET">
				<div class="input-group">
					<input type="text" class="form-control input-sm" placeholder="Search..." name="query">
					<span class="input-group-btn">
						<a class="btn submit">
							<i class="icon-magnifier"></i>
						</a>
					</span>
				</div>
			</form> -->
			<!-- END HEADER SEARCH BOX -->
			<!-- BEGIN TOP NAVIGATION MENU -->

			

			<!-- <div class="search-form {{isopen}}">
				<div class="input-group">
					<input type="text" (keyup)="autoComplete($event)" autocomplete="off" class="form-control input-sm" placeholder="Search..." id="userIdFirstWay" list="dynmicUserIds" [(ngModel)]="inputVar">
					<span class="input-group-btn">
						<a href="javascript:;" class="btn submit">
							<i class="icon-magnifier" (click)="open()"></i>
						</a>
					</span>					
				</div>
				<ul class="list-group" id="ulautocomplete" *ngIf="searchListVisible" style="display: inline-block!important;" >
					<a  #empID class="card-link  handcursor" *ngFor="let item of filteredBrands" style="text-decoration: none;" (click)="getEmpDetails($event,empID)"  [attr.data-id]="item.empId"><li class="list-group-item"> {{item.name}}</li></a>					
				</ul>
			</div> -->

			<!-- <span id="spnauto">
				<input type="text" (keyup)="autoComplete($event)" autocomplete="off"  class="form-control frm  m-2  " placeholder="Search..." id="userIdFirstWay" list="dynmicUserIds" [(ngModel)]="inputVar"/>
						 <ul class="list-group" id="ulautocomplete" *ngIf="searchListVisible">
							<a  #empID class="card-link  handcursor" *ngFor="let item of filteredBrands" style="text-decoration: none;" (click)="getEmpDetails($event,empID)" [attr.data-id]="item.empId"><li class="list-group-item"> {{item.name}}</li></a>

							
						  </ul>
						</span> -->

			<div class="top-menu">
				<ul class="nav navbar-nav pull-right">
					<li class="separator hide"> </li>
					<!-- BEGIN NOTIFICATION DROPDOWN -->
					<!-- DOC: Apply "dropdown-dark" class after below "dropdown-extended" to change the dropdown styte -->
					<!-- DOC: Apply "dropdown-hoverable" class after "dropdown" and remove data-toggle="dropdown" data-hover="dropdown" data-close-others="true" attributes to enable hover dropdown mode -->
					<!-- DOC: Remove "dropdown-hoverable" and add data-toggle="dropdown" data-hover="dropdown" data-close-others="true" attributes to the below A element with dropdown-toggle class -->
					<li class="dropdown dropdown-extended dropdown-notification dropdown-dark"
						id="header_notification_bar">
						<a class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"
							data-close-others="true">
							<i class="icon-bell"></i>
							<span class="badge badge-success"> {{pendingNotification}} </span>
						</a>
						<ul class="dropdown-menu">
							<li class="external">
								<h3>
									<span class="bold">{{pendingNotification}} pending</span> notifications
								</h3>
								<!-- <a href="#">view all</a> -->
							</li>
							<li>
								<ul class="dropdown-menu-list scroller" style="height: 250px;overflow: auto;"
									data-handle-color="#637283">
									<li *ngFor="let item of allNotification">
										<a (click)="openModal(item.emp_Id)">
											<!-- <span class="time">{{item.time_Calculate}}</span> -->
											<span class="details">SignUp Request :<span
													class="label label-sm label-icon pull-right label-success">
													{{item.time_Calculate}}
												</span>
												<br><br> {{item.firstName}} {{item.lastName}} </span>
										</a>
									</li>
									<li *ngFor="let item of reviewNotification">
										<a (click)="reviewRequest(item.person_Id)">
											<!-- <span class="time">{{item.time_Calculate}}</span> -->
											<span class="details">Review Request :
												<br> {{item.name}}<span (click)="reviewRequest(item.person_Id)"
													class="label label-sm label-icon pull-right label-success">
													{{item.time_Calculate}}
												</span> </span>
										</a>
									</li>
									<li *ngFor="let item of userCreationNotification">
										<a (click)="userCreation(item.id ,item.userCreation_Rule_Id)">
											<!-- <span class="time">{{item.time_Calculate}}</span> -->
											<span class="details">User Creation Request :
												<br> {{item.firstName}} {{item.middleName}} {{item.lastName}}<span
													class="label label-sm label-icon pull-right label-success">
													{{item.time_Calculate}}
												</span> </span>
										</a>
									</li>
									<!-- <li *ngFor="let item of probationNotification">
										<a >
											<span class="details">Probation Review Notification :
												<br> {{item.firstName}} {{item.middleName}} {{item.lastName}}
												has {{item.days_Calculate}} days left for next review date ({{item.nextReviewDate | date :'dd/MM/yyyy'}})
												<span 
													class="label label-sm label-icon pull-right label-success">
													
												</span> </span>
										</a>
									</li> -->
								</ul>
							</li>
						</ul>
					</li>
					<!-- END NOTIFICATION DROPDOWN -->
					<li class="separator hide"> </li>
					<!-- BEGIN INBOX DROPDOWN -->
					<!-- DOC: Apply "dropdown-dark" class after below "dropdown-extended" to change the dropdown styte -->
					<!-- <li class="dropdown dropdown-extended dropdown-inbox dropdown-dark" id="header_inbox_bar">
						<a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"
							data-close-others="true">
							<i class="icon-envelope-open"></i>
							<span class="badge badge-danger"> 0 </span>
						</a>
						<ul class="dropdown-menu">
							<li class="external">
								<h3>You have
									<span class="bold">0 New</span> Messages</h3>
							</li>
							 <li>
								<ul class="dropdown-menu-list scroller" style="height: 275px;"
									data-handle-color="#637283">
									<li *ngFor="let item of probationNotification">
										<a >											
											<span class="details">Probation Review Notification :
												<br> {{item.firstName}} {{item.middleName}} {{item.lastName}}
												has {{item.days_Calculate}} days left for next review date ({{item.nextReviewDate | date :'dd/MM/yyyy'}})
												<span 
													class="label label-sm label-icon pull-right label-success">
													
												</span> </span>
										</a>
									</li>

								</ul>
							</li> -->
					<!--</ul>
					</li> -->
					<!-- END INBOX DROPDOWN -->
					<li class="separator hide"> </li>
					<!-- BEGIN TODO DROPDOWN -->
					<!-- DOC: Apply "dropdown-dark" class after below "dropdown-extended" to change the dropdown styte -->
					<!-- <li class="dropdown dropdown-extended dropdown-tasks dropdown-dark" id="header_task_bar">
						<a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"
							data-close-others="true">
							<i class="icon-calendar"></i>
							<span class="badge badge-primary"> 0 </span>
						</a>
						<ul class="dropdown-menu extended tasks">
							<li class="external">
								<h3>You have <span class="bold">12 pending</span> tasks</h3>
								<a href="?p=page_todo_2">view all</a>
							</li>
							<li>
								<ul class="dropdown-menu-list scroller" style="height: 275px;"
									data-handle-color="#637283">
									<li>
										<a href="javascript:;">
											<span class="task">
												<span class="desc">New release v1.2 </span>
												<span class="percent">30%</span>
											</span>
											<span class="progress">
												<span style="width: 40%;" class="progress-bar progress-bar-success"
													aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
													<span class="sr-only">40% Complete</span>
												</span>
											</span>
										</a>
									</li>

								</ul>
							</li>
						</ul>
					</li> -->

					
						<li *ngIf= "!isCandidateOnly" class="dropdown dropdown-extended dropdown-tasks dropdown-dark" id="header_task_bar">
							<a href="javascript:;" class="dropdown-toggle" title="Employee Dictionary"
								routerLink="employee-dictonary">
								<i class="fa fa-language"></i>
							</a>
						</li>
					

					

					<!-- END TODO DROPDOWN -->
					<!-- BEGIN USER LOGIN DROPDOWN -->
					<!-- DOC: Apply "dropdown-dark" class after below "dropdown-extended" to change the dropdown styte -->
					<li class="dropdown dropdown-user dropdown-dark">
						<a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"
							data-close-others="true">
							<span class="username username-hide-on-mobile"> {{loginName}} </span>
							<!-- DOC: Do not remove below empty space(&nbsp;) as its purposely used -->
							<img alt="" class="img-circle" src="assets/layouts/layout4/img/avatar9.jpg" /> </a>
						<ul class="dropdown-menu dropdown-menu-default">
							<li *ngIf= "!isCandidateOnly">
								<a data-toggle="tooltip" data-placement="left" title="My Profile" (click)="showProfile()">
									<i class="icon-user"></i> My Profile </a>
							</li>
							<!-- <li>
								<a data-toggle="tooltip" data-placement="left" title="My Calender">
									<i class="icon-calendar"></i> My Calendar </a>
							</li> -->
							<!-- <li>
								<a href="#">
									<i class="icon-envelope-open"></i> My Inbox
									<span class="badge badge-danger"> 3 </span>
								</a>
							</li> -->
							<li *ngIf= "!isCandidateOnly">
								<a routerLink="changepassword" data-toggle="tooltip" data-placement="left"
									title="Change Password">
									<i class="icon-rocket"></i> Change Password
									<!-- <span class="badge badge-success"> 7 </span> -->
								</a>
							</li>
							<li class="divider"> </li>
							<!-- <li>
								<a href="#">
									<i class="icon-lock"></i> Lock Screen </a>
							</li> -->
							<li>
								<a href="javascript:;" (click)="logOut()" data-toggle="tooltip" data-placement="left"
									title="Log Out">
									<i class="icon-key"></i> Log Out </a>
							</li>
						</ul>
					</li>
					<!-- END USER LOGIN DROPDOWN -->
					<!-- BEGIN QUICK SIDEBAR TOGGLER -->
					<li class="dropdown dropdown-extended dropdown-inbox dropdown-dark" id="header_inbox_bar"
						data-toggle="tooltip" data-placement="bottom" title="Log Out">
						<a (click)="logOut()" class="dropdown-toggle">
							<i class="icon-logout"></i>
						</a>

					</li>
					<!-- <li class="dropdown dropdown-extended quick-sidebar-toggler">
						<a (click)="logOut()" data-toggle="tooltip" title="Log Out"><span class="sr-only">Toggle Quick
								Sidebar</span><i class="icon-logout"></i></a>

					</li> -->
					<!-- END QUICK SIDEBAR TOGGLER -->
				</ul>
			</div>
			<!-- END TOP NAVIGATION MENU -->
		</div>
		<!-- END PAGE TOP -->
	</div>
	<!-- END HEADER INNER -->
</div>
<!-- END HEADER -->
<!-- BEGIN HEADER & CONTENT DIVIDER -->
<div class="clearfix"> </div>
<!-- END HEADER & CONTENT DIVIDER -->


<div class="modal fade draggable-modal" id="notificationRole" tabindex="-1" role="basic" aria-hidden="true">
	<div class="modal-dialog modal-lg" style="margin:5% auto !important">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
				<h4 class="modal-title">Role Assign</h4>
			</div>
			<div class="modal-body">
				<app-roleassign></app-roleassign>
			</div>
		</div>
	</div>
</div>

<div class="modal fade draggable-modal" id="userCreationForm">
	<div class="modal-dialog modal-lg-usercreation" style="margin:5% auto !important">

		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
				<h4 class="modal-title">User Creation Form</h4>
			</div>
			<div class="modal-body">
				<app-usercreationform></app-usercreationform>
			</div>
			<!-- <div class="modal-footer">
					<button type="button" (click)="generatePDF()" class="btn dark btn-outline">Export to PDF</button>
					<button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
					<button type="button" (click)="userCreationSave()" class="btn blue">Save</button>
				</div> -->
		</div>
	</div>
</div>

<div class="modal fade draggable-modal" id="userProfile">
	<div class="modal-dialog modal-lg-usercreation" style="margin:5% auto !important">

		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
				<h4 class="modal-title">User Profile</h4>
			</div>
			<div class="modal-body" style="height: 800px;overflow: auto;">
				<app-user-profile></app-user-profile>
			</div>
			<!-- <div class="modal-footer">
					<button type="button" (click)="generatePDF()" class="btn dark btn-outline">Export to PDF</button>
					<button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
					<button type="button" (click)="userCreationSave()" class="btn blue">Save</button>
				</div> -->
		</div>
	</div>
</div>



<div class="modal fade draggable-modal" id="headermailSend" tabindex="-1" role="basic" aria-hidden="true">
	<div class="modal-dialog modal-lg" style="margin:5% auto !important">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-hidden="true"></button>
				<h4 class="modal-title">Send Mail</h4>
			</div>
			<div class="modal-body">
				<app-mailsend></app-mailsend>
			</div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" (click)="closeModal()" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="sendMail()" class="btn blue">Send</button>
            </div>
		</div>
	</div>
</div>


