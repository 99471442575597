import { Component, OnInit, ViewChild } from '@angular/core';
import { CandidatePersonalInfoComponent } from "../onboarding-personal-Info/candidate-personal-info.component";
import { CandidateJobDetailsComponent } from "../onboarding-job-detail/candidate-job-detail.component";
import { CandidatesDocumentComponent } from "../onboarding-document/candidate-document.component";
import { PersonOnboradingAdvancedSearchComponent } from '../person-advanced-search/person-advanced-search.component';

import { InterviewComponent } from "../../recruitment/interview/interview.component";
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { fromEventPattern } from 'rxjs';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { timingSafeEqual } from 'crypto';
import * as moment from 'moment';
import { SendMailComponent } from '../../sendmail/sendmail.component'
declare var $: any;
declare var tinymce: any;


@Component({
   selector: 'app-candidate-add',
   templateUrl: './candidate-add.component.html',

})
export class CandidateAddComponent implements OnInit {
   @ViewChild(CandidatePersonalInfoComponent, { static: false }) empW1: CandidatePersonalInfoComponent;
   // @ViewChild(CandidateAddressInfoComponent, { static: false }) empW2: CandidateAddressInfoComponent;
   @ViewChild(CandidateJobDetailsComponent, { static: false }) empW2: CandidateJobDetailsComponent;
   // @ViewChild(CandidateContactComponent, { static: false }) empW4: CandidateContactComponent;
   // @ViewChild(CandidateEmergencyContactComponent, { static: false }) empW5: CandidateEmergencyContactComponent;
   // @ViewChild(CandidateReferenceComponent, { static: false }) empW6: CandidateReferenceComponent;
   // @ViewChild(candidateBankInfoComponent, { static: false }) empW7: candidateBankInfoComponent;
   // @ViewChild(CandidateProbationComponent, { static: false }) empW3: CandidateProbationComponent;
   // @ViewChild(CandidateContractComponent, { static: false }) empW4: CandidateContractComponent;
   // @ViewChild(CandidateRightWorkComponent, { static: false }) empW10: CandidateRightWorkComponent;
   // @ViewChild(CandidateSalaryComponent, { static: false }) empW5: CandidateSalaryComponent;
   @ViewChild(CandidatesDocumentComponent, { static: false }) empW3: CandidatesDocumentComponent;
   @ViewChild(InterviewComponent, { static: false }) Interview: InterviewComponent;
   @ViewChild(PersonOnboradingAdvancedSearchComponent, { static: false }) advancedSearch: PersonOnboradingAdvancedSearchComponent;
   @ViewChild(SendMailComponent, { static: false }) MailSend: SendMailComponent;

   personSearchList: string = "hidden";
   searchPersonList: any;
   backbutton: boolean = false;
   pbWidth: number = 33.33;
   progressBarWidth: string = this.pbWidth + '%';
   btnName: string = "Continue";
   activeWizard: number = 1;
   visibleSendmail: boolean = false;
   statusChange: boolean = false;
   candidateId: any;
   w1: string = "active";
   w2: string = "";
   w3: string = "";
   // w4: string = "";
   // w5: string = "";
   // w6: string = "";
   // w7: string = "";
   // w8: string = "";
   // w9: string = "";
   // w10: string = "";
   // w11: string = "";
   // w12: string = "";
   t1: string = "tab-pane active";
   t2: string = "tab-pane";
   t3: string = "tab-pane";
   // t4: string = "tab-pane";
   // t5: string = "tab-pane";
   // t6: string = "tab-pane";
   // t7: string = "tab-pane";
   // t8: string = "tab-pane";
   // t9: string = "tab-pane";
   // t10: string = "tab-pane";
   // t11: string = "tab-pane";
   // t12: string = "tab-pane";
   companyId: any;


   constructor(private commonApiService: CommonApiService,
      private toastr: ToastrService,
      private router: Router,
      private fb: FormBuilder,
      private commonservice: CommonService
   ) {

   }

   ngOnInit() {

      $(document).ready(function () {
         $('[data-toggle="tooltip"]').tooltip();
      });
   }

   Upsert() {

      if (this.activeWizard == 3 && this.empW3.validation()) {

         // this.empW2.candidateJobDetailform.controls.onboarding_basicinfo.setValue(this.empW1.candidateBasicInfoform.value);
         this.empW2.candidateJobDetailform.controls.FirstName.setValue(this.empW1.candidateBasicInfoform.get("FirstName").value);
         this.empW2.candidateJobDetailform.controls.MiddleName.setValue(this.empW1.candidateBasicInfoform.get("MiddleName").value);
         this.empW2.candidateJobDetailform.controls.LastName.setValue(this.empW1.candidateBasicInfoform.get("LastName").value);
         this.empW2.candidateJobDetailform.controls.person.setValue(this.empW1.candidateBasicInfoform.value);

         //this.empW2.candidateJobDetailform.controls.Mobile.setValue(this.empW1.candidateBasicInfoform.get("Mobile").value);
         //this.empW2.candidateJobDetailform.controls.EMail.setValue($("#Email").val());
         // this.empW2.candidateJobDetailform.controls.NiNo.setValue($("#NiNumber").val());
         // this.empW2.candidateJobDetailform.controls.TotalExperience.setValue($("#TotalExperience").val() != "" ? $("#TotalExperience").val() : null);



         if ($("#contract_Code").val() != "" && $("#contract_Code").val() != undefined) {
            let days = this.empW2.contractlist.filter(x => x.contract_Id == $("#contract_Code").val())[0].contract_Days.trim();
            let contract_Id = this.empW2.contractlist.filter(x => x.contract_Id == $("#contract_Code").val())[0].contract_Id;

            var contractEndDate = moment($("#JoiningDate").val(), 'DD-MM-YYYY').add(days, 'd').format('YYYY-MM-DD')
            // console.log(contractEndDate.format('YYYY-MM-DD'));return false;
            var candidate_contract = this.fb.array([this.fb.group({
               contract_Id: contract_Id,
               Contract_Code: $("#contract_Code").val(),
               Contract_Name: $("#contract_Code option:selected").text().trim(),
               Contract_Days: this.empW2.contractlist.filter(x => x.contract_Id == $("#contract_Code").val())[0].contract_Days,
               Contract_Week: this.empW2.contractlist.filter(x => x.contract_Id == $("#contract_Code").val())[0].contract_Week,
               Contract_Start: this.empW2.candidateJobDetailform.controls.JoiningDate.value,
               Contract_End: contractEndDate,
               isActive: 1,
               AddedBy: Commonvariable.User_Id
            })])
            this.empW2.candidateJobDetailform.controls.onboarding_contract.setValue(candidate_contract.value);
         }


         if ($("#ProbationPeriod").val() != "" && $("#ProbationPeriod").val() != undefined) {


            let prob_period = $("#ProbationPeriod").val() != null ? $("#ProbationPeriod").val().split(" ") : "";


            let days = prob_period[0].trim();
            let dayType = prob_period[1].toLowerCase();


            if (dayType == 'day' || dayType == 'days') {

               var futureMonth = moment($("#JoiningDate").val(), 'DD-MM-YYYY').add(days, 'd').format('YYYY-MM-DD')

            } else if (dayType == 'month' || dayType == 'months') {
               var futureMonth = moment($("#JoiningDate").val(), 'DD-MM-YYYY').add(days, 'M').format('YYYY-MM-DD');

            } else if (dayType == 'year' || dayType == 'years') {

               var futureMonth = moment($("#JoiningDate").val(), 'DD-MM-YYYY').add(days, 'Y').format('YYYY-MM-DD');
            }

            var futureReviewMonth = moment($("#JoiningDate").val(), 'DD-MM-YYYY').add(3, 'M').format('YYYY-MM-DD');

            var candidate_probation = this.fb.array([this.fb.group({
               ProbationPeriod: $("#ProbationPeriod").val().trim(),
               ProbationEndDate: futureMonth,
               nextReviewDate: futureReviewMonth,
               status: "On probation",
               Prob_Id: null,
               isActive: 1,
               AddedBy: Commonvariable.User_Id
            })])
            this.empW2.candidateJobDetailform.controls.onboarding_probation.setValue(candidate_probation.value);
         }

         if ($("#Salary").val() != "" && $("#Salary").val() != undefined) {
            var candidate_salary = this.fb.array([this.fb.group({
               Company_Id: $("#company_Id").val().trim(),
               Dept_Id: $("#Dept_Id").val().trim(),
               Desig_Id: $("#Desig_Id").val().trim(),
               Salary: $("#Salary").val().trim(),
               SalaryType: $("#onboardingSalaryType").val(),
               ApprisalFrom: this.commonservice.todayDate(),
               ApprisalTo: this.commonservice.todayDate(365),
               isActive: 1,
               AddedBy: Commonvariable.User_Id
            })])
            this.empW2.candidateJobDetailform.controls.onboarding_salary.setValue(candidate_salary.value);
         }

         var documents = this.empW3.candidateform.get('candidate_document').value;


         let Doc_idCollection = [];

         for (let index = 0; index < this.empW3.docFiles.length; index++) {

            let documentData = {
               "isActive": documents[index]["isActive"] ? 1 : 0,
               "FileNameRef": this.empW3.docFiles[index]["name"],
            }
            Doc_idCollection.push(documentData);
         }

         const formData = new FormData();
         formData.append('onboarding', JSON.stringify(this.empW2.empUpsert()));
         formData.append('Doc_idCollection', JSON.stringify(Doc_idCollection));

         this.empW3.docFiles.filter(f => f != null).map(function (x) {
            formData.append('file', x);
         })



         this.commonApiService.PostRequest(Commonvariable.OnboardingDetail, "AddWithPerson", formData).subscribe((response) => {
            if (response != null) {
               this.candidateId = response["data"]["id"];
               if (response["message"] == Commonvariable.DuplicationMsg) {
                  this.toastr.warning('Record is already exists !', 'Onboarding');
               }
               else {

                  if (response["data"]["id"] != undefined && this.empW1.candidateLogoChange) {
                     const formData = new FormData();
                     formData.append('empBasicInfo_Id', response["data"]["id"]);
                     formData.append('file', this.empW1.candidateLogo);
                     this.commonApiService.UploadLogo(Commonvariable.Person, "UploadImage", formData).subscribe((res) => {
                        this.visibleSendmail = true
                        this.commonservice.toaster("success",Commonvariable.SaveMsg,"Onboarding");
                        this.SendMail()
                       // this.uploadDocument(response["data"]["id"]);
                     });
                  }
                  else {
                     this.commonservice.toaster("success",Commonvariable.SaveMsg,"Onboarding");
                     // this.uploadDocument(response["data"]["id"]);
                     this.visibleSendmail = true
                     this.SendMail()
                  }
               }
            }
         })


         // this.commonApiService.Upsert(Commonvariable.Person, this.empW1.candidateBasicInfoform.value, this.empW1.candidateBasicInfoform.value.Id).subscribe((result) => {
         //    if (result["data"]["id"] != undefined) {
         //       this.empW2.candidateJobDetailform.controls.person_Id.setValue(result["data"]["id"])




         //    }
         // })


      }
      else {


         if (this["empW" + this.activeWizard].validation()) {
            if (this.activeWizard == 1) {
               var ids = "ProbationEndDate0,#NextReviewDate0,#Contract_Start0,#Contract_End0,#IssueDate0,#ExpiryDate0,#ApprisalFrom0,#ApprisalTo0"
               this.commonservice.flatpickr(ids, 0);
            }
            var tidbox = $('.nav-justified>li.done').last().index();
            if (tidbox + 1 > this.activeWizard) {
               this.activeWizard = tidbox + 1;
            }
            this.wizardSetting("next");
         }

      }
   }




   uploadDocument(candidateId) {


      if (this.empW3.docFiles.length) {



         var documents = this.empW3.candidateform.get('candidate_document').value;


         for (var i = 0; i < this.empW3.docFiles.length; i++) {

            const formData = new FormData();
            formData.append('Onboarding_Id', candidateId);
            formData.append('Doc_Id', '0');
            formData.append('files', this.empW3.docFiles[i]);
            formData.append('AddedBy', Commonvariable.User_Id);
            formData.append('isActive', documents[0].isActive == true ? "1" : "0");
            formData.append('Is_CompanyDoc', "1");

            this.commonApiService.UploadLogo(Commonvariable.Onboarding_Document, "Add", formData).subscribe((response) => {
               if (i == this.empW3.docFiles.length - 1) {
                  this.visibleSendmail = true
                  this.SendMail()
               }
            });
         }
      }
      else {
         this.visibleSendmail = true
         this.SendMail()
      }
   }


   SendMail() {
      this.companyId = $("#company_Id").val().trim()
      this.MailSend.docFiles = [];
      this.MailSend.documentName = "Attach File";
      this.commonservice.dyanmicConfirm("You Want Send Mail To Candidate !", "Yes !").then((result) => {
         if (result.value) {

            this.commonApiService.getById(Commonvariable.OnboardingDetail, this.candidateId, "GetSendMailData").subscribe((response) => {
               if (response["data"] != "") {

                  $('#SendMail').modal({
                     backdrop: 'static'
                  });

                  setTimeout(() => {
                     $("#SendMail #to").val(response["data"]["to"])
                     $("#SendMail  #cc").val(response["data"]["cc"])
                     $("#SendMail #bcc").val(response["data"]["bcc"])
                     $("#SendMail #subject").val(response["data"]["subject"])
                     $('#SendMail #emailsend').summernote('code', response["data"]["body"]);
                  }, 500);

               }
            })
         }
         else {
            this.router.navigateByUrl("onboarding")
         }
      })
   }

   tabClick(tabIndex) {


      // if (this.Upsert()) {
      //    if(tabIndex > 3)
      //    {
      //         if(this.empW3.validation())
      //         {
      //          this.wizardSetting("tabClick", tabIndex);
      //         }
      //         else
      //         {
      //          this.wizardSetting("tabClick", 3);
      //         }
      //    }
      //    else
      //    {
      //       this.wizardSetting("tabClick", tabIndex);
      //    }
      // }
   }

   wizardSetting(event, tabIndex = 0) {
      var tab = 3;
      if (event == "next") {
         if (this.activeWizard != 3) {
            this.activeWizard++;
            this.pbWidth = this.pbWidth + 33.33;
         }
         else {
            this.activeWizard;
            this.pbWidth = this.pbWidth;
         }
      }
      else if (event == "back") {
         this.activeWizard--;
         this.pbWidth = this.pbWidth - 33.33;
      }
      else {
         this.activeWizard = tabIndex
         tab = tabIndex;
      }
      for (let index = 1; index <= tab; index++) {
         this["w" + index] = index == this.activeWizard ? "active" : index < this.activeWizard ? "done" : "";
         this["t" + index] = index == this.activeWizard ? "tab-pane active" : "tab-pane";
      }
      if (event == "tabClick" && tabIndex > 0) {

         this.pbWidth = 33.33 * tabIndex;
         for (let index = 3; index >= tabIndex; index--) {
            this["w" + index] = index == this.activeWizard ? "active" : index < this.activeWizard ? "done" : "";
            this["t" + index] = index == this.activeWizard ? "tab-pane active" : "tab-pane";
         }
      }
      this.progressBarWidth = this.pbWidth + "%"
      this.backbutton = this.activeWizard > 1 ? true : false
      this.btnName = this.activeWizard == 3 ? "Save" : "Continue"
      this.personSearchList = this.activeWizard > 1 ? "hidden" : "visible"

   }
   opentab(id) {

      var movePermission = true;

      if (this.activeWizard < id) {
         for (let index = 1; index < id; index++) {
            if (!this["empW" + index].validation()) {
               movePermission = false;
               break;
            }
         }
      }
      else {
         if (!this["empW" + this.activeWizard].validation()) {
            movePermission = false;
         }
      }

      if (movePermission) {
         if (this.activeWizard < id) {
            this.activeWizard = id - 1;
            this.pbWidth = 33.33 * this.activeWizard
            this.wizardSetting("next")
         }
         else {
            this.activeWizard = id + 1;
            this.pbWidth = 33.33 * this.activeWizard;
            this.wizardSetting("back")
         }
      }

      // if ($('.nav-justified>li:nth-child(' + tabid + ')').hasClass('done')) {
      //    if (this["empW" + this.activeWizard].validation()) {
      //       this["w" + this.activeWizard] = "done";
      //       this["t" + this.activeWizard] = "tab-pane";
      //       this["w" + tabid] = "active";
      //       this["t" + tabid] = "tab-pane active";

      //       this["w" + tabid] = tabid < this.activeWizard ? "active" : "done";
      //       this["w" + tabid] = tabid > this.activeWizard ? "active" : "done";
      //       // this["t" + tabid] = tabid < this.activeWizard ? "tab-pane active" : "tab-pane";
      //       this.activeWizard = tabid;
      //       this.pbWidth = 33.33 * tabid;
      //       this.progressBarWidth = this.pbWidth + "%"
      //       this.backbutton = this.activeWizard > 1 ? true : false
      //       this.btnName = this.activeWizard == 3 ? "Save" : "Continue"
      //       this.personSearchList = this.activeWizard > 1 ? "hidden" : "visible"
      //    }
      // }
   }


   // UpsertInterviewDetail() {
   //    var requestData = this.Interview.upsert(this.candidateId)
   //    if (requestData != null) {
   //       this.commonApiService.Upsert("Candidate_Interviewer", requestData, 1, '/EditMultiple').subscribe((result) => {
   //          if (result != null) {
   //             this.statusChange = result["message"] == "Saved Successfully" ? true : false
   //             this.commonservice.toaster("success", Commonvariable.SaveMsg, "Interview")
   //          }
   //       })
   //    }
   // }

   ChangeInterviewCandidateStatus(status) {
      this.commonservice.dyanmicConfirm("You Want To Change Status Of Candidate !", "Yes !").then((result) => {
         if (result.value) {
            var ids = this.candidateId + "," + status + "," + Commonvariable.User_Id
            this.commonApiService.Put(Commonvariable.OnboardingDetail, ids, "StatusChange").subscribe((response) => {
               if (response != null) {
                  this.statusChange = false;
                  this.toastr.success(response["message"], "Onboarding");
               }
            })
         }
      })
   }


   advanceFilter() {
      this.searchPersonList = [];
      this.empW1.ngOnInit();
      var data = {
         "SearchBy": this.advancedSearch.search()
      };
      data.SearchBy[data.SearchBy.length - 1].ConditionWith = "&&"
      let addData = {
         "FieldName": "Is_Candidate",
         "FieldValue": 1,
         "Parameter": "=="
      }
      data.SearchBy.push(addData)
      if (data.SearchBy) {
         this.commonApiService.getPaginated(Commonvariable.PersonService, null, null, null, null, null, data, "GetPaginatedByViewSummary").subscribe((res) => {
            if (res["data"] != undefined && res["data"] != "") {
               this.searchPersonList = res["data"];
               this.personSearchList = this.searchPersonList.length > 0 ? "visible" : "hidden"
            }
            $("#filterModal").modal("hide");
            this.advancedSearch.ngOnInit();
         })
      }

   }

   setPersonValue(id) {
      this.empW1.searchPersonFill(this.searchPersonList.filter(x => x.id == id)[0])
      this.empW2.searchPersonFill(this.searchPersonList.filter(x => x.id == id)[0])
   }

   sendMail() {

      const formData = new FormData();
      formData.append('To', $("#SendMail  #to").val());
      formData.append('CC', $("#SendMail #cc").val());
      formData.append('BCC', $("#SendMail #bcc").val());
      formData.append('Subject', $("#SendMail #subject").val());
      formData.append('Onboarding_Id', this.candidateId);
      formData.append('Role_Ids', this.MailSend.roleId != undefined ? this.MailSend.roleId : null);
      formData.append('Body', $('#SendMail #emailsend').summernote('code'));

      formData.append('Company_Id', this.companyId);
      for (var i = 0; i < this.MailSend.docFiles.length; i++) {
         formData.append('file', this.MailSend.docFiles[i]);
      }
      this.commonApiService.UploadLogo(Commonvariable.OnboardingDetail, "SendMail_Onboarding", formData).subscribe((response) => {

         let postRequest = {
            "Notification_Type_Id": 1,
            "Company_Id": this.companyId,
            "Mail_To": $("#SendMail #to").val(),
            "Mail_CC": $("#SendMail #CC").val(),
            "Mail_BCC": $("#SendMail #BCC").val(),
            "Role_Id": this.MailSend.roleId != "undefined" ? this.MailSend.roleId : null,
            "Subject": $("#SendMail #subject").val(),
            "Body": $('#SendMail #emailsend').summernote('code'),
            "isActive": 1,
            "AddedBy": Commonvariable.User_Id
         }
         this.commonApiService.Upsert(Commonvariable.Notification, postRequest, 0).subscribe((response) => {
            $(".candidate").addClass("active");
            $(".candidateadd").removeClass("active");
            this.toastr.success("Sent mail successfully", "Onboarding");
            this.router.navigateByUrl('onboarding');
         })
      });

   }
}