import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
import * as moment from 'moment';
declare var $: any;


@Component({
  selector: 'app-workingshift',
  templateUrl: './workingshift.component.html'
})
export class WorkingShiftComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  shiftlist: any;
  filterList: any;
  shiftform: FormGroup;
  companylist: Array<Select2OptionData>;;
  listcall: boolean = true;
  title: string;
  p: number = 1;
  totalRecord: 0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.title = "Add";
    this.shiftform = this.fb.group({
      shift_Id: ['0'],
      company_Id: ['', Validators.required],
      shift_Code: [''],
      shift_Name: ['', Validators.required],
      shift_Start: ['', Validators.required],
      shift_End: ['', Validators.required],
      Shift_Duration: ['', Validators.required],
      nightShift: [''],
      shift_Variable: [''],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });
    this.commonService.timePicker("flatpickropenshiftstatetime,#flatpickropenshiftendtime", 0)
    if (this.listcall) {
      this.getShiftList();
      this.getCompanyList();
    }

  }

  getShiftList(PageIndex = 1) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.shiftlist = []
    this.commonApiService.getListWithFilter(Commonvariable.Shift, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.shiftlist = result["data"]["list"];
        this.filterList = result["data"]["list"];
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);
      }
    })
  }

  getCompanyList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'company_Name'
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }

  setFormData(event) {
    this.shiftform.controls.company_Id.setValue(event)
  }

  onChangeTimeS() {
    var Shift_Duration = $("#Shift_Duration").val();
    if (Shift_Duration != 0 && Shift_Duration != "") {
      var selectedStartTime = moment($("#shift_Start").val(), 'H:m').format("H:m");
      var endTime = moment(selectedStartTime, 'H:m').add(Shift_Duration, 'hours').format("H:m");
      $("#shift_End").flatpickr({ dateFormat: "H:i", defaultDate: endTime, enableTime: true, noCalendar: true, time_24hr: true });
    }
    else {
      $("#shift_End").flatpickr({ dateFormat: "H:i", defaultDate: "", enableTime: true, noCalendar: true, time_24hr: true });
    }

    var selectedStartShift = moment($("#shift_Start").val(), 'H:mm').format("H");
    $("#nightShift").prop("disabled", false);
    if (parseInt(selectedStartShift) < 10) {
      $("#nightShift").prop("checked", false);
      $("#nightShift").prop("disabled", true);
    }
  }
  onChangeTime(times): void {
    var Shift_Duration = times;
    var shift_Start = moment($("#shift_Start").val(), 'H:m').format("H:m");
    if (Shift_Duration != 0 && Shift_Duration != "" && shift_Start != "") {
      var endTime = moment(shift_Start, 'H:m').add(Shift_Duration, 'hours').format("H:m");
      $("#shift_End").flatpickr({ dateFormat: "H:i", defaultDate: endTime, enableTime: true, noCalendar: true, time_24hr: true });

    }
  }

  shiftUpsert() {
    var sForm = this.shiftform;

    var shift_End = $("#shift_End").val();
    sForm.controls.shift_End.setValue(shift_End);
    if (this.validation()) {
      sForm.get("isActive").setValue(sForm.get("isActive").value ? 1 : 0);
      sForm.get("nightShift").setValue(sForm.get("nightShift").value ? 1 : 0);
      sForm.get("shift_Variable").setValue(sForm.get("shift_Variable").value ? 1 : 0);
      sForm.get("shift_Name").setValue(sForm.get("shift_Name").value);      
      this.commonApiService.UpsertWithMultiCompany(Commonvariable.Shift, sForm.value, sForm.controls.shift_Id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Shift');
          }
          else {
            $("#draggable").modal('hide');
            this.getShiftList();
            this.toastr.success(sForm.controls.shift_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Shift');
          }
        }
      })
    }

  }

  shiftDelete(sId) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Shift, sId).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getShiftList();
          this.toastr.success(response["message"], 'Shift');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Shift, Id, status).subscribe((response) => {
          
          if (response["status"] == 200) {
            this.getShiftList();
            this.toastr.success(response["message"], 'Shift');                        
          }

        })
      }

    });
  }

  parentComponent(value) { 
    
    if (parseInt(value.Shift_Id) > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Shift, parseInt(value.Shift_Id)).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var sForm = this.shiftform;
          this.commonService.setEditData(sForm, editData);
          sForm.controls.Shift_Duration.setValue(editData[0].shift_Duration);
          sForm.controls.updatedBy.setValue(Commonvariable.User_Id);
          // $("#shift_Code").prop("disabled", "disabled");
        }
      })
    }else {
      // $("#shift_Code").removeAttr("disabled");
      this.listcall = false;
      this.ngOnInit();
    }
  }
  openModel(sId) {
    if (sId > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Shift, sId).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var sForm = this.shiftform;
          this.commonService.setEditData(sForm, editData);
          sForm.controls.Shift_Duration.setValue(editData[0].shift_Duration);
          sForm.controls.updatedBy.setValue(Commonvariable.User_Id);
          // $("#shift_Code").prop("disabled", "disabled");
        }
      })
    }
    else {
      // $("#shift_Code").removeAttr("disabled");
      this.listcall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });

  }




  validation() {
    var sForm = this.shiftform;
    if (!sForm.valid) {
      for (const key of Object.keys(sForm.controls)) {
        if (sForm.controls[key].invalid) {
          let fieldName = "";

          if (key == "company_Id") {
            fieldName = "Company"
            $("#company_Id select").focus();
          }
          // else if (key == "shift_Code") {
          //   fieldName = "Shift code"
          // }
          else if (key == "shift_Name") {
            fieldName = "Shift name"
          }
          else if (key == "shift_Start") {
            fieldName = "Shift start"
          }
          else if (key == "shift_End") {
            fieldName = "Shift end"
          }
          else if (key == "Shift_Duration") {
            fieldName = "Shift Duration"
          }

          this.toastr.info(fieldName + " is required !", "Shift");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if ($("#shift_Start").val() == '00:00') {
        this.toastr.info("Shift Start is required !", "Shift");
        $("#shift_Start").focus();
        return false;
      }

      if ($("#Shift_Duration").val() == '0') {
        this.toastr.info("Shift Duration is required !", "Shift");
        $("#Shift_Duration").focus();
        return false;
      }

      if ($("#shift_End").val() == '00:00') {
        this.toastr.info("Shift End is required !", "Shift");
        $("#shift_End").focus();
        return false;
      }

      // if ($("#shift_Code").val().indexOf(" ") > -1) {
      //   this.toastr.info("Shift code not allow space !", "Shift");
      //   $("#shift_Code").focus();
      //   return false;
      // }
      // if ($("#shift_Code").val().length < 2) {
      //   this.toastr.info("Shift code must be at least 2 characters long !", "Shift");
      //   $("#shift_Code").focus();
      //   return false;
      // }
      // if ($("#shift_Code").val().search(/[^a-zA-Z0-9]+/) > -1) {
      //   this.toastr.info("Shift code only alphabets and numeric allow !", "Shift");
      //   $("#shift_Code").focus();
      //   return false;
      // }
      if ($("#shift_Name").val().length < 2) {
        this.toastr.info("Shift name must be at least 2 characters long !", "Shift");
        $("#shift_Name").focus();
        return false;
      }
      if (/["\\]/.test($("#shift_Name").val())) {
        this.toastr.info("Shift name does not allow Special Characters \" \\ )", "Shift");
        $("#shift_Name").focus();
        return false;
      }
      // if ($("#shift_Name").val().search(/^[^-\s][a-zA-Z0-9\s]+[^-\s]+$/) != 0) {
      //   this.toastr.info("Shift name only alphabets and numeric allow !", "Shift");
      //   $("#shift_Name").focus();
      //   return false;
      // }
      // if ($("#shift_Name").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
      //   this.toastr.info("Shift name only alphabets and numeric allow !", "Shift");
      //   $("#shift_Name").focus();
      //   return false;
      // }
      if (!$("#nightShift").prop("checked") && moment($("#shift_Start").val(), 'hh:mm') > moment($("#shift_End").val(), 'hh:mm')) {
        this.toastr.info("Shift start date not more then shift end date !", "Shift");
        $("#shift_Start").focus();
        return false;
      }

    }


    return true;
  }

  history() {
    this.historyView.getHistory("shift",this.shiftform.value.shift_Id)
  }

}
