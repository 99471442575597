<div class="modal fade draggable-modal in" id="draggable" role="basic" aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="closeModal()"></button>
                <h4 class="modal-title">{{title}} Zone</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="zoneform">
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Company
                                        <!-- <span class="required"> *</span> -->
                                    </label>
                                    <ng-select2 *ngIf="title != 'Edit'" [data]="companylist" formControlName="company_Id" [placeholder]="'--- Select ---'" [options]="{multiple: true,tags: false}" [disabled]="title == 'Edit'?true:false">
                                    </ng-select2>
                                    <input *ngIf="title == 'Edit'" type="text" id="company_Id" class="form-control" autocomplete="off" formControlName="company_Name" readonly>
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Zone Code<span class="required"> *
                                        </span></label>
                                    <input type="text" id="zone_Code" class="form-control" autocomplete="off"
                                        formControlName="zone_Code" maxlength="30">
                                </div>
                            </div> -->

                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Zone Name<span class="required"> *
                                        </span></label>
                                    <input type="text" id="zone_Name" class="form-control" autocomplete="off" formControlName="zone_Name" maxlength="30">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">

                                <label class="control-label">Active</label>
                                <div class="md-checkbox-inline">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="checkbox1_3" class="md-check" formControlName="isActive">
                                        <label for="checkbox1_3">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn  dark btn-outline pull-left" (click)="history()" *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button>
                <button type="button" class="btn dark btn-outline" (click)="closeModal()">Close</button>
                <button type="button" (click)="zoneUpsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Zone History</h4>
            </div>
            <div class="modal-body" *ngIf="title == 'Edit'">
                <app-history></app-history>
            </div>
        </div>
    </div>
</div>