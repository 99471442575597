<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>Audit Log List</h1>
            </div>
        </div>
        <ul class="page-breadcrumb breadcrumb mb20">
            <li>
                <div class="col-md-6">
                    <div class="input-group input-xlarge">
                        <div class="input-group-btn">
                            <button type="button" class="btn blue dropdown-toggle" data-toggle="dropdown">Search By
                                <i class="fa fa-angle-down"></i>
                            </button>
                            <ul class="dropdown-menu">
                                <li>
                                    <div class="col-md-3 md-radio-inline">
                                        <div class="md-radio has-success">
                                            <input type="radio" id="rbtn1" value="HostName" name="filter"
                                                class="md-check">
                                            <label for="rbtn1">
                                                <span class="inc"></span>
                                                <span class="check"></span>
                                                <span class="box" style="top:3px"></span> Host Name </label>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="col-md-3 md-radio-inline">
                                        <div class="md-radio has-success">
                                            <input type="radio" id="rbtn2" value="IpAddress" name="filter"
                                                class="md-check">
                                            <label for="rbtn2">
                                                <span class="inc"></span>
                                                <span class="check"></span>
                                                <span class="box" style="top:3px"></span> Ip Address </label>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="col-md-3 md-radio-inline">
                                        <div class="md-radio has-success">
                                            <input type="radio" id="rbtn3" value="Status" name="filter"
                                                class="md-check">
                                            <label for="rbtn3">
                                                <span class="inc"></span>
                                                <span class="check"></span>
                                                <span class="box" style="top:3px"></span> Status </label>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <input type="text" id="filterValue" class="form-control" placeholder="Search">
                        <span class="input-group-btn">
                            <button class="btn blue" (click)= 'searchOneValue("filter",null)' type="button">Go!</button>
                            <button class="btn btn-default" (click)="clear()" type="button"><i
                                    class="fa fa-refresh"></i></button>
                        </span>
                    </div>
                </div>
            </li>

            <!-- <li>
                <a data-toggle="modal" href="#filterModal" class="btn btn-transparent blue  btn-sm mrl10">
                    Advance Search</a>
            </li> -->

        </ul>
        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control"
                                placeholder="Type to filter the status column..." (change)="filterData($event)">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tbl">
                            <thead class="flip-content">
                                <tr>
                                    <th class="text-center"> User </th>
                                    <th class="text-center" style="width: 15%;"> Description </th>
                                    <th class="text-center" style="width: 25%;"> Type </th>
                                    <th class="text-center" style="width: 10%;"> Host Name </th>
                                    <th class="text-center" style="width: 10%;"> Ip Address </th>
                                    <th class="text-center" style="width: 5%;"> Status </th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of auditLogList | paginate: { itemsPerPage: 10, currentPage: p ,totalItems :totalItems }">
                                <tr>
                                    <td>{{item.firstName}} {{item.middleName}} {{item.lastName}}</td>
                                    <td>{{item.description.split(":")[1]}}</td>
                                    <td>{{item.type.split(":")[1]}}</td>
                                    <td class="text-center">{{item.hostName}}</td>
                                    <td class="text-center">{{item.ipAddress}}</td>
                                    <td class="text-center">{{item.status}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="auditLogList != null">
                        <pagination-controls (pageChange)="serverPagination($event)" autoHide="true"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- <div class="modal fade draggable-modal" id="filterModal" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Advance Search</h4>
            </div>
            <div class="modal-body">
                <app-empAdvancedSearch></app-empAdvancedSearch>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="clear()" class="btn dark btn-outline">Clear</button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="advanceFilter()" class="btn blue">Search</button>
            </div>
        </div>
    </div>
</div> -->