import { Component, OnInit, } from '@angular/core';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { Router} from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
declare var $: any;

@Component({
  selector: 'app-405',
  templateUrl: './405.component.html'  
})
export class AccessDeniedComponent implements OnInit {

  constructor(
    private commonApiService: CommonApiService,    
    private router: Router, private loginService: LoginService) { }

  ngOnInit() {
//    $("body").css("background-color","#ff996680");

  }


  logOut() {
    this.loginService.logout(localStorage.getItem("user_Id")).subscribe((result) => {
        // localStorage.clear();
        this.router.navigateByUrl('dashboard');
    },(error)=>{localStorage.clear();this.router.navigateByUrl('dashboard');});
  }

}
