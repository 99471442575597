import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;

@Component({
  selector: 'app-contract-form',
  templateUrl: './contract-form.component.html'
})
export class ContractFormComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  contractform: FormGroup;
  companylist: Array<Select2OptionData>;
  title: string;
  @Input() pId?: any;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshPage: EventEmitter<number> = new EventEmitter();
  @Output() refreshPageForEmp: EventEmitter<number> = new EventEmitter();


  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private fb: FormBuilder) { }



  ngOnInit() {

    document.body.className = "modal-open";

    this.title = "Add";
    this.contractform = this.fb.group({
      contract_Id: ['0'],
      company_Id: ['', Validators.required],
      contract_Days: [''],
      contract_HoursDaily: ['', Validators.required],
      contract_HoursWeekly: [0],
      company_Name:[''],
      contract_Code: [''],
      contract_Name: ['', Validators.required],
      strContract_Week: [null],
      contract_Type: ['', Validators.required],
      notes: [''],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });


    this.getCompanyList();

    if (this.pId > 0) {
      this.editRecord(this.pId);
    }


  }
  getCompanyList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "company_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }



  contractUpsert() {
    var cData = this.contractform;
  
    if (this.validation()) {
      cData.get("isActive").setValue(cData.get("isActive").value ? 1 : 0);
      cData.get("contract_Name").setValue(cData.get("contract_Name").value);

      this.commonApiService.UpsertWithMultiCompany(Commonvariable.Contract, cData.value, cData.controls.contract_Id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.commonService.toaster("warning",'Record is already exists !');
          }
          else {
            this.closeModal();

            this.refreshPageForEmp.emit(parseInt(localStorage.getItem("employeeHRCompanyId")));
            this.refreshPage.emit(1);
            this.commonService.toaster('success',cData.controls.contract_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg);
          }
        }
      })
    }
  }


  editRecord(id) {

    if (id > 0) {
      this.title = "Edit";

      this.commonApiService.getById(Commonvariable.Contract, id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var formData = this.contractform;
          this.commonService.setEditData(formData, editData);
          this.contractform.controls.updatedBy.setValue(Commonvariable.User_Id);
          $(".wd").prop("checked", false);
          var workingDays = this.contractform.value.strContract_Week
          if (workingDays != null) {
            workingDays.forEach(x => {
              $("[value=" + x + "]").prop("checked", true)
            })
          }
          // $("#company_Id").val(editData[0]['company_Name']);          
        }
      })


      // this.commonApiService.getById(Commonvariable.Zone, zoneId).subscribe((response) => {
      //   if (response != null) {
      //     var editData = response["data"];
      //     var formData = this.zoneform;
      //     this.commonService.setEditData(formData, editData);
      //     formData.controls.updatedBy.setValue(Commonvariable.User_Id);
      //     // $("#company_Id").val(editData[0]['company_Name']);

      //     this.zoneform.patchValue({
      //       company_Id : editData[0]['company_Name']
      //     })
      //   }
      // })
    }
  }
  

  
  validation() {

    

    var cData = this.contractform;

    if (!cData.valid) {
      for (const key of Object.keys(cData.controls)) {
        if (cData.controls[key].invalid) {
          let fieldName = "";
          if (cData.controls[key].errors.required) {

            if (key == "company_Id") {
              fieldName = "Company"
              $("#company_Id select").focus();
            }
            if (key == "contract_Name") {
              fieldName = "Contract Name"
            }
            if (key == "contract_HoursDaily") {
              fieldName = "Contract Hours Weekly"
            }
            if (key == "strContract_Week") {
              fieldName = "Working Days"
            }
            // if (key == "contract_Days") {
            //   fieldName = "Length of Contract"
            // }
            if (key == "contract_Type") {
              fieldName = "Contract Type"
            }

            if (fieldName != "") {
              this.commonService.toaster("info", fieldName + " is required !");        
              $("#" + key).focus();
              return false;
            }

          }



          // if (cData.controls[key].errors.pattern) {
          //   if (key == "contract_Name") {
          //     this.toastr.info("Contract name only alphabets allow !", "Contract");
          //     $("#" + key).focus();
          //     return false;
          //   }
          // }
        }

      }

      

    }
    //else {

      if ($("#contract_HoursDaily").val() == '0') {
        this.commonService.toaster("info", "Contract hours daily should not zero!");        
        $("#contract_HoursDaily").focus();
        return false;
      }

      if (/["\\]/.test($("#contract_Name").val())) {
        this.commonService.toaster("info", "Contract name does not allow Special Characters \" \\ ");   
        $("#contract_Name").focus();
        return false;
      }
      if (/["\\]/.test($("#notes").val())) {
        this.commonService.toaster("info", "notes does not allow Special Characters \" \\ ");   
        $("#notes").focus();
        return false;
      }
      if (parseInt($("#contract_HoursDaily").val()) > 24) {
        this.commonService.toaster("info", "Contract hours daily  not more then 24 (1 Day) !");   
        $("#contract_HoursDaily").focus();
        return false;
      }
      if (parseInt($("#contract_Days").val()) > 1825) {
        this.commonService.toaster("info", "Length of Contract not more then 1825 (5 Years) !");   
        $("#contract_Days").focus();
        return false;
      }
      if ($("#contract_HoursDaily").val() == "") {
        cData.controls.contract_HoursDaily.setValue(0)
      }
      
      if ($("#contract_Days").val() == "") {
        cData.controls.contract_Days.setValue(null)
      }
      
    let workingDays = $("input:checkbox.wd:checked").map(function () {
      return $(this).val();
    }).get()
    
    if (workingDays.length > 0) {
      this.contractform.controls.strContract_Week.setValue(workingDays);
    }
    if (workingDays.length == 0) {
      this.commonService.toaster("info", "Working Days is required !");        
      return false;
    }
  //}
    return true;
  }


  closeModal() {
    this.modalClose.emit(false)
  }

  history() {
    this.historyView.getHistory("contract", this.contractform.value.contract_Id)
  }

}


// if ($("#contract_Name").val().search(/^[^-\s][a-zA-Z0-9\s]+[^-\s]+$/) != 0) {
      //   this.toastr.info("Contract name only alphabets and numeric allow !", "Contract");
      //   $("#contract_Name").focus();
      //   return false;
      // }

      // if ($("#contract_Name").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
      //   this.toastr.info("Contract name only alphabets and numeric allow !", "Contract");
      //   $("#contract_Name").focus();
      //   return false;
      // }
      // if (parseInt($("#contract_HoursWeekly").val()) > 168) {
      //   this.toastr.info("Contract hours weekly not more then 168 (1 Week) !", "Contract");
      //   $("#contract_HoursWeekly").focus();
      //   return false;
      // }

      // if ($("#contract_Days").val().length < 1) {
      //   this.commonService.toaster("info", "Length of Contract must be at least 1 characters long !");   
      //   $("#contract_Days").focus();
      //   return false;
      // }
      // if ($("#contract_Type").val().length < 2) {
      //   this.commonService.toaster("info", "Contract type must be at least 2 characters long !");   
      //   $("#contract_Type").focus();
      //   return false;
      // }

      // if ($("#contract_HoursWeekly").val() == "") {
      //   cData.controls.contract_HoursWeekly.setValue(0)
      // }

      // if ($("#contract_HoursWeekly").val() == '0') {
      //   this.toastr.info("Contract hours weekly should not zero!", "Contract");
      //   $("#contract_HoursWeekly").focus();
      //   return false;
      // }

      // if ($("#contract_Days").val() == '0') {
      //   this.commonService.toaster("info", "Length of Contract should not zero!");   
      //   $("#contract_Days").focus();
      //   return false;
      // }

      // if ($("#contract_Code").val().length < 2) {
      //   this.toastr.info("Contract code must be at least 2 characters long !", "Contract");
      //   $("#contract_Code").focus();
      //   return false;
      // }
      // if ($("#contract_Code").val().indexOf(" ") > -1) {
      //   this.toastr.info("Contract code not allow space !", "Contract");
      //   $("#contract_Code").focus();
      //   return false;
      // }
      // if ($("#contract_Code").val().search(/[^a-zA-Z0-9]+/) > -1) {
      //   this.toastr.info("Contract code only alphabets and numeric allow !", "Contract");
      //   $("#contract_Code").focus();
      //   return false;
      // }
      // if ($("#contract_Name").val().length < 2) {
      //   this.commonService.toaster("info", "Contract name must be at least 2 characters long !");   
      //   $("#contract_Name").focus();
      //   return false;
      // }
