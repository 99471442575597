<form [formGroup]="companyform">
    <div class="form-group">
        <label class="control-label col-md-4">Logo
        </label>
        <div class="col-md-3">
            <div [class]="logoClass" data-provides="fileinput">
                <div class="fileinput-new thumbnail" style="width: 200px; height: 150px;">
                    <img src="assets/layouts/layout4/img/selectPic.png" alt="" />
                </div>
                <div class="fileinput-preview fileinput-exists thumbnail" style="max-width: 200px; max-height: 150px;"
                    id="companyLogoImage" [innerHTML]="logoInnerHTML">
                </div>
                <div>
                    <span class="btn default btn-file">
                        <span class="fileinput-new"> Select image </span>
                        <span class="fileinput-exists"> Change </span>
                        <input type="file" name="..." id="companyLogo" accept="image/x-png,image/jpg,image/jpeg"
                            (change)="filechange($event.target.files)">
                    </span>
                    <a class="btn red fileinput-exists" (click)="removeLogo()" id="remove"
                        data-dismiss="fileinput"> Remove </a>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-md-4">Currency
        </label>
        <div class="col-md-3">
            <select id="currency" class="form-control" formControlName="currency_Id">
                <option value="null">Select Your Currency</option>
                <option *ngFor="let item of currenciesList" value="{{item.id}}">{{item.currency_Name}} - {{item.currency_Code}}
                </option>
            </select>
        </div>
    </div>
    <!-- <div class="form-group">
        <label class="control-label col-md-4">Language
        </label>
        <div class="col-md-3">
            <select id="language" class="form-control" formControlName="language">
                <option value="null">Select Your Language</option>
                <option *ngFor="let item of languageList" value="{{item.languages}}">{{item.languages}}</option>
            </select>
        </div>
    </div> -->
</form>