<style type="text/css">
    .border {
        border: 1px solid black;
        width: 1000px;
    }

    .border td {
        border: 1px solid;
    }

    .h-30 {
        height: 30px;
    }
    .w-300 {
        width: 300px;
    }
    .pr-30 {
        padding-right: 30px;
    }
    .mt-2 {
        margin-top: 2px;
    }
    .inline-block {
        display: inline-block;
    }
    .page {
        height: 842px;
        width: 595px;
        margin: 5mm 5mm 20mm 5mm;
    }
</style>



<div class="table-responsive" style="max-height: 625px; overflow: auto;" id="userCreation">
    <div id="userCreationFormExportToPDF" #pdfTable>
        <table cellspacing="0" cellpadding="0" border="0" bordercollapse="collapse" align="center"
            id="sc5057" style="font-family:arial; margin: 50px 0px 50px 80px; table-layout: auto; empty-cells: show;">
            <tbody>
                <tr>
                    <td style="padding: 80px 0px 20px 0px; text-align: left;">
                        <span style="display: block; font-weight: 600; margin: 0 0 5px; font-size: 20;">
                            <b>B&S Group<br>User Creation Form</b>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>
                            <p
                                style="text-transform: uppercase;font-size: 20px;margin-top: 60px; border-bottom: 2px solid;width: max-content;">
                                to be filled in by - hr</p>
                        </b>
                    </td>
                </tr>
                <tr>
                    <td style="padding-bottom: 20px;">
                        <b>(For administration purpose only)</b>
                    </td>
                </tr>

                <tr>
                    <td colspan="2">
                        <table border="0"
                            style="font-family:arial; width: 100%; padding: 5px 15px 30px 15px;" class="pl-2">
                            <tbody>
                                
                                <tr>
                                    <td style="padding-top: 15px;">
                                        <form [formGroup]="hrForm">
                                            <table>
                                                <tr>
                                                    <td><b>First Name:</b></td>
                                                    <td>
                                                        <input type="text" formControlName="firstName" disabled class="h-30 w-300"
                                                            style="border:1px solid; padding: 0 5px;">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Last Name:</b></td>
                                                    <td>
                                                        <input type="text" formControlName="lastName" disabled class="h-30 w-300"
                                                            style="  border:1px solid; padding: 0 5px;">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Department:</b></td>
                                                    <td>
                                                        <input type="text" formControlName="department" disabled class="h-30 w-300"
                                                            style="  border:1px solid; padding: 0 5px;">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td><b>HR Signature:</b></td>
                                                    <td>
                                                        <input type="text" formControlName="hrSignature" disabled class="h-30 w-300"
                                                            style="  border:1px solid; padding: 0 5px;">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Employee ID:</b></td>
                                                    <td>
                                                        <input type="text" formControlName="empId" disabled class="h-30 w-300"
                                                            style="  border:1px solid; padding: 0 5px;">
                                                    </td>
                                                </tr>

                                            </table>
                                        </form>
                                    </td>

                                    <td>
                                        <form [formGroup]="hrForm">
                                            <table style="position: relative; bottom:20px;">
                                                <tr>
                                                    <td><b>Middle Name:</b></td>
                                                    <td>
                                                        <input type="text" formControlName="middleName" disabled class="h-30 w-300"
                                                            style="  border:1px solid; padding: 0 5px;">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Start Date:</b></td>
                                                    <td>
                                                        <input type="date" formControlName="startDate" disabled class="h-30 w-300"
                                                            style="  border:1px solid; padding: 0 5px;">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Designation:</b></td>
                                                    <td>
                                                        <input type="text" formControlName="designation" disabled class="h-30 w-300"
                                                            style="  border:1px solid; padding: 0 5px;">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Date:</b></td>
                                                    <td>
                                                        <input type="date" formControlName="date" disabled class="h-30 w-300"
                                                            style="  border:1px solid; padding: 0 5px;">
                                                    </td>
                                                </tr>
                                            </table>
                                        </form>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <hr style="border-bottom: #707070; width: 1000px;" /> 
                        <table cellspacing="0" border="0" class="hod"
                            style=" font-family:arial; margin: 0 auto; width: 595px; max-width: 100%; padding: 30px 15px 25px 15px;">
                            <tbody>
                                <tr>
                                    <td>
                                        <b>
                                            <p
                                                style="text-transform: uppercase; font-size: 20px; margin-top: 60px; border-bottom: 2px solid ; width: max-content;">
                                                to be filled in by - HOD
                                            </p>
                                        </b>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-size: 17px; padding-top: 25px;">
                                        <form [formGroup]="hodForm">
                                            <p>
                                                <b>Aurthorised HOD : </b>
                                                <input type="text" formControlName="aurthorisedHOD" class="h-30 w-300"
                                                    style="border: 1px solid ; width: 300px ;padding: 5px 10px;">
                                            </p>
                                        </form>
                                    </td>
                                    <td style="font-size: 15px; position: relative; padding-top: 20px;">
                                        <form [formGroup]="hodForm">
                                            <p style="left: -223px; position: relative;">
                                                <b>Date : </b>
                                                <input type="date" formControlName="date" class="h-30 w-300"
                                                    style="border:1px solid ; width: 200px; padding: 5px 10px;">
                                            </p>
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="padding-top: 20px;">
                                        <table class="border" style="border-collapse: collapse;">
                                            <tr>
                                                <td colspan="2" style="padding:10px 10px;"><b>Location:</b></td>
                                                <td style="padding:5px 10px;">
                                                    <form [formGroup]="hodForm">
                                                        <div class="inline-block pr-30 mt-2">
                                                            <input type="checkbox" formControlName="isruislip">&nbsp;
                                                            <label> Ruislip</label>
                                                        </div>
                                                        <div class="pr-30 inline-block mt-2">
                                                            <input type="checkbox" formControlName="isHomeBased">&nbsp;
                                                            <label> Home Based</label>
                                                        </div>
                                                    </form>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="padding-top: 20px;">

                                        <table class="border" style="border-collapse: collapse; margin-top: 35px;">
                                            <tr>
                                                <td colspan="2" style="padding:5px 10px; text-align: center;">
                                                    Application Access Requirement<br>(Check that may apply)</td>
                                                <td colspan="2" style="padding:0 10px; text-align: center;">Email
                                                    Access Requirement<br>(Check that may apply)</td>
                                                <td colspan="2" style="padding:0 10px; text-align: center;">Internet
                                                    Access Requirement<br>(Check appropriate options)</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:5px 10px;">
                                                    <form [formGroup]="hodForm">
                                                        <input type="checkbox" formControlName="rdpAccess">&nbsp;
                                                        <label> RDP Access(Remote
                                                            Login)</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding: 5px 10px;">
                                                    <form [formGroup]="hodForm">
                                                        <input type="checkbox"
                                                            formControlName="emailAccessRequirementYes">&nbsp;
                                                        <label> Yes</label>
                                                    </form>
                                                </td>
                                                <td style="padding: 5px 10px;">
                                                    <form [formGroup]="hodForm">
                                                        <input type="checkbox"
                                                            formControlName="internetAccessRequirementYes">&nbsp;
                                                        <label> Yes</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:5px 10px;">
                                                    <form [formGroup]="hodForm">
                                                        <input type="checkbox" formControlName="eCTD">&nbsp;
                                                        <label for="e-CTD"> e-CTD</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding: 5px 10px;">
                                                    <form [formGroup]="hodForm">
                                                        <input type="checkbox"
                                                            formControlName="emailAccessRequirementNo">&nbsp;
                                                        <label for="No1"> No</label>
                                                    </form>
                                                </td>
                                                <td style="padding: 5px 10px;">
                                                    <form [formGroup]="hodForm">
                                                        <input type="checkbox"
                                                            formControlName="internetAccessRequirementNo">&nbsp;
                                                        <label for="No2"> No</label>
                                                    </form>
                                                </td>
                                            </tr>
                                        </table>
                                         <hr style="border-bottom: #707070; width: 1000px;" /> 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td style="padding: 30px 0;">Other Requests:</td>
                                                    <td style="position: relative; right: -20px;">
                                                        <form [formGroup]="hodForm">
                                                            <textarea rows="10" cols="121"
                                                                formControlName="otherRequest1"
                                                                style="padding: 5px"></textarea>
                                                        </form>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table cellspacing="0" border="0"
                                            style=" font-family:arial; margin: 0 auto; width: 100%; max-width: 1000px;">
                                            <tbody>
                                                <tr>
                                                    <td style="padding-top: 250px; text-align: right;">Page 1 of 7</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table cellspacing="0" border="0"
                                            style=" font-family:arial; margin: 0 auto; width: 100%; max-width: 1000px;">
                                            <tbody>
                                                <tr>
                                                    <td style="padding-top: 30px; text-align: left;">Parent SOP
                                                        SOP/IT/0001
                                                    </td>
                                                    <td style="padding-top: 30px; text-align: right;">Form Number
                                                        F/IT/0001/001/v9</td>
                                                </tr>
                                                <tr>
                                                    <td style="text-align: left;">Effective Date: 23SEP2019</td>
                                                    <td style="text-align: right;">Review Date: 22SEP2021</td>
                                                </tr>
                                                <tr>
                                                    <td style="padding: 20px 0;"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- *********************** Page 1 Completed ************************ -->
                        <!-- <hr style="border-bottom: #707070; width: 1000px;" />  -->
                        <table class="hod">
                            <tbody>
                                <tr>
                                    <td style="padding: 80px 0px 20px 0px; text-align: left;">
                                        <span
                                            style="display: block; font-weight: 600; margin: 0 0 5px;font-size: 20;">
                                            <b>B&S Group<br>User Creation Form</b></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-top: 100px;">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td colspan="2">
                                                        <div class="">
                                                            <table class="border" style="border-collapse: collapse;">
                                                                <tr>
                                                                    <td colspan="5" rowspan="2"
                                                                        style="padding:5px 10px;text-align: center;">
                                                                        <b>Application Access Requirement<br>(Check that
                                                                            may
                                                                            apply)</b>
                                                                    </td>
                                                                </tr>
                                                                <tr></tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Special Orders"
                                                                                formControlName="specialOrders"
                                                                                name="Special Orders">&nbsp;
                                                                            <label for="Special Orders"> Special
                                                                                Orders</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Finance KPI"
                                                                                formControlName="financeKPI"
                                                                                name="Finance KPI" value="">&nbsp;
                                                                            <label for="Finance KPI"> Finance
                                                                                KPI</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="PLPI" name="PLPI"
                                                                                formControlName="pLPI" value="">&nbsp;
                                                                            <label for="PLPI"> PLPI</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Customer Service"
                                                                                formControlName="customerService"
                                                                                name="Customer Service" value="">&nbsp;
                                                                            <label for="Customer Service"> Customer
                                                                                Service</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="Finance Enterprise"
                                                                                formControlName="financeEnterprise"
                                                                                name="Finance Enterprise"
                                                                                value="">&nbsp;
                                                                            <label for="Finance Enterprise"> Finance
                                                                                Enterprise</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="WMS" name="WMS"
                                                                                formControlName="wMS" value="">&nbsp;
                                                                            <label for="WMS"> WMS</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Annual Planner"
                                                                                formControlName="annualPlanner"
                                                                                name="Annual Planner" value="">&nbsp;
                                                                            <label for="Annual Planner"> Annual
                                                                                Planner</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="BNS-SOP (Customer Order)"
                                                                                formControlName="bNSSOP"
                                                                                name="BNS-SOP (Customer Order)"
                                                                                value="">&nbsp;
                                                                            <label for="BNS-SOP (Customer Order)">
                                                                                BNS-SOP
                                                                                (Customer Order)</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="TPS" name="TPS"
                                                                                formControlName="tPS" value="">&nbsp;
                                                                            <label for="TPS"> TPS</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Purchase Order"
                                                                                formControlName="purchaseOrder"
                                                                                name="Purchase Order" value="">&nbsp;
                                                                            <label for="Purchase Order"> Purchase
                                                                                Order</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="Request Puschase Order"
                                                                                formControlName="requestPuschaseOrder"
                                                                                name="Request Puschase Order"
                                                                                value="">&nbsp;
                                                                            <label for="Request Puschase Order"> Request
                                                                                Puschase Order</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="MCS" name="MCS"
                                                                                formControlName="MCS" value="">&nbsp;
                                                                            <label for="MCS"> MCS</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>


                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Price Matrix"
                                                                                formControlName="priceMatrix"
                                                                                name="Price Matrix" value="">&nbsp;
                                                                            <label for="Price Matrix"> Price
                                                                                Matrix</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="RSM-Management"
                                                                                formControlName="rSMManagement"
                                                                                name="RSM-Management" value="">&nbsp;
                                                                            <label for="RSM-Management">
                                                                                RSM-Management</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="DMS" name="DMS"
                                                                                formControlName="dMS" value="">&nbsp;
                                                                            <label for="DMS"> DMS</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="Colorama Logistic"
                                                                                formControlName="coloramaLogistic"
                                                                                name="Colorama Logistic" value="">&nbsp;
                                                                            <label for="Colorama Logistic"> Colorama
                                                                                Logistic</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="Project Management"
                                                                                formControlName="projectManagement"
                                                                                name="Project Management"
                                                                                value="">&nbsp;
                                                                            <label for="Project Management"> Project
                                                                                Management</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="PMS" name="PMS"
                                                                                formControlName="pMS" value="">&nbsp;
                                                                            <label for="PMS"> PMS</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="Room Booking System"
                                                                                formControlName="roomBookingSystem"
                                                                                name="Room Booking System"
                                                                                value="">&nbsp;
                                                                            <label for="Room Booking System"> Room
                                                                                Booking
                                                                                System</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="Warehouse Stock Control"
                                                                                formControlName="warehouseStockControl"
                                                                                name="Warehouse Stock Control"
                                                                                value="">&nbsp;
                                                                            <label for="Warehouse Stock Control">
                                                                                Warehouse
                                                                                Stock Control</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="POD" name="POD"
                                                                                formControlName="pOD" value="">&nbsp;
                                                                            <label for="POD"> POD</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="BNS-Loyalty"
                                                                                formControlName="bNSLoyalty"
                                                                                name="BNS-Loyalty" value="">&nbsp;
                                                                            <label for="BNS-Loyalty">
                                                                                BNS-Loyalty</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="Purchase Order india"
                                                                                formControlName="purchaseOrderindia"
                                                                                name="Purchase Order india"
                                                                                value="">&nbsp;
                                                                            <label for="Purchase Order india"> Purchase
                                                                                Order
                                                                                india</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="HR Recruitment"
                                                                                formControlName="hRRecruitment"
                                                                                name="HR Recruitment" value="">&nbsp;
                                                                            <label for="HR Recruitment"> HR
                                                                                Recruitment</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>


                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="PayrollSystemIndia"
                                                                                formControlName="payrollSystemIndia"
                                                                                name="Payroll System India"
                                                                                value="">&nbsp;
                                                                            <label for="PayrollSystemIndia"> Payroll
                                                                                System
                                                                                India</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Report Server"
                                                                                formControlName="reportServer"
                                                                                name="Report Server" value="">&nbsp;
                                                                            <label for="Report Server"> Report
                                                                                Server</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="Meeting Management"
                                                                                formControlName="meetingManagement"
                                                                                name="Meeting Management"
                                                                                value="">&nbsp;
                                                                            <label for="Meeting Management"> Meeting
                                                                                Management</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>


                                                                <tr>
                                                                    <td colspan="2" style="padding: 10px 10px;">
                                                                        <b>Access
                                                                            Rights:</b>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Manager"
                                                                                name="Manager"
                                                                                formControlName="accessRightsManager"
                                                                                value="">&nbsp;
                                                                            <label for="Manager"> Manager</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Normal"
                                                                                name="Normal"
                                                                                formControlName="accessRightsNormal"
                                                                                value="">&nbsp;
                                                                            <label for="Normal"> Normal</label>
                                                                        </form>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>

                                                                <tr>
                                                                    <td rowspan="3" colspan="1"
                                                                        style="padding: 10px 10px;">
                                                                        <b>Site:</b>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="SPE" name="SPE"
                                                                                formControlName="siteSpe"
                                                                                value="">&nbsp;
                                                                            <label for="SPE"> SPE</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="SPE-L"
                                                                                name="SPE-L" formControlName="siteSPeL"
                                                                                value="">&nbsp;
                                                                            <label for="SPE-L"> SPE-L</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="WHO-L"
                                                                                name="WHO-L" formControlName="siteWHOL"
                                                                                value="">&nbsp;
                                                                            <label for="WHO-L"> WHO-L</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="LAX" name="LAX"
                                                                                formControlName="sitelax"
                                                                                value="">&nbsp;
                                                                            <label for="LAX"> LAX</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="CON-L"
                                                                                name="CON-L" formControlName="siteconl"
                                                                                value="">&nbsp;
                                                                            <label for="CON-L"> CON-L</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="WHO-G"
                                                                                name="WHO-G" formControlName="sitewhog"
                                                                                value="">&nbsp;
                                                                            <label for="WHO-G"> WHO-G</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="CON-G"
                                                                                name="CON-G" formControlName="sitecong"
                                                                                value="">&nbsp;
                                                                            <label for="CON-G"> CON-G</label>
                                                                        </form>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <table style="margin-top:80px;">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="padding: 30px 0;">Other Requests:</td>
                                                                    <td style="position: relative; right: -20px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <textarea rows="6" cols="121"
                                                                                formControlName="otherRequest"
                                                                                style="padding: 5px"></textarea>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <table cellspacing="0" border="0"
                                                            style=" font-family:arial; margin: 0 auto; width: 100%; max-width: 1000px;">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="padding-top: 450px; text-align: right;">
                                                                        Page 2
                                                                        of 7</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <table cellspacing="0" border="0"
                                                            style=" font-family:arial; margin: 0 auto; width: 100%; max-width: 1000px;">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="padding-top: 30px; text-align: left;">
                                                                        Parent
                                                                        SOP SOP/IT/0001
                                                                    </td>
                                                                    <td style="padding-top: 30px; text-align: right;">
                                                                        Form
                                                                        Number
                                                                        F/IT/0001/001/v9</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="text-align: left;">Effective Date:
                                                                        23SEP2019
                                                                    </td>
                                                                    <td style="text-align: right;">Review Date:
                                                                        22SEP2021
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="padding: 20px 0;"></td>
                                                                </tr>
                                                            </tbody>
                                                           
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                         <!-- *********************** Page 1 Completed ************************ -->
                        <!-- <hr style="border-bottom: #707070; width: 1000px;" /> -->
                        <table class="hod">
                            <tbody>
                                <tr>
                                    <td style="padding: 80px 0px 20px 0px; text-align: left;">
                                        <span style="display: block; font-weight: 600; margin: 0 0 5px; font-size: 20;">
                                            <b>B&S Group<br>User Creation Form</b>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-top: 70px;">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td colspan="2">
                                                        <div class="">
                                                            <table class="border" style="border-collapse: collapse;">
                                                                <tr>
                                                                    <td colspan="5" rowspan="2"
                                                                        style="padding:5px 10px;text-align: center;">
                                                                        <b>PLPI Modules List<br>(Check that may apply)</b>
                                                                    </td>
                                                                </tr>
                                                                <tr></tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Regulatory Task"
                                                                                formControlName="regulatoryTask"
                                                                                name="Regulatory Task" value="">&nbsp;
                                                                            <label for="Regulatory Task"> Regulatory
                                                                                Task</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Regulatory Log"
                                                                                formControlName="regulatoryLog"
                                                                                name="Regulatory Log" value="">&nbsp;
                                                                            <label for="Regulatory Log"> Regulatory
                                                                                Log</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Product Add"
                                                                                formControlName="productAdd"
                                                                                name="Product Add" value="">&nbsp;
                                                                            <label for="Product Add"> Product
                                                                                Add</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                formControlName="productEMVOReport"
                                                                                id="Product/ Product EMVO Report"
                                                                                name="Product/ Product EMVO Report"
                                                                                value="">&nbsp;
                                                                            <label for="Product/ Product EMVO Report">
                                                                                Product/
                                                                                Product EMVO
                                                                                <br>&nbsp;&nbsp;Report</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Label Size"
                                                                                formControlName="labelSize"
                                                                                name="Label Size" value="">&nbsp;
                                                                            <label for="Label Size"> Label Size</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Carton Size"
                                                                                formControlName="cartonSize"
                                                                                name="Carton Size" value="">&nbsp;
                                                                            <label for="Carton Size"> Carton
                                                                                Size</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Manufacturer"
                                                                                formControlName="manufacturer"
                                                                                name="Manufacturer" value="">&nbsp;
                                                                            <label for="Manufacturer">
                                                                                Manufacturer</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="PPM Receive(Checker)"
                                                                                formControlName="pPMReceive"
                                                                                name="PPM Receive(Checker)"
                                                                                value="">&nbsp;
                                                                            <label for="PPM Receive(Checker)"> PPM
                                                                                Receive(Checker)</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Packing List"
                                                                                formControlName="packingList"
                                                                                name="Packing List" value="">&nbsp;
                                                                            <label for="Packing List"> Packing
                                                                                List</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Batch Checker"
                                                                                formControlName="batchChecker"
                                                                                name="Batch Checker" value="">&nbsp;
                                                                            <label for="Batch Checker"> Batch
                                                                                Checker</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="PPM Receive(Goods IN)"
                                                                                formControlName="PPMReceiveGoodsIN"
                                                                                name="PPM Receive(Goods IN)"
                                                                                value="">&nbsp;
                                                                            <label for="PPM Receive(Goods IN)"> PPM
                                                                                Receive(Goods IN)</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="Batch Allocation Supplier"
                                                                                formControlName="batchAllocationSupplier"
                                                                                name="Batch Allocation Supplier"
                                                                                value="">&nbsp;
                                                                            <label for="Batch Allocation Supplier">
                                                                                Batch
                                                                                Allocation<br>&nbsp;&nbsp;Supplier</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                formControlName="modifyBatchAllocation"
                                                                                id="Modify Batch Allocation"
                                                                                name="BModify Batch Allocation"
                                                                                value="">&nbsp;
                                                                            <label for="Modify Batch Allocation"> Modify
                                                                                Batch
                                                                                Allocation</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                formControlName="batchAllocationShiftChangeAuditLog"
                                                                                id="Batch Allocation Shift Change Audit Log"
                                                                                name="Batch Allocation Shift Change Audit Log"
                                                                                value="">&nbsp;
                                                                            <label
                                                                                for="Batch Allocation Shift Change Audit Log">
                                                                                Batch Allocation
                                                                                Shift<br>&nbsp;&nbsp;Change
                                                                                Audit Log</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="BNS Batch Add"
                                                                                formControlName="bNSbatchAdd"
                                                                                name="BNS Batch Add" value="">&nbsp;
                                                                            <label for="BNS Batch Add"> BNS Batch
                                                                                Add</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Print Label"
                                                                                formControlName="printlabel"
                                                                                name="Print Label" value="">&nbsp;
                                                                            <label for="Print Label"> Print
                                                                                Label</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Print Leaflet"
                                                                                formControlName="printLeaflet"
                                                                                name="Print Leaflet" value="">&nbsp;
                                                                            <label for="Print Leaflet"> Print
                                                                                Leaflet</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding: 10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Print Braille"
                                                                                formControlName="printBraille"
                                                                                name="Print Braille" value="">&nbsp;
                                                                            <label for="Print Braille"> Print
                                                                                Braille</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Printer"
                                                                                formControlName="Printer" name="Printer"
                                                                                value="">&nbsp;
                                                                            <label for="Printer"> Printer</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Leaflet Folding"
                                                                                formControlName="LeafletFolding"
                                                                                name="Leaflet Folding" value="">&nbsp;
                                                                            <label for="Leaflet Folding"> Leaflet
                                                                                Folding</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Double Check"
                                                                                formControlName="DoubleCheck"
                                                                                name="Double Check" value="">&nbsp;
                                                                            <label for="Double Check"> Double
                                                                                Check</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                formControlName="ProductionController"
                                                                                id="Production Controller"
                                                                                name="Production Controller"
                                                                                value="">&nbsp;
                                                                            <label for="Production Controller">
                                                                                Production
                                                                                Controller</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Assembly Room"
                                                                                formControlName="AssemblyRoom"
                                                                                name="Assembly Room" value="">&nbsp;
                                                                            <label for="Assembly Room"> Assembly
                                                                                Room</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Room Log"
                                                                                formControlName="RoomLog"
                                                                                name="Room Log" value="">&nbsp;
                                                                            <label for="Room Log"> Room Log</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Destroy Label"
                                                                                formControlName="DestroyLabel"
                                                                                name="Destroy Label" value="">&nbsp;
                                                                            <label for="Destroy Label"> Destroy
                                                                                Label</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="BNS Batch SEQ"
                                                                                formControlName="BNSBatchSEQ"
                                                                                name="BNS Batch SEQ" value="">&nbsp;
                                                                            <label for="BNS Batch SEQ"> BNS Batch
                                                                                SEQ</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Unlock User"
                                                                                formControlName="UnlockUser"
                                                                                name="Unlock User" value="">&nbsp;
                                                                            <label for="Unlock User"> Unlock
                                                                                User</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                formControlName="PPMReceiveStockCon"
                                                                                id="PPMReceive(Stock Con)"
                                                                                name="PPMReceive(StockCon)"
                                                                                value="">&nbsp;
                                                                            <label for="PPMReceive(Stock Con)"> PPM
                                                                                Receive
                                                                                (Stock Con)</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="PPM Audit"
                                                                                formControlName="PPMAudit"
                                                                                name="PPM Audit" value="">&nbsp;
                                                                            <label for="PPM Audit"> PPM Audit</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="PPM Audit History"
                                                                                formControlName="PPMAuditHistory"
                                                                                name="PPM Audit History History"
                                                                                value="">&nbsp;
                                                                            <label for="PPM Audit History"> PPM Audit
                                                                                History</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="Production Checking"
                                                                                formControlName="ProductionChecking"
                                                                                name="Production Checkingt"
                                                                                value="">&nbsp;
                                                                            <label for="Production Checking"> Production
                                                                                Checking</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="Release Log Sheet"
                                                                                formControlName="ReleaseLogSheett"
                                                                                name="Release Log Sheet" value="">&nbsp;
                                                                            <label for="Release Log Sheet"> Release Log
                                                                                Sheett</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Pre QP"
                                                                                name="Pre QP" formControlName="PreQP"
                                                                                value="">&nbsp;
                                                                            <label for="Pre QP"> Pre QP</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Released Labels"
                                                                                formControlName="ReleasedLabels"
                                                                                name="Released Labels" value="">&nbsp;
                                                                            <label for="Released Labels"> Released
                                                                                Labels</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Supp Info"
                                                                                formControlName="SuppInfo"
                                                                                name="Supp Info" value="">&nbsp;
                                                                            <label for="Supp Info"> Supp Info</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Recovery"
                                                                                formControlName="Recovery"
                                                                                name="Recovery" value="">&nbsp;
                                                                            <label for="Recovery"> Recovery</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Batch History"
                                                                                formControlName="BatchHistory"
                                                                                name="Batch History" value="">&nbsp;
                                                                            <label for="Batch History"> Batch
                                                                                History</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="IPC View Print"
                                                                                formControlName="IPCViewPrint"
                                                                                name="IPC View Print" value="">&nbsp;
                                                                            <label for="IPC View Print"> IPC View
                                                                                Print</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="Release Pack List"
                                                                                formControlName="ReleasePackList"
                                                                                name="Release Pack List" value="">&nbsp;
                                                                            <label for="Release Pack List"> Release Pack
                                                                                List</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="User Reg"
                                                                                formControlName="UserReg"
                                                                                name="User Reg" value="">&nbsp;
                                                                            <label for="User Reg"> User Reg</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="Check Performance"
                                                                                formControlName="CheckPerformance"
                                                                                name="Check Performance" value="">&nbsp;
                                                                            <label for="Check Performance"> Check
                                                                                Performance</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Users"
                                                                                name="Users" formControlName="Users"
                                                                                value="">&nbsp;
                                                                            <label for="Users"> Users</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="MISC" name="MISC"
                                                                                formControlName="MISC" value="">&nbsp;
                                                                            <label for="MISC"> MISC</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="Ecternal Batch Scanning Summary (FMD Goods In)"
                                                                                name="Ecternal Batch Scanning Summary (FMD Goods In)"
                                                                                formControlName="EcternalBatchScanning"
                                                                                value="">&nbsp;
                                                                            <label
                                                                                for="Ecternal Batch Scanning Summary (FMD Goods In)">
                                                                                Ecternal Batch Scanning
                                                                                /<br>&nbsp;&nbsp;
                                                                                Summary (FMD Goods In)</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="FMD Print Label"
                                                                                formControlName="FMDPrintLabel"
                                                                                name="FMD Print Label" value="">&nbsp;
                                                                            <label for="FMD Print Label"> FMD Print
                                                                                Label</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Decommissioning"
                                                                                formControlName="Decommissioning"
                                                                                name="Decommissioning" value="">&nbsp;
                                                                            <label for="Decommissioning">
                                                                                Decommissioning</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="EMVO Stolen and Intended for destruction (FMD)"
                                                                                formControlName="EMVOStolenandIntendedfordestruction"
                                                                                name="EMVO Stolen and Intended for destruction (FMD)"
                                                                                value="">&nbsp;
                                                                            <label
                                                                                for="EMVO Stolen and Intended for destruction (FMD)">
                                                                                EMVO Stolen and Intended
                                                                                for<br>&nbsp;&nbsp;
                                                                                destruction (FMD)</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox"
                                                                                id="EMVO Report (FMD)"
                                                                                formControlName="EMVOReport"
                                                                                name="EMVO Report (FMD)" value="">&nbsp;
                                                                            <label for="EMVO Report (FMD)"> EMVO Report
                                                                                (FMD)</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <table cellspacing="0" border="0"
                                                            style=" font-family:arial; margin: 0 auto; width: 100%; max-width: 1000px;">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="padding-top: 400px; text-align: right;">
                                                                        Page 3
                                                                        of 7</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <table cellspacing="0" border="0"
                                                            style=" font-family:arial; margin: 0 auto; width: 100%; max-width: 1000px;">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="padding-top: 30px; text-align: left;">
                                                                        Parent
                                                                        SOP SOP/IT/0001
                                                                    </td>
                                                                    <td style="padding-top: 30px; text-align: right;">
                                                                        Form
                                                                        Number
                                                                        F/IT/0001/001/v9</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="text-align: left;">Effective Date:
                                                                        23SEP2019
                                                                    </td>
                                                                    <td style="text-align: right;">Review Date:
                                                                        22SEP2021
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="padding: 20px 0;"></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- *********************** Page 3 Completed ************************ -->
                        <!-- <hr style="border-bottom: #707070; width: 1000px;" /> -->
                        <table>
                            <tbody>
                                <tr>
                                    <td style="padding: 80px 0px 20px 0px; text-align: left;">
                                        <span
                                            style="display: block; font-weight: 600; margin: 0 0 5px;font-size: 20;"><b>B&S
                                                Group<br>User Creation Form</b></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-top: 100px;">
                                        <table>
                                            <tbody>
                                                <tr class="hod">
                                                    <td colspan="2">
                                                        <div class="">
                                                            <table class="border" style="border-collapse: collapse;">
                                                                <tr>
                                                                    <td colspan="5" rowspan="2"
                                                                        style="padding:5px 10px;text-align: center;">
                                                                        <b>MCS<br>(Check that may
                                                                            apply)</b>
                                                                    </td>
                                                                </tr>
                                                                <tr></tr>
                                                                <tr>
                                                                    <td colspan="5" rowspan="2"
                                                                        style="padding:5px 10px;text-align: center;">
                                                                        <b>Access Type(select any one)<br>Buyer / QA-JR
                                                                            /
                                                                            QA-SR / QC-JR / QC-SR / QC-MR / Goods In-JR
                                                                            /
                                                                            Goods In-SR / Read Only</b>
                                                                    </td>
                                                                </tr>
                                                                <tr></tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Supplier"
                                                                                formControlName="mcsSupplier"
                                                                                name="Supplier" value="">&nbsp;
                                                                            <label for="Supplier"> Supplier</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Manufacturer1"
                                                                                formControlName="mcsManufacturer"
                                                                                name="Manufacturer1" value=""> &nbsp;
                                                                            <label for="Manufacturer1">
                                                                                Manufacturer</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Product Linking"
                                                                                formControlName="mcsProductLinking"
                                                                                name="Product Linking" value=""> &nbsp;
                                                                            <label for="Product Linking"> Product
                                                                                Linking</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="RM Code"
                                                                                formControlName="mcsRMCode"
                                                                                name="RM Code" value=""> &nbsp;
                                                                            <label for="RM Code"> RM Code</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Sampling"
                                                                                formControlName="mcsSampling"
                                                                                name="Sampling" value="">&nbsp;
                                                                            <label for="Sampling"> Sampling</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="RM Testing"
                                                                                formControlName="mcsRMTesting"
                                                                                name="RM Testing" value="">&nbsp;
                                                                            <label for="RM Testing"> RM Testing</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Receive Order"
                                                                                formControlName="mcsReceiveOrder"
                                                                                name="Receive Order" value="">&nbsp;
                                                                            <label for="Receive Order"> Receive
                                                                                Order</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="PCL" name="PCL"
                                                                                formControlName="mcsPCL" value="">&nbsp;
                                                                            <label for="PCL"> PCL</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="ReTest"
                                                                                name="ReTest"
                                                                                formControlName="mcsReTest"
                                                                                value="">&nbsp;
                                                                            <label for="ReTest"> ReTest</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Add RM Code"
                                                                                formControlName="mcsAddRMCode"
                                                                                name="Add RM Code" value="">&nbsp;
                                                                            <label for="Add RM Code"> Add RM
                                                                                Code</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Options"
                                                                                formControlName="mcsOptions"
                                                                                name="Options" value="">&nbsp;
                                                                            <label for="Options"> Options</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Container"
                                                                                formControlName="mcsContainer"
                                                                                name="Container" value="">&nbsp;
                                                                            <label for="Container"> Container</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="COA" name="COA"
                                                                                formControlName="mcsCOA" value="">&nbsp;
                                                                            <label for="COA"> COA</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="QC Prints"
                                                                                formControlName="mcsQCPrints"
                                                                                name="QC Prints" value="">&nbsp;
                                                                            <label for="QC Prints"> QC Prints</label>
                                                                        </form>
                                                                    </td>
                                                                    <td style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Water Protocol"
                                                                                formControlName="mcsWaterProtocol"
                                                                                name="Water Protocol" value="">&nbsp;
                                                                            <label for="Water Protocol"> Water
                                                                                Protocol</label>
                                                                        </form>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style="padding:10px 7px;">
                                                                        <form [formGroup]="hodForm">
                                                                            <input type="checkbox" id="Water Test"
                                                                                formControlName="mcsWaterTest"
                                                                                name="Water Test" value="">&nbsp;
                                                                            <label for="Water Test"> Water Test</label>
                                                                        </form>
                                                                    </td>
                                                                    <td colspan="2"></td>
                                                                    <td></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="hod">
                                                    <td style="padding-top: 20px;">
                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    <form [formGroup]="hodForm">
                                                                        <input type="text" name=""
                                                                            formControlName="Signature"
                                                                            style="width: 300px; border:1px solid; padding: 0 5px; text-align: center;"
                                                                            placeholder="Signaure Here" class="h-30">
                                                                    </form>
                                                                </td>
                                                                <td></td>
                                                                <td>
                                                                    <form [formGroup]="hodForm">
                                                                        <input type="date" name=""
                                                                            formControlName="mcsdate"
                                                                            style="width: 300px; border:1px solid; padding: 0 5px; position: relative;left: 400px;"
                                                                            placeholder="dd/mm/yyyy" class="h-30">
                                                                    </form>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="position: relative; left: 12px; top: 10px;">
                                                                    Head
                                                                    of Department (Name & Signature)
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-top: 60px;">
                                                        <b>
                                                            <p
                                                                style="text-transform: uppercase; font-size: 20px; margin: 0; border-bottom: 2px solid; width: max-content;">
                                                                to be filled in by - software department
                                                            </p>
                                                        </b>
                                                    </td>
                                                </tr>
                                                <tr class="softwareDepartment">
                                                    <td>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <table class="border"
                                                                            style="border-collapse: collapse; margin-top: 30px;">
                                                                            <tr>
                                                                                <td colspan="5" rowspan="2"
                                                                                    style="padding:5px 10px;text-align: center;">
                                                                                    <b>Application Access
                                                                                        Requirement<br>(Check that may
                                                                                        apply)</b>
                                                                                </td>
                                                                            </tr>
                                                                            <tr></tr>
                                                                            <tr>
                                                                                <td colspan="2"
                                                                                    style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            formControlName="SpecialOrders"
                                                                                            id="Special Orders1"
                                                                                            name="Special Orders1"
                                                                                            value="">&nbsp;
                                                                                        <label for="Special Orders1">
                                                                                            Special
                                                                                            Orders</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td colspan="2"
                                                                                    style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            formControlName="FinanceKPI"
                                                                                            id="Finance KPI1"
                                                                                            name="Finance KPI1"
                                                                                            value="">&nbsp;
                                                                                        <label for="Finance KPI1">
                                                                                            Finance
                                                                                            KPI</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            id="PLPI_1"
                                                                                            formControlName="PLPI"
                                                                                            name="PLPI_1"
                                                                                            value="">&nbsp;
                                                                                        <label for="PLPI_1">
                                                                                            PLPI</label>
                                                                                    </form>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2"
                                                                                    style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            formControlName="CustomerService"
                                                                                            id="CustomerService1"
                                                                                            name="CustomerService1"
                                                                                            value="">&nbsp;
                                                                                        <label for="CustomerService1">
                                                                                            Customer
                                                                                            Service</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td colspan="2"
                                                                                    style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            formControlName="FinanceEnterprise"
                                                                                            id="Finance Enterprise1"
                                                                                            name="Finance Enterprise1"
                                                                                            value="">&nbsp;
                                                                                        <label
                                                                                            for="Finance Enterprise1">
                                                                                            Finance Enterprise</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            id="WMS_1"
                                                                                            formControlName="WMS"
                                                                                            name="WMS_1" value="">&nbsp;
                                                                                        <label for="WMS_1"> WMS</label>
                                                                                    </form>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2"
                                                                                    style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            formControlName="AnnualPlanner"
                                                                                            id="Annual Planner1"
                                                                                            name="Annual Planner1"
                                                                                            value="">&nbsp;
                                                                                        <label for="Annual Planner1">
                                                                                            Annual
                                                                                            Planner</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td colspan="2"
                                                                                    style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            formControlName="BNSSOPCustomerOrder"
                                                                                            id="BNS-SOP (Customer Order)1"
                                                                                            name="BNS-SOP (Customer Order)1"
                                                                                            value="">&nbsp;
                                                                                        <label
                                                                                            for="BNS-SOP (Customer Order)1">
                                                                                            BNS-SOP (Customer
                                                                                            Order)</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            id="TPS_1"
                                                                                            formControlName="TPS"
                                                                                            name="TPS_1" value="">&nbsp;
                                                                                        <label for="TPS_1"> TPS</label>
                                                                                    </form>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2"
                                                                                    style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            formControlName="PurchaseOrder"
                                                                                            id="Purchase Order1"
                                                                                            name="Purchase Order1"
                                                                                            value="">&nbsp;
                                                                                        <label for="Purchase Order1">
                                                                                            Purchase
                                                                                            Order</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td colspan="2"
                                                                                    style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            formControlName="RequestPuschaseOrder"
                                                                                            id="Request Puschase Order1"
                                                                                            name="Request Puschase Order1"
                                                                                            value="">&nbsp;
                                                                                        <label
                                                                                            for="Request Puschase Order1">
                                                                                            Request Puschase
                                                                                            Order</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            id="MCS_1"
                                                                                            formControlName="MCS"
                                                                                            name="MCS_1" value="">&nbsp;
                                                                                        <label for="MCS_1"> MCS</label>
                                                                                    </form>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2"
                                                                                    style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            formControlName="PriceMatrix"
                                                                                            id="Price Matrix1"
                                                                                            name="Price Matrix1"
                                                                                            value="">&nbsp;
                                                                                        <label for="Price Matrix1">
                                                                                            Price
                                                                                            Matrix</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td colspan="2"
                                                                                    style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            formControlName="RSMManagement"
                                                                                            id="RSM-Management1"
                                                                                            name="RSM-Management1"
                                                                                            value="">&nbsp;
                                                                                        <label for="RSM-Management1">
                                                                                            RSM-Management</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding:10px 7px;">
                                                                                    <form
                                                                                        [formGroup]="softwareDepartmentForm">

                                                                                        <input type="checkbox"
                                                                                            id="DMS_1"
                                                                                            formControlName="DMS"
                                                                                            name="DMS_1" value="">&nbsp;
                                                                                        <label for="DMS_1"> DMS</label>
                                                                                    </form>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2" style="padding:10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox"
                                                                                            id="Colorama Logistic1"
                                                                                            formControlName="ColoramaLogistic"
                                                                                            name="Colorama Logistic1"
                                                                                            value="">&nbsp;
                                                                                        <label for="Colorama Logistic1"> Colorama
                                                                                            Logistic</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td colspan="2" style="padding:10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox"
                                                                                            id="Project Management1"
                                                                                            formControlName="ProjectManagement"
                                                                                            name="Project Management1"
                                                                                            value="">&nbsp;
                                                                                        <label for="Project Management1"> Project
                                                                                            Management</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding:10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox" id="PMS_1"
                                                                                            name="PMS_1" formControlName="PMS"
                                                                                            value="">&nbsp;
                                                                                        <label for="PMS_1"> PMS</label>
                                                                                    </form>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2" style="padding:10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox"
                                                                                            id="Room Booking System1"
                                                                                            formControlName="RoomBookingSystem"
                                                                                            name="Room Booking System1"
                                                                                            value="">&nbsp;
                                                                                        <label for="Room Booking System1"> Room
                                                                                            Booking
                                                                                            System</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td colspan="2" style="padding:10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox"
                                                                                            formControlName="WarehouseStockControl"
                                                                                            id="Warehouse Stock Control1"
                                                                                            name="Warehouse Stock Control1"
                                                                                            value="">&nbsp;
                                                                                        <label for="Warehouse Stock Control1">
                                                                                            Warehouse
                                                                                            Stock Control</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding:10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox" id="POD_1"
                                                                                            name="POD_1" formControlName="POD"
                                                                                            value="">&nbsp;
                                                                                        <label for="POD_1"> POD</label>
                                                                                    </form>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2" style="padding:10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox" id="BNS-Loyalty1"
                                                                                            formControlName="BNSLoyalty"
                                                                                            name="BNS-Loyalty1" value="">&nbsp;
                                                                                        <label for="BNS-Loyalty1">
                                                                                            BNS-Loyalty</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td colspan="2" style="padding:10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox"
                                                                                            formControlName="PurchaseOrderindia"
                                                                                            id="Purchase Order india1"
                                                                                            name="Purchase Order india1"
                                                                                            value="">&nbsp;
                                                                                        <label for="Purchase Order india1"> Purchase
                                                                                            Order
                                                                                            india</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding:10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox" id="HR Recruitment1"
                                                                                            formControlName="HRRecruitment"
                                                                                            name="HR Recruitment1" value="">&nbsp;
                                                                                        <label for="HR Recruitment1"> HR
                                                                                            Recruitment</label>
                                                                                    </form>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2" style="padding:10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox"
                                                                                            formControlName="PayrollSystemIndia"
                                                                                            id="Payroll System India1"
                                                                                            name="Payroll System India1"
                                                                                            value="">&nbsp;
                                                                                        <label for="Payroll System India1"> Payroll
                                                                                            System
                                                                                            India</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td colspan="2" style="padding:10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox" id="Report Server1"
                                                                                            formControlName="ReportServer"
                                                                                            name="Report Server1" value="">&nbsp;
                                                                                        <label for="Report Server1"> Report
                                                                                            Server</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding:10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox"
                                                                                            id="Meeting Management1"
                                                                                            formControlName="MeetingManagement"
                                                                                            name="Meeting Management1"
                                                                                            value="">&nbsp;
                                                                                        <label for="Meeting Management1"> Meeting
                                                                                            Management</label>
                                                                                    </form>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2" style="padding: 10px 10px;">
                                                                                    <b>Access
                                                                                        Rights:</b>
                                                                                </td>
                                                                                <td style="padding: 10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox" id="Manager_1"
                                                                                            formControlName="Manager"
                                                                                            name="Manager_1" value="">&nbsp;
                                                                                        <label for="Manager_1"> Manager</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding: 10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox" id="Normal_1"
                                                                                            formControlName="Normal" name="Normal_1"
                                                                                            value="">&nbsp;
                                                                                        <label for="Normal_1"> Normal</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td rowspan="3" colspan="1"
                                                                                    style="padding: 10px 10px;">
                                                                                    <b>Site:</b>
                                                                                </td>
                                                                                <td style="padding: 10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox" id="SPE1" name="SPE1"
                                                                                            formControlName="SPE" value="">&nbsp;
                                                                                        <label for="SPE1"> SPE</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding: 10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox" id="SPE-L_1"
                                                                                            formControlName="SPEL" name="SPE-L_1"
                                                                                            value="">&nbsp;
                                                                                        <label for="SPE-L_1"> SPE-L</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding: 10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox" id="WHO-L_1"
                                                                                            formControlName="WHOL" name="WHO-L_1"
                                                                                            value="">&nbsp;
                                                                                        <label for="WHO-L_1"> WHO-L</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding: 10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox" id="LAX1" name="LAX1"
                                                                                            formControlName="LAX" value="">&nbsp;
                                                                                        <label for="LAX1"> LAX</label>
                                                                                    </form>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style="padding: 10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox" id="CON-L_1"
                                                                                            formControlName="CONL" name="CON-L_1"
                                                                                            value="">&nbsp;
                                                                                        <label for="CON-L_1"> CON-L</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding: 10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox" id="WHO-G_1"
                                                                                            formControlName="WHOG" name="WHO-G_1"
                                                                                            value="">&nbsp;
                                                                                        <label for="WHO-G_1"> WHO-G</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td style="padding: 10px 7px;">
                                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                                        <input type="checkbox" id="CON-G_1"
                                                                                            formControlName="CONG" name="CON-G_1"
                                                                                            value="">&nbsp;
                                                                                        <label for="CON-G_1"> CON-G</label>
                                                                                    </form>
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <table cellspacing="0" border="0"
                                                            style=" font-family:arial; margin: 0 auto; width: 100%; max-width: 1000px;">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="padding-top: 90px; text-align: right;">
                                                                        Page 4
                                                                        of 7</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <table cellspacing="0" border="0"
                                                            style=" font-family:arial; margin: 0 auto; width: 100%; max-width: 1000px;">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="padding-top: 30px; text-align: left;">
                                                                        Parent
                                                                        SOP SOP/IT/0001
                                                                    </td>
                                                                    <td style="padding-top: 30px; text-align: right;">
                                                                        Form
                                                                        Number
                                                                        F/IT/0001/001/v9</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="text-align: left;">Effective Date:
                                                                        23SEP2019
                                                                    </td>
                                                                    <td style="text-align: right;">Review Date:
                                                                        22SEP2021
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="padding: 20px 0;"></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- *********************** Page 4 Completed ************************ -->
                        <!-- <hr style="border-bottom: #707070; width: 1000px;" /> -->
                        <table>
                            <tbody>
                                <tr>
                                    <td style="padding: 80px 0px 20px 0px; text-align: left;">
                                        <span
                                            style="display: block; font-weight: 600; margin: 0 0 5px;font-size: 20;"><b>B&S
                                                Group<br>User Creation Form</b></span>
                                    </td>
                                </tr>
                                <tr class="softwareDepartment">
                                    <td>
                                        <table>
                                            <tbody>                                                
                                                <tr>
                                                    <td style="padding-top: 100px;">
                                                        <table cellspacing="0" border="0"
                                                            style=" font-family:arial; margin: 0 auto; width: 100%; max-width: 1000px; ">
                                                            <tbody>
                                                                <tr></tr>
                                                                <tr>
                                                                    <td style="padding: 20px 0 20px;"> Notes:</td>
                                                                    <td style="position: relative; left: 50px;">
                                                                        <textarea id="" name="" rows="1" cols="126"
                                                                            style="padding: 5px" class="h-30"></textarea>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <table cellspacing="0" border="0"
                                                            style=" font-family:arial; margin: 0 auto; width: 100%; max-width: 1000px;  ">
                                                            <tbody>
                                                                <tr></tr>
                                                                <tr>
                                                                    <td style="padding: 10px 0 10px;"> Application ID:
                                                                    </td>
                                                                    <td style="position: relative; right: -2px;">
                                                                        <textarea id="" name="" rows="2" cols="126"
                                                                            style="padding: 5px"></textarea>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr class="softwareDepartment">
                                    <td colspan="2">

                                        <table class="border" style="border-collapse: collapse; margin-top: 20px;">
                                            <tr>
                                                <td colspan="5" rowspan="2"
                                                    style="padding:5px 10px;text-align: center;">
                                                    <b>PLPI Modules List<br>(Check that may apply)</b>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Regulatory Task1"
                                                            name="Regulatory Task1" formControlName="RegulatoryTask"
                                                            value="">&nbsp;
                                                        <label for="Regulatory Task1"> Regulatory Task</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding: 10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Regulatory Log1"
                                                            name="Regulatory Log1" formControlName="RegulatoryLog"
                                                            value="">&nbsp;
                                                        <label for="Regulatory Log1"> Regulatory Log</label>
                                                    </form>
                                                </td>
                                                <td style="padding: 10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Product Add1" name="Product Add1"
                                                            formControlName="ProductAdd" value="">&nbsp;
                                                        <label for="Product Add1"> Product Add</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Product/ Product EMVO Report1"
                                                            formControlName="ProductEMVOReport"
                                                            name="Product/ Product EMVO Report1" value="">&nbsp;
                                                        <label for="Product/ Product EMVO Report1"> Product/ Product
                                                            EMVO
                                                            <br>&nbsp;&nbsp;Report</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding: 10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Label Size1" name="Label Size1"
                                                            formControlName="LabelSize" value="">&nbsp;
                                                        <label for="Label Size1"> Label Size</label>
                                                    </form>
                                                </td>
                                                <td style="padding: 10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Carton Size1" name="Carton Size1"
                                                            formControlName="CartonSize" value="">&nbsp;
                                                        <label for="Carton Size1"> Carton Size</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Manufacturer1" name="Manufacturer1"
                                                            formControlName="Manufacturer" value="">&nbsp;
                                                        <label for="Manufacturer1"> Manufacturer</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="PPM Receive(Checker)1"
                                                            formControlName="PPMReceiveChecker"
                                                            name="PPM Receive(Checker)1" value="">&nbsp;
                                                        <label for="PPM Receive(Checker)1"> PPM Receive(Checker)</label>
                                                    </form>
                                                </td>
                                                <td style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Packing List1" name="Packing List1"
                                                            formControlName="PackingList" value="">&nbsp;
                                                        <label for="Packing List1"> Packing List</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Batch Checker1" name="Batch Checker1"
                                                            formControlName="BatchChecker" value="">&nbsp;
                                                        <label for="Batch Checker1"> Batch Checker</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding: 10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="PPM Receive(Goods IN)1"
                                                            formControlName="PPMReceiveGoodsIN"
                                                            name="PPM Receive(Goods IN)1" value="">&nbsp;
                                                        <label for="PPM Receive(Goods IN)1"> PPM Receive(Goods
                                                            IN)</label>
                                                    </form>
                                                </td>
                                                <td style="padding: 10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Batch Allocation Supplier1"
                                                            formControlName="BatchAllocationSupplier"
                                                            name="Batch Allocation Supplier1" value="">&nbsp;
                                                        <label for="Batch Allocation Supplier1"> Batch
                                                            Allocation<br>&nbsp;&nbsp;Supplier</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Modify Batch Allocation1"
                                                            formControlName="ModifyBatchAllocation"
                                                            name="BModify Batch Allocation1" value="">&nbsp;
                                                        <label for="Modify Batch Allocation1"> Modify Batch
                                                            Allocation</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding: 10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox"
                                                            id="Batch Allocation Shift Change Audit Log1"
                                                            formControlName="BatchAllocationShiftChangeAuditLog"
                                                            name="Batch Allocation Shift Change Audit Log1"
                                                            value="">&nbsp;
                                                        <label for="Batch Allocation Shift Change Audit Log1"> Batch
                                                            Allocation
                                                            Shift<br>&nbsp;&nbsp;Change Audit Log</label>
                                                    </form>
                                                </td>
                                                <td style="padding: 10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="BNS Batch Add1" name="BNS Batch Add1"
                                                            formControlName="BNSBatchAdd" value="">&nbsp;
                                                        <label for="BNS Batch Add1"> BNS Batch Add</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding: 10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Print Label1" name="Print Label1"
                                                            formControlName="PrintLabel" value="">&nbsp;
                                                        <label for="Print Label1"> Print Label</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding: 10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Print Leaflet1" name="Print Leaflet1"
                                                            formControlName="PrintLeaflet" value="">&nbsp;
                                                        <label for="Print Leaflet1"> Print Leaflet</label>
                                                    </form>
                                                </td>
                                                <td style="padding: 10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Print Braille1" name="Print Braille1"
                                                            formControlName="PrintBraille" value="">&nbsp;
                                                        <label for="Print Braille1"> Print Braille</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Printer1" formControlName="Printer"
                                                            name="Printer1" value="">&nbsp;
                                                        <label for="Printer1"> Printer</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Leaflet Folding1"
                                                            name="Leaflet Folding1" formControlName="LeafletFolding"
                                                            value="">&nbsp;
                                                        <label for="Leaflet Folding1"> Leaflet Folding</label>
                                                    </form>
                                                </td>
                                                <td style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Double Check1" name="Double Check1"
                                                            formControlName="DoubleCheck" value="">&nbsp;
                                                        <label for="Double Check1"> Double Check</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Production Controller1"
                                                            formControlName="ProductionController"
                                                            name="Production Controller1" value="">&nbsp;
                                                        <label for="Production Controller1"> Production
                                                            Controller</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Assembly Room1" name="Assembly Room1"
                                                            formControlName="AssemblyRoom" value="">&nbsp;
                                                        <label for="Assembly Room1"> Assembly Room</label>
                                                    </form>
                                                </td>
                                                <td style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Room Log1" name="Room Log1"
                                                            formControlName="RoomLog" value="">&nbsp;
                                                        <label for="Room Log1"> Room Log</label>
                                                    </form>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Destroy Label1"
                                                            formControlName="DestroyLabel"
                                                            name="Destroy Label1" value="">&nbsp;
                                                        <label for="Destroy Label1"> Destroy
                                                            Label</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="BNS Batch SEQ1"
                                                            formControlName="BNSBatchSEQ"
                                                            name="BNS Batch SEQ1" value="">&nbsp;
                                                        <label for="BNS Batch SEQ1"> BNS Batch
                                                            SEQ</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Unlock User1"
                                                            formControlName="UnlockUser"
                                                            name="Unlock User1" value="">&nbsp;
                                                        <label for="Unlock User1"> Unlock User</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox"
                                                            id="PPMReceive(Stock Con)1"
                                                            formControlName="PPMReceiveStockCon"
                                                            name="PPMReceive(StockCon)1" value="">&nbsp;
                                                        <label for="PPMReceive(Stock Con)1"> PPM Receive
                                                            (Stock
                                                            Con)</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="PPM Audit1"
                                                            formControlName="PPMAudit" name="PPM Audit1"
                                                            value="">&nbsp;
                                                        <label for="PPM Audit1"> PPM Audit</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="PPM Audit History1"
                                                            formControlName="PPMAuditHistory"
                                                            name="PPM Audit History History1"
                                                            value="">&nbsp;
                                                        <label for="PPM Audit History1"> PPM Audit
                                                            History</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Production Checking1"
                                                            formControlName="ProductionChecking"
                                                            name="Production Checkingt1" value="">&nbsp;
                                                        <label for="Production Checking1"> Production
                                                            Checking</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Release Log Sheet1"
                                                            formControlName="ReleaseLogSheett"
                                                            name="Release Log Sheet1" value="">&nbsp;
                                                        <label for="Release Log Sheet1"> Release Log
                                                            Sheett</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Pre QP1"
                                                            name="Pre QP1" formControlName="PreQP"
                                                            value="">&nbsp;
                                                        <label for="Pre QP1"> Pre QP</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Released Labels1"
                                                            formControlName="ReleasedLabels"
                                                            name="Released Labels1" value="">&nbsp;
                                                        <label for="Released Labels1"> Released
                                                            Labels</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Supp Info1"
                                                            formControlName="SuppInfo" name="Supp Info1"
                                                            value="">&nbsp;
                                                        <label for="Supp Info1"> Supp Info</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Recovery1"
                                                            formControlName="Recovery" name="Recovery1"
                                                            value="">&nbsp;
                                                        <label for="Recovery1"> Recovery</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Batch History1"
                                                            formControlName="BatchHistory"
                                                            name="Batch History1" value="">&nbsp;
                                                        <label for="Batch History1"> Batch
                                                            History</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="IPC View Print1"
                                                            formControlName="IPCViewPrint"
                                                            name="IPC View Print1" value="">&nbsp;
                                                        <label for="IPC View Print1"> IPC View
                                                            Print</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Release Pack List1"
                                                            formControlName="ReleasePackList"
                                                            name="Release Pack List1" value="">&nbsp;
                                                        <label for="Release Pack List1"> Release Pack
                                                            List</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="User Reg1"
                                                            formControlName="UserReg" name="User Reg1"
                                                            value="">&nbsp;
                                                        <label for="User Reg1"> User Reg</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Check Performance1"
                                                            formControlName="CheckPerformance"
                                                            name="Check Performance1" value="">&nbsp;
                                                        <label for="Check Performance1"> Check
                                                            Performance</label>
                                                    </form>

                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Users1" name="Users1"
                                                            formControlName="Users" value="">&nbsp;
                                                        <label for="Users1"> Users</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="MISC1" name="MISC1"
                                                            formControlName="MISC" value="">&nbsp;
                                                        <label for="MISC1"> MISC</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox"
                                                            formControlName="EcternalBatchScanningSummary"
                                                            id="Ecternal Batch Scanning Summary (FMD Goods In)1"
                                                            name="Ecternal Batch Scanning Summary (FMD Goods In)1"
                                                            value="">&nbsp;
                                                        <label
                                                            for="Ecternal Batch Scanning Summary (FMD Goods In)1">
                                                            Ecternal Batch Scanning /<br>&nbsp;&nbsp;
                                                            Summary
                                                            (FMD Goods In)</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="FMD Print Label1"
                                                            formControlName="FMDPrintLabel"
                                                            name="FMD Print Label1" value="">&nbsp;
                                                        <label for="FMD Print Label1"> FMD Print
                                                            Label</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="Decommissioning1"
                                                            formControlName="Decommissioning"
                                                            name="Decommissioning1" value="">&nbsp;
                                                        <label for="Decommissioning1">
                                                            Decommissioning</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox"
                                                            formControlName="EMVOStolenandIntendedfordestruction"
                                                            id="EMVO Stolen and Intended for destruction (FMD)1"
                                                            name="EMVO Stolen and Intended for destruction (FMD)1"
                                                            value="">&nbsp;
                                                        <label
                                                            for="EMVO Stolen and Intended for destruction (FMD)1">
                                                            EMVO Stolen and Intended for<br>&nbsp;&nbsp;
                                                            destruction (FMD)</label>
                                                    </form>
                                                </td>
                                                <td colspan="2" style="padding:10px 7px;">
                                                    <form [formGroup]="softwareDepartmentForm">
                                                        <input type="checkbox" id="EMVO Report (FMD)1"
                                                            formControlName="EMVOReportFMD"
                                                            name="EMVO Report (FMD)1" value="">&nbsp;
                                                        <label for="EMVO Report (FMD)1"> EMVO Report
                                                            (FMD)</label>
                                                    </form>
                                                </td>
                                            </tr>

                                        </table>

                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <table cellspacing="0" border="0"
                                            style=" font-family:arial; margin: 220px auto  auto; width: 100%; max-width: 1000px;">
                                            <tbody>
                                                <tr>
                                                    <td style="padding-top: 50px; text-align: right;">Page 5
                                                        of 7</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table cellspacing="0" border="0"
                                            style=" font-family:arial; margin: 0 auto; width: 100%; max-width: 1000px;">
                                            <tbody>
                                                <tr>
                                                    <td style="padding-top: 30px; text-align: left;">Parent
                                                        SOP SOP/IT/0001
                                                    </td>
                                                    <td style="padding-top: 30px; text-align: right;">Form
                                                        Number
                                                        F/IT/0001/001/v9</td>
                                                </tr>
                                                <tr>
                                                    <td style="text-align: left;">Effective Date: 23SEP2019
                                                    </td>
                                                    <td style="text-align: right;">Review Date: 22SEP2021
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="padding: 20px 0;"></td>
                                                </tr>
                                            </tbody>
                                            <!-- *********************** Page 5 Completed ************************ -->
                                        </table>
                                    </td>
                                </tr>


                            </tbody>
                        </table>


                        <!-- <hr style="border-bottom: #707070; width: 1000px;" /> -->
                        <table class="softwareDepartment">
                            <tbody>
                                <tr>
                                    <td style="padding: 80px 20px 10px 15px; text-align: left;">
                                        <span
                                            style="display: block; font-weight: 600; margin: 0 0 5px;font-size: 20;"><b>B&S
                                                Group<br>User Creation Form</b></span>
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td>
                                <table cellspacing="0" border="0" style=" font-family:arial; margin:100px 0; width: 100%;">
                                            <tbody>
                                                <tr>
                                                    <td colspan="2">

                                                        <table class="border" style="border-collapse: collapse;">
                                                            <tr>
                                                                <td colspan="5" rowspan="2"
                                                                    style="padding:5px 10px;text-align: center;">
                                                                    <b>MCS<br>(Check that may apply)</b>
                                                                </td>
                                                            </tr>
                                                            <tr></tr>
                                                            <tr>
                                                                <td colspan="5" rowspan="2"
                                                                    style="padding:5px 10px;text-align: center;">
                                                                    <b>Access Type(select any one)<br>Buyer / QA-JR /
                                                                        QA-SR / QC-JR / QC-SR / QC-MR / Goods In-JR /
                                                                        Goods In-SR / Read Only</b>
                                                                </td>
                                                            </tr>
                                                            <tr></tr>
                                                            <tr>
                                                                <td colspan="2" style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="Supplier2"
                                                                            formControlName="mcsSupplier"
                                                                            name="Supplier2" value="">&nbsp;
                                                                        <label for="Supplier2"> Supplier</label>
                                                                    </form>
                                                                </td>
                                                                <td colspan="2" style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="Manufacturer2"
                                                                            formControlName="mcsManufacturer"
                                                                            name="Manufacturer2" value="">&nbsp;
                                                                        <label for="Manufacturer2"> Manufacturer</label>
                                                                    </form>
                                                                </td>
                                                                <td style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="Product Linking2"
                                                                            formControlName="mcsProductLinking"
                                                                            name="Product Linking2" value="">&nbsp;
                                                                        <label for="Product Linking2"> Product
                                                                            Linking</label>
                                                                    </form>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2" style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="RM Code2"
                                                                            name="RM Code2" formControlName="mcsRMCode"
                                                                            value="">&nbsp;
                                                                        <label for="RM Code2"> RM Code</label>
                                                                    </form>
                                                                </td>
                                                                <td colspan="2" style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="Sampling2"
                                                                            formControlName="mmcsSampling"
                                                                            name="Sampling2" value="">&nbsp;
                                                                        <label for="Sampling2"> Sampling</label>
                                                                    </form>
                                                                </td>
                                                                <td style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="RM Testing2"
                                                                            formControlName="mcsRMTesting"
                                                                            name="RM Testing2" value="">&nbsp;
                                                                        <label for="RM Testing2"> RM Testing</label>
                                                                    </form>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2" style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="Receive Order2"
                                                                            formControlName="mcsReceiveOrder"
                                                                            name="Receive Order2" value="">&nbsp;
                                                                        <label for="Receive Order2"> Receive
                                                                            Order</label>
                                                                    </form>
                                                                </td>
                                                                <td colspan="2" style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="PCL2" name="PCL2"
                                                                            formControlName="mcsPCL" value="">&nbsp;
                                                                        <label for="PCL2"> PCL</label>
                                                                    </form>
                                                                </td>
                                                                <td style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="ReTest2"
                                                                            name="ReTest2" formControlName="mcsReTest"
                                                                            value="">&nbsp;
                                                                        <label for="ReTest2"> ReTest</label>
                                                                    </form>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2" style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="Add RM Code2"
                                                                            formControlName="mcsAddRMCode"
                                                                            name="Add RM Code2" value="">&nbsp;
                                                                        <label for="Add RM Code2"> Add RM Code</label>
                                                                    </form>
                                                                </td>
                                                                <td colspan="2" style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="Options2"
                                                                            name="Options2" formControlName="mcsOptions"
                                                                            value="">&nbsp;
                                                                        <label for="Options2"> Options</label>
                                                                    </form>
                                                                </td>
                                                                <td style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="Container2"
                                                                            formControlName="mcsContainer"
                                                                            name="Container2" value="">&nbsp;
                                                                        <label for="Container2"> Container</label>
                                                                    </form>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2" style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="COA2" name="COA2"
                                                                            formControlName="mcsCOA" value="">&nbsp;
                                                                        <label for="COA2"> COA</label>
                                                                    </form>
                                                                </td>
                                                                <td colspan="2" style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="QC Prints2"
                                                                            formControlName="mcsQCPrints"
                                                                            name="QC Prints2" value="">&nbsp;
                                                                        <label for="QC Prints2"> QC Prints</label>
                                                                    </form>
                                                                </td>
                                                                <td style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="Water Protocol2"
                                                                            formControlName="mcsWaterProtocol"
                                                                            name="Water Protocol2" value="">&nbsp;
                                                                        <label for="Water Protocol2"> Water
                                                                            Protocol</label>
                                                                    </form>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2" style="padding:10px 7px;">
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="checkbox" id="Water Test2"
                                                                            formControlName="mcsWaterTest"
                                                                            name="Water Test2" value="">&nbsp;
                                                                        <label for="Water Test2"> Water Test</label>
                                                                    </form>
                                                                </td>
                                                                <td colspan="2"></td>
                                                                <td></td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-top: 25px;">
                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="text" name=""
                                                                            formControlName="Signature" class="h-30"
                                                                            style="width: 300px; border:1px solid; padding: 0 5px; text-align: center;"
                                                                            placeholder="Signaure Here">
                                                                    </form>
                                                                </td>
                                                                <td></td>
                                                                <td>
                                                                    <form [formGroup]="softwareDepartmentForm">
                                                                        <input type="date" name=""
                                                                            formControlName="date"
                                                                            style="width: 300px; border:1px solid; padding: 0 5px; position: relative; left: 400px;"
                                                                            placeholder="dd/mm/yyyy" class="h-30">
                                                                    </form>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="position: relative; left: 12px; top: 10px;">
                                                                    Software Engineer (Name & Signature)
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <table cellspacing="0" border="0"
                                                            style=" font-family:arial; margin: 800px auto auto; width: 100%; max-width: 1000px;">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="padding-top: 30px; text-align: right;">
                                                                        Page 6
                                                                        of 7</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <table cellspacing="0" border="0"
                                                            style=" font-family:arial; margin: 0 auto; width: 100%; max-width: 1000px;">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="padding-top: 30px; text-align: left;">
                                                                        Parent
                                                                        SOP SOP/IT/0001</td>
                                                                    <td style="padding-top: 30px; text-align: right;">
                                                                        Form
                                                                        Number F/IT/0001/001/v9</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="text-align: left;">Effective Date:
                                                                        23SEP2019
                                                                    </td>
                                                                    <td style="text-align: right;">Review Date:
                                                                        22SEP2021
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody> <!-- **********Page 6 Completed******** -->
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>


                        <!-- <hr style="border-bottom: #707070; width: 1000px;" /> -->
                        <table>
                            <tbody>
                                <tr>
                                    <td style="padding: 80px 20px 10px 15px; text-align: left;">
                                        <span
                                            style="display: block; font-weight: 600; margin: 0 0 5px;font-size: 20;"><b>B&S
                                                Group<br>User Creation Form</b></span>
                                    </td>
                                </tr>
                                <tr class="itDepartment">
                                    <td colspan="4">
                                        <table style="width: 100%;">
                                            <tr>
                                                <table cellspacing="0" border="0"
                                                    style=" font-family:arial; margin: 100px auto auto; width: 100%;padding: 15px;">
                                                    <tr>
                                                        <td>
                                                            <b>
                                                                <p
                                                                    style="text-transform: uppercase; font-size: 18px !important; margin: 0; border-bottom: 2px solid; width: max-content; font-size: 16px;">
                                                                    to be filled in by - IT department
                                                                </p>
                                                            </b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="font-size: 17px; padding-top: 25px;">
                                                            <form [formGroup]="itDepartmentForm">
                                                                <p>
                                                                    <b>Assigned User ID:</b>
                                                                    <input type="text" name=""
                                                                        formControlName="AssignedUserID"
                                                                        style="border: 1px solid; width: 40%; padding: 5px 10px;" class="h-30">
                                                                </p>
                                                            </form>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <table class="border" style="border-collapse: collapse;">
                                                    <tr>
                                                        <td colspan="2" style="padding:10px 0px;text-align: center;">
                                                            <b>File Server Access</b>
                                                        </td>
                                                        <td colspan="2" style="padding:10px 0px;text-align: center;">
                                                            <b>Internet Firewall Access Assigned</b>
                                                        </td>
                                                        <td colspan="2" style="padding:10px 0px;text-align: center;">
                                                            <b>Windows Domain Assigned</b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" style="padding:10px 7px;">
                                                            <form [formGroup]="itDepartmentForm">
                                                                <input type="checkbox" id="G Drive" name="G Drive"
                                                                    formControlName="GDrive" value="">&nbsp;
                                                                <label for="G Drive"> G Drive</label>
                                                            </form>
                                                        </td>
                                                        <td colspan="2" style="padding:10px 7px;">
                                                            <form [formGroup]="itDepartmentForm">
                                                                <input type="checkbox" id="LimitedInternetAccess"
                                                                    formControlName="LimitedInternetAccess"
                                                                    name="LimitedInternetAccess" value="">&nbsp;
                                                                <label for="LimitedInternetAccess"> Limited Internet
                                                                    Access</label>
                                                            </form>
                                                        </td>
                                                        <td style="padding: 10px 7px;">
                                                            <form [formGroup]="itDepartmentForm">
                                                                <input type="checkbox" id="BNS - Ruislip"
                                                                    formControlName="Ruislip" name="BNS - Ruislip"
                                                                    value="">&nbsp;
                                                                <label for="BNS - Ruislip">Ruislip</label>
                                                            </form>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" style="padding:10px 7px;">
                                                            <form [formGroup]="itDepartmentForm">
                                                                <input type="checkbox" id="S Drive" name="S Drive"
                                                                    formControlName="SDrive" value="">&nbsp;
                                                                <label for="S Drive"> S Drive</label>
                                                            </form>
                                                        </td>
                                                        <td colspan="2" style="padding:10px 7px;">
                                                            <form [formGroup]="itDepartmentForm">
                                                                <input type="checkbox" id="Full Internet Access"
                                                                    formControlName="FullInternetAccess"
                                                                    name="Full Internet Access" value="">&nbsp;
                                                                <label for="Full Internet Access"> Full Internet
                                                                    Access</label>
                                                            </form>
                                                        </td>
                                                        <td style="padding:10px 7px;">
                                                            <form [formGroup]="itDepartmentForm">
                                                                <input type="checkbox" id="BNS - Perivale"
                                                                    formControlName="Perivale" name="BNS - Perivale"
                                                                    value="">&nbsp;
                                                                <label for="BNS - Perivale"> Perivale</label>
                                                            </form>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" style="padding:10px 7px;">
                                                            <form [formGroup]="itDepartmentForm">
                                                                <input type="checkbox" id="F Drive" name="F Drive"
                                                                    formControlName="FDrive" value="">&nbsp;
                                                                <label for="F Drive"> F Drive</label>
                                                            </form>
                                                        </td>
                                                        <td colspan="2" style="padding:10px 7px;">
                                                            <form [formGroup]="itDepartmentForm">
                                                                <input type="checkbox" id="No Internet Access"
                                                                    formControlName="NoInternetAccess"
                                                                    name="No Internet Access" value="">&nbsp;
                                                                <label for="No Internet Access"> No Internet
                                                                    Access</label>
                                                            </form>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                </table>
                                            </tr>
                                        </table>


                                    </td>
                                </tr>
                                <tr class="itDepartment">
                                    <td colspan="4">
                                        <table class="border"
                                            style="width: 100%; max-width: 600px; border-collapse: collapse; margin-top: 30px;">
                                            <tr>
                                                <td colspan="2" style="padding:10px 0px; text-align: center;">
                                                    <b>Email ID created</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding:10px 7px;">
                                                    <form [formGroup]="itDepartmentForm">
                                                        <input type="checkbox" id="@bnshealthcare.com"
                                                            formControlName="bnshealthcare" name="@bnshealthcare.com"
                                                            value="">&nbsp;
                                                        <label for="@bnshealthcare.com"> @bnshealthcare.com</label>
                                                    </form>
                                                </td>
                                                <td style="padding:10px 7px;">
                                                    <form [formGroup]="itDepartmentForm">
                                                        <input type="checkbox" id="@bnsgroup.co.uk"
                                                            name="@bnsgroup.co.uk" formControlName="bnsgroup"
                                                            value="">&nbsp;
                                                        <label for="@bnsgroup.co.uk"> @bnsgroup.co.uk</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding:10px 7px;">
                                                    <form [formGroup]="itDepartmentForm">
                                                        <input type="checkbox" id="@thamelabs.co.uk"
                                                            name="@thamelabs.co.uk" formControlName="thamelabs"
                                                            value="">&nbsp;
                                                        <label for="@thamelabs.co.uk"> @thamelabs.co.uk</label>
                                                    </form>
                                                </td>
                                                <td style="padding:10px 7px;">
                                                    <form [formGroup]="itDepartmentForm">
                                                        <input type="checkbox" id="@colorpharm.co.uk"
                                                            formControlName="colorpharm" name="@colorpharm.co.uk"
                                                            value="">&nbsp;
                                                        <label for="@colorpharm.co.uk"> @colorpharm.co.uk</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding:10px 7px;">
                                                    <form [formGroup]="itDepartmentForm">
                                                        <input type="checkbox" id="bnscolorama.co.uk"
                                                            formControlName="bnscolorama" name="bnscolorama.co.uk"
                                                            value="">&nbsp;
                                                        <label for="bnscolorama.co.uk"> @bnscolorama.co.uk</label>
                                                    </form>
                                                </td>
                                                <td style="padding:10px 7px;">
                                                    <form [formGroup]="itDepartmentForm">
                                                        <input type="checkbox" id="@bnsthamelabs.co.uk"
                                                            formControlName="bnsthamelabs" name="@bnsthamelabs.co.uk"
                                                            value="">&nbsp;
                                                        <label for="@bnsthamelabs.co.uk"> @bnsthamelabs.co.uk</label>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding:10px 7px;">
                                                    <form [formGroup]="itDepartmentForm">
                                                        <input type="checkbox" id="@waymade-laxmico.co.uk"
                                                            formControlName="waymadelaxmico"
                                                            name="@waymade-laxmico.co.uk" value="">&nbsp;
                                                        <label for="@waymade-laxmico.co.uk">
                                                            @waymade-laxmico.co.uk</label>
                                                    </form>
                                                </td>
                                                <td style="padding:10px 7px;">
                                                    <form [formGroup]="itDepartmentForm">
                                                        <input type="checkbox" id="None" name="None" value=""
                                                            formControlName="None">&nbsp;
                                                        <label for="None"> None</label>
                                                    </form>
                                                </td>
                                            </tr>

                                        </table>
                                    </td>
                                </tr>
                                <tr class="itDepartment">
                                    <td colspan="4">

                                        <div style="padding-top: 15px;">
                                            <table>
                                                <tr>
                                                    <td>
                                                        <form [formGroup]="itDepartmentForm">
                                                            <input type="text" name="" formControlName="Signature" class="h-30"
                                                                style="width: 300px; border:1px solid; padding: 0 5px; text-align: center;"
                                                                placeholder="Signaure Here">
                                                        </form>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <form [formGroup]="itDepartmentForm">
                                                            <input type="date" name="" formControlName="date" class="h-30"
                                                                style="width: 300px; border:1px solid; padding: 0 5px; position: relative;left: 400px;"
                                                                placeholder="dd/mm/yyyy">
                                                        </form>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="position: relative; left: 12px; top: 10px;">Head
                                                        of Department (Name & Signature)
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table style="width: 100%;">
                                            <tr>
                                                <table cellspacing="0" border="0"
                                                    style=" font-family:arial; margin: 30px auto; width: 100%; max-width: 1000px; padding: 10px;">
                                                    <tr>
                                                        <td>
                                                            <b>
                                                                <p
                                                                    style="text-transform: uppercase; font-size: 20px; margin: 0; border-bottom: 2px solid; width: max-content;">
                                                                    to be filled in by - IT head
                                                                </p>
                                                            </b>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </tr>
                                        </table> <!-- page 7 completed -->
                                    </td>
                                </tr>
                                <tr class="itHead">
                                    <td>
                                        <table cellspacing="0" border="0"
                                            style="font-family:arial; margin: 0 auto; width: 100%; max-width: 1000px;">
                                            <tbody>
                                                <tr>
                                                    <td style="padding: 20px 0 20px;"> Remarks:</td>
                                                    <td style="text-align: right;">
                                                        <form [formGroup]="itHeadForm">
                                                            <input type="text" name="" formControlName="Remarks" class="h-30"
                                                                style="width: 100%; border: 1px solid; padding: 0 5px; border-left: 0; border-top: 0; border-right: 0;">
                                                        </form>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="itHead">
                                    <td style="padding-top: 15px;">
                                        <table>
                                            <tr>
                                                <td>
                                                    <form [formGroup]="itHeadForm">

                                                        <input type="text" name="" formControlName="Signature" class="h-30"
                                                            style="width: 300px; border:1px solid; padding: 0 5px; text-align: center;"
                                                            placeholder="Signaure Here">
                                                    </form>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <form [formGroup]="itHeadForm">
                                                        <input type="date" name="" formControlName="date" class="h-30"
                                                            style="width: 300px; border:1px solid; padding: 0 5px; position: relative; left: 400px;"
                                                            placeholder="dd/mm/yyyy">
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="position: relative; left: 12px; top: 10px;">
                                                    Software Engineer (Name & Signature)
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table cellspacing="0" border="0"
                                            style=" font-family:arial; margin: 400px auto auto; width: 100%; max-width: 1000px;">
                                            <tbody>
                                                <tr>
                                                    <td style="padding-top: 30px; text-align: right;">Page 7
                                                        of 7</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table cellspacing="0" border="0"
                                            style=" font-family:arial; margin: 0 auto; width: 100%; max-width: 1000px;">
                                            <tbody>
                                                <tr>
                                                    <td style="padding-top: 30px; text-align: left;">Parent
                                                        SOP SOP/IT/0001</td>
                                                    <td style="padding-top: 30px; text-align: right;">Form
                                                        Number F/IT/0001/001/v9</td>
                                                </tr>
                                                <tr>
                                                    <td style="text-align: left;">Effective Date: 23SEP2019
                                                    </td>
                                                    <td style="text-align: right;">Review Date: 22SEP2021
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>


                    </td>
                </tr>

            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button type="button" (click)="generatePDF()" class="btn dark btn-outline">Export to PDF</button>
    <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
    <button type="button" (click)="userCreationSave()" class="btn blue">Save</button>
</div>