import { Component, OnInit, ViewChild } from '@angular/core';
import { HistoryComponent } from '../history/history.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;

@Component({
  selector: 'app-rtwstatus-list',
  templateUrl: './rtwstatus-list.component.html',
  styleUrls: ['./rtwstatus-list.component.css']
})
export class RtwstatusListComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  rtwstatustypeform: FormGroup;
  //getDocumentTypeList: any;
  RTWStatusTypeList: any[] =[];
  filterDocumentType: any;
  listCall: boolean = true;
  companylist: any;
  title: string;
  Toast: any;
  p: number = 1;
  //cities: city[] = [];
  totalRecord: 0;
  constructor( private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder ) { }

  ngOnInit() {
    this.title = "Add";
    this.rtwstatustypeform = this.fb.group({
      id: ['0'],
      name: ['', Validators.required],
      notes: [''],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: ['0']
    });
    if (this.listCall) {
      this.getRtwStatusTypeList();
    }

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }
  getRtwStatusTypeList(PageIndex = 1) {
    this.p = PageIndex;
    this.totalRecord = 0;
    this.RTWStatusTypeList = [];
    this.filterDocumentType = [];
    this.commonApiService.getListWithFilter(Commonvariable.RTWVisaStatus, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.RTWStatusTypeList = result["data"]["list"];
        this.filterDocumentType = result["data"]["list"];
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);
      }
    })
  }

  rtwTypeUpsert() {
    var rtwstatustypeform = this.rtwstatustypeform;
    if (this.validation()) {
      rtwstatustypeform.get("isActive").setValue(rtwstatustypeform.get("isActive").value ? 1 : 0);
      var Name = rtwstatustypeform.get("name").value;
      rtwstatustypeform.get("name").setValue(Name.trim());
      this.commonApiService.Upsert(Commonvariable.RTWVisaStatus, rtwstatustypeform.value, rtwstatustypeform.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'RTWStatus Type');
          }
          else {
            $("#draggable").modal('hide');
            this.getRtwStatusTypeList();
            this.toastr.success(rtwstatustypeform.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'RTWStatus Type');
          }
        }
      })
    }
  }


  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.RTWVisaStatus, Id, status).subscribe((response) => {
          
          if (response["status"] == 200) {
            this.getRtwStatusTypeList();
            this.toastr.success(response["message"], 'RTWStatus  Type');                        
          }

        })
      }

    });
  }

  rtwStatusTypeDelete(rtwstatustypeId) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.RTWVisaStatus, rtwstatustypeId).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getRtwStatusTypeList();
          this.toastr.success(response["message"], 'RTWStatus Type');
          this.p = 1;
        })
      }
    })
  }

  openModel(rtwstatustypeId) {

    if (rtwstatustypeId > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.RTWVisaStatus, rtwstatustypeId).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var formData = this.rtwstatustypeform;
          this.commonService.setEditData(formData, editData);
          formData.controls.updatedBy.setValue(Commonvariable.User_Id)
        }
      })
    }
    else {
      // $("#zone_Code").removeAttr("disabled")
      this.listCall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });

  }

  
  validation() {
    var rtwstatusTypeForm = this.rtwstatustypeform;
    if (!rtwstatusTypeForm.valid) {

      for (const key of Object.keys(rtwstatusTypeForm.controls)) {
        if (rtwstatusTypeForm.controls[key].invalid) {
          let fieldName = "";
          if (key == "name") {
            fieldName = "Document Name"
          }
          this.toastr.info(fieldName + " is required !", "Document Type");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if ($("#name").val().length < 1) {
        this.toastr.info("Document Name must be at least 1 characters long !", "Document Type");
        $("#name").focus();
        return false;
      }
      if (/["\\]/.test($("#name").val())) {
        this.toastr.info("name does not allow Special Characters \" \\ ", "Document Type");
        $("#name").focus();
        return false;
      }
      if (/["\\]/.test($("#notes").val())) {
        this.toastr.info("notes does not allow Special Characters \" \\ ", "Document Type");
        $("#notes").focus();
        return false;
      }
      // if ($("#name").val().search(/[^a-zA-Z ]+/) > -1) {
      //   this.toastr.info("Document Name only alphabets and numeric allow !", "Document Type");
      //   $("#name").focus();
      //   return false;
      // }

    }
    return true;
  }

  history() {
    this.historyView.getHistory("rtwstatus",this.rtwstatustypeform.controls.id.value)
  }

}
