import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { analyzeAndValidateNgModules } from '@angular/compiler';
declare var $: any;

@Component({
   selector: 'app-companyemailconfig',
   templateUrl: './companyemailconfig.component.html',
})
export class CompanyEmailConfigComponent implements OnInit {


   companyemailconfigform: FormGroup;
   employeeCodeConfiguration: FormGroup;
   TotalEMPDigit: any;
   companyDoc: any;
   companyId = 0;
   constructor(
      private toastr: ToastrService,
      private commonService: CommonService,
      private commonApiService: CommonApiService,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {
      this.companyemailconfigform = this.fb.group({
         email_Config_Id: ['0'],
         company_Id: ['0'],
         email_Host: [null],
         email_Port: [null],
         email_UserName: [null],
         email_Password: [null],
         enableSSL: ['1'],
         tlsEnable: ['0'],
         isActive: ['1'],
         addedBy: Commonvariable.User_Id,
         updatedBy: Commonvariable.User_Id,
      });

      this.employeeCodeConfiguration = this.fb.group({
         id: ['0'],
         company_Id: ['0'],
         prefix: [null],
         total_Digit: [null],
         last_Emp_Code: [null],
         isActive: ['1'],
         is_Accross_Company: [false],
         addedBy: Commonvariable.User_Id,
         updatedBy: Commonvariable.User_Id,
      });
   }

   companyUpsert() {

      if (this.validation()) {
         return this.companyemailconfigform.value;
      }
      return "";
   }

   edit(emailConfig, empconfig,compId) {
      if (emailConfig != null) {
         var formData = this.companyemailconfigform;
         this.commonService.setEditData(formData, [emailConfig]);
      }
      if (empconfig != null) {
         var formData = this.employeeCodeConfiguration;
         this.commonService.setEditData(formData, [empconfig]);
         if (this.employeeCodeConfiguration.controls.prefix.value != null) {
            this.employeeCodeConfiguration.controls.total_Digit.setValue(this.employeeCodeConfiguration.controls.total_Digit.value + String(this.employeeCodeConfiguration.controls.prefix.value).length)
         }
this.companyId = compId;
         this.displayEmpCode();
      }

   }

   // ValidateIPaddress(inputText)
   // {       
   //    var ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
   //    if(inputText.match(ipformat))
   //    {  
   //       $( ".btn-outline" ).removeClass( "disabled" );        
   //       return true;         
   //    }
   //    else
   //    {
   //       this.toastr.info("You have entered an invalid Email Host!", "Company");         
   //       $("#email_Host").focus();
   //       $( ".btn-outline" ).addClass( "disabled" );         
   //       return false;
   //    }
   // }

   validation() {

      if (this.employeeCodeConfiguration.controls.is_Accross_Company.value) {
         this.employeeCodeConfiguration.controls.is_Accross_Company.setValue(1);
      } else {
         this.employeeCodeConfiguration.controls.is_Accross_Company.setValue(0);
      }


      if (this.employeeCodeConfiguration.controls.total_Digit.value == "") {
         this.toastr.info("Total Length is required !", "Company");
         $("#total_Digit").focus();
         return false;
      }

      // var formData = this.companyemailconfigform;
      // if (!formData.valid) {
      //    for (const key of Object.keys(formData.controls)) {
      //       if (formData.controls[key].invalid) {
      //          let fieldName = "";
      //          if (key == "email_Host") {
      //             fieldName = "Email host"
      //          }
      //          else if (key == "email_Port") {
      //             fieldName = "Email port"
      //          }
      //          else if (key == "email_UserName") {
      //             fieldName = "Email username"
      //          }
      //          else if (key == "email_Password") {
      //             fieldName = "Email password"
      //          }
      //          this.toastr.info(fieldName + " is required !", "Company");
      //          $("#" + key).focus();
      //          return false;
      //       }
      //    }
      // }
      // else {

      // }
      return true;
   }
   displayEmpCode() {

      if ((this.employeeCodeConfiguration.controls.prefix.value != null && this.employeeCodeConfiguration.controls.total_Digit.value != null) &&
         (this.employeeCodeConfiguration.controls.prefix.value != "" && this.employeeCodeConfiguration.controls.total_Digit.value != "")) {
         var prefixLength = this.employeeCodeConfiguration.controls.prefix.value.toString().length
         if (prefixLength >= this.employeeCodeConfiguration.controls.total_Digit.value) {
            this.toastr.info("Total Length is Invalid", "Company");
            $("#total_Digit").focus();
            return false;
         }
         var idDisplay = 0;
         var idEndDisplay = 9;
         let prefix = this.employeeCodeConfiguration.controls.prefix.value;
         let digit = this.employeeCodeConfiguration.controls.total_Digit.value;
         var startCode = prefix + idDisplay.toString().padStart(digit - prefixLength, "0");
         var endCode = prefix + idEndDisplay.toString().padStart(digit - prefixLength, "9");
         var empCode = startCode + " - " + endCode;
         $("#displayCode").val(empCode);
         this.TotalEMPDigit = this.employeeCodeConfiguration.controls.total_Digit.value - prefixLength;
      }
      else if ((this.employeeCodeConfiguration.controls.total_Digit.value != null) &&
         (this.employeeCodeConfiguration.controls.total_Digit.value != "")) {

         var idDisplay = 0;
         var idEndDisplay = 9;
         let digit = this.employeeCodeConfiguration.controls.total_Digit.value;
         var startCode = idDisplay.toString().padStart(digit, "0");
         var endCode = idEndDisplay.toString().padStart(digit, "9");
         var empCode = startCode + " - " + endCode;
         $("#displayCode").val(empCode);
         this.TotalEMPDigit = this.employeeCodeConfiguration.controls.total_Digit.value;
      }
      else {
         $("#displayCode").val("");
      }

   }

   changeAccrossCompany() {
      if (this.employeeCodeConfiguration.controls.is_Accross_Company.value) {
         this.commonApiService.List(Commonvariable.Company_Emp_Config, "GetAcrossCompanyData").subscribe((res) => {
            if (res) {
               let companyEmpConfigData = res["data"];
               if (companyEmpConfigData) {
                  this.employeeCodeConfiguration.controls.id.setValue(companyEmpConfigData.id);
                  this.employeeCodeConfiguration.controls.prefix.setValue(companyEmpConfigData.prefix);
                  this.employeeCodeConfiguration.controls.total_Digit.setValue(companyEmpConfigData.total_Digit);
                  this.displayEmpCode();
               }
            }
         })
      }
      else {
         if(this.companyId)
         {
            this.commonApiService.getById(Commonvariable.Company_Emp_Config, this.companyId, "GetByCompanyId").subscribe((res) => {
               if (res) {
                  let companyEmpConfigData = res["data"];
                  if (companyEmpConfigData) {
                     this.employeeCodeConfiguration.controls.id.setValue(companyEmpConfigData.id);
                     this.employeeCodeConfiguration.controls.prefix.setValue(companyEmpConfigData.prefix);
                     this.employeeCodeConfiguration.controls.total_Digit.setValue(companyEmpConfigData.total_Digit + companyEmpConfigData.prefix.length);
                     this.displayEmpCode();
                  }
                  else {
                     this.employeeCodeConfiguration.controls.id.setValue(0);
                     this.employeeCodeConfiguration.controls.prefix.setValue('');
                     this.employeeCodeConfiguration.controls.total_Digit.setValue('');
                     $("#displayCode").val("");
                  }
               }
            })
         }
         else
         {
            this.employeeCodeConfiguration.controls.id.setValue(0);
            this.employeeCodeConfiguration.controls.prefix.setValue('');
            this.employeeCodeConfiguration.controls.total_Digit.setValue('');
            $("#displayCode").val("");
         }
      }

   }

}


