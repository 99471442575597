import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;


@Component({
  selector: 'app-menupermission',
  templateUrl: './menu-permission.component.html'
})
export class MenuPermissionComponent implements OnInit {
  menuform: FormGroup;
  menulist: any;
  parentList: Array<Select2OptionData>;
  roleList: any[];
  moduleList: any[];
  filterModuleList: any[]
  title: string;
  moduleId = 0;
  p: number = 1;
  m: number = 1;
  totalRecord: 0;


  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private fb: FormBuilder) { }


  ngOnInit() {
    this.title = "Add";
    this.menuform = this.fb.group({
      menu_Id: ['0'],
      name: ['', Validators.required],
      url: [''],
      icon: [''],
      parent_Id: ['0'],
      sorting: ['0'],
      isColor_Code:[false],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });


    this.getMenuList(1);

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);


  }

  getMenuList(PageIndex) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.menulist = [];
    this.commonApiService.getListWithFilter(Commonvariable.Menu, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["status"] == 200 && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.menulist = result["data"]["list"];
      }
      this.getParentList();
    })
  }

  async getParentList() {
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "Name"
    }
    await this.commonApiService.GetByPaginated(Commonvariable.Menu, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.parentList = response["data"].map(x => ({
          id: x.menu_Id,
          text: x.name
        }));
      }
    })
  }

  // async getRoles() {
  //   await this.commonApiService.List(Commonvariable.Roles).subscribe((result) => {
  //     if (result != null && result["status"] == 200) {
  //       this.roleList = result["data"].filter(x => x.isActive != 0);
  //     }
  //   })
  // }


  async getModule(id) {
    this.moduleId = id
    this.commonApiService.getById(Commonvariable.Module, id, "GetPaginatedSelected").subscribe((result) => {
      if (result != null && result["status"] == 200) {
        this.moduleList = result["data"].filter(x => x.isActive != 0);
        this.setModule(result["data"].filter(x => x.isActive != 0));
      }
    })
    this.m = 1
    $('#modulePermission').modal({
      backdrop: 'static'
    });

  }

  setModule(list) {
    var array = [];
    var index = 0;
    for (const key of Object.keys(list)) {
      var data = {
        "id": index,
        "module_id": list[key].id,
        "moduleName": list[key].name,
        "permission": list[key].selected == 0 ? false : true
      }
      array.push(data);
      index++;
    }
    $("#checkall").prop("checked", array.length == array.filter(x => x.permission).length)
    this.moduleList = array
    this.filterModuleList = array
  }



  upsert() {
    var formData = this.menuform;
    if (this.validation()) {
      this.commonApiService.Upsert(Commonvariable.Menu, formData.value, formData.controls.menu_Id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.commonService.toaster("warning", 'Record is already exists !', 'Menu');
          }
          else {
            $("#draggable").modal('hide');
            this.getMenuList(1);
            this.commonService.toaster("success", formData.controls.menu_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Menu');
          }
        }
      })
    }
  }

  menuDelete(id) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Menu, id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getMenuList(1);
          this.commonService.toaster("success", response["message"], 'Menu');
          this.p = 1;
        })
      }
    })
  }

  openModel(id) {

    if (id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Menu, id).subscribe((response) => {
        if (response != null) {
          var editData = response["data"];
          var formData = this.menuform;
          this.commonService.setEditData(formData, editData);
        }
      })
    }
    else {
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });

  }


  validation() {
    var formData = this.menuform;

    formData.get("isActive").setValue(formData.get("isActive").value ? 1 : 0);
    formData.get("isColor_Code").setValue(formData.get("isColor_Code").value ? 1 : 0);

    for (const key of Object.keys(formData.controls)) {

      if (key == "name") {

        if (String(formData.value[key]).trim() == '') {
          this.commonService.toaster("info", "Name is required!", 'Menu');
          formData.controls[key].setValue('');
          $("#name").focus();
          return false;
        }


        if ($("#name").val().search(/[^a-zA-Z ]+/) > -1) {
          this.commonService.toaster("info", "Name only alphabets allow !", 'Menu');
          $("#name").focus();
          return false;
        }

      }

      if (key == "url") {
        if ($("#url").val() != "") {
          if ($("#url").val().search(/[^a-zA-Z-_]+/) > -1) {
            this.commonService.toaster("info", "URL only alphabets allow !", 'Menu');
            $("#url").focus();
            return false;
          }
        }
      }
    }

    this.commonService.setNull(formData);
    formData.get("sorting").setValue(formData.get("sorting").value ? formData.get("sorting").value : 0);

    return true;
  }


  checkBox(id, checkall = false) {
    if (checkall) {
      for (const key of Object.keys(this.moduleList)) {
        this.moduleList[key].permission = $("#checkall").prop("checked")
      }
      for (const key of Object.keys(this.filterModuleList)) {
        this.filterModuleList[key].permission = $("#checkall").prop("checked")
      }
    }
    else {

      this.moduleList[this.moduleList.map(x => x.id).indexOf(id)].permission = $("#permission" + id).prop("checked")
      this.filterModuleList[this.filterModuleList.map(x => x.id).indexOf(id)].permission = $("#permission" + id).prop("checked")
    }

    $("#checkall").prop("checked", this.moduleList.filter(x => x.permission == true).length == this.moduleList.length)
  }

  async upsertModulePermission() {
    var requestData = {
      "Menu_Id": this.moduleId,
      "Module_Id": String(this.moduleList.filter(x => x.permission).map(m => m.module_id)),
      "UpdatedBy": Commonvariable.User_Id,
    }
    if (this.moduleId > 0 && requestData.Module_Id != "") {
      await this.commonApiService.putWithParameter(Commonvariable.Menu, requestData, 'EditModuleId').subscribe((result) => {
        $('#modulePermission').modal('hide');
        this.commonService.toaster("success", Commonvariable.SaveMsg, 'Menu');
      })
    }
  }

  filterModulePermission(wordFind) {

    if (wordFind != "") {
      this.moduleList = this.filterModuleList.filter(x => String(x.moduleName).toLowerCase().trim().includes(String(wordFind).toLowerCase().trim()))
    }
    else {
      this.moduleList = this.filterModuleList
    }

    this.m = 1;

  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Menu, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.commonService.toaster("success", response["message"], 'Menu');
            this.getMenuList(1)
          }

        })
      }

    });
  }


  setFormData(id,event) {
    this.menuform.controls[id].setValue(event)
  }


}
