import { Component, OnInit,ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;

@Component({
  selector: 'app-currencies',
  templateUrl: './currencies.component.html'  
})
export class CurrenciesComponent implements OnInit {

  @ViewChild(HistoryComponent,{static:false}) historyView : HistoryComponent;
  currencyform: FormGroup;
  currencylist: any;
  filterList:any;
  companylist: any;
  listcall: boolean = true;
  title: string;
  p: number = 1;
  totalRecord:0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.title = "Add";
    this.currencyform = this.fb.group({
      id: ['0'],            
      currency_Name: ['', Validators.required],      
      currency_Code: ['', Validators.required],
      isActive: ['1'],
      isDefault:[null],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });
    
    if (this.listcall){
      this.getCurrenciesList();      
    }  

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);
      
  }

  getCurrenciesList(PageIndex = 1) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.currencylist = [];
    this.filterList = [];
    this.commonApiService.getListWithFilter(Commonvariable.Currencies, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.currencylist = result["data"]["list"];
        this.filterList = result["data"]["list"];
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })    
  }


  Upsert() {
    var Form = this.currencyform;

    if (this.validation()) {
      Form.get("isActive").setValue(Form.get("isActive").value ? 1 : 0);
      Form.get("isDefault").setValue(Form.get("isDefault").value ? 1 : 0);
      Form.get("updatedBy").setValue(Commonvariable.User_Id);
      
      this.commonApiService.Upsert(Commonvariable.Currencies, Form.value, Form.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Currency');
          }
          else {
            $("#draggable").modal('hide');
            this.getCurrenciesList();
            this.toastr.success(Form.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Currency');
          }
        }
      })
    }

  }

  Delete(Id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Currencies, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getCurrenciesList();
          this.toastr.success(response["message"], 'Currency');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Currencies, Id, status).subscribe((response) => {
          
          if (response["status"] == 200) {
            this.getCurrenciesList();
            this.toastr.success(response["message"], 'Currency');                        
          }

        })
      }

    });
  }

  openModel(Id) {
    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Currencies, Id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var Form = this.currencyform;
          this.commonService.setEditData(Form, editData);                    
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Currency'); })
    }
    else {      
      this.listcall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });


  }

  validation() {
    var Form = this.currencyform;
    if (!Form.valid) {
      for (const key of Object.keys(Form.controls)) {
        if (Form.controls[key].invalid) {
          let fieldName = "";
          if (key == "currency_Name") {
            fieldName = "Currency Name"
          }  
          else  if (key == "currency_Code") {
            fieldName = "Currency Code"
          }
                   
          this.toastr.info(fieldName + " is required !", "Currency");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {          
      
      if ($("#currency_Name").val().length < 1) {
        this.toastr.info("Currency Name must be at least 1 characters long !", "Currency");
        $("#currency_Name").focus();
        return false;
      }
      if (/["\\]/.test($("#currency_Name").val())) {
        this.toastr.info("Currency Name does not allow Special Characters \" \\ ", "Currency");
        $("#currency_Name").focus();
        return false;
      }
      if (/["\\]/.test($("#currency_Code").val())) {
        this.toastr.info("Currency Code does not allow Special Characters \" \\ ", "Currency");
        $("#currency_Code").focus();
        return false;
      }
      // if ($("#currency_Name").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
      //   this.toastr.info("Currency Name only alphabets and numeric allow !", "Currency");
      //   $("#currency_Name").focus();
      //   return false;
      // }      
      if ($("#currency_Code").val().indexOf(" ") > -1) {
        this.toastr.info("Currency code not allow space !", "Currency");
        $("#currency_Code").focus();
        return false;
      }
      // if ($("#currency_Code").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
      //   this.toastr.info("Currency code only alphabets and numeric allow !", "Currency");
      //   $("#currency_Code").focus();
      //   return false;
      // }  
    }
    return true;
  }

  history() {
    this.historyView.getHistory("currencies",this.currencyform.controls.id.value)
  }


  checkDefaultData(event) {

    if (event.target.checked) {
      
      var Form = this.currencyform;
      this.commonApiService.List(Commonvariable.Currencies, "CheckDefaultData").subscribe((response) => {
        if (response["data"] != null) {
          this.commonService.dyanmicConfirm("You want to set as default currency  !", "Yes !").then((result) => {
            if (result.value) {
              //console.log(response["data"])                            
            }else{
              Form.get("isDefault").setValue(0);
            }
          });          
        }
      });
    }
  }

}
