import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
import { HistoryComponent } from 'src/app/pages/history/history.component';
declare var $: any;



@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html'
})
export class HolidayComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  holidayform: FormGroup;
  holidaylist: any;
  companylist: Array<Select2OptionData>;;
  title: string;
  p: number = 1;
  totalRecord: 0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.title = "Add";
    this.holidayform = this.fb.group({
      id: ['0'],
      company_Id: ['', Validators.required],
      name: ['', Validators.required],
      holiday_Date: ['', Validators.required],
      isActive: ['1'],
      AddedBy: [Commonvariable.User_Id],
      updatedBy:[Commonvariable.User_Id]
    });

    this.getHolidayList();
    this.getCompanyList();

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

    this.commonService.flatpickr("holiday_date_open", 0);

  }

  getHolidayList(PageIndex = 1) {
    this.p = PageIndex;
    this.totalRecord = 0;
    this.holidaylist = []
    this.commonApiService.getListWithFilter(Commonvariable.Holiday_Master, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.holidaylist = result["data"]["list"];

        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);
      }
    })
  }

  getCompanyList() {
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'company_Name'
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }
  setFormData(event) {
    this.holidayform.controls.company_Id.setValue(event)
  }


  Upsert() {
    var Form = this.holidayform;

    if (this.validation()) {
      Form.get("isActive").setValue(Form.get("isActive").value ? 1 : 0);
      Form.get("name").setValue(Form.get("name").value);
      
      this.commonApiService.Upsert(Commonvariable.Holiday_Master, Form.value, Form.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.commonService.toaster("warning", 'Record is already exists !', 'Holiday')
          }
          else {
            $("#draggable").modal('hide');
            this.getHolidayList();
            this.commonService.toaster("success", Form.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Holiday')
          }
        }
      })
    }

  }

  Delete(Id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Holiday_Master, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getHolidayList();
          this.commonService.toaster("success", response["message"], 'Holiday')
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Holiday_Master, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.getHolidayList();
            this.commonService.toaster("success", response["message"], 'Holiday')
          }

        })
      }

    });
  }

  openModel(Id) {
    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Holiday_Master, Id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var Form = this.holidayform;

          let date = response["data"][0].holiday_Date != null ? response["data"][0].holiday_Date.split("T")[0].split("-") : "";

          if (date != "") {
             $("#holiday_Date").val(date[2] + "-" + date[1] + "-" + date[0]);
          }

          this.commonService.setEditData(Form, editData);
          this.holidayform.controls.updatedBy.setValue(Commonvariable.User_Id);

        }
        this.holidayform.controls.holiday_Date.setValue(String(response["data"][0].holiday_Date).split("T")[0])
      })
    }
    else {
      this.ngOnInit()
    }
    this.commonService.flatpickr("holiday_Date", 0)

    $('#draggable').modal({
      backdrop: 'static'
    });

  }



  validation() {
    var Form = this.holidayform;

    Form.controls.holiday_Date.setValue(this.commonService.dateFormatChange($("#holiday_Date").val()))

    for (const key of Object.keys(Form.controls)) {

      if (key == "company_Id") {
        if (Form.controls.company_Id.value == "") {
          this.commonService.toaster("info", "Company is required !", 'Holiday')
          $("#company_Id select").focus();
          return false;
        }
      }

      if (key == "name") {
        if ($("#name").val() == "") {
          this.commonService.toaster("info", "Holiday name is required !", 'Holiday')
          $("#name").focus();
          return false;
        }

        if ($("#name").val() != "" && String($("#name").val()).length < 2) {
          this.commonService.toaster("info", "Name must be at least 2 characters long !", 'Holiday')
          $("#name").focus();
          return false;
        }

        if (/["\\]/.test($("#name").val())) {
          this.commonService.toaster("info", "Type of document does not allow Special Characters \" \\ ", 'Holiday')          
          $("#name").focus();
          return false;
        }

      }

      if (key == "holiday_Date") {
        if ($("#holiday_Date").val() == "") {
          this.commonService.toaster("info", "Holiday date is required !", 'Holiday')
          $("#holiday_Date").focus();
          return false;
        }
      }
    }


    return true;
  }

  history() {
    this.historyView.getHistory("holiday_master",this.holidayform.controls.id.value)
  }

}
