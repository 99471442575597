import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;


@Component({
  selector: 'app-documentcandidate',
  templateUrl: './document-candidate.component.html'
})
export class DocumentCandidateComponent implements OnInit {
  candidateForm: FormGroup;
  documentList: any;
  fileName: any;
  userRole: any
  iscompanyDoc = 0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {

    this.userRole = JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"]

    this.candidateForm = this.fb.group({
      id: [0],
      doc_Id: [0, Validators.required],
      candidate_Id: ['0'],
      doc_Name: ['', Validators.required],
      files: ['', Validators.required],
      notes: [null],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: ['0']
    });
    $('.fileinput-filename').html('');
    $('.fileinput.fileinput-exists').removeClass('fileinput-exists').addClass('fileinput-new');
    $('.input-group.input-large').removeAttr('disabled');
    this, this.getDocument();
  }

  getDocument() {
    this.commonApiService.List(Commonvariable.Document).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.documentList = response["data"].sort((a, b) => a.name.localeCompare(b.name));;
      }
    })
  }

  Edit(Id) {
    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.Onboarding_Document, Id).subscribe((response) => {
        if (response != null && response["data"] != "") {
          var editData = response["data"][0];
          this.candidateForm = this.fb.group({
            id: [editData.id],
            doc_Id: [editData.doc_Id, Validators.required],
            candidate_Id: [editData.candidate_Id],
            doc_Name: [editData.doc_Name, Validators.required],
            files: [''],
            notes: [editData.notes == 'null' ? '' : editData.notes],
            isActive: [editData.isActive],
            addedBy: [editData.addedBy],
            updatedBy: [Commonvariable.User_Id]
          });
          this.fileName = response['data'][0].file_Name
          $('.fileinput-filename').html(this.fileName);
          $('.fileinput.fileinput-new').removeClass('fileinput-new').addClass('fileinput-exists');
          $('.input-group.input-large').attr('disabled', 'disabled');
        }
      })

      // this.commonApiService.getimage(Commonvariable.Onboarding_Document, Id, 'Download').subscribe((result) => {
      //   let file=new File([result],result.type)
      //   this.candidateForm.controls.files.setValue(file);
      // })
    }
  }


  validation() {
    var eForm = this.candidateForm;
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);

    if (this.iscompanyDoc == 0 && eForm.controls["doc_Id"].value == 0) {
      this.toastr.info("Other Document is required !", "Candidate");
      $("#doc_Id").focus();
      return false;
    }

    if (!eForm.valid) {
      for (const key of Object.keys(eForm.controls)) {
        if (eForm.controls[key].invalid) {
          let fieldName = "";
         if (key == "doc_Name" && this.iscompanyDoc == 0) {
            fieldName = "Document name"
          }
          else if (key == "files") {
            fieldName = "Files"
          }
          if (fieldName != "") {
            this.toastr.info(fieldName + " is required !", "Candidate");
            $("#" + key).focus();
            return false;
          }
        }
      }
      
    }
    else
    {
      if ( this.candidateForm.controls.files.value == null) {
        this.toastr.info("Files is required !", "Candidate");
        $("#files").focus();
        return false;
      }
    if ($("#doc_Name").val() != "" && /["\\]/.test($("#doc_Name").val())) {
      this.toastr.info("Document name does not allow Special Characters \" \\ ", "Candidate");
      $("#doc_Name").focus();
      return false;
    }

    if ($("#Addnotes").val() != "" && /["\\]/.test($("#Addnotes").val())) {
        this.toastr.info("Notes does not allow Special Characters \" \\ ", "Candidate");
        $("#Addnotes").focus();
        return false;
      }
    }
    // if(this.iscompanyDoc == 0){
    //   if ($("#doc_Name").val().indexOf(" ") > -1) {
    //     this.toastr.info("Document name not allow space !", "Candidate");
    //     $("#doc_Name").focus();
    //     return false;
    //   }
    // }
    
    return true;
  }

  fileChange(event) {

    // if (event.target.files.item(0).name.split('.')[1] == "pdf" || event.target.files.item(0).name.split('.')[1] == "doc"
    //   || event.target.files.item(0).name.split('.')[1] == "docx" || event.target.files.item(0).name.split('.')[1] == "xlsx") {
      this.candidateForm.controls.files.setValue(event.target.files[0]);
    // }
    // else {
    //   this.toastr.info("Allow only pdf,doc,docx,xlsx file format for upload !", "Employee");
    //   $("#remove").click();
    // }

  }
  removeFile(event){
    this.candidateForm.controls.files.setValue(null);
  }

}
