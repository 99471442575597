import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;


@Component({
  selector: 'app-leave-type',
  templateUrl: './leave-type.component.html'
})
export class LeaveTypeComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  leavetypeform: FormGroup;
  leavetypelist: any;
  filterList: any;
  companylist: any;
  title: string;
  p: number = 1;
  totalRecord: 0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.title = "Add";
    this.leavetypeform = this.fb.group({
      id: ['0'],
      name: ['', Validators.required],
      leave_Code: ['', Validators.required],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });


    this.getLeaveTypeList();

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }

  getLeaveTypeList(PageIndex = 1) {
    this.p = PageIndex;
    this.totalRecord = 0;
    this.leavetypelist = []
    this.commonApiService.getListWithFilter(Commonvariable.Leave_Type, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.leavetypelist = result["data"]["list"];
        this.filterList = result["data"]["list"];
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })
  }


  LeaveTypeUpsert() {
    var Form = this.leavetypeform;

    if (this.validation()) {
      this.commonApiService.Upsert(Commonvariable.Leave_Type, Form.value, Form.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Leave Type');
          }
          else {
            $("#draggable").modal('hide');
            this.getLeaveTypeList();
            this.toastr.success(Form.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Leave Type');
          }
        }
      })
    }

  }

  Delete(Id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Leave_Type, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getLeaveTypeList();
          this.toastr.success(response["message"], 'Leave Type');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Leave_Type, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.getLeaveTypeList();
            this.toastr.success(response["message"], 'Leave Type');
          }

        })
      }

    });
  }

  openModel(Id) {
    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Leave_Type, Id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var Form = this.leavetypeform;
          this.commonService.setEditData(Form, editData);
        }
      })
    }
    else {
      this.ngOnInit()
    }
    $('#draggable').modal({
      backdrop: 'static'
    });

  }



  validation() {
    var Form = this.leavetypeform;
    Form.get("isActive").setValue(Form.get("isActive").value ? 1 : 0);


    for (const key of Object.keys(Form.controls)) {
      if (key == "name") {
        if ($("#name").val() == "") {
          this.toastr.info("Name is required !", "Leave Type");
          $("#name").focus();
          return false;
        }

        if ($("#name").val() != "" && String($("#name").val()).length < 2) {
          this.toastr.info("Name must be at least 2 characters long !", "Leave Type");
          $("#name").focus();
          return false;
        }

        if (/["\\]/.test($("#name").val())) {
          this.toastr.info("Name does not allow Special Characters \" \\ ", "Leave Type");
          $("#name").focus();
          return false;
        }
      }

      if (key == "leave_Code") {
        if ($("#leave_Code").val() == "") {
          this.toastr.info("Code is required !", "Leave Type");
          $("#leave_Code").focus();
          return false;
        }

        if (/["\\]/.test($("#leave_Code").val())) {
          this.toastr.info("Code does not allow Special Characters \" \\ ", "Leave Type");
          $("#leave_Code").focus();
          return false;
        }
      }
    }

    return true;
  }

  history() {
    this.historyView.getHistory("leave_type", this.leavetypeform.controls.id.value)
  }
}
