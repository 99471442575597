<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Right to Work list
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a data-toggle="modal" (click)="id=0;modalOpen =true" class="btn btn-transparent blue  btn-sm">Add
                            Right to Work</a>
                    </div>
                </li>
            </ul>
        </div>

        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" id="filterList" placeholder="Type to filter..." (input)="getRightToWorkList()">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblZone">
                            <thead class="flip-content">
                                <tr>
                                    <th style="width: 25%;" class="text-center"> Company </th>
                                    <th class="text-center">Type of Document</th>
                                    <th class="text-center">Description</th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of rwlist| paginate: { itemsPerPage: 10, currentPage: p ,totalItems :totalRecord}">

                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(item.rwC_Id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.rwC_Id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12">{{item.company_Name}}
                                        </div>
                                    </td>
                                    <td style="width: 10%;">{{item.name}}</td>
                                    <td>{{item.description}}</td>
                                    <td class="text-center">
                                        <a *ngIf="item.isActive" (click)="id=item.rwC_Id;modalOpen =true"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Right To Work"></i></a>
                                        <a *ngIf="!item.isActive" (click)="Delete(item.rwC_Id)">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Delete Right To Work"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="rwlist!= null">
                        <pagination-controls (pageChange)="getRightToWorkList($event)" autoHide="true"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->




<div *ngIf="modalOpen">
    <app-righttowork-form [pId]="id" (modalClose)="modalOpen = $event" (refreshPage)="getRightToWorkList($event)"></app-righttowork-form>
</div>