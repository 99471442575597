import { Component, OnInit,ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
import { DomSanitizer } from '@angular/platform-browser';
import { JobDescriptionComponent } from 'src/app/pages/recruitment/jobDescription/jobdescription.component';
import { now } from 'jquery';
import * as moment from 'moment';
declare var $: any;

@Component({
   selector: 'app-empjobdetail',
   templateUrl: './employee-job-detail.component.html',
})

export class EmployeeJobDetailsComponent implements OnInit {
   
   @ViewChild(JobDescriptionComponent, { static: false }) JDescription: JobDescriptionComponent;
   companyDetail: any;
   companyList: any;
   siteList: any;
   departmentList: any;
   zoneList: any;
   JDList: any;
   shiftList: any;
   designationList: any;
   empList: Array<Select2OptionData>;
   contractList: any;
   ProbationList: any;
   deputyList: Array<Select2OptionData>;
   righttoworkList: any[] = [];
   modalOpen: boolean = false;
   modalOpencontract: boolean = false;
   modalOpendesig: boolean = false;
   modalOpendept: boolean = false;
   modalOpensift: boolean = false;
   modalOpensite: boolean = false;
   modalJobDescription: boolean = false;
   rafList: any;
   companyid: any;
   AutoGenButtonVisibal: boolean = true;
   AllrafList:any;
   isInputDisabled: boolean = false;
   isInputDisabledED: boolean = false;
   fileUrl;
   icon: string = "plus";
   title: string = "Add";
   isDBDreq: boolean = false;

   empJobDetailform: FormGroup;
   holidayempList:any;
   linemanagerempList:any;

   constructor(
      private commonApiService: CommonApiService,
      private toastr: ToastrService,
      private commonService: CommonService,
      private sanitizer: DomSanitizer,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {
      this.commonService.timePicker("flatpickropenshiftstatetime,#flatpickropenshiftendtime", 0)
      this.commonService.flatpickr("flatpickropenDBS", 0)
      var self = this;
      this.empJobDetailform = this.fb.group({
         Emp_Id: ['0'],
         Person_Id: 0,
         company_Id: ['', Validators.required],
         Site_Id: [0],
         Vacancy_Id: [''],
         vacancy_Name: [''],
         Dept_Id: ['', Validators.required],
         Zone_Id: ["", Validators.required],
         Desig_Id: ['', Validators.required],
         JD_Id: [""],
         Emp_Contract_Id: [0],
         contract_Id: [''],
         contract_Name: [''],
         Emp_Role_Start: [''],
         Emp_Contract_Type: [''],
         Emp_Joining_Date: [''],
         Emp_Job_Title: [''],
         Emp_Role_End: [''],
         Emp_Code: ['', Validators.required],
         JoiningDate: ['', Validators.required],
         Shift_Id: [''],
         shift_Start: ['', Validators.required],
         shift_End: ['', Validators.required],
         nightShift: [''],
         shift_Variable:[0],
         Reporting_Id: ['', Validators.required],
         //Additional_Reporting_Id: ['0'],
         Line_Manager_Id: ['0'],
         isSponsored: ['0'],
         Tupe: ['0'],
         RAF_Approval: [0],
         IsHOD: false,
         DBS_Expiry_Date:[''],
         DBS_Required:false,
         NiNo: [null],
         Loan: [null],
         NiCategory: [''],
         Deputy: '',
         PreviousEmp_Id: ['0'],
         Work_Email: [null],
         Work_Phone: [null],
         Work_Extension: [null],
         isActive: ['1'],
         AddedBy: [Commonvariable.User_Id],
         updatedBy: [Commonvariable.User_Id],
         Contract_End_Date : [''],
         Is_Line_Manager :false,
         Is_Holiday_Approvel :false,
         emp_basicinfo: this.fb.group,
         emp_address: this.fb.array,
         emp_bank: this.fb.array,
         emp_contact: this.fb.array,
         emp_contract: this.fb.array,
         emp_document: this.fb.array,
         emp_emergency: this.fb.array,
         emp_probation: this.fb.array,
         emp_reference: this.fb.array,
         emp_resignation: this.fb.array,
         emp_righttowork: this.fb.array,
         emp_righttoworkview: this.fb.array,
         emp_salary: this.fb.array,
         person_equality: this.fb.array,
      });
      this.commonService.flatpickr("flatpickropenJ", 0);
      this.commonService.flatpickr("flatpickropenE", 0)
      //$("select").select2()
      this.getCompany();
      this.getRafListAll();
      this.getholidayEmpList();
      this.getlinemanagerEmpList();
      $('#JD_Id').on('select2:select', function (e) {
         self.icon = parseInt($(".jdSelectListClass option:selected").val()) > 0 ? "edit" : "plus";
         self.title = parseInt($(".jdSelectListClass option:selected").val()) > 0 ? "Edit" : "Add";
      
       });
      
   }

   getholidayEmpList() {

        this.commonApiService.getallEmployeeDetails(Commonvariable.EmployeeDetail,"GetHolidayApprover").subscribe((result) => {
          if (result != null && result["data"] != "") {
            this.holidayempList = result["data"]
            if (this.holidayempList.length > 0)
             {
               this.holidayempList = result["data"].map(x => ({
                            id: x.id,
                            text: x.displayName
                          }))
             }
          }
        })
      
    }
    getlinemanagerEmpList() {

      this.commonApiService.getallEmployeeDetails(Commonvariable.EmployeeDetail,"GetLineManager").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.linemanagerempList = result["data"]
          if (this.linemanagerempList.length > 0)
           {
             this.linemanagerempList = result["data"].map(x => ({
                          id: x.id,
                          text: x.displayName
                        }))
           }
        }
      })
    
  }

   getCompany() {
     
      if (this.companyList == null) {
         this.companyList = [];
      }

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "company_Name"
      }
      if (this.companyList.length == 0) {
         this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((result) => {
            if (result != null && result["data"] != "") {
               //this.companyList = result["data"];
               this.companyList = result["data"].filter(x => x.isActive != 0);
            }
            // this.getSite();
         })
      }
   }
   getContractList(id) {
    
      this.contractList = [];
  
      var requestData = {
        "searchBy": [
          {
            "FieldName": "isActive",
            "FieldValue": "1",
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "company_Id",
            "FieldValue": id,
            "Parameter": "=="
          }
        ],
        "OrderBy": "contract_Name"
      }
  
      this.commonApiService.GetByPaginated(Commonvariable.Contract, requestData).subscribe((response) => {
        if (response["data"] != "") {
          this.contractList = response["data"];
        }
      })
     // this.getZone(id);
    }


   companyWiseGetData(editmode = false) {
      this.siteList = []
      this.departmentList = []
      this.zoneList = []
      this.JDList = []
      this.designationList = []
      this.shiftList = []
      this.empList = []
      this.deputyList = []
      this.righttoworkList = [];
      this.contractList = []

      if (!editmode) {
         this.empJobDetailform.controls.Site_Id.setValue('0')
         this.empJobDetailform.controls.Dept_Id.setValue('')
         this.empJobDetailform.controls.Zone_Id.setValue('')
         this.empJobDetailform.controls.JD_Id.setValue('')
         this.empJobDetailform.controls.Desig_Id.setValue('')
         this.empJobDetailform.controls.Shift_Id.setValue('')
         this.empJobDetailform.controls.Vacancy_Id.setValue('')
         this.empJobDetailform.controls.Reporting_Id.setValue('')
         this.empJobDetailform.controls.Line_Manager_Id.setValue('0')
         this.empJobDetailform.controls.contract_Id.setValue('')
         //this.genrateEmployeeCode(this.empJobDetailform.controls.company_Id.value)
      }


      this.companyid = this.empJobDetailform.controls.company_Id.value;


      if (this.empJobDetailform.controls.company_Id.value != "") {
         localStorage.removeItem('employeeHRCompanyId');
         localStorage.setItem('employeeHRCompanyId', this.empJobDetailform.controls.company_Id.value);
         var requestData = {
            "searchBy": [
               {
                  "FieldName": "Company_Id",
                  "FieldValue": this.empJobDetailform.controls.company_Id.value,
                  "Parameter": "==",
                  "ConditionWith": "&&"
               },
               {
                  "FieldName": "isActive",
                  "FieldValue": "1",
                  "Parameter": "=="
               }
            ]
         }

         this.commonApiService.getPaginated(Commonvariable.CompanyDetail, null, null, null, null, null, requestData, "GetByCompany_IdPaginated").subscribe((result) => {
            this.companyDetail = result["data"][0];
            this.siteList = result["data"][0]["site"]
            this.departmentList = result["data"][0]["department"]
            this.zoneList = result["data"][0]["zone"]
            //this.JDList = result["data"][0]["job_Descriptions"]
            //this.designationList = result["data"][0]["designations"]
            this.shiftList = result["data"][0]["shift"]
            //this.getEmployeeList();
            this.contractList = result["data"][0]["contract"]
            this.ProbationList = result["data"][0]["probation"]
            this.righttoworkList = result["data"][0]["rightToWork"]
            this.empJobDetailform.controls.company_Id.setValue(this.empJobDetailform.controls.company_Id.value)
            this.getJobTitle();

            
            if (this.empJobDetailform.controls.Desig_Id.value > 0) {
               this.getJobDescriptionList(this.empJobDetailform.controls.Desig_Id.value)
            }


         })
      }

   }



   getReportingList() {

      let formData = this.empJobDetailform.controls

      let reportingParameter = "Reporting" + "," + formData.company_Id.value + "," + formData.Dept_Id.value + "," + formData.Desig_Id.value + "," + 0

      let deputyParameter = "Deputy" + "," + formData.company_Id.value + "," + formData.Dept_Id.value + "," + formData.Desig_Id.value + "," + 0

      this.commonApiService.getById(Commonvariable.EmployeeDetail, reportingParameter, "GetEmployeeByAccessibility").subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.empList = result["data"].map(x => ({
               id: x.emp_Id,
               text: x.firstName + " " + x.lastName
            }))
         }
      })

      this.commonApiService.getById(Commonvariable.EmployeeDetail, deputyParameter, "GetEmployeeByAccessibility").subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.deputyList = [];
            // this.deputyList = result["data"];            
            this.deputyList = result["data"].map(x => ({
               id: x.emp_Id,
               text: x.firstName + " " + x.lastName
            }))
         }
      })
   }

   autoGenrateEmpoyeeCode() {
      this.genrateEmployeeCode(this.companyid);
   }


   genrateEmployeeCode(companyId) {
      if (companyId > 0) {
         this.commonApiService.getById(Commonvariable.Employee, companyId, "AutoGenerateEmployeeCode").subscribe((result) => {
            if (result["data"] != "") {
               this.empJobDetailform.controls.Emp_Code.setValue(result["data"])
            }
         })
      }
   }

   getJobTitle() {

      this.designationList = [];
      if (this.empJobDetailform.controls.company_Id.value != "" && this.empJobDetailform.controls.Dept_Id.value != "") {


         var requestData = {
            "searchBy": [
               {
                  "FieldName": "isActive",
                  "FieldValue": "1",
                  "Parameter": "==",
                  "ConditionWith": "&&"
               },
               {
                  "FieldName": "company_Id",
                  "FieldValue": this.empJobDetailform.controls.company_Id.value,
                  "Parameter": "==",
                  "ConditionWith": "&&"
               },
               {
                  "FieldName": "dept_Id",
                  "FieldValue": this.empJobDetailform.controls.Dept_Id.value,
                  "Parameter": "==",
               }
            ],
            "OrderBy": "desig_Name"
         }

         requestData.OrderBy = "desig_Name"
         this.commonApiService.GetByPaginated(Commonvariable.Designation, requestData).subscribe((response) => {
            if (response["data"] != "") {
               this.designationList = response["data"];
            }
         })

      }

   }


   async getDeputyList(company_Id = 0, Dept_Id = 0, Desig_Id = 0, Emp_Id = 0) {
      this.deputyList = [];
      var requestData = {
         "searchBy": [
            {
               "FieldName": "Company_Id",
               "FieldValue": company_Id != 0 ? company_Id : this.empJobDetailform.controls.company_Id.value,
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "Dept_Id",
               "FieldValue": Dept_Id != 0 ? Dept_Id : this.empJobDetailform.controls.Dept_Id.value,
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "Desig_Id",
               "FieldValue": Desig_Id != 0 ? Desig_Id : this.empJobDetailform.controls.Desig_Id.value,
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "Emp_Id",
               "FieldValue": Emp_Id != 0 ? Emp_Id : this.empJobDetailform.controls.Emp_Id.value,
               "Parameter": "!=",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }

         ],
         "OrderBy": "FirstName"
      }
      if (this.empJobDetailform.controls.Desig_Id.value != '') {
         this.commonApiService.GetByPaginated(Commonvariable.EmployeeDetail, requestData).subscribe((result) => {
            if (result["data"] != "" && result["data"] != null) {
               this.deputyList = result["data"];
               // this.deputyList = result["data"].map(x => ({
               //    id: x.emp_Id,
               //    text: x.firstName + " " + x.lastName
               // }))
            }
         })
      }

   }

   getRafListAll() {
     
         this.commonApiService.List(Commonvariable.VacancyService, "GetDropDownListAllRef").subscribe((result) => {
            if (result["data"] != "") {
              
               this.AllrafList = result["data"];
               console.log(this.AllrafList);
            }
         })
      
   }


   async getRafList(id) {
      
      this.rafList = [];
      let postRequest = {
         "searchBy": [
            {
               "FieldName": "Open",
               "FieldValue": "0",
               "Parameter": ">",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "site_Id",
               "FieldValue": id,
               "Parameter": "=="
            }
         ],
         "PageSize": 1000
      }

      if (id > 0) {
         await this.commonApiService.getPaginated(Commonvariable.VacancyService, null, null, null, null, 0, postRequest, 'RAFWiseGetPaginated').subscribe((result) => {
            if (result != null && result["data"] != "") {
               this.rafList = result["data"]["list"];
               //this.getDeputyList()
            }
         })
      }
   }

   getJobDescriptionList(id) {
      this.JDList = [];
      let postRequest = {
         "searchBy": [
            {
               "FieldName": "desig_Id",
               "FieldValue": id,
               "Parameter": "==",
               "ConditionWith": "&&",
            },
            {
               "FieldName": "isActive",
               "FieldValue": 1,
               "Parameter": "=="
            }
         ]
      }

      if (id > 0) {
         this.commonApiService.getPaginated(Commonvariable.JobDescription, null, null, null, null, 0, postRequest, 'GetByPaginated').subscribe((result) => {
            if (result != null && result["data"] != "") {
               this.JDList = result["data"];
            }
         })
      }
   }



   // getEmployeeList(requestData = null) {
   //    requestData = {
   //       "searchBy": [
   //          {
   //             "FieldName": "Company_Id",
   //             "FieldValue": this.empJobDetailform.controls.company_Id.value,
   //             "Parameter": "==",
   //             "ConditionWith": "&&"
   //          },
   //          {
   //             "FieldName": "isActive",
   //             "FieldValue": "1",
   //             "Parameter": "=="
   //          }
   //       ],
   //       "OrderBy": "firstName"
   //    }


   //    this.commonApiService.empDetailList(Commonvariable.EmployeeDetail, requestData).subscribe((result) => {
   //       if (result != null && result["data"] != "") {
   //          this.empList = result["data"].filter(x => x.isActive != 0);
   //       }
   //    })
   // }



   empUpsert() {

      if (this.validation()) {
         var formData = this.empJobDetailform;
         return this.empJobDetailform.value;
      }
      return "";
   }


   empEdit(Id) {
      
      this.getCompany();
      if (Id > 0) {
         this.AutoGenButtonVisibal = false;
         this.commonApiService.getById(Commonvariable.Employee, Id).subscribe((response) => {
            if (response != null && response["data"] != "") {
               var editData = response["data"][0];
              
               //$("#vacancy_Name").val(editData.vacancy_Name);

               
               let date = response["data"][0].joiningDate != null ? response["data"][0].joiningDate.split("T")[0].split("-") : "";
               if (date != "") {
                  $("#JoiningDate").val(date[2] + "-" + date[1] + "-" + date[0]);
               }
               let date1 = response["data"][0].joiningDate;
               date1 = new Date(date1);
               
               // Initialize the newDate variable
               let newDate = new Date(date1);
               newDate.setDate(date1.getDate() + 60);
               if(newDate < new Date()) {
                  this.isInputDisabled = true;
               }
               else{
                  this.isInputDisabled = false;
               }

               let dateEnd = response["data"][0].contract_End_Date != null ? response["data"][0].contract_End_Date.split("T")[0].split("-") : "";
               if (dateEnd != "") {
                  $("#Contract_End_Date").val(dateEnd[2] + "-" + dateEnd[1] + "-" + dateEnd[0]);
               }

               let dateJoin = response["data"][0].emp_Joining_Date != null ? response["data"][0].emp_Joining_Date.split("T")[0].split("-") : "";
               if (dateJoin != "") {
                  $("#Emp_Joining_Date").val(dateJoin[2] + "-" + dateJoin[1] + "-" + dateJoin[0]);
               }

              

               if (editData.dbS_Required == 1) { 
                  $('#DBS_Required').prop('checked', 'true');
                 
                     this.isDBDreq = true;
                    
                     setTimeout(() => {
                        this.commonService.flatpickr("flatpickropenDBS", 0)
                        let dbsExpDate = response["data"][0].dbS_Expiry_Date  != null ? response["data"][0].dbS_Expiry_Date.split("T")[0].split("-") : "";
                        if (dbsExpDate != "") {
                           $("#DBS_Expiry_Date").val(dbsExpDate[2] + "-" + dbsExpDate[1] + "-" + dbsExpDate[0]);
                        }
               
                     }, 5);
                    
                  
                }
               else { 
                  $('#DBS_Required').prop('checked', 'false');
                  this.isDBDreq = false;
                }
               // let dateEnd1 = response["data"][0].emp_Role_End;
               // dateEnd1 = new Date(dateEnd1);
               
               // // Initialize the newDate variable
               // let newEndDate = new Date(dateEnd1);
               // newEndDate.setDate(dateEnd1.getDate() + 60);
               // if(newEndDate < new Date()) {
               //    this.isInputDisabledED = true;
               // }
               // else{
               //    this.isInputDisabledED = false;
               // }


               var form = this.empJobDetailform.controls;
               form.vacancy_Name.setValue(editData.vacancy_Name != null ? editData.vacancy_Name : "");
               form.Emp_Id.setValue(editData.emp_Id != null ? editData.emp_Id : "");
               form.company_Id.setValue(editData.company_Id != null ? editData.company_Id : "");
               form.Site_Id.setValue(editData.site_Id != null ? editData.site_Id : 0);
               form.Vacancy_Id.setValue(editData.vacancy_Id != null ? editData.vacancy_Id : "");
               form.Dept_Id.setValue(editData.dept_Id != null ? editData.dept_Id : "");
               form.Zone_Id.setValue(editData.zone_Id != null ? editData.zone_Id : "");
               form.contract_Id.setValue(editData.emp_Contract_Id != null ? editData.emp_Contract_Id : "");
               form.contract_Name.setValue(editData.contract_Name != null ? editData.contract_Name : "");
               form.Emp_Contract_Id.setValue(editData.emp_Contract_Id != null ? editData.emp_Contract_Id : 0);
               form.Emp_Role_Start.setValue(editData.emp_Role_Start != null ? editData.emp_Role_Start : "");
               form.contract_Id.setValue(editData.emp_Contract_Id != null ? editData.emp_Contract_Id : "");
               form.Contract_End_Date.setValue(editData.contract_End_Date != null ? editData.contract_End_Date : "");
               
               //form.Emp_Joining_Date.setValue(editData.emp_Joining_Date != null ? editData.mp_Joining_Date : "");
               form.Emp_Job_Title.setValue(editData.emp_Job_Title != null ? editData.emp_Job_Title : "");
               form.Desig_Id.setValue(editData.desig_Id != null ? editData.desig_Id : "");
               form.JD_Id.setValue(editData.jD_Id != null ? editData.jD_Id : "");
               form.Shift_Id.setValue(editData.shift_Id != null ? editData.shift_Id : "");
               form.Tupe.setValue(editData.tupe != null ? editData.tupe : "");
               form.NiNo.setValue(editData.niNo);
               form.Loan.setValue(editData.loan);
               form.Emp_Code.setValue(editData.emp_Code);
               form.Reporting_Id.setValue(editData.reporting_Id != null ? editData.reporting_Id : "");
               form.Line_Manager_Id.setValue(editData.line_Manager_Id != null ? editData.line_Manager_Id : '0');
               form.Work_Email.setValue(editData.work_Email);
               form.Deputy.setValue(editData.deputy);
               form.Work_Phone.setValue(editData.work_Phone);
               form.Work_Extension.setValue(editData.work_Extension);
               form.updatedBy.setValue(Commonvariable.User_Id);
               form.shift_Start.setValue(editData.shift_Start);
               form.shift_End.setValue(editData.shift_End);
               form.nightShift.setValue(editData.nightShift);
               form.shift_Variable.setValue(editData.shift_Variable);
               form.DBS_Required.setValue(editData.dbS_Required);
               setTimeout(() => {
                  this.getRafListAll()
                  this.getRafList(editData.site_Id)
                  this.getJobDescriptionList(editData.desig_Id)
                  this.companyWiseGetData(true);
                  this.getReportingList();
                  this.getContractList(editData.company_Id);
                  this.getholidayEmpList();
                  this.getlinemanagerEmpList();
               }, 100);

               //$("#Emp_Code").prop("disabled", true);
               // $("#Vacancy_Id").prop("disabled", true);
              

               if (editData.nightShift == 1) { $('#nightShift').prop('checked', 'true'); }
               else { $('#nightShift').prop('checked', 'false'); }

               if (editData.shift_Variable == 1) 
               { $('#shift_Variable').prop('checked', 'true');
             }
               else
                { $('#shift_Variable').prop('checked', 'false'); }

               if (editData.tupe == 1) { $('#Tupe_yes').prop('checked', 'true'); }
               else { $('#Tupe_no').prop('checked', 'true'); }
               form.DBS_Required.setValue(editData.dbS_Required != null ? editData.dbS_Required : 0);
               form.shift_Variable.setValue(editData.shift_Variable != null ? editData.shift_Variable : 0);
               form.RAF_Approval.setValue(editData.raF_Approval != null ? editData.raF_Approval : 0);
               form.IsHOD.setValue(editData.isHOD == 1 ? true : false);

               form.Is_Line_Manager.setValue(editData.is_Line_Manager == 1 ? true : false);
               form.Is_Holiday_Approvel.setValue(editData.is_Holiday_Approvel == 1 ? true : false);

            }
         })
      }
   }





   validation() {
      
      var formData = this.empJobDetailform;
    //  var shift_End = $("#shift_End").val();
     // var shift_Start = $("#shift_Start").val();
     // formData.controls.shift_Start.setValue(shift_Start);
     // formData.controls.shift_End.setValue(shift_End);
      formData.controls.JoiningDate.setValue(this.commonService.dateFormatChange($("#JoiningDate").val()));
      formData.controls.Emp_Role_End.setValue(this.commonService.dateFormatChange($("#Contract_End_Date").val()));
      formData.controls.Contract_End_Date.setValue(this.commonService.dateFormatChange($("#Contract_End_Date").val()));
      if (this.isDBDreq === true) {
      formData.controls.DBS_Expiry_Date.setValue(this.commonService.dateFormatChange($("#DBS_Expiry_Date").val()));}
      
      //formData.controls.Emp_Joining_Date.setValue(this.commonService.dateFormatChange($("#Emp_Joining_Date").val()));
      formData.controls.contract_Name.setValue($("#contract_Id option:selected").text().trim());

      for (const key of Object.keys(formData.controls)) {
         if (formData.controls[key].invalid) {
            let fieldName = "";
            if (key == "company_Id") {
               fieldName = "Company"
            }
            // else if (key == "Site_Id") {
            //    fieldName = "Site"
            // }
            else if (key == "Dept_Id") {
               fieldName = "Department"
            }
            else if (key == "Zone_Id") {
               fieldName = "Zone"
            }
            else if (key == "Desig_Id") {
               fieldName = "Job Title"
            }
            // else if (key == "JD_Id") {
            //    fieldName = "Job Description"
            // }
            else if (key == "Emp_Code") {
               fieldName = "Employee Number"
            }
            // else if (key == "Shift_Id") {
            //    fieldName = "Shift Time"
            // }
            else if (key == "shift_Start") {
               fieldName = "Shift start"
             }
             else if (key == "shift_End") {
               fieldName = "Shift end"
             }
            else if (key == "JoiningDate") {
               fieldName = "Start Date"
            }
            // else if (key == "Emp_Role_End") {
            //    fieldName = "Contract End Date"
            // }
            else if (key == "Reporting_Id") {
               fieldName = "Holiday approver"
            }
            this.toastr.info(fieldName + " is required !", "Employee");
            $("#" + key).focus();
            return false;
         }
         else {
            if ($("#shift_Start").val() == '00:00') {
               this.commonService.toaster("Shift Start is required !", "Shift");
               $("#shift_Start").focus();
               return false;
             }
       
            
       
             if ($("#shift_End").val() == '00:00') {
               this.commonService.toaster("Shift End is required !", "Shift");
               $("#shift_End").focus();
               return false;
             }
            if ($("#Emp_Code").val().search(/[^a-zA-Z0-9-]+/) > -1) {
               this.toastr.info("Employee Number only alphabets ,numeric and '-' allow !", "Employee");
               $("#Emp_Code").focus();
               return false;
            }
            if ($("#NiNo").val().search(/[^a-zA-Z0-9]+/) > -1) {
               this.toastr.info("NI Number only alphabets ,numeric allow !", "Employee");
               $("#NiNo").focus();
               return false;
            }
            // if ($("#Emp_Code").val() != "" && $("#Emp_Code").val().length < 2) {
            //    this.toastr.info("Employee Number must be at least 2 characters long !", "Employee");
            //    $("#Emp_Code").focus();
            //    return false;
            // if ($("#Emp_Code").val() != "" && $("#Emp_Code").val().length < 1) {
            //    this.toastr.info("Employee Number must be at least 2 characters long !", "Employee");
            //    $("#Emp_Code").focus();
            //    return false;
            // }
            if ($("#JoiningDate").val() != "" && $("#JoiningDate").val().search(/^(0[1-9]|[12][0-9]|3[01])[-.](0[1-9]|1[012])[-.](19|20)\d\d$/) != 0) {
               this.toastr.info("Join Date Valid Format !", "Employee");
               $("#JoiningDate").focus();
               return false;
            }

            // if ($("#Emp_Role_End").val() != "" && $("#Emp_Role_End").val().search(/^(0[1-9]|[12][0-9]|3[01])[-.](0[1-9]|1[012])[-.](19|20)\d\d$/) != 0) {
            //    this.toastr.info("Join Date Valid Format !", "Employee");
            //    $("#Emp_Role_End").focus();
            //    return false;
            // }

            if ($("#Work_Email").val() != "" && $("#Work_Email").val().search(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/) != 0) {
               this.toastr.info("Enter valid email address  !", "Employee");
               $("#Work_Email").focus();
               return false;
            }

            // if ($("#Work_Phone").val() != "" && $("#Work_Phone").val().length < 9) {
            //    this.toastr.info("Mobile must be at least 9 digit long !", "Employee");
            //    $("#Work_Phone").focus();
            //    return false;
            // }

            // if ($("#Work_Phone").val() != "" && $("#Work_Phone").val().length < 10) {
            //    this.toastr.info("Phone no must be 10 digit  !", "Employee");
            //    $("#Work_Phone").focus();
            //    return false;
            // }

            // if ($("#Tupe").val() != "" && $("#Tupe").val().length > 5) {
            //    this.toastr.info("Tupe not more then 5 digit long !", "Employee");
            //    $("#Tupe").focus();
            //    return false;
            // }
         }
      }

      if (this.isDBDreq === true) {
            if (formData.controls.DBS_Expiry_Date.value == "") {
               this.toastr.info("DBS Expiry Date is required !", "Employee");
               $("#DBS_Expiry_Date").focus();
               return false;
            }
      }
      this.commonService.setNull(formData);
      if (formData.controls.RAF_Approval.value == true) {
         formData.controls.RAF_Approval.setValue(1)
      } else {
         formData.controls.RAF_Approval.setValue(0)
      }
   
      formData.controls.IsHOD.setValue(formData.controls.IsHOD.value ? 1 : 0)
      formData.controls.nightShift.setValue(formData.controls.nightShift.value ? 1 : 0)
      formData.controls.shift_Variable.setValue(formData.controls.shift_Variable.value ? 1 : 0)
      formData.controls.DBS_Required.setValue(formData.controls.DBS_Required.value ? 1 : 0)
      formData.controls.Is_Holiday_Approvel.setValue(formData.controls.Is_Holiday_Approvel.value ? 1 : 0)
      formData.controls.Is_Line_Manager.setValue(formData.controls.Is_Line_Manager.value ? 1 : 0)

      return true;
   }

   checkEmpCode() {
     
      let id = this.empJobDetailform.controls.Emp_Code.value + "," + this.empJobDetailform.controls.company_Id.value

      this.commonApiService.getById("Employee", id, "EmpCodeExists").subscribe((result) => {
         if (result != null && result["status"] == 412) {
            this.empJobDetailform.controls.Emp_Code.setValue('');
            this.toastr.info(result["message"], "Employee");
         }
         else if (result != null && result["status"] == 409) {

            this.empJobDetailform.controls.Emp_Code.setValue('');

            this.toastr.info(result["message"], "Employee");
            //this.genrateEmployeeCode(this.empJobDetailform.controls.company_Id.value);
         }
         else if (result != null && result["status"] == 200) {
            //this.genrateEmployeeCode(this.empJobDetailform.controls.company_Id.value);
         }

      }
         // , (error) => { 
         //    console.log("c");
         //    //this.genrateEmployeeCode(this.empJobDetailform.controls.company_Id.value)

         //  }
      )
   }

   // filechange(files: FileList)
   // {
   //    this.companyBasicInfoform.controls.documents.setValue(files.item(0));
   // }


   setFormData(fieldName, id) {
      if (id != undefined) {
        
         if (fieldName == 'Reporting_Id') {
            this.empJobDetailform.controls.Reporting_Id.setValue(id)
         }
         if (fieldName == 'Line_Manager_Id') {
            this.empJobDetailform.controls.Line_Manager_Id.setValue(id)
         }
         if (fieldName == 'Deputy') {
            this.empJobDetailform.controls.Deputy.setValue(id)
         }
      }
   }
   openModel() {
      $('#draggable').modal({
        backdrop: 'static'
      });
      //console.log($(".siteSelectListClass option:selected").val())
      this.empJobDetailform.controls.Company_Id.setValue($(".companySelectListClass option:selected").val());
      this.empJobDetailform.controls.Dept_Id.setValue($(".departmentSelectListClass option:selected").val());
      this.empJobDetailform.controls.Desig_Id.setValue($("#Desig_Id option:selected").val());
      this.empJobDetailform.controls.JD_Id.setValue($(".jdSelectListClass option:selected").val());
      this.JDescription.parentComponent(this.empJobDetailform.value)
  
    }
    
  
   download() {
      

      if (parseInt(this.empJobDetailform.controls.JD_Id.value) > 0) {
        //var fileName = String(this.jobTitleList.filter(x => x.jD_Id == this.empJobDetailform.controls.JD_Id.value)[0]["path"]).split(".")[1]
        //this.JDescription.download(fileName == undefined ? ".pdf" : fileName, parseInt(this.vacancyForm.controls.JD_Id.value));
        this.commonApiService.getimage(Commonvariable.JobDescription, parseInt(this.empJobDetailform.controls.JD_Id.value), "DownloadDocument").subscribe((resp: any) => {
  
          if (resp.type != "application/json") {
            const blobData = new Blob([resp], { type: resp.type });
            var createObjectURL = (window.URL.createObjectURL(blobData));
    
            if (resp.type == "application/pdf" || resp.type == "image/jpeg" || resp.type == "image/png") {
              this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
              $("#modalJobDescription").modal("show")
            } else if (resp.type == "application/vnd.ms-word") {
              const a = document.createElement('a');
              a.setAttribute('type', 'hidden');
              a.href = URL.createObjectURL(blobData);
              a.download = 'Job_Description.docx';
              a.click();
              a.remove();
            } else {
    
              const a = document.createElement('a');
              a.setAttribute('type', 'hidden');
              a.href = URL.createObjectURL(blobData);
              a.download = 'Job_Description';
              a.click();
              a.remove();
    
            }
          }else{
            this.commonService.toaster("warning", 'Document Not Available', 'Job Description')
          }
          
        });
      }
      else{
         this.commonService.toaster("warning", 'Not selected.', 'Job Description')
       }
    }
    modalJobDescriptionclose(){
      $("#modalJobDescription").modal("hide");
    }
    onChangeTimeS() {
      var Shift_Duration = $("#Shift_Duration").val();
      if (Shift_Duration != 0 && Shift_Duration != "") {
        var selectedStartTime = moment($("#shift_Start").val(), 'H:m').format("H:m");
        var endTime = moment(selectedStartTime, 'H:m').add(Shift_Duration, 'hours').format("H:m");
        $("#shift_End").flatpickr({ dateFormat: "H:i", defaultDate: endTime, enableTime: true, noCalendar: true, time_24hr: true });
      }
      else {
        $("#shift_End").flatpickr({ dateFormat: "H:i", defaultDate: "", enableTime: true, noCalendar: true, time_24hr: true });
      }
  
      var selectedStartShift = moment($("#shift_Start").val(), 'H:mm').format("H");
     
    }

    dbsclick(event) {
      var checkboxclick = event.target;
      if (checkboxclick.checked) {
         this.isDBDreq = true;
        
         setTimeout(() => {
            this.commonService.flatpickr("flatpickropenDBS", 0)
         }, 5);

      }
      else {
         this.isDBDreq = false;
      }

   }
    

}



