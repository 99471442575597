<div class="modal fade draggable-modal in" id="draggable" role="basic" aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="closeModal()" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Probation</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="probationform">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="">
                                <label class="control-label">Company<span class="required"> *
                                    </span></label>
                                <ng-select2 *ngIf="title != 'Edit'" [data]="companylist" id="company_Id" [options]="{multiple: true,tags: false}" [disabled]="title == 'Edit'?true:false" formControlName="company_Id" [placeholder]="'--- Select ---'">
                                </ng-select2>
                                <input *ngIf="title == 'Edit'" type="text" id="company_Id" class="form-control" formControlName="company_Name" readonly>

                            </div>
                        </div>
                        <!-- <div class="col-md-4">
                            <div class="">
                                <label class="control-label">Probation Code<span class="required"> *
                                    </span></label>
                                <input type="text" id="prob_Code" class="form-control" formControlName="prob_Code"
                                    autocomplete="off">
                            </div>
                        </div> -->
                        <div class="col-md-6">
                            <div class="">
                                <label class="control-label">Probation<span class="required"> *
                                    </span></label>
                                <input type="text" id="prob_Name" class="form-control" formControlName="prob_Name" maxlength="30" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <div class="  probationdurationbox">
                                <label class="control-label">Probation Duration<span class="required"> *
                                    </span></label>
                                <input type="number" id="prob_Duration" class="form-control" min="1" max="99" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"' max="500" formControlName="prob_Duration">
                                <select id="prob_DurationUnit" class="form-control" formControlName="prob_DurationUnit">
                                    <option value="">Select Probation Duration</option>
                                    <option value="Day">Day</option>
                                    <option value="Month">Month</option>
                                    <option value="Year">Year</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="">
                                <label class="control-label">Notes</label>
                                <textarea id="notes" class="form-control" rows="2" formControlName="notes"></textarea>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="">
                                <label class="control-label">Active</label>
                                <div class="md-checkbox-inline">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="checkbox1_3" class="md-check" formControlName="isActive">
                                        <label for="checkbox1_3">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                            <div class="">
                                <label class="control-label">Probation Description</label>
                                <textarea id="prob_Description" class="form-control" rows="2"
                                    formControlName="prob_Description"></textarea>
                            </div>
                        </div> -->
                    </div>

                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn dark btn-outline pull-left" (click)="history()" *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button>
                <button type="button" (click)="closeModal()" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="ProbationUpsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Probation History</h4>
            </div>
            <div class="modal-body" *ngIf="title == 'Edit'">
                <app-history></app-history>
            </div>
        </div>
    </div>
</div>