import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
import { retry } from 'rxjs/operators';
declare var $: any;

@Component({
   selector: 'app-empDocument',
   templateUrl: './employee-document.component.html',
})
export class EmployeeDocumentComponent implements OnInit {

   empform: FormGroup;
   items: FormArray;
   documentList: any;
   files: any[] = [];
   constructor(
      private toastr: ToastrService,
      private commonApiService: CommonApiService,
      private commonService: CommonService,
      private router: Router,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {

      this.empform = this.fb.group({
         emp_document: this.fb.array([this.createItem()]),
      });

      this.getDocument();
   }

   getDocument() {
      this.commonApiService.List(Commonvariable.Document).subscribe((response) => {
         if (response != null && response["data"] != "") {
            this.documentList = response["data"].sort((a, b) => a.name.localeCompare(b.name));
         }
      })
   }


   createItem() {
      return this.fb.group({
         Emp_Doc_Id: '0',
         Emp_Id: '0',
         Doc_Id: ['', Validators.required],
         files: '',
         Parent_Emp_Doc_Id: null,
         Emp_Doc_Name: ['', Validators.required],
         Notes: null,
         isActive: true,
         is_CompanyDoc:false,
         addedBy: Commonvariable.User_Id,
      });
   }

   addItem() {
      if(this.validation())
      {
         this.items = this.empform.get('emp_document') as FormArray;
         this.items.push(this.createItem());
      }
      
   }

   removeItem(index) {
      this.items = this.empform.get('emp_document') as FormArray;
      this.items.removeAt(index);
   }

   empUpsert() {
      
      var empData = this.empform.get('emp_document').value;
      if (empData[0].files == "") {
         return false;
      }
      return true;
   }

   validation() {
      var empData = this.empform.get('emp_document').value;
      var tempArray = [];
      if (empData[0].Doc_Id != "" || empData[0].Emp_Doc_Name != "") {
         for (let index = 0; index < empData.length; index++) {
            if (empData[index].Doc_Id == "" && !empData[index].is_CompanyDoc) {
               this.toastr.info("Document is required !", "Employee");
               $("#Doc_Id" + index).focus();
               return false;
            }

            if (empData[index].Emp_Doc_Name == "" && !empData[index].is_CompanyDoc) {
               this.toastr.info("Document name is required !", "Employee");
               $("#Emp_Doc_Name" + index).focus();
               return false;
            }

            // if(!empData[index].is_CompanyDoc){
            //    if ($("#Emp_Doc_Name" + index).val().search(/[^a-zA-Z0-9\s]+/) > -1) {
            //       this.toastr.info("Document name only alphabets and numeric allow !", "Employee");
            //       $("#Emp_Doc_Name" + index).focus();
            //       return false;
            //    }
            // }
            
            // if ($("#Emp_Doc_Name" + index).val().search(/^[^-\s][a-zA-Z0-9_\s-]+$/) != 0) {
            //    this.toastr.info("Document name not allow space !", "Employee");
            //    $("#Emp_Doc_Name" + index).focus();
            //    return false;
            // }

            

            if (empData[index].files == "" || empData[index].files == undefined) {
               this.toastr.info("File is required !", "Employee");
               return false;
            }

            // var tempData = {
            //    "Doc_Id": String(empData[index].Doc_Id).trim(),
            //    "Emp_Doc_Name": String(empData[index].Emp_Doc_Name).trim(),
            // }

            // if (tempArray.length > 0 && !empData[index].is_CompanyDoc) {
            //    var Doc_Id = tempArray.filter(x => x.Doc_Id == tempData.Doc_Id).length;
            //    var Emp_Doc_Name = tempArray.filter(x => x.Emp_Doc_Name == tempData.Emp_Doc_Name).length;

            //    if (Doc_Id > 0 && Emp_Doc_Name > 0) {
            //       this.toastr.info("This value already entered !", "Employee");
            //       $("#Doc_Id" + index).focus();
            //       return false;
            //    }
            // }


            // tempArray.push(tempData);
            // empData[index].isActive = empData[index].isActive ? 1 : 0
            // empData[index].is_CompanyDoc = empData[index].is_CompanyDoc ? 1 : 0
         }
      }

      return true;
   }

   drpchange(id) {
      var empData = this.empform.get('emp_document').value;
      empData[id].files = this.files[id]
   }


   fileChange(event, id) {

      // if (event.target.files.item(0).name.split('.')[1] == "pdf" || event.target.files.item(0).name.split('.')[1] == "doc"
      //    || event.target.files.item(0).name.split('.')[1] == "docx" || event.target.files.item(0).name.split('.')[1] == "xlsx") {
         var empData = this.empform.get('emp_document').value;
         empData[id].files = event.target.files[0];

         this.items = this.empform.get('emp_document') as FormArray;
         this.items.controls[id].setValue(empData[id]);

         this.files.push(event.target.files[0]);
      // }
      // else {
      //    this.toastr.info("Allow only pdf,doc,docx,xlsx file format for upload !", "Employee");
      //    $("#remove" + id).click();
      // }

   }

   removeDoc(index) {
      var empData = this.empform.get('emp_document').value;
      empData[index].files = "";
      this.files.reduce(index);
   }



}


