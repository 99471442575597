<div class="modal fade draggable-modal in" id="draggable" tabindex="-1" role="basic" aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closeModal()"></button>
                <h4 class="modal-title">HR Policies</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="hr_policiesform">
                    <div class="row">
                       
                        <div class="col-lg-2">
                            <input type="text" id="Ref_Name" class="form-control" formControlName="Ref_Name" placeholder="Ref Name">
                        </div>
                        <div class="col-lg-4">
                            <input type="text" id="Doc_Description" class="form-control" formControlName="Doc_Description" placeholder="Document Name">
                        </div>

                         <div class="col-lg-6">
                         
                        <div class="fileinput fileinput-new" data-provides="fileinput">


                            <div class="input-group input-large">
                                <div class="form-control uneditable-input input-fixed input-medium"
                                    data-trigger="fileinput">
                                    <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                    <span class="fileinput-filename"> </span>
                                </div>
                                <span class="input-group-addon btn default btn-file">
                                    <span class="fileinput-new"> Select file </span>
                                    <span class="fileinput-exists"> Change </span>
                                    <input type="file" id="Emp_Doc_Path" (change)="fileChange($event)">
                                </span>
                                <a href="javascript:;" id="remove"
                                    class="input-group-addon btn red fileinput-exists" (click)="removeDoc()"
                                    data-dismiss="fileinput"> Remove </a>
                            </div>
                        
                        </div>
                    </div>

                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="closeModal()" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="Upsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div> 


