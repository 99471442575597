<form [formGroup]="companyContactform">
    <div class="form-group">
        <div class="table-resposive">
            <table class="table table-hover" id="work"
                style="max-height: 600px;height: auto;overflow-y: auto;display: block;">
                <thead>
                    <tr>
                        <th style="width: 49%;">Contact Type <span class="required"> * </span></th>
                        <th style="width: 49%;">Contact Info <span class="required"> * </span></th>
                        <th style="width: 0.5%;">Default</th>
                        <th style="width: 0.5%;">Active</th>
                        <th style="width: 0.5%;"></th>
                    </tr>
                </thead>
                <tbody formArrayName="company_Contact">
                    <tr [formGroupName]="i"
                        *ngFor="let item of companyContactform.get('company_Contact')['controls']; let i = index;">
                        <td>
                            <!-- <div style="width: 5%;" class="circleround" *ngIf="companyContactform.get('company_Contact').value[i].company_Contact_Id != '0'">
                                <div class="circle greenColor" *ngIf="companyContactform.get('company_Contact').value[i].isActive" data-toggle="tooltip"
                                        data-placement="top" title="Active"></div>
                                <div class="circle redColor" *ngIf="!companyContactform.get('company_Contact').value[i].isActive" data-toggle="tooltip"
                                        data-placement="top" title="Inactive"></div>
                            </div> -->
                            <select id="Contact_Type{{i}}" class="form-control circleselect" #contact_Type
                                formControlName="contact_Type">
                                <option value="">Select Contact Type</option>
                                <option value="Email">Email</option>
                                <option value="Fax">Fax</option>
                                <option value="Mobile No">Mobile No</option>
                                <option value="Phone No">Phone No</option>
                                <option value="WebSite">WebSite</option>
                            </select>
                            <!-- <input type="text" class="form-control" id="contact_Type{{i}}" formControlName="contact_Type" autocomplete="off" maxlength="100"> -->
                        </td>
                        <td>
                            <input type="text" class="form-control" id="contact_Value{{i}}"
                                *ngIf="contact_Type.value != 'Mobile No' && contact_Type.value != 'Phone No'"
                                formControlName="contact_Value" autocomplete="off" maxlength="100">


                            <div class="input-group mobilenowithcountryode"
                                *ngIf="contact_Type.value == 'Mobile No' || contact_Type.value == 'Phone No'" >
                                <div class="input-group-btn">
                                    <ng-select2 [data]="countryList" id="country_Id{{i}}" formControlName="country_Id" [placeholder]="'--- Select ---'">
                                    </ng-select2>
                                </div>
                                <input type="text" class="form-control" id="contact_Value{{i}}"
                                    formControlName="contact_Value" autocomplete="off" maxlength="20"
                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.key === "Backspace" ||  event.charCode == 40 
                                  || event.charCode == 32 || event.charCode == 41 || event.charCode == 43 || event.charCode == 45'>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="isDefault{{i}}" class="md-check"
                                        formControlName="isDefault">
                                    <label for="isDefault{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="isActive{{i}}" class="md-check"
                                        formControlName="isActive">
                                    <label for="isActive{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td>
                        <td>
                            <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                                    class="fa fa-minus" aria-hidden="true"></i></button>
                        </td>
                    </tr>

                </tbody>
            </table>
            <div class="row">
                <div class="col-md-11" style="width: 99%!important;">
                    <button type="button" (click)="addItem(null)" class="btn pull-right"><i class="fa fa-plus"
                            aria-hidden="true"></i></button>
                </div>
            </div>
        </div>
    </div>
</form>