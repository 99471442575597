<style>
    .modal {
        z-index: 0;
    }
</style>
<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>Onboarding list</h1>
            </div>
        </div>
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <!-- <ul class="nav navbar-nav">
                <li>
                    <div class="col-md-6">
                        <div class="input-group input-xlarge">
                            <div class="input-group-btn">
                                <button type="button" class="btn blue dropdown-toggle" data-toggle="dropdown">Search By
                                    <i class="fa fa-angle-down"></i>
                                </button>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="col-md-3">
                                            <div class="md-radio-inline">
                                                <div class="md-radio has-success">
                                                    <input type="radio" id="radio15" value="FirstName" name="filter"
                                                        class="md-radiobtn">
                                                    <label for="radio15">
                                                        <span class="inc"></span>
                                                        <span class="check"></span>
                                                        <span class="box" style="top:4px !important"></span> First Name
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="col-md-3 md-radio-inline">
                                            <div class="md-radio has-success">
                                                <input type="radio" id="radio17" value="Company_Name" name="filter"
                                                    class="md-radiobtn">
                                                <label for="radio17">
                                                    <span class="inc"></span>
                                                    <span class="check"></span>
                                                    <span class="box" style="top:4px !important"></span> Company
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="col-md-3 md-radio-inline">
                                            <div class="md-radio has-success">
                                                <input type="radio" id="rdbDepartment" value="Dept_Name" name="filter"
                                                    class="md-radiobtn">
                                                <label for="rdbDepartment">
                                                    <span class="inc"></span>
                                                    <span class="check"></span>
                                                    <span class="box" style="top:4px !important"></span> Department
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="col-md-3 md-radio-inline">
                                            <div class="md-radio has-success">
                                                <input type="radio" id="radio16" value="Desig_Name" name="filter"
                                                    class="md-radiobtn">
                                                <label for="radio16">
                                                    <span class="inc"></span>
                                                    <span class="check"></span>
                                                    <span class="box" style="top:4px !important"></span> Designation
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <input type="text" id="filterValue" class="form-control" placeholder="Search"
                                autocomplete="off">
                            <span class="input-group-btn">
                                <button class="btn blue" (click)="searchOneValue()" type="button">Go!</button>
                                <button class="btn btn-default" type="button" (click)="clearSearch()"><i
                                        class="fa fa-refresh"></i></button>
                            </span>
                        </div>
                    </div>
                </li>
               
            </ul> -->
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div class="col-md-2">
                        <a data-toggle="modal" href="#filterModal" class="btn btn-transparent blue  btn-sm mrl10"
                            (click)="clickOnModal()">
                            Advanced Search</a>
                    </div>
                </li>
                <li>
                    <div class="col-md-2">
                        <a routerLink="/candidateadd" class="btn btn-transparent blue  btn-sm"
                            (click)="activeClass()">New Onboarding</a>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-3 text-left">
                            <select class="form-control" id="status" (change)="getCandidateListCan()">
                                <option value="All">All</option>
                                <option *ngFor="let item of candidateStatus;" value={{item.name}}>
                                    {{item.displayName}}</option>

                            </select>
                        </div>
                        <div class="col-md-3"></div>
                        <div class="col-md-3"></div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" placeholder="Type to filter ....."
                                autocomplete="off" id="filterList" (input)="getCandidateListCan()">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped  table-bordered table-hover">
                            <thead class="flip-content">
                                <tr>
                                    <th class="text-center" style="width: 14%;"> Name </th>
                                    <!-- <th class="text-center" style="width: 13%;">Email</th> -->
                                    <th class="text-center" style="width: 13%;">Company</th>
                                    <th class="text-center" style="width: 13%;">Department</th>
                                    <th class="text-center" style="width: 13%;">Designation</th>
                                    <!-- <th class="text-center" style="width: 10%;">Mobile</th> -->
                                    <th class="text-center" style="width: 10%;">Start Date</th>
                                    <th class="text-center" style="width: 7%;">Status</th>
                                    <th class="text-center" style="width: 7%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of candidateList| paginate: { itemsPerPage: 10, currentPage: p  ,totalItems :totalRecord}">
                                <tr [style.background-color]="item.statusBackGroundColor" [style.color]="item.statusFrontColor"
                                    >
                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(item.id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12">
                                            {{item.firstName}} {{item.middleName}} {{item.lastName}}</div>
                                    </td>
                                    <!-- <td>{{item.firstName}} {{item.middleName}} {{item.lastName}}</td> -->
                                    <!-- <td>{{item.eMail}}</td> -->
                                    <td>{{item.company_Name}}</td>
                                    <td>{{item.dept_Name}}</td>
                                    <td>{{item.desig_Name}}</td>
                                    <!-- <td>{{item.mobile}}</td> -->
                                    <td class="text-center">{{item.joiningDate | date :'dd/MM/yyyy'}}</td>
                                    <td>{{item.displayStatus}}</td>
                                    <td>
                                        <a data-toggle="modal"
                                            (click)="getSummary(item.id,item.moveToEmployee,item.status,item.isActive)">
                                            <i class="fa fa-list-alt" data-toggle="tooltip" data-placement="top"
                                                title="View Summary"></i>&nbsp; &nbsp;</a>

                                        <a (click)="SendMail(item.id,item.company_Id)" *ngIf="item.moveToEmployee != 1  && item.status != 'Reviewed'  && item.status != 'ReadyToCreate' && item.status != 'Cancel' && item.isActive &&
                                            item.status != 'SentToCandidate' && item.status != 'Submit'  ">
                                            <i class="fa fa-envelope-o iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Send Mail"></i>&nbsp; &nbsp;</a>

                                        <a (click)="SendMail(item.id,item.company_Id)"
                                            *ngIf="item.status == 'SentToCandidate' || item.status == 'Submit'">
                                            <i class="fa fa-envelope-o iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Resend Mail"></i>&nbsp; &nbsp;</a>

                                        <a [routerLink]="['/candidateedit']" [queryParams]="{ editId: item.id}"
                                            *ngIf="item.moveToEmployee != 1 && item.status != 'Cancel' && item.isActive && item.status != 'Reviewed' && item.status != 'ReadyToCreate'"><i class="fa fa-edit purple iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Edit Onboarding"></i>&nbsp; &nbsp;</a>

                                        <!-- <a *ngIf="(item.status == 'SentToCandidate' || item.status == 'Reviewed') && item.isActive && item.status != 'Cancel'" 
                                            (click)="reviewCandidate(item.id,'ReadyToCreate')"><i
                                                class="fa fa-check-square-o green iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Ready To Create"></i>&nbsp;
                                            &nbsp;</a> -->

                                        <a *ngIf="item.status == 'SentToCandidate' && item.isActive"
                                            (click)="reviewCandidate(item.id,'Submit')"><i
                                                class="fa fa-check iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Submit"></i>&nbsp;
                                            &nbsp;</a>
                                        <a *ngIf="(item.status == 'Reviewed') && item.isActive && item.status != 'Cancel'"
                                            (click)="userCreation(item.id,'UserCreation',item.company_Id)"><i
                                                class="fa fa-user-plus iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="User Creation"></i>&nbsp;
                                            &nbsp;</a>


                                        <a *ngIf="item.status == 'Submit' && item.isActive && item.status != 'Cancel'"
                                            (click)="candidateReview(item.id,'Reviewed',item.company_Id)"><i
                                                class="fa fa-eye green iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Review"></i>&nbsp;
                                            &nbsp;</a>
                                        <a (click)="moveToEmployee(item.id,item.company_Id)"
                                            *ngIf="item.status == 'ReadyToCreate' && item.isActive && item.status != 'Cancel'"><i
                                                class="fa fa-send green iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Move To Employee"></i>&nbsp;
                                            &nbsp;</a>
                                        <a (click)="reviewCandidate(item.id,'Cancel')"
                                            *ngIf="item.status != 'Close' && item.isActive && item.status != 'Cancel'"><i
                                                class="fa fa-close red-color iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Cancel"></i>&nbsp; &nbsp;</a>
                                        <a (click)="Delete(item.id)" *ngIf="!item.isActive">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Delete Onboarding"></i>&nbsp; &nbsp;</a>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="candidateList != null">
                        <pagination-controls (pageChange)="getCandidateListCan($event)" autoHide="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="draggable" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Candidate</h4>
            </div>
            <div class="modal-body">
                <ul class="nav nav-tabs">
                    <li class="active">
                        <a href="#details" data-toggle="tab">Candidate Details</a>
                    </li>
                    <li><a href="#docUpload" data-toggle="tab">Candidate Document</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane active" id="details">
                        <form [formGroup]="candidateform">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="control-label">Company<span class="required"> *
                                            </span></label>
                                        <select class="form-control" id="company_Id" formControlName="company_Id"
                                            (change)="getSite()">
                                            <option value="">Select Your Company</option>
                                            <option *ngFor="let company of companylist;" value={{company.company_Id}}>
                                                {{company.company_Name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Site<span class="required"> *
                                            </span></label>
                                        <select class="form-control" id="site_Id" formControlName="site_Id">
                                            <option value="">Select Your Site</option>
                                            <option *ngFor="let item of siteList;" value={{item.site_Id}}>
                                                {{item.site_Name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Department<span class="required"> *
                                            </span></label>
                                        <select class="form-control" id="dept_Id" formControlName="dept_Id">
                                            <option value="">Select Your Department</option>
                                            <option *ngFor="let item of departmentList;" value={{item.dept_Id}}>
                                                {{item.dept_Name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Zone<span class="required"> *
                                            </span></label>
                                        <select class="form-control" id="zone_Id" formControlName="zone_Id">
                                            <option value="">Select Your Zone</option>
                                            <option *ngFor="let item of zoneList;" value={{item.zone_Id}}>
                                                {{item.zone_Name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Designation<span class="required"> *
                                            </span></label>
                                        <select class="form-control" id="desig_Id" formControlName="desig_Id">
                                            <option value="">Select Your Designation</option>
                                            <option *ngFor="let item of designationList;" value={{item.desig_Id}}>
                                                {{item.desig_Name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Job Description<span class="required"> *
                                            </span></label>
                                        <select class="form-control" id="jD_Id" formControlName="jD_Id">
                                            <option value="">Select Job Description</option>
                                            <option *ngFor="let item of JDList;" value={{item.jD_Id}}>
                                                {{item.jD_Name}}</option>
                                        </select>
                                        <!-- <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="download()"
                                        title="Download Job Description"><i class="fa fa-download fa-1x"></i></a> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Shift<span class="required"> *
                                            </span></label>
                                        <select class="form-control" id="shift_Id" formControlName="shift_Id">
                                            <option value="">Select Your Shift</option>
                                            <option *ngFor="let item of shiftList;" value={{item.shift_Id}}>
                                                {{item.shift_Name}} [{{item.shift_Start}} - {{item.shift_End}}]</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Mobile<span class="required"> *
                                            </span></label>
                                        <input type="text" id="mobile" class="form-control" formControlName="mobile"
                                            autocomplete="off">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Email<span class="required"> *
                                            </span></label>
                                        <input type="text" id="eMail" class="form-control" formControlName="eMail"
                                            autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">First Name<span class="required"> *
                                            </span></label>
                                        <input type="text" id="firstName" class="form-control"
                                            formControlName="firstName" autocomplete="off">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Middle Name<span class="required"> *
                                            </span></label>
                                        <input type="text" id="middleName" class="form-control"
                                            formControlName="middleName" autocomplete="off">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Last Name<span class="required"> *
                                            </span></label>
                                        <input type="text" id="lastName" class="form-control" formControlName="lastName"
                                            autocomplete="off">
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Total Experience</label>
                                        <input type="text" id="totalExperience" class="form-control"
                                            formControlName="totalExperience" autocomplete="off">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Reference From</label>
                                        <input type="text" id="referenceFrom" class="form-control"
                                            formControlName="referenceFrom" autocomplete="off">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Reference By</label>
                                        <input type="text" id="referenceBy" class="form-control"
                                            formControlName="referenceBy" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">JobStartDate<span class="required"> *
                                            </span></label>
                                        <div class="input-group">
                                            <input type="text" id="jobStartDate" class="form-control" />
                                            <span class="input-group-btn">
                                                <button class="btn default" type="button">
                                                    <i class="fa fa-calendar"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label class="control-label">Notes</label>
                                        <textarea class="form-control" formControlName="notes"
                                            style="max-width: 100%;"></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- <div class="tab-pane" id="docUpload">
                        <app-candidatedocument></app-candidatedocument>
                    </div> -->
                </div>


            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn  dark btn-outline pull-left" (click)="history()"
                    *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button> -->
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="Upsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Candidate History</h4>
            </div>
            <div class="modal-body" *ngIf="title == 'Edit'">
                <!-- <app-Chistory></app-Chistory> -->
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="filterModal" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
                <h4 class="modal-title">Advanced Search</h4>
            </div>
            <div class="modal-body">
                <app-candidateAdvancedSearch></app-candidateAdvancedSearch>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="clear()" class="btn dark btn-outline" title="Clear All">Clear</button>
                <!-- <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button> -->
                <button type="button" (click)="advanceFilterCan()" class="btn blue">Search</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="viewSummary">
    <div class="modal-dialog modal-lg" *ngIf="modelpop" role="document" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="closepoopup()"></button>
                <h4 class="modal-title" id="exampleModalScrollableTitle">View Summary</h4>
            </div>
            <div class="modal-body">
                <app-candidateviewsummary [Summaryid]="SummaryId"></app-candidateviewsummary>
            </div> 
            <div class="modal-footer">
                <button type="button" (click)="closepoopup()"  data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- <div class="modal fade" id="viewSummary" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">View Summary</h4>
            </div>
            <div class="modal-body">
                <app-candidateviewsummary></app-candidateviewsummary>
            </div>
            
            <div class="modal-footer">
                 <div class="pull-left form-inline" *ngIf="moveEmployee">
                    <select class="form-control" id="statusReview"
                        [disabled]="!(employeeStatusReadyToCreate != true) || viewSummaryStatus">
                        <option *ngFor="let item of candidateStatus;" value={{item.name}}>
                            {{item.name}}</option>
                    </select>&nbsp;
                    <button type="button" id="statusupdate" class="btn dark btn-outline"
                        [disabled]="!(employeeStatusReadyToCreate != true)  || viewSummaryStatus">Update
                        Status</button>
                </div>


                <button type="button" *ngIf="moveEmployee" class="btn dark btn-outline" (click)="moveToEmployee()"
                    [disabled]="!(employeeStatusReadyToCreate == true)  || viewSummaryStatus">Move
                    To Employee</button> 

                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div> -->

<div class="modal" id="SendMail" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" *ngIf="mailsend"></button>
                <h4 class="modal-title">Send Mail</h4>
            </div>
            <div class="modal-body">
                <app-sendmail></app-sendmail>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline" *ngIf="mailsend">Close</button>
                <button type="button" data-dismiss="modal" class="btn blue"
                    (click)="sendMail()">Send</button>
            </div>
        </div>
    </div>
</div>

