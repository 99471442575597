    <style>
        div#draggable{
            z-index:0;
        }
    </style>
    <div class="modal fade draggable-modal" id="draggable" role="basic" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="margin:10% auto !important">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" (click)="Back()" aria-hidden="true"></button>
                    <h4 class="modal-title">Change Password</h4>
                </div>
                <div class="modal-body">
                    <div class="form-horizontal ng-untouched ng-pristine ng-invalid">
                        <div class="form-group">

                            <label class="control-label col-md-3">Old Password<span class="required"> *
                                </span></label>
                            <div class="col-md-8">
                                <input type="password" class="form-control" id="oldpassword">
                            </div>

                        </div>
                        <div class="form-group">
                            <label class="control-label col-md-3">New Password<span class="required"> *
                                </span></label>
                            <div class="col-md-8">
                                <input type="password" class="form-control" id="newpassword" data-toggle="tooltip"
                                    data-placement="right" data-container="body" title=""
                                    data-original-title="Must be at least 8 characters long.">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-md-3">Confirm Password<span
                                    class="required">*</span></label>
                            <div class="col-md-8">
                                <input type="password" class="form-control" id="confirmPassword" data-toggle="tooltip"
                                    data-placement="right" data-container="body" title=""
                                    data-original-title="Confirm new Password. 'Confirm Password' should match the input in the 'New password' field.">
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="modal-footer">                    
                    <button type="button" (click)="Back()" class="btn default">Back to Login</button>&nbsp;
                    <button type="button" (click)="changePassword()" class="btn btn-outline blue">Change Password</button>
                </div>
            </div>
        </div>
    </div>