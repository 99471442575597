import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-designation-form',
  templateUrl: './designation-form.component.html'
})
export class DesignationFormComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  designationform: FormGroup;
  companylist: Array<Select2OptionData>;
  title: string;
  listcall: boolean = true;
  @Input() pId?: any;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshPage: EventEmitter<number> = new EventEmitter();

  departmentlist: Array<Select2OptionData>;
  designationlist: any;
  filterlist: any;
  zonelist: Array<Select2OptionData>;
  employeelist: Array<Select2OptionData>;
  p: number = 1;
  totalRecord: 0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private fb: FormBuilder,
    private toastr: ToastrService,) { }



  ngOnInit() {

    document.body.className = "modal-open";

    this.title = "Add";
    this.designationform = this.fb.group({
      desig_Id: ['0'],
      company_Id: ['', Validators.required],
      dept_Id: ['', Validators.required],
      zone_Id: [''],      
      desig_Name: ['', Validators.required],
      report_To: [null],
      additionalReport_To: [null],
      level: [''],
      isActive: ['1'],
      mandatory_RAF_Approval: [false],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });
    // $("select").select2({
    //   dropdownParent: $('#draggable'),
    //   width: "auto"
    // });
    //this.getZoneList();
    if (this.listcall) {
      this.getDesignationList(1);
      this.getCompanyList();
    }

    if(this.pId > 0){
      this.openModel(this.pId);
    }

  }




  getCompanyList() {

    this.companylist = [];
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'company_Name'
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }


  validation() {
    var designationForm = this.designationform;
    designationForm.controls.zone_Id.setValue(designationForm.controls.zone_Id.value == '' ? null : designationForm.controls.zone_Id.value);
    if (!designationForm.valid) {
      for (const key of Object.keys(designationForm.controls)) {
        if (designationForm.controls[key].invalid) {
          let fieldName = "";
          if (key == "company_Id") {
            fieldName = "Company"
            $("#company_Id select").focus();
          }
          else if (key == "dept_Id") {
            fieldName = "Department"
            $("#dept_Id select").focus();
          }
          // else if (key == "zone_Id") {
          //   fieldName = "Zone"
          //   $("#zone_Id select").focus();
          // }
          // else if (key == "desig_Code") {
          //   fieldName = "Job code"
          // }
          else if (key == "desig_Name") {
            fieldName = "Job title"
          }
          // else if (key == "report_To") {
          //   fieldName = "Report to"
          //   $("#report_To select").focus();
          // }
          // else if (key == "level") {
          //   fieldName = "Level"
          // }
          this.toastr.info(fieldName + " is required !", "Job Title");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      // if ($("#desig_Code").val().indexOf(" ") > -1) {
      //   this.toastr.info("Job code not allow space !", "Job Title");
      //   $("#desig_Code").focus();
      //   return false;
      // }
      // if ($("#desig_Code").val().length < 2) {
      //   this.toastr.info("Job code must be at least 2 characters long !", "Job Title");
      //   $("#desig_Code").focus();
      //   return false;
      // }
      // if ($("#desig_Code").val().search(/[^a-zA-Z0-9]+/) > -1) {
      //   this.toastr.info("Job code only alphabets and numeric allow !", "Job Title");
      //   $("#desig_Code").focus();
      //   return false;
      // }

      // if (/["\\]/.test($("#desig_Code").val())) {
      //   this.toastr.info("Job title code does not allow Special Characters \" \\ ", "Job Title");
      //   $("#desig_Code").focus();
      //   return false;
      // }

      if ($("#desig_Name").val().length < 2) {
        this.toastr.info("Job title must be at least 2 characters long !", "Job Title");
        $("#desig_Name").focus();
        return false;
      }
      if (/["\\]/.test($("#desig_Name").val())) {
        this.toastr.info("Job title does not allow Special Characters \" \\ ", "Job Title");
        $("#desig_Name").focus();
        return false;
      }
      // if ($("#desig_Name").val().search(/^[^-\s][a-zA-Z0-9-()\s.]+$/) != 0) {
      //   this.toastr.info("Job title only alphabets and numeric allow !", "Job Title");
      //   $("#desig_Name").focus();
      //   return false;
      // }
      // if ($("#level").val().length > 3) {
      //   this.toastr.info("Level not more then 3 characters long !", "Job Title");
      //   $("#level").focus();
      //   return false;
      // }



    }
    return true;
  }

  history() {
    this.historyView.getHistory("designation", this.designationform.value.desig_Id)
  }

  setFormData(id, event) {
    this.designationform.controls[id].setValue(event != "" ? event : null)
    if (id == "company_Id") {
      this.getDepartmentList()
    }
    if (id == "dept_Id") {
      this.getEmployeeList()
    }

  }

  openModel(dId) {

    if (dId > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Designation, dId).subscribe((response) => {
        if (response != null) {
          var editData = response["data"];
          var formData = this.designationform;
          this.commonService.setEditData(formData, editData);
          formData.controls.updatedBy.setValue(Commonvariable.User_Id);
          this.getDepartmentList();

          // $("#desig_Code").prop("disabled", "disabled");
        }
      })
    }
    else {
      // $("#desig_Code").removeAttr("disabled");
      this.listcall = false;
      this.departmentlist = [];
      this.employeelist = [];
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });

  }


  getDepartmentList() {

    this.departmentlist = [];

    if (this.title != "Edit") {
      this.designationform.controls.dept_Id.setValue('')
      this.designationform.controls.zone_Id.setValue('')
      this.designationform.controls.report_To.setValue(null)
      this.designationform.controls.additionalReport_To.setValue(null)
    }

    if (parseInt(this.designationform.controls.company_Id.value) > 0) {


      var requestData = {
        "searchBy": [
          {
            "FieldName": "isActive",
            "FieldValue": "1",
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "company_Id",
            "FieldValue": this.designationform.controls.company_Id.value,
            "Parameter": "==",
          }
        ],
        "OrderBy": 'dept_Name'
      }
      this.commonApiService.GetByPaginated(Commonvariable.Department, requestData).subscribe((result) => {
        if (result["data"] != "") {
          this.departmentlist = result["data"].map(x => ({
            id: x.dept_Id,
            text: x.dept_Name
          }))

        }
        this.getZoneList();
      }, (error) => { this.getZoneList(); })

    }



  }

  getZoneList() {

    this.zonelist = [];

    var requestData;
    if (parseInt(this.designationform.controls.company_Id.value) > 0) {
      requestData = {
        "searchBy": [
          {
            "FieldName": "isActive",
            "FieldValue": "1",
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "Company_Id",
            "FieldValue": this.designationform.controls.company_Id.value,
            "Parameter": "=="
          }
        ],
        "OrderBy": 'zone_Name'
      }

    }
    else {
      requestData = {
        "searchBy": [
          {
            "FieldName": "isActive",
            "FieldValue": "1",
            "Parameter": "==",
          }
        ],
        "OrderBy": 'zone_Name'
      }
    }
    this.commonApiService.GetByPaginated(Commonvariable.Zone, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.zonelist = response["data"].map(x => ({
          id: x.zone_Id,
          text: x.zone_Name
        }))

        if (parseInt(this.designationform.controls.company_Id.value) > 0 && parseInt(this.designationform.controls.dept_Id.value) > 0) { this.getEmployeeList(); }

      }
    }, (error) => { if (parseInt(this.designationform.controls.company_Id.value) > 0 && parseInt(this.designationform.controls.dept_Id.value) > 0) { this.getEmployeeList(); } })

  }



  getEmployeeList() {

    this.employeelist = [];


    if (parseInt(this.designationform.controls.company_Id.value) > 0 && parseInt(this.designationform.controls.dept_Id.value) > 0) {


      var requestData = {
        "searchBy": [
          {
            "FieldName": "isActive",
            "FieldValue": "1",
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "Company_Id",
            "FieldValue": this.designationform.controls.company_Id.value,
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "dept_Id",
            "FieldValue": this.designationform.controls.dept_Id.value,
            "Parameter": "=="
          }
        ],
        "OrderBy": 'Desig_Name'
      }

      this.commonApiService.GetByPaginated(Commonvariable.Designation, requestData).subscribe((result) => {
        if (result["data"] != "") {
          this.employeelist = result["data"].map(x => ({
            id: x.desig_Id,
            text: x.desig_Name
          }))
          //console.log(this.employeelist)
        }
      }, (error) => {
        this.employeelist = null;
      })

    }

    // this.commonApiService.empDetailList(Commonvariable.EmployeeDetail).subscribe((result) => {
    //   if (result != null) {
    //     this.employeelist = result["data"].filter(x => x.isActive == 1 && x.company_Id == this.designationform.controls.company_Id.value).sort((a, b) => a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0);
    //   }
    // })
  }
  getDesignationList(PageIndex) {

    this.totalRecord = 0;
    this.designationlist = [];
    this.filterlist = [];
    this.p = PageIndex;
    this.commonApiService.getListWithFilter(Commonvariable.Designation, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.designationlist = result["data"]["list"];
        this.filterlist = result["data"]["list"];
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })
  }


  designationUpsert() {
    var designationForm = this.designationform;
    if (this.validation()) {
      var dId = designationForm.controls.desig_Id.value;
      designationForm.get("report_To").setValue(parseInt(this.designationform.controls.report_To.value));
      designationForm.get("additionalReport_To").setValue(parseInt(this.designationform.controls.additionalReport_To.value));
      designationForm.get("zone_Id").setValue(parseInt(this.designationform.controls.zone_Id.value));
      //console.log(parseInt(this.designationform.controls.report_To.value));
      designationForm.get("isActive").setValue(designationForm.get("isActive").value ? 1 : 0);
      designationForm.get("mandatory_RAF_Approval").setValue(designationForm.get("mandatory_RAF_Approval").value ? 1 : 0);
      designationForm.get("desig_Name").setValue(designationForm.get("desig_Name").value);

      this.commonApiService.Upsert(Commonvariable.Designation, designationForm.value, dId).subscribe((response) => {
        if (response["message"] == Commonvariable.DuplicationMsg) {
          this.toastr.warning('Record is already exists !', 'Job Title');
        }
        else {
          // $("#draggable").modal('hide');
          this.closeModal();
          this.refreshPage.emit(1);
          //this.router.navigateByUrl('designation');          
          this.toastr.success(dId > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Job Title');
        }
      })
    }
  }

  closeModal() {
    this.modalClose.emit(false)
  }

  
}


