import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
import { retry } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;

@Component({
  
  selector: 'app-hr-policies-form',
  templateUrl: './hr-policies-form.component.html'
})
export class HrPoliciesFormComponent implements OnInit {
  hr_policiesform: FormGroup;
  docFiles: string[] = [];
  listcall: boolean = true;
  title: string;
  fileName: any = "";
  constructor(
    private toastr: ToastrService,
    private commonApiService: CommonApiService,
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private fb: FormBuilder
  ) {
    
   }

  @Input() pId?: any;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshPage: EventEmitter<number> = new EventEmitter();

  ngOnInit() {

    this.hr_policiesform = this.fb.group({
      Id: '0',
      files: '',
      Ref_Name:['',Validators.required],
      Doc_Description: ['', Validators.required],
      isActive: true,
      addedBy: Commonvariable.User_Id,
      updatedBy: Commonvariable.User_Id
      
   });
   $('.fileinput-filename').html('');
   $(".removedacoument").click();
  
    if (this.pId > 0) {
      this.openModel(this.pId);
    }
  }
  openModel(sId){
   
    if (sId > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Hr_Policies_Document, sId).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var sForm = this.hr_policiesform;
          this.commonService.setEditData(sForm, editData);
          sForm.controls.Id.setValue(editData[0].id);
          sForm.controls.Ref_Name.setValue(editData[0].ref_Name);
          sForm.controls.Doc_Description.setValue(editData[0].doc_Description);
          this.fileName = response['data'][0].file_Name
          this.docFiles.push(this.fileName);
          $('.fileinput-filename').html(this.fileName);
          $('.fileinput.fileinput-new').removeClass('fileinput-new').addClass('fileinput-exists');
          $('.input-group.input-large').attr('disabled', 'disabled');
          sForm.controls.updatedBy.setValue(Commonvariable.User_Id);

         // sForm.controls.updatedBy.setValue(Commonvariable.User_Id);
       
       
          
        }
      })
    }
    else {
      // $("#shift_Code").removeAttr("disabled");
      this.listcall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });

  

  }
  closeModal() {
    this.modalClose.emit(false)
  }
  Upsert() {
    

    var Data = this.hr_policiesform.value;
    console.log(Data);
    if (this.validation()) {
     

      const formData = new FormData();
      formData.append('Id', Data.Id);
      formData.append('Ref_Name',Data.Ref_Name);
      formData.append('Doc_Description', Data.Doc_Description);
      formData.append('isActive', Data.isActive);
      formData.append('addedBy', Data.addedBy);
      formData.append('files', Data.files);
      // this.docFiles.filter(f => f != null).map(function (x) {
       
      //    formData.append('files', x);
      // })

      this.commonApiService.Upsert(Commonvariable.Hr_Policies_Document,formData, this.hr_policiesform.controls.Id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Hr_Policies_Document');
          }
          else {
            // $("#draggable").modal('hide');
            this.closeModal();
            this.refreshPage.emit(1);
            this.toastr.success(this.hr_policiesform.controls.Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Hr_Policies_Document');
          }
        }
      })
    }
    
 }
 validation() {

 
  var Data = this.hr_policiesform.value;
  
  console.log(this.docFiles);
        // if ($("#Doc_Description" ).val().search(/[^a-zA-Z0-9]+/) > -1) {
        //    this.toastr.info("Document Description only alphabets and numeric allow !", "HR Policies");
        //    $("#Doc_Description" ).focus();
        //       return false;
        // }

        if (this.docFiles.length == 0) {
           this.toastr.info("File is required !", "HR Policies");
           return false;
        }
        Data.isActive = Data.isActive ? 1 : 0
    
  return true;
}
fileChange(event) {



    //  for (var i = 0; i < event.target.files.length; i++) {
    //     this.docFiles=[event.target.files[i]];
    //  }
    this.docFiles = this.docFiles || [];
     var Data = this.hr_policiesform.value;
     Data.files = event.target.files[0];
     //this.docFiles = Data.files
     this.docFiles.push(event.target.files[0].name);
 
}


removeDoc() {
  var Data = this.hr_policiesform.value;
  Data.files = "";
  this.docFiles = Data.files
}

 

}
