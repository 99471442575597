<form [formGroup]="candidateForm">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Reference Name<span class="required"> * </span></label>
                <input type="text" class="form-control" id="ref_Name" formControlName="ref_Name" autocomplete="off"
                    maxlength="50">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Reference Email Address</label>
                <input type="text" class="form-control" id="ref_Email" formControlName="ref_Email" autocomplete="off"
                    maxlength="50">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <div class="form-group">

                <label class="control-label">Reference Contact Number<span class="required"> * </span></label>
                <div class="input-group mobilenowithcountryode">
                    <div class="input-group-btn">
                        <ng-select2 [data]="countryList" id="country_Id" formControlName="country_Id"
                            [placeholder]="'--- Select ---'">
                        </ng-select2>
                    </div>
                    <input type="text" class="form-control" id="ref_ContactNo" formControlName="ref_ContactNo"
                        autocomplete="off" maxlength="20" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.key === "Backspace" ||  event.charCode == 40 
            || event.charCode == 32 || event.charCode == 41 || event.charCode == 43 || event.charCode == 45'>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group">
                <label class="control-label">Reference Relationship<span class="required"> * </span></label>
                <input type="text" class="form-control" id="ref_Relationship" formControlName="relationship" autocomplete="off"
                maxlength="100">
                <!-- <select id="ref_Relationship" class="form-control" formControlName="relationship_Id">
                    <option value="">Select Reference Relationship</option>
                    <option *ngFor="let item of emprefrelList;" value={{item.id}}>
                        {{item.name}} </option>
                </select> -->
                <!-- <input list="browsers" id="ref_Relationship" class="" 
                    autocomplete="off" formControlName="ref_Relationship">
                <datalist id="browsers">
                    <option *ngFor="let item of emprefrelList;" value={{item.name}}>
                    </option>
                </datalist> -->
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="candidateref" class="md-check" formControlName="isActive">
                            <label for="candidateref">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</form>