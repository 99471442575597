<!-- <link rel="stylesheet" type="text/css" href="assets/login_util.css"> 
<link rel="stylesheet" type="text/css" href="assets/login_main.css"> -->

<!-- <link rel="stylesheet" type="text/css" href="assets/vendor/bootstrap/css/bootstrap.min.css"> -->
<!-- <ngx-spinner size="medium" ></ngx-spinner> -->

<link href="//maxcdn.bootstrapcdn.com/bootstrap/3.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">



<div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100">


            <div class="login100-more" style="background-image: url('assets/login_images/images/bg-01.jpg');">
                <div class="leftsideText leftsideBox">



                    <div class="container register-form">
                        <div class="form">
                            <h1>SignUp</h1>




                            <div class="form-content">




                                <form [formGroup]="employeeform">

                                    <div class="row">
                                        <div class="col-md-6 wow animated slideInLeft">
                                            <div class="form-group">
                                                <label class="control-label">Company<span class="required"> *
                                                    </span></label>&nbsp;

                                                <ng-select2 [data]="companylist" id="company_Id"
                                                    formControlName="Company_Id" [placeholder]="'--- Select ---'">
                                                </ng-select2>

                                                <!-- <select id="Company_Id" class="form-control"
                                                    formControlName="Company_Id" style="height: 3.5rem!important;">                                                    
                                                    <option value="">Select Your Company</option>
                                                    <option *ngFor="let company of companylist;"
                                                        value={{company.company_Id}}>
                                                        {{company.company_Name}}</option>
                                                </select> -->
                                            </div>
                                        </div>
                                        <div class="col-md-6 wow animated slideInRight">
                                            <div class="form-group">
                                                <label class="control-label">Corporate Email / Code<span
                                                        class="required"> * </span></label>&nbsp;
                                                <input type="text" id="Emp_Code" class="form-control"
                                                    formControlName="Emp_Code" autocomplete="off" maxlength="200"
                                                    placeholder="Corporate Email / Code *">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 wow animated slideInLeft">
                                            <div class="form-group">
                                                <label class="control-label">First Name
                                                    <span class="required"> * </span>
                                                </label>&nbsp;
                                                <input type="text" id="FirstName" class="form-control"
                                                    formControlName="FirstName" maxlength="30" autocomplete="off"
                                                    placeholder="First Name">
                                            </div>
                                        </div>
                                        <div class="col-md-6 wow animated slideInRight">
                                            <div class="form-group">
                                                <label class="control-label">Last Name
                                                    <span class="required"> * </span>
                                                </label>&nbsp;
                                                <input type="text" id="LastName" class="form-control"
                                                    formControlName="LastName" maxlength="30" autocomplete="off"
                                                    placeholder="Last Name">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <a routerLink="/home" class="btnSubmit wow animated slideInRight"
                                                style="float: right;margin-left: 14px;text-align: center;color:#fff;background-color: gray;">Back
                                                to Login</a>
                                            &nbsp;&nbsp;
                                            <button type="button" class="btnSubmit wow animated slideInLeft"
                                                (click)="Upsert()" style="font-size: 15px;float: right;">Submit</button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</div>