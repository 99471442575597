import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { RoleAssignComponent } from 'src/app/pages/notification/roleassign/roleassign.component'
import { MailSendComponent } from 'src/app/pages/mailsend/mailsend.component'
declare var $: any;

@Component({
  selector: 'app-signup-list',
  templateUrl: './signup-list.component.html'
})
export class SignupListComponent implements OnInit {
  @ViewChild(RoleAssignComponent, { static: false }) RoleAssign: RoleAssignComponent;
  @ViewChild(MailSendComponent, { static: false }) MailSend: MailSendComponent;
  signuplist: any;

  p: number = 1;
  totalRecord: 0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService, ) {
  }
  ngOnInit() { 
    this.commonService.myData.subscribe((data) => {
      this.getSignupList();
    });



    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }

  getSignupList(PageIndex = 1) {
    this.p = PageIndex;
    this.totalRecord = 0;
    this.signuplist = [];


    this.commonApiService.getListWithFilter(Commonvariable.EmployeeDetail, PageIndex, $("#filterList").val(), "GetSignUpEmployee").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.signuplist = result["data"]["list"];
      }
    })
  }


  openModal(id) {
    this.RoleAssign.openModal(id)
    this.MailSend.documentName = "";
    $('#signipnotificationRole').modal({
      backdrop: 'static'
    });
  }



  sendMail() {
    if (this.MailSend.validation()) {
      const formData = new FormData();
      formData.append('To', $("#mailSend #to").val());
      formData.append('CC', $("#mailSend #cc").val());
      formData.append('BCC', $("#mailSend #bcc").val());
      formData.append('Subject', $("#mailSend #subject").val());
      formData.append('Role_Ids', this.MailSend.roleId != undefined ? this.MailSend.roleId : null);
      formData.append('Body', $('#mailSend #emailsend').summernote('code'));
      formData.append('Company_Id', this.RoleAssign.empNotificatonform.controls.company_Id.value);
      formData.append('User_Id', this.RoleAssign.user_Id != undefined ? this.RoleAssign.user_Id : '0');
      formData.append('userEmailBodyPassword', $("#mailSend #userEmailBodyPassword").val());
      if (this.MailSend.docFiles != undefined) {
        for (var i = 0; i < this.MailSend.docFiles.length; i++) {
          formData.append('file', this.MailSend.docFiles[i]);
        }
      }
      this.commonApiService.UploadLogo(Commonvariable.User, "SendMail", formData).subscribe((response) => {
        this.commonService.toaster("success", "Mail send successfully", "Signup")
        $('.modal').modal("hide");
      });
    }

  }

  closeModal() {
    $('.modal').modal("hide");
  }




}
