import { Component, OnInit, ViewChild, DebugEventListener } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { DomSanitizer } from '@angular/platform-browser';
import { DateFilter } from 'ag-grid-community';
declare var $: any;


@Component({
  selector: 'app-jobdescription',
  templateUrl: './jobdescription.component.html'
})
export class JobDescriptionComponent implements OnInit {
  jDescriptionform: FormGroup;

  companylist: any;
  sitelist: any;
  departmentlist: any;
  designationlist: any;
  callList: boolean = true;
  jdDocument: any;
  fileUrl;
  fileName;
  uploadDocument: boolean = true;
  jdDocumentChange: boolean = false;

  constructor(
    private commonApiService: CommonApiService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.jDescriptionform = this.fb.group({
      jD_Id: ['0'],
      company_Id: ['', Validators.required],
      site_Id: ['', Validators.required],
      dept_Id: ['', Validators.required],
      desig_Id: ['', Validators.required],
      jD_Code: [''],
      jD_Name: ['', Validators.required],
      jD_Description: [''],
      notes: [''],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id]
    });

    if (this.callList) {
      this.getCompanyList();
    }


  }

  getCompanyList() {

    this.companylist = [];

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'company_Name'
    }

    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {

      if (response != null && response["data"] != "") {
        this.companylist = response["data"];
      }
    })
  }



  getDepartmentList(val = null) {

    this.departmentlist = [];
    this.sitelist = [];

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "company_Id",
          "FieldValue": val == null ? $(".companyid").val() : val,
          "Parameter": "==",
        }
      ],
      "OrderBy": 'dept_Name'
    }



    this.commonApiService.GetByPaginated(Commonvariable.Department, requestData).subscribe((response) => {

      if (response != null && response["data"] != "") {
        this.departmentlist = response["data"];

        if($(".departmentSelectListClass option:selected").val()){
          this.jDescriptionform.controls.dept_Id.setValue($(".departmentSelectListClass option:selected").val());
          this.getJobTitle()
          if($("#Desig_Id option:selected").val()){
            this.jDescriptionform.controls.desig_Id.setValue($("#Desig_Id option:selected").val());
          }
        }
      }

      requestData.OrderBy = "site_Name"

      this.commonApiService.GetByPaginated(Commonvariable.Site, requestData).subscribe((response) => {
        if (response["data"] != "") {
          this.sitelist = response["data"];
          if($(".siteSelectListClass option:selected").val()){
            this.jDescriptionform.controls.site_Id.setValue($(".siteSelectListClass option:selected").val());
          }
          
           
        }
      })

    })
  }

  getJobTitle() {

    this.designationlist = [];

    if (parseInt(this.jDescriptionform.controls.company_Id.value) > 0 && parseInt(this.jDescriptionform.controls.dept_Id.value) > 0) {


      var requestData = {
        "searchBy": [
          {
            "FieldName": "isActive",
            "FieldValue": "1",
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "company_Id",
            "FieldValue": this.jDescriptionform.controls.company_Id.value,
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "dept_Id",
            "FieldValue": this.jDescriptionform.controls.dept_Id.value,
            "Parameter": "==",
          }
        ],
        "OrderBy": "desig_Name"
      }

      requestData.OrderBy = "desig_Name"
      this.commonApiService.GetByPaginated(Commonvariable.Designation, requestData).subscribe((response) => {
        if (response["data"] != "") {
          this.designationlist = response["data"]
        }
      })

    }

  }




  validation() {
    var JDForm = this.jDescriptionform;
    this.jDescriptionform.controls.company_Id.setValue($(".companyid").val());
    this.jDescriptionform.controls.dept_Id.setValue($(".departmentid").val());
    this.jDescriptionform.controls.desig_Id.setValue($(".designationid").val());


    if (!JDForm.valid) {
      for (const key of Object.keys(JDForm.controls)) {
        if (JDForm.controls[key].invalid) {
          let fieldName = "";
          if (key == "company_Id") {
            fieldName = "Company"
          }
          else if (key == "site_Id") {
            fieldName = "Site"
          }
          else if (key == "dept_Id") {
            fieldName = "Department"
          }
          else if (key == "desig_Id") {
            fieldName = "Job Title"
          }
          // else if (key == "jD_Code") {
          //   fieldName = "Job Description Code"
          // }
          else if (key == "jD_Name") {
            fieldName = "Job Description Name"
          }
          this.toastr.info(fieldName + " is required !", "Job Description");
          $("#" + key).focus();
          return false;
        }
      }
    }else{
      if ($("#jD_Name").val().length < 2) {
        this.toastr.info("Job description name must be at least 2 characters long !", "Job Description");
        $("#jD_Name").focus();
        return false;
      }
      if (/["\\]/.test($("#jD_Name").val())) {
        this.toastr.info("Job description does not allow Special Characters \" \\ ", "Job Description");
        $("#jD_Name").focus();
        return false;
      }
       if (/["\\]/.test($("#notes").val())) {
        this.toastr.info("Notes does not allow Special Characters \" \\ ", "Job Description");
        $("#notes").focus();
        return false;
      }
    }
    // else {
    //   if ($("#jD_Code").val().indexOf(" ") > -1) {
    //     this.toastr.info("Job Description code not allow space !", "Job Description");
    //     $("#jD_Code").focus();
    //     return false;
    //   }
    //   if ($("#jD_Code").val().search(/[^a-zA-Z0-9]+/) > -1) {
    //     this.toastr.info("Job Description code only alphabets and numeric allow !", "Job Description");
    //     $("#jD_Code").focus();
    //     return false;
    //   }

    // }

    return true;
  }

  parentComponent(value) {


    if (parseInt(value.JD_Id) > 0) {
      this.uploadDocument = false
      this.commonApiService.getById(Commonvariable.JobDescription, value.JD_Id).subscribe((response) => {
        if (response != null) {
          var editData = response["data"];
          var JDForm = this.jDescriptionform;
          this.commonService.setEditData(JDForm, editData);
          // this.jDescriptionform.controls.company_Id.setValue(editData[0].company_Id)

          //$(".companyid").val(editData[0].company_Id)
          this.getDepartmentList()
          this.getJobTitle()
          this.jdDocumentChange = false;
          // this.jDescriptionform.controls.dept_Id.setValue(editData[0].dept_Id)
          // this.jDescriptionform.controls.desig_Id.setValue(editData[0].desig_Id)
          // $(".departmentid").val(editData[0].dept_Id)
          // $(".designationid").val(editData[0].desig_Id)
          // $("#jD_Code").prop("disabled", "disabled");
        }
      })
    }
    else {
      this.callList = false;
      this.ngOnInit()
      if (parseInt(value.Company_Id) > 0) {
        this.jDescriptionform.controls.company_Id.setValue(value.Company_Id)
        //$(".companyid").val(value.Company_Id)
        this.getDepartmentList(value.Company_Id)
        this.getJobTitle()
      }
      this.uploadDocument = true
      
      $(".departmentid").val("")
      $(".designationid").val("")
      // $("#jD_Code").prop("disabled", false);
      $("[data-dismiss='fileinput']").click()
      this.jdDocumentChange = false;
    }
    // $("#company_Id,#dept_Id,#desig_Id").select2({
    //   dropdownParent: $('#draggable'),
    //   width: "auto"
    // });
  }



  filechange(files: FileList) {
    this.jdDocumentChange = true;
    this.jdDocument = files.item(0)
  }

  download(extension, id) {
    this.commonApiService.getimage(Commonvariable.JobDescription, id, "DownloadDocument").subscribe((resp: any) => {
      this.fileName = "JobDescription." + extension;
      const blobData = new Blob([resp], { type: 'application/octet-stream' });
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blobData));
      setTimeout(() => {
        $("#download")[0].click();
      }, 500);
    });
  }







}
