<!-- BEGIN FOOTER -->
<div class="page-footer">
    <div class="page-footer-inner"> 2020 &copy; HR Portal By
        <a target="_blank" href="https://luxshtech.com/">LuxshTech</a>
    </div>
    <a (click)="scrollUp()">
        <div class="scroll-to-top">
            <i class="icon-arrow-up"></i>
        </div>
    </a>
</div>
<footer class="page-footer" role="contentinfo">
    <div class="d-flex align-items-center flex-1 text-muted">
        <span class="hidden-md-down fw-700"><b>Version : </b>{{appVersion}}</span>
    </div>
</footer>
<!-- END FOOTER -->
<!-- BEGIN QUICK NAV -->
<!-- <nav class="quick-nav">
            <a class="quick-nav-trigger" href="#0">
                <span aria-hidden="true"></span>
            </a>
            <ul>
                <li>
                    <a href="javascript:void(0);"  class="active">
                        <span>Tasks</span>
                        <i class="fa fa-list-alt"></i>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" >
                        <span>Employees</span>
                        <i class="icon-users"></i>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);">
                        <span>Profile</span>
                        <i class="icon-user"></i>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);">
                        <span>Employee Holidays</span>
                        <i class="icon-graph"></i>
                    </a>
                </li>
            </ul>
            <span aria-hidden="true" class="quick-nav-bg"></span>
        </nav> -->
<div class="quick-nav-overlay"></div>
<!-- END QUICK NAV -->