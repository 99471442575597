<form [formGroup]="candidateForm">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Emergency Contact Name<span class="required"> * </span></label>
                <input type="text" class="form-control" id="contactName" formControlName="contactName"
                    autocomplete="off" maxlength="100">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">

                <label class="control-label">Emergency Contact Number<span class="required"> * </span></label>
                <div class="input-group mobilenowithcountryode">
                    <div class="input-group-btn">
                        <ng-select2 [data]="countryList" id="country_Id" formControlName="country_Id"
                            [placeholder]="'--- Select ---'">
                        </ng-select2>
                    </div>

                    <!-- minlength="10" maxlength="20" -->
                    <input type="text" class="form-control" id="contactNo" formControlName="contactNo"
                        autocomplete="off"  onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.key === "Backspace" ||  event.charCode == 40 
                || event.charCode == 32 || event.charCode == 41 || event.charCode == 43 || event.charCode == 45'>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Relationship<span class="required"> * </span></label>

                <ng-select2 [data]="emerrelList" id="relationship" formControlName="relationship_Id"
                    [placeholder]="'--- Select ---'">
                </ng-select2>
                
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label class="control-label">Default</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox">
                        <input type="checkbox" id="emergencydefault" class="md-check" formControlName="isDefault">
                        <label for="emergencydefault">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="emeActive" class="md-check" formControlName="isActive">
                            <label for="emeActive">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>