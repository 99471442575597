import { Injectable, DebugElement } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    menuLinks: any[] = []; 

    constructor(
        private router: Router,
        private loginservice: LoginService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let currentUser = JSON.parse(localStorage.getItem('currentHRUser'));

        this.menuLinks = localStorage.getItem("luxshHRmenuLinks") != null ? localStorage.getItem("luxshHRmenuLinks").split(',') : [];
 
        if (currentUser != null) {
            if (currentUser["data"].token_No != null) {
                if (this.menuLinks.length > 0) {
                    if (this.menuLinks.filter(x => x == route.routeConfig.path).length > 0) {
                        //console.log('you are authorized to move');
                        return true;
                    }
                    else {
                        //console.log('you are not authorized to move');
                        return false;
                    }
                }
                return true;
            }
            else {
                this.router.navigateByUrl('home');
            }
        }
        else {
            this.router.navigateByUrl('home');
        }

        // if (currentUser != null) {
        //     if (currentUser["data"].token_No != null) {
        //         return true;
        //     }
        //     else {
        //         this.router.navigateByUrl('home');
        //     }
        // }
        // else {
        //     this.router.navigateByUrl('home');
        // }




        // not logged in so redirect to login page with the return url
        // if (currentUser != null && currentUser["data"].token_No != null && !this.loginservice.isTokenExpired()) {

        return false;
    }
}