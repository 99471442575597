<form [formGroup]="empAddressform">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Address 1<span class="required"> * </span></label>
                <input type="text" class="form-control" id="Address1" formControlName="Address1" autocomplete="off"
                    maxlength="100" ngx-google-places-autocomplete [options]=
                    'options'  (onAddressChange)="AddressChangeEmp($event)">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Address 2<span class="required"> * </span></label>
                <input type="text" class="form-control" id="Address2" formControlName="Address2" autocomplete="off"
                    maxlength="100">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Address 3</label>
                <input type="text" class="form-control" id="Address3" formControlName="Address3" autocomplete="off"
                    maxlength="100">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Address 4</label>
                <input type="text" class="form-control" id="Address4" formControlName="Address4" autocomplete="off"
                    maxlength="100">
            </div>
        </div>
    </div>
    <div class="row">        
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">City<span class="required"> * </span></label>
                <input type="text" class="form-control" id="City" formControlName="City" autocomplete="off"
                    maxlength="100">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">County</label>
                <input type="text" class="form-control" id="State" formControlName="State" autocomplete="off"
                    maxlength="100">
            </div>
        </div>
    </div>
    <div class="row">
       
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Country<span class="required"> * </span></label>                
                <select class="form-control" id="country_Id" formControlName="country_Id"
                                autocomplete="off">
                    <option value="">Select Country</option>
                    <option *ngFor="let item of countrylist;" value={{item.id}}>
                        {{item.country_Name}}</option>
                    
                </select> 
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Post Code<span class="required"> * </span></label>
                <input type="text" class="form-control" id="PostCode" formControlName="PostCode" autocomplete="off" maxlength="8"
                    maxlength="100">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-1">
            <div class="form-group">
                <label class="control-label">Default</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox">
                        <input type="checkbox" id="Default" class="md-check" formControlName="isDefault">
                        <label for="Default">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="Active" class="md-check" formControlName="isActive">
                            <label for="Active">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>