<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Shift List
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->

        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a (click)="id=0;modalOpen =true" class="btn btn-transparent blue  btn-sm">Add
                            Shift</a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" placeholder="Type to filter ..." id="filterList"
                                (input)="getShiftList()">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblZone">
                            <thead class="flip-content">
                                <tr>
                                    <th class="text-center"> Company </th>
                                    <!-- <th class="text-center" style="width: 20%;">Shift Code</th> -->
                                    <th class="text-center"> Shift </th>
                                    <th class="text-center">Start Shift Time </th>
                                    <th class="text-center">End Shift Time</th>
                                    <th class="text-center">Shift Duration</th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody
                                *ngFor="let item of shiftlist| paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">
                                <tr>
                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(item.shift_Id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.shift_Id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                            data-placement="bottom" title="{{item.company_Name}}">
                                            {{item.company_Name}}</div>
                                    </td>
                                    <td>{{item.shift_Name}}</td>
                                    <td>{{item.shift_Start}}</td>
                                    <td>{{item.shift_End}}</td>
                                    <td>{{item.shift_Duration}}</td>
                                    <td class="text-center">
                                        <a *ngIf="item.isActive" data-toggle="modal"
                                            (click)="id=item.shift_Id;modalOpen=true"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Shift"></i></a>
                                        <a *ngIf="!item.isActive" (click)="shiftDelete(item.shift_Id)">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top" title="Delete Shift"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="shiftlist != null">
                        <pagination-controls (pageChange)="getShiftList($event)" autoHide="true"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->

<div *ngIf="modalOpen">
    <app-sift-form [pId]="id" (modalClose)="modalOpen = $event" (refreshPage)="getShiftList($event)"></app-sift-form>
</div>
