import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;


@Component({
  selector: 'app-zone',
  templateUrl: './zone.component.html'
})
export class ZoneComponent implements OnInit {


  zonelist: any;
  filterZone: any;
  listCall: boolean = true;
  p: number = 1;
  totalRecord: 0;

  modalOpen: boolean = false
  id: number = 0

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,) { }



  ngOnInit() {
    if (this.listCall) {
      this.getZoneList(1);
    }

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);


  }

  getZoneList(PageIndex) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.zonelist = [];
    this.commonApiService.getListWithFilter(Commonvariable.Zone, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["status"] == 200 && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.zonelist = result["data"]["list"];
        this.filterZone = result["data"]["list"];
      }
    })
  }

  zoneDelete(zoneId) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Zone, zoneId).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getZoneList(1);
          this.toastr.success(response["message"], 'Zone');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Zone, Id, status).subscribe((response) => {
          if (response["status"] == 200) {
            this.toastr.success(response["message"], 'Zone');
            this.getZoneList(1);
          }

        })
      }

    });
  }


}


