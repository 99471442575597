<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Employee Exit List
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a (click)="openModel(0)" class="btn btn-transparent blue  btn-sm">Add Leaver</a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" id="filterList"
                                placeholder="Type to filter the employee column..."
                                (input)="getEmployeeResignationList()">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblZone">
                            <thead class="flip-content">
                                <tr>
                                    <th style="width: 20%;" class="text-center">Employee </th>
                                    <!-- <th class="text-center">Employee Number</th> -->
                                    <th class="text-center">Reason For Leaving </th>
                                    <th class="text-center" style="width: 9%;">Notice Period</th>
                                    <th class="text-center" style="width: 9%;">Date of Notice</th>
                                    <th class="text-center" style="width: 9%;">Leave Date</th>
                                    <th class="text-center" style="width: 9%;">Last Working Date</th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of empResignationlist| paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">

                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(item.emp_Resignation_Id,'0')">
                                                <div class="circle" style="background-color:#FFBF00;" *ngIf="item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.emp_Resignation_Id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                            data-placement="bottom" title="{{item.firstName}} {{item.lastName}}">
                                            {{item.firstName}}
                                            {{item.lastName}}
                                        </div>
                                    </td>
                                    <!-- <td>{{item.emp_Id}}</td> -->
                                    <td>{{item.leaveReason_Name}}</td>
                                    <td>{{item.noticePeriod}}</td>
                                    <td class="text-center">{{item.resignationDate | date :'dd/MM/yyyy'}}</td>
                                    <td class="text-center">{{item.leaveDate | date :'dd/MM/yyyy'}}</td>
                                    <td class="text-center">{{item.lastWorkingDate | date :'dd/MM/yyyy'}}</td>
                                    <td>
                                        <a *ngIf="item.isActive" (click)="openModel(item.emp_Resignation_Id)"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Leaver"></i></a>
                                        <a *ngIf="!item.isActive" (click)="Delete(item.emp_Resignation_Id)">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Delete Leaver"></i></a>
                                                &nbsp;
                                        <a (click)="getSummary(item.emp_Id)">
                                            <i class="fa fa-list-alt" data-toggle="tooltip" data-placement="top"
                                            title="View Summary"></i></a> 
                                                            <!-- <a *ngIf="item.isActive" (click)="openModel(item.emp_Resignation_Id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                                data-placement="top" title="Edit Leaver"></i></a> -->
                                                        
                                              
                                    </td>
                                   
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="empResignationlist != null">
                        <pagination-controls (pageChange)="getEmployeeResignationList($event)" autoHide="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->


<div class="modal fade draggable-modal" id="draggable" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Leaver</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="empResignationform">
                    <div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">Employee<span class="required"> *
                                        </span></label>
                                    <ng-select2 [data]="empList" id="emp_Id" formControlName="emp_Id"
                                        [placeholder]="'--- Select ---'" [options]="{multiple: false,tags: false}"
                                        (valueChanged)="getReasionForLeaveList($event)">
                                    </ng-select2>
                                    <!-- <select id="emp_Id" class="form-control" formControlName="emp_Id"
                                        (change)="getReasionForLeaveList()">
                                        <option value="">Select Your Employee</option>
                                        <option *ngFor="let emp of empList;" value={{emp.emp_Id}}>
                                            {{emp.firstName}} {{emp.lastName}}</option>
                                    </select> -->

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">Reason For Leaving<span class="required"> *
                                        </span></label>

                                    <select class="form-control" id="leaveReason_Id" formControlName="leaveReason_Id"
                                        autocomplete="off">
                                        <option value="">Reason For Leaving</option>
                                        <option *ngFor="let item of reasonforleavelist;" value={{item.id}}>
                                            {{item.name}}</option>

                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="hidden" id="noticePeriod" formControlName="noticePeriod"
                                        value="{{noticePeriodInput.value}} {{ noticePeriodType.value}}">
                                    <label class="control-label" style="width: 100%;">Notice Period<span
                                            class="required"> *</span></label>
                                    <input type="text" id="noticePeriodInput" class="form-control"
                                    (change)="autoCompleteLeaveDateNotice()" formControlName="noticePeriodInput"
                                        style="width: 50%;float: left;" autocomplete="off" #noticePeriodInput
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                        maxlength="3">
                                    <select class="form-control" formControlName="noticePeriodType" id="noticePeriodType"
                                        style="width: 50%;float: right;background-color: #eef1f5;" #noticePeriodType>
                                        <option value="Days">Days</option>
                                        <option value="Weeks">Weeks</option>
                                        <option value="Months" selected>Months</option>
                                        <!-- <option value="Years">Years</option> -->
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">Date of Notice<span class="required"> *
                                        </span></label>
                                    <div class="input-group" id="flatpickropenResD" data-wrap="true">
                                        <input type="text" (change)="autoCompleteLeaveDate()" id="ResignationDate" class="form-control" data-input
                                            >
                                        <span class="input-group-btn" data-toggle>
                                            <button class="btn default" type="button">
                                                <i class="fa fa-calendar"></i>
                                            </button>
                                        </span>
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">Leave Date<span class="required"> *
                                        </span></label>
                                    <div class="input-group" id="flatpickropenLeaD" data-wrap="true">
                                        <input type="text" id="LeaveDate" class="form-control" data-input>
                                        <span class="input-group-btn" data-toggle>
                                            <button class="btn default" type="button">
                                                <i class="fa fa-calendar"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">Last Working Date<span class="required"> *
                                        </span></label>
                                    <div class="input-group" id="flatpickropenLastWD" data-wrap="true">
                                        <input type="text" id="LastWorkingDate" class="form-control" data-input>
                                        <span class="input-group-btn" data-toggle>
                                            <button class="btn default" type="button">
                                                <i class="fa fa-calendar"></i>
                                            </button>
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-8">
                                <div class="form-group">
                                    <label class="control-label">Notes</label>
                                    <input type="text" id="notes" class="form-control" formControlName="notes">
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <label class="control-label">Active</label>
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="checkbox1_3" class="md-check"
                                                formControlName="isActive">
                                            <label for="checkbox1_3">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>





                        <div class="row">
                            <div class="col-md-12">
                                <form [formGroup]="empResignationform">
                                    <div class="table-responsive">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Select Document</th>
                                                    <th>Select File</th>
                                                    <!-- <th>View Doc</th> -->
                                                    <th style="width: 5%;"><button type="button"
                                                            class="btn pull-right green addMorefields2"
                                                            (click)="addItem(null)"><i class="fa fa-plus"
                                                                aria-hidden="true"></i></button></th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="emp_document"
                                                *ngFor="let item of empResignationform.get('emp_document')['controls']; let i = index;">

                                                <tr [formGroupName]="i">
                                                    <td>
                                                        <select class="form-control" id="Doc_Id{{i}}"
                                                            formControlName="Doc_Id">
                                                            <option value=''>Select Document Type</option>
                                                            <option *ngFor="let item of documentList;"
                                                                value={{item.id}}>
                                                                {{item.name}}</option>
                                                        </select>
                                                    </td>

                                                    <td>
                                                        <div class="fileinput fileinput-new" id="EmployeeDocument{{i}}"
                                                            data-provides="fileinput">
                                                            <div class="input-group input-large">
                                                                <div class="form-control uneditable-input input-fixed input-medium"
                                                                    data-trigger="fileinput">
                                                                    <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                                                    <span class="fileinput-filename"
                                                                        *ngIf="(empResignationform.get('emp_document').value[i].Emp_Doc_Id) == 0">
                                                                    </span>
                                                                    <span class="fileinput-filename"
                                                                        *ngIf="(empResignationform.get('emp_document').value[i].Emp_Doc_Id)>0">
                                                                        {{empResignationform.get('emp_document').value[i].file_Name}}
                                                                    </span>
                                                                </div>
                                                                <span class="input-group-addon btn default btn-file">
                                                                    <span class="fileinput-new"> Select file </span>
                                                                    <span class="fileinput-exists"> Change </span>
                                                                    <input type="file" id="Emp_Doc_Path{{i}}"
                                                                        (change)="fileChangeMultiple($event,i)">
                                                                </span>
                                                                <a href="javascript:;" id="removeEmpResignation{{i}}"
                                                                    (click)="removeDoc(i)"
                                                                    
                                                                    class="input-group-addon btn red fileinput-exists"
                                                                    data-dismiss="fileinput">
                                                                    Remove </a>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <!-- <td>
                                                        <a *ngIf="(empResignationform.get('emp_document').value[i].Emp_Doc_Id)>0"
                                                            class="btn"><i
                                                                class="fa fa-download purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Download Document"></i></a>
                                                    </td> -->
                                                    <td>
                                                        <button type="button" (click)="removeItem(i)"
                                                            class="btn pull-right red"><i class="fa fa-minus"
                                                                aria-hidden="true"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="Upsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="viewSummary">
    <div class="modal-dialog modal-lg" *ngIf="modelpop" role="document" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="closepoopup()"></button>
                <h4 class="modal-title" id="exampleModalScrollableTitle">View Summary</h4>
            </div>
            <div class="modal-body">
                <app-employeeviewsummary [Summaryid]="SummaryId"></app-employeeviewsummary>
            </div> 
            <div class="modal-footer">
                <button type="button" (click)="closepoopup()"  data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal fade" id="viewSummary" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">View Summary</h4>
            </div>
            <div class="modal-body">
                <app-employeeviewsummary [Summaryid]="SummaryId"></app-employeeviewsummary>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="closeModal()" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div> -->