import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import Swal from 'sweetalert2';
import { templateJitUrl } from '@angular/compiler';
import { ConcatSource } from 'webpack-sources';
import { flatMap } from 'rxjs/operators';
declare var $: any;


@Component({
  selector: 'app-userRole',
  templateUrl: './user_role.component.html'
})
export class UserRoleComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  roleList: any;
  module: any[];
  baseModuleList: any[];
  p: 1;




  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private fb: FormBuilder) { }
  ngOnInit() {
    this.getRoles()
  }

  getRoles() {
    this.commonApiService.List(Commonvariable.Roles).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.roleList = result["data"].filter(x => x.isActive != 0);
        this.getRoles_Permission();
      }
    })
  }

  getRoles_Permission() {
    this.baseModuleList = [];
    this.module = []
    if ($("#roleid").val() != "") {
      this.commonApiService.getById("Role_Permission", $("#roleid").val(), "GetByRoleId").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.baseModuleList = result["data"];
          this.moduleList(result["data"]["permission"]);
        }
      })
    }
  }


  moduleList(list) {
    var array = [];
    this.module = [];
    var index = 0;

    list.forEach(m => {
      var data = {
        "id": index,
        "moduleName": m.module_Name,
        "add": m.add > 0 ? true : false,
        "edit": m.edit > 0 ? true : false,
        "delete": m.delete > 0 ? true : false,
        "view": m.view > 0 ? true : false,
        "selectall": m.add > 0 && m.edit > 0 && m.delete > 0 && m.view > 0 ? true : false
      }
      array.push(data);
      index++;
    });
    this.module = array
    if (this.module.filter(x => x.selectall == true).length == this.module.length) {
      $("#checkall").prop("checked", true)
    }
    else {
      $("#checkall").prop("checked", false)
    }
  }

  checkAll(index, checkbox) {
    if (checkbox == 'selectall') {
      this.module[index].add = $("#selectall" + index).prop("checked")
      this.module[index].edit = $("#selectall" + index).prop("checked")
      this.module[index].delete = $("#selectall" + index).prop("checked")
      this.module[index].view = $("#selectall" + index).prop("checked")

      this.baseModuleList["permission"][index]["add"] = $("#selectall" + index).prop("checked") ? 1 : 0
      this.baseModuleList["permission"][index]["edit"] = $("#selectall" + index).prop("checked") ? 1 : 0
      this.baseModuleList["permission"][index]["delete"] = $("#selectall" + index).prop("checked") ? 1 : 0
      this.baseModuleList["permission"][index]["view"] = $("#selectall" + index).prop("checked") ? 1 : 0

      this.module[index].selectall = $("#selectall" + index).prop("checked")
    }
    else if (checkbox == 'checkall') {
      for (const key of Object.keys(this.module)) {
        this.module[key].add = $("#checkall").prop("checked")
        this.module[key].edit = $("#checkall").prop("checked")
        this.module[key].delete = $("#checkall").prop("checked")
        this.module[key].view = $("#checkall").prop("checked")
        this.module[key].selectall = $("#checkall").prop("checked")
      }

      for (const key of Object.keys(this.baseModuleList["permission"])) {
        this.baseModuleList["permission"][key]["add"] = $("#checkall").prop("checked") ? 1 : 0
        this.baseModuleList["permission"][key]["edit"] = $("#checkall").prop("checked") ? 1 : 0
        this.baseModuleList["permission"][key]["delete"] = $("#checkall").prop("checked") ? 1 : 0
        this.baseModuleList["permission"][key]["view"] = $("#checkall").prop("checked") ? 1 : 0
      }
    }
    else {
      this.module[index][checkbox] = $("#" + checkbox + index).prop("checked")
      this.baseModuleList["permission"][index][checkbox] = $("#" + checkbox + index).prop("checked") ? 1 : 0
      if ($("#add" + index).prop("checked") && $("#edit" + index).prop("checked") && $("#delete" + index).prop("checked") && $("#view" + index).prop("checked")) {
        this.module[index].selectall = true
        $("#selectall" + index).prop("checked", true)
      }
      else {
        this.module[index].selectall = false
        $("#selectall" + index).prop("checked", false)
      }
    }

    if (this.module.filter(x => x.selectall == true).length == this.module.length) {
      $("#checkall").prop("checked", true)
    }
    else {
      $("#checkall").prop("checked", false)
    }
  }

  addPermission() {
    
    if (this.baseModuleList.length != 0) {
      if (this.baseModuleList["permission"].length > 0) {
        this.baseModuleList["addedBy"] = Commonvariable.User_Id
        this.commonApiService.PostRequest("Role_Permission", "AddRole_Permission", this.baseModuleList).subscribe((result) => {
          this.getRoles_Permission();
          this.commonService.toaster("success","Record saved successfully !","User Role");
        })
      }
    }
  }



}
