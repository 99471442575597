<!-- <form [formGroup]="empform">
    <div class="row">
        <div class="col-md-5">
            <div class="form-group">
                <label class="control-label">Salary<span class="required"> * </span></label>
                <input type="number" min="0" value="0" step="0.01" class="form-control" id="salary" formControlName="salary" autocomplete="off"
                    maxlength="20"
                    [readonly]="editMode">
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group">
                <label class="control-label">Salary Type<span class="required"> * </span></label>
                <select id="salaryType" formControlName="salaryType" class="form-control">
                    <option value="">Select Salary Type</option>
                    <option value="Annually">Annually</option>
                    <option value="Hourly">Hourly</option>

                </select>

            </div>
        </div>
        <div class="col-md-1">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="empsalary" class="md-check" formControlName="isActive">
                            <label for="empsalary">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
</form> -->

<form [formGroup]="empform" class="pt-15">
    <div class="row">
        <input type="hidden" value="" id="Company_Id" name="Company_Id">
        <input type="hidden" value="" id="Dept_Id" name="Dept_Id">
        <input type="hidden" value="" id="Desig_Id" name="Desig_Id">
    
        <!-- <div class="col-sm-12">
            <div class="row mt-15">
                <button  type="button" *ngIf="this.empform.get('emp_salary').value[0].Salary != ''" (click)="addItem(null)" class="btn pull-right">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
            </div>
        </div> -->
        <div class="col-md-12"  >
            <!-- <div class="row" [formGroupName]="i"> -->
        <!-- <div class="col-md-12" formArrayName="emp_salary" >
            <div class="row" > -->
                
    
                <div class="col-md-4 mb-15 mt-15">
                    <div class="form-group">
                        <label class="control-label">Salary<span class="required"> * </span></label>
                        <input type="number" min="0" value="0" step="0.01" class="form-control" id="salary" formControlName="Salary" autocomplete="off"
                            maxlength="20"
                            >
                    </div>
                </div>
    
                <div class="col-md-4 mb-15 mt-15">
                    <label>Salary Type </label>
                    <select id="SalaryType" class="form-control" formControlName="SalaryType">
                        <option value="">Select Salary Type</option>
                        <option value="Annually">Annually</option>
                        <option value="Hourly">Hourly</option>
                        
                    </select>
                </div>
    
                <div class="col-md-4 mb-15 mt-15">
                    <label>Active</label>
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="empsal" class="md-check" formControlName="isActive">
                            <label for="empsal">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="row">
                        <button  type="button" (click)="addItem(null)" class="btn pull-right">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div formArrayName="emp_salary_additional">
                   
                        <div *ngFor="let additional of empform.get('emp_salary_additional')['controls']; let j = index">
                        <div [formGroupName]="j">
                        
                            <!-- <pre>{{ additional.value | json }}</pre> -->
    
                <div class="col-md-4 mb-15" >
                    <label>Additional Payment</label>
                    <select class="form-control" (change)="toggleCommentsTextarea($event, j)" formControlName="AdditionalPayment" id="AdditionalPayment{{j}}" >
                        <option value="">Select Payment</option>
                        <option value="Night Shift Premium">Night Shift Premium</option>
                        <option value="Car Allowance">Car Allowance</option>
                        <option value="Travel Allowance">Travel Allowance</option>
                        <option value="Other">Other</option> 
                    </select>
                </div>
    
                <div class="col-md-4 mb-15"  >
                    <label>Additional Payment Type<span class="required" *ngIf="isrequiredclass[j]"> * </span> </label>
                    <select class="form-control" formControlName="AdditionalPaymentType" id="AdditionalPaymentType{{j}}">
                        <option value="">Select Payment Type</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Hourly">Hourly</option> 
                    </select>
                </div>
    
                <div class="col-md-4 mb-15" >
                    <label>Additional Payment Amount<span class="required" *ngIf="isrequiredclass[j]"> * </span> </label>
                    <input type="text" class="form-control" formControlName="AdditionalPaymentAmount" id="AdditionalPaymentAmount{{j}}">
                </div>
    
                <div class="col-md-4 mb-15" id="comments{{j}}" *ngIf="isDivHidden[j]" >
                    <label>Comments<span class="required" *ngIf="isrequiredComclass[j]"> * </span></label>
                    <textarea cols="55" class="form-control" rows="3" id="Notes{{j}}" formControlName="Notes"></textarea>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <button *ngIf="j>0" type="button" (click)="removeItem(j)" class="btn pull-right"><i
                            class="fa fa-minus" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
    
                <!-- <div class="col-md-12">
                    <div class="row">
                        <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                            class="fa fa-minus" aria-hidden="true"></i></button>
                    </div>
                </div> -->
            </div>
        </div>
    
    
            
        </div>
    
    </div>
   
</form> 

  

