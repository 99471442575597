import { Component, OnInit } from '@angular/core';

import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { debug } from 'util';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  companylist: any;
  employeeform: FormGroup;
  passwordvisible: boolean = true;


  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit() {

    this.employeeform = this.fb.group({
      user_Id: ['0'],
      Company_Id: ['', Validators.required],
      Emp_Code: ['', Validators.required],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
    });
    this.passwordvisible = true;

    this.getCompanyList();

  }

  get formControls(): any {
    return this.employeeform['controls'];
  }


  Upsert() {
    var employeeForm = this.employeeform;

    if (this.validation()) {

      this.commonApiService.UpsertSignup(Commonvariable.User, employeeForm.value, 0).subscribe((response) => {
        if (response["status"] == 200) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'SignUp');
          }
          else if (response["message"] == "Employee Not Available.") {
            this.toastr.warning(response["message"], 'SignUp');
          }
          else if (response["message"] == "Person Not Available.") {
            this.toastr.info(response["message"], 'SignUp');
          }
          else {
            this.toastr.success(Commonvariable.EmpAddMsg, 'SignUp');
            employeeForm.reset();
            //this.router.navigateByUrl('/home');
          }
        }
      })
    }
  }


  validation() {
    var employeeForm = this.employeeform;

    this.employeeform.controls.Emp_Code.setValue(String(this.employeeform.controls.Emp_Code.value).trim())
    this.employeeform.controls.FirstName.setValue(String(this.employeeform.controls.FirstName.value).trim())
    this.employeeform.controls.LastName.setValue(String(this.employeeform.controls.LastName.value).trim())
    

    if (!employeeForm.valid) {
      for (const key of Object.keys(employeeForm.controls)) {
        if (employeeForm.controls[key].invalid) {
          let fieldName = "";

          if (key == "Company_Id") {
            fieldName = "Company"
          }
          else if (key == "Emp_Code") {
            fieldName = "Email / Code"
          }

          else if (key == "FirstName") {
            fieldName = "First name"
          }
          else if (key == "LastName") {
            fieldName = "Last name"
          }

          this.toastr.info(fieldName + " is required !", "SignUp");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
    }

    return true;
  }





  getCompanyList() {
    this.commonApiService.getCompanyInfo(Commonvariable.Company).subscribe((result) => {
      if (result != null && result["status"] == 200 && result["data"] != "") {
        //this.companylist = result["data"].filter(x => x.isActive != 0);
        this.companylist = result["data"].filter(x => x.isActive != 0).map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
        //this.getEmpList();
      }
    })
  }


}
