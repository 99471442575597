import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import fieldVisibleInvisibleData from 'src/app/_files/field_visible_invisible.json';
declare var $: any;

@Component({
  selector: 'app-raf-overview',
  templateUrl: './raf-overview.component.html'
})
export class RafOverviewComponent implements OnInit {
  myApprovallist: any[];
  approvallist: any;
  approvaledlist: any;
  filterList: any;
  totalItems1: 0;
  totalItems2: 0;
  totalItems3: 0;
  filterItems: 0;
  p: number = 1;
  p2: number = 1;
  p3: number = 1;
  itemsPerPage: number = 10;
  searchPage: number = 0;
  items: FormArray;
  approvalSummary: any;
  publisher: any;
  vacancyCandidateList: any;
  historyvacancy: any[];
  publisherForm: FormGroup;
  module: any;
  salaryType: string = "";
  salaryTitle: string = "";
  requirements: any;
  statuslist: any;
  fieldVisibleInvisible: any;
  statusColorCodeList: any[];
  BGColor: string;
  FontColor: string;
  flagActions: number = 0
  companylist: any[] = [];
  currency_Code: any;
  openPopup:boolean=false;
  fileUrl : any;
  constructor(private commonApiService: CommonApiService, private toastr: ToastrService, private sanitizer: DomSanitizer,private commonService: CommonService, private router: Router, private fb: FormBuilder, private ngZone: NgZone) { }

  ngOnInit() {

    //this.getStatusColorList();
    

    this.fieldVisibleInvisible = fieldVisibleInvisibleData[0].RAF.Create_RAF;
    this.myAprrovalSearchOneValue("onLoad", 0);
    this.aprrovalSearchOneValue("onLoad", 0);
    this.aprrovaledSearchOneValue("onLoad", 0);
    this.publisherForm = this.fb.group({
      checkArray: this.fb.array([]),
    })
    this.getCompanyList();

  }





  getCompanyList() {
    
    this.companylist = [];
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'company_Name'
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response["data"] != null && response["status"] == 200 && response["data"] != "") {
        this.companylist = response["data"];
      }
    })
  }
  getCurrencyCode(companyId) {
    let currency_Code = this.companylist.filter(x => x.company_Id == companyId)
    if (currency_Code.length > 0) {
      this.currency_Code = currency_Code[0]["currency_Code"];
    }
  }

  addPublisher(data, id) {
    return this.fb.group({
      Vacancy_Id: id,
      Publisher_Id: data != null ? data.id : null,
      checkboxchecked: data != null ? true : false,
      Notes: data != null ? data.notes : null,
      PublishedBy: Commonvariable.User_Id,
      publishSource: data != null ? data.publishSource : null,
      isActive: '1',
      AddedBy: Commonvariable.User_Id
    })
  }

  getSummary(id, flagAction = false) {
   
    this.approvalSummary = [];
    this.commonApiService.getById(Commonvariable.VacancyService, id, "ViewSummary").subscribe((response) => {
      if (response["data"][0] != null && response["data"][0] != "") {
        this.approvalSummary = response["data"][0];
        this.approvalSummary["skills"] = String(this.approvalSummary["skills"]).replace(/[ ~]+/g, ",");

        if (response["data"][0].salaryType == "Annually") {
          this.salaryType = 'Annually';
          this.salaryTitle = 'Per Annum'
        } else {
          this.salaryType = 'Hourly';
          this.salaryTitle = 'Per Hour'
        }
        this.requirements = String(response["data"][0].requirements).split(",");
        // $("#skills_tags").val(response["data"][0].skills != null ? response["data"][0].skills.replace(/[ ~]+/g, ",") : null);
        // $("#skills_tags").tagsinput('add', this.approvalSummary["skills"] != null ? this.approvalSummary["skills"] : null);

        setTimeout(() => {
          $('#skills_tags').tagsinput('add',this.approvalSummary["skills"]); 
        
          var workingDays = String(this.approvalSummary.contract_Days).split(',')
          workingDays.forEach(x => {
            console.log(x);
            $("[value=" + x + "]").prop("checked", true)
          });
          $(".wd").prop("disabled", "disabled")
  
          if (flagAction) {
            this.flagActions = 1  
  
          } else {
            this.flagActions = 0
          }
        
        }, 50);




        // var workingDays = String(this.approvalSummary.contract_Days).split(',')
        // workingDays.forEach(x => {
        //   console.log(x);
        //   $("[value=" + x + "]").prop("checked", true)
        // });
        // $(".wd").prop("disabled", "disabled")

        // if (flagAction) {
        //   this.flagActions = 1  

        // } else {
        //   this.flagActions = 0
        // }
        console.log(this.approvalSummary);

        this.getCurrencyCode(this.approvalSummary.company_Id);
        this.openPopup=true;
        $('#viewSummary').modal({
          backdrop: 'static'
        });

      }
    })
  }

  getPostWebsite(id) {
    this.commonApiService.List(Commonvariable.Publisher).subscribe((response) => {
      if (response["data"] != null && response["data"] != "") {
        this.publisher = response["data"];
        this.items = this.publisherForm.get('checkArray') as FormArray;
        this.items.clear();
        response["data"].forEach(data => {
          this.items.push(this.addPublisher(data, id));
        });
        $('#postOnWebsite').modal({
          backdrop: 'static'
        });
      }
    })
  }

  checkBoxAll(index, all = null) {
    if (all == null) {
      if ($(".candidateCheck").length == $(".candidateCheck:checked").length) {
        $("#checkall").prop("checked", true)
      }
      else {
        $("#checkall").prop("checked", false)
      }
    }
    else {
      if ($("#checkall").prop("checked")) {
        $(".candidateCheck").prop("checked", true)
      }
      else {
        $(".candidateCheck").prop("checked", false)
      }
    }

  }

  linkVacancyToCandidate() {
    var array = [];
    $('.candidateCheck:checked').each(function () {
      array.push(this.value);
    });
    var str1 = array.toString(); // Gives you "42,55"
    var str2 = String(array); // Ditto
    var data = {
      "Candidate_Ids": str2,
      "Vacancy_Id": $("#vacancyvalue").val() != '' ? parseInt($("#vacancyvalue").val()) : 0
    }
    this.commonApiService.vacancyLinkToCandidates("CandidateDetail", "VacancyLinkToCandidate", data).subscribe((response) => {
      this.toastr.success(response["message"], 'Candidate');
      $("#CandidatetoRaf").modal('hide');
    });
  }

  selectCandidate(vid, cid, sid, did) {

    this.vacancyCandidateList = [];
    var data = {
      "searchBy": [
        {
          "FieldName": "company_Id",
          "FieldValue": parseInt(cid),
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": 'site_Id',
          "FieldValue": parseInt(sid),
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": 'dept_Id',
          "FieldValue": parseInt(did),
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": 'vacancy_Id',
          "FieldValue": "null",
          "Parameter": "=="
        }
      ]
    }
    $("#vacancyvalue").val(vid);
    this.commonApiService.getPaginated('OnboardingDetail', null, null, null, null, null, data).subscribe((result) => {
      if (result["data"] != null && result["data"] != "" && result["status"] == 200) {
        this.vacancyCandidateList = result["data"]["list"];
      }
    }, (error) => {
      this.toastr.warning(error["error"].message, 'Candidate List');
    })

    $('#CandidatetoRaf').modal({
      backdrop: 'static'
    })
  }


  upsert() {
    const checkArray = this.publisherForm.get('checkArray') as FormArray;

    for (let index = 0; index < checkArray.value.length; index++) {
      //console.log(checkArray.value[index]);
      if (checkArray.value[index].checkboxchecked === true) {
        this.commonApiService.Upsert(Commonvariable.Vacancy_Publish, checkArray.value[index], 0).subscribe((result) => {
          if (result != null) {

            this.items = this.publisherForm.get('checkArray') as FormArray;
            this.items.removeAt(index);

            this.commonService.toaster("success", "Post Successfully", 'RAF Overview')
          }
        })
      }
      $("#postOnWebsite").modal('hide');
    }
  }

  // Waiting for my approval list Start

  myApprovalServerPagination(event) {
    this.myAprrovalSearchOneValue("onLoad", event - 1);
    this.p = event;
    this.searchPage = event;
  }
  myAprrovalSearchOneValue(action, pageIndex) {

    var pageSize = 10;

    this.totalItems1 = 0;
    this.myApprovallist = [];

    var postRequest = {
      "PageIndex": pageIndex != null ? pageIndex : 0,
      "PageSize": pageSize != null ? pageSize : 10,
      "CommonSearch": String(JSON.parse(localStorage.getItem('currentHRUser'))["data"].emp_Id.map(x => x.emp_Id))
    }

    if (String(JSON.parse(localStorage.getItem('currentHRUser'))["data"].emp_Id) != "null") {

      this.ngZone.run(() => {

        this.commonApiService.PostRequest(Commonvariable.VacancyService, "GetApprovallist", postRequest).subscribe((result) => {
          this.totalItems1 = 0;
          this.myApprovallist = [];
          if (result["data"] != "") {

            if (result["data"] != "" && result["data"]["list"] != 'undefined') {
              this.totalItems1 = result["data"]["recordCount"];
              this.myApprovallist = result["data"]["list"];
              this.itemsPerPage = pageSize;
              if (result["data"]["list"].length > 0) {
                this.toastr.info("RAF available for your approval", 'RAF Overview');
              }
            } else {
              this.totalItems1 = 0;
              this.myApprovallist = [];
            }

          }
          else {
            this.totalItems1 = 0;
            this.myApprovallist = [];
          }
        })
      })
    }
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }


  // Waiting for my approval list End


  // Waiting for approval Start

  approvalServerPagination(event) {
   
    this.aprrovalSearchOneValue("onLoad", event - 1);
    this.p2 = event;
  }
  aprrovalSearchOneValue(action, pageIndex) {
    
    var apiCall = true;

    this.totalItems2 = 0;
    this.approvallist = [];
    if (action == "filter" && ($("input[name='filter']:checked").val() == undefined || $("#filterValue").val() == "")) {
      apiCall = false;
    }
    else if (($("input[name='filter']:checked").val() != undefined && $("#filterValue").val() != "")) {
      action = "filter";
    }

    var pageSize = 10;

    if ($("#approvallist_status").val() == 'All') {
      var data = {
        "searchBy": [
          {
            "FieldName": 'status',
            "FieldValue": '\"\"',
            "Parameter": "!=",
            "ConditionWith": "&&"
          },
          {
            "FieldName": 'isActive',
            "FieldValue": 1,
            "Parameter": "=="
          }
        ],
        "PageIndex": pageIndex != null ? pageIndex : 0,
        "PageSize": pageSize != null ? pageSize : 10,
        "RecordCount": 100,
        "RecordLimit": 100,
        "CommonSearch": $("#filterList").val().trim()
      }
    } else {
      var data = {
        "searchBy": [
          {
            "FieldName": 'status',
            "FieldValue": '\"' + $("#approvallist_status").val() + '\"',
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": 'isActive',
            "FieldValue": 1,
            "Parameter": "=="
          }
        ],
        "PageIndex": pageIndex != null ? pageIndex : 0,
        "PageSize": pageSize != null ? pageSize : 10,
        "RecordCount": 100,
        "RecordLimit": 100,
        "CommonSearch": $("#filterList").val().trim()
      }
    }
    this.totalItems2 = 0;
    this.approvallist = [];
    if ($("#approvallist_status").val() == 'Planned') {
      if (apiCall) {
        this.ngZone.run(() => {
          this.commonApiService.getPaginated(Commonvariable.VacancyService, null, null, null, null, null, data).subscribe((result) => {
            this.totalItems2 = 0;
            this.approvallist = [];
            if (result["data"] != null && result["status"] == 200) {

              if (result["data"] != "" && result["data"]["list"] != 'undefined') {
                this.totalItems2 = result["data"]["recordCount"];
                this.approvallist = result["data"]["list"];
                this.approvallist.forEach(element => {
                  element.nextApprover_Emp_Name = element.nextApprover_Emp_Name.replace('()', '')
                });

                this.itemsPerPage = pageSize;
                this.p2 = pageIndex + 1;
              } else {
                this.totalItems2 = 0;
                this.approvallist = [];
              }
            }
          }, (error) => {
            this.toastr.warning(error["error"].message, 'RAF Overview');
          })
        })
      }

    } else {
      if (apiCall) {

        this.ngZone.run(() => {
          this.commonApiService.getPaginated(Commonvariable.VacancyService, null, null, null, null, null, data).subscribe((result) => {
            this.totalItems2 = 0;
            this.approvallist = [];
            if (result["data"] != null && result["status"] == 200) {

              if (result["data"] != "" && result["data"]["list"] != 'undefined') {
                this.totalItems2 = result["data"]["recordCount"];
                this.approvallist = result["data"]["list"];
                
                this.approvallist.forEach(element => {
                  element.nextApprover_Emp_Name = element.nextApprover_Emp_Name.replace('()', '')
                });
                this.itemsPerPage = pageSize;
                this.p2 = pageIndex + 1;

              } else {
                this.totalItems2 = 0;
                this.approvallist = [];
              }

            }
          }, (error) => {
            this.toastr.warning(error["error"].message, 'RAF Overview');
          })

        });
      }
    }


    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);



  }

  getStatusColorList() {
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ]
    }
    this.commonApiService.getPaginated(Commonvariable.Color_Code, null, null, null, null, null, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.statusColorCodeList = response["data"]["list"][0];
      } else {
        this.statusColorCodeList = [];
      }
    })
  }


  // Waiting for approval End


  // Approvaled RAFs Start

  approvaledServerPagination(event) {
   
    this.aprrovaledSearchOneValue("onLoad", event - 1);
    this.p3 = event;
  }
  aprrovaledSearchOneValue(action, pageIndex) {
    var apiCall = true;
    this.totalItems3 = 0;
    this.approvaledlist = [];
    if (action == "filter" && ($("input[name='filter']:checked").val() == undefined || $("#filterValue").val() == "")) {
      apiCall = false;
    }
    else if (($("input[name='filter']:checked").val() != undefined && $("#filterValue").val() != "")) {
      action = "filter";
    }

    var pageSize = 10;
    var data = {
      "searchBy": [
        {
          "FieldName": 'status',
          "FieldValue": '\"Approved\"',
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": 'approvalStatus',
          "FieldValue": '\"Approved\"',
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": 'isActive',
          "FieldValue": 1,
          "Parameter": "=="
        }
      ],
      "PageIndex": pageIndex != null ? pageIndex : 0,
      "PageSize": pageSize != null ? pageSize : 10,
      "RecordCount": 100,
      "RecordLimit": 100,
      "CommonSearch": $("#filterList_approved").val().trim()

    }

    this.totalItems3 = 0;
    this.approvaledlist = [];
    if (apiCall) {
      this.approvaledlist = [];

      this.ngZone.run(() => {

        this.commonApiService.getPaginated(Commonvariable.VacancyService, null, null, null, null, null, data).subscribe((result) => {
          this.totalItems3 = 0;
          this.approvaledlist = [];
          if (result["data"] != null && result["status"] == 200) {

            if (result["data"] != "" && result["data"]["list"] != 'undefined') {

              this.totalItems3 = result["data"]["recordCount"];
              this.approvaledlist = result["data"]["list"];
              this.itemsPerPage = pageSize;
            } else {
              this.totalItems3 = 0;
              this.approvaledlist = [];
            }
          }
        }, (error) => {
          this.toastr.warning(error["error"].message, 'RAF Overview');
        })

      })
    }

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);
  }

  myApprovalReject(Id) {

    this.commonService.takeReasonConfirm('Give Reason For Reject', 'Yes, Reject it !')
      .then((result) => {
        if (result.isConfirmed) {
          if (result.value != "") {

            var requestData = {
              "id": Id,
              "status": 'Rejected',
              "notes": result.value === true ? null : result.value,
            }
            this.commonApiService.putWithParameter(Commonvariable.Vacancy, requestData, 'ChangeStatus').subscribe((response) => {
              if (response["status"] == 200) {
                this.vacancyHistory(Id, "Rejected", "Rejected")
                this.myAprrovalSearchOneValue("onLoad", 0);
                this.aprrovalSearchOneValue("onLoad", 0);
                this.aprrovaledSearchOneValue("onLoad", 0);
                this.toastr.success(response["message"], 'RAF Overview');
                $("#viewSummary").modal('hide');
              }
            })
          }
          else {
            this.commonService.toaster("info", "Enter reason for reject !", "RAF Overview")
            this.myApprovalReject(Id)
          }

        }
      })
  }

  myApprovalCancel(Id) {

    this.commonService.takeReasonConfirm('Give Reason For Cancel', 'Yes, Cancel it !')
      .then((result) => {
        if (result.isConfirmed) {
          if (result.value != "") {

            var requestData = {
              "id": Id,
              "status": 'Cancelled',
              "notes": result.value === true ? null : result.value,
            }
            this.commonApiService.putWithParameter(Commonvariable.Vacancy, requestData, 'ChangeStatus').subscribe((response) => {
              if (response["status"] == 200) {
                this.vacancyHistory(Id, "Cancelled", "Cancelled")
                this.myAprrovalSearchOneValue("onLoad", 0);
                this.aprrovalSearchOneValue("onLoad", 0);
                this.aprrovaledSearchOneValue("onLoad", 0);
                this.toastr.success(response["message"], 'RAF Overview');
                $("#viewSummary").modal('hide');
              }
            })
          }
          else {
            this.commonService.toaster("info", "Enter reason for cancel !", "RAF Overview")
            this.myApprovalReject(Id)
          }

        }
      })
  }

  myApprovalApprove(Id, vrdid, nextApprover) {
    this.commonService.dyanmicConfirm('', 'Yes, Approve it!').then((result) => {
      if (result.value) {
        var data = {
          "Vacancy_Id": Id,
          "Vacancy_Rule_Detail_Id": vrdid,
          "ActionBy": nextApprover,
          "ActionOn": this.commonService.todayDateYYMMDDFormate(),
          "isActive": 1,
          "AddedBy": Commonvariable.User_Id,
          "AddedOn": this.commonService.todayDateYYMMDDFormate()
        }
        this.commonApiService.Upsert(Commonvariable.Vacancy_Approval, data, 0).subscribe((response) => {
          if (response["status"] == 200) {
            this.vacancyHistory(Id, "Awaiting Approval", "Approved")
            this.myAprrovalSearchOneValue("onLoad", 0);
            this.aprrovalSearchOneValue("onLoad", 0);
            this.aprrovaledSearchOneValue("onLoad", 0);
            this.toastr.success(response["message"], 'RAF Overview');
            $("#viewSummary").modal('hide');
          }
        })
      }
    })
  }

  approvalReject(Id) {
    this.commonService.dyanmicConfirm('', 'Yes, reject it!').then((result) => {
      if (result.value) {

        var requestData = {
          "id": Id,
          "status": 'Rejected',
          "notes": result.value === true ? null : result.value,
        }
        this.commonApiService.putWithParameter(Commonvariable.Vacancy, requestData, 'ChangeStatus').subscribe((response) => {
          if (response["status"] == 200) {
            this.vacancyHistory(Id, "Rejected", "Rejected")
            this.aprrovalSearchOneValue("onLoad", 0);
            this.toastr.success(response["message"], 'RAF Overview');
          }
        })
      }
    })
  }


  approvalHide(Id, status = null) {
    var buttontext = status != 'Hold' ? 'Yes, Unhold it!' : 'Yes, Hold it!';
    this.commonService.dyanmicConfirm('', buttontext).then((result) => {
      if (result.value) {

        var requestData = {
          "id": Id,
          "status": status,
          "notes": result.value === true ? null : result.value,
        }
        this.commonApiService.putWithParameter(Commonvariable.Vacancy, requestData, 'ChangeStatus').subscribe((response) => {
          if (response["status"] == 200) {

            if (status != 'Hold') {
              this.vacancyHistory(Id, status, 'Unhold')
            } else {
              this.vacancyHistory(Id, status, status)
            }

            this.toastr.success(response["message"], 'RAF Overview');
            this.myAprrovalSearchOneValue("onLoad", 0);
            this.aprrovalSearchOneValue("onLoad", 0);
            this.aprrovaledSearchOneValue("onLoad", 0);
          }
        })
      }
    })
  }

  approvedCompleted(Id, status = null) {
    var buttontext = status != 'Filled' ? 'Yes Complete!' : 'Yes';
    this.commonService.dyanmicConfirm('', buttontext).then((result) => {
      if (result.value) {
        var requestData = {
          "id": Id,
          "status": status,
          "notes": result.value === true ? null : result.value,
        }
        this.commonApiService.putWithParameter(Commonvariable.Vacancy, requestData, 'ChangeStatus').subscribe((response) => {
          if (response["status"] == 200) {
            this.vacancyHistory(Id, status, status)
            this.toastr.success(response["message"], 'RAF Overview');
            this.myAprrovalSearchOneValue("onLoad", 0);
            this.aprrovalSearchOneValue("onLoad", 0);
            this.aprrovaledSearchOneValue("onLoad", 0);
          }
        })
      }
    })
  }

  approvalSendReminder(Id) {
    this.commonApiService.sendReminder(Commonvariable.VacancyService, Id).subscribe((response) => {
      if (response["status"] == 200) {
        this.aprrovalSearchOneValue("onLoad", 0);
        this.toastr.success(response["message"], 'RAF Overview');
      }
    })

  }


  rafOverviewDelete(Id) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.VacancyService, Id).subscribe((response) => {
          this.vacancyHistory(Id, "Deleted", "Deleted")
          this.ngOnInit()
          this.toastr.success(response["message"], 'RAF Overview');
          this.p = 1;
          this.p2 = 1;
          this.p3 = 1;

        })
      }
    })
  }

  rafApprovedDelete(Id) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.VacancyService, Id).subscribe((response) => {
          //this.vacancyHistory(Id, "Deleted", "Deleted")
          this.aprrovalSearchOneValue("onLoad", 0);
          this.toastr.success(response["message"], 'RAF Overview');
          this.p = 1;
          this.p2 = 1;
          this.p3 = 1;
        })
      }
    })
  }


  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.VacancyService, Id, status).subscribe((response) => {
          if (response["status"] == 200) {
            this.toastr.success(response["message"], 'RAF Overview');
            this.ngOnInit();
          }

        })
      }

    });
  }

  expandTable(id) {
    //this.candidateWiseRAF = [];
    this.getVacancyHistory(id);
    var visibility = $(".sub_tbl_" + id).toggle().is(":visible");
    $(".sub_tbl").hide();
    $(".details-control").css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    if (visibility === true) {
      $(".sub_tbl_" + id).show();
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/d4ICC.png) no-repeat center center");
      //this.getCanidateWiseRAF(id);
    } else {
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    }

  }


  reAssign(Id) {
    this.commonService.takeReasonConfirm('Give Reason For Re Assign', 'Yes, Re Assign it !')
      .then((result) => {
        if (result.isConfirmed) {
          if (result.value != "") {

            var requestData = {
              "id": Id,
              "status": 'Awaiting Approval',
              "notes": result.value === true ? null : result.value,
            }

            this.commonApiService.putWithParameter(Commonvariable.Vacancy, requestData, 'ChangeStatus').subscribe((response) => {
              if (response["status"] == 200) {
                this.vacancyHistory(Id, "Re Assign", "Re Assign")
                this.toastr.success(response["message"], 'RAF Overview');
                this.myAprrovalSearchOneValue("onLoad", 0);
                this.aprrovalSearchOneValue("onLoad", 0);
                this.aprrovaledSearchOneValue("onLoad", 0);
              }
            })
          }
          else {
            this.commonService.toaster("info", "Enter reason for  Re Assign !", "RAF Overview")
            this.reAssign(Id)
          }
        }
      })
  }


  getVacancyHistory(id) {
    if (id > 0) {
      this.historyvacancy = [];
      this.commonApiService.getById(Commonvariable.Vacancy_History, id, "GetByVacancyId").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.historyvacancy = result["data"];
        }
      })
    }

  }

  vacancyHistory(vacancyId = 0, status, action) {
    var postRequest = {
      "Vacancy_Id": vacancyId,
      "Status": status,
      "Action": action,
      "AddedBy": Commonvariable.User_Id,
    }

    this.commonApiService.Upsert(Commonvariable.Vacancy_History, postRequest, 0).subscribe((result) => {
    })

  }
  download() {
    

    if (parseInt(this.approvalSummary.jD_Id) > 0) {
      //var fileName = String(this.jobTitleList.filter(x => x.jD_Id == this.empJobDetailform.controls.JD_Id.value)[0]["path"]).split(".")[1]
      //this.JDescription.download(fileName == undefined ? ".pdf" : fileName, parseInt(this.vacancyForm.controls.JD_Id.value));
      this.commonApiService.getimage(Commonvariable.JobDescription, parseInt(this.approvalSummary.jD_Id), "DownloadDocument").subscribe((resp: any) => {

        if (resp.type != "application/json") {
          const blobData = new Blob([resp], { type: resp.type });
          var createObjectURL = (window.URL.createObjectURL(blobData));
  
          if (resp.type == "application/pdf" || resp.type == "image/jpeg" || resp.type == "image/png") {
            this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
            $("#modalJobDescription").modal("show")
          } else if (resp.type == "application/vnd.ms-word") {
            const a = document.createElement('a');
            a.setAttribute('type', 'hidden');
            a.href = URL.createObjectURL(blobData);
            a.download = 'Job_Description.docx';
            a.click();
            a.remove();
          } else {
  
            const a = document.createElement('a');
            a.setAttribute('type', 'hidden');
            a.href = URL.createObjectURL(blobData);
            a.download = 'Job_Description';
            a.click();
            a.remove();
  
          }
        }else{
          this.commonService.toaster("warning", 'Document Not Available', 'Job Description')
        }
        
      });
    }
    else{
      this.commonService.toaster("warning", 'Not selected.', 'Job Description')
    }
  }
  
  modalJobDescriptionclose(){
    $("#modalJobDescription").modal("hide");
  }


  closepoopup(){
    this.openPopup=false;
    $("#viewSummary").modal("hide");
    // this.approvalSummary = [];
    // this.viewSummary.
  }

  // Approvaled RAFs End

}
