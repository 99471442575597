import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';

declare var $: any;

@Component({
  selector: 'app-roleassign',
  templateUrl: './roleassign.component.html'
})
export class RoleAssignComponent implements OnInit {

  empNotificatonform: FormGroup;
  roleList: any;
  userType: any;
  Role_Id: any;
  user_Id: any;


  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {

    this.empNotificatonform = this.fb.group({
      emp_Id: ['0'],
      person_Id: ['0'],
      company_Id: ['0'],
      Password: [null],
      user_Type: ["", Validators.required],
      login_Id: [null, Validators.required],
      emp_Code: [null],
      email: [null],
      firstName: [null],
      lastName: [null],
      isActive: 1
    });


    this.commonService.myData.subscribe((data) => {

    });

  }


  getRoleList() {
    this.roleList = []
    this.commonApiService.List(Commonvariable.Roles).subscribe((result) => {
      this.roleList = result["data"].sort((a, b) => a.name.localeCompare(b.name));
    })
  }


  openModal(id) {
    this.getRoleList();
    this.empNotificatonform.reset();
    if (id > 0) {
      var requestData = {
        "searchBy": [
          {
            "FieldName": "emp_Id",
            "FieldValue": id,
            "Parameter": "=="
          }
        ]
      }
      this.commonApiService.GetByPaginated(Commonvariable.EmployeeDetail, requestData, 'Get').subscribe((response) => {
        if (response != null) {

          var editData = response["data"];
          this.empNotificatonform.controls.emp_Id.setValue(editData[0]['emp_Id']);
          this.empNotificatonform.controls.person_Id.setValue(editData[0]['person_Id']);
          this.empNotificatonform.controls.emp_Code.setValue(editData[0]['emp_Code']);
          this.empNotificatonform.controls.email.setValue(editData[0]['work_Email']);
          this.empNotificatonform.controls.firstName.setValue(editData[0]['firstName']);
          this.empNotificatonform.controls.lastName.setValue(editData[0]['lastName']);
          this.empNotificatonform.controls.company_Id.setValue(editData[0]['company_Id']);
          this.empNotificatonform.controls.Password.setValue('1234!@#$abcd');
          this.empNotificatonform.controls.user_Type.setValue('');
          this.empNotificatonform.controls.isActive.setValue(1);


        }
      }, (error) => { this.toastr.error(error["error"].message, 'Role Assign'); })
    }
  }


  
  openMailBox(id) {
    
    this.commonApiService.getById(Commonvariable.User, id, "GetSendMailData").subscribe((response) => {
      if (response["data"] != "") {

        //if (this.router.url == "/signup-notifications") {
          $('#headermailSend').modal("show");
          setTimeout(() => {
            $("#headermailSend #to").val(response["data"]["to"])
            $("#headermailSend #cc").val(response["data"]["cc"])
            $("#headermailSend #bcc").val(response["data"]["bcc"])
            $("#headermailSend #subject").val(response["data"]["subject"])
            $('#headermailSend #emailsend').summernote('code', response["data"]["body"]);
          }, 500);
        // }
        // else {
        //   $('#HeadermailSend').modal("show");
        //   setTimeout(() => {
        //     $("#HeadermailSend #to").val(response["data"]["to"])
        //     $("#HeadermailSend #cc").val(response["data"]["cc"])
        //     $("#HeadermailSend #bcc").val(response["data"]["bcc"])
        //     $("#HeadermailSend #subject").val(response["data"]["subject"])
        //     $('#HeadermailSend #emailsend').summernote('code', response["data"]["body"]);
        //   }, 500);
        // }


      }
    })

  }


  upsert() {

    if (this.validation()) {

      var userForm = this.empNotificatonform;

      userForm.controls.user_Type.setValue(this.userType)

      this.commonApiService.Upsert(Commonvariable.User, userForm.value, 0).subscribe((response) => {
        if (response["status"] == 200) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'User');
          }
          else {
            if (this.empNotificatonform.controls.user_Type.value != "") {

              this.user_Id = response["data"]['user_Id'];
              var requestData = {
                "User_Id": response["data"]['user_Id'],
                "Role_Id": this.Role_Id,
                "isActive": 1,
                "AddedBy": Commonvariable.User_Id,
              }
              //this.commonApiService.Upsert(Commonvariable.User_Role, requestData, 0).subscribe((result) => {
                this.commonService.myData.next(!this.commonService.myData)
                $('.modal').modal("hide");
                this.openMailBox(this.user_Id);

              //}, (error) => { this.toastr.error(error["error"].message, 'User role assign'); })
            }
            else {
              this.commonService.myData.next(!this.commonService.myData)
              this.toastr.success(response["data"]['user_Id'] > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'User role assign');
            }
          }
        }
      })

    }

  }

  reject() {
    this.commonApiService.Put(Commonvariable.PersonService, this.empNotificatonform.controls.person_Id.value, "RejectSignUp").subscribe((result) => {
      $('.modal').modal("hide")
      this.commonService.myData.next(!this.commonService.myData)
    })

  }

  validation() {
    var employeeForm = this.empNotificatonform;
    for (const key of Object.keys(employeeForm.controls)) {
      if (employeeForm.controls[key].invalid) {
        let fieldName = "";

        if (key == "login_Id") {
          fieldName = "User name"
        }
        else if (key == "user_Type") {
          fieldName = "User type"
        }

        this.toastr.info(fieldName + " is required !", "Role Assign");
        $("#" + key).focus();
        return false;
      }
    }
    this.Role_Id = employeeForm.controls.user_Type.value;
    this.userType = this.roleList.filter(x => x.id == employeeForm.controls.user_Type.value)[0]["name"]

    if (employeeForm.controls.login_Id.value.length < 2) {
      this.toastr.info("Login Id must be at least 2 characters long !", "Role Assign");
      $("#login_Id").focus();
      return false
    }
    return true;
  }








}
