import { Component } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { withModule } from '@angular/core/testing';
import packagejson from '../../package.json';





declare var $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'UKHRModule';
  public appVersion: string = packagejson.version;
  //displayFlag:"router.url != '/' && router.url != '/signup' && router.url != '/home'"  

  constructor(public router: Router) {
    this.router.events.subscribe((event: Event) => {
    });
  }


}
