<form [formGroup]="candidateForm">
    <div class="row">
        <div class="col-md-5">
            <div class="form-group">
                <label class="control-label">Salary<span class="required"> * </span></label>
                <input type="number" min="0" value="0" step="0.01" class="form-control" id="salary"
                    formControlName="salary" autocomplete="off" maxlength="20" [readonly]="editMode">
            </div>
        </div>

        <div class="col-md-5">
            <div class="form-group">
                <label class="control-label">Salary Type<span class="required"> * </span></label>
                <select id="salaryType" formControlName="salaryType" class="form-control">
                    <option value="">Select Salary Type</option>
                    <option value="Annually">Annually</option>
                    <option value="Hourly">Hourly</option>
                </select>

            </div>
        </div>

        <div class="col-md-1">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="empsalary" class="md-check" formControlName="isActive">
                            <label for="empsalary">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Apprisal From<span class="required"> * </span></label>
                <div class="input-group">
                    <input type="text" class="form-control" id="apprisalFrom">
                    <span class="input-group-btn">
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div> -->
    </div>
    <div class="row">
        <!-- <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Apprisal To<span class="required"> * </span></label>
                <div class="input-group">
                    <input type="text" class="form-control" id="apprisalTo">
                    <span class="input-group-btn">
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-md-1">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="empsalary" class="md-check" formControlName="isActive">
                            <label for="empsalary">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</form>