import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;

@Component({
  selector: 'app-zone-form',
  templateUrl: './zone-form.component.html'
})
export class ZoneFormComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  zoneform: FormGroup;
  companylist: Array<Select2OptionData>;
  title: string;
  @Input() pId?: any;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshPage: EventEmitter<number> = new EventEmitter();


  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private fb: FormBuilder) { }



  ngOnInit() {

    document.body.className = "modal-open";

    this.title = "Add";
    this.zoneform = this.fb.group({
      zone_Id: ['0'],
      company_Id: [''],
      company_Name:[''],
      zone_Code: [''],
      zone_Name: ['', Validators.required],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });


    this.getCompanyList();

    if (this.pId > 0) {
      this.editRecord(this.pId);
    }


  }




  getCompanyList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "company_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }



  zoneUpsert() {
    var zoneForm = this.zoneform;
    if (this.validation()) {
      zoneForm.get("isActive").setValue(zoneForm.get("isActive").value ? 1 : 0);
      var zoneName = zoneForm.get("zone_Name").value;
      zoneForm.get("zone_Name").setValue(zoneName.trim());
      this.commonApiService.UpsertWithMultiCompany(Commonvariable.Zone, zoneForm.value, zoneForm.controls.zone_Id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.commonService.toaster('warning', 'Record is already exists !');
          }
          else {
            this.closeModal();
            this.refreshPage.emit(1);
            this.commonService.toaster('success', zoneForm.controls.zone_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg);
          }
        }
      })
    }
  }


  editRecord(zoneId) {

    if (zoneId > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Zone, zoneId).subscribe((response) => {
        if (response != null) {
          var editData = response["data"];
          var formData = this.zoneform;
          this.commonService.setEditData(formData, editData);
          formData.controls.updatedBy.setValue(Commonvariable.User_Id);
        
        }
      })
    }
  }


  validation() {
    var zoneForm = this.zoneform;
    if (!zoneForm.valid) {

      for (const key of Object.keys(zoneForm.controls)) {
        if (zoneForm.controls[key].invalid) {
          let fieldName = "";
          // if (key == "company_Id") {
          //   fieldName = "Company"
          // }
          // if (key == "zone_Code") {
          //   fieldName = "Zone code"
          // }
           if (key == "zone_Name") {
            fieldName = "Zone name"
          }
          this.commonService.toaster('info', fieldName + " is required !");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if (/["\\]/.test($("#zone_Name").val())) {
        this.commonService.toaster('info', "Zone name does not allow Special Characters \" \\ ");
        $("#zone_Name").focus();
        return false;
      }
      // if ($("#zone_Name").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
      //   this.toastr.info("Zone name only alphabets and numeric allow !", "Zone");
      //   $("#zone_Name").focus();
      //   return false;
      // }
      // if ($("#zone_Code").val().indexOf(" ") > -1) {
      //   this.toastr.info("Zone code not allow space !", "Zone");
      //   $("#zone_Code").focus();
      //   return false;
      // }
      // if ($("#zone_Code").val().length < 2) {
      //   this.toastr.info("Zone code must be at least 2 characters long !", "Zone");
      //   $("#zone_Code").focus();
      //   return false;
      // }
      // if ($("#zone_Code").val().search(/[^a-zA-Z0-9]+/) > -1) {
      //   this.toastr.info("Zone code only alphabets and numeric allow !", "Zone");
      //   $("#zone_Code").focus();
      //   return false;
      // }
      if ($("#zone_Name").val().length < 2) {
        this.commonService.toaster('info', "Zone name must be at least 2 characters long !");
        $("#zone_Name").focus();
        return false;
      }
      // if ($("#zone_Name").val().search(/^[^-\s][a-zA-Z0-9\s.]+[^-\s]+$/) != 0 && $("#zone_Name").val().search(/^[^-\s][a-zA-Z0-9\s.]+[^-\s]+$/) > -1 ) {
      //   this.toastr.info("Zone name only alphabets and numeric allow !", "Zone");
      //   $("#zone_Name").focus();
      //   return false;
      // }

    }
    return true;
  }

  closeModal() {
    this.modalClose.emit(false)
  }

  history() {
    this.historyView.getHistory("zone", this.zoneform.value.zone_Id)
  }

}


