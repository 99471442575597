import { Component, OnInit, HostListener } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
//import { Observable } from 'rxjs';
declare var $: any;

@Component({
   selector: 'app-emppersonalinfo',
   templateUrl: './employee-personal-info.component.html',
})
export class EmployeePersonalInfoComponent implements OnInit {



   // @HostListener allows us to also guard against browser refresh, close, etc.
   //   @HostListener('window:beforeunload', ['$event'])
   //   canDeactivate(){
   //     // insert logic to check if there are pending changes here;
   //     // returning true will navigate without confirmation
   //     // returning false will show a confirm dialog before navigating away
   //   }



   logoClass = "fileinput fileinput-new";
   logoInnerHTML = "";
   empLogo: any;
   empLogoChange: boolean = false;


   empBasicInfoform: FormGroup;
   ethinicityList: any;
   nationalityList: any;
   languagesList: any;
   MaritalList: any;
   nationality_Id_List: any;
   AgeList: any;
   constructor(private commonApiService: CommonApiService,
      private toastr: ToastrService,
      private commonService: CommonService,
      private fb: FormBuilder
   ) {

   }


   ngOnInit() {
      this.empBasicInfoform = this.fb.group({
         Id: ['0'],
         FirstName: ['', Validators.required],
         MiddleName: null,
         LastName: ['', Validators.required],
         Title: ['', Validators.required],
         Ethnicity_Id: [null],
         //Gender_Id: [''],
         MaritalStatus_Id: [''],
         //Nationality_Id: ['', Validators.required],
         lstNationality_Id: [''],
         //Languages_Id: [[]],
         DOB: ['', Validators.required],
         Disability: [''],
         Sexual_Orientation: [''],
         Email: null,
         PhoneNumber: null,
         Telephone: null,
         isActive: ['1'],
         isReview: JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "User" ? 0 : 1,
         AddedBy: [Commonvariable.User_Id],
         person_Address: this.fb.array,
         person_Contact: this.fb.array,
         person_Emergency: this.fb.array,
         person_Reference: this.fb.array
      });

      this.commonService.flatpickr("flatpickropen", 0);

      this.commonService.flatpickr("flatpickropen", 0);
      this.commonService.select2SingleDropDown("ethnicitySelectListClass");
      this.commonService.select2SingleDropDown("nationalitySelectListClass");
      //$("select").select2();

      //this.getEthinicityList();
      this.getNationalityList();
      //this.getMaritalStatusList();
      //this.getlanguagesList();

   }


   getNationalityList() {

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "name"
      }

      this.commonApiService.GetByPaginated(Commonvariable.Nationality, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            //this.nationalityList = response["data"];
            this.nationalityList = response["data"].map(x => ({
               id: x.id,
               text: x.name
            }))
         }
      })

   }

   getEthinicityList() {
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "ethnicity_Name"
      }

      this.commonApiService.GetByPaginated(Commonvariable.Ethinicity, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            //this.ethinicityList = response["data"];
            this.ethinicityList = response["data"].map(x => ({
               id: x.id,
               text: x.ethnicity_Name
            }))
            //this.companylist = response["data"].filter(x => x.isActive != 0);
         }
      })
   }

   getMaritalStatusList() {
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "Marital_Status"
      }

      this.commonApiService.GetByPaginated(Commonvariable.Marital_Status, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            //this.MaritalList = response["data"];
            this.MaritalList = response["data"].map(x => ({
               id: x.id,
               text: x.marital_Status
            }))
            //this.companylist = response["data"].filter(x => x.isActive != 0);
         }
      })
   }

   getAgeList() {
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "Description"
      }

      this.commonApiService.GetByPaginatedForAge(Commonvariable.Marital_Status, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            this.AgeList = response["data"].map(x => ({
               id: x.id,
               text: x.description,
               start: x.start,
               end: x.end
            }))

            console.log(this.AgeList);

          
         }
      })
   }


   // getlanguagesList() {
   //    this.languagesList = [];
   //    var requestData = {
   //       "searchBy": [
   //          {
   //             "FieldName": "isActive",
   //             "FieldValue": "1",
   //             "Parameter": "=="
   //          }
   //       ],
   //       "OrderBy": "LANGUAGES"
   //    }

   //    this.commonApiService.GetByPaginated(Commonvariable.Languages, requestData).subscribe((response) => {
   //       if (response != null && response["data"] != "") {

   //          this.languagesList = response["data"].map(x => ({
   //             id: x.id,
   //             text: x.languages
   //          }));

   //       }
   //    })
   // }




   empUpsert() {
      if (this.validation()) {
         return this.empBasicInfoform.value;
      }
      return "";
   }


   empEdit(Id) {
      if (Id > 0) {
         this.commonApiService.getById(Commonvariable.Person, Id).subscribe((response) => {
            if (response != null && response["data"] != "") {

               var editData = response["data"][0];
               if (JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "User") {
                  if (response["data"].length > 1) {
                     if (response["data"][1] != null) {
                        editData = response["data"][1]
                     }
                  }
               }


               let date = response["data"][0].dob != null ? response["data"][0].dob.split("T")[0].split("-") : "";
               if (date != "") {
                  $("#DOB").val(date[2] + "-" + date[1] + "-" + date[0]);
               } 
               setTimeout(() => {
                  this.empBasicInfoform = this.fb.group({
                     Id: [editData.id],
                     FirstName: [editData.firstName, Validators.required],
                     MiddleName: [editData.middleName != "" ? editData.middleName : null],
                     LastName: [editData.lastName, Validators.required],
                     Title: [editData.title],
                     Ethnicity_Id: [editData.ethnicity_Id],
                     MaritalStatus_Id: [editData.maritalStatus_Id],
                     DOB: ['', Validators.required],
                     //Gender_Id: [String(editData.gender_Id)],
                     //Nationality_Id: [editData.nationality_Id, Validators.required],
                    // lstNationality_Id: [String(editData.lstNationality_Id).split(","), Validators.required],
                    lstNationality_Id:[''],
                     Disability: [editData.disability],
                     Sexual_Orientation: [editData.sexual_Orientation],
                     Email: [editData.email],
                     PhoneNumber: [editData.phoneNumber],
                     Telephone: [editData.telephone],
                     isActive: ['1'],
                     isReview: ['1'],
                     UpdatedBy: [Commonvariable.User_Id],
                     person_Address: this.fb.array,
                     person_Contact: this.fb.array,
                     person_Emergency: this.fb.array,
                     person_Reference: this.fb.array
                  });

                  this.commonApiService.getimage(Commonvariable.Person, Id, "DownloadImage").subscribe((response) => {

                     var fileType = response.type;
                     var validImageTypes = ["image/gif", "image/jpeg", "image/png", "image/jpg", "image/bmp"];
                     if ($.inArray(fileType, validImageTypes) < 0) {
                        this.logoInnerHTML = "";
                        this.logoInnerHTML = "<img  src='assets/layouts/layout4/img/selectPic.png' alt = ''/>";
                     } else {

                        if (response != null) {
                           var reader = new FileReader();
                           reader.readAsDataURL(response);
                           reader.addEventListener("load", () => {
                              this.logoInnerHTML = "";
                              this.logoInnerHTML = "<img  src=" + reader.result + " alt = ''/>";
                              this.logoClass = "fileinput fileinput-exists";
                           }, false);
                        }

                     }

                  })
               });
            }
         }, (error) => { this.toastr.error(error["error"].message, 'Employee Personal Info'); })
      }
   }

   validation() {
      var formData = this.empBasicInfoform;
      //formData.controls.lstNationality_Id.setValue(formData.value.lstNationality_Id == null ? [] : formData.value.lstNationality_Id)
      //formData.controls.lstNationality_Id.setValue(this.nationality_Id_List == null ? [] : this.nationality_Id_List)
      formData.controls.DOB.setValue(this.commonService.dateFormatChange($("#DOB").val()));
      // formData.get("isActive").setValue(formData.get("isActive").value ? 1 : 0)

       let usdate = formData.value.DOB;
      // let TODAY = new Date(Date.now());
      // let EIGHTEEN_YEARS_BACK = new Date(new Date(TODAY).getDate() + "/" + new Date(TODAY).getMonth() + "/" + (new Date(TODAY).getFullYear() - 18));
       let USER_INPUT = new Date(usdate);
      // Validate Now
      //let result = EIGHTEEN_YEARS_BACK > USER_INPUT
      let TODAY = new Date(); // Current date
  let EIGHTEEN_YEARS_BACK = new Date(TODAY);
  EIGHTEEN_YEARS_BACK.setFullYear(TODAY.getFullYear() - 18);
      
      if (EIGHTEEN_YEARS_BACK < USER_INPUT) {
         this.toastr.info("Date Of Birth should be more than 18 years. !", "Employee");
         return false;
      }

      if (formData.value.Title == '') {
         this.toastr.info("Title is required !", "Employee");
         return false;
      }
      // else if (formData.value.Gender_Id == '') {
      //    this.toastr.info("Gender is required !", "Employee");
      //    return false;
      // }
      // else if (formData.value.Disability == '') {
      //    this.toastr.info("Disability is required !", "Employee");
      //    return false;
      // }

      if (formData.invalid) {
         //var Nationality = $(".nationalitySelectListClass option:selected").val();     
         for (const key of Object.keys(formData.controls)) {
            if (formData.controls[key].invalid) {
               let fieldName = "";
               if (key == "FirstName") {
                  fieldName = "First Name"
               }
               // else if (key == "MiddleName") {
               //    fieldName = "Middle Name"
               // }
               else if (key == "LastName") {
                  fieldName = "Last Name"
               }
               // else if (key == "Ethnicity_Id") {
               //    fieldName = "Ethnicity"
               // }
               // else if (key == "Nationality_Id") {
               //    fieldName = "Nationality"
               // }
               // else if (key == "lstNationality_Id") {
               //    fieldName = "Nationality"
               // }
               // else if (key == "Sexual_Orientation") {
               //    fieldName = "Sexual Orientation"
               // }
               // else if (key == "Disability") {
               //    fieldName = "Disability"
               // }
               else if (key == "DOB") {
                  fieldName = "DOB"
               }

               this.toastr.info(fieldName + " is required !", "Employee");
               $("#" + key).focus();

               return false;
            }

         }
      }
      else {
         if ($("#FirstName").val() != "" && /["\\]/.test($("#FirstName").val())) {
            this.toastr.info("First Name does not allow Special Characters \" \\ ", "Candidate info");
            $("#FirstName").focus();
            return false;
         }

         if ($("#MiddleName").val() != "" && /["\\]/.test($("#MiddleName").val())) {
            this.toastr.info("Middle Name does not allow Special Characters \" \\ ", "Candidate info");
            $("#MiddleName").focus();
            return false;
         }

         if ($("#LastName").val() != "" && /["\\]/.test($("#LastName").val())) {
            this.toastr.info("Last Name does not allow Special Characters \" \\ ", "Candidate info");
            $("#LastName").focus();
            return false;
         }
         // if ($("#FirstName").val().length < 2) {
         //    this.toastr.info("First Name must be at least 2 characters long !", "Employee");
         //    $("#FirstName").focus();
         //    return false;
         // }
         // if ($("#FirstName").val().indexOf(" ") > -1) {
         //    this.toastr.info("First Name not allow space !", "Employee");
         //    $("#FirstName").focus();
         //    return false;
         // }
         // if ($("#FirstName").val().search(/[^a-zA-Z-]+/) > -1) {
         //    this.toastr.info("First Name only alphabets allow !", "Employee");
         //    $("#FirstName").focus();
         //    return false;
         // }
         // if ($("#MiddleName").val().length < 1) {
         //    this.toastr.info("Middle Name must be at least 1 characters long !", "Employee");
         //    $("#MiddleName").focus();
         //    return false;
         // }
         // if ($("#MiddleName").val().indexOf(" ") > -1) {
         //    this.toastr.info("Middle Name not allow space !", "Employee");
         //    $("#MiddleName").focus();
         //    return false;
         // }
         // if ($("#MiddleName").val().search(/[^a-zA-Z-]+/) > -1) {
         //    this.toastr.info("Middle Name only alphabets allow !", "Employee");
         //    $("#MiddleName").focus();
         //    return false;
         // }
         // if ($("#LastName").val().length < 2) {
         //    this.toastr.info("Last Name must be at least 2 characters long !", "Employee");
         //    $("#LastName").focus();
         //    return false;
         // }
         // if ($("#LastName").val().indexOf(" ") > -1) {
         //    this.toastr.info("Last Name not allow space !", "Employee");
         //    $("#LastName").focus();
         //    return false;
         // }
         // if ($("#LastName").val().search(/[^a-zA-Z-]+/) > -1) {
         //    this.toastr.info("Last Name only alphabets allow !", "Employee");
         //    $("#LastName").focus();
         //    return false;
         // }

         if ($("#DOB").val() != "" && $("#DOB").val().search(/^(0[1-9]|[12][0-9]|3[01])[-.](0[1-9]|1[012])[-.](19|20)\d\d$/) != 0) {
            this.toastr.info("DOB Valid Format !", "Employee");
            $("#DOB").focus();
            return false;
         }
         if ($("#Email").val() != "" && $("#Email").val().search(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/) != 0) {
            this.toastr.info("Enter valid email address  !", "Employee");
            $("#Email").focus();
            return false;
         }
         if ($("#PhoneNumber").val() != "" && $("#PhoneNumber").val().search(/[^0-9- +()]+/) > -1) {
            this.toastr.info("Enter valid Mobile Number value  !", "Employee");
            $("#PhoneNumber").focus();
            return false;
         }
         if ($("#Telephone").val() != "" && $("#Telephone").val().search(/[^0-9- +()]+/) > -1) {
            this.toastr.info("Enter valid Telephone value  !", "Employee");
            $("#Telephone").focus();
            return false;
         }
         // if ($("#BloodGroup").val() != "" && $("#BloodGroup").val().search(/^(A|a|B|b|AB|ab|O|o)[-+]$/) != 0) {
         //    this.toastr.info("Enter valid bloodgroup !", "Employee");
         //    $("#BloodGroup").focus();
         //    return false;
         // }
      }
      this.commonService.setNull(formData);
      return true;
   }



   filechange(files: FileList) {


      if (files.item(0).name.split('.')[1] == "jpg" || files.item(0).name.split('.')[1] == "png"
         || files.item(0).name.split('.')[1] == "jpeg") {
         this.empLogoChange = true;
         this.empLogo = files.item(0);
      }
      else {
         this.toastr.info("Allow only jpg,png,jpeg file format for upload !", "Employee");
         $("#remove").click();
      }

   }

   setFormData(fieldName, id) {
      if (id != null) {
         if (id != undefined && id[0] != "") {
            // this.nationality_Id_List = id[0] == "" ? [] : id;
            // console.log(this.nationality_Id_List);
            this.empBasicInfoform.controls[fieldName].setValue(id[0] == "" ? [] : id)
         }
         else {
            this.empBasicInfoform.controls[fieldName].setValue([]);
         }
      }
      else {
         this.empBasicInfoform.controls[fieldName].setValue(null);
      }
   }
}