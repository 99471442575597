<style>
    td.details-control {
        background: url('assets/pages/img/SD7Dz.png') no-repeat center center;
        cursor: pointer;
    }

    tr.shown td.details-control {
        background: url('assets/pages/img/d4ICC.png') no-repeat center center;
    }
</style>
<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>Recruitment Selection</h1>
            </div>
        </div>
        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <div class="row">
                        <div class="col-md-12">
                            <ul class="nav nav-tabs">
                                <li class="active">
                                    <a data-toggle="tab" (click)="tabActive = 'rafWise'">RAF Wise</a>
                                </li>
                                <li><a data-toggle="tab" (click)="tabActive = 'candidateWise'">Candidate Wise</a>
                                </li>
                                <li><a data-toggle="tab" (click)="tabActive = 'statusWise'">Status Wise</a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane" [class.active]="tabActive == 'rafWise'" id="rafWise">
                                    <app-selection-raf-wise></app-selection-raf-wise>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane" [class.active]="tabActive == 'candidateWise'" id="candidateWise">
                                    <app-selection-candidate-wise></app-selection-candidate-wise>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane" [class.active]="tabActive == 'statusWise'" id="statusWise">
                                    <app-selection-status-wise></app-selection-status-wise>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>