<a [href]="fileUrl" style="visibility: hidden;" id="download" class="btn btn-transparent blue  btn-sm"
    [download]="fileName">Export</a>
<form [formGroup]="jDescriptionform">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Company<span class="required"> *
                    </span></label>
                <select id="company_Id" class="form-control companyid" formControlName="company_Id"
                    (change)="getDepartmentList()">
                    <option value="">Select Your Company</option>
                    <option *ngFor="let company of companylist;" value={{company.company_Id}}>
                        {{company.company_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Site<span class="required"> *
                    </span></label>
                <select id="site_Id" class="form-control site_Id" formControlName="site_Id">
                    <option value="">Select Your site</option>
                    <option *ngFor="let item of sitelist;" value={{item.site_Id}}>
                        {{item.site_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Department<span class="required"> *
                    </span></label>
                <select id="dept_Id" class="form-control departmentid" formControlName="dept_Id" (change)="getJobTitle()">
                    <option value="">Select Your department</option>
                    <option *ngFor="let item of departmentlist;" value={{item.dept_Id}}>
                        {{item.dept_Name}}</option>
                </select>
            </div>
        </div>
      
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Job Title<span class="required"> *
                    </span></label>
                <select id="desig_Id" class="form-control designationid" formControlName="desig_Id">
                    <option value="">Select Job Title</option>
                    <option *ngFor="let item of designationlist;" value={{item.desig_Id}}>
                        {{item.desig_Name}}</option>
                </select>
            </div>
        </div>
        <!-- <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Job Description Code<span class="required"> *
                    </span></label>
                <input type="text" id="jD_Code" class="form-control" formControlName="jD_Code" autocomplete="off">
            </div>
        </div> -->
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Job Description Name<span class="required"> *
                    </span></label>
                <input type="text" id="jD_Name" class="form-control" formControlName="jD_Name" maxlength="30"
                    autocomplete="off">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Job Description</label>
                <textarea id="jD_Description" class="form-control" rows="2" formControlName="jD_Description"></textarea>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Notes</label>
                <textarea id="notes" class="form-control" rows="2" formControlName="notes"></textarea>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-7" *ngIf="uploadDocument">
            <label class="control-label">Upload Document</label>
            <div class="fileinput fileinput-new" data-provides="fileinput">
                <div class="input-group input-large">
                    <div class="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                        <i class="fa fa-file fileinput-exists"></i>&nbsp;
                        <span class="fileinput-filename"> </span>
                    </div>
                    <span class="input-group-addon btn default btn-file">
                        <span class="fileinput-new"> Select file </span>
                        <span class="fileinput-exists"> Change </span>
                        <input type="file"
                            accept="image/x-png,image/jpg,image/jpeg,application/doc,application/docx,application/pdf"
                            id="job_descriptionPath" (change)="filechange($event.target.files)"> </span>
                    <a class="input-group-addon btn red fileinput-exists" data-dismiss="fileinput">
                        Remove </a>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox">
                        <input type="checkbox" id="isActivechk" class="md-check" formControlName="isActive">
                        <label for="isActivechk">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>