<form [formGroup]="empContactform">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Contact Type<span class="required"> * </span></label>
                <select id="contact_Type" class="form-control" formControlName="contact_Type" #contact_Type>
                    <option value="">Select Contact Type</option>
                    <option value="Email">Email</option>
                    <option value="Fax">Fax</option>
                    <option value="Mobile No">Mobile No</option>
                    <option value="Phone No">Phone No</option>


                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Contact Details<span class="required"> * </span></label>
                <input type="text" class="form-control" id="contact_Value" formControlName="contact_Value"
                    autocomplete="off" maxlength="100"
                    *ngIf="contact_Type.value != 'Mobile No' && contact_Type.value != 'Phone No'">



                <div class="input-group mobilenowithcountryode"
                    *ngIf="contact_Type.value == 'Mobile No' || contact_Type.value == 'Phone No'">
                    <div class="input-group-btn">
                        <ng-select2 [data]="countryList" id="country_Id" formControlName="country_Id"
                            [placeholder]="'--- Select ---'">
                        </ng-select2>
                    </div>

                    <input type="text" class="form-control" id="contact_Value"
                        *ngIf="contact_Type.value == 'Mobile No' || contact_Type.value == 'Phone No'"
                        formControlName="contact_Value" autocomplete="off" maxlength="20" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.key === "Backspace" ||  event.charCode == 40 
                      || event.charCode == 32 || event.charCode == 41 || event.charCode == 43 || event.charCode == 45'>
                </div>



            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <div class="form-group">
                <label class="control-label">Default</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox">
                        <input type="checkbox" id="chk_1" class="md-check" formControlName="isDefault">
                        <label for="chk_1">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="chk_2" class="md-check" formControlName="isActive">
                            <label for="chk_2">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>