<style>
   .btnInterview button {
      margin-right: 10px;
      min-width: 100px;
   }

   .highlighted {
      background-color: #e9ecf3 !important;
   }

   .cursor {
      cursor: pointer;
   }


   .disabled {
      pointer-events: none;
      opacity: 0.5;
      display: none;
   }
</style>

<div class="page-content-wrapper">
   <div class="page-content">
      <div class="page-head">
         <div class="page-title">
            <h1>Interviewer Process</h1>
         </div>
      </div>
      <!-- <div class="navbar page-breadcrumb breadcrumb mb20">
         <ul class="nav navbar-nav navbar-right">
            <li>
               <div>
                  <a href="javascript:;" (click)="openModel(0)" class="btn btn-transparent blue  btn-sm">Add Gender</a>
               </div>
            </li>
         </ul>
      </div> -->
      <div class="col-md-12">
         <div class="portlet box grey">
            <div class="portlet-body">
               <diV class="row">
                  <div class="col-md-4">
                     <div class="form-group">
                        <label class="control-label">Company <span class="required"> *
                           </span></label>
                        <select class="form-control" #companyId (change)="getSiteList(companyId.value)">
                           <option value="">Select Company</option>
                           <option *ngFor="let item of companyList;" value={{item.company_Id}}>
                              {{item.company_Name}}</option>
                        </select>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="form-group">
                        <label class="control-label">Site <span class="required"> *
                           </span></label>
                        <select class="form-control" #siteId (change)="getRafList(siteId.value)">
                           <option value="">Select Site</option>
                           <option *ngFor="let item of siteList;" value={{item.site_Id}}>
                              {{item.site_Name}}</option>
                        </select>
                     </div>
                  </div>
                  <!-- <div class="col-md-3">
                     <select class="form-control" #departmentId (change)="getRafList(siteId.value)">
                        <option value="">Select Department</option>
                        <option *ngFor="let item of departmentList;" value={{item.dept_Id}}>
                           {{item.dept_Name}}</option>
                     </select>
                  </div> -->
                  <div class="col-md-4">
                     <div class="form-group">
                        <label class="control-label">RAF<span class="required"> *
                           </span></label>
                        <select class="form-control" #rafId (change)="getCandidate(companyId.value,rafId.value)">
                           <option value="">Select RAF</option>
                           <option *ngFor="let item of rafList;" value={{item.id}}>
                              {{item.desig_Name}}</option>
                        </select>
                     </div>
                  </div>
               </diV>
               <hr />
               <diV class="row">
                  <div class="col-md-1">
                     <div class="form-group">
                        <select class="form-control" id="interviewStatus"
                           (change)="getCandidate(companyId.value,rafId.value)">
                           <option value="All">All</option>
                           <option value="New">New</option>
                           <option value="Selected">Selected</option>
                           <option value="Shortlisted">Shortlisted</option>
                           <option value="Offered">Offered</option>
                           <option value="Accepted">Accepted</option>
                           <option value="Decline">Decline</option>
                        </select>
                     </div>
                  </div>
                  <div class="col-md-8">

                  </div>
                  <div class="col-md-3 text-right">
                     <div class="form-group">
                        <input type="text" class="form-control" id="filterList" autocomplete="off"
                           placeholder="Type to filter ..." (input)="filterData($event)">
                     </div>
                  </div>
               </diV>
               <div class="table-responsive">
                  <table class="table table-striped table-bordered" id="tblInterviewer">
                     <thead class="flip-content">
                        <tr>
                           <th style="width: 20%;" class="text-center"> Candidate Name </th>
                           <th style="width: 15%;" class="text-center"> Category </th>
                           <th style="width: 15%;" class="text-center"> Level </th>
                           <th style="width: 15%;" class="text-center"> Skill </th>
                           <th style="width: 15%;" class="text-center"> Experience </th>
                           <th style="width: 15%;" class="text-center"> Status </th>
                           <th class="text-center" style="width: 5%"> Actions </th>
                        </tr>
                     </thead>

                     <tbody>
                        <tr *ngFor="let item of shortListedCandidateList| paginate: { itemsPerPage: 5, currentPage: p }"
                           [class.highlighted]="item.id === candidate_Id" class="cursor">

                           <td (click)="getInterviews(item.id ,item.vacancy_Id,item.status)">{{item.firstName}}
                              {{item.lastName}}
                           </td>
                           <td (click)="getInterviews(item.id ,item.vacancy_Id,item.status)">
                              <aside data-toggle="tooltip" data-placement="bottom" title="{{item.job_Category}}"
                                 class="text-center">
                                 {{ item.job_Category != null ?  (item.job_Category.length>20)? (item.job_Category | slice:0:20)+'...':(item.job_Category) : item.job_Category }}
                              </aside>
                           </td>
                           <td (click)="getInterviews(item.id ,item.vacancy_Id,item.status)">
                              <aside data-toggle="tooltip" data-placement="bottom" title="{{item.job_Level}}"
                                 class="text-center">
                                 {{ item.job_Level != null ? (item.job_Level.length>20)? (item.job_Level | slice:0:20)+'...':(item.job_Level) : item.job_Level}}
                              </aside>
                           </td>
                           <td (click)="getInterviews(item.id ,item.vacancy_Id,item.status)">
                              <aside data-toggle="tooltip" data-placement="bottom" title="{{item.skills}}"
                                 class="text-center">
                                 {{ item.skills != null ?  (item.skills.length>20)? (item.skills | slice:0:20)+'...':(item.skills) : item.skills}}
                              </aside>
                           </td>
                           <td class="text-center" (click)="getInterviews(item.id ,item.vacancy_Id,item.status)"
                              *ngIf="item.experience_Year != null && item.experience_Month != null">
                              {{item.experience_Year}} Year {{item.experience_Month}} Month</td>
                           <td class="text-center" (click)="getInterviews(item.id ,item.vacancy_Id,item.status)"
                              *ngIf="item.experience_Year != null && item.experience_Month == null">
                              {{item.experience_Year}} Year</td>
                           <td class="text-center" (click)="getInterviews(item.id ,item.vacancy_Id,item.status)"
                              *ngIf="item.experience_Year == null && item.experience_Month != null">
                              {{item.experience_Month}} Month</td>
                           <td class="text-center" (click)="getInterviews(item.id ,item.vacancy_Id,item.status)"
                              *ngIf="item.experience_Year == null && item.experience_Month == null">0</td>
                           <td (click)="getInterviews(item.id ,item.vacancy_Id,item.status)">{{item.status}}</td>
                           <td>
                              <a (click)="downloadCV(item.id)" *ngIf="item.cv != null">
                                 <i class="fa fa-download purple iconcss iconFontsize" data-toggle="tooltip"
                                    data-placement="top" title="Download CV"></i>&nbsp;
                              </a>

                              <a *ngIf="item.status == 'Selected'"
                                 (click)="UpdateStatus('Offered',item.id,item.vacancy_Id)">
                                 <i class="fa fa-gift iconcss iconFontsize" data-toggle="tooltip" data-placement="top"
                                    title="Offered"></i>&nbsp;
                              </a>

                              <a *ngIf="item.status == 'Offered'"
                                 (click)="UpdateStatus('Accepted',item.id,item.vacancy_Id)"><i
                                    class="fa fa-check-square-o green iconcss iconFontsize" data-toggle="tooltip"
                                    data-placement="top" title="Accepted"></i>&nbsp;</a>

                              <a *ngIf="item.status == 'Offered'"
                                 (click)="UpdateStatus('Decline',item.id,item.vacancy_Id)"><i
                                    class="fa fa-close red-color iconcss iconFontsize" data-toggle="tooltip"
                                    data-placement="top" title="Decline"></i>&nbsp;</a>

                              <a *ngIf="item.status == 'Accepted'" (click)="moveToOnboarding(item.id,item.vacancy_Id)">
                                 <i class="fa fa-paper-plane-o iconcss iconFontsize" data-toggle="tooltip"
                                    data-placement="top" title="Move To Onboarding"></i>
                              </a>
                              <a *ngIf="item.status == 'New'"
                                 >
                                 <i class="fa fa-calendar iconcss iconFontsize" data-toggle="tooltip"
                                    data-placement="top" title="Schedule Interview"></i>
                              </a>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
               <div class="text-center" *ngIf="shortListedCandidateList!= null">
                  <pagination-controls (pageChange)="p = $event" autoHide="true"></pagination-controls>
               </div>
            </div>
         </div>
      </div>

      <div class="col-md-12" [class.disabled]="isDisabled">
         <div class="portlet light bordered">
            <div class="portlet-title">
               <div class="caption">
                  <span class="caption-subject font-red bold uppercase"> INTERVIEWS</span>
               </div>
            </div>
            <div class="portlet-body">
               <app-interview></app-interview>

               <div class="row" id="interviewerResponse">
                  <div class="col-md-12">
                     <div class="form-group">
                        <hr />
                        <div class="md-checkbox-inline">
                           <label class="control-label">Is Final &nbsp;&nbsp;</label>
                           <div class="md-checkbox">
                              <input type="checkbox" id="final" class="md-check" (change)="isFinal = !isFinal"
                                 [checked]="isFinal">
                              <label for="final">
                                 <span></span>
                                 <span class="check"></span>
                                 <span class="box"></span> </label>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="text-center btnInterview">
                     <button class="btn btn-outline blue" (click)="UpsertInterviewDetail('Shortlisted')"
                        [disabled]="isFinal">Save</button>
                     <button class="btn btn-outline blue" (click)="UpsertInterviewDetail('Selected')"
                        [disabled]="!isFinal">Selected</button>
                     <button class="btn btn-outline blue" (click)="UpsertInterviewDetail('Rejected')"
                        [disabled]="!isFinal">Rejected</button>
                  </div>
               </div>

            </div>
         </div>
      </div>
   </div>
</div>
<a [href]="fileUrl" id="download" class="btn btn-transparent blue  btn-sm" style="visibility: hidden;"
   download="CV.pdf">Export</a>




