import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;

@Component({
   selector: 'app-companybasicinfo',
   templateUrl: './company-basic-info.component.html',
})
export class CompanyBasicInfoComponent implements OnInit {


   companyBasicInfoform: FormGroup;
   companyDoc: any;


   constructor(
      private toastr: ToastrService,
      private commonService: CommonService,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {

      this.commonService.flatpickrForDOB('flatpickropen',{dateFormat: "d-m-Y"})
      
   
      this.companyBasicInfoform = this.fb.group({
         company_Id: ['0'],
         company_Code: [''],
         company_Name: ['', Validators.required],
         registration_No: ['', Validators.required],
         registration_Date: ['', Validators.required],
         company_Parent_Id: [null],
         currency_Id: [null],
         language: [null],
         isActive: ['1'],
         logo: [null],
         addedBy: [Commonvariable.User_Id],
         updatedBy: [Commonvariable.User_Id],
         company_contact: this.fb.array,
         email_config:this.fb.group,
         comp_emp_config:this.fb.group
      });

   }

   companyUpsert() {

      if (this.validation()) {
         return this.companyBasicInfoform.value;
      }
      return "";
   }


   validation() {
      var formData = this.companyBasicInfoform;
      formData.controls.registration_Date.setValue(this.commonService.dateFormatChange($("#registration_Date").val()));
      formData.get("isActive").setValue(formData.get("isActive").value ? 1 : 0);
      formData.get("company_Name").setValue(formData.get("company_Name").value);
      
      if (!formData.valid) {
         for (const key of Object.keys(formData.controls)) {
            if (formData.controls[key].invalid) {
               let fieldName = "";
               if (key == "company_Code") {
                  fieldName = "Code"
               }
               else if (key == "company_Name") {
                  fieldName = "Name"
               }
               else if (key == "registration_No") {
                  fieldName = "Registration No"
               }
               else if (key == "registration_Date") {
                  fieldName = "Registration Date"
               }
               this.toastr.info(fieldName + " is required !", "Company");
               $("#" + key).focus();
               return false;
            }
         }
      }
      else {
         // if ($("#company_Code").val().length < 2) {
         //    this.toastr.info("Code must be at least 2 characters long !", "Company");
         //    $("#company_Code").focus();
         //    return false;
         // }
         // if ($("#company_Code").val().indexOf(" ") > -1) {
         //    this.toastr.info("Code not allow space !", "Company");
         //    $("#company_Code").focus();
         //    return false;
         // }
         // if ($("#company_Code").val().search(/[^a-zA-Z0-9]+/) > -1) {
         //    this.toastr.info("Code only alphabets and numeric value allow !", "Company");
         //    $("#company_Code").focus();
         //    return false;
         // }
         if ($("#company_Name").val().length < 2) {
            this.toastr.info("Name must be at least 2 characters long !", "Company");
            $("#company_Name").focus();
            return false;
         }
         if (/["\\]/.test($("#company_Name").val())) {
            this.toastr.info("Name does not allow Special Characters \" \\ ", "Company");
            $("#company_Name").focus();
            return false;
          }
         // if ($("#company_Name").val().search(/^[^-\s][a-zA-Z0-9\s.]+[^-\s]+$/) != 0) {
         //    this.toastr.info("Name only alphabets and numeric allow !", "Company");
         //    $("#company_Name").focus();
         //    return false;
         // }
         if ($("#registration_No").val().length < 2) {
            this.toastr.info("Registration No must be at least 2 characters long !", "Company");
            $("#registration_No").focus();
            return false;
         }
         if ($("#registration_No").val().indexOf(" ") > -1) {
            this.toastr.info("Registration No not allow space !", "Company");
            $("#registration_No").focus();
            return false;
         }
         if ($("#registration_No").val().search(/[^a-zA-Z0-9]+/) > -1) {
            this.toastr.info("Registration No only alphabets and numeric value allow !", "Company");
            $("#registration_No").focus();
            return false;
         }
         // if ($("#registration_Date").val().search(/^(0[1-9]|[12][0-9]|3[01])[-.](0[1-9]|1[012])[-.](19|20)\d\d$/) != 0) {
         //    this.toastr.info("Registration Date Valid Format !", "Company");
         //    $("#registration_Date").focus();
         //    return false;
         // }
      }
      return true;
   }


}


