<style>
    @media (min-width: 992px) {
        .modal-lg-usercreation {
            width: 1200px !important;
        }
    }
    
</style>

<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>User Creation Request
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->

        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <!-- <li>
                    <div>
                        <a (click)="openModel(0)" class="btn btn-transparent blue  btn-sm"></a>
                    </div>
                </li> -->
            </ul>
        </div>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" placeholder="Type to filter ..." id="filterList"
                                (input)="getCandidateList()">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblZone">
                            <thead class="flip-content">
                                <tr>

                                    <th class="text-center" style="width: 14%;"> Name </th>
                                    <th class="text-center" style="width: 13%;">Email</th>
                                    <th class="text-center" style="width: 13%;">Company</th>
                                    <th class="text-center" style="width: 13%;">Department</th>
                                    <th class="text-center" style="width: 13%;">Designation</th>
                                    <th class="text-center" style="width: 10%;">Mobile</th>
                                    <th class="text-center" style="width: 10%;">Start Date</th>

                                </tr>
                            </thead>
                            <tbody
                                *ngFor="let item of candidatelist| paginate: { itemsPerPage: 10, currentPage: p  ,totalItems :totalRecord}">
                                <tr (click)="userCreation(item.id ,item.userCreation_Rule_Id)" style="cursor: pointer;">

                                    <td>
                                        {{item.firstName}} {{item.middleName}} {{item.lastName}}
                                    </td>
                                    <!-- <td>{{item.firstName}} {{item.middleName}} {{item.lastName}}</td> -->
                                    <td>{{item.eMail}}</td>
                                    <td>{{item.company_Name}}</td>
                                    <td>{{item.dept_Name}}</td>
                                    <td>{{item.desig_Name}}</td>
                                    <td>{{item.mobile}}</td>
                                    <td class="text-center">{{item.joiningDate | date :'dd/MM/yyyy'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center">
                        <pagination-controls (pageChange)="getCandidateList($event)" autoHide="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->

<div class="modal fade draggable-modal" id="userRequestCreationForm">
    <div class="modal-dialog modal-lg-usercreation" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">User Creation Form</h4>
            </div>
            <div class="modal-body">
                <app-usercreationform></app-usercreationform>
            </div>
        </div>
    </div>
</div>