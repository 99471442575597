import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { SendMailComponent } from 'src/app/pages/sendmail/sendmail.component';
import { ToastrService } from 'ngx-toastr';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { debug } from 'util';
declare var $: any;

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html'
})
export class UserComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  @ViewChild(SendMailComponent, { static: false }) MailSend: SendMailComponent;

  userform: FormGroup;
  userlist: any;
  filterUser: any;
  companylist: any;
  listcall: boolean = true;
  empList: any;
  roleList: any;
  passwordvisible: boolean = true;
  checkValidation: boolean = false;
  companyvisible: boolean = true;
  empVisible: boolean = true;
  title: string = "Add";
  p: number = 1;
  html: string = '';
  empIds: number;
  candidate_Ids: number;
  candidate_name: string;
  editMode: boolean = false;
  companyName: any;
  empName: string;
  candidateName: string;
  candidateVisible: boolean = true;
  totalRecord: 0;
  userId: any;

  dtOptions: DataTables.Settings = {};

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.title = "Add";
    var self = this;
    this.userform = this.fb.group({
      user_Id: ['0'],
      company_Id: ['', Validators.required],
      person_Id: ['', Validators.required],
      login_Id: ['', Validators.required],
      user_Type: ['', Validators.required],
      email: [null],
      password: ['1234!@#$abcd'],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: ['0']
    });
    this.passwordvisible = true;
    this.companyvisible = true;
    this.empVisible = true;
    this.candidateVisible = true;
    // $("select").select2({
    //   dropdownParent: $('#draggable'),
    //   width: "auto"
    // });

    setTimeout(() => {
      $('.companySelectClass, .employeeSelectClass, .userTypeSelectClass').select2({ width: '100%', dropdownParent: $('#draggable') });

      $('.companySelectClass').on('select2:select', function (e) {
        self.userform.controls.company_Id.setValue($('.companySelectClass').val())
        self.getEmpList();
      });

      $('.employeeSelectClass').on('select2:select', function (e) {
        self.userform.controls.person_Id.setValue($('.employeeSelectClass').val())
      });
    }, 500);

    if (this.listcall) {
      this.getUserList();
      this.getCompanyList();
    }

  }

  getUserList(PageIndex = 1) {


    this.p = PageIndex;
    this.totalRecord = 0;
    this.userlist = [];
    this.filterUser = [];

    var requestData = {
      "searchBy": [
        {
          "FieldName": "Onboarding_Id",
          "FieldValue": JSON.stringify(null),
          "Parameter": "=="
        }
      ],
      "PageIndex": PageIndex - 1,
      "CommonSearch": $("#filterList").val().trim(),
      "PageSize": 10
    }

    this.commonApiService.GetByPaginated(Commonvariable.User, requestData, "GetPaginated").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"];
        this.userlist = result["data"]["list"];
        this.filterUser = result["data"]["list"];
      }
      this.getRoleList();
    }, (error) => { this.getRoleList(); })


    // this.userlist = [];
    // this.filterUser = [];
    // this.commonApiService.List(Commonvariable.User).subscribe((result) => {
    //   if (result != null && result["data"] != "") {
    //     this.userlist = result["data"];
    //     this.filterUser = result["data"];
    //     this.getRoleList();
    //   }
    // }, (error) => { this.getRoleList(); })

  }

  getRoleList() {
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isDisplay",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Roles, requestData).subscribe((result) => {
   
      if (result["data"] != "")
        this.roleList = result["data"].sort((a, b) => a.name.localeCompare(b.name));
    })
  }



  getCompanyList() {
    this.companylist = [];
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "company_Name"
    }

    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companylist = response["data"];
      }
    })
  }




  getEmpList() {
    this.empList = [];
    // var requestData = {
    //   "searchBy": [
    //     {
    //       "FieldName": "Is_Userlogin",
    //       "FieldValue": 0,
    //       "Parameter": "==",
    //       "ConditionWith": "&&"
    //     },
    //     {
    //       "FieldName": "Company_Id",
    //       "FieldValue": this.userform.controls.company_Id.value,
    //       "Parameter": "==",
    //       "ConditionWith": "&&"
    //     },
    //     {
    //       "FieldName": "isActive",
    //       "FieldValue": 1,
    //       "Parameter": "=="
    //     }
    //   ]
    // }

    if (this.userform.controls.company_Id.value > 0) {

      this.commonApiService.getById(Commonvariable.EmployeeDetail, this.userform.controls.company_Id.value,"GetEmployeeForUser").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.empList = result["data"]
          if (this.empList.length > 0)
            this.empList = this.empList.sort((a, b) => a.firstName.localeCompare(b.firstName))
        }
      })
    }

  }



  get formControls(): any {
    return this.userform['controls'];
  }

  Upsert() {
    var userForm = this.userform;

    if (this.validation()) {
      var userId = userForm.controls.user_Id.value;
      if (userId > 0) {
        userForm.get("password").setValue(null);
      }
      userForm.get("isActive").setValue(userForm.get("isActive").value ? 1 : 0);
      this.commonApiService.Upsert(Commonvariable.User, userForm.value, userId).subscribe((response) => {
        if (response["status"] == 200) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'User');
          }
          else {
            $("#draggable").modal('hide');
            this.getUserList();
            if (userId > 0) {
              this.toastr.success(Commonvariable.UpdateMsg, 'User');
            }
            else {
              this.openMailBox(response["data"]["user_Id"])
              this.toastr.success(Commonvariable.SaveMsg, 'User');
            }
          }
        }
      })
    }
  }

  openModel(dId) {
    if (dId > 0) {

      this.editMode = true;

      this.commonApiService.getById(Commonvariable.User, dId).subscribe((response) => {
        if (response != null && response["data"] != "") {
          var editData = response["data"];
          var formData = this.userform;

          this.empIds = editData['emp_Id'];
          this.candidate_Ids = editData['candidate_Id'];
          for (const key of Object.keys(formData.controls)) {
            formData.controls[key].setValue(editData[key]);
          }

          var editRecord = this.userlist.filter(x => x.user_Id == dId)[0]

          var requestData = {
            "searchBy": [
              {
                "FieldName": "User_Id",
                "FieldValue": editRecord.user_Id,
                "Parameter": "=="
              }
            ]
          }
          this.commonApiService.getPaginated(Commonvariable.User_Role, null, null, null, null, null, requestData).subscribe((response) => {
            if (response != null && response["data"] != "") {
              $("#user_Type").val(response["data"]["list"][0]["role_Id"])
              $("#user_Type").prop("disabled", "disabled");
              $("#login_Username").prop("disabled", "disabled");
            }
          })




          if (editData['company_Id'])
            this.companyName = editRecord.company_Name;



          if (editData['emp_Id'])
            this.empName = editRecord.firstName + " " + editRecord.lastName


          if (this.candidate_Ids) {
            this.candidateName = editRecord.firstName + " " + editRecord.lastName
            formData.controls.emp_Id.setValue(editData['candidate_Id']);
          }



          if (editData['emp_Id']) {
            $("#login_Id").prop("disabled", "disabled");
          }
          if (editData['candidate_Id']) {
            $("#login_Id").prop("disabled", "disabled");
          }


          this.roleList.forEach(x => {

            if (x.name == editData["user_Type"]) {
              $("#user_Types").val(x.id);
            }

          });

          this.passwordvisible = false;
          this.companyvisible = false;
          this.empVisible = false;
          this.candidateVisible = false;
          this.getEmpList();
          this.title = "Edit";
          $('.userTypeSelectClass').select2({ width: '100%', dropdownParent: $('#draggable') });

        }
      })
    }
    else {
      this.listcall = false;
      $("#login_Id").removeAttr("disabled");
      $("#emp_Id").removeAttr("disabled");
      $("#user_Types").removeAttr("disabled");
      $("#login_Username").removeAttr("disabled");
      $("#user_Types").val("");
      this.empList = [];
      this.ngOnInit();

    }
    $('#draggable').modal({
      backdrop: 'static'
    });



  }

  Delete(dId) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.User, dId).subscribe((response) => {
          if (response["status"] == 200) {
            //$("#draggable").modal('hide');
            this.getUserList();
            this.toastr.success(response["message"], 'User');
          }
        }, (error) => { this.toastr.error(error["error"].message, 'User'); })
      }
    })
  }

  ResetPassword(loginId) {
    if (loginId != "") {
      var data = {
        login_Id: loginId,
        password: "1234!@#$abcd"
      }
      this.commonService.dyanmicConfirm("You want to reset password !", "Yes").then((result) => {
        if (result.value) {
          this.commonApiService.PostRequest(Commonvariable.User, "AdminChangePassword", data).subscribe((response) => {
            if (response["status"] == 200) {
              this.getUserList();
              this.toastr.success(response["message"], 'User');
            }
          })
        }
      })
    }
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.User, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.getUserList();
            this.toastr.success(response["message"], 'User');
          }

        })
      }

    });
  }


  openMailBox(id) {
    this.userId = id;

    this.commonApiService.getById(Commonvariable.User, id, "GetSendMailData").subscribe((response) => {
      if (response["data"] != "") {
        this.MailSend.documentName = "";
        $('#userSendMail').modal("show");

        setTimeout(() => {
          $("#userSendMail #to").val(response["data"]["to"])
          $("#userSendMail #cc").val(response["data"]["cc"])
          $("#userSendMail #bcc").val(response["data"]["bcc"])
          $("#userSendMail #subject").val(response["data"]["subject"])
          $('#userSendMail #emailsend').summernote('code', response["data"]["body"]);
        }, 500);

      }
    })

  }


  validation() {
    var userForm = this.userform;
    // userForm.get("company_Id").setValue($("#company_Id").val());
    // userForm.get("person_Id").setValue($("#person_Id").val());
    userForm.controls.user_Type.setValue(String($("#user_Types option:selected").text()).trim());
    if (!userForm.valid) {
      for (const key of Object.keys(userForm.controls)) {
        if (userForm.controls[key].invalid) {
          let fieldName = "";
          if (key == "company_Id") {
            fieldName = "Company"
          }
          else if (key == "person_Id") {
            fieldName = "Employee"
          }
          else if (key == "login_Id") {
            fieldName = "Username"
          }
          else if (key == "user_Type") {
            fieldName = "User type"
          }
          this.toastr.info(fieldName + " is required !", "User");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if ($("#user_Types option:selected").text() == "Select User Type") {
        this.toastr.info("User type is required !", "User");
        $("#user_Types").focus();
        return false
      }
      if ($("#login_Username").val().length < 2) {
        this.toastr.info("Username must be at least 2 characters long !", "User");
        $("#login_Username").focus();
        return false;
      }
      if (String($("#user_Types option:selected").text()).trim().length < 2) {
        this.toastr.info("User type must be at least 2 characters long !", "User");
        $("#user_Type").focus();
        return false;
      }
    }
    return true;
  }



  sendMail() {
    const formData = new FormData();
    formData.append('To', $("#userSendMail #to").val());
    formData.append('CC', $("#userSendMail #cc").val());
    formData.append('BCC', $("#userSendMail #bcc").val());
    formData.append('Subject', $("#userSendMail #subject").val());
    formData.append('Role_Ids', this.MailSend.roleId != undefined ? this.MailSend.roleId : null);
    formData.append('Body', $('#userSendMail #emailsend').summernote('code'));
    formData.append('Company_Id', this.userform.controls.company_Id.value);
    formData.append('User_Id', this.userId);
    formData.append('userEmailBodyPassword', $("#userSendMail #userEmailBodyPassword").val());
    if (this.MailSend.docFiles != undefined) {
      for (var i = 0; i < this.MailSend.docFiles.length; i++) {
        formData.append('file', this.MailSend.docFiles[i]);
      }
    }
    this.commonApiService.UploadLogo(Commonvariable.User, "SendMail", formData).subscribe((response) => {
      this.toastr.success(Commonvariable.SaveMsg, 'User');
      this.getUserList();
    });
  }


  history() {
    this.historyView.getHistory("users", this.userform.controls.user_Id.value)
  }



}
