import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { EmployeePersonalInfoComponent } from "./../employee-personal-info/employee-personal-info.component";
import { EmpAddressInfoComponent } from "../../employeeEdit/address-info/emp-address-Info.component";
import { EmployeeContactComponent } from "../../employeeEdit/emp-contact/emp-contact.component";
import { EmployeeEmergencyComponent } from "../../employeeEdit/emp-emergency-contact/emp-emergency-contact.component";
import { EmployeeRefComponent } from "../../employeeEdit/emp-reference/emp-reference.component";
import { EmployeeBankComponent } from '../../employeeEdit/emp-bank/emp-bank.component';
import { CandidatePersonEqualityComponent } from '../../onboarding/onboarding-person-equality/candidate-person-equality.component';
declare var $: any;

@Component({
   selector: 'app-userviewsummary',
   templateUrl: './user-view-summary.component.html',
})
export class UserViewSummaryComponent implements OnInit {
   @ViewChild(EmployeePersonalInfoComponent, { static: false }) EmpPersonalInfo: EmployeePersonalInfoComponent;
   @ViewChild(EmpAddressInfoComponent, { static: false }) EmpAddress: EmpAddressInfoComponent;
   @ViewChild(EmployeeContactComponent, { static: false }) EmpContact: EmployeeContactComponent;
   @ViewChild(EmployeeEmergencyComponent, { static: false }) EmpEmeContact: EmployeeEmergencyComponent;
   @ViewChild(EmployeeRefComponent, { static: false }) EmpReference: EmployeeRefComponent;
   @ViewChild(EmployeeBankComponent, { static: false }) EmpBank: EmployeeBankComponent;
   @ViewChild(CandidatePersonEqualityComponent, { static: false }) CandidatePersonEquality: CandidatePersonEqualityComponent;
   employeeId: any;
   employeeSummary: any;
   employeeAddress: any[] = [];
   employeeBasicInfo: any;
   employeeContract: any;
   employeeEmergency: any;
   employeeReference: any;
   employeeBank: any;
   employeeProbation: any;
   employeeContact: any;
   employeeRighttowork: any;
   empLeaveBalance: any;
   employeeSalary: any;
   ethnicity_Name: any;
   empDocuments: any;
   empNiNoDetailform: FormGroup;
   person_equalityInfo?: any;
   constructor(private commonApiService: CommonApiService,
      private toastr: ToastrService,
      private commonService: CommonService,
      private router: Router,
      private fb: FormBuilder
   ) {

      let currentUser = JSON.parse(localStorage.getItem('currentHRUser'));
      if (currentUser["data"]["role"] != "User") {
         this.router.navigateByUrl('home');
      }

   }

   ngOnInit() {
      this.getSummary();
   }

   getSummary() {

      let currentUser = JSON.parse(localStorage.getItem('currentHRUser'));

      if (currentUser["data"]["company_Id"] > 0 && currentUser["data"]["person_Id"] > 0) {
         var id = currentUser["data"]["person_Id"] + "," + currentUser["data"]["company_Id"]
         this.commonApiService.getById(Commonvariable.EmployeeDetail, id, "GetEmpId").subscribe((res) => {
            this.commonApiService.getById(Commonvariable.EmployeeDetail, res["data"], "ViewSummary").subscribe((response) => {
               if (response != null) {
                  this.employeeSummary = response["data"][0];

                  this.employeeContract = this.employeeSummary["emp_contract"];
                  this.employeeBank = this.employeeSummary["emp_bank"];
                  this.employeeProbation = this.employeeSummary["emp_probation"];
                  this.employeeRighttowork = this.employeeSummary["emp_righttowork"];
                  this.empLeaveBalance = this.employeeSummary["emp_leave_balance"];
                  this.employeeSalary = this.employeeSummary["emp_salary"];
                  this.empDocuments = this.employeeSummary["emp_document"];

                  if (response["data"][0]["person_Id"] != null) {
                     this.commonApiService.getById(Commonvariable.PersonService, response["data"][0]["person_Id"], "ViewSummary").subscribe((result) => {

                        this.employeeBasicInfo = result["data"][0];
                        // if (result["data"][0]["review_person"][0] != null) {
                        //    this.employeeBasicInfo = result["data"][0]["review_person"][0];
                        // }

                        this.employeeAddress = result["data"][0]["person_Address"];

                        // if (result["data"][0]["review_person_address"][0] != null) {
                        //    result["data"][0]["review_person_address"].forEach(x => {
                        //       this.employeeAddress.push(x);
                        //    });
                        // }

                        this.employeeContact = result["data"][0]["person_Contact"];

                        // if (result["data"][0]["review_person_contact"][0] != null) {
                        //    result["data"][0]["review_person_contact"].forEach(x => {
                        //       this.employeeContact.push(x);
                        //    });
                        // }


                        this.employeeEmergency = result["data"][0]["person_Emergency"];

                        // if (result["data"][0]["review_person_Emergency"][0] != null) {
                        //    result["data"][0]["review_person_Emergency"].forEach(x => {
                        //       this.employeeEmergency.push(x);
                        //    });
                        // }

                        this.employeeReference = result["data"][0]["person_Reference"];
                        this.person_equalityInfo = result["data"][0]["person_equality"];
                        // if (result["data"][0]["review_person_reference"][0] != null) {
                        //    result["data"][0]["review_person_reference"].forEach(x => {
                        //       this.employeeReference.push(x);
                        //    });
                        // }


                        this.getRelationshipList()
                     })
                  }
               }
            })
         })
      }
   }

   getRelationshipList() {
      if (this.EmpEmeContact.emerrelList == null) {
         this.commonApiService.List(Commonvariable.Relationship).subscribe((result) => {
            if (result != null && result["data"] != "") {
               this.EmpEmeContact.emerrelList = result["data"].sort((a, b) => a.name.localeCompare(b.name));
               this.EmpReference.emprefrelList = result["data"].sort((a, b) => a.name.localeCompare(b.name));
            }
         });
      }
   }


   editRecord(name, eid) {
      if (eid > 0) {
         if (name == 'CandidatePersonEquality') {
            this[name].Edit(eid);
         }
         else {
            this[name].empEdit(eid);
         }

      }
      else {
         this[name].ngOnInit();
      }
   }


   updateEmpInfo(eid) {
      let data = "";
      let tableName = "";
      let modal = ""
      let id = 1;
      let currentUser = JSON.parse(localStorage.getItem('HR_Emp_Id'));
      if (eid == 5) {
         data = this.EmpPersonalInfo.validation() ? this.EmpPersonalInfo.empBasicInfoform.value : ""
         tableName = Commonvariable.Person;
         modal = "personal-info";
         data["id"] = this.employeeBasicInfo.id;
         data["isReview"] = 0
      }
      else if (eid == 6) {
         data = this.EmpAddress.validation() ? this.EmpAddress.empAddressform.value : ""
         tableName = Commonvariable.Person_Address
         modal = "address-info";
         id = data["Id"] == "0" ? 0 : 1
         data["Person_Id"] = this.employeeBasicInfo.id;
         data["isReview"] = 0
      }
      else if (eid == 7) {
         data = this.EmpContact.validation() ? this.EmpContact.empContactform.value : ""
         tableName = Commonvariable.Person_Contact
         modal = "emp-contact";
         id = data["id"] == "0" ? 0 : 1
         data["person_Id"] = this.employeeBasicInfo.id;
         data["isReview"] = 0
      }
      else if (eid == 8) {
         data = this.EmpEmeContact.validation() ? this.EmpEmeContact.empEContactform.value : ""
         tableName = Commonvariable.Person_Emergency
         modal = "emp-eme-contact";
         id = data["id"] == "0" ? 0 : 1
         data["person_Id"] = this.employeeBasicInfo.id;
         data["isReview"] = 0
      }
      else if (eid == 9) {
         data = this.EmpReference.validation() ? this.EmpReference.empform.value : ""
         tableName = Commonvariable.Person_Reference
         modal = "emp-ref";
         id = data["id"] == "0" ? 0 : 1
         data["person_Id"] = this.employeeBasicInfo.id;
         data["isReview"] = 0
      }
      else if (eid == 10) {
         data = this.EmpBank.validation() ? this.EmpBank.empBankform.value : ""
         tableName = Commonvariable.EmpBank
         modal = "emp-bank";
         id = data["emp_Bank_Id"] == "0" ? 0 : 1
         data["person_Id"] = this.employeeBasicInfo.id;
         data["emp_Id"] = currentUser;
         data["isReview"] = 0
         //data["emp_Id"] = this.editid;
      }
      else if (eid == 12) {
         data = this.CandidatePersonEquality.validation() ? this.CandidatePersonEquality.candidatePersonEqualityform.value : ""
         tableName = Commonvariable.Person_Equality
         modal = "equality_info";
         id = data["Id"] == "0" ? 0 : 1;
         data["person_Id"] = this.employeeBasicInfo.id;
         data["isReview"] = 0;
      }


      if (data != "") {
         data["updatedBy"] = Commonvariable.User_Id;
         this.commonApiService.Upsert(tableName, data, id).subscribe((result) => {
            if (result["message"] == Commonvariable.DuplicationMsg) {
               this.toastr.warning('Record is already exists !', 'Employee');
            }
            else {
               const formData = new FormData();
               formData.append('empBasicInfo_Id', this.employeeBasicInfo.id);
               formData.append('file', this.EmpPersonalInfo.empLogo);

               if (this.EmpPersonalInfo.empLogoChange) {
                  this.commonApiService.UploadLogo(Commonvariable.Person, "UploadImage", formData).subscribe((response) => {
                     $("#" + modal).modal('hide');
                     this.toastr.success(id == 1 ? result["message"] : Commonvariable.SaveMsg, 'Employee');
                     this.getSummary();
                  });
               }
               else {
                  $("#" + modal).modal('hide');
                  this.toastr.success('Your request is generated. It will be approval/rejection by HR.', 'Employee');
                  //this.toastr.success(id == 1 ? result["message"] : Commonvariable.SaveMsg, 'Employee');
                  this.getSummary()
               }
            }
         })
      }
   }


   Delete(eid, id) {

      let tableName = "";

      if (eid == 6) {
         tableName = Commonvariable.Person_Address
      }
      else if (eid == 7) {
         tableName = Commonvariable.Person_Contact
      }
      else if (eid == 8) {
         tableName = Commonvariable.Person_Emergency
      }
      else if (eid == 9) {
         tableName = Commonvariable.Person_Reference
      }
      else if (eid == 10) {
         tableName = Commonvariable.EmpBank
      }
      this.commonService.deleteConfirm().then((result) => {
         if (result.value) {
            this.commonApiService.Delete(tableName, id).subscribe((response) => {
               if (response["status"] == 200) {
                  if (response["message"] == 'Delete Successfully') {
                     this.toastr.success(Commonvariable.DeleteMsg, 'Employee');
                  } else {
                     this.toastr.success(Commonvariable.InactiveMsg, 'Employee');
                  }
                  this.getSummary();
               }
            })
         }
      })
   }

   logout() {
      localStorage.clear();
      this.router.navigateByUrl('home');
   }
   updateNiNumber(eid) {
      let nino = (<HTMLInputElement>document.getElementById("NiNo")).value;
      let loan = (<HTMLInputElement>document.getElementById("Loan")).value;
      this.empNiNoDetailform = this.fb.group({
         Emp_Id: [eid],
         NiNo: [nino],
         Loan: [loan],
         updatedBy: [Commonvariable.User_Id],
      });
      let data = this.empNiNoDetailform.value;
      data["person_Id"] = this.employeeBasicInfo.id;
      data["isReview"] = 0;
      this.commonApiService.Upsert(Commonvariable.Employee, data, eid, '/UpdateEmployeeNiNo').subscribe((result) => {
         if (result["status"] == 200) {
            (<HTMLInputElement>document.getElementById("NiNo")).value = this.employeeSummary.niNo;
            this.toastr.success('Your request is generated. It will be approval/rejection by HR.', 'Employee');
            //this.toastr.success(Commonvariable.SaveMsg, 'Employee');
         } else {
            this.toastr.info(result["message"], 'Employee');
         }
      })
      //window.location.reload();
      //this.router.navigate(['userviewsummary']);
      this.getSummary();
   }
}


