import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home/home.component';
import { SignupComponent } from './home/signup/signup.component';
import { AuthGuard } from 'src/app/helper/auth.guard';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'signup', component: SignupComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'employee-dictonary', loadChildren: () => import('./../app/pages/employee-dictonary/employee-dictonary.module').then(m => m.EmployeeDictonaryModule) },
  { path: 'attendance', loadChildren: () => import('./../app/pages/attendance/attendance.module').then(m => m.AccessibilityModule) },
  { path: 'attendance_rule', loadChildren: () => import('./../app/pages/attendance/attendance_rule/attendance_rule.module').then(m => m.AttendanceRuleModule), canActivate: [AuthGuard] },
  { path: 'color-code', loadChildren: () => import('./../app/pages/color-code/color-code.module').then(m => m.ColorCodeModule) },
  { path: 'attendance_report', loadChildren: () => import('./../app/pages/attendance/attendance_report/attendance_report.module').then(m => m.AttendanceReportModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
