import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
import { DomSanitizer } from '@angular/platform-browser';
import { removeSummaryDuplicates } from '@angular/compiler';
declare var $: any;


@Component({
  selector: 'app-masterupload',
  templateUrl: './masterupload.component.html'
})
export class MasterUploadComponent implements OnInit {
  title: string = "Master Upload";
  companylist: Array<Select2OptionData>;
  masterlist: Array<Select2OptionData>;
  companyId: any;
  masterId: any;
  file: any;
  fileUrl: any;
  fileName: any;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder) { }

  ngOnInit() {

    this.getCompanyList()
    this.getMasterList()

  }

  getCompanyList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "company_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }


  getMasterList() {
    this.commonApiService.List("Upload_Master", "GetMaster").subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.masterlist = response["data"].map(x => ({
          id: x.value,
          text: x.name
        }))
      }
    })
  }

  UploadMaster() {

    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('master', this.masterId);
    //formData.append('company_id', this.companyId);

    this.commonApiService.PostRequest("Upload_Master", "Upload", formData).subscribe((result) => {
      this.commonService.toaster("success", "Upload successfully !", this.title)
    })

  }

  exportMaster() {



    this.commonApiService.getById("Upload_Master", this.masterId, "Export").subscribe((result) => 
    {
      this.commonService.toaster("info", result["message"], this.title)
    }, 
    (err) => {
      this.commonApiService.getimage("Upload_Master", this.masterId, "Export").subscribe((resp) => {
        const blobData = new Blob([resp], { type: resp.type });
        var createObjectURL = (window.URL.createObjectURL(blobData));
        this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);

        const a = document.createElement('a');
        a.setAttribute('type', 'hidden');
        a.href = URL.createObjectURL(blobData);
        a.download = this.masterId + '.csv';
        a.click();
        a.remove();
        this.commonService.toaster("success", "Export success !", this.title)
      })
    })

  }

  setFormData(id, value) {
    this[id] = value
  }

  downloadMasterSampleCSVFile() {

    if (this.masterId) {


      this.commonApiService.getById("Upload_Master", this.masterId, "DownloadTemplate").subscribe((result) => {
        this.commonService.toaster("info", result["message"], this.title)
      }, (err) => {
        this.commonApiService.getimage("Upload_Master", this.masterId, "DownloadTemplate").subscribe((resp: any) => {

          const blobData = new Blob([resp], { type: resp.type });

          var createObjectURL = (window.URL.createObjectURL(blobData));
          this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);

          const a = document.createElement('a');
          a.setAttribute('type', 'hidden');
          a.href = URL.createObjectURL(blobData);
          a.download = this.masterId + '.csv';
          a.click();
          a.remove();
          this.commonService.toaster("success", "Download success !", this.title)
        });

      })


    } else {
      this.commonService.toaster("success", "Please select master !", this.title)
    }




  }

  fileChange(event) {
    //event.target.files.item(0).name.split('.')[1] == "xlsx" ||
    if (event.target.files.item(0).name.split('.')[1] == "csv") {
      this.file = event.target.files[0];
    }
    else if (event.target.files.item(0).name.split('.')[1] == "xlsx") {
      this.file = event.target.files[0];
    }
    else if (event.target.files.item(0).name.split('.')[1] == "xls") {
      this.file = event.target.files[0];
    }
    else {
      this.commonService.toaster("info", "Allow only csv, xlsx and xls  file format for upload !", this.title)
      $("#remove").click();
    }
  }
}

