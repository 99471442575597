import { Component, OnInit, } from '@angular/core';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { Router} from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
declare var $: any;

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html'  
})
export class TimeoutComponent implements OnInit {

  constructor(
    private commonApiService: CommonApiService,    
    private router: Router, private loginService: LoginService) { }

  ngOnInit() {
    // $("body").css("background-color","#6672b2");
    // $("app-left-menu,app-header,app-footer").hide();
  }

  logOut() {
    
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigateByUrl('home');
    // this.loginService.logout(localStorage.getItem("user_Id")).subscribe((result) => {
    //     localStorage.clear();
    //     this.router.navigateByUrl('home');
    // },(error)=>{
    //   localStorage.clear();this.router.navigateByUrl('home');
    // });
  }

}
