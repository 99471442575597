import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { InterviewComponent } from "../interview/interview.component";
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { async } from '@angular/core/testing';
declare var $: any;

@Component({
   selector: 'app-interviewprocess',
   templateUrl: './interviewprocess.component.html'
})
export class InterviewProcessComponent implements OnInit {
   @ViewChild(InterviewComponent, { static: false }) Interview: InterviewComponent;
   isFinal: boolean = false;
   companyList: any[];
   siteList: any[];
   departmentList: any[];
   rafList: any[];
   p: number = 1
   shortListedCandidateList: any[];
   filterShortListedCandidateList: any[];
   candidate_Id = 0;
   vacancy_Id = 0;
   isDisabled: boolean = true;
   fileUrl;



   constructor(private commonApiService: CommonApiService,
      private commonService: CommonService,
      private sanitizer: DomSanitizer,
      private router: Router,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {

      this.getCompanyList();

   }

   async getCompanyList() {
      this.companyList = [];
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": 'company_Name'
      }
      await this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            this.companyList = response["data"];
         }
      })
   }


   async getSiteList(id) {
      this.siteList = [];
      this.rafList = [];
      this.shortListedCandidateList = [];
      this.Interview.empList = [];
      this.isDisabled = true;

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "company_Id",
               "FieldValue": id,
               "Parameter": "=="
            }
         ]
      }
      if (id > 0) {
         await this.commonApiService.GetByPaginated(Commonvariable.Site, requestData).subscribe((response) => {
            if (response != null && response["data"] != "") {
               this.siteList = response["data"];
            }
            this.commonApiService.GetByPaginated(Commonvariable.EmployeeDetail, requestData).subscribe((response) => {
               if (response != null && response["data"] != "") {
                  this.Interview.empList = response["data"];
               }
            })

         })
      }
   }

   async getDepartmentList(id) {
      this.siteList = [];
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "site_Id",
               "FieldValue": id,
               "Parameter": "=="
            }
         ],
         "OrderBy": 'dept_Name'
      }
      await this.commonApiService.GetByPaginated(Commonvariable.Department, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            this.departmentList = response["data"];
         }
      })
   }

   async getRafList(id) {
      this.rafList = [];
      this.shortListedCandidateList = [];
      let postRequest = {
         "searchBy": [
            {
               "FieldName": "site_Id",
               "FieldValue": id,
               "Parameter": "=="
            }
         ],
         "PageSize": 1000
      }

      if (id > 0) {
         await this.commonApiService.getPaginated(Commonvariable.VacancyService, null, null, null, null, 0, postRequest, 'RAFWiseGetPaginated').subscribe((result) => {
            if (result != null && result["data"] != "") {
               this.rafList = result["data"]["list"];
            }
         })
      }
   }

   async getCandidate(companyId, rafid, candidate_Id = 0) {

      this.shortListedCandidateList = [];
      this.candidate_Id = candidate_Id
      if (candidate_Id == 0) {
         this.isDisabled = true
         this.Interview.interview = [];
         this.Interview.createItem();
         $("#interviewerResponse").css("display", "none")
      }
      let postRequest = {
         "searchBy": [
            {
               "FieldName": "Vacancy_Id",
               "FieldValue": rafid,
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "status",
               "FieldValue": JSON.stringify($("#interviewStatus").val()),
               "Parameter": $("#interviewStatus").val() == "All" ? "!=" : "=="
            }
         ]
      }
      if (rafid > 0) {
         await this.commonApiService.getPaginated(Commonvariable.CandidateService, null, null, null, null, 0, postRequest, "GetInterviewers").subscribe((result) => {
            if (result != null && result["data"] != "") {
               this.shortListedCandidateList = result["data"]["list"];
               this.shortListedCandidateList.forEach(x => {
                  x.job_Category = x.job_Category != null ? x.job_Category.replace(/[ ~]+/g, " , ") : x.job_Category
                  x.skills = x.skills != null ? x.skills.replace(/[ ~]+/g, " , ") : x.skills
                  x.job_Level = x.job_Level != null ? x.job_Level.replace(/[ ~]+/g, " , ") : x.job_Level
               });
               this.filterShortListedCandidateList = this.shortListedCandidateList

            }
         })
      }

      setTimeout(() => {
         $('[data-toggle="tooltip"]').tooltip();
      }, 500);

   }

   async getInterviews(id, vacancyid, status) {
      this.candidate_Id = id
      this.isFinal = false
      this.isDisabled = false;
      this.vacancy_Id = vacancyid
      $("#interviewerResponse").css("display", "")
      $("#btnAdd").css("display", "")
      if (id > 0) {
         await this.commonApiService.getById(Commonvariable.Candidate_Interviewer, id + "," + vacancyid, "GetInterviewSchedule").subscribe((result) => {
            if (result != null && result["data"] != "") {
               this.Interview.fillInterviewer(result["data"])
            }
            else {
               this.Interview.interview = [];
               this.Interview.createItem();
            }
         })
      }

      if (status == "Selected" || status == "Offered" || status == "Rejected" || status == "Accepted" || status == "Decline") {
         $("#interviewerResponse").css("display", "none")
         $("#btnAdd").css("display", "none")
      }
   }


   async UpsertInterviewDetail(status) {
      var requestData = this.Interview.upsert(this.candidate_Id, this.vacancy_Id, status)
      if (requestData != null) {
         await this.commonApiService.Upsert("Candidate_Interviewer", requestData, this.candidate_Id, '/EditMultiple').subscribe((result) => {
            if (result != null) {


               this.getInterviews(this.candidate_Id, this.vacancy_Id, status)

               if (status != "Shortlisted") {
                  $("#interviewerResponse").css("display", "none")
                  $("#btnAdd").css("display", "none")
               }
               this.isFinal = false
               this.getCandidate(0, this.vacancy_Id, this.candidate_Id)
               this.commonService.toaster("success", Commonvariable.SaveMsg, "Interview")
            }
         })
      }
   }

   async UpdateStatus(status, candidate_Id = 0, vacancy_Id = 0) {

      this.candidate_Id = candidate_Id != 0 ? candidate_Id : this.candidate_Id;
      this.vacancy_Id = vacancy_Id != 0 ? vacancy_Id : this.vacancy_Id;

      if (status == "Offered") {
         this.commonApiService.getById(Commonvariable.VacancyService, vacancy_Id, "OpenVacancyCount").subscribe((result) => {
            if (result["data"] > 0) {
               this.commonService.dyanmicConfirm("You Want To Change Status Of Candidate !", "Yes !").then((result) => {
                  if (result.value) {
                     let ids = this.candidate_Id + "," + this.vacancy_Id + "," + status
                     this.commonApiService.Put(Commonvariable.Candidate_Vacancy_Link, ids, "ChangeStatus").subscribe((response) => {
                        if (response != null) {
                           this.isFinal = false
                           this.getCandidate(0, this.vacancy_Id)
                           this.commonService.toaster("success", response["message"], "Interview")
                        }
                     })
                  }
               })
            }
            else {
               this.commonService.toaster("info", "All open vacancies have been filled !", "Interview")
            }
         })
      }
      else {
         this.commonService.dyanmicConfirm("You Want To Change Status Of Candidate !", "Yes !").then((result) => {
            if (result.value) {
               let ids = this.candidate_Id + "," + this.vacancy_Id + "," + status
               this.commonApiService.Put(Commonvariable.Candidate_Vacancy_Link, ids, "ChangeStatus").subscribe((response) => {
                  if (response != null) {
                     this.isFinal = false
                     this.getCandidate(0, this.vacancy_Id)
                     this.commonService.toaster("success", response["message"], "Interview")
                  }
               })
            }
         })
      }
   }

   async moveToOnboarding(candidate_Id, vacancy_Id) {

      if (candidate_Id > 0) {
         this.commonService.dyanmicConfirm("You Want This Candidate Move To Onboarding !", "Yes !").then((result) => {
            if (result.value) {
               this.commonApiService.PostRequest(Commonvariable.CandidateService, "MoveToOnboarding/" + candidate_Id + "," + vacancy_Id, {}).subscribe((response) => {
                  if (response != null) {
                     this.isFinal = !this.isFinal
                     this.getCandidate(0, vacancy_Id)
                     this.Interview.interview = [];
                     this.Interview.createItem();
                     this.commonService.toaster("success", response["message"], "Interview")
                  }
               })
            }
         })
      }

   }



   async filterData(event) {
      const val = event.target.value.toLowerCase().trim();
      if (val == "") {
         this.shortListedCandidateList = this.filterShortListedCandidateList;
      }
      else {
         const temp = this.filterShortListedCandidateList.filter(function (d) {
            return String(d["firstName"]).toLowerCase().indexOf(val) !== -1 || String(d["lastName"]).toLowerCase().indexOf(val) !== -1
               || String(d["job_Category"]).toLowerCase().indexOf(val) !== -1 || String(d["job_Level"]).toLowerCase().indexOf(val) !== -1
               || String(d["experience_Year"]).toLowerCase().indexOf(val) !== -1 || String(d["experience_Month"]).toLowerCase().indexOf(val) !== -1
               || !val;
         });
         this.shortListedCandidateList = temp;
      }
      this.p = 1;
   }


   async downloadCV(id) {
      this.commonApiService.getimage(Commonvariable.JobSeeker, id, 'DownloadCV').subscribe((result) => {
         const blobData = new Blob([result], { type: 'application/octet-stream' });
         this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blobData));
         setTimeout(() => {
            $("#download")[0].click();
         }, 500);
      })
   }   
}
