<!-- <form>
    <div class="form-group">
        <label class="control-label col-md-4">&nbsp;</label>
        <div class="col-md-2">
            <input type="text" class="form-control" placeholder="Address Find By Postal Code" />
        </div>
        <div class="col-md-3">
            <button class="btn btn-primary findLocationBtn">Find Address</button>
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-md-2">Address Type</label>
        <div class="col-md-3">
            <select id="company_list" class="form-control">
                <option value="">Select Address type</option>
                <option value="AF">Home</option>
                <option value="AL">Office</option>
            </select>
        </div>
        <label class="control-label col-md-2">Select Address</label>
        <div class="col-md-3">
            <select id="company_list" class="form-control">
                <option value="">Select Your Address</option>
                <option value="AF">B&SGroup Distribution Ltd.</option>
                <option value="AL">Gowrie Healthcare Pvt. Ltd.</option>
            </select>
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-md-2">Address1
        </label>
        <div class="col-md-3">
            <input type="text" class="form-control" />
        </div>
        <label class="control-label col-md-2">Address2
        </label>
        <div class="col-md-3">
            <input type="text" class="form-control" />
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-md-2">Address3
        </label>
        <div class="col-md-3">
            <input type="text" class="form-control" />
        </div>
        <label class="control-label col-md-2">Address4
        </label>
        <div class="col-md-3">
            <input type="text" class="form-control" />
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-md-2">City
        </label>
        <div class="col-md-3">
            <input type="text" class="form-control" />
        </div>
        <label class="control-label col-md-2">County
        </label>
        <div class="col-md-3">
            <input type="text" class="form-control" />
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-md-2">Country</label>
        <div class="col-md-3">
            <select id="country_list" class="form-control">
                <option value="">Select Country</option>
                <option value="AF">Afghanistan</option>
                <option value="AL">Albania</option>
            </select>
        </div>
        <label class="control-label col-md-2">Land Line No
        </label>
        <div class="col-md-3">
            <input type="text" class="form-control" />
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-md-2">Post Code
        </label>
        <div class="col-md-3">
            <input type="text" class="form-control" />
        </div>
    </div>
</form> -->

<form [formGroup]="empform">

    <div>
        <button *ngIf="this.empform.get('emp_address').value[0].Address1 != ''" type="button" (click)="addItem(null)"
            class="btn pull-right"><i class="fa fa-plus" aria-hidden="true"></i></button>
    </div>
    <br />
    <div class="accordion" formArrayName="emp_address"
        *ngFor="let item of empform.get('emp_address')['controls']; let i = index;">
        <div class="card" [formGroupName]="i">
            <hr>
            <div class="card-header">
                <h2 class="mb-0">
                    <button class="btn btn-link" type="button" data-toggle="collapse"
                        [attr.data-target]="'#collapse' + i">
                        Address Details {{i+1}}
                    </button>
                    <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                            class="fa fa-minus" aria-hidden="true"></i></button>
                </h2>
            </div>
            <div id="collapse{{i}}" class="collapse in">
                <div class="card-body">
                    <div class="row">
                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label col-md-4">Address Type</label>
                                <div class="col-md-8">
                                    <select id="Address_Type{{i}}" class="form-control" formControlName="Address_Type">
                                        <option value="">Select address type</option>
                                        <option value="Permenant">Permenant</option>
                                        <option value="Temporary">Temporary</option>
                                    </select>

                                </div>
                            </div>
                        </div> -->
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label col-md-4">Address 1
                                    <span class="required"
                                        *ngIf="this.empform.get('emp_address').value[0].Address1 != ''"> * </span>
                                </label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" id="Address1{{i}}"
                                        formControlName="Address1" autocomplete="off" maxlength="100"
                                        ngx-google-places-autocomplete [options]='options'
                                        (onAddressChange)="AddressChange($event,i)">

                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label col-md-4">Address 2
                                    <span class="required"
                                        *ngIf="this.empform.get('emp_address').value[0].Address1 != ''"> * </span>
                                </label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" id="Address2{{i}}"
                                        formControlName="Address2" autocomplete="off" maxlength="100">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label col-md-4">Address 3</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" id="Address3{{i}}"
                                        formControlName="Address3" autocomplete="off" maxlength="100">
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label col-md-4">Address 4</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" id="Address4{{i}}"
                                        formControlName="Address4" autocomplete="off" maxlength="100">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label col-md-4">City
                                    <span class="required"
                                        *ngIf="this.empform.get('emp_address').value[0].Address1 != ''"> * </span>
                                </label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" id="City{{i}}" formControlName="City"
                                        autocomplete="off" maxlength="50">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label col-md-4">County

                                </label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" id="State{{i}}" formControlName="State"
                                        autocomplete="off" maxlength="50">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label col-md-4">Country
                                    <span class="required"
                                        *ngIf="this.empform.get('emp_address').value[0].Address1 != ''"> * </span>
                                </label>
                                <div class="col-md-8">
                                    <ng-select2 [data]="countrylist" id="country_Id{{i}}"
                                        formControlName="country_Id" [placeholder]="'--- Select ---'" autocomplete="off">
                                    </ng-select2>                                   
                                    
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label col-md-4">Post Code
                                    <span class="required"
                                        *ngIf="this.empform.get('emp_address').value[0].Address1 != ''"> * </span>
                                </label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" id="PostCode{{i}}"
                                        formControlName="PostCode" autocomplete="off" maxlength="10">
                                    <!-- <div *ngIf="PostCode.errors"> 
                                            <div *ngIf="PostCode.errors.required">
                                                User name required.
                                            </div>  
                                            <div *ngIf="PostCode.errors.pattern">
                                                User name not valid.
                                            </div> 
                                        </div> -->
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label col-md-4">Default</label>
                                <div class="col-md-2">
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="isDefault{{i}}" class="md-check"
                                                formControlName="isDefault">
                                            <label for="isDefault{{i}}">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div>
                                </div>
                                <label class="control-label col-md-2">Active</label>
                                <div class="col-md-2">
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="isActive{{i}}" class="md-check"
                                                formControlName="isActive">
                                            <label for="isActive{{i}}">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- <div class="form-group">
        <div class="table-resposive">
            <table class="table table-hover" id="work">
                <thead>
                    <tr>
                        <th style="width: 5%;">Address Type</th>
                        <th style="width: 5%;">LandlineNo</th>
                        <th style="width: 5%;">Address1</th>
                        <th style="width: 5%;">Address2</th>
                        <th style="width: 5%;">Address3</th>
                        <th style="width: 5%;">Address4</th>
                        <th style="width: 5%;">Post Code</th>
                        <th style="width: 5%;">City</th>
                        <th style="width: 5%;">County</th>
                        <th style="width: 5%;">Country</th>
                        <th style="width: 2%;">Default</th>
                        <th style="width: 2%;">Active</th>
                        <th style="width: 5%;"><button type="button" (click)="addItem(null)" class="btn pull-right"><i
                                    class="fa fa-plus" aria-hidden="true"></i></button></th>
                    </tr>
                </thead>
                <tbody formArrayName="emp_address"
                    *ngFor="let item of empform.get('emp_address')['controls']; let i = index;">
                    <tr [formGroupName]="i">
                        <td>
                            <input type="text" class="form-control" id="Address_Type{{i}}"
                                formControlName="Address_Type" autocomplete="off" maxlength="100">
                        </td>
                        <td>
                            <input type="text" class="form-control" id="LandlineNo{{i}}" formControlName="LandlineNo"
                                autocomplete="off" maxlength="100">
                        </td>
                        <td>
                            <input type="text" class="form-control" id="Address1{{i}}" formControlName="Address1"
                                autocomplete="off" maxlength="100">
                        </td>
                        <td>
                            <input type="text" class="form-control" id="Address2{{i}}" formControlName="Address2"
                                autocomplete="off" maxlength="100">
                        </td>
                        <td>
                            <input type="text" class="form-control" id="Address3{{i}}" formControlName="Address3"
                                autocomplete="off" maxlength="100">
                        </td>
                        <td>
                            <input type="text" class="form-control" id="Address4{{i}}" formControlName="Address4"
                                autocomplete="off" maxlength="100">
                        </td>
                        <td>
                            <input type="text" class="form-control" id="PostCode{{i}}" formControlName="PostCode"
                                autocomplete="off" maxlength="100">
                        </td>
                        <td>
                            <input type="text" class="form-control" id="City{{i}}" formControlName="City"
                                autocomplete="off" maxlength="100">
                        </td>
                        <td>
                            <input type="text" class="form-control" id="State{{i}}" formControlName="State"
                                autocomplete="off" maxlength="100">
                        </td>
                        <td>
                            <input type="text" class="form-control" id="Country{{i}}" formControlName="Country"
                                autocomplete="off" maxlength="100">
                        </td>
                        <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="isDefault{{i}}" class="md-check"
                                        formControlName="isDefault">
                                    <label for="isDefault{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="isActive{{i}}" class="md-check"
                                        formControlName="isActive">
                                    <label for="isActive{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td>
                        <td>
                            <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                                    class="fa fa-minus" aria-hidden="true"></i></button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div> -->
</form>