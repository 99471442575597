import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;
import * as moment from 'moment';


@Component({
  selector: 'app-contractcandidate',
  templateUrl: './contract-candidate.component.html'
})
export class ContractCandidateComponent implements OnInit {
  candidateForm: FormGroup;
  contractlist: any;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {

    this.candidateForm = this.fb.group({
      id: ['0'],
      contract_Id: [''],
      onboarding_Id: ['0'],
      contract_Code: ['000', Validators.required],
      contract_Name: ['', Validators.required],
      contract_HoursDaily: [null],
      contract_HoursWeekly: [null],
      contract_Days: [''],
      contract_Type: [null],
      contract_Start: ['', Validators.required],
      contract_End: [''],
      doc_Id: [null],
      isRequired: [null],
      notes: [null],
      version_Id: [null],
      isActive: ['1'],
      updatedBy: [Commonvariable.User_Id]
    });
   
    this.commonService.flatpickr("flatpickropencontract_SD", 0);
    this.commonService.flatpickr("flatpickropencontract_ED", 0);
  }



  getContractList(id) {
    this.contractlist = [];
console.log("contract");
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "company_Id",
          "FieldValue": id,
          "Parameter": "=="
        }
      ],
      "OrderBy": "contract_Name"
    }

    this.commonApiService.GetByPaginated(Commonvariable.Contract, requestData).subscribe((response) => {
      if (response["data"] != "") {
        //this.contractlist = response["data"];
        this.contractlist = response["data"].map(x => ({
          id: x.contract_Id,
          text: x.contract_Name + ' / ' + x.contract_Type
        }));
      }
    })
  }

  // getContractList(id) {
  //   this.commonApiService.cascadingDropDwon(Commonvariable.Contract, "company_Id", id).subscribe((response) => {
  //     if (response != null && response["data"] != "") {
  //       this.contractlist = response["data"].sort((a, b) => a.contract_Name.localeCompare(b.contract_Name));
  //     }
  //   })
  // }

  onChangeDate() {
    var contract_Days = $("#contract_Days").val();
    if (contract_Days != 0 && contract_Days != "") {
      var selectedStartDate = moment($("#contract_Start").val(), 'DD-MM-YYYY').format("DD-MM-YYYY");
      var endDate = moment(selectedStartDate, 'DD-MM-YYYY').add(contract_Days, 'days').format("DD-MM-YYYY");
      //$("#contract_End").flatpickr({ dateFormat: "d-m-Y", defaultDate: endDate });
      if ($("#contract_Start").val() != "") {
        $("#contract_End").val(endDate);
      }
    }
  }

  onChangeDays(days): void {
    var contract_Days = days;
    var selectedStartDate = moment($("#contract_Start").val(), 'DD-MM-YYYY').format("DD-MM-YYYY");
    if (contract_Days != 0 && contract_Days != "" && selectedStartDate != "") {
      var endDate = moment(selectedStartDate, 'DD-MM-YYYY').add(contract_Days, 'days').format("DD-MM-YYYY");
      //$("#contract_End").flatpickr({ dateFormat: "d-m-Y", defaultDate: endDate });
      if ($("#contract_Start").val() != "") {
        $("#contract_End").val(endDate);
      }
    }
  }

  Edit(Id) {
    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.Onboarding_Contract, Id).subscribe((response) => {
        if (response != null && response["data"] != "") {
          var editData = response["data"];
          let startDate = response["data"][0].contract_Start != null ? response["data"][0].contract_Start.split("T")[0].split("-") : "";
          let endRDate = response["data"][0].contract_End != null ? response["data"][0].contract_End.split("T")[0].split("-") : "";
          if (startDate != "") {
            //$("#contract_Start").flatpickr({ dateFormat: "d-m-Y", defaultDate: startDate[2] + "-" + startDate[1] + "-" + startDate[0] });
            $("#contract_Start").val(startDate[2] + "-" + startDate[1] + "-" + startDate[0]);
          }
          if (endRDate != "") {
            //$("#contract_End").flatpickr({ dateFormat: "d-m-Y", defaultDate: endRDate[2] + "-" + endRDate[1] + "-" + endRDate[0] });
            $("#contract_End").val(endRDate[2] + "-" + endRDate[1] + "-" + endRDate[0]);
          }
          var formData = this.candidateForm;
          this.commonService.setEditData(formData, editData);
          formData.controls.updatedBy.setValue(Commonvariable.User_Id);
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Candidate'); })
    }
  }


  validation() {
    var eForm = this.candidateForm;
    //sForm.get("company_Id").setValue($("#company_Id").val()); 
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);
    eForm.controls.contract_Start.setValue(this.commonService.dateFormatChange($("#contract_Start").val()));
    eForm.controls.contract_End.setValue(this.commonService.dateFormatChange($("#contract_End").val()));
    eForm.controls.contract_Name.setValue($("#contract_Id option:selected").text().trim());

    if ($("#contract_Id option:selected").text().trim() == "Select Contract") {
      this.toastr.info("Contract name is required !", "Candidate");
      $("#contract_Id").focus();
      return false;
    }

    if (!eForm.valid) {
      for (const key of Object.keys(eForm.controls)) {
        if (eForm.controls[key].invalid) {
          let fieldName = "";

          if (key == "contract_Id") {
            fieldName = "Contract"
          }
          else if (key == "contract_Name") {
            fieldName = "Contract type"
          }
          else if (key == "contract_Days") {
            fieldName = "Length of Contract"
          }
          else if (key == "contract_Start") {
            fieldName = "Employee contract start date"
          }
          // else if (key == "contract_End") {
          //   fieldName = "Employee contract end date"
          // }

          if (fieldName != "") {
            this.toastr.info(fieldName + " is required !", "Candidate");
            $("#" + key).focus();
            return false;
          }

        }
      }
    }
    else {
      // if ($("#contract_Code").val().length < 2) {
      //   this.toastr.info("Contract code must be at least 2 characters long !", "Candidate");
      //   $("#contract_Code").focus();
      //   return false;
      // }
      // if ($("#contract_Code").val().indexOf(" ") > -1) {
      //   this.toastr.info("Contract code not allow space !", "Candidate");
      //   $("#contract_Code").focus();
      //   return false;
      // }

      // if ($("#contract_Days").val().length < 2 && $("#contract_Days").val().length !=0) {
      //   this.toastr.info("Length of Contract must be at least 2 characters long !", "Candidate");
      //   $("#contract_Days").focus();
      //   return false;
      // }
      // if ($("#contract_Days").val().indexOf(" ") > -1) {
      //   this.toastr.info("Length of Contract not allow space !", "Candidate");
      //   $("#contract_Days").focus();
      //   return false;
      // }
      // if ($("#contract_Days").val() != "" && $("#contract_Days").val().search(/[^0-9]+/) > -1) {
      //   this.toastr.info("Length of Contract only numeric allow !", "Candidate");
      //   $("#contract_Days").focus();
      //   return false;
      // }
      
      // if (moment($("#contract_Start").val(), 'DD-MM-YYYY') > moment($("#contract_End").val(), 'DD-MM-YYYY')) {
      //   this.toastr.info("Contract start date not more then contract end date !", "Candidate");
      //   $("#contract_Start").focus();
      //   return false;
      // }

    }


    return true;
  }




}
