<!-- <div class="progress">
    <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="40" aria-valuemin="0"
        aria-valuemax="100" style="width:40%">
        40%
    </div>
</div> -->
<div id="accordion1" class="panel-group">

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_3"> 1. Job Details </a>
            </h4>
        </div>
        <div id="accordion1_3" class="panel-collapse collapse in">
            <div class="panel-body">


                <div class="row" *ngIf="candidateSummary != null">
                    <div class="form-group">

                        <label class="control-label col-md-2">Company</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.company_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Site</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.site_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Department</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.dept_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Zone</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.zone_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Job Title</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.desig_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Job Description</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.jD_Name}}" readonly />
                                <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="download()"
                                title="Download Job Description"><i class="fa fa-download fa-1x"></i></a>
                        </div>

                        <!-- <label class="control-label col-md-2">Shift Time</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.shift_Name}} {{this.candidateSummary.shift_Time}}"
                                readonly />
                        </div> -->

                        <label class="control-label col-md-2">Shift Start</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" id="shift_Start" value="{{this.candidateSummary.shift_Start}}" readonly>
                        </div>
                        <label class="control-label col-md-2">Shift End</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" id="shift_End" value="{{this.candidateSummary.shift_End}}" readonly>
                        </div>
<!-- 
                        <label class="control-label col-md-2">Night Shift</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                *ngIf="candidateSummary.nightShift != 1" value="No" readonly />
                            <input type="text" class="form-control readonlyClass"
                                *ngIf="candidateSummary.nightShift == 1" value="Yes" readonly />

                            <label class="control-label" *ngIf="employeeSummary.tupe == 0">No</label>
                        <label class="control-label" *ngIf="employeeSummary.tupe == 1">Yes</label> 
                        </div> -->

                        <label class="control-label col-md-2">Shift Variable</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                *ngIf="candidateSummary.shift_Variable != 1" value="No" readonly />
                            <input type="text" class="form-control readonlyClass"
                                *ngIf="candidateSummary.shift_Variable == 1" value="Yes" readonly />

                            <!-- <label class="control-label" *ngIf="employeeSummary.tupe == 0">No</label>
                        <label class="control-label" *ngIf="employeeSummary.tupe == 1">Yes</label> -->
                        </div>

                        <label class="control-label col-md-2">Email</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.eMail}}" readonly />
                        </div>

                        <!-- <label class="control-label col-md-2">Total Experience</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.totalExperience}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Reference From</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.referenceFrom}}" readonly />
                        </div> -->

                        <label class="control-label col-md-2">Planned Start Date</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.joiningDate | date:'dd/MM/yyyy'}}" readonly />
                        </div>

                        <label class="control-label col-md-2">RAF</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.vacancy_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Reporting To</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.reporting_To}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Employee Number</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.emp_Code}}" readonly />
                        </div>


                    </div>
                </div>


            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_9"> 2. Contract </a>
            </h4>
        </div>
        <div id="accordion1_9" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center"> Contract Type</th>
                                <th class="text-center"> Length of Contract</th>
                                <th class="text-center"> Contract Start</th>
                                <th class="text-center"> Contract End</th>

                            </tr>
                        </thead>
                        <tbody *ngFor="let item of candidateContract">
                            <tr>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.emp_Contract_Name}}">
                                        {{item.contract_Name}}
                                    </div>
                                </td>
                                <td>{{item.contract_Days}}</td>
                                <td class="text-center">{{item.contract_Start | date :'dd/MM/yyyy'}}</td>
                                <td class="text-center">{{item.contract_End | date :'dd/MM/yyyy'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_8"> 3. Probation </a>
            </h4>
        </div>
        <div id="accordion1_8" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>

                                <th class="text-center">Probation Period</th>
                                <th class="text-center">Probation End Date</th>
                                <th class="text-center" class="text-center">Next Review Date</th>
                                <th class="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of candidateProbation">
                            <tr>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.probationPeriod}}">
                                        {{item.probationPeriod}}
                                    </div>
                                </td>
                                <td class="text-center">{{item.probationEndDate |date:'dd/MM/yyyy'}}</td>
                                <td class="text-center">{{item.nextReviewDate | date :'dd/MM/yyyy'}}</td>
                                <td>{{item.status}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_11"> 4. Salary </a>
            </h4>
        </div>
        <div id="accordion1_11" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Salary</th>
                                <th class="text-center">Salary Type</th>
                                <!-- <th class="text-center">Apprisal From</th>
                                <th class="text-center">Apprisal To</th> -->
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of candidateSalary">
                            <tr>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.salary}}">{{item.salary}}
                                    </div>
                                </td>
                                <!-- <td class="text-center">{{item.apprisalFrom | date :'dd/MM/yyyy'}}</td>
                                <td class="text-center">{{item.apprisalTo | date :'dd/MM/yyyy'}}</td> -->
                                <td class="text-center">{{item.salaryType}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_1"> 5. Personal Details </a>

            </h4>
        </div>
        <div id="accordion1_1" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="row" *ngIf="candidateBasicInfo != null">
                    <div class="form-group">
                        <label class="control-label col-md-2">Title</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateBasicInfo.title}}" readonly />
                        </div>
                        <label class="control-label col-md-2">Gender</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateBasicInfo.gender_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">First Name</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateBasicInfo.firstName}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Middle Name</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateBasicInfo.middleName}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Last Name</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateBasicInfo.lastName}}" readonly />
                        </div>

                        <!-- <label class="control-label col-md-2">Mobile</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateSummary.mobile}}" readonly />
                        </div> -->

                        <label class="control-label col-md-2">Date Of Birth </label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateBasicInfo.dob | date:'dd/MM/yyyy'}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Ethnicity</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateBasicInfo.ethnicity_Name}}" readonly />
                        </div>
                        <!-- <label class="control-label col-md-2">Nationality</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateBasicInfo.nationality_Name}}" readonly />
                        </div> -->
                        <label class="control-label col-md-2">Language</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.candidateBasicInfo.languages_Name}}" readonly />
                            <!-- <ng-select2 [data]="this.candidateBasicInfo.languages_Name"
                                [options]="{multiple: true,tags: false}" [value]='this.candidateBasicInfo.languages_Name'
                                [placeholder]="" [disabled]="true">
                            </ng-select2> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_2"> 6. Address Details </a>
            </h4>
        </div>
        <div id="accordion1_2" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <!-- <th class="text-center">Address Type</th> -->
                                <th class="text-center">Address</th>

                                <th style="width: 10%;" class="text-center">City</th>
                                <th style="width: 10%;" class="text-center">County</th>

                                <th style="width: 10%;" class="text-center">Country</th>
                                <th class="text-center" style="width: 10%;">Post Code </th>
                                <th class="text-center" style="width: 5%;">Default</th>
                                <th class="text-center" style="width: 5%;">Active</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of candidateAddress">
                            <tr>
                                <!-- <td>{{item.address_Type}}</td> -->
                                <td>{{item.address1}}, {{item.address2}}</td>

                                <td>{{item.city}}</td>
                                <td>{{item.state}}</td>

                                <td>{{item.country_Name}}</td>
                                <td>{{item.postCode}}</td>
                                <td>
                                    <div class="text-center">
                                        <div class="circle greenColor" *ngIf="item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Default">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Indefault">
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="text-center">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive">
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_4"> 7. Personal Contact Details </a>
            </h4>
        </div>
        <div id="accordion1_4" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Contact Type</th>
                                <th class="text-center">Contact Details</th>
                                <th class="text-center" style="width: 2%;">Default</th>
                                <th class="text-center" style="width: 2%;">Active</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of candidateContact">
                            <tr>
                                <td>{{item.contact_Type}}</td>
                                <td>{{item.contact_Value}}</td>
                                <td>
                                    <div>
                                        <div class="circle greenColor" *ngIf="item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Default">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Indefault">
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="text-center">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive">
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_5"> 8. Emergency Contact </a>
            </h4>
        </div>
        <div id="accordion1_5" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Emergency Contact Name</th>
                                <th class="text-center">Emergency Contact Number</th>
                                <th class="text-center">Relationship</th>
                                <th class="text-center" style="width: 2%;">Default</th>
                                <th class="text-center" style="width: 2%;">Active</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of candidatEmergency">
                            <tr>
                                <td>{{item.contactName}}</td>
                                <td>{{item.contactNo}}</td>
                                <td>{{item.relationship_Name}}</td>
                                <td>
                                    <div class="text-center">
                                        <div class="circle greenColor" *ngIf="item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Default">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Indefault">
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="text-center">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive">
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_6"> 9. Work Reference </a>
            </h4>
        </div>
        <div id="accordion1_6" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Reference Name</th>
                                <th class="text-center">Reference Email Address</th>
                                <th class="text-center">Reference Contact Number</th>
                                <th class="text-center">Reference Relationship</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of candidateReference">
                            <tr>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.ref_Name}}">{{item.ref_Name}}
                                    </div>
                                </td>
                                <td>{{item.ref_Email}}</td>
                                <td>{{item.ref_ContactNo}}</td>
                                <td>{{item.relationship}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_7"> 10. Bank Details </a>
            </h4>
        </div>
        <div id="accordion1_7" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Bank Name</th>
                                <th class="text-center">Account Holder</th>
                                <!-- <th class="text-center">Account Type</th> -->
                                <th class="text-center">Account No.</th>
                                <th class="text-center">Sort Code</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of candidateBank">
                            <tr>
                                <td>{{item.bank_Name}}</td>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.account_Holder}}">{{item.account_Holder}}
                                    </div>
                                </td>
                                <!-- <td>{{item.account_Type}}</td> -->
                                <td>{{item.account_No}}</td>
                                <td>{{item.bank_Code}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_10"> 11. Right To Work </a>
            </h4>
        </div>
        <div id="accordion1_10" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Date of Issue</th>
                                <th class="text-center">Authority</th>
                                <th class="text-center">Country of Issue</th>
                                <th class="text-center">Date of Expiry</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of candidateRighttowork">
                            <tr>
                                <td>{{item.issueDate | date :'dd/MM/yyyy'}}</td>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.issueAuthority}}">{{item.issueAuthority}}
                                    </div>
                                </td>
                                <td>{{item.issueCountry}}</td>
                                <td class="text-center">{{item.expiryDate | date :'dd/MM/yyyy'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_12"> 12. Onboarding Document</a>
            </h4>
        </div>
        <div id="accordion1_12" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Upload File Name</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of candidatedocument">
                            <tr *ngIf="item.is_CompanyDoc == 1">
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.file_Name}}">{{item.file_Name}}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#accordion1_13"> 13. Additional Document </a>
            </h4>
        </div>
        <div id="accordion1_13" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Document Name</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of candidatedocument">
                            <tr *ngIf="item.is_CompanyDoc != 1">
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.doc_Name}}">{{item.doc_Name}}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!--- the html code written by Brajeswari -->
    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle popoverTitle accordion-toggle-styled" data-toggle="collapse"
                    data-parent="#accordion1" href="#accordion1_14">
                    14. Equality and Diversity Monitoring
                </a>
                <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-container="body"
                                        data-placement="top" data-html="true" id="equality">
                                        <span class="glyphicon glyphicon-info-sign"></span>
                                    </span>
                                    <div id="popover-content-equality" class="hide">
                                        The information requested in this form is required for us to monitor
                                        our Equality and Diversity Policy and we encourage you complete
                                        this form in full to assist us with this. The information provided is to
                                        be used solely for monitoring and to comply with our legal
                                        obligations in relation to the Equality Act 2010. Any information
                                        provided in this form will be treated in the strictest confidence.
                                        The data provided will be processed and held for the specified
                                        purposes and in accordance with data protection legislation, as set
                                        out in our Data Protection Policy and the Privacy Notice issued to
                                        you.
                                    </div>
            </h4>

        </div>
        <div id="accordion1_14" class="panel-collapse collapse in">
            <div class="panel-body">

                <div class="row mt-0 mb-0">
                    <label class="control-label col-md-2">Gender</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.gender}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Marital Status</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.maritalStatus}}" readonly />
                        </div>

                        <label class="control-label col-md-2"><span *ngIf="person_equalityInfo?.gender === 'Other'">If Other, please specify</span></label>                        <div class="col-md-4">
                            <input type="text" class="form-control form-group readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.gender === 'Other'"
                                value="{{person_equalityInfo?.gender_Other}}" readonly />
                        </div>

                        <label class="control-label col-md-2"><span *ngIf="person_equalityInfo?.maritalStatus === 'Other'">If Other, please specify</span></label>                        <div class="col-md-4">
                            <input type="text" class="form-control form-group readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.maritalStatus === 'Other'"
                                value="{{person_equalityInfo?.marital_Other}}" readonly />
                        </div>

                </div>

                <div class="row mt-0 mb-0">
                    <label class="control-label col-md-2">Sexual Orientation</label>
                    <div class="col-md-4 form-group">
                        <input type="text" class="form-control readonlyClass" autocomplete="off"
                            value="{{person_equalityInfo?.sexual_Orientation}}" readonly />
                    </div>
                    <label class="control-label col-md-2">Age</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.age}}" readonly />
                        </div>

                        <label class="control-label col-md-2"><span *ngIf="person_equalityInfo?.sexual_Orientation === 'Other'">If Other, please specify</span></label>                        <div class="col-md-4">
                            <div class="col-md-4">
                            <input type="text" class="form-control form-group readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.sexual_Orientation === 'Other'"
                                value="{{person_equalityInfo?.sexual_Other}}" readonly />
                        </div>
                </div>

                <div class="row mt-0 mb-0">
                    <label class="control-label col-md-2">
                        Disability
                        <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-container="body"
                                                data-placement="left" type="button" data-html="true" id="disability">
                                                <span class="glyphicon glyphicon-info-sign"></span>
                                            </span>
                    </label>
                    <div id="popover-content-disability" class="hide">
                        The Equality Act 2010 defines a disabled person
                        as someone who has a mental or physical
                        impairment that has a substantial and long-term
                        adverse effect on the person’s ability to carry out
                        normal day-to-day activities. If you have a
                        condition which fits the Equality Act definition,
                        please tick ‘yes’ even if you are not limited by
                        your condition.
                        Do you consider yourself to have a disability?
                    </div>
                    <div class="col-md-4 form-group pos-relative">
                        <input type="text" class="form-control readonlyClass" autocomplete="off"
                            value="{{person_equalityInfo?.disability}}" readonly />
                    </div>

                    <label class="control-label col-md-2">Religion or Belief</label>
                    <div class="col-md-4 form-group">
                        <input type="text" class="form-control readonlyClass" autocomplete="off"
                            value="{{person_equalityInfo?.religionOrBelief}}" readonly />
                    </div>
                </div>

                <div class="row mt-0 mb-0">
                    <label class="control-label col-md-2">Is the gender you identify with the same as your gender
                        registered at birth?</label>
                    <div class="col-md-4 form-group">
                        <input type="text" class="form-control readonlyClass" autocomplete="off"
                            value="{{person_equalityInfo?.gender_Birth}}" readonly />
                    </div>
                    <label class="control-label col-md-2">
                        Ethnicity
                        <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-placement="left" type="button"
                        data-html="true" id="ethnicity">
                        <span class="glyphicon glyphicon-info-sign"></span>
                    </span>
                    </label>
                    <div id="popover-content-ethnicity" class="hide">
                        Ethnic origin is not about nationality, place of
                        birth or citizenship. It is about what best
                        describes your ethnic group or background.
                        Please choose one option and tick the
                        appropriate box below.
                        What is your ethnic group?
                    </div>
                    <div class="col-md-4 form-group">
                        <input type="text" class="form-control readonlyClass" autocomplete="off"
                            value="{{person_equalityInfo?.ethnicity}}" readonly />
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="form-group">
                        <label class="control-label col-md-2">Gender</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.gender}}" readonly />
                        </div>

                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.gender === 'Other'"
                                value="{{person_equalityInfo?.gender_Other}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Marital Status</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.maritalStatus}}" readonly />
                        </div>

                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.maritalStatus === 'Other'"
                                value="{{person_equalityInfo?.marital_Other}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Sexual Orientation</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.sexual_Orientation}}" readonly />
                        </div>

                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.sexual_Orientation === 'Other'"
                                value="{{person_equalityInfo?.sexual_Other}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Age</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.age}}" readonly />
                        </div>

                        <label class="control-label col-md-2">
                            Disability
                            <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-container="body"
                                                    data-placement="left" type="button" data-html="true" id="disability">
                                                    <span class="glyphicon glyphicon-info-sign"></span>
                                                </span>
                        </label>
                        <div id="popover-content-disability" class="hide">
                            The Equality Act 2010 defines a disabled person
                            as someone who has a mental or physical
                            impairment that has a substantial and long-term
                            adverse effect on the person’s ability to carry out
                            normal day-to-day activities. If you have a
                            condition which fits the Equality Act definition,
                            please tick ‘yes’ even if you are not limited by
                            your condition.
                            Do you consider yourself to have a disability?
                        </div>
                        <div class="col-md-4 form-group pos-relative">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.disability}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Religion or Belief</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.religionOrBelief}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Is the gender you identify with the same as your gender
                            registered at birth?</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.gender_Birth}}" readonly />
                        </div>
                        <label class="control-label col-md-2">
                            Ethnicity
                            <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-placement="left" type="button"
                            data-html="true" id="ethnicity">
                            <span class="glyphicon glyphicon-info-sign"></span>
                        </span>
                        </label>
                        <div id="popover-content-ethnicity" class="hide">
                            Ethnic origin is not about nationality, place of
                            birth or citizenship. It is about what best
                            describes your ethnic group or background.
                            Please choose one option and tick the
                            appropriate box below.
                            What is your ethnic group?
                        </div>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{person_equalityInfo?.ethnicity}}" readonly />
                        </div>
                    </div>
                </div> -->


            </div>
        </div>
    </div>
    <!--- / -->
</div>
<div class="modal" id="modalJobDescription" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="modalJobDescriptionclose()"  aria-hidden="true"></button>
                <h4 class="modal-title">Job Description</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <iframe [src]='fileUrl' width="100%" height="600px"></iframe>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button"  class="btn dark btn-outline" (click)="modalJobDescriptionclose()">Close</button>
            </div>
        </div>
    </div>
</div>