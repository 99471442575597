<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>Menu Permission List</h1>
            </div>
        </div>
        <ul class="page-breadcrumb breadcrumb mb20">
            <li>
                <!-- <a data-toggle="modal" href="#draggable" (click)="openModel(0)"
                    class="btn btn-transparent blue  btn-sm">Add Zone</a> -->
            </li>
        </ul>
        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <!-- <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" placeholder="Type to filter the zone name column..."
                                (change)="filterData($event)">
                        </div>
                    </diV> -->
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label">Role</label>
                                <select id="roleid" class="form-control" #roleid (change)="getRoles_Permission(roleid.value)">
                                    <option value="">Select Role</option>
                                    <option *ngFor="let item of roleList;" value={{item.id}}>
                                        {{item.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-9">
                            
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Type to filter ....." (input)="filterModulePermission($event.target.value)">
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover">
                            <thead class="flip-content">
                                <tr>
                                    <th style="padding: 26px" class="text-center">Menu</th>
                                    <th class="text-center" style="width: 10%;padding: 26px">Add</th>
                                    <th class="text-center" style="width: 10%;padding: 26px">Edit</th>
                                    <th class="text-center" style="width: 10%;padding: 26px">Delete</th>
                                    <th class="text-center" style="width: 10%;padding: 26px">View</th>
                                    <th class="text-center" style="width: 10%;padding: 12px">
                                        <div class="form-group">
                                            <label class="control-label col-md-8" style="padding: 9px">Select
                                                All</label>
                                            <div class="col-md-1" *ngIf="this.module != null" style="padding: 5px">
                                                <div class="md-checkbox-inline">
                                                    <div class="md-checkbox">
                                                        <input type="checkbox" id="checkall"
                                                            (click)="checkAll(0,'checkall')" class="md-check">
                                                        <label for="checkall">
                                                            <span></span>
                                                            <span class="check"></span>
                                                            <span class="box"></span> </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of module| paginate: { itemsPerPage: 10, currentPage: p }">
                                    <td style="padding: 17px"> {{item.moduleName}}
                                    </td>
                                    <td class="text-center">
                                        <div class="form-group">
                                            <div class="md-checkbox-inline">
                                                <div class="md-checkbox">
                                                    <input type="checkbox" id="add{{item.id}}" class="md-check"
                                                        (click)="checkAll(item.id,'add')" [checked]="item.add">
                                                    <label for="add{{item.id}}">
                                                        <span></span>
                                                        <span class="check"></span>
                                                        <span class="box"></span> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <div class="form-group">
                                            <div class="md-checkbox-inline">
                                                <div class="md-checkbox">
                                                    <input type="checkbox" id="edit{{item.id}}" class="md-check"
                                                        (click)="checkAll(item.id,'edit')" [checked]="item.edit">
                                                    <label for="edit{{item.id}}">
                                                        <span></span>
                                                        <span class="check"></span>
                                                        <span class="box"></span> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <div class="form-group">
                                            <div class="md-checkbox-inline">
                                                <div class="md-checkbox">
                                                    <input type="checkbox" id="delete{{item.id}}" class="md-check"
                                                        (click)="checkAll(item.id,'delete')" [checked]="item.delete">
                                                    <label for="delete{{item.id}}">
                                                        <span></span>
                                                        <span class="check"></span>
                                                        <span class="box"></span> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td class="text-center">
                                        <div class="form-group">
                                            <div class="md-checkbox-inline">
                                                <div class="md-checkbox">
                                                    <input type="checkbox" id="view{{item.id}}" class="md-check"
                                                        (click)="checkAll(item.id,'view')" [checked]="item.view">
                                                    <label for="view{{item.id}}">
                                                        <span></span>
                                                        <span class="check"></span>
                                                        <span class="box"></span> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center" colspan="2">
                                        <div class="form-group">
                                            <div class="md-checkbox-inline">
                                                <div class="md-checkbox">
                                                    <input type="checkbox" id="selectall{{item.id}}" class="md-check"
                                                        (click)="checkAll(item.id,'selectall')"
                                                        [checked]="item.selectall">
                                                    <label for="selectall{{item.id}}">
                                                        <span></span>
                                                        <span class="check"></span>
                                                        <span class="box"></span> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <!-- <tbody *ngFor="let zone of zonelist| paginate: { itemsPerPage: 10, currentPage: p }">
                                <tr>
                                    
                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <div class="circle greenColor" *ngIf="zone.isActive" data-toggle="tooltip"
                                                data-placement="top" title="Active"></div>
                                            <div class="circle redColor" *ngIf="!zone.isActive" data-toggle="tooltip"
                                                data-placement="top" title="Inactive"></div>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip" data-placement="bottom"
                                            title="{{zone.company_Name}}">{{zone.company_Name}}</div>
                                    </td>
                                    <td style="width: 10%;">{{zone.zone_Code}}</td>
                                    <td>{{zone.zone_Name}}</td>
                                    <td class="text-center">
                                        <a data-toggle="modal" href="#draggable" (click)="openModel(zone.zone_Id)"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Zone"></i></a>&nbsp;
                                        <a (click)="zoneDelete(zone.zone_Id)">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top" title="Delete Zone"></i></a>
                                    </td>
                                </tr>
                            </tbody> -->
                        </table>
                    </div>

                    <div class="text-center">
                        <pagination-controls (pageChange)="p = $event" autoHide="true"></pagination-controls>
                    </div>
                    <hr/>
                    <div class="text-right">
                        <button type="button" style="width: 100px" (click)="addPermission()" class="btn btn-primary">Save</button>
                    </div>
                    

                </div>
            </div>
        </div>
    </div>
</div>



<!-- <div class="modal fade draggable-modal" id="draggable" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Zone</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="zoneform">
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Company<span class="required"> *
                                        </span></label>
                                    <select id="company_Id" class="form-control" formControlName="company_Id">
                                        <option value="">Select Your Company</option>
                                        <option *ngFor="let company of companylist;" value={{company.company_Id}}>
                                            {{company.company_Name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Zone Code<span class="required"> *
                                        </span></label>
                                    <input type="text" id="zone_Code" class="form-control" formControlName="zone_Code">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Zone Name<span class="required"> *
                                        </span></label>
                                    <input type="text" id="zone_Name" class="form-control" formControlName="zone_Name">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Active</label>
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="checkbox1_3" class="md-check"
                                                formControlName="isActive">
                                            <label for="checkbox1_3">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                    <button type="button" class="btn  dark btn-outline pull-left" (click)="history()" *ngIf="title == 'Edit'"><i
                        class="fa fa-history"></i></button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="zoneUpsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="basic" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="margin:5% auto !important">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title">Zone History</h4>
                </div>
                <div class="modal-body" *ngIf="title == 'Edit'">
                        <app-history></app-history>
                </div>
                <div class="modal-footer">
                    <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                </div>
            </div>
        </div>
</div> -->