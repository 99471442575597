import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { interval } from 'rxjs';
declare var $: any;
declare var moment: any;
@Component({
   selector: 'app-employeeviewsummary',
   templateUrl: './employee-view-summary.component.html',
})
export class EmployeeViewSummaryComponent implements OnInit {
   employeeId: any;
   employeeSummary: any;
   employeeAddress: any;
   employeeBasicInfo: any;
   employeeContract: any;
   employeeEmergency: any;
   employeeReference: any;
   employeeBank: any;
   employeeProbation: any;
   employeeContact: any;
   employeeRighttowork: any;
   emp_righttoworkview:any;
   empLeaveBalance: any;
   employeeSalary: any;
   ethnicity_Name: any;
   empDocuments: any;
   empReference:any;
   viewSummeryPopup: boolean = false;
   employee_length_service_year: any;
   employee_length_service_month: any;
   person_equalityInfo?:any;
   private _Summaryid: any
   fileUrl;

   @Input()

   public set Summaryid(v: any) {

      this._Summaryid = v;

      if (v > 0) {
         this.getSummary(v);
      }

   }



   public get Summaryid(): any {



      return this._Summaryid

   }
   constructor(private commonApiService: CommonApiService,
      private toastr: ToastrService,private sanitizer: DomSanitizer,
      private commonService: CommonService,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {
   }
   ngAfterViewInit(){
      $("[data-toggle=popover]").each(function (i, obj) {
      
               $(this).popover({
                  html: true,
                  content: function () {
                     var id = $(this).attr('id')
                     return $('#popover-content-' + id).html();
                  }
               });
            });
            
   }

   getSummary(id) {
      this.employeeId = id;
      this.commonApiService.getById(Commonvariable.EmployeeDetail, id, "ViewSummary").subscribe((response) => {
         if (response != null) {
            this.employeeSummary = response["data"][0];
            console.log(this.employeeSummary);

            let joindig = this.employeeSummary.joiningDate;
            this.Getname(joindig);
            // console.log(this.employeeSummary)

            // if (this.employeeBasicInfo.ethnicity_Code != null && this.employeeBasicInfo.ethnicity_Code !="") {
            //    this.commonApiService.getById("Ethinicity", this.employeeBasicInfo.ethnicity_Code).subscribe((response) => {
            //       if (response != null) {
            //          this.ethnicity_Name = response["data"][0].ethnicity_Name;
            //       }
            //    })
            // }
            this.employeeContract = this.employeeSummary["emp_contract"];
            this.employeeBank = this.employeeSummary["emp_bank"];
            this.employeeProbation = this.employeeSummary["emp_probation"];
            this.employeeRighttowork = this.employeeSummary["emp_righttowork"];
            this.emp_righttoworkview = this.employeeSummary["emp_righttoworkview"];
            console.log(this.emp_righttoworkview);
            this.empLeaveBalance = this.employeeSummary["emp_leave_balance"];
            this.employeeSalary = this.employeeSummary["emp_salary"];
            console.log(this.employeeSalary);
            this.empDocuments = this.employeeSummary["emp_document"];
            this.employeeReference = this.employeeSummary["emp_reference"];

            if (response["data"][0]["person_Id"] != null) {
               this.commonApiService.getById(Commonvariable.PersonService, response["data"][0]["person_Id"], "ViewSummary").subscribe((result) => {
                  this.employeeBasicInfo = result["data"][0];
                  this.employeeAddress = this.employeeBasicInfo["person_Address"];
                  this.employeeContact = this.employeeBasicInfo["person_Contact"];
                  this.employeeEmergency = this.employeeBasicInfo["person_Emergency"];
                  //this.employeeReference = this.employeeBasicInfo["person_Reference"];
                  this.person_equalityInfo = this.employeeBasicInfo["person_equality"];
               })

            }

            // $('#viewSummary').trigger('focus');
            this.viewSummeryPopup = true;
            // $('#viewSummary').modal({
            //    backdrop: 'static'
            // });
            //, window.scroll(0,0)
            //  $('#viewSummary').modal("show");
         }
      })
   }
   Getname(_date: any) {
      let out;
      var tDate = new Date();
      tDate.setDate(tDate.getDate())
      var date = tDate.getDate().toString().length == 1 ? "0" + tDate.getDate() : tDate.getDate();
      var month = tDate.getMonth() + 1;
      var getmonth = month.toString().length == 1 ? "0" + month : month;
      let DateObj = tDate.getFullYear() + "-" + getmonth + "-" + date;

      const datee = _date;//$('#getjoiningDate').val();
      const now = moment(datee);
      let proEndDate = now.format('YYYY-MM-DD');

      out = this.diffDate(new Date(proEndDate), new Date(DateObj));
      this.display(out);


   }

   diffDate(startDate, endDate) {
      var b = moment(startDate),
         a = moment(endDate),
         //intervals = ['years', 'months', 'weeks', 'days'],
         intervals = ['years', 'months'],
         out = {};

      for (var i = 0; i < intervals.length; i++) {
         var diff = a.diff(b, intervals[i]);
         b.add(diff, intervals[i]);
         out[intervals[i]] = diff;
      }
      return out;
   }

   display(obj) {
      var stryear = '';
      var strmonth = '';
      for (const key in obj) {
         if (key == 'years') {
            stryear = stryear + obj[key] + ' ' + key + ' '
         }
         if (key == 'months') {
            strmonth = strmonth + obj[key] + ' ' + key + ' '
         }

      }
      this.employee_length_service_year = stryear;
      this.employee_length_service_month = strmonth;
   }

   download() {
      
  
      if (parseInt(this.employeeSummary.jD_Id) > 0) {
        //var fileName = String(this.jobTitleList.filter(x => x.jD_Id == this.empJobDetailform.controls.JD_Id.value)[0]["path"]).split(".")[1]
        //this.JDescription.download(fileName == undefined ? ".pdf" : fileName, parseInt(this.vacancyForm.controls.JD_Id.value));
        this.commonApiService.getimage(Commonvariable.JobDescription, parseInt(this.employeeSummary.jD_Id), "DownloadDocument").subscribe((resp: any) => {
  
          if (resp.type != "application/json") {
            const blobData = new Blob([resp], { type: resp.type });
            var createObjectURL = (window.URL.createObjectURL(blobData));
    
            if (resp.type == "application/pdf" || resp.type == "image/jpeg" || resp.type == "image/png") {
              this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
              $("#modalJobDescription").modal("show")
            } else if (resp.type == "application/vnd.ms-word") {
              const a = document.createElement('a');
              a.setAttribute('type', 'hidden');
              a.href = URL.createObjectURL(blobData);
              a.download = 'Job_Description.docx';
              a.click();
              a.remove();
            } else {
    
              const a = document.createElement('a');
              a.setAttribute('type', 'hidden');
              a.href = URL.createObjectURL(blobData);
              a.download = 'Job_Description';
              a.click();
              a.remove();
    
            }
          }else{
            this.commonService.toaster("warning", 'Document Not Available', 'Job Description')
          }
          
        });
      }
      else{
         this.commonService.toaster("warning", 'Not selected.', 'Job Description')
       }
    }
    
    modalJobDescriptionclose()
    {
      $("#modalJobDescription").modal("hide");
      $('#viewSummary').modal({
         backdrop: 'static'
       });
   
     
    }
}


