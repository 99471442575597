<div class="row">
    <div class="col-md-4">
        <div class="form-group">
            <label class="control-label">Effective Date<span class="required"> * </span></label>
            <div class="input-group flatpickr" id="effectiveDatesign" data-wrap="true">
                <input type="text" class="form-control readonlyClass" id="effectiveDate" data-input />
                <span class="input-group-btn" data-toggle>
                    <button class="btn default" type="button">
                        <i class="fa fa-calendar"></i>
                    </button>
                </span>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <button type="button" style="margin-top: 8.5%;" (click)="addLeaveData(editEmpid)"
            class="btn btn-primary">Search</button>
    </div>
</div>
<form [formGroup]="empform">

    <div class="table-responsive">
        <table class="table table-hover" id="work" style="position: relative;" role="grid">
            <thead>
                <tr>
                    <th>Leave Type</th>
                    <th>Prorate Balance</th>
                    <th>Update Balance</th>
                </tr>
            </thead>
            <tbody formArrayName="emp_Leave" *ngFor="let item of empform.get('emp_Leave')['controls']; let i = index;">
                <tr [formGroupName]="i">
                    <td>
                        <label class="control-label">{{item.value.leave_Type}} ({{item.value.leave_Type_Code}})</label>
                    </td>
                    <td>
                        <label class="control-label">{{item.value.prorated_LeaveBalance}}</label>
                    </td>
                    <td>
                        <input type="text" class="form-control" formControlName="leaveBalanceToUpdate" maxlength="20"
                            onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</form>