

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>Employee List</h1>
            </div>
        </div>
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <!-- <ul class="nav navbar-nav navbar-right"> -->
            <!-- <li>
                    <div class="col-md-6">
                        <div class="input-group input-xlarge">
                            <div class="input-group-btn">
                                <button type="button" class="btn blue dropdown-toggle" data-toggle="dropdown">Search By
                                    <i class="fa fa-angle-down"></i>
                                </button>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="col-md-3">
                                            <div class="md-radio-inline">
                                                <div class="md-radio has-success">
                                                    <input type="radio" id="radio15" value="FirstName" name="filter"
                                                        class="md-radiobtn">
                                                    <label for="radio15">
                                                        <span class="inc"></span>
                                                        <span class="check"></span>
                                                        <span class="box" style="top:4px !important"></span>First Name
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="col-md-3 md-radio-inline">
                                            <div class="md-radio has-success">
                                                <input type="radio" id="radio17" value="Dept_Name" name="filter"
                                                    class="md-radiobtn">
                                                <label for="radio17">
                                                    <span class="inc"></span>
                                                    <span class="check"></span>
                                                    <span class="box" style="top:4px !important"></span> Department
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="col-md-3 md-radio-inline">
                                            <div class="md-radio has-success">
                                                <input type="radio" id="radio16" value="Desig_Name" name="filter"
                                                    class="md-radiobtn">
                                                <label for="radio16">
                                                    <span class="inc"></span>
                                                    <span class="check"></span>
                                                    <span class="box" style="top:4px !important"></span> Designation
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <input type="text" id="filterValue" class="form-control" placeholder="Search">
                            <span class="input-group-btn">
                                <button class="btn blue" (click)="searchOneValue()" type="button">Go!</button>
                                <button class="btn btn-default" (click)="clear()" type="button"><i
                                        class="fa fa-refresh"></i></button>
                            </span>
                        </div>
                    </div>
                </li> -->

            <!-- </ul> -->
            <ul class="nav navbar-nav navbar-right" *ngIf="userRole != 'User'">
                <li>
                    <div class="col-md-2">
                        <a data-toggle="modal" href="#filterModal" class="btn btn-transparent blue  btn-sm mrl10"
                            (click)="clickOnModal()">
                            Advanced Search</a>
                    </div>
                </li>
                <li>
                    <div class="col-md-2">
                        <a routerLink="/employeeadd" class="btn btn-transparent blue  btn-sm mrl10"
                            (click)="clickOnModaltest()">
                            New Employee</a>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <!-- <table datatable [dtOptions]="dtOptions" class="table table-sm  table-striped table-bordered table-hover"></table> -->
                    <diV class="row">
                        <div class="col-md-3 customSelect">
                            <div class="text-left">        
                                <select class="form-control" [(ngModel)]="itemsPerPage" (change)="onItemsPerPageChange()"  style="width: 75px;">
                                   <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                                 </select>
                            </div>
                        </div>
                       
                        <div class="col-md-3">
                            <input type="text" class="form-control" id="fname" autocomplete="off"
                                placeholder="Type First Name" (input)="getEmployeeList();">
                        </div>
                         <div class="col-md-3">
                            <input type="text" class="form-control" id="lname" autocomplete="off"
                                placeholder="Type Last Name" (input)="getEmployeeList();">
                        </div> 
                        <div class="col-md-3">
                            <input type="text" class="form-control" id="filterList" autocomplete="off"
                                placeholder="Type to filter ..." (input)="getEmployeeList();">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblemp">
                            <thead class="flip-content">
                                <tr>
                                    <!-- <th style="width: 5%;" class="text-center"> Photo </th> -->
                                    <th class="text-center" style="width: 8%;" (click)="sortColumn('emp_Code')" > Emp.Code <i class="fa" [ngClass]="getSortIcon('emp_Code')"></i></th>
                                    <th class="text-center" (click)="sortColumn('firstName')"> Name <i class="fa" [ngClass]="getSortIcon('firstName')"></i></th>
                                    <!-- <th class="text-center">Email </th> -->
                                    <th class="text-center" (click)="sortColumn('company_Name')"> Company <i class="fa" [ngClass]="getSortIcon('company_Name')"></i></th>
                                    <!-- <th class="text-center"> Site </th>
                                    <th class="text-center"> Department </th> -->
                                    <th class="text-center" (click)="sortColumn('desig_Name')"> Job Title <i class="fa" [ngClass]="getSortIcon('desig_Name')"></i></th>
                                    <!-- <th class="text-center"> Shift </th> -->
                                    <th class="text-center" (click)="sortColumn('lineManager')"> Line Manager <i class="fa" [ngClass]="getSortIcon('lineManager')"></i></th>
                                    <th class="text-center" (click)="sortColumn('dob')"> Date of Birth <i class="fa" [ngClass]="getSortIcon('dob')"></i></th>
                                    <!-- <th class="text-center"> Extension </th> -->
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                            
                                  
                                <tr
                                    *ngFor="let item of empList| paginate: { itemsPerPage: itemsPerPage, currentPage: p ,totalItems :totalRecord}">
                                    <!-- <td class="text-center">
                                        <img src="assets/layouts/layout4/company_logo/logo-light.png">
                                    </td> -->
                                    <td>
                                        <div>
                                            <a (click)="updateStatus(item.emp_Id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.emp_Id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        {{item.emp_Code}}
                                    </td>
                                    <td>

                                        <img src="data:image/png;base64,{{item.photo}}" *ngIf="item.photo != null"
                                            height="50px" width="50px" class="img-circle" />
                                        <img src="assets/layouts/layout4/img/avatar9.jpg" *ngIf="item.photo == null"
                                            height="50px" width="50px" class="img-circle" />
                                        &nbsp; &nbsp;
                                        <!-- <img alt="" class="img-circle" src="data:image/jpeg;base64, {{item.photo}}" height="40px" width="40px" /> &nbsp; &nbsp; -->

                                        {{item.firstName}} {{item.middleName}} {{item.lastName}}
                                    </td>
                                    <!-- <td>{{item.work_Email}}</td> -->
                                    <td>
                                        {{item.company_Name}}
                                    </td>

                                    <!-- <td>{{item.site_Name}}</td>
                                    <td>{{item.dept_Name}}</td> -->
                                    <td>{{item.desig_Name}}</td>
                                    <!-- <td>{{item.shift_Name}}</td> -->
                                    <td>{{item.lineManager}}</td>
                                    <td>{{item.dob}}</td>
                                    <!-- <td>{{item.work_Extension}}</td> -->
                                    <td class="numeric text-center">
                                        <!-- <a (click)="getSummary(item.emp_Id)">
                                            <i class="fa fa-list-alt" data-toggle="tooltip" data-placement="top"
                                                title="View Summary"></i></a> &nbsp; -->
                                        <a *ngIf="item.isActive"
                                            (click)="editRecord(item.emp_Id)">
                                            <i class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Employee"></i></a>                                       

                                        <a *ngIf="!item.isActive" (click)="employeeDelete(item.emp_Id)"><i
                                                class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Delete Employee"></i></a>
                                    </td>
                                </tr>
                          
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="empList != null">
                        <pagination-controls (pageChange)=" getEmployeeList($event)" autoHide="true">
                        </pagination-controls>
                    </div>
                    
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->




<div class="modal fade" id="filterModal" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
                <h4 class="modal-title">Advanced Search</h4>
            </div>
            <div class="modal-body">
                <app-empAdvancedSearch></app-empAdvancedSearch>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="clear()" title="Clear All" class="btn dark btn-outline">Clear</button>
                <button type="button" (click)="advanceFilterEmp()" class="btn blue">Search</button>
            </div>
        </div>
    </div>
</div>

<!-- <div class="modal fade" id="viewSummary" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalScrollableTitle">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <app-employeeviewsummary></app-employeeviewsummary>
        </div>
        </div>
    </div>
</div> -->

<!-- <div class="modal fade" id="viewSummary" *ngIf="modelpop" role="dialog" aria-labelledby="exampleModalScrollableTitle"> -->
   
    <div class="modal" id="viewSummary">
    <div class="modal-dialog modal-lg" *ngIf="modelpop" role="document" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="closepoopup()"></button>
                <h4 class="modal-title" id="exampleModalScrollableTitle">View Summary</h4>
            </div>
            <div class="modal-body">
                <app-employeeviewsummary [Summaryid]="SummaryId"></app-employeeviewsummary>
            </div> 
            <div class="modal-footer">
                <button type="button" (click)="closepoopup()"  data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>