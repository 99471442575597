import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Select2OptionData } from 'ng-select2';

declare var $: any;

@Component({
  selector: 'app-leave-add',
  templateUrl: './leave-add.component.html',
  styleUrls: ['./leave-add.component.css']
})
export class LeaveAddComponent implements OnInit {

  constructor(
   
  ) { }

  selectCompanyValue: string ;
  data: Array<any> = ['LUXSH', 'LAXIMO', 'SYRI'];
  options: any = {
    multiple: true
  };

  selectZoneValue: string;
  data1: Array<any> = ['ZONE 1', 'ZONE 2', 'ZONE 3'];
  options1: any = {
    multiple: true
  };

  selectUserValue: string;
  data2: Array<any> = ['', 'Self', 'Holiday Approver'];
  options2: any = {
    multiple: false
  };




  
  deleteLeave() {
    return Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete this leave? This action is irreversible.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
  }

  disableLeave() {
    return Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to disable this leave?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
  }
  enableLeave() {
    return Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to enable this leave?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
  }

  ngOnInit() {
  }

  
}
