import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { UserCreationComponent } from './../../usercreationform/usercreationform.component';
declare var $: any;

@Component({
  selector: 'app-user-request-list',
  templateUrl: './user-request-list.component.html'
})
export class UserRequestListComponent implements OnInit {
  @ViewChild(UserCreationComponent, { static: false }) UserCreation: UserCreationComponent;


  filterList: any;
  p: number = 1;
  candidatelist: any;
  totalRecord: 0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {


    this.commonService.myData.subscribe((data) => {
      this.getCandidateList();
    });

    
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }


  getCandidateList(PageIndex = 1) {

    this.totalRecord = 0;
    this.candidatelist = [];
    this.filterList = [];
    this.p = PageIndex;


    var requestData = {
      "searchBy": [
      ],
      "PageIndex": PageIndex - 1,
      "CommonSearch": $("#filterList").val().trim(),
      "PageSize": 10

    }

    this.commonApiService.GetUserCreationOnboarding(Commonvariable.OnboardingDetail, requestData).subscribe((result) => {
      if (result != null && result["status"] == 200) {
        this.totalRecord = result["data"]["recordCount"]
        this.candidatelist = result["data"]["list"];
        this.filterList = result["data"]["list"];
      }
    })

  }

  userCreation(id, usercreationid) {
    this.UserCreation.fillHRData(id, usercreationid)

    $('#userRequestCreationForm').modal("show");

    $('#userRequestCreationForm #userCreation').animate({
      scrollTop: $("#userRequestCreationForm  #userCreation").offset().top
    }, 500);
  }







}
