import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
   selector: 'app-candidatepersonequality',
   templateUrl: './candidate-person-equality.component.html',
})
export class CandidatePersonEqualityComponent implements OnInit {

   logoClass = "fileinput fileinput-new";
   logoInnerHTML = "";
   candidateLogo: any;
   candidateLogoChange: boolean = false;
   candidatePersonEqualityform: FormGroup;
   ethinicityList: any;
   nationalityList: any;
   languagesList: any;
   editMode: boolean = false;
   MaritalList: any;
   AgeList: any;
   treeData: any[] = [];
   selectedValueforGenderItem: any;
   selectedValueforSexualItem: any;
   selectedValueforMaritalItem: any;
   isDivHidden: boolean;
   isrequiredforGender: boolean;
   isrequiredforSexual: boolean;
   isrequiredforMarital: boolean;
   genderOther:string;
   maritalOther:string;
   sexualOther:string;
   constructor(private commonApiService: CommonApiService,
      private toastr: ToastrService,
      private commonService: CommonService,
      private fb: FormBuilder
   ) {

   }
   ngOnInit() {
      this.candidatePersonEqualityform = this.fb.group({
         Id: ["0"],
         Gender: [''],
         Gender_Other:[''],
         MaritalStatus: [''],
         Marital_Other: [''],
         Sexual_Orientation: [''],
         Sexual_Other: [''],
         Age: [''],
         Disability: [''],
         ReligionOrBelief: [''],
         Gender_Birth: [''],
         Ethnicity: [''],
         Other: [''],
         person_equalitycol: [''],
         isActive: ['1'],
         AddedBy: [Commonvariable.User_Id]
      });
      this.getEthinicityList();
      this.getMaritalStatusList();
      this.getEthinicityDropDownList();
      this.getAgeList();
   }

   ngAfterViewInit(){
      $("[data-toggle=popover]").each(function (i, obj) {
      
               $(this).popover({
                  html: true,
                  content: function () {
                     var id = $(this).attr('id')
                     return $('#popover-content-' + id).html();
                  }
               });
            });
            $('body').on('click', function (e) {
               $('[data-toggle="popover"]').each(function () {
                   //the 'is' for buttons that trigger popups
                   //the 'has' for icons within a button that triggers a popup
                   if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
       $(this).popover('hide');
                   }
               });
           });
          var createPopover = function (item, title) { 
            var $pop = $(item);
            $pop.popover({
                placement: 'right',
                title: ( title || '&nbsp;' ) + ' <a class="close" href="#">&times;</a>',
                trigger: 'click',
                html: true,
                content: function () {
                    return $('#popup-content').html();
                }
            }).on('shown.bs.popover', function(e) {
                //console.log('shown triggered');
                // 'aria-describedby' is the id of the current popover
                var current_popover = '#' + $(e.target).attr('aria-describedby');
                var $cur_pop = $(current_popover);
                $cur_pop.find('.close').click(function(){
                    //console.log('close triggered');
                    $pop.popover('hide');
                });
                $cur_pop.find('button').click(function(){
                    //console.log('OK triggered');
                    $pop.popover('hide');
                });
            });
            return $pop;
        };
      // create popover
      createPopover('#ethnicity',  'Demo popover!');
   }


   getMaritalStatusList() {
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "Marital_Status"
      }

      this.commonApiService.GetByPaginated(Commonvariable.Marital_Status, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            //this.MaritalList = response["data"];
            this.MaritalList = response["data"].map(x => ({
               id: x.id,
               text: x.marital_Status
            }))
            //this.companylist = response["data"].filter(x => x.isActive != 0);
         }
      })
   }

   getAgeList() {
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "Description"
      }

      this.commonApiService.GetByPaginatedForAge(Commonvariable.Marital_Status, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            this.AgeList = response["data"].map(x => ({
               id: x.id,
               text: x.description,
               start: x.start,
               end: x.end
            }))

            console.log(this.AgeList);
         }
      })
   }

   getEthinicityList() {
      this.commonApiService.List("Ethinicity").subscribe((result) => {
         if (result["data"] != "" && result["status"] == 200) {
            this.ethinicityList = result["data"].sort((a, b) => a.ethnicity_Name.localeCompare(b.ethnicity_Name));
         }
      })
   }
   getEthinicityDropDownList()
    {
   
      this.commonApiService.TreeDropDownList("Ethinicity").subscribe((result) => {
        console.log(result);
         if (result["data"] != "" && result["status"] == 200) {
          this.treeData = result["data"];
         }
      })
      //static
      // this.treeData = [
      //    {
      //        "name": "Asian or Asian British",
      //        "children": [
      //            {
      //                "name": "Bangladeshi"
      //            },
      //            {
      //                "name": "Indian"
      //            },
      //            {
      //                "name": "Pakistani"
      //            },
      //            {
      //                "name": "Chinese"
      //            },
      //            {
      //                "name": "Any other Asian background"
      //            }
      //        ]
      //    },
      //    {
      //        "name": "Black or Black British",
      //        "children": [
      //            {
      //                "name": "African"
      //            },
      //            {
      //                "name": "Caribbean"
      //            },
      //            {
      //                "name": "Any other Black, African or Caribbean"
      //            }
      //        ]
      //    },
      //    {
      //        "name": "Other Ethnic Group",
      //        "children": [
      //            {
      //                "name": "Arab"
      //            },
      //            {
      //                "name": "Any other ethnic group"
      //            }
      //        ]
      //    },
      //    {
      //        "name": "White",
      //        "children": [
      //            {
      //                "name": "English, Welsh or Scottish,Northern Irish or British"
      //            },
      //            {
      //                "name": "Irish"
      //            },
      //            {
      //                "name": "Gypsy or Irish Traveller"
      //            },
      //            {
      //                "name": "Chinese"
      //            },
      //            {
      //                "name": "Any other White background"
      //            }
      //        ]
      //    },
      //    {
      //        "name": "Mixed Heritage",
      //        "children": [
      //            {
      //                "name": "White and Asian"
      //            },
      //            {
      //                "name": "White and Black African"
      //            },
      //            {
      //                "name": "White and Black Caribbean"
      //            },
      //            {
      //                "name": "Any Other Mixed background"
      //            },
      //            {
      //                "name": "Prefer Not To Say"
      //            }
      //        ]
      //    }
      //  ]
   
   }
   
    

   empUpsert() {
     
      console.log(this.candidatePersonEqualityform.value);
      if (this.validation()) {
         return this.candidatePersonEqualityform.value;
      }
      return "";
   }

   Edit(Id) {
      
      if (Id > 0) {
         this.editMode = true;
         this.commonApiService.getById(Commonvariable.Person_Equality, Id).subscribe((response) => {
            if (response != null) {
               var editData = response["data"][0];
               setTimeout(() => {
                  this.candidatePersonEqualityform = this.fb.group({
                     Id: [editData.id],
                     Gender: [editData.gender,Validators.required],
                     Gender_Other: [editData.gender_Other],
                     MaritalStatus: [editData.maritalStatus,Validators.required],
                     Marital_Other: [editData.marital_Other],
                     Sexual_Orientation: [editData.sexual_Orientation,Validators.required],
                     Sexual_Other: [editData.sexual_Other],
                     // Age: [editData.age,Validators.required],
                     Age: [this.candidatePersonEqualityform.value.Age, Validators.required],
                     Disability: [editData.disability,Validators.required],
                     ReligionOrBelief: [editData.religionOrBelief,Validators.required],
                     Gender_Birth: [editData.gender_Birth,Validators.required],
                     Ethnicity: [editData.ethnicity,Validators.required],
                     Other: [editData.other],
                     person_equalitycol: [editData.person_equalitycol],
                     isActive: ['1'],
                     isReview: ['1'],
                     UpdatedBy: [Commonvariable.User_Id]
                  });
               });
            }
            this.genderOther = editData.gender;
            this.maritalOther = editData.maritalStatus;
            this.sexualOther = editData.sexual_Orientation;

            if(this.genderOther === "Other"){
               this.isrequiredforGender = true;
             }
             else{
               this.isrequiredforGender = false;
             }

             if(this.maritalOther === "Other"){
               this.isrequiredforMarital = true;
             }
             else{
               this.isrequiredforMarital = false;
             }

             if(this.sexualOther === "Other"){
               this.isrequiredforSexual = true;
             }
             else{
               this.isrequiredforSexual = false;
             }
   


         })
      }
   }


   validation() {
      
      var formData = this.candidatePersonEqualityform;
      if (formData.invalid) {
         for (const key of Object.keys(formData.controls)) {
            if (formData.controls[key].invalid) {
               let fieldName = key;
               this.toastr.info(fieldName + " is required !", "Employee");
               $("#" + key).focus();
               return false;
            }
         }

      }
      else {

      }
      this.commonService.setNull(formData);
      return true;
   }

   

   onChangeforGender(event:Event) {
             this.selectedValueforGenderItem = (event.target as HTMLSelectElement).value;

             if ( this.selectedValueforGenderItem  === 'Other') {
               this.isDivHidden = true;
               this.isrequiredforGender = true;
               } 
             else
              {
                  this.isDivHidden = false;
                  this.isrequiredforGender = false;
             }
         
            // const option = this.rtwVisaStaList.find(item => item.id === Number(this.selectedValueData));
            
            // const options= option ? option.name : null;

            // this.selecedValueItem = options;
   }

   onChangeforSexual(event:Event) {
      
             this.selectedValueforSexualItem = (event.target as HTMLSelectElement).value;

             if ( this.selectedValueforSexualItem  === 'Other') {
               this.isDivHidden = true;
               this.isrequiredforSexual = true;
               } 
             else
              {
                  this.isDivHidden = false;
                  this.isrequiredforSexual = false;
             }
         
            // const option = this.rtwVisaStaList.find(item => item.id === Number(this.selectedValueData));
            
            // const options= option ? option.name : null;

            // this.selecedValueItem = options;
   }
  

   onChangeforMaritalStatus(event:Event) {
             this.selectedValueforMaritalItem = (event.target as HTMLSelectElement).value;

             if ( this.selectedValueforMaritalItem  === 'Other') {
               this.isDivHidden = true;
               this.isrequiredforMarital = true;
               } 
             else
              {
                  this.isDivHidden = false;
                  this.isrequiredforMarital = false;
             }
         
            // const option = this.rtwVisaStaList.find(item => item.id === Number(this.selectedValueData));
            
            // const options= option ? option.name : null;

            // this.selecedValueItem = options;
   }

  
}


