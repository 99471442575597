<div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100">
            <form [formGroup]="resetPasswordForm" class="login100-form validate-form" id="resetForm">
                <span class="login100-form-title p-b-43">
                    <img src="assets/login_images/images/LuxshTechnologiesLogo_2020.svg" />
                </span>

                <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                    <input [class]="login_Id" type="text" formControlName="login_Id" id="login_Id" autocomplete="off" readonly>
                    <span class="focus-input100"></span>
                    <span class="label-input100">UserName</span>
                </div>

                <div class="wrap-input100 validate-input" data-validate="Password is required">
                    <input [class]="newPassword" id="newPassword" formControlName="newPassword"  (change)="valueChange('newPassword')" type="password">
                    <span class="focus-input100"></span>
                    <span class="label-input100">New Password</span>
                </div>

                <div class="wrap-input100 validate-input" data-validate="Password is required">
                    <input [class]="confirmPassword" id="confirmPassword" formControlName="confirmPassword"  (change)="valueChange('confirmPassword')" type="password">
                    <span class="focus-input100"></span>
                    <span class="label-input100">Confirm Password</span>
                </div>

                <div class="container-login100-form-btn">
                    <button type="button" (click)="resetPassword()" class="login100-form-btn">Reset Password</button>
                </div>
            </form>


            <div class="login100-more" style="background-image: url('assets/login_images/images/bg-01.jpg');">
                <div class="leftsideText leftsideBox">
                    <h1 class="loginboxTitle">Why choose LuxTech HR admin over other HR Portals?</h1>
                    <h6 class="loginboxsubTitle">The only admin dashboard template you would need for your all next
                        backend and
                        application projects. Go
                        and Get it!</h6>
                    <div class="row">
                        <div class="col-md-4 colmd4">
                            <img src="assets/login_images/images/icon1.png">
                            <br />
                            <br />
                            <h5>Handcrafted Designs</h5>
                            <p>
                                Our Designs are truly awesome and unique, which brings freshness to your project.
                            </p>
                        </div>
                        <div class="col-md-4 colmd4">
                            <img src="assets/login_images/images/icon2.png">
                            <br />
                            <br />
                            <h5>Seamless Coding</h5>
                            <p>
                                We have crafted our templates very carefully and our code is W3C validated.
                            </p>
                        </div>
                        <div class="col-md-4 colmd4">
                            <img src="assets/login_images/images/icon3.png">
                            <br />
                            <br />
                            <h5>Dedicated Support</h5>
                            <p>
                                We offer amazingly fast and professional support to our customers.
                            </p>
                        </div>
                    </div>
                    <hr />

                    <h1 class="loginboxTitle">Features which will Amaze you!</h1>
                    <h6 class="loginboxsubTitle">We know how important is for you to save time and create something
                        stunning for
                        your client, its easily
                        possible with Ample admin </h6>


                    <div class="row">
                        <div class="col-md-4 colmd4">
                            <img src="assets/login_images/images/f1.png">
                            <br />
                            <br />
                            <h5>Handcrafted Designs</h5>
                            <p>
                                We have included 6 pre-defined color schemes with Ample admin.
                            </p>
                        </div>
                        <div class="col-md-4 colmd4">
                            <img src="assets/login_images/images/f2.png">
                            <br />
                            <br />
                            <h5>Colored / Dark / Light Sidebar</h5>
                            <p>
                                Options available to select suitable sidebar for your project
                            </p>
                        </div>
                        <div class="col-md-4 colmd4">
                            <img src="assets/login_images/images/f4.png">
                            <br />
                            <br />
                            <h5>300+ UI Components</h5>
                            <p>
                                You will get more than 300 unique UI Components
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>