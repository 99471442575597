import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;

@Component({
   selector: 'app-empaddressinfo',
   templateUrl: './employee-address-info.component.html',
})
export class EmployeeAddressInfoComponent implements OnInit {

   unamePattern = "^[a-z0-9_-]{8,15}$";
   empform: FormGroup;
   items: FormArray;
   companyId: any = 0;
   countrylist: any;
   constructor(
      private toastr: ToastrService,
      private commonService: CommonService,
      private router: Router,
      private fb: FormBuilder,
      private commonApiService: CommonApiService,
   ) {

   }

   options = {
      componentRestrictions: {
         country: ["UK", "IND"]
      }
   }

   ngOnInit() {

      this.empform = this.fb.group({
         emp_address: this.fb.array([this.createItem(null)]),
      });
      this.getCountryList();
   }

   createItem(data) {
      return this.fb.group({
         Id: data != null ? data.id : '0',
         Person_Id: data != null ? data.person_Id : '0',
         Address_Type: data != null ? data.address_Type : null,
         LandlineNo: data != null ? data.landlineNo : null,
         Address1: data != null ? data.address1 : '',
         Address2: data != null ? data.address2 : '',
         Address3: data != null ? data.address3 : null,
         Address4: data != null ? data.address4 : null,
         PostCode: data != null ? data.postCode : '',
         City: data != null ? data.city : '',
         State: data != null ? data.state : '',
         isReview: JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "User" ? 0 : 1,
         country_Id: data != null ? data.country_Id : '',
         isDefault: data != null ? data.isDefault == 1 ? true : false : false,
         isActive: data != null ? data.isActive == 1 ? true : false : true,
         AddedBy: Commonvariable.User_Id
      });
   }

   edit(data) {
      if (data != null) {
         if (data.length > 0) {
            this.items = this.empform.get('emp_address') as FormArray;
            this.items.clear()
            data.forEach(x => {
               this.items.push(this.createItem(x));
            });
         }
      }
   }


   getCountryList() {

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "country_Name"
      }
      this.commonApiService.GetByPaginated(Commonvariable.Country, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            //this.countrylist = response["data"];
            this.countrylist = response["data"].map(x => ({
               id: x.id,
               text: x.country_Name
            }))
         }
      })
   }


   addItem(data) {
      if (this.validation()) {
         this.items = this.empform.get('emp_address') as FormArray;


         this.items.push(this.createItem(data));
      }
   }


   removeItem(index) {
      this.items = this.empform.get('emp_address') as FormArray;
      this.items.removeAt(index);
   }


   empUpsert() {
      var empaddressData = this.empform.get('emp_address').value;
      if (empaddressData[0].Address1 == "") {
         return false;
      }
      return true;
   }


   public AddressChange(address: any, index) {
       console.log(address.address_components)



      // let address1 = "";
      // let postcode = "";

      // Do some stuff
      // var component = address.address_components;
       console.log(address) 

      this.items = this.empform.get('emp_address') as FormArray;
      //console.log(this.items)
      this.items.controls[index]["controls"]["Address2"].setValue("")
      this.items.controls[index]["controls"]["PostCode"].setValue("")
      this.items.controls[index]["controls"]["City"].setValue("")
      this.items.controls[index]["controls"]["State"].setValue("")
      this.items.controls[index]["controls"]["country_Id"].setValue("")

      for (const component of address.address_components) {



         const componentType = component.types[0];
         var address1
         var address2
         var address2_1



         switch (componentType) {
            case "street_number": {
               address1 = component.long_name
               break;
            }

            case "route": {
               address2 = component.long_name
               break;
            }

            case "postal_town": {
               var city_1 = component.long_name
               break;
            }


            case "locality":{
               var city_2 = component.long_name
               break;
            }
            
            case "neighborhood": {
               address2_1 = component.long_name
               break;
            }

            case "administrative_area_level_1": {
               var state_1 = component.long_name
               break;
            }

            case "administrative_area_level_2": {
               var state_2 = component.long_name
               break;
            }

            case "country":
                const found = this.countrylist.find((obj) => {
                  return obj.text.toLowerCase() === component.long_name.toLowerCase();
                });
                this.items.controls[index]["controls"]["country_Id"].setValue((found.id))
               
                //  console.log(found); 
               // for (let i = 0; i < this.countrylist.length; i++) {
               //    if (this.countrylist[i].text == component.long_name) {
               //       console.log(this.countrylist[i]);
               //       this.items.controls[index]["controls"]["country_Id"].setValue((this.countrylist[i].id))
               //    }
               // }

               break;

            case "postal_code": {

               this.items.controls[index]["controls"]["PostCode"].setValue(component.long_name)
               //$("#PostCode"+index).val(component.long_name); 
               break;
            }

         }
      }

      if (address1) {
         this.items.controls[index]["controls"]["Address1"].setValue(address1)
      } else {
         this.items.controls[index]["controls"]["Address1"].setValue(address.name)
      }

      if(address2){
         this.items.controls[index]["controls"]["Address2"].setValue(address2)
       }else{
         this.items.controls[index]["controls"]["Address2"].setValue(address2_1)      
       }

      if (city_1) {
         this.items.controls[index]["controls"]["City"].setValue(city_1)
      } else {
         this.items.controls[index]["controls"]["City"].setValue(city_2)
      }

      if (state_1) {
         this.items.controls[index]["controls"]["State"].setValue(state_1)
      } else {
         this.items.controls[index]["controls"]["State"].setValue(state_2)
      }

   }


   validation() {
      var empaddressData = this.empform.get('emp_address').value;
      // var groupByName = {};
      // empContactData.forEach(function (a) {
      //     groupByName [a.contact_Type] = groupByName [a.contact_Type] || [];
      //     groupByName [a.contact_Type].push({ isDefault: a.isDefault});
      // });

     
      for (let index = 0; index < empaddressData.length; index++) {            
         if(empaddressData[0].Address1 === "" || empaddressData[0].Address2 === "" || empaddressData[0].City === "" ){
            empaddressData[index].country_Id = undefined
         }
      }

      if (empaddressData[0].Address1 != "" || empaddressData[0].Address2 != "" || empaddressData[0].City != "" ) {
         for (let index = 0; index < empaddressData.length; index++) {
            console.log(empaddressData)
            // if (empaddressData[index].Address_Type == "") {
            //    this.toastr.info("Address type is required !", "Employee");
            //    $("#Address_Type" + index).focus();
            //    return false;
            // }
            if (empaddressData[index].Address1 == "") {
               this.toastr.info("Address1 is required !", "Employee");
               $("#Address1" + index).focus();
               return false;
            }
            if ($("#Address1" + index).val().length < 2) {
               this.toastr.info("Address1 must be at least 2 characters long !", "Employee");
               $("#Address1" + index).focus();
               return false;
            }
            if (/["\\]/.test($("#Address1" + index).val())) {
               this.toastr.info("Address1 does not allow Special Characters \" \\ ", "Employee");
               $("#Address1" + index).focus();
               return false;
             }
            // if ($("#Address1" + index).val().search(/^[^-\s][a-zA-Z0-9\s-,/]+[^-\s]+$/) != 0) {
            //    this.toastr.info("Enter proper Address1 !", "Employee");
            //    $("#Address1" + index).focus();
            //    return false;
            // }            
            if (empaddressData[index].Address2 == undefined || empaddressData[index].Address2 == "") {
               this.toastr.info("Address2 is required !", "Employee");
               $("#Address2" + index).focus();
               return false;
            }
            if ($("#Address2" + index).val() != "" && $("#Address2" + index).val().length < 2) {
               this.toastr.info("Address2 must be at least 2 characters long !", "Employee");
               $("#Address2" + index).focus();
               return false;
            }
            if ($("#Address2" + index).val() != "" && /["\\]/.test($("#Address2" + index).val())) {
               this.toastr.info("Address2 does not allow Special Characters \" \\ ", "Employee");
               $("#Address2" + index).focus();
               return false;
             }
            // if ($("#Address2" + index).val() != "" && $("#Address2" + index).val().search(/^[^-\s][a-zA-Z0-9\s-,/]+[^-\s]+$/) != 0) {
            //    this.toastr.info("Enter proper Address2 !", "Employee");
            //    $("#Address2" + index).focus();
            //    return false;
            // }
            // if ($("#Address3" + index).val() != "" && $("#Address3" + index).val().length < 2) {
            //    this.toastr.info("Address3 must be at least 2 characters long !", "Employee");
            //    $("#Address3" + index).focus();
            //    return false;
            // }
            if ($("#Address3" + index).val() != "" && /["\\]/.test($("#Address3" + index).val())) {
               this.toastr.info("Address3 does not allow Special Characters \" \\ ", "Employee");
               $("#Address3" + index).focus();
               return false;
             }
            // if ($("#Address3" + index).val() != "" && $("#Address3" + index).val().search(/^[^-\s][a-zA-Z0-9\s-,/]+[^-\s]+$/) != 0) {
            //    this.toastr.info("Enter proper Address3 !", "Employee");
            //    $("#Address3" + index).focus();
            //    return false;
            // }
            // if ($("#Address4" + index).val() != "" && $("#Address4" + index).val().length < 2) {
            //    this.toastr.info("Address4 must be at least 2 characters long !", "Employee");
            //    $("#Address4" + index).focus();
            //    return false;
            // }
            if ($("#Address4" + index).val() != "" && /["\\]/.test($("#Address4" + index).val())) {
               this.toastr.info("Address4 does not allow Special Characters \" \\ ", "Employee");
               $("#Address4" + index).focus();
               return false;
             }
            // if ($("#Address4" + index).val() != "" && $("#Address4" + index).val().search(/^[^-\s][a-zA-Z0-9\s-,/]+[^-\s]+$/) != 0) {
            //    this.toastr.info("Enter proper Address4 !", "Employee");
            //    $("#Address4" + index).focus();
            //    return false;
            // }
            if (empaddressData[index].City == undefined || empaddressData[index].City == "") {
               this.toastr.info("City is required !", "Employee");
               $("#City" + index).focus();
               return false;
            }
            if ($("#City" + index).val().length < 2) {
               this.toastr.info("City must be at least 2 characters long !", "Employee");
               $("#City" + index).focus();
               return false;
            }
            // if ($("#City" + index).val().search(/^[^-\s][a-zA-Z\s]+[^-\s]+$/) != 0) {
            //    this.toastr.info("City only alphabets allow !", "Employee");
            //    $("#City" + index).focus();
            //    return false;
            // }
            // if (empaddressData[index].State == "") {
            //    this.toastr.info("County is required !", "Employee");
            //    $("#State" + index).focus();
            //    return false;
            // }
            if ($("#State" + index).val().length < 2 && $("#State" + index).val().length != 0) {
               this.toastr.info("County must be at least 2 characters long !", "Employee");
               $("#State" + index).focus();
               return false;
            }
            // if ($("#State" + index).val().search(/^[^-\s][a-zA-Z\s]+[^-\s]+$/) != 0) {
            //    this.toastr.info("County only alphabets allow !", "Employee");
            //    $("#State" + index).focus();
            //    return false;
            // }
            if (empaddressData[index].country_Id == undefined || empaddressData[index].country_Id == "") {
               this.toastr.info("Country is required !", "Employee");
               $("#country_Id" + index).focus();
               return false;
            }
            
       if ($("#City"+ index).val().search(/^[-a-zA-Z]+(\s+[-a-zA-Z]+)*$/) != 0) {
         this.toastr.info("City only alphabets allow !", "Employee");
         $("#City"+ index).focus();
         return false;
       }

 
       if ($("#State"+ index).val() != "" && /["\\]/.test($("#State").val())) {
         this.toastr.info("County does not allow Special Characters \" \\ ", "Employee");
         $("#State"+ index).focus();
         return false;
       }
       
       // if ($("#State").val().search(/^[^-\s][a-zA-Z\s-][^-\s]+$/) != 0){
       if ($("#State"+ index).val() != "" && $("#State"+ index).val().search(/^[-a-zA-Z]+(\s+[-a-zA-Z]+)*$/) != 0){
         this.toastr.info("County only alphabets allow !", "Employee");
         $("#State"+ index).focus();
         return false;
       }

            

            // if ($("#Country" + index).val().length < 2) {
            //    this.toastr.info("Country must be at least 2 characters long !", "Employee");
            //    $("#Country" + index).focus();
            //    return false;
            // }
            // if ($("#Country" + index).val().search(/^[^-\s][a-zA-Z\s]+[^-\s]+$/) != 0) {
            //    this.toastr.info("Country only alphabets allow !", "Employee");
            //    $("#Country" + index).focus();
            //    return false;
            // }
            if (empaddressData[index].PostCode == undefined || empaddressData[index].PostCode == "") {
               this.toastr.info("Post code is required !", "Employee");
               $("#PostCode" + index).focus();
               return false;
            }            
            if ($("#PostCode" + index).val() != "" && ($("#PostCode" + index).val().length < 2 || $("#PostCode" + index).val().length > 10)) {
               this.toastr.info("Post code must be at least 2 to 10 characters long !", "Employee");
               $("#PostCode" + index).focus();
               return false;
            }

            if ($("#PostCode" + index).val() != "" && $("#PostCode" + index).val().search(/[^a-zA-Z0-9 ]+/) > -1) {
               this.toastr.info("Post code only alphabets and numeric allow !", "Employee");
               $("#PostCode" + index).focus();
               return false;
            }

            // if ($("#PostCode" + index).val() != "" && $("#PostCode" + index).val().search(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/g) != 0) {

            //    this.toastr.info("Post code should be alphabets and numeric !", "Employee");
            //    $("#PostCode" + index).focus();
            //    return false;
            // }


            //empaddressData[index].Address2 = empaddressData[index].Address2 != "" ? empaddressData[index].Address2 : null
            empaddressData[index].Address3 = empaddressData[index].Address3 != "" ? empaddressData[index].Address3 : null
            empaddressData[index].Address4 = empaddressData[index].Address4 != "" ? empaddressData[index].Address4 : null
            empaddressData[index].isDefault = empaddressData[index].isDefault ? 1 : 0
            empaddressData[index].isActive = empaddressData[index].isActive ? 1 : 0
         }
      }


      return true;
   }


}


