import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { JwtInterceptor } from 'src/app/helper/jwt.interceptor';
// import { PagesModule } from './pages/pages.module';
import { OnboardingModule } from './pages/onboarding/onboarding.module';
import { HomeComponent } from './home/home/home.component';
import { FormsModule } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SignupComponent } from './home/signup/signup.component';
import { UserCreationComponent } from './pages/usercreationform/usercreationform.component';
import { UserRequestListComponent } from './pages/notification/user-request-list/user-request-list.component';
import { RoleAssignComponent } from './pages/notification/roleassign/roleassign.component';
import { SignupListComponent } from './pages/notification/signup-list/signup-list.component';

import { UserProfileComponent } from './pages/user-profile/user-profile.component';

import { MailSendComponent } from './pages/mailsend/mailsend.component';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { NgxPaginationModule } from 'ngx-pagination';

//import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';

import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NgSelect2Module } from 'ng-select2';






// import { FullCalendarModule } from '@fullcalendar/angular';
// import {PagesModule} from '././pages/pages.module';


FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
])

// import { FullCalendarModule } from '@fullcalendar/angular';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import listPlugin from '@fullcalendar/list';
// import interactionPlugin from '@fullcalendar/interaction';


// FullCalendarModule.registerPlugins([
//   dayGridPlugin,
//   timeGridPlugin,
//   listPlugin,
//   interactionPlugin
// ])




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LeftMenuComponent,
    HomeComponent,
    SignupComponent,
    UserCreationComponent,
    UserRequestListComponent,
    RoleAssignComponent,
    SignupListComponent,
    UserProfileComponent,
    MailSendComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    OnboardingModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    AutocompleteLibModule,
    AutoCompleteModule,    
    NgSelect2Module,

    
    // PagesModule,
    //  FullCalendarModule


  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
