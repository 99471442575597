import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;


@Component({
  selector: 'app-reviewperson',
  templateUrl: './review-person.component.html'
})
export class ReviewPersonComponent implements OnInit {


  reviewList: any[];
  reviewExpandList: any[];
  p: number = 1;
  totalRecord: 0
  person_Id = 0;

  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private commonApiService: CommonApiService,
    private fb: FormBuilder) {

    this.route.queryParamMap.subscribe(params => {
      this.person_Id = params["params"]["Person_Id"];
      this.getReviewList()
    });

  }



  ngOnInit() {

  }


  getReviewList(PageIndex = 1) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.reviewList = []

    var requestData = {
      "searchBy": [
        {
          "FieldName": "Person_Id",
          "FieldValue": this.person_Id,
          "Parameter": "=="
        }
      ],
      "PageIndex": PageIndex - 1,
      "CommonSearch": $("#filterList").val(),
      "PageSize": 10,
    }

    if (this.person_Id > 0) {
      this.commonApiService.PostRequest("Review_PersonEdit", "GetPaginated", requestData).subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.totalRecord = result["data"]["recordCount"]
          this.reviewList = result["data"]["list"]
        }
      })
    }
    else {

      this.commonApiService.getListWithFilter("Review_PersonEdit", PageIndex, $("#filterList").val()).subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.totalRecord = result["data"]["recordCount"]
          this.reviewList = result["data"]["list"]
        }
      })

    }


  }

  getReviewExpandList(id) {
    this.commonApiService.getById("Review_PersonEdit", id, "Get").subscribe((response) => {
      if (response["data"] != "") {
        response["data"].forEach(x => {
          let index = 0;
          for (const key of Object.keys(x.newValue)) {

            //if (x.oldValue[key] != x.newValue[key] && key != "addedOn" && key != "person_Id" && key != "updatedOn" && key != "updatedBy" && key != "addedBy"
            //&& key != "id" && (!key.includes("is") || key.includes("isActive") || key.includes("isDefault"))) {
            if (x.oldValue[key] != x.newValue[key] && key != "addedOn" && key != "person_Id" && key != "updatedOn" && key != "updatedBy" && key != "addedBy" && key != "id" && key != "emp_Bank_Id" && key != "emp_Id" && key != "account_Title" && key != "emp_Doc_Id" && key != "notes" && key != "version_Id" && (key.includes("disability") || !key.includes("is") || key.includes("isActive") || key.includes("isDefault"))) {

              let personReview = {
                "id": index,
                "fieldName": key,
                "oldValue": x.oldValue[key],
                "newValue": x.newValue[key]
              }


              if (x.tableName == "Person") {

                if (key == "ethnicity_Id") {
                  personReview.fieldName = "Ethnicity"
                  if (x.oldValue[key] != null) {
                    this.commonApiService.getById(Commonvariable.Ethinicity, x.oldValue[key]).subscribe((result) => {
                      if (result["data"] != "") {
                        personReview.oldValue = result["data"][0]["ethnicity_Name"]
                      }
                    })
                  }

                  if (x.newValue[key] != null) {
                    this.commonApiService.getById(Commonvariable.Ethinicity, x.newValue[key]).subscribe((result) => {
                      if (result["data"] != "") {
                        personReview.newValue = result["data"][0]["ethnicity_Name"]
                      }
                    })
                  }
                }

                if (key == "maritalStatus_Id") {
                  personReview.fieldName = "MaritalStatus"
                  if (x.oldValue[key] != null) {
                    this.commonApiService.getById(Commonvariable.Marital_Status, x.oldValue[key]).subscribe((result) => {
                      if (result["data"] != "") {
                        personReview.oldValue = result["data"][0]["marital_Status"]
                      }
                    })
                  }
                  if (x.newValue[key] != null) {
                    this.commonApiService.getById(Commonvariable.Marital_Status, x.newValue[key]).subscribe((result) => {
                      if (result["data"] != "") {
                        personReview.newValue = result["data"][0]["marital_Status"]
                      }
                    })
                  }
                }

                if (key == "nationality_Id") {
                  personReview.fieldName = "Nationality"

                  if (x.oldValue[key] != null) {
                    this.commonApiService.getById(Commonvariable.Nationality, x.oldValue[key]).subscribe((result) => {
                      if (result["data"] != "") {
                        personReview.oldValue = result["data"][0]["name"]
                      }
                    })
                  }

                  if (x.newValue[key] != null) {
                    this.commonApiService.getById(Commonvariable.Nationality, x.newValue[key]).subscribe((result) => {
                      if (result["data"] != "") {
                        personReview.newValue = result["data"][0]["name"]
                      }
                    })
                  }
                }

                if (key == "gender_Id") {
                  personReview.fieldName = "Gender"
                  this.commonApiService.getById(Commonvariable.Gender, x.oldValue[key]).subscribe((result) => {
                    if (result["data"] != "") {
                      personReview.oldValue = result["data"][0]["gender"]

                      this.commonApiService.getById(Commonvariable.Gender, x.newValue[key]).subscribe((result) => {
                        if (result["data"] != "") {
                          personReview.newValue = result["data"][0]["gender"]
                        }
                      })
                    }
                  })
                }

              }


              if (key == "country_Id") {
                personReview.fieldName = "Country"
                if (x.oldValue[key] != null) {
                  this.commonApiService.getById(Commonvariable.Country, x.oldValue[key]).subscribe((result) => {
                    if (result["data"] != "") {
                      personReview.oldValue = result["data"][0]["country_Name"]
                    }
                  })
                }

                if (x.newValue[key] != null) {
                  this.commonApiService.getById(Commonvariable.Country, x.newValue[key]).subscribe((result) => {
                    if (result["data"] != "") {
                      personReview.newValue = result["data"][0]["country_Name"]
                    }
                  })
                }
              }


              if (x.tableName == "Person_Emergency") {
                if (key == "relationship_Id") {
                  personReview.fieldName = "Relationship"

                  if (x.oldValue[key] != null) {
                    this.commonApiService.getById(Commonvariable.Relationship, x.oldValue[key]).subscribe((result) => {
                      if (result["data"] != "") {
                        personReview.oldValue = result["data"][0]["name"]
                      }
                    })
                  }

                  if (x.newValue[key] != null) {
                    this.commonApiService.getById(Commonvariable.Relationship, x.newValue[key]).subscribe((result) => {
                      if (result["data"] != "") {
                        personReview.newValue = result["data"][0]["name"]
                      }
                    })
                  }
                }
              }

              if (x.tableName == "Person") {
                if (key == "languages_Id") {
                  personReview.fieldName = "Languages"

                  if (x.oldValue[key] != null) {
                    this.commonApiService.getById(Commonvariable.PersonService, x.oldValue[key], "GetListofLangs").subscribe((result) => {
                      if (result["data"] != "") {
                        personReview.oldValue = result["data"]
                      }
                    })
                  }

                  if (x.newValue[key] != null) {
                    this.commonApiService.getById(Commonvariable.PersonService, x.newValue[key], "GetListofLangs").subscribe((result) => {
                      if (result["data"] != "") {
                        personReview.newValue = result["data"]
                      }
                    })
                  }
                }
              }

              if (key == "isActive") {
                personReview.fieldName = "Active"
                personReview.oldValue = personReview.oldValue == 1 ? true : false;
                personReview.newValue = personReview.newValue == 1 ? true : false
              }

              if (key == "isDefault") {
                personReview.fieldName = "Default"
                personReview.oldValue = personReview.oldValue == 1 ? true : false;
                personReview.newValue = personReview.newValue == 1 ? true : false
              }





              if (personReview.fieldName == "contact_Type") {
                personReview.fieldName = "Contact Type"
              }
              if (personReview.fieldName == "contact_Value") {
                personReview.fieldName = "Contact Value"
              }
              if (personReview.fieldName == "title") {
                personReview.fieldName = "Title"
              }
              if (personReview.fieldName == "firstName") {
                personReview.fieldName = "First Name"
              }
              if (personReview.fieldName == "middleName") {
                personReview.fieldName = "Middle Name"
              }
              if (personReview.fieldName == "lastName") {
                personReview.fieldName = "Last Name"
              }
              if (personReview.fieldName == "dob") {
                personReview.fieldName = "Date Of Birth"
              }
              if (personReview.fieldName == "address1") {
                personReview.fieldName = "Address1"
              }
              if (personReview.fieldName == "address2") {
                personReview.fieldName = "Address2"
              }
              if (personReview.fieldName == "address3") {
                personReview.fieldName = "Address3"
              }
              if (personReview.fieldName == "address4") {
                personReview.fieldName = "Address4"
              }
              if (personReview.fieldName == "postCode") {
                personReview.fieldName = "Post Code"
              }
              if (personReview.fieldName == "city") {
                personReview.fieldName = "City"
              }
              if (personReview.fieldName == "state") {
                personReview.fieldName = "State"
              }
              if (personReview.fieldName == "contactName") {
                personReview.fieldName = "Contact Name"
              }
              if (personReview.fieldName == "contactNo") {
                personReview.fieldName = "Contact No"
              }
              if (personReview.fieldName == "ref_Name") {
                personReview.fieldName = "Reference Name"
              }
              if (personReview.fieldName == "ref_ContactNo") {
                personReview.fieldName = "Reference Contact Number"
              }
              if (personReview.fieldName == "ref_Email") {
                personReview.fieldName = "Reference Email"
              }
              if (personReview.fieldName == "relationship") {
                personReview.fieldName = "Relationship"
              }
              if (personReview.fieldName == "MaritalStatus") {
                personReview.fieldName = "Marital Status"
              }

              this.reviewExpandList.push(personReview)
              index++;
            }
          }

        });

      }

    })
  }



  expandTable(id) {
    this.reviewExpandList = [];
    var visibility = $(".sub_tbl_" + id).toggle().is(":visible");
    $(".sub_tbl").hide();
    $(".details-control").css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    if (visibility === true) {
      $(".sub_tbl_" + id).show();
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/d4ICC.png) no-repeat center center");
      this.getReviewExpandList(id);
    } else {
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    }

  }



  acceptChanges(id, tableName, newvalue) {
    newvalue["isReview"] = 1

    if (newvalue.languages_Id != undefined) {
      newvalue.languages_Id = String(newvalue.languages_Id).split("~");
    }
    if (tableName == "Employee_Bank") {
      this.commonApiService.Upsert(tableName, newvalue, newvalue.emp_Bank_Id).subscribe((response) => {
        if (response["data"] != "") {
          this.commonApiService.Delete("Review_PersonEdit", id, "ApproveReview").subscribe((response) => {
            this.getReviewList(1)
            this.commonService.myData.next(!this.commonService.myData)
            this.commonService.toaster("success", "Accept Successfully !", "Review Request")
          })
        }
      })
    }
    else if(tableName == "Employee") {
      this.commonApiService.Upsert(tableName, newvalue, newvalue.emp_Id).subscribe((response) => {
        if (response["data"] != "") {
          this.commonApiService.Delete("Review_PersonEdit", id, "ApproveReview").subscribe((response) => {
            this.getReviewList(1)
            this.commonService.myData.next(!this.commonService.myData)
            this.commonService.toaster("success", "Accept Successfully !", "Review Request")
          })
        }
      })
    }
    else {
      this.commonApiService.Upsert(tableName, newvalue, newvalue.id).subscribe((response) => {
        if (response["data"] != "") {
          this.commonApiService.Delete("Review_PersonEdit", id, "ApproveReview").subscribe((response) => {
            this.getReviewList(1)
            this.commonService.myData.next(!this.commonService.myData)
            this.commonService.toaster("success", "Accept Successfully !", "Review Request")
          })
        }
      })
    }



  }

  rejectChanges(id) {

    this.commonService.dyanmicConfirm('', 'Reject it !').then((result) => {
      if (result.value) {
        this.commonApiService.Delete("Review_PersonEdit", id, "RejectReview").subscribe((response) => {
          this.getReviewList(1)
          this.commonService.myData.next(!this.commonService.myData)
          this.commonService.toaster("success", "Reject Successfully !", "Review Request")
        })
      }
    })
  }










}

