<!-- <form [formGroup]="empform">
   
    <div class="form-group">
        <div class="table-responsive">
            
          
        
            <table class="table table-hover" id="work" style="position: relative;" role="grid">
                <thead>
                    <tr>
                        <th style="width: 10%;">Received </th>
                        <th style="width: 15%;">Date Of Received </th>
                        <th style="width: 15%;">Notes</th>                        
                        <th style="width: 15%;">Attachment Upload</th>
                        <th style="width: 2%;">Active</th>
                        <th style="width: 5%;"><button  type="button" (click)="addItem(null)" class="btn pull-right"><i class="fa fa-plus"
                            aria-hidden="true"></i></button></th>
                    </tr>
                </thead>
                <tbody formArrayName="emp_ref"
                    *ngFor="let item of empform.get('emp_ref')['controls']; let i = index;">
                    <tr [formGroupName]="i">
                        <label>Reference{{i+1}}   Received</label>
                        <div class="md-checkbox-inline">
                            <label class="control-label">Yes &nbsp;&nbsp;</label>
                            <div class="md-checkbox">
                                <input type="checkbox" id="empReferenceRequired{{i}}" class="md-check" (click)="chekboxclickyes($event,i)" >
                                <label for="empReferenceRequired{{i}}">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> </label>
                            </div>
                            <label class="control-label">No &nbsp;&nbsp;</label>
                            <div class="md-checkbox">
                                <input type="checkbox" id="empReferencenotRequired{{i}}" class="md-check"  (click)="chekboxclickno($event,i)" >
                                <label for="empReferencenotRequired{{i}}">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> </label>
                            </div>
                        </div>
                        <td>
                            <div class="input-group" id="flatpickropendr{{i}}" data-wrap="true"><span class="required" *ngIf="isrequiredclass[i]"> * </span>
                                <input type="text" class="form-control readonlyClass" id="DateOfRec{{i}}" formControlName="DateOfRec" data-input  />
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div> 
                            <div class="input-group" id="flatpickropenEmpCStD{{i}}" data-wrap="true">
                                <input type="text" class="form-control readonlyClass" id="DateOfRec{{i}}" formControlName="DateOfRec"
                                    data-input >
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </td>
                        <td><span class="required" *ngIf="isrequiredclass[i]"> * </span>
                            <textarea cols="55" class="form-control" rows="3" id="Notes{{i}}" formControlName="Notes"></textarea>
                        </td>                       
                        <td><span class="required" *ngIf="isrequiredclass[i]"> * </span>
                            <div class="fileinput fileinput-new" data-provides="fileinput">
                                <div class="input-group input-large">
                                    <div class="form-control uneditable-input input-fixed input-medium"
                                        data-trigger="fileinput">
                                        <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                        <span class="fileinput-filename"> </span>
                                    </div>
                                    <span class="input-group-addon btn default btn-file">
                                        <span class="fileinput-new"> Select file </span>
                                        <span class="fileinput-exists"> Change </span>
                                        <input type="file" id="Emp_Doc_Path{{i}}" (change)="fileChange($event,i)">
                                    </span>
                                    <a href="javascript:;" id="remove{{i}}"
                                        class="input-group-addon btn red fileinput-exists" (click)="removeDoc(i)"
                                        data-dismiss="fileinput"> Remove </a>
                                </div>
                            </div>
                        </td>
                     
    
                        <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="empdoc{{i}}" class="md-check" formControlName="isActive">
                                    <label for="empdoc{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td>
    
                      
                        <td>
                            <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                                    class="fa fa-minus" aria-hidden="true"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
    </div>
</form> -->
<form [formGroup]="empform">
<div class="col-sm-12" id="work" role="grid">

    <div class="pull-right">
        <button type="button" (click)="addItem(null)" class="btn pull-right m-15"><i class="fa fa-plus"
                aria-hidden="true"></i></button>
    </div>
    
    <div class="row" formArrayName="emp_ref"
        *ngFor="let item of empform.get('emp_ref')['controls']; let i = index;">

        <div [formGroupName]="i">

            <div class="row">
                <div class="col-lg-6">
                    <h4 class="mb-15">Reference{{i+1}}</h4>
                </div>
                <div class="col-lg-6">
                    <button *ngIf="i>0" type="button"  class="btn pull-right"><i
                        class="fa fa-minus" aria-hidden="true"></i></button>
                        <!-- (click)="removeItem(i)" -->
                </div>
            </div>
            
            <div class="row mb-15">
                <div class="col-lg-2"></div>
                <label class="col-lg-2 form-label l-38">Received</label>
                <div class="md-checkbox-inline col-lg-4" style="margin-top: 0;">
                    <label class="control-label">Yes &nbsp;&nbsp;</label>
                    <div class="md-checkbox">
                        <input type="checkbox" id="empReferenceRequired{{i}}" class="md-check"
                            (click)="chekboxclickyes($event,i)" >
                        <label for="empReferenceRequired{{i}}">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> </label>
                    </div>
                    <label class="control-label">No &nbsp;&nbsp;</label>
                    <div class="md-checkbox">
                        <input type="checkbox" id="empReferencenotRequired{{i}}" class="md-check"
                            (click)="chekboxclickno($event,i)" checked>
                        <label for="empReferencenotRequired{{i}}">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> </label>
                    </div>
                </div>
            </div>

            <!-- <div class="row mb-15">
                <div class="col-lg-2"></div>
                <label class="col-lg-2 form-label l-38">Date Sent <span class="required text-danger" *ngIf="isrequiredclass[i]"> * </span></label>
                <div class="col-lg-4">
                    <div class="input-group" id="flatpickropenEmpCEnD{{i}}" data-wrap="true">
                        <input type="text" class="form-control readonlyClass" id="DateSent{{i}}" formControlName="DateSent"
                            data-input >
                        <span class="input-group-btn" data-toggle>
                            <button class="btn default" type="button">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </span>
                    </div>
                </div>
            </div> -->

       
            
            <div class="row mb-15">
                <div class="col-lg-2"></div>
                <label class="col-lg-2 form-label l-38">Date Of Received <span class="required text-danger" *ngIf="isrequiredclass[i]"> * </span></label>
                <div class="col-lg-4">
                    <div class="input-group" id="flatpickropenEmpCStD{{i}}" data-wrap="true">
                        <input type="text" class="form-control readonlyClass" id="DateOfRec{{i}}" formControlName="DateOfRec"
                            data-input >
                        <span class="input-group-btn" data-toggle>
                            <button class="btn default" type="button">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            
            <div class="row mb-15">
                <div class="col-lg-2"></div>
                <label class="col-lg-2 form-label l-38">Date Sent <span class="required text-danger" *ngIf="isrequiredclass[i]"> * </span></label>
                <div class="col-lg-4">
                    <div class="input-group" id="flatpickropenEmpCEnD{{i}}" data-wrap="true">
                        <input type="text" class="form-control readonlyClass" id="DateSent{{i}}" formControlName="DateSent"
                            data-input >
                        <span class="input-group-btn" data-toggle>
                            <button class="btn default" type="button">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row mb-15">
                <div class="col-lg-2"></div>
                <label class="col-lg-2 form-label l-38">Comment<span class="required text-danger" *ngIf="isrequiredclass[i]"> * </span></label>
                <div class="col-lg-4">
                    <textarea cols="55" class="form-control" rows="3" id="Notes{{i}}" formControlName="Notes"></textarea>
                </div>
            </div>

            <div class="row mb-15">
                <div class="col-lg-2"></div>
                <label class="col-lg-2 form-label l-38">Attachment Upload<span class="required text-danger" *ngIf="isrequiredclass[i]"> * </span></label>
                <div class="col-lg-4">
                    <div class="fileinput fileinput-new" data-provides="fileinput">
                        <div class="input-group input-large">
                            <div class="form-control uneditable-input input-fixed input-medium"
                                data-trigger="fileinput">
                                <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                <span class="fileinput-filename"> </span>
                            </div>
                            <span class="input-group-addon btn default btn-file">
                                <span class="fileinput-new"> Select file </span>
                                <span class="fileinput-exists"> Change </span>
                                <input type="file" id="Emp_Doc_Path{{i}}" (change)="fileChange($event,i)">
                            </span>
                            <a href="javascript:;" id="remove{{i}}"
                                class="input-group-addon btn red fileinput-exists" (click)="removeDoc(i)"
                                data-dismiss="fileinput"> Remove </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-15">
                <div class="col-lg-2"></div>
                <label class="col-lg-2 form-label l-38">Active</label>
                <div class="col-lg-4">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="empdoc{{i}}" class="md-check" formControlName="isActive">
                            <label for="empdoc{{i}}">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

    </div>


</div>
</form>




