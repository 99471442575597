<style type="text/css">
    .inputaddon>i {
        color: #9b9da1 !important;
    }

    .bs-deselect-all {
        display: none;
    }

    .bs-actionsbox .btn-group button {
        width: 100%;
    }

    .select2-dropdown--below {
        min-width: 150px;
    }

    .select2-results__option {
        white-space: nowrap;
    }

    .dropSelect_company .select2-container {
        width: 100% !important;
    }

    table.dataTable tbody th,
    table.dataTable tbody td {
        padding: 2px 10px;
    }

    #example2_filter {
        float: right;
    }

    #example2_filter input,
    #example .dataTables_filter input {
        height: 34px;
        padding: 6px 12px;
        background-color: #fff;
        border: 1px solid #c2cad8 !important;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }

    td.details-control {
        background: url('assets/pages/img/SD7Dz.png') no-repeat center center;
        cursor: pointer;
    }

    tr.shown td.details-control {
        background: url('assets/pages/img/d4ICC.png') no-repeat center center;
    }

    .iconFontsize {
        margin-right: 5px;
    }

    .pdl70 {
        padding-left: 70px;
    }

    .pdl51 {
        padding-left: 51px;
    }

    .pdl30 {
        padding-left: 30px;
    }

    .pdl9 {
        padding-left: 9px !important;
    }

    .pdr45 {
        padding-right: 45px;
    }

    #rightwork {
        height: 250px;
        overflow: auto;
    }

    #Createrecruitment .moel-body {
        width: 100%;
        background: #fff;
        box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    }

    #Createrecruitment .moel-body .modal-content {
        box-shadow: none;
        border: none;
    }
</style>

<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Approval Process Configuration
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div class="col-md-6 dropSelect_company">
                        <div class="input-group input-xlarge">
                            <div class="input-group-btn">
                                <button type="button" class="btn blue dropdown-toggle" data-toggle="dropdown">Search By
                                    Company
                                </button>

                            </div>
                            <select id="Company_Id_Filter"
                                class="form-control companySelectListClassFilter select_company">
                                <option value="">Select Company</option>
                                <option *ngFor="let company of companyListFilter;" value={{company.company_Id}}>
                                    {{company.company_Name}}</option>
                            </select>


                        </div>
                    </div>
                </li>
                <li>
                    <div class="col-md-6 dropSelect_company">
                        <div class="input-group input-xlarge">
                            <div class="input-group-btn">
                                <button type="button" class="btn blue dropdown-toggle" data-toggle="dropdown">Search By
                                    Site
                                </button>

                            </div>

                            <select id="Site_Id_Filter" class="form-control siteSelectListClassFilter select_company">
                                <option value="">Select Site</option>
                                <option *ngFor="let item of siteListFilter;" value={{item.site_Id}}>
                                    {{item.site_Name}}</option>
                            </select>


                        </div>
                    </div>
                </li>

                <li>
                    <div class="col-md-6 dropSelect_company">
                        <div class="input-group input-xlarge">
                            <div class="input-group-btn">
                                <button type="button" class="btn blue dropdown-toggle" data-toggle="dropdown">Search By
                                    Department
                                </button>

                            </div>

                            <select id="Dept_Id_Filter"
                                class="form-control departmentSelectListClassFilter select_company">
                                <option value="">Select Department</option>
                                <option *ngFor="let item of departmentListFilter;" value={{item.dept_Id}}>
                                    {{item.dept_Name}}</option>
                            </select>

                        </div>
                    </div>
                </li>

                <li *ngIf="currentUserRole?.isAdd == 1">
                    <div>
                        <a class="btn btn-transparent blue  btn-sm" data-toggle="modal"
                            (click)="clear(true,true); popupTitle();" href="#Createrecruitment">Create Approval
                            Process</a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->


        <div class="col-md-12">
            <div class="portlet light bordered" id="form_wizard_1">
                <p style="margin: 5px 0;">&nbsp;</p>
                <div class="portlet-body form">

                    <form class="form-horizontal" action="#" id="submit_form" method="POST">
                        <div class="form-group">
                            <div class="col-md-12">
                                <!-- BEGIN SAMPLE TABLE PORTLET-->
                                <div class="portlet box white">
                                    <div class="portlet-body table-responsive" id="rafTable">
                                        <diV class="row">

                                            <div class="col-md-9 col-sm-6">
                                                <div class="dataTables_length" id="sample_4_length">
                                                    <label>
                                                        <select name="myApprovallist_length" id="myApprovallist_length"
                                                            aria-controls="sample_4"
                                                            class="form-control input-sm input-xsmall input-inline"
                                                            (change)="myAprrovalSearchOneValue('onLoad', 0)">
                                                            <option value="10">10</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                        </select>
                                                        records
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="col-md-3 text-right">
                                                <input type="search" class="form-control"
                                                    placeholder="Type to filter ..." id="filterList"
                                                    (input)="myApprovalServerPagination(1)">
                                            </div>

                                        </diV>
                                        <br />
                                        <table id="example2" class="display table table-striped table-bordered"
                                            cellspacing="0" width="100%" style="border:1px solid #e7ecf1;">
                                            <thead>
                                                <tr>
                                                    <th style="width: 2%;"></th>
                                                    <th class="text-center" style="width: 25%">Approval Process</th>
                                                    <th class="text-center" style="width: 20%">Company</th>
                                                    <th class="text-center" style="width: 15%">Site</th>
                                                    <th class="text-center" style="width: 5%">Department</th>

                                                    <th class="text-center" style="width: 10%">Requester</th>
                                                    <th class="text-center" style="width: 10%"> Type</th>
                                                    <th class="text-center" style="width: 8%">Budgeted</th>

                                                    <th class="text-center" style="width: 5%">No of Approvers</th>
                                                    <!-- <th class="text-center">Name of Recruiter</th>
                                                    <th class="text-center">Created On</th>
                                                    <th class="text-center">Created By</th> -->
                                                    <th style="width: 2%;" class="text-center">Action</th>
                                                </tr>
                                            </thead>

                                            <tbody
                                                *ngFor="let item of myApprovallist | paginate: { itemsPerPage: itemsPerPage, currentPage: p ,totalItems :totalItems1, id: 'pagination1' }">
                                                <tr id="{{item.id}}" role="row" class="odd"
                                                    title="{{item.vacancy_Rule_Id}}" tabindex="{{item.company_Id}}">
                                                    <td class="details-control details-control-icon-{{item.id}}"
                                                        (click)="getRules(item.id)"></td>
                                                    <td class="sorting_1" style="  width: 150px;">

                                                        <div class="col-md-1 col-xs-2">
                                                            <a (click)="updateStatus(item.id,'0')">
                                                                <div class="circle greenColor" *ngIf="item.isActive"
                                                                    data-toggle="tooltip" data-placement="top"
                                                                    title="Active"></div>
                                                            </a>
                                                            <a (click)="updateStatus(item.id,'1')">
                                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                                    data-toggle="tooltip" data-placement="top"
                                                                    title="Inactive"></div>
                                                            </a>

                                                        </div>
                                                        <div class="col-md-1 col-xs-10 text-wrap">
                                                            <aside data-toggle="tooltip" data-placement="bottom"
                                                                title="{{item.name}}" class="text-center">
                                                                {{ item.name != null ? (item.name.length>18)? (item.name
                                                                | slice:0:18)+'...':(item.name) : item.name}}
                                                            </aside>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <aside data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.company_Name}}" class="text-center">
                                                            {{ item.company_Name != null ?
                                                            (item.company_Name.length>18)? (item.company_Name |
                                                            slice:0:18)+'...':(item.company_Name) : item.company_Name}}
                                                        </aside>
                                                    </td>
                                                    <td class="text-center"> {{item.site_Name}}</td>
                                                    <!-- <td class="text-center"> {{item.dept_Name}}</td> -->
                                                    <td class="text-center" title="{{item.multi_Dept_Name}}"> {{item.multi_Dept_Name ? item.multi_Dept_Name.split(',').length : 0}}</td>
                                                    <td class="text-center"> {{item.person_Name}}</td>
                                                    <td class="text-center"> {{item.display_Type}}</td>
                                                    <td class="text-center" *ngIf="item.budgeted  == 1">Yes</td>
                                                    <td class="text-center" *ngIf="item.budgeted  == 0">No</td>
                                                    <td class="text-center"> {{item.rule_Detail_Count}}</td>
                                                    <!-- <td>
                                                        <aside data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.rule_Recruiter_Name}}" class="text-center">
                                                            {{ item.rule_Recruiter_Name != null ?
                                                            (item.rule_Recruiter_Name.length>12)?
                                                            (item.rule_Recruiter_Name |
                                                            slice:0:12)+'...':(item.rule_Recruiter_Name) :
                                                            item.rule_Recruiter_Name}}
                                                        </aside>
                                                    </td>
                                                    <td class="text-center"> {{item.addedOn | date: 'dd/MM/yyyy'}}</td>
                                                    <td class="text-center">
                                                        <aside data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.addedBy_Name}}" class="text-center">
                                                            {{ item.addedBy_Name != null ?
                                                            (item.addedBy_Name.length>12)? (item.addedBy_Name |
                                                            slice:0:12)+'...':(item.addedBy_Name) : item.addedBy_Name}}
                                                        </aside>
                                                    </td> -->
                                                    <td class="text-center">
                                                        <!-- <a *ngIf="item.isActive" data-toggle="modal"
                                                            href="#Createrecruitment"
                                                            (click)="editRules(item.id,item.company_Id,item.site_Id,item.dept_Id)"><i
                                                                class="fa fa-pencil iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit"></i></a> -->
                                                        <a *ngIf="item.isActive && currentUserRole?.isEdit == 1"
                                                            data-toggle="modal" href="#Createrecruitment"
                                                            (click)="editRecord(item.id)"><i
                                                                class="fa fa-pencil iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit"></i></a>

                                                        <a *ngIf="!item.isActive" (click)="rafConfigDelete(item.id)"><i
                                                                class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete RAF"></i></a>
                                                    </td>
                                                </tr>
                                                <tr class="sub_tbl sub_tbl_{{item.id}}" style="display:none;">
                                                    <td colspan="10">
                                                        <table cellpadding="5" cellspacing="0" style="width:80%;"
                                                            class="table table-striped table-bordered dataTable no-footer">
                                                            <thead>
                                                                <tr>
                                                                    <th>Job Title</th>
                                                                    <th>Approver Name</th>
                                                                    <th>Final Approver</th>
                                                                    <th>Priority</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let items of rulesList">
                                                                    <td>{{items.desig_Name}}</td>
                                                                    <td>{{items.emp_Name}}</td>
                                                                    <td>{{items.isFinal === 1 ? 'Yes' : 'No'}}</td>
                                                                    <td>{{items.priority}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-center" *ngIf="myApprovallist!= null">
                                        <pagination-controls (pageChange)="myApprovalServerPagination($event)"
                                            autoHide="true" id="pagination1"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- END PAGE BASE CONTENT -->
    </div>
</div>




<div class="modal fade draggable-modal" id="Createrecruitment" role="basic" aria-hidden="true">
    <div class="modal-dialog" style="margin: auto !important;width:90%;display: flex;align-items: center;height: 100%;">
        <div class="moel-body">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">X</button>
                    <h4 class="modal-title">{{modalTitle}}</h4>
                </div>
                <div class="modal-body">
                    <form [formGroup]="vacancyRuleForm">
                        <div class="row">

                            <div class="col-md-3">
                                <div class="form-group">
                                    <label> Approval Process Name <span class="required"> * </span></label>
                                    <input id="name" type="text" name="role" formControlName="name"
                                        placeholder="Approval Process Name" class="form-control" maxlength="30"
                                        autocomplete="off">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label> Company <span class="required"> * </span></label>

                                    <select id="Company_Id" class="form-control companySelectListClass drp"
                                        formControlName="Company_Id">
                                        <option value="">Select Company</option>
                                        <option *ngFor="let company of companyList;" value={{company.company_Id}}>
                                            {{company.company_Name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" id="sitebody">
                                    <label> Requester </label>
                                    <select id="person_Id" class="form-control drp drppersonId"
                                        formControlName="person_Id">
                                        <option value="">Select Employee</option>
                                        <option *ngFor="let item of employeeList;" value={{item.person_Id}}>
                                            {{item.firstName}} {{item.lastName}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" id="sitebody">
                                    <label> Site</label>
                                    <select id="site_Id" class="form-control siteSelectListClass drp"
                                        formControlName="site_Id">
                                        <option value="">Select Site</option>
                                        <option *ngFor="let item of siteList;" value={{item.site_Id}}>
                                            {{item.site_Name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                        
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label> Department</label>
                                    <!-- <select id="dept_Id" class="form-control drp" formControlName="dept_IdList">
                                        <option value="">Select Department</option>
                                        <option *ngFor="let item of departmentList;" value={{item.dept_Id}}>
                                            {{item.dept_Name}}</option>
                                    </select>  -->

                                    <ng-select2 [disabled]="isDisabled" 
                                        [data]="multidepartmentList"
                                        [options]="{ multiple: true, tags: true }"
                                        [placeholder]="'Select Department '"
                                        [value]='this.vacancyRuleForm.controls.dept_IdList.value'
                                         formControlName="dept_IdList">
                                        <!-- <option disabled>Select Department</option>
                                        <option *ngFor="let department of departmentList" [value]="department.dept_Id">
                                            {{ department.dept_Name }}</option> -->
                                    </ng-select2>
                                </div>


                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label> Position Type</label>
                                    <select id="Type" class="form-control drp select">
                                        <option value="">Select Position Type</option>
                                        <option value="NewVacancy">New Vacancy</option>
                                        <option value="InterimCover">Interim Cover</option>
                                        <option value="Replacement">Replacement</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label> Assigned to HR Recruiter <span class="required"> * </span></label>
                                    <select id="emp_Id" class="form-control drp">
                                        <option value="">Select Assigned to HR Recruiter</option>
                                        <option *ngFor="let item of hrList;" value={{item.emp_Id}}>
                                            {{item.firstName}} {{item.lastName}}</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div class="col-md-2">
                                <div class="form-group" id="Budgeted">
                                    <label class="control-label">Budgeted</label>

                                    <div class="md-radio-inline"
                                        style="margin: 0 0 !important; border-top: 1px solid black;">
                                        <div class="md-radio" style="margin-top: 5px;">
                                            <input type="radio" id="radio53" name="budgeted" formControlName="budgeted"
                                                value="1" class="md-radiobtn">
                                            <label for="radio53">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span>Yes</label>
                                        </div>
                                        <div class="md-radio" style="margin-top: 5px;">
                                            <input type="radio" id="radio59" name="budgeted" formControlName="budgeted"
                                                value="0" class="md-radiobtn">
                                            <label for="radio59">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> No </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1">
                                <div class="form-group">
                                    <button type="button" style="margin-top: 22px;" class="btn pull-right blue"
                                        [disabled]="!addrolevisible" (click)="upsert(0)">Add
                                        Rule</button>
                                </div>
                            </div>

                            
                        </div>
                       
                          <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label> Created By <span class="required"> * </span></label>
                                      
                                    <input id="created_by" type="text" name="created_by" 
                                    placeholder="Created By" class="form-control" value={{this.createdname}}
                                    autocomplete="off" disabled>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label> Created On <span class="required"> * </span></label>
                                    <input id="created_on" type="text" name="created_on"
                                        placeholder="Created On" class="form-control"  value="{{this.addedOn | date: 'dd/MM/yyyy'}}"
                                        autocomplete="off" disabled>
                                </div>
                            </div>
                          </div>
                        
                    </form>
               
                   
                    <!-- <div class="row">
                        <div class="col-md-12">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="acrossCompany" class="md-check">
                                    <label for="acrossCompany">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span>Across Company</label>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div>
                            <div class="col-md-3 pdl70">
                                Job Title
                            </div>
                            <!-- <div class="col-md-3 pdl51">
                                Rule
                            </div> -->
                            <div class="col-md-3 pdl51">
                                Name of Approver
                            </div>
                            <div class="col-md-2 pdl9 text-center">
                                Final Approver
                            </div>
                            <div class="col-md-2 text-center">
                                Across Company
                            </div>
                            <div class="col-md-1 pdr45">
                                <button type="button" class="btn pull-right green addMorefields"
                                    [disabled]="addrolevisible" (click)="addvacancyRuleDetail()"><i class="fa fa-plus"
                                        aria-hidden="true"></i></button>
                            </div>

                        </div>
                    </div>
                    <div class="scroller" style="height:420px" data-always-visible="1" data-rail-visible1="1">
                        <form [formGroup]="vacancyRuleForm">
                            <ol class="dd-list">
                                <li class="dd-item dd3-item" formArrayName="vacancy_rule_detail"
                                    *ngFor="let item of vacancyRuleForm.get('vacancy_rule_detail')['controls']; let i = index;">
                                    <div [formGroupName]="i">
                                        <div class="dd-handle dd3-handle" (click)="changeIndex(i)"
                                            style="height: 46px;"></div>

                                        <div class="dd3-content">
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <select class="form-control selectDesignationClass"
                                                        id="Desig_Id{{i}}" formControlName="Desig_Id"
                                                        (change)="onChangeDesignation($event.target.value,i,'')">

                                                        <option value="">Select Job Title</option>
                                                        <option *ngFor="let item of designationList;"
                                                            value={{item.desig_Id}}>
                                                            {{item.desig_Name}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-md-3"> <select id="Employee"
                                                    class="form-control select_employee" id="Emp_Id{{i}}"
                                                    formControlName="Emp_Id">
                                                    <option value="">Select Employee</option>
                                                </select> </div>
                                            <div class="col-md-2 pdl30 text-center"><label class="mt-checkbox"
                                                    style="margin-top: 7px;"><input type="checkbox"
                                                        id="isfinalcheck{{i}}" class="isfinalcheck" value="option2"
                                                        (click)='changeFinalCheckBox(i)' formControlName="isFinal">
                                                    &nbsp;<span></span></label></div>
                                            <div class="col-md-2 pdl30 text-center"><label class="mt-checkbox"
                                                    style="margin-top: 7px;"><input type="checkbox"
                                                        id="acrossCompany{{i}}" class="md-check"
                                                        formControlName="acrossCompany"
                                                        (change)="filllAcorssCompanydrp(i)">&nbsp;<span></span></label>
                                            </div>
                                            <div class="col-md-1"><button type="button"
                                                    (click)="removevacancyRuleDetail(i)"
                                                    class="btn pull-right red removefields"><i class="fa fa-minus"
                                                        aria-hidden="true"></i></button></div>
                                        </div>
                                    </div>
                                </li>
                            </ol>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn light btn-outline">Cancel</button>
                <button type="button" class="btn blue" (click)="upsert(1)" [disabled]="addrolevisible">Save</button>
            </div>
        </div>
    </div>
</div>