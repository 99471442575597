<!-- <img src="assets/layouts/layout4/company_logo/logo-light.png"> -->
<style>
    @media (max-width:767px) {
        p.mobwidth {
            font: 33px Times New Roman, sans-serif !important;
            color: maroon;
            word-break: break-all !important;
        }
        p.titlewidth{
            font: 60px Times New Roman, sans-serif !important;
            color: maroon;
            word-break: break-all !important;
        }
    }
</style>

<div style="background-color: #ff996680; height: 100vh;">

    <p class="text-center titlewidth" style="font:150px Times New Roman, sans-serif;color: #333;margin: 0;">405</p>
    <p class="text-center mobwidth" style="font:100px Times New Roman, sans-serif;color: #333 ">Access Permission Denied</p>
    <div class="text-center"><a href="/#/dashboard">
            <p class="text-center" style="font:50px Times New Roman, sans-serif;color:#0781d5 ">Back To Dashboard</p>
        </a></div>

</div>