<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Zone List
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a (click)="id=0;modalOpen =true" class="btn btn-transparent blue  btn-sm">Add
                            Zone</a>
                    </div>
                </li>
            </ul>
        </div>

        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" id="filterList" placeholder="Type to filter..." (input)="getZoneList(1)">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblZone">
                            <thead class="flip-content">
                                <tr>
                                    <th class="text-center"> Company </th>
                                    <th class="text-center">Zone</th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let zone of zonelist| paginate: { itemsPerPage: 10, currentPage: p ,totalItems :totalRecord}">

                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(zone.zone_Id,'0')">
                                                <div class="circle greenColor" *ngIf="zone.isActive" data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(zone.zone_Id,'1')">
                                                <div class="circle redColor" *ngIf="!zone.isActive" data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12">{{zone.company_Name}}
                                        </div>
                                    </td>
                                    <td>{{zone.zone_Name}}</td>
                                    <td class="text-center">
                                        <a *ngIf="zone.isActive" (click)="id=zone.zone_Id;modalOpen =true"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Zone"></i></a>
                                        <a *ngIf="!zone.isActive" (click)="zoneDelete(zone.zone_Id)">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Delete Zone"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="zonelist!= null">
                        <pagination-controls (pageChange)="getZoneList($event)" autoHide="true"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->



<div *ngIf="modalOpen">
    <app-zone-form [pId]="id" (modalClose)="modalOpen = $event" (refreshPage)="getZoneList($event)"></app-zone-form>
</div>