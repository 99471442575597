<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>Company
                </h1>
            </div>
        </div>

        <ul class="page-breadcrumb breadcrumb mb20">
            <!-- <li>
                <a routerLink="/dashboard"><i class="fa fa-tachometer"></i> Dashboard</a>
            </li> -->
        </ul>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <div class="portlet light bordered" id="form_wizard_1">
                <div class="portlet-title">
                    <div class="caption">
                        <i class="fa fa-building font-red"></i>
                        <span class="caption-subject font-red bold uppercase"> {{title}} Company -
                            <span class="step-title"> Step {{activeWizard}} of 4 </span>
                        </span>
                    </div>
                    <div class="caption pull-right">
                        <a routerLink="/companylist"><i class="fa fas fa-reply" data-toggle="tooltip" data-placement="top" title="Back To Overview"></i></a>&nbsp;&nbsp;
                        <a class="btn  dark btn-outline" (click)="history(editCompany)"
                            *ngIf="editCompany > 0"><i class="fa fa-history fa-1x"></i></a>
                    </div>
                </div>
                <div class="portlet-body form">
                    <form class="form-horizontal" action="#" id="submit_form" method="POST">
                        <div class="form-wizard">
                            <div class="form-body">
                                <ul class="nav nav-pills nav-justified steps">
                                    <li [class]="w1">
                                        <a class="step">
                                            <span class="number" (click)="moveWizard(1)"> 1 </span>
                                            <span class="desc" (click)="moveWizard(1)">
                                                <i class="fa fa-check"></i> Basic Info </span>
                                        </a>
                                    </li>
                                    <li [class]="w2">
                                        <a class="step active">
                                            <span class="number" (click)="moveWizard(2)"> 2 </span>
                                            <span class="desc" (click)="moveWizard(2)">
                                                <i class="fa fa-check"></i> Settings </span>
                                        </a>
                                    </li>
                                    <li [class]="w3">
                                        <a class="step active">
                                            <span class="number" (click)="moveWizard(3)"> 3 </span>
                                            <span class="desc" (click)="moveWizard(3)">
                                                <i class="fa fa-check"></i> Contact </span>
                                        </a>
                                    </li>
                                    <li [class]="w4">
                                        <a class="step active">
                                            <span class="number" (click)="moveWizard(4)"> 4 </span>
                                            <span class="desc" (click)="moveWizard(4)">
                                                <i class="fa fa-check"></i> Configuration </span>
                                        </a>
                                    </li>
                                </ul>
                                <div id="bar" class="progress progress-striped" role="progressbar">
                                    <div class="progress-bar progress-bar-success" id="progress-bar"
                                        [style.width]="progressBarWidth"> </div>
                                </div>
                                <div class="tab-content">
                                    <div class="alert alert-danger display-none">
                                        <button class="close" data-dismiss="alert"></button> You have some form errors.
                                        Please check below. </div>
                                    <div class="alert alert-success display-none">
                                        <button class="close" data-dismiss="alert"></button> Your form validation is
                                        successful! </div>
                                    <div [class]="t1" id="tab1">
                                        <app-companybasicinfo></app-companybasicinfo>
                                    </div>
                                    <div [class]="t2" id="tab2">
                                        <app-companysetting></app-companysetting>
                                    </div>
                                    <div [class]="t3" id="tab3">
                                        <app-companycontact></app-companycontact>
                                    </div>
                                    <div [class]="t4" id="tab5">
                                        <app-companyemailconfig></app-companyemailconfig>
                                    </div>
                                </div>
                            </div>
                            <div class="form-actions">
                                <div class="row">
                                    <div class="col-md-12 text-right">
                                        <a (click)="wizardSetting('back')" class="btn default"
                                            [style.visibility]="backbutton">
                                            <i class="fa fa-angle-left"></i> Back </a>&nbsp;&nbsp;
                                        <a (click)="companyUpsert()"
                                            class="btn btn-outline blue">{{btnName}} <i *ngIf="btnName != 'Save'"
                                                class="fa fa-angle-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- END PAGE BASE CONTENT -->

    </div>
    <!-- END CONTENT BODY -->
</div>
<div class="modal fade draggable-modal" id="historyModal" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Company History</h4>
            </div>
            <div class="modal-body" *ngIf="editCompany > 0">
                <app-history></app-history>
            </div>
        </div>
    </div>
</div>