<!-- <ngx-spinner  bdColor="#bfbfbf" color="#00cccc"></ngx-spinner> -->
<ngx-spinner type="ball-spin-clockwise" size="large" color="#00cccc" zIndex="1020"></ngx-spinner>
<app-header
    *ngIf="router.url != '/' && router.url != '/signup' && router.url != '/home' && router.url != '/405' &&
     router.url != '/frocechangepassword' && router.url != '/session_expired' && router.url != '/company-selection' &&!this.router.url.includes('resetpassword') ">
</app-header>

<!-- <app-left-menu *ngIf="router.url != '/'"></app-left-menu> -->

<app-left-menu
    *ngIf="router.url != '/signup' && router.url != '/home' && router.url != '/candidateedit' && router.url != '/405' 
     && router.url != '/frocechangepassword' && router.url != '/session_expired' && router.url != '/company-selection'  &&!this.router.url.includes('resetpassword')">
</app-left-menu>

<!-- <app-home *ngIf="router.url == '/'"></app-home> -->

<router-outlet></router-outlet>

<app-footer
    *ngIf="router.url != '/' && router.url != '/home' && router.url != '/405'&& router.url != '/401' && router.url != '/frocechangepassword' 
     && router.url != '/session_expired' && router.url != '/company-selection'  &&!this.router.url.includes('resetpassword') ">
</app-footer>