import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import Swal from 'sweetalert2';
import { templateJitUrl } from '@angular/compiler';
import { ConcatSource } from 'webpack-sources';
import { flatMap } from 'rxjs/operators';
declare var $: any;


@Component({
  selector: 'app-Menupermission',
  templateUrl: './menupermission.component.html'
})
export class MenupermissionComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  roleList: any;
  module: any[];
  baseModuleList: any[];
  filterModuleList: any[] = [];
  p: 1;




  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private fb: FormBuilder) { }
  ngOnInit() {
    this.getRoles()
  }

  getRoles() {
    this.commonApiService.List(Commonvariable.Roles).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.roleList = result["data"].filter(x => x.isActive != 0);
        this.getRoles_Permission(0);
      }
    })
  }

  getRoles_Permission(id) {
    this.baseModuleList = [];
    this.module = []
    this.p = 1
    if (id > 0) {
      this.commonApiService.getById(Commonvariable.Menu, id, "GetMenuByRoleId").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.baseModuleList = result["data"];
          this.moduleList(result["data"]["permission"]);
        }
      })
    }
  }


  moduleList(list) {
    var array = [];
    this.module = [];
    var index = 0;

    list.forEach(m => {
      var data = {
        "id": index,
        "moduleName": m.menu_Name,
        "add": m.add > 0 ? true : false,
        "edit": m.edit > 0 ? true : false,
        "delete": m.delete > 0 ? true : false,
        "view": m.view > 0 ? true : false,
        "selectall": m.add > 0 && m.edit > 0 && m.delete > 0 && m.view > 0 ? true : false
      }
      array.push(data);
      index++;
    });
    this.module = array
    this.filterModuleList = array
    if (this.module.filter(x => x.selectall == true).length == this.module.length) {
      $("#checkall").prop("checked", true)
    }
    else {
      $("#checkall").prop("checked", false)
    }
  }

  checkAll(index, checkbox) {

    let moduleIndex = this.module.findIndex(x => x.id == index);
    let filterModuleIndex = this.filterModuleList.findIndex(x => x.id == index);
    let baseModuleIndex = this.baseModuleList["permission"].findIndex(x => x.id == index);
    
    if (checkbox == 'selectall') {


      this.module[moduleIndex].add = $("#selectall" + index).prop("checked")
      this.module[moduleIndex].edit = $("#selectall" + index).prop("checked")
      this.module[moduleIndex].delete = $("#selectall" + index).prop("checked")
      this.module[moduleIndex].view = $("#selectall" + index).prop("checked")

      

      this.filterModuleList[filterModuleIndex].add = $("#selectall" + index).prop("checked")
      this.filterModuleList[filterModuleIndex].edit = $("#selectall" + index).prop("checked")
      this.filterModuleList[filterModuleIndex].delete = $("#selectall" + index).prop("checked")
      this.filterModuleList[filterModuleIndex].view = $("#selectall" + index).prop("checked")


      this.baseModuleList["permission"][filterModuleIndex]["add"] = $("#selectall" + index).prop("checked") ? 1 : 0
      this.baseModuleList["permission"][filterModuleIndex]["edit"] = $("#selectall" + index).prop("checked") ? 1 : 0
      this.baseModuleList["permission"][filterModuleIndex]["delete"] = $("#selectall" + index).prop("checked") ? 1 : 0
      this.baseModuleList["permission"][filterModuleIndex]["view"] = $("#selectall" + index).prop("checked") ? 1 : 0

      this.module[moduleIndex].selectall = $("#selectall" + index).prop("checked")
    }
    else if (checkbox == 'checkall') {
      for (const key of Object.keys(this.module)) {
        this.module[key].add = $("#checkall").prop("checked")
        this.module[key].edit = $("#checkall").prop("checked")
        this.module[key].delete = $("#checkall").prop("checked")
        this.module[key].view = $("#checkall").prop("checked")
        this.module[key].selectall = $("#checkall").prop("checked")
      }

      for (const key of Object.keys(this.filterModuleList)) {
        this.filterModuleList[key].add = $("#checkall").prop("checked")
        this.filterModuleList[key].edit = $("#checkall").prop("checked")
        this.filterModuleList[key].delete = $("#checkall").prop("checked")
        this.filterModuleList[key].view = $("#checkall").prop("checked")
        this.filterModuleList[key].selectall = $("#checkall").prop("checked")
      }

      for (const key of Object.keys(this.baseModuleList["permission"])) {
        this.baseModuleList["permission"][key]["add"] = $("#checkall").prop("checked") ? 1 : 0
        this.baseModuleList["permission"][key]["edit"] = $("#checkall").prop("checked") ? 1 : 0
        this.baseModuleList["permission"][key]["delete"] = $("#checkall").prop("checked") ? 1 : 0
        this.baseModuleList["permission"][key]["view"] = $("#checkall").prop("checked") ? 1 : 0
      }
    }
    else {
      this.module[moduleIndex][checkbox] = $("#" + checkbox + index).prop("checked")
      this.filterModuleList[filterModuleIndex][checkbox] = $("#" + checkbox + index).prop("checked")
      this.baseModuleList["permission"][filterModuleIndex][checkbox] = $("#" + checkbox + index).prop("checked") ? 1 : 0

      if ($("#add" + index).prop("checked") && $("#edit" + index).prop("checked") && $("#delete" + index).prop("checked") && $("#view" + index).prop("checked")) {
        this.module[moduleIndex].selectall = true
        this.filterModuleList[filterModuleIndex].selectall = true

        $("#selectall" + index).prop("checked", true)
      }
      else {
        this.module[moduleIndex].selectall = false
        this.filterModuleList[filterModuleIndex].selectall = false

        $("#selectall" + index).prop("checked", false)
      }
    }

    if (this.module.filter(x => x.selectall == true).length == this.module.length) {
      $("#checkall").prop("checked", true)
    }
    else {
      $("#checkall").prop("checked", false)
    }
  }

  addPermission() {

    if (this.baseModuleList.length != 0) {
      if (this.baseModuleList["permission"].length > 0) {
        this.baseModuleList["addedBy"] = Commonvariable.User_Id
        this.commonApiService.PostRequest(Commonvariable.Menu, "AddMenu_Permission", this.baseModuleList).subscribe((result) => {
          this.p = 1
          this.getRoles_Permission($("#roleid").val());
          this.commonService.toaster("success", "Record saved successfully !", "User Role");
        })
      }
    }
  }

  filterModulePermission(wordFind) {
    if (wordFind != "") {
      this.module = this.filterModuleList.filter(x => String(x.moduleName).toLowerCase().trim().includes(String(wordFind).toLowerCase().trim()))
    }
    else {
      this.module = this.filterModuleList
    }
    this.p = 1;
  }



}
