import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import Swal from 'sweetalert2';
import { templateJitUrl } from '@angular/compiler';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-vacancy',
  templateUrl: './vacancy.component.html'
})
export class VacancyComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  vacancyform: FormGroup;
  vacancylist: any;
  filterList: any;
  listCall: boolean = true;
  companylist: any;
  siteList: any;
  departmentList: any;
  title: string;
  Toast: any;
  p: number = 1;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) { }
  ngOnInit() {
    this.title = "Add";
    this.vacancyform = this.fb.group({
      id: ['0'],
      company_Id: ['', Validators.required],
      site_Id: ['', Validators.required],
      dept_Id: ['', Validators.required],
      jobTitle: ['', Validators.required],
      noOfPositions: ['', Validators.required],
      validFrom: ['', Validators.required],
      validUpto: ['', Validators.required],
      description: [''],
      notes: [''],
      isActive: [true],
      addedBy: [Commonvariable.User_Id],
      updatedBy: ['0']
    });
    this.commonService.flatpickr("validFrom,#validUpto", 0);
    if (this.listCall) {
      this.getVacancyList();
      this.getCompanyList();
    }


  }

  getVacancyList() {
    this.commonApiService.List(Commonvariable.Vacancy).subscribe((result) => {
      if (result != null && result["status"] == 200) {
        this.vacancylist = result["data"];
        this.filterList = result["data"];
      }
    }, (error) => { this.toastr.error(error["error"].message, 'Vacancy'); })
  }

  getCompanyList() {
    this.commonApiService.List(Commonvariable.Company).subscribe((response) => {
      if (response != null && response["status"] == 200) {
        this.companylist = response["data"];
        //this.companylist = response["data"].filter(x => x.isActive != 0);
      }
    }, (error) => { this.toastr.error(error["error"].message, 'Vacancy'); })
  }

  getSite(cId = 0) {
    this.commonApiService.cascadingDropDwon(Commonvariable.Site, "company_Id", cId > 0 ? cId : $("#company_Id").val()).subscribe((response) => {
      if (response != null) {
        this.siteList = response["data"];
        this.getDepartment();
      }
    }, (error) => { this.toastr.error(error["error"].message, 'Vacancy'); })
  }

  getDepartment() {
    this.commonApiService.cascadingDropDwon(Commonvariable.Department, "company_Id", $("#company_Id").val()).subscribe((response) => {
      if (response != null) {
        this.departmentList = response["data"];
      }
    }, (error) => { this.toastr.error(error["error"].message, 'Vacancy'); })
  }


  Upsert() {
    var formData = this.vacancyform;
    if (this.validation()) {
      formData.get("isActive").setValue(formData.get("isActive").value ? 1 : 0);
      this.commonApiService.Upsert(Commonvariable.Vacancy, formData.value, formData.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Vacancy');
          }
          else {
            $("#draggable").modal('hide');
            // $('a[href="#docUpload"]').click();
            this.getVacancyList();
            this.toastr.success(formData.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Vacancy');
          }
        }
      })
    }

  }

  Delete(id) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Vacancy, id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getVacancyList();
          this.toastr.success(response["message"], 'Vacancy');
        })
      }
    })
  }

  openModel(id) {
    if (id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Vacancy, id).subscribe((response) => {
        if (response != null) {
          var editData = response["data"];
          var formData = this.vacancyform;
          this.commonService.setEditData(formData, editData);
          let validFrom = response["data"][0].validFrom != null ? response["data"][0].validFrom.split("T")[0].split("-") : "";
          let validUpto = response["data"][0].validUpto != null ? response["data"][0].validUpto.split("T")[0].split("-") : "";
          if (validFrom != "") {
            $("#validFrom").val(validFrom[2] + "-" + validFrom[1] + "-" + validFrom[0]);
          }
          if (validUpto != "") {
            $("#validUpto").val(validUpto[2] + "-" + validUpto[1] + "-" + validUpto[0]);
          }

          this.getSite(editData[0].company_Id);
          formData.controls.updatedBy.setValue(Commonvariable.User_Id)
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Zone'); })
    }
    else {
      this.listCall = false;
      this.siteList = [];
      this.departmentList = [];
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });


  }

  filterData(event) {
    const val = event.target.value;
    if (val == "") {
      this.vacancylist = this.filterList;
    }
    else {
      const temp = this.commonService.filterData(event, this.vacancylist, "jobTitle");
      this.vacancylist = temp;
      this.p = 1;
    }
  }

  validation() {
    var formData = this.vacancyform;
    formData.controls.validFrom.setValue(this.commonService.dateFormatChange($("#validFrom").val()));
    formData.controls.validUpto.setValue(this.commonService.dateFormatChange($("#validUpto").val()));
    if (!formData.valid) {
      for (const key of Object.keys(formData.controls)) {
        if (formData.controls[key].invalid) {
          let fieldName = "";
          if (key == "company_Id") {
            fieldName = "Company"
          }
          else if (key == "site_Id") {
            fieldName = "Site"
          }
          else if (key == "dept_Id") {
            fieldName = "Department"
          }
          else if (key == "jobTitle") {
            fieldName = "Job Title OR Valid Job Title"
          }
          else if (key == "noOfPositions") {
            fieldName = "No of positions"
          }
          else if (key == "validFrom") {
            fieldName = "Valid from"
          }
          else if (key == "validUpto") {
            fieldName = "Valid Up to"
          }
          this.toastr.info(fieldName + " is required !", "Vacancy");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if (moment($("#validFrom").val(), 'DD-MM-YYYY') >= moment($("#validUpto").val(), 'DD-MM-YYYY')) {
        this.toastr.info("valid from date not more then valid up to date !", "Vacancy");
        $("#validFrom").focus();
        return false;
      }
      //   if ($("#mobile").val().length < 10) {
      //     this.toastr.info("Mobile must be at least 10 characters long !", "Candidate");
      //     $("#mobile").focus();
      //     return false;
      //   }
      //   if ($("#mobile").val().search(/[^0-9]+/) > -1) {
      //     this.toastr.info("Mobile only numeric value allow !", "Candidate");
      //     $("#mobile").focus();
      //     return false;
      //   }
      //   if ($("#eMail").val().length < 2) {
      //     this.toastr.info("Email must be at least 2 characters long !", "Candidate");
      //     $("#eMail").focus();
      //     return false;
      //   }
      //   if ($("#firstName").val().length < 2) {
      //     this.toastr.info("First name must be at least 2 characters long !", "Candidate");
      //     $("#firstName").focus();
      //     return false;
      //   }
      //   if ($("#firstName").val().search(/[^a-zA-Z]+/) > -1) {
      //     this.toastr.info("First name only alphabets value allow !", "Candidate");
      //     $("#firstName").focus();
      //     return false;
      //   }
      //   if ($("#middleName").val().length < 1) {
      //     this.toastr.info("Middle name must be at least 1 characters long !", "Candidate");
      //     $("#middleName").focus();
      //     return false;
      //   }
      //   if ($("#middleName").val().search(/[^a-zA-Z]+/) > -1) {
      //     this.toastr.info("Middle name only alphabets value allow !", "Candidate");
      //     $("#middleName").focus();
      //     return false;
      //   }
      //   if ($("#lastName").val().length < 2) {
      //     this.toastr.info("Last name must be at least 2 characters long !", "Candidate");
      //     $("#lastName").focus();
      //     return false;
      //   }
      //   if ($("#lastName").val().search(/[^a-zA-Z]+/) > -1) {
      //     this.toastr.info("last name only alphabets value allow !", "Candidate");
      //     $("#lastName").focus();
      //     return false;
      //   }

    }
    return true;
  }

  history() {
    //this.historyView.getHistoryList('\"vacancy\"','Id',this.vacancyform.value.id);
  }



}
