<style type="text/css">
    .inputaddon>i {
        color: #9b9da1 !important;
    }

    .bg-white {
        background: #fff !important;
    }

    .btn {
        max-width: 125 px !important;
    }

    #form_wizard_1 #submit_form .mt-element-step .step-line .done .mt-step-content,
    .mt-element-step .step-line .done .mt-step-title {
        background-color: none !important;
        color: none !important;
        background: none !important;
    }

    #form_wizard_1 #submit_form .mt-element-step .step-line .done .mt-step-title::after,
    .mt-element-step .step-line .done .mt-step-title::before {
        background-color: none !important;
        background: none !important;
    }

    #form_wizard_1 #submit_form .mt-element-step .step-line .mt-step-title::after,
    .mt-element-step .step-line .mt-step-title::before {
        content: ' ' !important;
        background-color: none !important;
        background: none !important;
    }

    #form_wizard_1 #submit_form .mt-element-step .step-line .mt-step-title::after,
    .mt-element-step .step-line .mt-step-title::after {
        content: ' ' !important;
        background-color: none !important;
        background: none !important;
    }

    td.details-control {
        background: url('assets/pages/img/SD7Dz.png') no-repeat center center;
        cursor: pointer;
    }

    tr.shown td.details-control {
        background: url('assets/pages/img/d4ICC.png') no-repeat center center;
    }
</style>

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>{{pagetitle}} RAF</h1>
            </div>
        </div>
        <!-- <div class="page-head">
            <div class="page-title">
                <h1>Create a RAF</h1>
            </div>
        </div>

        <ul class="page-breadcrumb breadcrumb mb20">
            <li style="border:none;">&nbsp;                
                <div class="caption pull-right">
                    <a routerLink="/raf-overview" style="color:#327ad5;"><i class="fa fas fa-reply" data-toggle="tooltip" data-placement="top" title="Back To Overview"></i></a>                        
                </div>
                
            </li>
        </ul> -->
        <ul class="page-breadcrumb breadcrumb mb20">
        </ul>

        <div class="col-md-12">
            <div class="portlet light bordered" id="form_wizard_1">

                <div class="portlet-title" style="padding-bottom: 15px;">
                    <!-- <div class="caption">
                        <span class="caption-subject font-red bold uppercase">{{pagetitle}} RAF</span>
                    </div> -->
                    <label class="control-label col-md-2" style="text-align: right;">Instructions
                        <!-- <span class="required"> * </span> -->
                    </label>
                    <div class="col-md-8">
                        <textarea class="form-control"  style="background-color: #fff !important; height: 70px; overflow-y: hidden; resize: none;" disabled maxlength="500">Please ensure all information is completed. If you require any help with completing this form, please contact a member of the HR Team. Once you have submitted your RAF, the relevant SLT approver(s) will be notified and given the option to approve or reject. Once a decision has been made, you will be notified. 
                        </textarea>
                    </div>
                    <div class="caption pull-right">
                        <a routerLink="/raf-overview"><i class="fa fas fa-reply" data-toggle="tooltip"
                                data-placement="top" title="Back To Overview"></i></a>&nbsp;&nbsp;
                        <button type="button" class="btn  dark btn-outline"
                            *ngIf="this.vacancyForm.controls.Id.value > 0" (click)="history()"><i
                                class="fa fa-history"></i></button>
                    </div>
                </div>

                <p style="margin: 5px 0;">&nbsp;</p>

                <div class="portlet-body form">
                    <form class="form-horizontal" [formGroup]="vacancyForm">
                        
                        <div class="form-group">
                            <label class="control-label col-md-2">Company
                                <span class="required"> * </span>
                            </label>

                            <div class="col-md-3">
                                <select id="Company_Id" class="form-control companySelectListClass select"
                                    (change)="getDepartmentList($event.target.value)" formControlName="Company_Id">
                                    <option value="">Select Company</option>
                                    <option *ngFor="let company of companylist;" value={{company.company_Id}}>
                                        {{company.company_Name}}</option>
                                </select>
                            </div>

                            <label class="control-label col-md-2">Department
                                <span class="required"> * </span>
                            </label>

                            <div class="col-md-3">
                                <select id="Dept_Id" class="form-control departmentSelectListClass select"
                                    formControlName="Dept_Id">
                                    <option value="">Select Department</option>
                                    <option *ngFor="let item of departmentList;" value={{item.dept_Id}}>
                                        {{item.dept_Name}}</option>
                                </select>
                            </div>
                        </div>




                        <div class="form-group">
                            <label class="control-label col-md-2">Site
                                <span class="required"> * </span>
                            </label>
                            <div class="col-md-3">
                                <select id="Site_Id" class="form-control siteSelectListClass select"
                                    formControlName="Site_Id">
                                    <option value="">Select Site</option>
                                    <option *ngFor="let item of siteList;" value={{item.site_Id}}>
                                        {{item.site_Name}}</option>
                                </select>
                            </div>

                            <label class="control-label col-md-2">Position Type
                                <span class="required"> * </span>
                            </label>
                            <div class="col-md-3">
                                <select id="Type" class="form-control positionSelectListClass select"
                                    formControlName="Type">
                                    <option value="">Select Position Type</option>
                                    <option value="NewVacancy">New Vacancy</option>
                                    <option value="InterimCover">Interim Cover</option>
                                    <option value="Replacement">Replacement</option>
                                </select>
                            </div>


                        </div>


                        <div class="form-group">

                            <label class="control-label col-md-2">Requester
                                <span class="required"> * </span>
                            </label>
                            <div class="col-md-3">



                                <div class="md-radio-inline">
                                    <div class="md-radio has-info" style="margin-top: 5px;">
                                        <input type="radio" id="radio51" name="isRequester"
                                            formControlName="isRequester" value="0" class="md-radiobtn"
                                            (change)="selectRequesterType();getVacancyRuleId()">
                                        <label for="radio51">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span>Self</label>
                                    </div>
                                    <div class="md-radio has-info" style="margin-top: 5px;">
                                        <input type="radio" id="radio60" name="isRequester"
                                            formControlName="isRequester" value="1" class="md-radiobtn"
                                            (change)="selectRequesterType()">
                                        <label for="radio60">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> Other </label>
                                    </div>
                                </div>



                                <ng-select2 [data]="employeeListForRafRequester" id="RequestedBy"
                                    formControlName="RequestedBy" *ngIf="this.vacancyForm.value.isRequester  == '1'"
                                    (ngModelChange)="getVacancyRuleId()" [placeholder]="'--- Select ---'">
                                </ng-select2>
                            </div>

                            <label class="control-label col-md-2">Budgeted</label>
                            <div class="md-radio-inline col-md-3" *ngIf="fieldVisibleInvisible[0].Budget == 0">
                                <div class="md-radio has-info" style="margin-right: 10px;">
                                    <input type="radio" id="radio6Yes" class="md-radiobtn" name="budgetYesNo"
                                        checked="checked" value="Yes" (click)="viewBudget($event.target.value)">
                                    <label for="radio6Yes">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Yes</label>
                                </div>
                                <div class="md-radio has-info" style="margin-right: 10px;">
                                    <input type="radio" id="radio7No" class="md-radiobtn" name="budgetYesNo" value="No"
                                        (click)="viewBudget($event.target.value)">
                                    <label for="radio7No">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> No </label>
                                </div>

                                <br><br>
                                <div class="md-radio has-info classBudget" style="margin-right: 10px;">
                                    <input type="radio" id="radio6" class="md-radiobtn" formControlName="BudgetType"
                                        (change)="budgetTitle ='Per Hour'" checked="checked" value="Hourly">
                                    <label for="radio6">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Hourly</label>
                                </div>
                                <div class="md-radio has-info classBudget" style="margin-right: 10px;">
                                    <input type="radio" id="radio7" class="md-radiobtn" formControlName="BudgetType"
                                        (change)="budgetTitle ='Per Annum'" value="Annually">
                                    <label for="radio7">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Annually </label>
                                </div>
                            </div>
                            <div class="col-md-3 classBudget" *ngIf="fieldVisibleInvisible[0].Budget == 1">
                                <div class="md-radio-inline">
                                    <div class="md-radio has-info" style="margin-top: 5px;">
                                        <input type="radio" id="radio53" name="budgeted" formControlName="budgeted"
                                            value="1" class="md-radiobtn" (change)="getVacancyRuleId()">
                                        <label for="radio53">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span>Yes</label>
                                    </div>
                                    <div class="md-radio has-info" style="margin-top: 5px;">
                                        <input type="radio" id="radio59" name="budgeted" formControlName="budgeted"
                                            value="0" class="md-radiobtn" (change)="getVacancyRuleId()">
                                        <label for="radio59">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> No </label>
                                    </div>
                                </div>
                                <!-- <input type="text" class="form-control" id="Budget" maxlength="20"
                                    formControlName="Budget"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'> -->
                                <!-- <span class="input-group-addon inputaddon">
                                        <i class="fa" id="perAnumAndHour">{{budgetTitle}}</i>
                                    </span> -->

                            </div>
                        </div>

                        <div class="form-group">

                            <div class="col-md-12">
                                <div class="form-group mt-element-step">
                                    <div class="row step-line text-center">
                                        <div class="col-md-{{vacancyRuleListLength}} mt-step-col done"
                                            *ngFor="let item of vacancyRuleList; let i = index">
                                            <div class="mt-step-number bg-white">{{i+1}}</div>
                                            <div class="mt-step-title uppercase font-grey-cascade">{{item.emp_Name}}
                                            </div>
                                            <div class="mt-step-title font-grey-cascade">{{item.desig_Name}}</div>
                                        </div>
                                        <!-- <div class="col-md-3 mt-step-col done">
                                            <div class="mt-step-number bg-white">2</div>
                                            <div class="mt-step-title uppercase font-grey-cascade">Test User2</div>
                                            <div class="mt-step-title font-grey-cascade">(Manager)</div>
                                        </div>
                                        <div class="col-md-3 mt-step-col done">
                                            <div class="mt-step-number bg-white">3</div>
                                            <div class="mt-step-title uppercase font-grey-cascade">Test User3</div>
                                            <div class="mt-step-title font-grey-cascade">(BUH)</div>
                                        </div>
                                        <div class="col-md-3 mt-step-col done">
                                            <div class="mt-step-number bg-white">4</div>
                                            <div class="mt-step-title uppercase font-grey-cascade">Test User4</div>
                                            <div class="mt-step-title font-grey-cascade">(Admin Manager)</div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="form-group">
                            <label class="control-label col-md-2">Job Title
                                <span class="required"> * </span>
                            </label>
                            <div class="col-md-2">
                                <select id="Desig_Id" formControlName="Desig_Id"
                                    class="form-control recruitmentSelectListClass select">
                                    <option value="">Select Job Title</option>
                                    <option *ngFor="let item of designationList;" value={{item.desig_Id}}>
                                        {{item.desig_Name}}</option>
                                </select>
                            </div>
                            <div class="col-md-1"  *ngIf ="job_title_access =='HR Manager' || job_title_access =='HR Executive' 
                            || job_title_access =='HR Recruiter'">
                                <a class="btn btn-sm dark btn-outline" data-toggle="tooltip"
                                    (click)="openModelJobTitle()" *ngIf="titleForJobTitle == 'Add'"
                                    title="Add Job Title"><i class="fa fa-plus fa-1x"></i></a>&nbsp;

                                <a class="btn btn-sm dark btn-outline" data-toggle="tooltip"
                                    (click)="openModelJobTitle()" *ngIf="titleForJobTitle == 'Edit'"
                                    title="Edit Job Title"><i class="fa fa-edit fa-1x"></i></a>&nbsp;

                            </div>
                            <label class="control-label col-md-2">Job Description
                                <span class="required"> * </span>
                            </label>

                            <div class="col-md-3">
                                <select id="JD_Id" class="form-control jdSelectListClass select"
                                    formControlName="JD_Id">
                                    <option value="">Select Job Description</option>
                                    <option *ngFor="let item of jobTitleList;" value={{item.jD_Id}}>
                                        {{item.jD_Name}}</option>
                                </select>
                            </div>
                            <div class="col-md-1">
                                <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="openModel()"
                                    *ngIf="title == 'Add'" title="Add Job Description"><i
                                        class="fa fa-plus fa-1x"></i></a>&nbsp;

                                <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="openModel()"
                                    *ngIf="title == 'Edit'" title="Edit Job Description"><i
                                        class="fa fa-edit fa-1x"></i></a>&nbsp;

                                <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="download()"
                                    title="Download Job Description"><i class="fa fa-download fa-1x"></i></a>
                            </div>

                        </div>

                        <div class="form-group">

                            <label class="control-label col-md-2">Zone
                                <span class="required"> * </span>
                            </label>
                            <div class="col-md-3">
                                <select id="Zone_Id" class="form-control zoneSelectListClass select"
                                    formControlName="Zone_Id">
                                    <option value="">Select Zone</option>
                                    <option *ngFor="let item of zoneList;" value={{item.zone_Id}}>
                                        {{item.zone_Name}}</option>
                                </select>
                            </div>

                            <label class="control-label col-md-2">No. of Position
                                <span class="required"> * </span>
                            </label>
                            <div class="col-md-3">
                                <input type="text" class="form-control" id="NoOfPositions" maxlength="5"
                                    formControlName="NoOfPositions"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'>
                            </div>


                        </div>

                        <div class="form-group">
                            <label class="control-label col-md-2">Priority
                                <span class="required"> * </span>
                            </label>
                            <div class="col-md-3">
                                <select id="Priority" class="form-control prioritySelectListClass select"
                                    formControlName="Priority">
                                    <option value="">Select Priority</option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Urgent">Urgent</option>
                                    <option value="Very Urgent">Very Urgent</option>
                                </select>
                            </div>

                            <label class="col-md-2 control-label" for="form_control">Skills
                            </label>
                            <div class="col-md-3">
                                <input type="text" id="skills_tags" data-role="tagsinput" maxlength="500"
                                    class="skills_tags">
                            </div>


                        </div>



                        <div class="form-group">
                            <label class="control-label col-md-2">Salary
                                <span class="required"> * </span>
                            </label>


                            <div class="md-radio-inline col-md-3">
                                <div class="md-radio has-info" style="margin-right: 10px;">
                                    <input type="radio" id="radio5" class="md-radiobtn" formControlName="SalaryType"
                                        (change)="salaryTitle ='Per Hour'" checked="checked" value="Hourly">

                                    <label for="radio5">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Hourly</label>
                                </div>
                                <div class="md-radio has-info" style="margin-right: 10px;">
                                    <input type="radio" id="radio2" class="md-radiobtn" formControlName="SalaryType"
                                        (change)="salaryTitle = 'Per Annum'" value="Annually">
                                    <label for="radio2">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Annually </label>
                                </div>
                            </div>


                            <!-- <label class="control-label col-md-2">Bonus
                            </label>
                            <div class="col-md-3">
                                <input type="number" min="0" value="0" step="0.01" class="form-control" id="Bonus"
                                    maxlength="20" formControlName="Bonus" autocomplete="off">
                                
                            </div> -->
                            <!-- <input type="text" class="form-control" id="Bonus" maxlength="20" formControlName="Bonus" autocomplete="off" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46 || event.key === "Backspace"'> -->

                        </div>


                        <div class="form-group">
                            <label class="control-label col-md-2">&nbsp;
                            </label>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <div class="input-group">
                                        <input type="number" placeholder="From" min="0" step="0.01" class="form-control"
                                            id="From" maxlength="20" formControlName="SalaryFrom">
                                        <input type="number" placeholder="To" min="0" step="0.01" class="form-control"
                                            id="To" maxlength="20" formControlName="SalaryTo">
                                        <span class="input-group-addon inputaddon">{{currency_Code}}</span>
                                    </div>
                                    <span class="input-group-addon inputaddon">
                                        <i class="fa" id="perAnumAndHour">{{salaryTitle}}</i>
                                    </span>
                                </div>
                            </div>

                            <label class="control-label col-md-2">&nbsp;
                            </label>
                            <div class="col-md-3 classBudget" *ngIf="fieldVisibleInvisible[0].Budget == 0">
                                <div class="input-group">
                                    <input type="text" class="form-control" id="BFrom" maxlength="20" placeholder="From"
                                        formControlName="BudgetFrom"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'>
                                    <input type="text" class="form-control" id="BTo" maxlength="20" placeholder="To"
                                        formControlName="BudgetTo"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'>
                                    <span class="input-group-addon inputaddon">
                                        <i class="fa" id="perAnumAndHour">{{budgetTitle}}</i>
                                    </span>
                                </div>
                            </div>

                        </div>



                        <div class="form-group">


                            <!-- <label class="control-label col-md-2">Benchmark
                            </label>
                            <div class="col-md-3">
                                <input type="text" id="Benchmark" class="form-control" formControlName="Benchmark"
                                    autocomplete="off">
                            </div> -->


                            <label class="control-label col-md-2 mb20">Contract Type
                                <span class="required"> * </span>
                            </label>
                            <div class="col-md-3  mb20">

                                <ng-select2 [data]="contractList" id="Contract_Id" formControlName="Contract_Id"
                                    (ngModelChange)="fillContractValue('notChange')"
                                    [placeholder]="'Select Contract Type'">
                                </ng-select2>

                            </div>

                            <label class="control-label col-md-2">Asset Requirements</label>

                            <div class="col-md-3">

                                <ng-select2 [data]="assestList" id="requirements"
                                    [options]="{multiple: true,tags: false}"
                                    [value]='this.vacancyForm.controls.Requirements.value'
                                    (valueChanged)="setFormData('Requirements',$event)"
                                    [placeholder]="'--- Select ---'">
                                </ng-select2>

                            </div>


                        </div>

                        <div class="form-group">
                            <label class="control-label col-md-2">Shift Start<span class="required"> *
                            </span></label>
                            <div class="col-md-3">
                            <div class="input-group" id="flatpickropenshiftstatetime" data-wrap="true">
                                <input type="time" id="shift_Start" class="form-control readonlyClass"
                                    formControlName="shift_Start"  data-input  
                                    >
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button" >
                                        <i class="fa fa-clock-o"></i>
                                    </button>
                                </span>
                            </div>
                            </div>

                            <label class="control-label col-md-2">Shift End<span class="required"> *
                            </span></label>
                            <div class="col-md-3">
                            <div class="input-group" id="flatpickropenshiftendtime" data-wrap="true">
                                <input type="time" id="shift_End" class="form-control readonlyClass"
                                    formControlName="shift_End" data-input  >
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-clock-o"></i>
                                    </button>
                                </span>
                            </div>
                            </div>
                        </div>
                    

                        <div class="form-group">

                            <label class="control-label col-md-2">Contracted Hours
                                <span class="required"> * </span>
                            </label>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input type="number" class="form-control" id="Contract_HoursDaily" min="0" max="24"
                                        formControlName="Contract_HoursDaily" autocomplete="off"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'>
                                    <span class="input-group-addon inputaddon">
                                        <i class="fa">Daily</i>
                                    </span>
                                </div>
                            </div>
                            
                                
                                    <!-- <label class="control-label col-md-2">Night Shift</label>
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox ">
                                            <input type="checkbox" id="nightShift" class="md-check" formControlName="nightShift">
                                            <label for="nightShift">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div> -->

                                    <label class="control-label col-md-2">Shift Variable</label>
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox ">
                                            <input type="checkbox" id="shift_Variable" class="md-check" formControlName="shift_Variable">
                                            <label for="shift_Variable">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div>
                                
                            

                            <!-- <label class="control-label col-md-2">Shift Time
                                <span class="required"> * </span>
                            </label>
                            <div class="col-md-2">
                                <select id="Shift_Id" class="form-control workingShiftSelectListClass select"
                                    formControlName="Shift_Id">
                                    <option value="">Select Shift</option>
                                    <option *ngFor="let item of shiftList;" value={{item.shift_Id}}>
                                        {{item.shift_Name}} [{{item.shift_Start}} - {{item.shift_End}}]</option>
                                </select>
                            </div>
                            <div class="col-md-1">
                                <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="openModelShift()"
                                    *ngIf="titleForShift == 'Add'" title="Add Shift Time"><i
                                        class="fa fa-plus fa-1x"></i></a>&nbsp;

                                <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="openModelShift()"
                                    *ngIf="titleForShift == 'Edit'" title="Edit Shift Time"><i
                                        class="fa fa-edit fa-1x"></i></a>&nbsp;

                            </div> -->

                        </div>





                        <div class="form-group">

                            <div class="">
                                <label class="control-label col-md-2">Working Days
                                    <span class="required" aria-required="true"> * </span>
                                </label>
                                <div class="md-checkbox-inline col-md-3">
                                    <div class="md-checkbox has-success" style="margin-right: 10px;">
                                        <input type="checkbox" id="checkbox14" value="mon" class="md-check wd">
                                        <label for="checkbox14">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> M</label>
                                    </div>
                                    <div class="md-checkbox has-success" style="margin-right: 10px;">
                                        <input type="checkbox" id="checkbox15" value="tue" class="md-check wd">
                                        <label for="checkbox15">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> T </label>
                                    </div>
                                    <div class="md-checkbox has-info" style="margin-right: 10px;">
                                        <input type="checkbox" id="checkbox16" value="wed" class="md-check wd">
                                        <label for="checkbox16">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> W </label>
                                    </div>
                                    <div class="md-checkbox has-info" style="margin-right: 10px;">
                                        <input type="checkbox" id="checkbox17" value="thr" class="md-check wd">
                                        <label for="checkbox17">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> T </label>
                                    </div>
                                    <div class="md-checkbox has-info" style="margin-right: 10px;">
                                        <input type="checkbox" id="checkbox18" value="fri" class="md-check wd">
                                        <label for="checkbox18">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> F </label>
                                    </div>
                                    <div class="md-checkbox has-error" style="margin-right: 10px;">
                                        <input type="checkbox" id="checkbox19" value="sat" class="md-check wd">
                                        <label for="checkbox19">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> S </label>
                                    </div>
                                    <div class="md-checkbox has-error" style="margin-right: 10px;">
                                        <input type="checkbox" id="checkbox20" value="sun" class="md-check wd">
                                        <label for="checkbox20">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> S </label>
                                    </div>

                                </div>
                            </div>


                            <label class="control-label col-md-2"
                                *ngIf="fieldVisibleInvisible[0].Car_Allowance == 1">Car
                                Allowance</label>
                            <div class="md-radio-inline col-md-3" *ngIf="fieldVisibleInvisible[0].Car_Allowance == 1">
                                <input type="text" class="form-control" formControlName="Car_Allowance"
                                    id="Car_Allowance" maxlength="6"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.key === 'Backspace'">
                            </div>

                        </div>






                        <div class="form-group">
                            <label class="control-label col-md-2">Justification
                                <!-- <span class="required"> * </span> -->
                            </label>
                            <div class="col-md-8">
                                <textarea class="form-control" id="justification" formControlName="Notes"
                                    rows="3"></textarea>
                            </div>

                        </div>


                        <div class="form-group">
                            <label class="control-label col-md-2">Reference Number</label>
                            <div class="col-md-3"><input type="text" class="form-control" id="reference_Number"
                                    formControlName="reference_Number" autocomplete="off" maxlength="150" disabled>
                            </div>

                            <label class="control-label col-md-2">Date of Submission<span class="required"> *
                            </span></label>
                        <div class="col-md-3">
                            <div class="input-group" id="flatpickropencontract_DS" data-wrap="true">
                                <input type="text" class="form-control readonlyClass"
                                    formControlName="date_Submition" id="date_Submition" readonly data-input>
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </div>

                        </div>


                        <div class="form-group">
                            <label class="control-label col-md-2">Holiday manager</label>

                            <div class="col-md-3">
                                <select id="Reporting" formControlName="Reporting"
                                    class="form-control recruitmentSelectListClass select">
                                    <option value="">Select Holiday Manager's Name</option>
                                    <option *ngFor="let item of holidayempList;" value={{item.id}}>
                                        {{item.text}}</option>
                                </select>

                              
                            </div>

                            <label class="control-label col-md-2">Line Manager</label>
                            <div class="col-md-3">
                                <select id="line_Manager" formControlName="line_Manager"
                                    class="form-control recruitmentSelectListClass select">
                                    <option value="">Select Line Manager's Name</option>
                                    <option *ngFor="let item of linemanagerempList;" value={{item.id}}>
                                        {{item.text}}  </option>
                                </select>
                            </div>

                        </div>

                        <div class="form-group">
                            
                        </div>
                        <div class="form-group">
                           
                        </div>

                        <!-- <div class="form-group">
                            <label class="control-label col-md-2">Instructions
                                <span class="required"> * </span>
                            </label>
                            <div class="col-md-8">
                                <textarea class="form-control" disabled>Please ensure all information is completed. If you require any help with completing this form, please contact a member of the HR Team. Once you have submitted your RAF, the relevant SLT approver(s) will be notified and given the option to approve or reject. Once a decision has been made, you will be notified. 
                                </textarea>
                            </div>

                        </div> -->



                        <div class="form-group">
                            <label class="control-label col-md-2"
                                *ngIf="fieldVisibleInvisible[0].Compensation == 1">Compensation
                            </label>
                            <div class="col-md-3" *ngIf="fieldVisibleInvisible[0].Compensation == 1">
                                <input type="text" class="form-control" id="Compensation" maxlength="20"
                                    formControlName="Compensation"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'>
                            </div>




                        </div>
                        <div class="form-group">
                            <label class="control-label col-md-2"
                                *ngIf="fieldVisibleInvisible[0].min_Experience == 1">Minimum
                                Experience</label>
                            <div class="md-radio-inline col-md-3" *ngIf="fieldVisibleInvisible[0].min_Experience == 1">
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="min_Experience"
                                        id="min_Experience" maxlength="2"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.key === 'Backspace'"
                                        placeholder="Minimum Experience">
                                    <span class="input-group-addon inputaddon">In Year</span>
                                </div>
                            </div>

                            <label class="control-label col-md-2" style="visibility: hidden;">Interviewer
                                <!-- <span class="required"> * </span> -->
                            </label>
                            <div class="col-md-3" style="visibility: hidden;">
                                <select id="interviewerDrop" class="form-control interviewerSelectListClass select">
                                    <option value="">Select Employee</option>
                                    <option *ngFor="let item of employeeList;" value={{item.emp_Id}}>
                                        {{item.firstName}} {{item.lastName}}</option>
                                </select>
                            </div>

                        </div>

                        <div class="form-group">


                            <label class="control-label col-md-2" style="visibility: hidden;">Interviewer
                                <!-- <span class="required"> * </span> -->
                            </label>
                            <div class="col-md-3" style="visibility: hidden;">
                                <select id="interviewerDrop" class="form-control interviewerSelectListClass select">
                                    <option value="">Select Employee</option>
                                    <option *ngFor="let item of employeeList;" value={{item.emp_Id}}>
                                        {{item.firstName}} {{item.lastName}}</option>
                                </select>
                            </div>

                        </div>





                        <div class="form-actions">
                            <div class="text-center">
                                <button type="button" class="btn default" (click)="vacancyUpsert('Planned')"
                                    *ngIf="this.vacancyForm.controls.Status.value == 'Planned'  || this.vacancyForm.controls.Status.value == 'Rejected' || this.vacancyForm.controls.Status.value == null || this.vacancyForm.controls.Id.value == 0">
                                    Save For Later </button>&nbsp;
                                <button type="button" class="btn btn-outline blue"
                                    *ngIf="this.vacancyForm.controls.Status.value == 'Planned'  || this.vacancyForm.controls.Status.value == 'Rejected' || this.vacancyForm.controls.Status.value == null || this.vacancyForm.controls.Id.value == 0"
                                    (click)="vacancyUpsert('Awaiting Approval')" [disabled]="!(vacancyRuleList.length > 0)">
                                    Save & Confirm
                                </button>&nbsp;
                                <button type="button" class="btn btn-outline blue" (click)="vacancyUpsert('Awaiting Approval')"
                                    *ngIf="this.vacancyForm.controls.Status.value != 'Planned' && this.vacancyForm.controls.Status.value != 'Rejected' && this.vacancyForm.controls.Status.value != null && this.vacancyForm.controls.Id.value != 0 ">
                                    Save </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="draggableShift" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Shift</h4>
            </div>
            <div class="modal-body">
                <app-workingshift></app-workingshift>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="shiftUpsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="draggableJobTitle" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Job Title</h4>
            </div>
            <div class="modal-body">
                <app-jobtitle></app-jobtitle>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="jobTitleUpsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="draggable" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Job Description</h4>
            </div>
            <div class="modal-body">
                <app-jobdescription></app-jobdescription>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="jobDescriptionUpsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="selectRule" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Rules</h4>
            </div>
            <div class="modal-body">
                <div class="table-responsive">


                    <table class="display table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th style="width: 2%"></th>
                                <th class="text-center"> Rule Name </th>
                                <th class="text-center"> Company </th>
                                <th class="text-center"> Site </th>
                                <th class="text-center"> Department </th>
                                <th class="text-center">Type</th>
                                <th class="text-center"> Budget </th>
                                <th class="text-center"> Requester </th>

                            </tr>
                        </thead>
                        <tbody *ngFor="let item of tempvacancyRuleList">
                            <tr>
                                <td class="details-control details-control-icon-{{item.vacancy_Rule_Id}}"
                                    (click)="expandTable(item.vacancy_Rule_Id)"></td>
                                <td (click)="selectRule(item.vacancy_Rule_Id)">{{item.name}}</td>
                                <td>{{item.company_Name}}</td>
                                <td>{{item.site_Name}}</td>
                                <td>{{item.dept_Name}}</td>
                                <td (click)="selectRule(item.vacancy_Rule_Id)">{{item.display_Type}}</td>
                                <td *ngIf="item.budgeted == 1">Yes</td>
                                <td *ngIf="item.budgeted == 0">No</td>
                                <td>{{item.person_Name}}</td>
                            </tr>
                            <tr class="sub_tbl sub_tbl_{{item.vacancy_Rule_Id}}" style="display:none;">
                                <td colspan="12">
                                    <table cellpadding="6" cellspacing="0" style="width:50%;"
                                        class="table table-striped table-bordered dataTable no-footer">
                                        <thead>
                                            <tr>
                                                <th>Job Title</th>
                                                <th>Approver Name</th>
                                                <th>Final Approver</th>
                                                <th>Priority</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let items of vacancyChildData">
                                                <td>{{items.desig_Name}}</td>
                                                <td>{{items.emp_Name}}</td>
                                                <td>{{items.isFinal}}</td>
                                                <td>{{items.priority}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="modalJobDescription" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Job Description</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <iframe [src]='fileUrl' width="100%" height="600px"></iframe>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">RAF History</h4>
            </div>
            <div class="modal-body">
                <app-history></app-history>
            </div>
        </div>
    </div>
</div>