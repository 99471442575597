import { Component, OnInit } from '@angular/core';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { CommonService } from 'src/app/services/common.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ComingSoonComponent } from 'src/app/home/comingsoon/comingsoon.component';
import { strictEqual } from 'assert';
import { groupBy } from 'rxjs/operators';
import { Select2OptionData } from 'ng-select2';

declare var $: any;

@Component({
  selector: 'app-raf-config',
  templateUrl: './raf-config.component.html',
  styleUrls: ['./raf-config.component.css']
})
export class RafConfigComponent implements OnInit {
  
  

  
  title = "Add";
  ruleList: number[] = [1, 2]
  vacancyRuleForm: FormGroup;
  Vacancy_Rule_Id: '0'
  vacancyruleId: 0
  items: FormArray;

  companyList: any;
  companyListFilter: any;

  siteList: any;
  siteListFilter: any;

  departmentList: any;
  multidepartmentList: any;
  selectedText:any;
  isDisabled: boolean = false;
  departmentListFilter: any;

  designationList: any;
  vacancyRuleList: any;
  filllAcorssCompany: any[];
  addrolevisible: boolean = true;
  hrList: any;
  p: number = 1;
  totalItems1: 0;
  myApprovallist: any;
  itemsPerPage: number = 10;
  rulesList: any;
  modalTitle: string;

  employeeList: any[] = [];


  vacancyRuleRecruiterId: any = 0;

  currentUserRole: any;
  userId:any = 0;
  createdname:any;
  addedOn :any = new Date();

  constructor(private commonService: CommonService, private fb: FormBuilder,
    private commonApiService: CommonApiService, private toastr: ToastrService
    ) { }


  ngOnInit() {
    

    this.currentUserRole = this.commonService.getRolePermission('Approval Process Configuration')

    this.title = "Add"
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

    var self = this;

    this.vacancyRuleForm = this.fb.group({
      Id: ['0'],
      name: ['', Validators.required],
      Company_Id: ['', Validators.required],
      site_Id: [''],
      dept_Id: [''],
      dept_IdList:[null],
      person_Id: [''],
      Type: [''],
      budgeted: ['0'],
      isActive: ['1'],
      AddedBy: [Commonvariable.User_Id],
      vacancy_rule_recruiter: this.fb.array([this.vacancyRuleRecuiter()]),
      vacancy_rule_detail: this.fb.array([])

    })
    this.userId=Commonvariable.User_Id;
    this.getCompanyList()
    this.getEmpList()
    this.getfilllAcorssCompanydrp()
    this.getemployeeListForname(this.userId);
    $('.drp, .companySelectListClassFilter,.siteSelectListClassFilter, .departmentSelectListClassFilter').select2({ width: '100%' });

    $('#Company_Id').on('select2:select', function (e) {
      self.clear(true, false, true);
      self.addrolevisible = true
      self.getCompanywisedata($('#Company_Id').val())
      //self.getVacancyRuleDetailList()
    });

    $('.drppersonId').on('select2:select', function (e) {
      
      self.setDepartmentIdByRequester($(this).val());
    });



    this.myAprrovalSearchOneValue("onLoad", 0);

    $('#Company_Id_Filter').on('select2:select', function (e) {
      
      var compIds = $(".companySelectListClassFilter option:selected").val();
      
      if (compIds != "") {
        self.getSiteForfilter(compIds);
        self.myAprrovalSearchOneValue("onLoad", 0);
      }
      else {
        if (compIds == ""){
          self.getSiteForfilter(0);
        }
        $(".siteSelectListClassFilter option:selected").val(compIds);
        $(".departmentSelectListClassFilter option:selected").val(compIds);
        self.myAprrovalSearchOneValue("onLoad", 0);

      }
    });

    $('#Site_Id_Filter, #Dept_Id_Filter').on('select2:select', function (e) {
      
      self.myAprrovalSearchOneValue("onLoad", 0);
    });


  }
 

//   ngAfterViewInit(){
//     $("[data-toggle=popover]").each(function (i, obj) {
    
//              $(this).popover({
//                 html: true,
//                 content: function () {
//                    var id = $(this).attr('id')
//                    return $('#popover-content-' + id).html();
//                 }
//              });
//           });
          
//  }

  setDepartmentIdByRequester(id) {
  
    if (id != "" && id != null) {
      let dept_Id = this.employeeList.filter(x => x.person_Id == id)[0]["dept_Id"];
      $("#dept_Id").val(String(dept_Id));
    //  this.vacancyRuleForm.controls.dept_Id.setValue(dept_Id);
    this.vacancyRuleForm.controls.dept_IdList.setValue(dept_Id);
      $('#dept_Id').select2({ data:this.multidepartmentList,width: '100%', disabled: true ,multiple: true }).trigger('change');
     this.isDisabled = true;
    
    }
    else {
      this.vacancyRuleForm.controls.dept_IdList.setValue("");
     
      let staticData = [
        { id: 1, text: 'Department 1' },
        { id: 2, text: 'Department 2' },
        { id: 3, text: 'Department 3' }
        // Add more departments as needed
      ];
      let allData = this.multidepartmentList.map(department => ({
        id: department.id,
        text: department.text // Adjust this according to your data structure
      }));
      $('#dept_Id').select2({ 
        data: staticData,
        width: '100%', 
        disabled: false, 
        multiple: true 
      }).trigger('change');
      this.isDisabled = false;
    }
  }


  onChangeDesignation(designationID, id, empId) {
    var requestData = {
      "searchBy": [
        {
          "FieldName": 'Company_Id',
          "FieldValue": $("#acrossCompany" + id).prop("checked") || $('#Company_Id').val() == "" ? JSON.stringify(null) : $('#Company_Id').val(),
          "Parameter": $("#acrossCompany" + id).prop("checked") ? "!=" : "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": 'RAF_Approval',
          "FieldValue": 1,
          "Parameter": "=="
        }
      ]
    }
    this.commonApiService.GetByPaginated(Commonvariable.EmployeeDetail, requestData).subscribe((result) => {
      if (result["data"] != null && result["data"] != "" && result["status"] == 200) {
        var optionHtml = '<option value="">Select Employee</option>';
        result["data"].filter(x => x.desig_Id == designationID).forEach(x => {
          optionHtml += "<option value='" + x.emp_Id + "'>" + x.firstName + " " + x.lastName + "</option>"
        });
        $("#Emp_Id" + id).html("")
        $("#Emp_Id" + id).html(optionHtml)

        // this.vacancyRuleForm.get('vacancy_rule_detail').value[id].Emp_Id = '';
        // for (let index = 0; index < this.vacancyRuleForm.controls.vacancy_rule_detail.value.length; index++) {



        $("#Emp_Id" + id).val(empId)


        // }
      }
    });


  }


  myApprovalServerPagination(event) {
    this.myAprrovalSearchOneValue("onLoad", event - 1);
    this.p = event;
  }

  getRuleDetails(ids) {

    var data = {
      "searchBy": [
        // {
        //   "FieldName": "company_Id",
        //   "FieldValue": parseInt(company_id),
        //   "Parameter": "==",
        //   "ConditionWith": "&&"
        // },
        {
          "FieldName": 'vacancy_Rule_Id',
          "FieldValue": ids,
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": 'isActive',
          "FieldValue": 1,
          "Parameter": "=="
        }
      ],
      "OrderBy": 'isFinal'
    }
    this.commonApiService.getPaginated(Commonvariable.Vacancy_Rule_Detail, null, null, null, null, null, data).subscribe((result) => {
      if (result["data"] != null && result["data"] != "" && result["status"] == 200) {
        this.rulesList = result["data"]["list"];
      }
    });
  }

  myAprrovalSearchOneValue(action, pageIndex) {

    this.totalItems1 = 0;
    this.myApprovallist = [];

    var apiCall = true;

    var pageSize = parseInt($("#myApprovallist_length").val());

    var pageSize = pageSize != null ? pageSize : 10;

    this.p = 1;

    if ($(".companySelectListClassFilter option:selected").val() == "" && $(".siteSelectListClassFilter option:selected").val() == "" && $(".departmentSelectListClassFilter option:selected").val() == "") {

      var data0 = {
        "searchBy": [
          {
            "FieldName": "company_Id",
            "FieldValue": $(".companySelectListClassFilter option:selected").val() != "" ? $(".companySelectListClassFilter option:selected").val() : '0',
            "Parameter": "!=",
            "ConditionWith": "&&"
          },
          {
            "FieldName": 'site_Id',
            "FieldValue": $(".siteSelectListClassFilter option:selected").val() != "" ? $(".siteSelectListClassFilter option:selected").val() : '0',
            "Parameter": "!=",
            "ConditionWith": "&&"
          },
          {
            "FieldName": 'dept_Id',
            "FieldValue": $(".departmentSelectListClassFilter option:selected").val() != "" ? $(".departmentSelectListClassFilter option:selected").val() : '0',
            "Parameter": "!="
          }
        ],
        "PageIndex": pageIndex != null ? pageIndex : 0,
        "PageSize": pageSize != null ? pageSize : 10,
        "RecordCount": 100,
        "RecordLimit": 100,
        "CommonSearch": $("#filterList").val()
      }


      if (apiCall) {
        this.commonApiService.getPaginated(Commonvariable.VacancyRuleService, null, null, null, null, null, data0).subscribe((result) => {
          
          if (result["data"] != null && result["data"] != "" && result["status"] == 200) {
            this.totalItems1 = result["data"]["recordCount"];
            this.myApprovallist = result["data"]["list"];
            console.log(this.myApprovallist);
            this.itemsPerPage = pageSize;
          }
        }, (error) => {
          this.totalItems1 = 0;
          this.myApprovallist = [];
          //this.toastr.warning(error["error"].message, 'Approval Process Configuration');
        })
      }

    } else if ($(".companySelectListClassFilter option:selected").val() != '' && $(".siteSelectListClassFilter option:selected").val() == '' && $(".departmentSelectListClassFilter option:selected").val() == '') {

      var data = {
        "searchBy": [
          {
            "FieldName": 'company_Id',
            "FieldValue": $(".companySelectListClassFilter option:selected").val() != "" ? $(".companySelectListClassFilter option:selected").val() : '0',
            "Parameter": "=="
          }
        ],
        "PageIndex": pageIndex != null ? pageIndex : 0,
        "PageSize": pageSize != null ? pageSize : 10,
        "RecordCount": 100,
        "RecordLimit": 100,
        "CommonSearch": $("#filterList").val().trim()
      }

      if (apiCall) {
        this.commonApiService.getPaginated(Commonvariable.VacancyRuleService, null, null, null, null, null, data).subscribe((result) => {
          if (result["data"] != null && result["data"] != "" && result["status"] == 200) {
            this.totalItems1 = result["data"]["recordCount"];
            this.myApprovallist = result["data"]["list"];
            this.itemsPerPage = pageSize;
          }
        }, (error) => {
          this.totalItems1 = 0;
          this.myApprovallist = [];
          //this.toastr.warning(error["error"].message, 'Approval Process Configuration');
        })
      }

    } else if ($(".companySelectListClassFilter option:selected").val() != '' && $(".siteSelectListClassFilter option:selected").val() != '' && $(".departmentSelectListClassFilter option:selected").val() == '') {
      var data1 = {
        "searchBy": [
          {
            "FieldName": 'company_Id',
            "FieldValue": $(".companySelectListClassFilter option:selected").val() != "" ? $(".companySelectListClassFilter option:selected").val() : '0',
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": 'site_Id',
            "FieldValue": $(".siteSelectListClassFilter option:selected").val() != "" ? $(".siteSelectListClassFilter option:selected").val() : '0',
            "Parameter": "=="
          }
        ],
        "PageIndex": pageIndex != null ? pageIndex : 0,
        "PageSize": pageSize != null ? pageSize : 10,
        "RecordCount": 100,
        "RecordLimit": 100,
        "CommonSearch": $("#filterList").val().trim()
      }


      if (apiCall) {
        this.commonApiService.getPaginated(Commonvariable.VacancyRuleService, null, null, null, null, null, data1).subscribe((result) => {
          if (result["data"] != null && result["data"] != "" && result["status"] == 200) {
            this.totalItems1 = result["data"]["recordCount"];
            this.myApprovallist = result["data"]["list"];
            this.itemsPerPage = pageSize;

          }
        }, (error) => {
          this.totalItems1 = 0;
          this.myApprovallist = [];
          this.toastr.warning(error["error"].message, 'Approval Process Configuration');
        })
      }

    } else if ($(".companySelectListClassFilter option:selected").val() != '' && $(".siteSelectListClassFilter option:selected").val() != '' && $(".departmentSelectListClassFilter option:selected").val() != '') {


      var data2 = {
        "searchBy": [
          {
            "FieldName": "company_Id",
            "FieldValue": $(".companySelectListClassFilter option:selected").val() != "" ? $(".companySelectListClassFilter option:selected").val() : '0',
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": 'site_Id',
            "FieldValue": $(".siteSelectListClassFilter option:selected").val() != "" ? $(".siteSelectListClassFilter option:selected").val() : '0',
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": 'dept_Id',
            "FieldValue": $(".departmentSelectListClassFilter option:selected").val() != "" ? $(".departmentSelectListClassFilter option:selected").val() : '0',
            "Parameter": "=="
          }
        ],
        "PageIndex": pageIndex != null ? pageIndex : 0,
        "PageSize": pageSize != null ? pageSize : 10,
        "RecordCount": 100,
        "RecordLimit": 100,
        "CommonSearch": $("#filterList").val().trim()
      }


      if (apiCall) {
        this.commonApiService.getPaginated(Commonvariable.VacancyRuleService, null, null, null, null, null, data2).subscribe((result) => {
          if (result["data"] != null && result["data"] != "" && result["status"] == 200) {
            this.totalItems1 = result["data"]["recordCount"];
            this.myApprovallist = result["data"]["list"];
            this.itemsPerPage = pageSize;
          }
        }, (error) => {
          this.totalItems1 = 0;
          this.myApprovallist = [];
          this.toastr.warning(error["error"].message, 'Approval Process Configuration');
        })
      }
    }
  }

  vacancyRuleRecuiter() {
    return this.fb.group({
      Id: '0',
      Vacancy_Rule_Id: 0,
      Emp_Id: ['0'],
      isActive: '1',
      AddedBy: Commonvariable.User_Id,
    });
  }

  vacancyRuleDetail(vrDetail) {
    return this.fb.group({
      Id: vrDetail != null ? vrDetail.id : '0',
      Vacancy_Rule_Id: vrDetail != null ? vrDetail.vacancy_Rule_Id : '',
      Company_Id: vrDetail != null ? vrDetail.company_Id : '0',
      Site_Id: vrDetail != null ? vrDetail.site_Id : '0',
      //dept_IdList: vrDetail != null ? vrDetail.dept_IdList : '0',
      Dept_Id: vrDetail != null ? vrDetail.dept_Id : '0',
      Desig_Id: vrDetail != null ? vrDetail.desig_Id : '',
      Emp_Id: vrDetail != null ? String(vrDetail.emp_Id) : '',
      Priority: vrDetail != null ? vrDetail.priority : '0',
      isFinal: vrDetail == null || vrDetail.isFinal == 0 ? false : true,
      acrossCompany: vrDetail != null ? vrDetail.acrossCompany == 1 ? true : false : false,
      isActive: '1',
      AddedBy: Commonvariable.User_Id,
      empList: this.fb.array([])
    });



  }


  addvacancyRuleDetail() {
    if ($("#Company_Id").val() != "") {
      if (this.validation()) {
        this.items = this.vacancyRuleForm.get('vacancy_rule_detail') as FormArray;
        this.items.push(this.vacancyRuleDetail(null));
        this.vacancyRuleForm.controls.vacancy_rule_detail.value[this.items.length - 1]["empList"] = this.hrList
      }
    }
  }

  removevacancyRuleDetail(index) {
    this.items = this.vacancyRuleForm.get('vacancy_rule_detail') as FormArray;
    this.items.removeAt(index);
  }

  getCompanyList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'company_Name'
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {

      if (response["status"] == 200 && response["data"] != "") {
        this.companyList = response["data"].filter(x => x.isActive != 0);
        this.companyListFilter = response["data"].filter(x => x.isActive != 0);
      }
    })
  }

  getSiteList(event = null, siteID = null, deptID = null) {
    if (event != null) {
      var requestData = {
        "searchBy": [
          {
            "FieldName": "Company_Id",
            "FieldValue": event,
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "isActive",
            "FieldValue": "1",
            "Parameter": "=="
          }
        ],
        "OrderBy": 'site_Name'
      }

      this.commonApiService.GetByPaginated(Commonvariable.Site, requestData).subscribe((response) => {
        if (response["status"] == 200 && response["data"] != "") {
          this.siteList = response["data"];
          this.getDepartmentList(event, deptID);
        }
      })
    }


    $('#site_Id').val(siteID != null ? siteID : '');
    this.getEmpList(this.vacancyRuleForm.get('Company_Id').value, this.vacancyRuleForm.get('site_Id').value);



  }

  getSiteForfilter(event = null, siteID = null, deptID = null) {
    if (event != null) {

      var requestData = {
        "searchBy": [
          {
            "FieldName": "Company_Id",
            "FieldValue": event,
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "isActive",
            "FieldValue": "1",
            "Parameter": "=="
          }
        ],
        "OrderBy": 'site_Name'
      }

      this.commonApiService.GetByPaginated(Commonvariable.Site, requestData).subscribe((response) => {
        if (response["status"] == 200 && response["data"] != "") {
          this.siteListFilter = response["data"];
          this.getDepartmentFilterList(event, deptID);
        }
        else{
          this.siteListFilter = [];
          this.getDepartmentFilterList(0);
        }
      })
    }

    $('#site_Id').val(siteID).trigger('change');
    this.getEmpList(this.vacancyRuleForm.get('Company_Id').value, this.vacancyRuleForm.get('site_Id').value);

  }

  getSiteAndDepartmentList(event) {
    this.myAprrovalSearchOneValue("onLoad", 0);
    this.getSiteList(event);
  }

  getDepartmentList(event = null, deptID = null) {


    var requestData = {
      "searchBy": [
        {
          "FieldName": "Company_Id",
          "FieldValue": event,
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'dept_Name'
    }

    this.commonApiService.GetByPaginated(Commonvariable.Department, requestData).subscribe((response) => {
      if (response["status"] == 200 && response["data"] != "") {
        this.departmentList = response["data"];
        this.multidepartmentList = response["data"].map(x => ({
          id: x.dept_Id,
          text: x.dept_Name
        }))
        this.getDesignationList(event)
      }
    })

    // $('#dept_Id').val(deptID != null ? deptID : '').trigger('change');

  }

  getDepartmentFilterList(event = null, deptID = null) {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "Company_Id",
          "FieldValue": event,
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'dept_Name'
    }
    this.commonApiService.GetByPaginated(Commonvariable.Department, requestData).subscribe((response) => {
      if (response["status"] == 200 && response["data"] != "") {
        this.departmentListFilter = response["data"];
        this.getDesignationList(event)
      }
      else
      {
        this.departmentListFilter =[];
      }
    })

    if (deptID != null) {
      $('#dept_Id').val(deptID).trigger('change');
    }
  }

  getDesignationList(event = null) {
    var requestData = {
      "searchBy": [
        {
          "FieldName": "Company_Id",
          "FieldValue": event,
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'desig_Name'
    }

    this.commonApiService.GetByPaginated(Commonvariable.Designation, requestData).subscribe((response) => {
      if (response["status"] == 200 && response["data"] != "") {
        this.designationList = response["data"];
      }
    })
  }

  getEmpList(cid = null, sid = null) {

    this.hrList = [];
    this.commonApiService.List(Commonvariable.EmployeeDetail, "GetHRRecruiters").subscribe((response) => {
      if (response["data"] != "") {
        this.hrList = response["data"].sort((a, b) => a.firstName.localeCompare(b.firstName));

     
        // if (cid) {
        //   this.getVacancyRuleDetailList(cid, sid, this.vacancyRuleForm.get('dept_Id').value)
        // } else {
        //   this.getVacancyRuleDetailList()
        // }
      }
    })
  }

  upsert(id) {
    if (this.validation(true)) {
      

      if (id > 0) {
        
        this.vacancyRuleForm.controls.Id.setValue(this.Vacancy_Rule_Id);
        this.vacancyRuleForm.controls.vacancy_rule_recruiter.value[0]["Vacancy_Rule_Id"] = this.Vacancy_Rule_Id
        this.vacancyRuleForm.controls.vacancy_rule_recruiter.value[0]["Id"] = this.vacancyRuleRecruiterId
        if(this.vacancyRuleForm.controls.dept_IdList.value.length && (this.vacancyRuleForm.controls.dept_IdList.value[0]==''|| this.vacancyRuleForm.controls.dept_IdList.value[0]==null))
        {
          this.vacancyRuleForm.controls.dept_IdList.value[0]=0;
        }
        
        
      }


      this.commonApiService.Upsert("Vacancy_RuleService", this.vacancyRuleForm.value, id).subscribe((result) => {
        
        if (result["data"] != null) {
          if (result["message"] == Commonvariable.DuplicationMsg) {
            this.commonService.toaster("warning", 'Record is already exists !', 'Create Rules')
          }
          else {
            this.addrolevisible = false;
            this.commonService.toaster("success", id > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Create Rules')
            this.Vacancy_Rule_Id = result["data"]["id"];
            this.vacancyRuleRecruiterId = result["data"].vacancy_rule_recruiter[0]["id"]
            if (id > 0) {
              $("#Createrecruitment").modal("hide");
            }
            // this.getVacancyRuleDetailList();
            this.myAprrovalSearchOneValue("onLoad", 0);
          }
        }
      })
    }
  }

  validation(upsert = false) {
    
    var formData = this.vacancyRuleForm;
    formData.controls.Company_Id.setValue($(".companySelectListClass option:selected").val());
    formData.controls.site_Id.setValue($("#site_Id option:selected").val() != "" ? $("#site_Id option:selected").val() : null);
  //  formData.controls.dept_IdList = $("#dept_Id option:selected").text().trim();
  //  formData.controls.dept_IdList.setValue(String(formData.controls.dept_IdList).trim());
    if (formData.controls.dept_IdList.value != "") {
      if (Array.isArray(formData.controls.dept_IdList.value)) {
      let trimmedArray = formData.controls.dept_IdList.value.map((value: string) => value.trim());
          formData.controls.dept_IdList.setValue(trimmedArray);
      }
      else{
        formData.controls.dept_IdList.setValue([formData.controls.dept_IdList.value]);
      }
    }
   
    
    formData.controls.Type.setValue($("#Type option:selected").val() != "" ? $("#Type option:selected").val() : null);
    formData.controls.person_Id.setValue($(".drppersonId").val() != "" ? $(".drppersonId").val() : null);
    formData.controls.vacancy_rule_recruiter.value[0]["Emp_Id"] = $("#emp_Id").val()


    formData.controls.vacancy_rule_detail.value.forEach(x => {
      x.isFinal = x.isFinal ? 1 : 0
      x.Company_Id = formData.value.Company_Id
      x.Site_Id = formData.value.site_Id
      x.Dept_Id = formData.value.dept_Id
    });

    for (const key of Object.keys(formData.controls)) {

      if (formData.controls[key].invalid) {
        let fieldName = "";
        if (key == "name") {
          fieldName = "Name"
        }
        else if (key == "Company_Id") {
          fieldName = "Company"
        }
        else if (key == "site_Id") {
          fieldName = "Site"
        }
        else if (key == "dept_Id") {
          fieldName = "Department"
        }
        this.commonService.toaster('info', fieldName + " is required !", 'Create Rules')
        $("#" + key).focus();
        return false;
      }

      if (key == "name") {
        // if ($("#name").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
        //   this.toastr.info("Name only alphabets and numeric allow !", "Create Rules");
        //   $("#name").focus();
        //   return false;
        // }

        if (/["\\]/.test($("#name").val())) {
          this.toastr.info("Name does not allow Special Characters \" \\ ", "Company");
          $("#name").focus();
          return false;
        }
      }
    }

    if ($("#emp_Id").val() == "") {
      this.commonService.toaster('info', "Assigned to HR recruiter is required !", 'Create Rules')
      $("#emp_Id").focus();
      return false;
    }

    var vacancyDetail = this.vacancyRuleForm.get('vacancy_rule_detail').value;

    for (let index = 0; index < vacancyDetail.length; index++) {
      vacancyDetail[index].Vacancy_Rule_Id = this.Vacancy_Rule_Id;
      vacancyDetail[index].Emp_Id = $("#Emp_Id" + index).val();

      if (vacancyDetail[index].Desig_Id == '') {
        this.commonService.toaster('info', "Job title is required !", 'Create Rules')
        $("#Desig_Id" + index).focus();
        return false;
      }
      if (vacancyDetail[index].Emp_Id == '' || $("#Emp_Id" + index).val() == "") {
        this.commonService.toaster('info', "Name of approver is required !", 'Create Rules')
        $("#Emp_Id" + index).focus();
        return false;
      }

      // if (vacancyDetail.filter(x => x.Desig_Id == vacancyDetail[index].Desig_Id && x.Emp_Id == vacancyDetail[index].Emp_Id).length > 1) {
      //   $("#Emp_Id" + index).focus();
      //   this.commonService.toaster('info', "Approver more then 1 time not allow !", 'Create Rules')
      //   return false;
      // }

      vacancyDetail[index].isFinal = $("#isfinalcheck" + index).prop("checked") ? 1 : 0
      vacancyDetail[index].acrossCompany = $("#acrossCompany" + index).prop("checked") ? 1 : 0
      vacancyDetail[index].Priority = index + 1
    }

    if (vacancyDetail.length > 0 && $(".isfinalcheck:checked").length == 0 && upsert) {
      this.commonService.toaster('info', "Final approver is required !", 'Create Rules')
      return false;
    }


    return true;
  }

  popupTitle() {
    this.modalTitle = "Create Rules";
    this.title="Add";
  }

  clear(detailClear = false, init = false, onchange = false) {
    if (detailClear) {
      this.items = this.vacancyRuleForm.get('vacancy_rule_detail') as FormArray;
      this.items.clear()
      this.items = this.vacancyRuleForm.get('vacancy_rule_recruiter') as FormArray;
      this.items.clear()
      this.items.push(this.vacancyRuleRecuiter())
      $("#acrossCompany").prop("checked", false)
    }
    if (init) {
      $("#Company_Id").val('')
      $("#emp_Id").val('')
      $("#Type").val('')
      $("#person_Id").val('')
      $("#acrossCompany").prop("checked", false)
      $('.companySelectListClass').select2({ width: '100%', disabled: false });
      this.addrolevisible = true
      this.vacancyRuleForm.controls.Id.setValue('0');
      this.vacancyRuleForm.controls.Company_Id.setValue('');
      this.vacancyRuleForm.controls.site_Id.setValue('');
      this.vacancyRuleForm.controls.dept_Id.setValue('');
      this.vacancyRuleForm.controls.dept_IdList.setValue('');
      this.vacancyRuleForm.controls.Type.setValue('');
      this.vacancyRuleForm.controls.person_Id.setValue('');
      $('.drp').select2({ width: '100%' });
      $('#dept_Id').select2({ width: '100%', disabled: false,multiple: true }).trigger('change');
      this.isDisabled = false;

    }
    if (init || onchange) {
      this.siteList = null;
      this.departmentList = null;
      this.multidepartmentList=null;
      $("#name").val($("#name").val() != "" ? init ? "" : $("#name").val() : "");
    }
  }

  changeIndex(id) {

    const rules = this.vacancyRuleForm.get('vacancy_rule_detail') as FormArray;
    const currentGroup = rules.at(id);
    let newIndex: number = (id + (-1)) === -1 ? rules.length - 1 : (id + (-1)) == rules.length ? 0 : id + (-1);
    rules.removeAt(id);
    rules.insert(newIndex, currentGroup)
  }


  changeFinalCheckBox(id) {
    $(".isfinalcheck").prop("checked", false);
    $("#isfinalcheck" + id).prop("checked", true);
  }


  rafConfigDelete(Id) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.VacancyRuleService, Id).subscribe((response) => {
          this.myAprrovalSearchOneValue("onLoad", 0);
          this.toastr.success(response["message"], 'Approval Process Configuration');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.VacancyRuleService, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.toastr.success(response["message"], 'Approval Process Configuration');
            this.ngOnInit();
          }

        })
      }

    });
  }



  getfilllAcorssCompanydrp() {
    var requestData = {
      "searchBy": [
        {
          "FieldName": 'RAF_Approval',
          "FieldValue": 1,
          "Parameter": "=="
        }
      ]
    }
    this.commonApiService.GetByPaginated(Commonvariable.EmployeeDetail, requestData).subscribe((result) => {
      if (result["data"] != "") {
        this.filllAcorssCompany = result["data"]
      }
    });

  }

  filllAcorssCompanydrp(id, designationid = 0, empId = 0) {

    $("#Emp_Id" + id).html("")
    $("#Emp_Id" + id).html('<option value="">Select Employee</option>')

    this.vacancyRuleForm.get('vacancy_rule_detail').value[id].Emp_Id = "";

    if ($("#acrossCompany" + id).prop("checked")) {
      var requestData = {
        "searchBy": [
          {
            "FieldName": 'RAF_Approval',
            "FieldValue": 1,
            "Parameter": "=="
          }
        ]
      }
      var designationId = [];
      let designationOptionHtml = '<option value="">Select Job Title</option>'
      this.filllAcorssCompany.forEach(x => {
        if (!designationId.includes(x.desig_Id)) {
          designationOptionHtml += "<option value='" + x.desig_Id + "'>" + x.desig_Name + "</option>";
        }
        designationId.push(x.desig_Id)
      });
      $("#Desig_Id" + id).html('')
      $("#Desig_Id" + id).html(designationOptionHtml)

      if (designationid > 0) {
        $("#Desig_Id" + id).val(designationid)
        this.onChangeDesignation(designationid, id, empId)
      }
    }
    else {
      let designationOptionHtml = '<option value="">Select Job Title</option>'
      this.designationList.forEach(x => {
        designationOptionHtml += "<option value='" + x.desig_Id + "'>" + x.desig_Name + "</option>";
      });
      $("#Desig_Id" + id).html('')
      $("#Desig_Id" + id).html(designationOptionHtml)
    }



  }


  getCompanywisedata(id, filter = false) {
    if (filter) {
      this.siteListFilter = [];
      this.departmentListFilter = [];
    }
    else {
      this.siteList = [];
      this.employeeList = [];
      this.departmentList = [];
      this.multidepartmentList=[];
    }
    var requestData = {
      "searchBy": [
        {
          "FieldName": "Company_Id",
          "FieldValue": id,
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ]
    }

    if (id > 0) {
    
      this.commonApiService.getPaginated(Commonvariable.CompanyDetail, null, null, null, null, null, requestData, "GetByCompany_IdPaginated").subscribe((result) => {
        this.siteList = result["data"][0]["site"]
        this.departmentList = result["data"][0]["department"]

        this.multidepartmentList = result["data"][0]["department"].map(x => ({
          id: x.dept_Id,
          text: x.dept_Name
        }))


         this.designationList = result["data"][0]["desigforRaf"]
      })

      this.commonApiService.GetByPaginated(Commonvariable.EmployeeDetail, requestData).subscribe((result) => {
        
        if (result["data"] != "" && result["data"]) {
          this.employeeList = result["data"];
       
         
        }
      })
    }
  }

  getemployeeListForname(userId){
    this.commonApiService.getById(Commonvariable.Employee,userId).subscribe((result) => {
      
      if (result["data"] != "" && result["data"]) {
        this.createdname = result["data"][0].employee_FullName;
      
       
      }
    })
  }

  getRules(id) {
    this.rulesList = [];
    var visibility = $(".sub_tbl_" + id).toggle().is(":visible");
    $(".sub_tbl").hide();
    $(".details-control").css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    if (visibility === true) {
      $(".sub_tbl_" + id).show();
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/d4ICC.png) no-repeat center center");
      this.getRuleDetails(id);
    } else {
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    }
  }
  onChangeSelectCompany(e){
  }
 
  editRecord(id) {
    
    this.title = "Edit"

    this.modalTitle = "Edit Rules";

    this.commonApiService.getById(Commonvariable.VacancyRuleService, id, "ViewSummary").subscribe((result) => {


      $('.companySelectListClass').select2({ width: '100%', disabled: true });

      if (result["data"] != "") {


        this.getCompanywisedata(result["data"][0]["company_Id"])
        this.vacancyRuleForm.controls.Company_Id.setValue(result["data"][0]["company_Id"])
        this.vacancyRuleForm.controls.site_Id.setValue(result["data"][0]["site_Id"])
        this.vacancyRuleForm.controls.dept_Id.setValue(result["data"][0]["dept_Id"])
        this.vacancyRuleForm.controls.dept_IdList.setValue(String(result["data"][0]["dept_IdList"]).split(","));
        //this.vacancyRuleForm.controls.dept_IdList.setValue(result["data"][0]["dept_IdList"]);
        this.vacancyRuleForm.controls.Type.setValue(result["data"][0]["type"])
        this.vacancyRuleForm.controls.person_Id.setValue(result["data"][0]["person_Id"])
        this.vacancyRuleForm.controls.budgeted.setValue(String(result["data"][0]["budgeted"]))




        $('#Company_Id').val(result["data"][0]["company_Id"] != null ? result["data"][0]["company_Id"] : "");
        $('#site_Id').val(result["data"][0]["site_Id"] != null ? result["data"][0]["site_Id"] : "");
       // $('#dept_Id').val(result["data"][0]["dept_IdList"] != null ? result["data"][0]["dept_IdList"] : "")
        $('#person_Id').val(result["data"][0]["deperson_Idpt_Id"] != null ? result["data"][0]["person_Id"] : "")
        $('#Type').val(result["data"][0]["type"] != null ? result["data"][0]["type"] : "")
        $('#emp_Id').val(result["data"][0]["vacancy_rule_recruiter"][0]["emp_Id"] != null ? result["data"][0]["vacancy_rule_recruiter"][0]["emp_Id"] : "")

        $('.drp, .companySelectListClassFilter,.siteSelectListClassFilter, .departmentSelectListClassFilter').select2({ width: '100%' });

        if (result["data"][0]["person_Id"] != null && result["data"][0]["person_Id"] != "") {
          $('#dept_Id').select2({ width: '100%', disabled: true,multiple: true  }).trigger('change');
          this.isDisabled = true;
        }

        this.addedOn = result["data"][0].addedOn;
        this.createdname=  this.getemployeeListForname(result["data"][0].addedBy);

        this.addrolevisible = false

        this.vacancyRuleList = result["data"][0];
        this.vacancyRuleForm.controls.name.setValue(this.vacancyRuleList.name);
        this.Vacancy_Rule_Id = this.vacancyRuleList.id
        this.vacancyRuleRecruiterId = result["data"][0]["vacancy_rule_recruiter"][0]["id"]
        this.clear(true);

        this.items = this.vacancyRuleForm.get('vacancy_rule_detail') as FormArray;
        result["data"][0]["vacancy_rule_detail"].forEach(x => {
          this.items.push(this.vacancyRuleDetail(x));
        });

        setTimeout(() => {
          for (let index = 0; index < this.vacancyRuleForm.controls.vacancy_rule_detail.value.length; index++) {

            this.onChangeDesignation(this.vacancyRuleForm.controls.vacancy_rule_detail.value[index]["Desig_Id"], index, result["data"][0]["vacancy_rule_detail"][index]["emp_Id"]);

            if (this.vacancyRuleForm.controls.vacancy_rule_detail.value[index].acrossCompany) {
              this.filllAcorssCompanydrp(index, this.vacancyRuleForm.controls.vacancy_rule_detail.value[index]["Desig_Id"], result["data"][0]["vacancy_rule_detail"][index]["emp_Id"])
            }

            $("#Emp_Id" + index).val(result["data"][0]["vacancy_rule_detail"][index]["emp_Id"])


          }
        }, 200);

      }
    })

  }

}

