<div class="modal fade draggable-modal in" id="draggable" role="basic" aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="closeModal()"></button>
                <h4 class="modal-title">{{title}} Contract</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="contractform">
                    <div class="row">
                        <div class="col-md-6">
                            <div>
                                <label class="control-label">Company<span class="required"> *
                                    </span></label>

                                <ng-select2 *ngIf="title != 'Edit'" [data]="companylist" id="company_Id" formControlName="company_Id" [placeholder]="'--- Select ---'" [options]="{multiple: true,tags: false}" [disabled]="title == 'Edit'?true:false">
                                </ng-select2>
                                <input *ngIf="title == 'Edit'" type="text" id="company_Id" class="form-control readonlyClass" autocomplete="off" formControlName="company_Name" maxlength="10" readonly disabled>

                            </div>
                        </div>
                        <!-- <div class="col-md-4">
                            <div >
                                <label class="control-label">Contract Code<span class="required"> *
                                    </span></label>
                                <input type="text" id="contract_Code" class="form-control"
                                    formControlName="contract_Code" autocomplete="off" maxlength="20">
                            </div>
                        </div> -->
                        <div class="col-md-3">
                            <div>
                                    <label class="control-label">Contract Type<span class="required"> *
                                        </span></label>
                                    <select id="contract_Type" class="form-control " formControlName="contract_Type">
                                        <option value="">Select Contract Type</option>
                                        <option value="Full time">Full time</option>
                                        <option value="Part time">Part time</option>
                                        <option value="Permanent">Permanent</option>
                                        <option value="Temporary">Temporary</option>
                                        <option value="Casual worker">Casual worker</option>
                                    </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div>
                                <label class="control-label">Contract Hours Weekly<span class="required"> *
                                    </span></label>
                                <input type="number" id="contract_HoursDaily" class="form-control" min="1" max="24" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"' formControlName="contract_HoursDaily">
                            </div>
                        </div>

                    </div>
                    <div class="row">

                        <!-- <div class="col-md-4">
                            <div >
                                <label class="control-label">Contract Hours Weekly<span class="required"> *
                                    </span></label>
                                <input type="number" id="contract_HoursWeekly" class="form-control" min="1" max="168"
                
                                    formControlName="contract_HoursWeekly">
                            </div>
                        </div> -->
                        <div class="col-md-6">
                            <div>
                                <label class="control-label">Contract Name<span class="required"> *
                                    </span></label>
                                <input type="text" id="contract_Name" class="form-control" formControlName="contract_Name" autocomplete="off" maxlength="30">
                            </div>
                        </div>

                        <div class="col-md-6">
                            <label class="control-label">Working Days<span class="required"> * </span></label>
                            <hr>
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox has-success" style="margin-right: 10px;">
                                    <input type="checkbox" id="checkbox14" value="mon" class="md-check wd">
                                    <label for="checkbox14">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> M</label>
                                </div>
                                <div class="md-checkbox has-success" style="margin-right: 10px;">
                                    <input type="checkbox" id="checkbox15" value="tue" class="md-check wd">
                                    <label for="checkbox15">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> T </label>
                                </div>
                                <div class="md-checkbox has-info" style="margin-right: 10px;">
                                    <input type="checkbox" id="checkbox16" value="wed" class="md-check wd">
                                    <label for="checkbox16">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> W </label>
                                </div>
                                <div class="md-checkbox has-info" style="margin-right: 10px;">
                                    <input type="checkbox" id="checkbox17" value="thr" class="md-check wd">
                                    <label for="checkbox17">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> T </label>
                                </div>
                                <div class="md-checkbox has-info" style="margin-right: 10px;">
                                    <input type="checkbox" id="checkbox18" value="fri" class="md-check wd">
                                    <label for="checkbox18">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> F </label>
                                </div>
                                <div class="md-checkbox has-error" style="margin-right: 10px;">
                                    <input type="checkbox" id="checkbox19" value="sat" class="md-check wd">
                                    <label for="checkbox19">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> S </label>
                                </div>
                                <div class="md-checkbox has-error" style="margin-right: 10px;">
                                    <input type="checkbox" id="checkbox20" value="sun" class="md-check wd">
                                    <label for="checkbox20">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> S </label>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div>
                                <label class="control-label">Length of Contract</label>
                                <input type="number" id="contract_Days" class="form-control" min="1" max="1825" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"' formControlName="contract_Days">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div>
                                <label class="control-label">Notes</label>
                                <textarea id="notes" class="form-control" rows="1" formControlName="notes" maxlength="1000"></textarea>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div>
                                <label class="control-label">Active</label>
                                <div class="md-checkbox-inline">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="checkbox1_3" class="md-check" formControlName="isActive">
                                        <label for="checkbox1_3">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn dark btn-outline pull-left" (click)="history()" *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button>
                <button type="button" data-dismiss="modal" (click)="closeModal()" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="contractUpsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Contract History</h4>
            </div>
            <div class="modal-body" *ngIf="title == 'Edit'">
                <app-history></app-history>
            </div>
        </div>
    </div>
</div>