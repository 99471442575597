<form [formGroup]="empform" class="pt-15">
    <input type="hidden" value="" id="Company_Id" name="Company_Id">
    <input type="hidden" value="" id="Dept_Id" name="Dept_Id">
    <input type="hidden" value="" id="Desig_Id" name="Desig_Id">

    <!-- <div class="col-sm-12">
        <div class="row mt-15">
            <button  type="button" *ngIf="this.empform.get('emp_salary').value[0].Salary != ''" (click)="addItem(null)" class="btn pull-right">
                <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
        </div>
    </div> -->
    <div class="col-md-12" formArrayName="emp_salary" *ngFor="let item of empform.get('emp_salary')['controls']; let i = index;">
        <div class="row" [formGroupName]="i">
    <!-- <div class="col-md-12" formArrayName="emp_salary" >
        <div class="row" > -->
            

            <div class="col-md-4 mb-15 mt-15">
                <label>Salary </label>
                <div class="input-group">
                    <input type="number" min="0" value="0" step="0.01" class="form-control" id="Salary{{i}}" formControlName="Salary" maxlength="20">
                    <!-- <span class="input-group-addon inputaddon">£</span> -->
                    <span class="input-group-addon inputaddon">{{currency_Code}}</span>
                </div>
            </div>

            <div class="col-md-4 mb-15 mt-15">
                <label>Salary Type </label>
                <select id="SalaryType{{i}}" class="form-control" formControlName="SalaryType">
                    <option value="">Select Salary Type</option>
                    <option value="Annually">Annually</option>
                    <option value="Hourly">Hourly</option>
                    
                </select>
            </div>

            <div class="col-md-4 mb-15 mt-15">
                <label>Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox">
                        <input type="checkbox" id="empsal{{i}}" class="md-check" formControlName="isActive">
                        <label for="empsal{{i}}">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> </label>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="row">
                    <button  type="button"  (click)="addItemSalary(null)" class="btn pull-right">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            <div formArrayName="emp_salary_additional">
                <!-- <div *ngFor="let additional of empform.get('emp_salary.emp_salary_additional')['controls']; let j = index"> -->
                    <div *ngFor="let additional of item.get('emp_salary_additional')['controls']; let j = index">
                    <div [formGroupName]="j">
           

            <div class="col-md-4 mb-15" >
                <label>Additional Payment</label>
                <select class="form-control" (change)="toggleCommentsTextarea($event, j)"  formControlName="AdditionalPayment" id="AdditionalPayment{{j}}" >
                    <option value="">Select Payment</option>
                    <option value="Night Shift Premium">Night Shift Premium</option>
                    <option value="Car Allowance">Car Allowance</option>
                    <option value="Travel Allowance">Travel Allowance</option>
                    <option value="Other">Other</option> 
                </select>
            </div>

            <div class="col-md-4 mb-15" >
                <label>Additional Payment Type<span class="required" *ngIf="isrequiredclass[j]"> * </span> </label>
                <select class="form-control" formControlName="AdditionalPaymentType" id="AdditionalPaymentType{{j}}">
                    <option value="">Select Payment Type</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Hourly">Hourly</option> 
                </select>
            </div>

            <div class="col-md-4 mb-15" >
                <label>Additional Payment Amount<span class="required" *ngIf="isrequiredclass[j]"> * </span></label>
                <input type="text" class="form-control" formControlName="AdditionalPaymentAmount" id="AdditionalPaymentAmount{{j}}">
            </div>

            <div class="col-md-4 mb-15" id="comments{{j}}"  *ngIf="isDivHidden[j]">
                <label>Comments<span class="required" *ngIf="isrequiredComclass[j]"> * </span></label>
                <textarea cols="55" class="form-control" rows="3" id="Notes{{j}}" formControlName="Notes"></textarea>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <button *ngIf="j>0" type="button" (click)="removeItemSalary(j)" class="btn pull-right"><i
                        class="fa fa-minus" aria-hidden="true"></i></button>
                </div>
            </div>
        </div>

            <!-- <div class="col-md-12">
                <div class="row">
                    <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                        class="fa fa-minus" aria-hidden="true"></i></button>
                </div>
            </div> -->
        </div>
    </div>


        </div>
    </div>

</form> 
    
<!-- 
    <div class="form-group">
        <div class="table-responsive">
            <table class="table table-hover" id="work" style="position: relative;" role="grid">
                <thead>
                    <tr>
                        <th style="width: 20%;">Salary <span class="required" *ngIf="this.empform.get('emp_salary').value[0].Salary != '' || this.empform.get('emp_salary').value.length > 1"> * </span></th>
                        <th style="width: 20%;">Salary Type <span class="required" *ngIf="this.empform.get('emp_salary').value[0].Salary != '' || this.empform.get('emp_salary').value.length > 1"> * </span></th>
                        <th style="width: 40%;">Comments</th>

                        <th style="width: 20%;">Apprisal From</th>
                        <th style="width: 20%;">Apprisal To</th>
                        <th style="width: 2%;">Active</th>
                        <th style="width: 5%;">
                            <button *ngIf="this.empform.get('emp_salary').value[0].Salary != ''" type="button" (click)="addItem(null)" class="btn pull-right">
                                <i class="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody formArrayName="emp_salary" *ngFor="let item of empform.get('emp_salary')['controls']; let i = index;">
                    <tr [formGroupName]="i">
                        <td>
                            <div class="input-group">
                                <input type="number" min="0" value="0" step="0.01" class="form-control" id="Salary{{i}}" formControlName="Salary" maxlength="20">
                                
                                <span class="input-group-addon inputaddon">{{currency_Code}}</span>
                            </div>

                        </td>
                        <td>
                            <select id="SalaryType{{i}}" class="form-control" formControlName="SalaryType">
                                <option value="">Select Salary Type</option>
                                <option value="Annually">Annually</option>
                                <option value="Hourly">Hourly</option>
                                
                            </select>
                        </td>
                        <td>
                            <textarea cols="55" class="form-control" rows="3" id="Notes{{i}}" formControlName="Notes"></textarea>
                        </td>
                        <td>
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="empsal{{i}}" class="md-check" formControlName="isActive">
                                    <label for="empsal{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Additional Payment</label>
                            <select class="form-control">
                                <option value="">Select Payment</option>
                                <option value="">Night Shift Premium</option>
                                <option value="">Car allownce</option>
                                <option value="">Travel allownce</option>
                                <option value="">Other</option>
                                
                            </select>
                        </td> 
                        <td>
                            <div>
                            <label>Additional Payment Type</label>
                            <select class="form-control">
                                <option value="">Select Payment Type</option>
                                <option value="">Monthly</option>
                                <option value="">Hourly</option> 
                            </select>
                            </div>
                            <div>
                                <label>* Additional Payment Ammount</label>
                                <input type="text">
                            </div>
                        </td> 
                    </tr>
                    <tr>
                       
                        <td>
                            <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                                    class="fa fa-minus" aria-hidden="true"></i></button>
                        </td>
                    </tr>
                     

                   
                  
                </tbody>
            </table>
        </div>
    </div> -->


    <!-- <span class="input-group-addon inputaddon">£</span> -->
     <!-- <td>
                            <textarea cols="55" class="form-control" rows="3" id="Notes{{i}}" formControlName="Notes"></textarea>
                        </td>
                        <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="empsal{{i}}" class="md-check" formControlName="isActive">
                                    <label for="empsal{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td> -->
                        
                        <!-- <td>
                            <div class="input-group">
                                <input type="text" class="form-control" id="ApprisalFrom{{i}}">
                                <span class="input-group-btn">
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </td>
                        <td>
                            <div class="input-group">
                                <input type="text" class="form-control" id="ApprisalTo{{i}}">
                                <span class="input-group-btn">
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </td> -->


