<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title mrl10">
                <h1>Human Resource
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->

        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">

            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box white">

                <div class="col-md-4 col-md-offset-1 changereqform">
                    <div class="portlet-title">
                        <div class="caption">
                            <h3 class="mb-0">Change Requisition Form</h3>
                            <span>Please complete the following form before forwarding to HR </span>
                        </div>

                        <div class="actions">

                        </div>
                    </div>
                </div>

                <div class="portlet-body table-responsive" id="rafTable">
                    <form method="get" [formGroup]="changereqform">>
                        <div class="border border-primary container changepanelcontainer pt-3 mb-5" role="content">
                            <div class="panel-hdr">
                                <div class="alert alert-primary mb-5 rounded-0 sectionchangereqform" role="alert">
                                    <strong>Section 1 (Change Request Details)</strong>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-md-3 col-lg-3 col-sm-12 mb-3">
                                            <div class="form-group">
                                                <label class="form-label">Company:</label>
                                                <input type="text" class="form-control" placeholder="{{campany}}"
                                                    name="company" autocomplete="off" id="tags2"
                                                    formControlName="company11" >
                                            </div>
                                            
                                        </div>
                                        <!-- <div class="col-md-3 col-lg-3 col-sm-12 mb-3">
                                        <div class="form-group">
                                            <label class="form-label"> Employee number: </label>
                                            <input type="text" class="form-control" placeholder="Employee number" name="Employee number" autocomplete="off" id="tags2" formControlName="empNum">
                                        </div>
                                    </div> -->
                                        <div class="autocomplete pt-25">
                                           
                                            <!-- <div class="autocomplete-dropdown" *ngIf="employeeData?.length > 0 && !isLoading">
                                          <div *ngFor="let employee of employeeData" (click)="onSelectEmployee(employee)">
      
                                            {{ employeeData.}}
                                          </div>
                                        </div>
                                        <div *ngIf="isLoading" class="loading-indicator">Loading...</div>
                                      
                                        <div *ngIf="selectedEmployee">
                                          <p>Selected Employee ID: {{ selectedEmployee.emp_id }}</p>
                                          
                                        </div> -->
                                        
                                        <div class="col-md-6 col-lg-6 col-sm-12 mb-3">
                                            <div class="form-group">
                                                <label class="form-label"> Employee Name and Surname: </label>
                                                <input type="text" class="form-control"
                                                    
                                                    autocomplete="off" id="tags2" formControlName="fullName" >

                                            </div>
                                        </div>

                                         <div class="col-md-3 col-lg-3 col-sm-12 mb-3">
                                            <label class="form-label">Employee ID</label>
                                            
                                    
                                        <input
                              
                                        type="text"
                                        formControlName="enteremployeeid"
                                        placeholder="Enter Employee ID" class="form-control"
                                        [formControl]="searchControl"
                                        list="employeeDataList"  autocomplete="off"
                                        (input)="onSelectEmployee(searchControl.value)"
                                      />
                                      
                                      <datalist id="employeeDataList">
                                        <option *ngFor="let item of employeeDatas" [value]="item.emp_Code">
                                          {{ item.emp_Code }}
                                        </option>
                                      </datalist>
                                    </div>
                                    
                                     

                                        <div class="col-md-12">
                                            <div class="appenddata3"></div>
                                        </div>
                                    </div>

                                    <!-- datatable end -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="table-responsive">
                                    <table class="table table-bordered w-100 m-0">
                                        <thead>
                                            <tr class="bg-primary-400">
                                                <th>Change Requested:
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </th>
                                                <th>Current
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </th>
                                                <th>New
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row" class="p-2">Company </th>
                                                <td class="p-2">
                                                    <div class="form-group">
                                                        <input type="text" id="Company" class="form-control"
                                                            formControlName="company11" placeholder="{{campany}}"
                                                            >
                                                    </div>
                                                </td>

                                                <td class="p-2">
                                                    <!-- <div class="form-group">
                                                        <input type="text" id="Company1" class="form-control"
                                                            formControlName="company1">
                                                    </div> -->
                                                    <div class="form-group">
                                                        
<!--                                                         
                                                            <select id="company_Id" class="form-control" formControlName="company_Id"
                                                                (change)="companyWiseGetData()">
                                                                <option value="">Select Company</option>
                                                                <option *ngFor="let company of companyList;" value={{company.company_Id}}>
                                                                    {{company.company_Name}}</option>
                                                            </select> -->
                                                            <select id="company_Id" class="form-control" formControlName="company_Id" (change)="companyWiseGetData()">
                                                                <option value="">Select Company</option>
                                                                <option *ngFor="let company of companyList;" [value]="company.company_Id">{{company.company_Name}}</option>
                                                             </select>
                                                             
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" class="p-2">Department </th>
                                                <td class="p-2">
                                                    <div class="form-group">
                                                        <input type="text" id="Department" class="form-control"
                                                            formControlName="department1" placeholder="{{department}}"
                                                            >
                                                    </div>
                                                </td>
                                                <td class="p-2">
                                                    <!-- <div class="form-group">
                                                        <input type="text" id="Department1" class="form-control"
                                                            formControlName="department">
                                                    </div> -->
                                                
                                                        <div class="form-group">
                                                            <select id="Dept_Id" class="form-control" formControlName="Dept_Id" (change)="getJobTitle()">
                                                                <option value="">Select Department</option>
                                                                <option *ngFor="let item of departmentList;" [value]="item.dept_Id">
                                                                    {{item.dept_Name}}</option>
                                                            </select>
                                                            
                                                        </div>
                                                    
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" class="p-2">Zone</th>
                                                <td class="p-2">
                                                    <div class="form-group">
                                                        <input type="text" id="Zone" class="form-control"
                                                            formControlName="zone1" >
                                                    </div>
                                                </td>
                                                <td class="p-2">
                                                    <!-- <div class="form-group">
                                                        <input type="text" id="Zone1" class="form-control"
                                                            formControlName="zone">
                                                    </div> -->
                                                    
                                                        <div class="form-group">
                                                            <select id="Zone_Id" class="form-control" formControlName="Zone_Id">
                                                                <option value="">Select Zone</option>
                                                                <option *ngFor="let item of zoneList;" value={{item.zone_Id}}>
                                                                    {{item.zone_Name}}</option>
                                                            </select>
                                                           
                                                        </div>
                                                
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" class="p-2">Work Location / Site </th>
                                                <td class="p-2">
                                                    <div class="form-group">
                                                        <input type="text" id="WorkLocation" class="form-control"
                                                            formControlName="workSite1" placeholder="{{worklocation}}"
                                                            >
                                                    </div>
                                                </td>
                                                <td class="p-2">
                                                    <!-- <div class="form-group">
                                                        <input type="text" id="WorkLocation1" class="form-control"
                                                            formControlName="workSite">
                                                    </div> -->
                                                    
                                                        <div class="form-group">
                                                            <select id="Site_Id" class="form-control" formControlName="Site_Id"
                                                                (change)="getRafList($event.target.value)">
                                                                <option value="">Select Site</option>
                                                                <option *ngFor="let item of siteList;" value={{item.site_Id}}>
                                                                    {{item.site_Name}}</option>
                                                            </select>
                                                           
                                                        </div>
                                                    
                                                </td>
                                            </tr>


                                            <tr>
                                                <th scope="row" class="p-2">Job Title</th>
                                                <td class="p-2">
                                                    <div class="form-group">
                                                        <input type="text" id="Jobtitle" class="form-control"
                                                            formControlName="jobTitle1" placeholder="{{jobtitle}}"
                                                            >
                                                    </div>
                                                </td>
                                                <td class="p-2">
                                                    <!-- <div class="form-group">
                                                        <input type="text" id="Jobtitle1" class="form-control"
                                                            formControlName="jobTitle">
                                                    </div> -->
                                                    <div class="form-group">
                                                        <select id="Desig_Id" class="form-control" formControlName="Desig_Id"
                                                            (change)="getReportingList();getJobDescriptionList($event.target.value)">
                                                            <option value="">Select Job Title</option>
                                                            <option *ngFor="let item of designationList;" value={{item.desig_Id}}>
                                                                {{item.desig_Name}}</option>
                                                        </select>
                                                       
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" class="p-2">Reporting </th>
                                                <td class="p-2">
                                                    <div class="form-group">
                                                        <input type="text" id="ReportingLine" class="form-control"
                                                            formControlName="reporting1" placeholder="{{ linemanager}}"
                                                            >
                                                    </div>
                                                </td>
                                                <td class="p-2">
                                                    <!-- <div class="form-group">
                                                        <input type="text" id="ReportingLine1" class="form-control"
                                                            formControlName="reporting">
                                                    </div> -->
                                                    <div class="form-group">
                                                        <ng-select2 [data]="empList" id="companyid" [width]="400" formControlName="Line_Manager_Id"
                                                            [placeholder]="'Select Your Employee'">
                                                        </ng-select2>
                                                    </div>
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <th scope="row" class="p-2">Shift Time </th>
                                                <td class="p-2">
                                                    <div class="form-group">
                                                        <input type="text" id="contractedhours" class="form-control"
                                                            formControlName="shifttime1" placeholder="{{shifttime}}"
                                                            >
                                                    </div>
                                                </td>
                                                <td class="p-2">
                                                    <!-- <div class="form-group">
                                                        <input type="text" id="contractedhours1" class="form-control"
                                                            formControlName="shifttime">
                                                    </div> -->
                                                    
                                                        <div class="form-group">
                                                            <select id="Dept_Id" class="form-control" formControlName="Shift_Id">
                                                                <option value="">Select Shift</option>
                                                                <option *ngFor="let item of shiftList;" value={{item.shift_Id}}>
                                                                    {{item.shift_Name}} [{{item.shift_Start}} - {{item.shift_End}}]</option>
                                                            </select>
                                                            
                                                        </div>
                                                
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" class="p-2">Salary/ hourly rate </th>
                                                <td class="p-2">
                                                    <div class="form-group">
                                                        <input type="text" id="salaryhours" class="form-control"
                                                            formControlName="salaryHouryRate1" placeholder="{{ salary}}"
                                                            >
                                                    </div>
                                                </td>
                                                <td class="p-2">
                                                    <div class="form-group">
                                                        <input type="text" id="salaryhours1" class="form-control"
                                                            formControlName="salaryHouryRate">
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <!-- Your circular loader using CSS -->



                                                <th scope="row" class="p-2">Additional Payment</th>
                                                <td class="p-2">
                                                    <div class="form-group">
                                                        <input type="text" id="bonusannual1" class="form-control"
                                                            formControlName="addPayment1"
                                                            placeholder="{{additionalpayment}}" disabled>
                                                    </div>
                                                    <div *ngIf="uniqueAdditionalPayments.length > 1">
                                                        <div class="form-group"
                                                            *ngFor="let payment of uniqueAdditionalPayments; let i = index">
                                                            <ng-container *ngIf="i > 0">
                                                                <input type="text" id="bonusannual" class="form-control"
                                                                    formControlName="addPayment"
                                                                    placeholder="{{ payment }}" disabled>
                                                            </ng-container>
                                                        </div>
                                                    </div>

                                                </td>
                                                <td class="p-2">
                                                    <div class="form-group">
                                                        <input type="text" id="bonusannual1" class="form-control"
                                                            formControlName="addPayment1" placeholder="">
                                                    </div>
                                                    <div *ngIf="uniqueAdditionalPayments.length > 1">
                                                        <div class="form-group"
                                                            *ngFor="let payment of uniqueAdditionalPayments; let i = index">
                                                            <ng-container *ngIf="i > 0">
                                                                <input type="text" id="bonusannual" class="form-control"
                                                                    formControlName="addPayment1" placeholder="">
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-12">
                                        <form class="form-inline">
                                            <div class="form-group col-md-2">
                                                <label for="requestedtype"><strong>Request Type: </strong></label>
                                            </div>
                                            <div class="radio col-md-3 radchangerequest">
                                                <label class="radio-inline">
                                                  <input type="radio" [value]="'Permanent'" [formControl]="radioSelection" (change)="onRadioChange()"> Permanent
                                                </label>
                                                <label class="radio-inline">
                                                  <input type="radio" [value]="'Temporary'" [formControl]="radioSelection" (change)="onRadioChange()"> Temporary
                                                </label>
                                              </div>
                                              
                                            <div class="form-group col-md-3">

                                                <div class="input-group" id="flatpickropenReq" data-wrap="true">
                                                    <input type="text" class="form-control readonlyClass"
                                                        id="" readonly data-input />
                                                    <span class="input-group-btn" data-toggle>
                                                        <button class="btn default" type="button">
                                                            <i class="fa fa-calendar"></i>
                                                        </button>
                                                    </span>
                                                </div>

                                                <!-- <div class="input-group">
                                                            <input type="text" class="form-control " placeholder="Select Duration" id="datepicker-4-7">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text fs-xl">
                                                                    <i class="fal fa-calendar-check"></i>
                                                                </span>
                                                            </div>
                                                        </div> -->

                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <form class="form-inline">
                                            <div class="form-group col-md-3">
                                                <label for="effectivedate"><strong>EFFECTIVE DATE: </strong></label>
                                            </div>
                                            <!-- <div class="form-group col-md-4">
                                                <div class="input-group" id="flatpickropenEff" data-wrap="true">
                                                    <input type="text" class="form-control readonlyClass"
                                                        id="effectiveDate"   formControlName="effectiveDate"  readonly data-input />
                                                    <span class="input-group-btn" data-toggle>
                                                        <button class="btn default" type="button">
                                                            <i class="fa fa-calendar"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div> -->
                                            <div class="">
                                                <div class="input-group DOB" id="effectiveDate" data-wrap="true">
                                                    <input type="text" class="form-control readonlyClass"
                                                      id=""  autocomplete="off" formControlName="effectiveDatee" data-input />

                                                    <span class="input-group-btn" data-toggle>
                                                        <button class="btn default form-control datePicker" type="button">
                                                            <i class="fa fa-calendar"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>




                                
                                <hr>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <p class="mb-0"><strong>Annual leave to be re-calculated: YES/NO</strong> </p>
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-lg-8">
                                        <p class="mb-2"><strong>Please present the justification for Change
                                                required:</strong> (submit supporting documents if relevant) </p>

                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" id="submitsupport" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-3">
                                        <div class="form-group">
                                            <textarea class="form-control" id="example-textarea" rows="2"></textarea>
                                        </div>
                                    </div>

                                </div>
                                <div class="text-right mb-15">
                                    <button type="button" class="btn blue btn-md" (click)="updateProfile()">Save</button>
                                </div>
                                <div *ngIf="isLoading" class="loader"></div>

<!-- Your content goes here -->

                            </div>
                            <div class="col-lg-12 text-right mt-5 mb-5 revised">
                                <h6><strong> Revised v9 26 January 2023</strong> </h6>
                            </div>

                        </div>
                </div>
                <br />


                </form>
            </div>
            <div class="text-center">
                <pagination-controls autoHide="true" id="pagination1"></pagination-controls>
            </div>

        </div>

    </div>
    <!-- END PAGE BASE CONTENT -->
</div>
<!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->