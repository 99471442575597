import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;

@Component({
   selector: 'app-companycontact',
   templateUrl: './company-contact.component.html',
})
export class CompanyContactComponent implements OnInit {

   companyContactform: FormGroup;
   items: FormArray;
   circleActive: any = 0;
   countryList: Array<Select2OptionData>;
   countryId: any = 0
   isedit: boolean = false;
   constructor(
      private toastr: ToastrService,
      private commonApiService: CommonApiService,
      private commonService: CommonService,
      private router: Router,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {
      this.isedit = false;
      this.companyContactform = this.fb.group({
         company_Contact: this.fb.array([this.createItem(null)]),
      });

      this.getCountryList();
   }

   getCountryList() {

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "country_Name"
      }
      this.commonApiService.GetByPaginated(Commonvariable.Country, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            this.countryList = response["data"].map(x => ({
               id: x.id,
               text: x.country_Name + " (" + x.country_Code + ") "
            }));

            this.countryId = response["data"].filter(x => x.isDefault == 1)[0]["id"];
            if (!this.isedit) {
               this.items = this.companyContactform.get('company_Contact') as FormArray;
               this.items.clear();
               this.addItem(null)
            }

         }
      })
   }

   createItem(data) {
      return this.fb.group({
         company_Contact_Id: data != null ? data.company_Contact_Id : '0',
         company_Id: data != null ? data.company_Id : 0,
         contact_Type: data != null ? data.contact_Type : '',
         contact_Value: data != null ? data.contact_Value : '',
         country_Id: data != null ? data.country_Id : this.countryId != 0 ? this.countryId : '',
         isDefault: data != null ? data.isDefault == 1 ? true : false : false,
         isActive: data != null ? data.isActive == 1 ? true : false : true,
         addedBy: Commonvariable.User_Id,
         updatedBy: Commonvariable.User_Id
      });

   }

   addItem(data) {

      // if(this.validation())
      // {         
      if (data === null) {
         if (this.validation()) {
            this.items = this.companyContactform.get('company_Contact') as FormArray;
            this.items.push(this.createItem(data));
         }
      } else {
         this.items = this.companyContactform.get('company_Contact') as FormArray;
         this.items.push(this.createItem(data));
      }

      // }
   }


   removeItem(index) {
      this.items = this.companyContactform.get('company_Contact') as FormArray;
      if (this.items.value[index].company_Contact_Id > 0) {
         this.commonService.toaster("warning", "Saved entry not removed ! ", "Company")
         // this.commonService.deleteConfirm().then((result) => {
         //    if (result.value) {
         //       this.commonApiService.Delete(Commonvariable.CompanyContact, this.items.value[index].company_Contact_Id).subscribe((response) => {
         //          this.items[index].isActive = false	
         //       })
         //    }
         // })
      }
      else {
         this.items.removeAt(index);
      }

   }

   async companyContactUpsert(companyid, companyCode = null, companyLogo = null, cLogochange = false) {


      var companyContactData = this.companyContactform.get('company_Contact').value;
      if (companyContactData.length > 0) {
         if (this.validation()) {
            for (let index = 0; index < companyContactData.length; index++) {
               companyContactData[index].company_Id = companyid;
            }
            this.upsertServiceCall(companyCode, companyLogo, cLogochange);
         }
      }
      else {
         this.toastr.success(Commonvariable.SaveMsg, 'Company');
         this.router.navigateByUrl('companylist');
      }
   }


   index: number = 0;
   async upsertServiceCall(companyCode = null, companyLogo = null, cLogochange = false) {
      var data = this.companyContactform.get('company_Contact').value;
      await this.commonApiService.Upsert(Commonvariable.CompanyContact, data[this.index], data[this.index].company_Contact_Id).subscribe((response) => {
         if (response != null) {
            this.index++;
            if (this.index < data.length) {
               this.upsertServiceCall(companyCode, companyLogo, cLogochange);
            }
            else {
               if (companyCode != null && cLogochange) {
                  const formData = new FormData();
                  formData.append('strCompCode', companyCode);
                  formData.append('file', companyLogo);

                  this.commonApiService.UploadLogo(Commonvariable.Company, "UploadLogo", formData).subscribe((response) => {
                     this.toastr.success(Commonvariable.SaveMsg, 'Company');
                     this.router.navigateByUrl('companylist')
                  });
               }
               else {
                  this.toastr.success(Commonvariable.SaveMsg, 'Company');
                  this.router.navigateByUrl('companylist')
               }
            }
         }
      }, (error) => { this.toastr.error(error["error"].message, 'Company Contact'); })

   }

   validation() {
      var companyContactData = this.companyContactform.get('company_Contact').value;
      var groupByName = {};
      companyContactData.forEach(function (a) {
         groupByName[a.contact_Type] = groupByName[a.contact_Type] || [];
         groupByName[a.contact_Type].push({ isDefault: a.isDefault });
      });

      //console.log(groupByName);

      for (let index = 0; index < companyContactData.length; index++) {

         if (companyContactData[index].contact_Type == "") {
            this.toastr.info("Contact type is required !", "Company");
            $("#contact_Type" + index).focus();
            return false;
         }
         if (companyContactData[index].contact_Value == "") {
            this.toastr.info("Contact value is required !", "Company");
            $("#contact_Value" + index).focus();
            return false;
         }
         if ($("#contact_Value" + index).val().length < 2) {
            this.toastr.info("Contact value must be at least 2 characters long !", "Company");
            $("#contact_Value" + index).focus();
            return false;
         }

         if (companyContactData[index].contact_Type == "Phone No" || companyContactData[index].contact_Type == "Mobile No") {
            if (companyContactData[index].country_Id == "" || companyContactData[index].country_Id == null) {
               this.toastr.info("Country is required !", "Company");
               $("#country_Id" + index + " select").focus();
               return false;
            }
         }



         if (companyContactData[index].contact_Type == "Phone No" || companyContactData[index].contact_Type == "Mobile No" ||
            companyContactData[index].contact_Type == "Fax") {
            if ($("#contact_Value" + index).val().search(/[^0-9- +()]+/) > -1) {
               this.toastr.info("Fax allow only numbers !", "Company");
               $("#contact_Value" + index).focus();
               return false;
            }
            if ($("#contact_Value" + index).val().search(/[ ]{2,}/) > -1) {
               this.toastr.info("Fax does not allow whitespace !", "Company");
               $("#contact_Value" + index).focus();
               return false;
            }
            // if (companyContactData[index].contact_Type == "Mobile No" && $("#contact_Value" + index).val().length < 9) {
            //    this.toastr.info("Mobile no must be at least 9 digit long !", "Company");
            //    $("#contact_Value" + index).focus();
            //    return false;
            // }
            // if (companyContactData[index].contact_Type == "Phone No" && ($("#contact_Value" + index).val().length < 9 || $("#contact_Value" + index).val().length > 15) &&
            //    companyContactData[index].contact_Type != "Fax") {
            //    this.toastr.info("Phone no must be at least 9 digit long !", "Company");
            //    $("#contact_Value" + index).focus();
            //    return false;
            // }
         }
         if (companyContactData[index].contact_Type == "Email") {
            if ($("#contact_Value" + index).val().search(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/) != 0) {
               this.toastr.info("Enter valid email address  !", "Company");
               $("#contact_Value" + index).focus();
               return false;
            }
         }
         if (companyContactData[index].contact_Type == "WebSite") {
            if ($("#contact_Value" + index).val().search(/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/) != 0) {
               this.toastr.info("Enter valid website address  !", "Company");
               $("#contact_Value" + index).focus();
               return false;
            }
         }

         companyContactData[index].isDefault = companyContactData[index].isDefault ? 1 : 0
         companyContactData[index].isActive = companyContactData[index].isActive ? 1 : 0
      }
      return true;
   }

}


