import { Component, OnInit } from '@angular/core';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;

@Component({
   selector: 'app-companysetting',
   templateUrl: './company-setting.component.html',
})
export class CompanySettingComponent implements OnInit {
   logoClass = "fileinput fileinput-new";
   logoInnerHTML = "";
   companyLogo: any = "";
   currenciesList: any;
   languageList: any;
   companyLogoChange: boolean = false;
   companyform: FormGroup;
   constructor(
      private fb: FormBuilder,
      private toastr: ToastrService,
      private commonApiService: CommonApiService
   ) {

   }
   ngOnInit() {
      this.companyform = this.fb.group({
         currency_Id: [null],
         language: [null]
      });

      this.getCurrencies()
      this.getLanguage()
   }
   validation() {
      this.companyform.controls.currency_Id.setValue($("#currency").val() == "null" ? null : $("#currency").val());
      this.companyform.controls.language.setValue($("#language").val() == "null" ? null : $("#language").val());
      return true;
   }

   getCurrencies() {
      this.commonApiService.List(Commonvariable.Currencies).subscribe((result) => {
         if (result["data"] != "")
            this.currenciesList = result["data"].filter(x => x.isActive != 0).sort((a, b) => a.currency_Name < b.currency_Name ? -1 : a.currency_Name > b.currency_Name ? 1 : 0);

            this.companyform.controls.currency_Id.setValue(result["data"].filter(x => x.isDefault == 1)[0]["id"]); 
      })
   }

   getLanguage() {
      this.commonApiService.List(Commonvariable.Languages).subscribe((result) => {
         if (result["data"] != "")
            this.languageList = result["data"].filter(x => x.isActive != 0).sort((a, b) => a.languages < b.languages ? -1 : a.languages > b.languages ? 1 : 0);
      })
   }

   filechange(files: FileList) {

      if (files.item(0).name.split('.')[1] == "jpg" || files.item(0).name.split('.')[1] == "png"
         || files.item(0).name.split('.')[1] == "jpeg") {

         this.companyLogoChange = true;
         this.companyLogo = files.item(0)
      }
      else {
         this.toastr.info("Allow only jpg,png,jpeg file format for upload !", "Company");
         $("#remove").click();
      }


      //console.log(files.item(0).name.split('.')[1]);

      // var reader = new FileReader();

      // reader.onload = this._handleReaderLoaded.bind(this);

      // reader.readAsBinaryString(files.item(0));
   }

   removeLogo() {
      this.companyLogoChange = true;
      this.companyLogo = null;
   }


   // _handleReaderLoaded(readerEvt) {
   //    var binaryString = readerEvt.target.result;
   //    this.base64textString = btoa(binaryString);
   //    console.log(btoa(binaryString));
   // }

}


