<style>
    .order-card {
        color: #fff;
    }

    .bg-c-blue {
        background: linear-gradient(45deg, #4099ff, #73b4ff);
    }

    .bg-c-green {
        background: linear-gradient(45deg, #2ed8b6, #59e0c5);
    }

    .bg-c-yellow {
        background: linear-gradient(45deg, #FFB64D, #ffcb80);
    }

    .bg-c-pink {
        background: linear-gradient(45deg, #FF5370, #ff869a);
    }


    .card {
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
        box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
        border: none;        
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .card .card-block {
        padding: 25px;
    }

    .order-card i {
        font-size: 26px;
    }

    .f-left {
        float: left;
    }

    .f-right {
        float: right;
    }

    .portlet {
        margin-left: 0px !important;
    }

    .portlet.box.grey {
        min-height: 473px;
    }

    .portlet.box>.portlet-body {
        min-height: 473px;
    }
</style>

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head" style="display: none;">
            <div class="page-title" >
                <h1>Welcome to Luxsh Technologies
                </h1>
            </div>
        </div>
        <ul class="page-breadcrumb breadcrumb">
            <!-- <li>
                <a><i class="fa fa-space-shuttle rotate"></i> ACTIVE MISSION</a>
            </li>
            <li>
                <a>Building Bases</a> &nbsp;
                <span>(11% Completed)</span>
            </li>
            <li>
                <a><i class="fa fa-plus"></i> Start a New Mission</a>
            </li> -->

        </ul>


        <div class="portfolio-content portfolio-1">

            <div class="row" *ngIf="ismanager != true">
                <a routerLink="/signup-notifications">
                    <div class="col-md-4 col-xl-3">
                        <div class="card bg-c-blue order-card">
                            <div class="card-block">
                                <!-- <h6 class="m-b-20">New Signup</h6> -->

                                <h2 class="text-right"><i
                                        class="fa fa-user-plus f-left"></i><span>{{signUpCount}}</span>
                                </h2>
                                <p class="m-b-0">SignUp Request<span class="f-right"></span></p>

                            </div>
                        </div>
                    </div>
                </a>

                <a routerLink="/user-request">
                    <div class="col-md-4 col-xl-3">
                        <div class="card bg-c-green order-card">
                            <div class="card-block">
                                <!-- <h6 class="m-b-20">User Creation</h6> -->
                                <h2 class="text-right"><i
                                        class="fa fa-users f-left"></i><span>{{userCreationCount}}</span>
                                </h2>
                                <p class="m-b-0">User Creation Request<span class="f-right"></span></p>
                            </div>
                        </div>
                    </div>
                </a>

                <a routerLink="/review-person">
                    <div class="col-md-4 col-xl-3">
                        <div class="card bg-c-yellow order-card">
                            <div class="card-block">
                                <!-- <h6 class="m-b-20">Review Request</h6> -->
                                <h2 class="text-right"><i
                                        class="fa fa-paper-plane f-left"></i><span>{{reviewRequestCount}}</span></h2>
                                <p class="m-b-0">Review Request<span class="f-right"></span></p>
                            </div>
                        </div>
                    </div>
                </a>

                <!-- <div class="col-md-4 col-xl-3">
                    <div class="card bg-c-pink order-card">
                        <div class="card-block">
                            <h6 class="m-b-20">Orders Received</h6>
                            <h2 class="text-right"><i class="fa fa-credit-card f-left"></i><span>486</span></h2>
                            <p class="m-b-0">Completed Orders<span class="f-right">351</span></p>
                        </div>
                    </div>
                </div> -->
            </div>





            <div class="row"  *ngIf="ismanager != true">



                <div class="col-md-4 col-xl-3">

                    <div class="page-head">
                        <div class="page-title">
                            <h1>Candidate Status</h1>
                        </div>
                    </div>
                    <div style="padding: 25px 0px 25px;background-color: #fff;">
                        <canvas ngChartjs [datasets]="lineChartData" [labels]="lineChartLabels" [legend]="lineChartLegend" [chartType]="lineChartType" [options]="options" [colors]="pieChartColor" height="200"></canvas>
                    </div>
                </div>
                

                <div class="col-md-4 col-xl-3">
                    <div class="page-head">
                        <div class="page-title">
                            <h1>Vacancy Status</h1>
                        </div>
                    </div>
                    <div style="padding: 25px 0px 25px;background-color: #fff;">
                        <canvas ngChartjs [datasets]="lineChartData2" [labels]="lineChartLabels2" [legend]="lineChartLegend2" [chartType]="lineChartType2" [options]="options" [colors]="pieChartColor" height="200"></canvas>
                    </div>
                </div>

                <div class="col-md-4 col-xl-3" >
                    <div class="page-head">
                        <div class="page-title">
                            <h1>Onboarding Status</h1>
                        </div>
                    </div>
                    <div style="padding: 25px 0px 25px;background-color: #fff;">
                        <canvas ngChartjs [datasets]="lineChartData3" [labels]="lineChartLabels3" [legend]="lineChartLegend3" [chartType]="lineChartType3" [options]="options" height="200" [colors]="pieChartColor"></canvas>
                    </div>
                </div>
            </div>  
            
            <div class="row"  *ngIf="ismanager == true">

                <div class="col-md-4 col-xl-3" style="visibility:hidden;">
                </div>

            <div class="col-md-4 col-xl-3">
                <div class="page-head">
                    <div class="page-title">
                        <h1>Vacancy Status</h1>
                    </div>
                </div>
                <div style="padding: 25px 0px 25px;background-color: #fff;">
                    <canvas ngChartjs [datasets]="lineChartData2" [labels]="lineChartLabels2" [legend]="lineChartLegend2" [chartType]="lineChartType2" [options]="options" [colors]="pieChartColor" height="200"></canvas>
                </div>
            </div>
            </div>
            
           
            <div class="page-content-wrapper" >
              
                    <div class="row mt-15" *ngIf="ismanager != true">

                        <div class="col-md-12" >
                            <div class="portlet box grey">
                                <div class="portlet-body" >
                                    <!-- <table datatable [dtOptions]="dtOptions" class="table table-sm  table-striped table-bordered table-hover"></table> -->
                                    <diV class="row" >
                                        <div class="col-md-9 customSelect">
                                            <div class="text-left">        
                                                <select class="form-control" [(ngModel)]="itemsPerPage" (change)="onItemsPerPageChange()"  style="width: 75px;">
                                                   <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                                                 </select>
                                            </div>
                                        </div>
                                        <div class="col-md-3 text-right" >
                                            <input type="text" class="form-control" id="filterList" autocomplete="off"
                                                placeholder="Type to filter ..." (input)="getEmployeeList();">
                                        </div>
                                    </diV>
                                    <br />
                                    <div class="table-responsive" >
                                        <table class="table table-striped table-bordered table-hover" id="tblemp">
                                            <thead class="flip-content">
                                                <tr>
                                           
                                                    <th class="text-center" style="width: 10%;" (click)="sortColumn('emp_Code')" > Employee.Code <i class="fa" [ngClass]="getSortIcon('emp_Code')"></i></th>
                                                    <th class="text-center" (click)="sortColumn('firstName')">Employee Name <i class="fa" [ngClass]="getSortIcon('firstName')"></i></th>
                                               
                                                    <th class="text-center" (click)="sortColumn('expiryField')"> Expiry Field <i class="fa" [ngClass]="getSortIcon('expiryField')"></i></th>
                                            
                                                    <th class="text-center" (click)="sortColumn('expiryDate')"> Expiry Date<i class="fa" [ngClass]="getSortIcon('expiryDate')"></i></th>
                                     
                                                    <th class="text-center" > Action </th>
                                                   
                                                </tr>
                                            </thead>
                                            <tbody>
                                            
                                                  
                                                <tr
                                                   *ngFor="let item of empList | paginate: { itemsPerPage: itemsPerPage, currentPage: p, totalItems: totalRecord }">
                                                    <!-- *ngFor="let item of empList| paginate: { itemsPerPage: 10, currentPage: p ,totalItems :totalRecord}"> -->
                                                    <!-- <td class="text-center">
                                                        <img src="assets/layouts/layout4/company_logo/logo-light.png">
                                                    </td> -->
                                                    <td>
                                                        <!-- <div>
                                                            <a (click)="updateStatus(item.emp_Id,'0')">
                                                                <div class="circle greenColor" *ngIf="item.isActive"
                                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                                            </a>
                                                            <a (click)="updateStatus(item.emp_Id,'1')">
                                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                                            </a>
                                                        </div> -->
                                                        {{item.emp_Code}}
                                                    </td>
                                                    <td>
                
                                                        <img src="data:image/png;base64,{{item.photo}}" *ngIf="item.photo != null"
                                                            height="50px" width="50px" class="img-circle" />
                                                        <img src="assets/layouts/layout4/img/avatar9.jpg" *ngIf="item.photo == null"
                                                            height="50px" width="50px" class="img-circle" />
                                                        &nbsp; &nbsp;
                                                        <!-- <img alt="" class="img-circle" src="data:image/jpeg;base64, {{item.photo}}" height="40px" width="40px" /> &nbsp; &nbsp; -->
                
                                                        {{item.firstName}} {{item.middleName}} {{item.lastName}}
                                                    </td>
                                                  
                                                    <td>
                                                        {{item.expiryField }}
                                                    </td>
                
                                                    <!-- <td>{{item.site_Name}}</td>
                                                    <td>{{item.dept_Name}}</td> -->
                                                    <td>{{item.expiryDate | date :'dd/MM/yyyy'}}</td>
                                                    <!-- <td>{{item.shift_Name}}</td> -->
                                                    
                                                   
                                                    <!-- <td>{{item.work_Extension}}</td> -->
                                                    <td class="numeric text-center">
                                                        <!-- <a (click)="getSummary(item.emp_Id)">
                                                            <i class="fa fa-list-alt" data-toggle="tooltip" data-placement="top"
                                                                title="View Summary"></i></a> &nbsp; -->

                                                                <!-- *ngIf="item.isActive"
                                                                (click)="editRecord(item.emp_Id)"
                                                                *ngIf="!item.isActive" (click)="employeeDelete(item.emp_Id)" 
                                                            -->

                                                        <a 
                                                        (click)="editRecord(item.emp_Id)">
                                                            <i class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                                data-placement="top" title="Edit Employee"></i></a>                                       
                
                                                        <!-- <a ><i
                                                                class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Employee"></i></a> -->
                                                    </td>
                                                </tr>
                                          
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-center" >
                                        <pagination-controls (pageChange)=" getEmployeeList($event)" autoHide="true">
                                        </pagination-controls>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                    
            
                    <!-- END PAGE BASE CONTENT -->
                </div>
                <!-- END CONTENT BODY -->
            </div>
            <!-- END CONTENT -->
            


    </div>

</div>