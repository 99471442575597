<style>
    .mb-10 {
        margin-bottom: 10px;
    }
</style>
<form [formGroup]="interviewFeedBackform">
    <div class="form-group">
        <div class="table-resposive" style="max-height: 500px;overflow: auto;">
            <table class="table table-hover">
                <thead>
                    <tr>

                        <!-- <th style="width: 2%;">Sr No.</th> -->
                        <th style="width: 15%;">Interview Round<span class="required"> *
                            </span></th>
                        <th style="width: 15%;">Date<span class="required"> *
                            </span></th>
                        <th style="width: 15%;">Interviewer<span class="required"> *
                            </span></th>
                        <th style="width: 15%;">FeedBack<span class="required"> *
                            </span></th>
                        <th style="width: 15%;">Note</th>
                    </tr>
                </thead>
                <tbody formArrayName="interviewFeedback"
                    *ngFor="let item of interviewFeedBackform.get('interviewFeedback')['controls']; let i = index;">
                    <tr [formGroupName]="i">
                        <!-- <td>
                            {{i+1}}
                        </td> -->
                        <td>
                            <input type="text" class="form-control" id="name{{i}}" formControlName="name" maxlength="20"
                                autocomplete="off"
                                [readonly]="interviewFeedBackform.get('interviewFeedback').value[i].disabled">
                        </td>
                        <td>

                            <input type="text" class="form-control" readonly 
                            value="{{interviewFeedBackform.get('interviewFeedback').value[i].dateTime}}">
                        </td>
                        <td>
                            <div formArrayName="interviewDetail"
                                *ngFor="let interview of interviewDetail(i).controls; let y = index;" class="mb-10">
                                <div [formGroupName]="y">
                                    <select class="form-control" id="interviewer{{i}}{{y}}"
                                        formControlName="interviewer"
                                        [attr.disabled]="interviewFeedBackform.get('interviewFeedback').value[i].disabled ? '':null">
                                        <option value="">Select Interviewer</option>
                                        <option *ngFor="let item of empList" value="{{item.emp_Id}}">{{item.firstName}}
                                            {{item.lastName}}</option>
                                    </select>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div formArrayName="interviewDetail"
                                *ngFor="let interview_satus of interviewDetail(i).controls; let j = index;"
                                class="mb-10">
                                <div [formGroupName]="j">
                                    <select class="form-control" id="interview_satus{{i}}{{j}}" formControlName="status"
                                        [attr.disabled]="interviewFeedbackDisabled(i,j) ? '':null" (change)="getInterviewStatus($event.target.value)">
                                        <option value="ShortListed">Shortlisted</option>
                                        <option value="Rejected">Rejected</option>
                                    </select>
                                </div>
                            </div>
                        </td>

                        <td>
                            <div formArrayName="interviewDetail"
                                *ngFor="let interviewnote of interviewDetail(i).controls; let m = index;" class="mb-10">
                                <div [formGroupName]="m">
                                    <textarea class="form-control" style="resize: none;" rows="1" formControlName="note"
                                        [attr.disabled]="interviewFeedbackDisabled(i,m) ? '':null"
                                        id="Notes{{i}}{{m}}"></textarea>
                                </div>
                            </div>
                        </td>

                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</form>