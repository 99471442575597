import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { CanAdvancedSearchComponent } from '../advanced-search/advanced-search.component'
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Select2OptionData } from 'ng-select2';
declare var $: any;
declare const Bloodhound;


@Component({
  selector: 'app-candidate-overview',
  templateUrl: './candidate-overview.component.html'
})
export class CandidateOverviewComponent implements OnInit {
  @ViewChild(CanAdvancedSearchComponent, { static: false }) advancedSearch: CanAdvancedSearchComponent;
  p: number = 1;
  fileUrl;
  fileName: any;
  candidateList: any;
  viewSummaryList: any;
  SummaryListDocument: any;
  designationList: any;
  candidateStatus: any;
  totalRecord: 0;
  filterCandidate: any;
  displayCV: boolean = false;
  cvName = "";
  jobCategorArr: any;
  skillsArr: any;
  prefereLocationArr: any;
  jobLevelArr: any;
  perferLocation: any;
  jobCategoryList: any[] = [];
  jobLevelList: any[] = [];
  skillList: any;
  skill: any;
  advancedFilterData: any;
  reportingEmpList: any[] = []
  candidateVacancyList: any[] = []
  countryList: Array<Select2OptionData>;
  currenciesList: Array<Select2OptionData>;
  salaryPlaceHolder = "0";
  currency_Code = "";
  candidate_Id = 0;
  salaryType = ""
  vacancy_Id = 0
  openPopup:boolean=false;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.getCandidateList(1);
    this.getStatus();
    this.getCountryList();
    this.getCurrencies();
  }

  getCurrencies() {
    this.commonApiService.List(Commonvariable.Currencies).subscribe((result) => {
      if (result["data"] != "")
        this.currenciesList = result["data"].filter(x => x.isActive != 0).sort((a, b) => a.currency_Name < b.currency_Name ? -1 : a.currency_Name > b.currency_Name ? 1 : 0).map(x => ({
          id: x.id,
          text: x.currency_Name + " - " + x.currency_Code
        }));;
    })
  }



  getCountryList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "country_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Country, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.countryList = response["data"].map(x => ({
          id: x.id,
          text: x.country_Name + " (" + x.country_Code + ") "
        }));
      }
    })
  }


  getCandidateList(PageIndex) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.candidateList = [];

    var requestData
    if (this.advancedFilterData != "" && this.advancedFilterData != null && this.advancedFilterData != 'undefined') {
      requestData = {
        "searchBy": this.advancedFilterData,
        "PageIndex": PageIndex - 1,
        "CommonSearch": $("#filterList").val().trim(),
        "PageSize": 10,
      }
    } else {

      requestData = {
        "searchBy": [
          {
            "FieldName": "moveToOnboarding",
            "FieldValue": "0",
            "Parameter": "=="
          }
        ],
        "PageIndex": PageIndex - 1,
        "CommonSearch": $("#filterList").val().trim(),
        "PageSize": 10,
      }

    }


    this.commonApiService.getPaginated(Commonvariable.CandidateService, null, null, null, null, null, requestData).subscribe((result) => {

      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.candidateList = result["data"]["list"];
        this.filterCandidate = result["data"]["list"];
      }
    })
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);
  }

  getStatus() {
    this.commonApiService.List(Commonvariable.Onboarding_Status).subscribe((response) => {
      if (response["data"] != null && response["data"] != "" && response["status"] == 200) {
        this.candidateStatus = response["data"].filter(x => x.isActive != 0);
        this.getDesignation();
      }
    })
  }

  getDesignation() {
    this.commonApiService.List(Commonvariable.Designation).subscribe((response) => {
      if (response["data"] != null && response["data"] != "" && response["status"] == 200) {
        this.designationList = response["data"].filter(x => x.isActive != 0);
      }
    })
  }



  candidateDelete(id) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.CandidateService, id).subscribe((response) => {
          this.commonService.toaster("success", Commonvariable.DeleteMsg, "Candidate-Overview")
          this.getCandidateList(1);
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.CandidateService, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.toastr.success(response["message"], 'Candidate-Overview');
            this.ngOnInit();
          }

        })
      }

    });
  }
  viewSummary(id) {



    this.commonApiService.getById(Commonvariable.CandidateService, id, "ViewSummary").subscribe((response) => {
      this.viewSummaryList = response["data"][0]
      // this.viewSummaryList["skills"] = String(this.viewSummaryList["skills"]).replace(/[ ~]+/g, ",")
      // this.viewSummaryList["job_Category"] = String(this.viewSummaryList["job_Category"]).replace(/[ ~]+/g, ",")
      // this.viewSummaryList["job_Level"] = String(this.viewSummaryList["job_Level"]).replace(/[ ~]+/g, ",")
      // this.viewSummaryList["prefer_Location"] = String(this.viewSummaryList["prefer_Location"]).replace(/[ ~]+/g, ",")
      // this.SummaryListDocument = this.viewSummaryList["candidate_documentView"];

      if (response["data"][0].cv != null) {
        this.displayCV = true;
        if (response["data"][0].cv.indexOf("\\") > -1) {
          this.cvName = response["data"][0].cv.split("\\")[1];
        } else {
          this.cvName = response["data"][0].cv.split("/")[1];
        }
      }
      this.jobCategorArr = String(response["data"][0].job_Category).split("~");
      this.jobLevelArr = String(response["data"][0].job_Level).split("~");
      //this.perferLocation = response["data"][0].prefer_Location.replace(/[ ~]+/g, ",")      
      this.skillsArr = String(response["data"][0].skills).split("~");
      this.prefereLocationArr = String(response["data"][0].prefer_Location).split("~");




    })
    this.openPopup=true;
    $('#viewSummary').modal({
      backdrop: 'static'
    });
  }

  advanceFilter() {

    var data = {
      "SearchBy": this.advancedSearch.search()
    }

    if (data.SearchBy) {

      if (data.SearchBy.filter(x => x.FieldName == 'moveToOnboarding').length > 0) {
        data.SearchBy = data.SearchBy.filter(x => x.FieldName != 'moveToOnboarding');
      }
      data.SearchBy.push({
        "FieldName": "moveToOnboarding",
        "FieldValue": "0",
        "Parameter": "=="
      })
      data.SearchBy[data.SearchBy.length - 2]["ConditionWith"] = "&&"


      this.advancedFilterData = data.SearchBy;


      if (data.SearchBy[0]["FieldName"] != "" && data.SearchBy[0]["FieldValue"] != "") {
        this.candidateList = []
        this.totalRecord = 0
        this.commonApiService.getPaginated(Commonvariable.CandidateService, null, null, null, null, 10, data).subscribe((result) => {
          if (result != null && result["data"] != "") {
            this.candidateList = result["data"]["list"];
            this.totalRecord = result["data"]["recordCount"];
            this.p = 1
          }
          $("#draggable").modal("hide");
        })
      }
    }

  }

  downloadCV(id) {

    this.commonApiService.getimage(Commonvariable.JobSeeker, id, 'DownloadCV').subscribe((result) => {
      const blobData = new Blob([result], { type: result.type });
      var createObjectURL = (window.URL.createObjectURL(blobData));
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
      if (result.type == "application/pdf" || result.type == "image/jpeg" || result.type == "image/png") {
        $("#modalCandidateDocument").modal("show")
      } else if (result.type == "application/vnd.ms-word") {
        const a = document.createElement('a');
        a.setAttribute('type', 'hidden');
        a.href = URL.createObjectURL(blobData);
        a.download = 'download.docx';
        a.click();
        a.remove();
      } else {

        const a = document.createElement('a');
        a.setAttribute('type', 'hidden');
        a.href = URL.createObjectURL(blobData);
        a.download = 'download';
        a.click();
        a.remove();

      }

    })
  }

  downloadDocument(id) {

    this.commonApiService.getimage(Commonvariable.JobSeeker, id, 'DownloadCV').subscribe((result) => {

      const blobData = new Blob([result], { type: result.type });
      var createObjectURL = (window.URL.createObjectURL(blobData));

      if (result.type == "application/pdf" || result.type == "image/jpeg" || result.type == "image/png") {

        this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
        $("#candidateCvModal").modal("show")

      } else {
        const a = document.createElement('a');
        a.setAttribute('type', 'hidden');
        a.href = URL.createObjectURL(blobData);
        a.download = 'CV';
        a.click();
        a.remove();
      }


    })
  }
  clear() {
    this.advancedFilterData = [];
    this.getCandidateList(1);
    this.advancedSearch.ngOnInit();
    $("#draggable").modal("hide");
  }

  editCandidate(id) {
    var paraEncoded = btoa(id);
    this.router.navigate(['/addnewcandidate'], { queryParams: { editId: paraEncoded } })
  }

  directOpenMoveToOnboarding(id) {

    this.candidateVacancyList = [];

    this.candidate_Id = id;

    this.commonApiService.getById(Commonvariable.Candidate_Vacancy_Link, id, "GetCan_Vac_LinkByCandidate_Id").subscribe((res) => {
      if (res) {
        this.candidateVacancyList = res["data"];
      }
    })
    this.currency_Code = "";
    this.salaryPlaceHolder = "0";
    this.salaryType = "";
    this.vacancy_Id = 0;
    
    $("#modalMovetoOnboarding").modal("show");
    this.commonService.flatpickr('canidateOverviewJoiningDates', 0)
    $("#candidateOverviewSalary").val("");
    $("#canidateOverviewJoiningDate").val("");
    $("#candidateOverviewReporting").val("");
  }



  getReportingEmpList(company_Id, dept_Id, desig_Id, salaryFrom, salaryTo, currency_Code, salaryType, vacancy_Id) {

    this.salaryPlaceHolder = salaryFrom + "-" + salaryTo;
    this.currency_Code = currency_Code;
    this.salaryType = salaryType;
    this.vacancy_Id = vacancy_Id;

    let reportingParameter = "Reporting" + "," + company_Id + "," + dept_Id + "," + desig_Id + "," + 0
    this.commonApiService.getById(Commonvariable.EmployeeDetail, reportingParameter, "GetEmployeeByAccessibility").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.reportingEmpList = result["data"];
      }
    })
  }

  directMoveToOnboarding() {

    if (this.validation()) {
      let postRequest = {
        "candidate_Id": this.candidate_Id,
        "vacancy_Id": this.vacancy_Id,
        "Salary": $("#candidateOverviewSalary").val(),
        "SalaryType": this.salaryType,
        "Reporting_Id": $("#candidateOverviewReporting").val(),
        "JoiningDate":  this.commonService.dateFormatChange($("#canidateOverviewJoiningDate").val())
      }

      this.commonApiService.PostRequest(Commonvariable.CandidateService, "DirectMoveToOnboarding", postRequest).subscribe((res) => {
        if (res) {
          this.commonService.toaster("success", "Move to onboarding successfully ", "Candidate");
          this.getCandidateList(1);
          $("#modalMovetoOnboarding").modal("hide");
        }
      })
    }

  }



  validation() {

    if (this.currency_Code == "" || this.salaryType == "" && this.vacancy_Id == 0 ) {
      this.commonService.toaster("info", "Select Vacancy ", "Candidate");
      return false;
    }

    if ($("#candidateOverviewSalary").val() != "" && $("#candidateOverviewSalary").val() !=  undefined) {
      if ($("#candidateOverviewSalary").val().search(/[^0-9.]+/) > -1) {
        this.commonService.toaster("info", "Salary only number allow !", "Candidate");
        $("#candidateOverviewSalary").focus();
        return false;
     }
    }

    // if ($("#candidateOverviewReporting").val() == "") {
    //   this.commonService.toaster("info", "Reporting is required ", "Candidate");
    //   $("#candidateOverviewReporting").focus();
    //   return false;
    // }

    // if ($("#canidateOverviewJoiningDate").val() == "") {
    //   this.commonService.toaster("info", "Joining date is required ", "Candidate");
    //   $("#canidateOverviewJoiningDate").focus();
    //   return false;
    // }

    return true;
  }

  closepoopup(){
    this.openPopup=false;
    $("#viewSummary").modal("hide");
    // this.approvalSummary = [];
    // this.viewSummary.
  }






}
