<form [formGroup]="candidateBasicInfoform">
    <div class="row" *ngIf="editMode">
        <div class="form-group">
            <label class="control-label col-md-2">Candidate Photo
            </label>
            <div class="col-md-10">
                <div [class]="logoClass" data-provides="fileinput">
                    <div class="fileinput-new thumbnail" style="width: 200px; height: 150px;">
                        <img src="assets/layouts/layout4/img/selectPic.png" alt="" /> </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"
                        style="max-width: 200px; max-height: 150px;" [innerHTML]="logoInnerHTML">
                    </div>
                    <div>
                        <span class="btn default btn-file">
                            <span class="fileinput-new"> Select image </span>
                            <span class="fileinput-exists"> Change </span>
                            <input type="file" name="..." accept="image/x-png,image/jpg,image/jpeg"
                                (change)="filechange($event.target.files)"> </span>
                        <a id="remove" class="btn red fileinput-exists"
                            (click)="candidateLogo= null;candidateLogoChange = true" data-dismiss="fileinput"> Remove
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group form-md-line-input">
            <label class="col-md-2 control-label" for="form_control_1">Title
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <div class="md-radio-inline">
                    <div class="md-radio">
                        <input type="radio" id="radio53" name="Title" value="Mr." formControlName="Title"
                            class="md-radiobtn">
                        <label for="radio53">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Mr. </label>
                    </div>
                    <div class="md-radio has-error">
                        <input type="radio" id="radio54" name="Title" value="Mrs." formControlName="Title"
                            class="md-radiobtn" checked>
                        <label for="radio54">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Mrs. </label>
                    </div>
                    <div class="md-radio has-warning">
                        <input type="radio" id="radio55" name="Title" value="Miss." formControlName="Title"
                            class="md-radiobtn">
                        <label for="radio55">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Miss. </label>
                    </div>
                    <div class="md-radio has-info">
                        <input type="radio" id="radio56" name="Title" value="Ms." formControlName="Title"
                            class="md-radiobtn">
                        <label for="radio56">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Ms. </label>
                    </div>
                    <div class="md-radio has-info">
                        <input type="radio" id="radio57" name="Title" value="Dr." formControlName="Title"
                            class="md-radiobtn">
                        <label for="radio57">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Dr. </label>
                    </div>
                    <div class="md-radio has-warning">
                        <input type="radio" id="radio58" name="Title" formControlName="Title" value="Other"
                            class="md-radiobtn">
                        <label for="radio58">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Other </label>
                    </div>
                </div>
            </div>
            <label class="col-md-2 control-label" for="form_control_1">Gender
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <div class="md-radio-inline">
                    <div class="md-radio">
                        <input type="radio" id="radio59" name="Gender_Id" formControlName="Gender_Id" value="1"
                            class="md-radiobtn">
                        <label for="radio59">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Male </label>
                    </div>
                    <div class="md-radio has-error">
                        <input type="radio" id="radio60" name="Gender_Id" formControlName="Gender_Id" value="2"
                            class="md-radiobtn">
                        <label for="radio60">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Female </label>
                    </div>
                    <div class="md-radio has-warning">
                        <input type="radio" id="radio61" name="Gender_Id" formControlName="Gender_Id" value="3"
                            class="md-radiobtn">
                        <label for="radio61">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Prefer Not To Say </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">First Name
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="FirstName" id="FirstName" autocomplete="off" />
            </div>
            <label class="control-label col-md-2">Middle Name
                <!-- <span class="required"> * </span> -->
            </label>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="MiddleName" id="MiddleName"
                    autocomplete="off" />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Last Name
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="LastName" id="LastName" autocomplete="off" />
            </div>

            <!-- <label class="control-label col-md-2" *ngIf="!editMode">Blood Group</label>
            <div class="col-md-3" *ngIf="!editMode">
                <input type="text" formControlName="BloodGroup" id="BloodGroup" class="form-control"
                    autocomplete="off" />
            </div> -->
            <!-- <label class="control-label col-md-2" *ngIf="!editMode">Email
                <span class="required"> * </span>
            </label>
            <div class="col-md-3" *ngIf="!editMode">
                <input type="text" class="form-control" id="Email" autocomplete="off" />
            </div> -->
        </div>
    </div>
    <div class="row" *ngIf="editMode">
        <div class="form-group">
            <label class="control-label col-md-2">Ethnicity</label>
            <div class="col-md-3">
                <select id="Ethnicity_Code" class="form-control" formControlName="Ethnicity_Id">
                    <option value="null">Select Ethnicity</option>
                    <option *ngFor="let item of ethinicityList;" value={{item.id}}>
                        {{item.ethnicity_Name}}</option>
                </select>
            </div>

            <label class="control-label col-md-2">Date Of Birth
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <div class="input-group" id="flatpickropenDOBs" data-wrap="true">
                    <input type="text" class="form-control DOBClass readonlyClass" id="DOB" data-input />
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="editMode">
        <div class="form-group">


            <label class="control-label col-md-2">Language</label>
            <div class="col-md-3">
                <!-- <select class="form-control" formControlName="Languages_Id" id="Languages_Id">
                    <option value="null">Select Languages</option>
                    <option *ngFor="let item of languagesList;" value={{item.id}}>
                        {{item.languages}}</option>
                </select> -->
                <ng-select2 [data]="languagesList" id="Languages_Id" formControlName="Languages_Id" [options]="{multiple: true,tags: false}"
                [value]='this.candidateBasicInfoform.controls.Languages_Id.value' [placeholder]="'--- Select ---'">
                </ng-select2>

            </div>


            <label class="control-label col-md-2">Nationality
                <!-- <span class="required"> * </span> -->
            </label>
            <div class="col-md-3">
                <select id="nationality" class="form-control" formControlName="Nationality_Id" id="Nationality">
                    <option value="null">Select Nationality</option>
                    <option *ngFor="let item of nationalityList;" value={{item.id}}>
                        {{item.name}}</option>
                </select>
            </div>

            <!-- <label class="control-label col-md-2" *ngIf="!editMode">Mobile
               
            </label>
            <div class="col-md-3" *ngIf="!editMode">
                <input type="text" id="Mobile" class="form-control" autocomplete="off" maxlength="10" formControlName="Mobile"/>
            </div> -->

            <!-- <label class="control-label col-md-2" *ngIf="editMode">NI Number
            </label>
            <div class="col-md-3" *ngIf="editMode">
                <input type="text" class="form-control" id="NiNumber" autocomplete="off" formControlName="NiNo" />
            </div> -->
        </div>
    </div>
    <div class="row" *ngIf="editMode">
        <div class="form-group">
            <!-- <label class="control-label col-md-2">Total Experience</label>
            <div class="col-md-3">
                <input type="text" id="TotalExperience" formControlName="TotalExperience" class="form-control" autocomplete="off" />
            </div> -->

            <!-- <label class="control-label col-md-2">Nationality
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <select id="nationality" class="form-control" formControlName="Nationality" id="Nationality">
                    <option value="">Select Nationality</option>
                    <option value="Indian">Indian</option>
                    <option value="USA">USA</option>
                    <option value="UK">UK</option>
                </select>
            </div> -->
        </div>
    </div>
</form>