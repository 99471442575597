<div class="page-content-wrapper">
   <div class="page-content">
      <div class="page-head">
         <div class="page-title mrl10">
            <h1> Leave Process </h1>
         </div>
      </div>
      <div class="navbar page-breadcrumb breadcrumb mb20">
      </div>
      <div class="col-md-12">
         <div class="portlet light bordered" id="form_wizard_1">
            <div class="portlet-title">
               <div class="caption">
                  <i class="fa fa-building font-red"></i>
                  <span class="caption-subject font-red bold uppercase"> Leave Process -
                     <span class="step-title"> Step 1 of 2 </span>
                  </span>
               </div>
            </div>
            <div class="portlet-body form">
               <div class="form-wizard">
                  <div class="form-body">
                     <ul class="nav nav-pills nav-justified steps">
                        <li [class]="w1">
                           <a class="step">
                              <span class="number"> 1 </span>
                              <span class="desc">
                                 <i class="fa fa-check"></i> Leave Type </span>
                           </a>
                        </li>
                        <li [class]="w2">
                           <a class="step active">
                              <span class="number"> 2 </span>
                              <span class="desc">
                                 <i class="fa fa-check"></i> Employee </span>
                           </a>
                        </li>
                        <li [class]="w3">
                           <a class="step active">
                              <span class="number"> 3 </span>
                              <span class="desc">
                                 <i class="fa fa-check"></i> Summary </span>
                           </a>
                        </li>
                     </ul>
                     <div id="bar" class="progress progress-striped" role="progressbar">
                        <div class="progress-bar progress-bar-success" [style.width]="progressBarWidth"> </div>
                     </div>
                     <div class="tab-content">
                        <div class="alert alert-danger display-none">
                           <button class="close" data-dismiss="alert"></button> You have some form errors. Please
                           check below.
                        </div>
                        <div class="alert alert-success display-none">
                           <button class="close" data-dismiss="alert"></button> Your form validation is successful!
                        </div>
                        <div [class]="t1" id="tab1">
                           <div class="row">
                              <div class="col-md-1"><label class="control-label">Company<span
                                       class="required">*</span></label></div>
                              <div class="col-md-3">
                                 <div class="form-group">
                                    <div class="input-group">
                                       <select id="Company_Id" class="form-control companySelectListClass select">
                                          <option value="">Select Company</option>
                                          <option *ngFor="let company of companylist;" value={{company.company_Id}}>
                                             {{company.company_Name}}</option>
                                       </select>
                                    </div>
                                 </div>
                              </div>

                              <div class="col-md-1"><label class="control-label">Effective Year<span class="required"> *
                                    </span></label></div>
                              <div class="col-md-3">
                                 <div class="form-group">
                                    <select id="eveffectiveYear" class="form-control select">
                                       <option value="">Select Year</option>
                                       <option *ngFor="let abc of yearList">{{abc}}</option>
                                    </select>
                                 </div>
                              </div>

                              <br/>


                              <div class="col-md-12">
                                 <form [formGroup]="empform">
                                    <div class="table-responsive">
                                       <table class="table table-hover leave_bal_table" id="work"
                                          style="position: relative;" role="grid">
                                          <thead>
                                             <tr>
                                                <th><input type="checkbox" (change)="checkAllLB(0,true)" id="checkalllb"
                                                      [checked]="empAllCheckLB"></th>
                                                <th>Leave Type</th>
                                             </tr>
                                          </thead>
                                          <tbody formArrayName="emp_Leave"
                                             *ngFor="let item of empform.get('emp_Leave')['controls'];  let i = index;">
                                             <tr [formGroupName]="i">
                                                <td>
                                                   <input type="checkbox" id="chkEmp{{item.value.emp_Id}}"
                                                      (change)="checkAllLB(i)" class="md-check"
                                                      formControlName="ischecked" value="{{item.value.emp_Id}}">
                                                </td>
                                                <td colspan="2">
                                                   <label class="control-label">{{item.value.leave_Type}}
                                                      ({{item.value.leave_Type_Code}})</label>
                                                </td>
                                             </tr>
                                          </tbody>
                                          <br>
                                          <tr>
                                             <td></td>
                                             <td>
                                                <label><input type="radio" value="1" checked classs="uploaded_field"
                                                      name="uploaded_field"> Processed</label> &nbsp;&nbsp;&nbsp;
                                                <label><input type="radio" value="2" classs="uploaded_field"
                                                      name="uploaded_field"> Not-Processed</label>&nbsp;&nbsp;&nbsp;
                                                <label><input type="radio" value="3" classs="uploaded_field"
                                                      name="uploaded_field"> All</label>
                                             </td>
                                          </tr>
                                       </table>
                                    </div>
                                 </form>
                              </div>


                           </div>
                        </div>
                        <div [class]="t2" id="tab2">
                           <form [formGroup]="empnewleaveform">
                              <div class="row">
                                 <div class="col-md-12">
                                    <div class="table-responsive">
                                       <table class="table table-hover" style="position: relative;" role="grid"
                                          id="sample_2">
                                          <thead>
                                             <tr>
                                                <th><input type="checkbox" (change)="balanceSet(0,0,0,true)" id="checkall"
                                                      [checked]="empAllCheck"></th>
                                                <th>Employee Full Name</th>
                                                <th>Leave Type</th>
                                                <th>Current Balance</th>
                                                <th>Carry Forward Balance</th>
                                                <th>Encashment Balance</th>
                                                <th>Encashment Amount</th>
                                                <th>Waive Off</th>
                                                <!-- <th>Uploaded Balance</th>
                                                <th>New Balance</th> -->
                                             </tr>
                                          </thead>
                                          <!-- <tbody formArrayName="emp_new_Leave"
                                             *ngFor="let item1 of empnewleaveform.get('emp_new_Leave')['controls'] | paginate: { itemsPerPage: 5, currentPage: p ,id: 'pagination'}; let i = index;">
                                             <tr [formGroupName]="i">
                                                <td>
                                                   <input type="checkbox" id="chkEmp{{item1.value.id}}"
                                                      (change)="checkAll(this.item1.value.id)" class="md-check"
                                                      formControlName="ischecked" value="{{item1.value.id}}">

                                                   
                                                </td>
                                                <td>
                                                   <label class="control-label">
                                                      {{item1.value.firstName}}
                                                      {{item1.value.lastName}}</label>
                                                </td>
                                                <td>
                                                   <label class="control-label">{{item1.value.leaveType}}</label>
                                                </td>
                                                <td>
                                                   <label class="control-label">{{item1.value.current_Balance}}</label>
                                                </td>
                                                <td>
                                                   <input type="text" class="form-control" maxlength="3"
                                                      id="carryForward_Balance{{item1.value.id}}"
                                                      onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                                                      value="{{item1.value.carryForword_Balance}}">
                                                </td>
                                                <td>
                                                   <input type="text" class="form-control" maxlength="3"
                                                      id="encashment_Balance{{item1.value.id}}"
                                                      onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                                                      value="{{item1.value.encashment_Balance}}">
                                                </td>
                                                <td>
                                                   {{item1.value.employee_Leave_Total_Amount}}
                                                </td>
                                                <td>
                                                   <input type="text" class="form-control" maxlength="3"
                                                      id="waiveOffLeave{{item1.value.id}}"
                                                      onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                                                      value="{{item1.value.waiveOffLeave}}">
                                                </td>
                                             </tr>
                                          </tbody> -->


                                          <tbody *ngFor="let item1 of empBalanceList | paginate: { itemsPerPage: 10, currentPage: p ,id: 'emp_pagination'}; let i = index;">
                                          <tr>
                                             <td>
                                                <input type="checkbox" id="balanceIsChecked{{item1.index}}" class="md-check" [checked]="item1.ischecked" (change)="balanceSet(item1.index,'ischecked',$event)">
                                             </td>
                                             <td>
                                                <label class="control-label">
                                                   {{item1.firstName}}
                                                   {{item1.lastName}}</label>
                                             </td>
                                             <td>
                                                <label class="control-label">{{item1.leaveType}}</label>
                                             </td>
                                             <td>
                                                <label class="control-label">{{item1.current_Balance}}</label>
                                             </td>
                                             <td>
                                                <input type="text" class="form-control" maxlength="5" (keypress)="keyPressNumbersDecimal($event)"
                                                   id="carryForward_Balance{{item1.id}}" value="{{item1.carryForword_Balance}}" (input)="balanceSet(item1.index,'carryForword_Balance',$event)">
                                             </td>
                                             <td>
                                                <input type="text" class="form-control" maxlength="5" (keypress)="keyPressNumbersDecimal($event)"
                                                   id="encashment_Balance{{item1.id}}" value="{{item1.encashment_Balance}}" (input)="balanceSet(item1.index,'encashment_Balance',$event)">
                                             </td>
                                             <td>
                                                £{{item1.employee_Leave_Total_Amount}}
                                             </td>
                                             <td>
                                                <input type="text" class="form-control" maxlength="5"
                                                   id="waiveOffLeave{{item1.id}}" (keypress)="keyPressNumbersDecimal($event)"
                                                   value="{{item1.waiveOffLeave}}" (input)="balanceSet(item1.index,'waiveOffLeave',$event)">
                                             </td>
                                             <!-- <td>
                                                <label class="control-label">{{item1.value.nextYearsUploadedBalance}}</label>
                                             </td>
                                             <td>
                                                <label class="control-label">{{item1.value.newBalance}}</label>
                                             </td> -->
                                          </tr>
                                       </tbody>

                                       </table>
                                    </div>
                                    <div class="text-center">
                                       <pagination-controls id="emp_pagination" (pageChange)="p =$event" autoHide="true">
                                       </pagination-controls>
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>

                        <div [class]="t3" id="tab3">

                           <form [formGroup]="empleavesummeryform">
                              <div class="row">
                                 <div class="col-md-12">
                                    <div class="table-responsive" style="max-height: 400px; overflow: auto;">
                                       <table class="table table-hover" style="position: relative;" role="grid"
                                          id="sample_3">
                                          <thead>
                                             <tr>
                                                <th>Employee Full Name</th>
                                                <th>Leave Type</th>
                                                <th>Current Balance</th>
                                                <th>Carry Forward Balance</th>
                                                <th>Encashment Balance</th>
                                                <th>Encashment Amount</th>
                                                <th>Waive Off</th>
                                                <th>Uploaded Balance</th>
                                                <th>New Balance</th>
                                             </tr>
                                          </thead>
                                          <tbody formArrayName="emp_leave_summery"
                                             *ngFor="let itemSummery of empleavesummeryform.get('emp_leave_summery')['controls'] | paginate: { itemsPerPage: 10, currentPage: p2 ,id: 'emp_summarry_pagination'}; let i = index;">
                                             <tr [formGroupName]="i">
                                                <td>
                                                   {{itemSummery.value.firstName}}
                                                   {{itemSummery.value.lastName}}
                                                </td>
                                                <td>
                                                   {{itemSummery.value.leaveType}}
                                                </td>
                                                <td>
                                                   {{itemSummery.value.current_Balance}}
                                                </td>
                                                <td>
                                                   {{itemSummery.value.carryForword_Balance}}
                                                </td>
                                                <td>
                                                   {{itemSummery.value.encashment_Balance}}
                                                </td>
                                                <td>
                                                   £{{itemSummery.value.employee_Leave_Total_Amount}}
                                                </td>
                                                <td>
                                                   {{itemSummery.value.waiveOffLeave}}
                                                </td>
                                                <td>
                                                   {{itemSummery.value.nextYearsUploadedBalance}}
                                                </td>
                                                <td>
                                                   {{itemSummery.value.newBalance}}
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                    <div class="text-center">
                                       <pagination-controls id="emp_summarry_pagination" (pageChange)="p2=$event" autoHide="true">
                                       </pagination-controls>
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
                  <div class="form-actions">
                     <div class="row">
                        <div class="col-md-12 text-right">
                           <a (click)="wizardSetting('back')" class="btn default" [style.visibility]="backbutton">
                              <i class="fa fa-angle-left"></i> Back </a>&nbsp;&nbsp;
                           <a (click)="Upsert()" class="btn btn-outline blue" *ngIf="activeWizard == 1">{{btnName}} <i
                                 *ngIf="btnName != 'Save'" class="fa fa-angle-right"></i>
                           </a>
                           <a (click)="UpsertEmpLeaveSummary()" class="btn btn-outline blue"
                              *ngIf="activeWizard == 2">{{btnName}} <i *ngIf="btnName != 'Save'"
                                 class="fa fa-angle-right"></i>
                           </a>
                           <a (click)="UpsertEmpLeaveUpload()" class="btn btn-outline blue"
                              *ngIf="activeWizard == 3">{{btnName}} <i *ngIf="btnName != 'Save'"
                                 class="fa fa-angle-right"></i>
                           </a>
                           &nbsp;&nbsp; <a (click)="exportTableToCSV()" class="btn btn-outline purple"
                              *ngIf="activeWizard == 3">Download <i
                                 class="fa fa-download purple iconcss iconFontsize"></i> </a>
                        </div>
                     </div>
                  </div>

                  <!-- <div class="form-actions">
                     <div class="row">
                        <div class="col-md-12 text-right">
                           <a (click)="wizardSetting('back')" class="btn default" [style.visibility]="backbutton"> <i
                                 class="fa fa-angle-left"></i> Back </a>&nbsp;&nbsp;
                           <a *ngIf="activeWizard == 1" (click)="Upsert()" class="btn btn-outline blue">{{btnName}} <i
                                 *ngIf="btnName != 'Save'" class="fa fa-angle-right"></i> </a>
                           <a *ngIf="activeWizard == 2" (click)="UpsertEmpLeaveUpload()"
                              class="btn btn-outline blue">Save<i *ngIf="btnName != 'Save'"
                                 class="fa fa-angle-right"></i> </a>
                        </div>
                     </div>
                  </div> -->
               </div>
            </div>
         </div>
      </div>
   </div>
</div>