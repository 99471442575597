import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormArray,FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;


@Component({
  selector: 'app-employeesalary',
  templateUrl: './emp-salary.component.html'
})
export class EmpSalaryComponent implements OnInit {
  empform: FormGroup;
  editMode : boolean = false;
  items: FormArray;
   items1: FormArray;
   public isDivHidden: boolean[] = [];
   public isrequiredclass: boolean[] = [];
   public isrequiredComclass: boolean[] = [];
   tempAdditionalPayment = false;
   isValueSelected = false;
   selectedValue:any;
   empSalaryAdditionalArray:FormArray;
   

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    

    // this.empform = this.fb.group({
    //   // emp_salary: this.createItem(null),
    //   emp_salary:  this.fb.array([this.createItem(null)])
      
    // });
    this.empform = this.fb.group({
      Emp_Salary_Id: '0',
      Company_Id: null,
      Dept_Id: null,
      Desig_Id: null,
      Emp_Id: '0',
      ApprisalFrom: '',
      ApprisalTo: '',
      Salary: '',
      Notes: '',
      SalaryType: '',
      ExpiryDate: '',
      Reporting_Id: null,
      isActive: true,
      addedBy: Commonvariable.User_Id,
      emp_salary_additional: this.fb.array([this.createEmployeeSalaryAdditional(null)])
    });
    
 }

//  createEmployeeSalaryAdditional(data) {
//     return this.fb.group({
//       Emp_Ad_Salary_Id:0,
//       Emp_Salary_Id:0,
//       AdditionalPayment: '',
//       AdditionalPaymentType: '',
//       AdditionalPaymentAmount: '',
//       Notes: '',
//       isActive: 1,
//       AddedBy: Commonvariable.User_Id,
//       AddedOn: [new Date()]
//     });
    
//   }
createEmployeeSalaryAdditional(data) {
  return this.fb.group({
    Emp_Ad_Salary_Id: data && data.emp_Ad_Salary_Id !== undefined ? data.emp_Ad_Salary_Id : '0',
    Emp_Salary_Id: data && data.emp_Salary_Id !== undefined ? data.emp_Salary_Id : '0',
    AdditionalPayment: data && data.additionalPayment !== undefined ? data.additionalPayment : '',
    AdditionalPaymentType: data && data.additionalPaymentType !== undefined ? data.additionalPaymentType : '',
    AdditionalPaymentAmount: data && data.additionalPaymentAmount !== undefined ? data.additionalPaymentAmount : '',
    Notes: data && data.notes !== undefined ? data.notes : '',
    isActive: data && data.isActive !== undefined ? data.isActive : 1,
    AddedBy: data && data.addedBy !== undefined ? data.addedBy : Commonvariable.User_Id,
    AddedOn: data && data.addedOn !== undefined ? data.addedOn : [new Date()],
  });

}

  
  

  addItem(data) {
    
    if(this.validation())
    {
      
      this.items = this.empform.get('emp_salary_additional') as FormArray;
       this.items.push(this.createEmployeeSalaryAdditional(data));
       setTimeout(() => {
          this.items = this.empform.get('emp_salary_additional') as FormArray;
          var id = this.items.length - 1;         
          // this.commonService.flatpickr("ApprisalFrom",id)
          // this.commonService.flatpickr("ApprisalTo",id)
       }, 5);
    }
 }
 


 

  removeItem(index: number) {
    
 
    
    const empSalaryAdditionalArray = this.empform.get('emp_salary_additional') as FormArray;
    
    if (index >= 0 && index < empSalaryAdditionalArray.length) {
      empSalaryAdditionalArray.removeAt(index);
    }
  }


  empEdit(Id) {
    this.empform.reset();
    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.EmpSalary, Id).subscribe((response) => {
        if (response != null && response["data"] != "") {
          var editData = response["data"];
          console.log(editData);
         
          var formData = this.empform;
          this.editMode = true;
          $("#salaryType").prop("disabled",true)
          this.setEditData(formData, editData);
         // formData.controls.updatedBy.setValue(Commonvariable.User_Id);
         console.log(this.empform);
        }
      })
    }
  }
  setEditData(form, editData) {
    // Map keys from editData to form control names
    const keyMap = {
      emp_Salary_Id: 'Emp_Salary_Id',
      company_Id: 'Company_Id',
      dept_Id: 'Dept_Id',
      desig_Id: 'Desig_Id',
      emp_Id: 'Emp_Id',
      apprisalFrom: 'ApprisalFrom',
      apprisalTo: 'ApprisalTo',
      salary: 'Salary',
      notes: 'Notes',
      salaryType: 'SalaryType',
      expiryDate: 'ExpiryDate',
      reporting_Id: 'Reporting_Id',
      isActive: 'isActive',
      addedBy: 'addedBy',
    };
  
    // Set values for form controls
    for (const key in keyMap) {
      if (keyMap.hasOwnProperty(key)) {
        const controlName = keyMap[key];
        form.get(controlName).setValue(editData[0][key]);
      }
    }
  
    
    
    const additionalData = editData[0].emp_salary_additional;

    // Get the emp_salary_additional FormArray
    const empSalaryAdditionalArray = this.empform.get('emp_salary_additional') as FormArray;
  
    // Clear any existing items in the FormArray
    while (empSalaryAdditionalArray.length) {
      empSalaryAdditionalArray.removeAt(0);
    }
  
    // Loop through additionalData and add FormGroup instances
    additionalData.forEach((data) => {
      empSalaryAdditionalArray.push(this.createEmployeeSalaryAdditional(data));
      setTimeout(() => {
        this.empSalaryAdditionalArray = this.empform.get('emp_salary_additional') as FormArray;
        var id = this.empSalaryAdditionalArray.length;
        let i;
      for (i=0;i<this.empSalaryAdditionalArray.length;i++){
        if(this.empSalaryAdditionalArray.controls[i].value.Notes != '')  
        {
         this.isDivHidden[i] = true;
        }
        else{
         this.isDivHidden[i] = false;
        }
      }
        
        
        // this.commonService.flatpickr("ApprisalFrom",id)
        // this.commonService.flatpickr("ApprisalTo",id)
     }, 5);
    });
  
   
   
    
   
  }
  


  validation() {
    var eForm = this.empform;
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);
   
    if (!eForm.valid) {
      for (const key of Object.keys(eForm.controls)) {
        if (eForm.controls[key].invalid) {
          let fieldName = "";

          if (key == "salary") {
            fieldName = "Salary"
          }
          else if (key == "salaryType") {
            fieldName = "Salary Type"
          }
          
          this.toastr.info(fieldName + " is required !", "Employee");
          $("#" + key).focus();
          return false;
        }
      }
      if (this.isValueSelected) {
        this.toastr.info("Additional Payment Value is already Selected!", "Employee");
        return false;
      }
    }
    else {
      if ($("#salary").val().search(/[^0-9.]+/) > -1) {
        this.toastr.info("Salary only number allow !", "Employee");
        $("#salary").focus();
        return false;
      }
    }
   
      const empSalaryAdditionalFormArray = this.empform.get('emp_salary_additional') as FormArray;
    
      if (empSalaryAdditionalFormArray) {
        for (let j = 0; j < empSalaryAdditionalFormArray.length; j++) {
          const additionalPayment = empSalaryAdditionalFormArray.at(j).get('AdditionalPayment').value;
          if (additionalPayment != "") {
    
             const AdditionalPaymentType = empSalaryAdditionalFormArray.at(j).get('AdditionalPaymentType').value;
             const AdditionalPaymentAmount = empSalaryAdditionalFormArray.at(j).get('AdditionalPaymentAmount').value;
             const Comments = empSalaryAdditionalFormArray.at(j).get('Notes').value;

             if(additionalPayment != "Other")
             {
    
             if(AdditionalPaymentType == ""){
                this.toastr.info("AdditionalPayment type is required !", "Employee");
                $("#AdditionalPaymentType" + j).focus();
                return false;
    
             }
             if (AdditionalPaymentAmount == "") {
            
            
                this.toastr.info("AdditionalPayment Amount  is required !", "Employee");
                $("#AdditionalPaymentAmount" + j).focus();
                return false;
             }
            }
             if(additionalPayment === "Other")
             {

              if (Comments == "") {
            
            
                this.toastr.info("Comments   is required !", "Employee");
                $("#Notes" + j).focus();
                return false;
             }
             }

           
            
          }
        }
      }
    
    
    return true;
  }
 

  toggleCommentsTextarea(event: Event, j: number) {
    this.isValueSelected = false; 

    this.selectedValue = (event.target as HTMLSelectElement).value;
    
    // const empSalaryFormArray = this.empform.get('emp_salary') as FormArray;
    
    // for (let i = 0; i < empSalaryFormArray.length; i++) {
    //   const empSalaryFormGroup = empSalaryFormArray.at(i) as FormGroup;
      const empSalaryAdditionalFormArray = this.empform.get('emp_salary_additional') as FormArray;
    
      if (empSalaryAdditionalFormArray) {
        for (let j = 0; j < empSalaryAdditionalFormArray.length-1; j++) {
          const additionalPayment = empSalaryAdditionalFormArray.at(j).get('AdditionalPayment').value;
    
          if (additionalPayment === this.selectedValue) {
            this.isValueSelected = true;
            break; // Exit the inner loop if a match is found
          }
          else{
             this.isValueSelected = false; 
          }
         
        }
        
      }
    
      
    
    
    if (this.isValueSelected) {
      this.toastr.info("Additional Payment Value is already Selected!", "Employee");
      
    } 
    
    
    
        if (this.selectedValue === 'Night Shift Premium' || this.selectedValue === 'Car Allowance' || this.selectedValue === 'Travel Allowance' || this.selectedValue === '') {
          this.isDivHidden[j] = false;
         
        } 
        else
         {
             this.isDivHidden[j] = true;
            
        }
    
        if(this.selectedValue != '')
        {
          this.isrequiredclass[j] = true;
        }
        
        else{
          this.isrequiredclass[j] =false ;
    
        }
        if(this.selectedValue === 'Other'){
          this.isrequiredComclass[j] =true ;
          this.isrequiredclass[j] =false ;

        }
        
    
       
    }




}
