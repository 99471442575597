<div class="modal fade draggable-modal in" id="draggable" role="basic" aria-hidden="true" style="display: block;z-index: 999;">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="closeModal()" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Company Site</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="siteform">
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Company<span class="required"> *
                                        </span></label>
                                    <ng-select2 *ngIf="title != 'Edit'" [data]="exampleData" id="company_Id" [options]="{multiple: true,tags: false}" [disabled]="title == 'Edit'?true:false" formControlName="company_Id" [placeholder]="'--- Select ---'">
                                    </ng-select2>

                                    <input *ngIf="title == 'Edit'" type="text" id="company_Name" class="form-control" autocomplete="off" formControlName="company_Name" readonly>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Site Name<span class="required"> *
                                        </span></label>
                                    <input type="text" id="site_Name" autocomplete="off" class="form-control" formControlName="site_Name" maxlength="30">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Address 1<span class="required"> *</span></label>
                                    <input type="text" id="address1" class="form-control" formControlName="address1" ngx-google-places-autocomplete [options]='options' (onAddressChange)="AddressChangeSite($event)" autocomplete="off">
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Address 2<span class="required"> *</span></label>
                                    <input type="text" class="form-control" id="address2" formControlName="address2">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Address 3</label>
                                    <input type="text" class="form-control" id="address3" formControlName="address3">
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Address 4</label>
                                    <input type="text" class="form-control" id="address4" formControlName="address4">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">City<span class="required"> *</span></label>
                                    <input type="text" id="city" class="form-control" formControlName="city" autocomplete="off" maxlength="30">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">County</label>
                                    <input type="text" id="state" class="form-control" formControlName="state" autocomplete="off" maxlength="30">
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Country<span class="required"> *</span></label>
                                    <ng-select2 [data]="countrylist" id="country_Id" formControlName="country_Id" [placeholder]="'--- Select ---'">
                                    </ng-select2>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Post Code<span class="required"> *</span></label>
                                    <input type="text" id="postCode" class="form-control" formControlName="postCode" autocomplete="off" maxlength="10">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="">
                                    <label class="control-label">Active</label>
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="checkbox1_3" class="md-check" formControlName="isActive">
                                            <label for="checkbox1_3">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="form-group">
                                <label class="control-label col-md-2">Countries
                                </label>
                                <div class="col-md-4">
                                    <select id="country_list" class="form-control">
                                        <option value="">Select Country</option>
                                        <option value="AF">Afghanistan</option>
                                        <option value="AL">Albania</option>
                                    </select>
                                </div>

                            </div>
                        </div> -->
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn  dark btn-outline pull-left" (click)="history()" *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button>
                <button type="button" (click)="closeModal()" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="siteUpsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Site History</h4>
            </div>
            <div class="modal-body" *ngIf="title == 'Edit'">
                <app-history></app-history>
            </div>
        </div>
    </div>
</div>