import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
import { templateJitUrl } from '@angular/compiler';
import { trim } from 'jquery';
declare var $: any;


@Component({
  selector: 'app-righttowork',
  templateUrl: './righttowork.component.html'
})
export class RightToWorkComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  rwform: FormGroup;
  rwlist: any;
  filterRW: any;
  totalRecord: 0;
  listCall: boolean = true;
  companylist: Array<Select2OptionData>;;
  title: string;
  Toast: any;
  p: number = 1;
  cities: city[] = [];

  modalOpen: boolean = false
  id: number = 0


  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) { }



  ngOnInit() {
    this.title = "Add";
    this.rwform = this.fb.group({
      rwC_Id: ['0'],
      company_Id: [''],
      category: [null],
      name: ['', Validators.required],
      description: [''],
      notes: [''],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });

    if (this.listCall) {
      this.getRightToWorkList(1);
      this.getCompanyList();
    }


    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);


  }

  getRightToWorkList(PageIndex = 1) {
    
    this.p = PageIndex;
    this.totalRecord = 0;
    this.rwlist = []
    this.commonApiService.getListWithFilter(Commonvariable.RightToWork_Category, PageIndex, trim($("#filterList").val())).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.rwlist = result["data"]["list"];
        this.filterRW = result["data"]["list"];
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);
      }
    })  
    
  }



  getCompanyList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy":"company_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }

  setFormData(event) {
    this.rwform.controls.company_Id.setValue(event)
  }



  Upsert() {
    var formData = this.rwform;

    if (this.validation()) {
      formData.get("isActive").setValue(formData.get("isActive").value ? 1 : 0);
      formData.get("name").setValue(formData.get("name").value);
      
      this.commonApiService.UpsertWithMultiCompany(Commonvariable.RightToWork_Category, formData.value, formData.controls.rwC_Id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Right To Work');
          }
          else {
            $("#draggable").modal('hide');
            this.getRightToWorkList(this.p);
            this.toastr.success(formData.controls.rwC_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Right To Work');
          }
        }
      })
    }

  }

  Delete(Id) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.RightToWork_Category, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getRightToWorkList(this.p);
          this.toastr.success(response["message"], 'Right To Work');
        })
      }
    })
  }
  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.RightToWork_Category, Id, status).subscribe((response) => {
          
          if (response["status"] == 200) {
            this.getRightToWorkList(this.p);
            this.toastr.success(response["message"], 'Right To Work');                        
          }

        })
      }

    });
  }


  openModel(Id) {

    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getByIdNew(Commonvariable.RightToWork_Category, Id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var formData = this.rwform;
          this.commonService.setEditData(formData, editData);
          $("#company_Id").val(editData[0]['company_Name']);          
        }
      })
    }
    else {
      this.listCall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });

  }


  validation() {
    var zoneForm = this.rwform;
    if (!zoneForm.valid) {

      for (const key of Object.keys(zoneForm.controls)) {
        if (zoneForm.controls[key].invalid) {
          let fieldName = "";

          if (key == "name") {
            fieldName = "Type of Document"
          }
          this.toastr.info(fieldName + " is required !", "Right To Work");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {

      if ($("#name").val().length < 2) {
        this.toastr.info("Type of document must be at least 2 characters long !", "Right To Work");
        $("#name").focus();
        return false;
      }
      if (/["\\]/.test($("#name").val())) {
        this.toastr.info("Type of document does not allow Special Characters \" \\ ", "Right To Work");
        $("#name").focus();
        return false;
      }
      if (/["\\]/.test($("#description").val())) {
        this.toastr.info("Description does not allow Special Characters \" \\ ", "Right To Work");
        $("#description").focus();
        return false;
      }
      if (/["\\]/.test($("#notes").val())) {
        this.toastr.info("Notes does not allow Special Characters \" \\ ", "Right To Work");
        $("#notes").focus();
        return false;
      }
      
      // if ($("#name").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
      //   this.toastr.info("Type of document only alphabets and numeric allow !", "Right To Work");
      //   $("#name").focus();
      //   return false;
      // }

    }
    return true;
  }

  history() {
    this.historyView.getHistory("righttowork_category",this.rwform.value.rwC_Id)
  }


}

interface city {
  id: number;
  text: string;
}
