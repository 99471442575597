import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
import * as moment from 'moment';
import { combineAll } from 'rxjs/operators';
declare var $: any;

@Component({
   selector: 'app-empProbation',
   templateUrl: './employee-probation.component.html',
})
export class EmpProbationComponent implements OnInit {
   empform: FormGroup;
   items: FormArray;
   probationList: any;
   modalOpen: boolean = false;
   p: number = 1;
   totalRecord: 0;
   companyid: any;
   id: number = 0;
   constructor(
      private toastr: ToastrService,
      private commonService: CommonService,
      private commonApiService: CommonApiService,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {

      this.empform = this.fb.group({
         emp_Probation: this.fb.array([this.createItem()]),
      });
   }

   getProbationList(PageIndex = 1) {
      var requestData = {
         "searchBy": [
            {
               "FieldName": "Company_Id",
               "FieldValue": this.companyid,
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ]
      }

      this.commonApiService.GetByPaginated(Commonvariable.Probation, requestData).subscribe((result) => {
         this.probationList = result["data"]

      })

   }

   createItem() {
      return this.fb.group({
         Emp_Probation_Id: '0',
         Emp_Id: '0',
         ProbationEndDate: '',
         ProbationPeriod: '',
         ProbationPeriod_Id: '',
         NextReviewDate: null,
         Status: null,
         Notes: null,
         isActive: true,
         AddedBy: Commonvariable.User_Id,
      });
   }

   addItem() {
      if (this.validation()) {
         this.items = this.empform.get('emp_Probation') as FormArray;
         this.items.push(this.createItem());
         setTimeout(() => {
            this.items = this.empform.get('emp_Probation') as FormArray;
            var id = this.items.length - 1;
            this.commonService.flatpickr("flatpickropenProEndD", id)
            this.commonService.flatpickr("flatpickropenNextRevD", id)
         }, 5);
      }
   }


   removeItem(index) {
      this.items = this.empform.get('emp_Probation') as FormArray;
      this.items.removeAt(index);
   }


   empUpsert() {
      var empProbation = this.empform.get('emp_Probation').value;
      if (empProbation[0].ProbationEndDate == "") {
         return false;
      }
      return true;
   }

   validation() {
      var empProbation = this.empform.get('emp_Probation').value;
      var tempArray = [];

      for (let index = 0; index < empProbation.length; index++) {
         if ($("#ProbationEndDate0").val() != "" || $("#ProbationPeriod0").val() != "") {
            empProbation[index].ProbationEndDate = this.commonService.dateFormatChange($("#ProbationEndDate" + index).val())

            if ($("#NextReviewDate" + index).val() != "") {
               empProbation[index].NextReviewDate = this.commonService.dateFormatChange($("#NextReviewDate" + index).val())
            }

            if (empProbation[index].ProbationEndDate == "") {
               this.toastr.info("Probation end date is required !", "Employee");
               $("#ProbationEndDate" + index).focus();
               return false;
            }

            // if (moment($("#ProbationEndDate" + index).val(), 'DD-MM-YYYY') > moment($("#NextReviewDate" + index).val(), 'DD-MM-YYYY')) {
            //    this.toastr.info("Probation end date not more then next review date !", "Employee");
            //    $("#ProbationEndDate" + index).focus();
            //    return false;
            // }

            if (empProbation[index].ProbationPeriod == "") {
               this.toastr.info("Probation period is required !", "Employee");
               $("#ProbationPeriod" + index).focus();
               return false;
            }
            if ($("#ProbationPeriod" + index).val().search(/^[^-\s][a-zA-Z0-9_\s-]+$/) != 0) {
               this.toastr.info("Probation period not valid !", "Employee");
               $("#ProbationPeriod" + index).focus();
               return false;
            }
            if ($("#ProbationPeriod" + index).val().search(/[^a-zA-Z0-9 ]+/) > -1) {
               this.toastr.info("Probation period only alphabets and numeric value allow !", "Employee");
               $("#ProbationPeriod" + index).focus();
               return false;
            }
            if ($("#ProbationPeriod" + index).val().length < 1) {
               this.toastr.info("Probation period must be at least 1 characters long !", "Employee");
               $("#ProbationPeriod" + index).focus();
               return false;
            }

            var tempData = {
               "EndDate": String(empProbation[index].ProbationEndDate).trim(),
               "ProbationPeriod": String(empProbation[index].ProbationPeriod).trim(),
               "NextReviewDate": String(empProbation[index].NextReviewDate).trim(),
            }

            if (tempArray.length > 0) {
               var endDate = tempArray.filter(x => x.EndDate == String(empProbation[index].ProbationEndDate).trim()).length;
               var probationPeriod = tempArray.filter(x => x.ProbationPeriod == String(empProbation[index].ProbationPeriod).trim()).length;
               var nextReviewDate = tempArray.filter(x => x.NextReviewDate == String(empProbation[index].NextReviewDate).trim()).length;

               if (endDate > 0 && probationPeriod > 0 && nextReviewDate > 0) {
                  this.toastr.info("This value already entered !", "Employee");
                  $("#ProbationEndDate" + index).focus();
                  return false;
               }
            }


            tempArray.push(tempData);

            // if ($("#Status" + index).val() != "") {
            //    if ($("#Status" + index).val().length < 2) {
            //       this.toastr.info("Status must be at least 2 characters long !", "Employee");
            //       $("#Status" + index).focus();
            //       return false;
            //    }
            //    if ($("#Status" + index).val().indexOf(" ") > -1) {
            //       this.toastr.info("Status not allow space !", "Employee");
            //       $("#Status" + index).focus();
            //       return false;
            //    }
            // }

         }
         empProbation[index].isActive = empProbation[index].isActive ? 1 : 0
      }
      return true;
   }

   updateprob(event) {
      let probation = this.probationList.filter(x => x.prob_Duration == String(event.target.value).split(' ')[0] && x.prob_DurationUnit == String(event.target.value).split(' ')[1])
      this.items = this.empform.get('emp_Probation') as FormArray;
      if (probation.length > 0) {
         this.items.controls[0]["controls"].ProbationPeriod_Id.setValue(probation[0]["prob_Id"])


         const dateee = $('#JoiningDate').val();
         //alert(dateee);
         let selected = this.probationList.find(c => c.prob_Duration == String(event.target.value).split(' ')[0] && c.prob_DurationUnit == String(event.target.value).split(' ')[1]);
         if (selected.prob_DurationUnit == "Month") {
            const datee = $('#JoiningDate').val();
            const now = moment(datee,'DD-MM-YYYY');
            const add = now.add(selected.prob_Duration, 'months');
            let proEndDate = add.format('DD-MM-YYYY').split("-");
            $("#ProbationEndDate0").val(proEndDate[0] + "-" + proEndDate[1] + "-" + proEndDate[2]);
         } else if (selected.prob_DurationUnit == "Day") {
            const datee = $('#JoiningDate').val();
            const now = moment(datee,'DD-MM-YYYY');
            const add = now.add(selected.prob_Duration, 'days');
            let proEndDate = add.format('DD-MM-YYYY').split("-");
            $("#ProbationEndDate0").val(proEndDate[0] + "-" + proEndDate[1] + "-" + proEndDate[2]);
         }
         else if (selected.prob_DurationUnit == "Year") {
            const datee = $('#JoiningDate').val();
            const now = moment(datee,'DD-MM-YYYY');
            const add = now.add(selected.prob_Duration, 'years');
            let proEndDate = add.format('DD-MM-YYYY').split("-");
            $("#ProbationEndDate0").val(proEndDate[0] + "-" + proEndDate[1] + "-" + proEndDate[2]);
         }
         //Fixed Three Month add on review date.
         const datee = $('#JoiningDate').val();
         const now = moment(datee,'DD-MM-YYYY');
         const add = now.add(3, 'months');
         let proEndDate = add.format('DD-MM-YYYY').split("-");
         $("#NextReviewDate0").val(proEndDate[0] + "-" + proEndDate[1] + "-" + proEndDate[2]);
      }
      else {
         $("#ProbationEndDate0").val('');
         $("#NextReviewDate0").val('');
      }
   }


}




