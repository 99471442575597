import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;
import * as moment from 'moment';


@Component({
  selector: 'app-probationcandidate',
  templateUrl: './probation-candidate.component.html'
})
export class ProbationCandidateComponent implements OnInit {
  candidateForm: FormGroup; 
  eProbationList:any;
   probationList:any;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {

    this.candidateForm = this.fb.group({
      company_Id: ['0'],
      joiningDate:['0'],
      prob_Duration:['0'],
      prob_DurationUnit:['0'],
      id: ['0'],
      onboarding_Id: ['0'],
      probationEndDate: ['', Validators.required],
      probationPeriod: ['', Validators.required],
      nextReviewDate: null,
      status: '',
      notes: null,
      isActive: ['1'],
      AddedBy: [Commonvariable.User_Id],
      updatedBy: [0]
    });

    this.commonService.flatpickr("flatpickropenpEndD,#flatpickropenReviewD", 0);
    
  }  

  addCandidateProbation(Id){
    
    this.commonApiService.getById(Commonvariable.Candidate, Id).subscribe((response_candidate) => {

      var editCandidateData = response_candidate["data"][0];         
      

      var form = this.candidateForm.controls;         
            
      form.company_Id.setValue(editCandidateData.company_Id);


      let startDate = editCandidateData.joiningDate != null ? editCandidateData.joiningDate.split("T")[0].split("-") : "";          
      form.joiningDate.setValue(startDate[0] + "-" + startDate[1] + "-" + startDate[2]);

      this.casCadingDropdwon();

    })

  }


  Edit(Id) {
    if (Id > 0) {      
      //this.probationList = this.eProbationList.filter(x => x.company_Id == this.candidateForm.controls.company_Id.value)
      
      this.commonApiService.getById(Commonvariable.Onboarding_Probation, Id).subscribe((response) => {
        if (response != null && response["data"] != "") {
          var editData = response["data"];         
          
          let proEndDate = response["data"][0].probationEndDate != null ? response["data"][0].probationEndDate.split("T")[0].split("-") : "";
          let nextRDate = response["data"][0].nextReviewDate != null ? response["data"][0].nextReviewDate.split("T")[0].split("-") : "";          
          if (proEndDate != "") {
            $("#probationEndDate").val(proEndDate[2] + "-" + proEndDate[1] + "-" + proEndDate[0]);
          }
          if (nextRDate != "") {
            $("#nextReviewDate").val(nextRDate[2] + "-" + nextRDate[1] + "-" + nextRDate[0]);
          }
          var formData = this.candidateForm;
          this.commonService.setEditData(formData, editData);
          formData.controls.updatedBy.setValue(Commonvariable.User_Id);

          this.commonApiService.getById(Commonvariable.Candidate, editData[0].onboarding_Id).subscribe((response_candidate) => {

            var editCandidateData = response_candidate["data"][0];         
            

            var form = this.candidateForm.controls;         
                  
            form.company_Id.setValue(editCandidateData.company_Id);


            let startDate = editCandidateData.joiningDate != null ? editCandidateData.joiningDate.split("T")[0].split("-") : "";          
            form.joiningDate.setValue(startDate[0] + "-" + startDate[1] + "-" + startDate[2]);

            this.casCadingDropdwon();

          })



        }
      }, (error) => { this.toastr.error(error["error"].message, 'Employee'); })
    }
  }




  casCadingDropdwon() {

       //this.probationList = this.eProbationList.filter(x => x.company_Id == this.candidateForm.controls.company_Id.value)    

       var requestData = {
        "searchBy": [
          {
            "FieldName": "isActive",
            "FieldValue": "1",
            "Parameter": "==",
            "ConditionWith":"&&"
          },
          {
            "FieldName": "company_Id",
            "FieldValue": this.candidateForm.controls.company_Id.value,
            "Parameter": "=="
          }
        ]
      }


       this.commonApiService.GetByPaginated(Commonvariable.Probation,requestData).subscribe((response) => {
        if (response != null) {
           this.probationList = response["data"];          

           var formData = this.candidateForm;
           
           formData.controls.probationPeriod.setValue(formData.value.probationPeriod);
          
          //console.log(this.probationList);

          let prob_period = formData.value.probationPeriod != null ? formData.value.probationPeriod.split(" "): "";          
            //console.log(editCandidateData);
          formData.controls.prob_Duration.setValue(prob_period[0]);
          formData.controls.prob_DurationUnit.setValue(prob_period[1]);
          //console.log(formData.value.probationPeriod);
        }
     })
  }


  onChangeProb(value){
    
    let prob_period = value != null ? value.split(" "): "";    
    let startDate = $("#joiningDate").val();

    let days = prob_period[0].trim();
    let dayType = prob_period[1].toLowerCase();    

    var currentDate = moment(startDate);
    
   
    if(dayType == 'day' || dayType == 'days'){

      var futureMonth = moment(currentDate).add(days, 'd');

    }else if(dayType == 'month' || dayType == 'months'){
      var futureMonth = moment(currentDate).add(days, 'M');

    }else if(dayType == 'year' || dayType == 'years'){
      var futureMonth = moment(currentDate).add(days, 'Y');  
    }

    //console.log(futureMonth.format('DD-MM-YYYY'));   

    var futureReviewMonth = moment(currentDate).add(3, 'M');  

    $("#probationEndDate").flatpickr({ dateFormat:"d-m-Y" ,defaultDate:futureMonth.format('DD-MM-YYYY') });
    $("#nextReviewDate").flatpickr({ dateFormat:"d-m-Y", defaultDate:futureReviewMonth.format('DD-MM-YYYY') });



  } 
  validation() {
    var eForm = this.candidateForm;
    //sForm.get("company_Id").setValue($("#company_Id").val()); 
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);
    this.commonService.setNull(this.candidateForm);
    eForm.controls.probationEndDate.setValue(this.commonService.dateFormatChange($("#probationEndDate").val()))
    if ($("#nextReviewDate").val() != "") {
      eForm.controls.nextReviewDate.setValue(this.commonService.dateFormatChange($("#nextReviewDate").val()))
    }

    if (!eForm.valid) {
      for (const key of Object.keys(eForm.controls)) {
        if (eForm.controls[key].invalid) {
          let fieldName = "";
          if (key == "probationPeriod") {
            fieldName = "Probation Period"
          }          
          else if (key == "probationEndDate") {
            fieldName = "Probation End Date"
          }
          this.toastr.info(fieldName + " is required !", "Candidate");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {

      // if (moment($("#probationEndDate").val(), 'DD-MM-YYYY') > moment($("#nextReviewDate").val(), 'DD-MM-YYYY')) {
      //   this.toastr.info("Probation end date not more then next review date !", "Candidate");
      //   $("#probationEndDate").focus();
      //   return false;
      // }
      if (moment($("#probationEndDate").val(), 'DD-MM-YYYY') == moment($("#nextReviewDate").val(), 'DD-MM-YYYY')) {
        this.toastr.info("Probation end date and next review date should not be same date !", "Candidate");
        $("#probationEndDate").focus();
        return false;
      }

      if ($("#probationPeriod").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
        this.toastr.info("Probation period only alphabets and numeric value allow !", "Candidate");
        $("#probationPeriod").focus();
        return false;
      }

      if ($("#probationPeriod").val().length < 1) {
        this.toastr.info("Probation period must be at least 1 characters long !", "Candidate");
        $("#probationPeriod").focus();
        return false;
      }
      if ($("#notes").val() != "" && /["\\]/.test($("#notes").val())) {
        this.toastr.info("Notes does not allow Special Characters \" \\ ", "Candidate");
        $("#notes").focus();
        return false;
      }
      // if ($("#status").val() != "") {
      //   if ($("#status").val().length < 2) {
      //     this.toastr.info("Status must be at least 2 characters long !", "Candidate");
      //     $("#status").focus();
      //     return false;
      //   }
      //   if ($("#status").val().indexOf(" ") > -1) {
      //     this.toastr.info("Status not allow space !", "Candidate");
      //     $("#status").focus();
      //     return false;
      //   }
      // }
    }
    return true;
  }




}
