import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;

@Component({
  selector: 'app-interview-stage',
  templateUrl: './interview-stage.component.html'
})
export class InterviewStageComponent implements OnInit {

  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  nationalityform: FormGroup;
  nationalityList: any;
  filterList: any;
  companylist: any;
  listcall: boolean = true;
  title: string;
  p: number = 1;
  totalRecord: 0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.title = "Add";
    this.nationalityform = this.fb.group({
      id: ['0'],
      name: ['', Validators.required],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id]
    });

    if (this.listcall) {
      this.getInterviewStagesList();
    }

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }

  getInterviewStagesList(PageIndex = 1) {
    this.p = PageIndex;
    this.totalRecord = 0;
    this.nationalityList = [];
    this.filterList = [];
    this.commonApiService.getListWithFilter(Commonvariable.InterviewStages, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.nationalityList = result["data"]["list"];
        this.filterList = result["data"]["list"];
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);
      }
    })
  }


  Upsert() {
    var Form = this.nationalityform;

    if (this.validation()) {
      Form.get("name").setValue(Form.get("name").value.trim());
      Form.get("isActive").setValue(Form.get("isActive").value ? 1 : 0);
      this.commonApiService.Upsert(Commonvariable.InterviewStages, Form.value, Form.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'InterviewStages');
          }
          else {
            $("#draggable").modal('hide');
            this.getInterviewStagesList();
            this.toastr.success(Form.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'InterviewStages');
          }
        }
      })
    }

  }

  Delete(Id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.InterviewStages, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getInterviewStagesList();
          this.toastr.success(response["message"], 'InterviewStages');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.InterviewStages, Id, status).subscribe((response) => {
          
          if (response["status"] == 200) {
            this.getInterviewStagesList();
            this.toastr.success(response["message"], 'InterviewStages');                        
          }

        })
      }

    });
  }

  openModel(Id) {
    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.InterviewStages, Id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var Form = this.nationalityform;
          this.commonService.setEditData(Form, editData);
        }
      })
    }
    else {
      this.listcall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });


  }


  validation() {
    var Form = this.nationalityform;
    this.commonService.setNull(Form);
    if (!Form.valid) {
      for (const key of Object.keys(Form.controls)) {
        if (Form.controls[key].invalid) {
          let fieldName = "";

          if (key == "name") {
            fieldName = "Name"
          }
          this.toastr.info(fieldName + " is required !", "InterviewStages");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {

      if (String($("#name").val()).trim() == '') {
        this.toastr.info("Name is required !", "InterviewStages");
        $("#name").val('');
        $("#name").focus();
        return false;
      }

      if ($("#name").val().length < 2) {
        this.toastr.info("Name must be at least 2 characters long !", "InterviewStages");
        $("#name").focus();
        return false;
      }

      // if ($("#name").val().search(/[^a-zA-Z- ]+/) > -1) {
      //   this.toastr.info("Name only alphabets allow !", "InterviewStages");
      //   $("#name").focus();
      //   return false;
      // }

      // if ($("#name").val().search(/^[^-\s][a-zA-Z0-9\s.]+[^-\s]+$/) != 0) {
      //    this.toastr.info("Name only alphabets and numeric allow !", "InterviewStages");
      //    $("#name").focus();
      //    return false;
      // }     

    }

    return true;
  }

  history() {
    this.historyView.getHistory("nationality",this.nationalityform.controls.id.value)
  }

}
