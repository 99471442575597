import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
   selector: 'app-candidatepersonalinfo',
   templateUrl: './candidate-personal-info.component.html',
})
export class CandidatePersonalInfoComponent implements OnInit {

   logoClass = "fileinput fileinput-new";
   logoInnerHTML = "";
   candidateLogo: any;
   candidateLogoChange: boolean = false;
   candidateBasicInfoform: FormGroup;
   ethinicityList: any;
   nationalityList: any;
   languagesList: any;
   editMode: boolean = false;


   constructor(private commonApiService: CommonApiService,
      private toastr: ToastrService,
      private commonService: CommonService,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {
      this.candidateBasicInfoform = this.fb.group({
         Id: ['0'],
         FirstName: ['', Validators.required],
         MiddleName: [""],
         LastName: ['', Validators.required],
         // Mobile: [null],
         //BloodGroup: [null],
         Title: [''],
         Ethnicity_Id: [null],
         NiNo: [null],
         Languages_Id: [null],
         // TotalExperience:[null],
         DOB: [null],
         Gender_Id: [''],
         Nationality_Id: [null],
         isActive: ['1'],
         AddedBy: [Commonvariable.User_Id]
      });

      this.commonService.flatpickrForDOB("flatpickropenDOBs", 0);
      //$("select").select2();

      this.getEthinicityList();
      this.getNationalityList();
      this.getlanguagesList();

   }

   getNationalityList() {
      this.commonApiService.List(Commonvariable.Nationality).subscribe((result) => {
         if (result["data"] != "" && result["status"] == 200) {
            this.nationalityList = result["data"].sort((a, b) => a.name.localeCompare(b.name));
         }
      })
   }

   getEthinicityList() {
      this.commonApiService.List("Ethinicity").subscribe((result) => {
         if (result["data"] != "" && result["status"] == 200) {
            this.ethinicityList = result["data"].sort((a, b) => a.ethnicity_Name.localeCompare(b.ethnicity_Name));
         }
      })
   }

   getlanguagesList() {
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "LANGUAGES"
      }

      this.commonApiService.GetByPaginated(Commonvariable.Languages, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {

            this.languagesList = response["data"].map(x => ({
               id: x.id,
               text: x.languages
            }));

            //this.languagesList = response["data"];
         }
      })
   }





   empUpsert() {

      if (this.validation()) {
         return this.candidateBasicInfoform.value;
      }
      return "";
   }

   searchPersonFill(person) {
      if (person != null) {
         this.candidateBasicInfoform.controls.Id.setValue(person.id)
         this.candidateBasicInfoform.controls.Gender_Id.setValue(String(person.gender_Id))
         this.candidateBasicInfoform.controls.Title.setValue(person.title)
         this.candidateBasicInfoform.controls.FirstName.setValue(person.firstName)
         this.candidateBasicInfoform.controls.MiddleName.setValue(person.middleName)
         this.candidateBasicInfoform.controls.LastName.setValue(person.lastName)
      }
   }


   Edit(Id) {
      if (Id > 0) {
         this.editMode = true;
         this.commonApiService.getById(Commonvariable.Person, Id).subscribe((response) => {
            if (response != null) {
               var editData = response["data"][0];
               this.commonService.flatpickrForDOB("flatpickropenDOBs", 0);
               let date = response["data"][0].dob != null ? response["data"][0].dob.split("T")[0].split("-") : "";
               if (date != "") {
                  //$(".DOBClass").val(date[2] + "-" + date[1] + "-" + date[0]);
                  $(".DOBClass").flatpickr({ dateFormat: "d-m-Y", maxDate: "today", defaultDate: date[2] + "-" + date[1] + "-" + date[0] });
               }
               //console.log(date);

               setTimeout(() => {
                  this.candidateBasicInfoform = this.fb.group({
                     Id: [editData.id],
                     FirstName: [editData.firstName, Validators.required],
                     MiddleName: [editData.middleName],
                     LastName: [editData.lastName, Validators.required],
                     // BloodGroup: [editData.bloodGroup],
                     Title: [String(editData.title)],
                     Ethnicity_Id: [editData.ethnicity_Id],
                     DOB: ['', Validators.required],
                     Gender_Id: [String(editData.gender_Id)],
                     NiNo: [editData.niNo],
                     Languages_Id: [editData.languages_Id],
                     // TotalExperience:[editData.totalExperience],
                     Nationality_Id: [editData.nationality_Id],
                     isActive: ['1'],
                     isReview: ['1'],
                     UpdatedBy: [Commonvariable.User_Id]
                  });

                  if (editData.languages_Id != null) {
                     this.candidateBasicInfoform.controls.Languages_Id.setValue(editData.languages_Id.length > 0 ? String(editData.languages_Id).split(",") : null);
                  }
                  this.commonApiService.getimage(Commonvariable.Person, Id, "DownloadImage").subscribe((response) => {

                     if (response != null) {
                        var fileType = response.type;
                        var validImageTypes = ["image/gif", "image/jpeg", "image/png", "image/jpg", "image/bmp"];
                        if ($.inArray(fileType, validImageTypes) < 0) {
                           this.logoInnerHTML = "";
                           this.logoInnerHTML = "<img  src='assets/layouts/layout4/img/selectPic.png' alt = ''/>";
                        }
                        else {
                           var reader = new FileReader();
                           reader.readAsDataURL(response);
                           reader.addEventListener("load", () => {
                              this.logoInnerHTML = "";
                              this.logoInnerHTML = "<img  src=" + reader.result + " alt = ''/>";
                              this.logoClass = "fileinput fileinput-exists";
                           }, false);
                        }
                     }
                     else {
                        this.logoInnerHTML = "";
                        this.logoInnerHTML = "<img  src='assets/layouts/layout4/img/selectPic.png' alt = ''/>";
                     }



                  })
               });



            }
         })
      }
   }


   validation() {
      var formData = this.candidateBasicInfoform;
      if ($("#DOB").val() == undefined) {
         formData.controls.DOB.setValue(null);
      }
      else {
         formData.controls.DOB.setValue(this.commonService.dateFormatChange($("#DOB").val()));
      }

      if (formData.value.Title == '') {
         this.toastr.info("Title  is required !", "Candidate");
         return false;
      }
      else if (formData.value.Gender_Id == '') {
         this.toastr.info("Gender  is required !", "Candidate");
         return false;
      }

      // formData.get("isActive").setValue(formData.get("isActive").value ? 1 : 0)

      if (formData.invalid) {
         for (const key of Object.keys(formData.controls)) {
            if (formData.controls[key].invalid) {
               let fieldName = "";
               if (key == "FirstName") {
                  fieldName = "First Name"
               }
               // else if (key == "MiddleName") {
               //    fieldName = "Middle Name"
               // }
               else if (key == "LastName") {
                  fieldName = "Last Name"
               }
               else if (key == "DOB") {
                  fieldName = "DOB"
               }
               this.toastr.info(fieldName + " is required !", "Candidate");
               $("#" + key).focus();

               return false;
            }
         }

      }
      else {
         // if ($("#Email").val() == "" && !this.editMode) {
         //    this.toastr.info("Email is required !", "Candidate");
         //    $("#Email").focus();
         //    return false;
         // }
         // if (!this.editMode && $("#Mobile").val() != "" && $("#Mobile").val().length != 10 && !this.editMode) {
         //    this.toastr.info("Mobile must be 10 digit !", "Candidate");
         //    $("#Mobile").focus();
         //    return false;
         // }
         // if ($("#Nationality").val() == "") {
         //    this.toastr.info("Nationality is required !", "Candidate");
         //    $("#Nationality").focus();
         //    return false;
         // }

         if ($("#FirstName").val() != "" && /["\\]/.test($("#FirstName").val())) {
            this.toastr.info("First Name does not allow Special Characters \" \\ ", "Candidate info");
            $("#FirstName").focus();
            return false;
          }

          if ($("#MiddleName").val() != "" && /["\\]/.test($("#MiddleName").val())) {
            this.toastr.info("Middle Name does not allow Special Characters \" \\ ", "Candidate info");
            $("#MiddleName").focus();
            return false;
          }

          if ($("#LastName").val() != "" && /["\\]/.test($("#LastName").val())) {
            this.toastr.info("Last Name does not allow Special Characters \" \\ ", "Candidate info");
            $("#LastName").focus();
            return false;
          }
         // if ($("#FirstName").val().length < 2) {
         //    this.toastr.info("First Name must be at least 2 characters long !", "Candidate");
         //    $("#FirstName").focus();
         //    return false;
         // }

         // if ($("#FirstName").val().indexOf(" ") > -1) {
         //    this.toastr.info("First Name not allow space !", "Candidate");
         //    $("#FirstName").focus();
         //    return false;
         // }
         
         // if ($("#FirstName").val().search(/[^a-zA-Z-]+/) > -1) {
         //    this.toastr.info("First Name only alphabets allow !", "Candidate");
         //    $("#FirstName").focus();
         //    return false;
         // }

         // if ($("#MiddleName").val().length < 1) {
         //    this.toastr.info("Middle Name must be at least 1 characters long !", "Candidate");
         //    $("#MiddleName").focus();
         //    return false;
         // }
         // if ($("#MiddleName").val().indexOf(" ") > -1) {
         //    this.toastr.info("Middle Name not allow space !", "Candidate");
         //    $("#MiddleName").focus();
         //    return false;
         // }
         
         // if ($("#MiddleName").val().search(/[^a-zA-Z-]+/) > -1) {
         //    this.toastr.info("Middle Name only alphabets allow !", "Candidate");
         //    $("#MiddleName").focus();
         //    return false;
         // }
         // if ($("#LastName").val().length < 2) {
         //    this.toastr.info("Last Name must be at least 2 characters long !", "Candidate");
         //    $("#LastName").focus();
         //    return false;
         // }
         // if ($("#LastName").val().indexOf(" ") > -1) {
         //    this.toastr.info("Last Name not allow space !", "Candidate");
         //    $("#LastName").focus();
         //    return false;
         // }
         // if ($("#LastName").val().search(/[^a-zA-Z-]+/) > -1) {
         //    this.toastr.info("Last Name only alphabets allow !", "Candidate");
         //    $("#LastName").focus();
         //    return false;
         // }

         // if ($("#BloodGroup").val() != "" && this.editMode) {
         //    if ($("#BloodGroup").val().search(/^(A|B|AB|O)[-+]$/) != 0) {
         //       this.toastr.info("Enter valid bloodgroup !", "Candidate");
         //       $("#BloodGroup").focus();
         //       return false;
         //    }
         // }
         // else {
         //    formData.controls.BloodGroup.setValue(null);
         // }

         // if (!this.editMode && $("#Email").val().search(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/) != 0) {
         //    this.toastr.info("Enter valid email address !", "Candidate");
         //    $("#Email").focus();
         //    return false;
         // }

         // if (!this.editMode && $("#Mobile").val().search(/[^0-9]+/) > -1) {
         //    this.toastr.info("Mobile only numeric value allow !", "Candidate");
         //    $("#Mobile").focus();
         //    return false;
         // }

         // if ($("#TotalExperience").val() != "" && this.editMode) {
         //    if ($("#TotalExperience").val().length < 2) {
         //       this.toastr.info("Total experience must be at least 2 characters long !", "Candidate");
         //       $("#TotalExperience").focus();
         //       return false;
         //    }
         // }
      }
      this.commonService.setNull(formData);
      return true;
   }




   filechange(files: FileList) {


      if (files.item(0).name.split('.')[1] == "jpg" || files.item(0).name.split('.')[1] == "png"
         || files.item(0).name.split('.')[1] == "jpeg") {
         this.candidateLogoChange = true;
         this.candidateLogo = files.item(0);
      }
      else {
         this.toastr.info("Allow only jpg,png,jpeg file format for upload !", "Candidate");
         $("#remove").click();
      }

   }


}


