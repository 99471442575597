import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;


@Component({
  selector: 'app-empAddressInfo',
  templateUrl: './emp-address-Info.component.html'
})
export class EmpAddressInfoComponent implements OnInit {
  empAddressform: FormGroup;
  countrylist: any;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }

  options = {
    componentRestrictions: {
      country: ["UK", "IND"]
    }
  }
  ngOnInit() {

    this.empAddressform = this.fb.group({
      Id: ['0'],
      Person_Id: ['0'],
      //Address_Type: ['', Validators.required],
      Address1: ['', Validators.required],
      Address2: ['', Validators.required],
      Address3: [null],
      Address4: [null],
      LandlineNo: [null],
      PostCode: ['', Validators.required],
      City: ['', Validators.required],
      State: [null],
      country_Id: ['', Validators.required],
      isDefault: false,
      Emp_Doc_Id: ['0'],
      isReview: ['1'],
      isActive: true,
      UpdatedBy: [Commonvariable.User_Id]
    });
    this.getCountryList();
  }
  AddressChangeEmp(address: any) {

    // var component = address.address_components;
    console.log(address)

    this.empAddressform.controls.Address2.setValue("")
    this.empAddressform.controls.City.setValue("")
    this.empAddressform.controls.State.setValue("")
    this.empAddressform.controls.country_Id.setValue("")
    this.empAddressform.controls.PostCode.setValue("")

    for (const component of address.address_components) {

      const componentType = component.types[0];
      var address1
      var address2
      var address2_1


      switch (componentType) {

        case "street_number": {
          address1 = component.long_name
          break;
        }

        case "route": {
          address2 = component.long_name
          break;
        }

        case "postal_town": {
          var city_1 = component.long_name
          break;
        }


        case "locality": {
          var city_2 = component.long_name
          break;
        }


        case "neighborhood": {
          address2_1 = component.long_name
          break;
        }

        case "administrative_area_level_1": {
          var state_1 = component.long_name
          break;
        }

        case "administrative_area_level_2": {
          var state_2 = component.long_name
          break;
        }

        case "country":
          const found = this.countrylist.find((obj) => {
            return obj.text.toLowerCase() === component.long_name.toLowerCase();
          });
          this.empAddressform.controls.country_Id.setValue((found.id))

          // for (let i = 0; i < this.countrylist.length; i++) {
          //   if (this.countrylist[i].country_Name == component.long_name) {
          //     //console.log(this.countrylist[i]);
          //     this.empAddressform.controls.country_Id.setValue((this.countrylist[i].id))

          //   }
          // }

          break;

        case "postal_code": {
          this.empAddressform.controls.PostCode.setValue(component.long_name)
          break;
        }
      }
    }

    if (address1) {
      this.empAddressform.controls.Address1.setValue(address1)
    } else {
      this.empAddressform.controls.Address1.setValue(address.name)
    }

    if (address2) {
      this.empAddressform.controls.Address2.setValue(address2)
    } else {
      this.empAddressform.controls.Address2.setValue(address2_1)
    }

    if (city_1) {
      this.empAddressform.controls.City.setValue(city_1)
    } else {
      this.empAddressform.controls.City.setValue(city_2)
    }

    if (state_1) {
      this.empAddressform.controls.State.setValue(state_1)
    } else {
      this.empAddressform.controls.State.setValue(state_2)
    }

  }
  getCountryList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "country_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Country, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.countrylist = response["data"];
      }
    })
  }


  empEdit(Id) {
    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.Person_Address, Id).subscribe((response) => {
        if (response != null) {
          var editData = response["data"][0];

          if (JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "User") {
            if (response["data"].length > 1) {
              if (response["data"][1] != null) {
                editData = response["data"][1]
              }
            }
          }


          this.empAddressform = this.fb.group({
            Id: [editData.id],
            Person_Id: [editData.person_Id],
            //Address_Type: [editData.address_Type, Validators.required],
            Address1: [editData.address1, Validators.required],
            Address2: [editData.address2, Validators.required],
            Address3: [editData.address3],
            Address4: [editData.address3],
            LandlineNo: [editData.landlineNo != "" ? editData.landlineNo : null],
            PostCode: [editData.postCode, Validators.required],
            City: [editData.city, Validators.required],
            State: [editData.state],
            country_Id: [editData.country_Id, Validators.required],
            isDefault: [editData.isDefault],
            Emp_Doc_Id: ['0'],
            isReview: ['1'],
            isActive: [editData.isActive],
            UpdatedBy: [Commonvariable.User_Id]
          });
        }
      })
    }
  }





  validation() {
    var eForm = this.empAddressform;
    //sForm.get("company_Id").setValue($("#company_Id").val()); 
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);
    eForm.get("isDefault").setValue(eForm.get("isDefault").value ? 1 : 0);
    if (!eForm.valid) {
      for (const key of Object.keys(eForm.controls)) {
        if (eForm.controls[key].invalid) {
          let fieldName = "";

          // if (key == "Address_Type") {
          //   fieldName = "Address Type"
          // }
          if (key == "Address1") {
            fieldName = "Address1"
          }
          else if (key == "Address2") {
            fieldName = "Address2"
          }
          else if (key == "City") {
            fieldName = "City"
          }

          else if (key == "country_Id") {
            fieldName = "Country"
          }
          else if (key == "PostCode") {
            fieldName = "Post Code"
          }
          this.toastr.info(fieldName + " is required !", "Employee");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if ($("#Address1").val().length < 2 && $("#Address1").val().length != 0) {
        this.toastr.info("Address1 must be at least 2 characters long !", "Employee");
        $("#Address1").focus();
        return false;
      }
      if (/["\\]/.test($("#Address1").val())) {
        this.toastr.info("Address1 does not allow Special Characters \" \\ ", "Employee");
        $("#Address1").focus();
        return false;
      }

      // if ($("#Address1").val().search(/^[^-\s][a-zA-Z0-9\s-,/]+[^-\s]+$/) != 0) {
      //   this.toastr.info("Enter proper Address1 !", "Employee");
      //   $("#Address1").focus();
      //   return false;
      // }
      if ($("#Address2").val() != "" && $("#Address2").val().length < 2) {
        this.toastr.info("Address2 must be at least 2 characters long !", "Employee");
        $("#Address2").focus();
        return false;
      }
      if ($("#Address2").val() != "" && /["\\]/.test($("#Address2").val())) {
        this.toastr.info("Address2 does not allow Special Characters \" \\ ", "Employee");
        $("#Address2").focus();
        return false;
      }
      // if ($("#Address2").val() != "" && $("#Address2").val().search(/^[^-\s][a-zA-Z0-9\s-,/]+[^-\s]+$/) != 0) {
      //   this.toastr.info("Enter proper Address2 !", "Employee");
      //   $("#Address2").focus();
      //   return false;
      // }
      // if ($("#Address3").val() != "" && $("#Address3").val().length < 2) {
      //   this.toastr.info("Address3 must be at least 2 characters long !", "Employee");
      //   $("#Address3").focus();
      //   return false;
      // }
      if ($("#Address3").val() != "" && /["\\]/.test($("#Address3").val())) {
        this.toastr.info("Address3 does not allow Special Characters \" \\ ", "Employee");
        $("#Address3").focus();
        return false;
      }
      // if ($("#Address3").val() != "" && $("#Address3").val().search(/^[^-\s][a-zA-Z0-9\s-,/]+[^-\s]+$/) != 0) {
      //   this.toastr.info("Enter proper Address3 !", "Employee");
      //   $("#Address3").focus();
      //   return false;
      // }
      // if ($("#Address4").val() != "" && $("#Address4").val().length < 2) {
      //   this.toastr.info("Address4 must be at least 2 characters long !", "Employee");
      //   $("#Address4").focus();
      //   return false;
      // }
      if ($("#Address4").val() != "" && /["\\]/.test($("#Address4").val())) {
        this.toastr.info("Address4 does not allow Special Characters \" \\ ", "Employee");
        $("#Address4").focus();
        return false;
      }
      // if ($("#Address4").val() != "" && $("#Address4").val().search(/^[^-\s][a-zA-Z0-9\s-,/]+[^-\s]+$/) != 0) {
      //   this.toastr.info("Enter proper Address4 !", "Employee");
      //   $("#Address4").focus();
      //   return false;
      // }

      if ($("#City").val().length < 2 && $("#City").val().length != 0) {
        this.toastr.info("City must be at least 2 characters long !", "Employee");
        $("#City").focus();
        return false;
      }
      if ($("#City").val().search(/^[-a-zA-Z]+(\s+[-a-zA-Z]+)*$/) != 0) {
        this.toastr.info("City only alphabets allow !", "Employee");
        $("#City").focus();
        return false;
      }
      // if ($("#State").val().length < 2 && $("#State").val().length != 0) {
      //   this.toastr.info("County must be at least 2 characters long !", "Employee");
      //   $("#State").focus();
      //   return false;
      // }

      // if ($("#State").val() != "" && /["\\]/.test($("#State").val())) {
      //   this.toastr.info("County does not allow Special Characters \" \\ ", "Employee");
      //   $("#State").focus();
      //   return false;
      // }

      // if ($("#State").val().search(/^[^-\s][a-zA-Z\s-][^-\s]+$/) != 0){
      // if ($("#State").val().search(/^[-a-zA-Z]+(\s+[-a-zA-Z]+)*$/) != 0) {
      //   this.toastr.info("County only alphabets allow !", "Employee");
      //   $("#State").focus();
      //   return false;
      // }

      if ($("#PostCode").val() != "" && ($("#PostCode").val().length < 2 || $("#PostCode").val().length > 10)) {
        this.toastr.info("Post code must be at least 2 to 10 characters long !", "Employee");
        $("#PostCode").focus();
        return false;
      }

      if ($("#PostCode").val() != "" && $("#PostCode").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
        this.toastr.info("Post code should be alphabets or numeric !", "Employee");
        $("#PostCode").focus();
        return false;
      }


      // if ($("#PostCode").val() != "" && $("#PostCode").val().search(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/g) != 0) {
      //   this.toastr.info("Post code should be alphabets and numeric !", "Employee");
      //   $("#PostCode").focus();
      //   return false;
      // }

      // if ($("#State").val().search(/^[^-\s][a-zA-Z\s]+[^-\s]+$/) != 0) {
      //   this.toastr.info("County only alphabets allow !", "Employee");
      //   $("#State").focus();
      //   return false;
      // }
    }


    return true;
  }




}
