<style>
    .mb-10 {
        margin-bottom: 10px;
    }
</style>
<form>
    <div class="form-group" *ngIf="interview != null">
        <div class="table-resposive">
            <table class="table table-hover" id="work">
                <thead>
                    <tr>

                        <th style="width: 2%;">Sr No.</th>
                        <th style="width: 15%;">Interview Round<span class="required"> *
                            </span></th>
                        <th style="width: 15%;">Date<span class="required"> *
                            </span></th>
                        <th style="width: 15%;">Interviewer<span class="required"> *
                            </span></th>
                        <th style="width: 15%;">FeedBack<span class="required"> *
                            </span></th>
                        <th style="width: 15%;">Note</th>
                        <th style="width: 5%;"></th>
                        <th style="width: 5%;"><button id="btnAdd" type="button" class="btn pull-right" (click)="createItem()"><i
                                    class="fa fa-plus" aria-hidden="true"></i></button>
                        </th>
                    </tr>
                </thead>
                <tbody *ngFor="let item of interview; let i = index;">
                    <tr>
                        <td>
                            {{i+1}}
                        </td>
                        <td>
                            <input type="text" class="form-control" id="name{{i}}" autocomplete="off" maxlength="100">
                        </td>
                        <td>
                            <div class="input-group" id="flatpickropenintopen{{i}}" data-wrap="true">
                                <input type="text" class="form-control" id="interview_date_time{{i}}" data-input>
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </td>
                        <td>
                            <div *ngFor="let iv of item.interviewDetail" class="mb-10">
                                <input type="hidden" id="id{{i}}{{iv}}" value="0">
                                <select class="form-control" id="interviewer{{i}}{{iv}}" (change)="validation(true)">
                                    <option value="">Select Interviewer</option>
                                    <option *ngFor="let item of empList" value="{{item.emp_Id}}">{{item.firstName}}
                                        {{item.lastName}}</option>
                                </select>
                            </div>
                        </td>
                        <td>
                            <div *ngFor="let iv of item.interviewDetail" class="mb-10">
                                <select class="form-control" id="interview_satus{{i}}{{iv}}">
                                    <!-- <option value="newapplicant">New Applicant</option> -->
                                    <option value="ShortListed">ShortListed</option>
                                    <option value="Rejected">Rejected</option>
                                    <!-- <option value="offered">Offered</option>
                                    <option value="selected">Selected</option> -->
                                </select>
                            </div>
                        </td>
                        <td class="text-center">
                            <div *ngFor="let iv of item.interviewDetail" class="mb-10">
                                <textarea class="form-control" style="resize: none;" rows="1"
                                    id="Notes{{i}}{{iv}}"></textarea>
                            </div>
                        </td>
                        <td>
                            <div *ngFor="let iv of item.interviewDetail;" class="mb-10">
                                <button *ngIf="iv==0" type="button" class="btn" id="btnadd{{i}}{{iv}}"
                                    (click)="addRemoveInterviewDetail('add',i,0)"><i class="fa fa-plus"
                                        aria-hidden="true"></i></button>
                                <button *ngIf="iv > 0" type="button" class="btn" id="btnremove{{i}}{{iv}}"
                                    (click)="addRemoveInterviewDetail('minus',i,iv)"><i class="fa fa-minus"
                                        aria-hidden="true"></i></button>
                            </div>
                        </td>
                        <td>
                            <button *ngIf="i>0" type="button" id="btnAdd{{i}}" (click)="removeItem(i)"
                                class="btn pull-right"><i class="fa fa-minus" aria-hidden="true"></i></button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</form>