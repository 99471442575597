import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { JobDescriptionComponent } from '../jobDescription/jobdescription.component';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Router, ActivatedRoute } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import fieldVisibleInvisibleData from 'src/app/_files/field_visible_invisible.json';
import { DomSanitizer } from '@angular/platform-browser';
import { HistoryComponent } from 'src/app/pages/history/history.component';

import { JobTitleComponent } from '../designation/jobtitle.component';

import { WorkingShiftComponent } from '../shift/workingshift.component';
import * as moment from 'moment';
import { event } from 'jquery';


declare var $: any;
declare const Bloodhound;

@Component({
  selector: 'app-raf-add',
  templateUrl: './raf-add.component.html'
})
export class RAFAddComponent implements OnInit {

  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;

  editid: "";

  @ViewChild(JobDescriptionComponent, { static: false }) JDescription: JobDescriptionComponent;

  @ViewChild(JobTitleComponent, { static: false }) JobTitle: JobTitleComponent;
  @ViewChild(WorkingShiftComponent, { static: false }) WorkingShift: WorkingShiftComponent;

  vacancyForm: FormGroup;
  currency_Code: any;
  companylist: any;
  departmentList: any;
  designationList: any;
  jobTitleList: any;
  siteList: any;
  zoneList: any;
  contractList: any;
  tempcontractList: any;
  shiftList: any;
  employeeList: any;
  employeeListForRafRequester: any;
  assestList: any[] = [];
  addDescription: boolean = false;
  personalAllowanceList: any;
  vacancyRuleList: any[] = [];
  vacancyRuleListLength: number = 12;
  salaryTitle: string = "Per Hour";
  budgetTitle: string = "Per Hour";
  requester: string = "";
  vacancyEditInfo: any;
  icon: string = "plus";
  title: string = "Add";

  iconForJobTitle: string = "plus";
  titleForJobTitle: string = "Add";

  iconForShift: string = "plus";
  titleForShift: string = "Add";



  skillList: any;
  skill: any;
  fieldVisibleInvisible: any
  tempvacancyRuleList: any;
  vacancyChildData: any[];
  pagetitle: string = "Create";
  fileUrl;

  checkskillList: Array<Select2OptionData>;
  AlljobTitleList:any;
  allemployeeList:any;

  job_title_access:string;

  holidayempList:any;
  linemanagerempList:any;
  

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    
    
    ) {
      this.job_title_access=Commonvariable.Roles;
  }



  ngOnInit() {
   
    const currentDate = moment(new Date()).format("DD-MM-YYYY");
    this.commonService.timePicker("flatpickropenshiftstatetime,#flatpickropenshiftendtime", 0)

    this.fieldVisibleInvisible = fieldVisibleInvisibleData[0].RAF.Create_RAF;


    var self = this;
    this.vacancyForm = this.fb.group({
      Id: 0,
      Company_Id: [null, Validators.required],
      Dept_Id: [null, Validators.required],
      Desig_Id: [null, Validators.required],
      JD_Id: [null, Validators.required],
      Site_Id: [null, Validators.required],
      Type: [null, Validators.required],
      Zone_Id: [null, Validators.required],
      NoOfPositions: ['1', Validators.required],
      Compensation: [null],
      min_Experience: [null],
      Car_Allowance: [null],
      SalaryType: ['Hourly'],
      SalaryFrom: [null],
      SalaryTo: [null],
      Budget: [''],
      BudgetType: ['Hourly'],
      BudgetFrom: ['0'],
      BudgetTo: ['0'],
      Status: [null],
      Benchmark: [null],
      Bonus: [null],
      Vacancy_Rule_Id: [0],
      Contract_Id: [null, Validators.required],
      Shift_Id: [null],
      shift_Start: ['', Validators.required],
      shift_End: ['', Validators.required],
      nightShift: [''],
      shift_Variable:[''],
      Contract_HoursDaily: [null, Validators.required],
      Contract_Days: [null, Validators.required],
      Priority: [null, Validators.required],
      Contract_Type: [null],
      Notes: [null],
      date_Submition: [currentDate,Validators.required],
      reference_Number:[''],
      Reporting:[null],
      line_Manager:[null],
      budgeted: ['0'],
      isRequester: ['0'],
      isActive: [1],
      skills: [null],
      Requirements: [null],
      RequestedBy: [null, Validators.required],
      AddedBy: Commonvariable.User_Id,
      UpdatedBy: Commonvariable.User_Id
    })
    
    this.commonService.flatpickr("flatpickropencontract_DS", 0);
    //$("#skills_tags").tagsinput();
    //$("#skills_tags").tagsinput('items');    
    this.getjobtitledata();
    this.getAllEmpList();
    //var skillArray = self.skills();
    this.skills();
    this.getEmpList();
    this.getEmpListForRequester();
    this.getCompanyList();
    this.getZoneList(0);
    this.getRequester();
    this.getholidayEmpList();
    this.getlinemanagerEmpList();

 
 


    $('#Contract_Id').on('select2:select', function (e) {
      var data = e.params.data;
      self.fillContractValue('Change')
  });

    // Select2 Start
    $('.select').select2({ width: '100%', dropdownCssClass: "bigdrop" });

    $('#Company_Id').on('select2:select', function (e) {
      var selectedds = $('#Company_Id').val();
      if (selectedds) {
        self.getSiteList(selectedds);
        self.getDepartmentList(selectedds);
        self.getCurrencyCode(selectedds);
        self.getShiftList(selectedds)
        self.getVacancyRuleId()
        self.getEmpList();
        self.getEmpListForRequester();
        self.genrateRefNumber(selectedds);
      }
      self.personalAllowanceList = null;
      self.assestList = null;
      self.vacancyRuleList = [];
      if (selectedds == '') {
        self.departmentList = [];
        self.designationList = [];
        self.siteList = [];
        self.zoneList = [];
        self.jobTitleList = [];
        self.shiftList = [];
      }

      self.iconForJobTitle = "plus";
      self.titleForJobTitle = "Add";

      self.iconForShift = "plus";
      self.titleForShift = "Add";

      self.icon = "plus";
      self.title = "Add";

    });
    $('#Dept_Id').on('select2:select', function (e) {

      var selectedcompany_ds = $('#Company_Id').val();
      var selecteddept_ds = $('#Dept_Id').val();
      if (selectedcompany_ds && selecteddept_ds) {
        self.getDesignationList(selectedcompany_ds, selecteddept_ds);
      } else {
        self.designationList = [];
        self.jobTitleList = [];
      }

      self.iconForJobTitle = "plus";
      self.titleForJobTitle = "Add";

      self.icon = "plus";
      self.title = "Add";

    });

    $('#Desig_Id').on('select2:select', function (e) {
     

      var selectedcompany_ds = $('#Company_Id').val();
      var selecteddept_ds = $('#Dept_Id').val();
      var selectedsite_ds = $('#Site_Id').val();
      var selecteddesig_ds = $('#Desig_Id').val();

      if (selectedcompany_ds && selecteddept_ds && selectedsite_ds && selecteddesig_ds) {
        self.getJobTitleList(selectedcompany_ds, selecteddept_ds, selectedsite_ds, selecteddesig_ds);
      } else {
        self.jobTitleList = [];
      }
      self.vacancyForm.controls.JD_Id.setValue(parseInt($(".jdSelectListClass option:selected").val()))      
      self.icon = "plus";
      self.title = "Add";     

      self.iconForJobTitle = parseInt($(".recruitmentSelectListClass option:selected").val()) > 0 ? "edit" : "plus";
      self.titleForJobTitle = parseInt($(".recruitmentSelectListClass option:selected").val()) > 0 ? "Edit" : "Add";

    });
    $('.drppersonId,.positionSelectListClass,.siteSelectListClass,.departmentSelectListClass').on('select2:select', function (e) {
      self.getVacancyRuleId();
    });


    $('#JD_Id').on('select2:select', function (e) {
      self.icon = parseInt($(".jdSelectListClass option:selected").val()) > 0 ? "edit" : "plus";
      self.title = parseInt($(".jdSelectListClass option:selected").val()) > 0 ? "Edit" : "Add";




    });

    $('#Shift_Id').on('select2:select', function (e) {


      self.iconForShift = parseInt($(".workingShiftSelectListClass option:selected").val()) > 0 ? "edit" : "plus";
      self.titleForShift = parseInt($(".workingShiftSelectListClass option:selected").val()) > 0 ? "Edit" : "Add";
    });



    $('#JD_Id').on('select2:select', function (e) {
      self.vacancyForm.controls.JD_Id.setValue(parseInt($(".jdSelectListClass option:selected").val()))
      self.getSkills(parseInt($(".jdSelectListClass option:selected").val()))
    });
    // Select2 End


    this.route.queryParamMap.subscribe(params => {
      this.editid = params["params"]["editId"];

      if (this.editid) {
        if (params["params"]["recreate"]) {
          this.vacancyInfo(this.editid, true);
        }
        else {
          this.vacancyInfo(this.editid);
        }
      }
    });

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }
  getholidayEmpList() {

    this.commonApiService.getallEmployeeDetails(Commonvariable.EmployeeDetail,"GetHolidayApprover").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.holidayempList = result["data"]
        if (this.holidayempList.length > 0)
         {
           this.holidayempList = result["data"].map(x => ({
                        id: x.id,
                        text: x.displayName
                      }))
         }
      }
    })
  
}
getlinemanagerEmpList() {

  this.commonApiService.getallEmployeeDetails(Commonvariable.EmployeeDetail,"GetLineManager").subscribe((result) => {
    if (result != null && result["data"] != "") {
      this.linemanagerempList = result["data"]
      if (this.linemanagerempList.length > 0)
       {
         this.linemanagerempList = result["data"].map(x => ({
                      id: x.id,
                      text: x.displayName
                    }))
       }
    }
  })

}

  getCurrencyCode(companyId) {
    this.currency_Code = this.companylist.filter(x => x.company_Id == companyId)[0]["currency_Code"];
  }

  viewBudget(value) {

    if (value == 'No') {
      $(".classBudget").hide();
    } else {
      $(".classBudget").show();
    }
  }

  skills() {
    this.skillList = [];
    this.commonApiService.List('Skills').subscribe((response: any) => {

      if (response["data"] != null && response["status"] == 200 && response["data"] != "") {
        var tempArray = []
        this.skillList = response["data"];

        for (let index = 0; index < this.skillList.length; index++) {
          var json = {
            name: this.skillList[index].name,
            id: this.skillList[index].id,
          }
          tempArray.push(json);

          if (index == (this.skillList.length - 1)) {
            this.skillInitialize(tempArray);
            break;
          }

        }
      }
    })
  }

  skillInitialize(data) {

    if (data.length > 0) {
      this.skill = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: data
      });

      $('.skills_tags').tagsinput({
        typeaheadjs: {
          name: 'skills',
          displayKey: 'name',
          valueKey: 'name',
          source: this.skill.ttAdapter()
        }
      });
      this.skill.initialize();
    }


  }


  vacancyInfo(id, recreate = false) {
    
    
    this.commonApiService.getById('VacancyService', id).subscribe((response) => {
      if (response["data"]['0'] != null && response["data"]['0'] != "") {
        this.vacancyEditInfo = response["data"]['0'];

        var formData = this.vacancyForm;
        this.pagetitle = recreate ? "Create" : 'Edit'
        //var requirements = String(this.vacancyEditInfo.requirements).split(',')

        this.iconForJobTitle = parseInt(this.vacancyEditInfo.desig_Id) > 0 ? "edit" : "plus";
        this.titleForJobTitle = parseInt(this.vacancyEditInfo.desig_Id) > 0 ? "Edit" : "Add";

        this.iconForShift = parseInt(this.vacancyEditInfo.shift_Id) > 0 ? "edit" : "plus";
        this.titleForShift = parseInt(this.vacancyEditInfo.shift_Id) > 0 ? "Edit" : "Add";

        this.icon = parseInt(this.vacancyEditInfo.jD_Id) > 0 ? "edit" : "plus";
        this.title = parseInt(this.vacancyEditInfo.jD_Id) > 0 ? "Edit" : "Add";

        formData.controls.Id.setValue(recreate ? 0 : this.vacancyEditInfo.id);
        formData.controls.Company_Id.setValue(this.vacancyEditInfo.company_Id);

        formData.controls.Dept_Id.setValue(this.vacancyEditInfo.dept_Id);
        formData.controls.Desig_Id.setValue(this.vacancyEditInfo.desig_Id);
        formData.controls.JD_Id.setValue(this.vacancyEditInfo.jD_Id);
        formData.controls.Site_Id.setValue(this.vacancyEditInfo.site_Id);
        formData.controls.RequestedBy.setValue(this.vacancyEditInfo.requestedBy);
        formData.controls.Type.setValue(this.vacancyEditInfo.type != null ? this.vacancyEditInfo.type : null);
        formData.controls.Zone_Id.setValue(this.vacancyEditInfo.zone_Id);
        formData.controls.NoOfPositions.setValue(this.vacancyEditInfo.noOfPositions);
        formData.controls.Type.setValue(this.vacancyEditInfo.type);
        formData.controls.Compensation.setValue(this.vacancyEditInfo.compensation);

        formData.controls.min_Experience.setValue(this.vacancyEditInfo.min_Experience);
        formData.controls.Car_Allowance.setValue(this.vacancyEditInfo.car_Allowance);




        formData.controls.SalaryType.setValue(this.vacancyEditInfo.salaryType);

        formData.controls.SalaryFrom.setValue(this.vacancyEditInfo.salaryFrom);


        formData.controls.budgeted.setValue(this.vacancyEditInfo.budgeted);


        if (this.vacancyEditInfo.budgeted == 0) {
          $("#radio59").prop("checked", true);
          $('#radio59').val(this.vacancyEditInfo.budgeted).trigger('change');
          this.viewBudget('0')
        }
        if (this.vacancyEditInfo.budgeted == 1) {
          $("#radio53").prop("checked", true);
          $('#radio53').val(this.vacancyEditInfo.budgeted).trigger('change');
          this.viewBudget('1')
        }


        formData.controls.Reporting.setValue(this.vacancyEditInfo.reporting);
        formData.controls.line_Manager.setValue(this.vacancyEditInfo.line_Manager);


        formData.controls.SalaryTo.setValue(this.vacancyEditInfo.salaryTo);

        formData.controls.BudgetType.setValue(this.vacancyEditInfo.budgetType);

        formData.controls.BudgetFrom.setValue(this.vacancyEditInfo.budgetFrom);

        formData.controls.BudgetTo.setValue(this.vacancyEditInfo.budgetTo);

        formData.controls.Status.setValue(this.vacancyEditInfo.status);

        formData.controls.Benchmark.setValue(this.vacancyEditInfo.benchmark);

        //formData.controls.Bonus.setValue(this.vacancyEditInfo.bonus);

        formData.controls.Vacancy_Rule_Id.setValue(this.vacancyEditInfo.vacancy_Rule_Id);

        formData.controls.Contract_Id.setValue(this.vacancyEditInfo.contract_Id);

       // formData.controls.Shift_Id.setValue(this.vacancyEditInfo.shift_Id);
        
        formData.controls.Contract_HoursDaily.setValue(this.vacancyEditInfo.contract_HoursDaily);

        formData.controls.Contract_Days.setValue(this.vacancyEditInfo.contract_Days);

        formData.controls.Priority.setValue(this.vacancyEditInfo.priority);

        formData.controls.Contract_Type.setValue(this.vacancyEditInfo.contract_Type);

        formData.controls.Notes.setValue(this.vacancyEditInfo.notes);

       
        formData.controls.reference_Number.setValue(this.vacancyEditInfo.reference_Number);
        formData.controls.shift_Start.setValue(this.vacancyEditInfo.shift_Start);
        formData.controls.shift_End.setValue(this.vacancyEditInfo.shift_End);
        formData.controls.nightShift.setValue(this.vacancyEditInfo.nightShift);
        formData.controls.shift_Variable.setValue(this.vacancyEditInfo.shift_Variable);



        formData.controls.Requirements.setValue(String(this.vacancyEditInfo.requirements).split(","));

        // console.log(this.vacancyEditInfo);
        // console.log(recreate);

        formData.controls.RequestedBy.setValue(this.vacancyEditInfo.requestedBy);
        if(this.vacancyEditInfo.requestedBy == Commonvariable.emp_Id){
          formData.controls.isRequester.setValue(String(0));
        }else{
          formData.controls.isRequester.setValue(String(this.vacancyEditInfo.isRequester));  
        }       


        if (this.vacancyEditInfo.status != 'Planned' && this.vacancyEditInfo.status != 'Rejected') {

          if (recreate == true) {
            this.getRequester()
          } else {
            formData.controls.RequestedBy.setValue(this.vacancyEditInfo.requestedBy);
            formData.controls.isRequester.setValue(String(this.vacancyEditInfo.isRequester));
            //this.requester = this.vacancyEditInfo.requestedBy_Emp_Name;
          }

        }
        $('#Reporting').val(this.vacancyEditInfo.reporting).trigger('change');
        $('#line_Manager').val(this.vacancyEditInfo.line_Manager).trigger('change');
        formData.controls.isActive.setValue(this.vacancyEditInfo.isActive);
        $('#Company_Id').val(this.vacancyEditInfo.company_Id).trigger('change');
        this.getDepartmentList(this.vacancyEditInfo.company_Id);
        $('#Dept_Id').val(this.vacancyEditInfo.dept_Id).trigger('change');


        if (this.vacancyEditInfo.company_Id && this.vacancyEditInfo.dept_Id) {
          this.getDesignationList(this.vacancyEditInfo.company_Id, this.vacancyEditInfo.dept_Id);
        }
        $('#Desig_Id').val(this.vacancyEditInfo.desig_Id).trigger('change');


        if (this.vacancyEditInfo.company_Id && this.vacancyEditInfo.dept_Id && this.vacancyEditInfo.site_Id && this.vacancyEditInfo.desig_Id) {
          this.getJobTitleList(this.vacancyEditInfo.company_Id, this.vacancyEditInfo.dept_Id, this.vacancyEditInfo.site_Id, this.vacancyEditInfo.desig_Id);
        }
        $('#JD_Id').val(this.vacancyEditInfo.jD_Id).trigger('change');

        if (this.vacancyEditInfo.company_Id) {
          this.getSiteList(this.vacancyEditInfo.company_Id);
        }
        $('#Site_Id').val(this.vacancyEditInfo.site_Id).trigger('change');

        $('#Type').val(this.vacancyEditInfo.type).trigger('change');
        $('#Zone_Id').val(this.vacancyEditInfo.zone_Id).trigger('change');
        $('#Contract_Id').val(this.vacancyEditInfo.contract_Id).trigger('change');
        $('#Shift_Id').val(this.vacancyEditInfo.shift_Id).trigger('change');
        $('#Priority').val(this.vacancyEditInfo.priority).trigger('change');
       
        var workingDays = String(this.vacancyEditInfo.contract_Days).split(',')
        workingDays.forEach(x => {
          $("[value=" + x + "]").prop("checked", true)
        });


        if (this.vacancyEditInfo.salaryType == "Annually") {
          this.salaryTitle = 'Per Annum'
        } else {
          this.salaryTitle = 'Per Hour'
        }

        if (this.vacancyEditInfo.budgetType == "Annually") {
          this.budgetTitle = 'Per Annum'
        } else {
          this.budgetTitle = 'Per Hour'
        }

        if (this.vacancyEditInfo.budgetFrom == 0) {

          $("#radio7No").prop("checked", true);
          this.viewBudget('No')
        }
        if (this.vacancyEditInfo.budgetFrom == 0 && this.vacancyEditInfo.budgetTo == 0 && this.vacancyEditInfo.budget != null) {

          $("#radio6Yes").prop("checked", true);
          this.viewBudget('Yes')
        }
        if (this.vacancyEditInfo.skills != null) {
          $("#skills_tags").val(this.vacancyEditInfo.skills != null ? this.vacancyEditInfo.skills.replace(/[ ~]+/g, ",") : null);
          $("#skills_tags").tagsinput('add', this.vacancyEditInfo.skills != null ? this.vacancyEditInfo.skills.replace(/[ ~]+/g, ",") : null);
        }

        // setTimeout(() => {
        //   $("#requirements").val(requirements).trigger('change')
        // }, 400);

        this.getVacancyRuleId();
        this.getCurrencyCode(this.vacancyEditInfo.company_Id)
        this.getEmpList();
        this.getEmpListForRequester();

      }
    })
  }

  getCompanyList() {

    this.companylist = [];

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'company_Name'
    }

    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response["data"] != null && response["status"] == 200 && response["data"] != "") {
        this.companylist = response["data"];
        $('.select').select2({ width: '100%', dropdownCssClass: "bigdrop" });
        $('.select').val("").trigger('change')
        //this.companylist = response["data"].filter(x => x.isActive != 0);
      }
    })
  }

  getRequester() {
    this.requester = "";
    let requestData = {
      "searchBy": [
        {
          "FieldName": "Id",
          "FieldValue": Commonvariable.emp_Id != null ? Commonvariable.emp_Id : 0,
          "Parameter": "="
        }
      ]
    }
    this.commonApiService.getPaginated(Commonvariable.Person, null, null, null, null, null, requestData).subscribe((response) => {
      if (response["data"] != "") {
        this.requester = response["data"]["list"][0]["emp_Id"] != null ? response["data"]["list"][0]["emp_Id"] : Commonvariable.emp_Id;
        this.vacancyForm.controls.RequestedBy.setValue(this.requester);
      }
    })
  }



  getDepartmentList(event) {
    this.addDescription = true;
    this.departmentList = [];
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "Company_Id",
          "FieldValue": event,
          "Parameter": "==",
        }
      ],
      "OrderBy": 'dept_Name'
    }

    this.commonApiService.GetByPaginated(Commonvariable.Department, requestData).subscribe((response) => {
      if (response["data"] != null && response["data"] != "") {
        this.departmentList = response["data"];
        this.getpersonalAllowance()
        //this.getDesignationList(event)

      }
    })
  }

  getDesignationList(cid, deptid = null) {



    this.designationList = [];
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "Company_Id",
          "FieldValue": cid,
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "dept_Id",
          "FieldValue": deptid != null ? deptid : parseInt($("#dept_Id option:selected").val()),
          "Parameter": "==",
        }
      ],
      "OrderBy": 'desig_Name'
    }

    this.commonApiService.GetByPaginated(Commonvariable.Designation, requestData).subscribe((response) => {
      if (response["data"] != "") {
        this.designationList = response["data"];
       
        //this.getSiteList(cid)        
      }
    })
  }



  getSiteList(event) {

    this.siteList = [];
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "Company_Id",
          "FieldValue": event,
          "Parameter": "==",
        }
      ],
      "OrderBy": 'site_Name'
    }

    this.commonApiService.GetByPaginated(Commonvariable.Site, requestData).subscribe((response) => {
      if (response["data"] != "") {
        this.siteList = response["data"];
        this.getZoneList(event)
      }
    })
  }


  getEmpListForRequester() {

    this.employeeList = [];
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "Company_Id",
          "FieldValue": parseInt($(".companySelectListClass").val()) > 0 ? $(".companySelectListClass option:selected").val() : this.vacancyEditInfo != null ? this.vacancyEditInfo.company_Id : '0',
          "Parameter": "=="
        }
      ]
    }

    if (requestData.searchBy[1].FieldValue > 0) {
      this.commonApiService.GetByPaginatedForRAFRequester(Commonvariable.EmployeeDetail, requestData).subscribe((result) => {
        if (result["data"] != "" && result["data"]) {
          this.employeeListForRafRequester = result["data"];

          this.employeeListForRafRequester = result["data"].map(x => ({
            id: x.person_Id,
            text: x.firstName + " " + x.lastName
          }));
        }
      })
    }

  }
  getEmpList() {
    this.employeeList = [];
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "Company_Id",
          "FieldValue": parseInt($(".companySelectListClass").val()) > 0 ? $(".companySelectListClass option:selected").val() : this.vacancyEditInfo != null ? this.vacancyEditInfo.company_Id : '0',
          "Parameter": "=="
        }
      ]
    }

    if (requestData.searchBy[1].FieldValue > 0) {
      this.commonApiService.GetByPaginated(Commonvariable.EmployeeDetail, requestData).subscribe((result) => {
        if (result["data"] != "" && result["data"]) {
          this.employeeList = result["data"];

          this.employeeList = result["data"].map(x => ({
            id: x.person_Id,
            text: x.firstName + " " + x.lastName
          }));
        }
      })
    }
  }
 


  getZoneList(event) {

    this.zoneList = [];
    var requestData;

    if (parseInt(event) > 0) {
      requestData = {
        "searchBy": [
          {
            "FieldName": "isActive",
            "FieldValue": "1",
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "Company_Id",
            "FieldValue": event,
            "Parameter": "=="
          }
        ],
        "OrderBy": 'zone_Id desc'
      }
    }
    else {

      requestData = {
        "searchBy": [
          {
            "FieldName": "isActive",
            "FieldValue": "1",
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "Company_Id",
            "FieldValue": "null",
            "Parameter": "=="
          }
        ],
        "OrderBy": 'zone_Id desc'
      }

    }


    this.commonApiService.GetByPaginated(Commonvariable.Zone, requestData).subscribe((response) => {
      if (response["data"] != "") {
        this.zoneList = response["data"];
        this.getContractList(event)
      }
    }, (error) => {
      this.getContractList(event);
    })
  }

  getContractList(event) {

    this.contractList = [];
    this.tempcontractList = [];

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "Company_Id",
          "FieldValue": event,
          "Parameter": "==",
        }
      ],
      "OrderBy": 'contract_Name'
    }

    this.commonApiService.GetByPaginated(Commonvariable.Contract, requestData).subscribe((response) => {

      if (response["data"] != "") {
        this.tempcontractList = response["data"]
        this.contractList = response["data"].map(x => ({
          id: x.contract_Id,
         // text: x.contract_Name + " / " + x.contract_Type
          text: x.contract_Name
        }));
      }
      this.getShiftList(event)
    })
  }

  getShiftList(event) {


    this.shiftList = [];

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "Company_Id",
          "FieldValue": event,
          "Parameter": "==",
        }
      ],
      "OrderBy": 'shift_Name'
    }

    this.commonApiService.GetByPaginated(Commonvariable.Shift, requestData).subscribe((response) => {
      if (response["data"] != "") {
        this.shiftList = response["data"];
      }
    })
  }

  getJobTitleList(cid, deptid, siteid, desigid) {


    this.jobTitleList = [];

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "Company_Id",
          "FieldValue": cid,
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "dept_Id",
          "FieldValue": deptid,
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "site_Id",
          "FieldValue": siteid,
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": "desig_Id",
          "FieldValue": desigid,
          "Parameter": "==",
        }
      ],
      "OrderBy": 'jD_Name'
    }

    this.commonApiService.GetByPaginated(Commonvariable.JobDescription, requestData).subscribe((response) => {

      if (response["data"] != "") {
        this.jobTitleList = response["data"].filter(x => x.isActive != 0);
      }
      this.getpersonalAllowance();
    })
  }

  getpersonalAllowance() {

    this.assestList = [];
    this.personalAllowanceList = [];

    this.commonApiService.getById(Commonvariable.VacancyService, $("#Company_Id").val() != null && $("#Company_Id").val() != "" ? $("#Company_Id").val() : '0', "GetRequirements").subscribe((response) => {
      if (response["data"] != "") {

        //this.assestList = response["data"]["list"];

        this.assestList = response["data"].map(x => ({
          id: x,
          text: x
        }));


      }
    })
  }

  getVacancyRuleId() {
    this.vacancyRuleList = [];
    var requestData =
    {
      "companyId": parseInt($(".companySelectListClass").val()) > 0 ? $(".companySelectListClass option:selected").val() : this.vacancyEditInfo != null ? this.vacancyEditInfo.company_Id : '0',
      "siteId": parseInt($(".siteSelectListClass").val()) > 0 ? $(".siteSelectListClass option:selected").val() : this.vacancyEditInfo != null ? this.vacancyEditInfo.site_Id : null,
      "deptId": parseInt($(".departmentSelectListClass").val()) > 0 ? $(".departmentSelectListClass option:selected").val() : this.vacancyEditInfo != null ? this.vacancyEditInfo.dept_Id : null,
      "type": $(".positionSelectListClass").val() != "" ? $(".positionSelectListClass").val() : null,
      "requestedBy": this.vacancyForm.controls.RequestedBy.value > 0 ? this.vacancyForm.controls.RequestedBy.value : 0,
      "budgeted": this.vacancyForm.controls.budgeted.value
    }

    if (parseInt(requestData.companyId) > 0 && parseInt(requestData.requestedBy) > 0) {

      this.commonApiService.PostRequest("Vacancy_RuleService", "GetRules", requestData).subscribe((response) => {
        if (response["data"] != "") {
          // if (this.vacancyForm.controls.Vacancy_Rule_Id.value > 0 && this.editid != undefined) {
          //   this.vacancyRuleList = response["data"].filter(x => x.vacancy_Rule_Id == this.vacancyForm.controls.Vacancy_Rule_Id.value)[0]["childData"]
          //   this.vacancyRuleListLength = Math.floor(12 / this.vacancyRuleList.length);
          //   this.vacancyForm.controls.Vacancy_Rule_Id.setValue(this.vacancyRuleList[0]["vacancy_Rule_Id"])

          // }
          // else {
          if (response["data"].length == 1) {
            this.vacancyRuleList = response["data"][0]["childData"];
            this.vacancyRuleListLength = Math.floor(12 / this.vacancyRuleList.length);
            this.vacancyForm.controls.Vacancy_Rule_Id.setValue(this.vacancyRuleList[0]["vacancy_Rule_Id"])
          }
          else {
            this.tempvacancyRuleList = response["data"];

            $('#selectRule').modal({
              backdrop: 'static'
            });

          }
          //}








          // this.vacancyRuleList = response["data"];
          // this.vacancyRuleListLength = Math.floor(12 / response["data"].length);
          // this.vacancyForm.controls.Vacancy_Rule_Id.setValue(response["data"][0]["vacancy_Rule_Id"])
        }
      })
    }

  }
  
  onChangeTime(name): void {
    
    
    var shift_Time = moment($("#"+name).val(), 'H:m').format("H:m");
    if (shift_Time) {
     
    }
  }

  vacancyUpsert(status) {
    

    var methodName = "VacancyService";
    var formData = this.vacancyForm;
    var id = formData.controls.Id.value;

    //var editId = formData.controls.Id.value;


    // if (status != 'Planned') {
    //   formData.controls.Id.setValue(0);
    // }

    var Workingdays = $(".wd:checked").map(function () {
      return $(this).val();
    }).get();

    formData.controls.Contract_Days.setValue(String(Workingdays) == "" ? null : String(Workingdays));
    formData.controls.Company_Id.setValue($(".companySelectListClass option:selected").val());
    formData.controls.Dept_Id.setValue($(".departmentSelectListClass option:selected").val());
    formData.controls.Desig_Id.setValue($("#Desig_Id option:selected").val());
    formData.controls.JD_Id.setValue($(".jdSelectListClass option:selected").val());

    formData.controls.Site_Id.setValue($(".siteSelectListClass option:selected").val() == "" ? null :
      $(".siteSelectListClass option:selected").val());

    formData.controls.Type.setValue($(".positionSelectListClass option:selected").val() != "" ? $(".positionSelectListClass option:selected").val() : null);

    formData.controls.Zone_Id.setValue($(".zoneSelectListClass option:selected").val() == "" ? null :
      $(".zoneSelectListClass option:selected").val());

    // formData.controls.Contract_Id.setValue($(".contractTypeSelectListClass option:selected").val() == "" ? null :
    //   $(".contractTypeSelectListClass option:selected").val());

    formData.controls.Shift_Id.setValue($(".workingShiftSelectListClass option:selected").val() == "" ? null :
      $(".workingShiftSelectListClass option:selected").val());

    formData.controls.Priority.setValue($(".prioritySelectListClass option:selected").val() == "" ? null :
      $(".prioritySelectListClass option:selected").val());

    if ($("#radio7No").prop("checked")) {
      formData.controls.BudgetFrom.setValue(0);
      formData.controls.BudgetTo.setValue(0);
    }
    formData.controls.line_Manager.setValue($("#line_Manager option:selected").val());
    formData.controls.Reporting.setValue($("#Reporting option:selected").val());

    if (this.validation(status)) {

      //if (id == 0 || status == "Pipeline") {Awaiting Approval
       if (id == 0 || status == "Awaiting Approval") {
        this.vacancyForm.controls.Status.setValue(status);
      }

      this.commonApiService.Upsert(methodName, this.vacancyForm.value, id).subscribe((result) => {
        if (result["data"] != null && result["data"] != "") {
          if (result["message"] == Commonvariable.DuplicationMsg) {
            this.commonService.toaster("warning", 'Record is already exists !', 'Vacancy')
          }
          else {
            // if (status != 'Planned') {
            //   if (editId > 0) {
            //     this.commonApiService.Delete('VacancyService', editId).subscribe((result) => {

            //     })
            //   }
            // }
          
            var postRequest = {
              "Vacancy_Id": result["data"]["id"],
              "Status": this.vacancyForm.controls.Status.value,
              "Action": id > 0 ? "Edit" : "Add",
              "AddedBy": Commonvariable.User_Id,
            }
            this.commonApiService.Upsert(Commonvariable.Vacancy_History, postRequest, 0).subscribe((result) => {
              this.commonService.toaster("success", Commonvariable.SaveMsg, 'Vacancy')
              this.router.navigateByUrl('raf-overview');
            })
          }
        }
      })
    }

   


  }




  validation(status) {
  

    var formData = this.vacancyForm;

    // var shift_End = $("#shift_End").val();
    // var shift_Start = $("#shift_Start").val();
    // formData.controls.shift_Start.setValue(shift_Start);
    // formData.controls.shift_End.setValue(shift_End);
    formData.get("nightShift").setValue(formData.get("nightShift").value ? 1 : 0);
    formData.get("shift_Variable").setValue(formData.get("shift_Variable").value ? 1 : 0);

    formData.controls.skills.setValue($("#skills_tags").val().replace(/[ ,]+/g, "~"));

    formData.controls.Contract_Type.setValue($("#Contract_Id option:selected").text() == "" ? null :
      String($("#Contract_Id option:selected").text()).trim());


    var Workingdays = $(".wd:checked").map(function () {
      return $(this).val();
    }).get();

    formData.controls.Contract_Days.setValue(String(Workingdays) == "" ? null : String(Workingdays));
    formData.controls.Vacancy_Rule_Id.setValue(this.vacancyRuleList.length > 0 ? this.vacancyRuleList[0].vacancy_Rule_Id : 0);
    formData.controls.Company_Id.setValue($(".companySelectListClass option:selected").val());
    formData.controls.Dept_Id.setValue($(".departmentSelectListClass option:selected").val());
    formData.controls.Desig_Id.setValue($("#Desig_Id option:selected").val());

    formData.controls.JD_Id.setValue($(".jdSelectListClass option:selected").val() == "" ? null : $(".jdSelectListClass option:selected").val());

    this.vacancyForm.controls.RequestedBy.setValue(this.vacancyForm.controls.RequestedBy.value ? this.vacancyForm.controls.RequestedBy.value : String(this.requester))

    formData.controls.Site_Id.setValue($(".siteSelectListClass option:selected").val() == "" ? null :
      $(".siteSelectListClass option:selected").val());

    formData.controls.Type.setValue($(".positionSelectListClass option:selected").val() != "" ? $(".positionSelectListClass option:selected").val() : null);

    formData.controls.Zone_Id.setValue($(".zoneSelectListClass option:selected").val() == "" ? null :
      $(".zoneSelectListClass option:selected").val());

    // formData.controls.Contract_Id.setValue($(".contractTypeSelectListClass option:selected").val() == "" ? null :
    //   $(".contractTypeSelectListClass option:selected").val());

    formData.controls.Shift_Id.setValue($(".workingShiftSelectListClass option:selected").val() == "" ? null :
      $(".workingShiftSelectListClass option:selected").val());

    formData.controls.Priority.setValue($(".prioritySelectListClass option:selected").val() == "" ? null :
      $(".prioritySelectListClass option:selected").val());

    for (const key of Object.keys(formData.controls)) {
    

      if (formData.controls[key].invalid) {
        let fieldName = "";
        if (key == "Company_Id") {
          fieldName = "Company"
        }
        if (key == "Dept_Id" && status != "Planned") {
          fieldName = "Department"
        }
        else if (key == "Desig_Id" && status != "Planned") {
          fieldName = "Job title"
        }
        else if (key == "JD_Id" && status != "Planned") {
          fieldName = "Job description"
        }
        else if (key == "Site_Id" && status != "Planned") {
          fieldName = "Site"
        }
        else if (key == "Type" && status != "Planned") {
          fieldName = "Position Type"
        }
        else if (key == "Zone_Id" && status != "Planned") {
          fieldName = "Zone"
        }
        else if (key == "NoOfPositions" && status != "Planned") {
          fieldName = "No of Positions"
        }
        else if (key == "Contract_Id" && status != "Planned") {
          fieldName = "Contract"
        }
        // else if (key == "Shift_Id" && status != "Planned") {
        //   fieldName = "Shift"
        // }
        else if (key == "shift_Start") {
          fieldName = "Shift start"
        }
        else if (key == "shift_End") {
          fieldName = "Shift end"
        }
        else if (key == "Contract_HoursDaily" && status != "Planned") {
          fieldName = "Contract hours"
        }
        else if (key == "Contract_Days" && status != "Planned") {
          fieldName = "Working days"
        }
        else if (key == "Priority" && status != "Planned") {
          fieldName = "Priority"
        }
        else if (key == "Contract_Type" && status != "Planned") {
          fieldName = "Contract type"
        }
        else if (key == "RequestedBy") {
          fieldName = "RequestedBy"
        }
        else if (key == "date_Submition") {
          fieldName = "Date Submition"
        }


        if (fieldName != "") {
          this.commonService.toaster("info", fieldName + " is required !", "Vacancy");
          $("#" + key).focus();
          return false;
        }
      }
      else {
        if ($("#shift_Start").val() == '00:00') {
          this.commonService.toaster("Shift Start is required !", "Shift");
          $("#shift_Start").focus();
          return false;
        }
  
       
  
        if ($("#shift_End").val() == '00:00') {
          this.commonService.toaster("Shift End is required !", "Shift");
          $("#shift_End").focus();
          return false;
        }

        if (key == "Contract_HoursDaily" && status != "Planned") {
          if (parseInt($("#Contract_HoursDaily").val()) > 24) {
            this.commonService.toaster("info", "Contract hours not more then 24", "Vacancy");
            $("#Contract_HoursDaily").focus();
            return false;
          }
        }
        if (key == "NoOfPositions" && status != "Planned") {
          if (parseInt($("#NoOfPositions").val()) == 0) {
            this.commonService.toaster("info", "No of postion require more then 0", "Vacancy");
            $("#NoOfPositions").focus();
            return false;
          }
          if (parseInt($("#NoOfPositions").val()) > 100) {
            this.commonService.toaster("info", "No of postion not more then 100", "Vacancy");
            $("#NoOfPositions").focus();
            return false;
          }
        }
        if (key == "SalaryFrom" && status != "Planned") {
          if ($("#From").val() == '') {
            this.commonService.toaster("info", "Salary From is required !", "Vacancy");
            $("#From").focus();
            return false;
          }
        }
        if (key == "SalaryTo" && status != "Planned") {
          if ($("#To").val() == '') {
            this.commonService.toaster("info", "Salary To is required !", "Vacancy");
            $("#To").focus();
            return false;
          }
          if ($("#To").val() == 0) {
            this.commonService.toaster("info", "Salary To require more then 0", "Vacancy");
            $("#To").focus();
            return false;
          }
        }
        if ($("#justification").val() != "" && /["\\]/.test($("#justification").val())) {
          this.commonService.toaster("info","Justification does not allow Special Characters \" \\ ", "Vacancy");
          $("#justification").focus();
          return false;
        }
      }
    }


    // if ($("#zone_Code").val().indexOf(" ") > -1) {
    //   this.commonService.toaster("info", "", "Vacancy");
    //   $("#zone_Code").focus();
    //   return false;
    // }
    // if ($("#zone_Code").val().search(/[^a-zA-Z0-9]+/) > -1) {
    //   // this.toastr.info("Zone code only alphabets and numeric allow !", "Zone");
    //   this.commonService.toaster("info", "", "Vacancy");
    //   $("#zone_Code").focus();
    //   return false;
    // }

    formData.controls.Requirements.setValue(formData.controls.Requirements.value != null ? String(formData.controls.Requirements.value) : "");
    formData.controls.date_Submition.setValue(this.commonService.dateFormatChange($("#date_Submition").val()));
    return true;
  }

  openModelShift() {
    //console.log($(".companySelectListClass option:selected").val())

    this.vacancyForm.controls.Shift_Id.setValue($("#Shift_Id option:selected").val());
    if (parseInt(this.vacancyForm.value.Shift_Id) > 0) {
      this.title = "Edit";
    }
    this.WorkingShift.parentComponent(this.vacancyForm.value)
    this.WorkingShift.shiftform.controls.company_Id.setValue([$(".companySelectListClass option:selected").val()]);

    $('#draggableShift').modal({
      backdrop: 'static'
    });
  }
 
  

  shiftUpsert() {


    var sForm = this.WorkingShift.shiftform;
    var shift_End = $("#shift_End").val();
    sForm.controls.shift_End.setValue(shift_End);
    if (this.WorkingShift.validation()) {

      sForm.get("isActive").setValue(sForm.get("isActive").value ? 1 : 0);
      sForm.get("nightShift").setValue(sForm.get("nightShift").value ? 1 : 0);
      sForm.get("shift_Variable").setValue(sForm.get("shift_Variable").value ? 1 : 0);
      sForm.get("shift_Name").setValue(sForm.get("shift_Name").value);
      this.commonApiService.UpsertWithMultiCompany(Commonvariable.Shift, sForm.value, sForm.controls.shift_Id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {

            this.commonService.toaster("warning", 'Record is already exists !', 'Shift')
          }
          else {
            $("#draggableShift").modal('hide');

            // console.log($(".companySelectListClass option:selected").val())
            if ($(".companySelectListClass option:selected").val()) {
              this.getShiftList($(".companySelectListClass option:selected").val())
            }


            this.commonService.toaster("success", sForm.controls.shift_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Shift')
          }
        }
      })

    }
  }

  openModelJobTitle() {
    this.vacancyForm.controls.Company_Id.setValue($(".companySelectListClass option:selected").val());

    this.vacancyForm.controls.Dept_Id.setValue($(".departmentSelectListClass option:selected").val());
    this.vacancyForm.controls.Desig_Id.setValue($("#Desig_Id option:selected").val());
    this.vacancyForm.controls.JD_Id.setValue($(".jdSelectListClass option:selected").val());
    // if (parseInt(this.vacancyForm.value.Desig_Id) > 0) {
    //   this.title = "Edit";

    // }
    this.JobTitle.parentComponent(this.vacancyForm.value)

    this.JobTitle.designationform.controls.company_Id.setValue($(".companySelectListClass option:selected").val());

    this.JobTitle.getDepartmentList();
    this.JobTitle.designationform.controls.dept_Id.setValue($("#Dept_Id option:selected").val());
    this.JobTitle.designationform.controls.zone_Id.setValue($("#Zone_Id option:selected").val());

    $('#draggableJobTitle').modal({
      backdrop: 'static'
    });
    //this.vacancyForm.controls.Company_Id.setValue($(".companySelectListClass option:selected").val());
  }

  jobTitleUpsert() {
    var designationForm = this.JobTitle.designationform;
    if (this.JobTitle.validation()) {

      var dId = designationForm.controls.desig_Id.value;
      designationForm.get("report_To").setValue(parseInt(this.JobTitle.designationform.controls.report_To.value));
      designationForm.get("additionalReport_To").setValue(parseInt(this.JobTitle.designationform.controls.additionalReport_To.value));
      designationForm.get("zone_Id").setValue(parseInt(this.JobTitle.designationform.controls.zone_Id.value));
      //console.log(parseInt(this.designationform.controls.report_To.value));
      designationForm.get("isActive").setValue(designationForm.get("isActive").value ? 1 : 0);
      designationForm.get("desig_Name").setValue(designationForm.get("desig_Name").value);

      designationForm.get("mandatory_RAF_Approval").setValue(designationForm.get("mandatory_RAF_Approval").value ? 1 : 0);
      this.commonApiService.Upsert(Commonvariable.Designation, designationForm.value, dId).subscribe((response) => {
        if (response["message"] == Commonvariable.DuplicationMsg) {
          this.commonService.toaster("warning", 'Record is already exists !', 'Job Title')
        }
        else {
          $("#draggableJobTitle").modal('hide');

          let vacancyForm = this.vacancyForm.value

          if (vacancyForm.Company_Id && vacancyForm.Dept_Id) {
            this.getDesignationList(vacancyForm.Company_Id, vacancyForm.Dept_Id)
          }
          // else {
          //  this.getDesignationList(designationForm.get("company_Id").value, designationForm.get("dept_Id").value)
          //}


          //this.getDesignationList(1);
          //this.router.navigateByUrl('designation');          

          this.commonService.toaster("success", dId > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Job Title')
        }
      })



    }
  }
  openModel() {
    $('#draggable').modal({
      backdrop: 'static'
    });
    //console.log($(".siteSelectListClass option:selected").val())
    this.vacancyForm.controls.Company_Id.setValue($(".companySelectListClass option:selected").val());
    this.vacancyForm.controls.Dept_Id.setValue($(".departmentSelectListClass option:selected").val());
    this.vacancyForm.controls.Desig_Id.setValue($("#Desig_Id option:selected").val());
    this.vacancyForm.controls.JD_Id.setValue($(".jdSelectListClass option:selected").val());
    this.JDescription.parentComponent(this.vacancyForm.value)

  }


  jobDescriptionUpsert() {
    var JDForm = this.JDescription.jDescriptionform;
    this.vacancyForm.controls.Site_Id.setValue($(".siteSelectListClass option:selected").val());
    if (this.JDescription.validation()) {
      JDForm.get("isActive").setValue(JDForm.get("isActive").value ? 1 : 0);
      JDForm.get("jD_Name").setValue(JDForm.get("jD_Name").value);

      this.commonApiService.Upsert(Commonvariable.JobDescription, JDForm.value, JDForm.controls.jD_Id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.commonService.toaster("warning", 'Record is already exists !', 'Job Description')
          }
          else {
            if (this.JDescription.jdDocumentChange) {
              const formData = new FormData();
              formData.append('Jd_Id', response["data"]["jD_Id"]);
              formData.append('file', this.JDescription.jdDocument);
              this.commonApiService.UploadLogo(Commonvariable.JobDescription, "AddDocument", formData).subscribe((response) => {
                $("#draggable").modal('hide');
                this.commonService.toaster("success", JDForm.controls.jD_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Job Description')

                let vacancyForm = this.vacancyForm.value
                if (this.vacancyForm.value.Company_Id && this.vacancyForm.value.Dept_Id && this.vacancyForm.value.Site_Id && this.vacancyForm.value.Desig_Id){
                  this.getJobTitleList(this.vacancyForm.value.Company_Id, this.vacancyForm.value.Dept_Id, this.vacancyForm.value.Site_Id, this.vacancyForm.value.Desig_Id)
                }

              });
            }
            else {
              
              $("#draggable").modal('hide');
              this.commonService.toaster("success", JDForm.controls.jD_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Job Description')
              let vacancyForm = this.vacancyForm.value
              console.log(vacancyForm)
              console.log($(".siteSelectListClass").val())
              if (this.vacancyForm.value.Company_Id && this.vacancyForm.value.Dept_Id && this.vacancyForm.value.Site_Id && this.vacancyForm.value.Desig_Id){
                this.getJobTitleList(this.vacancyForm.value.Company_Id, this.vacancyForm.value.Dept_Id, this.vacancyForm.value.Site_Id, this.vacancyForm.value.Desig_Id)
              }
            }
            //this.vacancyForm.controls.JD_Id.setValue(response["data"]["jD_Id"])
          }
        }
      })
    }

  }

  getSkills(id) {
    $("#skills_tags").tagsinput('removeAll')
    if (id > 0) {

      this.commonApiService.getById(Commonvariable.JobDescription, id, "GetSkill").subscribe((result) => {
        if (result["data"] != "") {
          $("#skills_tags").tagsinput('add', String(result["data"]));
        }
      })
    }
  }

  download() {

    if (parseInt(this.vacancyForm.controls.JD_Id.value) > 0) {
      var fileName = String(this.jobTitleList.filter(x => x.jD_Id == this.vacancyForm.controls.JD_Id.value)[0]["path"]).split(".")[1]
      //this.JDescription.download(fileName == undefined ? ".pdf" : fileName, parseInt(this.vacancyForm.controls.JD_Id.value));
      this.commonApiService.getimage(Commonvariable.JobDescription, parseInt(this.vacancyForm.controls.JD_Id.value), "DownloadDocument").subscribe((resp: any) => {

        if (resp.type != "application/json") {
          const blobData = new Blob([resp], { type: resp.type });
          var createObjectURL = (window.URL.createObjectURL(blobData));
  
          if (resp.type == "application/pdf" || resp.type == "image/jpeg" || resp.type == "image/png") {
            this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
            $("#modalJobDescription").modal("show")
          } else if (resp.type == "application/vnd.ms-word") {
            const a = document.createElement('a');
            a.setAttribute('type', 'hidden');
            a.href = URL.createObjectURL(blobData);
            a.download = 'Job_Description.docx';
            a.click();
            a.remove();
          } else {
  
            const a = document.createElement('a');
            a.setAttribute('type', 'hidden');
            a.href = URL.createObjectURL(blobData);
            a.download = 'Job_Description';
            a.click();
            a.remove();
  
          }
        }else{
          this.commonService.toaster("warning", 'Document Not Available', 'Job Description')
        }
        
      });
    }
    else{
      this.commonService.toaster("warning", 'Not selected.', 'Job Description')
    }
  }
  // history() {
  //   this.historyView.getHistoryList("\"zone\"");
  // }
  expandTable(id) {
    this.vacancyChildData = [];
    var visibility = $(".sub_tbl_" + id).toggle().is(":visible");
    $(".sub_tbl").hide();
    $(".details-control").css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    if (visibility === true) {
      $(".sub_tbl_" + id).show();
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/d4ICC.png) no-repeat center center");
      // this.vacancyChildData = this.tempvacancyRuleList.filter(x => x.vacancy_Rule_Id == id)[0]["childData"]
      this.getRuleDetails(id)

    } else {
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    }
  }

  selectRule(id) {
    this.vacancyRuleList = []
    var data = {
      "searchBy": [
        {
          "FieldName": 'vacancy_Rule_Id',
          "FieldValue": id,
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": 'isActive',
          "FieldValue": 1,
          "Parameter": "=="
        }
      ],
      "OrderBy": 'Priority'
    }
    this.commonApiService.getPaginated(Commonvariable.Vacancy_Rule_Detail, null, null, null, null, null, data).subscribe((result) => {
      if (result["data"] != null && result["data"] != "" && result["status"] == 200) {
        this.vacancyRuleList = result["data"]["list"];
        this.vacancyRuleListLength = Math.floor(12 / this.vacancyRuleList.length);
        this.vacancyForm.controls.Vacancy_Rule_Id.setValue(this.vacancyRuleList[0]["vacancy_Rule_Id"])
        $('#selectRule').modal("hide")

      }
    });




  }



  getRuleDetails(ids) {

    var data = {
      "searchBy": [
        {
          "FieldName": 'vacancy_Rule_Id',
          "FieldValue": ids,
          "Parameter": "==",
          "ConditionWith": "&&"
        },
        {
          "FieldName": 'isActive',
          "FieldValue": 1,
          "Parameter": "=="
        }
      ],
      "OrderBy": 'Priority'
    }
    this.commonApiService.getPaginated(Commonvariable.Vacancy_Rule_Detail, null, null, null, null, null, data).subscribe((result) => {
      if (result["data"] != null && result["data"] != "" && result["status"] == 200) {
        this.vacancyChildData = result["data"]["list"];
      }
    });
  }


  setFormData(fieldName, id) {

    if (id != null) {
      if (fieldName != undefined) {
        this.vacancyForm.controls[fieldName].setValue(id == "" ? null : id)
      }
    }
    else {
      this.vacancyForm.controls[fieldName].setValue(null)
    }
  }

  fillContractValue(flag:string) {
    // && this.vacancyForm.controls.Id.value == 0
    if (this.vacancyForm.controls.Contract_Id.value != null) {      
      if (this.tempcontractList != undefined) {
        let contract = this.tempcontractList.filter(x => x.contract_Id == this.vacancyForm.controls.Contract_Id.value)
        if(contract.length > 0){
          if(flag == 'Change'){            
            this.vacancyForm.controls.Contract_HoursDaily.setValue(contract[0]["contract_HoursDaily"]);
            $(".wd").prop("checked", false)
            if (contract[0]["contract_Week"] != null) {
              var workingDays = String(contract[0]["contract_Week"]).split('~')
              workingDays.forEach(x => {
                $("[value=" + x + "]").prop("checked", true)
              });
            }
          }          
        }
      }
    }
  }

  history() {
    this.historyView.getHistory("vacancy", this.vacancyForm.controls.Id.value)
  }

  selectRequesterType() {
    if (this.vacancyForm.value.isRequester == '1') {

      setTimeout(() => {
        this.vacancyForm.controls.RequestedBy.setValue(null);  
      });
      
      this.getEmpListForRequester();
    }
    else {
      this.vacancyForm.controls.RequestedBy.setValue(String(this.requester));
    }

  }
  onChangeDate() {
      var selectedDateSubmition = moment($("#date_Submition").val(), 'DD-MM-YYYY').format("DD-MM-YYYY");
  }




 genrateRefNumber(companyId) {
  
    if (companyId > 0) {
       this.commonApiService.getById(Commonvariable.VacancyService, companyId, "GEtReferencenumbr").subscribe((result) => {
          if (result["data"] != "") {
             this.vacancyForm.controls.reference_Number.setValue(result["data"])
          }
       })
    }
 }


 getjobtitledata(){
  
  this.commonApiService.JobTitleDropDownList(Commonvariable.Designation).subscribe((response) => {
    if (response["data"] != "") {
      
      this.AlljobTitleList = response["data"];
      console.log(this.AlljobTitleList)
      //this.getSiteList(cid)        
    }
  })
 }

 getAllEmpList(cid = null, sid = null) {
  

  this.allemployeeList = [];
  this.commonApiService.List(Commonvariable.EmployeeDetail, "GetAllEmployee").subscribe((response) => {
    if (response["data"] != "") {
      this.allemployeeList = response["data"]
      console.log(this.allemployeeList);
    }
  })
}





  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

}
