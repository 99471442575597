import { Component, OnInit, asNativeElements } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service'
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-resetpassword',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;

  data: any;
  login_Id: string = "input100 has-val";
  newPassword: string = "input100";
  confirmPassword: string = "input100";

  constructor(
    private commonService: CommonService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private fb: FormBuilder) { }

  ngOnInit() {

    $("app-left-menu,app-header,app-footer").hide();
    this.linkVerify();

    this.resetPasswordForm = this.fb.group({
      // login_Id: ['', Validators.required],
      // newPassword: ['', Validators.required,Validators.minLength(8)],
      // confirmPassword: ['', Validators.required,Validators.minLength(8)],
      login_Id: ['', [Validators.required, Validators.maxLength(100)]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    });

  }

  linkVerify() {
    this.route.queryParams.subscribe(params => {

      if (params.Login_Id != undefined && params.Token_No != undefined) {
        this.data = {
          "login_Id": params.Login_Id,
          "token_No": params.Token_No,
          "password": ""
        }
        this.loginService.verifyLink(this.data).subscribe((result) => {
          if (result["status"] == 200) {
            this.resetPasswordForm.controls.login_Id.setValue(params.Login_Id);
            this.resetPasswordForm.controls.login_Id.disable();
          }
        }, (error) => { this.toastr.error("Link is expired !", 'Reset Password'); this.router.navigateByUrl('home'); })
      }
      else {

        this.router.navigateByUrl('home');

      }
    })

  }

  resetPassword() {
    if (this.validation()) {
      this.loginService.resetPassword(this.data).subscribe((result) => {
        if (result["status"] == 200) {
          this.toastr.success("Reset password successfully !", 'Reset Password');
          this.router.navigateByUrl('home');
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Reset Password'); })
    }
  }

  valueChange(Fieldname) {
    if ($("#" + Fieldname).val() != "") {
      this[Fieldname] = "input100 has-val";
    }
    else {
      this[Fieldname] = "input100";
    }
  }

  validation() {
    var resetPasswordForm = this.resetPasswordForm;
    if (!resetPasswordForm.valid) {
      for (const key of Object.keys(resetPasswordForm.controls)) {
        if (resetPasswordForm.controls[key].invalid) {
          let Msg = "";
          if (key == "login_Id") {
            Msg = "UserName is required !"
          }
          else if (key == "newPassword") {
            //Msg = "New Password is required !"
            if (this.resetPasswordForm.controls[key].errors.required) {
              Msg = "New Password is required !"
            }
            // else if (loginForm.controls[key].errors.maxlength) {
            //   Msg = "Password not more then 15 character !"
            // }
            else if (this.resetPasswordForm.controls[key].errors.minlength) {
              Msg = "New Password enter must be 8 character !"
            }
          }
          else if (key == "confirmPassword") {
            Msg = "Confirm Password is required !"
          }
          this.toastr.info(Msg, "Reset Password");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if (resetPasswordForm.controls.confirmPassword.value != resetPasswordForm.controls.newPassword.value) {
        this.toastr.info("New password and confirmation password do not match !", "Reset Password");
        $("#confirmPassword").focus();
        return false
      }
      this.data.password = $("#newPassword").val();
    }
    return true;
  }

}
