import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;

@Component({
   selector: 'app-empbankinfo',
   templateUrl: './employee-bank-info.component.html',
})
export class EmployeeBankInfoComponent implements OnInit {


   empBankInfoform: FormGroup;
   items: FormArray;
   companyDoc: any;
   constructor(
      private toastr: ToastrService,
      private commonService: CommonService,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {
      this.empBankInfoform = this.fb.group({
         emp_BankInfo: this.fb.array([this.createItem(null)]),
      });

      //$("select").select2();
   }

   createItem(data) {
      return this.fb.group({
         Emp_Bank_Id: data != null ? data.Emp_Bank_Id : '0',
         Emp_Id: data != null ? data.Emp_Id : '0',
         Bank_Code: data != null ? data.Bank_Code : '',
         Bank_Name: data != null ? data.Bank_Name : '',
         Account_Title: data != null ? data.Account_Title : '0',
         Account_No: data != null ? data.Account_No : null,
         Account_Holder: data != null ? data.Account_Holder : '',
         // Account_Type: data != null ? data.Account_Type : '',
         Account_Code: data != null ? data.Account_Code : null,
         isPayed: data != null ? data.isPayed : '0',
         Emp_Doc_Id: data != null ? data.Emp_Doc_Id : '0',
         isRequired: data != null ? data.isRequired : '0',
         Notes: data != null ? data.Notes : '',
         Version_Id: data != null ? data.Version_Id : '0',
         isActive: data != null ? data.isActive == 1 ? true : false : true,
         AddedBy: Commonvariable.User_Id,
         NiNo: [null],
         Loan: [null],
      });
   }

   addItem(data) {
      if(this.validation())
      {
         this.items = this.empBankInfoform.get('emp_BankInfo') as FormArray;
         this.items.push(this.createItem(data));
      }
   }

   removeItem(index) {
      this.items = this.empBankInfoform.get('emp_BankInfo') as FormArray;
      if (this.items.value[index].company_Contact_Id > 0) {
         this.commonService.deleteConfirm().then((result) => {
         })
      }
      else {
         this.items.removeAt(index);
      }
   }


   empUpsert() {
      var empbankInfo = this.empBankInfoform.get('emp_BankInfo').value;      
      if (empbankInfo[0].Bank_Name == "") {
         return false;
      }
      return true;
   }

   validation() {
      var empbankInfo = this.empBankInfoform.get('emp_BankInfo').value;
      var tempArray = [];
      for (let index = 0; index < empbankInfo.length; index++) {
         if (empbankInfo[0].Bank_Name != "" || empbankInfo[0].Account_Holder != "" || (empbankInfo[0].Account_No != "0" && empbankInfo[0].Account_No != null) ||empbankInfo[0].Bank_Code != "") {
            if (empbankInfo[index].Bank_Name == "") {
               this.toastr.info("Bank Name is required !", "Employee");
               $("#Bank_Name" + index).focus();
               return false;
            }
            if ($("#Bank_Name" + index).val().search(/^[^-\s][a-zA-Z0-9_\s-]+$/) != 0) {
               this.toastr.info("Bank Name not allow space !", "Employee");
               $("#Bank_Name" + index).focus();
               return false;
            }
            if ($("#Bank_Name" + index).val().length < 2) {
               this.toastr.info("Bank Name must be at least 2 characters long !", "Employee");
               $("#Bank_Name" + index).focus();
               return false;
            }
            if ($("#Bank_Name" + index).val().search(/[^a-zA-Z\s]+/) > -1) {
               this.toastr.info("Bank Name only alphabets allow !", "Employee");
               $("#Bank_Name" + index).focus();
               return false;
            }
            if (empbankInfo[index].Account_Holder == "") {
               this.toastr.info("Account holder is required !", "Employee");
               $("#Account_Holder" + index).focus();
               return false;
            }
            //comment on 28/12/2022 by Amit Prajapati
            // if ($("#Account_Holder" + index).val().search(/^[^-\s][a-zA-Z0-9_\s-]+$/) != 0) {
            //    this.toastr.info("Account Holder not allow space !", "Employee");
            //    $("#Account_Holder" + index).focus();
            //    return false;
            // }
            if ($("#Account_Holder" + index).val().length < 2) {
               this.toastr.info("Account holder must be at least 2 characters long !", "Employee");
               $("#Account_Holder" + index).focus();
               return false;
            }
            if ($("#Account_Holder" + index).val().search(/[^a-zA-Z\s]+/) > -1) {
               this.toastr.info("Account holder only alphabets allow !", "Employee");
               $("#Account_Holder" + index).focus();
               return false;
            }

            // if (empbankInfo[index].Account_Type == "") {
            //    this.toastr.info("Account type is required !", "Employee");
            //    $("#Account_Type" + index).focus();
            //    return false;
            // }
            // if ($("#Account_Type" + index).val().length < 2) {
            //    this.toastr.info("Account type must be at least 2 characters long !", "Employee");
            //    $("#Account_Type" + index).focus();
            //    return false;
            // }
            // if($("#Account_Type" + index).val().indexOf(" ") > -1)
            // {
            //    this.toastr.info("Account type not allow space !", "Employee");
            //    $("#Account_Type" + index).focus();
            //    return false;
            // }
            if (empbankInfo[index].Account_No == "") {
               this.toastr.info("Account number is required !", "Employee");
               $("#Account_No" + index).focus();
               return false;
            }
            if ($("#Account_No" + index).val().length < 5 || $("#Account_No" + index).val().length > 20) {
               this.toastr.info("Account number between 5 to 20 digit long !", "Employee");
               $("#Account_No" + index).focus();
               return false;
            }
            if ($("#Account_No" + index).val().search(/[^0-9]+/) > -1) {
               this.toastr.info("Account number only numeric value allow !", "Employee");
               $("#Account_No" + index).focus();
               return false;
            }

            // if ($("#Bank_Code" + index).val().search(/^[a-zA-Z0-9 -]+$/) != 0) {
            //    this.toastr.info("Please enter valid sort code !", "Employee");
            //    $("#Bank_Code" + index).focus();
            //    return false;
            // }

            if (empbankInfo[index].Bank_Code == "") {
               this.toastr.info("Sort code is required !", "Employee");
               $("#Bank_Code" + index).focus();
               return false;
            }
            // if ($("#Bank_Code" + index).val().length < 6) {
            //    this.toastr.info("Sort code must be at least 6 characters long !", "Employee");
            //    $("#Bank_Code" + index).focus();
            //    return false;
            // }
            // if ($("#Bank_Code" + index).val().length > 6) {
            //    this.toastr.info("Sort code not more than 6 characters !", "Employee");
            //    $("#Bank_Code" + index).focus();
            //    return false;
            // }
            // if ($("#Bank_Code" + index).val().indexOf(" ") > -1) {
            //    this.toastr.info("Sort code not allow space !", "Employee");
            //    $("#Bank_Code" + index).focus();
            //    return false;
            // }


            if(tempArray.length > 0 )
            {
               if(tempArray.filter(x=>x == String(empbankInfo[index].Account_No).trim()).length > 0)
               {
                  this.toastr.info("This value already entered !", "Employee");
                  $("#Account_No" + index).focus();
                  return false
               }
            }
            tempArray.push(String(empbankInfo[index].Account_No).trim())
         }
         empbankInfo[index].isActive = empbankInfo[index].isActive ? 1 : 0
      }
      return true;
   }



}


