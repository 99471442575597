import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { ScheduleInterviewComponent } from "../scheduleInterview/scheduleInterview.component";
import { InterviewFeedbackComponent } from "../interviewFeedback/interviewFeedback.component";
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { id, tr } from 'date-fns/locale';

import { SendMailComponent } from '../../sendmail/sendmail.component';
declare var $: any;


@Component({
  selector: 'app-selection-raf-wise',
  templateUrl: './selection-raf-wise.component.html'
})
export class SelectionRafWiseComponent implements OnInit {
  @ViewChild(ScheduleInterviewComponent, { static: false }) ScheduleInterview: ScheduleInterviewComponent;
  @ViewChild(InterviewFeedbackComponent, { static: false }) InterviewFeedback: InterviewFeedbackComponent;
  @ViewChild(SendMailComponent, { static: false }) MailSend: SendMailComponent;
  isFinal: boolean = false;
  p: number = 1;
  m: number = 1;
  s: number = 1;
  totalRecord: 0;
  rafWiseList: any[];
  reportingEmpList: any;
  candidateList: any[];
  filterCandidateList: any[];
  fileUrl: any;
  filename: string = "Job_Description.pdf";
  raf_Id: 0;
  candidate_Id = 0;
  vacancy_Id = 0;
  company_Id: any;
  files: string;
  rafwiseCandidate: any[];
  file: any;
  salaryPlaceHolder: any;
  jd_Name: string;
  salaryType = ""
  currency_Code = "";
  candidateVacancyList: any[] = []

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.getRAFWiseList(1)
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);
  }

  getRAFWiseList(PageIndex) {

    this.totalRecord = 0;
    this.p = PageIndex;
    this.commonApiService.getListWithFilter(Commonvariable.VacancyService, PageIndex, $("#filterList").val(), "RAFWiseGetPaginated").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.rafWiseList = result["data"]["list"];
      }
    })

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }

  openModel(id) {
    this.m = 1;
    this.raf_Id = id;
    var requestData = {
      "searchBy": [
        {
          "FieldName": "vacancy_Id",
          "FieldValue": this.raf_Id,
          "Parameter": "=="
        }
      ],
    }
    this.commonApiService.getPaginated(Commonvariable.CandidateService, null, null, null, null, 0, requestData, "CadidateWiseSelectedGetPaginated").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.candidateList = result["data"]["list"];
        this.candidateList.forEach(x => {
          x.job_Category = x.job_Category != null ? x.job_Category == "null" ? '' : x.job_Category.replace(/[~]+/g, ", ") : x.job_Category
          x.skills = x.skills != null ? x.skills == "null" ? '' : x.skills.replace(/[~]+/g, ", ") : x.skills
          x.job_Level = x.job_Level != null ? x.job_Level == "null" ? '' : x.job_Level.replace(/[~]+/g, ", ") : x.job_Level
        });
        this.filterCandidateList = this.candidateList;
        this.ngOnInit();
      }
    })
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

    $("#filterListRafWise").val("")
    $('#draggable').modal({
      backdrop: 'static'
    });


  }

  downloadJDDocument(id, path) {
    this.filename = "Job_Description" + "." + String(path).split('.')[1]
    this.commonApiService.getimage(Commonvariable.JobDescription, id, 'DownloadDocument').subscribe((result) => {
      const blobData = new Blob([result], { type: 'application/octet-stream' });
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blobData));
      setTimeout(() => {
        $("#download")[0].click();
      }, 500);
    })
  }


  filterData(event) {
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

    const val = event.target.value.toLowerCase().trim();
    if (val == "") {
      this.candidateList = this.filterCandidateList;
      //this.ngOnInit();
    }
    else {
      const temp = this.filterCandidateList.filter(function (d) {
        return String(d["firstName"]).toLowerCase().indexOf(val) !== -1 || String(d["lastName"]).toLowerCase().indexOf(val) !== -1 || String(d["firstName"] + " " + d["lastName"]).toLowerCase().indexOf(val) !== -1
          || String(d["job_Category"]).toLowerCase().indexOf(val) !== -1 || String(d["job_Level"]).toLowerCase().indexOf(val) !== -1
          || String(d["skills"]).toLowerCase().indexOf(val) !== -1 || String(d["experience"]).toLowerCase().indexOf(val) !== -1
          || !val;
      });
      this.candidateList = temp;
      this.m = 1;
      //this.ngOnInit();
    }
    this.p = 1;
  }


  updateSelectRAF(id) {
    this.candidateList[this.candidateList.findIndex(x => x.id == id)].selectedRaf = $("#rafCandidate" + id).prop("checked") ? 1 : 0
    this.filterCandidateList[this.filterCandidateList.findIndex(x => x.id == id)].selectedRaf = $("#rafCandidate" + id).prop("checked") ? 1 : 0
  }

  Addrecord() {

    let postRecord = {
      "Candidate_Id": this.filterCandidateList.filter(x => x.selectedRaf == 1).map(x => x.id),
      "Vacancy_Id": this.raf_Id,
      "AddedBy": Commonvariable.User_Id,
    }

    this.commonApiService.getPaginated(Commonvariable.Candidate_Vacancy_Link, null, null, null, null, 0, postRecord, "Insert_MultipleCandidate").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.getRAFWiseList(1)
        this.commonService.toaster("success", Commonvariable.SaveMsg, "Raf-wise");
        $('#draggable').modal("hide")
      }
    })

  }


  getRAFWiseCandidate(id) {
    var requestData = {
      "searchBy": [
        {
          "FieldName": "vacancy_Id",
          "FieldValue": id,
          "Parameter": "=="
        }
      ],
    }
    this.commonApiService.getPaginated(Commonvariable.Candidate_Vacancy_Link, null, null, null, null, 0, requestData, "GetCandidateVacancyLinkedData").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.rafwiseCandidate = result["data"]
      }
    })


  }


  expandTable(id, company_Id) {
    this.company_Id = company_Id;
    this.rafwiseCandidate = [];
    var visibility = $(".sub_tbl_" + id).toggle().is(":visible");
    $(".sub_tbl").hide();
    $(".details-control").css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    if (visibility === true) {
      $(".sub_tbl_" + id).show();
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/d4ICC.png) no-repeat center center");
      this.getRAFWiseCandidate(id);
      this.s = 1
    } else {
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    }

  }

  async downloadCV(id) {
    this.commonApiService.getimage(Commonvariable.JobSeeker, id, 'DownloadCV').subscribe((result) => {
      const blobData = new Blob([result], { type: 'application/octet-stream' });
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blobData));
      setTimeout(() => {
        $("#download")[0].click();
      }, 500);
    })
  }

  async UpdateStatus(status, candidate_Id = 0, vacancy_Id = 0) {

    this.candidate_Id = candidate_Id != 0 ? candidate_Id : this.candidate_Id;
    this.vacancy_Id = vacancy_Id != 0 ? vacancy_Id : this.vacancy_Id;

    if (status == "Offered") {
      this.commonApiService.getById(Commonvariable.VacancyService, vacancy_Id, "OpenVacancyCount").subscribe((result) => {
        if (result["data"] > 0) {
          this.commonService.dyanmicConfirm("You Want To Change Status Of Candidate !", "Yes !").then((result) => {
            if (result.value) {
              let ids = this.candidate_Id + "," + this.vacancy_Id + "," + status
              this.commonApiService.Put(Commonvariable.Candidate_Vacancy_Link, ids, "ChangeStatus").subscribe((response) => {
                if (response != null) {
                  this.getRAFWiseList(1)
                  this.commonService.toaster("success", response["message"], "Interview")
                }
              })
            }
          })
        }
        else {
          this.commonService.toaster("info", "All open vacancies have been filled !", "Interview")
        }
      })
    }
    else {
      this.commonService.dyanmicConfirm("You Want To Change Status Of Candidate !", "Yes !").then((result) => {
        if (result.value) {
          let ids = this.candidate_Id + "," + this.vacancy_Id + "," + status
          this.commonApiService.Put(Commonvariable.Candidate_Vacancy_Link, ids, "ChangeStatus").subscribe((response) => {
            if (response != null) {
              this.getRAFWiseList(1)
              this.commonService.toaster("success", response["message"], "Interview")
            }
          })
        }
      })
    }


  }

  async moveToOnboarding(candidate_Id, vacancy_Id) {

    if (candidate_Id > 0) {
      this.commonService.dyanmicConfirm("You Want This Candidate Move To Onboarding !", "Yes !").then((result) => {
        if (result.value) {
          this.commonApiService.PostRequest(Commonvariable.CandidateService, "MoveToOnboarding/" + candidate_Id + "," + vacancy_Id, {}).subscribe((response) => {
            if (response != null) {
              this.getRAFWiseList(1)
              this.commonService.toaster("success", response["message"], "Interview")
            }
          })
        }
      })
    }

  }

  openInterviewModel(Id, vId) {
    this.candidate_Id = Id;
    this.vacancy_Id = vId;
    if (this.company_Id > 0) {
      this.ScheduleInterview.getEmpList(this.company_Id)
      this.getInterviews();
      $('#draggable1').modal({
        backdrop: 'static'
      });
    }
    else {
      this.commonService.toaster("success", "Company Not Available", "Interview")
    }

  }

  async UpsertInterviewDetail(status) {
    var requestData = this.ScheduleInterview.upsert(this.candidate_Id, this.vacancy_Id, status)
    if (requestData != null) {
      await this.commonApiService.Upsert("Candidate_Interviewer", requestData, this.candidate_Id, '/EditMultiple').subscribe((result) => {
        if (result != null) {
          this.GetMail();
          $("#draggable1").modal('hide');

          this.commonService.toaster("success", Commonvariable.SaveMsg, "Interview")
          this.getRAFWiseList(1)
        }
      })
    }

  }

  async UpsertInterviewDetailForFeedback(status) {
    var requestData: any;
    if (status == 'Selected') {
      if ($("#rafWiseSalary").val() == "" || $("#rafWiseSalary").val() == undefined) {
        this.commonService.toaster("info", "Salary is required !", "Interview Feedback")
        $("#rafWiseSalary").focus();
        return false;
      }
      // if ($("#rafWiseSalaryType").val() == "" || $("#rafWiseSalaryType").val() == undefined) {
      //   this.commonService.toaster("info", "SalaryType is required !", "Interview Feedback")
      //   $("#rafWiseSalaryType").focus();
      //   return false;
      // }
      if ($("#rafWiseReporting_Id").val() == "" || $("#rafWiseReporting_Id").val() == undefined) {
        this.commonService.toaster("info", "Reporting is required !", "Interview Feedback")
        $("#rafWiseReporting_Id").focus();
        return false;
      }
      if ($("#rafWiseJoiningDate").val() == "" || $("#rafWiseJoiningDate").val() == undefined) {
        this.commonService.toaster("info", "Joining date is required !", "Interview Feedback")
        $("#rafWiseJoiningDate").focus();
        return false;
      }
      requestData = this.InterviewFeedback.upsert(this.candidate_Id, this.vacancy_Id, status, $("#rafWiseSalary").val(), $("#rafWiseReporting_Id").val(), this.commonService.dateFormatChange($("#rafWiseJoiningDate").val()))
    }
    else {
      requestData = this.InterviewFeedback.upsert(this.candidate_Id, this.vacancy_Id, status)
    }

    if (requestData != null) {
      await this.commonApiService.Upsert("Candidate_Interviewer", requestData, this.candidate_Id, '/EditMultiple').subscribe((result) => {
        if (result != null) {
          $("#draggable2").modal('hide');
          this.commonService.toaster("success", Commonvariable.SaveMsg, "Interview")
          this.getRAFWiseList(1)
        }
      })
    }

  }

  getInterviews() {
    this.ScheduleInterview.ngOnInit()
    if (this.candidate_Id > 0) {
      this.commonApiService.getById(Commonvariable.Candidate_Interviewer, this.candidate_Id + "," + this.vacancy_Id, "GetInterviewSchedule").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.ScheduleInterview.fillInterviewer(result["data"])
        }
      })
    }
  }
  getInterviewsForFeedback() {
    if (this.candidate_Id > 0) {
      this.commonApiService.getById(Commonvariable.Candidate_Interviewer, this.candidate_Id + "," + this.vacancy_Id, "GetInterviewSchedule").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.InterviewFeedback.fillInterviewer(result["data"]);
          this.salaryPlaceHolder = result["data"][0][0]["salaryFrom"] + " - " + result["data"][0][0]["salaryTo"];
          this.currency_Code =  result["data"][0][0]["currency_Code"] ;
        }

      })
    }
  }
  openInterviewFeedbackModel(Id, vId, dept_Id, desig_Id) {

    $(".isFinalBody").hide();

    this.isFinal = false;
    this.candidate_Id = Id;
    this.vacancy_Id = vId;
    if (this.company_Id > 0) {
      this.InterviewFeedback.getEmpList(this.company_Id)

      let reportingParameter = "Reporting" + "," + this.company_Id + "," + dept_Id + "," + desig_Id + "," + 0
      this.commonApiService.getById(Commonvariable.EmployeeDetail, reportingParameter, "GetEmployeeByAccessibility").subscribe((result) => {
        if (result != null && result["data"] != "") {
          this.reportingEmpList = result["data"];
        }
      })

      this.getInterviewsForFeedback();
      $('#draggable2').modal({
        backdrop: 'static'
      });

    }
    else {
      this.commonService.toaster("info", "Company Not Available", "Interview")
    }
  }

  showIsFinal() {
    this.isFinal = !this.isFinal;

    setTimeout(() => {
      this.commonService.flatpickr('rafWiseJoiningDates', 0)
    }, 8);
  }


  GetMail(candidate_Id = 0, Vacancy_Id = 0) {
    if (candidate_Id > 0) {
      this.candidate_Id = candidate_Id;
    }
    if (Vacancy_Id > 0) {
      this.vacancy_Id = Vacancy_Id;
    }

    this.commonApiService.getByIdTwoParam(Commonvariable.Candidate_Interviewer, this.candidate_Id, this.vacancy_Id, "GetSendMailData").subscribe((response) => {
      if (response["data"] != "") {
        this.MailSend.documentName = "";
        this.MailSend.docFiles = [];
        this.MailSend.roleIdValue = null
        this.company_Id = response["data"]["company_Id"];
        this.files = response["data"]["jD_Path"] == null || response["data"]["jD_Path"] == "" ? '' : response["data"]["jD_Path"];
        this.jd_Name = response["data"]["jD_Name"]
        $('#SendMail').modal({
          backdrop: 'static'
        });

        setTimeout(() => {
          $("#SendMail #to").val(response["data"]["to"])
          $("#SendMail #cc").val(response["data"]["cc"])
          $("#SendMail #bcc").val(response["data"]["bcc"])
          $("#SendMail #subject").val(response["data"]["subject"])
          $('#SendMail #emailsend').summernote('code', response["data"]["body"]);
        }, 500);

      }
    })

  }

  sendMail() {
    const formData = new FormData();
    formData.append('To', $("#SendMail #to").val());
    formData.append('CC', $("#SendMail #cc").val());
    formData.append('BCC', $("#SendMail #bcc").val());
    formData.append('Subject', $("#SendMail #subject").val());
    formData.append('Role_Ids', this.MailSend.roleId != undefined ? this.MailSend.roleId : null);
    formData.append('Body', $('#SendMail #emailsend').summernote('code'));
    formData.append('Company_Id', this.company_Id);
    formData.append('JD_Path', this.files);
    formData.append('JD_Name', this.jd_Name);
    if (this.MailSend.docFiles != undefined) {
      for (var i = 0; i < this.MailSend.docFiles.length; i++) {
        formData.append('file', this.MailSend.docFiles[i]);
      }
    }
    this.commonApiService.UploadLogo(Commonvariable.Candidate_Interviewer, "SendMailWithFileObj", formData).subscribe((response) => {
      if (response["status"] == 200) {
        $("#SendMail").modal('hide');
        this.commonService.toaster("success", response["message"], "Interview")
      }
    })
    //     var postData = ({
    //       To: $("#to").val(),
    //       CC: $("#cc").val(),
    //       BCC: $("#bcc").val(),
    //       Subject: $("#subject").val(),
    //       Body: $('#emailsend').summernote('code'),
    //       fileString: this.files,
    //       fileObj :$('#emailsend').summernote('code'),
    //       Role_Ids: this.MailSend.roleId != "undefined" ? this.MailSend.roleId : null,
    //       Company_Id: this.company_Id
    //     });
    // for (var i = 0; i < this.MailSend.docFiles.length; i++) {
    //   //formData.append('file', this.MailSend.docFiles[i]);
    // }
    // this.commonApiService.PostRequest(Commonvariable.Candidate_Interviewer, "SendMail", postData).subscribe((response) => {
    //   if (response["status"] == 200) {
    //     $("#SendMail").modal('hide');
    //     this.commonService.toaster("success", response["message"], "Interview")
    //   }
    // })

    this.getRAFWiseList(1)
  }


  openJDDocument(id, path) {
    this.commonApiService.getimage(Commonvariable.JobDescription, id, 'DownloadDocument').subscribe((result) => {
      const blobData = new Blob([result], { type: result.type });
      var createObjectURL = (window.URL.createObjectURL(blobData));
      this.file = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
      if (result.type == "application/pdf" || result.type == "image/jpeg" || result.type == "image/png") {
        $('#selectionRafWiseOpenFile').modal({
          backdrop: 'static'
        });
      } else if (result.type == "application/vnd.ms-word") {
        const a = document.createElement('a');
        a.setAttribute('type', 'hidden');
        a.href = URL.createObjectURL(blobData);
        a.download = 'document.docx';
        a.click();
        a.remove();
      } else {

        const a = document.createElement('a');
        a.setAttribute('type', 'hidden');
        a.href = URL.createObjectURL(blobData);
        a.download = 'document';
        a.click();
        a.remove();

      }


    })

  }


  directOpenMoveToOnboarding(id, vid) {

    this.candidateVacancyList = [];

    this.candidate_Id = id;
    this.reportingEmpList = [];

    this.commonApiService.getById(Commonvariable.Candidate_Vacancy_Link, id, "GetCan_Vac_LinkByCandidate_Id").subscribe((res) => {
      if (res) {
        this.candidateVacancyList = res["data"];

        let item = this.candidateVacancyList.filter(x => x.vacancy_Id == vid)

        if (item.length > 0) {
          item = item[0];
          this.getReportingEmpList(item["company_Id"], item["dept_Id"], item["desig_Id"], item["salaryFrom"], item["salaryTo"], item["currency_Code"], item["salaryType"], item["vacancy_Id"])
        }


      }
    })
    this.currency_Code = "";
    this.salaryPlaceHolder = "0";
    this.salaryType = "";
    this.vacancy_Id = vid;

    $("#modalrafMovetoOnboarding").modal("show");
    this.commonService.flatpickr('rafSelectionJoiningDates', 0)
    $("#rafSelectionSalary").val("");
    $("#rafSelectionJoiningDate").val("");
    $("#rafSelectionReporting").val("");
  }


  getReportingEmpList(company_Id, dept_Id, desig_Id, salaryFrom, salaryTo, currency_Code, salaryType, vacancy_Id) {

    this.salaryPlaceHolder = salaryFrom + "-" + salaryTo;
    this.currency_Code = currency_Code;
    this.salaryType = salaryType;
    this.vacancy_Id = vacancy_Id;

    let reportingParameter = "Reporting" + "," + company_Id + "," + dept_Id + "," + desig_Id + "," + 0
    this.commonApiService.getById(Commonvariable.EmployeeDetail, reportingParameter, "GetEmployeeByAccessibility").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.reportingEmpList = result["data"];
      }
    })
  }


  directMoveToOnboarding() {

    if (this.validation()) {
      let postRequest = {
        "candidate_Id": this.candidate_Id,
        "vacancy_Id": this.vacancy_Id,
        "Salary": $("#rafSelectionSalary").val(),
        "SalaryType": this.salaryType,
        "Reporting_Id": $("#rafSelectionReporting").val(),
        "JoiningDate": this.commonService.dateFormatChange($("#rafSelectionJoiningDate").val())
      }

      this.commonApiService.PostRequest(Commonvariable.CandidateService, "DirectMoveToOnboarding", postRequest).subscribe((res) => {
        if (res) {
          this.commonService.toaster("success", "Move to onboarding successfully ", "Interview");
          this.getRAFWiseList(1)
          $(".modal").modal("hide");
        }
      })
    }

  }

  validation() {

    if (this.currency_Code == "" || this.salaryType == "" && this.vacancy_Id == 0) {
      this.commonService.toaster("info", "Select Vacancy ", "Interview");
      return false;
    }

    // if ($("#rafSelectionSalary").val() == "") {
    //   this.commonService.toaster("info", "Salary is required ", "Candidate");
    //   $("#rafSelectionSalary").focus();
    //   return false;
    // }

    // if ($("#rafSelectionReporting").val() == "") {
    //   this.commonService.toaster("info", "Reporting is required ", "Candidate");
    //   $("#rafSelectionReporting").focus();
    //   return false;
    // }

    // if ($("#rafSelectionJoiningDate").val() == "") {
    //   this.commonService.toaster("info", "Joining date is required ", "Candidate");
    //   $("#rafSelectionJoiningDate").focus();
    //   return false;
    // }

    return true;
  }


}
