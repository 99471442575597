<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Probation List
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->

        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a href="javascript:;" (click)="id=0;modalOpen =true" class="btn btn-transparent blue  btn-sm">Add
                            Probation</a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" placeholder="Type to filter ..." id="filterList"
                                (input)="getProbationList()">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblZone">
                            <thead class="flip-content">
                                <tr>

                                    <th  class="text-center"> Company </th>
                                    <th class="text-center"> Probation</th>                                    
                                    <th class="text-center"> Probation Duration </th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody
                                *ngFor="let item of probationlist| paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">
                                <tr>

                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(item.prob_Id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.prob_Id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12">
                                            {{item.company_Name}}</div>
                                    </td>
                                    <td>{{item.prob_Name}}</td>
                                    <td>{{item.prob_Duration}} {{item.prob_DurationUnit}}</td>                                    
                                    <td class="text-center">
                                        <a *ngIf="item.isActive" href="javascript:;"
                                            (click)="id=item.prob_Id;modalOpen=true"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Probation"></i></a>
                                        <a *ngIf="!item.isActive" href="javascript:;"
                                            (click)="ProbationDelete(item.prob_Id)">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Delete Probation"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center">
                        <pagination-controls (pageChange)="getProbationList($event)" autoHide="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->

<div *ngIf="modalOpen">
    <app-probation-form [pId]="id" (modalClose)="modalOpen = $event" (refreshPage)="getProbationList($event)"></app-probation-form>
</div>
