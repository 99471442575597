import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-site-form',
  templateUrl: './site-form.component.html'
})
export class SiteFormComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  public exampleData: Array<Select2OptionData>;
  siteform: FormGroup;
  sitelist: any;
  calllist: boolean = true;
  filterSite: any;
  companylist: any;
  title: string;
  Toast: any;
  checkValidation: boolean = false;
  d: number = 1;
  countrylist: any;
  totalRecord: 0;
  cSearch =new FormControl("");
  @Input() pId?: any;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshPage: EventEmitter<number> = new EventEmitter();


  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private fb: FormBuilder,
    private toastr : ToastrService) { }


    options = {
      componentRestrictions: {
        country: ["UK", "IND"]
      }
    }
  ngOnInit() {

    document.body.className = "modal-open";

    this.title = "Add";
    this.title = "Add";
    this.siteform = this.fb.group({
      site_Id: ['0'],
      company_Id: ['', Validators.required],
      company_Name: [''],
      site_Code: [''],
      site_Name: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      address3: [null],
      address4: [null],
      city: ['', Validators.required],
      state: [null],
      country_Id: ['', Validators.required],
      postCode: ['', Validators.required],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });


    this.getSiteList(1);
    this.getCompanyList();
    this.getCountryList();
    if (this.pId > 0) {
      this.openModel(this.pId);
    }


  }


  AddressChangeSite(address: any) {

    // var component = address.address_components;
    console.log(address)

    this.siteform.controls.address2.setValue("")
    this.siteform.controls.city.setValue("")
    this.siteform.controls.state.setValue("")
    this.siteform.controls.country_Id.setValue("")
    this.siteform.controls.postCode.setValue("")

    for (const component of address.address_components) {

      const componentType = component.types[0];
      var address1
      var address2
      var address2_1

      switch (componentType) {

        case "street_number": {
          address1 = component.long_name
          break;
        }

        case "route": {
          address2 = component.long_name
          break;
        }

        case "postal_town": {
          var city_1 = component.long_name
          break;
        }

        case "locality": {
          var city_2 = component.long_name
          break;
        }

        case "neighborhood": {
          address2_1 = component.long_name
          break;
        }

        case "administrative_area_level_1": {
          var state_1 = component.long_name
          break;
        }

        case "administrative_area_level_2": {
          var state_2 = component.long_name
          break;
        }

        case "country": {
          const found = this.countrylist.find((obj) => {
            return obj.text.toLowerCase() === component.long_name.toLowerCase();
          });
          this.siteform.controls.country_Id.setValue((found.id))
          // for (let i = 0; i < this.countrylist.length; i++) {
          //   if (this.countrylist[i].text == component.long_name) {
          //     //console.log(this.countrylist[i]);
          //     this.siteform.controls.country_Id.setValue((this.countrylist[i].id))
          //   }
          // }
          break;
        }

        case "postal_code": {
          this.siteform.controls.postCode.setValue(component.long_name)
          break;
        }
      }
    }

    if (address1) {
      this.siteform.controls.address1.setValue(address1)
    } else {
      this.siteform.controls.address1.setValue(address.name)
    }

    if (address2) {
      this.siteform.controls.address2.setValue(address2)
    } else {
      this.siteform.controls.address2.setValue(address2_1)
    }


    if (city_1) {
      this.siteform.controls.city.setValue(city_1)
    } else {
      this.siteform.controls.city.setValue(city_2)
    }

    if (state_1) {
      this.siteform.controls.state.setValue(state_1)
    } else {
      this.siteform.controls.state.setValue(state_2)
    }


  }
  getSiteList(PageIndex) {

    // ,colName=null,Ordering=null`
    //   var OrderBy
    //   if(Ordering != null){
    //      OrderBy = colName+" "+Ordering;
    //   }else{
    //      OrderBy = null;
    //   }    
    this.d = PageIndex;
    this.totalRecord = 0;
    this.sitelist = [];
    this.commonApiService.getListWithFilter(Commonvariable.Site, PageIndex, this.cSearch.value).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.sitelist = result["data"]["list"];
        this.filterSite = result["data"]["list"];
      }
    })

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }


  getCountryList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "country_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Country, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.countrylist = response["data"].map(x => ({
          id: x.id,
          text: x.country_Name
        }));
      }
    })
  }



  getCompanyList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "company_Name"
    }

    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companylist = response["data"];
        this.exampleData = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }

  get formControls(): any {
    return this.siteform['controls'];
  }


  siteUpsert() {
    var sForm = this.siteform;

    if (this.validation()) {
      sForm.get("site_Name").setValue(sForm.get("site_Name").value.trim());
      sForm.get("isActive").setValue(sForm.get("isActive").value ? 1 : 0);
      this.commonApiService.UpsertWithMultiCompany(Commonvariable.Site, sForm.value, sForm.controls.site_Id.value).subscribe((response) => {
        if (response != null && response["status"] == 200) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Site');
          }
          else {
            this.closeModal();
            // $("#draggable").modal('hide');
           
            this.refreshPage.emit(1);
            this.toastr.success(sForm.controls.site_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Site');
          }
        }
      })
    }

  }

 

  

  openModel(sId) {
    if (sId > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Site, sId).subscribe((response) => {
        if (response != null && response["status"] == 200) {
          var editData = response["data"];
          var formData = this.siteform;
          this.commonService.setEditData(formData, editData);
          formData.controls.updatedBy.setValue(Commonvariable.User_Id);
          this.siteform.patchValue({
            company_Id : editData[0].company_Id
          });

          // console.log(this.siteform.value());
          // $("#site_Code").prop("disabled", "disabled");
        }
      })
    }
    else {
      // $("#site_Code").removeAttr("disabled")
      this.calllist = false
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });


  }


  validation() {
    var siteForm = this.siteform;

    for (const key of Object.keys(siteForm.controls)) {

      if (siteForm.controls[key].value == "" && key != "addedBy" && key != "updatedBy") {
        siteForm.controls[key].setValue(null)
      }
      if (siteForm.controls[key].invalid) {
        let fieldName = "";
        if (key == "company_Id") {
          fieldName = "Company"
          $("#company_Id select").focus();
        }
        // else if (key == "site_Code") {
        //   fieldName = "Site code"
        // }
        else if (key == "site_Name") {
          fieldName = "Site name"
        }
        else if (key == "address1") {
          fieldName = "Address1"
        }
        else if (key == "address2") {
          fieldName = "Address2"
        }

        else if (key == "city") {
          fieldName = "City"
        }
        // else if (key == "state") {
        //   fieldName = "County"
        // }
        else if (key == "country_Id") {
          fieldName = "Country"
        }
        else if (key == "postCode") {
          fieldName = "Post Code"
        }
        this.toastr.info(fieldName + " is required !", "Site");
        $("#" + key).focus();
        return false;
      }
    }
    // if ($("#site_Code").val().indexOf(" ") > -1) {
    //   this.toastr.info("Site code not allow space !", "Site");
    //   $("#site_Code").focus();
    //   return false;
    // }
    // if ($("#site_Code").val().length < 2) {
    //   this.toastr.info("Site code must be at least 2 characters long !", "Site");
    //   $("#site_Code").focus();
    //   return false;
    // }
    // if ($("#site_Code").val().search(/[^a-zA-Z0-9]+/) > -1) {
    //   this.toastr.info("Site code only alphabets and numeric allow !", "Site");
    //   $("#site_Code").focus();
    //   return false;
    // }
    if ($("#site_Name").val().length < 2) {
      this.toastr.info("Site name must be at least 2 characters long !", "Site");
      $("#site_Name").focus();
      return false;
    }
    if (/["\\]/.test($("#site_Name").val())) {
      this.toastr.info("Site name does not allow Special Characters \" \\ ", "Site");
      $("#site_Name").focus();
      return false;
    }
    // if ($("#site_Name").val().search(/^[^-\s][a-zA-Z0-9\s.]+[^-\s]+$/) != 0) {
    //   this.toastr.info("Site name only alphabets and numeric allow !", "Site");
    //   $("#site_Name").focus();
    //   return false;
    // }
    // if ($("#site_Name").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
    //   this.toastr.info("Site name only alphabets and numeric allow !", "Site");
    //   $("#site_Name").focus();
    //   return false;
    // }





    if ($("#address1").val() != '' && $("#address1").val().length < 2) {
      this.toastr.info("Address 1 must be at least 2 characters long !", "Site");
      $("#address1").focus();
      return false;
    }
    if ($("#address1").val() != '' && /["\\]/.test($("#address1").val())) {
      this.toastr.info("Address 1 does not allow Special Characters \" \\ ", "Site");
      $("#address1").focus();
      return false;
    }
    // if ($("#address1").val() != '' && $("#address1").val().search(/^[^-\s][a-zA-Z0-9\s,-/]+[^-.\s]+$/) != 0) {
    //   this.toastr.info("Address 1 should be alphabets and numeric !", "Site");
    //   $("#address1").focus();
    //   return false;
    // }
    if ($("#address2").val() != '' && $("#address2").val().length < 2) {
      this.toastr.info("Address 2 must be at least 2 characters long !", "Site");
      $("#address2").focus();
      return false;
    }
    if ($("#address2").val() != '' && /["\\]/.test($("#address2").val())) {
      this.toastr.info("Address 2 does not allow Special Characters \" \\ ", "Site");
      $("#address2").focus();
      return false;
    }
    // if ($("#address2").val() != '' && $("#address2").val().search(/^[^-\s][a-zA-Z0-9\s,-/]+[^-.\s]+$/) != 0) {
    //   this.toastr.info("Address 2 should be alphabets and numeric !", "Site");
    //   $("#address2").focus();
    //   return false;
    // }
    if ($("#address3").val() != '' && $("#address3").val().length < 2) {
      this.toastr.info("Address 3 must be at least 2 characters long !", "Site");
      $("#address3").focus();
      return false;
    }
    if ($("#address3").val() != '' && /["\\]/.test($("#address3").val())) {
      this.toastr.info("Address 3 does not allow Special Characters \" \\ ", "Site");
      $("#address3").focus();
      return false;
    }
    // if ($("#address3").val() != '' && $("#address3").val().search(/^[^-\s][a-zA-Z0-9\s,-/]+[^-.\s]+$/) != 0) {
    //   this.toastr.info("Address 3 should be alphabets and numeric !", "Site");
    //   $("#address3").focus();
    //   return false;
    // }
    if ($("#address4").val() != '' && $("#address4").val().length < 2) {
      this.toastr.info("Address 4 must be at least 2 characters long !", "Site");
      $("#address4").focus();
      return false;
    }
    if ($("#address4").val() != '' && /["\\]/.test($("#address4").val())) {
      this.toastr.info("Address 4 does not allow Special Characters \" \\ ", "Site");
      $("#address4").focus();
      return false;
    }
    // if ($("#address4").val() != '' && $("#address4").val().search(/^[^-\s][a-zA-Z0-9\s,-/]+[^-\s]+$/) != 0) {
    //   this.toastr.info("Address 4 should be alphabets and numeric !", "Site");
    //   $("#address4").focus();
    //   return false;
    // }

    if ($("#city").val().length < 2) {
      this.toastr.info("City must be at least 2 characters long !", "Site");
      $("#city").focus();
      return false;
    }
    if ($("#postCode").val().length < 2 || $("#postCode").val().length > 10) {
      this.toastr.info("Post code must be at least 2 to 10 characters long !", "Site");
      $("#postCode").focus();
      return false;
    }

    // if ($("#city").val().search(/^[^-\s][a-zA-Z\s]+[^-\s]+$/) != 0) {
    //   this.toastr.info("City only alphabets allow !", "Site");
    //   $("#city").focus();
    //   return false;
    // }
    if ($("#state").val().trim().length < 2 && $("#state").val().trim().length != 0) {
      this.toastr.info("County must be at least 2 characters long !", "Site");
      $("#state").focus();
      return false;
    }
    // if ($("#state").val().search(/^[^-\s][a-zA-Z\s.]+[^-\s]+$/) > -1 || $("#state").val().search(/^[^-\s][a-zA-Z\s.]+[^-\s]+$/) != 0) {
    //   this.toastr.info("County only alphabets allow !", "Site");
    //   $("#state").focus();
    //   return false;
    // }

    if ($("#postCode").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
      this.toastr.info("Post code only alphabets and numeric allow !", "Site");
      $("#postCode").focus();
      return false;
    }


    // if ($("#postCode").val().search(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/g) != 0) {
    //   this.toastr.info("Post code should be alphabets and numeric !", "Site");
    //   $("#postCode").focus();
    //   return false;
    // }


    return true;
  }


  history() {
    this.historyView.getHistory("site", this.siteform.controls.site_Id.value)
    //this.historyView.getHistoryList('\"site\"', 'Site_Id' + '\\\"', this.siteform.value.site_Id);
  }


  
  closeModal() {
    this.modalClose.emit(false)
  }

  
 

}


