import { Component, OnInit, ModuleWithComponentFactories, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;
import * as moment from 'moment';


@Component({
  selector: 'app-employeeprobation',
  templateUrl: './emp-probation.component.html'
})
export class EmployeeProbationComponent implements OnInit {
  empform: FormGroup;
  probationList: any;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    //this.commonService.refilldateTimeFormatChange(data.date) : moment(new Date()).format('DD-MM-YYYY HH:mm')
    
    this.empform = this.fb.group({
      emp_Probation_Id: ['0'],
      emp_Id: ['0'],
      probationEndDate: ['', Validators.required],
      probationPeriod: ['', Validators.required],
      nextReviewDate: null,
      status: '',
      notes: null,
      isActive: ['1'],
      AddedBy: [Commonvariable.User_Id],
      updatedBy: [0]
    });
    this.commonService.flatpickr("flatpickropenproEndD,#flatpickropennextRevD", 0);
  }


  probationWiseGetData(id: any) {
    if (id != "") {
      var arr = id.split(/\s+/);
      let selected = this.probationList.find(c => c.prob_Duration == arr[0] && c.prob_DurationUnit == arr[1]);
      if (selected.prob_DurationUnit == "Month") {
        const datee = $('#getjoiningDate').val();
        const now = moment(datee);
        const add = now.add(selected.prob_Duration, 'months');
        let proEndDate = add.format('DD-MM-YYYY').split("-");
        $("#probationEndDate").val(proEndDate[0] + "-" + proEndDate[1] + "-" + proEndDate[2]);
      } else if (selected.prob_DurationUnit == "Day") {
        const datee = $('#getjoiningDate').val();
        const now = moment(datee);
        const add = now.add(selected.prob_Duration, 'days');
        let proEndDate = add.format('DD-MM-YYYY').split("-");
        $("#probationEndDate").val(proEndDate[0] + "-" + proEndDate[1] + "-" + proEndDate[2]);
      }
      else if (selected.prob_DurationUnit == "Year") {
        const datee = $('#getjoiningDate').val();
        const now = moment(datee);
        const add = now.add(selected.prob_Duration, 'years');
        let proEndDate = add.format('DD-MM-YYYY').split("-");
        $("#probationEndDate").val(proEndDate[0] + "-" + proEndDate[1] + "-" + proEndDate[2]);
      }
      //Fixed Three Month add on review date.
      const datee = $('#getjoiningDate').val();
      const now = moment(datee);
      const add = now.add(3, 'months');
      let proEndDate = add.format('DD-MM-YYYY').split("-");
      $("#nextReviewDate").val(proEndDate[0] + "-" + proEndDate[1] + "-" + proEndDate[2]);
    }
    else {
      $("#probationEndDate").val('');
      $("#nextReviewDate").val('');
    }
  }

  empEdit(Id) {
    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.EmpProbation, Id).subscribe((response) => {
        if (response != null && response["data"] != "") {
          var editData = response["data"];
          let proEndDate = response["data"][0].probationEndDate != null ? response["data"][0].probationEndDate.split("T")[0].split("-") : "";
          let nextRDate = response["data"][0].nextReviewDate != null ? response["data"][0].nextReviewDate.split("T")[0].split("-") : "";
          if (proEndDate != "") {
            $("#probationEndDate").val(proEndDate[2] + "-" + proEndDate[1] + "-" + proEndDate[0]);
          }
          if (nextRDate != "") {
            $("#nextReviewDate").val(nextRDate[2] + "-" + nextRDate[1] + "-" + nextRDate[0]);
          }
          var formData = this.empform;
          this.commonService.setEditData(formData, editData);
          formData.controls.updatedBy.setValue(Commonvariable.User_Id);

        }
      })
    }
  }

  //   casCadingDropdwon() {

  //     this.commonApiService.List(Commonvariable.Probation).subscribe((response) => {
  //      if (response != null && response["data"] != "") {
  //         this.probationList = response["data"];          

  //         var formData = this.empform;

  //         formData.controls.probationPeriod.setValue(formData.value.probationPeriod);

  //        let prob_period = formData.value.probationPeriod != null ? formData.value.probationPeriod.split(" "): "";          

  //      }
  //   })
  // }

  validation() {
    var eForm = this.empform;
    //sForm.get("company_Id").setValue($("#company_Id").val()); 
    this.commonService.setNull(this.empform)
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);
    eForm.controls.probationEndDate.setValue(this.commonService.dateFormatChange($("#probationEndDate").val()))
    if ($("#nextReviewDate").val() != "") {
      eForm.controls.nextReviewDate.setValue(this.commonService.dateFormatChange($("#nextReviewDate").val()))
    }

    if (!eForm.valid) {
      for (const key of Object.keys(eForm.controls)) {
        if (eForm.controls[key].invalid) {
          let fieldName = "";
          if (key == "probationPeriod") {
            fieldName = "Probation Period"
          }
          else if (key == "probationEndDate") {
            fieldName = "Probation End Date"
          }
          this.toastr.info(fieldName + " is required !", "Employee");
          $("#" + key).focus();
          return false;
        }
      }

    }
    else {
      if ($("#probationPeriod").val().length < 1) {
        this.toastr.info("Probation period must be at least 1 characters long !", "Employee");
        $("#probationPeriod").focus();
        return false;
      }
      if ($("#probationPeriod").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
        this.toastr.info("Probation period only alphabets and numeric value allow !", "Employee");
        $("#probationPeriod").focus();
        return false;
      }
      if ($("#notes").val() != "" && /["\\]/.test($("#notes").val())) {
        this.toastr.info("notes does not allow Special Characters \" \\ ", "Employee");
        $("#notes").focus();
        return false;
      }


      // if (moment($("#probationEndDate").val(), 'DD-MM-YYYY') >= moment($("#nextReviewDate").val(), 'DD-MM-YYYY')) {
      //   this.toastr.info("Probation end date not more then next review date !", "Employee");
      //   $("#probationEndDate").focus();
      //   return false;
      // }

      // if ($("#status").val() != "") {
      //   if ($("#status").val().length < 2) {
      //     this.toastr.info("Status must be at least 2 characters long !", "Employee");
      //     $("#status").focus();
      //     return false;
      //   }
      //   if ($("#status").val().indexOf(" ") > -1) {
      //     this.toastr.info("Status not allow space !", "Employee");
      //     $("#status").focus();
      //     return false;
      //   }
      // }
    }
    return true;
  }




}
