import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { stringify } from 'querystring';
import { NgxSpinnerService } from 'ngx-spinner';
import { Commonvariable } from '../models/commonvariable';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    count = 0;
    constructor(private router: Router, private spinner: NgxSpinnerService, private toastr: ToastrService, ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available


        this.spinner.show();


        this.count++;
        let currentUser = JSON.parse(localStorage.getItem('currentHRUser'));
        //console.log(localStorage.getItem("user_Id").toString())
        if (currentUser != null && currentUser["data"].token_No != null) {
            request = request.clone({
                setHeaders: {
                    token_no: currentUser["data"].token_No,
                    login_Id: currentUser["data"].login_Id,
                    user_Id: localStorage.getItem("user_Id").toString(),
                    role_Id: currentUser["data"].role_Id.toString(),
                    company_Id: String(Commonvariable.Company_Id)


                }
            });
        }

        //return next.handle(request);
        return next.handle(request).pipe(tap(() => { },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    switch (true) {
                        case (err.status >= 100 && err.status < 200): {
                            this.toastr.info(JSON.stringify(err["error"]["message"]), 'Info');
                            break;
                        }
                        case (err.status > 200 && err.status < 300): {
                            this.toastr.warning(JSON.stringify(err["error"]["message"]), 'Warning');
                            break;
                        }
                        case (err.status >= 300 && err.status <= 399): {

                            break;
                        }
                        case (err.status == 400): {
                            // this.toastr.info(JSON.stringify(err["error"][Object.keys(err["error"])[0]][0]), Object.keys(err["error"])[0]);
                            let title = String(Object.keys(err["error"]["errors"])[0]).split('.')
                            this.toastr.info(err["error"]["errors"][Object.keys(err["error"]["errors"])[0]][0], title[1] ? title[1] : title[0]  );
                            break;
                        }
                        case (err.status == 401): {
                            localStorage.clear();
                            sessionStorage.clear();
                            this.router.navigateByUrl('/401');
                            break;
                        }
                        case (err.status >= 402 && err.status <= 403): {
                            this.toastr.warning(JSON.stringify(err["error"]["message"]), 'Warning');
                            break;
                        }
                        case (err.status == 404): {
                            this.toastr.info(JSON.stringify(err["error"]["message"]), 'Warning');
                            break;
                        }
                        case (err.status == 405): {
                            this.router.navigateByUrl('/405');
                            break;
                        }
                        case (err.status >= 406 && err.status < 500): {
                            this.toastr.warning(JSON.stringify(err["error"]["message"]), 'Warning');
                            break;
                        }
                        case (err.status >= 500 && err.status < 600): {
                            this.toastr.error(err["error"]["message"], 'Error');
                            break;
                        }
                        // default: {
                        //     this.router.navigateByUrl('session_expired');
                        //     break;
                        // }
                    }
                }
            }), finalize(() => {
                this.count--;
                if (this.count == 0) {

                    this.spinner.hide()

                }
            }));
    }
}