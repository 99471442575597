import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, FullCalendarComponent } from '@fullcalendar/angular';
import { Router } from '@angular/router';
import { INITIAL_EVENTS, createEventId } from './event-utils';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Select2OptionData } from 'ng-select2';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
declare var flatpickr: any;

@Component({
   selector: 'app-leave',
   templateUrl: './add-leave.component.html'
})
export class AddLeaveComponent implements OnInit {

   //@ViewChild('calendarLeaveApply', { static: false }) calendarComponent: FullCalendarComponent;

   leaveform: FormGroup;
   tabActive: string = "calendarView";
   addleaveform: FormGroup;
   leavetypelist: any;

   totalRecord: 0;
   dayscount: any;
   p: number = 1;
   leaveFilter: any;
   title: string;
   empLeaveList: any;
   empleaverangid: number;
   empleaverangcompanyid: number;

   empLeaveBalance: any;
   isVisible: boolean = false;
   calMonth: boolean = false;
   leaveTypeDisabled: boolean = false;

   isApprovedLeaveCancelPresent: boolean = false;
      isedit:boolean=false;
   leaveApplyComment: any;
   pendingActions: number;
   currentEvents: EventApi[] = [];
   leaveList: any[] = [];
   leaveGridList: any;
   calendarOptions: any;
   leaveTypeAll: any;

   filterAdvance: boolean = false;
   isApprovedLeaveCancel: number;
   isLeave_CancelAll: number;
   currentDateForApplyLeave: any;
   value: boolean = true;
   onText: "On";
   offText: "Off";
   onColor: "Green";
   offColor: "Red";
   size: "normal";
   empList: any;
   roleidCond: number;
   userroleCond: string;
   emp_idCond: number;
   selectedItem: any;
    emp_ids = 0;
    Reporting_id = 0;
    isAllActive = true; 
    leavetypenewList:any;
    companyId:number;
       
  private requestCounter = 0;
   selectedItemData: any;
   currentSort: { column: string, direction: string } = { column: 'emp_Code', direction: 'asc' };
   itemsPerPageOptions: number[] = [10, 20, 50,100];
  itemsPerPage: number = 10;
   filterform: FormGroup;
   advancedFilterData:any;
   selforall:any;

  
   constructor(private commonApiService: CommonApiService,
      private toastr: ToastrService,
      private commonService: CommonService,
      private router: Router,
      private fb: FormBuilder,private spinner: NgxSpinnerService,
   ) { }

   ngOnInit() {
      
      this.commonService.flatpickr("leaveHistoryDate", 0);
      this.commonService.flatpickr("startdateflatpickropen", 0);
      this.commonService.flatpickr("enddateflatpickropen", 0);
      var self = this;

      $("#checkbox1,#checkbox2,#checkbox3,#checkbox4,#checkbox5,#checkbox6").bootstrapSwitch();
  
      
      $('#checkbox1,#checkbox2,#checkbox3,#checkbox4,#checkbox5,#checkbox6,#checkboxSelf').on({
         'switchChange.bootstrapSwitch': function (e, state) {
            
            //console.log(this); // DOM element  //console.log(e); // jQuery event     //console.log(state); // true | false        //console.log(this.value); // true | false
            var leaveTypeArr = [];
            $('.bSwitch_apply:checkbox:checked').each(function (i) {
               leaveTypeArr[i] = $(this).val();
            });
            

            self.onFlagChange(null, null, leaveTypeArr)


         },
         'click': function (e) {
            e.stopPropagation();
         }
      });

      $('body').on('click', '#addLeaveCalendar button.fc-prev-button, #addLeaveCalendar button.fc-next-button', function () {
         self.calMonth = true;

         //self.loadLeaveApplyCalendarView();        

         var leaveTypeArr = [];
         $('.bSwitch_apply:checkbox:checked').each(function (i) {
            leaveTypeArr[i] = $(this).val();
         });

         self.getCalendarLeaveList(null, null, leaveTypeArr);

      });


      if (Commonvariable.UserRole && Commonvariable.role_Id && localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id) {

         this.roleidCond = Commonvariable.role_Id
         this.userroleCond = Commonvariable.UserRole
         this.emp_idCond = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id
      }
      this.leaveform = this.fb.group({
         id: 0,
         employee_Leave_Id: 0,
         leave_Day_Type: [''],
         company_id: 0,
         emp_id: 0,
         leave_type_id: 0,
         leave_Date: null
      });
      
      this.filterform = this.fb.group({
         effectiveDatee:['15/10/2023'],
      });
      var leaveTypeArrDefault = [];
      $('.bSwitch_apply:checkbox:checked').each(function (i) {
       
         leaveTypeArrDefault[i] = $(this).val();
      });

      var EmpFilterId = $('.employeeSelectClass').val();
      if (EmpFilterId != "" && EmpFilterId != null) {
         var emp_ids = EmpFilterId;
      } else {
         var emp_ids = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id
      }


      this.getCalendarLeaveList(null, null, leaveTypeArrDefault);
      this.getLeaveGridList(1);


      this.addleaveform = this.fb.group({
         Id: 0,
         Emp_id: emp_ids,
         Approver_id: 1,
         Leave_type_id: ['', Validators.required],
         Start_date: ['', Validators.required],
         Start_type: ['',''],
         End_date: ['', Validators.required],
         End_type: ['',''],
         Comment: [''],
         isActive: ['1'],
         selectEmployee:[],
         sickLeaveType:[],
         Company_id: Commonvariable.Company_Id,
         Applied_by: localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id,
         AddedBy: localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id,
         requestLeave: ['Self', Validators.required] 

         
      });

      setTimeout(() => {
         $('[data-toggle="tooltip"]').tooltip();
      }, 500);

      this.companyId = JSON.parse(localStorage.getItem('currentHRUser'))["data"].company_Id;
      this.getLeaveTypeList();

      this.getLeave(emp_ids);
      this.isForOthersSelected();

      var addLeaveForm = this.addleaveform;

      // $(function () {

      //    var sd = new Date(), ed = new Date();
      //    $("#Start_date").datepicker({
      //       todayBtn: 1,
      //       autoclose: true,
      //       pickTime: false,
      //       daysOfWeekDisabled: [0, 6],
      //       weekStart: 1,
      //       todayHighlight: true,
      //       format: "dd-mm-yyyy"
      //    }).on('changeDate', function (selected) {

      //       // var minDate = new Date(selected.date.valueOf());
      //       // var Start_date = $('#Start_date').val();               
      //       // console.log(selected);
      //       // addLeaveForm.controls.Start_date.setValue(Start_date);
      //       // $('#End_date').datepicker('setStartDate', minDate, selected);
      //    });

      //    $("#End_date").datepicker({
      //       todayBtn: 1,
      //       autoclose: true,
      //       pickTime: false,
      //       daysOfWeekDisabled: [0, 6],
      //       weekStart: 1,
      //       todayHighlight: true,
      //       format: "dd-mm-yyyy",
      //    })
      //       .on('changeDate', function (selected) {
      //          // var minDate = new Date(selected.date.valueOf());
      //          // var End_date = $('#End_date').val();
      //          // addLeaveForm.controls.End_date.setValue(End_date);
      //          // $('#Start_date').datepicker('setEndDate', minDate, selected);
      //       });
      // });

      setTimeout(() => {
         $('.employeeSelectClass').select2();

         $('.employeeSelectClass').on('select2:select', function (e) {

            var leaveTypeArrDefault = [];
            $('.bSwitch_apply:checkbox:checked').each(function (i) {
               leaveTypeArrDefault[i] = $(this).val();
            });

            self.getCalendarLeaveList(null, null, leaveTypeArrDefault);
            self.getLeaveGridList(1);


            var EmpFilterId = $('.employeeSelectClass').val();
            if (EmpFilterId != "" && EmpFilterId != null) {
               var emp_ids = EmpFilterId;
            } else {
               var emp_ids = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id
            }
            if (emp_ids != "" && emp_ids != null) {
               self.getLeave(emp_ids);
            }


         });
         self.getEmpList();

      }, 500);

   }

   ngAfterViewInit(){
      $('.btn-toggle').click(function() {
         $(this).find('.btn').toggleClass('active');           
         if ($(this).find('.blue').size()>0) {
            $(this).find('.btn').toggleClass('blue');
         }   
     });
        flatpickr('#leaveHistoryDate', {
        // Flatpickr options
        onClose: (selectedDates: Date[]) => {
          console.log('Flatpickr onClose Triggered');
          console.log('Selected Dates:', selectedDates);
    
          const selectedDate = selectedDates.length > 0 ? selectedDates[0] : null;
          console.log('Selected Date:', selectedDate);

    const formattedDate = selectedDate ? selectedDate.toISOString().split('T')[0] : null; // Extract date portion
        console.log('Formatted Date:', formattedDate);
          this.filterform.get('effectiveDatee').setValue(formattedDate);
        },
      });
 
   }

   isSelf = true;

  

   isForOthersSelected() {
   if(this.addleaveform.get('requestLeave').value == "Self")
      {
         this.leavetypenewList =[];
         var listPayload =
          {
              "AddedBy_System": 0,
              "Company_Id": this.companyId,
              "Zone_Id": 0
           }
           this.commonApiService.PostRequest(Commonvariable.Leave_Type_New,"GetAlleaveTypeByRoleWise",listPayload).subscribe((result) => {
            if (result != null && result["data"] != "") {
               this.leavetypenewList = result["data"]
               if (this.leavetypenewList.length > 0)
                  this.leavetypenewList = this.leavetypenewList.map(x => ({
                     id: x.id,
                     text: x.displayName
                   }))
            }
         })

      //return this.addleaveform.get('requestLeave').value === 'Others';
   }
   else
   {
      this.leavetypenewList =[];
      var listPayload =
          {
              "AddedBy_System": 1,
              "Company_Id": this.companyId,
              "Zone_Id": 0
           }
      this.commonApiService.PostRequest(Commonvariable.Leave_Type_New,"GetAlleaveTypeByRoleWise",listPayload).subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.leavetypenewList = result["data"]
            if (this.leavetypenewList.length > 0)
               this.leavetypenewList = this.leavetypenewList.map(x => ({
                  id: x.id,
                  text: x.displayName
                }))
         }
      })

      //return this.addleaveform.get('requestLeave').value === 'Others';
   }
      
      
    }

    isSickLeaveSelected() {
      return this.addleaveform.get('Leave_type_id').value === 'Sick Leave';
    }

   getEmpList() {
      this.empList = [];
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": 1,
               "Parameter": "=="
            }
         ]
      }
      this.commonApiService.empDetailList(Commonvariable.EmployeeDetail, requestData).subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.empList = result["data"]
            if (this.empList.length > 0)
               this.empList = this.empList.sort((a, b) => a.firstName.localeCompare(b.firstName))
         }
      })
   }

   

   async  getLeaveGridList(PageIndex = 1, sortColumn: string = 'emp_Code', sortDirection: string = 'asc'): Promise<void> {
      const currentCounter = ++this.requestCounter;
    
      try {
        if (this.filterAdvance) {
          this.advanceFilter(null, PageIndex);
        } else {
          this.totalRecord = 0;
          this.empList = [];
         //  this.filterEmp = [];
          this.p = PageIndex;
    
          let requestData;
    
          if (this.advancedFilterData && this.advancedFilterData.length > 0) {
            requestData = {
              searchBy: this.advancedFilterData,
              PageIndex: PageIndex - 1,
             CommonSearch: $("#filterList").val().trim(),
              PageSize: this.itemsPerPage,
              OrderBy: sortColumn + " "+ sortDirection ,
              SortDirection: sortDirection
            };
          } else {
            requestData = {
              searchBy: [],
              PageIndex: PageIndex - 1,
             CommonSearch: $("#filterList").val().trim(),
              PageSize: this.itemsPerPage,
              OrderBy: sortColumn +" "+ sortDirection,
              SortDirection: sortDirection
            };
          }
    
          this.empList = [];
          this.totalRecord = 0;
    
          const result = await this.commonApiService.GetByPaginated(Commonvariable.Dashboard, requestData, "GetPaginated").toPromise();
    
          //if (currentCounter === this.requestCounter) {
          if (requestData.CommonSearch == $("#filterList").val().trim()) {
            // Check if this is the last request
            if (result != null && result["status"] === 200) {
              this.empList = [];
              if (result["data"] !== "" && result["data"]["list"] !== undefined) {
                this.totalRecord = result["data"]["recordCount"];
                this.empList = result["data"]["list"];
              }
            }
    
            setTimeout(() => {
              $('[data-toggle="tooltip"]').tooltip();
            }, 500);
          }
          else {
            this.spinner.hide();
          }
        }
      } catch (error) {
    
      }
    }
    
  
   sortColumn(column: string): void {
      if (this.currentSort.column === column) {
        // toggle direction if it's the same column
        this.currentSort.direction = this.currentSort.direction === 'asc' ? 'desc' : 'asc';
      } else {
        // set the new column and reset direction
        this.currentSort = { column, direction: 'asc' };
      }
    
      // call your data retrieval method with the new sort parameters
      this. getLeaveGridList(this.p, this.currentSort.column, this.currentSort.direction);
    }
    
    // method to get the appropriate icon class based on the current sort
    getSortIcon(column: string): string {
      if (this.currentSort.column === column) {
        return this.currentSort.direction === 'asc' ? 'fa-sort-up' : 'fa-sort-down';
      } else {
        return 'fa-sort';
      }
    }
    
    onItemsPerPageChange() {
      this.p = 1;
      this. getLeaveGridList();
    }
    
    isColumnSorted(column: string, direction: string): boolean {
      const table = $('#tblemp').DataTable();
      const order = table.order()[0];
      return order && order[0] === table.column(`:${column}`).index() && order[1] === direction;
    }
    

   // getCalendarLeaveList(mm = null, yyyy = null, leaveTypeArr = null) {


   //    var EmpFilterId = $('.employeeSelectClass').val();
   //    if (EmpFilterId != "" && EmpFilterId != null) {
   //       var emp_ids = EmpFilterId;
   //    } else {
   //       var emp_ids = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id
   //    }

   //    this.leaveList = [];
   //    if (this.calMonth) {
   //       //let calendarApi = this.calendarComponent.getApi();
   //       // console.log(calendarApi);
   //       // return false;

   //       let calendarApi = String($("#addLeaveCalendar .fc-toolbar-title").text()).split(' ');


   //       let currentMonth = moment().month(calendarApi[0]).format("M");
   //       let currentYear = calendarApi[1];
   //       let nextMontYear = currentYear + '-' + this.leadingZero(currentMonth);

   //       var requestData = {
   //          "Company_Id": Commonvariable.Company_Id,
   //          "Reporting_id": 0,
   //          "Emp_id": emp_ids != null ? emp_ids : 0,
   //          "Start":"01/10/2023",
   //          "End": "01/10/2023",

   //       }

   //    } else {

   //       var m, y;
   //       if (mm) {
   //          var m = mm;
   //       } else {
   //          var check = moment(moment().toDate(), 'YYYY/MM/DD');
   //          m = check.format('M');

   //       }

   //       if (yyyy) {
   //          var y = yyyy;
   //       } else {

   //          var check = moment(moment().toDate(), 'YYYY/MM/DD');
   //          y = check.format('Y');
   //       }

   //       let nextMontYear = y + '-' + m;
   //       var requestData = {
   //          "Company_Id": Commonvariable.Company_Id,
   //          "Reporting_id": 0,
   //          "Emp_id": emp_ids != null ? emp_ids : 0,
   //          "Start": "01/10/2023",
   //          "End": "01/10/2023"
   //       }

   //    }

   //    if (emp_ids != "" && emp_ids != null) {

   //       this.commonApiService.GetEmpListCalendar(Commonvariable.Employee_Leave, requestData).subscribe((result) => {
   //          //this.commonApiService.getPaginated(Commonvariable.Employee_Leave, null, null, null, null, null, requestData).subscribe((result) => {
   //          if (result != null && result["data"] != "") {
   //             //this.leaveList = result["data"]['leaveList'];
   //             this.leaveList = [];
   //             let leave_List = [];
   //             leaveTypeArr.forEach(item => {

   //                leave_List = result["data"]['leaveList'].filter(x => x.status == item);

   //                leave_List.forEach(x => {
   //                   this.leaveList.push(x)
   //                });
   //             });

   //             result["data"]['holidayList'].forEach(element => {
   //                this.leaveList.push(element);
   //             });
   //             //  $('#addLeaveCalendar').fullCalendar('removeEvents');

   //             //Getting new event json data
   //             //  $("#addLeaveCalendar").fullCalendar('addEventSource', this.leaveList);

   //             this.calendarOptions = {
   //                headerToolbar: {
   //                   left: 'prev,next',
   //                   center: 'title',
   //                   right: ''
   //                },
   //                firstDay: 1,
   //                //events: this.json3,
   //                initialView: 'dayGridMonth',
   //                fixedWeekCount: false,
   //                //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
   //                weekends: true,
   //                editable: false,
   //                selectable: true,
   //                selectMirror: true,
   //                dayMaxEvents: false,
   //                select: this.handleDateSelect.bind(this),
   //                eventClick: this.handleEventClick.bind(this),
   //                eventsSet: this.handleEvents.bind(this),
   //                events: this.leaveList,

   //                buttonText: {
   //                   month: 'Month',
   //                   today: 'Today'
   //                },
   //                /* you can update a remote database when these fire:
   //                eventAdd:
   //                eventChange:
   //                eventRemove:
   //                */
   //             };
   //             setTimeout(() => {
   //                $("#addLeaveCalendar .fc-view-harness").trigger('click');
   //             }, 100);

   //          } else {
   //             this.calendarOptions = {
   //                headerToolbar: {
   //                   left: 'prev,next',
   //                   center: 'title',
   //                   right: ''
   //                },
   //                firstDay: 1,
   //                //events: this.json3,
   //                initialView: 'dayGridMonth',
   //                fixedWeekCount: false,
   //                //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
   //                weekends: true,
   //                editable: false,
   //                selectable: true,
   //                selectMirror: true,
   //                dayMaxEvents: false,
   //                select: this.handleDateSelect.bind(this),
   //                eventClick: this.handleEventClick.bind(this),
   //                eventsSet: this.handleEvents.bind(this),
   //                events: this.leaveList,
   //                buttonText: {
   //                   month: 'Month',
   //                   today: 'Today'
   //                },
   //             };

   //             setTimeout(() => {
   //                $("#addLeaveCalendar .fc-view-harness").trigger('click');
   //             }, 100);
   //          }
   //       })
   //    }
   //    return this.leaveList
   // }
   
   toggleSwitch(): void {
    
      this.isAllActive = !this.isAllActive; 

       this.selforall = this.isAllActive ? 'All' : 'Self';
      this.getCalendarLeaveListselforall(null, null, null,this.selforall)
       // Activate 'Self' button (blue color)
    }
  
    getActiveButton(): string {
      return this.isAllActive ? 'All' : 'Self';
    }
   getCalendarLeaveList(mm = null, yyyy = null, leaveTypeArr = null) {
      var EmpFilterId = $('.employeeSelectClass').val();
      var emp_ids = EmpFilterId != "" && EmpFilterId != null ? EmpFilterId : (localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id);
  
      this.leaveList = [];
      var requestData = {
          "Company_Id": Commonvariable.Company_Id,
          "Reporting_id": 0,
          "Emp_id": emp_ids,
          "Start": "",
          "End": ""
      };
  
      if (this.calMonth) {
          let currentDate = new Date();
          let currentMonth = currentDate.getMonth() + 1;
          let currentYear = currentDate.getFullYear();
          requestData.Start = `${currentYear}-${('0' + currentMonth).slice(-2)}-01`;
          requestData.End = `${currentYear}-${('0' + currentMonth).slice(-2)}-${new Date(currentYear, currentMonth, 0).getDate()}`;
      } else {
          let m = mm ? mm : moment().format('M');
          let y = yyyy ? yyyy : moment().format('YYYY');
          requestData.Start = `${y}-${('0' + m).slice(-2)}-01`;
          requestData.End = `${y}-${('0' + m).slice(-2)}-${new Date(y, m, 0).getDate()}`;
      }
  
      if (emp_ids) {
          
         this.commonApiService.GetEmpListCalendar(Commonvariable.Employee_Leave, requestData).subscribe((result) => {
            //this.commonApiService.getPaginated(Commonvariable.Employee_Leave, null, null, null, null, null, requestData).subscribe((result) => {
            if (result != null && result["data"] != "") {
               //this.leaveList = result["data"]['leaveList'];
               this.leaveList = [];
               let leave_List = [];
               leaveTypeArr.forEach(item => {

                  leave_List = result["data"]['leaveList'].filter(x => x.status == item);

                  leave_List.forEach(x => {
                     this.leaveList.push(x)
                  });
               });

               result["data"]['holidayList'].forEach(element => {
                  this.leaveList.push(element);
               });
               //  $('#addLeaveCalendar').fullCalendar('removeEvents');

               //Getting new event json data
               //  $("#addLeaveCalendar").fullCalendar('addEventSource', this.leaveList);

               this.calendarOptions = {
                  headerToolbar: {
                     left: 'prev,next',
                     center: 'title',
                     right: ''
                  },
                  firstDay: 1,
                  //events: this.json3,
                  initialView: 'dayGridMonth',
                  fixedWeekCount: false,
                  //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
                  weekends: true,
                  editable: false,
                  selectable: true,
                  selectMirror: true,
                  dayMaxEvents: false,
                  select: this.handleDateSelect.bind(this),
                  eventClick: this.handleEventClick.bind(this),
                  eventsSet: this.handleEvents.bind(this),
                  events: this.leaveList,

                  buttonText: {
                     month: 'Month',
                     today: 'Today'
                  },
                  /* you can update a remote database when these fire:
                  eventAdd:
                  eventChange:
                  eventRemove:
                  */
               };
               setTimeout(() => {
                  $("#addLeaveCalendar .fc-view-harness").trigger('click');
               }, 100);

            } else {
               this.calendarOptions = {
                  headerToolbar: {
                     left: 'prev,next',
                     center: 'title',
                     right: ''
                  },
                  firstDay: 1,
                  //events: this.json3,
                  initialView: 'dayGridMonth',
                  fixedWeekCount: false,
                  //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
                  weekends: true,
                  editable: false,
                  selectable: true,
                  selectMirror: true,
                  dayMaxEvents: false,
                  select: this.handleDateSelect.bind(this),
                  eventClick: this.handleEventClick.bind(this),
                  eventsSet: this.handleEvents.bind(this),
                  events: this.leaveList,
                  buttonText: {
                     month: 'Month',
                     today: 'Today'
                  },
               };

               setTimeout(() => {
                  $("#addLeaveCalendar .fc-view-harness").trigger('click');
               }, 100);
            }
         })
      }
      return this.leaveList
   }
   getCalendarLeaveListselforall(mm = null, yyyy = null, leaveTypeArr = null,selforall) {
     
      var leaveTypeArrDefault = [];
            $('.bSwitch_apply:checkbox:checked').each(function (i) {
               leaveTypeArrDefault[i] = $(this).val();
            });
      var EmpFilterId = $('.employeeSelectClass').val();
      
 
      this.leaveList = [];
      if(selforall == "Self"){
         this.emp_ids = EmpFilterId != "" && EmpFilterId != null ? EmpFilterId : (localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id);
         this.Reporting_id = 0;
      }
      else{
      this.emp_ids = 0;
      this.Reporting_id =EmpFilterId != "" && EmpFilterId != null ? EmpFilterId : (localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id);
      }
      var requestData = {
          "Company_Id": Commonvariable.Company_Id,
          "Reporting_id": this.Reporting_id,
          "Emp_id": this.emp_ids,
          "Start": "",
          "End": ""
      };
  
      if (this.calMonth) {
          let currentDate = new Date();
          let currentMonth = currentDate.getMonth() + 1;
          let currentYear = currentDate.getFullYear();
          requestData.Start = `${currentYear}-${('0' + currentMonth).slice(-2)}-01`;
          requestData.End = `${currentYear}-${('0' + currentMonth).slice(-2)}-${new Date(currentYear, currentMonth, 0).getDate()}`;
      } else {
          let m = mm ? mm : moment().format('M');
          let y = yyyy ? yyyy : moment().format('YYYY');
          requestData.Start = `${y}-${('0' + m).slice(-2)}-01`;
          requestData.End = `${y}-${('0' + m).slice(-2)}-${new Date(y, m, 0).getDate()}`;
      }
  
      if (this.emp_ids >= 0) {
          
         this.commonApiService.GetEmpListCalendar(Commonvariable.Employee_Leave, requestData).subscribe((result) => {
            //this.commonApiService.getPaginated(Commonvariable.Employee_Leave, null, null, null, null, null, requestData).subscribe((result) => {
            if (result != null && result["data"] != "") {
               //this.leaveList = result["data"]['leaveList'];
               this.leaveList = [];
               let leave_List = [];
               leaveTypeArrDefault.forEach(item => {

                  leave_List = result["data"]['leaveList'].filter(x => x.status == item);

                  leave_List.forEach(x => {
                     this.leaveList.push(x)
                  });
               });

               result["data"]['holidayList'].forEach(element => {
                  this.leaveList.push(element);
               });
               //  $('#addLeaveCalendar').fullCalendar('removeEvents');

               //Getting new event json data
               //  $("#addLeaveCalendar").fullCalendar('addEventSource', this.leaveList);

               this.calendarOptions = {
                  headerToolbar: {
                     left: 'prev,next',
                     center: 'title',
                     right: ''
                  },
                  firstDay: 1,
                  //events: this.json3,
                  initialView: 'dayGridMonth',
                  fixedWeekCount: false,
                  //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
                  weekends: true,
                  editable: false,
                  selectable: true,
                  selectMirror: true,
                  dayMaxEvents: false,
                  select: this.handleDateSelect.bind(this),
                  eventClick: this.handleEventClick.bind(this),
                  eventsSet: this.handleEvents.bind(this),
                  events: this.leaveList,

                  buttonText: {
                     month: 'Month',
                     today: 'Today'
                  },
                  /* you can update a remote database when these fire:
                  eventAdd:
                  eventChange:
                  eventRemove:
                  */
               };
               setTimeout(() => {
                  $("#addLeaveCalendar .fc-view-harness").trigger('click');
               }, 100);

            } else {
               this.calendarOptions = {
                  headerToolbar: {
                     left: 'prev,next',
                     center: 'title',
                     right: ''
                  },
                  firstDay: 1,
                  //events: this.json3,
                  initialView: 'dayGridMonth',
                  fixedWeekCount: false,
                  //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
                  weekends: true,
                  editable: false,
                  selectable: true,
                  selectMirror: true,
                  dayMaxEvents: false,
                  select: this.handleDateSelect.bind(this),
                  eventClick: this.handleEventClick.bind(this),
                  eventsSet: this.handleEvents.bind(this),
                  events: this.leaveList,
                  buttonText: {
                     month: 'Month',
                     today: 'Today'
                  },
               };

               setTimeout(() => {
                  $("#addLeaveCalendar .fc-view-harness").trigger('click');
               }, 100);
            }
         })
      }
      return this.leaveList
   }
  
   onFlagChange(mm = null, yyyy = null, leaveTypeArr = null) {
      //console.log(leaveTypeArr);


      var EmpFilterId = $('.employeeSelectClass').val();
      if (EmpFilterId != "" && EmpFilterId != null) {
         var emp_ids = EmpFilterId;
      } else {
         var emp_ids = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id
      }

      let calendarApi = String($("#addLeaveCalendar .fc-toolbar-title").text()).split(' ');


      let currentMonth = moment().month(calendarApi[0]).format("M");
      let currentYear = calendarApi[1];
      let nextMontYear = currentYear + '-' + this.leadingZero(currentMonth);


      var requestData = {
         "Company_Id": Commonvariable.Company_Id,
         "Reporting_id": 0,
         "Emp_id": parseInt(emp_ids),
         "Start": nextMontYear + "-" + moment(this.leadingZero(currentMonth)).clone().startOf("month").format("DD"),
         "End": nextMontYear + "-" + moment(this.leadingZero(currentMonth)).clone().endOf("month").format("DD")

      }




      this.commonApiService.GetEmpListCalendar(Commonvariable.Employee_Leave, requestData).subscribe((result) => {
         //this.commonApiService.getPaginated(Commonvariable.Employee_Leave, null, null, null, null, null, requestData).subscribe((result) => {
         if (result != null && result["data"] != "") {


            this.leaveList = [];
            leaveTypeArr.forEach(item => {

               let leave_List = result["data"]['leaveList'].filter(x => x.status == item);

               leave_List.forEach(x => {
                  this.leaveList.push(x)
               });
            });

            result["data"]['holidayList'].forEach(element => {
               this.leaveList.push(element);
            });
            this.calendarOptions = {
               headerToolbar: {
                  left: 'prev,next',
                  center: 'title',
                  right: ''
               },
               firstDay: 1,
               //events: this.json3,
               initialView: 'dayGridMonth',
               fixedWeekCount: false,
               //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
               weekends: true,
               editable: false,
               selectable: true,
               selectMirror: true,
               dayMaxEvents: false,
               select: this.handleDateSelect.bind(this),
               eventClick: this.handleEventClick.bind(this),
               eventsSet: this.handleEvents.bind(this),
               events: "amar",
               buttonText: {
                  month: 'Month',
                  today: 'Today'
               },
               /* you can update a remote database when these fire:
               eventAdd:
               eventChange:
               eventRemove:
               */
            };

         } else {
            this.calendarOptions = {
               headerToolbar: {
                  left: 'prev,next',
                  center: 'title',
                  right: ''
               },
               firstDay: 1,
               //events: this.json3,
               initialView: 'dayGridMonth',
               fixedWeekCount: false,
               //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
               weekends: true,
               editable: false,
               selectable: true,
               selectMirror: true,
               dayMaxEvents: false,
               select: this.handleDateSelect.bind(this),
               eventClick: this.handleEventClick.bind(this),
               eventsSet: this.handleEvents.bind(this),
               events: "amar",
               buttonText: {
                  month: 'Month',
                  today: 'Today'
               },
            };
         }
      })
      return this.leaveList

   }
   onFlagChangeAll(mm = null, yyyy = null, leaveTypeArr = null) {
      //console.log(leaveTypeArr);


      var EmpFilterId = $('.employeeSelectClass').val();
      if (EmpFilterId != "" && EmpFilterId != null) {
         var emp_ids = EmpFilterId;
      } else {
         var emp_ids = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id
        // var emp_ids = null;
      }

      let calendarApi = String($("#addLeaveCalendar .fc-toolbar-title").text()).split(' ');


      let currentMonth = moment().month(calendarApi[0]).format("M");
      let currentYear = calendarApi[1];
      let nextMontYear = currentYear + '-' + this.leadingZero(currentMonth);


      var requestData = {
         "Company_Id": Commonvariable.Company_Id,
         "Reporting_id": parseInt(emp_ids),
         "Emp_id": 0,
         "Start": nextMontYear + "-" + moment(this.leadingZero(currentMonth)).clone().startOf("month").format("DD"),
         "End": nextMontYear + "-" + moment(this.leadingZero(currentMonth)).clone().endOf("month").format("DD")

      }




      this.commonApiService.GetEmpListCalendar(Commonvariable.Employee_Leave, requestData).subscribe((result) => {
         //this.commonApiService.getPaginated(Commonvariable.Employee_Leave, null, null, null, null, null, requestData).subscribe((result) => {
         if (result != null && result["data"] != "") {


            this.leaveList = [];
            leaveTypeArr.forEach(item => {

               let leave_List = result["data"]['leaveList'].filter(x => x.status == item);

               leave_List.forEach(x => {
                  this.leaveList.push(x)
               });
            });

            result["data"]['holidayList'].forEach(element => {
               this.leaveList.push(element);
            });
            this.calendarOptions = {
               headerToolbar: {
                  left: 'prev,next',
                  center: 'title',
                  right: ''
               },
               firstDay: 1,
               //events: this.json3,
               initialView: 'dayGridMonth',
               fixedWeekCount: false,
               //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
               weekends: true,
               editable: false,
               selectable: true,
               selectMirror: true,
               dayMaxEvents: false,
               select: this.handleDateSelect.bind(this),
               eventClick: this.handleEventClick.bind(this),
               eventsSet: this.handleEvents.bind(this),
               events: "amar",
               buttonText: {
                  month: 'Month',
                  today: 'Today'
               },
               /* you can update a remote database when these fire:
               eventAdd:
               eventChange:
               eventRemove:
               */
            };

         } else {
            this.calendarOptions = {
               headerToolbar: {
                  left: 'prev,next',
                  center: 'title',
                  right: ''
               },
               firstDay: 1,
               //events: this.json3,
               initialView: 'dayGridMonth',
               fixedWeekCount: false,
               //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
               weekends: true,
               editable: false,
               selectable: true,
               selectMirror: true,
               dayMaxEvents: false,
               select: this.handleDateSelect.bind(this),
               eventClick: this.handleEventClick.bind(this),
               eventsSet: this.handleEvents.bind(this),
               events: "amar",
               buttonText: {
                  month: 'Month',
                  today: 'Today'
               },
            };
         }
      })
      return this.leaveList

   }

   leadingZero(value) {
      if (value < 10) {
         return "0" + value.toString();
      }
      return value.toString();
   }

   handleWeekendsToggle() {
      const { calendarOptions } = this;
      calendarOptions.weekends = !calendarOptions.weekends;
   }

selectInfo: DateSelectArg;

   handleDateSelect(selectInfo: DateSelectArg) {




      this.isVisible = false;

      var addLeaveForm = this.addleaveform;
      addLeaveForm.controls.Start_type.setValue('Fullday');
      addLeaveForm.controls.End_type.setValue('Fullday');

      addLeaveForm.controls.Leave_type_id.setValue('');
      addLeaveForm.controls.Comment.setValue('');



      var datePartStartStr = selectInfo.startStr.match(/\d+/g),
         yearStartStr = datePartStartStr[0],
         monthStartStr = datePartStartStr[1], dayStartStr = datePartStartStr[2];
      var startDatess = moment(dayStartStr + '-' + monthStartStr + '-' + yearStartStr, 'DD/MM/YYYY').add(0, 'days').format("DD-MM-YYYY");


console.log("stdate",startDatess)

      //console.log(startDatess);




      if (selectInfo.startStr) {
         //$("#Start_date").datepicker("setDate", startDatess);
         this.commonService.flatpickr('startdateflatpickropen', { dateFormat: "d-m-Y" })
         $("#startdateflatpickropen").flatpickr({ dateFormat: "d-m-Y", defaultDate: startDatess });

      }
      addLeaveForm.controls.Start_date.setValue(startDatess);

      var datePartEndStr = selectInfo.endStr.match(/\d+/g),
         yearEndStr = datePartEndStr[0],
         monthEndStr = datePartEndStr[1],
         dayEndStr = parseInt(datePartEndStr[2]);

      var endDatess = moment(dayEndStr + '-' + monthEndStr + '-' + yearEndStr, 'DD/MM/YYYY').add(-1, 'days').format("DD-MM-YYYY");
      if (selectInfo.endStr) {
         //$("#End_date").datepicker("setDate", endDatess);         
         this.commonService.flatpickr('enddateflatpickropen', { dateFormat: "d-m-Y" });
         $("#enddateflatpickropen").flatpickr({ dateFormat: "d-m-Y", defaultDate: endDatess });
      }
      addLeaveForm.controls.End_date.setValue(endDatess);


      let weekendDaysStart = moment(startDatess, 'DD-MM-YYYY').format('dddd');
      let weekendDaysEnd = moment(endDatess, 'DD-MM-YYYY').format('dddd');

      // if(weekendDaysStart == 'Saturday' || weekendDaysStart == 'Sunday'){
      //    this.toastr.warning('You have selected Weekend! Please select different date.', 'Leave Apply');
      //    return false;
      // }else if(weekendDaysEnd == 'Saturday' || weekendDaysEnd == 'Sunday'){
      //    this.toastr.warning("You have selected Weekend! Please select different date.", 'Leave Apply');
      //    return false;
      // }else{
      //console.log(startDatess);
      //console.log(endDatess);
      if (startDatess == endDatess) {
         addLeaveForm.controls.End_type.setValue('---');
         this.leaveTypeDisabled = true;
      } else {
         addLeaveForm.controls.End_type.setValue('Fullday');
         this.leaveTypeDisabled = false;
      }
      //console.log(this.leaveTypeDisabled);
      $('#draggable').modal({
         backdrop: 'static'
      });
      // $('#draggable').modal();
      //{backdrop: 'static'}
      //}

      var days = this.daysdifference(monthStartStr + '/' + dayStartStr + '/' + yearStartStr, monthEndStr + '/' + parseInt(datePartEndStr[2]) + '/' + yearEndStr);
      this.dayscount = days;
   }


   getSelectedLeaveDate() {

      var addLeaveForm = this.addleaveform;

      var selectedStartDate = $("#Start_date").val();
      var selectedEndDate = $("#End_date").val();

      if (selectedStartDate == selectedEndDate) {
         addLeaveForm.controls.End_type.setValue('---');
         this.leaveTypeDisabled = true;
      } else {
         addLeaveForm.controls.End_type.setValue('Fullday');
         this.leaveTypeDisabled = false;
      }

      // console.log(event);
   }
   daysdifference(firstDate, secondDate) {
      var startDay = new Date(firstDate);
      var endDay = new Date(secondDate);

      var millisBetween = startDay.getTime() - endDay.getTime();
      var days = millisBetween / (1000 * 3600 * 24);

      return Math.round(Math.abs(days));
   }

   handleEventClick(clickInfo: EventClickArg) {

      var Id = clickInfo.event._def.publicId;
      var status = 'Cancelled';
      var compony_id = clickInfo.event._def.extendedProps.company_id;

      if (clickInfo.event._def.extendedProps.status == 'Pending' || clickInfo.event._def.extendedProps.status == 'Approved' || clickInfo.event._def.extendedProps.status == 'Rejected' || clickInfo.event._def.extendedProps.status == 'Cancelled' || clickInfo.event._def.extendedProps.status == 'CancellationRequest') {
         this.openModelForLeaves(Id, true);
      } else {
         return false
      }
   }

   handleEvents(events: EventApi[]) {
      this.currentEvents = events;
   }

   getLeaveTypeList() {
      this.leavetypelist = [];
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "Company_Id",
               "FieldValue": Commonvariable.Company_Id,
               "Parameter": "=="
            }
         ],
         "OrderBy": 'name'
      }
      this.commonApiService.GetByPaginatedRuleWise(Commonvariable.Leave_Type, requestData).subscribe((response) => {
         if (response["data"] != "") {
            this.leavetypelist = response["data"];
            this.isApprovedLeaveCancelPresent = this.leavetypelist.some(function (el) { return el.is_Approved_Leave_Cancel === 1 });
         }
      })
   }
   getEmpLeaveBalance(id) {

      var EmpFilterId = $('.employeeSelectClass').val();
      if (EmpFilterId != "" && EmpFilterId != null) {
         var emp_ids = EmpFilterId;
      } else {
         var emp_ids = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id
      }

      this.isVisible = false;
      if (id) {
         this.empLeaveBalance = 0;
         var data = {
            "Leave_Type_Id": id,
            "Emp_Id": emp_ids,
            "Company_Id": Commonvariable.Company_Id,
            "StartDate": moment($("#Start_date").val(), "DD-MM-YYYY").format('YYYY-MM-DD hh:mm:ss'),

         }
         this.commonApiService.PostRequest(Commonvariable.Leave_Balance, "GetEmployeeLeaveBalance", data).subscribe((result) => {
            //this.commonApiService.getByIdTwoParam(Commonvariable.Leave_Balance, localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id, id, "GetLeaveBalance").subscribe((result) => {
            if (result != null && result["data"] != "") {

               if (result["data"].balance_leave) {
                  this.isVisible = true;
                  this.empLeaveBalance = result["data"].balance_leave;
               } else {
                  this.isVisible = true;
                  this.empLeaveBalance = 0;
               }

            } else {
               this.isVisible = false;

            }
         })
      }

   }

   getLeave(id) {
      if (id) {
         this.commonApiService.getById(Commonvariable.Leave_Balance, id, "GetLeaveBalance").subscribe((result) => {
            if (result != null && result["data"] != "") {
               this.leaveTypeAll = result["data"];

            }
         })
      }

   }

   CancelLeaves(Id, status = null, compony_id = null, reporter_id = null) {


      this.commonService.takeReasonConfirm("You want to cancel !", "Yes !").then((result) => {
         if (result.value) {



            var requestData = {
               "employee_leave_id": Id,
               "status": status,
               "Company_id": compony_id,
               "reporter_id": parseInt(localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id),
               "Action_Notes": result.value
            }
            this.commonApiService.putWithParameter(Commonvariable.Employee_Leave, requestData, 'UpdateLeaveStatus').subscribe((response) => {

               var EmpFilterId = $('.employeeSelectClass').val();
               if (EmpFilterId != "" && EmpFilterId != null) {
                  var emp_ids = EmpFilterId;
               } else {
                  var emp_ids = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id
               }

               if (response["status"] == 200) {
                  this.toastr.success(response["message"], 'Leave');
                  this.expandTable(Id);

                  var leaveTypeArrDefault = [];
                  $('.bSwitch_apply:checkbox:checked').each(function (i) {
                     leaveTypeArrDefault[i] = $(this).val();
                  });

                  this.getCalendarLeaveList(null, null, leaveTypeArrDefault);
                  this.getLeaveGridList(1);
                  this.getLeave(emp_ids);
                  $('#draggableForLeaves').modal("hide")
               }
            })
         }

      });
   }

   CancelLeavesForApprovedLeave(Id, status = null, compony_id = null, reporter_id = null) {

      this.commonService.takeReasonConfirm("You want to cancel !", "Yes !").then((result) => {
         if (result.value) {
            var requestData = {
               "employee_leave_id": Id,
               "status": status,
               "Company_id": compony_id,
               "reporter_id": parseInt(localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id),
               "Action_Notes": result.value
            }
            this.commonApiService.putWithParameter(Commonvariable.Employee_Leave, requestData, 'UpdateLeaveStatusForApprovedLeave').subscribe((response) => {
               if (response["status"] == 200) {

                  var EmpFilterId = $('.employeeSelectClass').val();
                  if (EmpFilterId != "" && EmpFilterId != null) {
                     var emp_ids = EmpFilterId;
                  } else {
                     var emp_ids = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id
                  }

                  this.toastr.success(response["message"], 'Leave');
                  this.expandTable(Id);

                  var leaveTypeArrDefault = [];
                  $('.bSwitch_apply:checkbox:checked').each(function (i) {
                     leaveTypeArrDefault[i] = $(this).val();
                  });

                  this.getCalendarLeaveList(null, null, leaveTypeArrDefault);
                  this.getLeaveGridList(1);
                  this.getLeave(emp_ids);
                  $('#draggableForLeaves').modal("hide")
               }
            })
         }

      });
   }

   CancelSingleLeaves(Id, compony_id = null, status = null) {
      this.commonService.takeReasonConfirm("You want to cancel !", "Yes !").then((result) => {
         if (result.value) {
            var requestData = {
               "employee_leave_days_id": Id,
               "Company_id": compony_id,
               "status": status,
               "Action_Notes": result.value
            }
            this.commonApiService.putWithParameter(Commonvariable.Employee_Leave, requestData, 'UpdateEmployeeLeaveDayStatus').subscribe((response) => {
               if (response["status"] == 200) {
                  var EmpFilterId = $('.employeeSelectClass').val();
                  if (EmpFilterId != "" && EmpFilterId != null) {
                     var emp_ids = EmpFilterId;
                  } else {
                     var emp_ids = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id
                  }


                  this.expandTable(Id);

                  var leaveTypeArrDefault = [];
                  $('.bSwitch_apply:checkbox:checked').each(function (i) {
                     leaveTypeArrDefault[i] = $(this).val();
                  });

                  this.getCalendarLeaveList(null, null, leaveTypeArrDefault);
                  this.getLeaveGridList(1);
                  this.getLeave(emp_ids);
                  this.toastr.success(response["message"], 'Leave');
                  $('#draggableForLeaves').modal("hide")
               }
            })
         }

      });
   }

   getEmpLeaveDays(id) {

      this.empLeaveList = [];
      this.commonApiService.getById(Commonvariable.Employee_Leave, id, "GetEmployeeLeaveDayList").subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.empLeaveList = result["data"];
         }
      })
   }

   expandTable(id) {
      this.empLeaveList = [];
      var visibility = $(".sub_tbl_" + id).toggle().is(":visible");
      $(".sub_tbl").hide();
      $(".details-control").css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
      if (visibility === true) {
         $(".sub_tbl_" + id).show();
         $(".details-control-icon-" + id).css("background", "url(assets/pages/img/d4ICC.png) no-repeat center center");
         this.getEmpLeaveDays(id);
      } else {
         $(".details-control-icon-" + id).css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
      }

   }

   openModelForLeaves(id, reload = true) {

      this.empLeaveList = [];
      this.commonApiService.getById(Commonvariable.Employee_Leave, id, "GetEmployeeLeaveDayList").subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.empLeaveList = result["data"]['employee_Leave_Days'];

            this.leaveApplyComment = result["data"]['leaveApplyComment'];
            this.pendingActions = result["data"]['pendingActions'];

            this.isApprovedLeaveCancel = result["data"]['is_Approved_Leave_Cancel'];
            this.isLeave_CancelAll = result["data"]['is_Leave_CancelAll'];

            //this.currentDateForApplyLeave = moment().format('YYYY-MM-DD')+"T00:00:00";            

            this.empleaverangid = id;
            this.empleaverangcompanyid = result["data"]['employee_Leave_Days'][0]['company_id'];

         }
      })

      setTimeout(() => {
         $('[data-toggle="tooltip"]').tooltip();
      }, 500);

      if (reload) {
         $('#draggableForLeaves').modal({
            backdrop: 'static'
         });
      }
   }
   openModel(id) {

      if (id > 0) {
         this.title = "Edit";
         this.commonApiService.getById(Commonvariable.Employee_Leave, id, 'GetEmployeeLeaveDay').subscribe((response) => {
            if (response != null) {
               var editData = response["data"];
               var formData = this.leaveform;
               //this.commonService.setEditData(formData, editData);                    
               //console.log(editData.leave_Day_Type);
               formData.controls.id.setValue(editData.id);
               formData.controls.leave_Day_Type.setValue(editData.leave_Day_Type);
               formData.controls.employee_Leave_Id.setValue(editData.employee_Leave_Id);
               formData.controls.company_id.setValue(editData.company_id);
               formData.controls.emp_id.setValue(editData.emp_id);
               formData.controls.leave_type_id.setValue(editData.leave_type_id);
               formData.controls.leave_Date.setValue(editData.leave_Date);
            }
         })
      }
      else {
         this.ngOnInit();
      }
      $("#draggable").modal({
         backdrop: 'static'
      });

   }

   leaveUpsert() {
      var leaveForm = this.leaveform;
      this.commonApiService.UpsertLeave(Commonvariable.Employee_Leave, leaveForm.value, leaveForm.controls.id.value).subscribe((response) => {
         if (response != null) {
            if (response["data"] != null && response["data"] != "") {

               var EmpFilterId = $('.employeeSelectClass').val();
               if (EmpFilterId != "" && EmpFilterId != null) {
                  var emp_ids = EmpFilterId;
               } else {
                  var emp_ids = localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id
               }

               //console.log(response["data"]);
               $("#draggableEditLeave").modal('hide');
               this.toastr.success(response["message"], 'Leave');
               this.getLeaveGridList(1);
               this.getLeave(emp_ids);
               this.expandTable(leaveForm.controls.id.value);

               this.openModelForLeaves(leaveForm.controls.employee_Leave_Id.value, false);
            }
            else {
               this.toastr.warning(response["message"], 'Leave');
            }

         }
      })
   }


   validation() {
      var formData = this.addleaveform;
      for (const key of Object.keys(formData.controls)) {

         if (formData.controls[key].invalid) {
            let fieldName = "";
            if (key == "Leave_type_id") {
               fieldName = "Leave Type"
            }
            else if (key == "Start_date") {
               fieldName = "Start Date"
            }
            else if (key == "End_date") {
               fieldName = "End Date"
            }

            this.commonService.toaster("info", fieldName + " is required !", "Add Leave")
            $("#" + key).focus();

            return false;
         }

      }

      return true;
   }

   loadLeaveApplyCalendarView() {
      var leaveTypeArrDefault = [];
      $('.bSwitch_apply:checkbox:checked').each(function (i) {
         leaveTypeArrDefault[i] = $(this).val();
      });
      this.getCalendarLeaveList(null, null, leaveTypeArrDefault);
   }
   Upsert() {
      var addLeaveForm = this.addleaveform;
      var selectedStartDate = $("#Start_date").val();
      var selectedEndDate = $("#End_date").val();
      var selectedEndType = $("#End_type").val();

      if ($('.employeeSelectClass').val() != "" && $('.employeeSelectClass').val() != null) {
         console.log($('.employeeSelectClass').val());
         addLeaveForm.controls.Emp_id.setValue($('.employeeSelectClass').val())
      } else {
         addLeaveForm.controls.Emp_id.setValue(localStorage.getItem('HR_Emp_Id'))
      }

      if (selectedStartDate != selectedEndDate) {
         if (selectedEndType == '---') {
            this.commonService.toaster("info", "Leave End Type is required !", "Add Leave")
            $("#End_type").focus();
            return false;
         }
      }

      if (selectedStartDate > selectedEndDate) {
            this.commonService.toaster("info", "Leave End Date should be grater than Start Date", "Add Leave")
            $("#End_date").focus();
            return false;
      }
      if (this.validation()) {

         $("#draggable").modal('hide');
         var Start_date = $('#Start_date').val();
         var sd = Start_date.split("-").reverse().join("-");
         addLeaveForm.controls.Start_date.setValue(sd);

         var End_date = $('#End_date').val();
         var ed = End_date.split("-").reverse().join("-");
         addLeaveForm.controls.End_date.setValue(ed);
         this.commonApiService.Upsert(Commonvariable.Employee_Leave, addLeaveForm.value, addLeaveForm.value.Id).subscribe((response) => {
            if (response["data"] != null && response["data"] != "") {
               var mmmm = moment(response["data"]['start_date']).clone().endOf("month").format("MM")
               var yyyy = moment(response["data"]['start_date']).clone().endOf("month").format("YYYY");

               var leaveTypeArrDefault = [];
               $('.bSwitch_apply:checkbox:checked').each(function (i) {
                  leaveTypeArrDefault[i] = $(this).val();
               });

               if (mmmm && yyyy) {
                  this.getCalendarLeaveList(mmmm, yyyy, leaveTypeArrDefault);
               } else {
                  this.getCalendarLeaveList(null, null, leaveTypeArrDefault);
               }

               // this.router.navigateByUrl('/leaves');
               
               this.commonService.toaster("success", addLeaveForm.controls.Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, "Add Leave");

               this.getLeaveGridList(1);
               var EmpFilterId = $('.employeeSelectClass').val();
               if (EmpFilterId != "" && EmpFilterId != null) {
                  this.getLeave(EmpFilterId);
               } else {
                  this.getLeave(localStorage.getItem('HR_Emp_Id') != "" ? localStorage.getItem('HR_Emp_Id') : Commonvariable.emp_Id);
               }

            } else {

               var Start_date = $('#Start_date').val();
               var sd = Start_date.split("-").reverse().join("-");
               addLeaveForm.controls.Start_date.setValue(sd);

               var End_date = $('#End_date').val();
               var ed = End_date.split("-").reverse().join("-");
               addLeaveForm.controls.End_date.setValue(ed);

               this.commonService.toaster("info", response['message'], "Add Leave");
            }
         }, (error) => {
            var Start_date = $('#Start_date').val();
            var sd = Start_date.split("-").reverse().join("-");
            addLeaveForm.controls.Start_date.setValue(sd);

            var End_date = $('#End_date').val();
            var ed = End_date.split("-").reverse().join("-");
            addLeaveForm.controls.End_date.setValue(ed);
         })


      }
   }

   
   cancelLeave(){
      return Swal.fire({
         title: 'Are you sure?',
         text: "Do you want to Cancel this leave!",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes',
         cancelButtonText: 'No'
       })

   }
   
//   loadData() {
//    this.dataService.getData().subscribe(response => {
//      this.data = response;
//    });
//  }
   openModal() {
      this. isedit=true;
      // $('#draggable').modal('show')
      // Fetch data for the selected ID
      this.commonApiService.getById("","").subscribe(response => {
         this.selectedItemData  = response['data'] !== '' ? response['data'] : null;
       this.userprofileDetails(this.selectedItemData)
      this. isedit=true;
       
      });
    }

   
  userprofileDetails(selectedItemData){
   this.addleaveform.patchValue({     
      requestLeave: this.selectedItem && this.selectedItem[0] && `${this.selectedItem[0]['requestLeave']} ${this.selectedItem[0]['requestLeave']}`,
      Leave_type_id:this.selectedItem && this.selectedItem[0] && `${this.selectedItem[0][' Leave_type_id']} ${this.selectedItem[0][' Leave_type_id']}`,
      Start_date : this.selectedItem && this.selectedItem[0] && this.selectedItem[0]['Start_date'],
      End_date : this.selectedItem && this.selectedItem[0] && this.selectedItem[0]['End_date'],
      Start_type: this.selectedItem && this.selectedItem[0] && this.selectedItem[0]['Start_type'],
      End_type: this.selectedItem && this.selectedItem[0] && this.selectedItem[0]['End_type'],
  })
console.log(this.addleaveform.value,"data")
} 


    save() {
      const formData = this.addleaveform.value;

    
  const updateData = {
   
         emp_Id: formData.emp_id !== undefined ? (formData.emp_id.match(/\d+/) ? parseInt(formData.emp_id.match(/\d+/)[0], 10) : null) : null,
       
         Leave_type_id:formData.Leave_type_id?formData.Leave_type_id:'',
         Start_date:formData.Start_date?formData. Start_date:'',
         End_date: formData.End_date? formData.End_date : '',
         Start_type: formData.Start_type? formData.Start_type : '',
         End_type: formData.End_type? formData.End_type : '',
          
      };
console.log('Updated Data:', updateData);
     console.log('Updated Data:', updateData);
      
     
         this.commonApiService.SaveData(Commonvariable.Employee_Change_Requisition, 'Add', updateData).subscribe(
        (response) => {
         // const effectiveDateFlatpickr = flatpickr("#effectiveDate input");
         // const flatpickropenReq = flatpickr("#flatpickropenReq");
      
        
          if (response != null) {
            console.log('Data updated successfully:', response);
            this.toastr.success('Record saved sucessfully!');
            $('#draggable').modal('hide');
          this.addleaveform.reset();
          
          
         //  effectiveDateFlatpickr.clear();
         //  flatpickropenReq.clear();
             
          }
            else {
             
               this.toastr.warning('Record not saved !');
          
            
         }
       
         })
      }
    
  
    closeModal() {
      // Close modal without saving and reset the selected item
      $('#draggable').modal('hide');
      this.selectedItem = {};
    }



    
//   editRecord(id) {

   

//    this.commonApiService.getById(Commonvariable.VacancyRuleService, id, "ViewSummary").subscribe((result) => {


//      $('.companySelectListClass').select2({ width: '100%', disabled: true });

//      if (result["data"] != "") {


//        this.getCompanywisedata(result["data"][0]["company_Id"])
//        this.vacancyRuleForm.controls.Company_Id.setValue(result["data"][0]["company_Id"])
//        this.vacancyRuleForm.controls.site_Id.setValue(result["data"][0]["site_Id"])
//        this.vacancyRuleForm.controls.dept_Id.setValue(result["data"][0]["dept_Id"])
//        this.vacancyRuleForm.controls.Type.setValue(result["data"][0]["type"])
//        this.vacancyRuleForm.controls.person_Id.setValue(result["data"][0]["person_Id"])
//        this.vacancyRuleForm.controls.budgeted.setValue(String(result["data"][0]["budgeted"]))




//        $('#Company_Id').val(result["data"][0]["company_Id"] != null ? result["data"][0]["company_Id"] : "");
//        $('#site_Id').val(result["data"][0]["site_Id"] != null ? result["data"][0]["site_Id"] : "");
//        $('#dept_Id').val(result["data"][0]["dept_Id"] != null ? result["data"][0]["dept_Id"] : "")
//        $('#person_Id').val(result["data"][0]["deperson_Idpt_Id"] != null ? result["data"][0]["person_Id"] : "")
//        $('#Type').val(result["data"][0]["type"] != null ? result["data"][0]["type"] : "")
//        $('#emp_Id').val(result["data"][0]["vacancy_rule_recruiter"][0]["emp_Id"] != null ? result["data"][0]["vacancy_rule_recruiter"][0]["emp_Id"] : "")

//        $('.drp, .companySelectListClassFilter,.siteSelectListClassFilter, .departmentSelectListClassFilter').select2({ width: '100%' });

//        if (result["data"][0]["person_Id"] != null && result["data"][0]["person_Id"] != "") {
//          $('#dept_Id').select2({ width: '100%', disabled: true }).trigger('change');
//        }

//        this.addrolevisible = false

//        this.vacancyRuleList = result["data"][0];
//        this.vacancyRuleForm.controls.name.setValue(this.vacancyRuleList.name);
//        this.Vacancy_Rule_Id = this.vacancyRuleList.id
//        this.vacancyRuleRecruiterId = result["data"][0]["vacancy_rule_recruiter"][0]["id"]
//        this.clear(true);

//        this.items = this.vacancyRuleForm.get('vacancy_rule_detail') as FormArray;
//        result["data"][0]["vacancy_rule_detail"].forEach(x => {
//          this.items.push(this.vacancyRuleDetail(x));
//        });

//        setTimeout(() => {
//          for (let index = 0; index < this.vacancyRuleForm.controls.vacancy_rule_detail.value.length; index++) {

//            this.onChangeDesignation(this.vacancyRuleForm.controls.vacancy_rule_detail.value[index]["Desig_Id"], index, result["data"][0]["vacancy_rule_detail"][index]["emp_Id"]);

//            if (this.vacancyRuleForm.controls.vacancy_rule_detail.value[index].acrossCompany) {
//              this.filllAcorssCompanydrp(index, this.vacancyRuleForm.controls.vacancy_rule_detail.value[index]["Desig_Id"], result["data"][0]["vacancy_rule_detail"][index]["emp_Id"])
//            }

//            $("#Emp_Id" + index).val(result["data"][0]["vacancy_rule_detail"][index]["emp_Id"])


//          }
//        }, 200);

//      }
//    })

//  }
     

advanceFilter(sData = null, pageindex = 1) {
 

   const  effectiveDatee = this.filterform.controls.effectiveDatee.value;
   console.log(effectiveDatee,"date")
   var requestData = {
     "searchBy": [
        {
           "FieldName": " effectiveDatee",
           "FieldValue": effectiveDatee != 0 ? effectiveDatee: this.filterform.controls. effectiveDatee.value,
           "Parameter": "==",
           "ConditionWith": "&&"
        },
        
 
     ],
     "OrderBy": "FirstName"
  }
  this.commonApiService.getPaginated(Commonvariable.CompanyDetail, null, null, null, null, null, requestData, "GetByCompany_IdPaginated").subscribe((result) => {
   this.empLeaveList =result;
 
 });
 
 
 }
  }
