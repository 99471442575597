<style>
   .modal-body{
    display: table;
}
.btn {
  color: #000;
  background-color: #fff;
}

.btn.active {
  color: #fff;
  background-color: #007bff; /* Blue color */
}
</style>
<div class="page-content-wrapper">
   <div class="page-content">
      <div class="page-head">
         <div class="page-title">
            <h1>Leave Apply</h1>
         </div>
      </div>
      <ul class="page-breadcrumb breadcrumb mb20"></ul>
      <div class="portfolio-content portfolio-1">
         <div id="js-grid-juicy-projects" class="cbp-caption-active cbp-caption-overlayBottomReveal cbp-ready ">

            <div class="row mb-3">
               <div class="col-xl-3 col-sm-3 py-2" *ngFor="let item of leaveTypeAll">
                  <div class="card text-white h-100">
                     <div class="card-body"
                        style="border-radius: 5px!important; padding: 15px;background:#fff!important;color: #678098;display: flex;margin-bottom:7px;">
                        <h6 class="text-uppercase"
                           style="font-size: 23px;text-transform: capitalize;font-weight: 500;float: left; width: 100%;">
                           {{item.leave_Type}}
                        </h6>
                        <h6 style="font-size: 23px;"><b>{{item.balance_leave}}/{{item.total_leave}}</b></h6>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <br>
      <div class="col-md-12">
       
         <div class="portlet box grey">
            <div class="portlet-body">
               <div class="row">
                  <div class="col-md-12">

                     <!-- <ul class="nav nav-tabs">
                        <li class="active">
                           <a data-toggle="tab"
                              (click)="tabActive = 'calendarView'; loadLeaveApplyCalendarView();">Calendar
                              View</a>
                        </li>
                        <li><a data-toggle="tab" (click)="tabActive = 'listView'">List View</a></li>
                     </ul> -->


                     <ul class="nav nav-tabs">
                        <li class="active">
                           <a data-toggle="tab"
                              (click)="tabActive = 'calendarView'; loadLeaveApplyCalendarView();">Time Off & Leave</a>
                        </li>
                        <li><a data-toggle="tab" (click)="tabActive = 'listView'">Leave History</a></li>
                        <li  class="pull-right"><button class="btn btn-md blue" data-toggle="modal" data-target="#draggable">Request Leave</button></li>
                     </ul>



                     <div class="tab-content">
                        <div class="tab-pane active" [class.active]="tabActive == 'calendarView'" id="calendarView">
                           <div class="col-md-12">
                              <div class="row">
                                 <div class="pull-right mb-15">
                                    <div class="btn-group btn-toggle">
                                       <button class="btn" [class.active]="isAllActive" (click)="toggleSwitch()">All</button>
                                       <button class="btn" [class.active]="!isAllActive" (click)="toggleSwitch()">Self</button>
                                       <!-- <input type="checkbox" class="bSwitch bSwitch_apply" name="checkbox[]"
                                             id="checkboxSelf" checked value="Self" /> -->
                                       <!-- <button class="btn btn-default" >Self</button>
                                       <button class="btn blue active" >All</button> -->
                                     </div>
                                 </div>

                              </div>
                            

                           </div>
                           <div class="col-md-10" style="z-index: 0;">
                              <div id="addLeaveCalendar" class="portlet light bordered">
                                 <full-calendar [options]='calendarOptions'></full-calendar>
                              </div>
                           </div>
                           <div class="col-md-2">
                              <select class="form-control employeeSelectClass" *ngIf="(roleidCond == 1 || roleidCond == 2 || roleidCond == 6) && userroleCond && emp_idCond">
                                 <option value="">Select Employee</option>
                                 <option *ngFor="let emp of empList;" value={{emp.emp_Id}}>
                                    {{emp.firstName}} {{emp.lastName}}</option>
                              </select>
                              <table class="table">
                                 <tbody>
                                    <tr>
                                       <th scope="row">
                                          <span class="label label-info"
                                             style="display: block;padding: 9px;background: #F5B041 !important;font-size: 15px;font-weight: 500;border-radius: 30px!important;width: 100px;float: left;margin-right: 12px;margin-bottom: 5px;">Pending
                                          </span>
                                          <input type="checkbox" class="bSwitch bSwitch_apply" name="checkbox[]"
                                             id="checkbox1" checked value="Pending" />
                                       </th>
                                    </tr>
                                    <tr>
                                       <th scope="row">
                                          <span class="label label-primary"
                                             style="display: block;padding: 9px;background: #52BE80!important;font-size: 15px;font-weight: 500;border-radius: 30px!important;width: 100px;float: left;margin-right: 12px;margin-bottom: 5px;">Approved
                                          </span>
                                          <input type="checkbox" class="bSwitch bSwitch_apply" name="checkbox[]"
                                             id="checkbox2" checked value="Approved" />
                                       </th>
                                    </tr>
                                    <tr>
                                       <th scope="row">
                                          <span class="label label-warning"
                                             style="display: block;padding: 9px;background: #E74C3C!important;font-size: 15px;font-weight: 500;border-radius: 30px!important;width: 100px;float: left;margin-right: 12px;margin-bottom: 5px;">Rejected
                                          </span>
                                          <input type="checkbox" class="bSwitch bSwitch_apply" name="checkbox[]"
                                             id="checkbox3" checked value="Rejected" />
                                       </th>
                                    </tr>
                                    <tr>
                                       <th scope="row">
                                          <span class="label label-success"
                                             style="display: block;padding: 9px;background: #5DADE2!important;font-size: 15px;font-weight: 500;border-radius: 30px!important;width: 100px;float: left;margin-right: 12px;margin-bottom: 5px;">Cancelled
                                          </span>
                                          <input type="checkbox" class="bSwitch bSwitch_apply" name="checkbox[]"
                                             id="checkbox4" checked value="Cancelled" />
                                       </th>
                                    </tr>

                                    <tr>
                                       <!--*ngIf="isApprovedLeaveCancelPresent" -->
                                       <th scope="row">
                                          <span class="label label-success"
                                             style="display: block;padding: 9px;background: #800080!important;font-size: 11px;font-weight: 500;border-radius: 30px!important;width: 100px;float: left;margin-right: 12px;margin-bottom: 5px;">Cancel
                                             Request
                                          </span>
                                          <input type="checkbox" class="bSwitch bSwitch_apply" name="checkbox[]"
                                             id="checkbox6" checked value="CancellationRequest" />
                                       </th>
                                    </tr>

                                    <tr>
                                       <th scope="row">
                                          <span class="label label-success"
                                             style="display: block;padding: 9px;background: #99A3A4!important;font-size: 15px;font-weight: 500;border-radius: 30px!important;width: 100px;float: left;margin-right: 12px;margin-bottom: 5px;">Holiday
                                          </span>
                                          <!-- <input type="checkbox" name="checkbox[]" id="checkbox5" checked value="Holiday"/> -->
                                       </th>
                                    </tr>

                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                     <div class="tab-content">
                        
                        <div class="tab-pane" [class.active]="tabActive == 'listView'" id="listView">
                           <diV class="row">
                              <div class="col-md-6">
                                 <div class="text-left">        
                                    <select class="form-control" [(ngModel)]="itemsPerPage" (change)="onItemsPerPageChange()"  style="width: 75px;">
                                       <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                                     </select>
                                </div>

                              </div>
                              <form [formGroup]="filterform">
                              <div class="col-md-3">
                                 <div class="input-group DOB" id="leaveHistoryDate" data-wrap="true">
                                    <input type="text" class="form-control readonlyClass"
                                      id="" formControlName="effectiveDatee" (change)="advanceFilter()"   data-input />
                                    
                                    
                                    <span class="input-group-btn" data-toggle>
                                        <button class="btn default form-control datePicker" type="button">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                    </span>
                                </div>
                              </div>
                              </form>
                           
                              <div class="col-md-3 text-right">
                                 <input type="text" class="form-control" placeholder="Type to filter ..."
                                    id="filterList" (input)="getLeaveGridList(1)">
                              </div>
                           </diV>
                           <br />
                           <div class="table-responsive">

                              <!-- <table id="example2" class="display table table-striped table-bordered" cellspacing="0"
                                 width="100%" style="border:1px solid #e7ecf1;">
                                 <thead>
                                    <tr>
                                       <th class="text-center" style="width: 15%;"> Date</th>
                                       <th class="text-center" style="width: 15%;">Leave Type </th>
                                       <th class="text-center" style="width: 10%;"> Effective Days </th>
                                       <th class="text-center" style="width: 10%;"> Approved Days </th>
                                       <th class="text-center" style="width: 10%;"> Status </th>
                                    </tr>
                                 </thead>
                                 <tbody
                                    *ngFor="let item of leaveGridList | paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">
                                    <tr>
                                       <td class="text-center"><a data-toggle="modal"
                                             (click)="openModelForLeaves(item.id)">{{item.start_date |
                                             date:'dd/MM/yyyy'}} To {{item.end_date | date:'dd/MM/yyyy'}}</a></td>
                                       <td class="text-center">{{item.leave_type}} ({{item.leave_Code}})</td>
                                       <td class="text-center">{{item.no_of_leaves}}</td>
                                       <td class="text-center">{{item.approvedCount}}</td>
                                       <td class="text-center">{{item.status}}</td>
                                    </tr>
                                 </tbody>
                              </table> -->

                              <table id="leaveHistory" class="display table table-striped table-bordered" cellspacing="0"
                                 width="100%" style="border:1px solid #e7ecf1;">
                                 <thead>
                                    <tr>
                                       <th class="text-center"(click)="sortColumn('Start Date')">Start Date <i class="fa" [ngClass]="getSortIcon(' Pending Days')"></i></th>
                                       <th class="text-center"(click)="sortColumn('End Date')">End Date <i class="fa" [ngClass]="getSortIcon(' Pending Days')"></i></th>
                                       <th class="text-center"(click)="sortColumn('No. of Days')">No. of Days <i class="fa" [ngClass]="getSortIcon(' Pending Days')"></i></th>
                                       <th class="text-center"(click)="sortColumn('Leave Type')">Leave Type <i class="fa" [ngClass]="getSortIcon(' Pending Days')"></i></th>
                                       <th class="text-center"(click)="sortColumn('Status ')"> Status <i class="fa" [ngClass]="getSortIcon(' Pending Days')"></i> </th>
                                       <th class="text-center"(click)="sortColumn('Applied By')"> Applied By <i class="fa" [ngClass]="getSortIcon(' Pending Days')"></i> </th>
                                       <th class="text-center"(click)="sortColumn('Approved By')"> Approved By<i class="fa" [ngClass]="getSortIcon(' Pending Days')"></i>  </th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td class="text-center">20 Feb 2024</td>
                                       <td class="text-center">22 Feb 2024</td>
                                       <td class="text-center">3</td>
                                       <td class="text-center">WFH</td>
                                       <td class="text-center">Pending</td>
                                       <td class="text-center">Self</td>
                                       <td class="text-center">Niraj</td>
                                       <td class="text-center">
                                          <!-- <a data-toggle="modal" >
                                             <i class="fa fa-edit purple iconcss iconFontsize mr-8" data-toggle="tooltip"
                                                 data-placement="top" title="Edit Leave" (click)="openModal(id)"></i>
                                          </a> -->
                                          <a  data-toggle="modal" href="#draggable" (click)="openModal()"><i
                                             class="fa fa-pencil iconcss iconFontsize"
                                             data-toggle="tooltip" data-placement="top"
                                             title="Edit"></i></a>
                                          <a (click)="cancelLeave()">
                                             <i class="fa fa-times red-color iconcss iconFontsize"
                                             data-toggle="tooltip" data-placement="top"
                                             title="Cancel Leave"></i>
                                         </a>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td class="text-center">22 Feb 2024</td>
                                       <td class="text-center">26 Feb 2024</td>
                                       <td class="text-center">5</td>
                                       <td class="text-center">Annual</td>
                                       <td class="text-center">Approved</td>
                                       <td class="text-center">Self</td>
                                       <td class="text-center">Niraj</td>
                                       <td class="text-center"></td>
                                    </tr>
                                    <tr>
                                       <td class="text-center">2 Jan 2024</td>
                                       <td class="text-center">3 Jan 2024</td>
                                       <td class="text-center">2</td>
                                       <td class="text-center">Sick</td>
                                       <td class="text-center">Approved</td>
                                       <td class="text-center">Niraj</td>
                                       <td class="text-center">Niraj</td>
                                       <td class="text-center"></td>
                                    </tr>
                                 </tbody>

                              </table>

                           </div>
                           <div class="text-center" *ngIf="leaveList != null">
                              <pagination-controls (pageChange)="getLeaveGridList($event)" autoHide="true">
                              </pagination-controls>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
       
      </div>
   </div>
</div>
<div class="modal fade draggable-modal" id="draggableForLeaves" role="basic" aria-hidden="true" style="z-index: 0;">
   <div class="modal-dialog modal-lg" style="margin:10% auto !important;">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
            <h4 class="modal-title">Applied Leave Days</h4>
         </div>
         <div class="modal-body">
            <div class="table-responsive">
               <table class="table table-striped table-bordered table-hover" id="tblDepartment"
                  style="position: relative;" role="grid">
                  <thead class="flip-content">
                     <tr>
                        <th>Leave Date</th>
                        <th>Day</th>
                        <th>Day Type</th>
                        <th>Leave Type</th>
                        <th>Status</th>
                        <th>Note</th>
                        <th>Action</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item_day of empLeaveList">
                        <td>{{item_day.leave_Date | date:'dd-MM-yyyy'}}</td>
                        <td>{{item_day.leave_Date | date:'EEEE'}}</td>
                        <td *ngIf="item_day.leave_Day_Type == 'Fullday'">Full Day</td>
                        <td *ngIf="item_day.leave_Day_Type == 'Firsthalf'">First Half</td>
                        <td *ngIf="item_day.leave_Day_Type == 'Secondhalf'">Second Half</td>
                        <td *ngIf="(item_day.leave_Day_Type == 'Sandwich')">Weekend
                        </td>
                        <td *ngIf="(item_day.leave_Day_Type == 'HolidaySandwich')">Holiday
                        </td>
                        <td>{{item_day.leave_Type}} ({{item_day.leave_Code}})</td>
                        <td *ngIf="(item_day.leave_Day_Type == 'Sandwich')"><span class="badge badge-pill"
                              style="background-color: #99A3A4 !important;font-size: 14px!important;height: 25px!important;padding: 5px 13px!important;">Weekend</span>
                        </td>
                        <td *ngIf="(item_day.leave_Day_Type == 'HolidaySandwich')"><span class="badge badge-pill"
                              style="background-color: #99A3A4 !important;font-size: 14px!important;height: 25px!important;padding: 5px 13px!important;">Holiday</span>
                        </td>
                        <td
                           *ngIf="(item_day.leave_Day_Type != 'Sandwich') && (item_day.leave_Day_Type != 'HolidaySandwich')">
                           <span class="badge badge-pill" *ngIf="(item_day.status == 'Pending')"
                              style="background-color: #F5B041 !important;font-size: 14px!important;height: 25px!important;padding: 5px 13px!important;">{{item_day.status}}</span>
                           <span class="badge badge-pill" *ngIf="(item_day.status == 'Approved')"
                              style="background-color: #52BE80 !important;font-size: 14px!important;height: 25px!important;padding: 5px 13px!important;">{{item_day.status}}</span>
                           <span class="badge badge-pill" *ngIf="(item_day.status == 'Rejected')"
                              style="background-color: #E74C3C !important;font-size: 14px!important;height: 25px!important;padding: 5px 13px!important;">{{item_day.status}}</span>
                           <span class="badge badge-pill" *ngIf="(item_day.status == 'Cancelled')"
                              style="background-color: #5DADE2 !important;font-size: 14px!important;height: 25px!important;padding: 5px 13px!important;">{{item_day.status}}</span>
                           <span class="badge badge-pill" *ngIf="(item_day.status == 'CancellationRequest')"
                              style="background-color: #800080 !important;font-size: 14px!important;height: 25px!important;padding: 5px 13px!important;">{{item_day.status}}</span>
                        </td>
                        <td>{{item_day.action_Notes}}</td>
                        <td class="text-center">
                           <a (click)="openModel(item_day.id)"
                              *ngIf="(item_day.leave_Day_Type != 'Sandwich') && (item_day.leave_Day_Type != 'HolidaySandwich') && (item_day.status == 'Pending')"><i
                                 class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                 data-placement="top" title="Edit"></i>&nbsp;</a>
                           <a (click)="CancelSingleLeaves(item_day.id,item_day.company_id,'Cancelled')"
                              *ngIf="(item_day.leave_Day_Type != 'Sandwich') && (item_day.leave_Day_Type != 'HolidaySandwich') && item_day.status == 'Pending'"><i
                                 class="fa fa-close red-color iconcss iconFontsize" data-toggle="tooltip"
                                 data-placement="top" title="Cancel"></i></a>

                           <a (click)="CancelSingleLeaves(item_day.id,item_day.company_id,'Cancelled')"
                              *ngIf="(item_day.leave_Day_Type != 'Sandwich') && (item_day.leave_Day_Type != 'HolidaySandwich') && (item_day.status == 'Approved') && (this.isApprovedLeaveCancel == 1) && (item_day.is_Salary == 0)"><i
                                 class="fa fa-close red-color iconcss iconFontsize" data-toggle="tooltip"
                                 data-placement="top" title="Cancel Request"></i></a>

                        </td>
                     </tr>
                     <tr>
                        <td colspan="7"></td>
                     </tr>
                     <tr>
                        <td colspan="1"><b>Note:</b></td>
                        <td colspan="6">{{this.leaveApplyComment}}</td>
                     </tr>
                  </tbody>
               </table>
            </div>
            <a *ngIf="pendingActions > 0"
               (click)="CancelLeaves(this.empleaverangid,'Cancelled',this.empleaverangcompanyid,'0')"
               class="btn red">Cancel All</a>
            <a *ngIf="(this.isApprovedLeaveCancel == 1) && (this.isLeave_CancelAll == 1)"
               (click)="CancelLeavesForApprovedLeave(this.empleaverangid,'Cancelled',this.empleaverangcompanyid,'0')"
               class="btn red">Cancel All</a>

         </div>
         <div class="modal-footer">
            <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
         </div>
      </div>
   </div>
</div>
<!-- END CONTENT -->
<div class="modal fade draggable-modal" id="draggable" tabindex="-1" role="dialog" aria-hidden="true">
   <div class="modal-dialog modal-lg" style="margin:10% auto !important">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
            <h4 class="modal-title">Add Leave</h4>
         </div>

            <div class="modal-body">

                     <form id="submit_form" class="form-horizontal" [formGroup]="addleaveform">

                        <div class="col-md-12">
                           <div class="row mb-0">
                              <label class="form-label col-md-2">Request For</label>
                              <div class="col-lg-3">

                                 <div class="md-radio-inline">
                                    <div class="md-radio">
                                      <input type="radio" id="forSelf" formControlName="requestLeave" value="Self" class="md-radiobtn" (change)="isForOthersSelected()">
                                      <label for="forSelf">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span>Self
                                      </label>
                                    </div>
                                    <div class="md-radio">
                                      <input type="radio" id="forOthers" formControlName="requestLeave" value="Others" class="md-radiobtn" (change)="isForOthersSelected()">
                                      <label for="forOthers">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span>Others
                                      </label>
                                    </div>
                                  </div>

                              </div>

                              

                              <div class="col-lg-5">
                                 <input type="text" *ngIf="addleaveform.get('requestLeave').value === 'Others'"  formControlName="selectEmployee" class="form-control" placeholder="Select Employee Name or Employee Code">

                              </div>
                           </div>

                        </div>

                        <div class="col-md-12">
                           <div class="row">
                              <div class="col-lg-5" >
                                 <label class="control-label mb-6">Leave Type<span class="required"> * </span></label>
                                 <ng-select2 [data]="leavetypenewList" id="Leave_type_id" [width]="400" formControlName="Leave_type_id"
                                             [placeholder]="'Select Leave Type'">
                                 </ng-select2>
                              </div>

                              <div class="col-lg-4" *ngIf="addleaveform.get('requestLeave').value !== 'Self' && isSickLeaveSelected()">
                                 <label class="control-label mb-6">Sick Leave Type<span class="required"> * </span></label>
                                 <select  class="form-control" formControlName="sickLeaveType">
                                    <option>Select Sick Leave Type</option>
                                    <option>Anxiety/ Depression/ Other Psychiatric Illnesses</option>
                                    <option>Mental Health - Work related</option>
                                    <option>Asthma</option>
                                    <option>Back Problems</option>
                                    <option>Benign and Malignant Tumours, Cancers</option>
                                    <option>Blood Disorders</option>
                                    <option>Burns, Poisoning, Frostbite, Hypothermia</option>
                                    <option>Chest and Respiratory Problems</option>
                                    <option>Cold, Cough, Flu - Influenza</option>
                                    <option>Dental and Oral Problems</option>
                                    <option>Ears, Nose and Throat</option>
                                    <option>Endocrine/Glandular Problems</option>
                                    <option>Eye Problems</option>
                                    <option>Gastro-intestinal Problems</option>
                                    <option>Genitourinary and Gynaecological Disorders</option>
                                    <option>Headache/Migraine</option>
                                    <option>Heart, Cardiac and Circulatory Problems</option>
                                    <option>Infections, Anaemia, Endocrine Disorders</option>
                                    <option>Infectious Diseases</option>
                                    <option>Injury, Fracture</option>
                                    <option>Musculo-skeletal</option>
                                    <option>Nervous System Disorders</option>
                                    <option>Other Known Causes - not elsewhere classified</option>
                                    <option>Other Musculoskeletal Problems</option>
                                    <option>Pregnancy Related Disorders</option>
                                    <option>Skin Disorders</option>
                                    <option>Stress</option>
                                    <option>Substance Abuse</option>
                                    <option>Surgery Related</option>
                                  </select>
                              </div>

                              <div class="col-lg-3">
                                 <label>&nbsp;</label>
                                 <h4 class="text-center">Available Balance: <span style="color: #52BE80; "><b>23</b></span></h4>
                              </div>
                           </div>
                           
                        </div>

                        <div class="col-md-12">
                           <div class="row mt-0">

                              
                              <div class="col-md-5">
                                 <label class="control-label mb-6">Leave Start From<span class="required"> * </span></label>
                                 <div id="start_Date_open" data-wrap="true">
                                    <div class="input-group flatpickr" id="startdateflatpickropen" data-wrap="true"
                                       (change)="getSelectedLeaveDate()">
                                       <input type="text" class="form-control" id="Start_date" data-input
                                          formControlName="Start_date" />
                                       <span class="input-group-btn" data-toggle>
                                          <button class="btn default" type="button">
                                             <i class="fa fa-calendar"></i>
                                          </button>
                                       </span>
                                    </div>
                                 </div>
                              </div>

                              <div class="col-lg-5">
                                 <label class="control-label mb-6">Leave End On<span class="required"> * </span></label>
                                 <div id="end_Date_open" data-wrap="true">
                                    <div class="input-group flatpickr" id="enddateflatpickropen" data-wrap="true"
                                       (change)="getSelectedLeaveDate()">
                                       <input type="text" class="form-control" id="End_date" data-input
                                          formControlName="End_date" />
                                       <span class="input-group-btn" data-toggle>
                                          <button class="btn default" type="button">
                                             <i class="fa fa-calendar"></i>
                                          </button>
                                       </span>
                                    </div>
                                 </div>
                              </div>

                              <div class="col-lg-5">
                                 <label class="control-label mb-6">&nbsp;</label>
                                 <select id="Start_type" class="form-control" formControlName="Start_type" >
                                    <option value="" disabled selected>Select Day Type</option>
                                    <option value="Fullday">Full Day</option>
                                    <option value="Firsthalf">First Half</option>
                                    <option value="Secondhalf">Second Half</option>
                                 </select>
                              </div>

                              <div class="col-lg-5">
                                 <label class="control-label mb-6">&nbsp;</label>
                                 <select id="End_type" class="form-control" formControlName="End_type" >
                                    <option value="" disabled selected>Select Day Type</option>
                                  <option value="Fullday">Full Day</option>
                                 <option value="Firsthalf">First Half</option>
                                 <option value="Secondhalf">Second Half</option>
                              </select>
                              </div>

                           </div>
                        </div>

                        <div class="col-md-12 mb-15" >
                           <h4>Total No. of Days Taken: <span style="color: #3598dc"><b>5</b></span></h4>
                        </div>

                         <!--
                           *ngIf="isVisible"
                           {{empLeaveBalance}}
                        -->

                           <div class="col-md-12">
                              <label class="control-label mb-6">Reason:</label>
                              <textarea class="form-control" formControlName="Comment" id="Comment"></textarea>
                           </div>



                        <!-- <div class="form-group" *ngIf="dayscount > 15">
                           <label class="control-label col-md-2">&nbsp;
                           </label>
                           <div class="col-md-8">
                              <div class="alert alert-danger">
                                 <strong>Warning!</strong> Your can't take leave more than 15 days.
                              </div>
                           </div>
                        </div> -->

                     </form>

            </div>

            <div class="modal-footer">
               <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
               <button type="button" *ngIf="!isedit"  (click)="Upsert()"  class="btn blue">Save</button>
               <button type="button"   *ngIf="isedit"  (click)="save()"  class="btn blue">Upadate</button>
               <!--[disabled]="dayscount > 15"-->
            </div>

      </div>
   </div>
</div>


<div class="modal fade draggable-modal" id="draggableEditLeave" tabindex="-1" role="basic" aria-hidden="true">
   <div class="modal-dialog modal-lg">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
            <h4 class="modal-title">{{title}} Leave Day Type</h4>
         </div>
         <div class="modal-body">
            <form [formGroup]="leaveform">
               <div>
                  <div class="row">
                     <div class="col-md-6">
                        <div class="form-group">
                           <label class="control-label">Day Type</label>
                           <select id="leave_Day_Type" class="form-control" formControlName="leave_Day_Type">
                              <option value="Fullday">Full Day</option>
                              <option value="Firsthalf">First Half</option>
                              <option value="Secondhalf">Second Half</option>
                           </select>
                        </div>
                     </div>
                  </div>
               </div>
            </form>
         </div>
         <div class="modal-footer">
            <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            <button type="button" class="btn blue" (click)="leaveUpsert()">Update</button>
         </div>
      </div>
   </div>
</div>
<style>
   td.details-control {
      background: url('assets/pages/img/SD7Dz.png') no-repeat center center;
      cursor: pointer;
   }

   tr.shown td.details-control {
      background: url('assets/pages/img/d4ICC.png') no-repeat center center;
   }
</style>
