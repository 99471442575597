<div id="accordion1" class="panel-group">
    <!-- <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#emailConfiguration"> Email Configuration </a>
            </h4>
        </div>
        <div id="emailConfiguration" class="panel-collapse collapse in">
            <div class="panel-body">
                <form [formGroup]="companyemailconfigform">

                    <div class="form-group">
                        <label class="control-label col-md-4">Email Host-->
                            <!-- <span class="required"> * </span> -->
                        <!--</label>
                        <div class="col-md-3">
                            <input type="text" id="email_Host" class="form-control" formControlName="email_Host"
                                autocomplete="off" maxlength="200" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-md-4">Email Port-->
                            <!-- <span class="required"> * </span> -->
                        <!--</label>
                        <div class="col-md-3">
                            <input type="text" id="email_Port" class="form-control" formControlName="email_Port"
                                autocomplete="off" maxlength="4"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"' />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-md-4">Email User Name-->
                            <!-- <span class="required"> * </span> -->
                      <!--  </label>
                        <div class="col-md-3">
                            <input type="text" id="email_UserName" class="form-control" formControlName="email_UserName"
                                autocomplete="off" maxlength="100" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-md-4">Email Password-->
                            <!-- <span class="required"> * </span> -->
                      <!--  </label>
                        <div class="col-md-3">
                            <input type="text" id="email_Password" class="form-control" formControlName="email_Password"
                                autocomplete="off" maxlength="20" />
                        </div>
                    </div>

                </form>

            </div>
        </div>
    </div> -->



    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#empCodeConfiguration"> Employee Code Configuration </a>
            </h4>
        </div>
        <div id="empCodeConfiguration" class="panel-collapse collapse in">
            <div class="panel-body">
                <form [formGroup]="employeeCodeConfiguration">
                    <div class="form-group">
                        <label class="control-label col-md-4">Accross Company
                            <!-- <span class="required"> * </span> -->
                        </label>
                        <div class="col-md-3">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="AccrossCompany" class="md-check"
                                        formControlName="is_Accross_Company" (change)="changeAccrossCompany()">
                                    <label for="AccrossCompany">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label col-md-4">Prefix
                            <!-- <span class="required"> * </span> -->
                        </label>
                        <div class="col-md-3">
                            <input type="text" id="prefix" class="form-control" formControlName="prefix"
                                autocomplete="off" maxlength="200" (change)="displayEmpCode()" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-md-4">Total Length
                            <span class="required"> * </span>
                        </label>
                        <div class="col-md-3">
                            <input type="text" id="total_Digit" class="form-control" formControlName="total_Digit"
                                autocomplete="off" (change)="displayEmpCode()"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"' />
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label col-md-4">Employee Code
                            <!-- <span class="required"> * </span> -->
                        </label>
                        <div class="col-md-3">
                            <input type="text" id="displayCode" class="form-control" readonly />
                        </div>
                    </div>

                    
                </form>


            </div>
        </div>
    </div>

</div>