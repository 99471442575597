import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;


@Component({
  selector: 'app-probation',
  templateUrl: './probation.component.html'
})
export class ProbationComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  probationform: FormGroup;
  probationlist: any;
  filterList: any;
  companylist: Array<Select2OptionData>;
  listcall: boolean = true;
  title: string;
  p: number = 1;
  totalRecord: 0; 
  id:number=0;
  modalOpen:boolean=false;
  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.title = "Add";
    this.probationform = this.fb.group({
      prob_Id: ['0'],
      company_Id: ['', Validators.required],
      prob_Code: [''],
      prob_Name: ['', Validators.required],
      prob_Description: [''],
      prob_DurationUnit: ['', Validators.required],
      prob_Duration: ['1', Validators.required],
      notes: [''],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });

    if (this.listcall) {
      this.getProbationList();
      this.getCompanyList();
    }

    // this.commonService.select2SingleDropDown("companySelectListClass");
    // this.commonService.select2SingleDropDown("durationUnitSelectListClass");
     // this.commonService.select2SingleDropDown("durationUnitSelectListClass");

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }

  getProbationList(PageIndex = 1) {
    this.p = PageIndex;
    this.totalRecord = 0;
    this.probationlist = []
    this.commonApiService.getListWithFilter(Commonvariable.Probation, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.probationlist = result["data"]["list"];
        this.filterList = result["data"]["list"];

        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })
  }

  getCompanyList() {
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'company_Name'
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }

   ProbationDelete(Id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Probation, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getProbationList();
          this.toastr.success(response["message"], 'Probation');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Probation, Id, status).subscribe((response) => {
          
          if (response["status"] == 200) {
            this.getProbationList();
            this.toastr.success(response["message"], 'Probation');                        
          }

        })
      }

    });
  }
  
}
