import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { DepartmentListComponent } from './department-list/department-list.component';
import { SiteComponent } from './site/site.component';
import { ZoneComponent } from './zone/zone.component';
import { CompanyListComponent } from './company/company-list.component';
import { CompanyAddComponent } from './company/company-add.component';
import { EmployeeAddComponent } from './employee/employee-add.component';
import { DesignationComponent } from './designation/designation.component';
import { EmployeeComponent } from './employee/employee.component';
import { EmployeeProfileComponent } from './employee/employee-profile.component';
import { TobDescriptionComponent } from './jobDescription/jobdescription.component';
import { ShiftComponent } from './shift/shift.component';
import { ProbationComponent } from './probation/probation.component';
import { EthnicityComponent } from './ethnicity/ethnicity.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { InterviewRoundComponent } from './interview-round/interview-round.component';
import { BudgetComponent } from './budget/budget.component';
import { RelationshipComponent } from './relationship/relationship.component';
import { MaritalStatusComponent } from './marital-status/marital-status.component';
import { ContractComponent } from './contract/contract.component';
import { ChangePasswordComponent } from '../home/changePassword/change-password.component';
import { UnauthorizationComponent } from '../home/unauthorization/unauthorization.component';
import { UserComponent } from '../home/user/user.component';
import { ResetPasswordComponent } from '../home/reset-password/reset-password.component';
import { PayrollComponent } from '../pages/payroll/payroll.component';
import { UserRoleComponent } from '../pages/user_role/user_role.component';
import { EmpResignationComponent } from '../pages/employee/employee-resignation/employee-resignation.component';
import { ComingSoonComponent } from '../home/comingsoon/comingsoon.component';
import { AuditLogComponent } from '../pages/auditlog/auditlog.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { CandidateAddComponent } from './onboarding/onboarding-add/candidate-add.component';
import { CandidateEditComponent } from './onboarding/candidate-edit/candidate-edit.component';
import { VacancyComponent } from '../pages/vacancy/vacancy.component';
import { TimeoutComponent } from '../home/timeout/timeout.component';

import { AuthGuard } from 'src/app/helper/auth.guard';
// import { CanDeactivateGuard } from '../services/can-deactivate-guard.service';
import { RAFAddComponent } from '../pages/recruitment/raf-add/raf-add.component';
import { RafOverviewComponent } from '../pages/recruitment/raf-overview/raf-overview.component';
import { RafConfigComponent } from '../pages/recruitment/raf-config/raf-config.component';
import { AccessDeniedComponent } from '../home/405/405.component';


import { AddNewCandidateComponent } from '../pages/recruitment/add-new-candidate/add-new-candidate.component';
import { CandidateOverviewComponent } from '../pages/recruitment/candidate-overview/candidate-overview.component';

import { DocumentTypeComponent } from './documenttype/documenttype.component';
import { RightToWorkComponent } from './righttowork/righttowork.component';
import { NationalityComponent } from './nationality/nationality.component';
import { InterviewStageComponent } from './interview-stage/interview-stage.component';
import { GenderComponent } from './gender/gender.component';
import { LanguageComponent } from './language/language.component';
import { CurrenciesComponent } from './currencies/currencies.component';
import { CountryComponent } from './country/country.component';
import { ReasonComponent } from './reason-for-leave/reason.component';
import { ForceChangePasswordComponent } from '../home/forcechangepassword/forcechangepassword.component';
import { UserViewSummaryComponent } from './employee/user-view-summary/user-view-summary.component';

import { MenuPermissionComponent } from './menu-permission/menu-permission.component';
import { InterviewProcessComponent } from './recruitment/interviewprocess/interviewprocess.component';

import { SelectionRafWiseComponent } from './recruitment/selection-raf-wise/selection-raf-wise.component';
import { SelectionCandidateWiseComponent } from './recruitment/selection-candidate-wise/selection-candidate-wise.component';
import { SelectionStatusWiseComponent } from './recruitment/selection-status-wise/selection-status-wise.component';

import { MenupermissionComponent } from './menupermission/menupermission.component';

import { RafProcessComponent } from './recruitment/raf-process/raf-process.component';

import { AddLeaveComponent } from '../pages/leave-management/add-leave/add-leave.component';
import { LeaveDashboardComponent } from '../pages/leave-management/leave-dashboard/leave-dashboard.component';
import { LeavesComponent } from '../pages/leave-management/leaves/leaves.component';

import { LeaveApprovalComponent } from '../pages/leave-management/leave-approval/leave-approval.component';

import { HolidayComponent } from '../pages/holiday/holiday.component';
import { LeaveTypeComponent } from '../pages/leave-type/leave-type.component';

import { ReviewPersonComponent } from './review-person/review-person.component'

import { CompanySelectionComponent } from './../home/company-selection/company-selection.component'
import { LeaveRuleComponent } from './leaverule/leaverule.component'

import { RecruitmentSelectionComponent } from './recruitment/recruitmentSelection/recruitmentSelection.component';

import { LeaveUploadComponent } from '../pages/leave-management/leave-upload/leave-upload.component';
import { LeaveProcessComponent } from '../pages/leave-management/leave-process/leave-process.component';
import { MasterUploadComponent } from './../pages/masterupload/masterupload.component';
import { RtwstatusListComponent } from './../pages/rtwstatus-list/rtwstatus-list.component';
//import { SignupComponent } from '../home/signup/signup.component';

import { SignupListComponent } from './notification/signup-list/signup-list.component';
import { UserRequestListComponent } from './notification/user-request-list/user-request-list.component';

import {AccessibilityComponent} from './accessibility/accessibility.component'
import { ReviewHistoryPersonComponent } from './reviewhistory-person/reviewhistory-person.component';
import { ChangereqFormComponent } from './../pages/changereq-form/changereq-form.component';
import { LeaveAddComponent } from './leave-add/leave-add.component';
import { HrPoliciesDocumentComponent } from './../pages/hr-policies-document/hr-policies-document.component';
import { HrPoliciesFormComponent } from './../pages/hr-policies-document/hr-policies-form/hr-policies-form.component';


//import { RtwstatusListComponent } from './rtwstatus-list/rtwstatus-list.component';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'department', component: DepartmentListComponent, canActivate: [AuthGuard] },
  { path: 'site', component: SiteComponent, canActivate: [AuthGuard] },
  { path: 'zone', component: ZoneComponent, canActivate: [AuthGuard] },
  { path: 'companylist', component: CompanyListComponent, canActivate: [AuthGuard] },
  { path: 'designation', component: DesignationComponent, canActivate: [AuthGuard] },
  { path: 'job_Description', component: TobDescriptionComponent, canActivate: [AuthGuard] },
  { path: 'shift', component: ShiftComponent, canActivate: [AuthGuard] },
  { path: 'contract', component: ContractComponent, canActivate: [AuthGuard] },
  { path: 'companyadd', component: CompanyAddComponent, canActivate: [AuthGuard] },
  { path: 'probation', component: ProbationComponent, canActivate: [AuthGuard] },
  { path: 'ethnicity', component: EthnicityComponent, canActivate: [AuthGuard] },
  { path: 'feedback', component: FeedbackComponent, canActivate: [AuthGuard] },
  { path: 'interview-round', component: InterviewRoundComponent, canActivate: [AuthGuard] },
  { path: 'budget', component: BudgetComponent, canActivate: [AuthGuard] },
  { path: 'relationship', component: RelationshipComponent, canActivate: [AuthGuard] },
  { path: 'marital-status', component: MaritalStatusComponent, canActivate: [AuthGuard] },
  { path: 'employee', component: EmployeeComponent, canActivate: [AuthGuard] },
  { path: 'employeeadd', component: EmployeeAddComponent, canActivate: [AuthGuard] },/*,canDeactivate: [CanDeactivateGuard]*/
  { path: 'employeeProfile', component: EmployeeProfileComponent, canActivate: [AuthGuard] },
  { path: 'raf-overview', component: RafOverviewComponent, canActivate: [AuthGuard] },
  { path: 'changepassword', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'payroll', component: PayrollComponent, canActivate: [AuthGuard] },
  { path: 'user_role', component: UserRoleComponent, canActivate: [AuthGuard] },
  { path: 'empResignation', component: EmpResignationComponent, canActivate: [AuthGuard] },
  { path: 'auditlog', component: AuditLogComponent, canActivate: [AuthGuard] },
  { path: 'onboarding', component: OnboardingComponent, canActivate: [AuthGuard] },
  { path: 'candidateadd', component: CandidateAddComponent, canActivate: [AuthGuard] },
  { path: 'candidateedit', component: CandidateEditComponent, canActivate: [AuthGuard] },
  { path: 'vacancy', component: VacancyComponent, canActivate: [AuthGuard] },
  { path: 'raf-add', component: RAFAddComponent, canActivate: [AuthGuard] },
  { path: 'raf-config', component: RafConfigComponent, canActivate: [AuthGuard] },
  { path: 'candidateoverview', component: CandidateOverviewComponent, canActivate: [AuthGuard] },
  { path: 'resetpassword', component: ResetPasswordComponent },
  { path: '401', component: UnauthorizationComponent },
  { path: 'comingsoon', component: ComingSoonComponent },
  { path: 'session_expired', component: TimeoutComponent },
  { path: '405', component: AccessDeniedComponent },
  //{path:'signup',component:SignupComponent},

  { path: 'addnewcandidate', component: AddNewCandidateComponent, canActivate: [AuthGuard] },
  { path: 'documenttype', component: DocumentTypeComponent, canActivate: [AuthGuard] },
  { path: 'righttowork', component: RightToWorkComponent, canActivate: [AuthGuard] },
  { path: 'nationality', component: NationalityComponent, canActivate: [AuthGuard] },
  { path: 'interview-stage', component: InterviewStageComponent, canActivate: [AuthGuard] },  
  { path: 'gender', component: GenderComponent, canActivate: [AuthGuard] },
  { path: 'language', component: LanguageComponent, canActivate: [AuthGuard] },
  { path: 'currencies', component: CurrenciesComponent, canActivate: [AuthGuard] },
  { path: 'country', component: CountryComponent, canActivate: [AuthGuard] },
  { path: 'reason-for-leave', component: ReasonComponent, canActivate: [AuthGuard] },

  { path: 'frocechangepassword', component: ForceChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'userviewsummary', component: UserViewSummaryComponent, canActivate: [AuthGuard] },
  { path: 'menupermission', component: MenuPermissionComponent, canActivate: [AuthGuard] },
  { path: 'interviewprocess', component: InterviewProcessComponent, canActivate: [AuthGuard] },

  { path: 'selection-raf-wise', component: SelectionRafWiseComponent, canActivate: [AuthGuard] },
  { path: 'selection-candidate-wise', component: SelectionCandidateWiseComponent, canActivate: [AuthGuard] },
  { path: 'selection-status-wise', component: SelectionStatusWiseComponent, canActivate: [AuthGuard] },
  

  { path: 'menu-permission', component: MenupermissionComponent, canActivate: [AuthGuard] },

  { path: 'raf-process', component: RafProcessComponent, canActivate: [AuthGuard] },

  { path: 'add-leave', component: AddLeaveComponent, canActivate: [AuthGuard] },
  { path: 'leave-dashboard', component: LeaveDashboardComponent, canActivate: [AuthGuard] },
  { path: 'leaves', component: LeavesComponent, canActivate: [AuthGuard] },
  { path: 'leave-approval', component: LeaveApprovalComponent, canActivate: [AuthGuard] },


  { path: 'holiday', component: HolidayComponent, canActivate: [AuthGuard] },
  { path: 'leave-type', component: LeaveTypeComponent, canActivate: [AuthGuard] },

  { path: 'review-person', component: ReviewPersonComponent, canActivate: [AuthGuard] },
  { path: 'reviewhistory-person', component: ReviewHistoryPersonComponent},
  { path: 'company-selection', component: CompanySelectionComponent, canActivate: [AuthGuard] },
  { path: 'leaverule', component: LeaveRuleComponent, canActivate: [AuthGuard] },
  { path: 'recruitmentselection', component: RecruitmentSelectionComponent, canActivate: [AuthGuard] },

  { path: 'leave-upload', component: LeaveUploadComponent, canActivate: [AuthGuard] },
  { path: 'leave-process', component: LeaveProcessComponent, canActivate: [AuthGuard] },
  
  { path: 'masterupload', component: MasterUploadComponent, canActivate: [AuthGuard] },

  { path: 'signup-notifications', component: SignupListComponent, canActivate: [AuthGuard] },
  { path: 'user-request', component: UserRequestListComponent, canActivate: [AuthGuard] },
  {path:'accessibility',component:AccessibilityComponent,canActivate:[AuthGuard]},
  { path: 'rtwvisastaustype', component: RtwstatusListComponent, canActivate: [AuthGuard] },
  { path: 'changereq-form', component: ChangereqFormComponent, canActivate: [AuthGuard] },
  {path: 'leaveAdd', component: LeaveAddComponent},
  {path: 'hr-policies-document', component: HrPoliciesDocumentComponent, canActivate: [AuthGuard]},
  {path: 'hr-policies-form', component: HrPoliciesFormComponent, canActivate: [AuthGuard]},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
