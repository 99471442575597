<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Ethnicity List
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->

        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a href="javascript:;" (click)="openModel(0)" class="btn btn-transparent blue  btn-sm">Add
                            Ethnicity</a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" id="filterList" placeholder="Type to filter ..."
                                (input)="getEthnicityList()">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblZone">
                            <thead class="flip-content">
                                <tr>

                                    <th class="text-center"> Ethnicity </th>
                                    
                                    <!-- <th class="text-center" style="width: 20%;"> Language </th> -->
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody
                                *ngFor="let item of ethnicitylist| paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">
                                <tr>

                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(item.id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12">
                                            {{item.ethnicity_Name}}</div>
                                    </td>
                                    <!-- <td>{{item.language}}</td> -->
                                    <td class="text-center">
                                        <a *ngIf="item.isActive" href="javascript:;" (click)="openModel(item.id)"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Ethnicity"></i></a>
                                        <a *ngIf="!item.isActive" href="javascript:;"
                                            (click)="EthnicityDelete(item.id)">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Delete Ethnicity"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="ethnicitylist != null">
                        <pagination-controls (pageChange)="getEthnicityList($event)" autoHide="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->


<div class="modal fade draggable-modal" id="draggable"  role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Ethnicity</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="ethnicityform">
                    <div class="row">
                        <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Ethnicity Code<span class="required"> *
                                    </span></label>
                                <input type="text" id="ethnicity_Code" class="form-control"
                                    formControlName="ethnicity_Code" autocomplete="off">
                            </div>
                        </div> -->
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Ethnicity_Label<span class="required"> *
                                </span></label>
                                <select class="form-control" id="ethinicity_Label" formControlName="ethinicity_Label">
                                    <option value="">Select Ethnicity Label</option>
                                    <option value="Prefer Not to Say">Prefer Not to Say</option>
                                    <option value="Asian Or Asian British">Asian Or Asian British</option>
                                    <option value="Black or Black British">Black or Black British</option>
                                    <option value="Mixed Heritage">Mixed Heritage</option>
                                    <option value="White">White</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Ethnicity<span class="required"> *
                                    </span></label>
                                <input type="text" id="ethnicity_Name" class="form-control"
                                    formControlName="ethnicity_Name" maxlength="30" autocomplete="off">
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Notes</label>
                                <textarea id="notes" class="form-control" rows="2" formControlName="notes"
                                    style="height: 35px;width: 100%;;"></textarea>
                            </div>
                        </div> -->
                    </div>
                    <div class="row">

                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Language <span class="required"> *
                                    </span></label>
                                <ng-select2 [data]="languageList" id="language" (valueChanged)="setFormData($event)"
                                    [value]="ethnicityform.controls.language.value" [placeholder]="'--- Select ---'">
                                </ng-select2>

                            </div>
                        </div> -->

                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Notes</label>
                                <textarea id="notes" class="form-control" rows="2" formControlName="notes"
                                    style="height: 35px;width: 100%;;"></textarea>
                            </div>
                        </div>
                       
                        <div class="col-md-2">
                            <div class="form-group">
                                <label class="control-label">Active</label>
                                <div class="md-checkbox-inline">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="checkbox1_3" class="md-check"
                                            formControlName="isActive">
                                        <label for="checkbox1_3">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">


                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn dark btn-outline pull-left" (click)="history()"
                    *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="EthnicityUpsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Ethnicity History</h4>
            </div>
            <div class="modal-body" *ngIf="title == 'Edit'">
                <app-history></app-history>
            </div>

        </div>
    </div>
</div>