import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { CommonService } from 'src/app/services/common.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HistoryComponent } from 'src/app/pages/history/history.component';
declare var $: any;


@Component({
  selector: 'app-accessibility',
  templateUrl: './accessibility.component.html'
})
export class AccessibilityComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  pageName = "Accessibility";
  title = "Add";
  companylist: Array<Select2OptionData>;
  setcompanyName: "";
  accessibilityForm: FormGroup;
  p: number = 1;
  totalRecord: 0;
  accessibilityList: any[] = [];

  constructor(private commonApiService: CommonApiService,
    private fb: FormBuilder,
    private commonService: CommonService) { }

  ngOnInit() {
    this.title = "Add"
    this.accessibilityForm = this.fb.group({
      id: ['0'],
      company_Id: [''],
      reporting: [''],
      reportingId: 0,
      deputy: [''],
      deputyId: 0,
    });
    this.getAccessibilityList();
  }

  getAccessibilityList(PageIndex = 1) {
    this.p = PageIndex;
    this.totalRecord = 0;
    this.accessibilityList = []
    this.commonApiService.getListWithFilter(Commonvariable.Accessibility, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        var tempList = result["data"]["list"];
        tempList.forEach(x => {
          let tempRecord = {
            "companyId": x[0]["company_Id"],
            "companyName": x[0]["company_Name"],
            "reporting": x[0]["reporting"],
            "deputy": x[1]["deputy"],
          }
          this.accessibilityList.push(tempRecord)
        });
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })
  }



  getCompanyList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'company_Name'
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }


  openModel(Id) {
    this.getCompanyList();
    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Accessibility, Id, "GetByCompany").subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          this.setcompanyName = editData[0]["company_Name"];
          this.accessibilityForm.controls.company_Id.setValue(editData[0]["company_Id"]);
          this.accessibilityForm.controls.reporting.setValue(editData[0]["rule"]);
          this.accessibilityForm.controls.reportingId.setValue(editData[0]["id"]);
          this.accessibilityForm.controls.deputy.setValue(editData[1]["rule"]);
          this.accessibilityForm.controls.deputyId.setValue(editData[1]["id"]);
        }
      })
    }
    else {
      this.ngOnInit()
    }

    $('#modalAccessibility').modal({
      backdrop: 'static'
    });
  }

  upsert() {
    var formData = this.accessibilityForm.value
    var requestData = {
      "Company_Id": String(formData.company_Id),
      "accessibilities": [
        {
          "Id": formData.reportingId,
          "Name": "Reporting",
          "Rule": formData.reporting,
          "isActive": 1,
          "AddedBy": Commonvariable.User_Id,
          "UpdatedBy": Commonvariable.User_Id,
        },
        {
          "Id": formData.deputyId,
          "Name": "Deputy",
          "Rule": formData.deputy,
          "isActive": 1,
          "AddedBy": Commonvariable.User_Id,
          "UpdatedBy": Commonvariable.User_Id,
        }
      ]
    }

    if (this.validation(requestData)) {
      this.commonApiService.Upsert(Commonvariable.Accessibility, requestData, requestData.accessibilities[0].Id).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.commonService.toaster("warning", 'Record is already exists !', this.pageName)
          }
          else {
            $("#modalAccessibility").modal('hide');
            this.getAccessibilityList();
            this.commonService.toaster("success", requestData.accessibilities[0].Id > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, this.pageName)
          }
        }
      })
    }
  }


  setFormData(fieldName, id) {
    if (id != undefined) {
      if (fieldName == 'companyid') {
        this.accessibilityForm.controls.company_Id.setValue(id)
      }
    }
  }




  delete(id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Accessibility, id, "DeleteByCompany").subscribe((response) => {
          $("#modalAccessibility").modal('hide');
          this.getAccessibilityList();
          this.commonService.toaster("success", Commonvariable.DeleteMsg, this.pageName);
          this.p = 1
        })
      }
    })
  }


  validation(formData) {
    if (($("#accessibilitycompanyid select").val() == null || $("#accessibilitycompanyid select").val() == '') && this.title == "Add") {
      this.commonService.toaster("info", "Company is required !", this.pageName);
      $("#accessibilitycompanyid select").focus()
      return false;
    }

    if (formData.accessibilities[0]["Rule"] == null || formData.accessibilities[0]["Rule"] == "") {
      this.commonService.toaster("info", "Reporting  is required !", this.pageName);
      return false;
    }

    if (formData.accessibilities[1]["Rule"] == null || formData.accessibilities[1]["Rule"] == "") {
      this.commonService.toaster("info", "Deputy is required !", this.pageName);
      return false;
    }

    return true;
  }
  history() {
    this.historyView.getHistory("accessibility", this.accessibilityForm.controls.id.value)
  }

}
