<form [formGroup]="empform">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Contract Type<span class="required"> * </span></label>
                <select id="emp_Contract_Code" class="form-control" formControlName="contract_Id" >
                    <option value="">Select Contract</option>
                    <option *ngFor="let item of contractlist;" value={{item.contract_Id}}>
                        {{item.contract_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Role Start<span class="required"> * </span></label>
                <div class="input-group input-medium" id="flatpickropenERstartD" data-wrap="true">
                    <input type="text" class="form-control readonlyClass cstartdate" id="emp_Role_Start"  data-input disabled>
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Role End</label>
                <div class="input-group input-medium" id="flatpickropenERendD" data-wrap="true">
                    <input type="text" class="form-control readonlyClass cstartdate" id="emp_Role_End"  data-input disabled>
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <!-- <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Contract Start<span class="required"> * </span></label>
                <div class="input-group input-medium" id="flatpickropenECstartD" data-wrap="true">
                    <input type="text" class="form-control readonlyClass cstartdate" id="emp_Contract_Start" readonly (change)="onChangeDate()" data-input>
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div> -->
    </div>
    <div class="row">
        <!-- <div class="col-md-4">
            <div class="form-group">
               
                <label class="control-label">Length of Contract</label>
                <input type="text" class="form-control" id="emp_Contract_Days" formControlName="emp_Contract_Days" oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');" (input)="onChangeDays($event.target.value)"
                    maxlength="3" minlength="1">
            </div>
        </div> -->
        <!-- 
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Contract End
                     <span class="required"> * </span> 
                </label>
                <div class="input-group input-medium" id="flatpickropenECendD" data-wrap="true">
                    <input type="text" class="form-control readonlyClass cenddate" id="emp_Contract_End" readonly data-input>
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div> -->
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Continuous Service Date</label>
                <div class="input-group input-medium" id="flatpickropenEjoiD" data-wrap="true">
                <input type="text" class="form-control readonlyClass cstartdate" id="emp_Joining_Date"  data-input disabled>
                <span class="input-group-btn" data-toggle>
                    <button class="btn default" type="button">
                        <i class="fa fa-calendar"></i>
                    </button>
                </span>
            </div>

            <!-- <div class="row">
                <div class="col-md-6">
                    <button class="cudtom-d-inline-block custom-btn-ico" (click)="decrementYear()">-</button>
                    <input type="text" class="form-control cudtom-d-inline-block custom_input" value={{this.employee_length_service_year}} readonly>
                    <button class="cudtom-d-inline-block custom-btn-ico" (click)="incrementYear()">+</button>
                </div>

                <div class="col-md-6">
                    <button class="cudtom-d-inline-block custom-btn-ico" (click)="decrementMonth()" >-</button>
                    <input type="text" class="form-control cudtom-d-inline-block custom_input" value="{{this.employee_length_service_month}}" readonly min="1" max="12">
                    <button class="cudtom-d-inline-block custom-btn-ico" (click)="incrementMonth()">+</button>
                </div>

            </div> -->
           
                

                


            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Job Title</label>

                <input type="text" class="form-control" id="emp_Job_Title" formControlName="emp_Job_Title" disabled>

            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Zone</label>
                <select id="emp_Zone_Id" class="form-control" formControlName="emp_Zone_Id" disabled>
                    <option value="">Select Zone</option>
                    <option *ngFor="let item of zoneList;" value={{item.zone_Id}}>
                        {{item.zone_Name}}</option>
                </select>
            </div>
        </div>

        <div class="col-md-2">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="empContract" class="md-check" formControlName="isActive" disabled>
                            <label for="empContract">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>