<style>
    td.details-control {
        background: url('assets/pages/img/SD7Dz.png') no-repeat center center;
        cursor: pointer;
    }

    tr.shown td.details-control {
        background: url('assets/pages/img/d4ICC.png') no-repeat center center;
    }
</style>
<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>Candidate List</h1>
            </div>
        </div>
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li style="visibility: hidden;">
                    <div>
                        <a [href]="fileUrl" id="download" class="btn btn-transparent blue  btn-sm"
                            [download]="fileName">Export</a>
                    </div>
                </li>
                <li>
                    <div>
                        <a data-toggle="modal" href="#draggable" class="btn btn-transparent blue  btn-sm mrl10"
                            data-backdrop="static" data-keyboard="false">Advanced
                            Search</a>
                    </div>
                </li>
                <li>
                    <div>
                        <a routerLink="/addnewcandidate" class="btn btn-transparent blue  btn-sm">New Candidate</a>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="search" class="form-control" placeholder="Type to filter ..." id="filterList"
                                autocomplete="off" (input)="getCandidateList(1)">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblZone">
                            <thead class="flip-content">
                                <tr>
                                    <th class="text-center"> Candidate Name </th>
                                    <th class="text-center">Position Applied For</th>
                                    <th class="text-center">Expected Salary</th>
                                    <th class="text-center">Notice Period</th>


                                    <!-- <th class="text-center">Tags</th> -->
                                    <th class="text-center" style="width: 5%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of candidateList | paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord };let i = index;">

                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(item.id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12">
                                            {{item.firstName}} {{item.lastName}}
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <aside *ngIf="item.applied_For !=null" data-toggle="tooltip"
                                            data-placement="bottom" title="{{item.applied_For}}" class="text-center">
                                            {{item.applied_For ? (item.applied_For.length>50)? (item.applied_For |
                                            slice:0:50)+'...':(item.applied_For) : ''}}
                                        </aside>
                                    </td>
                                    <td>{{item.expected_Salary}}</td>
                                    <td><span *ngIf="item.noticeDay">{{item.noticeDay}} Days</span><span
                                            *ngIf="item.noticeMonth"> {{item.noticeMonth}} Months</span>
                                        <!-- <span *ngIf="item.noticeYear"> {{item.noticeYear}} Years </span> -->
                                    </td>

                                    <!-- <td>
                                        <div>{{item.tags}}</div>
                                    </td> -->
                                    <td class="text-left">
                                        <!-- <a><i class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                [routerLink]="['/addnewcandidate']" [queryParams]="{editId: item.id}" data-placement="top"
                                                title="Edit Candidate"></i></a>&nbsp; -->
                                        <a (click)="viewSummary(item.id)"><i
                                                class="fa fa-list-alt purple-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="View Candidate"></i></a>&nbsp;
                                        <a *ngIf="item.isActive" (click)="editCandidate(item.id)"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Candidate"></i></a>
                                        <a *ngIf="!item.isActive" (click)="candidateDelete(item.id)"><i
                                                class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Delete Candidate"></i></a>&nbsp;

                                        <a *ngIf="item.isActive" (click)="directOpenMoveToOnboarding(item.id)"><i
                                                class="fa fa-paper-plane-o iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Move To Onboarding"></i></a>&nbsp;

                                        <a (click)="downloadDocument(item.id)" data-toggle="modal"
                                            *ngIf="item.cv != null && item.cv != '' "><i
                                                class="fa fa-download purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Download CV"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="candidateList!= null">
                        <pagination-controls (pageChange)="getCandidateList($event)" autoHide="true">
                        </pagination-controls>
                    </div>

                    <!-- <div class="table-responsive">
                        <table id="example2" class="display table table-striped table-bordered" cellspacing="0"
                            width="100%" style="border:1px solid #e7ecf1;">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class="text-center"> Candidate Name </th>
                                    <th class="text-center">Position Applied For</th>
                                    <th class="text-center">Expected Salary</th>
                                    <th class="text-center">Notice Period</th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>

                            <tbody
                                *ngFor="let item of candidateList | paginate: { itemsPerPage: itemsPerPage, currentPage: p ,totalItems :totalRecord, id: 'pagination1' }">
                                <tr id="{{item.id}}" role="row" class="odd" title="{{item.vacancy_Rule_Id}}"
                                    tabindex="{{item.company_Id}}">
                                    <td class="details-control details-control-icon-{{item.id}}"></td>
                                    <td class="sorting_1">

                                        <div class="col-md-1 col-xs-12">
                                            <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                                data-placement="top" title="Active"></div>
                                            <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                                data-placement="top" title="Inactive"></div>
                                        </div>
                                        &nbsp;&nbsp;
                                        {{item.firstName}} {{item.lastName}}
                                    </td>
                                    <td>{{item.applied_For}}</td>
                                    <td>{{item.expected_Salary}}</td>
                                    <td>{{item.noticePeriod}} {{item.noticePeriodType}}</td>
                                    <td class="text-center">
                                        <a><i class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                [routerLink]="['/addnewcandidate']" [queryParams]="{ editId: item.id}"
                                                skipLocationChange="true" data-placement="top"
                                                title="Edit Candidate"></i></a>&nbsp;
                                        <a (click)="viewSummary(item.id)"><i
                                                class="fa fa-eye yellow-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="View Candidate"></i></a>&nbsp;
                                        <a (click)="candidateDelete(item.id)"><i
                                                class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Delete Candidate"></i></a>
                                    </td>
                                </tr>
                                <tr class="sub_tbl sub_tbl_{{item.id}}" style="display:none;">
                                    <td colspan="10">
                                        <table cellpadding="5" cellspacing="0" style="width:80%;"
                                            class="table table-striped table-bordered dataTable no-footer">
                                            <thead>
                                                <tr>
                                                    <th>Job Title</th>
                                                    <th>Approver Name</th>
                                                    <th>Final Approver</th>
                                                    <th>Priority</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let items of rulesList">
                                                    <td>{{items.desig_Name}}</td>
                                                    <td>{{items.emp_Name}}</td>
                                                    <td>{{items.isFinal === 1 ? 'Yes' : 'No'}}</td>
                                                    <td>{{items.priority}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->



<div class="modal fade draggable-modal" id="draggable" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" (click)="clear()" aria-hidden="true"></button>
                <h4 class="modal-title">Advanced Search</h4>
            </div>
            <div class="modal-body">
                <app-canAdvancedSearch></app-canAdvancedSearch>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn dark btn-outline" (click)="clear()" title="Clear All">Clear</button>
                <button type="button" (click)="advanceFilter()" class="btn blue">Search</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade draggable-modal" id="viewSummary" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" *ngIf="openPopup" style="margin-top: 5%;">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Candidate Summary </h4>
            </div>
            <div class="modal-body" *ngIf="viewSummaryList != null">

                <div class="row">
                    <div class="form-group form-md-line-input">
                        <label class="col-md-2 control-label" for="form_control_1">Title</label>
                        <div class="col-md-4">
                            <div class="md-radio-inline">
                                <div class="md-radio">
                                    <input type="radio" id="radio53" name="Title" value="Mr." class="md-radiobtn"
                                        [checked]="this.viewSummaryList.title == 'Mr.'" disabled>
                                    <label for="radio53">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Mr. </label>
                                </div>
                                <div class="md-radio has-error">
                                    <input type="radio" id="radio54" name="Title" value="Mrs" class="md-radiobtn"
                                        [checked]="this.viewSummaryList.title == 'Mrs'" disabled>
                                    <label for="radio54">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Mrs. </label>
                                </div>
                                <div class="md-radio has-warning">
                                    <input type="radio" id="radio55" name="Title" value="Miss" class="md-radiobtn"
                                        [checked]="this.viewSummaryList.title == 'Miss'" disabled>
                                    <label for="radio55">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Miss. </label>
                                </div>
                                <div class="md-radio has-info">
                                    <input type="radio" id="radio56" name="Title" value="Ms" class="md-radiobtn"
                                        [checked]="this.viewSummaryList.title == 'Ms'" disabled>
                                    <label for="radio56">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Ms. </label>
                                </div>
                                <div class="md-radio has-info">
                                    <input type="radio" id="radio57" name="Title" value="Ms" class="md-radiobtn"
                                        [checked]="this.viewSummaryList.title == 'Ms'" disabled>
                                    <label for="radio57">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Dr. </label>
                                </div>
                                <div class="md-radio has-warning">
                                    <input type="radio" id="radio58" name="Title" value="Other" class="md-radiobtn"
                                        [checked]="this.viewSummaryList.title == 'Other'" disabled>
                                    <label for="radio58">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Other </label>
                                </div>
                            </div>
                        </div>
                        <!-- <label class="col-md-2 control-label" for="form_control_1">Gender
                        </label>
                        <div class="col-md-4">
                            <div class="md-radio-inline">
                                <div class="md-radio">
                                    <input type="radio" id="radio577" class="md-radiobtn" name="Gender_Id" value="1"
                                        [checked]="this.viewSummaryList.gender_Id == '1'" disabled>
                                    <label for="radio577">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Male </label>
                                </div>
                                <div class="md-radio has-error">
                                    <input type="radio" id="radio58" class="md-radiobtn" name="Gender_Id" value="2"
                                        [checked]="this.viewSummaryList.gender_Id == '2'" disabled>
                                    <label for="radio58">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Female </label>
                                </div>
                                <div class="md-radio has-warning">
                                    <input type="radio" id="radio59" class="md-radiobtn" name="Gender_Id" value="3"
                                        [checked]="this.viewSummaryList.gender_Id == '3'" disabled>
                                    <label for="radio59">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Others </label>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="row">
                    <div class="form-group">
                        <label class="control-label col-md-2">First Name</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.viewSummaryList.firstName}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Middle Name</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.viewSummaryList.middleName}}" readonly />
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label col-md-2">Last Name</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.viewSummaryList.lastName}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Email</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.viewSummaryList.eMail}}" readonly />
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label col-md-2">Mobile</label>
                        <div class="col-md-4 form-group">


                            <div class="input-group mobilenowithcountryode">

                                <div class="input-group-btn">
                                    <ng-select2 [data]="countryList" id="Country_Id"
                                        [value]="this.viewSummaryList.country_Id" [disabled]="true"
                                        [placeholder]="'--- Select ---'">
                                    </ng-select2>
                                </div>

                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                    value="{{this.viewSummaryList.mobile}}" readonly />
                            </div>

                        </div>

                        <label class="control-label col-md-2">Expected Salary</label>
                        <div class="col-md-4 form-group">


                            <div class="input-group mobilenowithcountryode">
                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                    value="{{this.viewSummaryList.expected_Salary}}" readonly />
                                <div class="input-group-btn">
                                    <ng-select2 [data]="currenciesList" id="currency_Id"
                                        [value]="this.viewSummaryList.currency_Id" [placeholder]="'--- Select ---'"
                                        [disabled]="true">
                                    </ng-select2>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="form-group">

                        <label class="control-label col-md-2">Right To Work
                        </label>
                        <div class="col-md-4 form-group">
                            <div class="md-radio-inline">
                                <div class="md-radio">
                                    <input type="radio" id="radio60" name="isRightToWork" class="md-radiobtn" value="1"
                                        [checked]="this.viewSummaryList.isRightToWork == '1'" disabled>
                                    <label for="radio60">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> Yes </label>
                                </div>
                                <div class="md-radio has-error">
                                    <input type="radio" id="radio61" name="isRightToWork" class="md-radiobtn" value="0"
                                        [checked]="this.viewSummaryList.isRightToWork == '0'" disabled>
                                    <label for="radio61">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> No </label>
                                </div>
                            </div>
                        </div>



                    </div>

                    <div class="form-group">
                        <label class="control-label col-md-2">Skills</label>
                        <div class="col-md-4 form-group">
                            <ng-select2 [data]="skillsArr" id="Job_Category" [options]="{multiple: true,tags: false}"
                                [value]='skillsArr' [placeholder]="" [disabled]="true">
                            </ng-select2>
                        </div>


                        <label class="control-label col-md-2">Department</label>
                        <div class="col-md-4 form-group">

                            <ng-select2 [data]="jobCategorArr" id="Job_Category"
                                [options]="{multiple: true,tags: false}" [value]='jobCategorArr' [placeholder]=""
                                [disabled]="true">
                            </ng-select2>

                        </div>

                    </div>

                    <div class="form-group">



                        <label class="control-label col-md-2">Job Title</label>
                        <div class="col-md-4 form-group">

                            <ng-select2 [data]="jobLevelArr" id="Job_Level" [options]="{multiple: true,tags: false}"
                                [value]='jobLevelArr' [placeholder]="" [disabled]="true">
                            </ng-select2>

                        </div>



                        <!-- <label class="control-label col-md-2">Preferred Location</label>
                        <div class="col-md-4 form-group">
                            <ng-select2 [data]="prefereLocationArr" id="Job_Category"
                                [options]="{multiple: true,tags: false}" [value]='prefereLocationArr' [placeholder]=""
                                [disabled]="true">
                            </ng-select2>
                        </div> -->

                        <label class="control-label col-md-2">Experience</label>
                        <div class="col-md-4 form-group">
                            <select class="form-control readonlyClass" id="Experience" disabled>
                                <option value=''>Select Experience</option>
                                <option value='< 1 year' [selected]="this.viewSummaryList.experience == '< 1 year'">
                                    < 1 year</option>
                                <option value='1-2 years' [selected]="this.viewSummaryList.experience == '1-2 years'">
                                    1-2 years</option>
                                <option value='3-5 years' [selected]="this.viewSummaryList.experience == '3-5 years'">
                                    3-5 years</option>
                                <option value='5-10 years' [selected]="this.viewSummaryList.experience == '5-10 years'">
                                    5-10 years</option>
                                <option value='10+ years' [selected]="this.viewSummaryList.experience == '10+ years'">
                                    10+ years
                                </option>
                            </select>
                        </div>


                    </div>



                </div>

                <div class="row">
                    <div class="form-group">


                        <label class="control-label col-md-2">Notice Period
                        </label>
                        <div class="col-md-6 form-group">
                            <div class="input-group">
                                <input type="text" class="form-control readonlyClass" id="NoticeDay" min="0" max="31"
                                    maxlength="2" autocomplete="off"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.key === 'Backspace'"
                                    value="{{this.viewSummaryList.noticeDay}}" readonly>
                                <span class="input-group-addon inputaddon">Days</span>
                                <input type="text" class="form-control readonlyClass" id="NoticeMonth" maxlength="2"
                                    autocomplete="off"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.key === 'Backspace'"
                                    value="{{this.viewSummaryList.noticeMonth}}" readonly>
                                <span class="input-group-addon inputaddon">Months</span>
                                <!-- <input type="text" class="form-control readonlyClass" id="NoticeYear" maxlength="4"
                                    autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.key === 'Backspace'"
                                    value="{{this.viewSummaryList.noticeYear}}" readonly>
                                <span class="input-group-addon inputaddon">Years</span> -->

                            </div>
                        </div>
                    </div>
                </div>

                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <strong>Applied For &nbsp; : &nbsp; </strong>
                        </h4>
                    </div>
                    <div id="accordion1_9" class="panel-collapse collapse in">

                        <div class="panel-body">
                            <div class="table-responsive">
                                <table class="table table-sm  table-striped table-bordered table-hover">
                                    <thead class="flip-content">
                                        <tr>
                                            <th class="text-center"> Vacancy Name</th>
                                            <th class="text-center"> Joining Date</th>
                                            <th class="text-center"> Reporting Person</th>
                                            <th class="text-center"> Salary</th>
                                            <th class="text-center"> Status</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let item of viewSummaryList.candidate_vacancy_link">
                                        <tr [style.background-color]="item.statusBackGroundColor"
                                            [style.color]="item.statusFrontColor">
                                            <td>{{item.desig_Name}}</td>
                                            <td>{{item.joiningDate | date :'dd/MM/yyyy'}}</td>

                                            <td>{{item.name}}</td>
                                            <td class="text-center">{{item.salary}} {{item.salaryType}}</td>
                                            <td class="text-center">{{item.status}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <strong>Notes &nbsp; : &nbsp; </strong>
                        </h4>
                    </div>
                    <div id="accordion1_9" class="panel-collapse collapse in">

                        <div class="panel-body">
                            <div class="table-responsive">
                                <table class="table table-sm  table-striped table-bordered table-hover">
                                    <thead class="flip-content">
                                        <tr>
                                            <th class="text-center"> Note</th>
                                            <th class="text-center"> Added By</th>
                                            <th class="text-center"> Added On</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let item of viewSummaryList.candidate_Note">
                                        <tr>
                                            <td>{{item.notes}}</td>
                                            <td>{{item.addedBy_Name}}</td>
                                            <td>{{item.addedOn | date :'dd/MM/yyyy'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click) ="closepoopup()" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="candidateCvModal" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Candidate CV</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <iframe [src]='fileUrl' width="100%" height="600px"></iframe>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade draggable-modal" id="modalMovetoOnboarding" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Move To Onboarding</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <table class="table">

                            <thead>
                                <tr>
                                    <th>Vacancy Name</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of candidateVacancyList">
                                    <td>{{item.desig_Name}}</td>
                                    <td>{{item.status}}</td>
                                    <td>
                                        <input type="radio" name="mto" value="{{item.vacancy_Id}}"
                                            (click)="getReportingEmpList(item.company_Id,item.dept_Id,item.desig_Id,item.salaryFrom,item.salaryTo,item.currency_Code,item.salaryType,item.vacancy_Id)">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Salary
                                <!-- <span class="required"> *</span> -->
                            </label>
                            <div class="input-group">
                                <input type="number" min="0" step="0.01" id="candidateOverviewSalary"
                                    class="form-control" autocomplete="off" maxlength="50"
                                    placeholder="{{salaryPlaceHolder}}">
                                <span class="input-group-addon inputaddon">{{currency_Code}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Line manager
                                <!-- <span class="required"> *</span>  -->
                            </label>
                            <select class="form-control" id="candidateOverviewReporting">
                                <option value="">--- Select ---</option>
                                <option *ngFor="let emp of reportingEmpList;" value={{emp.emp_Id}}>
                                    {{emp.firstName}} {{emp.lastName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Joining Date
                                <!-- <span class="required"> *</span> -->
                            </label>

                            <div class="input-group flatpickr" id="canidateOverviewJoiningDates" data-wrap="true">
                                <input type="text" class="form-control" id="canidateOverviewJoiningDate" data-input />
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button class="btn blue" type="button" (click)="directMoveToOnboarding()">Save</button>
            </div>
        </div>
    </div>
</div>