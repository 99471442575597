import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;
import * as moment from 'moment';


@Component({
  selector: 'app-employeerightwork',
  templateUrl: './emp-right-work.component.html'
})
export class EmpRightWorkComponent implements OnInit {
  empform: FormGroup;
  rtwCategoryList: any;
  ecsstatusListList: any;
  nationalityList: any;
  documentList: any;
  fileName: any;
  file: any;
  rtwVisaStaList: any[];
  isrequiredclass: boolean = true;
  lstNationlaity: any[];
  selectedText: any;
  public isDivHidden: any;
  // public isrequiredclass: any;
  public isnotrequiredComclass: boolean = false;
  public isrequiredComclass: boolean = false;
  
  public isrequiredclassspo: boolean = false;
  isSponsoredEmployee: any;
  empRighttoworkRequired = new FormControl(false);
  selectedValue:any;
  selectedOtionItem: any;
  isrequiredforECS: boolean = false;
  selectedValueData:any;
  selecedValueItem: any;
  rtwStausName:string;
  ecsStatusName:string;
  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {


    this.empform = this.fb.group({
      lstNationality_Id: [null, Validators.required],
      rightToWork_Id: ['0'],
      emp_Id: ['0'],
      rwC_Id: ['', Validators.required],
      ecsexpirydate: ['', Validators.required],
      ecsstatus: ['', Validators.required],
      ecsreceiveddate: ['', Validators.required],
      rtw_VisaStatus_Id: ['', Validators.required],
      Other: [''],
      rtw_VisaStatus_ExpiryDate: ['', Validators.required],
      passportExpiryDate: ['', Validators.required],
      sponsoredExpiryDate: ['', Validators.required],
      isSponsoredEmployee: [''],
      comments: ['', Validators.required],
      isActive: ['1'],
      updatedBy: [Commonvariable.User_Id],
      // issueAuthority: [''],
      // issueCountry: [''],
      // issueDate: [''],
      // expiryDate: ['',],
      // emp_Doc_Id: [null],
      // notes: [''],
      // updatedBy: [Commonvariable.User_Id],
      // doc_Id: [''],
      // files: [''],
    });

    this.commonService.flatpickr("flatpickropenecsED", 0)
    this.commonService.flatpickr("flatpickropenecsRec", 0)
    this.commonService.flatpickr("flatpickropenrtwVSED", 0)
    this.commonService.flatpickr("flatpickropenpassportED", 0)
    this.commonService.flatpickr("flatpickropensponsershipED", 0)
    this.commonService.flatpickr("flatpickropenecsRec", 0)
    this.commonService.flatpickr("flatpickropenecsRec", 0)

    this.getListVisaStype();
    this.getecsstatusListList();
    this.getNationalityList();

    $(".removedacoument").click();
    this.getDocument();
  }



  changeShowrtwDoc(event: any) {

    if (event.target.value) {
      $(".rtwDecumentBody").show();
    }
    else {
      $(".rtwDecumentBody").hide();
    }
    if ($("#rwC_Id").val() == 13 || $("#rwC_Id").val() == 14 || $("#rwC_Id").val() == 15 || $("#rwC_Id").val() == 16 || $("#rwC_Id").val() == 17) {
      this.empform.get('expiryDate').clearValidators();
    } else {
      this.empform.get('expiryDate').setValidators(Validators.required);
    }
  }
  getecsstatusListList() {

    this.ecsstatusListList = [
      { id: "Requested", name: "Requested" },
      { id: "Received", name: "Received" },
      { id: "N/A", name: "N/A" }
    ];

  }
  getNationalityList() {


    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "name"
    }

    this.commonApiService.GetByPaginated(Commonvariable.Nationality, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        //this.nationalityList = response["data"];
        this.nationalityList = response["data"].map(x => ({
          id: x.id,
          text: x.name
        }))
      }
    })

  }

  getListVisaStype() {
    this.rtwVisaStaList = [];

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "Id"


    }

    this.commonApiService.GetByPaginated(Commonvariable.RTWVisaStatus, requestData).subscribe((response) => {
      if (response["data"] != "") {
        this.rtwVisaStaList = response["data"];
        // this.rtwVisaStaList = response["data"].sort((a, b) => a.name.localeCompare(b.name));
      }
    })
  }

  getRTWCategory(cID) {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "company_Id",
          "FieldValue": cID,
          "Parameter": "=="
        }
      ],
      "OrderBy": "name"
    }


    this.commonApiService.GetByPaginated(Commonvariable.RightToWork_Category, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.rtwCategoryList = response["data"].sort((a, b) => a.name.localeCompare(b.name));
      }
    })


  }

  getDocument() {
    this.documentList = [];
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Document, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.documentList = response["data"];
      }
    })

    // this.commonApiService.List(Commonvariable.Document).subscribe((response) => {
    //   if (response != null && response["data"] != "") {
    //     this.documentList = response["data"].sort((a, b) => a.name.localeCompare(b.name));
    //   }
    // })
  }


  empEdit(Id) {
    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.EmpRightWork, Id, "GetId").subscribe((response) => {
        if (response != null && response["data"] != "") {
          var editData = response["data"];

          if(editData[0].ecsStatus === 'Requested' || editData[0].ecsStatus === 'N/A'){
            this.isrequiredforECS = false ;
          }
          else{
            this.isrequiredforECS = true ;
          }

          if(editData[0].other !=""){
            this.isDivHidden = true;
            this.isrequiredComclass = true;
          }
          else{
            this.isDivHidden = false;
            this.isrequiredComclass = false;
          }

          let sponsoredExpiryDate = response["data"][0].sponsoredExpiryDate != null ? response["data"][0].sponsoredExpiryDate.split("T")[0].split("-") : "";
          let rtW_VisaStatus_ExpiryDate = response["data"][0].rtW_VisaStatus_ExpiryDate != null ? response["data"][0].rtW_VisaStatus_ExpiryDate.split("T")[0].split("-") : "";
          let passportExpiryDate = response["data"][0].passportExpiryDate != null ? response["data"][0].passportExpiryDate.split("T")[0].split("-") : "";
          let ecsReceivedDate = response["data"][0].ecsReceivedDate != null ? response["data"][0].ecsReceivedDate.split("T")[0].split("-") : "";
          let ecsExpiryDate = response["data"][0].ecsExpiryDate != null ? response["data"][0].ecsExpiryDate.split("T")[0].split("-") : "";


          if (sponsoredExpiryDate != "") {
            $("#sponsoredExpiryDate").val(sponsoredExpiryDate[2] + "-" + sponsoredExpiryDate[1] + "-" + sponsoredExpiryDate[0]);
          }
          if (rtW_VisaStatus_ExpiryDate != "") {
            $("#rtw_VisaStatus_ExpiryDate").val(rtW_VisaStatus_ExpiryDate[2] + "-" + rtW_VisaStatus_ExpiryDate[1] + "-" + rtW_VisaStatus_ExpiryDate[0]);
          }

          if (passportExpiryDate != "") {
            $("#passportExpiryDate").val(passportExpiryDate[2] + "-" + passportExpiryDate[1] + "-" + passportExpiryDate[0]);
          }
          if (ecsReceivedDate != "") {
            $("#ecsreceiveddate").val(ecsReceivedDate[2] + "-" + ecsReceivedDate[1] + "-" + ecsReceivedDate[0]);
          }

          if (ecsExpiryDate != "") {
            $("#ecsexpirydate").val(ecsExpiryDate[2] + "-" + ecsExpiryDate[1] + "-" + ecsExpiryDate[0]);
          }
          
         
          this.ecsStatusName = editData[0].ecsStatus;
          this.selectedOtionItem = this.ecsStatusName;
          this.rtwStausName = editData[0].rtW_VisaStatus_Name;


          if(this.rtwStausName === "N/A")
          {
            this.isnotrequiredComclass = false;
          }
          else{
            this.isnotrequiredComclass = true;
          }

          if(this.rtwStausName === "Other"){
            this.isDivHidden = true;
            this.isrequiredComclass = true;
          }
          else{
            this.isDivHidden = false;
            this.isrequiredComclass = false;
          }

          // const lstNationalityControl = this.empform.get('lstNationality_Id');

          // if (lstNationalityControl) {
          //  this.lstNationlaity = lstNationalityControl.value;
          //   // Now, lstNationalityValue contains the value of the lstNationality_Id control
          //   console.log(this.lstNationlaity);

          //   for (const value of this.lstNationlaity) {
          //     if (value === "7" || value === "74" || value === "43") {
          //       this.isrequiredclass = false;
          //       break; 
          //     }
          //     else{
          //       this.isrequiredclass = true;
          //     }
          //   }
          // }

          var formData = this.empform;
          setTimeout(() => {
            // this.commonService.setEditData(formData, editData);
            // this.empform.controls.updatedBy.setValue(Commonvariable.User_Id);
            // this.empform.controls.rwC_Id.setValue(editData[0].rwC_Id);
            // this.empform.controls.rtw_VisaStatus_Id.setValue(editData[0].rtW_VisaStatus_Id);
            // this.empform.controls.ecsstatus.setValue(editData[0].ecsStatus);
            // this.empform.controls.comments.setValue(editData[0].comments);
            // // this.empform.controls.lstNationality_Id.setValue((response["data"][0].lstNationality_Id).split(","));
            // this.empform.controls.lstNationality_Id.setValue([String(editData[0].lstNationality_Id).split(",")]);
 
 
            this.empform = this.fb.group({
              lstNationality_Id: [String(editData[0].lstNationality_Id).split(","), Validators.required],
              rightToWork_Id: [editData[0].rightToWork_Id],
              emp_Id: [editData[0].emp_Id],
              rwC_Id: [editData[0].rwC_Id],
              ecsexpirydate: [''],
              ecsstatus: [editData[0].ecsStatus],
              ecsreceiveddate: [''],
              rtw_VisaStatus_Id: [editData[0].rtW_VisaStatus_Id],
              Other: [editData[0].other],
              rtw_VisaStatus_ExpiryDate: [''],
              passportExpiryDate: [''],
              sponsoredExpiryDate: [''],
              isSponsoredEmployee: [editData[0].isSponsoredEmployee],
              comments: [editData[0].comments],
              isActive: [editData[0].isActive],
              updatedBy: [Commonvariable.User_Id],
           
            });

            if(this.empform.value.ecsStatus === 'Requested' || this.empform.value.ecsStatus === 'N/A'){
              this.isrequiredforECS = false ;
            }
            else{
              this.isrequiredforECS = true ;
            }

            if(this.empform.value.rtw_VisaStatus_Id === "1")
            {
              this.isnotrequiredComclass = false;
            }else{
              this.isnotrequiredComclass = true;
            }

            if(this.rtwStausName === "12"){
              this.isDivHidden = true;
              this.isrequiredComclass = true;
            }
            else{
              this.isDivHidden = false;
              this.isrequiredComclass = false;
            }

          });

          // this.isrequiredclass = true;
          // for (const lstNationality_Id in this.empform.controls) {
          //   if (this.empform.controls.hasOwnProperty(lstNationality_Id)) {
          //     const control = this.empform.get(lstNationality_Id);
          //     const controlValue = control.value;
          
              
          //     if (controlValue === "7" || controlValue === "74" || controlValue === "43") {
          //       this.isrequiredclass = false;
          //       break; 
          //     }
          //   }
          // }

         
          

         
            // this.empform.controls.emp_Doc_Id.setValue(parseInt(editData[0].emp_Doc_Id) > 0 ? parseInt(editData[0].emp_Doc_Id) : -12);

            // if (parseInt(editData[0].emp_Doc_Id) > 0) {
            //   this.commonApiService.getById(Commonvariable.EmpDocument, editData[0].emp_Doc_Id).subscribe((response) => {
            //     formData.controls.doc_Id.setValue(parseInt(response['data'][0].doc_Id));
            //     this.fileName = response['data'][0].file_Name
            //     $('.fileinput-filename').html(this.fileName);
            //     $('.fileinput.fileinput-new').removeClass('fileinput-new').addClass('fileinput-exists');
            //     $('.input-group.input-large').attr('disabled', 'disabled');
            //   })

            //   this.commonApiService.getimage(Commonvariable.EmpDocument, editData[0].emp_Doc_Id, 'Download').subscribe((result) => {
            //     this.empform.controls.files.setValue(new Blob([result], { type: result.type }));
            //     const blobData = new Blob([result], { type: result.type });
            //   })


            // }
          }
      })
    }
  }


  validation() {
    var eForm = this.empform;
    if (!Array.isArray(eForm.controls.lstNationality_Id.value)) {
      // Convert the string to an array with a single element
      eForm.controls.lstNationality_Id.setValue([eForm.controls.lstNationality_Id.value]);
    }
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);
    eForm.get("isSponsoredEmployee").setValue(eForm.get("isSponsoredEmployee").value ? 1 : 0);

    eForm.controls.sponsoredExpiryDate.setValue(this.commonService.dateFormatChange($("#sponsoredExpiryDate").val()));
    eForm.controls.rtw_VisaStatus_ExpiryDate.setValue(this.commonService.dateFormatChange($("#rtw_VisaStatus_ExpiryDate").val()));
    eForm.controls.passportExpiryDate.setValue(this.commonService.dateFormatChange($("#passportExpiryDate").val()));
    eForm.controls.ecsreceiveddate.setValue(this.commonService.dateFormatChange($("#ecsreceiveddate").val()));
    eForm.controls.ecsexpirydate.setValue(this.commonService.dateFormatChange($("#ecsexpirydate").val()));
    // eForm.controls.rwC_Id.setValue(this.commonService.dateFormatChange($("#rwC_Id").val()));
    // eForm.controls.rtw_VisaStatus_Id.setValue(this.commonService.dateFormatChange($("#rtw_VisaStatus_Id").val()));
    if (!eForm.valid) {
      if (eForm.controls.lstNationality_Id.value == "") {
        this.toastr.info("Nationality is required !", "Employee");
        $("#lstNationality_Id").focus();
        return false;
     }
     for (let i = 0; i < this.selectedText.length; i++) {
      if ((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish")) {
         if (eForm.controls.ecsstatus.value == "") {
            this.toastr.info("ECS Status is required !", "Employee");
            $("#ecsstatus").focus();
            return false;
         }

      }
      if (this.selectedOtionItem != 'Requested' || this.selectedOtionItem != 'N/A') {
        for (let i = 0; i < this.selectedText.length; i++) {
           if ((eForm.controls.ecsstatus.value == "Received") || (this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && this.selectedText[i] != "Irish" && eForm.controls.ecsstatus.value != "Requested" && eForm.controls.ecsstatus.value != "N/A")) {
              if (eForm.controls.ecsreceiveddate.value == "") {
                 this.toastr.info("ECS Received Date is required !", "Employee");
                 $("#ecsreceiveddate").focus();
                 return false;
              }

           }
        }
     }

     if (this.selectedOtionItem != 'Requested' || this.selectedOtionItem != 'N/A') {
      for (let i = 0; i < this.selectedText.length; i++) {
         if ((eForm.controls.ecsstatus.value == "Received") || (this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && eForm.controls.ecsstatus.value != "Requested" && eForm.controls.ecsstatus.value != "N/A")) {
            if (eForm.controls.ecsexpirydate.value == "") {
               this.toastr.info("ECS Expiry Date is required !", "Employee");
               $("#ecsexpirydate").focus();
               return false;
            }

         }
      }
   }

   for (let i = 0; i < this.selectedText.length; i++) {
    if ((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish")) {
       if (eForm.controls.rwC_Id.value == null || eForm.controls.rwC_Id.value == "") {
          this.toastr.info("RTW DocumentType is required !", "Employee");
          $("#rwC_Id").focus();
          return false;
       }

    }
 }

 for (let i = 0; i < this.selectedText.length; i++) {
  if ((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && this.selectedValueData != "") || eForm.controls.rtw_VisaStatus_Id.value == "" || eForm.controls.rtw_VisaStatus_Id.value == undefined) {
     if (eForm.controls.rtw_VisaStatus_Id.value == "0" || eForm.controls.rtw_VisaStatus_Id.value == undefined) {
        this.toastr.info("RTW - Visa/Status Type is required !", "Employee");
        $("#rtw_VisaStatus_Id").focus();
        return false;
     }

  }
}

if (this.isrequiredclassspo === true) {
  if (this.selectedText != "" || this.selectedText != "British" || this.selectedText != "Irish") {
     if (eForm.controls.sponsoredExpiryDate.value == "") {
        this.toastr.info("sponsored Expiry Date is required !", "Employee");
        $("#sponsoredExpiryDate").focus();
        return false;
     }

  }
  if (this.isrequiredComclass === true) {
    if (this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish" || this.selectedValueData === "12") {
       if (eForm.controls.other.value == "") {
          this.toastr.info("Other Value is required !", "Employee");
          $("#other").focus();
          return false;
       }
    }

    if (eForm.controls.rtw_VisaStatus_Id.value != "1") {
      var Nationality = true;
      for (let i = 0; i < this.selectedText.length; i++) {
         if ((this.selectedText[i] != "")) {

            if (this.selectedText[i] == "British" || this.selectedText[i] == "Irish") {

               Nationality = true;
               
            }else
            {
               Nationality=false;
            }
         }
      }

      if(Nationality==true)
      {
         if(eForm.controls.rtw_VisaStatus_Id.value == "0" || eForm.controls.rtw_VisaStatus_Id.value == "1")
         {
           
         }else
         {
          if (eForm.controls.rtw_VisaStatus_ExpiryDate.value == "") {
            this.toastr.info("RTW - Visa/Status Expiry Date is required !", "Employee");
            $("#rtw_VisaStatus_ExpiryDate").focus();
            return false;
         }
      }

      }else
      {
         if (eForm.controls.rtw_VisaStatus_ExpiryDate.value == "" || eForm.controls.rtw_VisaStatus_Id.value != "1") {
            this.toastr.info("RTW - Visa/Status Expiry Date is required !", "Employee");
            $("#rtw_VisaStatus_ExpiryDate").focus();
            return false;
         }
      }

      
   }

   for (let i = 0; i < this.selectedText.length; i++) {
    if ((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish")) {
       if (eForm.controls.passportExpiryDate.value  == "") {
          this.toastr.info("Passport Expiry Date is required !", "Employee");
          $("#passportExpiryDate").focus();
          return false;
       }

    }
 }

    // if (empData.SponsoredExpiryDate.value == "") {
    //    this.toastr.info("sponsored Expiry Date is required !", "Employee");
    //    $("#SponsoredExpiryDate").focus();
    //    return false;
    // }

 }

}

   }

    }
    else {

      if (this.isrequiredclassspo === true) {
        if (eForm.controls.sponsoredExpiryDate.value == "") {
           this.toastr.info("sponsored Expiry Date is required !", "Employee");
           $("#sponsoredExpiryDate").focus();
           return false;
        }
       
      }
      if (this.isrequiredComclass === true) {
        if (eForm.controls.Other.value == "") {
           this.toastr.info("Other Value is required !", "Employee");
           $("#Other").focus();
           return false;
        }
       
      }
      if (eForm.controls.lstNationality_Id.value == null || eForm.controls.lstNationality_Id.value.length == 0)  {
        this.toastr.info("Nationality is required !", "Employee");
        $("#lstNationality_Id").focus();
        return false;
      }

      // if (eForm.controls.ecsstatus.value == "" && this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish")  {
      //   this.toastr.info("ECS Status is required !", "Employee");
      //   $("#ecsstatus").focus();
      //   return false;
      // }

      for(let i=0;i<this.selectedText.length;i++)
      {
        if((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish")){
         if(eForm.controls.ecsstatus.value == ""){
          this.toastr.info("ECS Status is required !", "Employee");
          $("#ecsstatus").focus();
         return false;
         }
         
      }
   }

      // if (eForm.controls.ecsreceiveddate.value == "" && this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish" && this.selectedOtionItem != 'Requested' && this.selectedOtionItem != 'N/A' && this.ecsStatusName != 'Requested' && this.ecsStatusName != 'N/A')  {
      //   this.toastr.info("ECS Received Date is required !", "Employee");
      //   $("#ecsreceiveddate").focus();
      //   return false;
      // }

      // if (eForm.controls.ecsexpirydate.value == "" && this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish" && this.selectedOtionItem != 'Requested' && this.selectedOtionItem != 'N/A' && this.ecsStatusName != 'Requested' && this.ecsStatusName != 'N/A')  {
      //   this.toastr.info("ECS Expiry Date is required !", "Employee");
      //   $("#ecsexpirydate").focus();
      //   return false;
      // }

      for(let i=0;i<this.selectedText.length;i++)
      {
      if ( (eForm.controls.ecsstatus.value === "Received") || (this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && this.selectedOtionItem != 'Requested' && this.selectedOtionItem != 'N/A')) {
         if(eForm.controls.ecsreceiveddate.value == ""){
            this.toastr.info("ECS Received Date is required !", "Employee");
            $("#ecsreceiveddate").focus();
         return false;
         }
         
      }
   }
   for(let i=0;i<this.selectedText.length;i++)
   {
      if ( (eForm.controls.ecsstatus.value === "Received") || (this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && this.selectedOtionItem != 'Requested' && this.selectedOtionItem != 'N/A')) {
         if(eForm.controls.ecsexpirydate.value == ""){
            this.toastr.info("ECS Expiry Date is required !", "Employee");
            $("#ecsexpirydate").focus();
            return false;
         }
         
      }
   }

      // if (eForm.controls.rwC_Id.value == "" && this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish")  {
      //   this.toastr.info("RTW DocumentTyp is required !", "Employee");
      //   $("#rwC_Id").focus();
      //   return false;
      // }

    //   if ((eForm.controls.rwC_Id.value == null && this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish") || eForm.controls.rwC_Id.value == "") {
    //     this.toastr.info("RTW DocumentType is required !", "Employee");
    //     $("#rwC_Id").focus();
    //     return false;
    //  }

    for(let i=0;i<this.selectedText.length;i++)
      {
        if((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish")){
         if(eForm.controls.rwC_Id.value == null || eForm.controls.rwC_Id.value == ""){
          this.toastr.info("RTW DocumentType is required !", "Employee");
          $("#rwC_Id").focus();
         return false;
         }
         
      }
   }

      // if (eForm.controls.rtw_VisaStatus_Id.value == "" && this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish")  {
      //   this.toastr.info("RTW - Visa/Status Type is required !", "Employee");
      //   $("#rtw_VisaStatus_Id").focus();
      //   return false;
      // }

  //     for(let i=0;i<this.selectedText.length;i++)
  //     {
  //     if ((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && this.selectedValueData != "") || eForm.controls.rtw_VisaStatus_Id.value == "" || eForm.controls.rtw_VisaStatus_Id == undefined || eForm.controls.rtw_VisaStatus_Id.value != null) {
  //        if(eForm.controls.rtw_VisaStatus_Id.value == "0" || eForm.controls.rtw_VisaStatus_Id.value == undefined || eForm.controls.rtw_VisaStatus_Id.value == "" || eForm.controls.rtw_VisaStatus_Id.value != null){
  //           this.toastr.info("RTW - Visa/Status Type is required !", "Employee");
  //           $("#rtw_VisaStatus_Id").focus();
  //           return false;
  //        }

  //     }
  //  }

  for(let i=0;i<this.selectedText.length;i++)
  {
  if ((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish")) {
     if(eForm.controls.rtw_VisaStatus_Id.value == "0" ||  eForm.controls.rtw_VisaStatus_Id.value == undefined){
        this.toastr.info("RTW - Visa/Status Type is required !", "Employee");
        $("#rtw_VisaStatus_Id").focus();
        return false;
     }

  }
}

//    for(let i=0;i<this.selectedText.length;i++)
//    {
//    if ((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && eForm.controls.rtw_VisaStatus_Id.value != null) || eForm.controls.rtw_VisaStatus_Id.value == "" || eForm.controls.rtw_VisaStatus_Id == undefined) {
//       if(eForm.controls.rtw_VisaStatus_Id.value == "0" || eForm.controls.rtw_VisaStatus_Id.value == undefined || eForm.controls.rtw_VisaStatus_Id.value == "" ){
//          this.toastr.info("RTW - Visa/Status Type is required !", "Employee");
//          $("#rtw_VisaStatus_Id").focus();
//          return false;
//       }

//    }
// }

      // if ((eForm.controls.rtw_VisaStatus_ExpiryDate.value == "" && this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish" && this.rtwStausName != "N/A" && this.selecedValueItem != "N/A") || this.rtwStausName != "Other" || this.selecedValueItem !="Other")  {
      //   this.toastr.info("RTW - Visa/Status Expiry Date is required !", "Employee");
      //   $("#rtw_VisaStatus_ExpiryDate").focus();
      //   return false;
      // }
// for(let i=0;i<this.selectedText.length;i++)
// {
//   if((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && this.rtwStausName != "N/A" && this.selecedValueItem != "N/A") || this.rtwStausName != "Other" || this.selecedValueItem !="Other"){

//     if (eForm.controls.rtw_VisaStatus_ExpiryDate.value == "" )  {
//       this.toastr.info("RTW - Visa/Status Expiry Date is required !", "Employee");
//       $("#rtw_VisaStatus_ExpiryDate").focus();
//       return false;
//     }
//   }

// }

// for(let i=0;i<this.selectedText.length;i++)
// {
//   if((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && this.selectedValueData != "1") || this.selectedValueData != undefined){

//     if (eForm.controls.rtw_VisaStatus_ExpiryDate.value == "" )  {
//       this.toastr.info("RTW - Visa/Status Expiry Date is required !", "Employee");
//       $("#rtw_VisaStatus_ExpiryDate").focus();
//       return false;
//     }
//   }

// }

for(let i=0;i<this.selectedText.length;i++)
{
  if((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" ) || this.selecedValueItem != undefined || eForm.controls.rtw_VisaStatus_Id.value != "0")
  {
 if(eForm.controls.rtw_VisaStatus_Id.value != "1" && eForm.controls.rtw_VisaStatus_Id.value != null)
 {
  if (eForm.controls.rtw_VisaStatus_ExpiryDate.value == "" )  {
    this.toastr.info("RTW - Visa/Status Expiry Date is required !", "Employee");
    $("#rtw_VisaStatus_ExpiryDate").focus();
    return false;
  }
}


    
  }
  

}


// if (eForm.controls.rtw_VisaStatus_Id.value != "1") {
//   var Nationality = true;
//   for (let i = 0; i < this.selectedText.length; i++) {
//      if ((this.selectedText[i] != "")) {

//         if (this.selectedText[i] == "British" || this.selectedText[i] == "Irish") {

//            Nationality = true;
           
//         }else
//         {
//            Nationality=false;
//         }
//      }
//   }

//   if(Nationality==true)
//   {
//      if(eForm.controls.rtw_VisaStatus_Id.value == "0" || eForm.controls.rtw_VisaStatus_Id.value == "1")
//      {
       
//      }else
//      {
//       if (eForm.controls.rtW_VisaStatus_ExpiryDate.value == "") {
//         this.toastr.info("RTW - Visa/Status Expiry Date is required !", "Employee");
//         $("#rtw_VisaStatus_ExpiryDate").focus();
//         return false;
//      }
//   }

//   }else
//   {
//      if (eForm.controls.rtW_VisaStatus_ExpiryDate.value == "" || eForm.controls.rtw_VisaStatus_Id.value != "1") {
//         this.toastr.info("RTW - Visa/Status Expiry Date is required !", "Employee");
//         $("#rtw_VisaStatus_ExpiryDate").focus();
//         return false;
//      }
//   }

  
// }
      

      

      // if (eForm.controls.passportExpiryDate.value == "" && this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish")  {
      //   this.toastr.info("Passport Expiry Date is required !", "Employee");
      //   $("#passportExpiryDate").focus();
      //   return false;
      // }

      // for(let i=0;i<this.selectedText.length;i++)
      // {
      //  if((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish"  ) || eForm.controls.rtw_VisaStatus_Id.value != "1"){

      //   if (eForm.controls.passportExpiryDate.value == "")  {
      //     this.toastr.info("Passport Expiry Date is required !", "Employee");
      //     $("#passportExpiryDate").focus();
      //     return false;
      //    }
      //  }

      // }

      for(let i=0;i<this.selectedText.length;i++)
      {
       if((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish")){

        if (eForm.controls.passportExpiryDate.value == "")  {
          this.toastr.info("Passport Expiry Date is required !", "Employee");
          $("#passportExpiryDate").focus();
          return false;
         }
       }

      }

      // for(let i=0;i<this.selectedText.length;i++)
      // {
      //    if ( (this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && this.selectedValueData != "1") || this.selectedValueData != undefined) {
      //       if(eForm.controls.passportExpiryDate.value == "" ||  this.selecedValueItem !="N/A"){
      //         this.toastr.info("Passport Expiry Date is required !", "Employee");
      //       $("#passportExpiryDate").focus();
      //          return false;
      //       }
           
      //    }
      // }

      // if (eForm.controls.sponsoredExpiryDate.value == "" && this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish")  {
      //   this.toastr.info("Sponsorship Expiry Date is required !", "Employee");
      //   $("#sponsoredExpiryDate").focus();
      //   return false;
      // }

      // if (eForm.controls.comments.value == "" && this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish")  {
      //   this.toastr.info("Comments is required !", "Employee");
      //   $("#comments").focus();
      //   return false;
      // }

      // if (eForm.controls.files.value == undefined) {
      //   this.toastr.info("Files is required !", "Employee");
      //   $("#files").focus();
      //   return false;
      // }
      // if ($("#issueAuthority").val().length < 2) {
      //   this.toastr.info("Authority must be at least 2 characters long !", "Employee");
      //   $("#issueAuthority").focus();
      //   return false;
      // }
      // if ($("#issueAuthority").val().indexOf(" ") > -1) {
      //   this.toastr.info("Authority not allow space !", "Employee");
      //   $("#issueAuthority").focus();
      //   return false;
      // }

      // if ($("#issueAuthority").val() != "" && /["\\]/.test($("#issueAuthority").val())) {
      //   this.toastr.info("Authority does not allow Special Characters \" \\ ", "Employee");
      //   $("#issueAuthority").focus();
      //   return false;
      // }

      // if ($("#issueAuthority").val() != "" && $("#issueAuthority").val().search(/^[a-zA-Z0-9 ]+$/) != 0) {
      //   this.toastr.info("Authority only alphanumeric value allow !", "Employee");
      //   $("#issueAuthority").focus();
      //   return false;
      // }

      // if ($("#issueCountry").val().length < 2) {
      //   this.toastr.info("Country of Issue must be at least 2 characters long !", "Employee");
      //   $("#issueCountry").focus();
      //   return false;
      // }

      // if (moment($("#issueDate").val(), 'DD-MM-YYYY') >= moment($("#expiryDate").val(), 'DD-MM-YYYY')) {
      //   this.toastr.info("Issue date not more then expiry date !", "Employee");
      //   $("#issueDate").focus();
      //   return false;
      // }

      // if ($("#issueCountry").val() != "" && /["\\]/.test($("#issueCountry").val())) {
      //   this.toastr.info("Country of Issue does not allow Special Characters \" \\ ", "Employee");
      //   $("#issueCountry").focus();
      //   return false;
      // }

      // if ($("#issueCountry").val() != "" && $("#issueCountry").val().search(/^[a-zA-Z0-9 ]+$/) != 0) {
      //   this.toastr.info("Country of Issue only alphanumeric value allow !", "Employee");
      //   $("#issueCountry").focus();
      //   return false;
      // }

      // if ($("#docNotes").val() != "" && /["\\]/.test($("#docNotes").val())) {
      //   this.toastr.info("Document Notes does not allow Special Characters \" \\ ", "Employee");
      //   $("#docNotes").focus();
      //   return false;
      // }




      // if ($("#issueCountry").val().indexOf(" ") > -1) {
      //   this.toastr.info("Country of Issue not allow space !", "Employee");
      //   $("#issueCountry").focus();
      //   return false;
      // }
    }
    return true;
  }



  fileChange(event) {
    // if (event.target.files.item(0).name.split('.')[1] == "pdf" || event.target.files.item(0).name.split('.')[1] == "doc"
    //   || event.target.files.item(0).name.split('.')[1] == "docx" || event.target.files.item(0).name.split('.')[1] == "xlsx") {
    this.empform.controls.files.setValue(event.target.files[0]);
    this.file = event.target.files[0];
    // this.empform.controls.Emp_Doc_Name.setValue(event.target.files[0].name);
    // }
    // else {
    //   this.toastr.info("Allow only pdf,doc,docx,xlsx file format for upload !", "Employee");
    //   $("#remove").click();
    // }

  }

  removeDoc(event) {
    this.empform.controls.files.setValue(null);
    this.file = null;
  }
  setFormData(fieldName, id: any[]) {
    if (id != null) {
      if (id != undefined && id[0] != "") {
        // this.nationality_Id_List = id[0] == "" ? [] : id;
        // console.log(this.nationality_Id_List);
        this.empform.controls[fieldName].setValue(id[0] == "" ? [] : id)
      }
      else {
        this.empform.controls[fieldName].setValue([]);
      }
    }
    else {
      this.empform.controls[fieldName].setValue(null);
    }

    // this.selectedText = id;
    
      
    //   this.isrequiredclass = true;
    
    //   for (const value of this.selectedText) {
    //     if (value === "7" || value === "74" || value === "43") {
    //       this.isrequiredclass = false;
    //       break; 
    //     }
    //   }
    id = [id];
    if (Array.isArray(id)) {
      const selectedTextValues = id.map(selectedValue => {
         const option = this.nationalityList.find(item => item.id === Number(selectedValue));
         return option ? option.text : null;
      });

      this.selectedText = selectedTextValues;

      this.isrequiredclass = true;

      const fieldControl = this.empform.get('ecsstatus');
      const fieldValue = fieldControl.value;

      const fieldControl2 = this.empform.get('rtw_VisaStatus_Id');
      const fieldValue1 = fieldControl2.value;

      const fieldControl3 = this.empform.get('Other');
      const fieldValue2 = fieldControl3.value;


  //     if (this.selectedText === "British" || this.selectedText === "Irish") {
  //        this.isrequiredclass = false;
  //        this.isrequiredforECS = false;
  //        this.isnotrequiredComclass = false;
  //     }
  //     else if (id.length > 1) {
  //        let i;
  //        for (i = 0; i < id.length; i++) {
  //           if (this.selectedText[i] === "British" || this.selectedText[i] === "Irish" )
  //            {
  //              this.isrequiredclass = false;
  //              this.isrequiredforECS = false;
  //              this.isnotrequiredComclass = false;
  //           }
  //           else if(fieldValue === "Requested" || fieldValue === "N/A"){
  //              this.isrequiredclass = true;
  //              this.isrequiredforECS = false;
  //              this.isnotrequiredComclass = true;
  //              break;
  //           }else{
  //             this.isrequiredclass = true;
  //              this.isrequiredforECS = true;
  //              this.isnotrequiredComclass = true;
  //           }
            
  //        }
         
  //     }

  //     else {
  //        for (const textValue of selectedTextValues) {
  //           if (textValue === "British" || textValue === "Irish") {
  //              this.isrequiredclass = false;
  //              this.isrequiredforECS = false;
  //              this.isnotrequiredComclass = false;
  //              break;
  //           }
  //        }
  //     }
  //  }
  //  else {
  //     this.isrequiredclass = true;
  //     this.isrequiredforECS = true;
  //     this.isnotrequiredComclass = true;
  //  }

  if (this.selectedText === "British" || this.selectedText === "Irish") {
    this.isrequiredclass = false;
    this.isrequiredforECS = fieldValue === "Requested" || fieldValue === "N/A" || fieldValue ==="" ? false : true;
        this.isnotrequiredComclass = fieldValue1 == 1 || fieldValue1 == 0 || fieldValue1 == "" ? false : true;
        this.isDivHidden = fieldValue1 == 12 ? true : false;
        this.isrequiredComclass = fieldValue1 == 12 ? true : false;
} else if (id.length > 1) {
    for (let i = 0; i < id.length; i++) {
        if (this.selectedText[i] === "British" || this.selectedText[i] === "Irish") {
            this.isrequiredclass = false;
            this.isrequiredforECS = fieldValue === "Requested" || fieldValue === "N/A" || fieldValue ==="" ? false : true;
            this.isnotrequiredComclass = fieldValue1 == 1 || fieldValue1 == 0 || fieldValue1 == "" ? false : true;
            this.isDivHidden = fieldValue1 == 12 ? true : false;
            this.isrequiredComclass = fieldValue1 == 12 ? true : false;
            break;
        } else if (fieldValue === "Requested" || fieldValue === "N/A") {
            this.isrequiredclass = true;
            this.isrequiredforECS = false;
            this.isnotrequiredComclass = fieldValue1 == 1 || fieldValue1 == 0 || fieldValue1 == "" ? false : true;
            this.isDivHidden = fieldValue1 == 12 ? true : false;
            this.isrequiredComclass = fieldValue1 == 12 ? true : false;
            break;
        } else if (fieldValue1 == 1 || fieldValue1 == 0 || fieldValue1 == "") {
            this.isnotrequiredComclass = false;
            break;
        } else {
            this.isrequiredclass = true;
            this.isrequiredforECS = fieldValue === "Requested" || fieldValue === "N/A" || fieldValue ==="" ? false : true;
            this.isnotrequiredComclass = fieldValue1 == 1 || fieldValue1 == 0 || fieldValue1 == "" ? false : true;
            this.isDivHidden = fieldValue1 == 12 ? true : false;
            this.isrequiredComclass = fieldValue1 == 12 ? true : false;
            break;
        }
    }
} else {
    for (const textValue of selectedTextValues) {
        if (textValue === "British" || textValue === "Irish") {
            this.isrequiredclass = false;
            this.isrequiredforECS = fieldValue === "Requested" || fieldValue === "N/A" || fieldValue ==="" ? false : true;
                        // this.isnotrequiredComclass = fieldValue1 === "1" || fieldValue1 === "0" || fieldValue1 === "" ? false : true;
            this.isnotrequiredComclass = fieldValue1 == 1 || fieldValue1 == 0 || fieldValue1 == "" ? false : true;
            this.isDivHidden = fieldValue1 == 12 ? true : false;
            this.isrequiredComclass = fieldValue1 == 12 ? true : false;
            break;
        } else if (fieldValue === "Requested" || fieldValue === "N/A") {
            this.isrequiredclass = true;
            this.isrequiredforECS = false;
            this.isnotrequiredComclass = fieldValue1 == 1 || fieldValue1 == 0 || fieldValue1 == "" ? false : true;
            this.isDivHidden = fieldValue1 == 12 ? true : false;
             this.isrequiredComclass = fieldValue1 == 12 ? true : false;
             break;
        } else if (fieldValue1 == 1 || fieldValue1 == 0 || fieldValue1 == "") {
            this.isnotrequiredComclass = false;
            break;
        }
    }
}
}
else {
 this.isrequiredclass = true;
 this.isrequiredforECS = true;
 this.isnotrequiredComclass = true;
 this.isDivHidden = false;
 this.isrequiredComclass = false;
}

  }
    toggleCommentsTextarea(event:Event) {
            this.selectedValueData = (event.target as HTMLSelectElement).value;
            const option = this.rtwVisaStaList.find(item => item.id === Number(this.selectedValueData));
            
            const options= option ? option.name : null;

            this.selecedValueItem = options;
            
                  
                      if (options  === 'Other' || options === '') {
                        this.isDivHidden = true;
                       
                      } 
                      else
                       {
                           this.isDivHidden = false;
                      }
                  
                      // if( options === 'Other' )
                      // {
                      //   this.isrequiredclass = true;
                      // }

                      if(this.selecedValueItem === 'Other'){
                        this.isrequiredComclass = true ;
                  
                      }
                      // else{
                      //   this.isrequiredComclass = true ;
                      // }
                      // if(options === 'N/A' )
                      // {
                      //   this.isnotrequiredComclass = false;
                      //   this.isrequiredComclass = false;
                      // }
                      
                      // else{
                      //   this.isnotrequiredComclass = true ;
                  
                      // }
                      if(options === 'N/A' || options === null )
                      {
                        this.isnotrequiredComclass = false;
                        this.isrequiredComclass = false;
                        //this.isrequiredclass = true;
                      }
                      
                      else{
                        this.isnotrequiredComclass = true ;
                      }
                  }

          chekboxclickyes(event) {
            var checkboxclick = event.target;
            if(checkboxclick.checked){
            this.isrequiredclassspo = true;
              
            }
            else
            {
              this.isrequiredclassspo = false;
      
            }
          
      
          }

          ECSStatusData(event:Event){
            this.selectedOtionItem = (event.target as HTMLSelectElement).value;
            
            if(this.selectedOtionItem === 'Requested' || this.selectedOtionItem === 'N/A'){
               this.isrequiredforECS = false ;
             }
             else{
               this.isrequiredforECS = true ;
             }

         }


}
