import { Component, OnInit } from '@angular/core';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { CommonService } from 'src/app/services/common.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {FormControl} from '@angular/forms';


@Component({
  selector: 'app-company',
  templateUrl: './company-list.component.html',

})
export class CompanyListComponent implements OnInit {

  companyList: any;
  companyFilter: any;
  totalRecord: 0;
  cSearch =new FormControl("");
  p: number = 1;
  constructor(private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private router: Router,
    private commonService: CommonService) {
  }

  ngOnInit() {
    this.getCompanyList(1);
  }

  getCompanyList(PageIndex) {
    this.p = PageIndex;
    this.companyList = [];
    this.commonApiService.getListWithFilter(Commonvariable.Company, PageIndex,this.cSearch.value).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.companyList = result["data"]["list"];
        this.companyFilter = result["data"]["list"];
      }
    })
  }


  companyDelete(Id) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.CompanyDetail, Id).subscribe((response) => {
          if (response["status"] == 200) {
            this.getCompanyList(1);
            this.toastr.success(response["message"], 'Company');
          }
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.CompanyDetail, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.toastr.success(response["message"], 'Company');
            this.getCompanyList(1);
          }

        })
      }
    });
  }


}