<form [formGroup]="candidateForm">
    <div class="row" *ngIf= "iscompanyDoc == 0">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label" *ngIf="userRole == 'Candidate'">Other Document<span class="required"> * </span></label>
                <label class="control-label" *ngIf="userRole != 'Candidate'">Other Document<span class="required"> * </span></label>
                <select class="form-control" id="doc_Id" formControlName="doc_Id">
                    <option value="0">--- Select ---</option>
                    <option *ngFor="let item of documentList;" value={{item.id}}>
                        {{item.name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Document Name<span class="required"> * </span></label>
                <input type="text" class="form-control" id="doc_Name" formControlName="doc_Name"
                    autocomplete="off" maxlength="50">
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Upload Document<span class="required"> * </span></label>
                <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="input-group input-large">
                        <div class="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                            <i class="fa fa-file fileinput-exists"></i>&nbsp;
                            <span class="fileinput-filename"> </span>
                        </div>
                        <span class="input-group-addon btn default btn-file">
                            <span class="fileinput-new"> Select file </span>
                            <span class="fileinput-exists"> Change </span>
                            <input type="file" id="files" (change)="fileChange($event)"> </span>
                        <a (click)="removeFile($event)" class="input-group-addon btn red fileinput-exists removeFile" id="remove" data-dismiss="fileinput">
                            Remove </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5" *ngIf= "iscompanyDoc == 0">
            <div class="form-group">
                <label class="control-label">Notes</label>
                <textarea class="form-control" id="Addnotes" formControlName="notes" style="width: 100%;"></textarea>
            </div>
        </div>
        <div class="col-md-1">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="candidateDoc" class="md-check" formControlName="isActive">
                            <label for="candidateDoc">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>