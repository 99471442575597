<style>
    td.details-control {
        background: url('assets/pages/img/SD7Dz.png') no-repeat center center;
        cursor: pointer;
    }

    tr.shown td.details-control {
        background: url('assets/pages/img/d4ICC.png') no-repeat center center;
    }
</style>
<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Leave list
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right mr0">
                <li>
                    <div>
                        <a routerLink="/add-leave" class="btn btn-transparent blue  btn-sm">Add Leave</a>
                    </div>
                </li>
            </ul>
        </div>

        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->


        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" placeholder="Type to filter ..." id="filterList"
                                (input)="getLeaveList(1)">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <!-- <table class="table table-striped table-bordered table-hover" id="sample_2"> -->
                        <table id="example2" class="display table table-striped table-bordered" cellspacing="0"
                            width="100%" style="border:1px solid #e7ecf1;">

                            <thead>
                                <tr>
                                    <th style="width: 5%"></th>

                                    <th class="text-center" style="width: 15%;"> Date</th>
                                    <th class="text-center" style="width: 15%;">Leave Type </th>
                                    <th class="text-center" style="width: 10%;"> Total Days </th>
                                    <th class="text-center" style="width: 10%;"> Status </th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody
                                *ngFor="let item of leaveList | paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">
                                <tr>

                                    <td class="details-control details-control-icon-{{item.id}}"
                                        (click)="expandTable(item.id)"></td>

                                    <td class="text-center">{{item.start_date | date:'dd/MM/yyyy'}}
                                        To {{item.end_date | date:'dd/MM/yyyy'}}</td>
                                    <td class="text-center">{{item.leave_type}}</td>
                                    <td class="text-center">{{item.no_of_leaves}}</td>
                                    <td class="text-center">{{item.status}}</td>
                                    <td class="numeric text-center">
                                        <a (click)="CancelLeaves(item.id,'Cancelled',item.company_id,'0')"
                                            *ngIf="item.status == 'Pending'">
                                            <i class="fa fa-close red-color iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Cancel Leave"></i></a>

                                    </td>
                                </tr>

                                <tr class="sub_tbl sub_tbl_{{item.id}}" style="display:none;">
                                    <td colspan="10">
                                        <table cellpadding="5" cellspacing="0" style="width:50%;"
                                            class="table table-striped table-bordered dataTable no-footer">
                                            <thead>
                                                <tr>
                                                    <th>Leave Date</th>
                                                    <th>Day Type</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item_day of empLeaveList">
                                                    <td>{{item_day.leave_Date | date:'dd-MM-yyyy'}}</td>
                                                    <td *ngIf="item_day.leave_Day_Type == 'Fullday'">Full Day</td>
                                                    <td *ngIf="item_day.leave_Day_Type == 'Firsthalf'">First Half</td>
                                                    <td *ngIf="item_day.leave_Day_Type == 'Secondhalf'">Second Half</td>
                                                    <td
                                                        *ngIf="(item_day.leave_Day_Type == 'Sandwich') || (item_day.leave_Day_Type == 'HolidaySandwich')">
                                                    </td>
                                                    <td>{{item_day.status}}</td>
                                                    <td class="text-center">
                                                        <a (click)="openModel(item_day.id)"
                                                            *ngIf="(item_day.leave_Day_Type != 'Sandwich') && (item_day.leave_Day_Type != 'HolidaySandwich') && (item_day.status == 'Pending')"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit"></i>&nbsp;</a>
                                                        <!--<a (click)="CancelSingleLeaves(item_day.id,'Cancelled',item.id)" *ngIf="(item_day.leave_Day_Type != 'Sandwich') && (item_day.leave_Day_Type != 'HolidaySandwich') && item_day.status != 'Cancelled'"><i
                                                                class="fa fa-close red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Cancel"></i></a>-->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="leaveList != null">
                        <pagination-controls (pageChange)="getLeaveList($event)" autoHide="true">
                        </pagination-controls>
                    </div>

                </div>
            </div>
        </div>





        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->


<div class="modal fade draggable-modal" id="draggable" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Leave Day Type</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="leaveform">
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Day Type</label>
                                    <select id="leave_Day_Type" class="form-control" formControlName="leave_Day_Type">
                                        <option value="">Select Day Type</option>
                                        <option value="Fullday">Full Day</option>
                                        <option value="Firsthalf">First Half</option>
                                        <option value="Secondhalf">Second Half</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" class="btn blue" (click)="Upsert()">Update</button>
            </div>
        </div>
    </div>
</div>