import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
import { groupBy, mergeMap } from 'rxjs/operators';
declare var $: any;

@Component({
   selector: 'app-interview',
   templateUrl: './interview.component.html',
})
export class InterviewComponent implements OnInit {

   interview: InterView[] = [];
   empList: any;
   companyId: any = 0;
   constructor(
      private commonApiService: CommonApiService,
      private commonService: CommonService,
      private router: Router,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {

      this.createItem()
      //this.getEmpList()
   }

   createItem() {
      if (this.validation()) {
         var interViewArray = [];
         this.interview.forEach(x => {
            interViewArray.push(x);
         });
         var addRecord = {
            "Id": 0,
            "name": '',
            "dateTime": '',
            "isDefault": '',
            "interview": '',
            "status": '',
            "note": '',
            "interviewDetail": [0]
         }
         interViewArray.push(addRecord);
         this.interview = interViewArray;
         setTimeout(() => {
            $("#flatpickropenintopen" + (this.interview.length - 1)).flatpickr({ enableTime: true,dateFormat: "d-m-Y H:i", defaultDate: 'today' });
         }, 5);
      }
   }

   removeItem(index) {
      this.interview.splice(index, 1);
   }

   addRemoveInterviewDetail(control, index, childIndex) {
      if (control == 'add') {
         if (this.validation()) {
            this.interview[index].interviewDetail.push((this.interview[index].interviewDetail[this.interview[index].interviewDetail.length - 1]) + 1)
         }
      }
      else if (control == 'minus') {
         this.interview[index].interviewDetail = this.interview[index].interviewDetail.filter(x => x != childIndex)
      }
   }

   getEmpList() {

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": 'firstName'
      }
      this.commonApiService.getPaginated(Commonvariable.EmployeeDetail, null, null, null, null, 10, requestData).subscribe((response) => {
         if (response != null && response["status"] == 200) {
            this.empList = response["data"]["list"]
         }
      })
   }



   upsert(id, vacancy_Id, status) {

      var requestData = {
         "Candidate_Id": id,
         "Vacancy_Id": vacancy_Id,
         "status": status,
         "UpdatedBy": Commonvariable.User_Id,
         "candidate_Interviewer": []
      }

      if (this.validation()) {
         var index = 0
         this.interview.forEach(x => {
            var detailIndex = 0;
            x.interviewDetail.forEach(y => {
               var prepareJson = {
                  "Id": $("#id" + index + this.interview[index].interviewDetail[detailIndex]).val() ? $("#id" + index + this.interview[index].interviewDetail[detailIndex]).val() : 0,
                  "Candidate_Id": id,
                  "Vacancy_Id": vacancy_Id,
                  "Emp_Id": $("#interviewer" + index + this.interview[index].interviewDetail[detailIndex]).val(),
                  "Name": $("#name" + index).val(),
                  "Status": $("#interview_satus" + index + this.interview[index].interviewDetail[detailIndex]).val(),
                  "Date": this.commonService.dateTimeFormatChange($("#interview_date_time" + index).val()),
                  "Notes": $("#Notes" + index + this.interview[index].interviewDetail[detailIndex]).val(),
                  "isActive": 1,
                  "AddedBy": Commonvariable.User_Id,
                  "UpdatedBy": Commonvariable.User_Id
               }
               detailIndex++;
               requestData.candidate_Interviewer.push(prepareJson);
            });
            index++;
         });
      }

      return requestData.candidate_Interviewer.length > 0 ? requestData : null;
   }



   async fillInterviewer(data) {

      if (data != null && data.length > 0) {
         this.interview = []
         let interViewArray = [];
         for (let i = 0; i < data.length; i++) {
            let interviewDetail = []
            for (let index = 0; index < data[i].length; index++) {
               interviewDetail.push(index);
            }
            var addRecord = {
               "Id": 0,
               "name": '',
               "dateTime": '',
               "isDefault": '',
               "interview": '',
               "status": '',
               "note": '',
               "interviewDetail": interviewDetail
            }
            interViewArray.push(addRecord);
            this.interview = interViewArray;

            if (i == (data.length - 1)) {
               setTimeout(() => {
                  this.setInterviewData(data)
               }, 100);

               break;
            }
         }

      }
   }

   setInterviewData(data) {
      for (let index = 0; index < data.length; index++) {
         $("#name" + index).val(data[index][0].name).attr("readonly", true)
         $("#btnAdd" + index).attr("disabled", true)
         let time = data[index][0].date != null ? data[index][0].date.split("T")[1].split(":") : "";
         let date = data[index][0].date != null ? data[index][0].date.split("T")[0].split("-") : "";
         if (date != "") {
            $("#flatpickropenintopen" + index).flatpickr({ enableTime: true,dateFormat: "d-m-Y H:i", defaultDate: date[2] + "-" + date[1] + "-" + date[0] + " " + time[0] + ":" + time[1] });
            $("#interview_date_time" + index).attr("disabled", true)
         }
         this.interview[index].interviewDetail.forEach(x => {
            $("#id" + index + x).val(data[index][x].id).attr("readonly", true)
            $("#interviewer" + index + x).val(data[index][x].emp_Id).attr("disabled", true)
            $("#interview_satus" + index + x).val(data[index][x].status).attr("disabled", true)
            $("#Notes" + index + x).val(data[index][x].notes).attr("readonly", true)
            $("#btnadd" + index + x).attr("disabled", true)
            $("#btnremove" + index + x).attr("disabled", true)

         });

      }

   }





   validation(interviewerCheck = false) {

      if (this.interview.length > 0) {

         for (let index = 0; index < this.interview.length; index++) {
            if ($("#name" + index).val() == "" && !interviewerCheck) {
               this.commonService.toaster("info", "Name is required !", "Interview")
               $("#name" + index).focus();
               return false;
            }
            if ($("#interview_date_time" + index).val() == "" && !interviewerCheck) {
               this.commonService.toaster("info", "Datetime is required !", "Interview")
               $("#interview_date_time" + index).focus();
               return false;
            }
            if (this.interview[index].interviewDetail.length > 0) {
               for (let i = 0; i < this.interview[index].interviewDetail.length; i++) {
                  if ($("#interviewer" + index + this.interview[index].interviewDetail[i]).val() == "" && !interviewerCheck) {
                     this.commonService.toaster("info", "Interviewer is required !", "Interview")
                     $("#interviewer" + index + this.interview[index].interviewDetail[i]).focus();
                     return false;
                  }

                  if (i != 0) {

                     // for (let y = i - 1; y <= 0; y--) {
                     if ($("#interviewer" + index + this.interview[index].interviewDetail[i - 1]).val() == $("#interviewer" + index + this.interview[index].interviewDetail[i]).val() && interviewerCheck) {
                        this.commonService.toaster("info", "Interviewer already selected !", "Interview")
                        $("#interviewer" + index + this.interview[index].interviewDetail[i]).val("");
                        $("#interviewer" + index + this.interview[index].interviewDetail[i]).focus();
                        return false;
                     }
                     // }

                  }

                  if ($("#interview_satus" + index + this.interview[index].interviewDetail[i]).val() == "" && !interviewerCheck) {
                     this.commonService.toaster("info", "Interview satus is required !", "Interview")
                     $("#interview_satus" + index + this.interview[index].interviewDetail[i]).focus();
                     return false;
                  }
               }
            }
         }
      }

      return true;
   }

}

interface InterView {
   Id: number;
   name: string;
   dateTime: string;
   isDefault: number;
   interview: string;
   status: string;
   note: string;
   interviewDetail: number[];
}


