import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';

import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ComingSoonComponent } from 'src/app/home/comingsoon/comingsoon.component';
import fieldVisibleInvisibleData from 'src/app/_files/field_visible_invisible.json';
declare var $: any;
declare const Bloodhound;

@Component({
  selector: 'app-leaves',
  templateUrl: './leaves.component.html'
})
export class LeavesComponent implements OnInit {

  leaveform: FormGroup;
  leaveList: any;
  leaveFilter: any;
  totalRecord: 0;
  p: number = 1;
  empLeaveList: any;
  title: string;
  constructor(private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private router: Router,
    private commonService: CommonService,
    private fb: FormBuilder) {
  }

  ngOnInit() {

    this.leaveform = this.fb.group({
      id: 0,
      employee_Leave_Id: 0,
      leave_Day_Type: [''],
      company_id: 0,
      emp_id: 0,
      leave_type_id: 0,
      leave_Date: null
    });

    this.getLeaveList(1);
  }

  getLeaveList(PageIndex) {
    this.p = PageIndex;
    this.leaveList = [];

    var requestData = {
      "searchBy": [
        {
          "FieldName": "Emp_Id",
          "FieldValue": Commonvariable.hrEmpId != "" ? Commonvariable.hrEmpId : Commonvariable.emp_Id,
          "Parameter": "=="
        }
      ],
      "PageIndex": PageIndex - 1,
      "CommonSearch": $("#filterList").val().trim(),
      "PageSize": 10,
    }
    this.commonApiService.getPaginated(Commonvariable.Employee_Leave, null, null, null, null, null, requestData).subscribe((result) => {

      //this.commonApiService.getListWithFilterByGet(Commonvariable.Employee_Leave, PageIndex, $("#filterList").val(),'Get_Employee_Leave/1').subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.leaveList = result["data"]['list'];
        this.leaveFilter = result["data"]['list'];
      }
    })

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }


  CancelLeaves(Id, status = null, compony_id = null, reporter_id = null) {
    this.commonService.dyanmicConfirm("You want to cancel !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.leaveUpdateStatus(Commonvariable.Employee_Leave, Id, status, compony_id, reporter_id).subscribe((response) => {
          if (response["status"] == 200) {
            this.toastr.success(response["message"], 'leave');
            this.expandTable(Id);
            this.getLeaveList(1);
          }
        })
      }

    });
  }
  
  CancelSingleLeaves(Id,  compony_id = null, status = null, empLeaveId) {
    this.commonService.dyanmicConfirm("You want to cancel !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.leaveSingleUpdateStatus(Commonvariable.Employee_Leave, Id,compony_id, status).subscribe((response) => {
          if (response["status"] == 200) {
            this.expandTable(empLeaveId);
            this.toastr.success(response["message"], 'leave');
          }
        })
      }

    });
  }



  getEmpLeaveDays(id) {

    this.empLeaveList = [];
    this.commonApiService.getById(Commonvariable.Employee_Leave, id, "GetEmployeeLeaveDayList").subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.empLeaveList = result["data"];
      }
    })
  }

  expandTable(id) {
    this.empLeaveList = [];
    var visibility = $(".sub_tbl_" + id).toggle().is(":visible");
    $(".sub_tbl").hide();
    $(".details-control").css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    if (visibility === true) {
      $(".sub_tbl_" + id).show();
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/d4ICC.png) no-repeat center center");
      this.getEmpLeaveDays(id);
    } else {
      $(".details-control-icon-" + id).css("background", "url(assets/pages/img/SD7Dz.png) no-repeat center center");
    }

  }

  openModel(id) {

    if (id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Employee_Leave, id, 'GetEmployeeLeaveDay').subscribe((response) => {
        if (response != null) {
          var editData = response["data"];
          var formData = this.leaveform;
          //this.commonService.setEditData(formData, editData);                    
          //console.log(editData.leave_Day_Type);
          formData.controls.id.setValue(editData.id);
          formData.controls.leave_Day_Type.setValue(editData.leave_Day_Type);
          formData.controls.employee_Leave_Id.setValue(editData.employee_Leave_Id);
          formData.controls.company_id.setValue(editData.company_id);
          formData.controls.emp_id.setValue(editData.emp_id);
          formData.controls.leave_type_id.setValue(editData.leave_type_id);
          formData.controls.leave_Date.setValue(editData.leave_Date);
        }
      })
    }
    else {
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });

  }

  Upsert() {
    var leaveForm = this.leaveform;

    this.commonApiService.UpsertLeave(Commonvariable.Employee_Leave, leaveForm.value, leaveForm.controls.id.value).subscribe((response) => {
      if (response != null) {
        if (response["data"] != null && response["data"] != "") {
          $("#draggable").modal('hide');
          this.toastr.success(response["message"], 'Leave');
          this.getLeaveList(1);
          this.expandTable(leaveForm.controls.id.value);
        }
        else {
          this.toastr.warning(response["message"], 'Leave');
        }

      }
    })
  }
}
