<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>Approval Process Configuration</h1>
            </div>
        </div>
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div class="col-md-6 dropSelect_company">
                        <div class="input-group input-xlarge">
                            <div class="input-group-btn">
                                <button type="button" class="btn blue dropdown-toggle" data-toggle="dropdown">Search By
                                    Company
                                </button>
                            </div>
                            <ng-select2 [data]="companyListForFilter" [width]="300"
                                (valueChanged)="getSiteList('forFilter',$event)"
                                [placeholder]="'--- Select ---'"></ng-select2>

                        </div>
                    </div>
                </li>
                <li>
                    <div class="col-md-6 dropSelect_company">
                        <div class="input-group input-xlarge">
                            <div class="input-group-btn">
                                <button type="button" class="btn blue dropdown-toggle" data-toggle="dropdown">Search By
                                    Site
                                </button>
                            </div>
                            <ng-select2 [data]="siteListForFilter"  (valueChanged)="getRafRuleList($event,0)" [width]="300" [placeholder]="'--- Select ---'">
                            </ng-select2>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="col-md-6 dropSelect_company">
                        <div class="input-group input-xlarge">
                            <div class="input-group-btn">
                                <button type="button" class="btn blue dropdown-toggle" data-toggle="dropdown">Search By
                                    Department
                                </button>
                            </div>
                            <ng-select2 [data]="departmentListForFilter" (valueChanged)="getRafRuleList(0,$event)" [width]="260" [placeholder]="'--- Select ---'">
                            </ng-select2>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <a class="btn btn-transparent blue  btn-sm" data-toggle="modal">Create Approval Process</a>
                    </div>
                </li>
            </ul>
        </div>
            
        
        <!-- <div class="col-md-12">
            <div class="portlet light bordered" id="form_wizard_1">
                <p style="margin: 5px 0;">&nbsp;</p>
                <div class="portlet-body form">                  
                   
                    <form class="form-horizontal" action="#" id="submit_form" method="POST">
                        <div class="form-group">
                            <div class="col-md-12">
                                <div class="portlet box white">
                                    <div class="portlet-body table-responsive" id="rafTable">
                                
                                        <table id="example2" class="display table table-striped table-bordered"
                                            cellspacing="0" width="100%" style="border:1px solid #e7ecf1;">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Approval Process</th>
                                                    <th>Company</th>
                                                    <th>Site</th>
                                                    <th>Department</th>
                                                    <th>No of Approvers</th>
                                                    <th>Name of Recruiter</th>
                                                    <th>Created On</th>
                                                    <th>Created By</th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                            </thead>

                                            <tbody
                                                *ngFor="let item of rafRuleList | paginate: { itemsPerPage: 10, currentPage: P ,totalItems :totalItemForRafRuleList, id: 'pagination1' }">
                                                <tr id="{{item.id}}" role="row" class="odd"
                                                    title="{{item.vacancy_Rule_Id}}" tabindex="{{item.company_Id}}">
                                                    <td class="details-control details-control-icon-{{item.id}}"></td>
                                                    <td class="sorting_1"> 
                                                        
                                                        <div class="col-md-1 col-xs-2">
                                                            <a (click)="updateStatus(item.id,'0')">
                                                            <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                                                data-placement="top" title="Active"></div>
                                                            </a>
                                                            <a (click)="updateStatus(item.id,'1')">
                                                            <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                                                data-placement="top" title="Inactive"></div>
                                                            </a>
                                                                
                                                        </div>
                                                        <div class="col-md-1 col-xs-10 text-wrap">
                                                            {{item.name}}
                                                        </div>
                                                    </td>
                                                    <td> {{item.company_Name}}</td>
                                                    <td> {{item.site_Name}}</td>
                                                    <td> {{item.dept_Name}}</td>
                                                    <td> {{item.rule_Detail_Count}}</td>
                                                    <td> {{item.rule_Recruiter_Name}}</td>
                                                    <td> {{item.addedOn | date: 'dd/MM/yyyy'}}</td>
                                                    <td> {{item.addedBy_Name}}</td>
                                                    <td class="text-center">
                                                        <a *ngIf="item.isActive" data-toggle="modal" href="#Createrecruitment" (click)="editRules(item.company_Id,item.site_Id,item.dept_Id)"><i class="fa fa-pencil iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Edit"></i></a>
                                                        <a *ngIf="!item.isActive" (click)="rafConfigDelete(item.id)"><i class="fa fa-trash-o red-color iconcss iconFontsize"  data-toggle="tooltip" data-placement="top" title="Delete RAF"></i></a>
                                                    </td>
                                                </tr>
                                                <tr class="sub_tbl sub_tbl_{{item.id}}" style="display:none;">
                                                    <td colspan="10">
                                                        <table cellpadding="5" cellspacing="0" style="width:80%;"
                                                            class="table table-striped table-bordered dataTable no-footer">
                                                            <thead>
                                                                <tr>
                                                                    <th>Job Title</th>
                                                                    <th>Approver Name</th>
                                                                    <th>Final Approver</th>
                                                                    <th>Priority</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let items of rafRuleApprovalList">
                                                                    <td>{{items.desig_Name}}</td>
                                                                    <td>{{items.emp_Name}}</td>
                                                                    <td>{{items.isFinal === 1 ? 'Yes' : 'No'}}</td>
                                                                    <td>{{items.priority}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-center" *ngIf="myApprovallist!= null">
                                        <pagination-controls (pageChange)="myApprovalServerPagination($event)"
                                            autoHide="true" id="pagination1"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div> -->
        <!-- END PAGE BASE CONTENT -->
    </div>
</div>