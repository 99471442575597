import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;
import * as moment from 'moment';
import { Select2OptionData } from 'ng-select2';
import { element } from 'protractor';


@Component({
   selector: 'app-emprightwork',
   templateUrl: './employee-right-work.component.html',
})
export class EmployeeRightWorkComponent implements OnInit {

   empform: FormGroup;
   items: FormArray;
   companyId: any = 0;
   rtwCategoryList: any[];
   rtwVisaStaList: any[];
   nationalityList: any[];
   rtwvisastatusList: any[];
   modalOpen: boolean = false;
   notRequired = new FormControl(false)
   id = 0
   selectedText: any;
   selectedItem: any;
   isrequiredclass: boolean = true;
   isrequiredforECS: boolean = true;
   public isDivHidden: any;
   // public isrequiredclass: any;
   public isrequiredclassspo: boolean = false;
   //public sponserempreq: any;
   isSponsoredEmployee = new FormControl(false);
   empRighttoworkRequired = new FormControl(false);
   public isrequiredComclass: boolean = false;
   public isnotrequiredComclass: boolean = false;
   isValueSelected = false;
   selectedValueData: string;
   selectedOtionItem: any;
   selecedValueItem: any;
   selectedStatustype: string;
   constructor(
      private toastr: ToastrService,
      private commonService: CommonService,
      private commonApiService: CommonApiService,
      private router: Router,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {


      this.empform = this.fb.group({
         emp_righttowork: this.fb.array([this.createItem(null)]),
      });
      setTimeout(() => {
         this.commonService.flatpickr("flatpickropenecsRec0", 0)
         this.commonService.flatpickr("flatpickropenecsED0", 0)
         this.commonService.flatpickr("flatpickropenrtwVSED0", 0)
         this.commonService.flatpickr("flatpickropenpassportED0", 0)
         this.commonService.flatpickr("flatpickropensponsershipED", 0)
      }, 5);
      this.companyId = JSON.parse(localStorage.getItem('employeeHRCompanyId'));
      if (this.companyId != null) {

         this.getRTWList(this.companyId);

      }
      this.getListVisaStype();
      this.getNationalityList();

      //this.getRTWCategory()
   }

   // onValueChange(selectedValues: string[]): void {
   //    this.selectedText = selectedValues;


   //    this.isrequiredclass = true;

   //    for (const value of selectedValues) {
   //      if (value === "7" || value === "74" || value === "43") {
   //        this.isrequiredclass = false;
   //        break; 
   //      }
   //    }
   //  }

   onValueChange(selectedValues: any[]): void {
   //   if (Array.isArray(selectedValues)) {
   //       const selectedTextValues = selectedValues.map(selectedValue => {
   //          const option = this.nationalityList.find(item => item.id === String(selectedValue));
   //          return option ? option.text : null;
            
            
   //       });

         //  const fieldControl = this.empform.get('ecsstatus');
         //  const fieldValue = fieldControl.value;
          selectedValues = [selectedValues[0]];
          if (Array.isArray(selectedValues)) {
            const selectedTextValues = selectedValues.map(selectedValue => {
               const option = this.nationalityList.find(item => item.id === Number(selectedValue));
               return option ? option.text : null;
               
               
            });
            this.selectedText = selectedTextValues;
         
       //  const option = this.nationalityList.find(item => item.id === parseInt(selectedValues[0], 10));
        // this.selectedText = option;

         this.isrequiredclass = true;


         if (this.selectedText[0] === "British" || this.selectedText[0] === "Irish") {
            this.isrequiredclass = false;
            this.isrequiredforECS = false;
            this.isnotrequiredComclass = false;
         }
         else if (selectedValues.length > 1) {
            let i;
            var nat=false;
            for (i = 0; i < selectedValues.length; i++) {
               if (this.selectedText[i] === "British" || this.selectedText[i] === "Irish") {

                  nat=true;
                  this.isrequiredclass = false;
                  this.isrequiredforECS = false;
                  this.isnotrequiredComclass = false;
                 

               } else{
                  nat=false;
                  break;
               }
            }
               if(nat==true )
               {
                  this.isrequiredclass = false;
                  this.isrequiredforECS = false;
                  this.isnotrequiredComclass = false;

                  if((this.selectedOtionItem === "Received"))
                  {
                     //this.isrequiredclass = true;
                     this.isrequiredforECS = true;
                     this.isnotrequiredComclass = false;
                  }

                  
                  
               }

               //  if (this.selectedOtionItem === "Requested" || this.selectedOtionItem === "N/A") {
               //    this.isrequiredclass = true;
               //    this.isrequiredforECS = false;
               //    this.isnotrequiredComclass = true;
                  
               // }
               // else {
               //    this.isrequiredclass = true;
               //    this.isrequiredforECS = true;
               //    this.isnotrequiredComclass = true;
                  
               // }

            

         }

         else {
            for (const textValue of selectedValues) {
               if (textValue === "British" || textValue === "Irish") {
                  this.isrequiredclass = false;
                  this.isrequiredforECS = false;
                  this.isnotrequiredComclass = false;
                  break;
               }
            }
         }
     }
      else {
         this.isrequiredclass = true;
         this.isrequiredforECS = true;
         this.isnotrequiredComclass = true;
      }
   }

   createItem(data) {

      return this.fb.group({
         LstNationality_Id: ['0', Validators.required],
         RightToWork_Id: '0',
         ECSExpiryDate: '',
         Emp_Id: '0',
         RWC_Id: '0',
         ECSStatus: '',
         ECSReceivedDate: '',
         RTW_VisaStatus_Id: '0',
         Other: '',
         RTW_VisaStatus_ExpiryDate: '',
         PassportExpiryDate: '',
         SponsoredExpiryDate: '',
         isSponsoredEmployee: '0',
         Comments: '',
         isActive: ['1'],
         addedBy: Commonvariable.User_Id,
         // IssueDate: '',
         // IssueAuthority: '',
         // IssueCountry: '',
         // ExpiryDate: '',
         // Emp_Doc_Id: '',
         Notes: '',

      });


   }


   setId(event) {
      this.id = event.target.value
   }

   getNationalityList() {
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "name"
      }

      this.commonApiService.GetByPaginated(Commonvariable.Nationality, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            //this.nationalityList = response["data"];
            this.nationalityList = response["data"].map(x => ({
               id: x.id,
               text: x.name
            }))
         }
      })

   }
   setFormData(fieldName, id) {
      if (id != null) {
         if (id != undefined && id[0] != "") {
            // this.nationality_Id_List = id[0] == "" ? [] : id;
            // console.log(this.nationality_Id_List);
            this.empform.controls[fieldName].setValue(id[0] == "" ? [] : id)
         }
         else {
            this.empform.controls[fieldName].setValue([]);
         }
      }
      else {
         this.empform.controls[fieldName].setValue(null);
      }
   }


   getRTWList(id) {

      this.rtwCategoryList = [];

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "company_Id",
               "FieldValue": id,
               "Parameter": "=="
            }
         ]

      }

      this.commonApiService.GetByPaginated(Commonvariable.RightToWork_Category, requestData).subscribe((response) => {
         if (response["data"] != "") {
            this.rtwCategoryList = response["data"]["0"];
         }
      })
   }
   getListVisaStype() {
      this.rtwVisaStaList = [];

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "Id"


      }

      this.commonApiService.GetByPaginated(Commonvariable.RTWVisaStatus, requestData).subscribe((response) => {
         if (response["data"] != "") {
            this.rtwVisaStaList = response["data"];
            console.log(this.rtwVisaStaList);
         }
      })
   }



   // getRTWCategory() {
   //    this.commonApiService.List(Commonvariable.RightToWork_Category).subscribe((response) => {
   //       if (response != null && response["data"] != "") {
   //          this.rtwCategoryList = response["data"].sort((a, b) => a.name.localeCompare(b.name));
   //       }
   //    })
   // }

   addItem(data) {
      if (this.validation()) {
         this.items = this.empform.get('emp_righttowork') as FormArray;
         this.items.push(this.createItem(data));
         setTimeout(() => {
            this.items = this.empform.get('emp_righttowork') as FormArray;
            var id = this.items.length - 1;
            this.commonService.flatpickr("flatpickropenIssueD", id)
            this.commonService.flatpickr("flatpickropenExpiryD", id)
            this.id = 0
         }, 5);
      }
   }


   removeItem(index) {
      this.items = this.empform.get('emp_righttowork') as FormArray;
      this.items.removeAt(index);
   }

   empUpsert() {
      var empData = this.empform.get('emp_righttowork').value;
      if (!Array.isArray(empData[0].LstNationality_Id)) {
         // Convert the string to an array with a single element
         empData[0].LstNationality_Id = [empData[0].LstNationality_Id];
       }
     
      
      // if (empData[0].RWC_Id == "0") {
      //    return false;
      // }

      // if (!this.notRequired.value && empData[0].RWC_Id != "0") {
      //    return true;
      // }
      // if (!this.notRequired.value && empData[0].RWC_Id != "0") {
      //    return true;
      // }

      return true;
   }

   validation() {
      var empData = this.empform.get('emp_righttowork').value;
      var tempArray = [];
      for (let index = 0; index < empData.length; index++) {
         empData[index].ECSExpiryDate = this.commonService.dateFormatChange($("#ECSExpiryDate" + index).val())
         empData[index].ECSReceivedDate = this.commonService.dateFormatChange($("#ECSReceivedDate" + index).val())
         empData[index].RTW_VisaStatus_ExpiryDate = this.commonService.dateFormatChange($("#RTW_VisaStatus_ExpiryDate" + index).val())
         empData[index].PassportExpiryDate = this.commonService.dateFormatChange($("#PassportExpiryDate" + index).val())
         empData[index].SponsoredExpiryDate = this.commonService.dateFormatChange($("#SponsoredExpiryDate").val())
         empData[index].RWC_Id = $("#RWC_Id" + index).val()
         empData[index].RTW_VisaStatus_Id = $("#RTW_VisaStatus_Id" + index).val()
         empData[index].isActive = empData[index].isActive ? 1 : 0
         empData[index].isSponsoredEmployee = empData[index].isSponsoredEmployee ? 1 : 0
         // empData[index].LstNationality_Id.setValue(empData[index].LstNationality_Id)
         //empData[index].LstNationality_Id = $("#LstNationality_Id" + index + " option:selected").text().trim();


         if (empData[index].LstNationality_Id == "") {
            this.toastr.info("Nationality is required !", "Employee");
            $("#LstNationality_Id" + index).focus();
            return false;
         }

         // if (empData[index].ECSStatus == "" && this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish") {
         //    this.toastr.info("ECS Status is required !", "Employee");
         //    $("#ECSStatus" + index).focus();
         //    return false;
         // }

         for (let i = 0; i < this.selectedText.length; i++) {
            if ((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish")) {
               if (empData[index].ECSStatus == "") {
                  this.toastr.info("ECS Status is required !", "Employee");
                  $("#ECSStatus" + index).focus();
                  return false;
               }

            }
         }


         //    for(let i=0;i<this.selectedText.length;i++)
         //    {
         //    if ( this.selectedText[i] != "" || this.selectedText[i] != "British" || this.selectedText[i] != "Irish" || this.selectedOtionItem != 'Requested' || this.selectedOtionItem != 'N/A') {
         //       if(empData[index].ECSReceivedDate == ""){
         //          this.toastr.info("ECS Received Date is required !", "Employee");
         //       $("#ECSReceivedDate" + index).focus();
         //       return false;
         //       }

         //    }
         // }
         // for(let i=0;i<this.selectedText.length;i++)
         // {
         //    if ( this.selectedText[i] != "" || this.selectedText[i] != "British" || this.selectedText[i] != "Irish" || this.selectedOtionItem != 'Requested' || this.selectedOtionItem != 'N/A') {
         //       if(empData[index].ECSExpiryDate == "" ){
         //          this.toastr.info("ECS Expiry Date is required !", "Employee");
         //          $("#ECSExpiryDate" + index).focus();
         //          return false;
         //       }

         //    }
         // }

         //    for(let i=0;i<this.selectedText.length;i++)
         //    {
         //    if ( this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && this.selectedOtionItem != 'Requested' && this.selectedOtionItem != 'N/A') {
         //       if(empData[index].ECSReceivedDate == ""){
         //          this.toastr.info("ECS Received Date is required !", "Employee");
         //       $("#ECSReceivedDate" + index).focus();
         //       return false;
         //       }

         //    }
         // }
         // for(let i=0;i<this.selectedText.length;i++)
         // {
         //    if ( this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && this.selectedOtionItem != 'Requested' && this.selectedOtionItem != 'N/A') {
         //       if(empData[index].ECSExpiryDate == "" ){
         //          this.toastr.info("ECS Expiry Date is required !", "Employee");
         //          $("#ECSExpiryDate" + index).focus();
         //          return false;
         //       }

         //    }
         // }


         if (this.selectedOtionItem != 'Requested' || this.selectedOtionItem != 'N/A') {
            for (let i = 0; i < this.selectedText.length; i++) {
               if ((empData[index].ECSStatus == "Received") || (this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && this.selectedText[i] != "Irish" && empData[index].ECSStatus != "Requested" && empData[index].ECSStatus != "N/A")) {
                  if (empData[index].ECSReceivedDate == "") {
                     this.toastr.info("ECS Received Date is required !", "Employee");
                     $("#ECSReceivedDate" + index).focus();
                     return false;
                  }

               }
            }
         }

         if (this.selectedOtionItem != 'Requested' || this.selectedOtionItem != 'N/A') {
            for (let i = 0; i < this.selectedText.length; i++) {
               if ((empData[index].ECSStatus == "Received") || (this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && empData[index].ECSStatus != "Requested" && empData[index].ECSStatus != "N/A")) {
                  if (empData[index].ECSExpiryDate == "") {
                     this.toastr.info("ECS Expiry Date is required !", "Employee");
                     $("#ECSExpiryDate" + index).focus();
                     return false;
                  }

               }
            }
         }


         // if ((empData[index].RWC_Id == null && this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish") || empData[index].RWC_Id == "") {
         //    this.toastr.info("RTW DocumentType is required !", "Employee");
         //    $("#RWC_Id" + index).focus();
         //    return false;
         // }


         for (let i = 0; i < this.selectedText.length; i++) {
            if ((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish")) {
               if (empData[index].RWC_Id == null || empData[index].RWC_Id == "") {
                  this.toastr.info("RTW DocumentType is required !", "Employee");
                  $("#RWC_Id").focus();
                  return false;
               }

            }
         }

         for (let i = 0; i < this.selectedText.length; i++) {
            if ((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && this.selectedValueData != "") || empData[index].RTW_VisaStatus_Id == "" || empData[index].RTW_VisaStatus_Id == undefined) {
               if (empData[index].RTW_VisaStatus_Id == "0" || empData[index].RTW_VisaStatus_Id == undefined) {
                  this.toastr.info("RTW - Visa/Status Type is required !", "Employee");
                  $("#RTW_VisaStatus_Id" + index).focus();
                  return false;
               }

            }
         }


         if (this.isrequiredclassspo === true) {
            if (this.selectedText != "" || this.selectedText != "British" || this.selectedText != "Irish") {
               if (empData[index].SponsoredExpiryDate == "") {
                  this.toastr.info("sponsored Expiry Date is required !", "Employee");
                  $("#SponsoredExpiryDate").focus();
                  return false;
               }

            }
            // if (empData.SponsoredExpiryDate.value == "") {
            //    this.toastr.info("sponsored Expiry Date is required !", "Employee");
            //    $("#SponsoredExpiryDate").focus();
            //    return false;
            // }

         }
         if (this.isrequiredComclass === true) {
            if (this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish" || this.selectedValueData === "12") {
               if (empData[index].Other == "") {
                  this.toastr.info("Other Value is required !", "Employee");
                  $("#Other").focus();
                  return false;
               }
            }

            // if (empData.SponsoredExpiryDate.value == "") {
            //    this.toastr.info("sponsored Expiry Date is required !", "Employee");
            //    $("#SponsoredExpiryDate").focus();
            //    return false;
            // }

         }
         //     for(let i=0;i<this.selectedText.length;i++)
         //     {
         //    if ( (this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && this.selectedValueData != "1") || this.selectedValueData != undefined) {
         //       if(empData[index].RTW_VisaStatus_ExpiryDate == ""){
         //          this.toastr.info("RTW - Visa/Status Expiry Date is required !", "Employee");
         //          $("#RTW_VisaStatus_ExpiryDate" + index).focus();
         //          return false;
         //       }

         //    }
         // }
         if (empData[index].RTW_VisaStatus_Id != "1") {
            var Nationality = true;
            for (let i = 0; i < this.selectedText.length; i++) {
               if ((this.selectedText[i] != "")) {

                  if (this.selectedText[i] == "British" || this.selectedText[i] == "Irish") {

                     Nationality = true;
                     
                  }else
                  {
                     Nationality=false;
                  }
               }
            }

            if(Nationality==true)
            {
               if(empData[index].RTW_VisaStatus_Id == "0" || empData[index].RTW_VisaStatus_Id == "1")
               {
                 
               }else
               {
                if (empData[index].RTW_VisaStatus_ExpiryDate == "") {
                  this.toastr.info("RTW - Visa/Status Expiry Date is required !", "Employee");
                  $("#RTW_VisaStatus_ExpiryDate" + index).focus();
                  return false;
               }
            }

            }else
            {
               if (empData[index].RTW_VisaStatus_ExpiryDate == "" && empData[index].RTW_VisaStatus_Id != "1") {
                  this.toastr.info("RTW - Visa/Status Expiry Date is required !", "Employee");
                  $("#RTW_VisaStatus_ExpiryDate" + index).focus();
                  return false;
               }
            }

            
         }


         // if (empData[index].RTW_VisaStatus_Id != "1") {
         //    for (let i = 0; i < this.selectedText.length; i++) {
         //       if ((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish")) {
         //          if (empData[index].RTW_VisaStatus_ExpiryDate == "" || empData[index].RTW_VisaStatus_Id != "1") {
         //             this.toastr.info("RTW - Visa/Status Expiry Date is required !", "Employee");
         //             $("#RTW_VisaStatus_ExpiryDate" + index).focus();
         //             return false;
         //          }

         //       }
         //    }
         // }


         // for(let i=0;i<this.selectedText.length;i++)
         // {
         //    if ( (this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish" && this.selectedValueData != "1") || this.selectedValueData != undefined) {
         //       if(empData[index].PassportExpiryDate == "" ||  this.selectedStatustype !="N/A"){
         //          this.toastr.info("Passport Expiry Date is required !", "Employee");
         //          $("#PassportExpiryDate" + index).focus();
         //          return false;
         //       }

         //    }
         // }


         for (let i = 0; i < this.selectedText.length; i++) {
            if ((this.selectedText[i] != "" && this.selectedText[i] != "British" && this.selectedText[i] != "Irish")) {
               if (empData[index].PassportExpiryDate == "") {
                  this.toastr.info("Passport Expiry Date is required !", "Employee");
                  $("#PassportExpiryDate" + index).focus();
                  return false;
               }

            }
         }


         // if (empData[index].SponsoredExpiryDate == "" && this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish") {
         //    this.toastr.info("Sponsorship Expiry Date  is required !", "Employee");
         //    $("#SponsoredExpiryDate" + index).focus();
         //    return false;
         // }

         // if ((empData[index].isSponsoredEmployee == 1 && this.selectedText != "" && this.selectedText != "7" && this.selectedText != "43" && this.selectedText != "74") || empData[index].isSponsoredEmployee == 0) {
         //    this.toastr.info("Sponsored Type is required !", "Employee");
         //    $("#isSponsoredEmployee" + index).focus();
         //    return false;
         // }

         // if (empData[index].Comments == "" && this.selectedText != "" && this.selectedText != "British" && this.selectedText != "Irish") {
         //    this.toastr.info("Comments is required !", "Employee");
         //    $("#Comments" + index).focus();
         //    return false;
         // }

         if ($("#Comments" + index).val().length > 200) {
            this.toastr.info("Comments only 200 digit long allow !", "Employee");
            $("#Comments" + index).focus();
            return false;
         }


         var tempData = {
            "RWC_Id": String(empData[index].RWC_Id).trim(),
            "ECSExpiryDate": String(empData[index].ECSExpiryDate).trim(),
            "ECSReceivedDate": String(empData[index].ECSReceivedDate).trim(),
            "RTW_VisaStatus_ExpiryDate": String(empData[index].RTW_VisaStatus_ExpiryDate).trim(),
            "PassportExpiryDate": String(empData[index].PassportExpiryDate).trim(),
            "SponsoredExpiryDate": String(empData[index].SponsoredExpiryDate).trim(),
            "LstNationality_Id": String(empData[index].LstNationality_Id).trim(),
         }
         tempArray.push(tempData);




      }
      //    if (!this.notRequired.value) {

      //       // if ($("#RightToworkCategory" + index).val() == "0") {
      //       //    this.toastr.info("Right to work category is required !", "Employee");
      //       //    $("#RightToworkCategory" + index).focus();
      //       //    return false;
      //       // }
      //       // if ($("#IssueDate" + index).val() == "") {
      //       //    this.toastr.info("Issue Date is required !", "Employee");
      //       //    $("#IssueDate" + index).focus();
      //       //    return false;
      //       // }
      //       // if (empData[index].IssueAuthority == "") {
      //       //    this.toastr.info("Issue authority is required !", "Employee");
      //       //    $("#IssueAuthority" + index).focus();
      //       //    return false;
      //       // }
      //       // if ($("#IssueAuthority" + index).val().length < 2) {
      //       //    this.toastr.info("Issue authority must be at least 2 characters long !", "Employee");
      //       //    $("#IssueAuthority" + index).focus();
      //       //    return false;
      //       // }
      //       // if ($("#IssueAuthority" + index).val().search(/^[^-\s][a-zA-Z0-9_\s-]+$/) != 0) {
      //       //    this.toastr.info("Issue authority not allow space !", "Employee");
      //       //    $("#IssueAuthority" + index).focus();
      //       //    return false;
      //       // }
      //       // if ($("#IssueAuthority" + index).val().search(/[^a-zA-Z\s]+/) > -1) {
      //       //    this.toastr.info("Issue authority only alphabets allow !", "Employee");
      //       //    $("#IssueAuthority" + index).focus();
      //       //    return false;
      //       // }
      //       // if (empData[index].IssueCountry == "") {
      //       //    this.toastr.info("Issue country is required !", "Employee");
      //       //    $("#IssueCountry" + index).focus();
      //       //    return false;
      //       // }
      //       // if ($("#IssueCountry" + index).val().length < 2) {
      //       //    this.toastr.info("Issue country must be at least 2 characters long !", "Employee");
      //       //    $("#IssueCountry" + index).focus();
      //       //    return false;
      //       // }
      //       // if ($("#IssueCountry" + index).val().indexOf(" ") > -1) {
      //       //    this.toastr.info("Issue country not allow space !", "Employee");
      //       //    $("#IssueCountry" + index).focus();
      //       //    return false;
      //       // }

      //       // if ($("#RightToworkCategory" + index).val() == 13 || $("#RightToworkCategory" + index).val() == 14 || $("#RightToworkCategory" + index).val() == 15 || $("#RightToworkCategory" + index).val() == 16 || $("#RightToworkCategory" + index).val() == 17) {

      //       // }
      //       // else {
      //       //    if ($("#ExpiryDate" + index).val() == "") {
      //       //       this.toastr.info("Expiry Date is required !", "Employee");
      //       //       $("#ExpiryDate" + index).focus();
      //       //       return false;
      //       //    }
      //       // }
      //       if (moment($("#IssueDate" + index).val(), 'DD-MM-YYYY') >= moment($("#ExpiryDate" + index).val(), 'DD-MM-YYYY')) {
      //          this.toastr.info("Issue date not more then expiry date and not equal to !", "Employee");
      //          $("#IssueDate" + index).focus();
      //          return false;
      //       }
      //    }
      //    empData[index].IssueDate = this.commonService.dateFormatChange($("#IssueDate" + index).val())
      //    empData[index].ExpiryDate = this.commonService.dateFormatChange($("#ExpiryDate" + index).val())
      //    empData[index].RWC_Id = $("#RightToworkCategory" + index).val()
      //    empData[index].isActive = empData[index].isActive ? 1 : 0


      //    var tempData = {
      //       "RWC_Id": String(empData[index].RWC_Id).trim(),
      //       "IssueDate": String(empData[index].IssueDate).trim(),
      //       "IssueAuthority": String(empData[index].IssueAuthority).trim(),
      //       "IssueCountry": String(empData[index].IssueCountry).trim(),
      //       "ExpiryDate": String(empData[index].ExpiryDate).trim(),
      //    }

      //    if (tempArray.length > 0) {
      //       var IssueDate = tempArray.filter(x => x.IssueDate == tempData.IssueDate).length;
      //       var IssueAuthority = tempArray.filter(x => x.IssueAuthority == tempData.IssueAuthority).length;
      //       var IssueCountry = tempArray.filter(x => x.IssueCountry == tempData.IssueCountry).length;
      //       var ExpiryDate = tempArray.filter(x => x.ExpiryDate == tempData.ExpiryDate).length;

      //       if (IssueDate > 0 && IssueAuthority > 0 && IssueCountry > 0 && ExpiryDate > 0) {
      //          this.toastr.info("This value already entered !", "Employee");
      //          $("#IssueDate" + index).focus();
      //          return false;
      //       }
      //    }

      //    tempArray.push(tempData);



      // }


      return true;
   }

   //    toggleCommentsTextarea(selectedItems: any[]): void {
   //       if (Array.isArray(selectedItems)) {
   //          const selectedItemtValues = selectedItems.map(selectedValue => {
   //             const option = this.rtwVisaStaList.find(item => item.id === Number(selectedValue));
   //             return option ? option.text : null;
   //          });

   //          this.selectedText = selectedItemtValues;

   //          // this.isrequiredclass = true;


   //       //    if (this.selectedText === "British" || this.selectedText === "Irish") {
   //       //       this.isrequiredclass = false;
   //       //    }
   //       //    else if (selectedItems.length > 1) {
   //       //       let i;
   //       //       for (i = 0; i < selectedItems.length; i++) {
   //       //          if (this.selectedText[i] === "British" || this.selectedText[i] === "Irish" )
   //       //           {
   //       //             this.isrequiredclass = false;

   //       //          }
   //       //          else{
   //       //             this.isrequiredclass = true;
   //       //             break;
   //       //          }

   //       //       }

   //       //    }

   //       //    else {
   //       //       for (const textValue of selectedTextValues) {
   //       //          if (textValue === "British" || textValue === "Irish") {
   //       //             this.isrequiredclass = false;
   //       //             break;
   //       //          }
   //       //       }
   //       //    }
   //       // }
   //       // else {
   //       //    this.isrequiredclass = true;
   //       // }
   //    }
   // }

   // toggleCommentsTextarea(event:Event) {

   //          this.selectedValueData = (event.target as HTMLSelectElement).value;
   //          const option = this.rtwVisaStaList.find(item => item.id === Number(this.selectedValueData));

   //          const options= option ? option.name : null;

   //          this.selecedValueItem = options;


   //                    if (options  === 'Other' || options === '') {
   //                      this.isDivHidden = false;

   //                    } 
   //                    else
   //                     {
   //                         this.isDivHidden = true;
   //                    }

   //                    if( options === 'Other' )
   //                    {
   //                      this.isrequiredclass = true;
   //                    }

   //                    if(this.selecedValueItem === 'Other'){
   //                      this.isrequiredComclass = true ;

   //                    }
   //                    // else{
   //                    //   this.isrequiredComclass = true ;
   //                    // }
   //                    if(options === 'N/A' )
   //                    {
   //                      this.isnotrequiredComclass = false;
   //                      this.isrequiredComclass = false;
   //                    }

   //                    else{
   //                      this.isnotrequiredComclass = true ;

   //                    }
   //                }

   toggleCommentsTextarea(event: Event) {

      this.selectedValueData = (event.target as HTMLSelectElement).value;
      const option = this.rtwVisaStaList.find(item => item.id === Number(this.selectedValueData));

      const options = option ? option.name : null;

      this.selectedStatustype = options;


      if (options === 'Other' || options === '') {
         this.isDivHidden = true;

      }
      else {
         this.isDivHidden = false;
      }

      //  if(options != 'N/A')
      //  {
      //    this.isrequiredclass = true;
      //  }
      //  else{
      //    this.isrequiredclass = false;
      //  }

      if (this.selectedStatustype === 'Other') {
         this.isrequiredComclass = true;
      }
      else {
         this.isrequiredComclass = false;
      }
      if (options === 'N/A') {
         this.isnotrequiredComclass = false;
         this.isrequiredComclass = false;
         //this.isrequiredclass = true;
      }

      else {
         this.isnotrequiredComclass = true;
      }
   }

   ECSStatusData(event: Event) {
      this.selectedOtionItem = (event.target as HTMLSelectElement).value;

      if (this.selectedOtionItem === 'Requested' || this.selectedOtionItem === 'N/A') {
         this.isrequiredforECS = false;
      }
      else {
         this.isrequiredforECS = true;
      }

   }


   // toggleExpiryField(event,index: number) {
   //    var checkboxclick = event.target;
   //    if(checkboxclick.true){
   //       this.isrequiredclass= true;
   //       this.empRighttoworkRequired[index]=true;
   //       var checkboxId = "empright" + index;
   //       var checkbox = document.getElementById(checkboxId) as HTMLInputElement;
   //       checkbox.checked = false;

   //    }
   //    else{
   //       this.isrequiredclass= false;
   //       var checkboxId = "empright" + index;
   //       var checkbox = document.getElementById(checkboxId) as HTMLInputElement;
   //       checkbox.checked = true;

   //    }
   // }
   chekboxclickyes(event) {
      var checkboxclick = event.target;
      if (checkboxclick.checked) {
         this.isrequiredclassspo = true;

      }
      else {
         this.isrequiredclassspo = false;

      }

      // var checkboxclick = event.target;
      // if(checkboxclick.checked){
      //    this.isrequiredclassspo= true;
      //    this.empright=true;
      //    var checkboxId = "empRighttoworkRequired" ;
      //    var checkbox = document.getElementById(checkboxId) as HTMLInputElement;
      //    checkbox.checked = false;

      // }
      // else{
      //    this.isrequiredclassspo= false;
      //    var checkboxId = "empRighttoworkRequired" ;
      //    var checkbox = document.getElementById(checkboxId) as HTMLInputElement;
      //    checkbox.checked = true;

      // }


   }
   // this.selectedValue = this.empform.get('isSponsoredEmployee').value;
   // //const otherselect = this.empform.get('emp_righttowork').value;
   // //const otherselects = this.empform.get('isSponsoredEmployee').value;
   // if(this.selectedValue === true)
   // {
   //   this.sponserempreq = true;
   // }

   // else{
   //   this.sponserempreq = false;
   // }
   //}


}


