<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>User View Summary</h1>
            </div>
        </div>

        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav">
                <li *ngIf="employeeBasicInfo != null">
                    {{employeeBasicInfo.firstName}} {{employeeBasicInfo.lastName}}
                </li>
            </ul>
        </div>


        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <div id="accordion1" class="panel-group">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1" href="#accordion1_3"> 1. Job Details (Office Use
                                        Only)
                                    </a>
                                </h4>
                            </div>
                            <div id="accordion1_3" class="panel-collapse collapse">
                                <div class="panel-body">

                                    <!-- <div class="row" *ngIf="employeeSummary != null">
                                        <div class="form-group">

                                            <label class="control-label col-md-1">Company</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    autocomplete="off" value="{{this.employeeSummary.company_Name}}"
                                                    readonly />
                                            </div>

                                            <label class="control-label col-md-1">Site</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    autocomplete="off" value="{{this.employeeSummary.site_Name}}"
                                                    readonly />
                                            </div>

                                            <label class="control-label col-md-1">Department</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    autocomplete="off" value="{{this.employeeSummary.dept_Name}}"
                                                    readonly />
                                            </div>

                                            <label class="control-label col-md-1">Zone</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    autocomplete="off" value="{{this.employeeSummary.zone_Name}}"
                                                    readonly />
                                            </div>

                                            <label class="control-label col-md-1">Job Title</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    autocomplete="off" value="{{this.employeeSummary.desig_Name}}"
                                                    readonly />
                                            </div>

                                            <label class="control-label col-md-1">Job Description</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    autocomplete="off" value="{{this.employeeSummary.jD_Name}}"
                                                    readonly />
                                            </div>

                                            <label class="control-label col-md-1">Employee Number</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    autocomplete="off" value="{{this.employeeSummary.emp_Code}}"
                                                    readonly />
                                            </div>

                                            <label class="control-label col-md-1">Joining Date</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    autocomplete="off"
                                                    value="{{this.employeeSummary.joiningDate | date:'dd/MM/yyyy'}}"
                                                    readonly />
                                            </div>

                                            <label class="control-label col-md-1">Tupe</label>
                                            <div class="col-md-3 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    autocomplete="off" value="{{this.employeeSummary.tupe}}"
                                                    readonly />
                                            </div>

                                            <label class="control-label col-md-1"
                                                *ngIf="employeeSummary.niNo !=null">NI Number</label>
                                            <div class="col-md-3 form-group" *ngIf="employeeSummary.niNo !=null">
                                                <input type="text" class="form-control readonlyClass"
                                                    autocomplete="off" value="{{this.employeeSummary.niNo}}"
                                                    readonly />
                                            </div>
                                        </div>
                                    </div> -->


                                    <div class="row" *ngIf="employeeSummary != null">
                                        <div class="form-group">
                                            <label class="control-label col-md-2">Company</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeSummary.company_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Site</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.site_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Department</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.dept_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Zone</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.zone_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Job Title</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.desig_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Job Description</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.jD_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Employee Number</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.emp_Code}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Joining Date</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.joiningDate | date:'dd/MM/yyyy'}}" readonly />
                                            </div>


                                            <label class="control-label col-md-2">Shift Time</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.shift_Name}} {{this.employeeSummary.shift_Time}}" readonly />
                                            </div>


                                            <label class="control-label col-md-2">NI Number</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.niNo}}" readonly />
                                            </div>


                                            <label class="control-label col-md-2">Work Email</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.work_Email}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Work Phone</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.work_Phone}}" readonly />
                                            </div>


                                            <label class="control-label col-md-2">Reporting</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeSummary.reporting_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2"> Additional Reporting To</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeSummary.additional_Reporting_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Work Extension</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeSummary.work_Extension}}" readonly />
                                            </div>


                                            <label class="control-label col-md-2" >RAF</label>
                                            <div class="col-md-4 form-group" >
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeSummary.vacancy_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2" *ngIf="employeeSummary != null">Deputy</label>
                                            <div class="col-md-4 form-group" *ngIf="employeeSummary != null">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeSummary.deputy_Name}}" readonly />
                                            </div>


                                            <label class="control-label col-md-2">RAF Approval</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" *ngIf="employeeSummary.raF_Approval != 1" value="No" readonly />

                                                <input type="text" class="form-control readonlyClass" *ngIf="employeeSummary.raF_Approval == 1" value="Yes" readonly />
                                            </div>


                                            <label class="control-label col-md-2">Tupe</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" *ngIf="employeeSummary.tupe != 1" value="No" readonly />
                                                <input type="text" class="form-control readonlyClass" *ngIf="employeeSummary.tupe == 1" value="Yes" readonly />
                                            </div>


                                            <label class="control-label col-md-2">HOD</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" *ngIf="employeeSummary.isHOD != 1" value="No" readonly />

                                                <input type="text" class="form-control readonlyClass" *ngIf="employeeSummary.isHOD == 1" value="Yes" readonly />
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1" href="#accordion1_9"> 2. Contract (Office Use
                                        Only)
                                    </a>
                                </h4>
                            </div>
                            <div id="accordion1_9" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center"> Contract Name</th>
                                                    <th class="text-center"> Length of Contract</th>
                                                    <th class="text-center"> Contract Start</th>
                                                    <th class="text-center"> Contract End</th>

                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of employeeContract">
                                                <tr>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip" data-placement="top" title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip" data-placement="bottom" title="{{item.emp_Contract_Name}}">
                                                            {{item.emp_Contract_Name}}
                                                        </div>
                                                    </td>
                                                    <td>{{item.emp_Contract_Days}}</td>
                                                    <td class="text-center">
                                                        {{item.emp_Contract_Start | date :'dd/MM/yyyy'}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{item.emp_Contract_End | date :'dd/MM/yyyy'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1" href="#accordion1_8"> 3. Probation (Office Use
                                        Only)
                                    </a>
                                </h4>
                            </div>
                            <div id="accordion1_8" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>

                                                    <th class="text-center">Probation Period</th>
                                                    <th class="text-center">Probation End Date</th>
                                                    <th class="text-center" class="text-center">Next Review Date
                                                    </th>
                                                    <th class="text-center">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of employeeProbation">
                                                <tr>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip" data-placement="top" title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip" data-placement="bottom" title="{{item.probationPeriod}}">
                                                            {{item.probationPeriod}}
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        {{item.probationEndDate |date:'dd/MM/yyyy'}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{item.nextReviewDate | date :'dd/MM/yyyy'}}
                                                    </td>
                                                    <td>{{item.status}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1" href="#accordion1_11"> 4. Salary (Office Use Only)
                                    </a>
                                </h4>
                            </div>
                            <div id="accordion1_11" class="panel-collapse collapse ">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Salary</th>
                                                    <th class="text-center">Salary Type</th>
                                                    <!-- <th class="text-center">Apprisal From</th>
                                        <th class="text-center">Apprisal To</th> -->
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of employeeSalary">
                                                <tr>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip" data-placement="top" title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip" data-placement="bottom" title="{{item.salary}}">{{item.salary}}
                                                        </div>
                                                    </td>
                                                    <!-- <td class="text-center">{{item.apprisalFrom | date :'dd/MM/yyyy'}}</td>
                                        <td class="text-center">{{item.apprisalTo | date :'dd/MM/yyyy'}}</td> -->
                                                    <td class="text-center">{{item.salaryType}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1" href="#accordion1_1"> 5. Personal Details </a>

                                </h4>
                            </div>
                            <div id="accordion1_1" class="panel-collapse collapse ">
                                <div class="panel-body">

                                    <div class="row" *ngIf="employeeBasicInfo != null">
                                        <div class="form-group">

                                            <div class="col-md-12 form-group">
                                                <button type="button" data-toggle="modal" href="#personal-info" class="btn btn-sm dark btn-outline" (click)="editRecord('EmpPersonalInfo',employeeBasicInfo.id)" style="float:right"><i class="fa fa-edit fa-1x"
                                                        data-toggle="tooltip" title="Edit Personal Detail"></i></button>
                                            </div>

                                            <label class="control-label col-md-2">Title</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.title}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Gender</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.gender_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">First Name</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.firstName}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Middle Name</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.middleName}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Last Name</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.lastName}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Date Of Birth</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.dob | date:'dd/MM/yyyy'}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Ethnicity</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.ethnicity_Name}}" readonly />
                                            </div>

                                            <!-- <label class="control-label col-md-2">Language</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.languages_Name}}" readonly />
                                            </div> -->
 <!-- <ng-select2 [data]="this.employeeBasicInfo.languages_Id"
                                [options]="{multiple: true,tags: false}" [value]='this.employeeBasicInfo.languages_Id' [placeholder]=""
                                [disabled]="true">
                            </ng-select2> -->
                                            <!-- <label class="control-label col-md-2">Nationality</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.nationality_Name}}" readonly />
                                            </div> -->

                                            <label class="control-label col-md-2">Marital Status</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.marital_Status}}" readonly />
                                            </div>
                                            <label class="control-label col-md-2">Disability</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeBasicInfo.disability}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Sexual Orientation</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeBasicInfo.sexual_Orientation}}" readonly />
                                            </div>
                                            <label class="control-label col-md-2">Mobile Number</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.phoneNumber}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2"> Telephone</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.telephone}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2"> Email</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.employeeBasicInfo.email}}" readonly />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                            <div class="col-md-3">
                                <strong>Marital Status &nbsp; : &nbsp; </strong>
                                <label class="control-label">{{employeeBasicInfo.marital_Status}}</label>
                            </div>
                        </div> -->
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1" href="#accordion1_2"> 6. Address Details </a>
                                    <span style="float:right ;margin-top: -6px">
                                        <button type="button" data-toggle="modal" href="#address-info"
                                            class="btn btn-sm dark btn-outline" (click)="editRecord('EmpAddress',0)"
                                            style="float:right"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Address Info"></i></button>
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_2" class="panel-collapse collapse ">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <!-- <th class="text-center">Address Type</th> -->
                                                    <th class="text-center">Address</th>
                                                    <th style="width: 10%;" class="text-center">City</th>
                                                    <th style="width: 10%;" class="text-center">County</th>
                                                    <th class="text-center" style="width: 10%;">Post Code
                                                    </th>
                                                    <th style="width: 10%;" class="text-center">Default</th>
                                                    <th class="text-center" style="width: 2%;">Active</th>
                                                    <th class="text-center" style="width: 2%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of employeeAddress">
                                                <tr>
                                                    <td>{{item.address1}}, {{item.address2}}</td>
                                                    <td>{{item.city}}</td>
                                                    <td>{{item.state}}</td>
                                                    <td>{{item.postCode}}</td>
                                                    <td>
                                                        <div>
                                                            <div class="circle greenColor" *ngIf="item.isDefault" data-toggle="tooltip" data-placement="top" title="Default">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isDefault" data-toggle="tooltip" data-placement="top" title="Indefault">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="text-center">
                                                            <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip" data-placement="top" title="Active">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip" data-placement="top" title="Inactive">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#address-info" *ngIf="item.isActive && item.isReview == 1" (click)="editRecord('EmpAddress',item.id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Address"></i></a>&nbsp;
                                                        <a *ngIf="!item.isActive && item.isReview == 1" (click)="Delete(6,item.id)">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Delete Address info"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1" href="#accordion1_4"> 7. Personal Contact Details
                                    </a>
                                    <span style="float:right ;margin-top: -6px">
                                        <button type="button" data-toggle="modal" href="#emp-contact"
                                            class="btn btn-sm dark btn-outline" (click)="editRecord('EmpContact',0)"
                                            style="float:right"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Contact"></i></button>
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_4" class="panel-collapse collapse ">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Contact Type</th>
                                                    <th class="text-center">Contact Details</th>
                                                    <th class="text-center" style="width: 2%;">Default</th>
                                                    <th class="text-center" style="width: 2%;">Active</th>
                                                    <th class="text-center" style="width: 2%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of employeeContact">
                                                <tr>
                                                    <td>{{item.contact_Type}}</td>
                                                    <td>{{item.contact_Value}}</td>
                                                    <td>
                                                        <div>
                                                            <div class="circle greenColor" *ngIf="item.isDefault" data-toggle="tooltip" data-placement="top" title="Default">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isDefault" data-toggle="tooltip" data-placement="top" title="Indefault">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="text-center">
                                                            <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip" data-placement="top" title="Active">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip" data-placement="top" title="Inactive">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a data-toggle="modal" href="#emp-contact" *ngIf="item.isActive && item.isReview == 1" (click)="editRecord('EmpContact',item.id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Contact"></i></a>&nbsp;
                                                        <a *ngIf="!item.isActive && item.isReview == 1" (click)="Delete(7,item.id)">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Delete Contact"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1" href="#accordion1_5"> 8. Emergency Contact </a>
                                    <span style="float:right ;margin-top: -6px">
                                        <button type="button" data-toggle="modal" href="#emp-eme-contact"
                                            class="btn btn-sm dark btn-outline" (click)="editRecord('EmpEmeContact',0)"
                                            style="float:right"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Emergency Contact"></i></button>
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_5" class="panel-collapse collapse ">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Emergency Contact Name</th>
                                                    <th class="text-center">Emergency Contact Number</th>
                                                    <!-- <th class="text-center">Relationship</th> -->
                                                    <th class="text-center" style="width: 2%;">Default</th>
                                                    <th class="text-center" style="width: 2%;">Active</th>
                                                    <th class="text-center" style="width: 2%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of employeeEmergency">
                                                <tr>
                                                    <td>{{item.contactName}}</td>
                                                    <td>{{item.contactNo}}</td>
                                                    <!-- <td>{{item.relationship_Name}}</td> -->
                                                    <td>
                                                        <div class="text-center">
                                                            <div class="circle greenColor" *ngIf="item.isDefault" data-toggle="tooltip" data-placement="top" title="Default">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isDefault" data-toggle="tooltip" data-placement="top" title="Indefault">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="text-center">
                                                            <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip" data-placement="top" title="Active">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip" data-placement="top" title="Inactive">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-eme-contact" *ngIf="item.isActive && item.isReview == 1" (click)="editRecord('EmpEmeContact',item.id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Emergency Contact"></i></a>&nbsp;
                                                        <a *ngIf="!item.isActive && item.isReview == 1" (click)="Delete(8,item.id)">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Delete Emergency Contact"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1" href="#accordion1_6"> 9. Work Reference </a>
                                    <span style="float:right ;margin-top: -6px">
                                        <button type="button" data-toggle="modal" href="#emp-ref"
                                            class="btn btn-sm dark btn-outline" (click)="editRecord('EmpReference',0)"
                                            style="float:right"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Reference"></i></button>
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_6" class="panel-collapse collapse ">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Reference Name</th>
                                                    <th class="text-center">Reference Email Address</th>
                                                    <th class="text-center">Reference Contact Number</th>
                                                    <!-- <th class="text-center">Reference Relationship</th> -->
                                                    <th class="text-center" style="width: 2%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of employeeReference">
                                                <tr>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip" data-placement="top" title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip" data-placement="bottom" title="{{item.ref_Name}}">{{item.ref_Name}}
                                                        </div>
                                                    </td>
                                                    <td>{{item.ref_Email}}</td>
                                                    <td>{{item.ref_ContactNo}}</td>
                                                    <!-- <td>{{item.relationship_Name}}</td> -->
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-ref" *ngIf="item.isActive && item.isReview == 1" (click)="editRecord('EmpReference',item.id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="center"
                                                                title="Edit Reference"></i></a>&nbsp;
                                                        <a *ngIf="!item.isActive && item.isReview == 1" (click)="Delete(9,item.id)">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Delete Reference"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1" href="#accordion1_7"> 10. Payroll Details </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryData("\"employee_bank\"")'><i class="fa fa-history"
                                                data-toggle="tooltip" title="History"></i></button> -->
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#emp-bank" (click)="editRecord('EmpBank',0)" style="float:right"><i
                                                class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Bank Info"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_7" class="panel-collapse collapse ">
                                <div class="panel-body">
                                    <div class="row" *ngIf="employeeSummary != null">
                                        <label class="control-label col-md-1">NI Number</label>
                                        <div class="col-md-3 form-group">
                                            <input type="text" class="form-control" autocomplete="off" id="NiNo"
                                                name="NiNo" value="{{this.employeeSummary.niNo}}" />
                                        </div>

                                        <label class="control-label col-md-1">Loan</label>
                                        <div class="col-md-3 form-group">
                                            <input type="text" class="form-control" id="Loan"
                                            name="Loan" value="{{this.employeeSummary.loan}}" />
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <button type="button" style="width: 100px;" class="btn btn-outline blue"
                                                (click)="updateNiNumber(employeeSummary.emp_Id)">
                                                Save
                                            </button>
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Bank Name</th>
                                                    <th class="text-center" style="width: 20%;">Account Holder</th>
                                                    <!-- <th class="text-center" style="width: 15%;">Account Type</th> -->
                                                    <th class="text-center" style="width: 15%;">Account No.</th>
                                                    <th class="text-center" style="width: 15%;">Sort Code</th>
                                                    <th class="text-center" style="width: 2%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of employeeBank">
                                                <tr>
                                                    <td>{{item.bank_Name}}</td>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.account_Holder}}">{{item.account_Holder}}
                                                        </div>
                                                    </td>
                                                    <!-- <td>{{item.account_Type}}</td> -->
                                                    <td>{{item.account_No}}</td>
                                                    <td>{{item.bank_Code}}</td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-bank"
                                                            (click)="editRecord('EmpBank',item.emp_Bank_Id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Payroll Info"></i></a>&nbsp;
                                                        <a (click)="Delete(7,item.emp_Bank_Id)" *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Payroll Info"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <!-- <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Bank Name</th>
                                                    <th class="text-center">Account Holder</th>
                                                    <th class="text-center">Account No.</th>
                                                    <th class="text-center">Sort Code</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of employeeBank">
                                                <tr>
                                                    <td>{{item.bank_Name}}</td>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip" data-placement="top" title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip" data-placement="bottom" title="{{item.account_Holder}}">
                                                            {{item.account_Holder}}
                                                        </div>
                                                    </td>
                                                    <td>{{item.account_No}}</td>
                                                    <td>{{item.bank_Code}}</td>
                                                </tr>
                                            </tbody>
                                        </table> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1" href="#accordion1_10"> 11. Right To Work (Office
                                        Use
                                        Only) </a>
                                </h4>
                            </div>
                            <div id="accordion1_10" class="panel-collapse collapse ">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Date of Issue</th>
                                                    <th class="text-center">Authority</th>
                                                    <th class="text-center">Country of Issue</th>
                                                    <th class="text-center">Date of Expiry</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of employeeRighttowork">
                                                <tr>
                                                    <td>{{item.issueDate | date :'dd/MM/yyyy'}}</td>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip" data-placement="top" title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip" data-placement="bottom" title="{{item.issueAuthority}}">
                                                            {{item.issueAuthority}}
                                                        </div>
                                                    </td>
                                                    <td>{{item.issueCountry}}</td>
                                                    <td class="text-center">
                                                        {{item.expiryDate | date :'dd/MM/yyyy'}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1" href="#accordion1_12"> 12. Additional Document (Office Use
                                        Only) </a>
                                </h4>
                            </div>
                            <div id="accordion1_12" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Upload File Name</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of empDocuments">
                                                <tr>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip" data-placement="top" title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip" data-placement="bottom" title="{{item.file_Name}}">{{item.file_Name}}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1" href="#accordion1_13"> 13. Leave (Office Use
                                        Only) </a>
                                </h4>
                            </div>
                            <div id="accordion1_13" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Leave Type</th>
                                                    <th class="text-center">Total Leave</th>
                                                    <th class="text-center">Taken Leave</th>
                                                    <th class="text-center">Balance Leave</th>
                                                    <!-- <th class="text-center" style="width: 5%"> Actions </th> -->
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of empLeaveBalance">
                                                <tr>

                                                    <td class="text-center">
                                                        {{item.leave_Type}} ({{item.leave_Type_Code}})
                                                    </td>
                                                    <td class="text-center">
                                                        {{item.total_leave}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{item.taken_leave}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{item.balance_leave}}
                                                    </td>
                                                    <!-- <td class="text-right">
                                                        <a (click)="downloadDocument(item.emp_Doc_Id,item.emp_Doc_Path)"><i
                                                                class="fa fa-download purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Download Document"></i></a>&nbsp;
                                                        <a data-toggle="modal" href="#emp-document"
                                                            (click)="editempInfo('EmpDocument',item.emp_Doc_Id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Document"></i></a>&nbsp;
                                                        <a (click)="Delete(12,item.emp_Doc_Id)">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Document"></i></a>
                                                    </td> -->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle popoverTitle accordion-toggle-styled"
                                        data-toggle="collapse" data-parent="#accordion1" href="#accordion1_14">
                                        14. Equality and Diversity Monitoring
                                    </a>
                                    <span class="popoverTitle" data-toggle="popover" data-container="body"
                                        data-placement="right" type="button" data-html="true" id="equality">
                                        <span class="glyphicon glyphicon-info-sign"></span>
                                    </span>
                                    <span style="float:right ;margin-top: -6px" *ngIf="person_equalityInfo != null">
                                        <button class="btn btn-sm dark btn-outline pull-right" type="button"
                                            data-toggle="modal" href="#equality_info"
                                            *ngIf="person_equalityInfo != null"
                                            (click)="editRecord('CandidatePersonEquality',person_equalityInfo.id)"
                                            data-backdrop="static" data-keyboard="false"><i class="fa fa-edit"
                                                data-toggle="tooltip" data-placement="bottom"
                                                title="Edit"></i></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <span style="float:right ;margin-top: -6px" *ngIf="person_equalityInfo == null">
                                        <button class="btn btn-sm dark btn-outline pull-right" type="button"
                                            data-toggle="modal" href="#equality_info"
                                            *ngIf="person_equalityInfo == null"
                                            (click)="editRecord('CandidatePersonEquality',0)" data-backdrop="static"
                                            data-keyboard="false"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Add"></i></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <div id="popover-content-equality" class="hide">
                                        The information requested in this form is required for us to monitor
                                        our Equality and Diversity Policy and we encourage you complete
                                        this form in full to assist us with this. The information provided is to
                                        be used solely for monitoring and to comply with our legal
                                        obligations in relation to the Equality Act 2010. Any information
                                        provided in this form will be treated in the strictest confidence.
                                        The data provided will be processed and held for the specified
                                        purposes and in accordance with data protection legislation, as set
                                        out in our Data Protection Policy and the Privacy Notice issued to
                                        you.
                                    </div>
                                </h4>

                            </div>
                            <div id="accordion1_14" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="control-label col-md-2">Gender</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.gender}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2"><span *ngIf="person_equalityInfo?.gender === 'Other'">If Other, please specify</span></label>                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.gender === 'Other'"
                                                    value="{{person_equalityInfo?.gender_Other}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">Marital Status</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.maritalStatus}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2"><span *ngIf="person_equalityInfo?.maritalStatus === 'Other'">If Other, please specify</span></label>                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.maritalStatus === 'Other'"
                                                    value="{{person_equalityInfo?.marital_Other}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">Sexual Orientation</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.sexual_Orientation}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2"><span *ngIf="person_equalityInfo?.sexual_Orientation === 'Other'">If Other, please specify</span></label>                                            <div class="col-md-4 form-group">
                                                <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.sexual_Orientation === 'Other'"
                                                    value="{{person_equalityInfo?.sexual_Other}}" readonly />
                                            </div>
                    
                                            <!-- <label class="control-label col-md-2">Age</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.age}}" readonly />
                                            </div> -->
                    
                                            <label class="control-label col-md-2">
                                                Disability
                                                <span class="popoverTitle" data-toggle="popover" data-container="body"
                                                    data-placement="right" type="button" data-html="true" id="disability">
                                                    <span class="glyphicon glyphicon-info-sign"></span>
                                                </span>
                                            </label>
                                            <div id="popover-content-disability" class="hide">
                                                The Equality Act 2010 defines a disabled person
                                                as someone who has a mental or physical
                                                impairment that has a substantial and long-term
                                                adverse effect on the person’s ability to carry out
                                                normal day-to-day activities. If you have a
                                                condition which fits the Equality Act definition,
                                                please tick ‘yes’ even if you are not limited by
                                                your condition.
                                                Do you consider yourself to have a disability?
                                            </div>
                                            <div class="col-md-4 form-group pos-relative">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.disability}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">Religion or Belief</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.religionOrBelief}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">Is the gender you identify with the same as your gender
                                                registered at birth?</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.gender_Birth}}" readonly />
                                            </div>
                                            <label class="control-label col-md-2">
                                                Ethnicity
                                                <span class="popoverTitle" data-toggle="popover" data-container="body"
                                                    data-placement="right" type="button" data-html="true" id="ethnicity">
                                                    <span class="glyphicon glyphicon-info-sign"></span>
                                                </span>
                                            </label>
                                            <div id="popover-content-ethnicity" class="hide">
                                                Ethnic origin is not about nationality, place of
                                                birth or citizenship. It is about what best
                                                describes your ethnic group or background.
                                                Please choose one option and tick the
                                                appropriate box below.
                                                What is your ethnic group?
                                            </div>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.ethnicity}}" readonly />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade draggable-modal" id="personal-info" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Personal Details</h4>
            </div>
            <div class="modal-body">
                <app-emppersonalinfo></app-emppersonalinfo>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(5)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="address-info" role="basic" aria-hidden="true" style="z-index: 0;">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Address Details</h4>
            </div>
            <div class="modal-body">
                <app-empAddressInfo></app-empAddressInfo>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(6)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="emp-contact" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Personal Contact Details</h4>
            </div>
            <div class="modal-body">
                <app-employeeContact></app-employeeContact>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(7)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="emp-eme-contact" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Emergency Contact</h4>
            </div>
            <div class="modal-body">
                <app-employeeEmergency></app-employeeEmergency>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(8)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="emp-ref" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Work Reference</h4>
            </div>
            <div class="modal-body">
                <app-employeeRef></app-employeeRef>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(9)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-bank" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Payroll Details</h4>
            </div>
            <div class="modal-body">
                <app-employeeBank></app-employeeBank>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('employee_bank')">
                    <i class="fa fa-history"></i>
                </button> -->
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(10)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="equality_info" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Equality and Diversity Monitoring</h4>
            </div>
            <div class="modal-body">
                <app-candidatepersonequality></app-candidatepersonequality>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(12)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>