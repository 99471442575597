<form [formGroup]="empform">
    <div class="form-group">
        <div class="table-responsive">
            <table class="table table-hover" id="work">
                <thead>
                    <tr>
                        <th style="width: 15%;">Contract Type <span class="required">
                                <!-- *ngIf="(this.empform.get('emp_Contract').value[0].Emp_Contract_Start != '' || this.empform.get('emp_Contract').value.length > 1)"> -->
                                * </span></th>
                        <!-- <th style="width: 15%;">Continuous Service Date <span class="required">
                                    * </span></th> -->
                       <th style="width: 15%;">Role Start <span class="required">
                                    * </span></th>
                        <th style="width: 15%;">Role End </th>
                        <th style="width: 15%;">Job Title<span class="required">
                            * </span></th>  
                        <th style="width: 15%;">Zone </th>  
                   
                        <!-- <th style="width: 15%;">Contract Start <span class="required">
                            * </span></th>  
                        <th style="width: 15%;">Length of Contract</th>
                        <th style="width: 15%;">Contract End</th> -->
                        <th style="width: 2%;">Active</th>
                        <!-- <th style="width: 2%;">Action</th> -->
                        <th style="width: 5%;"><button
                                *ngIf="this.empform.get('emp_Contract').value[0].Emp_Contract_Code != ''" type="button"
                                (click)="addItem()" class="btn pull-right"><i class="fa fa-plus"
                                    aria-hidden="true"></i></button></th>
                    </tr>
                </thead>
                <tbody formArrayName="emp_Contract"
                    *ngFor="let item of empform.get('emp_Contract')['controls']; let i = index;">
                    <tr [formGroupName]="i">
                        <td>
                            <div class="input-group" (input)="onChangeDays(null)">
                                <select id="Contract_Id{{i}}" class="form-control" formControlName="Contract_Id"
                                    (change)="selectContractType($event)">
                                    <option value="">Select Contract Type</option>
                                    <option *ngFor="let item of contractlist;" value={{item.contract_Id}}>
                                        {{item.contract_Name}} </option>
                                        <!-- {{item.contract_Name}} / {{item.contract_Type}} -->
                                </select>
                                <span class="input-group-btn">
                                    <button class="btn dark btn-outline input-group-btn-input-grp" type="button"
                                        (click)="modalOpen =true">
                                        <i
                                            class="fa fa-{{this.empform.get('emp_Contract').value[0].Contract_Id != '' ? 'edit' : 'plus'}}"></i>
                                    </button>
                                </span>
                            </div>
                        </td>
                        <!-- <td>
                             <div class="input-group" id="flatpickropenEmpCStD{{i}}" data-wrap="true">
                                <input type="text" class="form-control readonlyClass" 
                                    data-input (input)="onChangeDays(null)">
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div> 
                      
                        </td> -->
                  
                        <td>
                            <div class="input-group" id="flatpickropenEmpCStD{{i}}" data-wrap="true">
                                <input type="text" class="form-control readonlyClass" id="Emp_Role_Start{{i}}" 
                                    data-input (input)="onChangeDays(null)">
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </td>
                       
                        <td>
                            <div class="input-group" id="flatpickropenEmpCStD{{i}}" data-wrap="true">
                                <input type="text" class="form-control readonlyClass" id="Emp_Role_End{{i}}" 
                                    data-input (input)="onChangeDays(null)" disabled>
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </td>
                        <td>
                            <input type="text" class="form-control" id="Emp_Job_Title{{i}}"
                            [value]="inputFromParentJT" disabled>
                        </td>
                        
                        <td>
                            <div class="input-group" >
                              
                            <select class="form-control" id="Emp_Zone_Id" formControlName="Emp_Zone_Id">
                                <option value="">Select Your Zone</option>
                                <option *ngFor="let item of zoneList;" value={{item.zone_Id}}>
                                    {{item.zone_Name}}</option>
                            </select>
                            
                            </div>
                        </td>
                    
                        
                        <!-- <td>
                            <div class="input-group" id="flatpickropenEmpCStD{{i}}" data-wrap="true">
                                <input type="text" class="form-control readonlyClass" id="Emp_Contract_Start{{i}}" value={{joiningDate}}
                                    data-input (input)="onChangeDays(null)">
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </td>
                        <td>
                            <input type="text" class="form-control" id="Emp_Contract_Days{{i}}"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                                formControlName="Emp_Contract_Days" min="0" max="1000"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                (input)="onChangeDays($event.target.value)" (change)="onChangeDays($event.target.value)">
                        </td>
                        <td>
                            <div class="input-group" id="flatpickropenEmpCStD{{i}}" data-wrap="true">
                                <input class="form-control readonlyClass" id="Emp_Contract_End{{i}}"
                                    data-input (input)="onChangeDays(null)">
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </td> -->
                        <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="empcontract{{i}}" class="md-check"
                                        formControlName="isActive">
                                    <label for="empcontract{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td>
                        <!-- <td class="text-center">
                            <a href="javascript:void(0);" data-toggle="modal" style="line-height: 34px;">
                                <i  class="fa fa-download purple iconcss iconFontsize" data-toggle="tooltip"  data-placement="top" title="Download Document"></i>
                            </a>
                        </td> -->
                        <td>
                            <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                                    class="fa fa-minus" aria-hidden="true"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</form>

<div *ngIf="modalOpen" class="row">
    <div class="col-lg-12">
        <app-contract-form [pId]="this.empform.get('emp_Contract').value[0].Contract_Id"
            (modalClose)="modalOpen = $event" (refreshPageForEmp)="getContractList($event)"></app-contract-form>
    </div>

</div>