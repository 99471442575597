<style>
    td.details-control {
        background: url('assets/pages/img/SD7Dz.png') no-repeat center center;
        cursor: pointer;
    }
    
    tr.shown td.details-control {
        background: url('assets/pages/img/d4ICC.png') no-repeat center center;
    }
</style>
<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title mrl10">
                <h1>RAF Overview
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a routerLink="/raf-add" class="btn btn-transparent blue  btn-sm">Create a RAF</a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12" *ngIf="myApprovallist.length > 0">

            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box white">
                <div class="portlet-title">
                    <div class="caption">
                        <i class="fa"></i>Waiting For My Approval
                    </div>
                    <div class="actions">

                    </div>
                </div>
                <div class="portlet-body table-responsive" id="rafTable">
                    <diV class="row">

                        <!-- <div class="col-md-6 col-sm-6">
                            <div class="dataTables_length" id="sample_4_length">
                                <label>
                                    <select name="myApprovallist_length" id="myApprovallist_length"
                                        aria-controls="sample_4" class="form-control input-sm input-xsmall input-inline"
                                        (change)="myAprrovalSearchOneValue('onLoad', 0)">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    records
                                </label>
                            </div>
                        </div> -->

                        <!-- <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" placeholder="Search">
                        </div> -->
                    </diV>
                    <br />
                    <table class="table table-striped table-bordered table-hover" id="tblZone">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center"> Job Title </th>
                                <th class="text-center"> Requester </th>
                                <th class="text-center"> Number Of Vacancies </th>
                                <th class="text-center"> Company </th>
                                <th class="text-center"> Last Updated By </th>
                                <th class="text-center"> Next Approval </th>
                                <th class="numeric text-center" style="width: 15%"> Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of myApprovallist | paginate: { itemsPerPage: itemsPerPage, currentPage: p ,totalItems :totalItems1, id: 'pagination1' }">
                                <td class="text-center">
                                    <!-- <div class="col-md-1 col-xs-12">
                                        <a (click)="updateStatus(item.id,'0')">
                                            <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                                data-placement="top" title="Active"></div>
                                        </a>
                                        <a (click)="updateStatus(item.id,'1')">
                                            <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                                data-placement="top" title="Inactive"></div>
                                        </a>
                                    </div> -->
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip" data-placement="bottom" title="{{item.desig_Name}}">
                                        {{item.desig_Name}}</div>
                                </td>
                                <td class="text-center"> {{item.requestedBy_Emp_Name}} </td>
                                <td class="text-center">{{item.noOfPositions}}</td>
                                <td class="text-left text-center"> {{item.company_Name}} </td>
                                <td class="text-center"> {{item.updatedBy_Name}} </td>
                                <td class="numeric text-center"> {{item.nextApprover_Emp_Name}} </td>
                                <td class="numeric text-center">

                                    <a (click)="myApprovalApprove(item.id,item.nextApprover_Rule_Detail_Id,item.nextApprover)"><i
                                            class="fa fa-check-square-o green iconcss iconFontsize"
                                            data-toggle="tooltip" data-placement="top"
                                            title="Approve RAF"></i></a>&nbsp;
                                    <a data-toggle="modal" (click)="getSummary(item.id,true)"><i
                                            class="fa fa-list-alt purple-color iconcss iconFontsize"
                                            data-toggle="tooltip" data-placement="top" title="View RAF"></i></a>&nbsp;
                                    <!-- <a [routerLink]="['/raf-add']" [queryParams]="{ editId: item.id}"><i
                                            class="fa fa-pencil iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Edit"></i>&nbsp; </a> -->
                                    <a (click)="myApprovalReject(item.id)"><i
                                            class="fa fa-close red-color iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Reject RAF"></i>&nbsp; </a>
                                    <a (click)="approvalHide(item.id,'Hold')"><i
                                            class="fa fa-eye-slash iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Hold"></i>&nbsp; </a>
                                    <a *ngIf="item.isCanclePermission == 1" (click)="myApprovalCancel(item.id)"><i
                                                class="fa fa-ban red-color iconcss iconFontsize" aria-hidden="true" data-toggle="tooltip"
                                                data-placement="top" title="Cancel RAF"></i>&nbsp; </a>

                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="text-center" *ngIf="myApprovallist!= null">
                    <pagination-controls (pageChange)="myApprovalServerPagination($event)" autoHide="true" id="pagination1"></pagination-controls>
                </div>
            </div>

        </div>


        <div class="col-md-12">

            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box white">
                <div class="portlet-title">
                    <div class="caption">
                        <i class="fa"></i>Approved RAF's
                    </div>
                    <div class="actions">

                    </div>
                    <!--  <div class="tools mr10">
                           
                            <a href="javascript:;" class="reload"> </a>
                            
                        </div> -->
                </div>
                <div class="portlet-body" id="rafTable2">
                    <diV class="row">

                        <div class="col-md-7 col-sm-6">
                            <div class="dataTables_length" id="sample_4_length">
                                <!-- <label>
                                    <select name="approvaledlist_length" id="approvaledlist_length"
                                        aria-controls="sample_4" class="form-control input-sm input-xsmall input-inline"
                                        (change)="aprrovaledSearchOneValue('onLoad', 0)">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    records
                                </label> -->
                            </div>
                        </div>

                        <div class="col-md-1 col-sm-1">
                            <!-- <label style="margin-left: 40%;">Search: </label> -->
                        </div>
                        <div class="col-md-4 col-sm-2">
                            <div class="dataTables_length">
                                <input type="text" class="form-control" placeholder="Type to filter..." id="filterList_approved" (input)="aprrovaledSearchOneValue('onLoad',null)">
                            </div>
                        </div>

                        <!-- <div class="col-md-9">
                            </div>
                            <div class="col-md-3 text-right">
                                <input type="text" class="form-control" placeholder="Search">
                            </div> -->
                    </diV>
                    <br>
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="sample_3">
                            <thead class="flip-content">
                                <tr>
                                    <th class="text-center"> Job Title </th>
                                    <th class="text-center"> Requester </th>
                                    <th class="text-center"> Number Of Vacancies </th>
                                    <th class="text-center"> Open Position </th>
                                    <th class="text-center"> Company </th>
                                    <th class="text-center"> Last Updated By </th>
                                    <!-- <th class="text-center"> Status </th> -->
                                    <th class="numeric text-center" style="width: 10%"> Action </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of approvaledlist | paginate: { itemsPerPage: itemsPerPage, currentPage: p3 ,totalItems :totalItems3, id: 'pagination3' }">
                                    <!-- <td class="text-center">
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(item.id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                            data-placement="bottom" title="{{item.desig_Name}}">
                                            {{item.desig_Name}}</div>
                                    </td> -->
                                    <td class="text-center">{{item.desig_Name}}</td>
                                    <td class="text-center"> {{item.requestedBy_Emp_Name}} </td>
                                    <td class="text-center">{{item.noOfPositions}}</td>
                                    <td class="text-center">{{item.open}}</td>
                                    <td class="text-left text-center"> {{item.company_Name}} </td>
                                    <td class="text-center"> {{item.approvedBy_Emp_Name}} </td>
                                    <!-- <td class="numeric text-center"> {{item.status}} </td> -->
                                    <td class="numeric text-center">
                                        <a data-toggle="modal" (click)="getPostWebsite(item.id)"><i
                                                class="fa fa-send green iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Post On Website"></i></a> &nbsp;
                                        <!-- <a data-toggle="modal" (click)="selectCandidate(item.id)"><i class="fa fa-hand-o-up green iconcss iconFontsize"  data-toggle="tooltip" data-placement="top" title="Select Candidate"></i></a>&nbsp; -->
                                        <a data-toggle="modal" (click)="getSummary(item.id)"><i
                                                class="fa fa-list-alt purple-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="View RAF"></i></a>&nbsp;

                                        <a (click)="approvedCompleted(item.id,'Filled')"><i
                                                class="fa fa-check iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Complete RAF"></i></a>&nbsp;

                                        <!-- <a (click)="rafOverviewDelete(item.id)"><i
                                                class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Delete RAF"></i></a>&nbsp; -->

                                        <!-- <a data-toggle="modal"
                                        (click)="selectCandidate(item.id,item.company_Id,item.site_Id,item.dept_Id)"><i
                                            class="fa fa-hand-o-up green iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Select Candidate"></i></a> -->
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="text-center" *ngIf="approvaledlist!= null">
                    <pagination-controls (pageChange)="approvaledServerPagination($event)" autoHide="true" id="pagination3"></pagination-controls>
                </div>
            </div>

        </div>


        <div class="col-md-12">

            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box white">
                <div class="portlet-title">
                    <div class="caption">
                        <i class="fa"></i>Approval Status
                    </div>
                    <div class="actions">

                    </div>
                    <!--  <div class="tools mr10">
                       
                        <a href="javascript:;" class="reload"> </a>
                        
                    </div> -->
                </div>
                <div class="portlet-body" id="rafTable">
                    <diV class="row">

                        <!-- <div class="col-md-1">
                            <label>Status&nbsp;: </label>
                        </div> -->

                        <div class="col-md-3">
                            <select name="approvallist_status" id="approvallist_status" aria-controls="sample_4" class="form-control" (change)="aprrovalSearchOneValue('onLoad', 0)">
                                <!-- <option value="Pipeline">Pipeline</option> -->
                                <option value="Awaiting Approval">Awaiting Approval</option>
                                <option value="Planned">Planned</option>
                                <option value="Rejected">Rejected</option>
                                <!-- <option value="Live Vacancy">Live Vacancy</option>Approved -->
                                <option value="Approved">Approved</option>
                                <option value="Hold">Hold</option>
                                <!-- <option value="Deleted">Deleted</option> -->
                                <!-- <option value="Closed">Closed</option>Filled -->
                                <option value="Filled">Filled</option>
                                <option value="Cancelled">Cancelled</option>                                
                                <option value="All">All</option>
                            </select>
                        </div>

                        <div class="col-md-5">

                        </div>
                        <div class="col-md-4">
                            <div class="dataTables_length">
                                <input type="text" class="form-control" placeholder="Type to filter..." id="filterList" (input)="aprrovalSearchOneValue('onLoad',null)">
                            </div>
                        </div>

                    </diV>
                    <br>
                    <div class="table-responsive">
                        <table class="display table table-striped table-bordered table-hover" id="sample_5">
                            <thead class="flip-content">
                                <tr>
                                    <th style="width: 2%"></th>
                                    <th class="text-center"> Job Title </th>
                                    <th class="text-center"> Requester </th>
                                    <th class="text-center"> Number Of Vacancies </th>
                                    <th class="text-center"> Company </th>
                                    <!-- <th class="text-center"> Last Updated By </th> -->
                                    <th class="text-center"> Next Approval </th>
                                    <th class="text-center"> Status </th>
                                    <th class="numeric text-center" style="width: 15%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of approvallist | paginate: { itemsPerPage: itemsPerPage, currentPage: p2 ,totalItems :totalItems2, id: 'pagination2' }">
                                <tr [style.background-color]="item.statusBackGroundColor" [style.color]="item.statusFrontColor">
                                    <td class="details-control details-control-icon-{{item.id}}" (click)="expandTable(item.id)"></td>
                                    <td class="text-center">
                                        {{item.desig_Name}}
                                        <!-- <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(item.id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                            data-placement="bottom" title="{{item.desig_Name}}">
                                            {{item.desig_Name}}</div> -->

                                    </td>
                                    <td class="text-center"> {{item.requestedBy_Emp_Name}} </td>
                                    <td class="text-center">{{item.noOfPositions}}</td>
                                    <td class="text-left text-center"> {{item.company_Name}} </td>
                                    <!-- <td class="text-center"> {{item.updatedBy_Name}} </td> -->
                                    <td class="numeric text-center"> {{item.nextApprover_Emp_Name}} </td>
                                    <td class="numeric text-center"> {{item.status}} </td>
                                    <td class="numeric text-center">
                                        <a *ngIf="item.status == 'Awaiting Approval'" (click)="approvalSendReminder(item.id)"><i
                                                class="fa fa-bell green iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Send Reminder"></i>&nbsp; </a>
                                        <a *ngIf="item.status == 'Awaiting Approval' || item.status == 'Approved'" (click)="approvalHide(item.id,'Hold')"><i
                                                class="fa fa-eye-slash iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Hold"></i>&nbsp; </a>
                                        <!-- <a *ngIf="item.status == 'Pipeline'" (click)="myApprovalApprove(item.id,item.nextApprover_Rule_Detail_Id)"><i class="fa fa-check-square-o green iconcss iconFontsize"  data-toggle="tooltip" data-placement="top" title="Approve RAF"></i></a>&nbsp;                               -->
                                        <!-- <a *ngIf="item.status == 'Pipeline'" (click)="approvalReject(item.id)"><i class="fa fa-close red-color iconcss iconFontsize"  data-toggle="tooltip" data-placement="top" title="Reject RAF"></i></a>&nbsp; -->
                                        <a data-toggle="modal" *ngIf="item.status != 'Cancelled'" (click)="getSummary(item.id)"><i
                                                class="fa fa-list-alt purple-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top" title="View RAF"></i>&nbsp;                                        
                                        </a>

                                        <a data-toggle="modal" *ngIf="(item.status == 'Cancelled' && item.isCanclePermission == 1)" (click)="getSummary(item.id)"><i
                                            class="fa fa-list-alt purple-color iconcss iconFontsize"
                                            data-toggle="tooltip" data-placement="top" title="View RAF"></i>&nbsp;
                                    </a>

                                        <a *ngIf="item.status == 'Awaiting Approval' || (item.status == 'Approved' && item.totalVacancyLinkCount == 0) || item.status == 'Planned' || item.status == 'Rejected'" [routerLink]="['/raf-add']" [queryParams]="{ editId: item.id}"><i
                                                class="fa fa-pencil iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit"></i>&nbsp; </a>
                                                
                                        <a *ngIf="item.status == 'Planned'" (click)="rafApprovedDelete(item.id)"><i
                                                class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top" title="Delete RAF"></i></a>
                                        <a *ngIf="item.status == 'Hold'" (click)="approvalHide(item.id,item.previousStatus)"><i
                                                class="fa fa-eye iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Unhold"></i>&nbsp; </a>

                                        <a *ngIf="item.status == 'Rejected'" (click)="reAssign(item.id)"><i
                                                class="fa fas fa-reply iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Re Assign"></i>&nbsp; </a>


                                        <a *ngIf="(item.status == 'Rejected' || item.status == 'Approved' || item.status == 'Filled') && item.status != 'Cancelled'" [routerLink]="['/raf-add']" [queryParams]="{ editId: item.id,recreate:true}"><i
                                                class="fa fa-clone iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Duplicate RAF"></i>&nbsp; </a>


                                        <a *ngIf="(item.status == 'Cancelled' && item.isCanclePermission == 1)" [routerLink]="['/raf-add']" [queryParams]="{ editId: item.id,recreate:true}"><i
                                                class="fa fa-clone iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Duplicate RAF"></i>&nbsp; </a>


                                        <a *ngIf="(item.status == 'Awaiting Approval' || item.status == 'Hold') && item.isCanclePermission == 1" (click)="myApprovalCancel(item.id)"><i
                                                    class="fa fa-ban red-color iconcss iconFontsize" aria-hidden="true" data-toggle="tooltip"
                                                    data-placement="top" title="Cancel RAF"></i>&nbsp; </a>


                                    </td>
                                </tr>
                                <tr class="sub_tbl sub_tbl_{{item.id}}" style="display:none;">
                                    <td colspan="8">

                                        <table cellpadding="4" cellspacing="0" style="width:50%;" class="table table-striped table-bordered dataTable no-footer">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">ActionBy</th>
                                                    <th class="text-center">Action</th>
                                                    <th class="text-center">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let items of historyvacancy">
                                                    <td>{{items.emp_Name}}</td>
                                                    <td>{{items.action}}</td>
                                                    <td>{{items.addedOn | date: 'dd/MM/yyyy'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <!-- <div class="form-wizard">
                                            <ul class="nav nav-pills nav-justified steps">
                                                <li class="done">
                                                    <a class="step">
                                                        <span class="number"> 1 </span><br />
                                                        <span class="desc">
                                                            Person 1 </span>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div id="bar" class="progress progress-striped" role="progressbar">
                                                <div class="progress-bar progress-bar-success" id="progress-bar"
                                                    style="width: 20%;"> </div>
                                            </div>
                                        </div> -->
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="text-center" *ngIf="approvallist!= null">
                    <pagination-controls (pageChange)="approvalServerPagination($event)" autoHide="true" id="pagination2"></pagination-controls>
                </div>
            </div>

        </div>


       





        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->


<div class="modal fade draggable-modal" id="postOnWebsite" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog" style="width: 500px;">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Post On Website</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group">
                        <form [formGroup]="publisherForm">
                            <div class="col-md-12" formArrayName="checkArray" *ngFor="let item of publisherForm.get('checkArray')['controls']; let i = index;">
                                <div class="md-checkbox has-success  col-md-1" [formGroupName]="i">
                                    <input type="checkbox" id="checkbox{{i}}" class="md-check postarray" formControlName="checkboxchecked" style="margin-left: -38%;">
                                    <label for="checkbox{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> &nbsp;</label>
                                </div>
                                <label class="control-label col-md-11" style="margin-left: -5%;">{{
                                    publisherForm.get('checkArray').value[i]['publishSource']}}</label>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" class="btn green" (click)="upsert()">Post</button>
            </div>
        </div>
    </div>
    <!-- /.modal-dialog -->
</div>

<div class="modal fade" id="viewSummary" role="dialog" aria-labelledby="myModalLabel" style="z-index: 0;">
    <div class="modal-dialog modal-lg" *ngIf="openPopup" style="margin:4% auto !important">
        <div class="modal-content" style="position: absolute!important;">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">View Summary</h4>
            </div>
            <div class="modal-body" *ngIf="approvalSummary != null">

                <div class="row1">
                    <div class="form-group row">
                        <label class="control-label col-md-2">Company</label>
                        <div class="col-md-4">
                            <select class="form-control readonlyClass select" readonly autocomplete="off" disabled>
                                <option value="">{{this.approvalSummary.company_Name}}</option>
                            </select>
                        </div>

                        <label class="control-label col-md-2">Department</label>
                        <div class="col-md-4">
                            <select class="form-control readonlyClass select" readonly autocomplete="off" disabled>
                                <option value="">{{this.approvalSummary.dept_Name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label col-md-2">Site</label>
                        <div class="col-md-4">
                            <select class="form-control readonlyClass select" readonly autocomplete="off" disabled>
                                <option value="">{{this.approvalSummary.site_Name}}</option>
                            </select>
                        </div>

                        <label class="control-label col-md-2">Position Type</label>
                        <div class="col-md-4">
                            <select class="form-control readonlyClass select" readonly autocomplete="off" disabled>
                                <option value="">{{this.approvalSummary.type}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">

                        <label class="control-label col-md-2">Requester</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.approvalSummary.requestedBy_Emp_Name}}" readonly />
                        </div>


                        <label class="control-label col-md-2">Budget</label>

                        <div class="col-md-4 classBudget" *ngIf="fieldVisibleInvisible[0].Budget == 1">
                            <div class="md-radio-inline">
                                <div class="md-radio has-info" style="margin-top: 5px;">
                                    <input type="radio" id="radio53" name="budgeted" [checked]="this.approvalSummary.budgeted == '1'" value="1" class="md-radiobtn" disabled>
                                    <label for="radio53">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span>Yes</label>
                                </div>
                                <div class="md-radio has-info" style="margin-top: 5px;">
                                    <input type="radio" id="radio59" name="budgeted" [checked]="this.approvalSummary.budgeted == '0'" value="0" class="md-radiobtn" disabled>
                                    <label for="radio59">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> No </label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="form-group mt-element-step row">
                        <div class="row step-line text-center">
                            <div class="col-md-3 mt-step-col done" *ngFor="let item of this.approvalSummary.vacancy_rule_detail; let i = index">
                                <div class="mt-step-number bg-white">{{i+1}}</div>
                                <div class="mt-step-title uppercase font-grey-cascade">{{item.emp_Name}}</div>
                                <div class="mt-step-title font-grey-cascade">{{item.desig_Name}}</div>
                            </div>
                        </div>
                    </div>


                    <div class="form-group row">
                        <label class="control-label col-md-2">Job Title</label>
                        <div class="col-md-4">
                            <select class="form-control readonlyClass select" readonly autocomplete="off" disabled>
                                <option value="">{{this.approvalSummary.desig_Name}}</option>
                            </select>
                        </div>

                        <label class="control-label col-md-2">Job Description</label>
                        <div class="col-md-4">
                            <select class="form-control readonlyClass select" readonly autocomplete="off" disabled>
                                <option value="">{{this.approvalSummary.jD_Name}}</option>
                            </select>
                            <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="download()"
                                   title="Download Job Description"><i class="fa fa-download fa-1x"></i></a>
                        </div>
                        <!-- <div class="col-md-1">
                            <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="openModel()"
                                   *ngIf="title == 'Add'" title="Add Job Description"><i
                                       class="fa fa-plus fa-1x"></i></a>&nbsp;
               
                               <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="openModel()"
                                   *ngIf="title == 'Edit'" title="Edit Job Description"><i
                                       class="fa fa-edit fa-1x"></i></a>&nbsp;  
               
                               <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="download()"
                                   title="Download Job Description"><i class="fa fa-download fa-1x"></i></a>
                           </div> -->
                    </div>
                    <div class="form-group row">
                        <label class="control-label col-md-2">Zone</label>
                        <div class="col-md-4">
                            <select class="form-control readonlyClass select" readonly autocomplete="off" disabled>
                                <option value="">{{this.approvalSummary.zone_Name}}</option>
                            </select>
                        </div>

                        <label class="control-label col-md-2">No. of Position</label>
                        <div class="col-md-4">
                            <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.approvalSummary.noOfPositions}}" readonly />
                        </div>
                    </div>


                    <div class="form-group row">
                        <label class="control-label col-md-2">Priority</label>
                        <div class="col-md-4 form-group">
                            <select class="form-control readonlyClass select" readonly autocomplete="off" disabled>
                                <option value="">{{this.approvalSummary.priority}}</option>
                            </select>
                        </div>


                        <label class="control-label col-md-2">Skills</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass skills_tags" id="skills_tags" data-role="tagsinput" autocomplete="off" value="{{this.approvalSummary.skills}}" readonly disabled />
                        </div>

                    </div>



                    <div class="form-group row">



                        <label class="control-label col-md-2">Salary</label>

                        <div class="md-radio-inline col-md-4">
                            <div class="md-radio has-info" style="margin-right: 10px;">
                                <input type="radio" id="radio5" class="md-radiobtn" [checked]="this.salaryType == 'Hourly'" value="Hourly" disabled>
                                <label for="radio5">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> Hourly</label>
                            </div>
                            <div class="md-radio has-info" style="margin-right: 10px;">
                                <input type="radio" id="radio2" class="md-radiobtn" [checked]="this.salaryType == 'Annually'" value="Annually" disabled>
                                <label for="radio2">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> Annually </label>
                            </div>
                        </div>

                        <!-- <label class="control-label col-md-2">Bonus</label>
                        <div class="col-md-4">
                            <input type="text" class="form-control readonlyClass" autocomplete="off" value="{{this.approvalSummary.bonus}}" readonly />
                        </div> -->

                    </div>
                    <div class="form-group row">

                        <div class="form-group">
                            <label class="control-label col-md-2">&nbsp;
                            </label>
                            <div class="col-md-4">
                                <div class="input-group">
                                    <div class="input-group">
                                        <input type="text" class="form-control readonlyClass" id="from" value="{{this.approvalSummary.salaryFrom}}" readonly>
                                        <input type="text" class="form-control readonlyClass" id="to" value="{{this.approvalSummary.salaryTo}}" readonly>
                                        <span class="input-group-addon inputaddon">{{currency_Code}}</span>
                                    </div>
                                    <span class="input-group-addon inputaddon">
                                        <i class="fa" id="perAnumAndHour">{{salaryTitle}}</i>
                                    </span>
                                </div>
                            </div>



                        </div>



                        <label class="control-label col-md-2">&nbsp; &nbsp; &nbsp; &nbsp;
                        </label>
                        <div _ngcontent-ogs-c5="" class="col-md-4 classBudget">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </div>

                    </div>
                    <div class="form-group row">


                        <label class="control-label col-md-2">Contract Type</label>
                        <div class="col-md-4">
                            <select class="form-control readonlyClass select" readonly autocomplete="off" disabled>
                                <option value="">{{this.approvalSummary.contract_Type}}</option>
                            </select>
                        </div>

                        <label class="control-label col-md-2">Requirements</label>

                        <div class="col-md-4">
                            <ng-select2 [data]="this.requirements" id="requirements" [options]="{multiple: true,tags: false}" [value]='this.requirements' [placeholder]="" [disabled]="true">
                            </ng-select2>
                        </div>

                    </div>
                    <div class="form-group row">



                        <label class="control-label col-md-2">Contracted Hours</label>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="number" class="form-control readonlyClass" autocomplete="off" value="{{this.approvalSummary.contract_HoursDaily}}" readonly>
                                <span class="input-group-addon inputaddon">
                                    <i class="fa">Daily</i>
                                </span>
                            </div>
                        </div>

                        <!-- <label class="control-label col-md-2">Shift Time</label>
                        <div class="col-md-4">
                            <select class="form-control readonlyClass select" readonly autocomplete="off" disabled>
                                <option value="">{{this.approvalSummary.shift_Name}}</option>
                            </select>
                        </div> -->
                    </div>
                    <div class="form-group row">


                        <div class="" readonly>
                            <label class="control-label col-md-2">Working Days
                                <!-- <span class="required" aria-required="true"> * </span> -->
                            </label>
                            <div class="md-checkbox-inline col-md-4">
                                <div class="md-checkbox has-success" style="margin-right: 10px;">
                                    <input type="checkbox" id="checkbox14" value="mon" class="md-check wd">
                                    <label for="checkbox14">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> M</label>
                                </div>
                                <div class="md-checkbox has-success" style="margin-right: 10px;">
                                    <input type="checkbox" id="checkbox15" value="tue" class="md-check wd">
                                    <label for="checkbox15">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> T </label>
                                </div>
                                <div class="md-checkbox has-info" style="margin-right: 10px;">
                                    <input type="checkbox" id="checkbox16" value="wed" class="md-check wd">
                                    <label for="checkbox16">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> W </label>
                                </div>
                                <div class="md-checkbox has-info" style="margin-right: 10px;">
                                    <input type="checkbox" id="checkbox17" value="thr" class="md-check wd">
                                    <label for="checkbox17">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> T </label>
                                </div>
                                <div class="md-checkbox has-info" style="margin-right: 10px;">
                                    <input type="checkbox" id="checkbox18" value="fri" class="md-check wd">
                                    <label for="checkbox18">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> F </label>
                                </div>
                                <div class="md-checkbox has-error" style="margin-right: 10px;">
                                    <input type="checkbox" id="checkbox19" value="sat" class="md-check wd">
                                    <label for="checkbox19">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> S </label>
                                </div>
                                <div class="md-checkbox has-error" style="margin-right: 10px;">
                                    <input type="checkbox" id="checkbox20" value="sun" class="md-check wd">
                                    <label for="checkbox20">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> S </label>
                                </div>

                            </div>
                        </div>
                        <label class="control-label col-md-2">Shift Start</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" id="shift_Start" value="{{this.approvalSummary.shift_Start}}" readonly>
                        </div>
                        <label class="control-label col-md-2">Shift End</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" id="shift_End" value="{{this.approvalSummary.shift_End}}" readonly>
                        </div>
                        <!-- <label class="control-label col-md-2">Night Shift</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                *ngIf="this.approvalSummary.nightShift != 1" value="No" readonly />
                            <input type="text" class="form-control readonlyClass"
                                *ngIf="this.approvalSummary.nightShift == 1" value="Yes" readonly />
                        </div> -->

                        <label class="control-label col-md-2">Shift Variable</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                *ngIf="this.approvalSummary.shift_Variable != 1" value="No" readonly />
                            <input type="text" class="form-control readonlyClass"
                                *ngIf="this.approvalSummary.shift_Variable == 1" value="Yes" readonly />
                        </div>

                        <label class="control-label col-md-2">Car Allowance</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" id="Car_Allowance" value="{{this.approvalSummary.car_Allowance}}" readonly>
                        </div>

                        <label class="control-label col-md-2">Reference Number</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" id="reference_Number" value="{{this.approvalSummary.reference_Number}}" readonly>
                        </div>

                        <label class="control-label col-md-2">Date of Submission</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" id="date_Submition" value="{{this.approvalSummary.dateofSubmission}}" readonly>
                        </div>

                        <label class="control-label col-md-2">Holiday manager</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" id="Reporting" value="{{this.approvalSummary.reporting}}" readonly>
                        </div>

                        <label class="control-label col-md-2">Line Manager</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" id="line_Manager" value="{{this.approvalSummary.line_Manager}}" readonly>
                        </div>


                    </div>

                    <div class="form-group row">
                        <label class="control-label col-md-2" *ngIf="this.approvalSummary.notes != null">Justification</label>
                        <div class="col-md-10 form-group" *ngIf="this.approvalSummary.notes != null">
                            <textarea class="form-control readonlyClass" rows="3" readonly>{{this.approvalSummary.notes}}</textarea>
                        </div>

                    </div>



                </div>

            </div>
            <div class="modal-footer">

                <a *ngIf="flagActions == 1" (click)="myApprovalApprove(this.approvalSummary.id,this.approvalSummary.nextApprover_Rule_Detail_Id,this.approvalSummary.nextApprover)" class="btn blue">Approve</a>
                <a *ngIf="flagActions == 1" (click)="myApprovalReject(this.approvalSummary.id)" class="btn red">Reject</a>
                <button type="button" (click) ="closepoopup()" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="CandidatetoRaf" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Çandidate Link To RAF</h4>
            </div>

            <div class="modal-body">
                <div class="scroller" style="height:500px" data-always-visible="1" data-rail-visible1="1">
                    <!-- <diV class="row">
                    <div class="col-md-9">
                    </div>
                    <div class="col-md-3 text-right">
                        <input type="search" class="form-control" placeholder="Type to filter ...">
                    </div>
                </diV>
                <br /> -->
                    <table class="table table-striped table-bordered table-hover" id="tblZone">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center"> Sr No. </th>
                                <th class="text-center"> Çandidate Name </th>
                                <th class="text-center"> Email </th>
                                <th class="text-center"> Status </th>
                                <th class="text-center"> Reviewed By </th>
                                <th class="text-center"> Reviewed On </th>
                                <th class="numeric text-center" style="width: 15%">
                                    <div class="form-group">
                                        <div class="md-checkbox-inline" title="Select All To Download">
                                            <div class="md-checkbox">
                                                <input type="checkbox" (click)="checkBoxAll(0,'all')" id="checkall" class="md-check">
                                                <label for="checkall">
                                                    <span></span>
                                                    <span class="check"></span>
                                                    <span class="box"></span> </label>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <input type="hidden" value="" class="vvalueclass" id="vacancyvalue">
                            <tr *ngFor="let item of vacancyCandidateList; let i = index;">
                                <td class="text-center"> {{i +1}} </td>
                                <td class="text-center"> {{item.firstName}} {{item.middleName}} {{item.lastName}} </td>
                                <td class="text-center">{{item.eMail}}</td>
                                <td class="text-left text-center"> {{item.status}} </td>
                                <td class="text-center"> {{item.reviewedBy}} </td>
                                <td class="numeric text-center"> {{item.reviewedOn}} </td>
                                <td class="numeric text-center">
                                    <div class="form-group">
                                        <div class="md-checkbox-inline">
                                            <div class="md-checkbox">
                                                <input type="checkbox" (click)="checkBoxAll(item.id)" id="selectall{{item.id}}" class="md-check candidateCheck" value="{{item.id}}">
                                                <label for="selectall{{item.id}}">
                                                    <span></span>
                                                    <span class="check"></span>
                                                    <span class="box"></span> </label>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" class="btn green" (click)="linkVacancyToCandidate()">Save</button>
            </div>

        </div>
    </div>
    <!-- /.modal-dialog -->
</div>
<div class="modal" id="modalJobDescription" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="modalJobDescriptionclose()"  aria-hidden="true"></button>
                <h4 class="modal-title">Job Description</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <iframe [src]='fileUrl' width="100%" height="600px"></iframe>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button"  class="btn dark btn-outline" (click)="modalJobDescriptionclose()">Close</button>
            </div>
        </div>
    </div>
</div>
<style>
    .mt-element-step .step-line .done .mt-step-title:after,
    .mt-element-step .step-line .done .mt-step-title:before {
        background-color: #fff !important;
    }
    
    .mt-element-step .step-line .done .mt-step-title:after,
    .mt-element-step .step-line .done .mt-step-title:before {
        background-color: #fff !important;
    }
</style>