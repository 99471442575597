import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { DomSanitizer } from '@angular/platform-browser';
import { Select2OptionData } from 'ng-select2';
declare var $: any;


@Component({
  selector: 'app-job-description',
  templateUrl: './jobdescription.component.html'
})
export class TobDescriptionComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  jDescriptionform: FormGroup;
  JDlist: any;
  filterJD: any;
  companylist: Array<Select2OptionData>;
  sitelist: Array<Select2OptionData>;
  listcall: boolean = true;
  departmentlist: Array<Select2OptionData>;
  designationlist: Array<Select2OptionData>;
  title: string;
  filename: string = "Job_Description.pdf";
  p: number = 1;
  jdDocument: any;
  jdDocumentChange: boolean = false;
  fileUrl: any;
  totalRecord: 0;
  fileName: any = "";
  docFiles: string[] = [];
  constructor(
    private commonApiService: CommonApiService,
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.title = "Add";
    this.jDescriptionform = this.fb.group({
      jD_Id: ['0'],
      company_Id: ['', Validators.required],
      site_Id: ['', Validators.required],
      dept_Id: ['', Validators.required],
      desig_Id: ['', Validators.required],
      jD_Code: [''],
      jD_Name: ['', Validators.required],
      jD_Description: [''],
      notes: [''],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });
    // $("select").select2({
    //   dropdownParent: $('#draggable'),
    //   width: "auto"
    // });
    if (this.listcall) {
      this.getJobDescriptionList(1);
      this.getCompanyList();
    }

  }

  getJobDescriptionList(PageIndex) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.JDlist = []
    this.commonApiService.getListWithFilter(Commonvariable.JobDescription, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["status"] == 200 && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.JDlist = result["data"]["list"];
        this.filterJD = result["data"]["list"];
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })
  }

  getCompanyList() {
    this.companylist = [];
    this.jDescriptionform.controls.company_Id.setValue('')
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "company_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }));
      }

    })
  }



  getsiteList() {

    this.sitelist = [];
    this.departmentlist = [];

    if (this.title != "Edit") {
      this.jDescriptionform.controls.site_Id.setValue('')
      this.jDescriptionform.controls.dept_Id.setValue('')
    }


    if (parseInt(this.jDescriptionform.controls.company_Id.value) > 0) {

      var requestData = {
        "searchBy": [
          {
            "FieldName": "isActive",
            "FieldValue": "1",
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "company_Id",
            "FieldValue": this.jDescriptionform.controls.company_Id.value,
            "Parameter": "==",
          }
        ],
        "OrderBy": "site_Name"
      }

      this.commonApiService.GetByPaginated(Commonvariable.Site, requestData).subscribe((response) => {
        if (response["data"] != "") {
          this.sitelist = response["data"].map(x => ({
            id: x.site_Id,
            text: x.site_Name
          }));
        }
        requestData.OrderBy = "dept_Name"


        this.commonApiService.GetByPaginated(Commonvariable.Department, requestData).subscribe((response) => {
          if (response["data"] != "") {
            this.departmentlist = response["data"].map(x => ({
              id: x.dept_Id,
              text: x.dept_Name
            }));
          }

          if (this.title == "Edit") {
            this.getJobTitle();
          }
          // requestData.OrderBy = "desig_Name"
          // this.commonApiService.GetByPaginated(Commonvariable.Designation, requestData).subscribe((response) => {
          //   if (response["data"] != "") {
          //     this.designationlist = response["data"];
          //   }
          // })

        })

      })

    }







  }

  getJobTitle() {

    this.designationlist = [];


    if (parseInt(this.jDescriptionform.controls.company_Id.value) > 0 && parseInt(this.jDescriptionform.controls.dept_Id.value) > 0) {


      var requestData = {
        "searchBy": [
          {
            "FieldName": "isActive",
            "FieldValue": "1",
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "company_Id",
            "FieldValue": this.jDescriptionform.controls.company_Id.value,
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": "dept_Id",
            "FieldValue": this.jDescriptionform.controls.dept_Id.value,
            "Parameter": "==",
          }
        ],
        "OrderBy": "desig_Name"
      }

      requestData.OrderBy = "desig_Name"
      this.commonApiService.GetByPaginated(Commonvariable.Designation, requestData).subscribe((response) => {
        if (response["data"] != "") {
          this.designationlist = response["data"].map(x => ({
            id: x.desig_Id,
            text: x.desig_Name
          }));
        }
      })

    }

  }


  getDepartmentList() {
    this.departmentlist = [];
    this.commonApiService.cascadingDropDwon(Commonvariable.Department, "company_Id", this.jDescriptionform.controls.company_Id.value).subscribe((response) => {
      if (response["data"] != "") {
        this.departmentlist = response["data"];
        this.getDesignationList();
      }
    })
  }

  getDesignationList() {
    this.designationlist = [];
    this.commonApiService.cascadingDropDwon(Commonvariable.Designation, "company_Id", this.jDescriptionform.controls.company_Id.value).subscribe((response) => {
      if (response["data"] != "") {
        this.designationlist = response["data"];
      }
    })
  }


  jobDescriptionUpsert() {
    var JDForm = this.jDescriptionform;
    if (this.validation()) {
      JDForm.get("isActive").setValue(JDForm.get("isActive").value ? 1 : 0);
      JDForm.get("jD_Name").setValue(JDForm.get("jD_Name").value);
      this.commonApiService.Upsert(Commonvariable.JobDescription, JDForm.value, JDForm.controls.jD_Id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Job Description');
          }
          else {
            if (this.jdDocumentChange) {
              const formData = new FormData();
              formData.append('Jd_Id', response["data"]["jD_Id"]);
              formData.append('file', this.jdDocument);
              this.commonApiService.UploadLogo(Commonvariable.JobDescription, "AddDocument", formData).subscribe((response) => {
                $("#draggable").modal('hide');
                this.getJobDescriptionList(1);
                this.toastr.success(JDForm.controls.jD_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Job Description');
              });
            }
            else {
              $("#draggable").modal('hide');
              this.getJobDescriptionList(1);
              this.toastr.success(JDForm.controls.jD_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Job Description');
            }
          }
        }
      })
    }

  }

  jobDescriptionDelete(jdId) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.JobDescription, jdId).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getJobDescriptionList(1);
          this.toastr.success(response["message"], 'Job Description');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.JobDescription, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.getJobDescriptionList(1);
            this.toastr.success(response["message"], 'Job Description');            
          }

        })
      }

    });
  }

  openModel(jdId, child = false) {
    
    if (jdId > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.JobDescription, jdId).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var JDForm = this.jDescriptionform;
          this.commonService.setEditData(JDForm, editData);
          this.jDescriptionform.controls.updatedBy.setValue(Commonvariable.User_Id);
          this.fileName = response['data'][0].doc_Name;
          this.docFiles.push(this.fileName);
          $('.fileinput-filename').html(this.fileName);
          $('.fileinput.fileinput-new').removeClass('fileinput-new').addClass('fileinput-exists');
          this.jdDocumentChange = false;
          this.getsiteList();
          // $("#jD_Code").prop("disabled", "disabled");
          $("#draggable").modal('show');
        }
      })
    }
    else {
      // $("#jD_Code").removeAttr("disabled");
      this.listcall = false;
      this.departmentlist = [];
      this.designationlist = [];
      this.ngOnInit();
    }
    $('.removedoc').trigger('click');
    $('#draggable').modal({
      backdrop: 'static'
    });

  }


  validation() {
    
    var JDForm = this.jDescriptionform;
    if (!JDForm.valid) {
      for (const key of Object.keys(JDForm.controls)) {
        if (JDForm.controls[key].invalid) {
          let fieldName = "";
          if (key == "company_Id") {
            fieldName = "Company"
            $("#company_Id select").focus();
          }
          else if (key == "site_Id") {
            fieldName = "Site"
            $("#site_Id select").focus();
          }
          else if (key == "dept_Id") {
            fieldName = "Department"
            $("#dept_Id select").focus();
          }
          else if (key == "desig_Id") {
            fieldName = "Job title"
            $("#desig_Id select").focus();
          }
          // else if (key == "jD_Code") {
          //   fieldName = "Job description code"
          // }
          else if (key == "jD_Name") {
            fieldName = "Job description name"
          }
         
          this.toastr.info(fieldName + " is required !", "Job Description");
          $("#" + key).focus();
          return false;
        }
      }
    }
    
    else {
      if (this.docFiles.length == 0) {
        this.toastr.info("Upload Document is required !", "Job Description");
        return false;
     }
      // if ($("#jD_Code").val().indexOf(" ") > -1) {
      //   this.toastr.info("Job description code not allow space !", "Job Description");
      //   $("#jD_Code").focus();
      //   return false;
      // }
      // if ($("#jD_Code").val().length < 2) {
      //   this.toastr.info("Job description code must be at least 2 characters long !", "Job Description");
      //   $("#jD_Code").focus();
      //   return false;
      // }
      // if ($("#jD_Code").val().search(/[^a-zA-Z0-9]+/) > -1) {
      //   this.toastr.info("Job description code only alphabets and numeric allow !", "Job Description");
      //   $("#jD_Code").focus();
      //   return false;
      // }
      if ($("#jD_Name").val().length < 2) {
        this.toastr.info("Job description name must be at least 2 characters long !", "Job Description");
        $("#jD_Name").focus();
        return false;
      }
      if (/["\\]/.test($("#jD_Name").val())) {
        this.toastr.info("Job description Name does not allow Special Characters \" \\ ", "Job Description");
        $("#jD_Name").focus();
        return false;
      }
      if (/["\\]/.test($("#jD_Description").val())) {
        this.toastr.info("Job description does not allow Special Characters \" \\ ", "Job Description");
        $("#jD_Description").focus();
        return false;
      }
      if (/["\\]/.test($("#notes").val())) {
        this.toastr.info("Notes does not allow Special Characters \" \\ ", "Job Description");
        $("#notes").focus();
        return false;
      }
      
      // if ($("#jD_Name").val().search(/^[^-\s][a-zA-Z0-9\s.]+[^-\s]+$/) != 0) {
      //   this.toastr.info("Job description name only alphabets and numeric allow !", "Job Description");
      //   $("#jD_Name").focus();
      //   return false;
      // }
      // if ($("#jD_Name").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
      //   this.toastr.info("Job description name only alphabets and numeric allow !", "Job Description");
      //   $("#jD_Name").focus();
      //   return false;
      // }

    }

    return true;
  }



  filechange(files: FileList) {
    
    this.jdDocumentChange = true;
    this.jdDocument = files.item(0);
  


    this.docFiles = this.docFiles || [];
    this.docFiles.push(files.item(0).name);

  }
  removeDoc() {
 
  this.jdDocument = "";
  this.docFiles =  this.jdDocument;
 
  }


  history() {
    this.historyView.getHistory("job_description",this.jDescriptionform.value.jD_Id)
  }
  


  downloadDocument(id, path) {
    this.filename = "Job_Description" + "." + String(path).split('.')[1]
    this.commonApiService.getimage(Commonvariable.JobDescription, id, 'DownloadDocument').subscribe((result) => {
     
      const blobData = new Blob([result], { type: result.type });
      var createObjectURL=(window.URL.createObjectURL(blobData));
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);      
      if (result.type == "application/pdf"  || result.type == "image/jpeg" || result.type == "image/png") {
        $("#jobDescriptionViewFile").modal("show")
      }
      
    })
  }

  setFormData(id, event) {
    if (id != "" && event != "") {
      this.jDescriptionform.controls[id].setValue(event)
      if (id == "company_Id") {
        this.getsiteList()
      }
      if (id == "dept_Id") {
        this.getJobTitle()
      }
    }

  }


}
