import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { formatDate } from '@angular/common';
import Swal from 'sweetalert2';
declare var $: any;
import * as moment from 'moment';
import { KeyedRead } from '@angular/compiler';

import { strictEqual } from 'assert';


@Component({
  selector: 'app-history',
  templateUrl: './history.component.html'
})
export class HistoryComponent implements OnInit {
  @ViewChild('historyFromDate', { static: false }) historyFromDate: ElementRef;
  @ViewChild('historyToDate', { static: false }) historyToDate: ElementRef;
  tableid = 0;
  historyList: any[] = [];
  p: number = 1;
  totalRecord: 0;


  historydata: any;
  filterhistory: any;
  totalItems: any;
  filterItems: 0;


  searchPage: number = 0;
  tableName: any;
  primaryField: '';
  primaryValue: '';

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService) { }
  ngOnInit() {
    //this.history();
    this.commonService.flatpickr("flatpickrhistoryfromdate,#flatpickrhistorytodate", 0);
  }


  clear() {
    this.commonService.flatpickr("flatpickrhistoryfromdate,#flatpickrhistorytodate", 0);
    this.historyList = [];
    this.totalRecord = 0;
  }

  validation() {
    if (this.historyFromDate.nativeElement.value == "") {
      this.toastr.info("From date is required !", 'History');
      this.historyFromDate.nativeElement.focus();
      return false;
    }
    if (this.historyToDate.nativeElement.value == "") {
      this.toastr.info("To date is required !", 'History');
      this.historyToDate.nativeElement.focus();
      return false;
    }

    if (moment(this.historyFromDate.nativeElement.value, 'DD-MM-YYYY') > moment(this.historyToDate.nativeElement.value, 'DD-MM-YYYY')) {
      this.toastr.info("From date not more then to date  !", "History");
      this.historyFromDate.nativeElement.focus();
      return false;
    }


    return true;
  }



  getHistoryByTable(pageIndex = 1) {
    this.historyList = [];

    this.p = pageIndex;
    this.totalRecord = 0;

    if (this.validation()) {

      let requestData = {
        "TableId": this.tableid,
        "TableName": this.tableName,
        "StartDate": this.commonService.dateFormatChange(this.historyFromDate.nativeElement.value),
        "EndDate": this.commonService.dateFormatChange(this.historyToDate.nativeElement.value),
        "PageIndex": pageIndex - 1,
        "PageSize": 5,
        "CommonSearch": "",
        "OrderBy": ""
      }

      this.commonApiService.PostRequest("HistoryTable", "GetData", requestData).subscribe((result) => {
        if (result["data"] != "") {

          this.historyList = result["data"]["list"];
          this.totalRecord = result["data"]["recordCount"];

          for (let obj of this.historyList) {
            let tempFieldValue = [];
            let tempoldValue = [];
            let tempnewValue = [];
            for (let key in obj.fieldName) {              
              tempFieldValue.push({ "value": obj.fieldName[key] })
            }
            for (let key in obj.oldValue) {
              tempoldValue.push({ "key": key, "value": obj.oldValue[key] })
            }
            for (let key in obj.newValue) {
              tempnewValue.push({ "key": key, "value": obj.newValue[key] })
            }
            obj.fieldValue = tempFieldValue;
            obj.oldValue = tempoldValue;
            obj.newValue = tempnewValue;
          }
        }
      })
    }
  }

  getHistory(tableName, tableid = 0, modelName = "historyModal") {
    this.clear();
    this.tableid = tableid;
    this.totalRecord = 0;
    this.tableName = tableName;
    $('#' + modelName).modal({
      backdrop: 'static'
    });

  }



}

