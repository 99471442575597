import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { id } from 'date-fns/locale';

import { SelectionRafWiseComponent } from '../selection-raf-wise/selection-raf-wise.component';
import { SelectionCandidateWiseComponent } from '../selection-candidate-wise/selection-candidate-wise.component';
import { ScheduleInterviewComponent } from "../scheduleInterview/scheduleInterview.component";
import { InterviewFeedbackComponent } from "../interviewFeedback/interviewFeedback.component";
declare var $: any;


@Component({
  selector: 'app-recruitmentSelection',
  templateUrl: './recruitmentSelection.component.html'
})
export class RecruitmentSelectionComponent implements OnInit {

  tabActive : string = "rafWise";
  @ViewChild(SelectionRafWiseComponent, { static: false }) SelectionRafWise: SelectionRafWiseComponent;
  @ViewChild(SelectionCandidateWiseComponent, { static: false }) SelectionCandidateWise: SelectionCandidateWiseComponent;
  
  
  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit() {
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);
  }

}
