<div class="modal fade draggable-modal" id="draggable" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="Back()" aria-hidden="true"></button>
                <h4 class="modal-title">Company Selection</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group">
                        <label class="control-label col-md-2 text-center">Company<span class="required"> *
                            </span></label>
                        <div class="col-md-3">
                            <ng-select2 [data]="companylist" (valueChanged)="companyId =$event" [width]="500"
                                [placeholder]="'--- Select ---'"></ng-select2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="Back()" class="btn default">Back to Login</button>&nbsp;
                <button type="button" (click)="Select()" class="btn btn-outline blue">Select</button>
            </div>
        </div>
    </div>
</div>
