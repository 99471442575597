<div class="row">
    <div class="col-md-12">
        <form [formGroup]="candidatePersonEqualityform">
            <div class="row mb-0">
        
                    <label class="control-label col-md-2">Gender<span class="required"> * </span></label>
                    <div class="col-md-4 form-group">
                        <select class="form-control" id="Gender" formControlName="Gender" (change)="onChangeforGender($event)">
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Non-Binary">Non-Binary</option>
                            <option value="Other">Other</option>
                            <option value="Prefer Not to Say">Prefer Not to Say</option>
                        </select>
                    </div>
        
                    <label class="control-label col-md-2">Marital Status<span class="required"> * </span></label>
                    <div class="col-md-4 form-group">
                        <select id="MaritalStatus" class="form-control" formControlName="MaritalStatus" (change)="onChangeforMaritalStatus($event)">
                            <option value="">Select Marital Status</option>
                            <option *ngFor="let item of MaritalList;" value={{item.text}}>
                                {{item.text}}</option>
                        </select>
                    </div>
                    <label class="control-label col-md-2"><span *ngIf="isrequiredforGender">If Other, please specify<span class="required"> * </span></span></label>
                        <div class="col-md-4 " >
                            <input type="text" class="form-control form-group" *ngIf="isrequiredforGender" id="Gender_Other" formControlName="Gender_Other"/>
                        </div>
        
                        <label class="control-label col-md-2"><span *ngIf="isrequiredforMarital">If Other, please specify<span class="required"> * </span></span></label>
                        <div class="col-md-4 ">
                            <input type="text" class="form-control form-group" *ngIf="isrequiredforMarital" id="Marital_Other" formControlName="Marital_Other"/>
                        </div>
        
            </div>
        
            <div class="row mt-0 mb-0">
                    <label class="control-label col-md-2">Sexual Orientation<span class="required"> * </span></label>
                    <div class="col-md-4 form-group">
                        <select class="form-control" id="Sexual_Orientation" formControlName="Sexual_Orientation" (change)="onChangeforSexual($event)">
                            <option value="">Select Sexual Orientation</option>
                            <option value="Heterosexual">Heterosexual</option>
                            <option value="Gay">Gay</option>
                            <option value="Lesbian">Lesbian</option>
                            <option value="Bisexual">Bisexual</option>
                            <option value="Asexual">Asexual</option>
                            <option value="Pansexual">Pansexual</option>
                            <option value="Other">Other</option>
                            <option value="Prefer Not to Say">Prefer Not to Say</option>
                        </select>
                    </div>
        
                    <label class="control-label col-md-2">Age<span class="required"> * </span></label>
                    <div class="col-md-4 form-group">
                        <!-- <select class="form-control" id="Age" formControlName="Age">
                            <option value="">Select Age</option>
                            <option value="18-30">18-30</option>
                            <option value="31-45">31-45</option>
                            <option value="46-60">46-60</option>
                            <option value="61-75">61-75</option>
                            <option value="76+">76+</option>
                            <option value="Prefer Not to Say">Prefer Not to Say</option>
                        </select> -->
                        <!-- <input type="text" class="form-control" formControlName="Age" id="Age" autocomplete="off"
                    maxlength="50" /> -->
                    <select id="Age" class="form-control" formControlName="Age">
                        <option value="">Select Age</option>
                        <option *ngFor="let item of AgeList;" value={{item.text}}>
                            {{item.text}}</option>
                    </select>
                    </div>
        
        
                    <label class="control-label col-md-2"><span *ngIf="isrequiredforSexual">If Other, please specify<span class="required"> * </span></span></label>
                <div class="col-md-4">
                    <input type="text" class="form-control form-group"  id="Sexual_Other" formControlName="Sexual_Other" *ngIf="isrequiredforSexual"/>
                </div>     
        
        
            </div>
        
          
        
            <div class="row mt-0 mb-0">
        
                    <label class="control-label col-md-2">
                        Disability<span class="required"> * </span>
                        <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-container="body"
                                                            data-placement="left" type="button" data-html="true" id="disability">
                                                            <span class="glyphicon glyphicon-info-sign"></span>
                                                        </span>
                    </label>
                    <div id="popover-content-disability" class="hide">
                        The Equality Act 2010 defines a disabled person
                        as someone who has a mental or physical
                        impairment that has a substantial and long-term
                        adverse effect on the person’s ability to carry out
                        normal day-to-day activities. If you have a
                        condition which fits the Equality Act definition,
                        please tick ‘yes’ even if you are not limited by
                        your condition.
                        Do you consider yourself to have a disability?
                    </div>
                    <div class="col-md-4 form-group">
        
                        <select class="form-control" id="Disability" formControlName="Disability">
                            <option value="">Select Disability</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="Prefer Not to say">Prefer Not to say</option>
                        </select>
                    </div>
                    <label class="control-label col-md-2">Religion or Belief<span class="required">*</span></label>
                    <div class="col-md-4 form-group">
                        <select class="form-control" id="ReligionOrBelief" formControlName="ReligionOrBelief">
                            <option value="">Select Religion or Belief</option>
                            <option value="Buddhist">Buddhist</option>
                            <option value="Hindu">Hindu</option>
                            <option value="Muslim">Muslim</option>
                            <option value="Christian">Christian</option>
                            <option value="Jewish">Jewish</option>
                            <option value="Sikh">Sikh</option>
                            <option value="No religion or beli">No Religion or belief </option>
                            <option value="Prefer Not to say">Prefer Not to say</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
        
        
            </div>
                    
        
            <div class="row mt-0 mb-0">
                    <label class="control-label col-md-7">Is the Gender you identify with the same as
                        your Gender Registered
                        at birth?<span class="required"> * </span></label>
                    <div class="col-md-5 form-group">
                        <div class="md-radio-inline">
                            <div class="md-radio">
                                <input type="radio" id="radio62" name="Gender_Birth" formControlName="Gender_Birth" value="Yes"
                                    class="md-radiobtn">
                                <label for="radio62">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> Yes </label>
                            </div>
                            <div class="md-radio has-error">
                                <input type="radio" id="radio63" name="Gender_Birth" formControlName="Gender_Birth" value="No"
                                    class="md-radiobtn">
                                <label for="radio63">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> No </label>
                            </div>
                            <div class="md-radio has-warning">
                                <input type="radio" id="radio64" name="Gender_Birth" formControlName="Gender_Birth"
                                    value="Prefer Not To Say" class="md-radiobtn">
                                <label for="radio64">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> Prefer Not To Say </label>
                            </div>
        
                        </div>
        
                    </div>
            </div>
                    
        
                 <div class="row mt-0 mb-0"> 
                    <label class="control-label col-md-2">Ethnicity<span class="required"> * </span>
                        <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-placement="left" type="button"
                                        data-html="true" id="ethnicity">
                                        <span class="glyphicon glyphicon-info-sign"></span>
                                    </span>
                    </label>
                    <div id="popover-content-ethnicitypopover" class="hide">
                        Ethnic origin is not about nationality, place of
                        birth or citizenship. It is about what best
                        describes your ethnic group or background.
                        Please choose one option and tick the
                        appropriate box below.
                        What is your ethnic group?
                    </div>
                    <div class="col-md-4 form-group">
                        <!-- <select id="Ethnicity_Code" class="form-control" formControlName="Ethnicity">
                            <option value="">Select Ethnicity</option>
                            <option *ngFor="let item of ethinicityList;" value={{item.ethnicity_Name}}>
                                {{item.ethnicity_Name}}</option>
                        </select> -->
                        <!-- <app-treedropdown ></app-treedropdown> -->
                        <select id="Ethnicity_Code" class="form-control"  formControlName="Ethnicity">
                            <option value="">Select Ethnicity</option>
                            <!-- <option value="Prefer not to say">Prefer not to say</option> -->
                            <optgroup *ngFor="let item of treeData" [label]="item.name">
                              <option *ngFor="let child of item.children" [value]="child.name">{{ child.name }}</option>
                            </optgroup>
                          </select>
                    </div>
        
        
                   
                    <!-- <div class="col-md-4 form-group">
                         <p-tree [selection]="selectedNodes" [propagateSelectionDown]="true" [propagateSelectionUp]="true"></p-tree> 
                        <app-treedropdown></app-treedropdown>
                        
                    </div> -->
            </div>
        </form>
    </div>
    
</div>
