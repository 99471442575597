import { Component, OnInit  } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, FormArray ,Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;
import * as moment from 'moment';
import { flatten } from '@angular/compiler';

@Component({
   selector: 'app-empSalary',
   templateUrl: './employee-salary.component.html',
})
export class EmployeeSalaryComponent implements OnInit {

   empform: FormGroup;
   items: FormArray;
   items1: FormArray;
   currency_Code:any;
   //public isDivHidden = false;
   public isDivHidden: boolean[] = [];
   public isrequiredclass: boolean[] = [];
   tempAdditionalPayment = false;
   isValueSelected = false;
   selectedValue:any;
   public isrequiredComclass: boolean[] = [];
   constructor(
      private toastr: ToastrService,
      private commonService: CommonService,
      private router: Router,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {

      this.empform = this.fb.group({
        // emp_salary: this.createItem(null),
        emp_salary:  this.fb.array([this.createItem(null)])
        
      });
   }
   createItem(data) {
      return this.fb.group({
         Emp_Salary_Id: '0',
         Company_Id:null,
         Dept_Id:null,
         Desig_Id:null,
         Emp_Id: '0',
         ApprisalFrom: '',
         ApprisalTo: '',
         Salary: '',
         Notes:'',
         SalaryType:'',
         ExpiryDate: '',
         Reporting_Id: null,
         isActive: true,
         addedBy: Commonvariable.User_Id,
         emp_salary_additional: this.fb.array([this.createEmployeeSalaryAdditional(null)])
      });
   }
   createEmployeeSalaryAdditional(data) {
      return this.fb.group({
        Emp_Ad_Salary_Id: 0,
        Emp_Salary_Id: 0,
        AdditionalPayment: '',
        AdditionalPaymentType: '',
        AdditionalPaymentAmount: '',
        Notes: '',
        isActive: 1,
        AddedBy: Commonvariable.User_Id,
        AddedOn: [new Date()]
      });
    }
   

   addItem(data) {
      
      if(this.validation())
      {
         this.items = this.empform.get('emp_salary') as FormArray;
        this.items.push(this.createItem(data));
        this.items = this.empform.get('emp_salary_additional') as FormArray;
         this.items.push(this.createEmployeeSalaryAdditional(data));
         setTimeout(() => {
            this.items = this.empform.get('emp_salary_additional') as FormArray;
            var id = this.items.length - 1;         
            // this.commonService.flatpickr("ApprisalFrom",id)
            // this.commonService.flatpickr("ApprisalTo",id)
         }, 5);
      }
   }
   // addItemSalary(data) {
   //    //if(this.validation())
   //    //{
      
   //    this.items1 = this.empform.get('emp_salary_additional') as FormArray;
   //    this.items1.push(this.createEmployeeSalaryAdditional(data));
   //       setTimeout(() => {
   //          this.items1 = this.empform.get('emp_salary_additional') as FormArray;
   //          var id = this.items1.length - 1;         
   //          // this.commonService.flatpickr("ApprisalFrom",id)
   //          // this.commonService.flatpickr("ApprisalTo",id)
   //       }, 5);
   //   // }
   // }
   addItemSalary(data) {
      const index = 0;
const empSalaryFormArray = this.empform.get('emp_salary') as FormArray;
//const empSalaryItem = empSalaryFormArray.at(index) as FormGroup;empSalaryFormArray.length - 1
const empSalaryItem = empSalaryFormArray.at(empSalaryFormArray.length - 1) as FormGroup;
//const empSalaryAdditionalArray = empSalaryItem.get('emp_salary_additional') as FormArray;
      
      this.items1 = empSalaryItem.get('emp_salary_additional') as FormArray;
      this.items1.push(this.createEmployeeSalaryAdditional(data));
  
      // Assuming you want to set some properties after adding a row
      setTimeout(() => {
         this.items1 = empSalaryItem.get('emp_salary_additional') as FormArray;
         var id = this.items1.length - 1;       
      }, 5);
    }
   
   


   removeItem(index) {
      this.items = this.empform.get('emp_salary') as FormArray;
      this.items.removeAt(index);
   }
 
   removeItemSalary(index: number) {
      
      const empSalaryFormArray = this.empform.get('emp_salary') as FormArray;
      const empSalaryItem = empSalaryFormArray.at(0) as FormGroup; // Assuming you want to remove from the first item
      
      const empSalaryAdditionalArray = empSalaryItem.get('emp_salary_additional') as FormArray;
      
      if (index >= 0 && index < empSalaryAdditionalArray.length) {
        empSalaryAdditionalArray.removeAt(index);
      }
    }
    
   
   empUpsert() {
      
      var empData = this.empform.get('emp_salary').value;
      if (empData[0].Salary == "") {
         return false;
      }
      return true;
   }

   validation() {
      
      var empData = this.empform.get('emp_salary').value;
      var tempArray = [];
      for (let index = 0; index < empData.length; index++) {

         if (empData[0].Salary != "" || empData[0].SalaryType != "") {

            if (empData[index].Salary == "" || empData[index].Salary ==  null) {
               this.toastr.info("Salary is required !", "Employee");
               $("#Salary" + index).focus();
               return false;
            }
            if ($("#Salary" + index).val().search(/[^0-9.]+/) > -1) {
               this.toastr.info("Salary only number allow !", "Employee");
               $("#Salary" + index).focus();
               return false;
            }
            if ($("#Salary" + index).val().length > 20) {
               this.toastr.info("Salary only 20 digit long allow !", "Employee");
               $("#Salary" + index).focus();
               return false;
            }
            if (empData[index].SalaryType == "") {
               this.toastr.info("Salary type is required !", "Employee");
               $("#SalaryType" + index).focus();
               return false;
            }


            if(tempArray.length > 0 )
            {
               if(tempArray.filter(x=>x == String(empData[index].Salary).trim()).length > 0)
               {
                  this.toastr.info("This value already entered !", "Employee");
                  $("#Salary" + index).focus();
                  return false
               }
            }

            tempArray.push(String(empData[index].Salary).trim())

            // if ($("#ApprisalFrom" + index).val() == "") {
            //    this.toastr.info("Apprisal from is required !", "Employee");
            //    $("#ApprisalFrom" + index).focus();
            //    return false;
            // }
            // if ($("#ApprisalTo" + index).val() == "") {
            //    this.toastr.info("Apprisal To is required !", "Employee");
            //    $("#ApprisalTo" + index).focus();
            //    return false;
            // }
            // if (moment($("#ApprisalFrom" + index).val(), 'DD-MM-YYYY') >= moment($("#ApprisalTo" + index).val(), 'DD-MM-YYYY')) {
            //    this.toastr.info("Apprisal from not more then apprisal to and not equal to !", "Employee");
            //    $("#ApprisalFrom" + index).focus();
            //    return false;
            // }
         }
         // empData[index].ApprisalFrom = this.commonService.dateFormatChange($("#ApprisalFrom" + index).val())
         // empData[index].ApprisalTo = this.commonService.dateFormatChange($("#ApprisalTo" + index).val())
         empData[index].isActive = empData[index].isActive ? 1 : 0

         if (this.isValueSelected) {
            this.toastr.info("Additional Payment Value is already Selected!", "Employee");
            return false;
          }
      }

      const empSalaryFormArray = this.empform.get('emp_salary') as FormArray;

for (let i = 0; i < empSalaryFormArray.length; i++) {
  const empSalaryFormGroup = empSalaryFormArray.at(i) as FormGroup;
  const empSalaryAdditionalFormArray = empSalaryFormGroup.get('emp_salary_additional') as FormArray;

  if (empSalaryAdditionalFormArray) {
    for (let j = 0; j < empSalaryAdditionalFormArray.length; j++) {
      const additionalPayment = empSalaryAdditionalFormArray.at(j).get('AdditionalPayment').value;
      if (additionalPayment != '')
      {
      if (additionalPayment != 'Other' )
       {

         const AdditionalPaymentType = empSalaryAdditionalFormArray.at(j).get('AdditionalPaymentType').value;
         const AdditionalPaymentAmount = empSalaryAdditionalFormArray.at(j).get('AdditionalPaymentAmount').value;

         if(AdditionalPaymentType == ""){
            this.toastr.info("AdditionalPayment type is required !", "Employee");
            $("#AdditionalPaymentType" + j).focus();
            return false;

         }
         if (AdditionalPaymentAmount == "") {
        
        
            this.toastr.info("AdditionalPayment Amount  is required !", "Employee");
            $("#AdditionalPaymentAmount" + j).focus();
            return false;
         }
        
        
        
      }
      else{
        
         const Notes = empSalaryAdditionalFormArray.at(j).get('Notes').value;
         if (Notes == "") {
            this.toastr.info("Comment   is required !", "Employee");
            $("#Notes" + j).focus();
            return false;
         }

      }
   }
     

    }
  }

}
      return true;
   }


  toggleCommentsTextarea(event: Event, j: number) {
  

this.selectedValue = (event.target as HTMLSelectElement).value;

const empSalaryFormArray = this.empform.get('emp_salary') as FormArray;

for (let i = 0; i < empSalaryFormArray.length; i++) {
  const empSalaryFormGroup = empSalaryFormArray.at(i) as FormGroup;
  const empSalaryAdditionalFormArray = empSalaryFormGroup.get('emp_salary_additional') as FormArray;

  if (empSalaryAdditionalFormArray) {
    for (let j = 0; j < empSalaryAdditionalFormArray.length-1; j++) {
      const additionalPayment = empSalaryAdditionalFormArray.at(j).get('AdditionalPayment').value;

      if (additionalPayment === this.selectedValue) {
        this.isValueSelected = true;
        break; // Exit the inner loop if a match is found
      }
      else{
         this.isValueSelected = false; 
      }
    }
  }

  if (this.isValueSelected) {
    break; // Exit the outer loop if a match is found
  }
}

if (this.isValueSelected) {
  this.toastr.info("Additional Payment Value is already Selected!", "Employee");
  
} 



    if (this.selectedValue === 'Night Shift Premium' || this.selectedValue === 'Car Allowance' || this.selectedValue === 'Travel Allowance' || this.selectedValue === '') {
      this.isDivHidden[j] = false;
     
    } 
    else
     {
         this.isDivHidden[j] = true;
    }

    if(this.selectedValue != ''  )
    {
      if(this.selectedValue === 'Other')
      {
      this.isrequiredclass[j] = false;
      }
      else{
         this.isrequiredclass[j] =true ;
   
       }
    }
    
    else{
      this.isrequiredclass[j] =false ;

    }
    if(this.selectedValue === 'Other'){
      this.isrequiredComclass[j] =true ;

    }
    else{
      this.isrequiredComclass[j] =false ;
    }
    

   
}

  



}


