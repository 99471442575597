<form [formGroup]="empJobDetailform">
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Company
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <select id="company_Id" class="form-control" formControlName="company_Id"
                    (change)="companyWiseGetData()">
                    <option value="">Select Company</option>
                    <option *ngFor="let company of companyList;" value={{company.company_Id}}>
                        {{company.company_Name}}</option>
                </select>
            </div>
            <label class="control-label col-md-2">Site
                <!-- <span class="required"> * </span> -->
            </label>
            <div class="col-md-3">
                <div class="input-group">
                    <select id="Site_Id" class="form-control" formControlName="Site_Id"
                        (change)="getRafList($event.target.value)">
                        <option value="">Select Site</option>
                        <option *ngFor="let item of siteList;" value={{item.site_Id}}>
                            {{item.site_Name}}</option>
                    </select>
                    <span class="input-group-btn">
                        <button class="btn dark btn-outline input-group-btn-input-grp" type="button"
                            (click)="modalOpensite =true">
                            <i class="fa fa-{{empJobDetailform.value.Site_Id != '' ? 'edit' : 'plus'}}"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Department
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <div class="input-group">
                    <select id="Dept_Id" class="form-control" formControlName="Dept_Id" (change)="getJobTitle()">
                        <option value="">Select Department</option>
                        <option *ngFor="let item of departmentList;" value={{item.dept_Id}}>
                            {{item.dept_Name}}</option>
                    </select>
                    <span class="input-group-btn">
                        <button class="btn dark btn-outline input-group-btn-input-grp" type="button"
                            (click)="modalOpendept =true">
                            <i class="fa fa-{{empJobDetailform.value.Dept_Id != '' ? 'edit' : 'plus'}}"></i>
                        </button>
                    </span>
                </div>
            </div>
            <label class="control-label col-md-2">Zone
                <span class="required"> * </span>
            </label>
            <!-- <div class="col-md-3">
                <select id="Zone_Id" class="form-control" formControlName="Zone_Id">
                    <option value="">Select Zone</option>
                    <option *ngFor="let item of zoneList;" value={{item.zone_Id}}>
                        {{item.zone_Name}}</option>
                </select>
            </div> -->

            <div class="col-md-3">
                <div class="input-group">
                    <select id="Zone_Id" class="form-control" formControlName="Zone_Id">
                        <option value="">Select Zone</option>
                        <option *ngFor="let item of zoneList;" value={{item.zone_Id}}>
                            {{item.zone_Name}}</option>
                    </select>
                    <span class="input-group-btn">
                        <button class="btn dark btn-outline input-group-btn-input-grp" type="button"
                            (click)="modalOpen =true">
                            <i class="fa fa-{{empJobDetailform.value.Zone_Id != '' ? 'edit' : 'plus'}}"></i>
                        </button>
                    </span>
                </div>
            </div>

            <!-- 
            <div class="col-md-1">
                


                <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="openModelShift()" *ngIf="titleForShift == 'Add'" title="Add Shift Time"><i
                        class="fa fa-plus fa-1x"></i></a>&nbsp;

                <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="openModelShift()" *ngIf="titleForShift == 'Edit'" title="Edit Shift Time"><i
                        class="fa fa-edit fa-1x"></i></a>&nbsp;

            </div> -->
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2"> Job Title
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <div class="input-group">
                    <select id="Desig_Id" class="form-control" formControlName="Desig_Id"
                        (change)="getReportingList();getJobDescriptionList($event.target.value)">
                        <option value="">Select Job Title</option>
                        <option *ngFor="let item of designationList;" value={{item.desig_Id}}>
                            {{item.desig_Name}}</option>
                    </select>
                    <span class="input-group-btn">
                        <button class="btn dark btn-outline input-group-btn-input-grp" type="button"
                            (click)="modalOpendesig =true">
                            <i class="fa fa-{{empJobDetailform.value.Desig_Id != '' ? 'edit' : 'plus'}}"></i>
                        </button>
                    </span>
                </div>
            </div>
            <label class="control-label col-md-2">Job Description
                <!-- <span class="required"> * </span> -->
            </label>
            <div class="col-md-3">
                <select id="JD_Id" class="form-control" formControlName="JD_Id">
                    <option value="">Select Job Description</option>
                    <option *ngFor="let item of JDList;" value={{item.jD_Id}}>
                        {{item.jD_Name}}</option>
                </select>

            </div>
            <div class="col-md-1">
             <!-- <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="openModel()"
                    *ngIf="title == 'Add'" title="Add Job Description"><i
                        class="fa fa-plus fa-1x"></i></a>&nbsp;

                <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="openModel()"
                    *ngIf="title == 'Edit'" title="Edit Job Description"><i
                        class="fa fa-edit fa-1x"></i></a>&nbsp;  -->

                <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="download()"
                    title="Download Job Description"><i class="fa fa-download fa-1x"></i></a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Employee Number
                <span class="required"> * </span>
            </label>
            <div class="col-md-2">
                <input type="text" class="form-control" formControlName="Emp_Code" id="Emp_Code"
                    autocomplete="off" (change)="checkEmpCode()" />
            </div>
            <div class="col-md-1">
                <div *ngIf="AutoGenButtonVisibal">
                    <button class="btn dark btn-outline input-group-btn-input-grp" type="button"
                        (click)="autoGenrateEmpoyeeCode()">
                        Auto Genrate
                    </button>
                </div>
            </div>
            <label class="control-label col-md-2">Role Start Date     <!-- prev name change Joining Date -->
                <span class="required" aria-required="true"> * </span>
            </label>
            <div class="col-md-3">
                <div class="input-group" id="flatpickropenJ" data-wrap="true">
                    <input type="text" class="form-control readonlyClass" id="JoiningDate" data-input [disabled]="isInputDisabled" />
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <!-- <label class="control-label col-md-2">Shift Time
                <span class="required" aria-required="true"> * </span>
            </label> -->
            <!-- <div class="col-md-3">
                <div class="input-group">
                    <select id="Dept_Id" class="form-control" formControlName="Shift_Id">
                        <option value="">Select Shift</option>
                        <option *ngFor="let item of shiftList;" value={{item.shift_Id}}>
                            {{item.shift_Name}} [{{item.shift_Start}} - {{item.shift_End}}]</option>
                    </select>
                    <span class="input-group-btn">
                        <button class="btn dark btn-outline input-group-btn-input-grp" type="button"
                            (click)="modalOpensift =true">
                            <i class="fa fa-{{empJobDetailform.value.Shift_Id != '' ? 'edit' : 'plus'}}"></i>
                        </button>
                    </span>
                </div>
            </div> -->
            <!-- <label class="control-label col-md-2">NI Number
            </label>
            <div class="col-md-3">
                <input type="text" id="NiNo" placeholder="" class="form-control" formControlName="NiNo" autocomplete="off" />
            </div> -->
            <!-- <label class="control-label col-md-2">Night Shift</label>
            <div class="md-checkbox-inline">
                <div class="md-checkbox ">
                    <input type="checkbox" id="nightShift" class="md-check" formControlName="nightShift">
                    <label for="nightShift">
                        <span></span>
                        <span class="check"></span>
                        <span class="box"></span> </label>
                </div>
            </div> -->
            <label class="control-label col-md-2">Shift Variable</label>
            <div class="md-checkbox-inline">
                <div class="md-checkbox ">
                    <input type="checkbox" id="shift_Variable" class="md-check" formControlName="shift_Variable">
                    <label for="shift_Variable">
                        <span></span>
                        <span class="check"></span>
                        <span class="box"></span> </label>
                </div>
            </div>
            <input type="hidden" id="NiNo" placeholder="" class="form-control" formControlName="NiNo"
                autocomplete="off" />
        </div>
    </div>
    <div class="row">
    <div class="form-group">
        <label class="control-label col-md-2">Shift Start<span class="required"> *
        </span></label>
        <div class="col-md-3">
        <div class="input-group" id="flatpickropenshiftstatetime" data-wrap="true">
            <input type="time" id="shift_Start" class="form-control readonlyClass"
                formControlName="shift_Start"  data-input>
            <span class="input-group-btn" data-toggle>
                <button class="btn default" type="button">
                    <i class="fa fa-clock-o"></i>
                </button>
            </span>
        </div>
        </div>

        <label class="control-label col-md-2">Shift End<span class="required"> *
        </span></label>
        <div class="col-md-3">
        <div class="input-group" id="flatpickropenshiftendtime" data-wrap="true">
            <input type="time" id="shift_End" class="form-control readonlyClass"
                formControlName="shift_End" data-input>
            <span class="input-group-btn" data-toggle>
                <button class="btn default" type="button">
                    <i class="fa fa-clock-o"></i>
                </button>
            </span>
        </div>
        </div>
    </div>
    </div>
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Holiday approver <span class="required"> * </span></label>
            <div class="col-md-3">
                <ng-select2 [data]="holidayempList" id="Reporting_Id" [width]="400" formControlName="Reporting_Id"
                    [placeholder]="'Select Your Employee'">
                </ng-select2>
            </div>
            <label class="control-label col-md-2">Line Manager</label>
            <div class="col-md-3">
                <ng-select2 [data]="linemanagerempList" id="Line_Manager_Id" [width]="400" formControlName="Line_Manager_Id"
                    [placeholder]="'Select Your Employee'">
                </ng-select2>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Work Email</label>
            <div class="col-md-3">
                <input type="text" id="Work_Email" class="form-control" formControlName="Work_Email" maxlength="100" />
            </div>
            <label class="control-label col-md-2">Work Phone</label>
            <div class="col-md-3">
                <input type="text" id="Work_Phone" class="form-control" formControlName="Work_Phone" maxlength="20"
                    autocomplete="off" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.key === "Backspace" ||  event.charCode == 40 
                || event.charCode == 32 || event.charCode == 41 || event.charCode == 43 || event.charCode == 45' />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Work Extension</label>
            <div class="col-md-3">
                <input type="text" id="Work_Extension" class="form-control" formControlName="Work_Extension"
                    maxlength="50"
                    onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"' />
            </div>

            <label class="control-label col-md-2">RAF</label>
            <div class="col-md-3">
                <select class="form-control" id="Vacancy_Id" formControlName="Vacancy_Id"
                >
                <option value="">Select RAF</option>
                <option *ngFor="let item of AllrafList;" value={{item.id}}>
                    {{item.displayName}}</option>
            </select>
            <!-- old code -->
                <!-- <select class="form-control" id="Vacancy_Id" formControlName="Vacancy_Id"
                    *ngIf="this.empJobDetailform.controls.Emp_Id.value == 0">
                    <option value="">Select RAF</option>
                    <option *ngFor="let item of rafList;" value={{item.id}}>
                        {{item.desig_Name}}</option>
                </select> -->

                <!-- <input type="text" class="form-control readonlyClass" id="vacancy_Name" formControlName="vacancy_Name"
                    readonly disabled *ngIf="this.empJobDetailform.controls.Emp_Id.value > 0" /> -->
            </div>
        </div>
    </div>

    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Deputy</label>
            <div class="col-md-3">
                <ng-select2 [data]="deputyList" id="companyid" [width]="400" formControlName="Deputy"
                    [placeholder]="'Select Deputy'">
                </ng-select2>
                <!-- <select class="form-control" formControlName="Deputy">
                    <option value="">Select Deputy</option>
                    <option *ngFor="let item of deputyList;" value={{item.emp_Id}}>
                        {{item.firstName}} {{item.lastName}}</option>
                </select> -->
            </div>

             <label class="control-label col-md-2">RAF Approver</label> <!-- prev name change RAF Approval -->
            <div class="col-md-3">
                <div class="md-checkbox-inline">
                    <div class="md-checkbox">
                        <input type="checkbox" id="RAF_Approval" class="md-check" formControlName="RAF_Approval">
                        <label for="RAF_Approval">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Tupe
            </label>
            <div class="col-md-3">
                <div class="md-radio-inline">
                    <div class="md-radio has-info">
                        <input type="radio" id="Tupe_yes" name="Tupe" value="1" formControlName="Tupe"
                            class="md-radiobtn">
                        <label for="Tupe_yes">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> Yes </label>
                    </div>
                    <div class="md-radio has-info">
                        <input type="radio" id="Tupe_no" name="Tupe" value="0" formControlName="Tupe"
                            class="md-radiobtn">
                        <label for="Tupe_no">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> No </label>
                    </div>

                </div>
            </div>
            <label class="control-label col-md-2">HOD</label>
            <div class="col-md-3">
                <div class="md-checkbox-inline">
                    <div class="md-checkbox">
                        <input type="checkbox" id="isHod" class="md-check" formControlName="IsHOD">
                        <label for="isHod">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Line Manager</label>
            <div class="col-md-3">
                <div class="md-checkbox-inline">
                    <div class="md-checkbox">
                        <input type="checkbox" id="Is_Line_Manager" class="md-check" formControlName="Is_Line_Manager">
                        <label for="Is_Line_Manager">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> </label>
                    </div>
                </div>
            </div>
        <label class="control-label col-md-2">Holiday Approver</label>
        <div class="col-md-3">
            <div class="md-checkbox-inline">
                <div class="md-checkbox">
                    <input type="checkbox" id="Is_Holiday_Approvel" class="md-check" formControlName="Is_Holiday_Approvel">
                    <label for="Is_Holiday_Approvel">
                        <span></span>
                        <span class="check"></span>
                        <span class="box"></span> </label>
                </div>
            </div>
        </div>
        </div>
    </div>

    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Contract Type
            </label>
            <div class="col-md-3">
                <div class="input-group">
                    <!-- <select id="contract_Id" class="form-control" formControlName="contract_Id">
                        <option value="">Select Contract</option>
                        <option *ngFor="let item of contractList;" value={{item.contact_Id}}>
                            {{item.contract_Name}}</option>
                    </select> -->
                    <select id="contract_Id" class="form-control" formControlName="contract_Id" >
                        <option value="">Select Contract</option>
                        <option *ngFor="let item of contractList;" value={{item.contract_Id}}>
                            {{item.contract_Name}}</option>
                    </select>
                    <span class="input-group-btn">
                        <button class="btn dark btn-outline input-group-btn-input-grp" type="button"
                            (click)="modalOpencontract =true">
                            <i class="fa fa-{{empJobDetailform.value.contract_Id != '' ? 'edit' : 'plus'}}"></i>
                        </button>
                    </span>
                </div>
            </div>
            <label class="control-label col-md-2">Contract End Date 
            </label>
            <div class="col-md-3">
                <div class="input-group" id="flatpickropenE" data-wrap="true">
                    <input type="text" class="form-control readonlyClass" id="Contract_End_Date" data-input  />
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group">

            <label class="control-label col-md-2">DBS Required</label>
            <div class="col-md-3">
                <div class="md-checkbox-inline">
                    <div class="md-checkbox">
                        <input type="checkbox" id="DBS_Required" class="md-check" formControlName="DBS_Required" (click)="dbsclick($event)">
                        <label for="DBS_Required">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> </label>
                    </div>
                </div>
            </div>


            <label class="control-label col-md-2" *ngIf="isDBDreq">DBS Expiry Date
                <span class="required" > * </span>
            </label>
            <div class="col-md-3" *ngIf="isDBDreq">
                <div class="input-group" id="flatpickropenDBS" data-wrap="true">
                    <input type="text" class="form-control readonlyClass" id="DBS_Expiry_Date" data-input  />
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
         
         
        </div>
    </div>


</form>




<div *ngIf="modalOpencontract" class="row">
    <div class="col-lg-12">
        <app-contract-form [pId]="empJobDetailform.value.contract_Id" (modalClose)="modalOpencontract = $event"
            (refreshPage)="companyWiseGetData(true);getReportingList()"></app-contract-form>
    </div>

</div>

<div *ngIf="modalOpen" class="row">
    <div class="col-lg-12">
        <app-zone-form [pId]="empJobDetailform.value.Zone_Id" (modalClose)="modalOpen = $event"
            (refreshPage)="companyWiseGetData(true);getReportingList()"></app-zone-form>
    </div>

</div>

<div *ngIf="modalOpendesig" class="row">
    <div class="col-lg-12">
        <app-designation-form [pId]="empJobDetailform.value.Desig_Id" (modalClose)="modalOpendesig = $event"
            (refreshPage)="companyWiseGetData(true);getReportingList()"></app-designation-form>
    </div>

</div>

<div *ngIf="modalOpendept" class="row">
    <div class="col-lg-12">
        <app-dept-form [pId]="empJobDetailform.value.Dept_Id" (modalClose)="modalOpendept = $event"
            (refreshPage)="companyWiseGetData(true);getReportingList()"></app-dept-form>
    </div>

</div>

<div *ngIf="modalOpensite" class="row">
    <div class="col-lg-12">
        <app-site-form [pId]="empJobDetailform.value.Site_Id" (modalClose)="modalOpensite = $event"
            (refreshPage)="companyWiseGetData(true);getReportingList()"></app-site-form>
    </div>

</div>

<div *ngIf="modalOpensift" class="row">
    <div class="col-lg-12">
        <app-sift-form [pId]="empJobDetailform.value.Shift_Id" (modalClose)="modalOpensift = $event"
            (refreshPage)="companyWiseGetData(true);getReportingList()"></app-sift-form>
    </div>

</div>
<div class="modal" id="modalJobDescription" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="modalJobDescriptionclose()"  aria-hidden="true"></button>
                <h4 class="modal-title">Job Description</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <iframe [src]='fileUrl' width="100%" height="600px"></iframe>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button"  class="btn dark btn-outline" (click)="modalJobDescriptionclose()">Close</button>
            </div>
        </div>
    </div>
</div>



