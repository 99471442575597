import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;


@Component({
  selector: 'app-employeeContact',
  templateUrl: './emp-contact.component.html'
})
export class EmployeeContactComponent implements OnInit {
  empContactform: FormGroup;
  countryList: Array<Select2OptionData>;
  editMode: boolean = false;
  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.editMode = false;
    this.empContactform = this.fb.group({
      id: ['0'],
      person_Id: ['0'],
      contact_Type: ['', Validators.required],
      country_Id: [null],
      contact_Value: ['', Validators.required],
      version_Id: ['0'],
      isDefault: false,
      isActive: true,
      isReview: [1],
      updatedBy: [Commonvariable.User_Id]
    });

    this.getCountryList();
  }


  getCountryList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "country_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Country, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.countryList = response["data"].map(x => ({
          id: x.id,
          text: x.country_Name + " (" + x.country_Code + ") "
        }));        
        if(this.editMode == false){
          this.empContactform.controls.country_Id.setValue(response["data"].filter(x => x.isDefault == 1)[0]["id"]); 
        }
      }
    })
  }




  empEdit(Id) {
    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.Person_Contact, Id).subscribe((response) => {
        if (response != null && response["data"] != "") {
          this.editMode = true;
          var editData = response["data"];
          this.getCountryList();
          var formData = this.empContactform;
          if (JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "User") {
            if (response["data"].length > 0) {
              if (response["data"][1] != null) {
                this.commonService.setEditData(formData, editData, 1);
              }
              else {
                this.commonService.setEditData(formData, editData);
              }
            }
          }
          else {
            this.commonService.setEditData(formData, editData);
          }



        }
      })
    }
  }


  validation() {
    var eForm = this.empContactform;
    //sForm.get("company_Id").setValue($("#company_Id").val()); 
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);
    eForm.get("isDefault").setValue(eForm.get("isDefault").value ? 1 : 0);
    if (!eForm.valid) {
      for (const key of Object.keys(eForm.controls)) {
        if (eForm.controls[key].invalid) {
          let fieldName = "";

          if (key == "contact_Type") {
            fieldName = "Contact Type"
          }
          else if (key == "contact_Value") {
            fieldName = "Contact Details"
          }
          this.toastr.info(fieldName + " is required !", "Employee");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {


      if ($("#contact_Type").val().length < 2) {
        this.toastr.info("Contact type must be at least 2 characters long !", "Employee");
        $("#contact_Type").focus();
        return false;
      }
      // if ($("#contact_Type").val().indexOf(" ") > -1) {
      //   this.toastr.info("Contact type not allow space !", "Employee");
      //   $("#contact_Type").focus();
      //   return false;
      // }

      if ($("#contact_Type").val() == "Phone No" || $("#contact_Type").val() == "Mobile No") {
        if (eForm.value.country_Id == null) {
          this.toastr.info("Country is required  !", "Candidate");
          $("#country_Id select").focus();
          return false;
        }
      }

      if ($("#contact_Type").val() == "Phone No" || $("#contact_Type").val() == "Mobile No") {
        if ($("#contact_Value").val().search(/[^0-9- +()]+/) > -1) {
          this.toastr.info("Enter valid value  !", "Employee");
          $("#contact_Value").focus();
          return false;
        }
        // if ($("#contact_Type").val() == "Mobile No" && $("#contact_Value").val().length < 9) {
        //   this.toastr.info("Mobile no must be at least 9 digit long !", "Employee");
        //   $("#contact_Value").focus();
        //   return false;
        // }
        // if ($("#contact_Type").val() == "Phone No" && ($("#contact_Value").val().length < 9)) {
        //   this.toastr.info("Phone no must be at least 9 digit long !", "Employee");
        //   $("#contact_Value").focus();
        //   return false;
        // }
      }
      if ($("#contact_Type").val() == "Email") {
        if ($("#contact_Value").val().search(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/) != 0) {
          this.toastr.info("Enter valid email address !", "Employee");
          $("#contact_Value").focus();
          return false;
        }
      }
      if ($("#contact_Value").val().length < 2) {
        this.toastr.info("Contact Details must be at least 2 characters long !", "Employee");
        $("#contact_Value").focus();
        return false;
      }
    }


    return true;
  }




}
