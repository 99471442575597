import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;

@Component({
   selector: 'app-empReference',
   templateUrl: './employee-reference.component.html',
})
export class EmpReferenceComponent implements OnInit, AfterViewInit {
   empform: FormGroup;
   items: FormArray;
   relationList: any;
   countryList: Array<Select2OptionData>;
   countryId: any = 0;
   empReferenceNR = new FormControl(false);
   empReferenceRequired = new FormControl(false);
   public isrequiredclass: boolean[] = [];
   files: any[] = [];
   constructor(
      private toastr: ToastrService,
      private commonApiService: CommonApiService,
      private commonService: CommonService,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {
      

      this.empform = this.fb.group({
         emp_ref: this.fb.array([this.createItem(null)]),
      });
      this.addItem(null);
      
     // this.getCountryList();
     // this.commonService.flatpickr("flatpickropendr0", 0);
      //this.getRelationshipList();
   }

   getCountryList() {

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "country_Name"
      }
      this.commonApiService.GetByPaginated(Commonvariable.Country, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            this.countryList = response["data"].map(x => ({
               id: x.id,
               text: x.country_Name + " (" + x.country_Code + ") "
            }));

            this.countryId = response["data"].filter(x => x.isDefault == 1)[0]["id"];
            this.items = this.empform.get('emp_ref') as FormArray;
            this.items.clear();
            this.addItem(null);
         }
      })
   }

   getRelationshipList() {
      this.commonApiService.List(Commonvariable.Relationship).subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.relationList = result["data"].sort((a, b) => a.name.localeCompare(b.name));
         }
      });
   }

   createItem(data) {
     // this.commonService.flatpickr("flatpickropendr", 0)
      return this.fb.group({
         Id: data != null ? data.id : '0',
         Emp_Id: data != null ? data.emp_Id : '0',
         DateSent:data !=null ? data.dateSent:'',
         DateOfRec:data !=null ? data.dateofRec:'',
         Notes:data !=null ? data.notes:'',
         files: data !=null ? data.files:'',
         Required:data !=null ? data.required : 'no',
         // Person_Id: data != null ? data.person_Id : '0',
         // Ref_Name: data != null ? data.ref_Name : '',
         // country_Id: data != null ? data.country_Id : this.countryId != 0 ? this.countryId : '',
         // Ref_ContactNo: data != null ? data.ref_ContactNo : '',
         // Ref_Email: data != null ? data.ref_Email : null,
         // Relationship: data != null ? data.relationship : null,
         // isReview: JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "User" ? 0 : 1,
         isActive: data != null ? data.isActive == 1 ? true : false : true,
         AddedBy: Commonvariable.User_Id,
         
      });
     
   }

   addItem(data) {
     // if (this.validation()) {
     // this.commonService.flatpickr("flatpickropendr", 0)
         this.items = this.empform.get('emp_ref') as FormArray;
         if(this.items.controls.length < 2){
         this.items.push(this.createItem(data));
         setTimeout(() => {
            this.items = this.empform.get('emp_ref') as FormArray;
            var id = this.items.length - 1;
            this.commonService.flatpickr("flatpickropenEmpCEnD", id)
            this.commonService.flatpickr("flatpickropenEmpCStD", id)
            // this.commonService.flatpickr("flatpickropenEmpCEnD", id)
         }, 5);
      }
      
     // }
   }

   ngAfterViewInit() {
      // Initialize flatpickr here
      // this.items = this.empform.get('emp_ref') as FormArray;
      // var id = this.items.length - 1;
      // this.commonService.flatpickr("flatpickropenEmpCEnD", id);
      // this.commonService.flatpickr("flatpickropenEmpCStD", id);
    }


   edit(data) {
      if (data != null) {
         if (data.length > 0) {
            this.items = this.empform.get('emp_ref') as FormArray;
            this.items.clear()
            data.forEach(x => {
               this.items.push(this.createItem(x));
            });
         }
      }
   }


   removeItem(index) {
      this.items = this.empform.get('emp_ref') as FormArray;
      this.items.removeAt(index);
   }

   empUpsert() {
      var empRefData = this.empform.get('emp_ref').value;
      // if (empRefData[0].DateOfRec != "") {
      //    return true;
      // }
      if (empRefData.length > 0) {
         return true;
      }
      return false;
   }

   validation() {
      var empRefData = this.empform.get('emp_ref').value;
      var tempArray = [];
      for (let index = 0; index < empRefData.length; index++) {
         var checkboxId = "empReferenceRequired" + index;
         var checkbox = document.getElementById(checkboxId) as HTMLInputElement;
   
         if (checkbox.checked)
          {
            empRefData[index].Required = "yes";

          }
          else{
            empRefData[index].Required = "no";
          }
          let DateSent = empRefData.dateSent != null ? empRefData.dateSent.split("T")[0].split("-") : "";
         let DateOfRec = empRefData.dateOfRec != null ? empRefData.dateOfRec.split("T")[0].split("-") : "";
         if (DateSent != "") {
            $("#DateSent"+[index]).val(DateSent[2] + "-" + DateSent[1] + "-" + DateSent[0]);
          }
         if (DateOfRec != "") {
           $("#DateOfRec"+[index]).val(DateOfRec[2] + "-" + DateOfRec[1] + "-" + DateOfRec[0]);
         }

         if (this.isrequiredclass[index] === true) {

            if (empRefData[index].DateOfRec == "") {
               this.toastr.info("Date Of Rec is required !", "Employee");
               $("#DateOfRec" + index).focus();
               return false;
            }
            if (empRefData[index].DateSent == "") {
               this.toastr.info("Date Sent is required !", "Employee");
               $("#DateSent" + index).focus();
               return false;
            }
            if ($("#Notes" + index).val().length > 200) {
               this.toastr.info("Comment  must be at least 200 characters long !", "Employee");
               $("#Notes" + index).focus();
               return false;
            }
            // if (empRefData[index].Ref_Name != "") {
            //    if ($("#Ref_Name" + index).val().search(/^[a-zA-Z\s]+$/) != 0) {
            //       this.toastr.info("Please enter valid reference name!", "Employee");
            //       $("#Ref_Name" + index).focus();
            //       return false;
            //    }
            //    if ($("#Ref_Name" + index).val().search(/^[^-\s][a-zA-Z0-9_\s-]+$/) != 0) {
            //       this.toastr.info("Reference name allow only alphabet!", "Employee");
            //       $("#Ref_Name" + index).focus();
            //       return false;
            //    }
            // }
            // if (empRefData[index].Ref_Email != "" && empRefData[index].Ref_Email != null) {
            //    if ($("#Ref_Email" + index).val().search(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/) != 0) {
            //       this.toastr.info("Please enter valid reference email!", "Employee");
            //       $("#Ref_Email" + index).focus();
            //       return false;
            //    }
            // }

            // if (empRefData[index].country_Id == "" || empRefData[index].country_Id == null) {
            //    this.toastr.info("Country is required !", "Company");
            //    $("#country_Id" + index + " select").focus();
            //    return false;
            // }


            // if (empRefData[index].files == "") {
            //    this.toastr.info("files  is required!", "Employee");
            //    $("#files" + index).focus();
            //    return false;
            // }
            if (empRefData[index].files == "" || empRefData[index].files == undefined) {
               this.toastr.info("File is required !", "Employee");
               $("#files" + index).focus();
               return false;
            }
            // if ($("#Ref_ContactNo" + index).val().length < 9) {
            //    this.toastr.info("Reference number must be at least 9 digit long !", "Employee");
            //    $("#Ref_ContactNo" + index).focus();
            //    return false;
            // }
            // if (empRefData[index].Relationship == "" || empRefData[index].Relationship == null) {
            //    this.toastr.info("Reference relationship is required !", "Employee");
            //    $("#Ref_Relationship" + index).focus();
            //    return false;
            // }


            // if (tempArray.length > 0) {
            //    if (tempArray.filter(x => x == String(empRefData[index].Ref_ContactNo).trim()).length > 0) {
            //       this.toastr.info("This value already entered !", "Employee");
            //       $("#Ref_ContactNo" + index).focus();
            //       return false
            //    }
            //    if (tempArray.filter(x => x == String(empRefData[index].Ref_Email).trim()).length > 0) {
            //       this.toastr.info("This value already entered !", "Employee");
            //       $("#Ref_Email" + index).focus();
            //       return false
            //    }
            // }

            // tempArray.push(String(empRefData[index].Ref_ContactNo).trim())
            // tempArray.push(String(empRefData[index].Ref_Email).trim() != "" ? String(empRefData[index].Ref_Email).trim() : 0)
            // if (empRefData[index].Relationship != "") {
            //    if ($("#Ref_Relationship" + index).val().search(/^[a-zA-Z]+$/) != 0) {
            //       this.toastr.info("Please enter valid relationship name  !", "Employee");
            //       $("#Ref_Relationship" + index).focus();
            //       return false;
            //    }
            // }
            // if ($("#Ref_Relationship" + index).val().length < 2) {
            //    this.toastr.info("Reference relationship must be at least 2 characters long !", "Employee");
            //    $("#Ref_Relationship" + index).focus();
            //    return false;
            // }
            // if ($("#Ref_Relationship" + index).val().indexOf(" ") > -1) {
            //    this.toastr.info("Reference relationship not allow space !", "Employee");
            //    $("#Ref_Relationship" + index).focus();
            //    return false;
            // }
         }
         empRefData[index].isActive = empRefData[index].isActive ? 1 : 0
      }

      return true;
   }

   addRelationship(event) {
      if (event.target.value != "") {
         var data = {
            "Id": 0,
            "Name": event.target.value,
            "isActive": 1,
            "AddedBy": Commonvariable.User_Id
         }
         this.commonApiService.Upsert(Commonvariable.Relationship, data, 0).subscribe((result) => {
            this.getRelationshipList();
         });
      }
   }

   fileChange(event, id) {

      // if (event.target.files.item(0).name.split('.')[1] == "pdf" || event.target.files.item(0).name.split('.')[1] == "doc"
      //    || event.target.files.item(0).name.split('.')[1] == "docx" || event.target.files.item(0).name.split('.')[1] == "xlsx") {
         var empData = this.empform.get('emp_ref').value;
         empData[id].files = event.target.files[0];

         this.items = this.empform.get('emp_ref') as FormArray;
         this.items.controls[id].setValue(empData[id]);

         this.files.push(event.target.files[0]);
      // }
      // else {
      //    this.toastr.info("Allow only pdf,doc,docx,xlsx file format for upload !", "Employee");
      //    $("#remove" + id).click();
      // }

   }
   removeDoc(index) {
      var empData = this.empform.get('emp_ref').value;
      empData[index].files = "";
      this.files.reduce(index);
   }
   chekboxclickyes(event,index: number) {
      var empData = this.empform.get('emp_ref').value;
      var checkboxclick = event.target;
      if(checkboxclick.checked){
         this.isrequiredclass[index]= true;
         this.empReferenceRequired[index]=true;
         var checkboxId = "empReferencenotRequired" + index;
         var checkbox = document.getElementById(checkboxId) as HTMLInputElement;
         checkbox.checked = false;
         empData[index].Required = "yes";

      }
      else{
         this.isrequiredclass[index]= false;
         var checkboxId = "empReferencenotRequired" + index;
         var checkbox = document.getElementById(checkboxId) as HTMLInputElement;
         checkbox.checked = true;

      }
    

    }
    chekboxclickno(event,index: number) {
      var empData = this.empform.get('emp_ref').value;
      this.isrequiredclass[index]= false;
      var checkboxId = "empReferenceRequired" + index;
      var checkbox = document.getElementById(checkboxId) as HTMLInputElement;
      checkbox.checked = false;
      empData[index].Required = "no";
    }

}


