<style>
    div>hr,
    p {
        margin: 0 0 !important;
        border-top: 1px solid black;
    }
</style>
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Contract List
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->

        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a (click)="id=0;modalOpen =true" class="btn btn-transparent blue  btn-sm">Add
                            Contract</a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" placeholder="Type to filter ..." id="filterList" (input)="getContractList()">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblZone">
                            <thead class="flip-content">
                                <tr>

                                    <th class="text-center"> Company </th>
                                    <!-- <th class="text-center" style="width: 15%;">Contract Code</th> -->
                                    <th class="text-center"> Contract Name </th>
                                    <th style="width: 11%;" class="text-center"> Contract Hours Weekly </th>
                                    <!-- <th class="text-center" style="width: 11%;">Contract Weekly</th> -->
                                    <th class="text-center" style="width: 11%;">Length of Contract</th>
                                    <th class="text-center" style="width: 11%;">Contract Type</th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of contractlist| paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">
                                <tr>

                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(item.contract_Id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.contract_Id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <!-- <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                            data-placement="bottom" title="{{item.company.company_Name}}">
                                            {{item.company.company_Name}}</div> -->
                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip" data-placement="bottom" title="{{item.company_Name}}">
                                            {{item.company_Name}}</div>
                                    </td>
                                    <!-- <td>{{item.contract_Code}}</td> -->
                                    <td>{{item.contract_Name}}</td>
                                    <td class="text-center">{{item.contract_HoursDaily}}</td>
                                    <!-- <td class="text-center">{{item.contract_Week}}</td> -->
                                    <td class="text-center">{{item.contract_Days}}</td>
                                    <td>{{item.contract_Type}}</td>
                                    <td class="text-center">
                                        <a *ngIf="item.isActive" (click)="id=item.contract_Id;modalOpen =true"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Contract"></i></a>
                                        <a *ngIf="!item.isActive" (click)="contractDelete(item.contract_Id)">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Delete Contract"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="contractlist != null">
                        <pagination-controls (pageChange)="getContractList($event)" autoHide="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->




<div *ngIf="modalOpen">
    <app-contract-form [pId]="id" (modalClose)="modalOpen = $event" (refreshPage)="getContractList($event)"></app-contract-form>
</div>