import { Component, OnInit, Inject, NgZone, PLATFORM_ID, ChangeDetectorRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Router } from '@angular/router';
import { debug } from 'util';
//import { EChartOption } from 'echarts';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
//import CanvasJS from 'src/app/_files/canvasjs.min.js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  //chartOption: any = {}
  signUpCount: number = 0;
  reviewRequestCount: number = 0;
  userCreationCount: number = 0;
  candidateStatusArray: [];
  vacancyStatusArray: [];
  onboardingStatusArray: [];
  //empList: any[];
  totalRecord: number;
  empList: any[] = [];
  filterEmp: any;
  p: number = 1;
  //totalRecord = 0;
  filterAdvance: boolean = false;
  userRole = "";
  personId = 0;
  advancedFilterData: any;
  SummaryId: number = 0;
  modelpop: boolean = false;
  sortDirection: { [key: string]: string } = {};
  currentSort: { column: string, direction: string } = { column: 'emp_Code', direction: 'asc' };
  advancedSearch: any;
  currentUserRole: boolean = false;
  ismanager: boolean = false;



  constructor(private router: Router,
    private commonService: CommonService,
    private commonApiService: CommonApiService, private ngZone: NgZone,private spinner: NgxSpinnerService, private changeDetectorRef: ChangeDetectorRef) { }

  lineChartData: Array<any> = [
    {
      label: 'Candidate Status',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [],
    },
  ];
  lineChartLabels: Array<any> = [];



  lineChartData2: Array<any> = [
    {
      label: 'Candidate Status',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [],
    },
  ];
  lineChartLabels2: Array<any> = [];

  lineChartData3: Array<any> = [
    {
      label: 'Candidate Status',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [],


    },
  ];

  lineChartLabels3: Array<any> = [];

  lineChartLegend = true;
  lineChartType = 'pie';


  lineChartLegend2 = true;
  lineChartType2 = 'pie';

  lineChartLegend3 = true;
  lineChartType3 = 'pie';


  public options: any = {
    legend: { position: 'bottom' }
  }

  public pieChartColor: any = [
    {
      backgroundColor: ['#5A9BD5',
        '#ED7D31',
        '#A5A19E',
        '#FFC000',
        '#4070C2',
        '#235C93',
        '#9F4812',
        '#636363',
        '#70AD46'
      ]
    }
  ]


  ngOnInit() {
    
    this.currentUserRole =JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "Admin" ? true : false || JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "HR Manager" ? true : false|| JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "HR Executive" ? true : false
    this.ismanager =JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "Manager";

    //     if(this.currentUserRole == true){
      
//   this.getEmployeeList(1,'emp_Code','asc');
// }

setTimeout(() => {
  this.getEmployeeList();
}, 100);

    //$('#example,#example2').DataTable();
    var candidateStatusArray = [];
    var onboardingStatusArray = [];
    var vacancyStatusArray = [];

    this.commonService.myData.subscribe((data) => {
      this.fillData();
    });
    
    
    //this.fillData();
  }

  fillData() {
    this.commonApiService.List(Commonvariable.Dashboard, 'GetDashboardData').subscribe((response) => {
      if (response["data"] != "") {
        this.signUpCount = response["data"].signUpCount;
        this.userCreationCount = response["data"].userCreationCount;
        this.reviewRequestCount = response["data"].reviewRequestCount;
        this.lineChartData = [
          {
            label: 'Candidate Status',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: '#fff',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: response["data"]["candidateStatus"].map(x => x['y']),
          },
        ];
        this.lineChartLabels = response["data"]["candidateStatus"].map(x => x['name'])


        this.lineChartData2 = [
          {
            label: 'Candidate Status',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: '#fff',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: response["data"]["vacancyStatus"].map(x => x['y']),
          },
        ];
        this.lineChartLabels2 = response["data"]["vacancyStatus"].map(x => x['name'])

        this.lineChartData3 = [
          {
            label: 'Candidate Status',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: '#fff',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: response["data"]["onboardingStatus"].map(x => x['y']),

          },

        ];

        this.lineChartLabels3 = response["data"]["onboardingStatus"].map(x => x['name'])


        //this.onboardingStatusArray = response["data"]["onboardingStatus"];
        //this.candidateStatusArray = response["data"]["candidateStatus"];
        //this.vacancyStatusArray = response["data"]["vacancyStatus"];


      }
    })
  }



  private requestCounter = 0;
  itemsPerPageOptions: number[] = [10, 20, 50,100];
  itemsPerPage: number = 10;

  async getEmployeeList(PageIndex = 1, sortColumn: string = 'emp_Code', sortDirection: string = 'asc'): Promise<void> {
    const currentCounter = ++this.requestCounter;

    try {
      if (this.filterAdvance) {
        this.advanceFilter(null, PageIndex);
      } else {
        this.totalRecord = 0;
        this.empList = [];
        this.filterEmp = [];
        this.p = PageIndex;

        let requestData;

        if (this.advancedFilterData && this.advancedFilterData.length > 0) {
          requestData = {
            searchBy: this.advancedFilterData,
            PageIndex: PageIndex - 1,
            CommonSearch: $("#filterList").val().trim(),
            PageSize: this.itemsPerPage,
            OrderBy: sortColumn + " "+ sortDirection ,
            //SortDirection: sortDirection
          };
        } else {
          requestData = {
            searchBy: [],
            PageIndex: PageIndex - 1,
            CommonSearch: $("#filterList").val().trim(),
            PageSize: this.itemsPerPage,
            OrderBy: sortColumn +" "+ sortDirection,
            //SortDirection: sortDirection
          };
        }

        this.empList = [];
        this.totalRecord = 0;

        const result = await this.commonApiService.GetByPaginated(Commonvariable.Dashboard, requestData, "GetPaginated").toPromise();

        //if (currentCounter === this.requestCounter) {
        if (requestData.CommonSearch == $("#filterList").val().trim()) {
          // Check if this is the last request
          if (result != null && result["status"] === 200) {
            this.empList = [];
            if (result["data"] !== "" && result["data"]["list"] !== undefined) {
              this.totalRecord = result["data"]["recordCount"];
              this.empList = result["data"]["list"];
            }
          }

          setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
          }, 500);
        }
        else {
          this.spinner.hide();
        }
      }
    } catch (error) {

    }
  }

 
  sortColumn(column: string): void {
    if (this.currentSort.column === column) {
      // toggle direction if it's the same column
      this.currentSort.direction = this.currentSort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      // set the new column and reset direction
      this.currentSort = { column, direction: 'asc' };
    }
  
    // call your data retrieval method with the new sort parameters
    this.getEmployeeList(this.p, this.currentSort.column, this.currentSort.direction);
  }
  
  // method to get the appropriate icon class based on the current sort
  getSortIcon(column: string): string {
    if (this.currentSort.column === column) {
      return this.currentSort.direction === 'asc' ? 'fa-sort-up' : 'fa-sort-down';
    } else {
      return 'fa-sort';
    }
  }
  
  onItemsPerPageChange() {
    this.p = 1;
    this.getEmployeeList();
  }
  

  isColumnSorted(column: string, direction: string): boolean {
    const table = $('#tblemp').DataTable();
    const order = table.order()[0];
    return order && order[0] === table.column(`:${column}`).index() && order[1] === direction;
  }
  

  advanceFilterEmp() {

    var data = {
      "SearchBy": this.advancedSearch.search()
    }

    if (data.SearchBy) {

      this.advancedFilterData = data.SearchBy;
      if (data.SearchBy[0]["FieldName"] != "" && data.SearchBy[0]["FieldValue"] != "") {
        this.empList = []
        this.totalRecord = 0

        this.ngZone.run(() => {
          this.commonApiService.getPaginated(Commonvariable.Dashboard, null, null, null, null, 2, data).subscribe((result) => {
            if (result != null && result["data"] != "") {
              this.empList = result["data"]["list"];
              this.totalRecord = result["data"]["recordCount"];
              this.p = 1
            }
          
            $("#filterModal").modal("hide");
          })

        })
      }
    }


  }
  

  advanceFilter(sData = null, pageindex = 1) {
    var data;
    if (sData != null) {
      data = sData;
    }
    else {
      this.p = pageindex;
      data = {
        "SearchBy": this.advancedSearch.search() == false ? [] : this.advancedSearch.search(),
        "PageIndex": pageindex - 1,
        "PageSize": 10,
      }
      this.filterAdvance = true;
    }
    if (data.SearchBy.length > 0) {

      this.ngZone.run(() => {

        if (data.SearchBy[0]["FieldName"] != "" && data.SearchBy[0]["FieldValue"] != "") {
          this.empList = []


          this.commonApiService.empDetailList(Commonvariable.Dashboard, data).subscribe((result) => {
            if (result != null && result["data"] != "") {
              console.log("result",result)
              this.empList = result["data"];
              this.totalRecord = this.empList.length;
              //this.empList = result["data"].filter(x => x.isActive != 0);
              $("body").css({ overflow: 'inherit' });
              $('body').css('position', 'unset');
              $("#filterModal").modal("hide");
              //this.advancedSearch.ngOnInit();
            }
          })

        }
        else {
          this.empList = this.filterEmp;
          this.filterAdvance = false;
          $("body").css({ overflow: 'inherit' });
          $('body').css('position', 'unset');
          $("#filterModal").modal("hide");
        }
      })
    }
  }

  
  editRecord(empId) {

    if (this.userRole == "User") {
      this.router.navigateByUrl('userviewsummary')
    }
    else {
      this.router.navigate(['employeeProfile'], { queryParams: { editId: empId } })
    }

  }

}
