import { Component, OnInit } from '@angular/core';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
import { retry } from 'rxjs/operators';
declare var $: any;

@Component({
   selector: 'app-candidateDocument',
   templateUrl: './candidate-document.component.html',
})
export class CandidatesDocumentComponent implements OnInit {

   candidateform: FormGroup;
   items: FormArray;
   documentList: any;
   documentName: "";


   docFiles: string[] = [];


   constructor(
      private toastr: ToastrService,
      private commonApiService: CommonApiService,
      private router: Router,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {
      this.candidateform = this.fb.group({
         candidate_document: this.fb.array([this.createItem(null)]),
      });
      this.getDocument();
   }


   getDocument() {
      this.commonApiService.List(Commonvariable.Document).subscribe((response) => {
         if (response != null) {
            this.documentList = response["data"];
         }
      })
   }

   createItem(data) {
      return this.fb.group({
         Id: '0',
         Onboarding_Id: '1',
         Doc_Id: ['', Validators.required],
         files: '',
         Doc_Name: ['', Validators.required],
         Notes: null,
         isActive: true,
         addedBy: Commonvariable.User_Id,
      });
   }

   addItem(data = null) {
      this.items = this.candidateform.get('candidate_document') as FormArray;
      this.items.push(this.createItem(data));
   }

   removeItem(index) {
      this.items = this.candidateform.get('candidate_document') as FormArray;
      this.items.removeAt(index);
      this.docFiles = this.items.value.map(x => x.files)
   }

   empUpsert() {
      var Data = this.candidateform.get('candidate_document').value;
      if (Data[0].Doc_Id == "") {
         return false;
      }
      return true;
   }

   validation() {

      if (this.docFiles.length === 0 && !$("#onBoardingnotRequired").prop("checked")) {
         this.toastr.info("Document is required !", "Candidate");
         return false;
      }
      var Data = this.candidateform.get('candidate_document').value;
      
      if (!$("#onBoardingnotRequired").prop("checked") ) {
         for (let index = 0; index < Data.length; index++) {

            // if (Data[index].Doc_Id == "") {
            //    this.toastr.info("Document is required !", "Candidate");
            //    $("#Doc_Id" + index).focus();
            //    return false;
            // }

            // if (Data[index].Doc_Name == "") {
            //    this.toastr.info("Document name is required !", "Candidate");
            //    $("#Doc_Name" + index).focus();
            //    return false;
            // }

            if ($("#Doc_Name" + index).val().search(/[^a-zA-Z0-9]+/) > -1) {
               this.toastr.info("Document name only alphabets and numeric allow !", "Candidate");
               $("#Doc_Name" + index).focus();
                  return false;
            }

            if (this.docFiles[index] == "") {
               this.toastr.info("File is required !", "Candidate");
               return false;
            }
            Data[index].isActive = Data[index].isActive ? 1 : 0
         }
      }
   


      return true;
   }

   // fileChange(event) {
   //    this.documentName ="";
   //    for (var i = 0; i < event.target.files.length; i++) {
   //       this.documentName += this.documentName == "" ? event.target.files[i].name : ", " + event.target.files[i].name;
   //       this.docFiles.push(event.target.files[i]);
   //    }

   // }

   fileChange(event, id) {

      // if (event.target.files.item(0).name.split('.')[1] == "pdf" || event.target.files.item(0).name.split('.')[1] == "doc"
      //    || event.target.files.item(0).name.split('.')[1] == "docx" || event.target.files.item(0).name.split('.')[1] == "xlsx") {

         for (var i = 0; i < event.target.files.length; i++) {
            this.docFiles.push(event.target.files[i]);
         }

         var Data = this.candidateform.get('candidate_document').value;
         Data[id].files = event.target.files[0];
      // }
      // else {
      //    this.toastr.info("Allow only pdf,doc,docx,xlsx file format for upload !", "Candidate");
      //    $("#remove" + id).click();
      // }
   }


   removeDoc(index) {
      var Data = this.candidateform.get('candidate_document').value;
      Data[index].files = "";
      this.docFiles = Data.map(x => x.files)
   }





}


