import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { CommonService } from 'src/app/services/common.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;

@Component({
  selector: 'app-usercreationform',
  templateUrl: './usercreationform.component.html'
})
export class UserCreationComponent implements OnInit {
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;

  hrForm: FormGroup;
  hodForm: FormGroup;
  softwareDepartmentForm: FormGroup;
  itDepartmentForm: FormGroup;
  itHeadForm: FormGroup;

  onboarding_Id: any;
  userCreationId: any;



  constructor(
    private commonApiService: CommonApiService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder) { }

  ngOnInit() {

    this.hrForm = this.fb.group({
      firstName: [''],
      middleName: [''],
      lastName: [''],
      startDate: [''],
      department: [''],
      designation: [''],
      hrSignature: [''],
      date: [''],
      empId: [''],
    })

    this.hodForm = this.fb.group({
      aurthorisedHOD: [''],
      date: [''],
      isruislip: false,
      isHomeBased: false,
      rdpAccess: false,
      eCTD: false,
      emailAccessRequirementYes: false,
      emailAccessRequirementNo: false,
      internetAccessRequirementYes: false,
      internetAccessRequirementNo: false,
      otherRequest: [''],
      specialOrders: false,
      financeKPI: false,
      pLPI: false,
      customerService: false,
      financeEnterprise: false,
      wMS: false,
      annualPlanner: false,
      bNSSOP: false,
      tPS: false,
      purchaseOrder: false,
      requestPuschaseOrder: false,
      MCS: false,
      priceMatrix: false,
      rSMManagement: false,
      dMS: false,
      coloramaLogistic: false,
      projectManagement: false,
      pMS: false,
      roomBookingSystem: false,
      warehouseStockControl: false,
      pOD: false,
      bNSLoyalty: false,
      purchaseOrderindia: false,
      hRRecruitment: false,
      payrollSystemIndia: false,
      reportServer: false,
      meetingManagement: false,
      accessRightsManager: false,
      accessRightsNormal: false,
      siteSpe: false,
      siteSPeL: false,
      siteWHOL: false,
      sitelax: false,
      siteconl: false,
      sitewhog: false,
      sitecong: false,
      otherRequest1: [''],
      regulatoryTask: false,
      regulatoryLog: false,
      productAdd: false,
      productEMVOReport: false,
      labelSize: false,
      cartonSize: false,
      manufacturer: false,
      pPMReceive: false,
      packingList: false,
      batchChecker: false,
      PPMReceiveGoodsIN: false,
      batchAllocationSupplier: false,
      modifyBatchAllocation: false,
      batchAllocationShiftChangeAuditLog: false,
      bNSbatchAdd: false,
      printlabel: false,
      printLeaflet: false, printBraille: false, Printer: false, LeafletFolding: false, DoubleCheck: false, ProductionController: false, AssemblyRoom: false, RoomLog: false,
      DestroyLabel: false, BNSBatchSEQ: false, UnlockUser: false, PPMReceiveStockCon: false, PPMAudit: false, PPMAuditHistory: false, ProductionChecking: false, ReleaseLogSheett: false,
      PreQP: false, ReleasedLabels: false, SuppInfo: false, Recovery: false, BatchHistory: false, IPCViewPrint: false, ReleasePackList: false, UserReg: false, CheckPerformance: false,
      Users: false, MISC: false, EcternalBatchScanning: false, FMDPrintLabel: false, Decommissioning: false, EMVOStolenandIntendedfordestruction: false, EMVOReport: false,
      mcsSupplier: false, mcsManufacturer: false, mcsProductLinking: false, mcsRMCode: false, mcsSampling: false, mcsRMTesting: false, mcsReceiveOrder: false, mcsPCL: false, mcsReTest: false,
      mcsAddRMCode: false, mcsOptions: false, mcsContainer: false, mcsCOA: false, mcsQCPrints: false, mcsWaterProtocol: false, mcsWaterTest: false, Signature: [''], mcsdate: ['']
    })

    this.softwareDepartmentForm = this.fb.group({
      SpecialOrders: false, FinanceKPI: false, PLPI: false, CustomerService: false, FinanceEnterprise: false, WMS: false, AnnualPlanner: false, BNSSOPCustomerOrder: false, TPS: false,
      PurchaseOrder: false, RequestPuschaseOrder: false, MCS: false, PriceMatrix: false, RSMManagement: false, DMS: false, ColoramaLogistic: false, ProjectManagement: false,
      PMS: false, RoomBookingSystem: false, WarehouseStockControl: false, POD: false, BNSLoyalty: false, PurchaseOrderindia: false, HRRecruitment: false, PayrollSystemIndia: false,
      ReportServer: false, MeetingManagement: false, Manager: false, Normal: false, SPE: false, SPEL: false, WHOL: false, LAX: false, CONL: false, WHOG: false, CONG: false,
      notes: [''], applicationID: [''], RegulatoryTask: false, RegulatoryLog: false, ProductAdd: false, ProductEMVOReport: false, LabelSize: false, CartonSize: false,
      Manufacturer: [''], PPMReceiveChecker: [''], PackingList: [''], BatchChecker: [''], PPMReceiveGoodsIN: false, BatchAllocationSupplier: false, ModifyBatchAllocation: false,
      BatchAllocationShiftChangeAuditLog: false, BNSBatchAdd: false, PrintLabel: false, PrintLeaflet: false, PrintBraille: false, Printer: false, LeafletFolding: false,
      DoubleCheck: false, ProductionController: false, AssemblyRoom: false, RoomLog: false, DestroyLabel: false, BNSBatchSEQ: false, UnlockUser: false, PPMReceiveStockCon: false,
      PPMAudit: false, PPMAuditHistory: false, ProductionChecking: false, ReleaseLogSheett: false, PreQP: false, ReleasedLabels: false, SuppInfo: false, Recovery: false,
      BatchHistory: false, IPCViewPrint: false, ReleasePackList: false, UserReg: false, CheckPerformance: false, Users: false, MISC: false, EcternalBatchScanningSummary: false,
      FMDPrintLabel: false, Decommissioning: false, EMVOStolenandIntendedfordestruction: false, EMVOReportFMD: false,
      mcsSupplier: false, mcsManufacturer: false, mcsProductLinking: false, mcsRMCode: false, mmcsSampling: false, mcsRMTesting: false, mcsReceiveOrder: false, mcsPCL: false, mcsReTest: false,
      mcsAddRMCode: false, mcsOptions: false, mcsContainer: false, mcsCOA: false, mcsQCPrints: false, mcsWaterProtocol: false, mcsWaterTest: false, Signature: [''], date: ['']

    })

    this.itDepartmentForm = this.fb.group({
      AssignedUserID: [''],
      GDrive: false, LimitedInternetAccess: false, Ruislip: false, SDrive: false, FullInternetAccess: false, Perivale: false, FDrive: false, NoInternetAccess: false, bnshealthcare: false,
      bnsgroup: false, thamelabs: false, colorpharm: false, bnscolorama: false, bnsthamelabs: false, waymadelaxmico: false, None: false, Signature: [''], date: ['']
    })

    this.itHeadForm = this.fb.group({
      Remarks: [''],
      Signature: [''],
      date: ['']
    })

  }

  fillHRData(id, usercreationid) {
    this.onboarding_Id = 0
    this.ngOnInit();
    if (id > 0) {
      this.onboarding_Id = id
      this.userCreationId = usercreationid


      this.commonApiService.getById(Commonvariable.UserCreation_Approval, id, "GetByOnboardingId").subscribe((result) => {
        if (result["data"] != "") {

          var jsonForms = result["data"]["json_Data"]

          for (const key of Object.keys(this.hrForm.controls)) {
            this.hrForm.controls[key].setValue(jsonForms.HRForm[key]);
          }

          for (const key of Object.keys(this.hodForm.controls)) {
            this.hodForm.controls[key].setValue(jsonForms.hodForm[key]);
          }

          for (const key of Object.keys(this.softwareDepartmentForm.controls)) {
            this.softwareDepartmentForm.controls[key].setValue(jsonForms.softwareDepartmentForm[key]);
          }

          for (const key of Object.keys(this.itDepartmentForm.controls)) {
            this.itDepartmentForm.controls[key].setValue(jsonForms.itDepartmentForm[key]);
          }

          for (const key of Object.keys(this.itHeadForm.controls)) {
            this.itHeadForm.controls[key].setValue(jsonForms.itHeadForm[key]);
          }


          this.commonApiService.getById(Commonvariable.OnboardingDetail, usercreationid, "GetForm_Unlock").subscribe((result) => {
            if (result["data"] != "") {

              var formUnlock = JSON.parse(result["data"])

              for (const key of Object.keys(formUnlock)) {
                $("." + key).find("input,button,textarea,select").attr("disabled", formUnlock[key]);
              }
            }
          })

        }

        else {


          this.commonApiService.getById(Commonvariable.Candidate, id).subscribe((result) => {

            if (result["data"] != "") {

              let hrDetail = result["data"][0];
              this.hrForm.controls.firstName.setValue(hrDetail.firstName)
              this.hrForm.controls.middleName.setValue(hrDetail.middleName)
              this.hrForm.controls.lastName.setValue(hrDetail.lastName)
              this.hrForm.controls.startDate.setValue(String(hrDetail.joiningDate.split('T')[0]))
              this.hrForm.controls.department.setValue(hrDetail.dept_Name)
              this.hrForm.controls.designation.setValue(hrDetail.desig_Name)
              this.hrForm.controls.empId.setValue(hrDetail.emp_Code)
              this.hrForm.controls.hrSignature.setValue('')
              this.hrForm.controls.date.setValue(this.commonService.todayDateYYMMDDFormate())

            }
          })


          this.commonApiService.getById(Commonvariable.OnboardingDetail, usercreationid, "GetForm_Unlock").subscribe((result) => {
            if (result["data"] != "") {

              var formUnlock = JSON.parse(result["data"])

              for (const key of Object.keys(formUnlock)) {
                $("." + key).find("input,button,textarea,select").attr("disabled", formUnlock[key]);
              }
            }
          })


        }

      })







    }
  }



  userCreationSave() {
    let jsonData = {
      "HRForm": this.hrForm.value,
      "hodForm": this.hodForm.value,
      "softwareDepartmentForm": this.softwareDepartmentForm.value,
      "itDepartmentForm": this.itDepartmentForm.value,
      "itHeadForm": this.itHeadForm.value,
    }
    var requestData = {
      "Onboarding_Id": this.onboarding_Id,
      "usercreation_rule_Id": this.userCreationId,
      "Json_Data": jsonData,
      "AddedBy": Commonvariable.User_Id,
    }
    this.commonApiService.Upsert(Commonvariable.UserCreation_Approval, requestData, 0).subscribe((result) => {
      this.savePDFFile();
      this.commonService.myData.next(!this.commonService.myData);
      $('.modal').modal("hide")
    })
  }



  generatePDF() {
    this.spinner.show();

    const pdfTable = this.pdfTable.nativeElement;

    // const content = this.reportContent.nativeElement;
    html2canvas(pdfTable).then(canvas => {
      const imgData = canvas.toDataURL('image/jpeg');
      // Few necessary setting options
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const doc = new jsPDF('p', 'mm');
      let heightLeft = imgHeight;
      let position = 0;

      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Generated PDF
      doc.save('UserCreationForm' + '.pdf');
      this.spinner.hide();

      // var b: any = doc.output('blob');
      // b.lastModifiedDate = new Date();
      // b.name = 'UserCreationForm' + '.pdf';

      // const formData = new FormData();
      // formData.append('Onboarding_Id', this.onboarding_Id);
      // formData.append('Doc_Id', '1');
      // formData.append('AddedBy', Commonvariable.User_Id);
      // formData.append('isActive', '1');
      // formData.append('files', <File>b);

      // this.commonApiService.UploadLogo(Commonvariable.Onboarding_Document, "AddUserCreationFile", formData).subscribe((response) => {
      // });

    });
  }




  savePDFFile() {
    this.spinner.show();

    const pdfTable = this.pdfTable.nativeElement;
    html2canvas(pdfTable).then(canvas => {
      const imgData = canvas.toDataURL('image/jpeg');
      // Few necessary setting options
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const doc = new jsPDF('p', 'mm');
      let heightLeft = imgHeight;
      let position = 0;

      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      var b: any = doc.output('blob');
      b.lastModifiedDate = new Date();
      b.name = 'UserCreationForm' + '.pdf';

      this.spinner.hide();

      const formData = new FormData();
      formData.append('Onboarding_Id', this.onboarding_Id);
      formData.append('Doc_Id', '1');
      formData.append('AddedBy', Commonvariable.User_Id);
      formData.append('isActive', '1');
      formData.append('files', <File>b);

      this.commonApiService.UploadLogo(Commonvariable.Onboarding_Document, "AddUserCreationFile", formData).subscribe((response) => {
        this.commonService.myData.next(!this.commonService.myData);
        $('.modal').modal("hide")
      });
    });
  }




}
