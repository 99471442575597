import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;


@Component({
  selector: 'app-employeedocument',
  templateUrl: './emp-document.component.html'
})
export class EmpDocumentComponent implements OnInit {
  empform: FormGroup;
  documentList: any;
  fileName: any = "";

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.fileName = "";
    this.empform = this.fb.group({
      emp_Doc_Id: [0],
      doc_Id: ['', Validators.required],
      emp_Id: ['0'],
      emp_Doc_Name: ['', Validators.required],
      files: ['', Validators.required],
      notes: [null],
      isActive: ['1'],
      is_CompanyDoc: [false],
      addedBy: [Commonvariable.User_Id],
      updatedBy: ['0']
    });
    $('.fileinput-filename').html('');
    $(".removedacoument").click();
    this, this.getDocument();
  }

  getDocument() {
    this.commonApiService.List(Commonvariable.Document).subscribe((response) => {
      if (response["data"] != "") {
        this.documentList = response["data"].sort((a, b) => a.name.localeCompare(b.name));
      }
    })
  }

  empEdit(Id) {
    
    $(".removedacoument").click();
    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.EmpDocument, Id).subscribe((response) => {
        if (response != null) {
          var editData = response["data"][0];
          this.empform = this.fb.group({
            emp_Doc_Id: [editData.emp_Doc_Id],
            doc_Id: [editData.doc_Id, Validators.required],
            emp_Id: [editData.emp_Id],
            emp_Doc_Name: [editData.emp_Doc_Name, Validators.required],
            notes: [editData.notes == 'null' ? '' : editData.notes],
            files: ['', Validators.required],
            isActive: [editData.isActive],
            is_CompanyDoc: [editData.is_CompanyDoc == 1 ? true : false],
            addedBy: [editData.addedBy],
            updatedBy: [Commonvariable.User_Id]
          });
          this.fileName = response['data'][0].file_Name
          $('.fileinput-filename').html(this.fileName);
          $('.fileinput.fileinput-new').removeClass('fileinput-new').addClass('fileinput-exists');
          $('.input-group.input-large').attr('disabled', 'disabled');
        }
      })
    }
  }


  validation() {
    var eForm = this.empform;
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);
    eForm.get("is_CompanyDoc").setValue(eForm.get("is_CompanyDoc").value ? 1 : 0);

    if (!eForm.valid) {
      for (const key of Object.keys(eForm.controls)) {
        if (eForm.controls[key].invalid) {
          let fieldName = "";

          if (key == "doc_Id" && this.empform.controls && !this.empform.controls.is_CompanyDoc.value) {
            fieldName = "Other Document"
          }
          else if (key == "emp_Doc_Name" && !this.empform.controls.is_CompanyDoc.value) {
            fieldName = "Document Name"
          }
          else if (key == "files" && this.fileName == "") {
            fieldName = "Files"
          }
          if (fieldName != "") {
            this.toastr.info(fieldName + " is required !", "Employee");
            $("#" + key).focus();
            return false;
          }
        }
      }
    }

    if ($("#emp_Doc_Name").val() != "" && /["\\]/.test($("#emp_Doc_Name").val())) {
      this.toastr.info("Document Name does not allow Special Characters \" \\ ", "Employee");
      $("#emp_Doc_Name").focus();
      return false;
    }

    if ($("#addNotes").val() != "" && /["\\]/.test($("#addNotes").val())) {
      this.toastr.info("Notes does not allow Special Characters \" \\ ", "Employee");
      $("#addNotes").focus();
      return false;
    }
    
    if (eForm.controls.files.value == '' && this.fileName == "") {
      this.toastr.info("Files is required !", "Employee");
      $("#files").focus();
      return false;
    }

    // if ($("#emp_Doc_Name").val().indexOf(" ") > -1) {
    //   this.toastr.info("Document name not allow space !", "Employee");
    //   $("#emp_Doc_Name").focus();
    //   return false;
    // }
    if (/["\\]/.test($("#emp_Doc_Name").val())) {
      this.toastr.info("Document name does not allow Special Characters \" \\ ", "Employee");
      $("#emp_Doc_Name").focus();
      return false;
    }

    return true;
  }

  fileChange(event) {
    this.fileName = "";
    // if (event.target.files.item(0).name.split('.')[1] == "pdf" || event.target.files.item(0).name.split('.')[1] == "doc"
    //   || event.target.files.item(0).name.split('.')[1] == "docx" || event.target.files.item(0).name.split('.')[1] == "xlsx") {
      this.empform.controls.files.setValue(event.target.files[0]);
      this.fileName = event.target.files[0].name;
    // }
    // else {
    //   this.toastr.info("Allow only pdf,doc,docx,xlsx file format for upload !", "Employee");
    //   $("#remove").click();
    // }
  }

  removedoc() {
    this.empform.controls.files.setValue('');
    this.fileName = "";
    $("#remove").click();
  }

}
