import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;

@Component({
   selector: 'app-empEmrContact',
   templateUrl: './employee-emergency-contact.component.html',
})
export class EmpEmergencyContactComponent implements OnInit {
   empform: FormGroup;
   items: FormArray;
   companyId: any = 0;
   relList: any;
   countryList: Array<Select2OptionData>;
   countryId :any = 0
   constructor(
      private toastr: ToastrService,
      private commonApiService: CommonApiService,
      private commonService: CommonService,
      private router: Router,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {

      this.empform = this.fb.group({
         emp_Contact: this.fb.array([this.createItem(null)]),
      });
      this.getCountryList();
   }

   getCountryList() {

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "country_Name"
      }
      this.commonApiService.GetByPaginated(Commonvariable.Country, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            this.countryList = response["data"].map(x => ({
               id: x.id,
               text: x.country_Name + " (" + x.country_Code + ") "
            }));
            this.countryId = response["data"].filter(x => x.isDefault == 1)[0]["id"]; 
            this.items = this.empform.get('emp_Contact') as FormArray;
            this.items.clear();
            this.addItem(null)
         }
      })
   }

   getRelationshipList() {
      this.commonApiService.List(Commonvariable.Relationship).subscribe((result) => {
         if (result != null && result["data"] != "") {
            // this.relList = result["data"].sort((a, b) =>  a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
            this.relList = result["data"].sort((a, b) => a.name.localeCompare(b.name));
         }
      });
   }

   createItem(data) {
      return this.fb.group({
         Id: data != null ? data.id : '0',
         Person_Id: data != null ? data.person_Id : '0',
         ContactName: data != null ? data.contactName : '',
         country_Id: data != null ? data.country_Id : this.countryId != 0 ? this.countryId :'',
         ContactNo: data != null ? data.contactNo : '',
         Relationship_Id: data != null ? data.relationship_Id : '',
         isReview: JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "User" ? 0 : 1,
         // isDefault: data != null ? data.isDefault == 1 ? true : false : false,
         isActive: data != null ? data.isActive == 1 ? true : false : true,
         AddedBy: Commonvariable.User_Id,
      });
   }

   edit(data) {
      if (data != null) {
         if (data.length > 0) {
            this.items = this.empform.get('emp_Contact') as FormArray;
            this.items.clear()
            data.forEach(x => {
               this.items.push(this.createItem(x));
            });
         }
      }
   }

   addItem(data) {
      if (this.validation()) {
         this.items = this.empform.get('emp_Contact') as FormArray;
         this.items.push(this.createItem(data));
      }
   }


   removeItem(index) {
      this.items = this.empform.get('emp_Contact') as FormArray;
      this.items.removeAt(index);

   }


   empUpsert() {
      var empaddressData = this.empform.get('emp_Contact').value;
      if (empaddressData[0].ContactName == "") {
         return false;
      }
      return true;
   }

   validation() {
      var empContactData = this.empform.get('emp_Contact').value;
      var tempArray = [];
      for (let index = 0; index < empContactData.length; index++) {
         if (empContactData[0].ContactName != "" || empContactData[0].ContactNo != "" || empContactData[0].Relationship_Id != "") {
            if (empContactData[index].ContactName == "") {
               this.toastr.info("Contact Name is required !", "Employee");
               $("#ContactName" + index).focus();
               return false;
            }
            if ($("#ContactName" + index).val().length < 2) {
               this.toastr.info("Contact Name must be at least 2 characters long !", "Employee");
               $("#ContactName" + index).focus();
               return false;
            }
            if ($("#ContactName" + index).val().search(/^[^-\s][a-zA-Z0-9_\s-]+$/) != 0) {
               this.toastr.info("Contact Name not allow space !", "Employee");
               $("#ContactName" + index).focus();
               return false;
            }
            if ($("#ContactName" + index).val().search(/[^a-zA-Z\s]+/) > -1) {
               this.toastr.info("Contact Name only alphabets allow !", "Employee");
               $("#ContactName" + index).focus();
               return false;
            }
            if (empContactData[index].ContactName != "") {
               if ($("#ContactName" + index).val().search(/^[a-zA-Z ]+$/) != 0) {
                  this.toastr.info("Please enter valid contact name  !", "Employee");
                  $("#ContactName" + index).focus();
                  return false;
               }
            }

            if (empContactData[index].country_Id == "" || empContactData[index].country_Id == null) {
               this.toastr.info("Country is required !", "Company");
               $("#country_Id" + index + " select").focus();
               return false;
            }

            if (empContactData[index].ContactNo != "") {
               if ($("#ContactNo" + index).val().search(/[^0-9- +()]+/) > -1) {
                  this.toastr.info("Please enter valid contact number !", "Employee");
                  $("#ContactNo" + index).focus();
                  return false;
               }
            }
            if (empContactData[index].ContactNo == "") {
               this.toastr.info("Contact no is required !", "Employee");
               $("#ContactNo" + index).focus();
               return false;
            }
            if ($("#ContactNo" + index).val().length < 9) {
               this.toastr.info("Contact number must be at least 9 digit long !", "Employee");
               $("#ContactNo" + index).focus();
               return false;
            }
            if (empContactData[index].Relationship_Id == "") {
               this.toastr.info("Relationship is required !", "Employee");
               $("#Relationship" + index).focus();
               return false;
            }


            if (tempArray.length > 0) {
               if (tempArray.filter(x => x == String(empContactData[index].ContactNo).trim()).length > 0) {
                  this.toastr.info("This value already entered !", "Employee");
                  $("#ContactNo" + index).focus();
                  return false
               }
            }

            tempArray.push(String(empContactData[index].ContactNo).trim())

            // if (empContactData[index].Relationship_Id != "") {
            //    if ($("#Relationship" + index).val().search(/^[a-zA-Z]+$/) != 0) {
            //       this.toastr.info("Please enter valid relationship  !", "Employee");
            //       $("#Relationship" + index).focus();
            //       return false;
            //     }
            // }
            // if ($("#Relationship" + index).val().length < 2) {
            //    this.toastr.info("Relationship must be at least 2 characters long !", "Employee");
            //    $("#Relationship" + index).focus();
            //    return false;
            // }
            // if ($("#Relationship" + index).val().indexOf(" ") > -1) {
            //    this.toastr.info("Relationship not allow space !", "Employee");
            //    $("#Relationship" + index).focus();
            //    return false;
            // }
         }
         //empContactData[index].isDefault = empContactData[index].isDefault ? 1 : 0
         empContactData[index].isActive = empContactData[index].isActive ? 1 : 0

      }
      return true;
   }

   addRelationship(event) {
      if (event.target.value != "") {
         if (this.relList.filter(x => x.name == event.target.value).length == 0) {
            var data = {
               "Id": 0,
               "Name": event.target.value,
               "isActive": 1,
               "AddedBy": Commonvariable.User_Id
            }
            this.commonApiService.Upsert(Commonvariable.Relationship, data, 0).subscribe((result) => {
               this.getRelationshipList();
            });
         }
      }
   }


}


