<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Company site List
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->
        <!-- <ul class="page-breadcrumb breadcrumb mb20">
            <li>
                <a data-toggle="modal" href="#draggable" (click)="openModel(0)"
                    class="btn btn-transparent blue  btn-sm">Add Site</a>
            </li>
        </ul> -->
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right mr0">
                <li>
                    <div>
                        <a data-toggle="modal" (click)="id=0;modalOpen =true" class="btn btn-transparent blue  btn-sm">Add
                            Company Site</a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="search" class="form-control" [formControl]="cSearch" placeholder="Type to filter ..." id="filterList"
                                (input)="getSiteList(1)">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblSite">
                            <thead class="flip-content">
                                <tr>
                                    <th class="text-center"> Company </th>
                                    <th class="text-center"> Site Name </th>
                                    <th class="text-center"> Address </th>                                    
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let site of sitelist| paginate: { itemsPerPage: 10, currentPage: d,totalItems :totalRecord,id:'d' }">

                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(site.site_Id,'0')">
                                                <div class="circle greenColor" *ngIf="site.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(site.site_Id,'1')">
                                                <div class="circle redColor" *ngIf="!site.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12">
                                            {{site.company_Name}}
                                        </div>
                                    </td>
                                    <td class="numeric">{{site.site_Name}}</td>
                                    <td>{{site.address1}}, {{site.address2}}</td>                                    
                                    <td class="numeric text-center">
                                        <a *ngIf="site.isActive" data-toggle="modal"
                                            (click)="id=site.site_Id;modalOpen =true"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Site"></i></a>

                                        <a *ngIf="!site.isActive" (click)="siteDelete(site.site_Id)">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top" title="Delete Site"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="text-center" *ngIf="sitelist!= null">
                        <pagination-controls (pageChange)="getSiteList($event)" id="d" autoHide="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>

<!-- END CONTENT -->
<div *ngIf="modalOpen">
    <app-site-form [pId]="id" (modalClose)="modalOpen = $event" (refreshPage)="getSiteList($event)"></app-site-form>
</div>


