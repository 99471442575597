<style>
    @media (max-width:767px) {
        p.mobwidth {
            font: 33px Times New Roman, sans-serif !important;
            color: maroon;
            word-break: break-all !important;
        }
        p.titlewidth{
            font: 60px Times New Roman, sans-serif !important;
            color: maroon;
            word-break: break-all !important;
        }
    }
</style>



<div style="background-color: #6672b2; height: 100vh;">

    <p class="text-center titlewidth" style="font:150px Times New Roman, sans-serif;color: maroon;margin: 0;">403</p>
    <p class="text-center mobwidth" style="font:100px Times New Roman, sans-serif;color: maroon ">Session timeout</p>
    <div class="text-center"><a href="javascript:;" (click)="logOut()">
            <p class="text-center" style="font:50px Times New Roman, sans-serif;color:maroon ">Back To Login</p>
        </a></div>

</div>
