import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;
import * as moment from 'moment';
import { identifierModuleUrl } from '@angular/compiler';

@Component({
   selector: 'app-canAdvancedSearch',
   templateUrl: './advanced-search.component.html',
})
export class CanAdvancedSearchComponent implements OnInit {

   filterform: FormGroup;
   items: FormArray;
   constructor(
      private toastr: ToastrService,
      private commonService: CommonService,
      private router: Router,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {

      this.filterform = this.fb.group({
         SearchBy: this.fb.array([this.createItem()]),
      });
   }

   createItem() {
      return this.fb.group({
         FieldName: '',
         FieldValue: '',
         Parameter: '',
         ConditionWith: ''
      });
   }

   addItem(data) {
      if (this.validation()) {
         this.items = this.filterform.get('SearchBy') as FormArray;
         this.items.push(this.createItem());
      }

   }


   removeItem(index) {
      this.items = this.filterform.get('SearchBy') as FormArray;
      this.items.removeAt(index);
   }

   search() {

      var formData = this.filterform.get("SearchBy").value;



      for (let index = 0; index < formData.length; index++) {
         if (formData[index].FieldValue != "" && formData[index].Parameter != "" && formData[index].FieldName != "") {
            if(formData[index].FieldValue.search(/[^0-9.]+/) > -1  && formData[index].FieldName == "intExpected_Salary"){
               this.toastr.info("Salary only number allow !", "Candidate")
               $("#FieldValue" + index).focus();
               return false;
            }

            if (!String(formData[index].FieldValue).includes('"')) {
               // formData[index].FieldValue = '\"' + $("#FieldValue" + index).val() + '\"';
               formData[index].FieldValue = JSON.stringify($("#FieldValue" + index).val());
            }

            if (index > 0) {
               if ($("#ConditionWith" + index).val() != "") {
                  formData[index - 1].ConditionWith = $("#ConditionWith" + index).val();
               }
               else {
                  this.toastr.info("Condition with is required !", "Candidate")
                  $("#ConditionWith" + index).focus();
                  return false;
               }

            }

            if (index == formData.length - 1) {
               formData[index].ConditionWith = "";
            }
         }
         else {
            this.toastr.info("Enter valid data !", "Candidate");
            return false
         }
      }

      return formData;

   }


   validation() {
      var formData = this.filterform.get("SearchBy").value;
      for (let index = 0; index < formData.length; index++) {
         if (formData[index].FieldValue == "" || formData[index].Parameter == "" || formData[index].FieldName == "") {
            this.toastr.info("Enter valid data !", "Candidate");
            return false
         }
         if (index > 0 && formData[index].ConditionWith == "") {
            this.toastr.info("Enter valid data !", "Candidate");
            return false

         }
      }
      return true;
   }

   changeEvent(event,index){      
      if(event.target.value == 'intExpected_Salary'){
         $("#inputFieldVal"+index).show();
      }else{
         $("#inputFieldVal"+index).hide();
      }


   }
}


