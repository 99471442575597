<div class="page-content-wrapper">
    <div class="page-content">
        <div class="col-md-10 fixed-top-title">
            <div class="page-head">
                <div class="page-title">
                    <h1>Employee Edit</h1>
                </div>
            </div>

            <div class="navbar page-breadcrumb breadcrumb mb20">
                <ul class="nav navbar-nav">
                    <li *ngIf="empPersonalInfo != null">
                        {{empPersonalInfo.firstName}} {{empPersonalInfo.lastName}}
                    </li>
                </ul>
                <ul class="nav navbar-nav navbar-right">

                    <!-- <li style="visibility: hidden;">
                    <div>
                        <a [href]="fileUrl" id="download" class="btn btn-transparent blue  btn-sm"
                            [download]="fileName">Export</a>
                    </div>
                </li> -->
                    <li>
                        <div>
                            <a routerLink="/employee"><i class="fa fas fa-reply" data-toggle="tooltip"
                                    data-placement="top" title="Go Back"></i></a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-12" style="margin-top: 130px;">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <div id="accordion1" class="panel-group">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_1"> 1. Personal Details </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <button class="btn btn-sm dark btn-outline pull-right" type="button"
                                            data-toggle="modal" href="#personal-info" *ngIf="empPersonalInfo != null"
                                            (click)="editempInfo('EmpPersonalInfo',empPersonalInfo.id)"><i
                                                class="fa fa-edit" data-toggle="tooltip" title="Edit"
                                                data-placement="top"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_1" class="panel-collapse collapse in">
                                <div class="panel-body" *ngIf="empPersonalInfo != null">

                                    <div class="row">
                                        <div class="form-group">
                                            <label class="control-label col-md-2">Employee Photo</label>
                                            <div class="col-md-10 form-group">
                                                <div [class]="logoClass" data-provides="fileinput">
                                                    <div class="fileinput-new thumbnail"
                                                        style="width: 200px; height: 150px;">
                                                        <img src="assets/layouts/layout4/img/selectPic.png" alt="" />
                                                    </div>
                                                    <div class="fileinput-preview fileinput-exists thumbnail"
                                                        [innerHTML]="logoInnerHTML"
                                                        style="max-width: 200px; max-height: 150px;"> </div>
                                                </div>
                                            </div>

                                            <label class="control-label col-md-2">Title<span class="required"> *
                                                </span></label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.title}}" readonly />
                                            </div>

                                            <!-- <label class="control-label col-md-2">Gender</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.gender_Name}}" readonly />
                                            </div> -->

                                            <label class="control-label col-md-2">First Name<span class="required"> *
                                                </span></label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.firstName}}" readonly />
                                            </div>


                                            <label class="control-label col-md-2">Middle Name</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.middleName}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Last Name<span class="required"> *
                                                </span></label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.lastName}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Date Of Birth<span class="required"> *
                                                </span></label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.dob | date:'dd/MM/yyyy'}}" readonly />
                                                <!-- <pre><input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.age | calculateAge}}" readonly /></pre>   -->
                                            </div>

                                            <!-- <label class="control-label col-md-2">Age</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.age}}" readonly />
                                            </div> -->

                                            <!-- <label class="control-label col-md-2">Ethnicity</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.ethnicity_Name}}" readonly />
                                            </div> -->

                                            <!-- <label class="control-label col-md-2">Language</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.languages_Name}}" readonly />
                                            </div> -->
                                            <!-- <ng-select2 [data]="this.empPersonalInfo.languages_Name"
                                                    [options]="{multiple: true,tags: false}"
                                                    [value]='this.empPersonalInfo.languages_Name' [placeholder]=""
                                                    [disabled]="true">
                                                </ng-select2> -->

                                            <!-- <label class="control-label col-md-2">Nationality</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.nationality_Name}}" readonly />
                                            </div> -->

                                            <!-- <label class="control-label col-md-2">Marital Status</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.marital_Status}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Disability</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.disability}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Sexual Orientation</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.sexual_Orientation}}" readonly />
                                            </div> -->

                                            <label class="control-label col-md-2">Mobile Number</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.phoneNumber}}" readonly />
                                            </div>
                                            <label class="control-label col-md-2"> Telephone</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.telephone}}" readonly />
                                            </div>
                                            <label class="control-label col-md-2">Email</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.empPersonalInfo.email}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Leaver</label>
                                            <div class="col-md-4 form-group">
                                                <div class="md-checkbox-inline">
                                                    <div class="md-checkbox">
                                                        <input type="checkbox" id="myCheckbox"
                                                            [(ngModel)]="checkboxValue" (change)="onCheckboxChange()">
                                                        <label for="myCheckbox">
                                                            <span></span>
                                                            <span class="check"></span>
                                                            <span class="box"></span> </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_2"> 2.Current Job Details </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <button type="button" class="btn btn-sm dark btn-outline pull-right"
                                            data-toggle="modal" href="#job-details" *ngIf="employeeSummary != null"
                                            (click)="editempInfo('EmpJDetail',employeeSummary.emp_Id)"><i
                                                class="fa fa-edit" data-toggle="tooltip" title="Edit"
                                                data-placement="top"></i></button>&nbsp;
                                    </span>
                                    <!-- <span style="float:right ;margin-top:-6px">
                                        <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryData("\"employee\"")'><i class="fa fa-history"
                                                data-toggle="tooltip"
                                                title="History"></i></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    </span> -->
                                </h4>
                            </div>
                            <div id="accordion1_2" class="panel-collapse collapse ">
                                <div class="panel-body" *ngIf="employeeSummary != null">

                                    <div class="row">
                                        <div class="form-group">
                                            <label class="control-label col-md-2">Company<span class="required"> *
                                                </span></label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.company_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Site</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.site_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Department<span class="required"> *
                                                </span></label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.dept_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Zone<span class="required"> *
                                                </span></label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.zone_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Job Title<span class="required"> *
                                                </span></label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.desig_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Job Description</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.jD_Name}}" readonly />
                                                    <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="download()"
                                                    title="Download Job Description"><i class="fa fa-download fa-1x"></i></a>
                                            </div>

                                            <label class="control-label col-md-2">Employee Number<span class="required">
                                                    * </span></label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.emp_Code}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Role Start Date<span class="required">
                                                    * </span></label> <!-- prev name change Joining Date  -->
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.joiningDate | date :'dd/MM/yyyy'}}"
                                                    readonly />
                                                <input type="hidden" id="getjoiningDate"
                                                    value="{{this.employeeSummary.joiningDate}}">
                                            </div>

                                            <!-- <label class="control-label col-md-2">Shift Time<span class="required"> *
                                                </span></label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.shift_Name}} {{this.employeeSummary.shift_Time}}"
                                                    readonly />
                                            </div> -->
                                            <label class="control-label col-md-2">Shift Start</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" id="shift_Start"
                                                    value="{{this.employeeSummary.shift_Start}}" readonly>
                                            </div>
                                            <label class="control-label col-md-2">Shift End</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" id="shift_End"
                                                    value="{{this.employeeSummary.shift_End}}" readonly>
                                            </div>
                                            <!-- <label class="control-label col-md-2">Night Shift</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.nightShift != 1" value="No" readonly />
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.nightShift == 1" value="Yes" readonly />

                                                 <label class="control-label" *ngIf="employeeSummary.tupe == 0">No</label>
                                            <label class="control-label" *ngIf="employeeSummary.tupe == 1">Yes</label> 
                                            </div> -->

                                            <label class="control-label col-md-2">Shift Variable</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.shift_Variable != 1" value="No" readonly />
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.shift_Variable == 1" value="Yes" readonly />

                                                <!-- <label class="control-label" *ngIf="employeeSummary.tupe == 0">No</label>
                                            <label class="control-label" *ngIf="employeeSummary.tupe == 1">Yes</label> -->
                                            </div>

                                            <!-- <label class="control-label col-md-2">NI Number</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.niNo}}" readonly />
                                            </div> -->

                                            <label class="control-label col-md-2">Work Email</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.work_Email}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Work Phone</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.work_Phone}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Holiday approver<span
                                                    class="required"> * </span></label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.reporting_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2"> Line Manager</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.line_Manager_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Work Extension</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.work_Extension}}" readonly />
                                            </div>


                                            <label class="control-label col-md-2"
                                                *ngIf="employeeSummary != null">RAF</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.vacancy_Name}}" readonly />
                                            </div>

                                            <label class="control-label col-md-2"
                                                *ngIf="employeeSummary != null">Deputy</label>
                                            <div class="col-md-4 form-group" *ngIf="employeeSummary != null">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.deputy_Name}}" readonly />
                                            </div>


                                            <label class="control-label col-md-2">RAF Approver</label>
                                            <!--  pre name change RAF Approval -->
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.raF_Approval != 1" value="No" readonly />

                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.raF_Approval == 1" value="Yes" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Tupe</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.tupe != 1" value="No" readonly />
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.tupe == 1" value="Yes" readonly />

                                                <!-- <label class="control-label" *ngIf="employeeSummary.tupe == 0">No</label>
                                            <label class="control-label" *ngIf="employeeSummary.tupe == 1">Yes</label> -->
                                            </div>
                                            <label class="control-label col-md-2">DBS Required</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.dbS_Required  != 1" value="No" readonly />

                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.dbS_Required  == 1" value="Yes" readonly />
                                            </div>

                                            <label class="control-label col-md-2">DBS Expiry Date  <span class="required" *ngIf="employeeSummary.dbS_Required  == 1"> * </span></label>
                                           
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{employeeSummary.dbS_Expiry_Date | date :'dd/MM/yyyy'}}"
                                                    readonly />
                                            </div>


                                            <label class="control-label col-md-2">Contract Type</label>
                                             <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.contract_Name}}" readonly />
                                            </div> 
                                            <!-- <div class="col-md-4 form-group"
                                                *ngIf="empContract && empContract.length > 0">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    [value]="empContract[0]?.emp_Contract_Name" readonly />
                                            </div>
                                            <div class="col-md-4 form-group"
                                            *ngIf="empContract && empContract.length == 0">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                [value]="empContract[0]?.emp_Contract_Name" readonly />
                                        </div> -->

                                            <label class="control-label col-md-2">Contract End Date</label>
                                            <div class="col-md-4 form-group"
                                               >
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{this.employeeSummary.contract_End_Date | date :'dd/MM/yyyy'}}"
                                                    readonly />
                                            </div>
                                            <!-- <div class="col-md-4 form-group"
                                            *ngIf="empContract && empContract.length == 0">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                value="{{this.empContract[0]?.emp_Role_End | date :'dd/MM/yyyy'}}"
                                                readonly />
                                            <input type="hidden" id="getroleendDate"
                                                value="{{this.empContract[0]?.emp_Role_End}}">
                                        </div> -->


                                            <label class="control-label col-md-2">HOD</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.isHOD != 1" value="No" readonly />

                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.isHOD == 1" value="Yes" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Line Manager</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.is_Line_Manager != 1" value="No" readonly />

                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.is_Line_Manager == 1" value="Yes" readonly />
                                            </div>

                                            <label class="control-label col-md-2">Holiday Approver</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.is_Holiday_Approvel != 1" value="No" readonly />

                                                <input type="text" class="form-control readonlyClass"
                                                    *ngIf="employeeSummary.is_Holiday_Approvel == 1" value="Yes" readonly />
                                            </div>


                                            
                                            

                                            <!-- <div class="col-md-2 form-group">
                                                <input type="text" class="form-control readonlyClass"
                                                    value="{{this.employee_length_service_month}}" readonly />
                                            </div> -->
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_3"> 3. Career History </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryData("\"employee_contract\"")'><i class="fa fa-history"
                                                data-toggle="tooltip" title="History"></i></button> -->
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#emp-contract" (click)="editempInfo('EmpContract',0)"
                                            style="float:right"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Contract"></i></button>&nbsp; -->
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_3" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="row" *ngIf="employeeSummary != null">
                                        <label class="control-label col-md-1">Continuous Service Date</label>
                                        <div class="col-md-3 form-group">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                value="{{this.employeeSummary.joiningDate | date :'dd/MM/yyyy'}}"
                                                readonly />
                                        </div>

                                        <label class="control-label col-md-1">Employee's length of Service</label>
                                        <div class="col-md-3 form-group">
                                            <input type="text" class="form-control readonlyClass"
                                                value="{{this.employee_length_service_year}}" readonly />
                                        </div>
                                        <div class="col-md-2 form-group">
                                            <input type="text" class="form-control readonlyClass"
                                                value="{{this.employee_length_service_month}}" readonly />
                                        </div>

                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Contract Type<span class="text-danger"> *
                                                        </span></th>

                                                    <!-- <th style="width:15%;" class="text-center">Employee Contract Code
                                                    </th> -->
                                                    <th class="text-center" style="width: 15%;">Job Title<span
                                                            class="text-danger"> * </span>
                                                    </th>

                                                    <th class="text-center" style="width: 15%;">Role Start<span
                                                            class="text-danger"> * </span>
                                                    </th>

                                                    <th class="text-center" style="width: 15%;">Role End
                                                    </th>

                                                    <th class="text-center" style="width: 15%;">Zone
                                                    </th>
                                                    <th class="text-center" style="width: 2%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of empContract">
                                                <tr>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.emp_Contract_Name}}">
                                                            {{item.emp_Contract_Name}}
                                                        </div>
                                                    </td>
                                                    <!-- <td>{{item.emp_Contract_Code}}</td> -->
                                                    <td>{{item.emp_Job_Title}}</td>
                                                    <td>{{item.emp_Role_Start | date :'dd/MM/yyyy'}}</td>
                                                    <td>{{item.emp_Role_End | date :'dd/MM/yyyy'}}</td>
                                                    <td>{{item.emp_Zone_Name}}</td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-contract"
                                                            (click)="editempInfo('EmpContract',item.emp_Contract_Id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Contract"></i></a>&nbsp;
                                                        <a (click)="Delete(9,item.emp_Contract_Id)"
                                                            *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Contract"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_4"> 4. Probation </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryData("\"employee_probation\"")'><i class="fa fa-history"
                                                data-toggle="tooltip" title="History"></i></button> -->
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#emp-probation" (click)="editempInfo('EmpProbation',0)"
                                            style="float:right"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Probation"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_4" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Probation Period<span class="text-danger"> *
                                                        </span></th>
                                                    <th style="width:15%;" class="text-center">Probation End Date<span
                                                            class="text-danger"> * </span></th>
                                                    <th class="text-center" style="width: 15%;">Next Review Date</th>
                                                    <th class="text-center" style="width: 15%;">Status</th>
                                                    <th class="text-center" style="width: 2%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of empProbation">
                                                <tr>

                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.probationPeriod}}">{{item.probationPeriod}}
                                                        </div>
                                                    </td>
                                                    <td>{{item.probationEndDate |date:'dd/MM/yyyy'}}</td>
                                                    <td>{{item.nextReviewDate | date :'dd/MM/yyyy'}}</td>
                                                    <td>{{item.status}}</td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-probation"
                                                            (click)="editempInfo('EmpProbation',item.emp_Probation_Id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Probation"></i></a>&nbsp;
                                                        <a (click)="Delete(8,item.emp_Probation_Id)"
                                                            *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Probation"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_5"> 5. Salary </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryData("\"employee_salary\"")'><i class="fa fa-history"
                                                data-toggle="tooltip" title="History"></i></button> -->
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#emp-salary" (click)="editempInfo('EmpSalary',0)"
                                            style="float:right"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Salary"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_5" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Salary<span class="text-danger"> * </span>
                                                    </th>
                                                    <th class="text-center" style="width: 20%;">Salary Type</th>
                                                    <!-- <th class="text-center" style="width: 10%;">Apprisal From</th>
                                                    <th class="text-center" style="width: 10%;">Apprisal To</th> -->
                                                    <th class="text-center" style="width: 2%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of empsalary">
                                                <tr>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.salary | currency:'GBP':'symbol':'1.0-0'}}">
                                                            {{item.salary | currency:'GBP':'symbol':'1.0-0'}}
                                                        </div>
                                                    </td>
                                                    <td>{{item.salaryType}}</td>
                                                    <!-- <td>{{item.apprisalFrom | date :'dd/MM/yyyy'}}</td>
                                                    <td>{{item.apprisalTo | date :'dd/MM/yyyy'}}</td> -->
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-salary"
                                                            (click)="editempInfo('EmpSalary',item.emp_Salary_Id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Salary"></i></a>&nbsp;
                                                        <!-- <a (click)="Delete(11,item.emp_Salary_Id)"
                                                            *ngIf="item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Salary"></i></a> -->
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3">
                                                        <table class="table table-sm table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center">Additional Payment</th>
                                                                    <th class="text-center">Amount</th>
                                                                    <th class="text-center">Payment Type</th>
                                                                    <!-- Add more headers if needed -->
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let additionalItem of item.emp_salary_additional">
                                                                    <td class="text-center">{{
                                                                        additionalItem.additionalPayment }}</td>
                                                                    <td class="text-center">{{
                                                                        additionalItem.additionalPaymentAmount }}</td>
                                                                    <td class="text-center">{{
                                                                        additionalItem.additionalPaymentType }}</td>
                                                                    <!-- Add more columns for other properties if needed -->
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_6"> 6. Address Details </a>
                                    <span style="float:right ;margin-top: -6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryData("\"employee_address\"")'><i class="fa fa-history"
                                                data-toggle="tooltip" title="History"></i></button> -->
                                        <button type="button" data-toggle="modal" href="#address-info"
                                            class="btn btn-sm dark btn-outline" (click)="editempInfo('EmpAddress',0)"
                                            style="float:right"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Address Info"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_6" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Address<span class="text-danger"> * </span>
                                                    </th>
                                                    <th style="width: 10%;" class="text-center">City<span
                                                            class="text-danger"> * </span></th>
                                                    <th style="width: 10%;" class="text-center">County</th>
                                                    <th style="width: 10%;" class="text-center">Country<span
                                                            class="text-danger"> * </span></th>
                                                    <th class="text-center" style="width: 10%;">Post Code<span
                                                            class="text-danger"> * </span></th>
                                                    <th class="text-center" style="width: 5%;">Default</th>
                                                    <th class="text-center" style="width: 5%;">Active</th>
                                                    <th class="text-center" style="width: 2%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of empAddress">
                                                <tr>
                                                    <td>{{item.address1}}, {{item.address2}}</td>
                                                    <td>{{item.city}}</td>
                                                    <td>{{item.state}}</td>
                                                    <td>{{item.country_Name}}</td>
                                                    <td>{{item.postCode}}</td>
                                                    <td>
                                                        <div>
                                                            <div class="circle greenColor" *ngIf="item.isDefault"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Default">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isDefault"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Indefault">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Default">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Indefault">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#address-info"
                                                            (click)="editempInfo('EmpAddress',item.id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Address info"></i></a>&nbsp;
                                                        <a (click)="Delete(2,item.id)" *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Address info"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_7"> 7. Personal Contact Details </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryData("\"employee_contact\"")'><i class="fa fa-history"
                                                data-toggle="tooltip" title="History"></i></button>
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#emp-contact" (click)="editempInfo('EmpContact',0)"
                                            style="float:right"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Contact"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_7" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Contact Type</th>
                                                    <th class="text-center" style="width: 20%;">Contact Details</th>
                                                    <th class="text-center" style="width: 5%;">Default</th>
                                                    <th class="text-center" style="width: 5%;">Active</th>
                                                    <th class="text-center" style="width: 2%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of empConatct">
                                                <tr>
                                                    <td>{{item.contact_Type}}</td>
                                                    <td>{{item.contact_Value}}</td>
                                                    <td>
                                                        <div>
                                                            <div class="circle greenColor" *ngIf="item.isDefault"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Default">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isDefault"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Indefault">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="text-center">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-contact"
                                                            (click)="editempInfo('EmpContact',item.id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Employee Contact"></i></a>&nbsp;
                                                        <a (click)="Delete(4,item.id)" *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Employee Contact"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_8"> 7. Emergency Contact </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryData("\"employee_emergency\"")'><i class="fa fa-history"
                                                data-toggle="tooltip" title="History"></i></button> -->
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#emp-eme-contact" (click)="editempInfo('EmpEmeContact',0)"
                                            style="float:right"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Emergency Contact"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_8" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Emergency Contact Name<span
                                                            class="text-danger"> * </span></th>
                                                    <th class="text-center" style="width: 15%;">Emergency Contact
                                                        Number<span class="text-danger"> * </span>
                                                    </th>
                                                    <th class="text-center" style="width: 15%;">Relationship<span
                                                            class="text-danger"> * </span></th>
                                                    <th class="text-center" style="width: 4%;">Default</th>
                                                    <th class="text-center" style="width: 4%;">Active</th>
                                                    <th class="text-center" style="width: 2%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of empEmergency">
                                                <tr>
                                                    <td>{{item.contactName}}</td>
                                                    <td>{{item.contactNo}}</td>
                                                    <td>{{item.relationship_Name}}</td>
                                                    <td>
                                                        <div class="text-center">
                                                            <div class="circle greenColor" *ngIf="item.isDefault"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Default">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isDefault"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Indefault">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="text-center">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active">
                                                            </div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-eme-contact"
                                                            (click)="editempInfo('EmpEmeContact',item.id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Emergency Contact"></i></a>&nbsp;
                                                        <a (click)="Delete(5,item.id)" *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Emergency Contact"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_9"> 8. Work Reference </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryData("\"employee_reference\"")'><i class="fa fa-history"
                                                data-toggle="tooltip" title="History"></i></button> -->
                                        <button type="button" *ngIf="!hasEmpRef()" class="btn btn-sm dark btn-outline"
                                            data-toggle="modal" href="#emp-ref" (click)="editempInfo('EmpReference',0)"
                                            style="float:right"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Reference"></i></button>
                                        &nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_9" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Reference Received</th>
                                                    <th class="text-center">Date Received</th>
                                                    <th class="text-center">Date Sent</th>
                                                    <th class="text-center" style="width: 20%;">Attachment
                                                    </th>
                                                    <th class="text-center" style="width: 2%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of empRef">
                                                <tr>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom" title="Yes">
                                                            {{item.required}}
                                                        </div>
                                                    </td>
                                                    <!-- <td>{{item.dateOfRec}}</td> -->

                                                    <td>{{item.dateOfRec | date :'dd/MM/yyyy'}}</td>
                                                    <td>{{item.dateSent | date :'dd/MM/yyyy'}}</td>
                                                    <!-- <td>{{item.file_Name}}</td> -->
                                                    <td><a [href]="''+item.emp_Doc_Path"
                                                            target="_blank">{{item.file_Name}}</a></td>
                                                    <!-- <td>{{item.relationship}}</td> -->
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-ref"
                                                            (click)="editempInfo('EmpReference',item.emp_Id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Reference"></i></a>&nbsp;
                                                        <a (click)="Delete(6,item.id)" *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Reference"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_10"> 9. Payroll Details </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryData("\"employee_bank\"")'><i class="fa fa-history"
                                                data-toggle="tooltip" title="History"></i></button> -->
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#emp-bank" (click)="editempInfo('EmpBank',0)" style="float:right"><i
                                                class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Bank Info"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_10" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="row" *ngIf="employeeSummary != null">
                                        <label class="control-label col-md-1">NI Number</label>
                                        <div class="col-md-3 form-group">
                                            <input type="text" class="form-control" autocomplete="off" id="NiNo"
                                                name="NiNo" value="{{this.employeeSummary.niNo}}" />
                                        </div>

                                        <label class="control-label col-md-1">Loan</label>
                                        <div class="col-md-3 form-group">
                                            <input type="text" class="form-control" id="Loan" name="Loan"
                                                value="{{this.employeeSummary.loan}}" />
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <button type="button" style="width: 100px;" class="btn btn-outline blue"
                                                (click)="updateNiNumber(employeeSummary.emp_Id)">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Bank Name<span class="text-danger"> *
                                                        </span></th>
                                                    <th class="text-center" style="width: 20%;">Account Holder<span
                                                            class="text-danger"> * </span></th>
                                                    <!-- <th class="text-center" style="width: 15%;">Account Type</th> -->
                                                    <th class="text-center" style="width: 15%;">Account No.<span
                                                            class="text-danger"> * </span></th>
                                                    <th class="text-center" style="width: 15%;">Sort Code<span
                                                            class="text-danger"> * </span></th>
                                                    <th class="text-center" style="width: 2%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of empBank">
                                                <tr>
                                                    <td>{{item.bank_Name}}</td>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.account_Holder}}">{{item.account_Holder}}
                                                        </div>
                                                    </td>
                                                    <!-- <td>{{item.account_Type}}</td> -->
                                                    <td>{{item.account_No}}</td>
                                                    <td>{{item.bank_Code}}</td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#emp-bank"
                                                            (click)="editempInfo('EmpBank',item.emp_Bank_Id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Payroll Info"></i></a>&nbsp;
                                                        <a (click)="Delete(7,item.emp_Bank_Id)" *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Payroll Info"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_11"> 10. Right To Work </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryData("\"employee_righttowork\"")'><i
                                                class="fa fa-history" data-toggle="tooltip"
                                                title="History"></i></button> -->
                                        <button type="button" *ngIf="!hasEmpRtw()" class="btn btn-sm dark btn-outline"
                                            data-toggle="modal" href="#emp-right-to-work"
                                            (click)="editempInfo('EmpRightToWork',0)" style="float:right"><i
                                                class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Right To Work"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_11" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th style="width:8%;" class="text-center">Nationality<span
                                                            class="text-danger"> * </span></th>
                                                    <th class="text-center" style="width: 20%;">ECS Status</th>
                                                    <th class="text-center" style="width: 20%;">RTW Status type</th>
                                                    <th class="text-center" style="width: 10%;">Sponsored Employee</th>
                                                    <th class="text-center" style="width: 2%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of emp_righttoworkview">
                                                <tr>
                                                    <td>{{item.nationality_Name}}</td>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.ecsStatus}}">{{item.ecsStatus}}
                                                        </div>
                                                    </td>
                                                    <td>{{item.rtW_VisaStatus_Name}}</td>
                                                    <!-- <td>{{item.ecsExpiryDate  | date :'dd/MM/yyyy'}}</td> -->
                                                    <td>{{item.stringisSponsoredEmployee}}</td>
                                                    <td class="text-center">
                                                        <!-- <a (click)="downloadDocument(item.emp_Doc_Id,item.doc_Path)"><i
                                                                class="fa fa-download purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Download Document"></i></a>&nbsp; -->
                                                        <a data-toggle="modal" href="#emp-right-to-work"
                                                            (click)="editempInfo('EmpRightToWork',item.rightToWork_Id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Right to work"></i></a>&nbsp;
                                                        <a (click)="Delete(10,item.rightToWork_Id)"
                                                            *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Right to work"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_12"> 11. Additional Document </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <!-- <button type="button" class="btn btn-sm dark btn-outline"
                                            (click)='getHistoryData("\"employee_document\"")'><i class="fa fa-history"
                                                data-toggle="tooltip" title="History"></i></button> -->
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#emp-document" (click)="editempInfo('EmpDocument',0)"
                                            style="float:right"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Document"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_12" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Upload File Name<span class="text-danger"> *
                                                        </span></th>
                                                    <!-- <th class="text-center">Document Type</th> -->
                                                    <th class="text-center" style="width: 5%"> Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of empDocuments">
                                                <tr>
                                                    <td>
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="!item.isActive"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        <div class="short_description col-md-10 col-xs-12"
                                                            data-toggle="tooltip" data-placement="bottom"
                                                            title="{{item.file_Name}}">{{item.file_Name}}
                                                        </div>
                                                    </td>
                                                    <!-- <td>
                                                        {{item.emp_Doc_Type}}
                                                    </td> -->
                                                    <td class="text-right">
                                                        <a
                                                            (click)="downloadDocument(item.emp_Doc_Id,item.emp_Doc_Path)"><i
                                                                class="fa fa-download purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Download Document"></i></a>&nbsp;
                                                        <a data-toggle="modal" href="#emp-document"
                                                            (click)="editempInfo('EmpDocument',item.emp_Doc_Id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Document"></i></a>&nbsp;
                                                        <a (click)="Delete(12,item.emp_Doc_Id)" *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Document"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_13"> 12. Leave </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#EmpLeave" (click)="editempInfo('EmpLeave',0)" style="float:right"><i
                                                class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Document"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_13" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Leave Type</th>
                                                    <th class="text-center">Total Leave</th>
                                                    <th class="text-center">Taken Leave</th>
                                                    <th class="text-center">Balance Leave</th>
                                                    <!-- <th class="text-center" style="width: 5%"> Actions </th> -->
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of empLeaveBalance">
                                                <tr>

                                                    <td class="text-center">
                                                        {{item.leave_Type}} ({{item.leave_Type_Code}})
                                                    </td>
                                                    <td class="text-center">
                                                        {{item.total_leave}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{item.taken_leave}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{item.balance_leave}}
                                                    </td>
                                                    <!-- <td class="text-right">
                                                        <a (click)="downloadDocument(item.emp_Doc_Id,item.emp_Doc_Path)"><i
                                                                class="fa fa-download purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Download Document"></i></a>&nbsp;
                                                        <a data-toggle="modal" href="#emp-document"
                                                            (click)="editempInfo('EmpDocument',item.emp_Doc_Id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Document"></i></a>&nbsp;
                                                        <a (click)="Delete(12,item.emp_Doc_Id)">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Document"></i></a>
                                                    </td> -->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle popoverTitle accordion-toggle-styled"
                                        data-toggle="collapse" data-parent="#accordion1" href="#accordion1_14">
                                        13. Equality and Diversity Monitoring
                                    </a>
                                    <span class="popoverTitle" data-toggle="popover" data-trigger="hover"
                                        data-container="body" data-placement="top" data-html="true" id="equality">
                                        <span class="glyphicon glyphicon-info-sign"></span>
                                    </span>
                                    <span style="float:right ;margin-top: -6px" *ngIf="person_equalityInfo != null">
                                        <button class="btn btn-sm dark btn-outline pull-right" type="button"
                                            data-toggle="modal" href="#equality_info"
                                            *ngIf="person_equalityInfo != null"
                                            (click)="editempInfo('CandidatePersonEquality',person_equalityInfo.id)"
                                            data-backdrop="static" data-keyboard="false"><i class="fa fa-edit"
                                                data-toggle="tooltip" data-placement="bottom"
                                                title="Edit"></i></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <span style="float:right ;margin-top: -6px" *ngIf="person_equalityInfo == null">
                                        <button class="btn btn-sm dark btn-outline pull-right" type="button"
                                            data-toggle="modal" href="#equality_info"
                                            *ngIf="person_equalityInfo == null"
                                            (click)="editempInfo('CandidatePersonEquality',0)" data-backdrop="static"
                                            data-keyboard="false"><i class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Add"></i></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <div id="popover-content-equality" class="hide">
                                        The information requested in this form is required for us to monitor
                                        our Equality and Diversity Policy and we encourage you complete
                                        this form in full to assist us with this. The information provided is to
                                        be used solely for monitoring and to comply with our legal
                                        obligations in relation to the Equality Act 2010. Any information
                                        provided in this form will be treated in the strictest confidence.
                                        The data provided will be processed and held for the specified
                                        purposes and in accordance with data protection legislation, as set
                                        out in our Data Protection Policy and the Privacy Notice issued to
                                        you.
                                    </div>
                                </h4>

                            </div>
                            <div id="accordion1_14" class="panel-collapse collapse">
                                <div class="panel-body">

                                    <div class="row mt-0 mb-0">
                                        <label class="control-label col-md-2">Gender<span class="required"> *
                                            </span></label>
                                        <div class="col-md-4 form-group">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                value="{{person_equalityInfo?.gender}}" readonly />
                                        </div>

                                        <label class="control-label col-md-2">Marital Status<span class="required"> *
                                            </span></label>
                                        <div class="col-md-4 form-group">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                value="{{person_equalityInfo?.maritalStatus}}" readonly />
                                        </div>

                                        <label class="control-label col-md-2"><span
                                                *ngIf="person_equalityInfo?.gender === 'Other'">If Other, please
                                                specify</span></label>
                                        <div class="col-md-4">
                                            <input type="text" class="form-control form-group readonlyClass"
                                                autocomplete="off" *ngIf="person_equalityInfo?.gender === 'Other'"
                                                value="{{person_equalityInfo?.gender_Other}}" readonly />
                                        </div>
                                        <label class="control-label col-md-2"><span
                                                *ngIf="person_equalityInfo?.maritalStatus === 'Other'">If Other, please
                                                specify</span></label>
                                        <div class="col-md-4">
                                            <input type="text" class="form-control form-group readonlyClass"
                                                autocomplete="off"
                                                *ngIf="person_equalityInfo?.maritalStatus === 'Other'"
                                                value="{{person_equalityInfo?.marital_Other}}" readonly />
                                        </div>
                                    </div>

                                    <div class="row mt-0 mb-0">
                                        <label class="control-label col-md-2">Sexual Orientation<span class="required">
                                                * </span></label>
                                        <div class="col-md-4 form-group">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                value="{{person_equalityInfo?.sexual_Orientation}}" readonly />
                                        </div>
                                        <label class="control-label col-md-2">Age<span class="required"> *
                                            </span></label>
                                        <div class="col-md-4 form-group">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                value="{{person_equalityInfo?.age}}" readonly />
                                        </div>
                                        <label class="control-label col-md-2"><span
                                                *ngIf="person_equalityInfo?.sexual_Orientation === 'Other'">If Other,
                                                please specify<span class="required"> * </span></span></label>
                                        <div class="col-md-4">
                                            <input type="text" class="form-control  form-group readonlyClass"
                                                autocomplete="off"
                                                *ngIf="person_equalityInfo?.sexual_Orientation === 'Other'"
                                                value="{{person_equalityInfo?.sexual_Other}}" readonly />
                                        </div>
                                    </div>

                                    <div class="row mt-0 mb-0">
                                        <label class="control-label col-md-2">
                                            Disability<span class="required"> * </span>
                                            <span class="popoverTitle" data-toggle="popover" data-trigger="hover"
                                                data-container="body" data-placement="left" type="button"
                                                data-html="true" id="disability">
                                                <span class="glyphicon glyphicon-info-sign"></span>
                                            </span>
                                        </label>
                                        <div id="popover-content-disability" class="hide">
                                            The Equality Act 2010 defines a disabled person
                                            as someone who has a mental or physical
                                            impairment that has a substantial and long-term
                                            adverse effect on the person’s ability to carry out
                                            normal day-to-day activities. If you have a
                                            condition which fits the Equality Act definition,
                                            please tick ‘yes’ even if you are not limited by
                                            your condition.
                                            Do you consider yourself to have a disability?
                                        </div>
                                        <div class="col-md-4 form-group pos-relative">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                value="{{person_equalityInfo?.disability}}" readonly />
                                        </div>

                                        <label class="control-label col-md-2">Religion or Belief<span class="required">
                                                * </span></label>
                                        <div class="col-md-4 form-group">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                value="{{person_equalityInfo?.religionOrBelief}}" readonly />
                                        </div>
                                    </div>

                                    <div class="row mt-0 mb-0">
                                        <label class="control-label col-md-2">Is the gender you identify with the same
                                            as your gender
                                            registered at birth?<span class="required"> * </span></label>
                                        <div class="col-md-4 form-group">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                value="{{person_equalityInfo?.gender_Birth}}" readonly />
                                        </div>
                                        <label class="control-label col-md-2">
                                            Ethnicity<span class="required"> * </span>
                                            <span class="popoverTitle" data-toggle="popover" data-trigger="hover"
                                                data-placement="left" type="button" data-html="true" id="ethnicity">
                                                <span class="glyphicon glyphicon-info-sign"></span>
                                            </span>
                                        </label>
                                        <div id="popover-content-ethnicity" class="hide">
                                            Ethnic origin is not about nationality, place of
                                            birth or citizenship. It is about what best
                                            describes your ethnic group or background.
                                            Please choose one option and tick the
                                            appropriate box below.
                                            What is your ethnic group?
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                value="{{person_equalityInfo?.ethnicity}}" readonly />
                                        </div>
                                    </div>
                                    <!-- 
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="control-label col-md-2">Gender</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.gender}}" readonly />
                                            </div>

                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.gender === 'Other'"
                                                    value="{{person_equalityInfo?.gender_Other}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">Marital Status</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.maritalStatus}}" readonly />
                                            </div>

                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.maritalStatus === 'Other'"
                                                    value="{{person_equalityInfo?.marital_Other}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">Sexual Orientation</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.sexual_Orientation}}" readonly />
                                            </div>

                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off" *ngIf="person_equalityInfo?.sexual_Orientation === 'Other'"
                                                    value="{{person_equalityInfo?.sexual_Other}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">Age</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.age}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">
                                                Disability
                                                <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-container="body"
                                                    data-placement="left" type="button" data-html="true" id="disability">
                                                    <span class="glyphicon glyphicon-info-sign"></span>
                                                </span>
                                            </label>
                                            <div id="popover-content-disability" class="hide">
                                                The Equality Act 2010 defines a disabled person
                                                as someone who has a mental or physical
                                                impairment that has a substantial and long-term
                                                adverse effect on the person’s ability to carry out
                                                normal day-to-day activities. If you have a
                                                condition which fits the Equality Act definition,
                                                please tick ‘yes’ even if you are not limited by
                                                your condition.
                                                Do you consider yourself to have a disability?
                                            </div>
                                            <div class="col-md-4 form-group pos-relative">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.disability}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">Religion or Belief</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.religionOrBelief}}" readonly />
                                            </div>
                    
                                            <label class="control-label col-md-2">Is the gender you identify with the same as your gender
                                                registered at birth?</label>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.gender_Birth}}" readonly />
                                            </div>
                                            <label class="control-label col-md-2">
                                                Ethnicity
                                                <span class="popoverTitle" data-toggle="popover" data-trigger="hover" data-placement="left" type="button"
                                data-html="true" id="ethnicity">
                                <span class="glyphicon glyphicon-info-sign"></span>
                            </span>
                                            </label>
                                            <div id="popover-content-ethnicity" class="hide">
                                                Ethnic origin is not about nationality, place of
                                                birth or citizenship. It is about what best
                                                describes your ethnic group or background.
                                                Please choose one option and tick the
                                                appropriate box below.
                                                What is your ethnic group?
                                            </div>
                                            <div class="col-md-4 form-group">
                                                <input type="text" class="form-control readonlyClass" autocomplete="off"
                                                    value="{{person_equalityInfo?.ethnicity}}" readonly />
                                            </div>
                                        </div>
                                    </div> -->


                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                                        data-parent="#accordion1" href="#accordion1_15"> 14. Employee Relations
                                    </a>
                                    <span style="float:right ;margin-top:-6px">
                                        <button type="button" class="btn btn-sm dark btn-outline" data-toggle="modal"
                                            href="#EmpRelations" (click)="editempInfo('EmpRelations',0)" style="float:right"><i
                                                class="fa fa-plus fa-1x" data-toggle="tooltip"
                                                title="Add Employee Relations"></i></button>&nbsp;
                                    </span>
                                </h4>
                            </div>
                            <div id="accordion1_15" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-striped table-bordered table-hover">
                                            <thead class="flip-content">
                                                <tr>
                                                    <th class="text-center">Sanction
                                                    </th>
                                                    <th class="text-center">Live Date</th>
                                                    <th class="text-center">Expiry Date
                                                    </th>
                                                    <th class="text-center">Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let item of empRelations">
                                                <tr>

                                                    <td class="text-center">
                                                        <div class="col-md-1 col-xs-12">
                                                            <div class="circle greenColor" *ngIf="!item.statusfordate"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Active"></div>
                                                            <div class="circle redColor" *ngIf="item.statusfordate"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Inactive"></div>
                                                        </div>
                                                        {{item.c_name}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{item.live_Date | date :'dd/MM/yyyy'}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{item.exp_Date | date :'dd/MM/yyyy'}}
                                                    </td>
                                                    <td class="text-center">
                                                        <a data-toggle="modal" href="#EmpRelations"
                                                            (click)="editempInfo('EmpRelations',item.id)"><i
                                                                class="fa fa-edit purple iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Edit Employee Relations"></i></a>
                                                        <!-- <a (click)="Delete(14,item.id)"
                                                            *ngIf="!item.isActive">
                                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                                data-toggle="tooltip" data-placement="top"
                                                                title="Delete Employee Relations"></i></a> -->
                                                    </td>
                                                
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="personal-info" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Personal Details</h4>
            </div>
            <div class="modal-body">
                <app-emppersonalinfo></app-emppersonalinfo>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('person')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(1)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="job-details" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Current Job Details</h4>
            </div>
            <div class="modal-body">
                <app-empjobdetail></app-empjobdetail>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn dark btn-outline pull-left"
                    (click)="history('employee')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(3)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="address-info" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Address Details</h4>
            </div>
            <div class="modal-body">
                <app-empAddressInfo></app-empAddressInfo>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('person_address')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(2)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-contact" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Personal Contact Details</h4>
            </div>
            <div class="modal-body">
                <app-employeeContact></app-employeeContact>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('person_contact')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(4)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-eme-contact" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Emergency Contact</h4>
            </div>
            <div class="modal-body">
                <app-employeeEmergency></app-employeeEmergency>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('person_emergency')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(5)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-bank" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Payroll Details</h4>
            </div>
            <div class="modal-body">
                <app-employeeBank></app-employeeBank>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('employee_bank')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(7)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-ref" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Work Reference</h4>
            </div>
            <div class="modal-body">
                <app-employeeRef></app-employeeRef>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('person_reference')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(6)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-probation" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Probation</h4>
            </div>
            <div class="modal-body">
                <app-employeeprobation></app-employeeprobation>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('employee_probation')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(8)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-contract" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Career History</h4>
            </div>
            <div class="modal-body">
                <app-employeecontract></app-employeecontract>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('employee_contract')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(9)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-right-to-work" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Right To Work</h4>
            </div>
            <div class="modal-body">
                <app-employeerightwork></app-employeerightwork>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('employee_righttowork')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(10)" class="btn blue">Save</button>
                <!-- <button type="button" (click)="addDocumentsonRTW()" class="btn blue">Save</button> -->
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-salary" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Salary</h4>
            </div>
            <div class="modal-body">
                <app-employeesalary></app-employeesalary>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('employee_salary')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(11)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="emp-document" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Additional Document</h4>
            </div>
            <div class="modal-body">
                <app-employeedocument></app-employeedocument>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('employee_document')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="addDocuments()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="EmpLeave" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Employee Leave</h4>
            </div>
            <div class="modal-body">
                <app-emp-leave></app-emp-leave>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('employee_leave')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" class="btn blue" (click)="addLeaveBalance()">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="EmpRelations" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Employee Relations</h4>
            </div>
            <div class="modal-body" style="margin-left: 15px; margin-right: 15px;">
                
                <form [formGroup]="empRelationsform">
                <div class="row">
                    <div class="col-md-6 mb-15">
                        <label class="form-label">Current Sanction<span class="text-danger">*</span></label>
                        <select class="form-control" id="current_Sanction_Id" formControlName="current_Sanction_Id">
                            <option value=''>Select Current Sanction</option>
                            <option *ngFor="let item of currentsanctionlist;" value={{item.id}}>
                                {{item.name}}</option>
                        </select>
                    </div>
                    <div class="col-md-6 mb-15">
                        <label class="form-label">Live Date<span class="text-danger">*</span></label>
                        <div class="input-group" id="flatpickrooenLivedate" data-wrap="true">
                            <input type="text"  id="LiveDate"
                                class="form-control readonlyClass" data-input>
                            <span class="input-group-btn" data-toggle>
                                <button class="btn default" type="button">
                                    <i class="fa fa-calendar"></i>
                                </button>
                            </span>
                        </div>
                      
                    </div>
                    <div class="col-md-6 mb-15">
                        <label class="form-label">Expiry Date<span class="text-danger">*</span></label>
                        <div class="input-group" id="flatpickropenExpdate" data-wrap="true">
                            <input type="text"  id="ExpDate"
                                class="form-control readonlyClass" data-input>
                            <span class="input-group-btn" data-toggle>
                                <button class="btn default" type="button">
                                    <i class="fa fa-calendar"></i>
                                </button>
                            </span>
                        </div>
                        
                    </div>
                    <div class="col-md-6 mb-15">
                        <label class="form-label">Issuing Manager<span class="text-danger">*</span></label>
                        <!-- <ng-select2 [data]="empmanagerList" id="issuing_Manger_Emp_Id" formControlName="issuing_Manger_Emp_Id"
                        [placeholder]="'--- Select ---'" [options]="{multiple: false,tags: false}"
                        >
                    </ng-select2>  -->
                    <!-- <select class="form-control" id="issuing_Manger_Emp_Id" formControlName="issuing_Manger_Emp_Id">
                        <option value=''>Select Issuing Manager</option>
                        <option *ngFor="let item of empmanagerList;" value={{item.id}}>
                            {{item.text}}</option>
                    </select> -->
                    <input  
                    type="text"
                    formControlName="issuing_Manger_Emp_Id"
                    placeholder="Enter Manager's Name or Employee ID" class="form-control"
                    [formControl]="searchControl"
                    list="empmanagerListdata"  autocomplete="off"
                    id="issuing_Manger_Emp_Id"
                    
                   
                  />
                  
                  <datalist id="empmanagerListdata">
                    <option *ngFor="let item of empmanagerList;" [value]="item.text">
                        {{item.empcode}}  
                    </option>
                </datalist>
                    </div>
                    <div class="col-md-12">
                        <label class="form-label">Sanction Description<span class="text-danger">*</span></label>
                        <textarea class="form-control" formControlName="sanction_Description" id="sanction_Description_Id"></textarea>
                    </div>
                </div>
            </form>
            </div>

            <div class="modal-footer">
                <button type="button" *ngIf="editMode" class="btn  dark btn-outline pull-left"
                    (click)="history('employee_relations')">
                    <i class="fa fa-history"></i>
                </button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline" (click)="empRelationscloset()">Close</button>
                <button type="button" class="btn blue" (click)="empRelationsUpsert()">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="historyModal" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">History</h4>
            </div>
            <div class="modal-body">
                <app-history></app-history>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="modalEmployeeDocument" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Employee Document</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <iframe [src]='fileUrl' width="100%" height="600px"></iframe>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade draggable-modal" id="equality_info" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Equality and Diversity Monitoring</h4>
            </div>
            <div class="modal-body">
                <app-candidatepersonequality></app-candidatepersonequality>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="updateEmpInfo(12)" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="leaverModal" role="basic" aria-hidden="true" (hidden)="onModalHidden()">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseClick()">
                    <span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">Edit Leaver</h4>
            </div>
            <div class="modal-body">

                <form [formGroup]="empResignationform">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Employee<span class="required"> *
                                    </span></label>
                                <ng-select2 [data]="empList" id="emp_Id" formControlName="emp_Id"
                                    [placeholder]="'--- Select ---'" [options]="{multiple: false,tags: false}"
                                    (valueChanged)="getReasionForLeaveList($event)">
                                </ng-select2>


                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Reason For Leaving<span class="required"> *
                                    </span></label>

                                <select class="form-control" id="leaveReason_Id" formControlName="leaveReason_Id"
                                    autocomplete="off">
                                    <option value="">Reason For Leaving</option>
                                    <option *ngFor="let item of reasonforleavelist;" value={{item.id}}>
                                        {{item.name}}</option>

                                </select>
                                <!--  -->
                            </div>
                        </div>


                        <div class="col-md-4">
                            <div class="form-group">
                                <input type="hidden" id="noticePeriod" formControlName="noticePeriod"
                                    value="{{noticePeriodInput.value}} {{ noticePeriodType.value}}">
                                <label class="control-label" style="width: 100%;">Notice Period<span class="required">
                                        *</span></label>
                                <input type="text" id="noticePeriodInput" class="form-control"
                                    (change)="autoCompleteLeaveDateNotice()" formControlName="noticePeriodInput"
                                    style="width: 50%;float: left;" autocomplete="off" #noticePeriodInput
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    maxlength="3">
                                <select class="form-control" formControlName="noticePeriodType" id="noticePeriodType"
                                    style="width: 50%;float: right;background-color: #eef1f5;" #noticePeriodType>
                                    <option value="Days">Days</option>
                                    <option value="Weeks">Weeks</option>
                                    <option value="Months" selected>Months</option>
                                    <!-- <option value="Years">Years</option> -->
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Date of Notice<span class="required"> *
                                    </span></label>
                                <div class="input-group" id="flatpickropenResD" data-wrap="true">
                                    <input type="text" (change)="autoCompleteLeaveDate()" id="ResignationDate"
                                        class="form-control" data-input>
                                    <span class="input-group-btn" data-toggle>
                                        <button class="btn default" type="button">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                    </span>
                                </div>

                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Leave Date<span class="required"> *
                                    </span></label>
                                <div class="input-group" id="flatpickropenLeaD" data-wrap="true">
                                    <input type="text" id="LeaveDate" class="form-control" data-input>
                                    <span class="input-group-btn" data-toggle>
                                        <button class="btn default" type="button">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Last Working Date<span class="required"> *
                                    </span></label>
                                <div class="input-group" id="flatpickropenLastWD" data-wrap="true">
                                    <input type="text" id="LastWorkingDate" class="form-control" data-input>
                                    <span class="input-group-btn" data-toggle>
                                        <button class="btn default" type="button">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-8">
                            <div class="form-group">
                                <label class="control-label">Notes</label>
                                <input type="text" id="notes" class="form-control" formControlName="notes">
                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="form-group">
                                <label class="control-label">Active</label>
                                <div class="md-checkbox-inline">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="checkbox1_3" class="md-check"
                                            formControlName="isActive">
                                        <label for="checkbox1_3">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">

                            <form [formGroup]="empResignationform">
                                <div class="table-responsive">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Select Document</th>
                                                <th>Select File</th>
                                                <th style="width: 5%;"><button type="button"
                                                        class="btn pull-right green addMorefields2"
                                                        (click)="addItem(null)"><i class="fa fa-plus"
                                                            aria-hidden="true"></i></button></th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="emp_document"
                                            *ngFor="let item of empResignationform.get('emp_document')['controls']; let i = index;">

                                            <tr [formGroupName]="i">
                                                <td>
                                                    <select class="form-control" id="Doc_Id{{i}}"
                                                        formControlName="Doc_Id">
                                                        <option value=''>Select Document Type</option>
                                                        <option *ngFor="let item of documentList;" value={{item.id}}>
                                                            {{item.name}}</option>
                                                    </select>
                                                </td>

                                                <td>
                                                    <div class="fileinput fileinput-new" id="EmployeeDocument{{i}}"
                                                        data-provides="fileinput">
                                                        <div class="input-group input-large">
                                                            <div class="form-control uneditable-input input-fixed input-medium"
                                                                data-trigger="fileinput">
                                                                <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                                                <span class="fileinput-filename"
                                                                    *ngIf="(empResignationform.get('emp_document').value[i].Emp_Doc_Id) == 0">
                                                                </span>
                                                                <span class="fileinput-filename"
                                                                    *ngIf="(empResignationform.get('emp_document').value[i].Emp_Doc_Id)>0">
                                                                    {{empResignationform.get('emp_document').value[i].file_Name}}
                                                                </span>
                                                            </div>
                                                            <span class="input-group-addon btn default btn-file">
                                                                <span class="fileinput-new"> Select file </span>
                                                                <span class="fileinput-exists"> Change </span>
                                                                <input type="file" id="Emp_Doc_Path{{i}}"
                                                                    (change)="fileChangeMultiple($event,i)">
                                                            </span>
                                                            <a href="javascript:;" id="removeEmpResignation{{i}}"
                                                                (click)="removeDoc(i)"
                                                                class="input-group-addon btn red fileinput-exists"
                                                                data-dismiss="fileinput">
                                                                Remove </a>
                                                        </div>
                                                    </div>
                                                </td>


                                                <td>
                                                    <button type="button" (click)="removeItem(i)"
                                                        class="btn pull-right red"><i class="fa fa-minus"
                                                            aria-hidden="true"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </form>

                        </div>
                    </div>


                </form>

            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="Upsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="modalJobDescription1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Job Description</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <iframe [src]='fileUrlmodalJobDescription' width="100%" height="600px"></iframe>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>