import { Component, OnInit } from '@angular/core';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
import { retry } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Component({
   selector: 'app-hr-policies-document',
   templateUrl: './hr-policies-document.component.html',
})
export class HrPoliciesDocumentComponent implements OnInit {

   hr_policiesform: FormGroup;
   items: FormArray;
   hr_policies_documentList: any;
   documentName: "";
   p: number = 1;
   totalRecord: 0;
   id:number=0;
   filterList: any;
   docFiles: string[] = [];
   filename: string = "test.pdf";
   fileUrl: any;
   modalOpen:boolean=false;
   currentUserRole: any;

   constructor(
      private toastr: ToastrService,
      private commonApiService: CommonApiService,
      private commonService: CommonService,
      private sanitizer: DomSanitizer,
      private spinner: NgxSpinnerService,
      private router: Router,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {
   
      this.getHrPoliciesList();
      this.currentUserRole =JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "Admin" ? true : false || JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "HR Manager" ? true : false|| JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "HR Executive" ? true : false
  console.log(this.currentUserRole);
   }
 
   updateStatus(Id, status = null) {
      this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
        if (result.value) {
          this.commonApiService.commonUpdateStatus(Commonvariable.Hr_Policies_Document, Id, status).subscribe((response) => {
  
            if (response["status"] == 200) {
              this.getHrPoliciesList();
              this.toastr.success(response["message"], 'Hr Policies');
            }
  
          })
        }
  
      });
    }
    onItemsPerPageChange() {
      this.p = 1;
      this.getHrPoliciesList();
    }
    

  private requestCounter = 0;
  itemsPerPageOptions: number[] = [10, 20, 50,100];
  itemsPerPage: number = 10;
  async getHrPoliciesList(PageIndex = 1, sortColumn: string = 'id', sortDirection: string = 'desc'): Promise<void> {
    const currentCounter = ++this.requestCounter;

    try {
     
        this.totalRecord = 0;
        this.hr_policies_documentList = [];
       // this.filterEmp = [];
        this.p = PageIndex;

        let requestData;

     
          requestData = {
            searchBy: [],
            PageIndex: PageIndex - 1,
            CommonSearch: $("#filterList").val().trim(),
            PageSize: this.itemsPerPage,
            OrderBy: sortColumn +" "+ sortDirection,
            //SortDirection: sortDirection
          };
      

        this.hr_policies_documentList = [];
        this.totalRecord = 0;

        const result = await this.commonApiService.GetByPaginated(Commonvariable.Hr_Policies_Document, requestData, "GetPaginated").toPromise();

        //if (currentCounter === this.requestCounter) {
        if (requestData.CommonSearch == $("#filterList").val().trim()) {
          // Check if this is the last request
          if (result != null && result["status"] === 200) {
            this.hr_policies_documentList = [];
            if (result["data"] !== "" && result["data"]["list"] !== undefined) {
              this.totalRecord = result["data"]["recordCount"];
              this.hr_policies_documentList = result["data"]["list"];
            }
          }

          setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
          }, 500);
        }
        else {
          this.spinner.hide();
        }
      
    } catch (error) {

    }
  }

  //  getHrPoliciesList(PageIndex = 1) {

  //     this.p = PageIndex;
  //     this.totalRecord = 0;
  //     this.hr_policies_documentList = []
  //     this.commonApiService.getListWithFilter(Commonvariable.Hr_Policies_Document, PageIndex, $("#filterList").val()).subscribe((result) => {
  //       if (result != null && result["data"] != "") {
  //         this.totalRecord = result["data"]["recordCount"]
  //         this.hr_policies_documentList = result["data"]["list"];
  //         this.filterList = result["data"]["list"];
  //         setTimeout(() => {
  //           $('[data-toggle="tooltip"]').tooltip();
  //         }, 500);
  //       }
  //     })
  //   }


    viewDocument(id, path,filename) {
      
     // this.filename = "xyz" + "." + String(path).split('.')[1]
      this.commonApiService.getimage(Commonvariable.Hr_Policies_Document, id, 'DownloadDocument').subscribe((result) => {
         const blobData = new Blob([result], { type: result.type });
         const url = window.URL.createObjectURL(blobData);
   
         // Download the document
         const a = document.createElement('a');
         a.href = url;
         // Open the document in a new tab
         const newTab = window.open(url, '_blank');
         if (!newTab) {
           alert('Please allow pop-ups for this website');
         }
   
         // Cleanup
         window.URL.revokeObjectURL(url);
     
        
      })
    }


    downloadDocument(id, path,filename) {
      
     // this.filename = "xyz" + "." + String(path).split('.')[1]
      this.commonApiService.getimage(Commonvariable.Hr_Policies_Document, id, 'DownloadDocument').subscribe((result) => {
       
        const blobData = new Blob([result], { type: result.type });
        var createObjectURL=(window.URL.createObjectURL(blobData));
        this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);    

        if (result.type == "application/pdf") {
        const a = document.createElement('a');
        a.setAttribute('type', 'hidden');
        a.href = URL.createObjectURL(blobData);
        a.download = filename;
        a.click();
        a.remove();
        this.commonService.toaster("success", "Export success !")
        }

        
      })
    }

}


