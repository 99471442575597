import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';

declare var $: any;


@Component({
  selector: 'app-rightworkCandidate',
  templateUrl: './right-work-candidate.component.html'
})
export class RightWorkCandidateComponent implements OnInit {
  candidateForm: FormGroup;
  rtwCategoryList: any;
  documentList: any;
  cadidateDocId: number;
  editDocumentId: number = 0;
  fileName: any;




  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {

    this.candidateForm = this.fb.group({
      id: ['0'],
      onboarding_Id: ['0'],
      rwC_Id: ['', Validators.required],
      issueAuthority: ['', Validators.required],
      issueCountry: ['', Validators.required],
      issueDate: ['', Validators.required],
      expiryDate: ['', Validators.required],
      emp_Doc_Id: [null],
      Doc_Name:[null],
      notes: [''],
      isActive: ['1'],
      updatedBy: [Commonvariable.User_Id],
      doc_Id: ['', Validators.required],
      files: [''],

    });
    $('.fileinput-filename').html('');
    $('.fileinput.fileinput-exists').removeClass('fileinput-exists').addClass('fileinput-new');
    $('.input-group.input-large').removeAttr('disabled');
    this.commonService.flatpickr("flatpickropenissueDate", 0)
    this.commonService.flatpickr("flatpickropenexpiryDate", 0)

    
    this.getDocument();
  }

  changeShowrtwDoc(event: any) {

    if (event.target.value) {
      $(".rtwDecumentBody").show();
    }
    else {
      $(".rtwDecumentBody").hide();
    }


  }


  getRTWCategory(cID) {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "company_Id",
          "FieldValue": cID,
          "Parameter": "=="
        }
      ],
      "OrderBy": "name"
    }


    this.commonApiService.GetByPaginated(Commonvariable.RightToWork_Category, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.rtwCategoryList = response["data"].sort((a, b) => a.name.localeCompare(b.name));
      }
    })
  }



  getDocument() {
    this.commonApiService.List(Commonvariable.Document).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.documentList = response["data"].sort((a, b) => a.name.localeCompare(b.name));
      }
    })
  }

  Edit(Id) {

    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.Onboarding_RightToWork, Id).subscribe((response) => {
        if (response != null && response["data"] != "") {
          var editData = response["data"];
          this.editDocumentId = editData[0].doc_Id
          let issueDate = response["data"][0].issueDate != null ? response["data"][0].issueDate.split("T")[0].split("-") : "";
          let expiryDate = response["data"][0].expiryDate != null ? response["data"][0].expiryDate.split("T")[0].split("-") : "";
          if (issueDate != "") {
            $("#issueDate").val(issueDate[2] + "-" + issueDate[1] + "-" + issueDate[0]);
          }
          if (expiryDate != "") {
            $("#expiryDate").val(expiryDate[2] + "-" + expiryDate[1] + "-" + expiryDate[0]);
          }

          response["data"][0].notes != null ? "" : formData.controls.notes.setValue(null)


          var formData = this.candidateForm;
          this.commonService.setEditData(formData, editData);
          formData.controls.updatedBy.setValue(Commonvariable.User_Id);

          this.commonApiService.getById(Commonvariable.Onboarding_Document, editData[0].doc_Id).subscribe((response) => {
            formData.controls.doc_Id.setValue(response['data'][0].doc_Id);
            formData.controls.Doc_Name.setValue(response['data'][0].file_Name);
            this.fileName =  String(response['data'][0].file_Name)
            $('.fileinput-filename').html(this.fileName);
            $('.fileinput.fileinput-new').removeClass('fileinput-new').addClass('fileinput-exists');
            $('.input-group.input-large').attr('disabled', 'disabled');
          })

          this.commonApiService.getimage(Commonvariable.Onboarding_Document, editData[0].doc_Id, 'Download').subscribe((result) => {
            const fileData = new ClipboardEvent('').clipboardData || new DataTransfer();
            fileData.items.add(new File([result],this.fileName));
            formData.controls.files.setValue(fileData.files);
          })


        }
      }, (error) => { this.toastr.error(error["error"].message, 'Candidate'); })
    }
  }



  validation() {
    var eForm = this.candidateForm;
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);
    eForm.controls.issueDate.setValue(this.commonService.dateFormatChange($("#issueDate").val()));
    eForm.controls.expiryDate.setValue(this.commonService.dateFormatChange($("#expiryDate").val()));
    if (!eForm.valid) {
      for (const key of Object.keys(eForm.controls)) {
        if (eForm.controls[key].invalid) {
          let fieldName = "";
          if (key == "rwC_Id") {
            fieldName = "Right to work category"
          }
          else if (key == "issueAuthority") {
            fieldName = "Authority"
          }
          else if (key == "issueCountry") {
            fieldName = "Country of Issue"
          }
          else if (key == "issueDate") {
            fieldName = "Issue date"
          }
          else if (key == "expiryDate") {
            fieldName = "Expiry Date"
          }
          else if (key == "doc_Id") {
            fieldName = "Document Type"
          }
          if (fieldName != "") {
            this.toastr.info(fieldName + " is required !", "Candidate");
            $("#" + key).focus();
            return false;
          }
        }
      }
    }
    else {

      if (eForm.controls.files.value == null) {
        this.toastr.info("Files is required !", "Candidate");
        $("#files").focus();
        return false;
      }

      if (eForm.controls.files.value == '' && eForm.controls.id.value == '0') {
        this.toastr.info("Files is required !", "Candidate");
        $("#files").focus();
        return false;
      }
      if ($("#issueAuthority").val().length < 2) {
        this.toastr.info("Authority must be at least 2 characters long !", "Candidate");
        $("#issueAuthority").focus();
        return false;
      }
      if ($("#issueAuthority").val().search(/^[-a-zA-Z]+(\s+[-a-zA-Z,-]+)*$/) != 0){
        this.toastr.info("Authority only alphabets allow !", "Candidate");
        $("#issueAuthority").focus();
        return false;
      }
      if ($("#issueCountry").val().length < 2) {
        this.toastr.info("Country of Issue must be at least 2 characters long !", "Candidate");
        $("#issueCountry").focus();
        return false;
      }

      // if ($("#City").val().search(/^[-a-zA-Z]+(\s+[-a-zA-Z]+)*$/) != 0) {
      // if ($("#issueCountry").val().search(/^[^-\s][a-zA-Z_\s/,-]+$/) != 0) {
      if ($("#issueCountry").val().search(/^[-a-zA-Z]+(\s+[-a-zA-Z,-]+)*$/) != 0)  {
        this.toastr.info("Country of Issue only alphabets allow !", "Candidate");
        $("#issueCountry").focus();
        return false;
      }
     
      if ($("#noteswe").val() != "" && (/["\\]/).test($("#noteswe").val())) {
        this.toastr.info("Notes does not allow Special Characters \" \\ ", "Candidate");
        $("#noteswe").focus();
        return false;
      }
      // if ($("#issueCountry").val().indexOf(" ") > -1) {
      //   this.toastr.info("Country of Issue not allow space !", "Candidate");
      //   $("#issueCountry").focus();
      //   return false;
      // }
    }
    
    return true;
  }

  fileChange(event) {
  
    // if (event.target.files.item(0).name.split('.')[1] == "pdf" || event.target.files.item(0).name.split('.')[1] == "doc"
    //   || event.target.files.item(0).name.split('.')[1] == "docx" || event.target.files.item(0).name.split('.')[1] == "xlsx") {
      this.candidateForm.controls.files.setValue(event.target.files[0]);
      this.candidateForm.controls.Doc_Name.setValue(event.target.files[0].name);
      
    // }
    // else {
    //   this.toastr.info("Allow only pdf,doc,docx,xlsx file format for upload !", "Employee");
    //   $("#remove").click();
    // }

  }

  removeFile(event){
    this.candidateForm.controls.files.setValue(null);
    this.candidateForm.controls.Doc_Name.setValue(null);
  }




}
