import { Component, OnInit, ModuleWithComponentFactories, HostListener } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;


@Component({
  selector: 'app-employeeBank',
  templateUrl: './emp-bank.component.html'
})
export class EmployeeBankComponent implements OnInit {
  empBankform: FormGroup;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    ) {
  }



  ngOnInit() {

    this.empBankform = this.fb.group({
      emp_Bank_Id: ['0'],
      emp_Id: ['0'],
      bank_Name: ['', Validators.required],
      account_Holder: ['', Validators.required],
      account_Type: [null],
      account_No: ['', Validators.required],
      bank_Code: ['', Validators.required],
      account_Title: ['0'],
      account_Code: [null],
      isPayed: ['0'],
      emp_Doc_Id: ['0'],
      isRequired: ['0'],
      notes: [''],
      version_Id: [''],
      isActive: ['1'],
      isReview: [1],
      updatedBy: [Commonvariable.User_Id]
    });
  }
  
  empEdit(Id) {
    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.EmpBank, Id).subscribe((response) => {
        if (response != null && response["data"] != "") {
          var editData = response["data"];
          var formData = this.empBankform;
          this.commonService.setEditData(formData, editData);
          this.empBankform.controls.updatedBy.setValue(Commonvariable.User_Id)
        }
      })
    }
  }


  validation() {
    var eForm = this.empBankform;
    //sForm.get("company_Id").setValue($("#company_Id").val()); 
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);
    if (!eForm.valid) {
      for (const key of Object.keys(eForm.controls)) {
        if (eForm.controls[key].invalid) {
          let fieldName = "";

          if (key == "bank_Name") {
            fieldName = "Bank name"
          }
          else if (key == "account_Holder") {
            fieldName = "Account holder"
          }
          else if (key == "account_No") {
            fieldName = "Account number"
          }
          else if (key == "bank_Code") {
            fieldName = "Sort code"
          }
          this.toastr.info(fieldName + " is required !", "Employee");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if ($("#bank_Name").val().length < 2) {
        this.toastr.info("Bank name must be at least 2 characters long !", "Employee");
        $("#bank_Name").focus();
        return false;
      }
      if ($("#bank_Name" ).val().search(/^[^-\s][a-zA-Z_\s-]+$/) != 0) {
        this.toastr.info("Bank Name only alphabets allow !", "Employee");
        $("#bank_Name").focus();
        return false;
     }
    //  if ($("#Bank_Name").val().search(/[^a-zA-Z]+/) > -1) {
    //     this.toastr.info("Bank Name only alphabets allow !", "Employee");
    //     $("#Bank_Name").focus();
    //     return false;
    //  }
      if ($("#account_Holder").val().length < 2) {
        this.toastr.info("Account holder must be at least 2 characters long !", "Employee");
        $("#account_Holder").focus();
        return false;
      }
       //comment on 28/12/2022 by Amit Prajapati
      if ($("#account_Holder" ).val().search(/^[^-\s][a-zA-Z_\s-]+$/) != 0) {
      //if ($("#account_Holder" ).val().search(/[^a-zA-Z\s]+/) != 0) {
        this.toastr.info("Account holder only alphabets allow !", "Employee");
        $("#account_Holder").focus();
        return false;
     }
    //  if ($("#account_Holder").val().search(/[^a-zA-Z]+/) > -1) {
    //     this.toastr.info("Account holder only alphabets allow !", "Employee");
    //     $("#account_Holder").focus();
    //     return false;
    //  }

      // if ($("#account_Type").val().length < 2) {
      //   this.toastr.info("Account type must be at least 2 characters long !", "Employee");
      //   $("#account_Type").focus();
      //   return false;
      // }
      // if ($("#account_Type").val().indexOf(" ") > -1) {
      //   this.toastr.info("Account type not allow space !", "Employee");
      //   $("#account_Type").focus();
      //   return false;
      // }
      if ($("#account_No").val().length < 5 || $("#account_No").val().length > 20) {
        this.toastr.info("Account no. between 5 to 20 digit long !", "Employee");
        $("#account_No").focus();
        return false;
      }
      // if ($("#bank_Code").val().length < 6) {
      //   this.toastr.info("Sort code must be at least 6 characters long !", "Employee");
      //   $("#bank_Code").focus();
      //   return false;
      // }
      // if ($("#bank_Code").val().length > 6) {
      //   this.toastr.info("Sort code not more than 6 characters !", "Employee");
      //   $("#bank_Code").focus();
      //   return false;
      // }

      // if ($("#bank_Code").val().search(/^[a-zA-Z0-9 -]+$/) != 0) {
      //   this.toastr.info("Please enter valid sort code !", "Employee");
      //   $("#bank_Code").focus();
      //   return false;
      // }

      // if ($("#bank_Code").val().indexOf(" ") > -1) {
      //   this.toastr.info("Sort code not allow space !", "Employee");
      //   $("#bank_Code").focus();
      //   return false;
      // }
    }


    return true;
  }




}
