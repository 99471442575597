import { Component, OnInit } from '@angular/core';
import { CommonApiService } from './../services/commonApi.service';
import { LoginService } from './../services/login.service';
import { Commonvariable } from '../models/commonvariable';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css']
})
export class LeftMenuComponent implements OnInit {

  datas: any;
  currentUserRole: string = "Admin";


  constructor(private commonApiService: CommonApiService, private loginService: LoginService, public router: Router) { }

  ngOnInit() {
    this.loginService.leftMenuRefresh.subscribe((res: any) => {
      this.getMenuList();
    })

    //this.currentUserRole = String(JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"]); 
  }




  getMenuList() {
    
    this.datas = [];
    if (localStorage.getItem('currentHRUser') != null) {
      let roleId = String(JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role_Id"]);
      this.commonApiService.getById(Commonvariable.Menu, roleId, "GetMasterMenuData").subscribe((result) => {

        result["data"].forEach(menu => {

         

          let tempChildData = [];
          menu.childData.forEach(childData => {
            let cData = {
              "icon": childData.icon,
              "leafData": childData.leafData,
              "menuName": childData.menuName,
              "parent_Id": childData.parent_Id,
              "sorting": childData.sorting,
              "collapse": false
            }
            tempChildData.push(cData)
          });
          menu.childData = tempChildData;
        });
        this.datas = result["data"];
      })
    }
  }

  collapseMenu(menuName) {
    this.datas.forEach(menu => {
      menu.childData.forEach(childData => {
        if (childData.menuName == menuName) {
          childData.collapse = !childData.collapse
        }
        else {
          childData.collapse = false
        }
      });
    });
  }

}
