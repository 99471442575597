<form [formGroup]="candidateForm">
    <div class="row">        
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Right To Work Document<span class="required"> * </span></label>                
                <select class="form-control" id="rwC_Id" formControlName="rwC_Id" (change)="changeShowrtwDoc($event)">
                    <option value="">Select Right To Work Document</option>
                    <option *ngFor="let item of rtwCategoryList;" value={{item.rwC_Id}}>{{item.name}}</option>
                </select>                
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Authority<span class="required"> * </span></label>
                <input type="text" class="form-control" id="issueAuthority" formControlName="issueAuthority"
                    autocomplete="off" maxlength="50">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Country of Issue<span class="required"> * </span></label>
                <input type="text" class="form-control" id="issueCountry" formControlName="issueCountry"
                    autocomplete="off" maxlength="50">
            </div>
        </div>
    </div>
    <div class="row">
        
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Date of Issue<span class="required"> * </span></label>
                <div class="input-group" id="flatpickropenissueDate" data-wrap="true">
                    <input type="text" class="form-control readonlyClass" id="issueDate" data-input>
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Date of Expiry<span class="required"> * </span></label>
                <div class="input-group" id="flatpickropenexpiryDate" data-wrap="true">
                    <input type="text" class="form-control readonlyClass" id="expiryDate" data-input>
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="empright" class="md-check" formControlName="isActive">
                            <label for="empright">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="rtwDecumentBody" style="display: block;">    
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label">Document Type<span class="required"> * </span></label>
                    <select class="form-control" id="doc_Id" formControlName="doc_Id">
                        <option value="">Select Document</option>
                        <option *ngFor="let item of documentList;" value={{item.id}}>
                            {{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label">Upload Document<span class="required"> * </span></label>
                    <div class="fileinput fileinput-new" data-provides="fileinput">
                        <div class="input-group input-large">
                            <div class="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                <span class="fileinput-filename"> </span>
                            </div>
                            <span class="input-group-addon btn default btn-file">
                                <span class="fileinput-new"> Select file </span>
                                <span class="fileinput-exists"> Change </span>
                                <input type="file" id="files" (change)="fileChange($event)"> </span>
                            <a (click)="removeFile($event)" class="input-group-addon btn red fileinput-exists" id="remove"
                                data-dismiss="fileinput">
                                Remove </a>
                        </div>
                        
                    </div>
                    
                </div>
            </div>             

        </div>
        <div class="row">
            
            <div class="col-md-12">
                <div class="form-group">
                    <label class="control-label">Document Notes</label>
                    <textarea class="form-control" id="noteswe" formControlName="notes" style="width: 100%;"> </textarea>
                </div>
            </div>        
        </div>
    </div>


</form>