<style>
    td.details-control {
        background: url('assets/pages/img/SD7Dz.png') no-repeat center center;
        cursor: pointer;
    }
    
    tr.shown td.details-control {
        background: url('assets/pages/img/d4ICC.png') no-repeat center center;
    }
</style>

<!-- <div class="page-head">
    <div class="page-title">
        <h1>Selection Candidate Wise
        </h1>
    </div>
</div> -->

<div class="col-md-12">
    <!-- BEGIN SAMPLE TABLE PORTLET-->
    <div class="portlet box grey">
        <div class="portlet-body">

            <diV class="row">
                <div class="col-md-9">
                    <a [href]="fileUrl" id="download" class="btn btn-transparent blue  btn-sm" style="visibility: hidden;" [download]="filename">Export</a>
                </div>
                <div class="col-md-3 text-right">
                    <input type="text" class="form-control" placeholder="Type to filter..." id="candfilterList" autocomplete="off" (input)="getCandidateWiseList(1)">
                </div>
            </diV>
            <br />
            <!-- <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" style="position: relative;"
                            role="grid">
                            <thead class="flip-content">
                                <tr>
                                    <th class="text-center"> Candidate Name </th>
                                    <th class="text-center" style="width:15%;"> Category </th>
                                    <th class="text-center" style="width:15%;"> Level </th>
                                    <th class="text-center" style="width:15%;">Skills</th>
                                    <th class="text-center" style="width:15%;"> Experience</th>
                                    <th class="text-center" style="width:15%;"> RAF</th>
                                    <th style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>                                
                                <tr *ngFor="let item of candidateList| paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">
                                    <td class="text-center">{{item.firstName}} {{item.lastName}}</td>
                                    <td class="text-center">{{item.job_Category}}</td>
                                    <td class="text-center">{{item.job_Level}}</td>
                                    <td class="text-center">{{item.skills}}</td>

                                    <td class="text-center"
                                        *ngIf="item.experience_Year != null && item.experience_Month != null">
                                        {{item.experience_Year}} Year {{item.experience_Month}} Month</td>
                                    <td class="text-center"
                                        *ngIf="item.experience_Year != null && item.experience_Month == null">
                                        {{item.experience_Year}} Year</td>
                                    <td class="text-center"
                                        *ngIf="item.experience_Year == null && item.experience_Month != null">
                                        {{item.experience_Month}} Month</td>
                                    <td class="text-center"
                                        *ngIf="item.experience_Year == null && item.experience_Month == null">0</td>

                                    <td class="text-center">{{item.raf}}</td>
                                    <td class="numeric">
                                        <a data-toggle="modal" (click)="openModel(item.id)" data-toggle="tooltip" data-placement="top" data-container="body" title="" data-original-title="Select Candidate"><i
                                                class="fa fa-hand-o-up green iconcss iconFontsize" ></i></a>&nbsp;
                                        <a (click)="downloadCV(item.id,item.cv)" *ngIf="item.cv">
                                            <i class="fa fa-download purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Download Candidate CV"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->


            <div class="table-responsive">
                <table id="example2" class="display table table-striped table-bordered" cellspacing="0" width="100%" style="border:1px solid #e7ecf1;">
                    <thead>
                        <tr>
                            <th style="width: 2%"></th>
                            <th class="text-center"> Candidate Name </th>
                            <th class="text-center" style="width:15%;"> Department </th>
                            <th class="text-center" style="width:15%;"> Job Title </th>
                            <th class="text-center" style="width:15%;">Skills</th>
                            <th class="text-center" style="width:15%;"> Experience</th>
                            <th class="text-center" style="width:15%;"> RAF</th>
                            <th style="width: 2%"> Actions </th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let item of candidateList| paginate: { itemsPerPage: 10, currentPage: p1,totalItems :totalRecord1,id:'pagination20' }">
                        <tr>
                            <td class="details-control details-control-icon-{{item.id}}" (click)="expandTable(item.id)">
                            </td>
                            <td class="text-center">{{item.firstName}} {{item.lastName}}</td>
                            <td class="text-center">
                                <aside data-toggle="tooltip" data-placement="bottom" title="{{item.job_Category}}" class="text-center">
                                    {{ item.job_Category != null ? (item.job_Category.length>20)? (item.job_Category | slice:0:20)+'...':(item.job_Category) : item.job_Category}}
                                </aside>
                            </td>
                            <td class="text-center">
                                <aside data-toggle="tooltip" data-placement="bottom" title="{{item.job_Level}}" class="text-center">
                                    {{ item.job_Level != null ? (item.job_Level.length>20)? (item.job_Level | slice:0:20)+'...':(item.job_Level) : item.job_Level}}
                                </aside>
                            </td>
                            <td class="text-center">
                                <aside data-toggle="tooltip" data-placement="bottom" title="{{item.skills}}">
                                    {{ item.skills != null ? (item.skills.length>20)? (item.skills | slice:0:20)+'...':(item.skills) : item.skills }}
                                </aside>
                            </td>

                            <td class="text-center">{{item.experience}}</td>

                            <td class="text-center">{{item.raf}}</td>
                            <td class="numeric">
                                <a data-toggle="modal" (click)="openModel(item.id)"><i
                                        class="fa fa-hand-o-up green iconcss iconFontsize" data-toggle="tooltip"
                                        data-placement="top" title="Selection Candidate"></i></a>&nbsp;
                                <a (click)="downloadCV(item.id,item.cv)" *ngIf="item.cv">
                                    <i class="fa fa-download purple iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="View Candidate CV"></i>
                                </a>
                            </td>
                        </tr>
                        <tr class="sub_tbl sub_tbl_{{item.id}}" style="display:none;">
                            <td colspan="8">
                                <div class="table-responsive">
                                    <table cellpadding="4" cellspacing="0" style="width:50%;" class="table table-striped table-bordered dataTable no-footer table-hover">
                                        <thead>
                                            <tr>
                                                <th>Company</th>
                                                <th>Vacancy</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of candidateWiseRAF | paginate: { itemsPerPage: 5, currentPage: n,id:'paginationsub22'}">
                                            <tr [style.background-color]="item.statusBackGroundColor" [style.color]="item.statusFrontColor">
                                                <td>{{item.company_Name}}</td>
                                                <td>{{item.vacancy_Name}}</td>
                                                <td>{{item.status}}</td>
                                                <td>

                                                    <a *ngIf="item.status == 'Selected'" (click)="UpdateStatus('Offered',item.candidate_Id,item.vacancy_Id)">
                                                        <i class="fa fa-gift iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Offered"></i>&nbsp;
                                                    </a>

                                                    <a *ngIf="item.status == 'Offered'" (click)="UpdateStatus('Accepted',item.candidate_Id,item.vacancy_Id)"><i
                                                            class="fa fa-check-square-o green iconcss iconFontsize"
                                                            data-toggle="tooltip" data-placement="top"
                                                            title="Accepted"></i>&nbsp;</a>

                                                    <a *ngIf="item.status == 'Offered'" (click)="UpdateStatus('Decline',item.candidate_Id,item.vacancy_Id)"><i
                                                            class="fa fa-close red-color iconcss iconFontsize"
                                                            data-toggle="tooltip" data-placement="top"
                                                            title="Decline"></i>&nbsp;</a>

                                                    <a *ngIf="item.status == 'Accepted' || item.status == 'Offered' ||item.status == 'Selected'" (click)="moveToOnboarding(item.candidate_Id,item.vacancy_Id)">
                                                        <i class="fa fa-paper-plane-o iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Move To Onboarding"></i>
                                                    </a>

                                                    <a *ngIf="item.status != 'Move To Onboarding' && item.status != 'Rejected' 
                                                    && item.status != 'Decline' && item.status != 'Move To Employee'&& item.status != 'Accepted'
                                                    && item.status != 'Offered' && item.status != 'Selected'" (click)="directOpenMoveToOnboarding(item.candidate_Id,item.vacancy_Id)">
                                                        <i class="fa fa-paper-plane-o iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Move To Onboarding"></i>&nbsp;</a>

                                                    <a *ngIf="item.status == 'New'||item.status == 'ShortListed' || item.status == 'Shortlisted'" (click)="openInterviewModel(item.candidate_Id,item.vacancy_Id,item.company_Id)">
                                                        <i class="fa fa-calendar iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Schedule Interview"></i>&nbsp;
                                                    </a>

                                                    <a *ngIf="item.status == 'Schedule'" (click)="openInterviewFeedbackModel(item.candidate_Id,item.vacancy_Id,item.company_Id,item.dept_Id,item.desig_Id)">
                                                        <i class="fa fa-comments-o iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Interview Feedback"></i>&nbsp;
                                                    </a>
                                                    <a *ngIf="item.status == 'Schedule'" (click)=" GetMail(item.candidate_Id,item.vacancy_Id)">
                                                        <i class="fa fa-envelope-o iconcss iconFontsize" data-toggle="tooltip" data-placement="top" title="Send Schedule Mail"></i>&nbsp;
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br />
                                <div class="text-center" *ngIf="candidateWiseRAF != null">
                                    <pagination-controls id="paginationsub22" (pageChange)="n =$event">
                                    </pagination-controls>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="text-center" *ngIf="candidateList != null">
                <pagination-controls id="pagination20" (pageChange)="getCandidateWiseList($event)" autoHide="true">
                </pagination-controls>
            </div>
        </div>
    </div>
</div>

<!-- END CONTENT -->
<div class="modal fade draggable-modal" id="modalbycandiadte" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">By Candidate</h4>
            </div>
            <div class="modal-body">
                <diV class="row">
                    <div class="col-md-9">
                    </div>
                    <div class="col-md-3 text-right">
                        <input type="text" class="form-control" placeholder="Type to filter..." id="filterListbyCandidate" autocomplete="off" (input)="filterData($event)">
                    </div>
                </diV>
                <br />
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover" style="position: relative;" role="grid">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center"> RAF </th>
                                <th class="text-center"> Applied For </th>
                                <th class="text-center"> Position </th>
                                <th class="text-center"> Applicant </th>
                                <th class="text-center">Selected</th>
                                <th class="text-center">Select By Candidate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of selectionCanndidateList| paginate: { itemsPerPage: 5, currentPage: m1,id: 'pagination23' }">
                                <td>{{item.desig_Name}}</td>
                                <td class="text-center">
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" class="md-check" disabled [checked]="item.selectedRaf != 0">
                                            <label>
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">{{item.noOfPositions}}</td>
                                <td class="text-center">{{item.appplicant}}</td>
                                <td class="text-center">{{item.selected}}</td>
                                <td class="text-center">
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="Candidate{{item.id}}" class="md-check" [disabled]="item.isDisplay > 0 || item.selectedRaf > 0" (click)="updateSelectCandidate(item.id)" [checked]="item.selectedRaf != 0">
                                            <label for="Candidate{{item.id}}">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center" *ngIf="selectionCanndidateList != null">
                    <pagination-controls id="pagination23" (pageChange)="m1 =$event" autoHide="true">
                    </pagination-controls>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" class="btn blue" (click)="Addrecord()">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="modalcandidatescheduleinterview" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Schedule Interview</h4>
            </div>
            <div class="modal-body">
                <app-scheduleInterviewforcandidate></app-scheduleInterviewforcandidate>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn dark btn-outline pull-left" (click)="history()"
                                               *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button> -->
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button class="btn btn-outline blue" (click)="UpsertInterviewDetail('Schedule')">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="modalfeedbackinterview" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Interview Feedback</h4>
            </div>
            <div class="modal-body">
                <app-interviewFeedback></app-interviewFeedback>
            </div>
            <div class="modal-footer">
                <div class="row isFinalBody">
                    <div class="col-md-2">
                        <div class="md-checkbox-inline text-left ">
                            <div class="md-checkbox">
                                <input type="checkbox" id="finalCandidateWise" class="md-check" (change)="showIsFinal()" [checked]="isFinal">
                                <label for="finalCandidateWise">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span>Is Final </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-left" *ngIf="isFinal">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Salary<span class="required"> *
                                </span> </label>
                            <div class="input-group">
                                <!-- onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode <= 46 || event.key === "Backspace"' -->
                                <input type="number" min="0" step="0.01" id="candidateWiseSalary" class="form-control" autocomplete="off" maxlength="50"  placeholder="{{CandidateWisesalaryPlaceHolder}}">
                                <span class="input-group-addon inputaddon">{{currency_Code}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label">Salary Type </label>
                            <select id="candidateWiseSalaryType" class="form-control">
                                <option value="">--- Select ---</option>
                                <option value="Annually">Annually</option>
                                <option value="Hourly">Hourly</option>
                            </select>
                        </div>
                    </div> -->
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Reporting<span class="required"> *
                                </span> </label>
                            <select id="Reporting_Id" class="form-control">
                                <option value="">--- Select ---</option>
                                <option *ngFor="let emp of reportingEmpList;" value={{emp.emp_Id}}>
                                    {{emp.firstName}} {{emp.lastName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Planned Start Date<span class="required"> *
                                </span> </label>
                            <!-- <input id="candidateWiseJoiningDate" type="date" class="form-control"> -->

                            <div class="input-group flatpickr" id="candidateWiseJoiningDates" data-wrap="true">
                                <input type="text" class="form-control" id="candidateWiseJoiningDate" data-input />
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="text-center btnInterview">
                    <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                    <button class="btn blue" (click)="UpsertInterviewDetailForFeedback('Shortlisted')" [disabled]="isFinal">Save</button>
                    <button class="btn btn-outline blue" (click)="UpsertInterviewDetailForFeedback('Selected')" [disabled]="!isFinal">Selected</button>
                    <button class="btn btn-outline blue" (click)="UpsertInterviewDetailForFeedback('Rejected')" [disabled]="!isFinal">Rejected</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="candSendMail" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Send Mail</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label">To</label>
                            <input type="text" id="candidatewiseto" class="form-control" autocomplete="off" placeholder="To">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label">Cc</label>
                            <input type="text" id="candidatewisecc" class="form-control" autocomplete="off" placeholder="Cc">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label">Bcc</label>
                            <input type="text" id="candidatewisebcc" class="form-control" autocomplete="off" placeholder="Bcc">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label">Subject</label>
                            <input type="text" id="candidatewisesubject" class="form-control" autocomplete="off" placeholder="Subject">
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="roleDisplay">
                        <div class="form-group">
                            <label class="control-label">Role</label>
                            <!-- <select id="roleId" class="form-control">
                                <option value="">--- Select ---</option>
                                <option *ngFor="let item of roleList" value="{{item.id}}">{{item.name}}</option>
                            </select> -->

                            <ng-select2 [data]="roleList" [options]="options" (valueChanged)="setData($event)" [value]="roleId" [placeholder]="'--- Select ---'"></ng-select2>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <div id="candidatewiseemailsend"></div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="fileinput fileinput-new" data-provides="">
                            <div class="input-group input-large docinbox">
                                <div class="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                    <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                    <span class="fileinput-filename"></span>
                                    <span class="fileinput-name">{{documentName}}</span>
                                </div>
                                <span class="input-group-addon btn default btn-file">
                                    <span class="fileinput-new"> Select file </span>
                                <span class="fileinput-exists"> Change </span>
                                <input type="file" id="files" name="files[]" (change)="fileChange($event)" multiple="multiple">
                                </span>
                                <a id="remove" class="input-group-addon btn red fileinput-exists" data-dismiss="fileinput"> Remove </a>
                            </div>
                            <br>
                            <strong>Note :- </strong> <label> Press Ctrl to select multiple files.</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" data-dismiss="modal" class="btn blue btn-outline" (click)="sendMail()">Send</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="selectionCandidateWiseOpenFile" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Description</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <iframe [src]='file' width="100%" height="600px"></iframe>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade draggable-modal" id="modalcandidateMovetoOnboarding" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Move To Onboarding</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <table class="table">

                            <thead>
                                <tr>
                                    <th>Vacancy Name</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of candidateVacancyList">
                                    <td>{{item.desig_Name}}</td>
                                    <td>{{item.status}}</td>
                                    <td>
                                        <input type="radio" name="mto" value="{{item.vacancy_Id}}" [checked]="item.vacancy_Id == vacancy_Id " (click)="getReportingEmpList(item.company_Id,item.dept_Id,item.desig_Id,item.salaryFrom,item.salaryTo,item.currency_Code,item.salaryType,item.vacancy_Id)">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Salary
                                <!-- <span class="required"> *</span> -->
                            </label>
                            <div class="input-group">
                                <input type="number" min="0" value="0" step="0.01" id="candidateSelectionSalary" class="form-control" autocomplete="off" maxlength="50" placeholder="{{salaryPlaceHolder}}">
                                <span class="input-group-addon inputaddon">{{currency_Code}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Reporting
                                <!-- <span class="required"> *</span>  -->
                            </label>
                            <select class="form-control" id="candidateSelectionReporting">
                                <option value="">--- Select ---</option>
                                <option *ngFor="let emp of reportingEmpList;" value={{emp.emp_Id}}>
                                    {{emp.firstName}} {{emp.lastName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Planned Start Date
                                <!-- <span class="required"> *</span> -->
                            </label>

                            <div class="input-group flatpickr" id="candidateSelectionJoiningDates" data-wrap="true">
                                <input type="text" class="form-control" id="candidateSelectionJoiningDate" data-input />
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button class="btn blue" type="button" (click)="directMoveToOnboarding()">Save</button>
            </div>
        </div>
    </div>
</div>