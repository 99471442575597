<style>
    td.details-control {
        background: url('assets/pages/img/SD7Dz.png') no-repeat center center;
        cursor: pointer;
    }

    tr.shown td.details-control {
        background: url('assets/pages/img/d4ICC.png') no-repeat center center;
    }
</style>
<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>Review Request History</h1>
            </div>
        </div>
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                   
                </li>
            </ul>
        </div>
        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" id="filterList" placeholder="Type to filter..."
                                (input)="getReviewList(1)">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="display table table-striped table-bordered ">
                            <thead class="flip-content">
                                <tr>
                                    <th style="width: 2%"></th>
                                    <th class="text-center"> Section </th>
                                    <th class="text-center"> Employee </th>
                                    <th class="text-center">Action By</th>
                                    <th class="text-center">Action On</th>
                                    <th class="text-center" style="width: 2%">Action</th>
                                </tr>
                            </thead>
                            <tbody
                                *ngFor="let item of reviewList| paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord}">
                                <tr>
                                    <td class="details-control details-control-icon-{{item.id}}"
                                        (click)="expandTable(item.id)"></td>
                                    <td>{{item.sectionName}}</td>
                                    <td>{{item.name}}</td>
                                    <td class="text-center">{{item.actionBy}}</td>
                                    <td class="text-center">{{item.actionOn | date :'dd/MM/yyyy hh:mm'}}</td>
                                    <td class="text-center" *ngIf="item.isApproved == 1">
                                        <span class="badge badge-success">Approved</span>
                                    </td>
                                    <td class="text-center" *ngIf="item.isRejected == 1">
                                        <span class="badge badge-danger">Rejected</span>
                                    </td>
                                </tr>
                                <tr class="sub_tbl sub_tbl_{{item.id}}" style="display:none;">
                                    <td colspan="4">
                                        <table cellpadding="4" cellspacing="0" style="width:50%;"
                                            class="table table-striped table-bordered dataTable no-footer">
                                            <thead>
                                                <tr>
                                                    <th>Field</th>
                                                    <th>Old Value</th>
                                                    <th>New Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let items of reviewExpandList">
                                                    <td>{{items.fieldName}}</td>
                                                    <td>{{items.oldValue}}</td>
                                                    <td>{{items.newValue}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="reviewList!= null">
                        <pagination-controls (pageChange)="getReviewList($event)" autoHide="true"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>