<style>
    .fileinput.fileinput-new {
        width: 98%;
    }
</style>
<form [formGroup]="candidateform">
    <div class="row">
        <div class="col-md-12 text-right">
            <div class="form-group">
                <div class="md-checkbox-inline">
                    <label class="control-label">Not Required &nbsp;&nbsp;</label>
                    <div class="md-checkbox">
                        <input type="checkbox" id="onBoardingnotRequired" class="md-check" #onBoardingnotRequired>
                        <label for="onBoardingnotRequired">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> </label>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="table-responsive">
            <table class="table table-hover" id="work" style="position: relative;" role="grid">
                <thead>
                    <tr>
                        <!-- <th style="width: 20%;">Document</th>
                        <th style="width: 20%;">Candidate Document Name</th>
                        <th style="width: 20%;">Notes</th> -->
                        <th style="width: 20%;">Upload Document</th>
                        <th style="width: 2%;">Active</th>
                        <th style="width: 5%;"><button type="button" (click)="addItem()" class="btn pull-right"><i
                                    class="fa fa-plus" aria-hidden="true"></i></button></th>
                    </tr>
                </thead>
                <tbody formArrayName="candidate_document"
                    *ngFor="let item of candidateform.get('candidate_document')['controls']; let i = index;">
                    <tr [formGroupName]="i">
                        <!-- <td>
                            <select class="form-control" id="Doc_Id{{i}}" formControlName="Doc_Id">
                                <option value=''>Select Document</option>
                                <option *ngFor="let item of documentList;" value={{item.id}}>
                                    {{item.name}}</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control" id="Doc_Name{{i}}"
                                formControlName="Doc_Name" autocomplete="off" maxlength="150">
                        </td>
                        <td>
                            <textarea class="form-control" formControlName="Notes" rows="1" id="Notes"></textarea>
                        </td> -->
                        <td>
                            <div class="fileinput fileinput-new" data-provides="fileinput">


                                <div class="input-group input-large">
                                    <div class="form-control uneditable-input input-fixed input-medium"
                                        data-trigger="fileinput">
                                        <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                        <span class="fileinput-filename"> </span>
                                    </div>
                                    <span class="input-group-addon btn default btn-file">
                                        <span class="fileinput-new"> Select file </span>
                                        <span class="fileinput-exists"> Change </span>
                                        <input type="file" id="Emp_Doc_Path{{i}}" (change)="fileChange($event,i)">
                                    </span>
                                    <a href="javascript:;" id="remove{{i}}"
                                        class="input-group-addon btn red fileinput-exists" (click)="removeDoc(i)"
                                        data-dismiss="fileinput"> Remove </a>
                                </div>
                                <!-- 


                                <div class="input-group input-large docinbox">
                                    <div class="form-control uneditable-input input-fixed input-medium"
                                        data-trigger="fileinput">
                                        <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                        <span class="fileinput-filename"></span>
                                        <span class="fileinput-filename"> </span>
                                    </div>
                                    <span class="input-group-addon btn default btn-file">
                                        <span class="fileinput-new"> Select file </span>
                                        <span class="fileinput-exists"> Change </span>
                                        <input type="file" id="files" name="files[]" (change)="fileChange($event)">
                                    </span>
                                    <a id="remove{{i}}" class="input-group-addon btn red fileinput-exists"
                                        (click)="removeDoc()" data-dismiss="fileinput"> Remove </a>
                                </div> -->
                            </div>
                        </td>

                        <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="Candidatedoc{{i}}" class="md-check"
                                        formControlName="isActive">
                                    <label for="Candidatedoc{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td>
                        <td>
                            <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                                    class="fa fa-minus" aria-hidden="true"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- <div class="row">
                <div class="col-md-11" style="width: 99%!important;">
                    <button type="button" (click)="addItem(null)" class="btn pull-right"><i class="fa fa-plus" aria-hidden="true"></i></button>
                </div>
            </div> -->
        </div>
    </div>
</form>