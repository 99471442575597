<style>
    .btnInterview button {
        margin-right: 10px;
        min-width: 100px;
    }

    div#ad_search {
        position: relative;
        padding-top: 20px;
    }
</style>
<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>New Onboarding
                </h1>
            </div>
        </div>
        <ul class="page-breadcrumb breadcrumb mb20">
            <!-- <li>
                <a routerLink="/dashboard"><i class="fa fa-tachometer"></i> Dashboard</a>
            </li> -->
        </ul>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <div class="portlet light bordered" id="form_wizard_1">
                <div class="portlet-title">
                    <div class="caption">
                        <i class="fa fa-building font-red"></i>
                        <span class="caption-subject font-red bold uppercase"> ADD ONBOARDING -
                            <span class="step-title"> STEP {{activeWizard}} OF 3 </span>
                        </span>
                    </div>
                    <div class="caption pull-right">
                        <a routerLink="/onboarding"><i class="fa fas fa-reply" data-toggle="tooltip" data-placement="top"
                                title="Back To Overview"></i></a>
                    </div>

                    <!-- <div class="pull-right">
                        <a class="btn btn-transparent blue" data-toggle="tooltip" title="Send mail to candidate with all details">Send Mail</a>
                    </div> -->
                </div>
                <div class="portlet-body form">
                    <form class="form-horizontal" action="#" id="submit_form" method="POST">
                        <div class="form-wizard">
                            <div class="form-body">
                                <ul class="nav nav-pills nav-justified steps">
                                    <li [class]="w1" (click)="opentab(1)">
                                        <a (click)="tabClick(1)" class="step">
                                            <span class="number"> 1 </span>
                                            <span class="desc">
                                                <!-- <i class="fa fa-check"></i>  -->
                                                Personal Info </span>
                                        </a>
                                    </li>
                                    <!-- <li [class]="w2">
                                        <a class="step active" (click)="tabClick(2)">
                                            <span class="number"> 2 </span><br />
                                            <span class="desc">
                                                <i class="fa fa-check"></i> 
                                                Address Info </span>
                                        </a>
                                    </li> -->
                                    <li [class]="w2" (click)="opentab(2)">
                                        <a class="step active" (click)="tabClick(2)">
                                            <span class="number"> 2 </span>
                                            <span class="desc">
                                                Job Details </span>
                                        </a>
                                    </li>
                                    <!-- <li [class]="w4">
                                        <a class="step active" (click)="tabClick(4)">
                                            <span class="number"> 4 </span><br />
                                            <span class="desc">
                                                Contacts </span>
                                        </a>
                                    </li> -->
                                    <!-- <li [class]="w5">
                                        <a class="step active" (click)="tabClick(5)">
                                            <span class="number"> 5 </span><br />
                                            <span class="desc">
                                                Emergency Contacts </span>
                                        </a>
                                    </li>
                                    <li [class]="w6">
                                        <a class="step active" (click)="tabClick(6)">
                                            <span class="number"> 6 </span><br />
                                            <span class="desc">
                                                Reference </span>
                                        </a>
                                    </li>
                                    <li [class]="w7">
                                        <a class="step active" (click)="tabClick(7)">
                                            <span class="number"> 7 </span><br />
                                            <span class="desc">
                                                Bank Info </span>
                                        </a>
                                    </li> -->
                                    <!-- <li [class]="w3">
                                        <a class="step active" (click)="tabClick(8)">
                                            <span class="number"> 8 </span><br />
                                            <span class="desc">
                                                Probation </span>
                                        </a>
                                    </li>
                                    <li [class]="w4">
                                        <a class="step active" (click)="tabClick(9)">
                                            <span class="number"> 9 </span><br />
                                            <span class="desc">
                                                Company Contract </span>
                                        </a>
                                    </li> -->
                                    <!-- <li [class]="w10">
                                        <a class="step active" (click)="tabClick(10)">
                                            <span class="number"> 10 </span><br />
                                            <span class="desc">
                                                Right To Work </span>
                                        </a>
                                    </li> -->
                                    <!-- <li [class]="w5" data-toggle="tab">
                                        <a class="step active" (click)="tabClick(11)">
                                            <span class="number"> 11 </span><br />
                                            <span class="desc">
                                                Salary </span>
                                        </a>
                                    </li> -->
                                    <li [class]="w3" (click)="opentab(3)">
                                        <a class="step active" (click)="tabClick(3)">
                                            <span class="number"> 3 </span>
                                            <span class="desc">
                                                Document </span>
                                        </a>
                                    </li>
                                </ul>
                                <div id="bar" class="progress progress-striped" role="progressbar">
                                    <div class="progress-bar progress-bar-success" id="progress-bar"
                                        [style.width]="progressBarWidth"> </div>
                                </div>
                                <div class="tab-content">
                                    <!-- <div class="alert alert-danger display-none">
                                        <button class="close" data-dismiss="alert"></button> You have some form errors.
                                        Please check below. </div>
                                    <div class="alert alert-success display-none">
                                        <button class="close" data-dismiss="alert"></button> Your form validation is
                                        successful! </div> -->
                                    <div [class]="t1" id="tab1">
                                        <app-candidatepersonalinfo></app-candidatepersonalinfo>
                                    </div>
                                    <!-- <div [class]="t2" id="tab2">
                                        <app-candidateaddressinfo></app-candidateaddressinfo>
                                    </div> -->
                                    <div [class]="t2" id="tab2">
                                        <app-candidatejobdetail></app-candidatejobdetail>
                                    </div>
                                    <!-- <div [class]="t4" id="tab4">
                                        <app-candidatecontact></app-candidatecontact>
                                    </div>
                                    <div [class]="t5" id="tab5">
                                        <app-candidateEmrContact></app-candidateEmrContact>
                                    </div>
                                    <div [class]="t6" id="tab6">
                                        <app-candidateReference></app-candidateReference>
                                    </div>
                                    <div [class]="t7" id="tab7">
                                        <app-candidatebankinfo></app-candidatebankinfo>
                                    </div> -->
                                    <!-- <div [class]="t3" id="tab3">
                                        <app-candidateProbation></app-candidateProbation>
                                    </div>
                                    <div [class]="t4" id="tab4">
                                        <app-candidateContract></app-candidateContract>
                                    </div> -->
                                    <!-- <div [class]="t10" id="tab10">
                                        <app-candidaterightwork></app-candidaterightwork>
                                    </div> -->
                                    <!-- <div [class]="t5" id="tab5">
                                        <app-candidateSalary></app-candidateSalary>
                                    </div> -->
                                    <div [class]="t3" id="tab3">
                                        <app-candidateDocument></app-candidateDocument>
                                    </div>
                                </div>
                            </div>
                            <div class="form-actions">
                                <div class="row">
                                    <div class="col-md-12 text-right" *ngIf="!visibleSendmail">
                                        <a data-toggle="modal" href="#filterModal" class="btn btn-outline blue"
                                        *ngIf="!backbutton">
                                            Candidate Search</a>

                                        <a (click)="wizardSetting('back')" class="btn default"
                                            *ngIf="backbutton">
                                            <i class="fa fa-angle-left"></i> Back </a>&nbsp;&nbsp;

                                        <a (click)="Upsert()"
                                            class="btn blue">{{btnName}} <i class="fa fa-angle-right"></i>
                                        </a>
                                    </div>
                                    <div class="col-md-12 text-right" [style.visibility]="personSearchList">
                                        <div id="ad_search">
                                            <div class="table-responsive">
                                                <table class="table table-striped table-bordered table-hover"
                                                    style="width: 75%;margin: 0 auto;">
                                                    <thead class="flip-content">
                                                        <tr>
                                                            <th class="text-center" style="width: 25%;">Fisrt Name</th>
                                                            <th class="text-center" style="width: 25%;">Last Name</th>
                                                            <th class="text-center" style="width: 25%;">Gender</th>
                                                            <th class="text-center" style="width: 25%;">Date of Birth
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of searchPersonList"
                                                            (click)="setPersonValue(item.id)">
                                                            <td class="text-center">
                                                                {{item.firstName}}
                                                            </td>
                                                            <td class="text-center">
                                                                {{item.lastName}}
                                                            </td>
                                                            <td class="text-center">
                                                                {{item.gender_Name}}
                                                            </td>
                                                            <td class="text-center">
                                                                {{item.dob | date :  "dd/MM/yyyy"}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-right" *ngIf="visibleSendmail">
                                        <a routerLink="/onboarding" class="btn default"> Close </a>&nbsp;&nbsp;
                                        <a (click)="SendMail()" class="btn btn-outline blue"> Send
                                            Mail </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- END PAGE BASE CONTENT -->

    </div>
    <!-- END CONTENT BODY -->
</div>
<div class="modal fade draggable-modal" id="filterModal" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Advanced Search</h4>
            </div>
            <div class="modal-body">
                <app-persononboardingadvancedsearch></app-persononboardingadvancedsearch>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn blue" (click)="advanceFilter()">Search</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="SendMail" role="dialog">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Send Mail</h4>
            </div>
            <div class="modal-body">
                <app-sendmail></app-sendmail>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" routerLink="/candidate" class="btn dark btn-outline">Close</button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline"
                    (click)="sendMail()">Send</button>
            </div>
        </div>
    </div>
</div>