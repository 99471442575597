<form [formGroup]="candidateForm">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Contract Type<span class="required"> * </span></label>
                <ng-select2 [data]="contractlist" id="contract_Id" formControlName="contract_Id" [placeholder]="'--- Select ---'">
                </ng-select2>
                <!-- <select id="contract_Id" class="form-control" formControlName="contract_Id">
                    <option value="">Select Contract</option>
                    <option *ngFor="let item of contractlist;" value={{item.contract_Id}}>
                        {{item.contract_Name}} / {{item.contract_Type}}</option>
                </select> -->
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Contract Start<span class="required"> * </span></label>
                <div class="input-group input-medium" id="flatpickropencontract_SD" data-wrap="true">
                    <input type="text" class="form-control readonlyClass" id="contract_Start" readonly (change)="onChangeDate()" data-input>
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <!-- <span class="required"> * </span> -->
                <label class="control-label">Length of Contract</label>
                <input type="text" class="form-control" id="contract_Days" formControlName="contract_Days" autocomplete="off" maxlength="4" oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');" (input)="onChangeDays($event.target.value)">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Contract End
                    <!-- <span class="required"> * </span> -->
                </label>
                <div class="input-group input-medium" id="flatpickropencontract_ED" data-wrap="true">
                    <input type="text" class="form-control readonlyClass" id="contract_End" readonly data-input>
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="empContract" class="md-check" formControlName="isActive">
                            <label for="empContract">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>