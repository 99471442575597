<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Department List
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->

        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a data-toggle="modal" (click)="id=0;modalOpen =true" class="btn btn-transparent blue  btn-sm">Add
                            Department</a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->

        <div class="col-md-12 align-items-center">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <!-- <table datatable [dtOptions]="dtOptions" class="table table-sm  table-striped table-bordered table-hover"></table> -->
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" id="filterList" placeholder="Type to filter ..."
                                (input)="getDepartmentList(1)">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblDepartment">
                            <thead class="flip-content">
                                <tr>
                                    <th class="text-center"> Company </th>
                                    <th class="text-center"> Department Name </th>
                                    <th class="text-center"> Department Code </th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let department of departmentlist| paginate: { itemsPerPage: 10, currentPage: p ,totalItems :totalRecord}">
                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(department.dept_Id,'0')">
                                                <div class="circle greenColor" *ngIf="department.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(department.dept_Id,'1')">
                                                <div class="circle redColor" *ngIf="!department.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12">
                                            {{department.company_Name}}</div>
                                    </td>
                                    <td>{{department.dept_Name}}</td>
                                    <td>{{department.dept_Code}}</td>
                                    <td class="numeric text-center">
                                        <a *ngIf="department.isActive" data-toggle="modal"
                                            (click)="id=department.dept_Id;modalOpen =true"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Department"></i></a>
                                        <a *ngIf="!department.isActive"
                                            (click)="departmentDelete(department.dept_Id)"><i
                                                class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Delete Department"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="departmentlist != null ">
                        <pagination-controls (pageChange)="getDepartmentList($event)" autoHide="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->
<div *ngIf="modalOpen">
    <app-dept-form [pId]="id" (modalClose)="modalOpen = $event" (refreshPage)="getDepartmentList($event)"></app-dept-form>
</div>

