import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;


@Component({
  selector: 'app-bankCandidate',
  templateUrl: './bank-candidate.component.html'
})
export class BankCandidateComponent implements OnInit {
  candidateForm: FormGroup;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {

    this.candidateForm = this.fb.group({
      id: ['0'],
      onboarding_Id: ['0'],
      bank_Name: ['', Validators.required],
      account_Holder: ['', Validators.required],
      account_Type: [null],
      account_No: ['', Validators.required],
      bank_Code: ['', Validators.required],
      account_Title: ['0'],
      account_Code: [null],
      isPayed: ['0'],
      emp_Doc_Id: ['0'],
      isRequired: ['0'],
      notes: [''],
      version_Id: [''],
      isActive: ['1'],
      updatedBy: [Commonvariable.User_Id]
    });
  }



  Edit(Id) {
    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.Onboarding_Bank, Id).subscribe((response) => {
        if (response != null && response["data"] != "") {
          var editData = response["data"];
          var formData = this.candidateForm;
          this.commonService.setEditData(formData, editData);
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Candidate'); })
    }
  }


  validation() {
    var eForm = this.candidateForm;
    //sForm.get("company_Id").setValue($("#company_Id").val()); 
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);
    if (!eForm.valid) {
      for (const key of Object.keys(eForm.controls)) {
        if (eForm.controls[key].invalid) {
          let fieldName = "";

          if (key == "bank_Name") {
            fieldName = "Bank name"
          }
          else if (key == "account_Holder") {
            fieldName = "Account holder"
          }
          // else if (key == "account_Type") {
          //   fieldName = "Account type"
          // }
          else if (key == "account_No") {
            fieldName = "Account number"
          }
          else if (key == "bank_Code") {
            fieldName = "Sort code"
          }
          this.toastr.info(fieldName + " is required !", "Candidate");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if ($("#bank_Name").val().length < 2) {
        this.toastr.info("Bank name must be at least 2 characters long !", "Candidate");
        $("#bank_Name").focus();
        return false;
      }
      if ($("#bank_Name" ).val().search(/^[^-\s][a-zA-Z_\s-]+$/) != 0) {
        this.toastr.info("Bank Name only alphabets allow !", "Candidate");
        $("#bank_Name").focus();
        return false;
     }
    //  if ($("#bank_Name").val().search(/[^a-zA-Z]+/) > -1) {
    //     this.toastr.info("Bank Name only alphabets allow !", "Employee");
    //     $("#bank_Name").focus();
    //     return false;
    //  }
      if ($("#account_Holder").val().length < 2) {
        this.toastr.info("Account holder must be at least 2 characters long !", "Candidate");
        $("#account_Holder").focus();
        return false;
      }
      if ($("#account_Holder" ).val().search(/^[^-\s][a-zA-Z_\s-]+$/) != 0) {
        this.toastr.info("Account holder only alphabets allow !", "Candidate");
        $("#account_Holder").focus();
        return false;
     }
    //  if ($("#account_Holder").val().search(/[^a-zA-Z]+/) > -1) {
    //     this.toastr.info("Account holder only alphabets allow !", "Employee");
    //     $("#account_Holder").focus();
    //     return false;
    //  }
     
      // if ($("#account_Type").val().length < 2) {
      //   this.toastr.info("Account type must be at least 2 characters long !", "Candidate");
      //   $("#account_Type").focus();
      //   return false;
      // }
      // if ($("#account_Type").val().indexOf(" ") > -1) {
      //   this.toastr.info("Account type not allow space !", "Candidate");
      //   $("#account_Type").focus();
      //   return false;
      // }
      if ($("#account_No").val().length < 5 ||  $("#account_No").val().length > 20) {
        this.toastr.info("Account number 5 to 20 digit long !", "Candidate");
        $("#account_No").focus();
        return false;
      }
      // if ($("#bank_Code").val().length < 6) {
      //   this.toastr.info("Sort code must be at least 6 characters long !", "Candidate");
      //   $("#bank_Code").focus();
      //   return false;
      // }
      // if ($("#bank_Code").val().length > 6) {
      //   this.toastr.info("Sort code not more than 6 characters long !", "Candidate");
      //   $("#bank_Code").focus();
      //   return false;
      // }
      // if ($("#bank_Code").val().search(/[^0-9a-zA-z -]+/) > -1) {
      //   this.toastr.info("Enter valid sort code  !", "Candidate");
      //   $("#bank_Code").focus();
      //   return false;
      // }
      // if ($("#bank_Code").val().indexOf(" ") > -1) {
      //   this.toastr.info("Sort code not allow space !", "Candidate");
      //   $("#bank_Code").focus();
      //   return false;
      // }
      // if ($("#bank_Code").val() != "" && /["\\]/.test($("#bank_Code").val())) {
      //   this.toastr.info("Sort Code does not allow Special Characters \" \\ ", "Candidate");
      //   $("#bank_Code").focus();
      //   return false;
      // }
    }

    return true;
  }




}
