
<!-- <style>
    @media (max-width:767px) {
        p.mobwidth {
            font: 44px Times New Roman, sans-serif !important;
            color: maroon;
            word-break: break-all !important;
        }
        p.titlewidth{
            font: 60px Times New Roman, sans-serif !important;
            color: maroon;
            word-break: break-all !important;
        }
    }
</style> -->
<!-- <div style="background-color: #ff996680; height: 100vh;">

    <p class="text-center titlewidth" style="font:250px Times New Roman, sans-serif;color: maroon;margin: 0; ">401</p>
    <p class="text-center mobwidth" style="font:100px Times New Roman, sans-serif;color: maroon ">UNAUTHORIZED</p>
    <div class="text-center"><a href="#">
            <p class="text-center" style="font:50px Times New Roman, sans-serif;color:maroon ">Back To Login</p>
        </a></div>

</div> -->

<div class="text-center" style="margin-top: 5%;">

    <p class="text-center titlewidth" style="font:150px Times New Roman, sans-serif;color: maroon;margin: 0; ">401</p>
    <p class="text-center mobwidth" style="font:50px Times New Roman, sans-serif;color: maroon ">UNAUTHORIZED</p>
    <div class="text-center"><a href="#">
            <p class="text-center" style="font:30px Times New Roman, sans-serif;color:maroon ">Back To Login</p>
        </a></div>

</div>