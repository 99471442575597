import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpBackend, HttpHeaders, HttpParams } from '@angular/common/http';
import { Commonvariable } from '../models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, forkJoin } from 'rxjs';
import { Options } from 'selenium-webdriver/safari';
import { dashCaseToCamelCase } from '@angular/compiler/src/util';
import { controllers } from 'chart.js';
import { Method } from 'ag-grid-community/dist/lib/widgets/componentAnnotations';

@Injectable({
  providedIn: 'root'
})
export class CommonApiService {

  //baseUrl = "http://172.16.1.61:8088/api/";
  baseUrl = Commonvariable.BASE_API_URL;

  constructor(private httpClient: HttpClient,
    private toastr: ToastrService
  ) { }

  // multipleApi() {
  //   let api1 = this.httpClient.get(this.baseUrl + "company/GetAll/5");
  //   let api2 = this.httpClient.get(this.baseUrl + "site/GetAll/");
  //   return forkJoin([api1, api2]);
  // }

  List(controller, method = null) {
    
    if (method == null) {
      method = "GetAll";
    }
    // if (controller == "company") {
    //   return this.httpClient.get(this.baseUrl + controller + "/GetAll/5");
    // }

    return this.httpClient.get(this.baseUrl + controller + "/" + method);
  }

  ListCity(controller, method = null) {
    if (method == null) {
      method = "GetAllCity";
    }
    // if (controller == "company") {
    //   return this.httpClient.get(this.baseUrl + controller + "/GetAll/5");
    // }

    return this.httpClient.get(this.baseUrl + controller + "/" + method);
  }

  getCompanyInfo(controller) {
    // if (controller == "company") {
    //   return this.httpClient.get(this.baseUrl + controller + "/GetInfo/5");
    // }

    return this.httpClient.get(this.baseUrl + controller + "/GetInfo");
  }


  getById(controller, id, method = null) {
   

    if (method == null) {
      method = "Get";
    }
    return this.httpClient.get(this.baseUrl + controller + "/" + method + "/" + id);
  }
  getByIdNew(controller, id, method = null) {

    if (method == null) {
      method = "GetById";
    }
    return this.httpClient.get(this.baseUrl + controller + "/" + method + "/" + id);
  }

  getByIdTwoParam(controller, paramOne, paramTwo, method = null) {

    if (method == null) {
      method = "Get";
    }
    return this.httpClient.get(this.baseUrl + controller + "/" + method + "/" + paramOne + "," + paramTwo);
  }

  Put(controller, id, method) {
    return this.httpClient.put(this.baseUrl + controller + "/" + method + "/" + id, "");
  }

  putWithParameter(controller, data, method) {
    return this.httpClient.put(this.baseUrl + controller + "/" + method, data);
  }

  Post(controller, id, method) {
   
    return this.httpClient.post(this.baseUrl + controller + "/" + method + "/" + id, "");
  }

  PostRequest(controller, method, data) {
    return this.httpClient.post(this.baseUrl + controller + "/" + method, data);
  }


  UploadLogo(controller, method, data, editcall = false) {
    if (!editcall) {
      return this.httpClient.post(this.baseUrl + controller + "/" + method, data);
    }
    else {
      return this.httpClient.put(this.baseUrl + controller + "/" + method, data);
    }
  }



  Upsert(controller, data, id, method = null) {
    
    var mName = method;
    if (id > 0) {
      mName = method == null ? "/Edit" : method
      return this.httpClient.put(this.baseUrl + controller + mName, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + controller + "/Add", data);
    }
  }

  UpsertData(controller, data, id, method = null) {
    
    var mName = method;
    if (id > 0) {
      mName = method == null ? "/EditNew" : method
      return this.httpClient.put(this.baseUrl + controller + mName, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + controller + "/AddData", data);
    }
  }

  

  UpsertWithMultiCompany(controller, data, id, method = null) {
    var mName = method;
    if (id > 0) {
      mName = method == null ? "/Edit" : method
      return this.httpClient.put(this.baseUrl + controller + mName, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + controller + "/AddBy_MultipleCompanies", data);
    }
  }
  UpsertLeave(controller, data, id, method = null) {
    var mName = method;
    if (id > 0) {
      mName = method == null ? "/UpdateEmployeeLeaveDayType" : method
      return this.httpClient.put(this.baseUrl + controller + mName, data);
    }

  }

  UpsertSignup(controller, data, id) {
    if (id > 0) {
      return this.httpClient.put(this.baseUrl + controller + "/Edit", data);
    }
    else {
      return this.httpClient.post(this.baseUrl + controller + "/SignUp", data);
    }
  }



  Delete(controller, id, methodName = null) {
    var deleteMethod = "/Delete/";

    if (methodName != null) {
      deleteMethod = "/" + methodName + "/";
    }

    return this.httpClient.delete(this.baseUrl + controller + deleteMethod + id);
  }

  RAFStatusUpdate(controller, id, statuts) {
    return this.httpClient.put(this.baseUrl + controller + "/ChangeStatus/" + id + "," + statuts, "");
  }

  commonUpdateStatus(controller, id, statuts) {
    return this.httpClient.put(this.baseUrl + controller + "/UpdateStatus/" + id + "," + statuts, "");
  }

  leaveUpdateStatus(controller, id, statuts, Company_id, reporter_id) {
    return this.httpClient.put(this.baseUrl + controller + "/UpdateLeaveStatus/" + id + "," + statuts + "," + Company_id + "," + reporter_id, "");
  }
  leaveSingleUpdateStatus(controller, id, Company_id, statuts) {
    return this.httpClient.put(this.baseUrl + controller + "/UpdateEmployeeLeaveDayStatus/" + id + "," + Company_id + "," + statuts, "");
  }

  sendReminder(controller, id) {
    return this.httpClient.get(this.baseUrl + controller + "/SendMail/" + id);
  }

  cascadingDropDwon(controller, fName, fValue) {
    

    var data = {
      "FieldName": fName,
      "FieldValue": fValue,
      "Parameter": "="
    }
    return this.httpClient.post(this.baseUrl + controller + "/GetBy", data);
  }

  empDetailList(controller, value = null) {
    var data = {}
    if (value != null) {
      data = value
    }
    return this.httpClient.post(this.baseUrl + controller + "/Get", data);
  }
  empManagersList(controller, value = null) {
    var data = {}
    if (value != null) {
      data = value
    }
    return this.httpClient.get(this.baseUrl + controller + "/AllMangers", data);
  }

  getBy(controller, fName, fValue, Parameter = null, PageIndex = null, PageSize = null, OrderBy = null) {

    var data = {
      "searchBy": [
        {
          "FieldName": fName,
          "FieldValue": fValue,
          "Parameter": Parameter == null ? "=" : Parameter
        }
      ],
      "PageIndex": PageIndex != null ? PageIndex : 0,
      "PageSize": PageSize != null ? PageSize : 100,
      "RecordCount": 100,
      "RecordLimit": 100,
      "OrderBy": OrderBy == null ? "" : fName == "TableName" ? "Id" : fName
    }
    return this.httpClient.post(this.baseUrl + controller + "/GetBy", data);
  }

  vacancyLinkToCandidates(controller, method, data) {

    return this.httpClient.put(this.baseUrl + controller + "/" + method, data);

  }

  getPaginated(controller, fName = null, fValue = null, Parameter = null, PageIndex = null, PageSize = 10, datas = null, method = "GetPaginated") {

    if (datas == null) {
      var data = {
        "searchBy": [
          {
            "FieldName": "company_Id",
            "FieldValue": Commonvariable.Company_Id,
            "Parameter": "==",
            "ConditionWith": "&&"
          },
          {
            "FieldName": fName,
            "FieldValue": '\"' + fValue + '\"',
            "Parameter": Parameter == null ? "==" : Parameter
          }
        ],
        "PageIndex": PageIndex != null ? PageIndex : 0,
        "PageSize": PageSize != null ? PageSize : 100,
        "RecordCount": 100,
        "RecordLimit": 100
      }
      return this.httpClient.post(this.baseUrl + controller + "/" + method, data);
    } else {
      return this.httpClient.post(this.baseUrl + controller + "/" + method, datas);
    }

  }


  getListWithFilter(controller, pageindex, commonsearch = null, methodName = null, orderBy = null, pageSize = 10, requestData = null, alpha = null) {

    if (methodName == null) {
      methodName = "GetPaginated";
    }
    if (requestData == null) {
      requestData = {
        "searchBy": [
          // {
          //   "FieldName": "isActive",
          //   "FieldValue": "null",
          //   "Parameter": "!="
          // }
        ],
        "PageIndex": pageindex - 1,
        "CommonSearch": commonsearch,
        "PageSize": pageSize,
        "OrderBy": orderBy
      }
    }

    if (alpha == null) {
      return this.httpClient.post(this.baseUrl + controller + "/" + methodName, requestData);
    }
    else {
      return this.httpClient.post(this.baseUrl + controller + "/" + methodName + "?alpha=" + alpha, requestData);
    }


  }

  getListWithFilterData(controller, pageindex, commonsearch = null, methodName = null, orderBy = null, pageSize = 5000, requestData = null, alpha = null) {

    if (methodName == null) {
      methodName = "GetPaginated";
    }
    if (requestData == null) {
      requestData = {
        "searchBy": [
          // {
          //   "FieldName": "isActive",
          //   "FieldValue": "null",
          //   "Parameter": "!="
          // }
        ],
        "PageIndex": pageindex - 1,
        "CommonSearch": commonsearch,
        "PageSize": pageSize,
        "OrderBy": orderBy
      }
    }

    if (alpha == null) {
      return this.httpClient.post(this.baseUrl + controller + "/" + methodName, requestData);
    }
    else {
      return this.httpClient.post(this.baseUrl + controller + "/" + methodName + "?alpha=" + alpha, requestData);
    }


  }


  GetByPaginated(controller, requestData, method = null) {
   
    if (method == null) {
      method = "GetByPaginated";
    }
    return this.httpClient.post(this.baseUrl + controller + "/" + method, requestData);
  }

  GetByPaginatedForAge(controller, requestData, method = null) {
   
    if (method == null) {
      method = "GetByPaginatedForAge";
    }
    return this.httpClient.post(this.baseUrl + controller + "/" + method, requestData);
  }

  GetByPaginatedForColorCode(controller, requestData, method = null) {
    if (method == null) {
      method = "GetMenuForColorCode";
    }
    return this.httpClient.get(this.baseUrl + controller + "/" + method, requestData);
  }

  

  GetByPaginatedForRAFRequester(controller, requestData, method = null) {
    if (method == null) {
      method = "GetByPaginatedForRAFRequester";
    }
    return this.httpClient.post(this.baseUrl + controller + "/" + method, requestData);
  }

  

  GetByPaginatedRuleWise(controller, requestData, method = null) {
    if (method == null) {
      method = "GetByPaginatedRuleWise";
    }
    return this.httpClient.post(this.baseUrl + controller + "/" + method, requestData);
  }

  GetSignUpEmployee(controller, requestData) {
    return this.httpClient.post(this.baseUrl + controller + "/GetSignUpEmployee", requestData);
  }

  export(controller, methodName, data) {
    return this.httpClient.post<Blob>(this.baseUrl + controller + "/" + methodName, data, { responseType: 'blob' as 'json' });
  }

  ExportById(controller, methodName, data) {
    return this.httpClient.post<Blob>(this.baseUrl + controller + "/" + methodName, data, { responseType: 'blob' as 'json' });
  }

  getimage(controller, id, methodName) {
    return this.httpClient.get<Blob>(this.baseUrl + controller + "/" + methodName + "/" + id, { responseType: 'blob' as 'json' });
  }

  changePassword(controller, oldpassword, newpassword) {
    return this.httpClient.put(this.baseUrl + controller + "/ChangePassword/" + Commonvariable.User_Id + "," + oldpassword + "," + newpassword, "");
  }
  GetUserCreationOnboarding(controller, requestData) {
    return this.httpClient.post(this.baseUrl + controller + "/GetUserCreationOnboarding", requestData);
  }

  GetEmpListCalendar(controller, requestData) {
    return this.httpClient.post(this.baseUrl + controller + "/GetEmployeeListCalendar", requestData);
  }

  TreeDropDownList(controller, method = null) {
    if (method == null) {
      method = "GetEthinicityDrodownList";
    }

    return this.httpClient.get(this.baseUrl + controller + "/" + method);
  }

  JobTitleDropDownList(controller, method = null) {
    if (method == null) {
      method = "GetDropDownListAllNames";
    }

    return this.httpClient.get(this.baseUrl + controller + "/" + method);
  }
  getByIdchangereq(controller, id, method = null) {

    if (method == null) {
      method = "GetByEmpID";
    }
    return this.httpClient.get(this.baseUrl + controller + "/" + method + "/" + id);
  }
  

 
  getEmployeesByPrefix(controller,method,id){
    if (method == null) {
      method = "Search";
    }
    return this.httpClient.get(this.baseUrl + controller + "/" + method + "/" +id);
    // return this.httpClient.get<Employee[]>(`${this.apiUrl}/employees?id=${Empcode}`);
  }

  getEmployeeDetails(controller,method,Empcode) {


    
    if (method == null) {
      method = "GetByEmpID";
    }
    return this.httpClient.get(this.baseUrl + controller + "/" + method + "/" +Empcode);
    // return this.httpClient.get<Employee[]>(`${this.apiUrl}/employees?id=${Empcode}`);
  
  }

  getEmployeesByKeyword(keyword: any): Observable<any> {
    const apiUrl = `${this.baseUrl}Employee_Change_Requisition/Search`;
    const params = new HttpParams().set('keyword', keyword);

    return this.httpClient.get(apiUrl, { params });
  }
  getEmployeesManagerByKeyword(controller,method,keyword: any): Observable<any> {
    const params = new HttpParams().set('keyword', keyword);
    
    return this.httpClient.get(this.baseUrl + controller + "/" + method ,{ params });
    
  }
  
  // updateDataById(controller,  method = null,updateData) {

  //   if (method == null) {
  //     method = "Edit";
      
  //   }
  //   return this.httpClient. (this.baseUrl + controller + "/" + method );
  // }
  updateData(controller,  method = null,updateData) {
    if (method == null) {
          method = "Edit";
          
         }

    return this.httpClient.put(this.baseUrl + controller + "/" + method,updateData);

  }
  SaveData(controller,  method = null,newData) {
    if (method == null) {
          method = "Add";
          
         }

    return this.httpClient.post(this.baseUrl + controller + "/" + method,newData);

  }


  getallEmployeeDetails(controller,method) {


    
    if (method == null) {
      method = "GetAll";
    }
    return this.httpClient.get(this.baseUrl + controller + "/" + method );
    // return this.httpClient.get<Employee[]>(`${this.apiUrl}/employees?id=${Empcode}`);
  
  }

  private ageSubject = new Subject<number>();

  sendAge(age: number): void {
    this.ageSubject.next(age);
  }

  getAge(): Subject<number> {
    return this.ageSubject;
  }



  private dateSubject = new Subject<Date>();

  getDateSubject() {
    return this.dateSubject.asObservable();
  }

  setDate(date: Date) {
    this.dateSubject.next(date);
  }
  }
  

export interface Employee {
  id: string;
  name: string;
}

export interface EmployeeDetails {
  // Define the properties of EmployeeDetails as needed
  id: string;
  fullName: string;
  // Add other properties as needed
}
