import { Component, OnInit ,Input} from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
import { combineAll } from 'rxjs/operators';
import * as moment from 'moment';
import { data } from 'jquery';


declare var $: any;


@Component({
   selector: 'app-empContract',
   templateUrl: './employee-contract.component.html',
})
export class EmpContractComponent implements OnInit {
   @Input() inputFromParent : string;
   @Input() inputFromParentJT : string;
   
   
   empform: FormGroup;
   items: FormArray;
   contractlist: any;
   joiningDate : any;
   modalOpen: boolean = false;
   zoneList:any;
   companyid: any;
   jobtitlename:any;
   
   
   constructor(
      private toastr: ToastrService,
      private commonService: CommonService,
      private commonApiService: CommonApiService,
      private fb: FormBuilder
   ) {}

   ngOnInit() {
      this.empform = this.fb.group({
         emp_Contract: this.fb.array([this.createItem()]),
      });
      $(document).ready(function () {
         $(".datepicker").flatpickr({ dateFormat: "d-m-Y" });
      })

      this.onChangeDays(null);
      

    console.log(this.inputFromParent);
    console.log(this.inputFromParentJT);
     
   }
 

   getContractList(id) {
     
      this.contractlist = [];

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "company_Id",
               "FieldValue": id,
               "Parameter": "=="
            }
         ],
         "OrderBy": "contract_Name"
      }

      this.commonApiService.GetByPaginated(Commonvariable.Contract, requestData).subscribe((response) => {
         if (response["data"] != "") {
            this.contractlist = response["data"];
         }
      })
      this.getZone(this.companyid);
   }

   createItem() {
     
     
     
      return this.fb.group({
         Emp_Contract_Id: [0],
         Contract_Id: '',
         Emp_Id: '0',
         Emp_Contract_Code: '',
         Emp_Contract_Name: '',
         Emp_Contract_HoursDaily: null,
         Emp_Contract_HoursWeekly: null,
         Emp_Contract_Days: '',
         Emp_Contract_Type: null,
        // Emp_Joining_Date:'',
         Emp_Job_Title:'',
         Emp_Role_Start: '',
         Emp_Role_End: '',
         Emp_Contract_Start: '',
         Emp_Contract_End: '',
         Emp_Doc_Id: null,
         Emp_Zone_Id:'',
         isRequired: null,
         Notes: null,
         Version_Id: null,
         isActive: true,
         AddedBy: Commonvariable.User_Id,
      });
     
   }

   addItem() {
      if (this.validation()) {
         this.items = this.empform.get('emp_Contract') as FormArray;
         this.items.push(this.createItem());
         setTimeout(() => {
            this.items = this.empform.get('emp_Contract') as FormArray;
            var id = this.items.length - 1;
            this.commonService.flatpickr("flatpickropenEmpCStD", id)
            // this.commonService.flatpickr("flatpickropenEmpCEnD", id)
         }, 5);
      }

   }
   
   getZone(companyid) {
     var id=companyid;
      this.commonApiService.cascadingDropDwon(Commonvariable.Zone, "company_Id", id > 0 ? id : $("#company_Id").val()).subscribe((response) => {
        if (response != null) {
          this.zoneList = response["data"];
          
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Candidate'); })
    }
 

   onChangeDays(days = null): void {
      var empcontract = this.empform.get('emp_Contract').value;
      for (let index = 0; index < empcontract.length; index++) {
         var contract_Days;
         if (days == null) {
            contract_Days = $("#Emp_Contract_Days" + index).val() === undefined ? null : $("#Emp_Contract_Days" + index).val().trim();
         } else {
            contract_Days = days
         }
         var selectedStartDate = $("#Emp_Contract_Start" + index).val();//moment($("#Emp_Contract_Start" + index).val(), 'DD-MM-YYYY').format("DD-MM-YYYY");         
         if (contract_Days != 0 && contract_Days != "" && selectedStartDate != "") {
            var endDate = moment(selectedStartDate, 'DD-MM-YYYY').add(contract_Days, 'days').format("DD-MM-YYYY");
            $("#Emp_Contract_End" + index).val(endDate);
            //$("#Emp_Contract_End" + index).flatpickr({ dateFormat: "d-m-Y", defaultDate: endDate });
         }
      }
   }

   removeItem(index) {
      this.items = this.empform.get('emp_Contract') as FormArray;
      this.items.removeAt(index);
   }

   empUpsert() {
      
      var empcontract = this.empform.get('emp_Contract').value;
      if (empcontract[0].Contract_Id == "") {
         return false;
      }
      return true;
   }


   selectContractType(event) {
      this.getZone(this.companyid);
      this.items = this.empform.get('emp_Contract') as FormArray;
      this.items.controls[0]["controls"].Emp_Contract_Days.setValue(this.contractlist.find(x=>x.contract_Id == event.target.value).contract_Days)
      this.onChangeDays(null)
   }
   

   validation() {
    
      var empcontract = this.empform.get('emp_Contract').value;
      var tempArray = [];
      for (let index = 0; index < empcontract.length; index++) {

         empcontract[index].Emp_Contract_Name = $("#Contract_Id" + index + " option:selected").text().trim();
         empcontract[index].Emp_Job_Title = this.inputFromParentJT;


         if (empcontract[0].Contract_Id != "" || $("#Emp_Contract_Start0").val() != "" || $("#Emp_Contract_Days0").val() != "") {

            if (empcontract[index].Contract_Id == "") {
               this.toastr.info("Contract type is required !", "Employee");
               $("#Contract_Id" + index).focus();
               return false;
            }
            // if ($("#Contract_Id" + index).val().length < 2) {
            //    this.toastr.info("Contract type must be at least 2 characters long !", "Employee");
            //    $("#Contract_Id" + index).focus();
            //    return false;z
            // }
            // if ($("#Contract_Id" + index).val().indexOf(" ") > -1) {
            //    this.toastr.info("Contract type not allow space !", "Employee");
            //    $("#Contract_Id" + index).focus();
            //    return false;
            // }
            // if (empcontract[index].Emp_Contract_Name == "") {
            //    this.toastr.info("Contract name is required !", "Employee");
            //    $("#Emp_Contract_Name" + index).focus();
            //    return false;
            // }
            // if (empcontract[index].Emp_Contract_Name.length < 2) {
            //    this.toastr.info("Contract name must be at least 2 characters long !", "Employee");
            //    $("#Emp_Contract_Name" + index).focus();
            //    return false;
            // }
            // if (empcontract[index].Emp_Contract_Name.indexOf(" ") > 0) {
            //    this.toastr.info("Contract name not allow space !", "Employee");
            //    $("#Emp_Contract_Name" + index).focus();
            //    return false;
            // }
            // if (empcontract[index].Emp_Contract_Days == "") {
            //    this.toastr.info("Length of Contract is required !", "Employee");
            //    $("#Emp_Contract_Days" + index).focus();
            //    return false;
            // }
            // if ($("#Emp_Contract_Days" + index).val().length < 2) {
            //    this.toastr.info("Length of Contract must be at least 2 digit long !", "Employee");
            //    $("#Emp_Contract_Days" + index).focus();
            //    return false;
            // }
            // if ($("#Emp_Joining_Date" + index).val() == "") {
            //    this.toastr.info("Continuous Service Date  is required !", "Employee");
            //    $("#Emp_Joining_Date" + index).focus();
            //    return false;
            // }
            if ($("#Emp_Role_Start" + index).val() == "") {
               this.toastr.info("Role start is required !", "Employee");
               $("#Emp_Role_Start" + index).focus();
               return false;
            }
            if ($("#Emp_Job_Title" + index).val() == "") {
               this.toastr.info("Job Title is required !", "Employee");
               $("#Emp_Job_Title" + index).focus();
               return false;
            }

            // if ($("#Emp_Contract_Start" + index).val() == "") {
            //    this.toastr.info("Contract start is required !", "Employee");
            //    $("#Emp_Contract_Start" + index).focus();
            //    return false;
            // }


            // if ($("#Emp_Contract_Days" + index).val().search(/[^a-zA-Z]+/) > -1) {
            //    this.toastr.info("Length of Contract only alphabets allow !", "Employee");
            //    $("#Emp_Contract_Days" + index).focus();
            //    return false;
            // }
            // if ($("#Emp_Contract_Days" + index).val().indexOf(" ") > -1) {
            //    this.toastr.info("Length of Contract not allow space !", "Employee");
            //    $("#Emp_Contract_Days" + index).focus();
            //    return false;
            // }

            // if ($("#Emp_Contract_End" + index).val() == "") {
            //    this.toastr.info("Contract end is required !", "Employee");
            //    $("#Emp_Contract_End" + index).focus();
            //    return false;
            // }
            // if (moment($("#Emp_Contract_Start" + index).val(), 'DD-MM-YYYY') > moment($("#Emp_Contract_End" + index).val(), 'DD-MM-YYYY')) {
            //    this.toastr.info("Employee contract start date not more then employee contract end date !", "Employee");
            //    $("#Emp_Contract_Start" + index).focus();
            //    return false;
            // }
            if (moment($("#Emp_Role_Start" + index).val(), 'DD-MM-YYYY') > moment($("#Emp_Role_End" + index).val(), 'DD-MM-YYYY')) {
               this.toastr.info("Employee Role start date not more then employee Role end date !", "Employee");
               $("#Emp_Role_Start" + index).focus();
               return false;
            }
            // empcontract[index].Emp_Contract_Start = this.commonService.dateFormatChange($("#Emp_Contract_Start" + index).val())
            // empcontract[index].Emp_Contract_End = this.commonService.dateFormatChange($("#Emp_Contract_End" + index).val())
          //  empcontract[index].Emp_Joining_Date = this.commonService.dateFormatChange($("#Emp_Joining_Date" + index).val())
            empcontract[index].Emp_Role_Start = this.commonService.dateFormatChange($("#Emp_Role_Start" + index).val())
            empcontract[index].Emp_Role_End = this.commonService.dateFormatChange($("#Emp_Role_End" + index).val())

            var tempData = {
              // "empContractStart": String(empcontract[index].Emp_Contract_Start).trim(),
             // "empJoiningDate": String(empcontract[index].Emp_Joining_Date).trim(),
              "empRoleStart": String(empcontract[index].Emp_Role_Start).trim(),
              "empRoleEnd": String(empcontract[index].Emp_Role_End).trim(),
              // "empContractDays": String(empcontract[index].Emp_Contract_Days).trim(),
              // "empContractEnd": String(empcontract[index].Emp_Contract_End).trim(),
            }

            if (tempArray.length > 0) {
               //var contractStart = tempArray.filter(x => x.empContractStart == tempData.empContractStart).length;
              // var joiningDate = tempArray.filter(x => x.empJoiningDate == tempData.empJoiningDate).length;
               var contractStart = tempArray.filter(x => x.empContractStart == tempData.empRoleStart).length;
              // var contractDays = tempArray.filter(x => x.empContractDays == tempData.empContractDays).length;
              // var contractEnd = tempArray.filter(x => x.empContractEnd == tempData.empContractEnd).length;
               var contractEnd = tempArray.filter(x => x.empContractEnd == tempData.empRoleEnd).length;

               if (contractStart > 0  && contractEnd > 0) {
                  this.toastr.info("This value already entered !", "Employee");
                  $("#Emp_Role_Start" + index).focus();
                  return false;
               }
            }
            tempArray.push(tempData);


         }
         empcontract[index].isActive = empcontract[index].isActive ? 1 : 0
      }

      return true;
   }

   
 
}




