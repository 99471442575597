import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Commonvariable } from '../models/commonvariable';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
//import flatpickr from "flatpickr";
declare var $: any;
declare var moment: any;

import { TEMPORARY_NAME, temporaryAllocator } from '@angular/compiler/src/render3/view/util';
import { tr } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  //baseUrl = "http://172.16.1.61:8088/api/";
  baseUrl = Commonvariable.BASE_API_URL;

  public myData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private httpClient: HttpClient, private toastr: ToastrService) { }

  deleteConfirm() {
    return Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    })
  }

  dyanmicConfirm(text, buttonText) {
    return Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: buttonText
    })
  }

  dyanmicConfirmForApplyLeave(text, buttonText) {
    return Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: buttonText
    })
  }

  dyanmicConfirmwithdenybutton(text, buttonText, ebuttontext) {
    return Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonColor: '#4ED354',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Close',
      confirmButtonText: buttonText,
      denyButtonText: ebuttontext,
      denyButtonColor: '#3085d6'
    })
  }

  takeReasonConfirm(title, buttonText) {
    return Swal.fire({
      title: title,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: buttonText,
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage(
            'Note is required'
          )
        } else {
          return value
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }

  takerejctapprove(title, buttonText) {

    return Swal.fire({
      title: title,
      text: buttonText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Approve!',
      cancelButtonText: 'Reject!',
      reverseButtons: true
    })

  }

  setEditData(form, editData, id = 0) {
    for (const key of Object.keys(form.controls)) {
      if (key != 'updatedBy') {
        form.controls[key].setValue(editData[id][key]);
      }
    }
  }
  
  setWizardData(form, data) {
    for (const key of Object.keys(data)) {
      form[key] = data[key];
    }
  }


  filterData(event, list, key) {
    const val = event.target.value.toLowerCase();
    const temp = list.filter(function (d) {
      return d[key].toLowerCase().indexOf(val) !== -1 || !val;
    });
    return temp;
  }

  // filterDataByArray(event, list, key) {
  //   var array = key.split(",");
  //   var temp = list;
  //   for (let index = 0; index < array.length; index++) {
  //     temp  = list.filter(function (d) {
  //       return d[array[index]].indexOf(event.target.value) !== -1 || !event.target.value;
  //     });
  //   }
  //   return temp;
  // }


  select2SingleDropDown(className, value = null) {
    $('.' + className).select2({ width: '100%', dropdownParent: $("#draggable") });
    $('.' + className).val(value); // Select the option with a value of '1'
    $('.' + className).trigger('change'); // Notify any JS components that the value changed    
  }

  dateFormatChange(dateval) {
    var date = dateval.split("-");
    return dateval == "" ? "" : date[2] + "-" + date[1] + "-" + date[0]
  }
  dateTimeFormatChange(dateval) {
    var time = dateval.split(" ");
    var date = time[0].split("-");
    return dateval == "" ? "" : date[2] + "-" + date[1] + "-" + date[0] + " " + time[1]
  }


  refilldateTimeFormatChange(dateval) {
    var time = dateval.split("T");
    var date = time[0].split("-");
    return dateval == "" ? "" : date[2] + "-" + date[1] + "-" + date[0] + " " + String(time[1]).substring(0, 5)
  }

  flatpickr(key, value) {
    
    if (value > 0) {

      // flatpickr('#' + key + value, { dateFormat: "d-m-Y" })

      $("#" + key + value).flatpickr({ dateFormat: "d-m-Y" });
    }
    else {
      //flatpickr('#' + key, { dateFormat: "d-m-Y" })
      $("#" + key).flatpickr({ dateFormat: "d-m-Y" });
    }

  }

  flatpickrForDOB(key, value) {
    if (value > 0) {
      $("#" + key + value).flatpickr({ dateFormat: "d-m-Y", maxDate: "today" });
    }
    else {
      $("#" + key).flatpickr({ dateFormat: "d-m-Y", maxDate: "today" });
    }

  }
  flatpickrWithTime(key, value) {
    if (value > 0) {
      $("#" + key + value).flatpickr({ dateFormat: "d-m-Y H:i", enableTime: true, defaultDate: moment(new Date()).format('DD-MM-YYYY HH:mm') });
    }
    else {
      $("#" + key).flatpickr({ dateFormat: "d-m-Y H:i", enableTime: true, defaultDate: moment(new Date()).format('DD-MM-YYYY HH:mm') });
    }

  }


  flatpickrForDOJ(key, value) {
    if (value > 0) {
      $("#" + key + value).flatpickr({
        dateFormat: "d-m-Y",
        minDate: "today",
        "disable": [
          function (date) {
            // return true to disable
            return (date.getDay() === 0);
          }
        ],
        "locale": {
          "firstDayOfWeek": 1 // start week on Monday
        }
      });
    }
    else {
      $("#" + key).flatpickr({
        dateFormat: "d-m-Y", minDate: "today",
        "disable": [
          function (date) {
            // return true to disable
            return (date.getDay() === 0);
          }
        ],
        "locale": {
          "firstDayOfWeek": 1 // start week on Monday
        }
      });
    }

  }

  dfpickr() {
    $('#flatpickropenJoiningD').flatpickr().destroy();
  }

  timePicker(key, value) {
  
    if (value > 0) {
      $("#" + key + value).flatpickr({
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      });
    }
    else {
      $("#" + key).flatpickr({
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      });
    }

  }

  todayDate(day = 0) {
    var tDate = new Date();
    tDate.setDate(tDate.getDate() + day)
    var date = tDate.getDate().toString().length == 1 ? "0" + tDate.getDate() : tDate.getDate();
    var month = tDate.getMonth() + 1;
    var getmonth = month.toString().length == 1 ? "0" + month : month;

    return getmonth + "-" + date + "-" + tDate.getFullYear();
  }

  todayDateYYMMDDFormate(day = 0) {
    var tDate = new Date();
    tDate.setDate(tDate.getDate() + day)
    var date = tDate.getDate().toString().length == 1 ? "0" + tDate.getDate() : tDate.getDate();
    var month = tDate.getMonth() + 1;
    var getmonth = month.toString().length == 1 ? "0" + month : month;

    return tDate.getFullYear() + "-" + getmonth + "-" + date;
  }

  toaster(method, msg, title="") {
    if (method == 'success') {
      this.toastr.success(msg, title)
    }
    else if (method == 'info') {
      this.toastr.info(msg, title)
    }
    else if (method == 'warning') {
      this.toastr.warning(msg, title)
    }
    else if (method == 'error') {
      this.toastr.error(msg, title)
    }
  }

  //set null value for all field of form  
  setNull(form) {
    for (const key of Object.keys(form.controls)) {
      if (form.controls[key].value == "" && key != "isActive" && key != "isDefault" && key != "isColor_Code") {
        form.controls[key].setValue(null);
      }
    }
  }

  getRolePermission(menuName) {
    let currentHRUser = JSON.parse(localStorage.getItem('currentHRUser'))

    if (currentHRUser) {
      if (currentHRUser["data"]) {
        if (currentHRUser["data"]["menuPermission"]) {
          return currentHRUser["data"]["menuPermission"].find(x => x.menu_Name == menuName)
        }
      }
    }

    return "";

  }









}
