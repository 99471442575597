import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;


@Component({
  selector: 'app-emergencycandidate',
  templateUrl: './emergency-contact-candidate.component.html'
})
export class EmergencyCandidateComponent implements OnInit {
  candidateForm: FormGroup;
  emerrelList: any;
  countryList: Array<Select2OptionData>;
  editMode: boolean = false;
  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {

    this.editMode = false;
    this.candidateForm = this.fb.group({
      id: ['0'],
      person_Id: ['0'],
      contactName: ['', Validators.required],
      country_Id:[null],
      contactNo: ['', Validators.required],
      relationship_Id: ['', Validators.required],
      isDefault: false,
      isActive: ['1'],
      isReview: ['1'],
      updatedBy: [Commonvariable.User_Id]
    });
    this.getRelationshipList();
    this.getCountryList();

  }

  getCountryList() {

    var requestData = {
       "searchBy": [
          {
             "FieldName": "isActive",
             "FieldValue": "1",
             "Parameter": "=="
          }
       ],
       "OrderBy": "country_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Country, requestData).subscribe((response) => {
       if (response != null && response["data"] != "") {
          this.countryList = response["data"].map(x => ({
             id: x.id,
             text: x.country_Name + " (" + x.country_Code + ") "
          }));          
          if(this.editMode == false){
            this.candidateForm.controls.country_Id.setValue(response["data"].filter(x => x.isDefault == 1)[0]["id"]); 
          }
       }
    })
 }



  getRelationshipList() {
    this.commonApiService.List(Commonvariable.Relationship).subscribe((result) => {
      if (result != null && result["data"] != "") {
        //this.emerrelList = result["data"].sort((a, b) => a.name.localeCompare(b.name));
        this.emerrelList = result["data"].map(x => ({
          id: x.id,
          text: x.name
        }));
      }
    });
  }



  Edit(Id) {
    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.Person_Emergency, Id).subscribe((response) => {
        if (response != null) {
          this.editMode = true;
          this.getCountryList();
          var editData = response["data"];
          var formData = this.candidateForm;
          this.commonService.setEditData(formData, editData);
        }
      })
    }
  }


  validation() {
    var eForm = this.candidateForm;
    //sForm.get("company_Id").setValue($("#company_Id").val()); 
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);
    eForm.get("isDefault").setValue(eForm.get("isDefault").value ? 1 : 0);
    if (!eForm.valid) {
      for (const key of Object.keys(eForm.controls)) {
        if (eForm.controls[key].invalid) {
          let fieldName = "";

          if (key == "contactName") {
            fieldName = "Contact Name"
          }
          else if (key == "contactNo") {
            fieldName = "Contact number"
          }
          else if (key == "relationship_Id") {
            fieldName = "Relationship"
          }
          this.toastr.info(fieldName + " is required !", "Candidate");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if ( eForm.value.country_Id == null) {
        this.toastr.info("Country is required !", "Candidate");
        $("#country_Id select").focus();
        return false;
      }

      // if ($("#contactName").val().length < 2) {
      //   this.toastr.info("Contact name must be at least 2 characters long !", "Candidate");
      //   $("#contactName").focus();
      //   return false;
      // }
      if ($("#contactName").val().search(/^[^-\s][a-zA-Z_\s-]+$/) != 0) {
        this.toastr.info("Please enter valid contact name!", "Candidate");
        $("#contactName").focus();
        return false;
      }
      // if ($("#contactNo").val().search(/[^0-9]+/) > -1) {
      //   this.toastr.info("Contact No  only numeric allow !", "Candidate");
      //   $("#contactNo").focus();
      //   return false;
      // }
      
      // if ($("#contactNo").val().length < 9) {
      //   this.toastr.info("Contact Details must be at least 9 digit long !", "Candidate");
      //   $("#contactNo").focus();
      //   return false;
      // }
      
      // if ($("#contactNo").val().indexOf(" ") > -1) {
      //   this.toastr.info("Contact number not allow space !", "Candidate");
      //   $("#contactNo").focus();
      //   return false;
      // }
      // if ($("#relationship").val().length < 2) {
      //   this.toastr.info("Relationship must be at least 2 characters long !", "Candidate");
      //   $("#relationship").focus();
      //   return false;
      // }
      // if ($("#relationship").val().indexOf(" ") > -1) {
      //   this.toastr.info("Relationship not allow space !", "Candidate");
      //   $("#relationship").focus();
      //   return false;
      // }
    }


    return true;
  }

  addRelationship(event) {
    if (event.target.value != "") {
      if (this.emerrelList.filter(x => x.name == event.target.value).length == 0) {
        var data = {
          "Id": 0,
          "Name": event.target.value,
          "isActive": 1,
          "AddedBy": Commonvariable.User_Id
        }
        this.commonApiService.Upsert(Commonvariable.Relationship, data, 0).subscribe((result) => {
          this.getRelationshipList();
        });
      }
    }
  }




}
