import { Component, OnInit,ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;

@Component({
  selector: 'app-gender',
  templateUrl: './gender.component.html'  
})
export class GenderComponent implements OnInit {  

  @ViewChild(HistoryComponent,{static:false}) historyView : HistoryComponent;
  genderform: FormGroup;
  genderList: any;
  filterList:any;
  companylist: any;
  listcall: boolean = true;
  title: string;
  p: number = 1;
  totalRecord:0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.title = "Add";
    this.genderform = this.fb.group({
      id: ['0'],      
      gender: ['', Validators.required],      
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id]
    });
    
    if (this.listcall){
      this.getGenderList();      
    }  
      
  }

  getGenderList(PageIndex = 1) {

    this.p = PageIndex;
    this.commonApiService.getListWithFilter(Commonvariable.Gender, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.genderList = result["data"]["list"];
        this.filterList = result["data"]["list"];
      }
    })    
  }


  Upsert() {
    var Form = this.genderform;

    if (this.validation()) {
      Form.get("isActive").setValue(Form.get("isActive").value ? 1 : 0);
      this.commonApiService.Upsert(Commonvariable.Gender, Form.value, Form.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Gender');
          }
          else {
            $("#draggable").modal('hide');
            this.getGenderList();
            this.toastr.success(Form.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Gender');
          }
        }
      })
    }

  }

  Delete(Id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Gender, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getGenderList();
          this.toastr.success(response["message"], 'Gender');
          this.p = 1;
        })
      }
    })
  }

  openModel(Id) {
    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Gender, Id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var Form = this.genderform;
          this.commonService.setEditData(Form, editData);                    
        }
      })
    }
    else {      
      this.listcall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });


  }


  validation() {
    var Form = this.genderform;
    if (!Form.valid) {
      for (const key of Object.keys(Form.controls)) {
        if (Form.controls[key].invalid) {
          let fieldName = "";
          
          if (key == "gender") {
            fieldName = "Gender"
          }            
          this.toastr.info(fieldName + " is required !", "Gender");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {     
      if ($("#gender").val().length < 2) {
        this.toastr.info("Name must be at least 2 characters long !", "Gender");
        $("#gender").focus();
        return false;
      }          
      

      if ($("#gender").val().search(/[^a-zA-Z ]+/) > -1) {
        this.toastr.info("Gender only alphabets allow !", "Gender");
        $("#gender").focus();
        return false;
      }

    }

    return true;
  }

}
