<form [formGroup]="empform">
    <div class="form-group">
        <div class="table-responsive">
            <table class="table table-hover" id="work" style="position: relative;" role="grid">
                <thead>
                    <tr>
                        <th style="width: 25%;">Other Document <span class="required"
                            *ngIf="this.empform.get('emp_document').value[0].Doc_Id != '' || this.empform.get('emp_document').value.length > 1">
                            * </span></th>
                        <th style="width: 20%;">Document Name <span class="required"
                            *ngIf="this.empform.get('emp_document').value[0].Doc_Id != '' || this.empform.get('emp_document').value.length > 1">
                            * </span></th>                        
                        <th style="width: 15%;">Upload Document <span class="required"
                            *ngIf="this.empform.get('emp_document').value[0].Doc_Id != '' || this.empform.get('emp_document').value.length > 1">
                            * </span></th>
                        <th style="width: 20%;">Notes</th>
                        <th style="width: 2%;">Active</th>
                        <th style="width: 2%;">Company Document</th>
                        <th style="width: 5%;"><button type="button" (click)="addItem()" class="btn pull-right"><i
                                    class="fa fa-plus" aria-hidden="true"></i></button></th>
                    </tr>
                </thead>
                <tbody formArrayName="emp_document"
                    *ngFor="let item of empform.get('emp_document')['controls']; let i = index;">
                    <tr [formGroupName]="i">
                        <td>
                            <select class="form-control" id="Doc_Id{{i}}" formControlName="Doc_Id" (change) = "drpchange(i)"
                            *ngIf="!item.value.is_CompanyDoc">
                                <option value=''>-- Select --</option>
                                <option *ngFor="let item of documentList;" value={{item.id}}>
                                    {{item.name}}</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control" id="Emp_Doc_Name{{i}}"
                                formControlName="Emp_Doc_Name" autocomplete="off" maxlength="150" (change)="drpchange(i)" *ngIf="!item.value.is_CompanyDoc">
                        </td>                        
                        <td>
                            <div class="fileinput fileinput-new" data-provides="fileinput">
                                <div class="input-group input-large">
                                    <div class="form-control uneditable-input input-fixed input-medium"
                                        data-trigger="fileinput">
                                        <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                        <span class="fileinput-filename"> </span>
                                    </div>
                                    <span class="input-group-addon btn default btn-file">
                                        <span class="fileinput-new"> Select file </span>
                                        <span class="fileinput-exists"> Change </span>
                                        <input type="file" id="Emp_Doc_Path{{i}}" (change)="fileChange($event,i)">
                                    </span>
                                    <a href="javascript:;" id="remove{{i}}"
                                        class="input-group-addon btn red fileinput-exists" (click)="removeDoc(i)"
                                        data-dismiss="fileinput"> Remove </a>
                                </div>
                            </div>
                        </td>
                        <td>
                            <textarea class="form-control" formControlName="Notes" rows="1" id="Notes" *ngIf="!item.value.is_CompanyDoc"></textarea>
                        </td>

                        <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="empdoc{{i}}" class="md-check" formControlName="isActive">
                                    <label for="empdoc{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td>

                        <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="empCompanydoc{{i}}" class="md-check" formControlName="is_CompanyDoc">
                                    <label for="empCompanydoc{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td>
                        <td>
                            <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                                    class="fa fa-minus" aria-hidden="true"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</form>