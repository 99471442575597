import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html'
})
export class BudgetComponent implements OnInit {

  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  budgetform: FormGroup;
  budgetlist: any;
  filterList: any;
  companylist: any;
  languageList: Array<Select2OptionData>;
  listcall: boolean = true;
  title: string;
  p: number = 1;
  totalRecord: 0;
  role: any ;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    let currentHRUser = JSON.parse(localStorage.getItem('currentHRUser'))

    if (currentHRUser) {
      if(currentHRUser["data"]){
        if(currentHRUser["data"]["menuPermission"]){
          this.role = currentHRUser["data"]["menuPermission"].filter(x=>x.menu_Name == 'Budget')[0] 
        }
      }
    }
    
    this.title = "Add";
    this.budgetform = this.fb.group({
      id: ['0'],
      budgetName: ['', Validators.required],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });

    if (this.listcall) {
      this.getBudgetList();
    }

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }

  getBudgetList(PageIndex = 1) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.budgetlist = []
    this.commonApiService.getListWithFilter(Commonvariable.Budget, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.budgetlist = result["data"]["list"];
        this.filterList = result["data"]["list"];

        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })
  }

  setFormData(event) {
    this.budgetform.controls.language.setValue(event)
  }


  BudgetUpsert() {
    var Form = this.budgetform;

    if (this.validation()) {
      Form.get("isActive").setValue(Form.get("isActive").value ? 1 : 0);
      Form.get("budgetName").setValue(Form.get("budgetName").value);

      this.commonApiService.Upsert(Commonvariable.Budget, Form.value, Form.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Budget');
          }
          else {
            $("#draggable").modal('hide');
            this.getBudgetList();
            this.toastr.success(Form.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Budget');
          }
        }
      })
    }

  }

  BudgetDelete(Id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Budget, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getBudgetList();
          this.toastr.success(response["message"], 'Budget');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Budget, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.getBudgetList();
            this.toastr.success(response["message"], 'Budget');
          }

        })
      }

    });
  }

  openModel(Id) {
    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Budget, Id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var Form = this.budgetform;
          this.commonService.setEditData(Form, editData);
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Budget'); })
    }
    else {
      this.listcall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });


  }

  validation() {
    var Form = this.budgetform;
    if (!Form.valid) {
      for (const key of Object.keys(Form.controls)) {
        if (Form.controls[key].invalid) {
          let fieldName = "";
          if (key == "budgetName") {
            fieldName = "Budget name"
          }
          this.toastr.info(fieldName + " is required !", "Budget");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if ($("#budgetName").val().length < 2) {
        this.toastr.info("Budget name must be at least 2 characters long !", "Budget");
        $("#budgetName").focus();
        return false;
      }
      if (/["\\]/.test($("#budgetName").val())) {
        this.toastr.info("Budget name does not allow Special Characters \" \\ ", "Budget");
        $("#budgetName").focus();
        return false;
      }

    }

    return true;
  }

  history() {
    this.historyView.getHistory("budget", this.budgetform.value.id)
  }


}
