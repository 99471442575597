<style>
    div>hr,
    p {
        margin: 0 0 !important;
        border-top: 1px solid black;
    }
</style>
<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>Leave Rule</h1>
            </div>
        </div>

        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a href="javascript:;" (click)="openModel(0)" class="btn btn-transparent blue  btn-sm">Add
                            Leave Rule</a>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" placeholder="Type to filter ..." id="filterList"
                                (input)="getLeaveRuleList()">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblZone">
                            <thead class="flip-content">
                                <tr>

                                    <th class="text-center">Leave Type </th>
                                    <th class="text-center">Company</th>
                                    <th class="text-center" style="width: 10%;">Min Sequence Leave</th>
                                    <th class="text-center" style="width: 10%;">Max Sequence Leave</th>
                                    <th class="text-center" style="width: 10%;">Encashment Upto</th>
                                    <th class="text-center" style="width: 10%;">Probation Monthly Allowed</th>
                                    <th class="text-center" style="width: 10%;">Leaver Monthly Allowed</th>
                                    <th class="text-center" style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody
                                *ngFor="let item of leaveRulelist| paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">
                                <tr>
                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(item.id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12">{{item.leave_Type_Name}}
                                        </div>
                                    </td>
                                    <td> {{item.company_Name}} </td>
                                    <td class="text-center"> {{item.min_Sequence_Leave}} </td>
                                    <td class="text-center"> {{item.max_Sequence_Leave}} </td>
                                    <td class="text-center"> {{item.encashment_Leave_Upto}} </td>
                                    <td class="text-center"> {{item.probation_Monthly_Allowed}} </td>
                                    <td class="text-center"> {{item.leaver_Monthly_Allowed}} </td>
                                    <td class="text-center">
                                        <a *ngIf="item.isActive" (click)="openModel(item.id)"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Leave Rule"></i></a>
                                        <a *ngIf="!item.isActive" (click)="Delete(item.id)">
                                            <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Delete Leave Rule"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center">
                        <pagination-controls (pageChange)="getLeaveRuleList($event)" autoHide="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->


<div class="modal fade draggable-modal" id="draggable" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:1% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Leave Rule</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="leaveRuleform">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Leave Type<span class="required"> * </span></label>
                                <ng-select2 [data]="leaveTypeList" id="leavetypeid"
                                    (valueChanged)="setFormData('leavetypeid',$event)" [disabled]="title == 'Edit'"
                                    [value]="leaveRuleform.controls.leave_Type_Id.value"
                                    [placeholder]="'--- Select ---'">
                                </ng-select2>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="title == 'Add'">
                            <div class="form-group">
                                <label class="control-label">Company<span class="required"> * </span></label>
                                <ng-select2 [data]="companylist" id="companyid" [options]="{multiple: true,tags: false}"
                                    [width]="400" (valueChanged)="setFormData('companyid',$event)"
                                    [placeholder]="'--- Select ---'">
                                </ng-select2>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="title == 'Edit'">
                            <div class="form-group">
                                <label class="control-label">Company<span class="required"> * </span></label>
                                <input id="tempCompany" class="form-control" value="{{setcompanyName}}" readonly>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Paid <span class="required"> * </span></label>
                                <hr>
                                <div class="md-radio-inline">
                                    <div class="md-radio">
                                        <input type="radio" id="isPaidyes" name="isPaid" value="Paid"
                                            formControlName="isPaid" class="md-radiobtn" (click)="isPaidStatus('Paid')">
                                        <label for="isPaidyes">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> Yes </label>
                                    </div>
                                    <div class="md-radio">
                                        <input type="radio" id="isPaidno" name="isPaid" value="UnPaid" (click)="isPaidStatus('UnPaid')"
                                            formControlName="isPaid" class="md-radiobtn">
                                        <label for="isPaidno">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span> No </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2" style="margin-top: 1%;">
                            <div class="form-group">
                                <div class="md-checkbox-inline">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="isActive" class="md-check"
                                            formControlName="isActive">
                                        <label for="isActive">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span>Active</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row" style="border:1px solid #ccc;margin:0px 0px 15px 0px;" *ngIf="paidStatus =='Paid'">
                        <div class="col-md-12">
                            <h4>Leave Upload</h4>
                        </div>

                        <div class="box-data col-md-12">


                            <div class="col-md-3">
                                <div class="form-group">
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="isBalance" class="md-check"
                                                (change)="isBalance = !isBalance" formControlName="is_Balance">
                                            <label for="isBalance">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span>Balance</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3" *ngIf="isBalance">
                                <div class="form-group">
                                    <label class="control-label">Balance Type <span class="required"> * </span></label>
                                    <hr>
                                    <div class="md-radio-inline">
                                        <div class="md-radio">
                                            <input type="radio" id="balanceTypenormal" name="balance_Type"
                                                value="Normal" formControlName="balance_Type" class="md-radiobtn"
                                                (change)="advancedBalanceType = 'Normal'">
                                            <label for="balanceTypenormal">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> Normal </label>
                                        </div>
                                        <div class="md-radio">
                                            <input type="radio" id="balanceTypeadvance" name="balance_Type"
                                                value="Advanced" formControlName="balance_Type" class="md-radiobtn"
                                                (change)="advancedBalanceType = 'Advanced'">
                                            <label for="balanceTypeadvance">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> Advanced </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3" *ngIf="advancedBalanceType == 'Normal' && isBalance">
                                <div class="form-group">
                                    <label class="control-label">Balance <span class="required"> * </span></label>
                                    <input type="text" id="normal_Balance" class="form-control" autocomplete="off"
                                        onkeypress='return (event.charCode >= 46 && event.charCode <= 57)'
                                        maxlength="10" formControlName="normal_Balance">
                                </div>
                            </div>
                            <div class="col-md-3" *ngIf="advancedBalanceType == 'Advanced' && isBalance">
                                <div class="form-group">
                                    <label class="control-label">Hourly Balance <span class="required"> *
                                        </span></label>
                                    <input type="text" id="advanced_Hourly_Balance" class="form-control"
                                        autocomplete="off"
                                        onkeypress='return (event.charCode >= 46 && event.charCode <= 57)'
                                        maxlength="10" formControlName="advanced_Hourly_Balance">
                                </div>
                            </div>
                            <div class="col-md-3" *ngIf="advancedBalanceType == 'Advanced' && isBalance">
                                <div class="form-group">
                                    <label class="control-label">Annually Balance <span class="required"> *
                                        </span></label>
                                    <input type="text" id="advanced_Annually_Balance" class="form-control"
                                        autocomplete="off"
                                        onkeypress='return (event.charCode >= 46 && event.charCode <= 57)'
                                        maxlength="10" formControlName="advanced_Annually_Balance">
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="row" style="border:1px solid #ccc;margin:0px 0px 15px 0px;">
                        <div class="col-md-12">
                            <h4>Leave Application</h4>
                        </div>

                        <div class="box-data col-md-12">




                            <div class="col-md-3">
                                <div class="form-group">
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="isLimit" class="md-check"
                                                (change)="isLimit = !isLimit" formControlName="is_Sequence">
                                            <label for="isLimit">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span>Limit</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="isLimit">
                                <div class="form-group">
                                    <!-- <label class="control-label">Min Limit <span class="required"> * </span></label> -->
                                    <input type="text" placeholder="Minimum Limit" id="min_Sequence_Leave" class="form-control" autocomplete="off"
                                        onkeypress='return (event.charCode >= 46 && event.charCode <= 57)'
                                        maxlength="10" formControlName="min_Sequence_Leave">
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="isLimit">
                                <div class="form-group">
                                    <!-- <label class="control-label">Max Limit <span class="required"> * </span></label> -->
                                    <input type="text" placeholder="Maximum Limit" id="max_Sequence_Leave" class="form-control" autocomplete="off"
                                        onkeypress='return (event.charCode >= 46 && event.charCode <= 57)'
                                        maxlength="10" formControlName="max_Sequence_Leave">
                                </div>
                            </div>



                        </div>


                        <div class="box-data col-md-12">


                            <div class="col-md-3">
                                <div class="form-group">
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="isProbation" class="md-check"
                                                (change)="isProbation = !isProbation" formControlName="is_Probation">
                                            <label for="isProbation">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span>Probation</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="isProbation">
                                <div class="form-group">
                                    <!-- <label class="control-label">Up To <span class="required"> * </span></label> -->
                                    <input type="text" placeholder="Maximum Limit" id="probation_Monthly_Allowed" class="form-control"
                                        autocomplete="off"
                                        onkeypress='return (event.charCode >= 46 && event.charCode <= 57)'
                                        maxlength="10" formControlName="probation_Monthly_Allowed">
                                </div>
                            </div>


                        </div>

                        <div class="box-data col-md-12">


                            <div class="col-md-3">
                                <div class="form-group">
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="isLeaver" class="md-check"
                                                (change)="isLeaver = !isLeaver" formControlName="is_Leaver">
                                            <label for="isLeaver">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span>Employee Exit</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="isLeaver">
                                <div class="form-group">
                                    <!-- <label class="control-label">Up To <span class="required"> * </span></label> -->
                                    <input type="text" placeholder="Maximum Limit" id="leaver_Monthly_Allowed" class="form-control"
                                        autocomplete="off"
                                        onkeypress='return (event.charCode >= 46 && event.charCode <= 57)'
                                        maxlength="10" formControlName="leaver_Monthly_Allowed">
                                </div>
                            </div>


                        </div>


                        <div class="box-data col-md-12">



                            <div class="col-md-3">
                                <div class="form-group">
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="sandwichApply" class="md-check"
                                                (change)="isSandwichApply = !isSandwichApply"
                                                formControlName="is_Sandwich_Apply">
                                            <label for="sandwichApply">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span>Sandwich Leave</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="isSandwichApply">
                                <div class="form-group">
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="holidayForSandwich" class="md-check"
                                                formControlName="is_Holiday_For_Sandwich">
                                            <label for="holidayForSandwich">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span>Consider Holiday In Sandwich</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-data col-md-12">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <div class="md-checkbox">
                                        <input type="checkbox" id="ApprovedLeaveCancellation" class="md-check"
                                            (change)="isApprovedLeaveCancel = !isApprovedLeaveCancel"
                                            formControlName="is_Approved_Leave_Cancel">
                                        <label for="ApprovedLeaveCancellation">
                                            <span></span>
                                            <span class="check"></span>
                                            <span class="box"></span>Approved Leave Cancellation</label>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>






                    <div class="row" style="border:1px solid #ccc;margin:0px 0px 15px 0px;" *ngIf="paidStatus == 'Paid'">
                        <div class="col-md-12">
                            <h4>Leave Process</h4>
                        </div>

                        <div class="box-data col-md-12">




                            <div class="col-md-3">
                                <div class="form-group">
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="isCarryForward" class="md-check"
                                                (change)="isCarryForward = !isCarryForward"
                                                formControlName="is_CarryForward">
                                            <label for="isCarryForward">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span>Carry Forward</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="isCarryForward">
                                <div class="form-group">
                                    <!-- <label class="control-label">Up To <span class="required"> * </span></label> -->
                                    <input type="text" placeholder="Maximum Limit" id="carryForward_Leave_Upto" class="form-control"
                                        autocomplete="off"
                                        onkeypress='return (event.charCode >= 46 && event.charCode <= 57)'
                                        maxlength="10" formControlName="carryForward_Leave_Upto">
                                </div>
                            </div>
                            <!-- <div class="col-md-3" *ngIf="isCarryForward">
                                <div class="form-group">
                                    <div class="md-radio-inline">
                                        <div class="md-radio">
                                            <input type="radio" id="priorityCaryForward" name="LeaveProcessPriority"
                                                value="CarryForword" formControlName="LeaveProcessPriority" class="md-radiobtn"
                                                (change)="advancedleaveProcessPriority = 'CarryForward'">
                                            <label for="priorityCaryForward">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> Primary </label>
                                        </div>
                                        
                                    </div>                                   
                                </div>
                            </div>  -->


                        </div>

                        <div class="box-data col-md-12">



                            <div class="col-md-3">
                                <div class="form-group">
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="isEncashment" class="md-check"
                                                (change)="isEncashment = !isEncashment" formControlName="is_Encashment">
                                            <label for="isEncashment">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span>Encashment</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-4" *ngIf="isEncashment">
                                <div class="form-group">
                                    <!-- <label class="control-label">Up To <span class="required"> * </span></label> -->
                                    <input type="text" placeholder="Maximum Limit" id="encashment_Leave_Upto" class="form-control"
                                        autocomplete="off"
                                        onkeypress='return (event.charCode >= 46 && event.charCode <= 57)'
                                        maxlength="10" formControlName="encashment_Leave_Upto">
                                </div>
                            </div>
                            <!-- <div class="col-md-3" *ngIf="isCarryForward">
                                <div class="form-group">
                                    <div class="md-radio-inline">
                                        <div class="md-radio">
                                            <input type="radio" id="priorityEncashment" name="LeaveProcessPriority"
                                                value="Encashment" formControlName="LeaveProcessPriority" class="md-radiobtn"
                                                (change)="advancedleaveProcessPriority = 'Encashment'">
                                            <label for="priorityEncashment">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> Primary </label>
                                        </div>
                                        
                                    </div>                                   
                                </div>
                            </div>    -->
                        </div>

                        <div class="box-data col-md-12">
                            <div class="col-md-6" *ngIf="isCarryForward && isEncashment">
                                <div class="form-group">
                                    <label class="control-label">Priority<span class="required"> * </span></label>
                                    <hr>
                                    <div class="md-radio-inline">
                                        <div class="md-radio">
                                            <input type="radio" id="isPriorityCF" name="LeaveProcessPriority" value="CarryForward"
                                                formControlName="LeaveProcessPriority" class="md-radiobtn">
                                            <label for="isPriorityCF">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> Carry Forward </label>
                                        </div>
                                        <div class="md-radio">
                                            <input type="radio" id="isPriorityEncashment" name="LeaveProcessPriority" value="Encashment"
                                                formControlName="LeaveProcessPriority" class="md-radiobtn">
                                            <label for="isPriorityEncashment">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> Encashment </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-3">
                                <div class="form-group">
                                    <div class="md-radio-inline">
                                        <div class="md-radio">
                                            <input type="radio" id="priorityCaryForward" name="LeaveProcessPriority"
                                                value="CarryForword" formControlName="LeaveProcessPriority" class="md-radiobtn"
                                                (change)="advancedleaveProcessPriority = 'CarryForward'">
                                            <label for="priorityCaryForward">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> Carry Forward </label>
                                        </div>
                                        
                                    </div>                                   
                                </div>
                            </div>      -->

                            <!-- <div class="col-md-3">
                                <div class="form-group">
                                    <div class="md-radio-inline">
                                        <div class="md-radio">
                                            <input type="radio" id="priorityEncashment" name="LeaveProcessPriority"
                                                value="Encashment" formControlName="LeaveProcessPriority" class="md-radiobtn"
                                                (change)="advancedleaveProcessPriority = 'Encashment'">
                                            <label for="priorityEncashment">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> Encashment </label>
                                        </div>
                                        
                                    </div>                                   
                                </div>
                            </div>      -->
                        
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn  dark btn-outline pull-left" (click)="history()"
                    *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button>
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" class="btn blue" (click)="Upsert()">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Leave Rule History</h4>
            </div>
            <div class="modal-body" *ngIf="title == 'Edit'">
                <app-history></app-history>
            </div>
        </div>
    </div>
</div>