<!-- <style>
    ul.page-sidebar-menu::-webkit-scrollbar {
        max-width: 2px;
    }

    .page-sidebar.navbar-collapse.collapse {
        min-height: 86vh !important;
    }

    ul.page-sidebar-menu::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #bfbfbf;
        border-radius: 10px;
    }

    ul.page-sidebar-menu::-webkit-scrollbar-thumb {
        background: #3d457c;
        border-radius: 10px;
    }

    ul.page-sidebar-menu::-webkit-scrollbar-thumb:hover {
        background: #3d457c;
    }

    #menuScrollBar {
        overflow-y: scroll !important;
        max-height: 200px !important;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    
    ::-webkit-scrollbar-thumb {
        background: #678098;
    }

    
    ::-webkit-scrollbar-thumb:hover {
        background: #888;
    }
</style> -->
<!-- BEGIN SIDEBAR -->

<div class="page-sidebar-wrapper">
    <div class="page-sidebar navbar-collapse collapse" id="menuScrollBar" style="overflow-y: auto !important;max-height: 92vh !important;">
        <ul class="page-sidebar-menu" data-keep-expanded="false" data-slide-speed="0" *ngFor="let item of datas;">
            <!-- <li class="nav-item start active" data-auto-scroll="true">
                <a routerLink="/dashboard" class="nav-link nav-toggle">
                    <i class="icon-bar-chart"></i>
                    <span class="title">Dashboard</span>
                </a>
            </li> -->
            <li class="heading">
                <h3 class="uppercase">{{item.titleName}}</h3>
            </li>
            <li *ngFor="let citem of item.childData" class="nav-item" [class.open]="citem.collapse">
                <a class="nav-link nav-toggle" (click)="collapseMenu(citem.menuName)">
                    <i class="{{citem.icon}}"></i>
                    <span class="title">{{citem.menuName}}</span>
                    <span class="arrow" [class.open]="citem.collapse"></span>
                </a>
                <ul class="sub-menu" [style.display]="citem.collapse ?'block':'none'">
                    <li class="nav-item sm" [class.open]="router.url.includes(cdata.link)" *ngFor="let cdata of citem.leafData">
                        <a routerLink="{{cdata.link}}" class="nav-link " *ngIf="cdata.link">
                            <span class="title">{{cdata.subMenuName}}</span>
                        </a>
                        <a class="nav-link" *ngIf="!cdata.link">
                            <span class="title">{{cdata.subMenuName}}</span>
                            <span class="arrow"></span>
                        </a>
                        <ul class="sub-menu">
                            <li class="nav-item sm" [class.open]="router.url.includes(cdata.link)" *ngFor="let cdata2 of cdata.bottomLeafData">
                                <a routerLink="{{cdata2.link}}" class="nav-link ">
                                    <span class="title">{{cdata2.bottomSubMenuName}}</span>
                                </a>
                            </li>
                        </ul>
                     
                    </li>
                </ul>
            </li>
        
        </ul>
        <ul>
                <a href="http://172.16.2.196/HR-Report/hedcount_report.php" class="nav-link" target="_blank">
                    <span class="title"> <i class="icon-graph"></i> Report</span>
                </a>
        </ul>

        <!-- END SIDEBAR MENU -->
    </div>

    <!-- END SIDEBAR -->
</div>
<!-- END SIDEBAR -->