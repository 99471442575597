<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>SignUp Request
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->

        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <!-- <li>
                    <div>
                        <a (click)="openModel(0)" class="btn btn-transparent blue  btn-sm"></a>
                    </div>
                </li> -->
            </ul>
        </div>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">
                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" placeholder="Type to filter ..." id="filterList"
                                (input)="getSignupList()">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblZone">
                            <thead class="flip-content">
                                <tr>

                                    <th class="text-center" style="width: 8%;"> Emp.Code </th>
                                    <th class="text-center"> Name </th>
                                    <th class="text-center" style="width: 13%;"> Company </th>
                                    <th class="text-center" style="width: 13%;"> Department </th>
                                    <th class="text-center" style="width: 9%;"> Job Title </th>
                                    <th class="text-center" style="width: 9%;"> Shift </th>
                                    <th class="text-center" style="width: 9%;"> Start Date </th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of signuplist| paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">
                                <tr (click)="openModal(item.emp_Id)" style="cursor: pointer;">     
                                    <td>
                                        {{item.emp_Code}}
                                    </td>                               
                                    <td>
                                        {{item.firstName}} {{item.middleName}} {{item.lastName}}
                                    </td>                                    
                                    <td>
                                        {{item.company_Name}}
                                    </td>
                                    <td>{{item.dept_Name}}</td>
                                    <td>{{item.desig_Name}}</td>
                                    <td>{{item.shift_Name}}</td>
                                    <td class="text-center">{{item.joiningDate | date : 'dd/MM/yyyy'}}</td>                                                                                   
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center">
                        <pagination-controls (pageChange)="getSignupList($event)" autoHide="true"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->

<div class="modal fade draggable-modal" id="signipnotificationRole" tabindex="-1" role="basic" aria-hidden="true">
	<div class="modal-dialog modal-lg" style="margin:5% auto !important">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" (click)="closeModal()" class="close" data-dismiss="modal" aria-hidden="true"></button>
				<h4 class="modal-title">Role Assign</h4>
			</div>
			<div class="modal-body">
				<app-roleassign></app-roleassign>
			</div>
		</div>
	</div>
</div>

<div class="modal fade draggable-modal" id="mailSend" tabindex="-1" role="basic" aria-hidden="true">
	<div class="modal-dialog modal-lg" style="margin:5% auto !important;overflow-y: auto;max-height: 700px;">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-hidden="true"></button>
				<h4 class="modal-title">Send Mail</h4>
			</div>
			<div class="modal-body">
				<app-mailsend></app-mailsend>
			</div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" (click)="closeModal()" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="sendMail()" class="btn blue">Send</button>
            </div>
		</div>
	</div>
</div>


