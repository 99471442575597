import { Component, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var $: any;


@Component({
  selector: 'app-comingSoon',
  templateUrl: './comingsoon.component.html'
})
export class ComingSoonComponent implements OnInit {

  constructor(
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit() {
    $("body").css("background-color","#7680bc");
    $("app-left-menu,app-header,app-footer").hide();
  }


}
