import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
declare var $: any;

@Component({
   selector: 'app-candidateviewsummary',
   templateUrl: './candidate-view-summary.component.html',
})
export class CandidateViewSummaryComponent implements OnInit {

   candidateId: any;
   candidateSummary: any;
   candidateAddress: any;
   candidateBasicInfo: any;
   person_equalityInfo?:any;
   candidateContact: any;
   candidatEmergency: any;
   candidateReference: any;
   candidateBank: any;
   candidateProbation: any;
   candidateContract: any;
   candidateRighttowork: any;
   candidateSalary: any
   candidatedocument: any
   viewSummeryPopup: boolean = false;
   fileUrl;
   private _Summaryid: any;
   constructor(private commonApiService: CommonApiService,
      private toastr: ToastrService,private sanitizer: DomSanitizer,
      private commonService: CommonService,
      private fb: FormBuilder
   ) {

   }

   // showTextbox: boolean = false;
   // textboxValue: string = ''
   // showTextbox1: boolean = false;
   // textboxValue1: string = ''
   // showTextbox2: boolean = false;
   // textboxValue2: string = ''
   // showTextbox3: boolean = false;
   // textboxValue3: string = ''
   // showTextbox4: boolean = false;
   // textboxValue4: string = ''

   @Input()

   public set Summaryid(v: any) {

      this._Summaryid = v;

      if (v > 0) {
         this.getSummary(v);
      }

   }

   ngOnInit() {


   }
   ngAfterViewInit() {
      $("[data-toggle=popover]").each(function (i, obj) {

         $(this).popover({
            html: true,
            content: function () {
               var id = $(this).attr('id')
               return $('#popover-content-' + id).html();
            }
         });

      });

      
   }

   getSummary(id) {
      this.candidateId = id;
      this.commonApiService.getById(Commonvariable.OnboardingDetail, id, "ViewSummary").subscribe((response) => {
         if (response != null) {
            this.candidateSummary = response["data"][0];
            this.candidateBank = this.candidateSummary["onboarding_bank"];
            this.candidateProbation = this.candidateSummary["onboarding_probation"];
            this.candidateContract = this.candidateSummary["onboarding_contract"];
            this.candidateRighttowork = this.candidateSummary["onboarding_righttowork"];
            this.candidateSalary = this.candidateSummary["onboarding_salary"];
            this.candidatedocument = this.candidateSummary["onboarding_document"]

            if (response["data"][0]["person_Id"] != null) {
               this.commonApiService.getById(Commonvariable.PersonService, response["data"][0]["person_Id"], "ViewSummary").subscribe((result) => {
                  this.candidateBasicInfo = result["data"][0];
                  this.candidateAddress = this.candidateBasicInfo["person_Address"];
                  this.candidateContact = this.candidateBasicInfo["person_Contact"];
                  this.candidatEmergency = this.candidateBasicInfo["person_Emergency"];
                  this.candidateReference = this.candidateBasicInfo["person_Reference"];
                  this.person_equalityInfo = this.candidateBasicInfo["person_equality"];
               })
            }
            this.viewSummeryPopup = true;
            // $('#viewSummary').modal({
            //    backdrop: 'static'
            // });
            $("#statusReview").val(this.candidateSummary.status)


         }
      })
   }

   download() {
      
  
      if (parseInt(this.candidateSummary.jD_Id) > 0) {
        //var fileName = String(this.jobTitleList.filter(x => x.jD_Id == this.empJobDetailform.controls.JD_Id.value)[0]["path"]).split(".")[1]
        //this.JDescription.download(fileName == undefined ? ".pdf" : fileName, parseInt(this.vacancyForm.controls.JD_Id.value));
        this.commonApiService.getimage(Commonvariable.JobDescription, parseInt(this.candidateSummary.jD_Id), "DownloadDocument").subscribe((resp: any) => {
  
          if (resp.type != "application/json") {
            const blobData = new Blob([resp], { type: resp.type });
            var createObjectURL = (window.URL.createObjectURL(blobData));
    
            if (resp.type == "application/pdf" || resp.type == "image/jpeg" || resp.type == "image/png") {
              this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
              $("#modalJobDescription").modal("show")
            } else if (resp.type == "application/vnd.ms-word") {
              const a = document.createElement('a');
              a.setAttribute('type', 'hidden');
              a.href = URL.createObjectURL(blobData);
              a.download = 'Job_Description.docx';
              a.click();
              a.remove();
            } else {
    
              const a = document.createElement('a');
              a.setAttribute('type', 'hidden');
              a.href = URL.createObjectURL(blobData);
              a.download = 'Job_Description';
              a.click();
              a.remove();
    
            }
          }else{
            this.commonService.toaster("warning", 'Document Not Available', 'Job Description')
          }
          
        });
      }
      else{
         this.commonService.toaster("warning", 'Not selected.', 'Job Description')
       }
    }
    
    modalJobDescriptionclose()
    {
      $("#modalJobDescription").modal("hide");
      $('#viewSummary').modal({
         backdrop: 'static'
       });
   
     
    }




}


