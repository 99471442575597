<!-- <link rel="stylesheet" type="text/css" href="assets/login_util.css"> 
<link rel="stylesheet" type="text/css" href="assets/login_main.css"> -->

<!-- <link rel="stylesheet" type="text/css" href="assets/vendor/bootstrap/css/bootstrap.min.css"> -->
<!-- <ngx-spinner size="medium" ></ngx-spinner> -->

<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form [formGroup]="loginForm" class="login100-form validate-form" id="loginForm" (ngSubmit)="userRequest()">
        <span class="login100-form-title p-b-43">
          <img src="assets/login_images/images/B&S-People_Approved-logo_2023.png" />
          <br />
          Login to continue
        </span>

        <div class="wrap-input100 validate-input wow animated slideInLeft"
          data-validate="Valid email is required: ex@abc.xyz">
          <input [class]="Login_Id" type="text" id="Login_Id" (change)="valueChange('Login_Id')"
            formControlName="Login_Id" autocomplete="off">
          <span class="focus-input100"></span>
          <span class="label-input100">UserName / Email</span>
        </div>

        <div class="wrap-input100 validate-input wow animated slideInRight" data-validate="Password is required">
          <input [class]="password" id="password" type="password" formControlName="password"
            (change)="valueChange('password')">
          <span class="focus-input100"></span>
          <span class="label-input100">Password</span>
        </div>

        <div class="flex-sb-m w-full p-t-3 p-b-32">
          <!-- <div class="contact100-form-checkbox wow animated slideInLeft">
            <input class="input-checkbox100" id="ckb1" type="checkbox" name="remember-me">
            <label class="label-checkbox100" for="ckb1">
              Remember me
            </label>
          </div> -->

          <div class="wow animated slideInRight">
            <a href="javascript:void(0);" (click)="changeScreen('loginForm','forgotPassForm')" id="forgetPassword"
              class="txt1">
              Forgot Password?
            </a>
          </div>
        </div>


        <div class="container-login100-form-btn wow animated slideInLeft">
          <button type="submit" class="login100-form-btn">Login</button>
        </div>
        <br>
        <div class="container-login100-form-btn wow animated slideInRight" style="float:left;">
          <a routerLink="/signup" class="login100-form-btn"
            style="float: right;text-align: center;color:#fff;background-color: gray;">Signup</a>
        </div>
        <div class="flex-sb-m w-full p-t-14">
          <div class="wow animated slideInRight">
            <p><b>Version : </b>{{appVersion}}</p>
          </div>
        </div>

        <!-- <div class="text-center p-t-46 p-b-20">
            <span class="txt2">
              or sign up using
            </span>
          </div>

          <div class="login100-form-social flex-c-m">
            <a href="javascript:void(0);" class="login100-form-social-item flex-c-m bg1 m-r-5">
              <i class="fa fa-facebook-f" aria-hidden="true"></i>
            </a>

            <a href="javascript:void(0);" class="login100-form-social-item flex-c-m bg2 m-r-5">
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
          </div> -->
        <div style="color: lightgray;bottom:200px;position:absolute;top: 940px;margin-left: 120px;"
          *ngIf="hostname == 'localhost' || hostname == '161.35.162.46'"><b>Testing Server:</b> 09/07/2020 08:00 AM(UK
          Time)</div>
      </form>

      <form class="login100-form validate-form" id="forgotPassForm">
        <span class="login100-form-title p-b-43">
          <img src="assets/login_images/images/LuxshTechnologiesLogo_2020.svg" />
          <br />
          Login to continue
        </span>

        <div class="wrap-input100 validate-input">
          <input [class]="username" type="text" (change)="valueChange('username')" name="username" id="username"
            autocomplete="off">
          <span class="focus-input100"></span>
          <span class="label-input100">UserName / Email</span>
        </div>



        <div class="flex-sb-m w-full p-t-3 p-b-32">
          <!-- <div class="contact100-form-checkbox">
              <input class="input-checkbox100" id="ckb1" type="checkbox" name="remember-me">
              <label class="label-checkbox100" for="ckb1">
                Remember me
              </label>
            </div> -->

          <div>
            <a href="javascript:void(0);" (click)="changeScreen('forgotPassForm','loginForm')" id="backToLogin"
              class="txt1">
              Back To Login
            </a>
          </div>

        </div>


        <div class="container-login100-form-btn">
          <button class="login100-form-btn" (click)="forgetPassword()">
            Submit
          </button>
        </div>
        <div class="flex-sb-m w-full p-t-14">
          <div class="wow animated slideInRight">
            <p><b>Version : </b>{{appVersion}}</p>
          </div>
        </div>
        <!-- 
        <div class="text-center p-t-46 p-b-20">
          <span class="txt2">
            or sign up using
          </span>
        </div>

        <div class="login100-form-social flex-c-m">
          <a href="javascript:void(0);" class="login100-form-social-item flex-c-m bg1 m-r-5">
            <i class="fa fa-facebook-f" aria-hidden="true"></i>
          </a>

          <a href="javascript:void(0);" class="login100-form-social-item flex-c-m bg2 m-r-5">
            <i class="fa fa-twitter" aria-hidden="true"></i>
          </a>
        </div> -->

      </form>



      <div class="login100-more" style="background-image: url('assets/login_images/images/bg-01.jpg');">
        <div class="leftsideText leftsideBox">
          <h1 class="loginboxTitle">Why choose LuxshTech HR admin over other HR Portals?</h1>
          <h6 class="loginboxsubTitle">The only admin dashboard template you would need for your all next backend and
            application projects. Go
            and Get it!</h6>
          <div class="row">
            <div class="col-md-4 colmd4">
              <img src="assets/login_images/images/icon1.png">
              <br />
              <br />
              <h5>Handcrafted Designs</h5>
              <p>
                Our Designs are truly awesome and unique, which brings freshness to your project.
              </p>
            </div>
            <div class="col-md-4 colmd4">
              <img src="assets/login_images/images/icon2.png">
              <br />
              <br />
              <h5>Seamless Coding</h5>
              <p>
                We have crafted our templates very carefully and our code is W3C validated.
              </p>
            </div>
            <div class="col-md-4 colmd4">
              <img src="assets/login_images/images/icon3.png">
              <br />
              <br />
              <h5>Dedicated Support</h5>
              <p>
                We offer amazingly fast and professional support to our customers.
              </p>
            </div>
          </div>
          <hr />

          <h1 class="loginboxTitle">Features which will Amaze you!</h1>
          <h6 class="loginboxsubTitle">We know how important is for you to save time and create something stunning for
            your client, its easily
            possible with Ample admin </h6>


          <div class="row">
            <div class="col-md-4 colmd4">
              <img src="assets/login_images/images/f1.png">
              <br />
              <br />
              <h5>Handcrafted Designs</h5>
              <p>
                We have included 6 pre-defined color schemes with Ample admin.
              </p>
            </div>
            <div class="col-md-4 colmd4">
              <img src="assets/login_images/images/f2.png">
              <br />
              <br />
              <h5>Colored / Dark / Light Sidebar</h5>
              <p>
                Options available to select suitable sidebar for your project
              </p>
            </div>
            <div class="col-md-4 colmd4">
              <img src="assets/login_images/images/f4.png">
              <br />
              <br />
              <h5>300+ UI Components</h5>
              <p>
                You will get more than 300 unique UI Components
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div style="display: none;">
  <app-left-menu></app-left-menu>
</div> -->