<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>Change Password
                </h1>
            </div>
        </div>
        <ul class="page-breadcrumb breadcrumb mb20">
            <li>
                <!-- <a data-toggle="modal" href="#draggable" (click)="openModel(0)"
                    class="btn btn-transparent blue  btn-sm">Add Designation</a> -->
            </li>
        </ul>
        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <br />
                    <div class="row">
                        <div class="form-group">
                            <div class="col-md-3"></div>
                            <label class="control-label col-md-2">Old Password<span class="required"> * </span></label>
                            <div class="col-md-3">
                                <input type="password" class="form-control" id="oldpassword">
                            </div>

                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="form-group">
                            <div class="col-md-3"></div>
                            <label class="control-label col-md-2">New Password<span class="required"> * </span></label>
                            <div class="col-md-3">
                                <input type="password" class="form-control" id="newpassword" data-toggle="tooltip"
                                    data-placement="right" data-container="body" title=""
                                    data-original-title="Must be at least 8 characters long.">
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="form-group">
                            <div class="col-md-3"></div>
                            <label class="control-label col-md-2">Confirm Password<span class="required"> *
                                </span></label>
                            <div class="col-md-3">
                                <input type="password" class="form-control" id="confirmPassword" data-toggle="tooltip"
                                    data-placement="right" data-container="body" title=""
                                    data-original-title="Confirm new Password. 'Confirm Password' should match the input in the 'New password' field.">
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-offset-5 col-md-8">
                            <button type="button" (click)="changePassword()" class="btn btn-outline blue">Change
                                Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="modal fade draggable-modal" id="draggable" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">{{title}} Designation</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="designationform">
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Company<span class="required"> * </span></label>
                                    <select id="company_Id" class="form-control" formControlName="company_Id">
                                        <option value="">Select Your Company</option>
                                        <option *ngFor="let company of companylist;" value={{company.company_Id}}>
                                            {{company.company_Name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Department<span class="required"> *
                                        </span></label>
                                    <select class="form-control" id="dept_Id" formControlName="dept_Id">
                                        <option value="">Select Your Department</option>
                                        <option *ngFor="let item of departmentlist;" value={{item.dept_Id}}>
                                            {{item.dept_Name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Zone<span class="required"> * </span></label>
                                    <select class="form-control" id="zone_Id" formControlName="zone_Id">
                                        <option value="">Select Your Zone</option>
                                        <option *ngFor="let item of zonelist;" value={{item.zone_Id}}>
                                            {{item.zone_Name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Designation Code<span class="required"> *</span></label>
                                    <input type="text" class="form-control" id="desig_Code" formControlName="desig_Code">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Designation Name<span class="required"> *
                                        </span></label>
                                    <input type="text" class="form-control" id="desig_Name" formControlName="desig_Name">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Report To<span class="required"> *
                                        </span></label>
                                    <input type="text" class="form-control" id="report_To" formControlName="report_To">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Additional Report To</label>
                                    <input type="text" class="form-control" formControlName="additionalReport_To">
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label class="control-label">Level<span class="required"> *</span></label>
                                    <input type="number" class="form-control" id="level" formControlName="level" min="0">
                                </div>
                            </div>
                            <div class="col-md-1 ">
                                <div class="form-group">
                                    <label class="control-label">Active</label>
                                    <div class="md-checkbox-inline">
                                        <div class="md-checkbox">
                                            <input type="checkbox" id="checkbox1_3" class="md-check"
                                                formControlName="isActive">
                                            <label for="checkbox1_3">
                                                <span></span>
                                                <span class="check"></span>
                                                <span class="box"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" (click)="designationUpsert()" class="btn blue">Save</button>
            </div>
        </div>
    </div>
</div> -->