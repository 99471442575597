<form [formGroup]="candidateJobDetailform">
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Company
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <select id="company_Id" class="form-control" formControlName="company_Id" (change)="companyWiseGetData(true)">
                    <option value="">Select Company</option>
                    <option *ngFor="let company of companyList;" value={{company.company_Id}}>
                        {{company.company_Name}}</option>
                </select>
            </div>
            <label class="control-label col-md-2">Site
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <select id="Site_Id" class="form-control" formControlName="Site_Id" (change)="getRafList($event.target.value)">
                    <option value="">Select Site</option>
                    <option *ngFor="let item of siteList;" value={{item.site_Id}}>
                        {{item.site_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2">Department
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <select id="Dept_Id" class="form-control" formControlName="Dept_Id" (change)="getJobTitle()">
                    <option value="">Select Department</option>
                    <option *ngFor="let item of departmentList;" value={{item.dept_Id}}>
                        {{item.dept_Name}}</option>
                </select>
            </div>
            <label class="control-label col-md-2">Zone
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <select id="Zone_Id" class="form-control" formControlName="Zone_Id">
                    <option value="null">Select Zone</option>
                    <option *ngFor="let item of zoneList;" value={{item.zone_Id}}>
                        {{item.zone_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2"> Job Title
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <select id="Desig_Id" class="form-control" formControlName="Desig_Id" (change)="getReportingList();getJobDescriptionList($event.target.value)">
                    <option value="">Select Job Title</option>
                    <option *ngFor="let item of designationList;" value={{item.desig_Id}}>
                        {{item.desig_Name}}</option>
                </select>
            </div>
            <label class="control-label col-md-2">Job Description
            </label>
            <div class="col-md-3">
                <select id="JD_Id" class="form-control" formControlName="JD_Id">
                    <option value="null">Select Job Description</option>
                    <option *ngFor="let item of JDList;" value={{item.jD_Id}}>
                        {{item.jD_Name}}</option>
                </select>
            </div>
            <div class="col-md-1">
                <a class="btn btn-sm dark btn-outline" data-toggle="tooltip" (click)="download()"
                    title="Download Job Description"><i class="fa fa-download fa-1x"></i></a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <!-- <label class="control-label col-md-2">Shift Time
                <span class="required" aria-required="true"> * </span>
            </label>
            <div class="col-md-3">
                <select id="Shift_Id" class="form-control" formControlName="Shift_Id">
                    <option value="">Select Shift Time</option>
                    <option *ngFor="let item of shiftList;" value={{item.shift_Id}}>
                        {{item.shift_Name}} [{{item.shift_Start}} - {{item.shift_End}}]</option>
                </select>
            </div> -->
            <!-- <label class="control-label col-md-2" *ngIf="editMode">NI Number
            </label>
            <div class="col-md-3" *ngIf="editMode">
                <input type="text" id="NiNo" placeholder="" class="form-control" formControlName="NiNo" />
            </div> -->
            <!-- <label class="control-label col-md-2">Night Shift</label>
            <div class="md-checkbox-inline">
                <div class="md-checkbox ">
                    <input type="checkbox" id="nightShift" class="md-check" formControlName="nightShift">
                    <label for="nightShift">
                        <span></span>
                        <span class="check"></span>
                        <span class="box"></span> </label>
                </div>
            </div> -->

            

            <label class="control-label col-md-2">Planned Start Date
                <span class="required" aria-required="true"> * </span>
            </label>
            <div class="col-md-3">
                <div class="input-group" id="flatpickropenJoiningD" data-wrap="true">
                    <input type="text" class="form-control readonlyClass" id="JoiningDate" data-input />
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
    <div class="form-group">
        <label class="control-label col-md-2">Shift Start<span class="required"> *
        </span></label>
        <div class="col-md-2">
        <div class="input-group" id="flatpickropenshiftstatetime" data-wrap="true">
            <input type="time" id="shift_Start" class="form-control readonlyClass"
                formControlName="shift_Start"  data-input>
            <span class="input-group-btn" data-toggle>
                <button class="btn default" type="button">
                    <i class="fa fa-clock-o"></i>
                </button>
            </span>
        </div>
        </div>

        <label class="control-label col-md-2">Shift End<span class="required"> *
        </span></label>
        <div class="col-md-2">
        <div class="input-group" id="flatpickropenshiftendtime" data-wrap="true">
            <input type="time" id="shift_End" class="form-control readonlyClass"
                formControlName="shift_End" data-input>
            <span class="input-group-btn" data-toggle>
                <button class="btn default" type="button">
                    <i class="fa fa-clock-o"></i>
                </button>
            </span>
        </div>
        </div>
        <div class="col-md-3">
            <label class="control-label col-md-4">Shift Variable</label>
            <div class="md-checkbox-inline">
                <div class="md-checkbox ">
                    <input type="checkbox" id="shift_Variable" class="md-check" formControlName="shift_Variable">
                    <label for="shift_Variable">
                        <span></span>
                        <span class="check"></span>
                        <span class="box"></span> </label>
                </div>
            </div>
        </div>
    </div>
</div>
    <!-- <div class="row" *ngIf="editMode">
        <div class="form-group">
            <label class="control-label col-md-2">Total Experience

            </label>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="TotalExperience" id="TotalExperience" />
            </div>
            <label class="control-label col-md-2">Reference From

            </label>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="ReferenceFrom" id="ReferenceFrom" />
            </div>
        </div>
    </div> -->
    <div class="row">
        <div class="form-group">
            <label class="control-label col-md-2" *ngIf="!editMode">Contract Type
            </label>
            <div class="col-md-3" *ngIf="!editMode">
                <select class="form-control" ID="contract_Code">
                    <option value="">Select Contract</option>
                    <option *ngFor="let item of contractlist;" value={{item.contract_Id}}>
                        {{item.contract_Name}} / {{item.contract_Type}}</option>
                </select>
            </div>

            <label class="control-label col-md-2">Holiday approver  <span class="required"> * </span></label>
            <div class="col-md-3">
                <select id="Reporting_Id" class="form-control" formControlName="Reporting_Id">
                    <option value="">--- Select ---</option>
                    <option *ngFor="let emp of reportingEmpList;" value={{emp.emp_Id}}>
                        {{emp.firstName}} {{emp.lastName}}</option>
                </select>
            </div>

        </div>
        <!-- </div>
    <div class="row" > -->
        <div class="form-group">
            <label class="control-label col-md-2">Email
                <span class="required"> * </span>
            </label>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="EMail" id="EMail" />
            </div>

            <label class="control-label col-md-2" *ngIf="!editMode">Probation Period</label>
            <div class="col-md-3" *ngIf="!editMode">
                <select class="form-control" ID="ProbationPeriod" formControlName="ProbationPeriod">
                    <option value="">Select Probation Period</option>
                    <option *ngFor="let item of probationList;"
                        value="{{item.prob_Duration}} {{item.prob_DurationUnit}}">
                        {{item.prob_Duration}} {{item.prob_DurationUnit}}</option>
                </select>
            </div>


        </div>
    </div>

    <div class="row" *ngIf="!editMode">
        <div class="form-group">

            <label class="control-label col-md-2">Mobile
            </label>
            <div class="col-md-3">
                <div class="input-group mobilenowithcountryode">
                    <div class="input-group-btn">
                        <ng-select2 [data]="countryList" id="country_Id" formControlName="country_Id" [placeholder]="'--- Select ---'">
                        </ng-select2>
                    </div>

                    <input type="text" class="form-control" formControlName="Mobile" id="JobMobile" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.key === "Backspace" ||  event.charCode == 40 
                               || event.charCode == 32 || event.charCode == 41 || event.charCode == 43 || event.charCode == 45 ' maxlength="20" />
                </div>
            </div>

            <label class="control-label col-md-2">Salary</label>
            <div class="col-md-3">
                <div class="input-group">
                    <input type="number" min="0" value="0" step="0.01" id="Salary" class="form-control" autocomplete="off" maxlength="20"/>
                    <span class="input-group-addon inputaddon">{{currency_Code}}</span>
                    <select id="onboardingSalaryType" class="form-control" style="float: right;background-color: #eef1f5;">
                        <option value="Annually">Annually</option>
                        <option value="Hourly">Hourly</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group">

            <label class="control-label col-md-2">RAF</label>
            <div class="col-md-3">
                <select class="form-control" id="Vacancy_Id" formControlName="Vacancy_Id" *ngIf="!editMode">
                    <option value="">Select RAF</option>
                    <option *ngFor="let item of rafList;" value={{item.id}}>
                        {{item.desig_Name}}</option>
                </select>

                <input type="text" class="form-control readonlyClass" id="vacancy_Name" readonly disabled *ngIf="editMode" />

            </div>


            <label class="control-label col-md-2">Employee Number <span class="required"> * </span> </label>
            <div class="col-md-3">
                <input type="text" class="form-control readonlyClass" formControlName="Emp_Code" id="Emp_Code" autocomplete="off" (change)="checkEmpCode()" />
            </div>

        </div>

    </div>

</form>
<div class="modal" id="modalJobDescription" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Job Description</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <iframe [src]='fileUrl' width="100%" height="600px"></iframe>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
            </div>
        </div>
    </div>
</div>