import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
import { groupBy, mergeMap } from 'rxjs/operators';
import { scheduled } from 'rxjs';
declare var $: any;
declare var moment: any;

@Component({
   selector: 'app-scheduleInterviewforcandidate',
   templateUrl: './scheduleInterviewforcandidate.component.html',
})
export class ScheduleInterviewCandidateComponent implements OnInit {
   title = "Interview Schedule"
   scheduleInterviewform: FormGroup;
   items: FormArray;
   empList: any;
   interviewStageList: any[] = [];

   constructor(
      private commonApiService: CommonApiService,
      private commonService: CommonService,
      private router: Router,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {

      this.scheduleInterviewform = this.fb.group({
         scheduleInterview: this.fb.array([this.createItem()]),
      });
   this.getInterviewStageList()
   }


   
   getInterviewStageList() {

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "name"
      }

      this.commonApiService.GetByPaginated(Commonvariable.InterviewStages, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
             this.interviewStageList = response["data"].map(x => ({
               id: x.name,
               text: x.name
             }))
         }
      })
   }

   createItem(data = null) {
      return this.fb.group({
         Id: data != null ? data.id : 0,
         name: data != null ? data.name : "",
         dateTime: data != null ? this.commonService.refilldateTimeFormatChange(data.date) : moment(new Date()).format('DD-MM-YYYY HH:mm'),
         status: data != null ? data.status : 'Schedule',
         note: data != null ? data.notes : '',
         interviewDetail: data != null ? this.fb.array([]) : this.fb.array([this.createInterviewDetail()]),
         disabled: data != null ? true : false
      });
   }

   createInterviewDetail(data = null) {
      return this.fb.group({
         Id: data != null ? data.id : 0,
         interviewer: data != null ? data.emp_Id : '',
         status: data != null ? data.status : '',
         notes: data != null ? data.notes : ''
      });
   }

   addscheduleInterview(data = null, mode = 'add') {

      var validate = mode == 'add' ? this.validation() : true

      if (validate) {
         this.items = this.scheduleInterviewform.get('scheduleInterview') as FormArray;
         this.items.push(this.createItem(data));

         setTimeout(() => {
            this.commonService.flatpickrWithTime("candidate_interview_date_time", this.items.length - 1)
         }, 5);

      }
   }

   removecheduleInterview(index) {
      this.items = this.scheduleInterviewform.get('scheduleInterview') as FormArray;
      this.items.removeAt(index);
   }

   interviewDetail(Index: number): FormArray {
      return (this.scheduleInterviewform.get('scheduleInterview') as FormArray).at(Index).get("interviewDetail") as FormArray;
   }

   addInterviewDetail(index, data = null, mode = 'add') {
      var validate = mode == 'add' ? this.validation() : true
      if (validate) {
         this.items = (this.scheduleInterviewform.get('scheduleInterview') as FormArray).at(index).get("interviewDetail") as FormArray;
         this.items.push(this.createInterviewDetail(data));
      }

   }

   removeInterviewDetail(index, detailIndex) {
      this.items = (this.scheduleInterviewform.get('scheduleInterview') as FormArray).at(index).get("interviewDetail") as FormArray;
      this.items.removeAt(detailIndex);
   }


   getEmpList(id) {
      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "==",
               "ConditionWith": "&&"
            },
            {
               "FieldName": "company_Id",
               "FieldValue": id,
               "Parameter": "=="
            }
         ]
      }
      this.commonApiService.GetByPaginated(Commonvariable.EmployeeDetail, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            this.empList = response["data"];
            this.commonService.flatpickrWithTime("candidate_interview_date_time0", 0)
         }
      })
   }



   upsert(id, vacancy_Id, status) {

      var requestData = {
         "Candidate_Id": id,
         "Vacancy_Id": vacancy_Id,
         "status": status,
         "UpdatedBy": Commonvariable.User_Id,
         "candidate_Interviewer": []
      }

      let formData = this.scheduleInterviewform.value;
      if (this.validation()) {
         formData.scheduleInterview.forEach(x => {
            x.interviewDetail.forEach(y => {
               var prepareJson = {
                  "Id": y.Id,
                  "Candidate_Id": id,
                  "Vacancy_Id": vacancy_Id,
                  "Emp_Id": y.interviewer,
                  "Name": x.name,
                  "Date": this.commonService.dateTimeFormatChange(x.dateTime),
                  "status": x.status,
                  "Notes": x.note,
                  "isActive": 1,
                  "AddedBy": Commonvariable.User_Id,
                  "UpdatedBy": Commonvariable.User_Id
               }
               requestData.candidate_Interviewer.push(prepareJson);
            });
         });
      }

      return requestData.candidate_Interviewer.length > 0 ? requestData : null;
   }



   async fillInterviewer(data) {
      if (data != null && data.length > 0) {
         this.items = this.scheduleInterviewform.get('scheduleInterview') as FormArray;
         this.items.clear();
         for (let index = 0; index < data.length; index++) {
            this.addscheduleInterview(data[index][0], 'edit')
            data[index].forEach(interviewer => {
               this.addInterviewDetail(index, interviewer, 'edit')
            });

            if (data.length == index + 1) {
               this.addscheduleInterview(null, 'add')
            }
         }
      }
   }


   validation() {

      let formData = this.scheduleInterviewform.value;


      var scheduleInterview = formData.scheduleInterview;


      for (let indexScheduleInterview = 0; indexScheduleInterview < scheduleInterview.length; indexScheduleInterview++) {


         if (!scheduleInterview[indexScheduleInterview].disabled) {

            if (scheduleInterview[indexScheduleInterview].name == null || String(scheduleInterview[indexScheduleInterview].name).trim() == "") {

               this.commonService.toaster("info", "Interview Round is required !", this.title)
               $("#name" + indexScheduleInterview +" select").focus();
               return false;
            }

            if ($("#candidate_interview_date_times" + indexScheduleInterview).val() == null || $("#candidate_interview_date_times" + indexScheduleInterview).val() == "") {
               this.commonService.toaster("info", "Datetime is required !", this.title)
               $("#candidate_interview_date_times" + indexScheduleInterview).focus();
               return false;
            }


            var interviewDetail = scheduleInterview[indexScheduleInterview]["interviewDetail"];

            for (let indexinterviewDetail = 0; indexinterviewDetail < interviewDetail.length; indexinterviewDetail++) {
               if (interviewDetail[indexinterviewDetail].interviewer == null || String(interviewDetail[indexinterviewDetail].interviewer).trim() == "") {

                  this.commonService.toaster("info", "Interviewer is required !", this.title)
                  $("#interviewer" + indexScheduleInterview + indexinterviewDetail).focus();
                  return false;

               }
            }
         }
      }
      return true;
   }

}


