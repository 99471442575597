<!-- <div class="progress">
    <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="40" aria-valuemin="0"
        aria-valuemax="100" style="width:40%">
        40%
    </div>
</div> -->
<div id="accordion1" class="panel-group">

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#upaccordion1_1"> 1. Job Details </a>
            </h4>
        </div>
        <div id="upaccordion1_1" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="row" *ngIf="employeeSummary != null">
                    <div class="form-group">
                        <label class="control-label col-md-2">Company</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeSummary.company_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Site</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.site_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Department</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.dept_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Zone</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.zone_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Job Title</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.desig_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Job Description</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.jD_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Employee Number</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.emp_Code}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Joining Date</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.joiningDate | date:'dd/MM/yyyy'}}" readonly />
                        </div>

                        
                        <label class="control-label col-md-2">Shift Time</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.shift_Name}} {{this.employeeSummary.shift_Time}}" readonly />
                        </div>


                        <label class="control-label col-md-2">NI Number</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" value="{{this.employeeSummary.niNo}}"
                                readonly />
                        </div>

                       
                        <label class="control-label col-md-2">Work Email</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.work_Email}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Work Phone</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                value="{{this.employeeSummary.work_Phone}}" readonly />
                        </div>


                        <label class="control-label col-md-2">Reporting</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeSummary.reporting_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2"> Additional Reporting To</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeSummary.additional_Reporting_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Work Extension</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeSummary.work_Extension}}" readonly />
                        </div>


                        <label class="control-label col-md-2" >RAF</label>
                        <div class="col-md-4 form-group" >
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeSummary.vacancy_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2" *ngIf="employeeSummary != null">Deputy</label>
                        <div class="col-md-4 form-group" *ngIf="employeeSummary != null">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeSummary.deputy_Name}}" readonly />
                        </div>


                        <label class="control-label col-md-2">RAF Approval</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass"
                                *ngIf="employeeSummary.raF_Approval != 1" value="No" readonly />

                            <input type="text" class="form-control readonlyClass"
                                *ngIf="employeeSummary.raF_Approval == 1" value="Yes" readonly />
                        </div>


                        <label class="control-label col-md-2">Tupe</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" *ngIf="employeeSummary.tupe != 1"
                                value="No" readonly />
                            <input type="text" class="form-control readonlyClass" *ngIf="employeeSummary.tupe == 1"
                                value="Yes" readonly />
                        </div>

                
                        <label class="control-label col-md-2">HOD</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" *ngIf="employeeSummary.isHOD != 1"
                                value="No" readonly />

                            <input type="text" class="form-control readonlyClass" *ngIf="employeeSummary.isHOD == 1"
                                value="Yes" readonly />
                        </div>


            
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#upaccordion1_2"> 2. Contract </a>
            </h4>
        </div>
        <div id="upaccordion1_2" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center"> Contract Type</th>                                
                                <th class="text-center"> Contract Days</th>
                                <th class="text-center"> Contract Start</th>                                
                                <th class="text-center"> Contract End</th>

                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeContract">
                            <tr>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.emp_Contract_Name}}">
                                        {{item.emp_Contract_Name}}
                                    </div>
                                </td>     
                                <td>{{item.emp_Contract_Days}}</td>                           
                                <td class="text-center">{{item.emp_Contract_Start | date :'dd/MM/yyyy'}}</td>                                
                                <td class="text-center">{{item.emp_Contract_End | date :'dd/MM/yyyy'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#upaccordion1_3"> 3. Probation </a>
            </h4>
        </div>
        <div id="upaccordion1_3" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Probation Period</th>
                                <th class="text-center">Probation End Date</th>                                
                                <th class="text-center" class="text-center">Next Review Date</th>
                                <th class="text-center">status</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeProbation">
                            <tr>                                
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.probationPeriod}}">
                                        {{item.probationPeriod}}
                                    </div>
                                </td>
                                <td class="text-center">{{item.probationEndDate |date:'dd/MM/yyyy'}}</td>
                                <td class="text-center">{{item.nextReviewDate | date :'dd/MM/yyyy'}}</td>
                                <td>{{item.status}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#upaccordion1_4"> 4. Salary </a>
            </h4>
        </div>
        <div id="upaccordion1_4" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Salary</th>
                                <th class="text-center">Salary Type</th>
                                <!-- <th class="text-center">Apprisal From</th>
                                <th class="text-center">Apprisal To</th> -->
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeSalary">
                            <tr>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.salary}}">{{item.salary}}
                                    </div>
                                </td>
                                <!-- <td class="text-center">{{item.apprisalFrom | date :'dd/MM/yyyy'}}</td>
                                <td class="text-center">{{item.apprisalTo | date :'dd/MM/yyyy'}}</td> -->
                                <td class="text-center">{{item.salaryType}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#upaccordion1_5"> 5. Personal Details </a>

            </h4>
        </div>
        <div id="upaccordion1_5" class="panel-collapse collapse in">
            <div class="panel-body">


                <div class="row" *ngIf="employeeBasicInfo != null">
                    <div class="form-group">
                        <label class="control-label col-md-2">Title</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.title}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Gender</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.gender_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">First Name</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.firstName}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Middle Name</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.middleName}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Last Name</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.lastName}}" readonly />
                        </div>

                    
                        <label class="control-label col-md-2">Date Of Birth</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.dob | date:'dd/MM/yyyy'}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Ethnicity</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.ethnicity_Name}}" readonly />
                        </div>

                        <label class="control-label col-md-2">Language</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.languages_Name}}" readonly />
                        </div>

                        <!-- <label class="control-label col-md-2">Nationality</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.nationality_Name}}" readonly />
                        </div> -->

                        <label class="control-label col-md-2">Marital Status</label>
                        <div class="col-md-4 form-group">
                            <input type="text" class="form-control readonlyClass" autocomplete="off"
                                value="{{this.employeeBasicInfo.marital_Status}}" readonly />
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#upaccordion1_6"> 6. Address Details </a>
            </h4>
        </div>
        <div id="upaccordion1_6" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <!-- <th class="text-center">Address Type</th> -->
                                <th class="text-center">Address</th>
                                <th  class="text-center">City</th>
                                <th class="text-center">County</th>
                                <th class="text-center">Country</th>
                                <th class="text-center">Post Code </th>
                                <th class="text-center">Default</th>
                                <th class="text-center">Active</th>
                                
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeAddress">
                            <tr>
                                <!-- <td>{{item.address_Type}}</td> -->
                                <td>{{item.address1}}, {{item.address2}}</td>
                                <td>{{item.city}}</td>
                                <td>{{item.state}}</td>
                                <td>{{item.country_Name}}</td>
                                <td>{{item.postCode}}</td>
                                <td>
                                    <div>
                                        <div class="circle greenColor" *ngIf="item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Default">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Indefault">
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="text-center">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive">
                                        </div>
                                    </div>
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#upaccordion1_7"> 7. Personal Contact Details </a>
            </h4>
        </div>
        <div id="upaccordion1_7" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Contact Type</th>
                                <th class="text-center">Contact Detailst</th>
                                <th class="text-center" style="width: 2%;">Default</th>
                                <th class="text-center" style="width: 2%;">Active</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeContact">
                            <tr>
                                <td>{{item.contact_Type}}</td>
                                <td>{{item.contact_Value}}</td>
                                <td>
                                    <div>
                                        <div class="circle greenColor" *ngIf="item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Default">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Indefault">
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="text-center">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive">
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#upaccordion1_8"> 8. Emergency Contact </a>
            </h4>
        </div>
        <div id="upaccordion1_8" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Emergency Contact Name</th>
                                <th class="text-center">Emergency Contact Number</th>
                                <th class="text-center">Relationship</th>
                                <th class="text-center" style="width: 2%;">Default</th>
                                <th class="text-center" style="width: 2%;">Active</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeEmergency">
                            <tr>
                                <td>{{item.contactName}}</td>
                                <td>{{item.contactNo}}</td>
                                <td>{{item.relationship_Name}}</td>
                                <td>
                                    <div class="text-center">
                                        <div class="circle greenColor" *ngIf="item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Default">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isDefault" data-toggle="tooltip"
                                            data-placement="top" title="Indefault">
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="text-center">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active">
                                        </div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive">
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#upaccordion1_9"> 9. Reference </a>
            </h4>
        </div>
        <div id="upaccordion1_9" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Reference Name</th>
                                <th class="text-center">Reference Email Address</th>
                                <th class="text-center">Reference Contact Number</th>
                                <th class="text-center">Reference Relationship</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeReference">
                            <tr>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.ref_Name}}">{{item.ref_Name}}
                                    </div>
                                </td>
                                <td>{{item.ref_Email}}</td>
                                <td>{{item.ref_ContactNo}}</td>
                                <td>{{item.relationship_Name}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#upaccordion1_10"> 10. Bank Details </a>
            </h4>
        </div>
        <div id="upaccordion1_10" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Bank Name</th>
                                <th class="text-center">Account Holder</th>
                                <!-- <th class="text-center">Account Type</th> -->
                                <th class="text-center">Account No.</th>
                                <th class="text-center">Sort Code</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeBank">
                            <tr>
                                <td>{{item.bank_Name}}</td>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.account_Holder}}">
                                        {{item.account_Holder}}
                                    </div>
                                </td>
                                <!-- <td>{{item.account_Type}}</td> -->
                                <td>{{item.account_No}}</td>
                                <td>{{item.bank_Code}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accordion1"
                    href="#upaccordion1_11"> 11. Right To Work </a>
            </h4>
        </div>
        <div id="upaccordion1_11" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Date of Issue</th>
                                <th class="text-center">Authority</th>
                                <th class="text-center">Country of Issue</th>
                                <th class="text-center">Date of Expiry</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of employeeRighttowork">
                            <tr>
                                <td>{{item.issueDate | date :'dd/MM/yyyy'}}</td>
                                <td>
                                    <div class="col-md-1 col-xs-12">
                                        <div class="circle greenColor" *ngIf="item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Active"></div>
                                        <div class="circle redColor" *ngIf="!item.isActive" data-toggle="tooltip"
                                            data-placement="top" title="Inactive"></div>
                                    </div>
                                    <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                        data-placement="bottom" title="{{item.issueAuthority}}">
                                        {{item.issueAuthority}}
                                    </div>
                                </td>
                                <td>{{item.issueCountry}}</td>
                                <td class="text-center">{{item.expiryDate | date :'dd/MM/yyyy'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>


    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                    data-parent="#accordion1" href="#upaccordion1_12"> 12. Additional Document </a>                                    
            </h4>
        </div>
        <div id="upaccordion1_12" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Document Name</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of empDocuments">
                            <tr>
                                <td>
                                    {{item.emp_Doc_Name}} 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>




    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
                <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse"
                    data-parent="#accordion1" href="#upaccordion1_13"> 13. Leave </a>                                    
            </h4>
        </div>
        <div id="upaccordion1_13" class="panel-collapse collapse in">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-sm  table-striped table-bordered table-hover">
                        <thead class="flip-content">
                            <tr>
                                <th class="text-center">Leave Type</th>
                                <th class="text-center">Total Leave</th>
                                <th class="text-center">Taken Leave</th>
                                <th class="text-center">Balance Leave</th>
                                <!-- <th class="text-center" style="width: 5%"> Actions </th> -->
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of empLeaveBalance">
                            <tr>

                                <td class="text-center">
                                    {{item.leave_Type}} ({{item.leave_Type_Code}})
                                </td>
                                <td class="text-center">
                                    {{item.total_leave}}
                                </td>
                                <td class="text-center">
                                    {{item.taken_leave}}
                                </td>
                                <td class="text-center">
                                    {{item.balance_leave}}
                                </td>
                                <!-- <td class="text-right">
                                    <a (click)="downloadDocument(item.emp_Doc_Id,item.emp_Doc_Path)"><i
                                            class="fa fa-download purple iconcss iconFontsize"
                                            data-toggle="tooltip" data-placement="top"
                                            title="Download Document"></i></a>&nbsp;
                                    <a data-toggle="modal" href="#emp-document"
                                        (click)="editempInfo('EmpDocument',item.emp_Doc_Id)"><i
                                            class="fa fa-edit purple iconcss iconFontsize"
                                            data-toggle="tooltip" data-placement="top"
                                            title="Edit Document"></i></a>&nbsp;
                                    <a (click)="Delete(12,item.emp_Doc_Id)">
                                        <i class="fa fa-trash-o red-color iconcss iconFontsize"
                                            data-toggle="tooltip" data-placement="top"
                                            title="Delete Document"></i></a>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>