import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-probation-form',
  templateUrl: './probation-form.component.html'
})
export class ProbationFormComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  probationform: FormGroup;
  probationlist: any;
  filterList: any;
  companylist: Array<Select2OptionData>;
  listcall: boolean = true;
  title: string;
  p: number = 1;
  totalRecord: 0; 
  @Input() pId?: any;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshPage: EventEmitter<number> = new EventEmitter();


  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private fb: FormBuilder,
    private toastr : ToastrService) { }


    options = {
      componentRestrictions: {
        country: ["UK", "IND"]
      }
    }
  ngOnInit() {

    document.body.className = "modal-open";

    this.title = "Add";
    this.probationform = this.fb.group({
      prob_Id: ['0'],
      company_Id: ['', Validators.required],
      company_Name:[''],
      prob_Code: [''],
      prob_Name: ['', Validators.required],
      prob_Description: [''],
      prob_DurationUnit: ['', Validators.required],
      prob_Duration: ['1', Validators.required],
      notes: [''],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });
    if (this.pId > 0) {
      this.openModel(this.pId);
    }
    this.getProbationList();
    this.getCompanyList();

  }

  getCompanyList() {
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'company_Name'
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }
  getProbationList(PageIndex = 1) {
    this.p = PageIndex;
    this.totalRecord = 0;
    this.probationlist = []
    this.commonApiService.getListWithFilter(Commonvariable.Probation, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.probationlist = result["data"]["list"];
        this.filterList = result["data"]["list"];

        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })
  }
  

  setFormData(event) {
    this.probationform.controls.company_Id.setValue(event)
  }


  ProbationUpsert() {
    var Form = this.probationform;
    
  
    var probDurationUnit = $("#prob_DurationUnit").val();
    Form.controls.prob_DurationUnit.setValue(probDurationUnit);
    if (this.validation()) {
      Form.get("isActive").setValue(Form.get("isActive").value ? 1 : 0);
      Form.get("prob_Name").setValue(Form.get("prob_Name").value);
      Form.get("updatedBy").setValue(Commonvariable.User_Id);
      
      this.commonApiService.UpsertWithMultiCompany(Commonvariable.Probation, Form.value, Form.controls.prob_Id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Probation');
          }
          else {
            // $("#draggable").modal('hide');
            // this.getProbationList();
            this.closeModal();
            this.refreshPage.emit(1);
            this.toastr.success(Form.controls.prob_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Probation');
          }
        }
      })
    }

  }

 
  
  openModel(Id) {
    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getById(Commonvariable.Probation, Id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var Form = this.probationform;
          this.commonService.setEditData(Form, editData);
          // this.commonService.select2SingleDropDown("companySelectListClass",editData[0].company_Id);
          // this.commonService.select2SingleDropDown("durationUnitSelectListClass",editData[0].prob_DurationUnit);
          // $("#prob_Code").prop("disabled", "disabled");
          // $("#company_Id").val(editData[0]['company_Name']);          

          this.probationform.patchValue({
            company_Id : editData[0]['company_Id']
          })
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Probation'); })
    }
    else {
      // $("#prob_Code").removeAttr("disabled");
      $("#prob_DurationUnit").val("");
      this.listcall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });


  }



  validation() {
    var Form = this.probationform;
    if (!Form.valid) {
      for (const key of Object.keys(Form.controls)) {
        if (Form.controls[key].invalid) {
          let fieldName = "";
          if (key == "company_Id") {
            fieldName = "Company"
            $("#company_Id select").focus();
          }
          // else if (key == "prob_Code") {
          //   fieldName = "Probation Code"
          // }
          else if (key == "prob_Name") {
            fieldName = "Probation"
          }
          else if (key == "prob_DurationUnit") {
            fieldName = "Probation duration unit"
          }
          else if (key == "prob_Duration") {
            fieldName = "Probation duration"
          }
          this.toastr.info(fieldName + " is required !", "Probation");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      // if ($("#prob_Code").val().indexOf(" ") > -1) {
      //   this.toastr.info("Probation code not allow space !", "Probation");
      //   $("#prob_Code").focus();
      //   return false;
      // }
      // if ($("#prob_Code").val().length < 2) {
      //   this.toastr.info("Probation code must be at least 2 characters long !", "Probation");
      //   $("#prob_Code").focus();
      //   return false;
      // }
      // if ($("#prob_Code").val().search(/[^a-zA-Z0-9]+/) > -1) {
      //   this.toastr.info("Probation only alphabets and numeric allow !", "Probation");
      //   $("#prob_Code").focus();
      //   return false;
      // }
      if ($("#prob_Name").val().length < 2) {
        this.toastr.info("Probation must be at least 2 characters long !", "Probation");
        $("#prob_Name").focus();
        return false;
      }
      if (/["\\]/.test($("#prob_Name").val())) {
        this.toastr.info("Probation does not allow Special Characters \" \\ ", "Probation");
        $("#prob_Name").focus();
        return false;
      }
      if (/["\\]/.test($("#notes").val())) {
        this.toastr.info("notes does not allow Special Characters \" \\ ", "Probation");
        $("#notes").focus();
        return false;
      }
      // if ($("#prob_Name").val().search(/^[^-\s][a-zA-Z0-9\s.]+[^-\s]+$/) != 0) {
      //    this.toastr.info("Probation only alphabets and numeric allow !", "Probation");
      //    $("#prob_Name").focus();
      //    return false;
      // }
      // if ($("#prob_Name").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
      //   this.toastr.info("Probation only alphabets and numeric allow !", "Probation");
      //   $("#prob_Name").focus();
      //   return false;
      // }
      if (parseInt($("#prob_Duration").val()) > 99) {
        this.toastr.info("Probation duartion not more then 99 !", "Probation");
        $("#prob_Duration").focus();
        return false;
      }

    }

    return true;
  }

  history() {
    this.historyView.getHistory("probation",this.probationform.value.prob_Id)
  }

 

  
  closeModal() {
    this.modalClose.emit(false)
  }

  
 

}


