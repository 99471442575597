import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;
import * as moment from 'moment';
import { identifierModuleUrl } from '@angular/compiler';

@Component({
   selector: 'app-personAdvancedSearch',
   templateUrl: './person-advanced-search.component.html',
})
export class PersonAdvancedSearchComponent implements OnInit {

   empfilterform: FormGroup;
   items: FormArray;
   constructor(
      private toastr: ToastrService,
      private commonService: CommonService,
      private router: Router,
      private fb: FormBuilder
   ) {

   }

   ngOnInit() {

      this.empfilterform = this.fb.group({
         SearchBy: this.fb.array([this.createItem()]),
      });
   }

   createItem() {
      return this.fb.group({
         FieldName: '',
         FieldValue: '',
         Parameter: '',
         ConditionWith: '',
         OrderBy: 'Id'
      });
   }

   addItem(data) {

      if(this.search()){

         this.items = this.empfilterform.get('SearchBy') as FormArray;
         this.items.push(this.createItem());
         
      }
      
   }


   removeItem(index) {
      this.items = this.empfilterform.get('SearchBy') as FormArray;
      this.items.removeAt(index);
   }

   search() {

      var formData = this.empfilterform.get("SearchBy").value;


      for (let index = 0; index < formData.length; index++) {

         if (formData[index].FieldValue != "" && formData[index].Parameter != "" && formData[index].FieldName != "") {

            if (!formData[index].FieldValue.includes('"')) {
               formData[index].FieldValue = '\"' + formData[index].FieldValue + '\"';
            }

            if (index > 0) {
               if ($("#ConditionWith" + index).val() != "") {
                  formData[index - 1].ConditionWith = $("#ConditionWith" + index).val();
               }
               else {
                  this.toastr.info("Condition with is required !", "Employee")
                  $("#ConditionWith" + index).focus();
                  return false;
               }
            }
            if (index == formData.length - 1) {
               formData[index].ConditionWith = "";
            }
         }
         else
         {
            this.toastr.info("Enter valid data !", "Employee")
            return false
         }
      }


      return formData;
   }



}


