import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
//import { ConsoleReporter } from 'jasmine';

declare var $: any;

@Component({
  selector: 'app-site-list',
  templateUrl: './site.component.html'
})
export class SiteComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  public exampleData: Array<Select2OptionData>;
  siteform: FormGroup;
  sitelist: any;
  calllist: boolean = true;
  filterSite: any;
  companylist: any;
  title: string;
  Toast: any;
  checkValidation: boolean = false;
  d: number = 1;
  countrylist: any;
  totalRecord: 0;
  cSearch =new FormControl("");
  id:number=0;
  modalOpen:boolean=false;
  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) { }

  options = {
    componentRestrictions: {
      country: ["UK", "IND"]
    }
  }
  ngOnInit() {

    
    if (this.calllist) {
      this.getSiteList(1);
      this.getCompanyList();
      this.getCountryList();
    }

  }
  
  getSiteList(PageIndex) {

    // ,colName=null,Ordering=null`
    //   var OrderBy
    //   if(Ordering != null){
    //      OrderBy = colName+" "+Ordering;
    //   }else{
    //      OrderBy = null;
    //   }    
    this.d = PageIndex;
    this.totalRecord = 0;
    this.sitelist = [];
    this.commonApiService.getListWithFilter(Commonvariable.Site, PageIndex, this.cSearch.value).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.sitelist = result["data"]["list"];
        this.filterSite = result["data"]["list"];
      }
    })

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }
  siteDelete(sId) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Site, sId).subscribe((response) => {
          $("#draggable").modal('hide');
          // this.sitelist = this.sitelist.filter(x => x.site_Id != sId)
          // this.filterSite = this.sitelist.filter(x => x.site_Id != sId)
          this.getSiteList(1);
          this.toastr.success(response["message"], 'Site');
          this.d = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Site, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.toastr.success(response["message"], 'Site');
            this.getSiteList(1);
          }

        })
      }

    });
  }

  getCountryList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "country_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Country, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.countrylist = response["data"].map(x => ({
          id: x.id,
          text: x.country_Name
        }));
      }
    })
  }



  getCompanyList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "company_Name"
    }

    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.companylist = response["data"];
        this.exampleData = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }

}
