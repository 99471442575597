import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { Commonvariable } from 'src/app/models/commonvariable';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { UserCreationComponent } from './../pages/usercreationform/usercreationform.component'
import { RoleAssignComponent } from './../pages/notification/roleassign/roleassign.component'
import { UserProfileComponent } from './../pages/user-profile/user-profile.component';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { MailSendComponent } from 'src/app/pages/mailsend/mailsend.component'




declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild(UserCreationComponent, { static: false }) UserCreation: UserCreationComponent;
  @ViewChild(RoleAssignComponent, { static: false }) RoleAssign: RoleAssignComponent;
  @ViewChild(UserProfileComponent, { static: false }) UserProfile: UserProfileComponent;
  @ViewChild(MailSendComponent, { static: false }) MailSend: MailSendComponent;
  hrLogin: boolean = true;
  pendingNotification: any = 0;
  pendingMessage: any = 0;
  allNotification: any;
  reviewNotification: any;
  userCreationNotification: any;
  probationNotification: any;
  emp_Id: any;
  isopen: any = "";
  loginName: "Admin";
  isCandidate: boolean = false

  isCandidateOnly: boolean = false

  subscription: Subscription;


  keyword = 'name';

  data: any;
  searchListVisible: boolean = true


  constructor(private router: Router, private loginService: LoginService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private commonService: CommonService,
  ) { }



  name = 'Angular 5';
  brands: string[] = ['Audi', 'BMW', 'Fiat', 'Ford', 'Honda', 'Jaguar', 'Mercedes', 'Renault', 'Volvo', 'VW'];

  filteredBrands: any[];
  filteredBrandsid: any[];

  brand: string;

  inputVar: string;


  autoComplete($event) {

    $(document).on('click', function (event) {
      if (!$(event.target).closest('#spnauto').length) {
        $('#ulautocomplete').hide();
      }
      else {
        $('#ulautocomplete').show();
      }

    });
    var PageIndex = 1;

    var RequestData = {
      "searchBy": [

      ],
      "PageIndex": 0,
      "CommonSearch": null,
      "PageSize": 50,
      "OrderBy": "FirstName"
    }

    let search = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;

    if (search.length > 0) {
      // this.searchListVisible = true;
      // this.commonApiService.getListWithFilter(Commonvariable.EmployeeDetail, PageIndex, null, "GetPaginatedForDictionary", null, 12, RequestData, search).subscribe((result) => {
      //   if (result["data"]["list"] != null && result["status"] == 200) {
      //     this.filteredBrands = [];
      //     this.filteredBrandsid = [];

      //     for (let i = 0; i < result["data"]["list"].length; i++) {
      //       var datas = {
      //        "id": result["data"]["list"][i].person_Id,
      //         "empId": result["data"]["list"][i].emp_Id,
      //         //"name": result["data"]["list"][i].firstName + " " + result["data"]["list"][i].middleName != null + " " + result["data"]["list"][i].lastName
      //         "name": result["data"]["list"][i].person_Name
      //       }
      //       console.log(datas);
      //       // let brand = result["data"]["list"][i].person_Name;
      //       // if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
      //         this.filteredBrands.push(datas);

      //       //}
      //     }
      //   }
      // })


    } else {
      this.searchListVisible = false;
    }



  }

  open() {
    this.isopen = "open";
  }


  getEmpDetails(event, e) {
    this.isopen = "";

    var empId = e.getAttribute('data-id');


    this.inputVar = event.target.innerText;
    //$('#ulautocomplete').hide();
    this.searchListVisible = false;
    setTimeout(() => {
      $('#userIdFirstWay').val("")
    }, 100);
    if (parseInt(empId)) {
      this.router.navigate(['/employee-dictonary'], { queryParams: { "empId": empId } });
    }

  }
  search(event) {


    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    var PageIndex = 1;

    var RequestData = {
      "searchBy": [

      ],
      "PageIndex": 0,
      "CommonSearch": null,
      "PageSize": 50,
      "OrderBy": "FirstName"
    }

    this.commonApiService.getListWithFilter(Commonvariable.EmployeeDetail, PageIndex, null, "GetPaginatedForDictionary", null, 12, RequestData, event.originalEvent.data).subscribe((result) => {
      if (result["data"]["list"] != null && result["status"] == 200) {
        this.filteredBrands = [];
        this.filteredBrandsid = [];

        for (let i = 0; i < result["data"]["list"].length; i++) {
          var datas = {
            "id": result["data"]["list"][i].person_Id,
            "empId": result["data"]["list"][i].emp_Id,
            //"name": result["data"]["list"][i].firstName + " " + result["data"]["list"][i].middleName != null + " " + result["data"]["list"][i].lastName
            "name": result["data"]["list"][i].person_Name
          }
          // let brand = result["data"]["list"][i].person_Name;
          // if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
          this.filteredBrands.push(datas.name);

          //}
        }
      }
    })


    // this.filteredBrands = [];
    // for (let i = 0; i < this.brands.length; i++) {
    //   let brand = this.brands[i];
    //   if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
    //     console.log(brand)
    //     this.filteredBrands.push(brand);
    //   }
    // }
  }

  ngOnInit() {


    this.pendingNotification = 0;
    //$(".ng-autocomplete input").addClass("form-control input-sm")

    // const source = interval(5000);

    // setTimeout(() => {
    //   this.subscription = source.subscribe(val => this.getNotification());
    // }, 5000);


    this.commonService.myData.subscribe((data) => {

      if (this.router.url != "/candidateedit" && this.router.url != "/401") {
        this.getNotification();
      }

    });

    $('[data-toggle="tooltip"]').tooltip();

    this.loginName = JSON.parse(localStorage.getItem('currentHRUser'))["data"]["person_Name"];

    this.isCandidate = (sessionStorage.getItem("menuHide") != null && sessionStorage.getItem("menuHide") != "") || JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "User" ? true : false
    this.isCandidateOnly = (sessionStorage.getItem("menuHide") != null && sessionStorage.getItem("menuHide") != "") || JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "Candidate" ? true : false

    if (sessionStorage.getItem("menuHide") != "") {
      this.hrLogin = false;
    }

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }

  selectEvent(item) {
    this.router.navigate(['/employee-dictonary'], { queryParams: { "empId": item.empId } });
  }


  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    var PageIndex = 1;

    var RequestData = {
      "searchBy": [

      ],
      "PageIndex": 0,
      "CommonSearch": null,
      "PageSize": 50,
      "OrderBy": "FirstName"
    }
    this.data = [];
    this.commonApiService.getListWithFilter(Commonvariable.EmployeeDetail, PageIndex, null, "GetPaginatedForDictionary", null, 12, RequestData, val).subscribe((result) => {
      if (result["data"]["list"] != null && result["status"] == 200) {
        for (let i = 0; i < result["data"]["list"].length; i++) {
          var datas = {
            "id": result["data"]["list"][i].person_Id,
            "empId": result["data"]["list"][i].emp_Id,
            // "name": result["data"]["list"][i].firstName + " " + result["data"]["list"][i].middleName != null + " " + result["data"]["list"][i].lastName
            "name": result["data"]["list"][i].person_Name
          }
          this.data.push(datas);
          // $(".ng-autocomplete input").addClass("form-control input-sm")
        }
      }
    })
  }

  onFocused(e) {
    // do something when input is focused
  }

  logOut() {
    this.commonService.dyanmicConfirm("Do you want to logout!", "Yes !").then((result) => {
      if (result.value) {
        this.loginService.logout(localStorage.getItem("user_Id")).subscribe((result) => {
          localStorage.clear();
          this.router.navigateByUrl('home');
        }, (error) => { localStorage.clear(); this.router.navigateByUrl('home'); });
      }
    })


  }


  getNotification() {

    let currentUser = JSON.parse(localStorage.getItem('currentHRUser'));

    // if (currentUser == null) {
    //   this.subscription.unsubscribe();
    // }

    if (this.router.url != "/candidateedit" && this.router.url != "/401" && currentUser != null) {
      this.pendingNotification = 0;

      var requestData = {
        "searchBy": [{}],
        "pageIndex": 0,
        "pageSize": 1000,
      }


      this.commonApiService.List(Commonvariable.Dashboard, "GetNotificationData").subscribe((result) => {

        if (result["data"] != null && result["data"] != "") {

          this.reviewNotification = result["data"]["reviewRequest"]["list"]
          this.pendingNotification += result["data"]["reviewRequest"]["list"].length;

          if (result["data"]["userCreation"] != null) {
            this.userCreationNotification = result["data"]["userCreation"]["list"]
            this.pendingNotification += result["data"]["userCreation"]["list"].length;
          }

          if (result["data"]["signUp"]["list"] != null) {
            this.allNotification = result["data"]["signUp"]["list"]
            this.pendingNotification += result["data"]["signUp"]["list"].length;
          }

        }
      })

    }




    // this.commonApiService.List(Commonvariable.EmployeeDetail, "GetProbationReviewEmployee").subscribe((result) => {
    //   if (result["data"] != "") {
    //     this.probationNotification = result["data"]
    //     this.pendingNotification += result["data"].length;
    //   }
    // })
  }


  openModal(id) {
    this.RoleAssign.openModal(id)
    $('#notificationRole').modal({
      backdrop: 'static'
    });
  }

  userCreation(id, usercreationid) {
    this.UserCreation.fillHRData(id, usercreationid)

    $('#userCreationForm').modal({
      backdrop: 'static'
    });

    $('#userCreation').animate({
      scrollTop: $("#userCreation").offset().top
    }, 500);
  }


  reviewRequest(person_Id) {

    this.router.navigate(['/review-person'], { queryParams: { Person_Id: person_Id } })
  }

  showProfile() {

    let empId = JSON.parse(localStorage.getItem('currentHRUser'))["data"]["emp_Id"][0]["emp_Id"];

    if (empId > 0) {
      this.UserProfile.getSummary(empId);
    }

  }


  sendMail() {
    if (this.MailSend.validation()) {
      const formData = new FormData();
      formData.append('To', $("#headermailSend #to").val());
      formData.append('CC', $("#headermailSend #cc").val());
      formData.append('BCC', $("#headermailSend #bcc").val());
      formData.append('Subject', $("#headermailSend #subject").val());
      formData.append('Role_Ids', this.MailSend.roleId != undefined ? this.MailSend.roleId : null);
      formData.append('Body', $('#headermailSend #emailsend').summernote('code'));
      formData.append('Company_Id', this.RoleAssign.empNotificatonform.controls.company_Id.value);
      formData.append('User_Id', this.RoleAssign.user_Id != undefined ? this.RoleAssign.user_Id : '0');
      formData.append('userEmailBodyPassword', $("#headermailSend #userEmailBodyPassword").val());
      if (this.MailSend.docFiles != undefined) {
        for (var i = 0; i < this.MailSend.docFiles.length; i++) {
          formData.append('file', this.MailSend.docFiles[i]);
        }
      }
      this.commonApiService.UploadLogo(Commonvariable.User, "SendMail", formData).subscribe((response) => {
        this.commonService.toaster("success", "Mail send successfully", "Signup")
        $('.modal').modal("hide");
      });
    }

  }

  closeModal() {
    $('.modal').modal("hide");
  }


}
