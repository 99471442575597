import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup,FormArray, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;


@Component({
  selector: 'app-employeeRef',
  templateUrl: './emp-reference.component.html'
})
export class EmployeeRefComponent implements OnInit {
  empform: FormGroup;
  items: FormArray;
  emprefrelList: any;
  countryList: Array<Select2OptionData>;
  editMode: boolean = false;
  empReferenceNR = new FormControl(false);
  empReferenceRequired = new FormControl(true);
  public isrequiredclass: boolean[] = [];
  files: any[] = [];
  emprefArray:FormArray;
  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.editMode = false;
    this.empform = this.fb.group({
      emp_ref: this.fb.array([this.createItem(null)]),
   });
    // this.empform = this.fb.group({
    //   id: ['0'],
    //   person_Id: ['0'],
    //   ref_Name: ['', Validators.required],
    //   ref_Email: [null],
    //   country_Id:[null],
    //   ref_ContactNo: ['', Validators.required],
    //   relationship: ['', Validators.required],
    //   isActive: ['1'],
    //   isReview: [1],
    //   updatedBy: [Commonvariable.User_Id]
    // });
   // this.commonService.flatpickr("flatpickropenEmpCStD0", 0)
   // this.commonService.flatpickr("flatpickropenEmpCStD1", 0)
   // this.getCountryList();
  }
  createItem(data) {
    
     return this.fb.group({
        Emp_Reference_Id: data != null ? data.emp_Reference_Id : '0',
        Emp_Id: data != null ? data.emp_Id : '0',
        //DateOfRec:data !=null ? data.dateOfRec : "",
        DateSent:data !=null ? data.formattedDate :"",
        DateOfRec:data !=null ? data.formattedDate :"",
        Notes:data !=null ? data.notes:'',
        files: data !=null ? data.file_Name:'',
        Required:data !=null ? data.required : 'no',
        isActive: data != null ? data.isActive == 1 ? true : false : true,
        AddedBy: Commonvariable.User_Id,
        
     });
     
    
  }

  getCountryList() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "country_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Country, requestData).subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.countryList = response["data"].map(x => ({
          id: x.id,
          text: x.country_Name + " (" + x.country_Code + ") "
        }));
        if(this.editMode == false){
          this.empform.controls.country_Id.setValue(response["data"].filter(x => x.isDefault == 1)[0]["id"]); 
        }

      }
    })
  }


  getRelationshipList() {
    this.commonApiService.List(Commonvariable.Relationship).subscribe((result) => {
      if (result != null && result["status"] == 200 && result["data"] != "") {
        this.emprefrelList = result["data"].sort((a, b) => a.name.localeCompare(b.name));
      }
    });
  }





  empEdit(Id) {
    
    if (Id > 0) {
      
      this.commonApiService.getById(Commonvariable.EmpRefrence, Id).subscribe((response) => {
        if (response != null && response["data"] != "") {
          this.editMode = true;
          this.getCountryList();
          var editData = response["data"];
          var formData = this.empform;
         
        //  this.commonService.setEditData(formData, editData);
         // $("#salaryType").prop("disabled",true)
          this.setEditData(formData, editData);

          if (JSON.parse(localStorage.getItem('currentHRUser'))["data"]["role"] == "User") {
            if (response["data"].length > 1) {
              if (response["data"][1] != null) {
                this.commonService.setEditData(formData, editData, 1);
              }
              else {
                this.commonService.setEditData(formData, editData);
              }
            }
          }
          else {
            //this.commonService.setEditData(formData, editData);
            this.setEditData(formData, editData);
          }
        }
      }, (error) => { this.toastr.error(error["error"].message, 'Employee'); })
    }
  }
  setEditData(form, editData) {
    const emprefdata = editData;
  
    if (Array.isArray(emprefdata)) {
      // Get the emp_salary_additional FormArray
      const emprefArray = this.empform.get('emp_ref') as FormArray;
  
      // Clear any existing items in the FormArray
      while (emprefArray.length) {
        emprefArray.removeAt(0);
      }
  
      // Loop through emprefdata and add FormGroup instances
      emprefdata.forEach((data,index) => {
        emprefArray.push(this.createItem(data));
      
       
     
        setTimeout(() => {
          if (data.required === "yes") {
            this.isrequiredclass[index] = true;
            this.empReferenceRequired[index]=true;
            var checkboxId = "empReferenceRequired" + index;
           var checkbox = document.getElementById(checkboxId) as HTMLInputElement;
           checkbox.checked = true;
           let DateSent = data.dateSent != null ? data.dateSent.split("T")[0].split("-") : "";
           let DateOfRec = data.dateOfRec != null ? data.dateOfRec.split("T")[0].split("-") : "";
           if (DateOfRec != "") {
             $("#DateOfRec"+[index]).val(DateOfRec[2] + "-" + DateOfRec[1] + "-" + DateOfRec[0]);
           }
           if (DateSent != "") {
            $("#DateSent"+[index]).val(DateSent[2] + "-" + DateSent[1] + "-" + DateSent[0]);
          }
          // form.controls.DateOfRec.setValue(this.commonService.dateFormatChange($("#DateOfRec"+index).val()));
          // emprefArray[index].Required = "no";
          
          }
          else{
            this.isrequiredclass[index] = false;
            this.empReferenceRequired[index]=false;
            var checkboxId = "empReferencenotRequired" + index;
           var checkbox = document.getElementById(checkboxId) as HTMLInputElement;
           checkbox.checked = true;
           let DateSent = data.dateSent != null ? data.dateSent.split("T")[0].split("-") : "";
           let DateOfRec = data.dateOfRec != null ? data.dateOfRec.split("T")[0].split("-") : "";
           if (DateSent != "") {
            $("#DateSent"+[index]).val(DateSent[2] + "-" + DateSent[1] + "-" + DateSent[0]);
          }
           if (DateOfRec != "") {
             $("#DateOfRec"+[index]).val(DateOfRec[2] + "-" + DateOfRec[1] + "-" + DateOfRec[0]);
           }
          // emprefArray[index].Required = "no";

          }
       
          // this.isrequiredclass[index]= true;
          // this.empReferenceRequired[index]=true;
          // var checkboxId = "empReferenceRequired" + index;
          // var checkbox = document.getElementById(checkboxId) as HTMLInputElement;
          // checkbox.checked = true;
           const filenameElement = document.getElementById(`fileinput-filename${index}`);
           if (filenameElement) {
             filenameElement.textContent = data.file_Name;
           }
          this.items = this.empform.get('emp_ref') as FormArray;
           var id = this.items.length - 1;
           // this.commonService.flatpickr("flatpickropenEmpCStD",id)
           // this.commonService.flatpickr("flatpickropenEmpCStD0",0)
          // this.commonService.flatpickr("ApprisalTo",id)
        }, 5);
      });
    } else {
      console.error('emprefdata is not an array.');
    }
  }
  openFlatpickr(index: number) {
    const inputId = `flatpickropenEmpCEnD${index}`;
    //const inputIds = `flatpickropenEmpCStD${index}`;
   // const inputElement = document.getElementById(inputId);
    if (inputId) {
      this.commonService.flatpickr(inputId,0);
    }
    // if (inputIds) {
    //   this.commonService.flatpickr(inputIds,0);
    // }
  }

  openFlatpickrStD(index: number) {
    // const inputId = `flatpickropenEmpCEnD${index}`;
    const inputIds = `flatpickropenEmpCStD${index}`;
   // const inputElement = document.getElementById(inputId);
    // if (inputId) {
    //   this.commonService.flatpickr(inputId,0);
    // }
    if (inputIds) {
      this.commonService.flatpickr(inputIds,0);
    }
  }
  

  validation() {
    var empRefData = this.empform.get('emp_ref').value;
    var tempArray = [];
    for (let index = 0; index < empRefData.length; index++) {

      var checkboxId = "empReferenceRequired" + index;
      var checkbox = document.getElementById(checkboxId) as HTMLInputElement;

      if (checkbox.checked)
       {
         empRefData[index].Required = "yes";

       }
       else{
         empRefData[index].Required = "no";
       }

      empRefData[index].DateSent = (this.commonService.dateFormatChange($("#DateSent"+[index]).val()));
      empRefData[index].DateOfRec = (this.commonService.dateFormatChange($("#DateOfRec"+[index]).val()));
       if (this.isrequiredclass[index] === true) {

          if (empRefData[index].DateOfRec == "" || empRefData[index].DateOfRec == null) {
             this.toastr.info("Date Of Rec is required !", "Employee");
             $("#DateOfRec" + index).focus();
             return false;
          }
          if (empRefData[index].DateSent == "" || empRefData[index].DateSent == null) {
            this.toastr.info("Date Sent is required !", "Employee");
            $("#DateSent" + index).focus();
            return false;
            }
          if ($("#Notes" + index).val().length > 200) {
             this.toastr.info("Comment  must be at least 200 characters long !", "Employee");
             $("#Notes" + index).focus();
             return false;
          }
          // if (empRefData[index].Ref_Name != "") {
          //    if ($("#Ref_Name" + index).val().search(/^[a-zA-Z\s]+$/) != 0) {
          //       this.toastr.info("Please enter valid reference name!", "Employee");
          //       $("#Ref_Name" + index).focus();
          //       return false;
          //    }
          //    if ($("#Ref_Name" + index).val().search(/^[^-\s][a-zA-Z0-9_\s-]+$/) != 0) {
          //       this.toastr.info("Reference name allow only alphabet!", "Employee");
          //       $("#Ref_Name" + index).focus();
          //       return false;
          //    }
          // }
          // if (empRefData[index].Ref_Email != "" && empRefData[index].Ref_Email != null) {
          //    if ($("#Ref_Email" + index).val().search(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/) != 0) {
          //       this.toastr.info("Please enter valid reference email!", "Employee");
          //       $("#Ref_Email" + index).focus();
          //       return false;
          //    }
          // }

          // if (empRefData[index].country_Id == "" || empRefData[index].country_Id == null) {
          //    this.toastr.info("Country is required !", "Company");
          //    $("#country_Id" + index + " select").focus();
          //    return false;
          // }


          // if (empRefData[index].files == "") {
          //    this.toastr.info("files  is required!", "Employee");
          //    $("#files" + index).focus();
          //    return false;
          // }
          if (empRefData[index].files == "" || empRefData[index].files == undefined) {
             this.toastr.info("File is required !", "Employee");
             $("#files" + index).focus();
             return false;
          }
          // if ($("#Ref_ContactNo" + index).val().length < 9) {
          //    this.toastr.info("Reference number must be at least 9 digit long !", "Employee");
          //    $("#Ref_ContactNo" + index).focus();
          //    return false;
          // }
          // if (empRefData[index].Relationship == "" || empRefData[index].Relationship == null) {
          //    this.toastr.info("Reference relationship is required !", "Employee");
          //    $("#Ref_Relationship" + index).focus();
          //    return false;
          // }


          // if (tempArray.length > 0) {
          //    if (tempArray.filter(x => x == String(empRefData[index].Ref_ContactNo).trim()).length > 0) {
          //       this.toastr.info("This value already entered !", "Employee");
          //       $("#Ref_ContactNo" + index).focus();
          //       return false
          //    }
          //    if (tempArray.filter(x => x == String(empRefData[index].Ref_Email).trim()).length > 0) {
          //       this.toastr.info("This value already entered !", "Employee");
          //       $("#Ref_Email" + index).focus();
          //       return false
          //    }
          // }

          // tempArray.push(String(empRefData[index].Ref_ContactNo).trim())
          // tempArray.push(String(empRefData[index].Ref_Email).trim() != "" ? String(empRefData[index].Ref_Email).trim() : 0)
          // if (empRefData[index].Relationship != "") {
          //    if ($("#Ref_Relationship" + index).val().search(/^[a-zA-Z]+$/) != 0) {
          //       this.toastr.info("Please enter valid relationship name  !", "Employee");
          //       $("#Ref_Relationship" + index).focus();
          //       return false;
          //    }
          // }
          // if ($("#Ref_Relationship" + index).val().length < 2) {
          //    this.toastr.info("Reference relationship must be at least 2 characters long !", "Employee");
          //    $("#Ref_Relationship" + index).focus();
          //    return false;
          // }
          // if ($("#Ref_Relationship" + index).val().indexOf(" ") > -1) {
          //    this.toastr.info("Reference relationship not allow space !", "Employee");
          //    $("#Ref_Relationship" + index).focus();
          //    return false;
          // }
       }
       empRefData[index].isActive = empRefData[index].isActive ? 1 : 0
      
    }

    return true;
 }

  addRelationship(event) {
    if (event.target.value != "") {
      if (this.emprefrelList.filter(x => x.name == event.target.value).length == 0) {
        var data = {
          "Id": 0,
          "Name": event.target.value,
          "isActive": 1,
          "AddedBy": Commonvariable.User_Id
        }
        this.commonApiService.Upsert(Commonvariable.Relationship, data, 0).subscribe((result) => {
          this.getRelationshipList();
        });
      }
    }
  }

  
  fileChange(event, id) {

    // if (event.target.files.item(0).name.split('.')[1] == "pdf" || event.target.files.item(0).name.split('.')[1] == "doc"
    //    || event.target.files.item(0).name.split('.')[1] == "docx" || event.target.files.item(0).name.split('.')[1] == "xlsx") {
       var empData = this.empform.get('emp_ref').value;
       empData[id].files = event.target.files[0];

       this.items = this.empform.get('emp_ref') as FormArray;
       this.items.controls[id].setValue(empData[id]);

       this.files.push(event.target.files[0]);
    // }
    // else {
    //    this.toastr.info("Allow only pdf,doc,docx,xlsx file format for upload !", "Employee");
    //    $("#remove" + id).click();
    // }

 }
 removeDoc(index) {
    var empData = this.empform.get('emp_ref').value;
    empData[index].files = "";
    this.files.reduce(index);
 }
 chekboxclickyes(event,index: number) {
  var empData = this.empform.get('emp_ref').value;
    var checkboxclick = event.target;
    if(checkboxclick.checked){
       this.isrequiredclass[index]= true;
       this.empReferenceRequired[index]=true;
       var checkboxId = "empReferencenotRequired" + index;
       var checkbox = document.getElementById(checkboxId) as HTMLInputElement;
       checkbox.checked = false;
       empData[index].Required = "yes";

    }
    else{
       this.isrequiredclass[index]= false;
       var checkboxId = "empReferencenotRequired" + index;
       var checkbox = document.getElementById(checkboxId) as HTMLInputElement;
       checkbox.checked = true;

    }
  

  }
  chekboxclickno(event,index: number) {
    var empData = this.empform.get('emp_ref').value;
    this.isrequiredclass[index]= false;
    var checkboxId = "empReferenceRequired" + index;
    var checkbox = document.getElementById(checkboxId) as HTMLInputElement;
    checkbox.checked = false;
    empData[index].Required = "no";
  }
  removeItem(index) {
    this.items = this.empform.get('emp_ref') as FormArray;
    this.items.removeAt(index);
 }
 addItem(data) {
  // if (this.validation()) {
 
   
  // this.commonService.flatpickr("flatpickropendr", 0)
      this.items = this.empform.get('emp_ref') as FormArray;
      if(this.items.controls.length < 2){
      this.items.push(this.createItem(data));
      setTimeout(() => {
         this.items = this.empform.get('emp_ref') as FormArray;
         var id = this.items.length - 1;
         this.commonService.flatpickr("flatpickropenEmpCEnD", id);
         this.commonService.flatpickr("flatpickropenEmpCStD", id)
         // this.commonService.flatpickr("flatpickropenEmpCEnD", id)
      }, 5);
    }
    else{
      return false;
    }
   
  // }
}



}
