<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>Import Export</h1>
            </div>
        </div>
        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                </li>
            </ul>
        </div>

        <div class="col-md-12">
            <div class="portlet box grey">
                <div class="portlet-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Master
                                    <!-- <span class="required"> *</span> -->
                                </label>
                                <ng-select2 [data]="masterlist" (valueChanged)="setFormData('masterId', $event)"
                                    [value]="masterId" [placeholder]="'--- Select ---'">
                                </ng-select2>
                            </div>
                        </div>
                        <!-- <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label">Company</label>
                                <ng-select2 [data]="companylist" (valueChanged)="setFormData('companyId',$event)"
                                    [value]="companyId" [placeholder]="'--- Select ---'">
                                </ng-select2>
                            </div>
                        </div> -->
                        <div class="col-md-5">
                            <div class="form-group">
                                <label class="control-label">Upload File</label>
                                <div class="fileinput fileinput-new" data-provides="fileinput">
                                    <div class="input-group input-large">
                                        <div class="form-control uneditable-input input-fixed input-medium"
                                            data-trigger="fileinput">
                                            <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                            <span class="fileinput-filename"> </span>
                                        </div>
                                        <span class="input-group-addon btn default btn-file">
                                            <span class="fileinput-new"> Select file </span>
                                            <span class="fileinput-exists"> Change </span>
                                            <input type="file" id="UploadDocument" (change)="fileChange($event)">
                                        </span>
                                        <a href="javascript:;" id="remove"
                                            class="input-group-addon btn red fileinput-exists" data-dismiss="fileinput">
                                            Remove </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="form-group text-center">
                                <label class="control-label">&nbsp;</label>
                                <button type="button" class="btn btn-primary" (click)="downloadMasterSampleCSVFile()"
                                    [attr.disabled]="masterId != null ? null : ''">Download Template</button>
                            </div>
                        </div>

                    </div>
                    <div class="text-center">
                        <button type="button" class="btn btn-primary" (click)="UploadMaster()"
                            [attr.disabled]="masterId != null ? null : ''"
                            style="width: 100px;">Import</button>&nbsp;&nbsp;
                        <button type="button" class="btn btn-primary" (click)="exportMaster()"
                            [attr.disabled]="masterId != null ? null : ''"
                            style="width: 100px;">Export</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>