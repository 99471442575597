<!-- BEGIN CONTENT -->
<div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
        <!-- BEGIN PAGE HEAD-->
        <div class="page-head">
            <!-- BEGIN PAGE TITLE -->
            <div class="page-title">
                <h1>Job Title List
                    <!--  <small>Portfolio 1 - Basic Grid</small> -->
                </h1>
            </div>
            <!-- END PAGE TITLE -->
        </div>
        <!-- END PAGE HEAD-->
        <!-- BEGIN PAGE BREADCRUMB -->

        <div class="navbar page-breadcrumb breadcrumb mb20">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <div>
                        <a data-toggle="modal" (click)="id=0;modalOpen =true" class="btn btn-transparent blue  btn-sm">Add
                            Job Title</a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <!-- BEGIN SAMPLE TABLE PORTLET-->
            <div class="portlet box grey">
                <div class="portlet-body">

                    <diV class="row">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3 text-right">
                            <input type="text" class="form-control" placeholder="Type to filter..." id="filterList"
                                (input)="getDesignationList(1)">
                        </div>
                    </diV>
                    <br />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="tblDepartment"
                            style="position: relative;" role="grid">
                            <thead class="flip-content">
                                <tr>
                                    <th class="text-center"> Company </th>
                                    <th class="text-center"> Department </th>
                                    <!-- <th class="text-center" style="width:20%;"> Job Code </th> -->
                                    <th class="text-center">Job Title</th>
                                    <th class="text-center"> Zone </th>
                                    <th style="width: 2%"> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of designationlist| paginate: { itemsPerPage: 10, currentPage: p,totalItems :totalRecord }">
                                    <td>
                                        <div class="col-md-1 col-xs-12">
                                            <a (click)="updateStatus(item.desig_Id,'0')">
                                                <div class="circle greenColor" *ngIf="item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Active"></div>
                                            </a>
                                            <a (click)="updateStatus(item.desig_Id,'1')">
                                                <div class="circle redColor" *ngIf="!item.isActive"
                                                    data-toggle="tooltip" data-placement="top" title="Inactive"></div>
                                            </a>
                                        </div>
                                        <div class="short_description col-md-10 col-xs-12" data-toggle="tooltip"
                                            data-placement="bottom" title="{{item.company_Name}}">{{item.company_Name}}
                                        </div>
                                    </td>
                                    <td>{{item.dept_Name}}</td>
                                    <!-- <td class="text-center">{{item.desig_Code}}</td> -->
                                    <td>{{item.desig_Name}}</td>
                                    <td>{{item.zone_Name}}</td>
                                    <td class="numeric text-center">
                                        <a *ngIf="item.isActive" href="javascript:;" data-toggle="modal"
                                            (click)="id=item.desig_Id;modalOpen =true"><i
                                                class="fa fa-edit purple iconcss iconFontsize" data-toggle="tooltip"
                                                data-placement="top" title="Edit Job Title"></i></a>
                                        <a *ngIf="!item.isActive" href="javascript:;"
                                            (click)="designationDelete(item.desig_Id)"><i
                                                class="fa fa-trash-o red-color iconcss iconFontsize"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Delete Job Title"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" *ngIf="designationlist != null">
                        <pagination-controls (pageChange)="getDesignationList($event)" autoHide="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>

        <!-- END PAGE BASE CONTENT -->
    </div>
    <!-- END CONTENT BODY -->
</div>
<!-- END CONTENT -->
<div *ngIf="modalOpen">
    <app-designation-form [pId]="id" (modalClose)="modalOpen = $event" (refreshPage)="getDesignationList($event)"></app-designation-form>
</div>