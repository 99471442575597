import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { EmployeeViewSummaryComponent } from '../employee-view-summary/employee-view-summary.component';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import * as moment from 'moment';
import { templateJitUrl } from '@angular/compiler';
import { strictEqual } from 'assert';
declare var $: any;


@Component({
  selector: 'app-EmpResignation',
  templateUrl: './employee-resignation.component.html'
})
export class EmpResignationComponent implements OnInit {
  @ViewChild(EmployeeViewSummaryComponent, { static: false }) viewSummary: EmployeeViewSummaryComponent;

  empResignationform: FormGroup;
  empResignationlist: any;
  filterlist: any;
  documentList: any[] = [];
  listCall: boolean = true;
  empList: any;
  title: string;
  Toast: any;
  p: number = 1;
  reasonforleavelist: any[] = [];
  totalRecord: 0;
  items: FormArray;
  SummaryId:number=0;  
  modelpop:boolean=false;
  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) { }
  ngOnInit() {

    this.title = "Add";
    this.empResignationform = this.fb.group({
      emp_Resignation_Id: ['0'],
      emp_Id: ['', Validators.required],
      leaveReason_Id: ['', Validators.required],
      noticePeriodInput: ['', Validators.required],
      noticePeriodType: ['Months', Validators.required],
      noticePeriod: [''],
      resignationDate: ['', Validators.required],
      leaveDate: ['', Validators.required],
      lastWorkingDate: ['', Validators.required],
      notes: [''],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: ['0'],
      emp_document: this.fb.array([this.createItem(null)]),
    });
    this.commonService.flatpickr("flatpickropenResD,#flatpickropenLeaD,#flatpickropenLastWD", 0)

    if (this.listCall) {
      this.getEmployeeResignationList();
      this.getEmpList();
      //this.getReasionForLeaveList();
    }

    //this.commonService.select2SingleDropDown("empNameSelectListClass");

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }

  getReasionForLeaveList(event) {
    this.reasonforleavelist = [];
    var empid = event != "" ? event : 0;    
    this.empResignationform.controls.leaveReason_Id.setValue('')
    this.commonApiService.getById(Commonvariable.ReasonForLeave, empid, "GetByEmpId").subscribe((response) => {
      if (response != null && response["data"] != "") {
        this.reasonforleavelist = response["data"];
      }
    })
  }


  getEmployeeResignationList(PageIndex = 1) {


    this.p = PageIndex;
    this.totalRecord = 0;
    this.empResignationlist = [];
    this.filterlist = [];
    this.commonApiService.getListWithFilter(Commonvariable.EmployeeResignation, PageIndex, $("#filterList").val().trim()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.empResignationlist = result["data"]["list"];
        console.log(this.empResignationlist);
        this.filterlist = result["data"]["list"];
      }
    })

    // this.empResignationlist = [];
    // this.commonApiService.List(Commonvariable.EmployeeResignation).subscribe((result) => {
    //   if (result != null && result["status"] == 200) {
    //     this.empResignationlist = result["data"];
    //     this.filterlist = result["data"];
    //     //this.filterlist = result["data"]["list"];        
    //   }
    // })
  }

  getEmpList() {
    this.commonApiService.empDetailList(Commonvariable.EmployeeDetail).subscribe((result) => {
      if (result != null && result["status"] == 200) {
        //this.empList = result["data"];
        //.filter(x => x.isActive != 0).sort((a, b) => a.firstName.localeCompare(b.firstName));
        this.empList = result["data"].sort((a, b) => a.firstName.localeCompare(b.firstName)).map(x => ({
          id: x.emp_Id,
          text: x.firstName+ ' '+x.lastName
        }))
      }
    })
  }

  getDocument() {

    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": 'name'
    }

    this.commonApiService.GetByPaginated(Commonvariable.Document, requestData).subscribe((response) => {
      if (response["data"] != null && response["data"] != "") {
        this.documentList = response["data"];
      }
    })
  }


  Upsert() {
    var empresForm = this.empResignationform;

    empresForm.controls.noticePeriod.setValue($("#noticePeriod").val());
    if (this.validation()) {
      //&& this.documentValidation()
      empresForm.get("isActive").setValue(empresForm.get("isActive").value ? 1 : 0);
      let narr = empresForm.value.emp_document.filter(f => f.files != null).map(function (x) {
        var o = Object.assign({});
        o.Emp_Doc_Id = x.Emp_Doc_Id;
        o.Doc_Id = x.Doc_Id;
        o.FileNameRef = x.FileNameRef;
        return o;
      })

      const formData = new FormData();
      formData.append('employee_Resignation', JSON.stringify(empresForm.value));
      formData.append('Doc_idCollection', JSON.stringify(narr));

      empresForm.value.emp_document.filter(f => f.files != null).map(function (x) {
        formData.append('file', x.files);
      })


      if (empresForm.controls.emp_Resignation_Id.value > 0) {
        this.commonApiService.putWithParameter(Commonvariable.EmployeeDetail, formData, "EditLeaver").subscribe((response) => {
          if (response != null) {
            if (response["message"] == Commonvariable.DuplicationMsg) {
              this.toastr.warning('Record is already exists !', 'Employee Exit');
            }
            else {
              $("#draggable").modal('hide');
              this.getEmployeeResignationList();
              this.toastr.success(empresForm.controls.emp_Resignation_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee Exit');
            }
          }
        })
      }
      else {
        this.commonApiService.PostRequest(Commonvariable.EmployeeDetail, "AddLeaver", formData).subscribe((response) => {
          if (response != null) {
            if (response["message"] == Commonvariable.DuplicationMsg) {
              this.toastr.warning('Record is already exists !', 'Employee Exit');
            }
            else {
              $("#draggable").modal('hide');
              this.getEmployeeResignationList();
              this.toastr.success(empresForm.controls.emp_Resignation_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee Exit');
            }
          }
        })
      }





      // this.commonApiService.Upsert(Commonvariable.EmployeeResignation, empresForm.value, empresForm.controls.emp_Resignation_Id.value).subscribe((response) => {
      //   if (response != null) {
      //     if (response["message"] == Commonvariable.DuplicationMsg) {
      //       this.toastr.warning('Record is already exists !', 'Employee Exit');
      //     }
      //     else {
      //       $("#draggable").modal('hide');
      //       this.getEmployeeResignationList();
      //       this.toastr.success(empresForm.controls.emp_Resignation_Id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Employee Exit');
      //     }
      //   }
      // })
    }

  }

  Delete(Id) {
    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.EmployeeResignation, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getEmployeeResignationList();
          this.toastr.success(Commonvariable.DeleteMsg, 'Employee Exit');
          this.p = 1;
        }, (error) => { this.toastr.error(error["error"].message, 'Employee Exit'); })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.EmployeeResignation, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.toastr.success(response["message"], 'Employee Exit');
            this.getEmployeeResignationList();
          }

        })
      }

    });
  }

  openModel(id) {
    this.getDocument();
    this.items = this.empResignationform.get('emp_document') as FormArray;
    this.items.clear();
    if (id > 0) {
      this.title = "Edit";

      this.commonApiService.getById(Commonvariable.EmployeeDetail, id, "GetLeaver").subscribe((response) => {

        var splittedNoticPeriod = response["data"].noticePeriod.split(" ", 2);

        if (response != null) {

          var editData = response["data"]


          this.getReasionForLeaveList(editData.emp_Id)
          var formData = this.empResignationform;


          this.empResignationform.controls.emp_Resignation_Id.setValue(editData.emp_Resignation_Id)
          this.empResignationform.controls.emp_Id.setValue(editData.emp_Id)
          this.empResignationform.controls.leaveReason_Id.setValue(editData.leaveReason_Id)
          this.empResignationform.controls.noticePeriod.setValue(editData.noticePeriod)
          this.empResignationform.controls.notes.setValue(editData.notes)
          this.empResignationform.controls.isActive.setValue(editData.isActive)

          //this.commonService.select2SingleDropDown("empNameSelectListClass", editData.emp_Id);

          if (editData.emp_Id != "" && editData.emp_Id != null) {
            $("#emp_Id").prop("disabled", true);
          }


          let resignationDate = response["data"].resignationDate != null ? response["data"].resignationDate.split("T")[0].split("-") : "";
          let leaveDate = response["data"].leaveDate != null ? response["data"].leaveDate.split("T")[0].split("-") : "";
          let lastWorkingDate = response["data"].lastWorkingDate != null ? response["data"].lastWorkingDate.split("T")[0].split("-") : "";




          if (resignationDate != "") {
            $("#ResignationDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: resignationDate[2] + "-" + resignationDate[1] + "-" + resignationDate[0] });
            $("#ResignationDate").val(resignationDate[2] + "-" + resignationDate[1] + "-" + resignationDate[0]);
          }
          if (leaveDate != "") {
            $("#LeaveDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: leaveDate[2] + "-" + leaveDate[1] + "-" + leaveDate[0] });
            $("#LeaveDate").val(leaveDate[2] + "-" + leaveDate[1] + "-" + leaveDate[0]);
          }
          if (resignationDate != "") {
            $("#LastWorkingDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: lastWorkingDate[2] + "-" + lastWorkingDate[1] + "-" + lastWorkingDate[0] });
            $("#LastWorkingDate").val(lastWorkingDate[2] + "-" + lastWorkingDate[1] + "-" + lastWorkingDate[0]);
          }
          if (splittedNoticPeriod[0] != "") {
            $("#noticePeriodInput").val(splittedNoticPeriod[0]);
          }
          if (splittedNoticPeriod[1] != "") {
            $("#noticePeriodType").val(splittedNoticPeriod[1]);
          }
          if (response["data"].noticePeriod != "") {
            $("#noticePeriod").val(response["data"].noticePeriod);
          }
          formData.controls.noticePeriodInput.setValue(splittedNoticPeriod[0])
          formData.controls.noticePeriodType.setValue(splittedNoticPeriod[1])
          formData.controls.updatedBy.setValue(Commonvariable.User_Id)


          if (response["data"]["doc"].length > 0) {
            response["data"]["doc"].forEach(x => {
              this.items.push(this.createItem(x));
            });
          }
        }
      })
    }
    else {
      $("#emp_Id").prop("disabled", false);

      this.listCall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });

  }

  filterData(event) {
    //const val = event.target.value;
    const val = event.target.value.toLowerCase().trim();

    if (val == "") {
      this.empResignationlist = this.filterlist;
      this.p = 1;
    }
    else {
      const temp = this.empResignationlist.filter(function (d) {

        if (d["resignationDate"]) {
          var date = val;
          var datearray = date.split("/");
          var resignationDate = datearray[2] + '-' + datearray[1] + '-' + datearray[0];
        }

        if (d["leaveDate"]) {
          var date = val;
          var datearray = date.split("/");
          var leaveDate = datearray[2] + '-' + datearray[1] + '-' + datearray[0];
        }

        if (d["lastWorkingDate"]) {
          var date = val;
          var datearray = date.split("/");
          var lastWorkingDate = datearray[2] + '-' + datearray[1] + '-' + datearray[0];
        }

        return String(d["firstName"]).toLowerCase().indexOf(val) !== -1 ||
          String(d["middleName"]).toLowerCase().indexOf(val) !== -1 || String(d["lastName"]).toLowerCase().indexOf(val) !== -1 ||
          String(d["leaveReason_Id"]).toLowerCase().indexOf(val) !== -1 || String(d["noticePeriod"]).toLowerCase().indexOf(val) !== -1 ||
          String(d["resignationDate"]).toLowerCase().indexOf(resignationDate) !== -1 || String(d["leaveDate"]).toLowerCase().indexOf(leaveDate) !== -1 ||
          String(d["lastWorkingDate"]).toLowerCase().indexOf(lastWorkingDate) !== -1 || !val;

      });
 
      //const temp = this.commonService.filterData(event, this.empResignationlist, "emp_Id");
      this.empResignationlist = temp;
      this.p = 1;
    }
  }

  validation() {
    var formData = this.empResignationform;
    formData.controls.resignationDate.setValue(this.commonService.dateFormatChange($("#ResignationDate").val()));
    formData.controls.leaveDate.setValue(this.commonService.dateFormatChange($("#LeaveDate").val()));
    formData.controls.lastWorkingDate.setValue(this.commonService.dateFormatChange($("#LastWorkingDate").val()));


    if (!formData.valid) {
      for (const key of Object.keys(formData.controls)) {
        if (formData.controls[key].invalid) {
          let fieldName = "";
          if (key == "emp_Id") {
            fieldName = "Employee"
          }
          else if (key == "leaveReason_Id") {
            fieldName = "Leave reason"
          }
          else if (key == "noticePeriodInput") {
            fieldName = "Notice period"
          }
          else if (key == "noticePeriodType") {
            fieldName = "Notice period Type"
          }
          else if (key == "resignationDate") {
            fieldName = "Date of notice"
          }          
          else if (key == "lastWorkingDate") {
            fieldName = "Last working date"
          }
          else if (key == "leaveDate") {
            fieldName = "Leave date"
          }

          this.toastr.info(fieldName + " is required !", "Employee Exit");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if ($("#leaveReason_Id").val().search(/[^a-zA-Z0-9- ]+/) > -1) {
        this.toastr.info("Leave reason only alphanumeric  value allow !", "Employee Exit");
        $("#leaveReason_Id").focus();
        return false;
      }
      if ($("#noticePeriodInput").val().search(/[^a-zA-Z0-9 ]+/) > -1) {
        this.toastr.info("Noticep period only alphanumeric value allow !", "Employee Exit");
        $("#noticePeriodInput").focus();
        return false;
      }
      if (moment($("#ResignationDate").val(), 'DD-MM-YYYY') > moment($("#LeaveDate").val(), 'DD-MM-YYYY') ||
        moment($("#ResignationDate").val(), 'DD-MM-YYYY') > moment($("#LastWorkingDate").val(), 'DD-MM-YYYY')) {
        this.toastr.info("Date of notice not more then leave date and last working date !", "Employee Exit");
        $("#ResignationDate").focus();
        return false;
      }
      if (moment($("#LeaveDate").val(), 'DD-MM-YYYY') > moment($("#LastWorkingDate").val(), 'DD-MM-YYYY')) {
        this.toastr.info("Leave date not more then last working date !", "Employee Exit");
        $("#LeaveDate").focus();
        return false;
      }
    }
    return true;
  }

  autoCompleteLeaveDate() {
    
    
    let addKey = $("#noticePeriodType").val() == "Days" ? 'days' : $("#noticePeriodType").val() == "Weeks" ? 'W' : $("#noticePeriodType").val() == "Months" ? 'M' : 'Y';
    
    let addvalue = $("#noticePeriodInput").val();    
    // if(addvalue !="" && addvalue !=null){            
    //   var noticeDate = moment(moment().format('DD-MM-YYYY'), 'DD-MM-YYYY').add(addvalue, addKey).format("DD-MM-YYYY");      
    //   $("#ResignationDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: noticeDate });
    // }
    if ($("#ResignationDate").val() != "") {
      var selectedStartDate = moment($("#ResignationDate").val(), 'DD-MM-YYYY').format("DD-MM-YYYY");
      var endDate = moment(selectedStartDate, 'DD-MM-YYYY').add(addvalue, addKey).format("DD-MM-YYYY");
      $("#LeaveDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: endDate });
      $("#LastWorkingDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: endDate });
    }

  }


  autoCompleteLeaveDateNotice(){
    

   // let addKey = $("#noticePeriodType").val() == "Days" ? 'days' : $("#noticePeriodType").val() == "Months" ? 'M' : 'Y';
   let addKey = $("#noticePeriodType").val() == "Days" ? 'days' : $("#noticePeriodType").val() == "Weeks" ? 'W' : $("#noticePeriodType").val() == "Months" ? 'M' : 'Y';
    let addvalue = $("#noticePeriodInput").val();    
    if(addvalue !="" && addvalue !=null){ 
      var selectedStartDate = moment($("#ResignationDate").val(), 'DD-MM-YYYY').format("DD-MM-YYYY");
      var endDate = moment(selectedStartDate, 'DD-MM-YYYY').add(addvalue, addKey).format("DD-MM-YYYY");
      $("#LeaveDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: endDate });
      $("#LastWorkingDate").flatpickr({ dateFormat: "d-m-Y", defaultDate: endDate });
    }
  }

  createItem(data) {
    return this.fb.group({
      Emp_Doc_Id: data != null ? data.emp_Doc_Id : '0',
      Doc_Id: data != null ? data.doc_Id : '',
      files: data != null ? null : '',
      FileNameRef: data != null ? data.emp_Doc_Name : '',
      file_Name: data != null ? data.file_Name : '',
      isActive: true,
      addedBy: Commonvariable.User_Id,
    });
  }

  addItem(data) {
    if (this.documentValidation()) {
      this.items = this.empResignationform.get('emp_document') as FormArray;
      this.items.push(this.createItem(data));
    }
  }
  removeItem(index) {
    this.items = this.empResignationform.get('emp_document') as FormArray;
    if (this.items.value[index]["Emp_Doc_Id"] > 0) {
      this.commonApiService.Delete(Commonvariable.EmployeeDetail, this.items.value[index]["Emp_Doc_Id"], "DeleteLeaverDocument").subscribe((result) => {
        this.commonService.toaster("success",result["message"],"Employee Document");
        this.items.removeAt(index);
        
      })
    }
    else {
      this.items.removeAt(index);
    }

    //this.items.removeAt(index);
  }

  fileChangeMultiple(event, id) {

    if (event.target.files.length > 0) {
      // if (event.target.files.item(0).name.split('.')[1] == "pdf" || event.target.files.item(0).name.split('.')[1] == "doc"
      //   || event.target.files.item(0).name.split('.')[1] == "docx") {

        this.items = this.empResignationform.get('emp_document') as FormArray;

        if (this.items.value[id].Doc_Id != "") {
          this.items.value[id].files = event.target.files[0];
          this.items.value[id].FileNameRef = event.target.files[0]["name"];
          this.items.value[id].onchange = true
        }
        else {
          this.toastr.info("Select document type !", "Employee");
          $("#Doc_Id" + id).focus();
          $("#removeEmpResignation" + id).click();
        }
      // }
      // else {
      //   this.toastr.info("Allow only pdf,doc,docx file format for upload !", "Employee");
      //   $("#removeEmpResignation" + id).click();
      // }
    }
  }

  removeDoc(index) {
    $("#EmployeeDocument" + index).removeClass("fileinput-exists")
    $("#EmployeeDocument" + index).addClass("fileinput-new")
  }



  documentValidation() {
    let document = this.empResignationform.controls.emp_document.value;
    if (document.length > 0) {
      for (let index = 0; index < document.length; index++) {

        if (document[index]["Doc_Id"] == '') {
          this.toastr.info("Select document type !", "Employee");
          $("#Doc_Id" + index).focus();
          return false;
        }

        if (document[index]["files"] == '') {
          this.toastr.info("Select file !", "Employee");
          return false;
        }
      }
    }

    return true;
  }

  getSummary(emp_Id) {
    this.modelpop=true;
    this.SummaryId=emp_Id;
    // $("#viewSummary").modal("show");
    $('#viewSummary').modal({
      backdrop: 'static'
   });
  }
//   getSummary(emp_Id) {
//     this.viewSummary.getSummary(emp_Id);

//  }

closepoopup(){
  this.modelpop=false;
  $("#viewSummary").modal("hide");
  // this.viewSummary.
}
}
// if (moment($("#IssueDate" + index).val(), 'DD-MM-YYYY') >= moment($("#ExpiryDate" + index).val(), 'DD-MM-YYYY')) {
//   this.toastr.info("Issue date not more then expiry date and not equal to !", "Employee");
//   $("#IssueDate" + index).focus();
//   return false;
// }