<!-- <form [formGroup]="empform">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Right To Work Document</label>                
                <select class="form-control" id="rwC_Id" formControlName="rwC_Id" (change)="changeShowrtwDoc($event)">
                    <option value="">Select Right To Work Document</option>
                    <option *ngFor="let item of rtwCategoryList;" value={{item.rwC_Id}}>{{item.name}}</option>
                </select>                
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Authority</label>
                <input type="text" class="form-control" id="issueAuthority" formControlName="issueAuthority"
                    autocomplete="off" maxlength="100">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Country of Issue</label>
                <input type="text" class="form-control" id="issueCountry" formControlName="issueCountry"
                    autocomplete="off" maxlength="100">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Date of Issue</label>
                <div class="input-group" id="flatpickropenissueD" data-wrap="true">
                    <input type="text" class="form-control readonlyClass" id="issueDate" data-input>
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label class="control-label">Date of Expiry</label>
                <div class="input-group" id="flatpickropenexpiryD" data-wrap="true">
                    <input type="text" class="form-control readonlyClass" id="expiryDate" data-input>
                    <span class="input-group-btn" data-toggle>
                        <button class="btn default" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="empright" class="md-check" formControlName="isActive">
                            <label for="empright">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="rtwDecumentBody" style="display: block;">    
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label">Document Type</label>
                    <select class="form-control" id="doc_Id" formControlName="doc_Id">
                        <option value="">Select Document</option>
                        <option *ngFor="let item of documentList;" value={{item.id}}>
                            {{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label">Upload Document</label>
                    <div class="fileinput fileinput-new" data-provides="fileinput">
                        <div class="input-group input-large">
                            <div class="form-control uneditable-input input-fixed input-medium" data-trigger="fileinput">
                                <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                <span class="fileinput-filename"> </span>
                            </div>
                            <span class="input-group-addon btn default btn-file">
                                <span class="fileinput-new"> Select file </span>
                                <span class="fileinput-exists"> Change </span>
                                <input type="file" id="files" (change)="fileChange($event)"> </span>
                            <a (click)="removeDoc($event)" class="input-group-addon btn red fileinput-exists removedacoument" id="remove"
                                data-dismiss="fileinput">
                                Remove </a>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label class="control-label">Document Notes</label>
                    <textarea class="form-control" id="docNotes" formControlName="notes" style="width: 100%;"></textarea>
                </div>
            </div>        
        </div>
    </div>
</form> -->
<form [formGroup]="empform">
    <div class="row form-group">

        <div class="col-md-4">
            <label class="form-label ">Nationality

                <span class="required"> * </span>
            </label>
            <!-- <select id="rwC_Id" class="form-control" formControlName="rwC_Id" >
                <option value="">Select RTW DocumentType</option>
                <option *ngFor="let item of nationalityList;" value={{item.rwC_Id}}>
                    {{item.name}}</option>
            </select> -->
            <ng-select2 [data]="nationalityList" id="Nationality_Id" [options]="{multiple: false,tags: true}"
            [value]='this.empform.controls.lstNationality_Id.value'
            (valueChanged)="setFormData('lstNationality_Id',$event)"
           [placeholder]="'--- Select ---'">
        </ng-select2>



        </div>



        <div class="col-md-4">

            <label class="form-label">ECS Status
                <span class="required" *ngIf="isrequiredclass"> * </span>
            </label>

        
            <select id="ecsstatus" class="form-control" formControlName="ecsstatus" (change)="ECSStatusData($event)">
                <option value="">Select ECS Status</option>
                <option *ngFor="let item of ecsstatusListList;" value={{item.id}}>
                    {{item.name}}</option>
            </select>
         
            

        </div>



        <div class="col-md-4">

            <label class="form-label">ECS Received
                <span class="required" *ngIf="isrequiredforECS"> * </span>
            </label>

            <div class="input-group DOB" id="flatpickropenecsRec" data-wrap="true">

                <input type="text" class="form-control datePicker readonlyClass" id="ecsreceiveddate"
                    autocomplete="off" data-input  />

                <span class="input-group-btn" data-toggle>

                    <button class="btn default form-control datePicker" type="button">

                        <i class="fa fa-calendar"></i>

                    </button>

                </span>

            </div>

        </div>



    </div>
    <div class="row form-group">





        <div class="col-md-4">

            <label class="form-label">ECS Expiry Date
                <span class="required" *ngIf="isrequiredforECS"> * </span>
            </label>

            <div class="input-group" id="flatpickropenecsED" data-wrap="true">

                <input type="text" class="form-control readonlyClass" id="ecsexpirydate"
                    data-input >

                <span class="input-group-btn" data-toggle>

                    <button class="btn default" type="button">

                        <i class="fa fa-calendar"></i>

                    </button>

                </span>

            </div>

        </div>



        <!-- <div class="col-md-4">

<label class="form-label">RTW Document Type</label>

<select class="form-control">

    <option>Document Type</option>

</select>

</div> -->

        <div class="col-md-4">

            <div class="form-group">

                <label class="control-label">RTW DocumentType
                    <span class="required" *ngIf="isrequiredclass"> * </span>
                </label>


                    <select id="rwC_Id" class="form-control" formControlName="rwC_Id" >
                        <option value="0">Select RTW DocumentType</option>
                        <option *ngFor="let item of rtwCategoryList;" value={{item.rwC_Id}}>
                            {{item.name}}</option>
                    </select>
                   

                   

               

            </div>

        </div>



        <!-- (click)=Other= "Other"; -->

        <div class="col-md-4">

            <label class="form-label">RTW - Visa/Status Type
                <span class="required" *ngIf="isrequiredclass"> * </span>
            </label>

          
            <select id="rtw_VisaStatus_Id" class="form-control"  (change)="toggleCommentsTextarea($event)" formControlName="rtw_VisaStatus_Id" >
                <option value="0">Select RTW - Visa/Status Type</option>
                <option *ngFor="let item of rtwVisaStaList;" value={{item.id}}>
                    {{item.name}}</option>
            </select>
           

        </div>

     

    </div>
    <div class="row form-group">



        <div class="col-md-4">

            <label class="form-label">RTW - Visa/Status Expiry Date
                <span class="required" *ngIf="isnotrequiredComclass"> * </span>
            </label>

            <div class="input-group" id="flatpickropenrtwVSED" data-wrap="true">

                <input type="text" class="form-control readonlyClass" id="rtw_VisaStatus_ExpiryDate" data-input
                    >

                <span class="input-group-btn" data-toggle>

                    <button class="btn default" type="button">

                        <i class="fa fa-calendar"></i>

                    </button>

                </span>

            </div>

        </div>



        <div class="col-md-4">

            <label class="form-label">Passport Expiry Date
                <span class="required" *ngIf="isrequiredclass"> * </span>
            </label>

            <div class="input-group" id="flatpickropenpassportED" data-wrap="true">

                <input type="text" class="form-control readonlyClass" id="passportExpiryDate" data-input
                   >

                <span class="input-group-btn" data-toggle>

                    <button class="btn default" type="button">

                        <i class="fa fa-calendar"></i>

                    </button>

                </span>

            </div>

            

        </div>

        <div class="col-md-4"  id="Others"  *ngIf="isDivHidden">
            <label>Other<span class="required" *ngIf="isrequiredComclass"> * </span></label>
            <textarea cols="55" class="form-control" id="Other" formControlName="Other"></textarea>
        </div>







    </div>
    <div class="row form-group">

        <div class="col-md-4">

            <label class="form-label">Sponsored Employee</label>

            <div class="md-checkbox-inline">

                <div class="md-checkbox">

                    <input type="checkbox" id="isSponsoredEmployee" class="md-check"
                        formControlName="isSponsoredEmployee" (click)="chekboxclickyes($event)">

                    <label for="isSponsoredEmployee">

                        <span></span>

                        <span class="check"></span>

                        <span class="box"></span> </label>

                </div>

            </div>

        </div>

        
        <div class="col-md-4">

            <label class="form-label">Sponsorship Expiry Date
                <span class="required" *ngIf="isrequiredclassspo"> * </span>
            </label>

            <div class="input-group" id="flatpickropensponsershipED" data-wrap="true">

                <input type="text" class="form-control readonlyClass" id="sponsoredExpiryDate"
                    data-input >

                <span class="input-group-btn" data-toggle>

                    <button class="btn default" type="button">

                        <i class="fa fa-calendar"></i>

                    </button>

                </span>

            </div>

        </div>


        <div class="col-md-4">

            <label class="form-label">Comments
                <!-- <span class="required" *ngIf="isrequiredclass"> * </span> -->
            </label>

            <textarea class="form-control" id="comments" formControlName="comments" maxlength="200"></textarea>

        </div>



    </div>
</form>