<style>
    td.details-control {
        background: url('assets/pages/img/SD7Dz.png') no-repeat center center;
        cursor: pointer;
    }

    tr.shown td.details-control {
        background: url('assets/pages/img/d4ICC.png') no-repeat center center;
    }
</style>

<!-- <div class="page-head">
    <div class="page-title">
        <h1>Selection Candidate Wise
        </h1>
    </div>
</div> -->

<div class="col-md-12">
    <!-- BEGIN SAMPLE TABLE PORTLET-->
    <div class="portlet box grey">
        <div class="portlet-body">

            <diV class="row">

                <div class="col-md-3">
                    <select name="approvallist_status" id="approvallist_status" aria-controls="sample_4"
                        class="form-control" (change)="getStatusWiseList(1)">
                        <option value="status">All</option>
                        <option value="New">New</option>
                        <option value="Schedule">Schedule</option>
                        <option value="Shortlisted">Shortlisted</option>
                        <option value="Selected">Selected</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Offered">Offered</option>
                        <option value="Accepted">Accepted</option>
                        <option value="Decline">Decline</option>
                        <!-- <option value="Move To Onboarding">Move To Onboarding</option> -->
                    </select>
                </div>

                <!-- <div class="col-md-5">

                </div>
                <div class="col-md-4">
                    <div class="dataTables_length">
                        <input type="text" class="form-control" placeholder="Type to filter..." id="statusFilterList"
                            autocomplete="off" (input)="getStatusWiseList(1)">
                    </div>
                </div> -->

            </diV>
            <br>

            <div class="table-responsive">
                <table id="example2" class="display table table-striped table-bordered table-hover" cellspacing="0"
                    width="100%" style="border:1px solid #e7ecf1;">
                    <thead>
                        <tr>
                            <th class="text-center"> Candidate Name </th>
                            <th class="text-center"> Company </th>
                            <th class="text-center" style="width:25%;"> Vacancy </th>

                            <th class="text-center" style="width:25%;"> Status</th>
                            <th style="width: 2%"> Actions </th>
                        </tr>
                    </thead>
                    <tbody
                        *ngFor="let item of statusWiseList| paginate: { itemsPerPage: 10, currentPage: p1,totalItems :totalRecord,id: 'pagination43' }">
                        <tr [style.background-color]="item.statusBackGroundColor" [style.color]="item.statusFrontColor">
                            <td class="text-center">{{item.firstName}} {{item.lastName}}</td>
                            <td class="text-center">{{item.company_Name}}</td>
                            <td class="text-center">{{item.vacancy_Name}}</td>


                            <td class="text-center">{{item.status}}</td>
                            <td>
                                <a (click)="downloadCV(item.id)" *ngIf="item.cv != null">
                                    <i class="fa fa-download purple iconcss iconFontsize" data-toggle="tooltip"
                                        data-placement="top" title="Download CV"></i>&nbsp;
                                </a>
                                <div *ngIf="item.moveToOnboarding != 1">

                                    <a *ngIf="item.status == 'Selected'"
                                        (click)="UpdateStatus('Offered',item.candidate_Id,item.vacancy_Id)">
                                        <i class="fa fa-gift iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Offered"></i>&nbsp;
                                    </a>

                                    <a *ngIf="item.status == 'Offered'"
                                        (click)="UpdateStatus('Accepted',item.candidate_Id,item.vacancy_Id)"><i
                                            class="fa fa-check-square-o green iconcss iconFontsize"
                                            data-toggle="tooltip" data-placement="top" title="Accepted"></i>&nbsp;</a>

                                    <a *ngIf="item.status == 'Offered'"
                                        (click)="UpdateStatus('Decline',item.candidate_Id,item.vacancy_Id)"><i
                                            class="fa fa-close red-color iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Decline"></i>&nbsp;</a>

                                    <a *ngIf="item.status == 'Accepted' || item.status == 'Offered' ||item.status == 'Selected'"
                                        (click)="moveToOnboarding(item.candidate_Id,item.vacancy_Id)">
                                        <i class="fa fa-paper-plane-o iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Move To Onboarding"></i>
                                    </a>

                                    <a *ngIf="item.status != 'Move To Onboarding' && item.status != 'Rejected' 
                                        && item.status != 'Decline' && item.status != 'Move To Employee'&& item.status != 'Accepted'
                                        && item.status != 'Offered' && item.status != 'Selected'"
                                        (click)="directOpenMoveToOnboarding(item.candidate_Id,item.vacancy_Id)">
                                        <i class="fa fa-paper-plane-o iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Move To Onboarding"></i>&nbsp;</a>

                                    <a *ngIf="item.status == 'New'||item.status == 'Shortlisted'"
                                        (click)="openInterviewModel(item.candidate_Id,item.vacancy_Id,item.company_Id)">
                                        <i class="fa fa-calendar iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Schedule Interview"></i>&nbsp;
                                    </a>

                                    <a *ngIf="item.status == 'Schedule'"
                                        (click)="openInterviewFeedbackModel(item.candidate_Id,item.vacancy_Id,item.company_Id,item.dept_Id,item.desig_Id)">
                                        <i class="fa fa-comments-o iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Interview Feedback"></i>&nbsp;
                                    </a>
                                    <a *ngIf="item.status == 'Schedule'"
                                        (click)="GetMail(item.candidate_Id,item.vacancy_Id)">
                                        <i class="fa fa-envelope-o iconcss iconFontsize" data-toggle="tooltip"
                                            data-placement="top" title="Send Schedule Mail"></i>&nbsp;
                                    </a>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="text-center" *ngIf="statusWiseList != null">
                <pagination-controls (pageChange)="getStatusWiseList($event)" autoHide="true" id="pagination43">
                </pagination-controls>
            </div>
        </div>
    </div>
</div>

<!-- END CONTENT -->


<div class="modal fade draggable-modal" id="modalStatusWisescheduleinterview" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Schedule Interview</h4>
            </div>
            <div class="modal-body">
                <app-scheduleInterviewforstatus></app-scheduleInterviewforstatus>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn dark btn-outline pull-left" (click)="history()"
                                               *ngIf="title == 'Edit'"><i class="fa fa-history"></i></button> -->
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button class="btn btn-outline blue" (click)="UpsertInterviewDetail('Schedule')">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="modalStatusWisefeedbackinterview" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:10% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Interview Feedback</h4>
            </div>
            <div class="modal-body">
                <app-interviewFeedback></app-interviewFeedback>
            </div>
            <div class="modal-footer">
                <div class="row isFinalBody">
                    <div class="col-md-2">
                        <div class="md-checkbox-inline text-left ">
                            <div class="md-checkbox">
                                <input type="checkbox" id="finalStatusWise" class="md-check" (change)="showIsFinal()"
                                    [checked]="isFinal">
                                <label for="finalStatusWise">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span>Is Final </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-left" *ngIf="isFinal">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Salary<span class="required"> *
                                </span> </label>
                            <div class="input-group">
                                <input type="number" min="0" step="0.01" id="candidateWiseSalary"
                                    class="form-control" autocomplete="off" maxlength="50"
                                    placeholder="{{CandidateWisesalaryPlaceHolder}}">
                                <span class="input-group-addon inputaddon">{{currency_Code}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label">Salary Type </label>
                            <select id="candidateWiseSalaryType" class="form-control">
                                <option value="">--- Select ---</option>
                                <option value="Annually">Annually</option>
                                <option value="Hourly">Hourly</option>
                            </select>
                        </div>
                    </div> -->
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Reporting<span class="required"> *
                                </span> </label>
                            <select id="Reporting_Id" class="form-control">
                                <option value="">--- Select ---</option>
                                <option *ngFor="let emp of reportingEmpList;" value={{emp.emp_Id}}>
                                    {{emp.firstName}} {{emp.lastName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Planned Start Date<span class="required"> *
                                </span> </label>
                            <!-- <input id="statusWiseJoiningDate" type="date" class="form-control"> -->


                            <div class="input-group flatpickr" id="statusWiseJoiningDates" data-wrap="true">
                                <input type="text" class="form-control" id="statusWiseJoiningDate" data-input />
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
                <hr />
                <div class="text-center btnInterview">
                    <button type="button" data-dismiss="modal" class="btn btn-outline dark">Close</button>
                    <button class="btn blue" (click)="UpsertInterviewDetailForFeedback('Shortlisted')"
                        [disabled]="isFinal">Save</button>
                    <button class="btn btn-outline blue" (click)="UpsertInterviewDetailForFeedback('Selected')"
                        [disabled]="!isFinal">Selected</button>
                    <button class="btn btn-outline blue" (click)="UpsertInterviewDetailForFeedback('Rejected')"
                        [disabled]="!isFinal">Rejected</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="StatusSendMail" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:4% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Send Mail</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label">To</label>
                            <input type="text" id="statuswiseto" class="form-control" autocomplete="off"
                                placeholder="To">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label">Cc</label>
                            <input type="text" id="statuswisecc" class="form-control" autocomplete="off"
                                placeholder="Cc">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label">Bcc</label>
                            <input type="text" id="statuswisebcc" class="form-control" autocomplete="off"
                                placeholder="Bcc">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label">Subject</label>
                            <input type="text" id="statuswisesubject" class="form-control" autocomplete="off"
                                placeholder="Subject">
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="roleDisplay">
                        <div class="form-group">
                            <label class="control-label">Role</label>
                            <!-- <select id="roleId" class="form-control">
                                <option value="">--- Select ---</option>
                                <option *ngFor="let item of roleList" value="{{item.id}}">{{item.name}}</option>
                            </select> -->

                            <ng-select2 [data]="roleList" id="roleId" [options]="options"
                                (valueChanged)="setData($event)" [value]="roleId" [placeholder]="'--- Select ---'">
                            </ng-select2>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <div id="statuswiseemailsend"></div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="fileinput fileinput-new" data-provides="">
                            <div class="input-group input-large docinbox">
                                <div class="form-control uneditable-input input-fixed input-medium"
                                    data-trigger="fileinput">
                                    <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                    <span class="fileinput-filename"></span>
                                    <span class="fileinput-name">{{documentName}}</span>
                                </div>
                                <span class="input-group-addon btn default btn-file">
                                    <span class="fileinput-new"> Select file </span>
                                    <span class="fileinput-exists"> Change </span>
                                    <input type="file" id="files" name="files[]" (change)="fileChange($event)"
                                        multiple="multiple">
                                </span>
                                <a id="remove" class="input-group-addon btn red fileinput-exists"
                                    data-dismiss="fileinput"> Remove </a>
                            </div>
                            <br>
                            <strong>Note :- </strong> <label> Press Ctrl to select multiple files.</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button type="button" data-dismiss="modal" class="btn blue btn-outline"
                    (click)="sendMail()">Send</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade draggable-modal" id="modalstatusMovetoOnboarding" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Move To Onboarding</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <table class="table">

                            <thead>
                                <tr>
                                    <th>Vacancy Name</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of candidateVacancyList">
                                    <td>{{item.desig_Name}}</td>
                                    <td>{{item.status}}</td>
                                    <td>
                                        <input type="radio" name="mto" value="{{item.vacancy_Id}}"
                                            [checked]="item.vacancy_Id == vacancy_Id "
                                            (click)="getReportingEmpList(item.company_Id,item.dept_Id,item.desig_Id,item.salaryFrom,item.salaryTo,item.currency_Code,item.salaryType,item.vacancy_Id)">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Salary
                                <!-- <span class="required"> *</span> -->
                            </label>
                            <div class="input-group">
                                <input type="number" min="0" step="0.01" id="statusSelectionSalary"
                                    class="form-control" autocomplete="off" maxlength="50"
                                    placeholder="{{salaryPlaceHolder}}">
                                <span class="input-group-addon inputaddon">{{currency_Code}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Reporting
                                <!-- <span class="required"> *</span>  -->
                            </label>
                            <select class="form-control" id="statusSelectionReporting">
                                <option value="">--- Select ---</option>
                                <option *ngFor="let emp of reportingEmpList;" value={{emp.emp_Id}}>
                                    {{emp.firstName}} {{emp.lastName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Planned Start Date
                                <!-- <span class="required"> *</span> -->
                            </label>

                            <div class="input-group flatpickr" id="statusSelectionJoiningDates" data-wrap="true">
                                <input type="text" class="form-control" id="statusSelectionJoiningDate" data-input />
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
                <button class="btn blue" type="button" (click)="directMoveToOnboarding()">Save</button>
            </div>
        </div>
    </div>
</div>