<form [formGroup]="empform">
    <div class="form-group">
        <div class="table-responsive">
            <table class="table table-hover" id="work">
                <thead>
                    <tr>
                        <th style="width: 15%;">Probation Period <span class="required" *ngIf="this.empform.get('emp_Probation').value[0].ProbationPeriod != '' || this.empform.get('emp_Probation').value.length > 1"> * </span></th>
                        <th style="width: 20%;">Probation End Date <span class="required" *ngIf="this.empform.get('emp_Probation').value[0].ProbationPeriod != '' || this.empform.get('emp_Probation').value.length > 1"> * </span></th>
                        <th style="width: 20%;">Next Review Date</th>
                        <th style="width: 10%;">Status</th>
                        <th style="width: 30%;">Notes</th>
                        <th style="width: 2%;">Active</th>
                        <th style="width: 5%;">
                            <!-- <button
                                *ngIf="this.empform.get('emp_Probation').value[0].ProbationPeriod != ''" type="button"
                                (click)="addItem()" class="btn pull-right"><i class="fa fa-plus"
                                    aria-hidden="true"></i></button> -->
                        </th>
                    </tr>
                </thead>
                <tbody formArrayName="emp_Probation" *ngFor="let item of empform.get('emp_Probation')['controls']; let i = index;">
                    <tr [formGroupName]="i">
                        <td>
                            <div class="input-group">
                                <select class="form-control" id="ProbationPeriod{{i}}" formControlName="ProbationPeriod" (change)="updateprob($event)">
                                    <option value="">Select Probation</option>
                                    <option *ngFor="let item1 of probationList; let j = index"
                                        value="{{item1.prob_Duration}} {{item1.prob_DurationUnit}}">
                                        {{item1.prob_Duration}} {{item1.prob_DurationUnit}}</option>
                                </select>
                                <span class="input-group-btn">
                                    <button class="btn dark btn-outline input-group-btn-input-grp" type="button" (click)="modalOpen =true">
                                       <i class="fa fa-{{this.empform.get('emp_Probation').value[0].ProbationPeriod != '' ? 'edit' : 'plus'}}"></i>
                                   </button>
                                </span>
                            </div>
                        </td>
                        <td>
                            <div class="input-group" id="flatpickropenProEndD{{i}}" data-wrap="true">
                                <input type="text" class="form-control readonlyClass" id="ProbationEndDate{{i}}" autocomplete="off" data-input />
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </td>
                        <td>
                            <div class="input-group" id="flatpickropenNextRevD{{i}}" data-wrap="true">
                                <input type="text" class="form-control readonlyClass" id="NextReviewDate{{i}}" data-input>
                                <span class="input-group-btn" data-toggle>
                                    <button class="btn default" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </span>
                            </div>
                        </td>
                        <td>
                            <select id="Status{{i}}" class="form-control" formControlName="Status">
                                <option value="null">Select Status</option>
                                <option value="On probation">On probation</option>
                                <option value="Probation frozen">Probation frozen</option>
                                <option value="Probation extended">Probation extended</option>
                                <option value="Passed probation">Passed probation</option>
                                <option value="Failed probation">Failed probation</option>
                            </select>
                        </td>
                        <td>
                            <textarea class="form-control" id="Notes{{i}}" formControlName="Notes"></textarea>
                        </td> 
                        <td class="text-center">
                            <div class="md-checkbox-inline">
                                <div class="md-checkbox">
                                    <input type="checkbox" id="{{i}}" class="md-check" formControlName="isActive">
                                    <label for="{{i}}">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span> </label>
                                </div>
                            </div>
                        </td>
                        <td>
                            <button *ngIf="i>0" type="button" (click)="removeItem(i)" class="btn pull-right"><i
                                    class="fa fa-minus" aria-hidden="true"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</form>

<div *ngIf="modalOpen" class="row">
    <div class="col-lg-12">
        <app-probation-form [pId]="this.empform.get('emp_Probation').value[0].ProbationPeriod_Id" (modalClose)="modalOpen = $event" (refreshPage)="getProbationList($event)"></app-probation-form>
    </div>

</div>