<style>
    div#ad_search {
        position: relative;
        padding-top: 20px;
    }

    .btn {
        width: 120px !important;
        margin-right: 10px;
    }
</style>
<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-head">
            <div class="page-title">
                <h1>New Employee
                </h1>
            </div>
        </div>
        <ul class="page-breadcrumb breadcrumb mb20">
            <!-- <li>
                <a routerLink="/dashboard"><i class="fa fa-tachometer"></i> Dashboard</a>
            </li> -->
        </ul>
        <!-- END PAGE BREADCRUMB -->
        <!-- BEGIN PAGE BASE CONTENT -->
        <div class="col-md-12">
            <div class="portlet light bordered" id="form_wizard_1">
                <div class="portlet-title">
                    <div class="caption">
                        <i class="fa fa-building font-red"></i>
                        <span class="caption-subject font-red bold uppercase"> ADD EMPLOYEE -
                            <span class="step-title"> STEP {{activeWizard}} OF 12 </span>
                        </span>
                    </div>
                    <div class="caption pull-right">
                        <a routerLink="/employee"><i class="fa fas fa-reply" data-toggle="tooltip" data-placement="top"
                                title="Go Back"></i></a>
                    </div>
                </div>
                <div class="portlet-body form">
                    <form class="form-horizontal" action="#" id="submit_form" method="POST">
                        <div class="form-wizard">
                            <div class="form-body">
                                <ul class="nav nav-pills nav-justified steps table-responsive">
                                    <li [class]="w1" (click)="opentab(1)">
                                        <a class="step">
                                            <span class="number"> 1 </span><br />
                                            <span class="desc">
                                                <!-- <i class="fa fa-check"></i>  -->
                                                Personal Details </span>
                                        </a>
                                    </li>
                                    <li [class]="w2" (click)="opentab(2)">
                                        <a class="step active">
                                            <span class="number"> 2 </span><br />
                                            <span class="desc">
                                                <!-- <i class="fa fa-check"></i>  -->
                                                Address Details </span>
                                        </a>
                                    </li>
                                    <li [class]="w3" (click)="opentab(3)">
                                        <a class="step active">
                                            <span class="number"> 3 </span><br />
                                            <span class="desc">
                                                Current Job Details </span>
                                        </a>
                                    </li>
                                    <!-- <li [class]="w4" (click)="opentab(4)">
                                        <a class="step active">
                                            <span class="number"> 4 </span><br />
                                            <span class="desc">
                                                Personal Contact Details </span>
                                        </a>
                                    </li> -->
                                    <li [class]="w5" (click)="opentab(5)">
                                        <a class="step active">
                                            <span class="number"> 4 </span><br />
                                            <span class="desc">
                                                Emergency Contact </span>
                                        </a>
                                    </li>
                                    <li [class]="w6" (click)="opentab(6)">
                                        <a class="step active">
                                            <span class="number"> 5 </span><br />
                                            <span class="desc">
                                                Work Reference </span>
                                        </a>
                                    </li>
                                    <li [class]="w7" (click)="opentab(7)">
                                        <a class="step active">
                                            <span class="number"> 6 </span><br />
                                            <span class="desc">
                                                Payroll Details </span>
                                        </a>
                                    </li>
                                    <li [class]="w8" (click)="opentab(8)">
                                        <a class="step active">
                                            <span class="number"> 7 </span><br />
                                            <span class="desc">
                                                Probation </span>
                                        </a>
                                    </li>
                                    <li [class]="w9" (click)="opentab(9)">
                                        <a class="step active">
                                            <span class="number"> 8 </span><br />
                                            <span class="desc">
                                                Career History </span>
                                        </a>
                                    </li>
                                    <li [class]="w10" (click)="opentab(10)">
                                        <a class="step active">
                                            <span class="number"> 9 </span><br />
                                            <span class="desc">
                                                Right To Work </span>
                                        </a>
                                    </li>
                                    <li [class]="w11" (click)="opentab(11)">
                                        <a class="step active">
                                            <span class="number"> 10 </span><br />
                                            <span class="desc">
                                                Salary </span>
                                        </a>
                                    </li>
                                    <li [class]="w12" (click)="opentab(12)">
                                        <a class="step active">
                                            <span class="number"> 11 </span><br />
                                            <span class="desc">
                                                Additional Document </span>
                                        </a>
                                    </li>
                                    <li [class]="w13" (click)="opentab(13)">
                                        <a class="step active">
                                            <span class="number"> 12 </span><br />
                                            <span class="desc">
                                                Equality and Diversity Monitoring </span>
                                        </a>
                                    </li>
                                </ul>
                                <div id="bar" class="progress progress-striped" role="progressbar">
                                    <div class="progress-bar progress-bar-success" id="progress-bar"
                                        [style.width]="progressBarWidth"> </div>
                                </div>
                                <div class="tab-content">
                                    <!-- <div class="alert alert-danger display-none">
                                        <button class="close" data-dismiss="alert"></button> You have some form errors.
                                        Please check below. </div>
                                    <div class="alert alert-success display-none">
                                        <button class="close" data-dismiss="alert"></button> Your form validation is
                                        successful! </div> -->
                                    <div [class]="t1" id="tab1">
                                        <app-emppersonalinfo></app-emppersonalinfo>
                                    </div>
                                    <div [class]="t2" id="tab2">
                                        <app-empaddressinfo></app-empaddressinfo>
                                    </div>
                                    <div [class]="t3" id="tab3">
                                        <app-empjobdetail></app-empjobdetail>
                                    </div>
                                    <!-- <div [class]="t4" id="tab4">
                                        <app-empcontact></app-empcontact>
                                    </div> -->
                                    <div [class]="t5" id="tab5">
                                        <app-empEmrContact ></app-empEmrContact>
                                    </div>
                                    <div [class]="t6" id="tab6">
                                        <app-empReference></app-empReference>
                                    </div>
                                    <div [class]="t7" id="tab7">
                                        <app-empbankinfo></app-empbankinfo>
                                    </div>
                                    <div [class]="t8" id="tab8">
                                        <app-empProbation></app-empProbation>
                                    </div>
                                    <div [class]="t9" id="tab9">
                                        <app-empContract [inputFromParent]="companyid" [inputFromParentJT]="jobtitlename"></app-empContract>
                                    </div>
                                    <div [class]="t10" id="tab10">
                                        <app-emprightwork></app-emprightwork>
                                    </div>
                                    <div [class]="t11" id="tab11">
                                        <app-empSalary></app-empSalary>
                                    </div>
                                    <div [class]="t12" id="tab12">
                                        <app-empDocument></app-empDocument>
                                    </div>
                                    <div [class]="t13" id="tab13">
                                        <app-candidatepersonequality></app-candidatepersonequality>
                                    </div>
                                </div>
                            </div>
                            <div class="form-actions">
                                <div class="row">
                                    <!-- <div class="col-md-offset-4 col-md-8">
                                        <a href="javascript:;" class="btn default button-previous">
                                            <i class="fa fa-angle-left"></i> Back </a>
                                        <a href="javascript:;" (click) ="companyUpsert()" class="btn btn-outline blue button-next"> Continue
                                            <i class="fa fa-angle-right"></i>
                                        </a>
                                        <a href="javascript:;" class="btn blue button-submit"> Submit
                                            <i class="fa fa-check"></i>
                                        </a>
                                    </div> -->
                                    <div class="col-md-12 text-right">
                                        <a data-toggle="modal" href="#filterModal" class="btn btn-outline blue"
                                            [style.visibility]="personSearchbutton">
                                            Person Search</a>

                                        <a href="javascript:;" (click)="wizardSetting('back')" class="btn default"
                                            *ngIf="backbutton">
                                            <i class="fa fa-angle-left"></i> Back </a>

                                        <a href="javascript:;" *ngIf="activeWizard >= 3 && activeWizard != 13 "
                                            (click)="employeeUpsert(true)" class="btn blue">Save & Exit</a>

                                        <a href="javascript:;" *ngIf="activeWizard == 13 "
                                            (click)="employeeUpsert(true)" class="btn blue">Save
                                        </a>

                                        <a href="javascript:;" (click)="employeeUpsert()" *ngIf="activeWizard != 13 "
                                            class="btn blue">{{btnName}} <i class="fa fa-angle-right"></i>
                                        </a>
                                    </div>

                                    <div class="col-md-12 text-right" [style.visibility]="personSearchList">
                                        <div id="ad_search">
                                            <div class="table-responsive">
                                                <table class="table table-striped table-bordered table-hover"
                                                    style="width: 75%;margin: 0 auto;">
                                                    <thead class="flip-content">
                                                        <tr>
                                                            <th class="text-center" style="width: 25%;">Fisrt Name</th>
                                                            <th class="text-center" style="width: 25%;">Last Name</th>
                                                            <th class="text-center" style="width: 25%;">Gender</th>
                                                            <th class="text-center" style="width: 25%;">Date of Birth
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of searchPersonList"
                                                            (click)="setPersonValue(item.id)">
                                                            <td class="text-center">
                                                                {{item.firstName}}
                                                            </td>
                                                            <td class="text-center">
                                                                {{item.lastName}}
                                                            </td>
                                                            <td class="text-center">
                                                                {{item.gender_Name}}
                                                            </td>
                                                            <td class="text-center">
                                                                {{item.dob | date : "dd/MM/yyyy"}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- END PAGE BASE CONTENT -->

    </div>
    <!-- END CONTENT BODY -->
</div>
<div class="modal fade draggable-modal" id="filterModal" tabindex="-1" role="basic" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="margin:5% auto !important">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Person Search</h4>
            </div>
            <div class="modal-body">
                <app-personAdvancedSearch></app-personAdvancedSearch>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn blue" (click)="advanceFilter()">Search</button>
            </div>
        </div>
    </div>
</div>