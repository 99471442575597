import { Component, OnInit,ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html'  
})
export class LanguageComponent implements OnInit {  

  @ViewChild(HistoryComponent,{static:false}) historyView : HistoryComponent;
  languageform: FormGroup;
  languagelist: any;
  filterList:any;
  listcall: boolean = true;
  title: string;
  p: number = 1;
  totalRecord: 0;

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.title = "Add";
    this.languageform = this.fb.group({
      id: ['0'],      
      languages: ['', Validators.required],      
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: [Commonvariable.User_Id]
    });
    
    if (this.listcall){
      this.getLanguageList();      
    }  

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);
      
  }

  getLanguageList(PageIndex = 1) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.languagelist = [];
    this.filterList = [];
    this.commonApiService.getListWithFilter(Commonvariable.Languages, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.languagelist = result["data"]["list"];
        this.filterList = result["data"]["list"];
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })
  }


  Upsert() {
    var Form = this.languageform;

    if (this.validation()) {
      Form.get("isActive").setValue(Form.get("isActive").value ? 1 : 0);
      this.commonApiService.Upsert("Languages", Form.value, Form.controls.id.value).subscribe((response) => {
        if (response != null) {
          if (response["message"] == Commonvariable.DuplicationMsg) {
            this.toastr.warning('Record is already exists !', 'Language');
          }
          else {
            $("#draggable").modal('hide');
            this.getLanguageList();
            this.toastr.success(Form.controls.id.value > 0 ? Commonvariable.UpdateMsg : Commonvariable.SaveMsg, 'Language');
          }
        }
      })
    }

  }

  Delete(Id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete("Languages", Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getLanguageList();
          this.toastr.success(response["message"], 'Language');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Languages, Id, status).subscribe((response) => {
          
          if (response["status"] == 200) {
            this.getLanguageList();
            this.toastr.success(response["message"], 'Language');                        
          }

        })
      }

    });
  }

  openModel(Id) {
    if (Id > 0) {
      this.title = "Edit";
      this.commonApiService.getById("Languages", Id).subscribe((response) => {
        if (response["data"] != "") {
          var editData = response["data"];
          var Form = this.languageform;
          this.commonService.setEditData(Form, editData);                    
        }
      })
    }
    else {      
      this.listcall = false;
      this.ngOnInit();
    }
    $('#draggable').modal({
      backdrop: 'static'
    });


  }




  validation() {
    var Form = this.languageform;
    if (!Form.valid) {
      for (const key of Object.keys(Form.controls)) {
        if (Form.controls[key].invalid) {
          let fieldName = "";
          
          if (key == "languages") {
            fieldName = "languages"
          }            
          this.toastr.info(fieldName + " is required !", "Language");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {     
      if ($("#languages").val().length < 2) {
        this.toastr.info("Language must be at least 2 characters long !", "Language");
        $("#language").focus();
        return false;
      }     
      
      if ($("#languages").val().search(/[^a-z A-Z()-,/]+/) > -1) {
         this.toastr.info("Language only alphabets allow !", "Language");
         $("#language").focus();
         return false;
      }     

    }

    return true;
  }

  history() {
    this.historyView.getHistory("languages",this.languageform.controls.id.value)
  }

}
