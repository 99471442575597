import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { HistoryComponent } from 'src/app/pages/history/history.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Select2OptionData } from 'ng-select2';
declare var $: any;


@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html'
})
export class ContractComponent implements OnInit {
  @ViewChild(HistoryComponent, { static: false }) historyView: HistoryComponent;
  contractform: FormGroup;
  contractlist: any;
  filterlist: any;
  listcall: boolean = true;
  companylist: Array<Select2OptionData>;
  title: string;
  p: number = 1;
  totalRecord: 0;

  modalOpen: boolean = false
  id: number = 0

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {


  }
  ngOnInit() {
    this.title = "Add";
    
    if (this.listcall) {
      this.getContractList();
      this.getCompanyList();
    }

    // this.commonService.select2SingleDropDown("companySelectListClass");
    // this.commonService.select2SingleDropDown("contractTypeSelectListClass");

  }


  getContractList(PageIndex = 1) {

    this.p = PageIndex;
    this.totalRecord = 0;
    this.contractlist = [];
    this.commonApiService.getListWithFilter(Commonvariable.Contract, PageIndex, $("#filterList").val()).subscribe((result) => {
      if (result != null && result["data"] != "") {
        this.totalRecord = result["data"]["recordCount"]
        this.contractlist = result["data"]["list"];
        this.filterlist = result["data"]["list"];

        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 500);

      }
    })
  }

  getCompanyList() {
    var requestData = {
      "searchBy": [
        {
          "FieldName": "isActive",
          "FieldValue": "1",
          "Parameter": "=="
        }
      ],
      "OrderBy": "company_Name"
    }
    this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((response) => {
      if (response["data"] != "") {
        this.companylist = response["data"].map(x => ({
          id: x.company_Id,
          text: x.company_Name
        }))
      }
    })
  }

  setFormData(event) {
    this.contractform.controls.company_Id.setValue(event)
  }



  contractDelete(Id) {

    this.commonService.deleteConfirm().then((result) => {
      if (result.value) {
        this.commonApiService.Delete(Commonvariable.Contract, Id).subscribe((response) => {
          $("#draggable").modal('hide');
          this.getContractList();
          this.toastr.success(response["message"], 'Contract');
          this.p = 1;
        })
      }
    })
  }

  updateStatus(Id, status = null) {
    this.commonService.dyanmicConfirm("You want update status !", "Yes !").then((result) => {
      if (result.value) {
        this.commonApiService.commonUpdateStatus(Commonvariable.Contract, Id, status).subscribe((response) => {

          if (response["status"] == 200) {
            this.getContractList();
            this.toastr.success(response["message"], 'Contract');
          }

        })
      }

    });
  }



}
