import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
import { _ } from 'ag-grid-community';
import { Select2OptionData } from 'ng-select2';
declare var $: any;
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
   selector: 'app-candidatejobdetail',
   templateUrl: './candidate-job-detail.component.html',
})
export class CandidateJobDetailsComponent implements OnInit {
   currency_Code: any;
   companyList: any;
   siteList: any;
   departmentList: any;
   zoneList: any;
   JDList: any;
   shiftList: any;
   designationList: any;
   esiteList: any;
   edepartmentList: any;
   ezoneList: any;
   eJDList: any;
   eshiftList: any;
   edesignationList: any;
   eContractlist: any;
   contractlist: any;
   eProbationList: any;
   rafList: any;
   probationList: any
   Email: any;
   reportingEmpList: any;
   editMode: boolean = false;
   countryList: Array<Select2OptionData>;
   candidateJobDetailform: FormGroup;
   fileUrl;
   constructor(
      private commonApiService: CommonApiService,
      private toastr: ToastrService,
      private commonService: CommonService,
      private sanitizer: DomSanitizer,
      private fb: FormBuilder
   ) {
   }

   ngOnInit() {
      this.commonService.timePicker("flatpickropenshiftstatetime,#flatpickropenshiftendtime", 0)
      this.candidateJobDetailform = this.fb.group({
         Id: ['0'],
         person_Id: ['0'],
         company_Id: ['', Validators.required],
         Site_Id: ['', Validators.required],
         Vacancy_Id: [''],
         Dept_Id: ['', Validators.required],
         Zone_Id: [null, Validators.required],
         Desig_Id: ['', Validators.required],
         JD_Id: [null],
         Shift_Id: [''],
         shift_Start: ['', Validators.required],
         shift_End: ['', Validators.required],
         nightShift: [''],
         shift_Variable:[''],
         FirstName: [''],
         MiddleName: [''],
         LastName: [''],
         JoiningDate: ['', Validators.required],
         Reporting_Id: ['', Validators.required],
         EMail_Id: 0,
         EMail: ['', Validators.required],
         country_Id: [null],
         Mobile_Id: 0,
         Mobile: [null],
         TotalExperience: [null],
         ReferenceFrom: [null],
         Tupe: [''],
         Emp_Code: ['', Validators.required],
         NiNo: [''],
         isActive: ['1'],
         ProbationPeriod: [''],
         AddedBy: [Commonvariable.User_Id],
         updatedBy: [Commonvariable.User_Id],
         onboarding_basicinfo: this.fb.group,
         person: this.fb.group,
         onboarding_address: this.fb.array,
         onboarding_bank: this.fb.array,
         onboarding_contact: this.fb.array,
         onboarding_contract: this.fb.array,
         onboarding_document: this.fb.array,
         onboarding_emergency: this.fb.array,
         onboarding_probation: this.fb.array,
         onboarding_reference: this.fb.array,
         onboarding_righttowork: this.fb.array,
         onboarding_salary: this.fb.array,
      });
      //this.commonService.flatpickrForDOJ("flatpickropenJoiningD", 0);
      //change by Amit Prajapati reference of Terence Gracias
      this.commonService.flatpickr("flatpickropenJoiningD", 0);
      this.getCompany();
      this.getCountryList();
   }

   genrateEmployeeCode(companyId) {
      if (companyId > 0) {
         this.commonApiService.getById(Commonvariable.Employee, companyId, "AutoGenerateEmployeeCode").subscribe((result) => {
            if (result["data"] != "") {
               this.candidateJobDetailform.controls.Emp_Code.setValue(result["data"])
            }
         })
      }
   }

   checkEmpCode() {
      let id = this.candidateJobDetailform.controls.Emp_Code.value + "," + this.candidateJobDetailform.controls.company_Id.value

      this.commonApiService.getById("Employee", id, "EmpCodeExists").subscribe((result) => {
      }, (error) => { this.genrateEmployeeCode(this.candidateJobDetailform.controls.company_Id.value) })
   }



   getCountryList() {

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "country_Name"
      }
      this.commonApiService.GetByPaginated(Commonvariable.Country, requestData).subscribe((response) => {
         if (response != null && response["data"] != "") {
            this.countryList = response["data"].map(x => ({
               id: x.id,
               text: x.country_Name + " (" + x.country_Code + ") "
            }));

            this.candidateJobDetailform.controls.country_Id.setValue(response["data"].filter(x => x.isDefault == 1)[0]["id"]);
         }
      })
   }

   getCompany() {

      if (this.companyList == null) {
         this.companyList = [];
      }

      var requestData = {
         "searchBy": [
            {
               "FieldName": "isActive",
               "FieldValue": "1",
               "Parameter": "=="
            }
         ],
         "OrderBy": "company_Name"
      }

      if (this.companyList.length == 0) {
         this.commonApiService.GetByPaginated(Commonvariable.Company, requestData).subscribe((result) => {
            if (result != null && result["data"] != "") {
               this.companyList = result["data"].filter(x => x.isActive != 0);
            }
            //this.getSite();
         })
      }
   }


   companyWiseGetData(drpCall = false) {
      this.siteList = []
      this.departmentList = []
      this.zoneList = []
      this.JDList = []
      this.designationList = []
      this.shiftList = []

      if (drpCall) {
         this.candidateJobDetailform.controls.Site_Id.setValue('')
         this.candidateJobDetailform.controls.Dept_Id.setValue('')
         this.candidateJobDetailform.controls.Zone_Id.setValue(null)
         this.candidateJobDetailform.controls.JD_Id.setValue(null)
         this.candidateJobDetailform.controls.Desig_Id.setValue('')
         this.candidateJobDetailform.controls.Shift_Id.setValue('')
         this.candidateJobDetailform.controls.Vacancy_Id.setValue('')
         this.candidateJobDetailform.controls.Reporting_Id.setValue('')
         this.genrateEmployeeCode(this.candidateJobDetailform.controls.company_Id.value)
      }

      if (this.candidateJobDetailform.controls.company_Id.value != "") {
         var requestData = {
            "searchBy": [
               {
                  "FieldName": "Company_Id",
                  "FieldValue": this.candidateJobDetailform.controls.company_Id.value,
                  "Parameter": "==",
                  "ConditionWith": "&&"
               },
               {
                  "FieldName": "isActive",
                  "FieldValue": "1",
                  "Parameter": "=="
               }
            ]
         }

         this.commonApiService.getPaginated(Commonvariable.CompanyDetail, null, null, null, null, null, requestData, "GetByCompany_IdPaginated").subscribe((result) => {
            this.currency_Code = result["data"][0]["currency_Code"]
            this.siteList = result["data"][0]["site"]
            this.departmentList = result["data"][0]["department"]
            this.zoneList = result["data"][0]["zone"]
            //this.JDList = result["data"][0]["job_Descriptions"]
            //this.designationList = result["data"][0]["designations"]
            this.shiftList = result["data"][0]["shift"]
            this.contractlist = result["data"][0]["contract"]
            this.probationList = result["data"][0]["probation"]
            this.getJobTitle();

            // this.commonApiService.empDetailList(Commonvariable.EmployeeDetail, requestData).subscribe((result) => {
            //    if (result != null && result["data"] != "") {
            //      this.reportingEmpList = result["data"];
            //    }
            //  })
         })
      }
   }

   getReportingList() {

      let formData = this.candidateJobDetailform.controls

      let parameter = "Reporting" + "," + formData.company_Id.value + "," + formData.Dept_Id.value + "," + formData.Desig_Id.value + "," + 0

      this.commonApiService.getById(Commonvariable.EmployeeDetail, parameter, "GetEmployeeByAccessibility").subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.reportingEmpList = result["data"];
         }
      })
   }


   getJobTitle() {

      this.designationList = [];


      if (this.candidateJobDetailform.controls.company_Id.value != "" && this.candidateJobDetailform.controls.Dept_Id.value != "") {


         var requestData = {
            "searchBy": [
               {
                  "FieldName": "isActive",
                  "FieldValue": "1",
                  "Parameter": "==",
                  "ConditionWith": "&&"
               },
               {
                  "FieldName": "company_Id",
                  "FieldValue": this.candidateJobDetailform.controls.company_Id.value,
                  "Parameter": "==",
                  "ConditionWith": "&&"
               },
               {
                  "FieldName": "dept_Id",
                  "FieldValue": this.candidateJobDetailform.controls.Dept_Id.value,
                  "Parameter": "==",
               }
            ],
            "OrderBy": "desig_Name"
         }

         requestData.OrderBy = "desig_Name"
         this.commonApiService.GetByPaginated(Commonvariable.Designation, requestData).subscribe((response) => {
            if (response["data"] != "") {
               this.designationList = response["data"];
            }
         })

      }

   }



   async getRafList(id) {
      this.rafList = [];
      let postRequest = {
         "searchBy": [
            {
               "FieldName": "site_Id",
               "FieldValue": id,
               "Parameter": "==",
               "ConditionWith": "&&",
            },
            {
               "FieldName": "open",
               "FieldValue": 0,
               "Parameter": ">"
            }
         ],
         "PageSize": 1000
      }

      if (id > 0) {
         await this.commonApiService.getPaginated(Commonvariable.VacancyService, null, null, null, null, 0, postRequest, 'RAFWiseGetPaginated').subscribe((result) => {
            if (result != null && result["data"] != "") {
               this.rafList = result["data"]["list"];
            }
         })
      }

   }



   getJobDescriptionList(id) {
      this.JDList = [];
      let postRequest = {
         "searchBy": [
            {
               "FieldName": "desig_Id",
               "FieldValue": id,
               "Parameter": "==",
               "ConditionWith": "&&",
            },
            {
               "FieldName": "isActive",
               "FieldValue": 1,
               "Parameter": "=="
            }
         ]
      }

      if (id > 0) {
         this.commonApiService.getPaginated(Commonvariable.JobDescription, null, null, null, null, 0, postRequest, 'GetByPaginated').subscribe((result) => {
            if (result != null && result["data"] != "") {
               this.JDList = result["data"];
            }
         })
      }
   }



   getSite() {
      this.esiteList = [];
      this.commonApiService.List(Commonvariable.Site).subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.esiteList = result["data"].filter(x => x.isActive != 0);
         }
         this.getDepartment();
      })

   }


   getDepartment() {
      this.edepartmentList = [];
      this.commonApiService.List(Commonvariable.Department).subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.edepartmentList = result["data"].filter(x => x.isActive != 0);
         }
         this.getZone();
      })
   }
   getZone() {
      this.ezoneList = [];
      this.commonApiService.List(Commonvariable.Zone).subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.ezoneList = result["data"].sort((a, b) => a.zone_Name.localeCompare(b.zone_Name));
            this.zoneList = result["data"].sort((a, b) => a.zone_Name.localeCompare(b.zone_Name));
         }
         this.getJD();
      })
   }

   getJD() {
      this.eJDList = [];
      this.commonApiService.List(Commonvariable.JobDescription).subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.eJDList = result["data"].filter(x => x.isActive != 0);
         }
         this.getDesignation();
      })
   }

   getDesignation() {
      this.edesignationList = [];
      this.commonApiService.List(Commonvariable.Designation).subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.edesignationList = result["data"].filter(x => x.isActive != 0);
         }
         this.getShift();
      })
   }

   getShift() {
      this.eshiftList = [];
      this.commonApiService.List(Commonvariable.Shift).subscribe((result) => {
         if (result != null && result["data"] != "") {
            this.eshiftList = result["data"].filter(x => x.isActive != 0);
         }
         this.getContractList();
      })
   }

   getContractList() {

      this.eContractlist = [];
      this.commonApiService.List(Commonvariable.Contract).subscribe((response) => {
         if (response["data"] != "") {
            this.eContractlist = response["data"].filter(x => x.isActive != 0);
         }
         this.getProbationList();
      })
   }



   getProbationList() {
      this.eProbationList = [];
      this.commonApiService.List(Commonvariable.Probation).subscribe((response) => {
         if (response["data"] != "") {
            this.eProbationList = response["data"].filter(x => x.isActive != 0);
         }
      })
   }

   casCadingDropdwon() {

      if (this.esiteList != null && this.edepartmentList != null && this.edesignationList != null && this.eshiftList != null) {
         this.siteList = this.esiteList.filter(x => x.company_Id == this.candidateJobDetailform.controls.company_Id.value).sort((a, b) => a.site_Name.localeCompare(b.site_Name));
         this.departmentList = this.edepartmentList.filter(x => x.company_Id == this.candidateJobDetailform.controls.company_Id.value).sort((a, b) => a.dept_Name.localeCompare(b.dept_Name));
         this.zoneList = parseInt(this.candidateJobDetailform.controls.company_Id.value) > 0 ? this.ezoneList.filter(x => x.company_Id == this.candidateJobDetailform.controls.company_Id.value || x.company_Id == null).sort((a, b) => a.zone_Name.localeCompare(b.zone_Name)) : this.ezoneList
         this.JDList = this.eJDList.filter(x => x.company_Id == this.candidateJobDetailform.controls.company_Id.value).sort((a, b) => a.jD_Name.localeCompare(b.jD_Name));
         this.designationList = this.edesignationList.filter(x => x.company_Id == this.candidateJobDetailform.controls.company_Id.value).sort((a, b) => a.desig_Name.localeCompare(b.desig_Name));
         this.shiftList = this.eshiftList.filter(x => x.company_Id == this.candidateJobDetailform.controls.company_Id.value).sort((a, b) => a.shift_Name.localeCompare(b.shift_Name));
         this.contractlist = this.eContractlist.filter(x => x.company_Id == this.candidateJobDetailform.controls.company_Id.value).sort((a, b) => a.contract_Name.localeCompare(b.contract_Name));
         this.probationList = this.eProbationList.filter(x => x.company_Id == this.candidateJobDetailform.controls.company_Id.value).sort((a, b) => a.prob_Duration.localeCompare(b.prob_Duration));
      }
      else {

         setTimeout(() => {
            this.casCadingDropdwon();
         }, 300);

      }
   }



   empUpsert() {

      if (this.validation()) {
         return this.candidateJobDetailform.value;
      }
      return "";
   }


   Edit(Id) {
      
      //this.getCompany();
      this.editMode = true;
      if (Id > 0) {
         this.commonApiService.getById(Commonvariable.Candidate, Id).subscribe((response) => {
            if (response["data"] != "") {
               var editData = response["data"][0];

               setTimeout(() => {
                  this.companyWiseGetData();
               }, 50);

               this.getRafList(editData.site_Id)
               this.getJobDescriptionList(editData.desig_Id)

               $("#Vacancy_Id").attr("disabled", true);
               $("#vacancy_Name").val(editData.vacancy_Name);
               $("#emp_Code").val(editData.emp_Code);



               setTimeout(() => {


                  this.candidateJobDetailform = this.fb.group({
                     Id: [editData.id],
                     person_Id: [editData.person_Id],
                     company_Id: [editData.company_Id != null ? editData.company_Id : "", Validators.required],
                     Site_Id: [editData.site_Id != null ? editData.site_Id : "", Validators.required],
                     Vacancy_Id: [editData.vacancy_Id != null ? editData.vacancy_Id : ""],
                     Dept_Id: [editData.dept_Id != null ? editData.dept_Id : "", Validators.required],
                     Zone_Id: [editData.zone_Id != null ? editData.zone_Id : "", Validators.required],
                     Desig_Id: [editData.desig_Id != null ? editData.desig_Id : "", Validators.required],
                     JD_Id: [editData.jD_Id != "" ? editData.jD_Id : null],
                     Shift_Id: [editData.shift_Id != null ? editData.shift_Id : ""],
                     shift_Start: [editData.shift_Start != null ? editData.shift_Start : "", Validators.required],
                     shift_End: [editData.shift_End != null ? editData.shift_End : "", Validators.required],
                     nightShift: [editData.nightShift != null ? editData.nightShift : 0],
                     shift_Variable: [editData.shift_Variable != null ? editData.shift_Variable : 0],
                     FirstName: [editData.firstName],
                     MiddleName: [editData.middleName],
                     LastName: [editData.lastName],
                     JoiningDate: [editData.joiningDate, Validators.required],
                     EMail_Id: 0,
                     EMail: [editData.eMail, Validators.required],
                     country_Id: [editData.country_Id],
                     Mobile_Id: 0,
                     Mobile: [editData.mobile],
                     TotalExperience: [editData.totalExperience],
                     ReferenceFrom: [editData.referenceFrom],
                     Tupe: [editData.tupe],
                     Emp_Code: [editData.emp_Code, Validators.required],
                     NiNo: [editData.niNo],
                     Reporting_Id: [editData.reporting_Id != null && editData.reporting_Id != 0 ? editData.reporting_Id : "", Validators.required],
                     isActive: ['1'],
                     ProbationPeriod: [editData.probationPeriod != null ? editData.probationPeriod : ""],
                     AddedBy: [Commonvariable.User_Id],
                     updatedBy: [Commonvariable.User_Id]



                  });



                  this.getReportingList();






               });
               let joingDate = response["data"][0].joiningDate != null ? response["data"][0].joiningDate.split("T")[0].split("-") : "";
               let jobStartDate = response["data"][0].jobStartDate != null ? response["data"][0].jobStartDate.split("T")[0].split("-") : "";
               if (joingDate != "") {
                  $("#JoiningDate").val(joingDate[2] + "-" + joingDate[1] + "-" + joingDate[0]);
               }
               if (jobStartDate != "") {
                  $("#JobStartDate").val(jobStartDate[2] + "-" + jobStartDate[1] + "-" + jobStartDate[0]);
               }
               if (editData.shift_Variable == 1) 
               { $('#shift_Variable').prop('checked', 'true');
             }
               else
                { $('#shift_Variable').prop('checked', 'false'); }



               //this.getSite();

            }
         })
      }
   }






   validation() {

      var formData = this.candidateJobDetailform;
      // var shift_End = $("#shift_End").val();
      // var shift_Start = $("#shift_Start").val();
      // formData.controls.shift_Start.setValue(shift_Start);
      // formData.controls.shift_End.setValue(shift_End);
      formData.get("nightShift").setValue(formData.get("nightShift").value ? 1 : 0);
      formData.get("shift_Variable").setValue(formData.get("shift_Variable").value ? 1 : 0);
      
      formData.controls.JoiningDate.setValue(this.commonService.dateFormatChange($("#JoiningDate").val()));

      formData.controls.Zone_Id.setValue($("#Zone_Id").val() == "null" ? null : $("#Zone_Id").val());
      formData.controls.JD_Id.setValue($("#JD_Id").val() == "null" ? null : $("#JD_Id").val());

      // formData.get("isActive").setValue(formData.get("isActive").value ? 1 : 0);
      for (const key of Object.keys(formData.controls)) {
         if (formData.controls[key].invalid) {
            let fieldName = "";
            if (key == "company_Id") {
               fieldName = "Company"
            }
            else if (key == "Site_Id") {
               fieldName = "Site"
            }
            else if (key == "Dept_Id") {
               fieldName = "Department"
            }
            else if (key == "Zone_Id") {
               fieldName = "Zone"
            }
            else if (key == "Desig_Id") {
               fieldName = "Job Title"
            }
            else if (key == "Emp_Code") {
               fieldName = "Employee number"
            }
            // else if (key == "country_Id") {
            //    fieldName = "country"
            // }
            // else if (key == "Shift_Id") {
            //    fieldName = "Shift Time"
            // }
            else if (key == "shift_Start") {
               fieldName = "Shift start"
             }
             else if (key == "shift_End") {
               fieldName = "Shift end"
             }
            else if (key == "JoiningDate") {
               fieldName = "Joining Date"
            }
            else if (key == "EMail") {
               fieldName = "Email"
            }
            else if (key == "Reporting_Id") {
               fieldName = "Holiday approver"
            }

            if (fieldName != "") {
               this.toastr.info(fieldName + " is required !", "Candidate");
               $("#" + key).focus();
               return false;
            }

         }

      }

      // if ($("#JobMobile").val() == "" && this.editMode) {
      //    this.toastr.info("Mobile is required !", "Candidate");
      //    $("#JobMobile").focus();
      //    return false;
      // }

      // if ($("#EMail").val() == "") {
      //    this.toastr.info("EMail id required !", "Candidate");
      //    $("#EMail").focus();
      //    return false;
      // }
      if ($("#shift_Start").val() == '00:00') {
         this.commonService.toaster("Shift Start is required !", "Shift");
         $("#shift_Start").focus();
         return false;
       }
 
      
 
       if ($("#shift_End").val() == '00:00') {
         this.commonService.toaster("Shift End is required !", "Shift");
         $("#shift_End").focus();
         return false;
       }

      if ($("#JobMobile").val() != '' && $("#JobMobile").val() != undefined) {

         if (formData.value.country_Id == null) {
            this.toastr.info("Country is required !", "Candidate");
            $("#country_Id select").focus();
            return false;
         }

         // if ($("#JobMobile").val().length < 9) {
         //    this.toastr.info("Mobile must be at least 9 digit long !", "Candidate");
         //    $("#JobMobile").focus();
         //    return false;
         // }


      }

      if ($("#EMail").val().search(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/) != 0) {
         this.toastr.info("Enter valid email address !", "Candidate");
         $("#EMail").focus();
         return false;
      }

      if ($("#EMail").val().length < 2 && $("#EMail").val() != undefined) {
         this.toastr.info("Email must be at least 2 characters long !", "Candidate");
         $("#EMail").focus();
         return false;
      }



      // if ($("#TotalExperience").val() != "" && this.editMode) {
      //    if ($("#TotalExperience").val().length < 2 && $("#TotalExperience").val() != undefined) {
      //       this.toastr.info("Total experience must be at least 2 characters long !", "Candidate");
      //       $("#TotalExperience").focus();
      //       return false;
      //    }
      // }


      // if ($("#ReferenceFrom").val() != "" && this.editMode) {
      //    if ($("#ReferenceFrom").val().length < 2 && $("#ReferenceFrom").val() != undefined) {
      //       this.toastr.info("Reference from must be at least 2 characters long !", "Candidate");
      //       $("#ReferenceFrom").focus();
      //       return false;
      //    }
      // }


      if ($("#ProbationPeriod").val() != "" && $("#ProbationPeriod").val() != undefined) {
         if ($("#ProbationPeriod").val().search(/[^a-zA-Z0-9 ]+/) > -1 && !this.editMode) {
            this.toastr.info("Probation period only alphabets and numeric value allow !", "Candidate");
            $("#ProbationPeriod").focus();
            return false;
         }
      }

      if ($("#Salary").val() != "" && $("#Salary").val() != undefined) {
         if ($("#Salary").val().search(/[^0-9.]+/) > -1 && !this.editMode) {
            this.toastr.info("Salary only number allow !", "Candidate");
            $("#Salary").focus();
            return false;
         }
      }



      return true;
   }


   searchPersonFill(person) {
      if (person) {
         this.candidateJobDetailform.controls.person_Id.setValue(person.person_Id);
         if (person.person_Contact.length > 0) {
            person.person_Contact.forEach(x => {
               if (x.contact_Type == "Email") {
                  this.candidateJobDetailform.controls.EMail_Id.setValue(x.id);
                  this.candidateJobDetailform.controls.EMail.setValue(x.contact_Value);
               }
               if (x.contact_Type == "Mobile No" || x.contact_Type == "MobileNo") {
                  this.candidateJobDetailform.controls.Mobile_Id.setValue(x.id);
                  this.candidateJobDetailform.controls.Mobile.setValue(x.contact_Value);
                  this.candidateJobDetailform.controls.country_Id.setValue(x.country_Id);
               }

            });
         }
      }

   }


   // filechange(files: FileList)
   // {
   //    this.companyBasicInfoform.controls.documents.setValue(files.item(0));
   // }
   download() {

      if (parseInt(this.candidateJobDetailform.controls.JD_Id.value) > 0) {
         var fileName = String(this.JDList.filter(x => x.jD_Id == this.candidateJobDetailform.controls.JD_Id.value)[0]["path"]).split(".")[1]
         this.commonApiService.getimage(Commonvariable.JobDescription, parseInt(this.candidateJobDetailform.controls.JD_Id.value), "DownloadDocument").subscribe((resp: any) => {
            if (resp.type != "application/json") {
               const blobData = new Blob([resp], { type: resp.type });
               var createObjectURL = (window.URL.createObjectURL(blobData));
               if (resp.type == "application/pdf" || resp.type == "image/jpeg" || resp.type == "image/png") {
                  this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(createObjectURL);
                  $("#modalJobDescription").modal("show")
               } else if (resp.type == "application/vnd.ms-word") {
                  const a = document.createElement('a');
                  a.setAttribute('type', 'hidden');
                  a.href = URL.createObjectURL(blobData);
                  a.download = 'Job_Description.docx';
                  a.click();
                  a.remove();
               } else {

                  const a = document.createElement('a');
                  a.setAttribute('type', 'hidden');
                  a.href = URL.createObjectURL(blobData);
                  a.download = 'Job_Description';
                  a.click();
                  a.remove();
               }
            }
            else {
               this.commonService.toaster("warning", 'Document Not Available', 'Job Description');
            }
         });
      }
      else{
         this.commonService.toaster("warning", 'Not selected.', 'Job Description')
       }
   }
   // onChangeTimeS() {
   //    var Shift_Duration = $("#Shift_Duration").val();
   //    if (Shift_Duration != 0 && Shift_Duration != "") {
   //      var selectedStartTime = moment($("#shift_Start").val(), 'H:m').format("H:m");
   //      var endTime = moment(selectedStartTime, 'H:m').add(Shift_Duration, 'hours').format("H:m");
   //      $("#shift_End").flatpickr({ dateFormat: "H:i", defaultDate: endTime, enableTime: true, noCalendar: true, time_24hr: true });
   //    }
   //    else {
   //      $("#shift_End").flatpickr({ dateFormat: "H:i", defaultDate: "", enableTime: true, noCalendar: true, time_24hr: true });
   //    }
  
   //    var selectedStartShift = moment($("#shift_Start").val(), 'H:mm').format("H");
     
   //  }

}


