import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Commonvariable } from 'src/app/models/commonvariable';
declare var $: any;
import * as moment from 'moment';


@Component({
  selector: 'app-salarycandidate',
  templateUrl: './salary-candidate.component.html'
})
export class SalaryCandidateComponent implements OnInit {
  candidateForm: FormGroup;
  editMode:boolean = false

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }
  ngOnInit() {

    this.candidateForm = this.fb.group({
      id: ['0'],
      company_Id: [null],
      dept_Id: [null],
      desig_Id: [null],
      onboarding_Id: ['0'],
      salary: ['', Validators.required],
      // apprisalFrom: ['', Validators.required],
      // apprisalTo: ['', Validators.required],
      salaryType:['', Validators.required],
      reporting_Id: ['0'],
      isActive: ['1'],
      addedBy: [Commonvariable.User_Id],
      updatedBy: ['0']
    });

    this.editMode = false;
    $("#salaryType").prop("disabled",false)
    // this.commonService.flatpickr("apprisalFrom", 0)
    // this.commonService.flatpickr("apprisalTo", 0)

  }



  Edit(Id) {
    if (Id > 0) {
      this.commonApiService.getById(Commonvariable.Onboarding_Salary, Id).subscribe((response) => {
        if (response != null && response["data"] != "") {
          var editData = response["data"];
          var formData = this.candidateForm;         
          this.commonService.setEditData(formData, editData);
          formData.controls.updatedBy.setValue(Commonvariable.User_Id);
          $("#salaryType").prop("disabled",true)
          this.editMode = true;
        }
      })
    }
  }


  validation() {
    var eForm = this.candidateForm;
    eForm.get("isActive").setValue(eForm.get("isActive").value ? 1 : 0);
    // eForm.controls.apprisalFrom.setValue(this.commonService.dateFormatChange($("#apprisalFrom").val()));
    // eForm.controls.apprisalTo.setValue(this.commonService.dateFormatChange($("#apprisalTo").val()));
    if (!eForm.valid) {
      for (const key of Object.keys(eForm.controls)) {
        if (eForm.controls[key].invalid) {
          let fieldName = "";

          if (key == "salary") {
            fieldName = "Salary"
          }
          else if (key == "salaryType") {
            fieldName = "Salary Type"
          }
          // else if (key == "issueAuthority") {
          //   fieldName = "Issue authority"
          // }
          // else if (key == "apprisalFrom") {
          //   fieldName = "Apprisal from"
          // }
          // else if (key == "apprisalTo") {
          //   fieldName = "Apprisal to"
          // }
          this.toastr.info(fieldName + " is required !", "Candidate");
          $("#" + key).focus();
          return false;
        }
      }
    }
    else {
      if ($("#salary").val().search(/[^0-9.]+/) > -1) {
        this.toastr.info("Salary only number allow !", "Candidate");
        $("#salary").focus();
        return false;
      }
      // if (moment($("#apprisalFrom").val(), 'DD-MM-YYYY') >= moment($("#apprisalTo").val(), 'DD-MM-YYYY')) {
      //   this.toastr.info("Apprisal from not more then apprisal to !", "Candidate");
      //   $("#apprisalFrom").focus();
      //   return false;
      // }
    }
    return true;
  }




}
