import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CommonApiService } from 'src/app/services/commonApi.service';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Commonvariable } from 'src/app/models/commonvariable';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;


@Component({
  selector: 'app-emp-leave',
  templateUrl: './emp-leave.component.html'
})
export class EmpLeaveComponent implements OnInit {
  empform: FormGroup;
  items: FormArray;
  emp_Id: any = 0;
  editEmpid: "";

  constructor(
    private commonService: CommonService,
    private commonApiService: CommonApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder) {
  }
  ngOnInit() {

    this.empform = this.fb.group({
      emp_Leave: this.fb.array([]),
    });

    this.route.queryParamMap.subscribe(params => {
      this.editEmpid = params["params"]["editId"];            
   });

    this.commonService.flatpickr("effectiveDatesign", 0)
  }

  createItem(data = null) {
    return this.fb.group({
      emp_id: data != null ? data.emp_id : '0',
      leave_type_id: data != null ? data.leave_type_id : null,
      leave_Type: data != null ? data.leave_Type : "",
      leave_Type_Code: data != null ? data.leave_Type_Code : "",
      prorated_LeaveBalance: data != null ? data.prorated_LeaveBalance : 0,
      leaveBalanceToUpdate: data != null ? data.balance_leave == 0 ? data.leaveBalanceToUpdate : data.balance_leave : 0,
      balance_leave: data != null ? data.balance_leave : 0
    });
  }

  addLeaveData(id) {
    this.emp_Id = id
    if ($("#effectiveDate").val() == "") {
      this.commonService.toaster("info", "Effective date required !", "Employee Leave")
      $("#effectiveDate").focus();
    }
    else {
      this.commonApiService.getById(Commonvariable.Leave_Balance, id + "," + this.commonService.dateFormatChange($("#effectiveDate").val()), "GetLeaveBalanceProrated").subscribe((result) => {
        this.items = this.empform.get('emp_Leave') as FormArray;
        this.items.clear();
        if (result["data"] != "") {
          result["data"].forEach(x => {
            this.items.push(this.createItem(x));
          });
        }
      })
    }
  }


}
