<style type="text/css">
   #rafTable .iconFontsize {
      font-size: 20px;
      margin-right: 5px;
      cursor: pointer;
   }

   #rafTable2 .iconFontsize {
      font-size: 20px;
      margin-right: 5px;
      cursor: pointer;
   }

   #sample_3_filter {
      float: right;
   }

   #sample_4_filter {
      float: right;
   }

   #sample_5_filter {
      float: right;
   }

   #sample_4_wrapper .row,
   #sample_2_wrapper .row {
      display: none;
   }

   #sample_4_wrapper .table-scrollable {
      margin: 0px 0 !important;
   }

   #fileInputDiv3 .fileUPload,
   #fileInputDiv4 .fileUPload {
      width: 280px !important;
   }

   #fileInputDiv .fileUPload,
   #fileInputDiv2 .fileUPload {
      width: 150px !important;
   }

   #fileInputDiv {
      display: block;
      width: 100%;
   }

   #fileInputDiv .input-group.input-small {
      display: block;
      width: 100% !important;
   }

   #fileInputDiv .input-group.input-small .fileUPload {
      width: 100% !important;
      display: block !important;
      float: unset;
   }

   #fileInputDiv .input-group.input-small span.input-group-addon.btn.default.btn-file {
      width: 33% !important;
      display: inline-block;
      margin-right: 5px;
      padding: 6px 5px;
      margin-top: 5px;
   }

   #fileInputDiv .input-group.input-small a.input-group-addon.btn.red.fileinput-exists {
      width: 32% !important;
      margin-top: 5px;
      display: inline-block;
   }

   #fileInputDiv .input-group.input-small .viewcvbtn.text-right {
      display: inline-block;
      width: 30% !important;
      margin-left: 5px;
      margin-top: 5px;
      vertical-align: top;
   }

   #fileInputDiv .input-group.input-small .viewcvbtn.text-right a.btn.blue.button-submit {
      padding: 6px 7px;
      width: 100%;
   }

   .fileinput-new#fileInputDiv .input-group.input-small a.input-group-addon.btn.red.fileinput-exists {
      display: none;
   }

   .d_flex {
      display: flex;
   }

   .justify_content_center {
      justify-content: center;
   }

   @media only screen and (max-width: 767px) {

      #fileInputDiv .fileUPload,
      #fileInputDiv2 .fileUPload {
         width: 100px !important;
      }

      #fileInputDiv3 .fileUPload,
      #fileInputDiv4 .fileUPload {
         width: 200px !important;
      }
   }

   @media only screen and (max-width: 414px) {
      input#NoticeYear {
         padding: 6px 7px;
         width: 50px;
      }
   }

   @media only screen and (max-width: 768px) {
      .input-group-addon {
         padding: 6px 6px;
      }
   }

   @media only screen and (min-device-width : 992px) and (max-device-width : 1200px) {
      .pt_md_2 {
         padding-top: 15px;
         clear: both;
      }

      .control-label .required,
      .form-group .required {
         position: absolute;
         top: 0;
      }
   }

   @media (min-width: 1201px) and (max-width: 1822px) {
      /* .col_xl_3 {
         width: 25% !important;
      } */

      .fileinput.fileinput-exists .form-control.uneditable-input.input-fixed.input-medium {
         min-width: unset !important;
      }

      .fileinput.fileinput-exists span.input-group-addon.btn.default.btn-file,
      .fileinput.fileinput-exists a.input-group-addon.fileinput-exists {
         padding: 6px 5px !important;
      }

      .input-group.notice-periodbox span.input-group-addon.inputaddon {
         padding: 6px 6px !important;
         font-size: 11px !important;
         width: 50% !important;
         display: inline-block !important;
         height: 34px !important;
         margin-bottom: 10px !important;
      }

      .input-group.notice-periodbox input {
         padding: 6px 6px !important;
         width: 50% !important;
         display: inline-block !important;
         margin-bottom: 10px !important;
      }
   }

   .dropdown-menu>li>a {
      padding-left: 25px !important;
   }

   .btnInterview button {
      margin-right: 10px;
      min-width: 100px;
   }

   .bootstrap-tagsinput {
      width: 100%;
   }
</style>
<div class="page-content-wrapper">
   <div class="page-content">
      <ul class="page-breadcrumb breadcrumb mb20">
      </ul>
      <div class="col-md-12">
         <div id="form_wizard_1" class="portlet light bordered">

            <div class="portlet-title">
               <div class="caption">
                  <span class="caption-subject font-red bold uppercase"> Candidate Info</span>
               </div>
               <div class="caption pull-right">
                  <a routerLink="/candidateoverview"><i class="fa fas fa-reply" data-toggle="tooltip"
                        data-placement="top" title="Back To Overview"></i></a>&nbsp;&nbsp;
                  <button type="button" class="btn  dark btn-outline" *ngIf="editMode" (click)="history()"><i
                        class="fa fa-history"></i></button>
               </div>
            </div>

            <p style="margin: 5px 0;">&nbsp;</p>
            <div class="portlet box white">
               <div class="portlet-body form">
                  <form id="submit_form" class="form-horizontal" [formGroup]="candidateinfoform">
                     <div class="form-group form-md-line-input">
                        <label class="col-xl-2 col-lg-2 col-md-2 control-label" for="form_control_1">Title
                           <!-- <span class="required"> *</span> -->
                        </label>
                        <div class="col-xl-3 col-lg-3 col-md-5">
                           <div class="md-radio-inline">
                              <div class="md-radio">
                                 <input type="radio" id="radio53" name="Title" value="Mr." formControlName="Title"
                                    class="md-radiobtn">
                                 <label for="radio53">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> Mr. </label>
                              </div>
                              <div class="md-radio has-error">
                                 <input type="radio" id="radio54" name="Title" value="Mrs" formControlName="Title"
                                    class="md-radiobtn" checked>
                                 <label for="radio54">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> Mrs. </label>
                              </div>
                              <div class="md-radio has-warning">
                                 <input type="radio" id="radio55" name="Title" value="Miss" formControlName="Title"
                                    class="md-radiobtn">
                                 <label for="radio55">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> Miss. </label>
                              </div>
                              <div class="md-radio has-info">
                                 <input type="radio" id="radio56" name="Title" value="Ms." formControlName="Title"
                                    class="md-radiobtn">
                                 <label for="radio56">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> Ms. </label>
                              </div>
                              <div class="md-radio has-info">
                                 <input type="radio" id="radio57" name="Title" value="Dr." formControlName="Title"
                                    class="md-radiobtn">
                                 <label for="radio57">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> Dr. </label>
                              </div>
                              <div class="md-radio has-warning">
                                 <input type="radio" id="radio58" name="Title" formControlName="Title" value="Other"
                                    class="md-radiobtn">
                                 <label for="radio58">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> Other </label>
                              </div>
                           </div>
                        </div>
                        <!-- <label class="col-xl-2 col-lg-2 col-md-2 control-label" for="form_control_1">Gender<span
                              class="required"> *
                           </span>
                        </label>
                        <div class="col-xl-3 col-lg-2 col-md-3">
                           <div class="md-radio-inline">
                              <div class="md-radio">
                                 <input type="radio" id="radio577" class="md-radiobtn" name="Gender_Id"
                                    formControlName="Gender_Id" value="1">
                                 <label for="radio577">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> Male </label>
                              </div>
                              <div class="md-radio has-error">
                                 <input type="radio" id="radio58" class="md-radiobtn" name="Gender_Id"
                                    formControlName="Gender_Id" value="2">
                                 <label for="radio58">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> Female </label>
                              </div>
                              <div class="md-radio has-warning">
                                 <input type="radio" id="radio59" class="md-radiobtn" name="Gender_Id"
                                    formControlName="Gender_Id" value="3">
                                 <label for="radio59">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> Others </label>
                              </div>
                           </div>
                        </div> -->
                     </div>

                     <div class="form-group">
                        <label class="control-label col-lg-2 col-md-3">First Name
                           <span class="required"> * </span>
                        </label>
                        <div class="col-md-3">
                           <input type="text" class="form-control" formControlName="FirstName" id="FirstName"
                              autocomplete="off" maxlength="50" />
                        </div>
                        <label class="control-label col-lg-2 col-md-2">Middle Name</label>
                        <div class="col-md-3">
                           <input type="text" class="form-control" formControlName="MiddleName" id="MiddleName"
                              autocomplete="off" maxlength="50" />
                        </div>
                     </div>
                     <div class="form-group">
                        <label class="control-label col-lg-2 col-md-3">Last Name
                           <span class="required"> * </span>
                        </label>
                        <div class="col-md-3">
                           <input type="text" class="form-control" formControlName="LastName" id="LastName"
                              autocomplete="off" maxlength="50" />
                        </div>

                        <label class="control-label col-lg-2 col-md-2">Email
                           <span class="required"> * </span>
                        </label>
                        <div class="col-md-3">
                           <input type="text" class="form-control" id="EMail" formControlName="EMail" autocomplete="off"
                              maxlength="100" />
                        </div>
                     </div>


                     <div class="form-group">
                        <div class="pt_md_2">
                           <label class="control-label col-xl-2 col-lg-2 col-md-3">Mobile <span class="required"> *
                              </span></label>
                           <div class="col_xl_3 col-lg-3 col-md-8">
                              <!-- style="width: 24.66667% !important;" -->
                              <div class="input-group mobilenowithcountryode">
                                 <div class="input-group-btn">
                                    <ng-select2 [data]="countryList" id="Country_Id" formControlName="Country_Id"
                                       [placeholder]="'--- Select ---'">
                                    </ng-select2>
                                 </div>

                                 <input type="text" id="Mobile" class="form-control" autocomplete="off"
                                    formControlName="Mobile"
                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.key === "Backspace" ||  event.charCode == 40 
                              || event.charCode == 32 || event.charCode == 41 || event.charCode == 43 || event.charCode == 45 ' minlength=10
                                    maxlength=20 />
                              </div>
                           </div>
                        </div>
                        <div class="pt_md_2">
                           <label class="control-label col-lg-2 col-md-3">Upload CV<span class="required"> * </span>
                           </label>
                           <div class="col-lg-3 col-md-8">
                              <div class="fileinput fileinput-new" data-provides="fileinput">
                                 <div class="input-group input-large docinbox">
                                    <div class="form-control uneditable-input input-fixed input-medium"
                                       data-trigger="fileinput">
                                       <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                       <span *ngIf="cvName == ''" class="fileinput-filename"> </span>
                                       <span *ngIf="cvName != ''" class="fileinput-filename">{{cvName}}</span>

                                    </div>
                                    <span class="input-group-addon btn default btn-file">
                                       <span class="fileinput-new"> Select file </span>
                                       <span class="fileinput-exists"> Change </span>
                                       <input type="file" id="uploadCV" (change)="fileChangeForCV($event,0)"> </span>
                                    <a id="removeCV" class="input-group-addon btn red fileinput-exists removedoc"
                                       data-dismiss="fileinput"> Remove </a>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="col-md-1">
                           <a class="btn btn-sm dark btn-outline" (click)="downloadCV()" data-toggle="modal"
                              *ngIf="(cvName != null && cvName != '')" class="btn">
                              <i class="fa fa-download purple iconcss iconFontsize" data-toggle="tooltip"
                                 data-placement="top" title="Download CV"></i></a>
                        </div>
                     </div>


                     <div class="form-group">
                        <label class="control-label col-lg-2 col-md-3">Expected Salary
                        </label>
                        <div class="col-lg-3 col-md-8">
                           <div class="input-group mobilenowithcountryode">
                              <input type="number" min="0" value="0" step="0.01" class="form-control"
                                 formControlName="Expected_Salary" maxlength="10" />
                              <div class="input-group-btn">
                                 <ng-select2 [data]="currenciesList" id="currency_Id" formControlName="currency_Id"
                                    [placeholder]="'--- Select ---'">
                                 </ng-select2>
                              </div>
                           </div>
                        </div>
                        <div class="pt_md_2">
                           <label class="control-label col-xl-2 col-lg-2 col-md-3">Notice Period</label>
                           <div class="col-lg-3 col-md-8">
                              <div class="input-group notice-periodbox">
                                 <input type="text" class="form-control" formControlName="NoticeDay" id="NoticeDay"
                                    min="0" max="31" maxlength="2" autocomplete="off"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.key === 'Backspace'"
                                    placeholder="">
                                 <span class="input-group-addon inputaddon">Days</span>
                                 <input type="text" class="form-control" formControlName="NoticeMonth" id="NoticeMonth"
                                    maxlength="2" autocomplete="off"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.key === 'Backspace'"
                                    placeholder="">
                                 <span class="input-group-addon inputaddon">Months</span>
                                 <!-- <input type="text" class="form-control" formControlName="NoticeYear" id="NoticeYear"
                                    maxlength="4" autocomplete="off"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.key === 'Backspace'" placeholder="">
                                 <span class="input-group-addon inputaddon">Years</span> -->
                              </div>
                           </div>
                        </div>
                     </div>


                     <div class="form-group">
                        <label class="col-lg-2 col-md-3 control-label" for="form_control_1">Applied For<span class="required"> * </span>
                        </label>
                        <div class="col-lg-3 col-md-3">
                           <ng-select2 [data]="approvedRAFList" id="Vacancy_Id" [options]="{multiple: true,tags: false}"
                           [value]='this.candidateinfoform.controls.Vacancy_Id.value'
                              (valueChanged)="setFormData('Vacancy_Id',$event)" [placeholder]="'--- Select ---'">
                              
                           </ng-select2>
                           <!-- <option id="0">Speculative</option> -->
                        </div>
                        <label class="control-label col-lg-2 col-md-2">Right To Work<span class="required"> * </span>
                        </label>
                        <div class="col-lg-1_1 col-md-3">
                           <div class="md-radio-inline">
                              <div class="md-radio">
                                 <input type="radio" id="radio60" name="isRightToWork" class="md-radiobtn"
                                    formControlName="isRightToWork" value="1" checked>
                                 <label for="radio60">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> Yes </label>
                              </div>
                              <div class="md-radio has-error">
                                 <input type="radio" id="radio61" name="isRightToWork" class="md-radiobtn"
                                    formControlName="isRightToWork" value="0">
                                 <label for="radio61">
                                    <span></span>
                                    <span class="check"></span>
                                    <span class="box"></span> No </label>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="form-group">
                        <label class="col-md-2 control-label" for="form_control"
                           *ngIf="fieldVisibleInvisible[0].Tags == 1">Tags
                        </label>
                        <div class="col-md-3" *ngIf="fieldVisibleInvisible[0].Tags == 1">
                           <input type="text" id="Tags" data-role="tagsinput" maxlength="500">
                        </div>
                        <label class="col-lg-2 col-md-3 control-label" for="form_control">Skills
                        </label>
                        <div class="col-lg-3 col-md-8">
                           <input type="text" id="Skills" data-role="tagsinput" maxlength="500">
                        </div>
                     </div>
                     <div class="form-group">
                        <label class="col-lg-2 col-md-3 control-label" for="form_control">Department
                        </label>
                        <div class="col-md-3">

                           <ng-select2 [data]="jobCategoryList" id="Job_Category"
                              [options]="{multiple: true,tags: false}"
                              [value]='this.candidateinfoform.controls.lstJob_Category.value'
                              (valueChanged)="setFormData('lstJob_Category',$event)" [placeholder]="'--- Select ---'">
                           </ng-select2>

                           <!-- <input type="text" id="Job_Category" data-role="tagsinput" maxlength="500"> -->
                        </div>
                        <label class="col-lg-2 col-md-2 control-label" for="form_control">Job Title
                        </label>
                        <div class="col-md-3">

                           <ng-select2 [data]="jobLevelList" id="Job_Level" [options]="{multiple: true,tags: false}"
                              [value]='this.candidateinfoform.controls.lstJob_Level.value'
                              (valueChanged)="setFormData('lstJob_Level',$event)" [placeholder]="'--- Select ---'">
                           </ng-select2>

                           <!-- <input type="text" id="Job_Level" data-role="tagsinput" maxlength="500"> -->
                        </div>
                     </div>
                     <div class="form-group">
                        <!-- <label class="col-lg-2 col-md-3 control-label" for="form_control">Preferred Location
                        </label>
                        <div class="col-lg-3 col-md-3">
                           <input type="text" id="Prefer_Location" data-role="tagsinput" maxlength="500">
                        </div> -->
                        <label class="col-md-2 control-label" for="form_control">Experience
                        </label>
                        <div class="md-radio-inline col-md-3">

                           <select class="form-control" id="Experience" formControlName="Experience">
                              <option value=''>Select Experience</option>
                              <option value='< 1 year'>
                                 < 1 year</option>
                              <option value='1-2 years'>1-2 years</option>
                              <option value='3-5 years'>3-5 years</option>
                              <option value='5-10 years'>5-10 years</option>
                              <option value='10+ years'>10+ years</option>
                           </select>
                        </div>
                     </div>
                     <hr>
                     <h4>Documents</h4>
                     <div class="row">
                        <div class="col-lg-offset-2 col-md-offset-1 col-lg-7 col-md-10">
                           <div class="form-group">
                              <div class="table-responsive col-md-12">

                                 <table class="table table-hover" id="rightwork2" style="width: 100%;">
                                    <thead>
                                       <tr>
                                          <th>Select Document</th>
                                          <th>Document Name</th>
                                          <th>Select File</th>
                                          <th style="width: 8%;">View Doc</th>
                                          <th style="width: 5%;"><button type="button"
                                                class="btn pull-right green addMorefields2" (click)="addItem(null)"><i
                                                   class="fa fa-plus" aria-hidden="true"></i></button></th>
                                       </tr>
                                    </thead>
                                    <tbody formArrayName="emp_document"
                                       *ngFor="let item of candidateinfoform.get('emp_document')['controls']; let i = index;">

                                       <tr [formGroupName]="i">
                                          <td>
                                             <select class="form-control" id="Doc_Id{{i}}" formControlName="Doc_Id">
                                                <option value='0'>Select Document Type</option>
                                                <option *ngFor="let item of documentList;" value={{item.id}}>
                                                   {{item.name}}</option>
                                             </select>
                                          </td>
                                          <td>
                                             <input type="text" class="form-control" autocomplete="off"
                                                formControlName="Doc_Name">
                                          </td>

                                          <td>
                                             <div class="fileinput fileinput-new" id="candidateDocument{{i}}"
                                                data-provides="fileinput">
                                                <div class="input-group input-large">
                                                   <div class="form-control uneditable-input input-fixed input-medium"
                                                      data-trigger="fileinput">
                                                      <i class="fa fa-file fileinput-exists"></i>&nbsp;
                                                      <span class="fileinput-filename"
                                                         *ngIf="(candidateinfoform.get('emp_document').value[i].Id) == 0">
                                                      </span>
                                                      <span class="fileinput-filename"
                                                         *ngIf="(candidateinfoform.get('emp_document').value[i].Id)>0">
                                                         {{candidateinfoform.get('emp_document').value[i].file_Name}}
                                                      </span>
                                                   </div>
                                                   <span class="input-group-addon btn default btn-file">
                                                      <span class="fileinput-new"> Select file </span>
                                                      <span class="fileinput-exists"> Change </span>
                                                      <input type="file" id="Emp_Doc_Path{{i}}"
                                                         (change)="fileChangeMultiple($event,i)">
                                                   </span>
                                                   <a href="javascript:;" id="remove{{i}}"
                                                      class="input-group-addon btn red fileinput-exists"
                                                      (click)="removeDoc(i)" data-dismiss="fileinput"> Remove </a>
                                                </div>
                                             </div>
                                          </td>

                                          <td>
                                             <a *ngIf="(candidateinfoform.get('emp_document').value[i].Id)>0"
                                                class="btn"
                                                (click)="downloadDocument(candidateinfoform.get('emp_document').value[i].Id)"><i
                                                   class="fa fa-download purple iconcss iconFontsize"
                                                   data-toggle="tooltip" data-placement="top"
                                                   title="Download Document"></i></a>
                                          </td>
                                          <td>
                                             <button type="button" (click)="removeItem(i)" class="btn pull-right red"><i
                                                   class="fa fa-minus" aria-hidden="true"></i></button>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>

                     <hr>
                     <h4>Notes</h4>
                     <div class="row">
                        <div class="col-lg-offset-2 col-md-offset-1 col-lg-7 col-md-10">
                           <div class="form-group">
                              <div class="table-responsive col-md-12">

                                 <table class="table table-hover" id="rightwork2" style="width: 100%;">
                                    <thead>
                                       <tr>
                                          <th>Note</th>
                                          <th style="width: 5%;"><button type="button"
                                                class="btn pull-right green addMorefields2"
                                                (click)="addItemNote(null)"><i class="fa fa-plus"
                                                   aria-hidden="true"></i></button></th>
                                       </tr>
                                    </thead>
                                    <tbody formArrayName="candidate_Note"
                                       *ngFor="let item of candidateinfoform.get('candidate_Note')['controls']; let i = index;">

                                       <tr [formGroupName]="i">
                                          <td>
                                             <textarea class="form-control" id="AddNotes"
                                                formControlName="Notes"></textarea>
                                                <!-- <textarea class="form-control" id="Notes{{i}}"
                                                formControlName="Notes"></textarea> -->
                                          </td>

                                          <td>
                                             <button type="button" (click)="removeItemNote(i)"
                                                class="btn pull-right red"><i class="fa fa-minus"
                                                   aria-hidden="true"></i></button>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>


                     <div class="form-actions">
                        <div class="row">
                           <div class="col-md-12 d_flex justify_content_center">
                              <a routerLink="/candidateoverview" class="btn default"> Cancel </a>&nbsp;

                              <button type="button" [disabled]="!(this.candidateid == null)" (click)="candidateUpsert()"
                                 style="width: 100px;" class="btn btn-outline blue">
                                 Save
                              </button>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>



<div class="modal fade draggable-modal" id="Createrecruitment" tabindex="-2" role="basic" aria-hidden="true">
   <div class="modal-dialog" style="margin: auto !important; display: flex;align-items: center;height: 100%;">
      <div class="modal-content" style="width: 100%;">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">X</button>
            <h4 class="modal-title">History Of Notes</h4>
         </div>
         <div class="modal-body">
            <div class="scroller" style="height:420px" data-always-visible="1" data-rail-visible1="1">
               <div class="row">
                  <div class="col-md-12">
                     <table class="table table-striped table-bordered table-hover" id="sample_2">
                        <thead class="flip-content">
                           <tr>
                              <th class="text-center"> Date </th>
                              <th class="text-center"> Notes </th>
                              <th class="text-center"> User </th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td>29/07/2020</td>
                              <td>
                                 <div class="short_description" data-toggle="tooltip" data-placement="bottom" title=""
                                    data-original-title="History of notes. This section will keep expanding as the notes get added.">
                                    History of notes. This section will keep expanding as the notes get added.</div>
                              </td>
                              <td class="text-center">
                                 Test user
                              </td>
                           </tr>
                           <tr>
                              <td>29/07/2020</td>
                              <td>
                                 <div class="short_description" data-toggle="tooltip" data-placement="bottom" title=""
                                    data-original-title="History of notes. This section will keep expanding as the notes get added.">
                                    History of notes. This section will keep expanding as the notes get added.</div>
                              </td>
                              <td class="text-center">
                                 Test user2
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<div class="modal" id="modalCandidateDocument" role="basic" aria-hidden="true">
   <div class="modal-dialog modal-lg" style="margin:4% auto !important">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
            <h4 class="modal-title">Candidate Document</h4>
         </div>
         <div class="modal-body">
            <div class="row">
               <div class="col-md-12">
                  <iframe [src]='fileUrl' width="100%" height="600px"></iframe>
               </div>
            </div>

         </div>
         <div class="modal-footer">
            <button type="button" data-dismiss="modal" class="btn dark btn-outline">Close</button>
         </div>
      </div>
   </div>
</div>


<div class="modal fade draggable-modal" id="historyModal" tabindex="-1" role="dialog" aria-hidden="true">
   <div class="modal-dialog modal-lg">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
            <h4 class="modal-title">Candidate History</h4>
         </div>
         <div class="modal-body">
            <app-history></app-history>
         </div>
      </div>
   </div>
</div>