<form [formGroup]="candidateForm">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Address 1<span class="required"> * </span></label>
                <input type="text" class="form-control" id="address1" formControlName="address1" autocomplete="off"
                    maxlength="100" ngx-google-places-autocomplete [options]=
                    'options'  (onAddressChange)="AddressChangeCandidate($event)">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Address 2<span class="required"> * </span></label>
                <input type="text" class="form-control" id="address2" formControlName="address2" autocomplete="off"
                    maxlength="100">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Address 3</label>
                <input type="text" class="form-control" id="address3" formControlName="address3" autocomplete="off"
                    maxlength="100">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Address 4</label>
                <input type="text" class="form-control" id="address4" formControlName="address4" autocomplete="off"
                    maxlength="100">
            </div>
        </div>
    </div>
    <div class="row">
       
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">City<span class="required"> * </span></label>
                <input type="text" class="form-control" id="city" formControlName="city" autocomplete="off"
                    maxlength="100">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">County</label>
                <input type="text" class="form-control" id="state" formControlName="state" autocomplete="off"
                    maxlength="100">
            </div>
        </div>
    </div>
    <div class="row">
        
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Country<span class="required"> * </span></label>  
                <ng-select2 [data]="countrylist" id="country_Id" formControlName="country_Id"
                    [placeholder]="'--- Select ---'">
                </ng-select2>              
                <!-- <select class="form-control" id="country_Id" formControlName="country_Id"
                                autocomplete="off">
                    <option value="">Select Country</option>
                    <option *ngFor="let item of countrylist;" value={{item.id}}>
                        {{item.country_Name}}</option>
                    
                </select>  -->
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Post Code<span class="required"> * </span></label>
                <input type="text" class="form-control" id="postCode" formControlName="postCode" autocomplete="off" 
                    maxlength="10">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-1">
            <div class="form-group">
                <label class="control-label">Default</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox">
                        <input type="checkbox" id="addressDefault" class="md-check" formControlName="isDefault">
                        <label for="addressDefault">
                            <span></span>
                            <span class="check"></span>
                            <span class="box"></span> </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label class="control-label">Active</label>
                <div class="md-checkbox-inline">
                    <div class="md-checkbox-inline">
                        <div class="md-checkbox">
                            <input type="checkbox" id="addressActive" class="md-check" formControlName="isActive">
                            <label for="addressActive">
                                <span></span>
                                <span class="check"></span>
                                <span class="box"></span> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>